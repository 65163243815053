import { Inject, Injectable, Type } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, NavController, PopoverController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { ROUTE_COMPONENTS } from '../constants';
import { StringHelper } from '../helpers/stringHelper';
import { PageInfo } from '../model/PageInfo';
import { RouteComponent } from '../model/RouteComponent';
import { ELifeCycleEvent } from '../model/lifeCycle/ELifeCycleEvent';
import { ILifeCycleEvent } from '../model/lifeCycle/ILifeCycleEvent';
import { INavbarEvent } from '../model/navbar/INavbarEvent';
import { ApplicationService } from './application.service';
import { DomainService } from './domain.service';

/** Le service PageManagerService donne l'accès à toutes les pages de l'application. */
@Injectable({ providedIn: 'root' })
export class PageManagerService {

	//#region FIELDS

	/** dynamicPage */
	private readonly C_DYNAMIC_PAGE: string = "dynamicPage";

	/** Sujet pour les événements de la navbar. */
	private moNavbarEventSubject: Subject<INavbarEvent> = new Subject();
	/** Sujet pour les événements du cycle de vie. */
	private moLifeCycleEventsSubject: Subject<ILifeCycleEvent> = new Subject();
	/** Ce tableau contiendra tous les composants/pages de l'application. */
	private maComponents: Array<RouteComponent> = [];
	/** Dernière page affichée */
	private msActivePageId: string;

	//#endregion

	//#region PROPERTIES

	/** PageInfo */
	public static readonly C_PAGE_INFO_NAME = "PageInfo";

	public get activePageId(): string {
		return this.msActivePageId;
	}

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des modales. */
		private ioModalCtrl: ModalController,
		/** Service de gestion des domaines. */
		private isvcDomain: DomainService,
		/** Service de routage */
		private ioNavController: NavController,
		private ioRouter: Router,
		private ioPopoverCtrl: PopoverController,
		private ioAlertCtrl: AlertController,
		@Inject(ROUTE_COMPONENTS) routeComponents: RouteComponent[]
	) {

		this.ioRouter.routeReuseStrategy.shouldReuseRoute = () => false;
		this.addComponents(routeComponents);
	}

	/** Ajoute les composants donnés au tableau des composants accessibles.
	 * @param paRouteComponents Les des RouteComponent à ajouter.
	 * @throws `Error` si un composant est déjà connu.
	 */
	public addComponents(paRouteComponents: Array<RouteComponent>): void {
		paRouteComponents.forEach((poRouteComponent: RouteComponent) => this.addComponent(poRouteComponent.id, poRouteComponent.componentType));
	}

	/** Ajoute le composant donné au tableau des composants accessibles.
	 * @param psComponentId Id du composant pour s'en servir.
	 * @param poComponent Composant à ajouter.
	 */
	private addComponent(psComponentId: string, poComponent: Type<any>): void {
		const loRouteComponent: RouteComponent = new RouteComponent(psComponentId, poComponent);

		if (this.getRouteComponentFromId(loRouteComponent.id))
			throw new Error(`error : ${loRouteComponent.id} est déjà un composant existant.`);

		this.maComponents.push(loRouteComponent);
	}

	/** Envoie les données nécessaires pour modifier la navbar (initialiser, ajouter, cacher, ...).
	 * @param poNavbarEvent Données à envoyer pour la navbar.
	 */
	public raiseNavbarEvent(poNavbarEvent: INavbarEvent): void {
		this.moNavbarEventSubject.next(poNavbarEvent);
	}

	/** Retourne le composant correspondant à l'id passé en paramètre, 'undefined' si non trouvé.
	 * @param psComponentId Id du composant à trouver.
	 */
	public getComponentById(psComponentId: string): Type<any> {
		const loRouteComponent: RouteComponent = this.getRouteComponentFromId(psComponentId);

		return loRouteComponent ? loRouteComponent.componentType : undefined;
	}

	/** Retourne le composant dont le nom est donné en paramètre.
	 * @param psId Id de la page cherchée.
	 */
	public getRouteComponentFromId(psId: string): RouteComponent {
		return this.maComponents.find((poRouteComponent: RouteComponent) => poRouteComponent.id === psId);
	}

	/** Retourne tous les composants enregistrés. */
	public getRouteComponents(): RouteComponent[] {
		return Array.from(this.maComponents);
	}

	/** Retourne sur la page précédente.
	 * @param poData Données à passer en paramètre de la modale lors de la fermeture
	 */
	public async goBack(poData?: any): Promise<void> {
		// On ferme toutes les popovers de la page.
		await this.closeAllPopovers();

		// On ferme la modale si la page en est une.
		const loModal: HTMLIonModalElement = await this.ioModalCtrl.getTop();

		if (loModal) {
			loModal.dismiss(poData);
		}
		else {
			const lsOldUrl: string = this.ioRouter.url;
			// Si la page n'est pas une modale alors on doit faire un 'back' sinon c'est que c'est une modale qu'on vient de fermer.
			await this.ioNavController.pop();
			// Permet de fixer le bug des guards https://github.com/angular/angular/issues/13586

			if (lsOldUrl === this.ioRouter.url) // Si on n'a pas changé d'url, alors il n'y a rien avant nous dans la pile de navigation. On se dirige donc sur la page home.
				this.goHome();
		}

	}

	/** Ferme la popover la plus récente de la page courante s'il y en a une.
	 * @returns `true` si une popover s'est fermée, `false` sinon.
	 */
	public async closePopover(): Promise<boolean> {
		const loPopover: HTMLIonPopoverElement = await this.ioPopoverCtrl.getTop();

		return loPopover ? this.ioPopoverCtrl.dismiss() : false;
	}

	/** Ferme toutes les popovers de la page courante s'il y en a.
	 * @returns `true` si des popovers se sont fermées, `false` sinon.
	 */
	public async closeAllPopovers(): Promise<boolean> {
		let loPopover: HTMLIonPopoverElement = await this.ioPopoverCtrl.getTop();
		let lbHasClosedPopover: boolean;

		while (loPopover) {
			lbHasClosedPopover = await this.ioPopoverCtrl.dismiss();
			loPopover = await this.ioPopoverCtrl.getTop();
		}

		return lbHasClosedPopover;
	}

	/** Ferme l'alert la plus récente de la page courante s'il y en a une.
	 * @returns `true` si une alert s'est fermée, `false` sinon.
	 */
	public async closeAlert(): Promise<boolean> {
		const loAlert: HTMLIonAlertElement = await this.ioAlertCtrl.getTop();

		return loAlert ? this.ioAlertCtrl.dismiss() : false;
	}

	/** Retour à la page d'accueil.
	 * @param psUrl Url de la page home.
	*/
	public goHome(psUrl?: string): void {
		this.ioNavController.navigateRoot(!StringHelper.isBlank(psUrl) ? psUrl : ApplicationService.C_HOME_ROUTE_URL);
	}

	/** Routage vers une page en utilisant le composant passé en paramètre plutôt que celui du pageInfo.
	 * @param poComponent Composant de la page.
	 * @param poPageInfo Informations pour la page de destination.
	 * @param psCallerId Identifiant du composant qui appelle la méthode.
	 */
	public async routePageFromComponent(poComponent: Type<any>, poPageInfo: PageInfo, psCallerId: string = ""): Promise<boolean> {
		const loRouteComponent: RouteComponent = !poPageInfo.isStandalone ? this.getRouteComponentFromId(this.C_DYNAMIC_PAGE) : undefined;
		const loComponent: Type<any> = loRouteComponent ? loRouteComponent.componentType : poComponent;

		return this.routeToPage(poPageInfo, loComponent, psCallerId);
	}

	/** Routage vers la page dont les informations sont en paramètre.
	 * @param poPageInfo Informations pour la page de destination.
	 * @param psCallerId Identifiant du composant qui appelle la méthode.
	 */
	public async routePageFromInfo(poPageInfo: PageInfo, psCallerId: string = ""): Promise<boolean> {
		let loRouteComponent: RouteComponent = this.getRouteComponentFromId(poPageInfo.componentName);

		if (loRouteComponent) {
			if (!poPageInfo.isStandalone) // Vérification de la nécessité d'un component-page.
				loRouteComponent = this.getRouteComponentFromId(this.C_DYNAMIC_PAGE);

			const loComponent: Type<any> = loRouteComponent ? loRouteComponent.componentType : undefined;
			return this.routeToPage(poPageInfo, loComponent, psCallerId);
		}

		else {
			console.error(`PM.S:: Erreur lors du routage : Le composant "${poPageInfo.componentName}" est introuvable, avez-vous pensé à l'ajouter dans le registerComponent ?`);
			return false;
		}
	}

	/** Route vers une nouvelle page.
	 * @param poPageInfo Informations pour la page de destination.
	 * @param poComponent Composant à créer pour router vers celui-ci.
	 */
	private async routeToPage(poPageInfo: PageInfo, poComponent: Type<any>, psCallerId: string): Promise<boolean> {
		const loOptions: { pageInfo: PageInfo } = { pageInfo: poPageInfo };

		this.isvcDomain.currentDomain = poPageInfo.domainId;

		if (poPageInfo.isModal) {
			const loModal: HTMLIonModalElement = await this.ioModalCtrl.create({ component: poComponent, componentProps: loOptions });
			await loModal.present();
		}
		else {
			this.ioRouter.navigateByUrl(poPageInfo.path)
				.catch(poError => {
					console.error(`PM.S:: Erreur de navigation :`, poPageInfo);
					console.error(`PM.S:: Erreur de navigation :`, poError);
				});
		}

		return true;
	}

	/** Permet de s'abonner aux événements de la navbar. */
	public getNavbarEventAsObservable(): Observable<INavbarEvent> {
		return this.moNavbarEventSubject.asObservable();
	}

	public subscribePageLifeCycleEvents(poLifeCycleEventsObservable: Observable<ILifeCycleEvent>): void {
		poLifeCycleEventsObservable
			.subscribe((poEvent: ILifeCycleEvent) => {
				if (poEvent.data.value === ELifeCycleEvent.viewDidEnter) {
					this.msActivePageId = poEvent.data.pageId;
					console.debug(`PM.S::Active page changed to ${this.msActivePageId}.`);
				}
				this.moLifeCycleEventsSubject.next(poEvent);
			});
	}

	public getLifeCycleEventsObservable(): Observable<ILifeCycleEvent> {
		return this.moLifeCycleEventsSubject.asObservable();
	}

	//#endregion

}
