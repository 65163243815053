<ng-container *ngIf="amoP">

	<ion-grid>
		<div *ngIf="!isSecured && amoP._rev" class="warning">
			<ion-label>
				Saisie débloquée ! Il sera nécessaire de lire la Carte Vitale ou d'utiliser l'ADRI pour faire une FSE sécurisée.
			</ion-label>
		</div>

		<div class="grid-ctn">
			<ion-row>
				<ion-col size="4">
					<ion-label>CPAM</ion-label>
				</ion-col>

				<ion-col size="8">
					<mat-form-field *ngIf="etablissements?.length > 0" appearance="outline">
						<input type="text" matInput [matAutocomplete]="auto" [(ngModel)]="etablissementName"
							(ngModelChange)="updateFilteredEtablissements()" placeholder="Libellé du tiers">
						<mat-autocomplete #auto="matAutocomplete">
							<mat-option *ngFor="let option of filteredEtablissements" [value]="option.label"
								(click)="onOptionClick(option)">
								<div class="auto-option">
									<span>{{option.label}}</span>
									<span class="small">{{ getFormatedId(option) }}</span>
								</div>
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
				</ion-col>
			</ion-row>

			<ion-row>
				<ion-col size="4">
					<ion-label>Le patient est</ion-label>
				</ion-col>

				<ion-col size="8">
					<mat-form-field appearance="outline">
						<mat-select [(value)]="amoP.qualite">
							<mat-option *ngFor="let item of qualiteOptions" [value]="item.value" (click)="onQualiteOptionChange()">
								{{item.label}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</ion-col>
			</ion-row>

			<ion-row *ngIf="amoP.qualite !== '00'">
				<ion-col size="4">
					<ion-label>Le patient assuré est</ion-label>
				</ion-col>

				<ion-col size="8">
					<idl-patient-contacts-list [params]="patientsListParams" [model]="amoP.assurePatientId"
						(modelChange)="onPatientAssureModelChanged($event)" disa>
					</idl-patient-contacts-list>
				</ion-col>
			</ion-row>

			<ion-row>
				<ion-col size="4">
					<ion-label>Tiers payant</ion-label>
				</ion-col>

				<ion-col size="8">
					<mat-form-field appearance="outline">
						<mat-select [(value)]="amoP.tp">
							<mat-option *ngFor="let item of TPOptions" [value]="item.value">
								{{item.label}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</ion-col>
			</ion-row>

			<ion-row>
				<ion-col size="4">
					<ion-label>Pièce justificative</ion-label>
				</ion-col>

				<ion-col size="8">
					<ion-label *ngIf="isSecured">{{isADRi ? 'ADRi' : 'Carte Vitale'}}</ion-label>
					<mat-form-field *ngIf="!isSecured" appearance="outline">
						<mat-select [(value)]="amoP.pieceJustificative">
							<mat-option *ngFor="let item of pieceJustificativeOptions" [value]="item.value"
								(click)="onPieceJustificativeChange(item.value)">
								{{item.label}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</ion-col>
			</ion-row>

			<ion-row *ngIf="amoP.datePieceJustificative || isSecured">
				<ion-col size="4">
					<ion-label>Date</ion-label>
				</ion-col>

				<ion-col size="8">
					<ion-label *ngIf="isSecured">{{ amoP.updateDate | dateWithLocale : 'fr-FR' : 'dd/MM/yy HH:mm' }}
					</ion-label>
					<osapp-date-time *ngIf="!isSecured" [(model)]="amoP.datePieceJustificative" [params]="datePickerParams">
					</osapp-date-time>
				</ion-col>
			</ion-row>

			<ion-row>
				<ion-col size="4">
					<ion-label>Garantie</ion-label>
				</ion-col>

				<ion-col size="8">
					<mat-form-field appearance="outline">
						<mat-select [(value)]="amoP.garantie">
							<mat-option *ngFor="let item of garantieOptions" [value]="item.value">
								{{item.label}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</ion-col>
			</ion-row>

			<ion-row>
				<ion-col size="4">
					<ion-label>Période de couverture</ion-label>
				</ion-col>

				<ion-col size="8">
					<div class="v-center space-between">
						<div class="v-center">
							<ion-label>Du</ion-label>
							<osapp-date-time [model]="amoP.dateDebut" (modelChange)="onDateDebutChanged($event)"
								[params]="datePickerParams"></osapp-date-time>
						</div>
						<div class="v-center">
							<ion-label>Au</ion-label>
							<osapp-date-time [model]="amoP.dateFin" (modelChange)="onDateFinChanged($event)"
								[params]="datePickerParams"></osapp-date-time>
						</div>
					</div>
				</ion-col>
			</ion-row>

			<ion-row>
				<ion-col size="4">
					<ion-label>ALD</ion-label>
				</ion-col>

				<ion-col size="8">
					<mat-form-field appearance="outline">
						<mat-select [(value)]="amoP.ald">
							<mat-option *ngFor="let item of ALDOptions" [value]="item.value">
								{{item.label}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</ion-col>
			</ion-row>

			<ion-row>
				<ion-col size="4">
					<ion-label>Situation</ion-label>
				</ion-col>

				<ion-col size="8">
					<ion-label *ngIf="!amoP.situation && isSecured">Assurance Maladie</ion-label>

					<mat-form-field *ngIf="amoP.situation || !isSecured" appearance="outline">
						<mat-select [(value)]="amoP.situation">
							<mat-option *ngFor="let item of situationOptions" [value]="item.value" (click)="onSituationChange()">
								{{item.label}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</ion-col>
			</ion-row>

			<ion-row *ngIf="amoP.situation === 'MA' || amoP.situation === 'AT' || amoP.situation === 'PM' ">
				<ion-col size="4">
					<ion-label>Date</ion-label>
				</ion-col>

				<ion-col size="8">
					<osapp-date-time [(model)]="amoP.situationDate" [params]="datePickerParams">
					</osapp-date-time>
				</ion-col>
			</ion-row>

			<ion-row *ngIf="amoP.situation === 'AT'">
				<ion-col size="4">
					<ion-label>Support</ion-label>
				</ion-col>

				<ion-col size="8">
					<mat-form-field appearance="outline">
						<mat-select [(value)]="amoP.supportAT">
							<mat-option *ngFor="let item of supportATOptions" [value]="item.value">
								{{item.label}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</ion-col>
			</ion-row>

			<ion-row *ngIf="amoP.situation === 'AT'">
				<ion-col size="4">
					<ion-label>N° de l'attestation AT</ion-label>
				</ion-col>

				<ion-col size="8">
					<ion-item lines="none" class="item-input">
						<ion-input diInputNoSpaces maxlength="9" [(ngModel)]="amoP.numAttestationAT" aria-label="NumAttestationAT"
							placeholder="N° de l'attestation AT"></ion-input>
					</ion-item>
				</ion-col>
			</ion-row>

			<ion-row *ngIf="amoP.situation === 'AT'">
				<ion-col size="4">
					<ion-label>Destinataire</ion-label>
				</ion-col>

				<ion-col size="8">
					<ion-item lines="none" class="item-input">
						<ion-input diInputNoSpaces maxlength="9" [(ngModel)]="amoP.destinataireAT" aria-label="DestinataireAT"
							placeholder="AMO si différente des droits"></ion-input>
					</ion-item>
				</ion-col>
			</ion-row>

			<ion-row *ngIf="amoP.situation === 'SM'">
				<ion-col size="4">
					<ion-label>Montant SMG</ion-label>
				</ion-col>

				<ion-col size="8">
					<ion-item lines="none" class="item-input">
						<ion-input diPositiveNumber diMaxTwoDecimalPrice [(ngModel)]="amoP.montantSMG" type="number" step="0.01"
							aria-label="0.00" placeholder="0.00"></ion-input>
					</ion-item>
				</ion-col>
			</ion-row>

			<ion-row *ngIf="amoP.situation === 'PM'">
				<ion-col size="4">
					<ion-label>N° de prévention</ion-label>
				</ion-col>

				<ion-col size="8">
					<ion-col size="8">
						<ion-item lines="none" class="item-input">
							<ion-input diInputNoSpaces maxlength="8" [(ngModel)]="amoP.numPM" aria-label="NumPM"
								placeholder="N° de prévention"></ion-input>
						</ion-item>
					</ion-col>
				</ion-col>
			</ion-row>

			<ion-row *ngIf="amoP.situation === 'PM'">
				<ion-col size="4">
					<ion-label>Informations additionnelles</ion-label>
				</ion-col>

				<ion-col size="8">
					<ion-col size="8">
						<ion-item lines="none" class="item-input">
							<ion-input diInputNoSpaces maxlength="1" [(ngModel)]="amoP.champPM" aria-label="ChampPM"
								placeholder="Informations additionnelles"></ion-input>
						</ion-item>
					</ion-col>
				</ion-col>
			</ion-row>

		</div>
	</ion-grid>

	<div *ngIf="getManualCreationDate() as date" class="info">
		<ion-label>Création manuelle le {{ date | dateWithLocale : 'fr-FR' : 'dd/MM/yy' }} à {{ date | dateWithLocale :
			'fr-FR' : 'HH:mm' }}</ion-label>
	</div>

</ng-container>