import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { EDatabaseRole, IDataSource } from '@osapp/model';
import { Store } from '@osapp/services';
import { EMPTY, from, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ITransmissionRapport } from '../model/ITransmissionRapport';

@Injectable({
	providedIn: 'root'
})
export class TransmissionResolver implements Resolve<ITransmissionRapport> {

	//#region METHODS

	constructor(private isvcStore: Store, private ioRouter: Router) { }

	public resolve(poRouteSnapshot: ActivatedRouteSnapshot): Observable<ITransmissionRapport> {
		const loDataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				include_docs: true,
				key: poRouteSnapshot.params.transmissionId
			}
		};

		return this.isvcStore.getOne<ITransmissionRapport>(loDataSource, false)
			.pipe(mergeMap((poModel: ITransmissionRapport) => poModel ? of(poModel) : from(this.ioRouter.navigate([".."])).pipe(mergeMap(_ => EMPTY))));
	}

	//#endregion
}