import { StringHelper } from '@osapp/helpers/stringHelper';
import { Entity } from '@osapp/model/entities/Entity';
import { EAvatarSize } from '@osapp/model/picture/EAvatarSize';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { EntityBuilder } from '@osapp/services/EntityBuilder';
import { C_PREFIX_TRAITEMENT } from '../../../app/app.constants';
import { ITraitement } from '../../../model/ITraitement';
import { Traitement } from '../../../model/Traitement';
import { TraitementEntityGuard } from './TraitementEntityGuard';

export class TraitementEntityBuilder extends EntityBuilder<ITraitement>{
	constructor() {
		super(
			(psModelId: string) => RegExp(C_PREFIX_TRAITEMENT).test(psModelId),
			(poTraitement: ITraitement) => {
				const loTraitement: Traitement = Traitement.createFromData(poTraitement);

				return new Entity(
					loTraitement,
					`traitements/${loTraitement._id}/edit`,
					() => StringHelper.isBlank(loTraitement.name) ? loTraitement.label : loTraitement.name
				);
			},
			(psModelId: string) => "Traitements",
			EDatabaseRole.workspace,
			C_PREFIX_TRAITEMENT,
			() => ({
				size: EAvatarSize.medium,
				icon: "clipboard"
			}),
			undefined,
			undefined,
			undefined,
			new TraitementEntityGuard()
		);
	}
}