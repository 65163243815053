<div class="container">
	<div class="content">
		<lua-icone
			[isIcon]="true"
			customIconName="desmos"
			width="100px"
			height="100px"
		></lua-icone>
		<h2>Sélectionnez votre espace de travail</h2>
		<div class="button-list">
			<di-bouton-surface
				*ngFor="let workspace of workspaces"
				mode="filled"
				customIconName="desmos"
				[title]="workspace.name"
				(click)="selectWorkspace($event, workspace.id)"
			></di-bouton-surface>
		</div>
		<lua-bouton-texte
			libelle="Annuler"
			color="Blanc"
			(click)="disconnect()"
		></lua-bouton-texte>
	</div>
</div>
