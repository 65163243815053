import { IContact } from '../model/contacts/IContact';
import { StringHelper } from './stringHelper';

export abstract class ContactHelper {

	//#region PROPERTIES

	public static readonly C_NO_NAME_CONTACT_NAME = "Sans nom";

	/** Préfixe des documents de contacts dans la base de données. */
	private static readonly C_PREFIX = "cont_";

	//#endregion

	//#region METHODS

	/** Formatte le nom complet d'un contact `NOM Prénom` par défaut, ou `Prénom NOM` si le booléen est `true`.
	 * @param poContact Contact dont il faut récupérer le prénom et nom formatés.
	 * @param pbIsFirstNameBefore indique si on met le prénom avant le nom, false par défaut.
	 */
	public static getCompleteFormattedName(poContact: IContact, pbIsFirstNameBefore?: boolean): string;
	/** Formatte le nom complet d'une personne ou de l'utilisateur `NOM Prénom` par défaut, ou `Prénom NOM` si le booléen est `true`.
	 * @param psFirstname Prénom de la personne.
	 * @param psLastName Nom de la personne.
	 * @param pbIsFirstNameBefore indique si on met le prénom avant le nom, false par défaut.
	 */
	public static getCompleteFormattedName(psFirstname: string, psLastName: string, pbIsFirstNameBefore?: boolean): string;
	public static getCompleteFormattedName(poContactOrFirstName: IContact | string, poLastNameOrFirstNameBefore?: string | boolean, pbIsFirstNameBefore?: boolean)
		: string {
		let lsFirstName: string, lsLastName: string, lsCompleteName: string;

		if (typeof poContactOrFirstName === "string") { // Prénom passé en paramètre (et donc nom passé également en paramètre).
			lsFirstName = StringHelper.isBlank(poContactOrFirstName) ? "" : poContactOrFirstName;
			lsLastName = StringHelper.isBlank(poLastNameOrFirstNameBefore as string) ? "" : poLastNameOrFirstNameBefore as string;
		}
		else if (poContactOrFirstName) { // Contact passé en paramètre.

			if (poContactOrFirstName.displayName)
				return poContactOrFirstName.displayName;

			else {
				lsFirstName = (poContactOrFirstName as IContact).firstName;
				lsLastName = (poContactOrFirstName as IContact).lastName;
			}
		}
		else { // `null` ou `undefined` ou autre valeur non attendue passée en paramètre pour le prénom.
			lsFirstName = "";

			lsLastName = typeof poLastNameOrFirstNameBefore === "string" ? StringHelper.isBlank(poLastNameOrFirstNameBefore) ?
				"" : poLastNameOrFirstNameBefore : "";
		}

		if (StringHelper.isBlank(lsLastName) && StringHelper.isBlank(lsFirstName)) // Si prénom et nom pas renseignés.
			lsCompleteName = "";
		else if (StringHelper.isBlank(lsFirstName)) // Si prénom pas renseigné.
			lsCompleteName = this.getFormattedLastName(lsLastName);
		else if (StringHelper.isBlank(lsLastName)) // Si nom pas renseigné.
			lsCompleteName = this.getFormattedFirstName(lsFirstName);
		else { // Si prénom et nom renseignés.
			const lbIsFirstNameBefore: boolean = (typeof poLastNameOrFirstNameBefore === "boolean" && poLastNameOrFirstNameBefore) || pbIsFirstNameBefore;
			lsCompleteName = lbIsFirstNameBefore ?
				`${this.getFormattedFirstName(lsFirstName)} ${this.getFormattedLastName(lsLastName)}` : `${this.getFormattedLastName(lsLastName)} ${this.getFormattedFirstName(lsFirstName)}`;
		}

		return lsCompleteName;
	}

	/** Retourne le nom formatté de l'utilisateur ("NOM").
	 * @param psLastName chaîne de caractères correspondant au nom de l'utilisateur, optionnel.
	 */
	public static getFormattedLastName(psLastName: string): string {
		return StringHelper.isBlank(psLastName) ? "" : psLastName.toUpperCase().trim();
	}

	/** Retourne le prénom formatté de l'utilisateur ("Prénom").
	 * @param psFirstName chaîne de caractères correspondant au prénom de l'utilisateur, optionnel.
	 */
	public static getFormattedFirstName(psFirstName: string): string {
		if (StringHelper.isBlank(psFirstName))
			return "";
		else {
			return psFirstName
				.trim()
				.split(/\s|-/)
				.map((psElement: string) => `${psElement[0].toUpperCase()}${psElement.replace(psElement[0], "").toLowerCase().trim()}`)
				.join('-');
		}
	}

	/** Supprime le prefix du contact.
	 * @param psContactId Identifiant du contact.
	 * @example ContactHelper.removePrefix("cont_toto"); // Retournera "toto".
	 */
	public static removePrefix(psContactId: string): string {
		return psContactId.replace(new RegExp(`(${ContactHelper.C_PREFIX})`), "");
	}

	/** Supprime le prefix du contact.
	 * @param psContactId Identifiant du contact.
	 * @example ContactHelper.removePrefix("cont_toto"); // Retournera "toto".
	 */
	public static addPrefix(psContactId: string): string {
		return psContactId.startsWith(ContactHelper.C_PREFIX) ? psContactId : `${ContactHelper.C_PREFIX}${psContactId}`;
	}

	/** Retourne les initiales concaténées d'un contact.
	 * @param poContact
	 * @returns
	 */
	public static getInitials(poContact: IContact): string {
		if (!poContact)
			return "";

		return `${!StringHelper.isBlank(poContact.firstName) ? poContact.firstName.charAt(0) : ""}${!StringHelper.isBlank(poContact.lastName) ? poContact.lastName.charAt(0) : ""}`.toUpperCase();
	}

	//#endregion
}
