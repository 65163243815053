<ion-content>
	<div *ngIf="filteredSeances && filteredSeances.length === 0" class="empty-result">
		<div class="bloc-empty-result">
			<div class="title-empty-result">
				<ion-icon name="alert"></ion-icon>
				<ion-label>Aucune séance affectée à cet intervenant</ion-label>
			</div>
			<ion-note>Veuillez filtrer les séances sur un autre intervenant ou une autre date.</ion-note>
		</div>
	</div>
	<div #scrollWrapper class="scroll-wrapper">
		<div class="tournees-wrapper">
			<ng-container *ngTemplateOutlet="tournee"></ng-container>
		</div>
	</div>
</ion-content>

<ng-template #tournee>
	<ng-container *ngFor="let item of groupedFilteredSeances">
		<idl-tournee *ngIf="!!item" [seancesTournee]="item.seanceTournees" [isPlanificationMode]="isPlanificationMode"
			[seanceTourneeToEdit]="seanceTourneeToEdit" [showPlannedSeanceStatuses]="showPlannedSeanceStatuses"
			[title]="item.key" (onModalStatusChange)="onModalStatusChange($event)" [acteDisplayMode]="acteDisplayMode">
		</idl-tournee>
	</ng-container>
</ng-template>

<!-- Bouton de facturation. -->
<ion-fab *ngIf="canBillToDesmos" vertical="bottom" horizontal="start" slot="fixed" class="fab-margin">
	<ion-fab-button color="fab-button" (click)="goToFacturation()" class="facturation-button">
		<ion-icon name="desmos"></ion-icon>
		<ion-label>Envoyer {{ validatedSeancesCount }} / {{ totalSeancesCount }}</ion-label>
	</ion-fab-button>
</ion-fab>

<ion-fab *ngIf="canBillToFsv" vertical="bottom" horizontal="start" slot="fixed" class="fab-margin">
	<ion-fab-button color="fab-button" (click)="goToFacturation()" class="facturation-button">
		<ion-label>Préparer {{ validatedSeancesCount }} / {{ totalSeancesCount }}</ion-label>
	</ion-fab-button>
</ion-fab>

<!-- FabButton d'édition/validation de tournée. -->
<ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
	<ion-fab-button color="osapp-element" (click)="toggleMode()" class="fab-shadow">
		<ion-icon [name]="isPlanificationMode ? 'checkmark' : 'create'"></ion-icon>
	</ion-fab-button>
</ion-fab>