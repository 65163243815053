<ng-container *ngIf="field.type === 'adeli'; else inputSansEspace">
	<!-- ion-input avec la directive diInputNoSpaces -->
	<ion-input class="input" style="border: none;" [hidden]="to.hidden" [(ngModel)]="displayValue"
	  [formControl]="formControl" [ngStyle]="to.uppercase ? {'text-transform': 'uppercase'} : {}" [type]="inputType"
	  [inputmode]="inputType" (ionChange)="onModelChanged()" diInputNoSpaces maxlength="9">
	</ion-input>
  </ng-container>
  
  <ng-template #inputSansEspace>
	<!-- ion-input sans la directive diInputNoSpaces -->
	<ion-input class="input" style="border: none;" [hidden]="to.hidden" [(ngModel)]="displayValue"
	  [formControl]="formControl" [ngStyle]="to.uppercase ? {'text-transform': 'uppercase'} : {}" [type]="inputType"
	  [maxlength]="maxLength" [inputmode]="inputType" (ionChange)="onModelChanged()">
	</ion-input>
  </ng-template>