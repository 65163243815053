import { AfterViewInit, ChangeDetectorRef, Component, Optional, ViewRef } from '@angular/core';
import { merge, of } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { PerformanceManager } from '../modules/performance/PerformanceManager';
import { ICanWaitToRoute } from '../modules/routing/models/ican-wait-to-route';
import { RoutingAwaiter } from '../modules/routing/models/routing-awaiter';
import { DestroyableComponentBase } from '../modules/utils/components/destroyable-component-base';
import { Queuer } from '../modules/utils/queue/models/queuer';
import { ArrayHelper } from './arrayHelper';
import { GuidHelper } from './guidHelper';

@Component({ template: "" })
export abstract class ComponentBase extends DestroyableComponentBase implements ICanWaitToRoute, AfterViewInit {

	//#region FIELDS

	private static readonly C_COMPONENT_BASE_LOG_ID = "COMP.BASE.C::";
	protected msInstanceId: string = GuidHelper.newGuid();
	protected mnDelayBetweenDetectChangesMs = 100;

	private readonly moDetectChangesQueue = new Queuer({
		thingToQueue: () => of(this.internalDetectChanges()),
		minimumGapMs: this.mnDelayBetweenDetectChangesMs,
		keepOnlyLastPending: true
	});

	/** Identifiant de template donné par le pageInfo de la construction du composant. */
	protected readonly templateId: string;

	/** Indique si le composant possède une instance pour rafraîchir la vue ou non. */
	private mbHasChangeDetectorRef: boolean;
	/** Liste des files d'attentes du composant. Elles seront clôturées dans l'ordre à la sortie du composant. */
	private maQueuers?: ReadonlyArray<Queuer<any, any>>;
	private mbDetectChangesPending = false;

	//#endregion

	//#region PROPERTIES

	/** @implements */
	public readonly routingAwaiter = new RoutingAwaiter;

	//#endregion

	//#region METHODS

	constructor(
		@Optional() private readonly ioChangeDetectorRef: ChangeDetectorRef = undefined
	) {
		super();

		this.mbHasChangeDetectorRef = !!this.ioChangeDetectorRef;
	}

	/** Récupère l'identifiant de l'instance. */
	public getInstanceId(): string {
		return this.msInstanceId;
	}

	public ngAfterViewInit(): void {
		this.moDetectChangesQueue.start().pipe(takeUntil(this.destroyed$)).subscribe();

		if (this.mbDetectChangesPending)
			this.detectChanges();
	}

	/** Affecte les files d'attentes au composant.
	 * @param paQueuers
	 */
	public setQueuers(paQueuers: Queuer<any, any>[]): void {
		if (ArrayHelper.hasElements(this.maQueuers))
			console.warn(`${ComponentBase.C_COMPONENT_BASE_LOG_ID}queues already initialized.`);
		else {
			if (ArrayHelper.hasElements(paQueuers)) {
				this.maQueuers = paQueuers;
				this.initQueues();

				this.routingAwaiter.navigationStart$
					.pipe( // Quand une navigation démarre, on termine les files.
						tap(() => ArrayHelper.getFirstElement(this.maQueuers).end()), // On clôture la première file.
						takeUntil(this.destroyed$)
					)
					.subscribe();
			}
		}
	}

	private initQueues(): void {
		merge(...this.maQueuers.map((poQueuer: Queuer<any>, pnIndex: number, paArray: Queuer<any>[]) => {
			return poQueuer.start().pipe(
				tap(
					_ => { }, _ => { }, //! Problème : le `finalize` se déclenche en remontant du subscribe vers la source donc s'exécute à l'envers de ce qu'on veut !
					() => {
						const loNextQueuer: Queuer<any> = paArray[pnIndex + 1]; // Quand une file est terminée, on clôture la suivante.
						if (loNextQueuer)
							loNextQueuer.end();
					}
				));
		}))
			.pipe(
				takeUntil(this.destroyed$),
				tap(
					_ => { }, _ => { }, //! Problème : le `finalize` se déclenche en remontant du subscribe vers la source donc s'exécute à l'envers de ce qu'on veut !
					() => {
						this.routingAwaiter.continueRouting(); // Quand les x files sont terminées, on peut continuer la navigation.
						if (!this.destroyed) // Si le composant n'est pas détruit, on doit se préparer à un retour sur la page.
							this.initQueues();
						else // Sinon, on s'assure de terminer toutes les files pour éviter des fuites mémoires en terminant la première, les autres se terminent en cascade.
							ArrayHelper.getFirstElement(this.maQueuers).end();
					}
				)
			)
			.subscribe();
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();

		if (this.mbHasChangeDetectorRef)
			this.ioChangeDetectorRef.detach();

		this.moDetectChangesQueue.end();
	}

	/** Rafraîchie la vue pour la mettre à jour. */
	public detectChanges(): void {
		this.moDetectChangesQueue.exec();
		this.mbDetectChangesPending = true;
	}

	private internalDetectChanges(): void {
		const loPerformance = new PerformanceManager();

		if (this.mbHasChangeDetectorRef && !(this.ioChangeDetectorRef as ViewRef).destroyed) { // Si la vue n'est pas détruite, on peut la mettre à jour.
			loPerformance.markStart();
			this.ioChangeDetectorRef.detectChanges();
			console.debug(`${ComponentBase.C_COMPONENT_BASE_LOG_ID}DetectChanges duration (ms) : ${loPerformance.markEnd().measure()} ; class:`, this.constructor.name);
		}
		else if (!this.mbHasChangeDetectorRef)
			console.warn(`${ComponentBase.C_COMPONENT_BASE_LOG_ID}Cannot detect changes without changeDetector injected.`);
	}

	/** Marque la vue comme `à rafraîchir` pour Angular. */
	protected markChanges(): void {
		if (this.ioChangeDetectorRef)
			this.ioChangeDetectorRef.markForCheck();
	}

	//#endregion

}