import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { IonicModule } from '@ionic/angular';
import { ContactsModule } from '@osapp/components/contacts/contacts.module';
import { GalleryModule } from '@osapp/components/gallery';
import { SearchModule } from '@osapp/components/search';
import { CalaoAvatarModule } from '@osapp/modules/avatar/calao-avatar.module';
import { FilterModule } from '@osapp/modules/filter';
import { ModalModule } from '@osapp/modules/modal';
import { SelectorModule } from '@osapp/modules/selector/selector.module';
import { VirtualScrollModule } from '@osapp/modules/virtual-scroll/virtual-scroll.module';
import { PipeModule } from '@osapp/pipes/pipes.module';
import { IdlPipeModule } from '../../pipes/idlPipes.module';
import { ActesModule } from '../actes/actes.module';
import { OlaqinModule } from '../olaqin/olaqin.module';
import { OrdonnancesModule } from '../ordonnances/ordonnances.module';
import { PatientsModule } from '../patients/patients.module';
import { TourneesModule } from '../tournees/tournees.module';
import { ClosedInvoicesListComponent } from './components/closed-invoices-list/closed-invoices-list.component';
import { FacturationContextModalComponent } from './components/facturation-context-modal/facturation-context-modal.component';
import { FacturationModalComponent } from './components/facturation-modal/facturation-modal.component';
import { FspListModalComponent } from './components/fsp-list-modal/fsp-list-modal.component';
import { InterventionStatementsListComponent } from './components/intervention-statements-list/intervention-statements-list.component';
import { InvoiceItemComponent } from './components/invoice-item/invoice-item.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { InvoicingComponent } from './components/invoicing/invoicing.component';
import { PrestationsItemComponent } from './components/prestations-item/prestations-item.component';
import { SentPrestationsListComponent } from './components/sent-prestations-list/sent-prestations-list.component';
import { TraitementPrestationsModalComponent } from './components/traitement-prestations-modal/traitement-prestations-modal.component';
import { CustomPaginator } from './customPaginator';
import { FacturationService } from './facturation.service';
import { TraitementItemComponent } from './components/traitement-item/traitement-item.component';

@NgModule({
	declarations: [
		SentPrestationsListComponent,
		InterventionStatementsListComponent,
		ClosedInvoicesListComponent,
		InvoiceComponent,
		InvoicingComponent,
		TraitementPrestationsModalComponent,
		FacturationModalComponent,
		FacturationContextModalComponent,
		InvoiceItemComponent,
		PrestationsItemComponent,
		FspListModalComponent,
		TraitementItemComponent
	],
	exports: [
		SentPrestationsListComponent,
		InterventionStatementsListComponent,
		ClosedInvoicesListComponent,
		InvoiceComponent,
		InvoicingComponent,
		TraitementPrestationsModalComponent,
		FacturationModalComponent,
		FacturationContextModalComponent,
		InvoiceItemComponent,
		PrestationsItemComponent,
		FspListModalComponent,
		TraitementItemComponent
	],
	imports: [
		CommonModule,
		SuperTabsModule,
		IonicModule,
		ActesModule,
		PipeModule,
		SearchModule,
		FilterModule,
		IdlPipeModule,
		MatExpansionModule,
		SelectorModule,
		TourneesModule,
		MatCheckboxModule,
		MatPaginatorModule,
		FormsModule,
		CalaoAvatarModule,
		MatRadioModule,
		MatFormFieldModule,
		MatInputModule,
		MatTooltipModule,
		ScrollingModule,
		VirtualScrollModule,
		OrdonnancesModule,
		ContactsModule,
		OlaqinModule,
		ModalModule,
		MatSelectModule,
		PatientsModule,
		GalleryModule
	],
	providers: [
		FacturationService,
		{ provide: MatPaginatorIntl, useClass: CustomPaginator }
	]
})
export class FacturationModule { }