import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[diInputNoSpaces]'
})
export class NoSpacesDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInput(event: any): void {
    const inputElement = this.el.nativeElement;
    const currentValue: string = inputElement.value;
    const newValue: string = currentValue.replace(/\s/g, ''); // Regex qui supprime les espaces

    // Mets à jour la valeur uniquement si elle a changé
    if (currentValue !== newValue) {
      inputElement.value = newValue;
      event.preventDefault();
      event.stopPropagation();

      // Envoi un événement manuel pour mettre à jour le model
      const inputEvent = new Event('input', { bubbles: true });
      inputElement.dispatchEvent(inputEvent);
    }
  }
}
