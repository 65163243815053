import { Component, Input, OnInit } from "@angular/core";
import { EPrefix, IContact } from "@osapp/model";
import { DestroyableComponentBase } from "@osapp/modules/utils/components/destroyable-component-base";
import { ContactsService } from "@osapp/services";
import { map, takeUntil } from "rxjs/operators";

@Component({
	selector: "di-affectation-soins",
	templateUrl: "./affectation-soins.component.html",
	styleUrls: ["./affectation-soins.component.scss"],
})
export class AffectationSoinsComponent extends DestroyableComponentBase implements OnInit {
	@Input() confirm: (infimierId :string) => void;
	@Input() cancel: () => void;
	public selectedInfirmierId: string;
	public infirmiers: IContact[] = [];

	constructor(private svcContacts : ContactsService) {super()}

	ngOnInit(): void {
		this.svcContacts.getSiteContactsAnakin([], EPrefix.contact, true, true).pipe(
			takeUntil(this.destroyed$),
      map((contacts: IContact[]) => {
        return contacts.map((contact: IContact) => {
					if(!contact.isDoctor && contact.finess)
						contact.isDoctor = true;         	
				return contact;
        });
      }),
    ).subscribe((contacts: IContact[]) => {
      this.infirmiers = contacts.filter(contact => contact.userId);
    });
	}

	selectInfirmier(id: string) {
		this.selectedInfirmierId = id;
	}

	getAvatarColor(infirmier : IContact) {
		return infirmier.avatarCouleur ?? 'CouleurPrimaire';
  }

	onConfirm() {
		this.confirm(this.selectedInfirmierId);
	}

	onCancel() {
		this.cancel();
	}
}
