import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormsService } from '@osapp/services/forms.service';
import { SlideboxService } from '@osapp/services/slidebox.service';
import { from } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { IdlActionButtonFieldBase } from '../../../model/action-buttons/IdlActionButtonFieldBase';
import { IdlActionButtonService } from '../../../services/idl-action-button.service';

@Component({
	templateUrl: './idl-action-button-field-conversation.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdlActionButtonFieldConversationComponent extends IdlActionButtonFieldBase {

	//#region FIELDS

	/** Identifiant de la slide des conversations. */
	private static readonly C_CONVERSATION_VIEWPATIENT_SLIDEBOX_ID = "viewPatientSlideConversations";

	//#endregion

	//#region METHODS

	constructor(
		psvcSlidebox: SlideboxService,
		psvcActionButton: IdlActionButtonService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcSlidebox, psvcActionButton, psvcForms, poChangeDetectorRef);
	}

	/** @override Ouvre un composant. */
	public action(): void {
		const lsSlideId: string = IdlActionButtonFieldConversationComponent.C_CONVERSATION_VIEWPATIENT_SLIDEBOX_ID;

		from(this.isvcSlidebox.slideTo(lsSlideId, this.isvcSlidebox.getLastSlideContainer().componentId))
			.pipe(
				catchError(poError => this.onSlideToError(poError, lsSlideId)),
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();
	}

	//#endregion
}