import "@angular/compiler";
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { AppModule } from './app/app.module';
import { AnakinModule } from './anakin/anakin.module';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
	console.debug = () => { };
}
const versionCourante = localStorage.getItem("versionAppErgo") || "1";
console.log("Version courante de l'ergo : ", versionCourante);

if (versionCourante === "2") {
	const ionApp = document.querySelector('ion-app');
	if (ionApp) {
		ionApp.style.display = 'none';
	}
} else {
	const ionAnakin = document.querySelector('ion-anakin') as HTMLElement;
	if (ionAnakin) {
		ionAnakin.style.display = 'none';
	}
}

platformBrowserDynamic().bootstrapModule(versionCourante === "2" ? AnakinModule : AppModule);
defineCustomElements(window);