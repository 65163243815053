import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { FavoritesService } from '@osapp/modules/preferences/favorites/services/favorites.service';
import { IPreferences } from '@osapp/modules/preferences/model/IPreferences';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { Store } from '@osapp/services';
import { C_PREFIX_ACT } from 'apps/idl/src/anakin/anakin.constants';
import { Acte } from 'apps/idl/src/model/Acte';
import { IActe } from 'apps/idl/src/model/IActe';
import { IChapter } from 'apps/idl/src/model/IChapter';
import { IChapterContent } from 'apps/idl/src/model/IChapterContent';
import { ActesService } from 'apps/idl/src/modules/actes/actes.service';
import { ENgapFilters } from 'apps/idl/src/modules/actes/model/ENgapFilters';
import { Subject, defer, of } from 'rxjs';
import { mergeMap, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { EPathologie } from '../../../../../model/EPathologies';
import { ITraitement } from '../../../../../model/ITraitement';
import { Traitement } from '../../../../../model/Traitement';
import { IPatient } from '../../../../../modules/patients/model/IPatient';
import { PatientsService } from '../../../../../modules/patients/services/patients.service';


enum ESearchableActeKey {
  keyLetters = "keyLetters",
  priceCoefficient = "priceCoefficient",
  label = "label"
}

@Component({
  selector: 'di-recherche-actes',
  templateUrl: './recherche-actes.component.html',
  styleUrls: ['./recherche-actes.component.scss'],
})

export class RechercheActesComponent extends DestroyableComponentBase implements OnInit, OnDestroy {

  public actes: Acte[];

  @Input() public displayCount = false;
  @Input() public mobileViewForce? = false;
  @Output() public onClickLigne = new EventEmitter<string>();
	@Input() ordonnance : ITraitement;

  public readonly C_UNDEFINED_ACT_ID = ActesService.C_UNDEFINED_ACT_ID;
  public searchedValue: string = "";

  public actesCount: number;
  public actesCountMessage: string = "";

  public noResultMessage: string;

  private allActes: IActe[];
  public allChapters: IChapter[];
  public filteredChapter: [key: IChapter, value: IChapterContent][] = [];
  public sortKey: ESearchableActeKey = ESearchableActeKey.label;
  private filters: string[] = [
		EPathologie.cancer,
		EPathologie.diabetic,
		EPathologie.mucoviscidose,
		EPathologie.palliatif
	];

  constructor(
    public router: Router,
    private svcActes: ActesService,
    private svcFavorites: FavoritesService,
		private svcPatient : PatientsService,
		private svcStore : Store
  ) {
    super();
  }

  public acts: Acte[] = [];
  public actesByChapter = new Map<IChapter, IChapterContent>();
  public filteredActs: Acte[] = [];
  public selectedChapter: IChapter | null = null; // Track the currently selected chapter
  public selectedChapterContent: IChapterContent | null = null; // Store the content of the selected chapter
  public open: boolean = false;
  public favorites: IPreferences;
  private favoritesClickSubject = new Subject<Acte>();
	public patient : IPatient;
	public isDependant : boolean = false;

  ngOnInit() {
		const patientId = Traitement.extractPatientId(this.ordonnance._id)
		this.svcPatient.getPatient(patientId).pipe(
			tap((patient : IPatient) => {
				this.patient = patient;
				this.isDependant = patient.pathologies?.includes(EPathologie.dependant);
				if(this.isDependant) this.filters.push(EPathologie.dependant)
			})
		).subscribe();
    this.loadListActeChapter();
  }

	private loadListActeChapter() {
		defer(() => {
			if (ArrayHelper.hasElements(this.acts)) return of(this.acts);
			return this.svcActes.getActes(undefined, true);
		})
			.pipe(
				tap((paActes: Acte[]) => this.onFilteredEntriesChanged(this.acts = ArrayHelper.dynamicSort(paActes, this.sortKey))),
				mergeMap(() => this.svcActes.getChapters()),
				tap((paChapters: IChapter[]) => {
					this.allActes = this.acts;
					this.allChapters = paChapters;
					this.actesByChapter = this.svcActes.groupActsByChapter(this.allChapters, this.filteredActs);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.svcFavorites.get(C_PREFIX_ACT, true)
			.pipe(
				tap((poFavorites: IPreferences) => {
					this.favorites = poFavorites;
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.favoritesClickSubject
			.pipe(
				throttleTime(500),
				mergeMap((poAct: Acte) => this.svcFavorites.toggle(poAct._id)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

  ngOnDestroy(): void {
    this.favoritesClickSubject.complete();

  }

	toggleIsAMX(isDependant : boolean)
	{
		this.isDependant = isDependant;
		if(isDependant){
			this.filters.push(EPathologie.dependant);
			if(!this.patient.pathologies)
				this.patient.pathologies = [];
			this.patient.pathologies.push(EPathologie.dependant);
		}
		else
		{
			this.filters = this.filters.filter(pathologie => pathologie !== EPathologie.dependant);
			this.patient.pathologies = this.patient.pathologies.filter(pathologie => pathologie !== EPathologie.dependant);
		}
		this.loadListActeChapter();
		this.applyfilterSearch();
	}

  onFilteredEntriesChanged(paActes: Acte[]): void {
    this.filteredActs = this.svcActes.removeActsBySelectedPathologies(paActes, this.filters as any as ENgapFilters[]);
    this.undefinedActFirstRow(this.filteredActs);
    if (ArrayHelper.hasElements(this.allChapters))
      this.actesByChapter = this.svcActes.groupActsByChapter(this.allChapters, this.filteredActs);
  }

  onClickChapter(chapter: any): void {
    this.selectedChapter = chapter.key;
    this.selectedChapterContent = chapter.value;
  }

  onClickFav(): void {
    this.selectedChapter = { _id: 'Favoris', description: 'Favoris' };
    let favoriteActes = this.allActes?.filter((act) => this.favorites?.ids?.includes(act._id))
    this.selectedChapterContent = { actes: favoriteActes };
  }

  goBackToChapters(): void {
    this.searchedValue = '';
    this.selectedChapter = null;
    this.selectedChapterContent = null;
  }

  onClickActes() {
    this.onClickLigne.emit();
  }

  filterChapter(newValue: string): void {
    this.searchedValue = newValue;

    this.applyfilterSearch();
  }

	private applyfilterSearch() {
		if (this.searchedValue.length > 2) {
			this.selectedChapter = { _id: 'Resultats', description: 'Résultats' };
			let facoriteActes = this.filteredActs?.filter((act) => act.description.toLowerCase().includes(this.searchedValue.toLowerCase()));

			this.selectedChapterContent = { actes: facoriteActes };
		} else {
			this.selectedChapter = null;
			this.selectedChapterContent = null;
		}
		this.getActesCount();
	}

  getActesCount(): void {
    this.actesCount = this.selectedChapterContent?.actes.length;
    this.actesCountMessage = `${this.actesCount > 0 ? this.actesCount : "Aucun"} acte${this.actesCount > 1 ? "s" : ""}  
    ${this.searchedValue.length > 0 ? (this.actesCount > 1 ? "trouvés" : "trouvé") : ""}`
    if (this.searchedValue.length > 0) {
      this.noResultMessage = "Aucun acte trouvé"
    } else {
      this.noResultMessage = "Aucun acte"
    }
  }

  onFavoritesClicked(poAct: Acte): void {
    this.favoritesClickSubject.next(poAct);
  }

  onActeSelected(acte: IActe) {
		this.svcPatient.savePatientAnakin(this.patient).subscribe();
    const acteComplet = new Acte(acte);
    this.svcActes.applyPriceToActe(acteComplet)
      .pipe(
        tap((acteWithPrice: Acte) => this.svcActes.emitSelectActe(acteWithPrice)),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

  private undefinedActFirstRow(paActs: Acte[]): void {
    let loUndefinedAct: Acte;

    paActs.forEach((poActe: Acte) => {
      if (poActe._id === this.C_UNDEFINED_ACT_ID) {
        loUndefinedAct = poActe;
        paActs.splice(paActs.indexOf(poActe), 1);
      }
    });

    if (loUndefinedAct)
      paActs.unshift(loUndefinedAct);
  }
}
