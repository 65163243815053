import { Component, Input } from '@angular/core';
import { ConversationTabComponent } from '@osapp/components/conversation/tab/conversation-tab.component';
import { IIdlConversationsListParams } from '../../model/IIdlConversationsListParams';

@Component({
	selector: 'idl-conversation-tab',
	templateUrl: './conversation-tab.component.html',
	styleUrls: ['./conversation-tab.component.scss'],
})
export class IdlConversationTabComponent extends ConversationTabComponent {

	//#region PROPERTIES

	/** Paramètres pour le composant. */
	@Input() public params: IIdlConversationsListParams;

	//#endregion

	//#region METHODS

	constructor() {
		super();
	}

	//#endregion

}
