<osapp-header-modal [title]="customTitle ? customTitle : 'Fichiers'"></osapp-header-modal>

<ion-content>
	<calao-gallery-selector [files]="files" [preselectedFilesGuids]="preselectedFilesGuids"
		(onFileSelectionChanged)="onFileSelectionChanged($event)"></calao-gallery-selector>
</ion-content>

<ion-fab vertical="bottom" horizontal="end" slot="fixed">
	<ion-fab-button color="success" (click)="close()" title="Fermer">
		<ion-icon name="checkmark"></ion-icon>
	</ion-fab-button>
</ion-fab>