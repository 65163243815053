<osapp-header-modal title="Annuler séance"></osapp-header-modal>

<ion-content>
	<mat-form-field appearance="outline" class="center-explains">
		<mat-label>Explications</mat-label>
		<textarea matInput matTextareaAutosize matAutosizeMinRows="1" matAutosizeMaxRows="6"
			[(ngModel)]="explanation"></textarea>
	</mat-form-field>

	<ion-item>
		<ion-label>Interrompre le traitement et annuler les prochaines séances?</ion-label>
		<ion-checkbox slot="end" color="danger" [(ngModel)]="hasToStopTreatment"></ion-checkbox>
	</ion-item>
</ion-content>

<ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
	<ion-fab-button color="danger" (click)="validate()" title="Supprimer">
		<ion-icon name="trash"></ion-icon>
	</ion-fab-button>
</ion-fab>