import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalComponentBase } from '@osapp/modules/modal/model/ModalComponentBase';
import { PlatformService } from '@osapp/services/platform.service';
import { Seance } from '../../../model/Seance';
import { IChooseSeancesToModifiyResponse } from '../model/ichoose-seances-to-modifiy-response';
import { EChoiceFilter } from './EChoiceFilter';
import { IChoice } from './IChoice';
import { IChooseSeancesToModifyModalParams } from './IChooseSeancesToModifyModalParams';

@Component({
	templateUrl: './choose-seances-to-modify-modal.component.html',
	styleUrls: ['./choose-seances-to-modify-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChooseSeancesToModifyModalComponent extends ModalComponentBase<IChooseSeancesToModifiyResponse> implements IChooseSeancesToModifyModalParams {

	//#region PROPERTIES

	/** @implements */
	public data: Seance | Date;
	/** @implements */
	public choices: IChoice[] = [];
	/** @implements */
	public title: string;

	//#endregion

	//#region METHODS

	constructor(poModalCtrl: ModalController, psvcPlatform: PlatformService) {
		super(poModalCtrl, psvcPlatform);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		if (this.data instanceof Seance)
			this.choices.unshift({ value: [this.data], label: "Cette séance uniquement" });
	}

	public onClose(paSeances: Seance[], paFilters: EChoiceFilter[]): void {
		this.close({ seances: paSeances, filters: paFilters ?? [] });
	}

	public getNbSeances(paSeances: Seance[]): number {
		return paSeances.filter((poSeance: Seance) => !poSeance.isProtected).length;
	}

	//#endregion

}