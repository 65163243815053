<div class="calendar">
	<div class="row">
		<div class="col-auto" *ngFor="let day of daysOfWeek">
			<button class="dayWeek-calendar-btn" (click)="getAllOccurrencesOfDayInMonth(day.number)">
				{{ day.label }}
			</button>
		</div>
	</div>
	<div class="row" *ngFor="let row of monthDays; let i = index">
		<div class="col-auto" *ngFor="let day of row">
			<di-day [isWorked]="getDayClassesWorkedByMe(day)" [onSelectDayItem]="dayItemClick"
				[isWorkedNotByMe]="getDayClassesWorkedNotByMe(day)" [number]="day" [month]="month" [year]="year">
			</di-day>
		</div>
	</div>
</div>