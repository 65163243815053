import { IDatabaseConfig } from '../store/IDatabaseConfig';
import { IStoreDocument } from '../store/IStoreDocument';
import { ILoggerConfig } from './ILoggerConfig';

export interface IConfApp extends IStoreDocument {

	/** Type du document. */
	type: string;
	/** Tableau des bases de données à répliquer. */
	databases: Array<IDatabaseConfig>;
	/** Tableau des bases de données des espaces de travail. */
	workspaceDatabases: Array<IDatabaseConfig>;
	/** Configuration du logging */
	logger: ILoggerConfig;
}