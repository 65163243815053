import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Directory } from '@capacitor/filesystem';
import { NumberHelper, StoreHelper } from '@osapp/helpers';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { BooleanHelper } from '@osapp/helpers/boolean.helper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { EnumHelper } from '@osapp/helpers/enumHelper';
import { FileHelper } from '@osapp/helpers/fileHelper';
import { GuidHelper } from '@osapp/helpers/guidHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { MapHelper } from '@osapp/helpers/mapHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { UserHelper } from '@osapp/helpers/user.helper';
import { EPrefix } from '@osapp/model';
import { ESortOrder } from '@osapp/model/ESortOrder';
import { UserData } from '@osapp/model/application/UserData';
import { ConfigData } from '@osapp/model/config/ConfigData';
import { EContactsType } from '@osapp/model/contacts/EContactsType';
import { IContact } from '@osapp/model/contacts/IContact';
import { IContactsSelectorParams } from '@osapp/model/contacts/IContactsSelectorParams';
import { IGroup } from '@osapp/model/contacts/IGroup';
import { IGroupMember } from '@osapp/model/contacts/IGroupMember';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { IGalleryFile } from '@osapp/model/gallery/IGalleryFile';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { IDataSource } from '@osapp/model/store/IDataSource';
import { IStoreDataResponse } from '@osapp/model/store/IStoreDataResponse';
import { IStoreDocument } from '@osapp/model/store/IStoreDocument';
import { IUiResponse } from '@osapp/model/uiMessage/IUiResponse';
import { DrawingModalComponent } from '@osapp/modules/drawing/components/drawing-modal/drawing-modal.component';
import { IDrawingModalParams } from '@osapp/modules/drawing/models/IDrawingModalParams';
import { IDrawingResult } from '@osapp/modules/drawing/models/IDrawingResult';
import { OsappError } from '@osapp/modules/errors/model/OsappError';
import { FilesystemService } from '@osapp/modules/filesystem/services/filesystem.service';
import { EFilterActionReturnType } from '@osapp/modules/filter/model/EFilterActionReturnType';
import { EFilterType } from '@osapp/modules/filter/model/EFilterType';
import { IFilterbarOptions } from '@osapp/modules/filter/model/IFilterbarOptions';
import { Loader } from '@osapp/modules/loading/Loader';
import { LogAction } from '@osapp/modules/logger/decorators/log-action.decorator';
import { ILogSource } from '@osapp/modules/logger/models/ILogSource';
import { LogActionHandler } from '@osapp/modules/logger/models/log-action-handler';
import { LoggerService } from '@osapp/modules/logger/services/logger.service';
import { ModalService } from '@osapp/modules/modal/services/modal.service';
import { OsappApiHelper } from '@osapp/modules/osapp-api/helpers/osapp-api.helper';
import { C_SECTORS_ROLE_ID, IHasPermission, PermissionsService } from '@osapp/modules/permissions/services/permissions.service';
import { EPrestationStatus } from '@osapp/modules/prestation/models/eprestation-status.enum';
import { Prestation } from '@osapp/modules/prestation/models/prestation';
import { PrestationService } from '@osapp/modules/prestation/services/prestation.service';
import { ContactNamePipe } from '@osapp/pipes/contactName.pipe';
import { ContactsService } from '@osapp/services/contacts.service';
import { GalleryService } from '@osapp/services/gallery.service';
import { GroupsService } from '@osapp/services/groups.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '@osapp/services/interfaces/ShowMessageParamsToast';
import { LoadingService } from '@osapp/services/loading.service';
import { PlatformService } from '@osapp/services/platform.service';
import { Store } from '@osapp/services/store.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { WorkspaceService } from '@osapp/services/workspace.service';
import { plainToClass } from 'class-transformer';
import { Observable, Subject, from, of } from 'rxjs';
import { catchError, defaultIfEmpty, filter, finalize, map, mapTo, mergeMap, tap, toArray } from 'rxjs/operators';
import { C_PREFIX_ACT, C_PREFIX_INTER_STATE, C_PREFIX_INVOICE, C_PREFIX_PAYEMENT, C_PREFIX_SIGNATURE, C_PREFIX_TRAITEMENT } from '../../app/app.constants';
import { Acte } from '../../model/Acte';
import { EMajorationType } from '../../model/EMajorationType';
import { IIdelizyContact } from '../../model/IIdelizyContact';
import { Traitement } from '../../model/Traitement';
import { TraitementService } from '../../services/traitement.service';
import { ConcatActesPipe } from '../actes/concat-actes.pipe';
import { InterventionStatementService } from '../intervention-statement/intervention-statement.service';
import { EIdlLogActionId } from '../logger/models/EIdlLogActionId';
import { TerminalInfoModalOpenerService } from '../olaqin/components/terminal-info-modal/services/terminal-info-modal-opener.service';
import { ITerminalInfo } from '../olaqin/models/iterminal-info';
import { OrdonnancesService } from '../ordonnances/services/ordonnances.service';
import { IPatient } from '../patients/model/IPatient';
import { AMCP } from '../patients/model/amc-p';
import { AMOP } from '../patients/model/amo-p';
import { EUpdateMode } from '../patients/model/eupdate-mode.enum';
import { CouverturesService } from '../patients/services/couvertures.service';
import { PatientsService } from '../patients/services/patients.service';
import { FspListModalComponent } from './components/fsp-list-modal/fsp-list-modal.component';
import { ForwardActionError } from './errors/forward-action-error';
import { EFormuleSTS } from './models/eformule-sts.enum';
import { EIdlPrestationLineCategory } from './models/eidl-prestation-line-category.enum';
import { EInvoiceStatus } from './models/einvoice-status';
import { EModeSecurisation } from './models/emode-securisation.enum';
import { EPayementMode } from './models/epayement-mode.enum';
import { EPayementStatus } from './models/epayement-status.enum';
import { EPayementType } from './models/epayement-type.enum';
import { IdlPrestation } from './models/idl-prestation';
import { IdlPrestationLine } from './models/idl-prestation-line';
import { IFsvErrorSV } from './models/ifsv-error-sv';
import { IIdlPrestationIdBuilderParams } from './models/iidl-prestation-id-builder-params';
import { IInvoice } from './models/iinvoice';
import { InterSateWithPatient } from './models/intervention-statement-with-patient';
import { Invoice } from './models/invoice';
import { InvoiceLine } from './models/invoice-line';
import { IPayement } from './models/ipayement';
import { IPriceInvoiceResponse } from './models/iprice-invoice-response';
import { ITraitement } from '../../model/ITraitement';
import { SnackbarService } from '../../anakin/features/shared/services/snackbar.service';
import { StoredSeance } from '../../anakin/models/StoredSeance';
import { SeanceService } from '../../anakin/features/shared/services/seance.service';
import { EStatusSeance } from '../../model/EStatusSeance';

interface IPrepareSecurisationResponse {
	terminalId: string;
}

interface IPrepareSecurisationDegradeeResponse {
	signatureBase64?: string;
	careSheet?: boolean;
}

@Injectable()
export class FacturationService implements ILogSource, IHasPermission {

	//#region FIELDS

	private static readonly C_LOG_ID = "IDL.FACT.S::";

	//#endregion

	//#region PROPERTIES

	/** @implements */
	public readonly logSourceId = FacturationService.C_LOG_ID;
	/** @implements */
	public readonly logActionHandler = new LogActionHandler(this);

	public static readonly C_INTERVENANT_FILTER_ID = "intervenants";
	public static readonly C_DATE_RANGE_FILTER_ID = "dates-range";
	public static readonly C_OTHER_FILTER_ID = "other";
	public static readonly C_UNDEFINED_ACT = "undefinedAct";
	public static readonly C_OBSERVATIONS = "observations";
	public static readonly C_HORS_NOMENCLATURE = "hors-nomenclature";

	private static readonly C_SELECT_INTERVENANT_TITLE = "Modifier l'intervenant ...";

	public get isModeAgrement(): boolean {
		return false;
	}

	private majPatientPanelSubject = new Subject<string>();
	public majPatientPanel$ = this.majPatientPanelSubject.asObservable();

	public triggerMajPatientPanel(idTraitement: string): void {
		this.majPatientPanelSubject.next(idTraitement);
	}
	//#endregion

	//#region METHODS

	constructor(
		public readonly isvcPermissions: PermissionsService,
		private readonly isvcContacts: ContactsService,
		private readonly isvcPatients: PatientsService,
		private readonly isvcGroups: GroupsService,
		private readonly isvcCouvertures: CouverturesService,
		private readonly isvcModal: ModalService,
		private readonly ioConcatActesPipe: ConcatActesPipe,
		private readonly ioContactNamePipe: ContactNamePipe,
		private readonly isvcStore: Store,
		private readonly isvcWorkspace: WorkspaceService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcOrdonnance: OrdonnancesService,
		private readonly isvcTerminalInfoModalOpener: TerminalInfoModalOpenerService,
		private readonly isvcInterventionStatement: InterventionStatementService,
		private readonly svcTraitement: TraitementService,
		private readonly ioHttp: HttpClient,
		private readonly isvcPrestations: PrestationService,
		/** @implements */
		public readonly isvcLogger: LoggerService,
		private readonly isvcLoading: LoadingService,
		private readonly isvcGallery: GalleryService,
		private readonly isvcPlatform: PlatformService,
		private readonly isvcFilesystem: FilesystemService,
		private readonly svcSnackbar: SnackbarService,
		private readonly svcSeance: SeanceService,
	) { }

	private getHttpOptions(): { headers: HttpHeaders, observe: "response" } {
		return {
			headers: new HttpHeaders({
				appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
				token: ConfigData.authentication.token,
				"api-key": ConfigData.environment.API_KEY,
			}),
			observe: "response"
		};
	}

	private getBaseUrl(): string {
		return `${ConfigData.environment.cloud_url}/api/apps/${ConfigData.appInfo.appId}/workspaces/${ArrayHelper.getFirstElement(this.isvcWorkspace.getUserWorkspaceIds())}`;
	}

	public selectIntervenant(poPrestation: Prestation): Observable<IGroupMember> {
		return this.isvcGroups.getGroups()
			.pipe(
				mergeMap((paGroups: IGroup[]) => {
					const laSectors: IGroup[] = paGroups.filter((poGroup: IGroup) => this.isvcGroups.hasRole(poGroup, C_SECTORS_ROLE_ID));
					const laIntervenants: IGroup[] = paGroups

					return this.isvcGroups.getGroupContacts(laIntervenants)
						.pipe(
							map((poContactsByGroupsIds: Map<string, IContact[]>) => ArrayHelper.unique(ArrayHelper.flat(MapHelper.valuesToArray(poContactsByGroupsIds)))),
							mergeMap((paContacts: IContact[]) => {
								const loContactsSelectorParams: IContactsSelectorParams = {
									hasSearchbox: true,
									selectionLimit: 1,
									selectionMinimum: 1,
									type: EContactsType.contacts,
									userContactVisible: true,
									contactsData: paContacts,
									groupsData: laIntervenants.concat(laSectors),
									groupFilterParams: {
										options: laSectors.map((poGroup: IGroup) => ({ label: poGroup.name, value: poGroup }))
									}
								};

								return this.isvcContacts.openContactsSelectorAsModalWithWorkspacePreSelection<IGroupMember>(poPrestation, loContactsSelectorParams, FacturationService.C_SELECT_INTERVENANT_TITLE);
							}),
							map((paGroupMembers: IGroupMember[]) => ArrayHelper.getFirstElement(paGroupMembers))
						);
				})
			);
	}

	public createInvoicesFromPrestations(psPatientId: string, paPrestations: IdlPrestation[]): Observable<Invoice[]> {
		const laInvoices: Invoice[] = [];
		const loInvoicesEntries = new Map<string, { prestationLine: IdlPrestationLine, prestation: IdlPrestation }[]>();

		paPrestations.forEach((poPrestation: IdlPrestation) => {
			poPrestation.lines.forEach((poPrestationLine: IdlPrestationLine) => {
				const lsKey = `${poPrestation.vendorId}-${poPrestationLine.ordonnanceId}`;

				if (loInvoicesEntries.has(lsKey))
					loInvoicesEntries.set(lsKey, [...loInvoicesEntries.get(lsKey), { prestationLine: poPrestationLine, prestation: poPrestation }]);
				else
					loInvoicesEntries.set(lsKey, [{ prestationLine: poPrestationLine, prestation: poPrestation }]);
			});
		});

		loInvoicesEntries.forEach((paEntries: { prestationLine: IdlPrestationLine, prestation: IdlPrestation }[], psKey: string) => {
			const loPrestation: IdlPrestation = ArrayHelper.getFirstElement(paEntries)?.prestation;
			const lsTraitementId: string = Traitement.createId(loPrestation.customerId, loPrestation.siteId, IdHelper.getLastGuidFromId(IdHelper.extractParentId(loPrestation._id)));
			const laEntries: { prestationLine: IdlPrestationLine, prestation: IdlPrestation }[] = paEntries
				.filter((poEntry: { prestationLine: IdlPrestationLine, prestation: IdlPrestation }) => poEntry.prestationLine.total > 0);
			const sousEnsemblePrestation: { prestationLine: IdlPrestationLine, prestation: IdlPrestation }[][] = this.splitArraySousArray(laEntries, 100);


			sousEnsemblePrestation.forEach((sousEnsemble) => {
				const loInvoice: Invoice = plainToClass(Invoice, {
					_id: `${C_PREFIX_INVOICE}${lsTraitementId}_${GuidHelper.newGuid()}`,
					intervenantId: psKey.split("-")[0],
					authorId: UserHelper.getUserContactId(),
					ordonnanceId: psKey.split("-")[1] ?? undefined,

					actes: sousEnsemble.map((poEntry: { prestationLine: IdlPrestationLine, prestation: IdlPrestation }, index) => ({
						acteId: poEntry.prestationLine.ref,
						lettreCle: poEntry.prestationLine.lettreCle,
						coefficient: poEntry.prestationLine.coefficient,
						date: poEntry.prestation.dateFromId,
						place: poEntry.prestation.place,
						prestationId: poEntry.prestation._id,
						description: poEntry.prestationLine.description,
						price: poEntry.prestationLine.price,
						extraCharge: poEntry.prestationLine.extraCharge,
						quantity: poEntry.prestationLine.quantity,
						abattement: poEntry.prestationLine.discountRate,
						isAldExonerante: poEntry.prestationLine.isAldExonerante,
						numero: index
					} as InvoiceLine))
				});

				if (this.isModeAgrement) {
					loInvoice.dre = false;
					loInvoice.amoTp = true;
					loInvoice.amcTp = true;
					loInvoice.gestionUnique = false;
					loInvoice.securisationMode = null;
				}
				laInvoices.push(loInvoice);
			});
		});

		return this.isvcCouvertures.getPatientSortedCouvertures(psPatientId).pipe(
			mergeMap(paCouvertures => {
				const loAMOP: AMOP = ArrayHelper.getFirstElement(paCouvertures.AMOPs);
				const loAMCP: AMCP = ArrayHelper.getFirstElement(paCouvertures.AMCPs);

				return this.isvcOrdonnance.getOrdonnance(ArrayHelper.getFirstElement(laInvoices).ordonnanceId).pipe(
					map(ordo => {
						laInvoices.forEach((poInvoice: Invoice) => {
							poInvoice.dre = (loAMCP !== undefined && loAMOP !== undefined && !ordo.isAld && !ordo.adcDate) ?? false;
							poInvoice.amoTp = loAMOP?.tp ? BooleanHelper.convertToBoolean(loAMOP?.tp) : false;
							poInvoice.amcTp = loAMCP?.tp ? BooleanHelper.convertToBoolean(loAMCP?.tp) : false;
						});

						return laInvoices;
					})
				);
			})
		);
	}

	public createInvoiceFromPrestations(patient: IPatient, prestations: IdlPrestation[], traitement: ITraitement): Invoice {
		let invoice: Invoice = new Invoice();
		const loInvoicesEntries = new Map<string, { prestationLine: IdlPrestationLine, prestation: IdlPrestation }[]>();

		prestations.forEach((prestation: IdlPrestation) => {
			prestation.lines.forEach((poPrestationLine: IdlPrestationLine) => {
				const lsKey = `${prestation.vendorId}-${poPrestationLine.ordonnanceId}`;

				if (loInvoicesEntries.has(lsKey))
					loInvoicesEntries.set(lsKey, [...loInvoicesEntries.get(lsKey), { prestationLine: poPrestationLine, prestation: prestation }]);
				else
					loInvoicesEntries.set(lsKey, [{ prestationLine: poPrestationLine, prestation: prestation }]);
			});
		});

		loInvoicesEntries.forEach((paEntries: { prestationLine: IdlPrestationLine, prestation: IdlPrestation }[], psKey: string) => {
			const laEntries: { prestationLine: IdlPrestationLine, prestation: IdlPrestation }[] = paEntries
				.filter((poEntry: { prestationLine: IdlPrestationLine, prestation: IdlPrestation }) => poEntry.prestationLine.total > 0);
			const sousEnsemblePrestation: { prestationLine: IdlPrestationLine, prestation: IdlPrestation }[][] = this.splitArraySousArray(laEntries, 100);


			sousEnsemblePrestation.forEach((sousEnsemble) => {
				invoice = plainToClass(Invoice, {
					_id: `${C_PREFIX_INVOICE}${traitement._id}_${GuidHelper.newGuid()}`,
					intervenantId: psKey.split("-")[0],
					authorId: UserHelper.getUserContactId(),
					ordonnanceId: undefined,

					actes: sousEnsemble.map((poEntry: { prestationLine: IdlPrestationLine, prestation: IdlPrestation }, index) => ({
						acteId: poEntry.prestationLine.ref,
						lettreCle: poEntry.prestationLine.lettreCle,
						coefficient: poEntry.prestationLine.coefficient,
						date: poEntry.prestation.dateFromId,
						place: poEntry.prestation.place,
						prestationId: poEntry.prestation._id,
						description: poEntry.prestationLine.description,
						price: poEntry.prestationLine.price,
						extraCharge: poEntry.prestationLine.extraCharge,
						quantity: poEntry.prestationLine.quantity,
						abattement: poEntry.prestationLine.discountRate,
						isAldExonerante: poEntry.prestationLine.isAldExonerante,
						numero: index
					} as InvoiceLine))
				});

				if (this.isModeAgrement) {
					invoice.dre = false;
					invoice.amoTp = true;
					invoice.amcTp = true;
					invoice.gestionUnique = false;
					invoice.securisationMode = null;
				}
			});
		});

		const amoP: AMOP = patient.AMO?.find(x => x.isActiveAnakin);
		const amcP: AMCP = patient.AMC?.find(x => x.isActive);

		invoice.dre = (amcP && amoP && !traitement.isAld && !traitement.adcDate) ?? false;
		invoice.amoTp = amoP?.tp ? BooleanHelper.convertToBoolean(amoP?.tp) : false;
		invoice.amcTp = amcP?.tp ? BooleanHelper.convertToBoolean(amcP?.tp) : false;

		return invoice;
	}

	private splitArraySousArray<T extends { prestationLine: IdlPrestationLine, prestation: IdlPrestation }>(array: T[], maxItemsPerSubset: number): T[][] {
		const subsets: T[][] = [];
		let currentSubset: T[] = [];
		let currentPrestations: Set<string> = new Set<string>();
		const prestationToSubsetMap: Map<string, T[]> = new Map<string, T[]>();

		for (const item of array) {
			const prestationId = item.prestation._id;

			// Si la prestation est déjà dans un sous-ensemble précédent
			if (prestationToSubsetMap.has(prestationId)) {
				// Ajouter la ligne au sous-ensemble correspondant
				prestationToSubsetMap.get(prestationId)!.push(item);
			} else {
				// Vérifier si le nombre maximum de prestations distinctes est atteint
				if (currentPrestations.size === maxItemsPerSubset) {
					// Ajouter le sous-ensemble actuel au tableau de sous-ensembles
					subsets.push(currentSubset);
					// Réinitialiser le sous-ensemble actuel et l'ensemble des prestations
					currentSubset = [];
					currentPrestations = new Set<string>();
				}

				// Ajouter l'élément au sous-ensemble actuel
				currentSubset.push(item);
				// Ajouter la prestation actuelle à l'ensemble des prestations du sous-ensemble actuel
				currentPrestations.add(prestationId);
				// Mettre à jour la carte avec le sous-ensemble actuel
				prestationToSubsetMap.set(prestationId, currentSubset);
			}
		}

		// Ajouter le dernier sous-ensemble s'il n'est pas vide
		if (currentSubset.length > 0) {
			subsets.push(currentSubset);
		}

		return subsets;
	}

	/**
	 * TODO : A delete
	 * @deprecated N'a plus lieu d'être pour le moment, mais à conserver en cas de pb
	 */
	public createPrestationsFromInvoices(psPatientId: string, paInvoices: Invoice[]): IdlPrestation[] {
		const laPrestations: IdlPrestation[] = [];

		paInvoices.forEach((poInvoice: Invoice) => {
			const loInvoiceLinesByDates = new Map<string, InvoiceLine[]>();

			poInvoice?.actes.forEach((poLine: InvoiceLine) => {
				const lsDate: string = poLine.date as any as string;
				if (loInvoiceLinesByDates.has(lsDate))
					loInvoiceLinesByDates.set(lsDate, [...loInvoiceLinesByDates.get(lsDate), poLine]);
				else
					loInvoiceLinesByDates.set(lsDate, [poLine]);
			});

			loInvoiceLinesByDates.forEach((paInvoiceLines: InvoiceLine[], psDate: string) => {
				const poRefInvoiceLine: InvoiceLine = ArrayHelper.getFirstElement(paInvoiceLines);
				const ldSeanceDate = new Date(psDate);

				const loPrestation = new IdlPrestation();
				// On prépare les cacheData à partir de celles de l'invoice.
				StoreHelper.updateDocumentCacheData(loPrestation, StoreHelper.getDocumentCacheData(poInvoice));
				loPrestation._id = this.isvcPrestations.buildId({ traitementId: poInvoice.traitementId, customerIdOrPrefix: psPatientId, prestationDate: ldSeanceDate } as IIdlPrestationIdBuilderParams);
				loPrestation.originalStatus = loPrestation.status = EPrestationStatus.created;
				loPrestation.vendorId = poInvoice.intervenantId;
				loPrestation.authorId = UserHelper.getUserContactId();
				loPrestation.createDate = loPrestation.lastUpdateDate = new Date();
				loPrestation.observation = "";
				loPrestation.place = poRefInvoiceLine.place;
				loPrestation.lines = [];

				paInvoiceLines.forEach((poInvoiceLine: InvoiceLine) => {
					const poPrestationLine = new IdlPrestationLine();

					poPrestationLine.ref = poInvoiceLine.acteId;
					poPrestationLine.price = poInvoiceLine.price;
					poPrestationLine.extraCharge = poInvoiceLine.extraCharge;
					poPrestationLine.description = poInvoiceLine.description;
					poPrestationLine.group = `Séance du ${DateHelper.transform(ldSeanceDate, ETimetablePattern.dd_MM_yyyy_HH_mm_slash)}`;
					poPrestationLine.coefficient = poInvoiceLine.coefficient;
					poPrestationLine.lettreCle = poInvoiceLine.lettreCle;
					poPrestationLine.quantity = poInvoiceLine.quantity ?? undefined;
					poPrestationLine.ordonnanceId = poInvoice.ordonnanceId;
					poPrestationLine.discountRate = poInvoiceLine.abattement;

					if (poInvoiceLine.acteId.startsWith(C_PREFIX_ACT))
						poPrestationLine.category = EIdlPrestationLineCategory.actes;
					else if (EnumHelper.getValues(EMajorationType).includes(poInvoiceLine.acteId))
						poPrestationLine.category = EIdlPrestationLineCategory.majorations;
					else
						poPrestationLine.category = EIdlPrestationLineCategory.indemnites;

					loPrestation.lines.push(poPrestationLine);
				});

				loPrestation.originalLines = loPrestation.lines;
				laPrestations.push(loPrestation);
			});
		});

		return laPrestations;
	}

	/** Sauvegarde plusieurs factures.
	 * @param paInvoices
	 */
	@LogAction<Parameters<FacturationService["saveInvoices"]>, ReturnType<FacturationService["saveInvoices"]>>({
		actionId: EIdlLogActionId.invoiceSave,
		successMessage: "Sauvegarde des factures.",
		errorMessage: "Echec de la sauvegarde des factures.",
		dataBuilder: (_, paInvoices: Invoice[]) => ({ userId: UserData.current?._id, invoicesIds: paInvoices.map((poInvoice: Invoice) => poInvoice._id) })
	})
	public saveInvoices(paInvoices: Invoice[]): Observable<Invoice[]> {
		return this.isvcStore.putMultipleDocuments(paInvoices, ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace)))
			.pipe(mapTo(paInvoices));
	}

	/** Sauvegarde une facture.
	 * @param poInvoice
	 */
	@LogAction<Parameters<FacturationService["saveInvoice"]>, ReturnType<FacturationService["saveInvoice"]>>({
		actionId: EIdlLogActionId.invoiceSave,
		successMessage: "Sauvegarde de la facture.",
		errorMessage: "Echec de la sauvegarde de la facture.",
		dataBuilder: (_, poInvoice: Invoice) => ({ userId: UserData.current?._id, invoicesId: poInvoice._id })
	})
	public saveInvoice(poInvoice: Invoice): Observable<Invoice> {
		const lsDatabaseId: string = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace));

		return this.saveDocuments(poInvoice)
			.pipe(
				mergeMap(() => {
					poInvoice.documents?.forEach((poGalleryFile: IGalleryFile) => {
						delete poGalleryFile.isAvailable;
						delete poGalleryFile.isNew;
						delete poGalleryFile.isLoading;
						delete poGalleryFile.file;
					});
					return this.isvcStore.put(poInvoice, lsDatabaseId);
				}),
				tap((poResponse: IStoreDataResponse) => poInvoice._rev = poResponse.rev),
				mapTo(poInvoice)
			);
	}

	private saveDocuments(poInvoice: Invoice): Observable<boolean> {
		if (ArrayHelper.hasElements(poInvoice.documents)) {
			poInvoice.documents.forEach((poDoc: IGalleryFile) => poDoc.type = "");

			return this.isvcGallery.saveFiles(poInvoice.documents);
		}

		return of(true);
	}

	public getInvoice(psInvoiceId: string, pbLive?: boolean): Observable<Invoice> {
		const loDataSource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				key: psInvoiceId,
				include_docs: true
			},
			live: pbLive,
		};

		return this.isvcStore.getOne<IInvoice>(loDataSource)
			.pipe(map((poInvoice: IInvoice) => plainToClass(Invoice, poInvoice)));
	}

	public getInvoices(pbLive?: boolean, pbWithAborted = false): Observable<Invoice[]> {
		const loDataSource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: C_PREFIX_INVOICE,
				endkey: `${C_PREFIX_INVOICE}${Store.C_ANYTHING_CODE_ASCII}`,
				include_docs: true
			},
			live: pbLive,
		};

		return this.isvcStore.get<IInvoice>(loDataSource)
			.pipe(
				map((paInvoices: IInvoice[]) => paInvoices.map((poInvoice: IInvoice) => plainToClass(Invoice, poInvoice))),
				map((paInvoices: Invoice[]) => pbWithAborted ? paInvoices : paInvoices.filter((poInvoice: IInvoice) => poInvoice.status.value !== EInvoiceStatus.aborted))
			);
	}

	public getPatientInvoicesPendingCashOut(psPatientId: string): Observable<Invoice[]> {
		const lsPrefix = `${C_PREFIX_INVOICE}${C_PREFIX_TRAITEMENT}${IdHelper.buildVirtualNode([UserData.currentSite?._id, psPatientId])}`;
		const loDataSource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: lsPrefix,
				endkey: `${lsPrefix}${Store.C_ANYTHING_CODE_ASCII}`,
				include_docs: true
			},
		};

		return this.isvcStore.get<IInvoice>(loDataSource)
			.pipe(
				map((paInvoices: IInvoice[]) => paInvoices.map((poInvoice: IInvoice) => plainToClass(Invoice, poInvoice))),
				map((paInvoices: Invoice[]) => paInvoices.filter((poInvoice: Invoice) => poInvoice.status.value === EInvoiceStatus.payment)),
			);
	}

	public getInvoicesByTraitement(traitementId: string, pbLive?: boolean, pbWithAborted = false): Observable<Invoice[]> {
		const loDataSource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: `${C_PREFIX_INVOICE}${traitementId}`,
				endkey: `${C_PREFIX_INVOICE}${traitementId}${Store.C_ANYTHING_CODE_ASCII}`,
				include_docs: true
			},
			live: pbLive,
		};

		return this.isvcStore.get<IInvoice>(loDataSource)
			.pipe(
				map((paInvoices: IInvoice[]) => paInvoices.map((poInvoice: IInvoice) => plainToClass(Invoice, poInvoice))),
				map((paInvoices: Invoice[]) => pbWithAborted ? paInvoices : paInvoices.filter((poInvoice: IInvoice) => poInvoice.status.value !== EInvoiceStatus.aborted))
			);
	}

	private async priceInvoice(poInvoice: Invoice): Promise<IPriceInvoiceResponse[]> {
		try {
			const lsUrl = `${this.getBaseUrl()}/entities/patients/${poInvoice.patientId}/invoices/${poInvoice._id}/tarifier?forcing=${true}`;

			return (await this.ioHttp.post(lsUrl, null, this.getHttpOptions()).toPromise()).body as IPriceInvoiceResponse[];
		}
		catch (poError) {
			console.error(`${FacturationService.C_LOG_ID}Error occured while pricing invoices`, poError);
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: poError.error?.error ?? "Erreur", message: poError.error?.message ?? "Une erreur inconnue est survenue" }));
			return [];
		}
	}

	private async priceInvoiceAnakin(invoice: Invoice): Promise<Invoice[]> {
		try {
			const lsUrl = `${this.getBaseUrl()}/entities/patients/${invoice.patientId}/invoices/${invoice._id}/tarifier?forcing=${true}`;

			let responses = (await this.ioHttp.post(lsUrl, null, this.getHttpOptions()).toPromise()).body as IPriceInvoiceResponse[];

			if (ArrayHelper.hasElements(responses)) {
				this.fillInvoicePrices(invoice, ArrayHelper.getFirstElement(responses));
				invoice.status.value = EInvoiceStatus.priced;
				return this.saveInvoices([invoice]).toPromise();
			} else {
				return [];
			}
		}
		catch (poError) {
			console.error(`${FacturationService.C_LOG_ID}Error occured while pricing invoices`, poError);
			this.svcSnackbar.showToast("error", "bottom center", "Une erreur inconnue est survenue lors de la tarification");
			throw poError;
		}
	}

	public async exportInvoiceAgrement(poInvoice: Invoice): Promise<Invoice> {
		const lsUrl = `${this.getBaseUrl()}/entities/${poInvoice.patientId}/invoices/${poInvoice._id}?system=fsv`;
		const loLoader: Loader = await this.isvcLoading.create("Export en cours...");
		await loLoader.present();

		try {
			const loUpdatedInvoice: Invoice = await this.saveInvoice(poInvoice).toPromise();
			const loResponse: HttpResponse<Invoice> = await this.ioHttp.post<Invoice>(lsUrl, { id: loUpdatedInvoice._id, rev: loUpdatedInvoice._rev }, this.getHttpOptions()).toPromise();
			return loResponse.body;
		} catch (poError) {
			console.error(`${FacturationService.C_LOG_ID}Error occured while export invoice`, poError);
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: poError.error?.error ?? "Erreur", message: poError.error?.message ?? "Une erreur inconnue est survenue." }));
			return undefined;
		}
		finally {
			await loLoader.dismiss();
		}
	}

	public async exportInvoicePdf(poInvoice: Invoice): Promise<void> {
		const lsUrl = `${this.getBaseUrl()}/entities/patients/${poInvoice.patientId}/invoices/${poInvoice._id}/pdf`;
		const loLoader: Loader = await this.isvcLoading.create("Export en cours...");
		await loLoader.present();

		try {
			const poResponse: HttpResponse<string[]> = await this.ioHttp.post<string[]>(lsUrl, { id: poInvoice._id, rev: poInvoice._rev }, this.getHttpOptions()).toPromise();
			for (let lnIndex = 0; lnIndex < poResponse.body.length; ++lnIndex) {
				const loPdfPage: string = poResponse.body[lnIndex];
				const lsFileName = `Facture_n°${poInvoice.invoiceNumber}${lnIndex > 0 ? `_${lnIndex + 1}` : ""}.pdf`;
				const loBlob: Blob = FileHelper.base64toBlob(loPdfPage);

				if (this.isvcPlatform.isMobileApp)
					await this.isvcFilesystem.createFileAsync(`Download/${lsFileName}`, loBlob, Directory.ExternalStorage, true);
				else
					FileHelper.downloadBlob(loBlob, lsFileName);
			}
			this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: "Téléchargement terminé." }));
		} catch (poError) {
			console.error(`${FacturationService.C_LOG_ID}Error occured while export invoice PDF`, poError);
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: poError.error?.error ?? "Erreur", message: poError.error?.message ?? "Une erreur inconnue est survenue" }));
		}
		finally {
			await loLoader.dismiss();
		}
	}

	public async exportInvoicePdfAnakin(poInvoice: Invoice, isMobile: boolean): Promise<void> {
		const lsUrl = `${this.getBaseUrl()}/entities/patients/${poInvoice.patientId}/invoices/${poInvoice._id}/pdf`;

		try {
			const poResponse: HttpResponse<string[]> = await this.ioHttp.post<string[]>(lsUrl, { id: poInvoice._id, rev: poInvoice._rev }, this.getHttpOptions()).toPromise();
			for (let lnIndex = 0; lnIndex < poResponse.body.length; ++lnIndex) {
				const loPdfPage: string = poResponse.body[lnIndex];
				const lsFileName = `Facture_n°${poInvoice.invoiceNumber}${lnIndex > 0 ? `_${lnIndex + 1}` : ""}.pdf`;
				const loBlob: Blob = FileHelper.base64toBlob(loPdfPage);

				if (isMobile)
					await this.isvcFilesystem.createFileAsync(`Download/${lsFileName}`, loBlob, Directory.ExternalStorage, true);
				else
					FileHelper.downloadBlob(loBlob, lsFileName);
			}
		} catch (poError) {
			console.error(`${FacturationService.C_LOG_ID}Error occured while export invoice PDF`, poError);
		}
		finally {
		}
	}

	public async getInvoiceFspPdf(poInvoice: Invoice): Promise<string[]> {
		const lsUrl = `${this.getBaseUrl()}/entities/patients/${poInvoice.patientId}/invoices/${poInvoice._id}/pdf?pdfType=fsp`;

		try {
			const poResponse: HttpResponse<string[]> = await this.ioHttp.post<string[]>(lsUrl, { id: poInvoice._id, rev: poInvoice._rev }, this.getHttpOptions()).toPromise();
			return poResponse.body;
		} catch (poError) {
			console.error(`${FacturationService.C_LOG_ID}Error occured while generate FSP`, poError);
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: poError.error?.error ?? "Erreur", message: poError.error?.message ?? "Une erreur inconnue est survenue" }));
			return [];
		}
	}

	public async priceInvoices(paInvoices: Invoice[]): Promise<Invoice[]> {
		const laUpdatedInvoices: Invoice[] = [];
		for (let lnIndex = 0; lnIndex < paInvoices.length; ++lnIndex) {
			const loInvoice: Invoice = paInvoices[lnIndex];
			const laResponses: IPriceInvoiceResponse[] = await this.priceInvoice(loInvoice);

			if (ArrayHelper.hasElements(laResponses)) {
				this.fillInvoicePrices(loInvoice, ArrayHelper.getFirstElement(laResponses));
				loInvoice.status.value = EInvoiceStatus.priced;
				laUpdatedInvoices.push(loInvoice);
			}
		}

		if (ArrayHelper.hasElements(laUpdatedInvoices))
			return this.saveInvoices(paInvoices).toPromise();
		return [];
	}

	private fillInvoicePrices(poInvoice: Invoice, poInvoicePrice: IPriceInvoiceResponse): void {
		if (ArrayHelper.hasElements(poInvoicePrice.acte)) {

			poInvoice.erreurSv = poInvoicePrice.erreurSv;
			poInvoice.actes.forEach((poInvoiceLine: InvoiceLine) => {
				//On recherche la correspondance entre l'acte retourné par le moteur de facturation et l'acte envoyé au moteur
				const actePriceResponse = poInvoicePrice.acte.find((acteResponse) => {
					//On convertit la date retournée par le moteur de facturation qui est au format 'yyyyMMDD'					
					const dateActeResponse = DateHelper.parseStringDate(acteResponse.dateExecution, ETimetablePattern.yyyyMMdd);
					const dateActeExecution = new Date(poInvoiceLine.date);
					dateActeExecution.setHours(0, 0, 0, 0);

					return acteResponse.numero == poInvoiceLine.numero && DateHelper.compareTwoDates(dateActeResponse, dateActeExecution) === 0;
				});

				//Si on retrouve bien la correspondance on valorise les montants
				if (actePriceResponse) {
					poInvoiceLine.partAMC = +actePriceResponse.montantAmc;
					poInvoiceLine.partAMO = +actePriceResponse.montantAmo;
					poInvoiceLine.partPatient = +actePriceResponse.montantPP;
					poInvoiceLine.honoraires = +actePriceResponse.montantHonoraire;
				}
			});
		}
	}

	public fillInvoiceSTSAndGU(poInvoice: Invoice, poAMCP?: AMCP): void {
		if (!this.isModeAgrement && poAMCP) {
			if (poInvoice.sts === undefined)
				poInvoice.sts = poAMCP.sts ? EFormuleSTS.ticketModerateur : undefined;
			if (poInvoice.gestionUnique === undefined)
				poInvoice.gestionUnique = poAMCP.gestionUnique;
		}
		else {
			poInvoice.sts = undefined;
			poInvoice.gestionUnique = undefined;
		}
	}

	@LogAction<Parameters<FacturationService["billPatientInvoices"]>, ReturnType<FacturationService["billPatientInvoices"]>>({
		actionId: EIdlLogActionId.invoiceExport,
		successMessage: "Export des factures.",
		errorMessage: "Echec de l'export des factures.",
		dataBuilder: (_, __, ___, paInvoices: Invoice[], peSecurisationMode: EModeSecurisation) => ({ userId: UserData.current?._id, invoicesIds: paInvoices?.map((poInvoice: Invoice) => poInvoice._id), securisationMode: peSecurisationMode })
	})
	public async billPatientInvoices(psPatientId: string, paInvoices: Invoice[], peSecurisationMode: EModeSecurisation): Promise<Invoice[]> {
		try {
			const lsUrl = `${this.getBaseUrl()}/entities/patients/${psPatientId}/invoices/export?system=fsv&forcing=true`;
			return await this.callInvoicesApi(lsUrl, paInvoices, peSecurisationMode);
		}
		catch (poError) {
			console.error(`${FacturationService.C_LOG_ID}Error occured while billing invoices`, poError);
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: poError.error?.error ?? "Erreur", message: poError.error?.message ?? "Une erreur inconnue est survenue" }));
			return [];
		}
	}

	@LogAction<Parameters<FacturationService["billPatientInvoicesAnakin"]>, ReturnType<FacturationService["billPatientInvoicesAnakin"]>>({
		actionId: EIdlLogActionId.invoiceExport,
		successMessage: "Export de la facture.",
		errorMessage: "Echec de l'export de la facture.",
		dataBuilder: (_, __, ___, paInvoice: Invoice, peSecurisationMode: EModeSecurisation) => ({
			userId: UserData.current?._id,
			invoicesIds: [paInvoice._id],  // Un seul invoice au lieu de plusieurs
			securisationMode: peSecurisationMode
		})
	})
	public async billPatientInvoicesAnakin(psPatientId: string, paInvoice: Invoice, peSecurisationMode: EModeSecurisation): Promise<Invoice[]> {
		try {
			const lsUrl = `${this.getBaseUrl()}/entities/patients/${psPatientId}/invoices/export?system=fsv&forcing=true`;
			return await this.callInvoicesApi(lsUrl, [paInvoice], peSecurisationMode);
		}
		catch (poError) {
			console.error(`${FacturationService.C_LOG_ID}Error occured while billing invoices`, poError);
			return [];
		}
	}

	public billPatientInvoicesAnakin$(
		psPatientId: string,
		paInvoice: Invoice,
		peSecurisationMode: EModeSecurisation
	): Observable<Invoice[]> {
		const lsUrl = `${this.getBaseUrl()}/entities/patients/${psPatientId}/invoices/export?system=fsv&forcing=true`;
		return from(this.callInvoicesApiAnakin(lsUrl, paInvoice, peSecurisationMode)).pipe(
			catchError((error) => {
				console.error(`${FacturationService.C_LOG_ID}Error occurred while billing invoices`, error);
				return of([]); // Return an empty array on error
			})
		);
	}

	private callInvoicesApiAnakin$(
		psUrl: string,
		invoice: Invoice,
		peSecurisationMode?: EModeSecurisation,
		psSignatureBase64?: string,
		pfOnHttpResponse?: (poResponse: HttpResponse<any>) => void
	): Promise<Invoice[]> {
		return this.ioHttp.post(
			psUrl,
			{ id: invoice._id, rev: invoice._rev, securisationMode: peSecurisationMode, signaturePraticien: psSignatureBase64 },
			this.getHttpOptions()
		).toPromise().then((loResponse: HttpResponse<any>) => {
			if (pfOnHttpResponse) pfOnHttpResponse(loResponse);
			const laInvoicesResponse: IInvoice[] = loResponse.body ?? [];
			const laInvoices: Invoice[] = laInvoicesResponse.map((poInvoice: IInvoice) => plainToClass(Invoice, poInvoice));
			return this.isvcStore.syncDocumentsToLocal(
				ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace)),
				laInvoices
			).then(() => laInvoices);
		});
	}

	private async callInvoicesApiAnakin(
		psUrl: string,
		invoice: Invoice,
		peSecurisationMode?: EModeSecurisation,
		psSignatureBase64?: string,
		pfOnHttpResponse?: (poResponse: HttpResponse<any>) => void
	): Promise<Invoice[]> {
		const loResponse: HttpResponse<any> = await this.ioHttp.post(
			psUrl,
			{ id: invoice._id, rev: invoice._rev, securisationMode: peSecurisationMode, signaturePraticien: psSignatureBase64 },
			this.getHttpOptions()).toPromise();

		if (pfOnHttpResponse)
			pfOnHttpResponse(loResponse);

		const laInvoicesResponse: IInvoice[] = loResponse.body ?? [];

		const laInvoices: Invoice[] = laInvoicesResponse.map((poInvoice: IInvoice) => plainToClass(Invoice, poInvoice));

		await this.isvcStore.syncDocumentsToLocal(
			ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace)),
			laInvoices
		);

		return laInvoices;
	}

	private async callInvoicesApi(
		psUrl: string,
		paInvoices: Invoice[],
		peSecurisationMode?: EModeSecurisation,
		psSignatureBase64?: string,
		pfOnHttpResponse?: (poResponse: HttpResponse<any>) => void
	): Promise<Invoice[]> {
		const loResponse: HttpResponse<any> = await this.ioHttp.post(
			psUrl,
			paInvoices.map((poInvoice: Invoice) => ({ id: poInvoice._id, rev: poInvoice._rev, securisationMode: peSecurisationMode, signaturePraticien: psSignatureBase64 })),
			this.getHttpOptions()).toPromise();

		if (pfOnHttpResponse)
			pfOnHttpResponse(loResponse);

		const laInvoicesResponse: IInvoice[] = loResponse.body ?? [];

		const laInvoices: Invoice[] = laInvoicesResponse.map((poInvoice: IInvoice) => plainToClass(Invoice, poInvoice));

		await this.isvcStore.syncDocumentsToLocal(
			ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace)),
			laInvoices
		);

		return laInvoices;
	}

	public cashOutInvoice(psPatientId: string, poInvoice: Invoice): Promise<Invoice[]> {
		let loLoader: Loader;
		let lePayementMode: EPayementMode;

		return this.isvcPatients.getPatient(psPatientId)
			.pipe(
				mergeMap((poPatient: IPatient) => this.presentCashOutPopup(poPatient, poInvoice)),
				filter((poResponse: IUiResponse<boolean, EPayementMode>) => poResponse.response && !StringHelper.isBlank(poResponse.values)),
				tap((poResponse: IUiResponse<boolean, EPayementMode>) => lePayementMode = poResponse.values),
				mergeMap(() => this.isvcLoading.create("Chargement en cours...")),
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap(() => loLoader.present()),
				mergeMap(() => this.innerCashOutInvoice(poInvoice, lePayementMode).pipe(tap(() => loLoader.dismiss()))),
				finalize(() => loLoader?.dismiss()),
				defaultIfEmpty([])
			)
			.toPromise();
	}

	private presentCashOutPopup(poPatient: IPatient, poInvoice: Invoice): Observable<IUiResponse<boolean, EPayementMode>> {
		return this.isvcUiMessage.showAsyncMessage<boolean, EPayementMode>(new ShowMessageParamsPopup({
			header: "Règlement de la part patient",
			message: `
				Patient: <strong>${this.ioContactNamePipe.transform(poPatient)}</strong><br>
				Reste à charge: <strong>${+NumberHelper.round(poInvoice.totalPartPatient, 2)}€</strong><br><br>
				Moyen de paiement :
			`,
			inputs: [
				{ type: 'radio', label: "Espèces", value: EPayementMode.cash },
				{ type: 'radio', label: "Chèque", value: EPayementMode.check },
				{ type: 'radio', label: "Virement", value: EPayementMode.transfer },
				{ type: 'radio', label: "Carte bancaire", value: EPayementMode.card },
			],
			buttons: [
				{ text: "Annuler", cssClass: "cancel-btn", handler: () => UiMessageService.getFalsyResponse() },
				{ text: "Valider", cssClass: "validate-btn", handler: () => UiMessageService.getTruthyResponse() }
			],
			cssClass: "invoice-popup"
		}));
	}

	@LogAction<Parameters<FacturationService["innerCashOutInvoice"]>, ReturnType<FacturationService["innerCashOutInvoice"]>>({
		actionId: EIdlLogActionId.invoiceCashOut,
		successMessage: "Encaissement de la facture.",
		errorMessage: "Echec de l'encaissement de la facture.",
		dataBuilder: (_, __, poInvoice: Invoice) => ({ userId: UserData.current?._id, invoiceId: poInvoice._id, patientId: poInvoice.patientId })
	})
	public innerCashOutInvoice(poInvoice: Invoice, pePayementMode: EPayementMode): Observable<Invoice[]> {
		const loPayement: IPayement = {
			_id: IdHelper.buildChildId(C_PREFIX_PAYEMENT, IdHelper.buildVirtualNode([UserData.currentSite?._id])),
			payerId: poInvoice.patientId,
			payerType: EPayementType.customer,
			amount: poInvoice.totalPartPatient,
			mode: pePayementMode,
			date: new Date(),
			status: EPayementStatus.completed,
			invoiceIds: [poInvoice._id]
		};
		poInvoice.status.value = EInvoiceStatus.closed;
		poInvoice.paymentIds.push(loPayement._id);

		return this.isvcStore.putMultipleDocuments([loPayement, poInvoice], ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace)))
			.pipe(map(() => [poInvoice]));
	}

	@LogAction<Parameters<FacturationService["secureInvoices"]>, ReturnType<FacturationService["secureInvoices"]>>({
		actionId: EIdlLogActionId.invoiceSecure,
		successMessage: "Sécurisation des factures.",
		errorMessage: "Echec de la sécurisation des factures.",
		dataBuilder: (_, __, paInvoices: Invoice[], peSecurisationMode: EModeSecurisation, ___, psTerminalId: string) => ({ userId: UserData.current?._id, invoicesIds: paInvoices.map((poInvoice: Invoice) => poInvoice._id), securisationMode: peSecurisationMode, psTerminalId: psTerminalId })
	})
	public async secureInvoices(
		paInvoices: Invoice[],
		peSecurisationMode: EModeSecurisation,
		psTerminalId: string,
		psSignatureBase64?: string
	): Promise<Invoice[]> {
		try {

			// ajout d'un code CPS fictif
			const lsUrl = `${this.getBaseUrl()}/entities/invoices/securiser?codeCPS=1111&terminalId=${psTerminalId}&forcing=${true}`;
			return await this.callInvoicesApi(
				lsUrl,
				paInvoices,
				peSecurisationMode,
				psSignatureBase64,
				(poResponse: HttpResponse<any>) =>
					this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: this.getSecureResponseMessage(poResponse.status) }))
			);
		}
		catch (poError) {
			console.error(`${FacturationService.C_LOG_ID}Error occured while secure invoices`, poError);
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: poError.error?.error ?? "Erreur", message: poError.error?.message ?? "Une erreur inconnue est survenue" }));
			return [];
		}
	}



	public async cancelAction(psPatientId: string, paInvoices: Invoice[]): Promise<string> {
		const loResponse: IUiResponse<boolean, any> = await this.isvcUiMessage.showAsyncMessage<boolean>(new ShowMessageParamsPopup({
			header: "Annulation",
			message: `Etes-vous sûr de vouloir annuler ${paInvoices.length > 1 ? "ces factures" : "cette facture?"}`,
			buttons: [{ text: "NON", handler: UiMessageService.getFalsyResponse }, { text: "OUI", handler: UiMessageService.getTruthyResponse }],
		})).toPromise();

		if (loResponse?.response) {
			const loLoader: Loader = await this.presentLoader("Annulation en cours");
			try {
				const laDocsToSave: IStoreDocument[] = [];
				const laInvoicesThatNeedCreditNote: Invoice[] = [];

				//On récupère les prestations à partir de l'invoice que l'on souhaite annuler
				const idsPrestationsASuppr: string[] = paInvoices.flatMap((invoice) => invoice.actes.map((acte) => acte.prestationId));
				await this.isvcPrestations.deletePrestations(idsPrestationsASuppr).toPromise();

				//Création des ids d'interventions statement à rechercher
				const idsInterventionARechercher: string[] = [];
				idsPrestationsASuppr.forEach((idPresta) => {
					const idTrait = IdHelper.extractIdWithoutPrefix(idPresta, EPrefix.prestation);
					const dernierUnderscoreIndex = idTrait.lastIndexOf("_");
					const idTraitModifie = idTrait.substring(0, dernierUnderscoreIndex);
					const idInter: string = `${C_PREFIX_INTER_STATE}${idTraitModifie}`;
					idsInterventionARechercher.push(idInter);
				});

				//Recherche des interventions statement à suppr
				let traitementsId: string[] = paInvoices.map((inv) => inv.traitementId);
				const traitementId = ArrayHelper.getFirstElement(traitementsId);
				const interventions = await this.isvcInterventionStatement.getTraitementInterventionStatements(traitementId).toPromise();
				const interventionsFiltrees = interventions.filter(intervention =>
					idsInterventionARechercher.some(idRecherche => intervention._id.startsWith(idRecherche))
				);

				//On met à jour le state du traitement, il n'est plus terminé
				await this.svcTraitement.modifierStateTraitement(traitementId).toPromise();

				//Suppression des interventions statement
				await this.isvcInterventionStatement.deleteInterventionsStatement(interventionsFiltrees).toPromise();

				paInvoices.forEach((poInvoice: Invoice) => {
					if ([EInvoiceStatus.created, EInvoiceStatus.priced].includes(poInvoice.status.value)) {
						poInvoice.status.value = EInvoiceStatus.aborted;
						laDocsToSave.push(poInvoice);
					}
					else
						laInvoicesThatNeedCreditNote.push(poInvoice);
				});

				if (ArrayHelper.hasElements(laInvoicesThatNeedCreditNote))
					await this.innerCancelInvoices(psPatientId, laInvoicesThatNeedCreditNote);

				await this.isvcStore.putMultipleDocuments(laDocsToSave).toPromise();

				return traitementId ?? "";
			}
			finally {
				await loLoader?.dismiss();
			}
		}

		return "";
	}

	private async innerCancelInvoices(psPatientId: string, paInvoices: Invoice[]): Promise<any[]> {
		try {
			const lsUrl = `${this.getBaseUrl()}/entities/patients/${psPatientId}/invoices/avoir`;
			return await this.callInvoicesApi(lsUrl, paInvoices);
		}
		catch (poError) {
			console.error(`${FacturationService.C_LOG_ID}Error occured while billing invoices`, poError);
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: poError.error?.error ?? "Erreur", message: poError.error?.message ?? "Une erreur inconnue est survenue" }));
			return [];
		}
	}

	public async cancelActionAnakin(psPatientId: string, paInvoice: Invoice, seances: StoredSeance[]): Promise<string> {
		try {
			const laDocsToSave: IStoreDocument[] = [];
			const laInvoicesThatNeedCreditNote: Invoice[] = [];

			//On récupère les prestations à partir de l'invoice que l'on souhaite annuler
			const idsPrestationsASuppr: string[] = paInvoice.actes.map((acte) => acte.prestationId);
			await this.isvcPrestations.deletePrestations(idsPrestationsASuppr).toPromise();

			//Création des ids d'interventions statement à rechercher
			const idsInterventionARechercher: string[] = [];
			idsPrestationsASuppr.forEach((idPresta) => {
				const idTrait = IdHelper.extractIdWithoutPrefix(idPresta, EPrefix.prestation);
				const dernierUnderscoreIndex = idTrait.lastIndexOf("_");
				const idTraitModifie = idTrait.substring(0, dernierUnderscoreIndex);
				const idInter: string = `${C_PREFIX_INTER_STATE}${idTraitModifie}`;
				idsInterventionARechercher.push(idInter);
			});

			//Recherche des interventions statement à suppr
			let traitementId: string = paInvoice.traitementId;
			const interventions = await this.isvcInterventionStatement.getTraitementInterventionStatements(traitementId).toPromise();
			const interventionsFiltrees = interventions.filter(intervention =>
				idsInterventionARechercher.some(idRecherche => intervention._id.startsWith(idRecherche))
			);

			//On met à jour le state du traitement, il n'est plus terminé
			await this.svcTraitement.modifierStateTraitement(traitementId).toPromise();

			//Suppression des interventions statement
			await this.isvcInterventionStatement.deleteInterventionsStatement(interventionsFiltrees).toPromise();

			//On remet les status des séances facturées : 
			// const newDateStatus: Date = new Date();
			// seances.forEach(async seance => {
			// 	seance.status = EStatusSeance.done;
			// 	seance.statusChangeDate = newDateStatus;
			// 	await this.svcSeance.updateSeance(seance).toPromise();
			// })


			if ([EInvoiceStatus.created, EInvoiceStatus.priced].includes(paInvoice.status.value)) {
				paInvoice.status.value = EInvoiceStatus.aborted;
				laDocsToSave.push(paInvoice);
			}
			else
				laInvoicesThatNeedCreditNote.push(paInvoice);


			if (ArrayHelper.hasElements(laInvoicesThatNeedCreditNote))
				await this.innerCancelInvoices(psPatientId, laInvoicesThatNeedCreditNote);

			await this.isvcStore.putMultipleDocuments(laDocsToSave).toPromise();

			return traitementId ?? "";
		}
		finally {

		}


		return "";
	}

	public cancelActionAnakin$(psPatientId: string, paInvoice: Invoice, seances: StoredSeance[]): Observable<string> {
		const laDocsToSave: IStoreDocument[] = [];
		const laInvoicesThatNeedCreditNote: Invoice[] = [];

		// On récupère les prestations à partir de l'invoice que l'on souhaite annuler
		const idsPrestationsASuppr: string[] = paInvoice.actes.map((acte) => acte.prestationId);

		return this.isvcPrestations.deletePrestations(idsPrestationsASuppr).pipe(
			// Création des ids d'interventions statement à rechercher
			mergeMap(() => {
				const idsInterventionARechercher: string[] = idsPrestationsASuppr.map((idPresta) => {
					const idTrait = IdHelper.extractIdWithoutPrefix(idPresta, EPrefix.prestation);
					const dernierUnderscoreIndex = idTrait.lastIndexOf("_");
					const idTraitModifie = idTrait.substring(0, dernierUnderscoreIndex);
					return `${C_PREFIX_INTER_STATE}${idTraitModifie}`;
				});

				// Recherche des interventions statement à supprimer
				const traitementId: string = paInvoice.traitementId;
				return this.isvcInterventionStatement.getTraitementInterventionStatements(traitementId).pipe(
					mergeMap((interventions) => {
						const interventionsFiltrees = interventions.filter(intervention =>
							idsInterventionARechercher.some(idRecherche => intervention._id.startsWith(idRecherche))
						);

						// Mise à jour du state du traitement, il n'est plus terminé
						return this.svcTraitement.modifierStateTraitement(traitementId).pipe(
							mergeMap(() => this.isvcInterventionStatement.deleteInterventionsStatement(interventionsFiltrees)),
							mergeMap(() => {
								const newDateStatus: Date = new Date();

								// Mise à jour des séances
								const updateSeances$ = from(seances).pipe(
									mergeMap(seance => {
										seance.status = EStatusSeance.done;
										seance.statusChangeDate = newDateStatus;
										return this.svcSeance.updateSeance(seance);
									}),
									toArray()
								);

								return updateSeances$;
							}),
							mergeMap(() => {
								if ([EInvoiceStatus.created, EInvoiceStatus.priced].includes(paInvoice.status.value)) {
									paInvoice.status.value = EInvoiceStatus.aborted;
									laDocsToSave.push(paInvoice);
								} else {
									laInvoicesThatNeedCreditNote.push(paInvoice);
								}

								if (ArrayHelper.hasElements(laInvoicesThatNeedCreditNote)) {
									// Conversion de la promesse en Observable
									return from(this.innerCancelInvoices(psPatientId, laInvoicesThatNeedCreditNote)).pipe(
										mergeMap(() => this.isvcStore.putMultipleDocuments(laDocsToSave))
									);
								}

								return this.isvcStore.putMultipleDocuments(laDocsToSave);
							}),
							map(() => traitementId ?? "")
						);
					})
				);
			})
		);
	}


	public async forwardAction(
		psPatientId: string,
		paInvoices: Invoice[],
		pbUpToDate: boolean
	): Promise<Invoice[]> {
		let loLoader: Loader;
		try {
			const laStatuses: EInvoiceStatus[] = ArrayHelper.unique(paInvoices.map((poInvoice: Invoice) => poInvoice.status.value));

			if (laStatuses.length > 1)
				throw new ForwardActionError("Toutes les factures doivent être au même état.");

			const leStatus: EInvoiceStatus = ArrayHelper.getFirstElement(laStatuses);
			let laInvoices: Invoice[];
			let lsMessage = `Facture${paInvoices.length > 1 ? "s" : ""}`;
			let leModeSecurisation: EModeSecurisation;




			switch (leStatus) {
				case EInvoiceStatus.created:
					const ordonnance = await this.isvcOrdonnance.getOrdonnance(ArrayHelper.getFirstElement(paInvoices).ordonnanceId).toPromise();
					if (!ordonnance.documents || ordonnance.documents.length === 0) {
						this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
							header: "Information : Aucun document n’est lié à l'ordonnance.",
							message: "Aucun document n'est lié à l'ordonnance, cela risque d'impacter l'envoi au service SCOR de l'assurance maladie."
						}));
					}
					loLoader = await this.presentLoader("Tarification en cours");
					laInvoices = await this.priceInvoices(paInvoices);
					lsMessage += ` tarifiée${paInvoices.length > 1 ? "s" : ""}`;
					break;
				case EInvoiceStatus.priced:
				case EInvoiceStatus.invoicing:
					leModeSecurisation = await this.getModeSecurisation(pbUpToDate);
					if (!leModeSecurisation)
						break;

					loLoader = await this.presentLoader("Facturation en cours");
					laInvoices = await this.billPatientInvoices(psPatientId, paInvoices, leModeSecurisation);
					lsMessage += ` prête${paInvoices.length > 1 ? "s" : ""}`;
					break;
				case EInvoiceStatus.checked:
					[loLoader, laInvoices, lsMessage] = await this.inner_forwardAction_secureInvoices(psPatientId, paInvoices, lsMessage, pbUpToDate);
					break;
				case EInvoiceStatus.payment:
					if (paInvoices.length > 1)
						throw new ForwardActionError("Vous ne pouvez encaisser qu'une facture à la fois.");

					const loInvoice: Invoice = paInvoices[0];
					if (loInvoice.totalPartPatient > 0) {
						laInvoices = await this.cashOutInvoice(psPatientId, loInvoice);
						lsMessage += ` encaissée${paInvoices.length > 1 ? "s" : ""}`;
					}
					break;
				default:
					throw new ForwardActionError(`état ${leStatus} inconnu.`);
			}
			if (ArrayHelper.hasElements(laInvoices)) {
				this.applyNewInvoices(paInvoices, laInvoices);
				this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: `${lsMessage}`, position: "bottom" }));
			}

			return laInvoices ?? [];
		}
		catch (poError) {
			console.error(`${FacturationService.C_LOG_ID}Erreur lors de l'action d'avancement de la facture.`, poError);
			throw poError;
		}
		finally {
			await loLoader?.dismiss();
		}
	}

	private async inner_forwardAction_secureInvoices(psPatientId: string, paInvoices: Invoice[], psMessage: string, pbUpToDate: boolean): Promise<[Loader, Invoice[], string]> {
		let loLoader: Loader;
		let laInvoices: Invoice[];

		const laSecurisationMode: EModeSecurisation[] = ArrayHelper.unique(paInvoices.map((poInvoice: Invoice) => poInvoice.securisationMode));

		if (laSecurisationMode.length > 1)
			throw new ForwardActionError("Toutes les factures doivent avoir le même mode de sécurisation.");

		const leModeSecurisation: EModeSecurisation = ArrayHelper.getFirstElement(laSecurisationMode);
		const lbHasDocuments: boolean = paInvoices.some((poInvoice: Invoice) => ArrayHelper.hasElements(poInvoice.documents));
		const leSelectedModeSecurisation: EModeSecurisation = lbHasDocuments ? EModeSecurisation.DEGRADE : await this.getModeSecurisation(pbUpToDate, leModeSecurisation);

		let loResponse: IPrepareSecurisationResponse;
		if (leSelectedModeSecurisation)
			loResponse = await this.prepareSecurisation(psPatientId, paInvoices, leSelectedModeSecurisation, leModeSecurisation);

		if (loResponse) {
			let loResponsePreparationDegradee: IPrepareSecurisationDegradeeResponse;
			if (leSelectedModeSecurisation === EModeSecurisation.DEGRADE && !lbHasDocuments)
				loResponsePreparationDegradee = await this.prepareForSecurisationDegradee();

			if (!loResponsePreparationDegradee || loResponsePreparationDegradee.signatureBase64) {
				loLoader = await this.presentLoader("Sécurisation en cours");
				laInvoices = await this.secureInvoices(paInvoices, leSelectedModeSecurisation, loResponse.terminalId, loResponsePreparationDegradee?.signatureBase64);
			}
			else if (loResponsePreparationDegradee?.careSheet)
				await this.displayFsp(paInvoices);

			if (leSelectedModeSecurisation === EModeSecurisation.SECURISE)
				psMessage += ` securisée${paInvoices.length > 1 ? "s" : ""}`;
			else if (leSelectedModeSecurisation === EModeSecurisation.DEGRADE)
				psMessage += ` signée${paInvoices.length > 1 ? "s" : ""}`;
		}
		else if (leSelectedModeSecurisation === EModeSecurisation.PAPIER) {
			paInvoices.forEach((poInvoice: Invoice) => poInvoice.status.value = poInvoice.totalPartPatient > 0 ? EInvoiceStatus.payment : EInvoiceStatus.closed);
			await this.saveInvoices(paInvoices).toPromise();
			await this.displayFsp(paInvoices);
		}

		return [loLoader, laInvoices, psMessage];
	}

	private async inner_forwardActionAnakin_secureInvoices(psPatientId: string, invoice: Invoice, pbUpToDate: boolean, terminalId: string): Promise<Invoice[]> {
		let laInvoices: Invoice[];

		const securisationMode: EModeSecurisation = invoice.securisationMode;
		const lbHasDocuments: boolean = ArrayHelper.hasElements(invoice.documents);
		const leSelectedModeSecurisation: EModeSecurisation = lbHasDocuments ? EModeSecurisation.DEGRADE : this.getModeSecurisationAnakin(pbUpToDate, securisationMode);

		laInvoices = await this.secureInvoices([invoice], leSelectedModeSecurisation, terminalId);
		// let loResponse: IPrepareSecurisationResponse;
		// // if (leSelectedModeSecurisation)
		// // 	loResponse = await this.prepareSecurisationAnakin(psPatientId, invoice, leSelectedModeSecurisation, securisationMode);

		// if (loResponse) {

		// 	let loResponsePreparationDegradee: IPrepareSecurisationDegradeeResponse;
		// 	if (leSelectedModeSecurisation === EModeSecurisation.DEGRADE && !lbHasDocuments)
		// 		loResponsePreparationDegradee = await this.prepareForSecurisationDegradee();

		// 	if (!loResponsePreparationDegradee || loResponsePreparationDegradee.signatureBase64) {
		// 		laInvoices = await this.secureInvoices([invoice], leSelectedModeSecurisation, loResponse.terminalId, loResponsePreparationDegradee?.signatureBase64);
		// 	}
		// 	else if (loResponsePreparationDegradee?.careSheet)
		// 		await this.displayFsp([invoice]);
		// }
		// else if (leSelectedModeSecurisation === EModeSecurisation.PAPIER) {
		// 	invoice.status.value = invoice.totalPartPatient > 0 ? EInvoiceStatus.payment : EInvoiceStatus.closed;
		// 	await this.saveInvoices([invoice]).toPromise();
		// 	await this.displayFsp([invoice]);
		// }

		return laInvoices;
	}

	public async forwardActionAnakin(
		psPatientId: string,
		invoice: Invoice,
		pbUpToDate: boolean,
		terminalId?: string,
	): Promise<Invoice> {
		try {
			const statusInv: EInvoiceStatus = invoice.status.value;
			let invoiceTraitee: Invoice[];
			let leModeSecurisation: EModeSecurisation;

			switch (statusInv) {
				case EInvoiceStatus.created:
					invoiceTraitee = await this.priceInvoiceAnakin(invoice);
					break;
				case EInvoiceStatus.priced:
				case EInvoiceStatus.invoicing:
					if (invoice.securisationMode === EModeSecurisation.PAPIER) {
						leModeSecurisation = this.getModeSecurisationAnakin(pbUpToDate, invoice.securisationMode);
					} else {
						leModeSecurisation = this.getModeSecurisationAnakin(pbUpToDate);
					}
					if (!leModeSecurisation)
						break;

					invoiceTraitee = await this.billPatientInvoicesAnakin(psPatientId, invoice, leModeSecurisation);
					break;
				case EInvoiceStatus.checked:
					if (terminalId) {
						invoiceTraitee = await this.inner_forwardActionAnakin_secureInvoices(psPatientId, invoice, pbUpToDate, terminalId);
					}
					break;
				case EInvoiceStatus.payment:
					if (invoice.totalPartPatient > 0) {
						invoiceTraitee = await this.cashOutInvoice(psPatientId, invoice);
					}
					break;
				default:
					throw new ForwardActionError(`état ${statusInv} inconnu.`);
			}

			return ArrayHelper.getFirstElement(invoiceTraitee) ?? null;
		}
		catch (poError) {
			console.error(`${FacturationService.C_LOG_ID}Erreur lors de l'action d'avancement de la facture.`, poError);
			this.svcSnackbar.showToast("error", "bottom center", "Erreur lors de la facturation.")
			throw poError;
		}
		finally {
			//TODO a voir si on met un loader
			// await loLoader?.dismiss();
		}
	}

	// public forwardActionAnakin$(
	// 	psPatientId: string,
	// 	invoice: Invoice,
	// 	pbUpToDate: boolean
	//   ): Observable<Invoice[]> {
	// 	const statusInv: EInvoiceStatus = invoice.status.value;

	// 	switch (statusInv) {
	// 	  case EInvoiceStatus.created:
	// 		return this.priceInvoiceAnakin(invoice);
	// 	  case EInvoiceStatus.priced:
	// 	  case EInvoiceStatus.invoicing:
	// 		return this.getModeSecurisation(pbUpToDate).pipe(
	// 		  switchMap((leModeSecurisation) => {
	// 			if (!leModeSecurisation) {
	// 			  return of([]);
	// 			}
	// 			return this.billPatientInvoicesAnakin(psPatientId, invoice, leModeSecurisation);
	// 		  })
	// 		);
	// 	  case EInvoiceStatus.checked:
	// 		return this.inner_forwardActionAnakin_secureInvoices(psPatientId, invoice, pbUpToDate);
	// 	  case EInvoiceStatus.payment:
	// 		if (invoice.totalPartPatient > 0) {
	// 		  return this.cashOutInvoice(psPatientId, invoice);
	// 		} else {
	// 		  return of([]);
	// 		}
	// 	  default:
	// 		return throwError(() => new ForwardActionError(`état ${statusInv} inconnu.`));
	// 	}
	//   }


	public async displayFsp(paInvoices: Invoice[]): Promise<void> {
		const loFspListByInvoice = new Map<Invoice, { blob: Blob; name: string; url: string; }[]>();
		let lbHasFsp: boolean;

		const loLoader: Loader = await this.isvcLoading.create("Génération des PDF en cours...");
		await loLoader.present();

		await Promise.all(paInvoices.map(async (poInvoice: Invoice) => {
			const laInvoiceFsp: string[] = await this.getInvoiceFspPdf(poInvoice);
			const laBlobs: Blob[] = laInvoiceFsp.map((psBase64: string) => FileHelper.base64toBlob(psBase64, "application/pdf"));

			laBlobs.forEach((poBlob: Blob, pnIndex: number) => {
				const loFsp: { blob: Blob; name: string; url: string; } = {
					blob: poBlob,
					name: `FSP n°${pnIndex + 1}`,
					url: window.URL.createObjectURL(poBlob)
				};


				if (loFspListByInvoice.has(poInvoice))
					loFspListByInvoice.get(poInvoice).push(loFsp);
				else
					loFspListByInvoice.set(poInvoice, [loFsp]);
			});

			if (!lbHasFsp)
				lbHasFsp = ArrayHelper.hasElements(laBlobs);
		}));

		await loLoader.dismiss();

		if (lbHasFsp) {
			await this.isvcModal.open<boolean>({
				component: FspListModalComponent,
				componentProps: {
					fspListByInvoice: loFspListByInvoice
				}
			}).toPromise();
		}
	}

	public async displayFspAnakin(paInvoices: Invoice[]): Promise<Map<Invoice, { blob: Blob; name: string; url: string; }[]>> {
		const loFspListByInvoice = new Map<Invoice, { blob: Blob; name: string; url: string; }[]>();
		let lbHasFsp: boolean;

		await Promise.all(paInvoices.map(async (poInvoice: Invoice) => {
			const laInvoiceFsp: string[] = await this.getInvoiceFspPdf(poInvoice);
			const laBlobs: Blob[] = laInvoiceFsp.map((psBase64: string) => FileHelper.base64toBlob(psBase64, "application/pdf"));

			laBlobs.forEach((poBlob: Blob, pnIndex: number) => {
				const loFsp: { blob: Blob; name: string; url: string; } = {
					blob: poBlob,
					name: `FSP n°${pnIndex + 1}`,
					url: window.URL.createObjectURL(poBlob)
				};


				if (loFspListByInvoice.has(poInvoice))
					loFspListByInvoice.get(poInvoice).push(loFsp);
				else
					loFspListByInvoice.set(poInvoice, [loFsp]);
			});

			if (!lbHasFsp)
				lbHasFsp = ArrayHelper.hasElements(laBlobs);
		}));

		return lbHasFsp ? loFspListByInvoice : null;
	}


	private async prepareForSecurisationDegradee(): Promise<IPrepareSecurisationDegradeeResponse> {
		const lbCanPatientSign: boolean = await this.showCanPatientSignPopup().toPromise();
		if (lbCanPatientSign === false) {
			const lsSignatureData: string = localStorage.getItem(`${C_PREFIX_SIGNATURE}${UserHelper.getUserContactId()}`);
			let lsBase64Signature: string = lsSignatureData ? (JSON.parse(lsSignatureData) as IDrawingResult).base64 : undefined;
			if (!lsBase64Signature)
				lsBase64Signature = await this.showSignaturePopup().toPromise();

			if (!lsBase64Signature)
				throw new OsappError("La signature doit être saisie.");

			return { signatureBase64: lsBase64Signature };
		}
		else
			return { careSheet: lbCanPatientSign === true };
	}

	private applyNewInvoices(paOldInvoices: Invoice[], paNewInvoices: Invoice[]): void {
		const loNewInvoiceById: Map<string, Invoice> = ArrayHelper.groupByUnique(paNewInvoices, (poInvoice: Invoice) => poInvoice._id);

		paOldInvoices.forEach((poInvoice: Invoice) => {
			const loNewInvoice: Invoice = loNewInvoiceById.get(poInvoice._id);

			if (loNewInvoice)
				ObjectHelper.assign(poInvoice, loNewInvoice);
		});
	}

	private async presentLoader(psMessage: string): Promise<Loader> {
		return (await this.isvcLoading.create(psMessage)).present();
	}

	private async getModeSecurisation(pbUpToDate: boolean, peSecurisationMode: EModeSecurisation = EModeSecurisation.SECURISE): Promise<EModeSecurisation> {
		peSecurisationMode = !pbUpToDate && peSecurisationMode === EModeSecurisation.SECURISE ? EModeSecurisation.DEGRADE : peSecurisationMode;

		const poResponse: IUiResponse<any, EModeSecurisation> = await this.isvcUiMessage.showAsyncMessage(new ShowMessageParamsPopup({
			header: "Mode de sécurisation",
			message: pbUpToDate ? "" : "Les informations du patient ou de sa couverture AMO n'ont pas été lues en carte, la facturation ne pourra donc pas être effectuée en mode 'Sésam Vitale'",
			inputs: [
				{ type: "radio", label: "Sésam Vitale", value: EModeSecurisation.SECURISE, checked: peSecurisationMode === EModeSecurisation.SECURISE, disabled: !pbUpToDate },
				{ type: "radio", label: "Papier", value: EModeSecurisation.PAPIER, checked: peSecurisationMode === EModeSecurisation.PAPIER },
				{ type: "radio", label: "Dégradée", value: EModeSecurisation.DEGRADE, checked: peSecurisationMode === EModeSecurisation.DEGRADE },
				{ type: "radio", label: "Sésam sans vitale", value: EModeSecurisation.SANSVITA, checked: peSecurisationMode === EModeSecurisation.SANSVITA },
			],
			buttons: [
				{ text: "ANNULER", role: UiMessageService.C_CANCEL_ROLE, handler: UiMessageService.getFalsyResponse },
				{ text: "VALIDER", handler: UiMessageService.getTruthyResponse }
			]
		})).toPromise();

		return poResponse?.values;
	}

	private getModeSecurisationAnakin(pbUpToDate: boolean, peSecurisationMode: EModeSecurisation = EModeSecurisation.SECURISE): EModeSecurisation {
		return !pbUpToDate && peSecurisationMode === EModeSecurisation.SECURISE ? EModeSecurisation.DEGRADE : peSecurisationMode;
	}

	public static arePatientAndAMOUpToDate(poPatient: IPatient, poAMOP: AMOP): boolean {
		if (!poAMOP)
			return false;
		return !StringHelper.isBlank(poPatient.externalId) && poAMOP.updateMode !== EUpdateMode.manual;
	}

	private async prepareSecurisation(
		psPatientId: string,
		paInvoices: Invoice[],
		peSelectedModeSecurisation: EModeSecurisation,
		peModeSecurisation: EModeSecurisation
	): Promise<IPrepareSecurisationResponse> {
		let lsTerminalId: string;

		if (peSelectedModeSecurisation !== peModeSecurisation) {
			paInvoices.forEach((poInvoice: Invoice) => poInvoice.status.value = EInvoiceStatus.invoicing);
			const loLoader: Loader = await this.presentLoader("Re-facturation en cours...");
			this.applyNewInvoices(paInvoices, await this.billPatientInvoices(psPatientId, paInvoices, peSelectedModeSecurisation).finally(() => loLoader.dismiss()));
		}

		if ([EModeSecurisation.SECURISE, EModeSecurisation.DEGRADE].includes(peSelectedModeSecurisation)) {
			const lbBypassVitale: boolean = peSelectedModeSecurisation === EModeSecurisation.DEGRADE;

			const loTerminalInfo: ITerminalInfo = await this.isvcTerminalInfoModalOpener.open({
				title: lbBypassVitale ? "Lecture carte CPS" : "Lecture carte vitale",
				bypassVitale: lbBypassVitale
			}).toPromise();
			if (loTerminalInfo) {
				return { terminalId: loTerminalInfo.terminalId };
			}
		}

		return undefined;
	}

	private async prepareSecurisationAnakin(
		psPatientId: string,
		paInvoice: Invoice,
		peSelectedModeSecurisation: EModeSecurisation,
		peModeSecurisation: EModeSecurisation
	): Promise<IPrepareSecurisationResponse> {
		if (peSelectedModeSecurisation !== peModeSecurisation) {
			paInvoice.status.value = EInvoiceStatus.invoicing;
			await this.billPatientInvoicesAnakin(psPatientId, paInvoice, peSelectedModeSecurisation)
		}

		if ([EModeSecurisation.SECURISE, EModeSecurisation.DEGRADE].includes(peSelectedModeSecurisation)) {
			const lbBypassVitale: boolean = peSelectedModeSecurisation === EModeSecurisation.DEGRADE;

			const loTerminalInfo: ITerminalInfo = await this.isvcTerminalInfoModalOpener.open({
				title: lbBypassVitale ? "Lecture carte CPS" : "Lecture carte vitale",
				bypassVitale: lbBypassVitale
			}).toPromise();
			if (loTerminalInfo) {
				return { terminalId: loTerminalInfo.terminalId };
			}
		}

		return undefined;
	}

	private showCPSPopup(): Observable<string> {
		return this.isvcUiMessage.showAsyncMessage<boolean, { cps: string }>(new ShowMessageParamsPopup({
			header: "Saisir code CPS",
			inputs: [{ type: "number", name: "cps" }],
			buttons: [{ text: "ANNULER", handler: UiMessageService.getFalsyResponse }, { text: "VALIDER", handler: UiMessageService.getTruthyResponse }],
			cssClass: "z-index-max invoice-popup"
		})).pipe(
			map((poResponse: IUiResponse<boolean, { cps: string }>) => poResponse.values?.cps)
		);
	}

	private showCanPatientSignPopup(): Observable<boolean> {
		return this.isvcUiMessage.showAsyncMessage<boolean, boolean>(new ShowMessageParamsPopup({
			header: "Signature patient",
			message: "Le patient est-il en capacité de signer le(s) documents(s) ?",
			inputs: [
				{ type: 'radio', label: "Oui", value: true },
				{ type: 'radio', label: "Non", value: false }
			],
			buttons: [
				{ text: "Annuler", cssClass: "cancel-btn", handler: () => UiMessageService.getFalsyResponse() },
				{ text: "Valider", cssClass: "validate-btn", handler: () => UiMessageService.getTruthyResponse() }
			],
			cssClass: "invoice-popup"
		}))
			.pipe(
				map((poResponse: IUiResponse<boolean, boolean>) => poResponse.values)
			);
	}

	private showSignaturePopup(): Observable<string> {
		return this.isvcModal.open<IDrawingResult>({
			component: DrawingModalComponent,
			componentProps: {
				title: "Signer les feuilles de soins"
			} as IDrawingModalParams
		})
			.pipe(
				map((poSignature: IDrawingResult) => {
					if (poSignature)
						localStorage.setItem(`${C_PREFIX_SIGNATURE}${UserHelper.getUserContactId()}`, JSON.stringify(poSignature));
					return poSignature.base64;
				})
			);
	}

	public showErreursSVPopup(poInvoice: Invoice) {
		if (ArrayHelper.hasElements(poInvoice.erreurSv)) {
			const lsErrorMessage: string = poInvoice.erreurSv
				.map((poError: IFsvErrorSV) => `<li>${poError.bloquant ? "Bloquant : " : "Non bloquant : "}${poError.libelle}</li>`)
				.join();

			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
				header: "Des erreurs ont été détectées sur la facture :",
				message: `<ul>${lsErrorMessage}</ul>`
			}
			));
		}
	}

	private getSecureResponseMessage(pnResponseStatus: number): string {
		switch (pnResponseStatus) {
			case 201:
				return "Toutes les FSE/DRE ont été générées.";

			case 204:
				return "Requête traitée avec succés, mais aucune FSE/DRE n'a été crée.";

			case 206:
				return "Requête traitée avec succés, mais certaines FSE/DRE n'ont pas été crées.";

			default:
				return "";
		}
	}

	public static sortInvoices(paInvoices: Invoice[]): Invoice[] {
		return ArrayHelper.dynamicSort(paInvoices, "invoiceNumber", ESortOrder.descending);
	}

	public static fsvToIdlInvoiceNumber(pnFsvInvoiceNumber: number): string {
		return pnFsvInvoiceNumber.toString().padStart(9, "0");
	}

	//#region Filters

	public patientMatchSearchValue(psValue: string, poPatient: IPatient): boolean {
		const lsPatientName: string = this.ioContactNamePipe.transform(poPatient).toLowerCase();

		return psValue.toLowerCase()
			.split(" ")
			.every((psWord: string) => lsPatientName.includes(psWord));
	}

	public seanceMatchSearchValue(psValue: string, paActes: Acte[]): boolean {
		const lsSeanceActesText: string = this.ioConcatActesPipe.transform(paActes, ",").toLowerCase();

		return psValue.toLowerCase()
			.split(" ")
			.every((psWord: string) => lsSeanceActesText.includes(psWord));
	}

	public interStateAndPatientMatchSearchValue(psValue: string, poInterStateWithSeance: InterSateWithPatient): boolean {
		return this.seanceMatchSearchValue(psValue, poInterStateWithSeance.actesData) || this.patientMatchSearchValue(psValue, poInterStateWithSeance.patient);
	}

	/** Crée les différentes options de filtrage. */
	public createFilterbarOptions(paSelectedPatients: IPatient[], paPatients: IPatient[], paSelectedIntervenants: IIdelizyContact[]): IFilterbarOptions[] {
		return [
			this.createPatientsFilterbarOptions(paSelectedPatients, paPatients),
			this.createDatesRangeFilterbarOptions(),
			this.createIntervenantFilterbarOptions(paSelectedIntervenants),
			this.createOtherFilterbarOptions()
		];
	}

	/** Crée les options de filtrage par patients. */
	private createPatientsFilterbarOptions(paSelectedPatients: IPatient[], paPatients: IPatient[]): IFilterbarOptions {
		return {
			id: PatientsService.C_FILTER_PATIENTS_ID,
			icon: "person-circle",
			returnType: EFilterActionReturnType.observable,
			filterType: EFilterType.avatar,
			action: () => {
				return this.isvcPatients.selectPatients(
					paSelectedPatients.map((poPatient: IPatient) => poPatient._id),
					paPatients,
					"Filtrer par patient"
				);
			}
		} as IFilterbarOptions;
	}

	/** Crée les options de filtrage par intervenants. */
	public createIntervenantFilterbarOptions(paSelectedIntervenants: IIdelizyContact[]): IFilterbarOptions {
		return {
			id: FacturationService.C_INTERVENANT_FILTER_ID,
			icon: "addressbook",
			returnType: EFilterActionReturnType.observable,
			filterType: EFilterType.avatar,
			action: () => {
				// On crée les paramètres du sélecteur des intervenants dans la fonction directement
				// pour prendre en compte les modifications des prescripteurs présélectionnés.
				const loSelectorParams: IContactsSelectorParams = {
					userContactVisible: true,
					hasSearchbox: true,
					preSelectedIds: paSelectedIntervenants.map((poPatient: IIdelizyContact) => poPatient._id),
					selectionMinimum: 0,
					allSelectionButton: true,
					searchOptions: {
						searchboxPlaceholder: "Rechercher un intervenant",
						searchableFields: [{ key: "firstName" }, { key: "lastName" }]
					}
				};

				return this.isvcContacts.openContactsSelectorAsModal(loSelectorParams, "Filtrer par intervenant") as Observable<IPatient[]>;
			}
		} as IFilterbarOptions;
	}

	private createOtherFilterbarOptions(): IFilterbarOptions {
		const laOtherTags: { label: string, value: string }[] = [
			{ label: "Acte non défini", value: FacturationService.C_UNDEFINED_ACT },
			{ label: "Observations", value: FacturationService.C_OBSERVATIONS },
			{ label: "Hors nomenclature", value: FacturationService.C_HORS_NOMENCLATURE }
		];
		return {
			id: FacturationService.C_OTHER_FILTER_ID,
			icon: "receipt-outline",
			returnType: EFilterActionReturnType.undefined,
			filterType: EFilterType.tags,
			tags: laOtherTags
		} as IFilterbarOptions;
	}

	public createDatesRangeFilterbarOptions(): IFilterbarOptions {
		return {
			id: FacturationService.C_DATE_RANGE_FILTER_ID,
			icon: "timetable",
			returnType: EFilterActionReturnType.undefined,
			filterType: EFilterType.dateRangePicker
		} as IFilterbarOptions;
	}

	//#endregion

	//#endregion

}
