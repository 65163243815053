import { Component, Input, OnInit } from '@angular/core';
import { ITraitement } from '../../../../../../model/ITraitement';
import { PanneauService } from '../../../services/panneau.service';




@Component({
  selector: 'di-panneau-recherche-actes',
  templateUrl: './panneau-recherche-actes.component.html',
  styleUrls: ['./panneau-recherche-actes.component.scss'],
})
export class PanneauRechercheActesComponent implements OnInit {

	@Input() ordonnance : ITraitement

  constructor(
    private svcPanneau: PanneauService,

  ) { }

  ngOnInit() {

  }

  public close() {
    this.svcPanneau.close();
  }
}