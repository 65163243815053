<ion-grid class="marge-btm-list">

	<!-- TITRE DU TRAITEMENT -->
	<ion-row>
		<ion-col size="12" class="traitement-header">
			<div class="dates">
				<ion-label>Traitement du</ion-label>
				<ion-label>&nbsp;{{ traitement.beginDate | dateWithLocale : 'fr-FR' : shortDateFormat }} au
					{{traitement.endDate | dateWithLocale : 'fr-FR' : shortDateFormat }}</ion-label>
			</div>
			<div class="margin-y">
				<ion-button *ngIf="!traitement.state || traitement.state !== 'terminé'" (click)="traitement.breakDate ? restartTraitement() : stopTraitement()" expand="block"
					color="danger" class="stop-btn">
					{{ traitement.breakDate ? "Annuler l'interruption" : "Interrompre" }}</ion-button>
			</div>
			<div *ngIf="traitement.breakDate">
				<div class="label-break txt-explains">
					<ion-note>Traitement annulé le
						{{traitement.breakDate | dateWithLocale : 'fr-FR' : breakDateDateFormat}}.
					</ion-note>
				</div>
				<div *ngIf="traitement.explanation" class="just-flex flex-col pad-tp explanations">
					<ion-note>Explications</ion-note>
					<div class="txt-explains">{{traitement.explanation}}</div>
				</div>
			</div>
			<div *ngIf="traitement.breakDate; else endsSoon" class="traitement-message traitement-break-message">
				<span class="title">Traitement interrompu</span>
			</div>
			<ng-template #endsSoon>
				<div *ngIf="traitementEndsSoon() && traitement._rev" class="traitement-message traitement-end-message">
					<ion-icon slot="icon-only" name="hourglass-outline"></ion-icon>
					<span class="title">Fin de traitement imminente</span>
				</div>
			</ng-template>
		</ion-col>
	</ion-row>

	<!--PRISE EN CHARGE SPE -->
	<ion-row>
		<ion-label class="bloc-title charges-title">Prises en charge spécifiques</ion-label>

		<div class="tags-selectors">
			<osapp-selector displayMode="tags" [options]="pathologiesOptions" [preselectedValues]="traitement.pathologies"
				scrollWrapper="false" (selectionChanged)="onPathologieChanged($event)" multiple>
			</osapp-selector>

			<osapp-selector displayMode="tags" [options]="tagsOptions" [preselectedValues]="traitement.tags"
				scrollWrapper="false" (selectionChanged)="onTagsChanged($event)">
			</osapp-selector>
		</div>
	</ion-row>

	<!-- ORDONNANCES / ACTES -->
	<div class="add-ordo">
		<ion-button class="ion-no-padding add-ordo-btn" slot="icon-only" shape="round" (click)="openOrdonnance()">
			<ion-icon class="txt24px" name="add"></ion-icon>
		</ion-button>
		<ion-label>Ajouter une ordonnance</ion-label>
	</div>

	<ng-container *ngFor="let item of actesByOrdonnance">
		<!-- Corrige un bug de duplication d'ordo (Bug 12772: L'ajout d'une ordonnance s'affiche seulement quand on change de page) -->
		<ng-container *ngTemplateOutlet="ordo; context: { item: item }"></ng-container>
	</ng-container>

	<ng-template #ordo let-item="item">
		<ion-row class="ordo-bloc">
			<ion-item lines="none" class="ordo-header" [ngClass]="item.actes.length === 0 ? 'bottom-radius' :''"
				color="primary">
				<div class="header-title">
					<ion-label>
						Ordo. {{ item.ordonnance.prescriptionDate | dateWithLocale : 'fr-FR' : shortDateFormat }}
					</ion-label>
					<ion-label *ngIf="item.startDate || item.endDate" class="sub-title">
						<span *ngIf="item.startDate">du {{ item.startDate | dateWithLocale : 'fr-FR' : shortDateFormat }}</span>
						<span *ngIf="item.endDate"> au {{ item.endDate | dateWithLocale : 'fr-FR' : shortDateFormat }}</span>
					</ion-label>
				</div>

				<ion-buttons slot="end">
					<ion-button class="title-btn" fill="clear" (click)="openSearchActePage(item.ordonnance)">
						<div class="action-btn">
							<ion-icon name="add-act"></ion-icon>
							<ion-label>Ajouter</ion-label>
						</div>
					</ion-button>
					<ion-button class="title-btn" fill="clear" (click)="openOrdonnance(item.ordonnance)">
						<div class="action-btn">
							<ion-icon name="see-document"></ion-icon>
							<ion-label>Voir</ion-label>
						</div>
					</ion-button>
					<ion-button class="title-btn" fill="clear" (click)="deleteOrdonnance(item.ordonnance)">
						<div class="action-btn">
							<ion-icon name="trash"></ion-icon>
							<ion-label>Supprimer</ion-label>
						</div>
					</ion-button>
				</ion-buttons>
			</ion-item>

			<ng-container *ngFor="let acte of item?.actes; let i = index;">
				<ng-container
					*ngTemplateOutlet="actItem; context: { acte: acte, radius: i + 1 === item.actes.length ? 'bottom-radius' : '' }">
				</ng-container>
			</ng-container>
		</ion-row>
	</ng-template>

	<ion-row class="ordo-bloc">
		<ion-item lines="none" class="ordo-header without-ordo"
			[ngClass]="actesWithoutOrdonnance.length === 0 ? 'bottom-radius' :''" color="osapp-element">
			<div class="header-title">
				<ion-label>Actes sans ordonnance</ion-label>
			</div>
			<ion-buttons slot="end">
				<ion-button class="title-btn" fill="clear" (click)="openSearchActePage()">
					<div class="action-btn">
						<ion-icon name="add-act"></ion-icon>
						<ion-label>Ajouter</ion-label>
					</div>
				</ion-button>
			</ion-buttons>
		</ion-item>

		<ng-container *ngFor="let acte of actesWithoutOrdonnance; let i = index;">
			<ng-container
				*ngTemplateOutlet="actItem; context: { acte: acte, radius: i + 1 === actesWithoutOrdonnance.length ? 'bottom-radius' : '' }">
			</ng-container>
		</ng-container>
	</ion-row>

	<ng-template #actItem let-acte="acte" let-radius="radius">
		<ion-item-sliding #itemSliding [disabled]="true">
			<ion-item lines="full" [ngClass]="radius" class="acte-item">
				<ion-button class="like-btn" fill="clear" (click)="onFavoritesClicked(acte)">
					<ion-icon [name]="favorites?.ids?.includes(acte._id) ? 'heart' : 'heart-outline'" slot="icon-only">
					</ion-icon>
				</ion-button>
				<div class="ctn-infos-acte dis-flex">
					<div class="ctn-info-item pointer" (click)="editActe(acte)">
						<div class="acte-title">
							<div class="ctn-lettre-cle-coeff ctn-txt-infos-acte">
								<p class="lbl">{{acte.keyLetters}} {{acte.priceCoefficient}}</p>
							</div>
							<div class="ctn-libelle">
								<p class="lbl-wrap">
									{{acte.label}}
								</p>
							</div>
						</div>
					</div>
					<ng-container>
						<div class="detail pointer" (click)="editActe(acte)">
							<ion-label *ngIf="acte.endDate as endDate" class="interuption">
								Intéruption le : {{ endDate | dateWithLocale : 'fr-FR' : shortDateFormat }}
							</ion-label>
							<ion-label class="options">{{acteOptionsByGuid?.get(acte.guid)}}</ion-label>
							<ion-label *ngIf="acte.observations" class="observations">Observations : {{acte.observations}}
							</ion-label>
						</div>
					</ng-container>
				</div>
				<div *ngIf="!isSmallScreen" class="ctn-btn ctn-btn-act ctn-txt-infos-acte">
					<ion-button fill="clear" (click)="linkOrdonnance(acte)">
						<ion-icon slot="icon-only" [name]="!actesWithoutOrdonnance?.includes(acte) ? 'document' : 'add-document'">
						</ion-icon>
					</ion-button>
					<ng-container *ngIf="!acte.endDate">
						<ion-button *ngIf="isvcActes.canChangeActe(acte._id)" (click)="changeActe(acte)" fill="clear">
							<ion-icon slot="icon-only" name="change-act"></ion-icon>
						</ion-button>
						<ion-button fill="clear" (click)="deleteActe(acte)"
							class="calao-btn calao-btn-red-hover calao-btn-transparent col-bottom marg-edit pad-btm-supp">
							<ion-icon slot="icon-only" name="trash" color="danger" class="css-txt12px" aria-hidden="true">
							</ion-icon>
						</ion-button>
					</ng-container>
				</div>
				<div *ngIf="isSmallScreen" class="ctn-btn ctn-txt-infos-acte">
					<ion-button class="btn-more" (click)="openOrCloseItemSliding(itemSliding, $event)" fill="clear">
						<ion-icon slot="icon-only" name="ellipsis-vertical" class="options-btn"></ion-icon>
					</ion-button>
				</div>
			</ion-item>

			<ion-item-options>
				<ion-item-option (click)="linkOrdonnance(acte); openOrCloseItemSliding(itemSliding)" color="primary">
					<ion-icon slot="icon-only" [name]="!actesWithoutOrdonnance?.includes(acte) ? 'document' : 'add-document'">
					</ion-icon>
				</ion-item-option>
				<ng-container *ngIf="!acte.endDate">
					<ion-item-option (click)="changeActe(acte); openOrCloseItemSliding(itemSliding)" color="medium">
						<ion-icon slot="icon-only" name="change-act">
						</ion-icon>
					</ion-item-option>
					<ion-item-option (click)="deleteActe(acte); openOrCloseItemSliding(itemSliding)" color="danger">
						<ion-icon slot="icon-only" name="trash">
						</ion-icon>
					</ion-item-option>
				</ng-container>
			</ion-item-options>
		</ion-item-sliding>
	</ng-template>

	<!-- MODIFICATIONS -->
	<ion-row class="modifications-ctn">
		<mat-accordion *ngIf="constraintItems?.length > 0" class="contraintes-ctn">
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						<ion-label class="bloc-title">Historique des modifications</ion-label>
					</mat-panel-title>
				</mat-expansion-panel-header>

				<cdk-virtual-scroll-viewport [style.height]="'160px'" itemSize="60">
					<ng-container *cdkVirtualFor="let item of constraintItems;">
						<div class="contrainte">
							<div>
								{{ item.label }}
							</div>
							<div class="contrainte-detail">
								<avatar [src]="item.creatorAvatar" cssClass="round"></avatar>
								<span>{{ item.createDate | dateWithLocale: 'fr-FR' : longDateFormat }}</span>
							</div>
						</div>
					</ng-container>
				</cdk-virtual-scroll-viewport>
			</mat-expansion-panel>
		</mat-accordion>
	</ion-row>