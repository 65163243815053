export interface IWorkspace {
	/** Id du workspace pour sa sauvegarde. */
	_id: string;
	/** Nom du workspace. */
	name: string;
	/** Id de l'utilisateur admin du workspace. */
	userId: string;
	/** Liste des bases de données du workspace. */
	databases: Array<string>;
	/** Liste des users ou groupes pouvant afficher le workspace. */
	permissions: Array<string>;
	/** Id du site par défaut du workspace. */
	defaultSiteId?: string;
}