import { coerceArray } from '@angular/cdk/coercion';
import { Injectable } from '@angular/core';
import { NumberHelper } from '@osapp/helpers/numberHelper';
import { ConfigData } from '@osapp/model';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { IDataSource } from '@osapp/model/store/IDataSource';
import { Store } from '@osapp/services/store.service';
import { ArrayHelper, StringHelper } from 'libs/osapp/src/helpers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { C_PREFIX_LC } from '../app/app.constants';
import { TarifHelper } from '../helpers/tarifHelper';
import { EProfession } from '../model/EProfession';
import { ESecteur } from '../model/ESecteur';
import { IDeplacementByProfession } from '../model/IDeplacementByProfession';
import { ITarifLettreCle } from '../model/ITarifLettreCle';
import { Seance } from '../model/Seance';
import { Traitement } from '../model/Traitement';
import { Deplacement } from '../modules/traitement/model/Deplacement';
import { EIndemniteType } from '../modules/traitement/model/EIndemniteType';
import { Indemnite } from '../modules/traitement/model/Indemnite';
import { IdlApplicationService } from './idlApplicationService.service';

@Injectable()
export class IndemniteService {

	//#region FIELDS

	private static readonly C_DISTANCE_ABATTEMENT_PLAINE = 4;
	private static readonly C_DISTANCE_ABATTEMENT_MONTAGNE = 2;
	private static readonly C_DISTANCE_ABATTEMENT_PIED_SKI = 0;
	private static readonly C_DEPLACEMENT_TYPE = "deplacement";
	private static readonly C_ICONE_PLAINE = "plain";
	private static readonly C_ICONE_MONTAGNE = "mountain";
	private static readonly C_ICONE_PIED_SKI = "walk";

	//#endregion

	//#region PROPERTIES

	/** Nombre maximum d'IFI dans une même journée. */
	public static C_MAX_DAILY_IFI = 4;

	//#endregion

	//#region METHODS

	constructor(private isvcApplication: IdlApplicationService, private isvcStore: Store) { }

	/** Récupération du prix total des indemnités d'une séance.
	 * @param poSeance Séance dont on veut récupérer le prix total des indemnités.
	 */
	public getIndemnitesTotalPrice(poSeance: Seance): number {
		return poSeance.indemnites
			.map((poIndemnite: Indemnite) => poIndemnite.disabled ? 0 : poIndemnite.price)
			.reduce((pnPreviousPrice: number, pnCurrentTotal: number) => pnPreviousPrice + pnCurrentTotal, 0);
	}

	/** Calcule et modifie le prix d'une indemnité.
	 * @param poDeplacement Déplacement permettant de calculer le prix de l'indemnité.
	 * @param poIndemnite Indemnité dont il faut calculer le prix.
	 * @param paDeplacementsByProfession Tableau des documents de déplacements par profession dans lequel récupérer celui qui nous intéresse.
	 * @param pnNewPrice Nouveau prix de l'indemnité (mode manuel uniquement).
	 */
	public setIndemnitePrice(poDeplacement: Deplacement, poIndemnite: Indemnite, paDeplacementsByProfession: IDeplacementByProfession[], pnNewPrice?: number, dateDebutSeance?: Date): void {
		let lnPrice = 0;
		const lnDistance = this.calculateAbattementDistance(poDeplacement);

		if (!poDeplacement.isManualPrice) { // Si on est en mode automatique, on détermine le prix de l'IK en fonction du prix du secteur et de la distance d'abattement.
			lnPrice = +NumberHelper.round(lnDistance * poDeplacement.sectorPrice, 2);

			if (poIndemnite.type === EIndemniteType.IFD || poIndemnite.type === EIndemniteType.IFI) {
				const loDeplacement: IDeplacementByProfession = this.getDeplacementByProfession(poIndemnite.type, paDeplacementsByProfession);

				if (loDeplacement)
					lnPrice = this.getDeplacementTarif(loDeplacement, dateDebutSeance);
			}
		}
		else if (NumberHelper.isValidPositive(pnNewPrice)) // Si on est en mode manuel et que le nouveau prix est valide et positif.
			lnPrice = pnNewPrice;

		poIndemnite.price = lnPrice;
	}

	/** Calcule la distance après abattement. */
	public calculateAbattementDistance(poDeplacement: Deplacement): number {
		let lnAbattement: number;

		if (poDeplacement.sectorType === ESecteur.Plaine)
			lnAbattement = IndemniteService.C_DISTANCE_ABATTEMENT_PLAINE;
		else if (poDeplacement.sectorType === ESecteur.Montagne)
			lnAbattement = IndemniteService.C_DISTANCE_ABATTEMENT_MONTAGNE;
		else if (poDeplacement.sectorType === ESecteur.PiedOuSki)
			lnAbattement = IndemniteService.C_DISTANCE_ABATTEMENT_PIED_SKI;

		const lnAbattementDistance: number = poDeplacement.distance - lnAbattement;

		return lnAbattementDistance >= 0 ? lnAbattementDistance : 0;
	}

	/** Récupère les documents de déplacements (avec une vue) par profession.
	 * @param peProfession Profession dont il faut récupérer les déplacements.
	 */
	public getDeplacementsByProfession(peProfession: EProfession): Observable<IDeplacementByProfession[]> {
		const loParams: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.formsEntries),
			viewParams: {
				startkey: C_PREFIX_LC,
				endkey: `${C_PREFIX_LC}${Store.C_ANYTHING_CODE_ASCII}`,
				include_docs: true
			}
		};

		return this.isvcStore.get<IDeplacementByProfession>(loParams)
			.pipe(
				map((paResults: IDeplacementByProfession[]) => {
					return paResults.filter((poItem: IDeplacementByProfession) =>
						poItem.typeLc === IndemniteService.C_DEPLACEMENT_TYPE && poItem.profession.some((peItem: EProfession) => peItem === peProfession)
					);
				})
			);
	}

	/** Récupère l'icône d'un secteur.
	 * @param peSecteur Secteur dont il faut récupérer l'icône.
	 */
	public getIconSector(peSecteur: ESecteur): string {
		if (peSecteur === ESecteur.Plaine)
			return IndemniteService.C_ICONE_PLAINE;
		else if (peSecteur === ESecteur.Montagne)
			return IndemniteService.C_ICONE_MONTAGNE;
		else if (peSecteur === ESecteur.PiedOuSki)
			return IndemniteService.C_ICONE_PIED_SKI;
		else
			return "";
	}

	/** Récupère le type d'une indemnité en fonction du secteur.
	 * @param peSecteur Secteur dont il faut récupérer le type d'indemnité associé.
	 */
	public getIndemniteTypeFromSecteurType(peSecteur: ESecteur): EIndemniteType {
		let leIndemniteType: EIndemniteType;

		if (peSecteur === ESecteur.Montagne)
			leIndemniteType = EIndemniteType.IKM;
		else if (peSecteur === ESecteur.Plaine)
			leIndemniteType = EIndemniteType.IK;
		else
			leIndemniteType = EIndemniteType.IKS;

		return leIndemniteType;
	}

	/**
	 * Récupère l'Id d'une indemnité en fonction du secteur
	 * @param peSecteur Secteur dont il faut récupérer le type d'indemnité associé.
	 */
	public getIndemniteIdFromSecteurType(peSecteur: ESecteur): string {
		switch (this.getIndemniteTypeFromSecteurType(peSecteur)) {
			case EIndemniteType.IFD:
				return `IFD`;
			case EIndemniteType.IFI:
				return `IFI`;
			case EIndemniteType.IKM:
				return `IK-${this.isvcApplication.profession}-2`;
			case EIndemniteType.IK:
				return `IK-${this.isvcApplication.profession}-1`;
			case EIndemniteType.IKS:
				return `IK-${this.isvcApplication.profession}-3`;
			default:
				return `IFD`;
		}
	}

	public getIndemniteTypeFromId(id: string): EIndemniteType | null {
    switch (id) {
        case 'IFD':
            return EIndemniteType.IFD;
        case 'IFI':
            return EIndemniteType.IFI;
        case `IK-${this.isvcApplication.profession}-2`:
            return EIndemniteType.IKM;
        case `IK-${this.isvcApplication.profession}-1`:
            return EIndemniteType.IK;
        case `IK-${this.isvcApplication.profession}-3`:
            return EIndemniteType.IKS;
        default:
            return null;  // Retourner null si l'id ne correspond à aucun type d'indemnité
    }
}


	/** Retourne une nouvelle instance d'indemnité.
	 * @param peType Type de l'indemnité à créer.
	 * @param paDeplacementsByProfession Tableau des documents de déplacements par profession dans lequel récupérer celui qui nous intéresse.
	 * @param pnPrice Prix de l'indemnité, optionnel.
	 */
	public createNewIndemnite(psId: string, peType: EIndemniteType, paDeplacementsByProfession: IDeplacementByProfession[], poDeplacement: Deplacement, dateDebutSeance?: Date): Indemnite {
		const loIndemnite = new Indemnite(
			StringHelper.isBlank(psId) ? this.getDeplacementByProfession(peType, paDeplacementsByProfession)?.id : psId,
			peType,
			this.getDeplacementByProfession(peType, paDeplacementsByProfession)?.description,
			);
		this.setIndemnitePrice(poDeplacement, loIndemnite, paDeplacementsByProfession, poDeplacement.manualPrice, dateDebutSeance);

		return loIndemnite;
	}

	/** Récupère un document de déplacement par profession en fonction d'un type d'indemnité (et de la profession), `undefined` si non trouvé.
	 * @param peIndemniteType Type de l'indemnité.
	 * @param paDeplacementsByProfession Tableau des documents de déplacements par profession dans lequel récupérer celui qui nous intéresse.
	 */
	public getDeplacementByProfession(peIndemniteType: EIndemniteType, paDeplacementsByProfession: IDeplacementByProfession[]): IDeplacementByProfession {
		return paDeplacementsByProfession.find((poItem: IDeplacementByProfession) =>
			this.isIndemniteAgreesWithDeplacementByProfession(peIndemniteType, poItem)
		);
	}

	/** Retourne `true` si le type d'indemnité correspond au déplacement par profession, `false` sinon.
	 * @param peIndemniteType Type de l'indemnité.
	 * @param poDeplacementByProfession Document de déplacement par profession.
	 */
	private isIndemniteAgreesWithDeplacementByProfession(peIndemniteType: EIndemniteType, poDeplacementByProfession: IDeplacementByProfession): boolean {
		const lsSecteur: string = StringHelper.isBlank(poDeplacementByProfession.secteur) ? "" : poDeplacementByProfession.secteur.toLowerCase().trim();
		const lsLettreCle: string = poDeplacementByProfession.lettreCle.toLowerCase();

		return (peIndemniteType === EIndemniteType.IFD && lsLettreCle === "ifd") ||
			(peIndemniteType === EIndemniteType.IFI && lsLettreCle === "ifi") ||
			(peIndemniteType === EIndemniteType.IK && lsSecteur === "plaine") ||
			(peIndemniteType === EIndemniteType.IKM && lsSecteur === "montagne") ||
			(peIndemniteType === EIndemniteType.IKS && lsSecteur === "pied-ski");
	}

	/** Récupère le prix d'un déplacement (en fonction de la zone géographique). */
	public getDeplacementTarif(poDeplacement: IDeplacementByProfession, dateSeance?: Date): number {
		//à partir du 28/01/2024 le tarif de certaine lettre clé change (cf. avenant 10)
		//On regarde le type du tarif
		if (ArrayHelper.hasElements(poDeplacement.tarif)) {
			//si c'est un tableau de nombre = ancien format
			return poDeplacement.tarif[ConfigData.geoZoneApp - 1];
		} else if (ArrayHelper.hasElements(poDeplacement.tarifs) && dateSeance) {
			//si c'est un tableau d'objet de type ITarifLettreCle = nouveau format (après avenant 10)
			const tarifIndemnite: ITarifLettreCle = poDeplacement.tarifs.find((tar: ITarifLettreCle) => {
				return TarifHelper.getFiltreTarifLettreCleParDate(tar, dateSeance);
			});
			return tarifIndemnite ? tarifIndemnite.montant : 0;
		}
		return 0;
	}

	
	public initIkIndemnite(poTraitement: Traitement, paSeances: Seance[], paDeplacementsByProfession: IDeplacementByProfession[]): void {
		let loIKIndemnite: Indemnite;
		for (let lnIndex = 0; lnIndex < paSeances.length; ++lnIndex) {
			loIKIndemnite = paSeances[lnIndex].indemnites.find((poIndemnite: Indemnite) => poIndemnite.isIKType);
			if (loIKIndemnite)
				break;
		}

		if (!loIKIndemnite) { // On s'assure que la propriété soit bien initialisée.
			loIKIndemnite = this.createNewIndemnite(
				this.getIndemniteIdFromSecteurType(poTraitement.deplacement.sectorType),
				EIndemniteType.IK,
				paDeplacementsByProfession,
				poTraitement.deplacement
			);
		}
		else
			loIKIndemnite = Indemnite.getInstanceFromData(loIKIndemnite);


		// On ajuste le prix de l'indemnité.
		this.setIndemnitePrice(poTraitement.deplacement, loIKIndemnite, paDeplacementsByProfession);
	}

	/** Retourne `true` si le type d'indemnité est déjà présent dans le tableau et qu'elle n'est pas désactivée, `false` sinon.
	 * @param paIndemnites Tableau des indemnités dans lequel vérifier si un certain type d'indemnité est présent.
	 * @param poIndemniteType Type d'indemnité dont il faut vérifier la présence dans un tableau.
	 */
	public isIndemniteEnabled(paIndemnites: Indemnite[], poIndemniteType: EIndemniteType | EIndemniteType[]): boolean {
		return paIndemnites.some((poItem: Indemnite) => coerceArray(poIndemniteType).includes(poItem.type) && !poItem.disabled);
	}

	/** Retourne `true` si le type d'indemnité est déjà présent dans le tableau, `false` sinon.
	 * @param paIndemnites Tableau des indemnités dans lequel vérifier si un certain type d'indemnité est présent.
	 * @param peIndemniteType Type d'indemnité dont il faut vérifier la présence dans un tableau.
	 */
	public hasIndemnite(paIndemnites: Indemnite[], peIndemniteType: EIndemniteType): boolean {
		return paIndemnites.some((poItem: Indemnite) => poItem.type === peIndemniteType);
	}

	/** Retourne `true` si la séance en paramètre contient une indemnité de type IK, `false` sinon. */
	public hasIKIndemnite(poSeance: Seance): boolean {
		return poSeance.indemnites.some((poItem: Indemnite) => poItem.isIKType);
	}

	//#endregion
}