<div class="page-ordonnances__container">
	<div class="page-ordonnances__header">
		<div class="page-ordonnances__header-titre-btn">
			<div class="page-ordonnances__titre">
				<h1>Ordonnances</h1>
				<small>{{ordoActiveCountMessage}}</small>
			</div>
			<div class="page-ordonnances__btn-add-ordonance">
				<lua-action-secondaire *ngIf="!isMobileView" [avecIcone]="true" libelle="Ordonnance" nomIcone="add"
					[fullWidth]="false" positionIcone="L" (click)="handleClickAddOrdonnance($event)"></lua-action-secondaire>
			</div>
			<lua-bouton-floating *ngIf="isMobileView" class="btn-ajout-contact-mobile" bottom="40px" right="40px"
				iconName="add" (click)="handleClickAddOrdonnance($event)">
			</lua-bouton-floating>
		</div>
		<div class="page-ordonnances__filters">
			<lua-recherche [searchText]="searchedValue" label="Rechercher" (onValueChange)="filterOrdonnances($event)"
				placeholder="Rechercher">
			</lua-recherche>
			<lua-filtre-groupe [filters]="ordonnancefilters" (filterSelected)="changeFiltre($event)"
				[defaultFilter]="defaultFilter"></lua-filtre-groupe>
		</div>
	</div>
	<div class="page-ordonnances__body">
		<ng-container *ngIf="showOrdonnanceList()">
			<ng-container *ngFor="let ordonnance of filteredOrdonnance; let i = index">
				<di-carte-ordonnance [ordonnance]="ordonnance" tagColor="Info" progressBarColor="Indigo"
					buttonIconName="more_vert">
				</di-carte-ordonnance>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="!showOrdonnanceList()">
			<lua-message-aucune-donnee (onClick)="handleClickAddOrdonnance($event)" mainText="Aucune ordonnance"
				iconName="contract" [subText]="sousTexteAucuneOrdonnance"
				buttonLabel="Nouvelle ordonnance"></lua-message-aucune-donnee>
		</ng-container>
	</div>
</div>