import { coerceArray } from '@angular/cdk/coercion';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { StoreDocumentHelper } from '@osapp/helpers/storeDocumentHelper';
import { StoreHelper } from '@osapp/helpers/storeHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { ConfigData } from '@osapp/model/config/ConfigData';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { LogAction } from '@osapp/modules/logger/decorators/log-action.decorator';
import { ILogSource } from '@osapp/modules/logger/models/ILogSource';
import { LogActionHandler } from '@osapp/modules/logger/models/log-action-handler';
import { LoggerService } from '@osapp/modules/logger/services/logger.service';
import { OsappApiHelper } from '@osapp/modules/osapp-api/helpers/osapp-api.helper';
import { CanExecute, IHasPermission, PermissionsService } from '@osapp/modules/permissions/services/permissions.service';
import { Prestation } from '@osapp/modules/prestation/models/prestation';
import { ShowMessageParamsToast } from '@osapp/services/interfaces/ShowMessageParamsToast';
import { Store } from '@osapp/services/store.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { WorkspaceService } from '@osapp/services/workspace.service';
import { EMPTY, Observable, from, of, throwError } from 'rxjs';
import { catchError, concatMap, filter, finalize, map, mapTo, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { C_DESMOS_PERMISSION_ID } from '../../../app/app.constants';
import { IdlApiHelper } from '../../../helpers/idl-api.helper';
import { IPrestationCacheData } from '../../facturation/models/iprestation-cache-data';
import { EIdlLogActionId } from '../../logger/models/EIdlLogActionId';
import { TerminalInfoModalOpenerService } from '../../olaqin/components/terminal-info-modal/services/terminal-info-modal-opener.service';
import { ITerminalInfo } from '../../olaqin/models/iterminal-info';
import { OlaqinService } from '../../olaqin/services/olaqin.service';
import { EExportResult } from '../model/EExportResult';
import { ExportError } from '../model/ExportError';
import { IExportPrestationResult } from '../model/IExportPrestationResult';
import { IPatient } from '../model/IPatient';
import { AMCP } from '../model/amc-p';
import { AMOP } from '../model/amo-p';
import { EPatientDataSource } from '../model/epatient-data-source.enum';
import { CouverturesService } from './couvertures.service';
import { DrawerPopoverService } from 'apps/idl/src/anakin/features/shared/services/drawer-popover.service';
import { LectureTerminalComponent } from 'apps/idl/src/anakin/features/shared/components/lecture-terminal/lecture-terminal.component';

@Injectable()
export class ExportService implements ILogSource, IHasPermission {

	//#region FIELDS

	public static readonly C_EXPORT_PATIENT_ERROR_MESSAGE = "Un problème est survenu lors de l'export du patient.";
	public static readonly C_ADRI_PATIENT_ERROR_MESSAGE = "Un problème est survenu lors de l'appel ADRi du patient.";
	public static readonly C_EXPORT_ACTES_ERROR_MESSAGE = "Un problème est survenu lors de l'export des actes.";

	/** Indique si une requête d'export patient est en cours ou non (pour éviter le spam de requêtes). */
	private mbIsRequestingExportPatient = false;
	/** Indique si une requête d'export des actes est en cours ou non (pour éviter le spam de requêtes). */
	private mbIsRequestingExportActes = false;

	//#endregion

	//#region PROPERTIES

	/** @implements */
	public readonly logSourceId = "EXP.S::";
	/** @implements */
	public readonly logActionHandler = new LogActionHandler(this);

	public get canBillToFsv(): boolean {
		return true;
	}

	public get exportSuffixUrl(): string {
		return `/export?to=${this.canBillToFsv ? "fsv" : C_DESMOS_PERMISSION_ID}`;
	}


	//#endregion

	//#region METHODS

	constructor(
		private readonly ioHttp: HttpClient,
		private readonly isvcWorkspace: WorkspaceService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcOlaqin: OlaqinService,
		private readonly isvcCouvertures: CouverturesService,
		private readonly isvcTerminalInfoModalOpener: TerminalInfoModalOpenerService,
		private readonly isvcStore: Store,
		/** @implements */
		public readonly isvcLogger: LoggerService,
		public readonly isvcPermissions: PermissionsService,
		public svcDrawerPopover: DrawerPopoverService
	) { }

	private getExportBaseUrl(): string {
		return `${ConfigData.environment.cloud_url}/api/apps/${ConfigData.appInfo.appId}/workspaces/${ArrayHelper.getFirstElement(this.isvcWorkspace.getUserWorkspaceIds())}/entities/patients`;
	}

	private getUpdatePatientUrl(poModel: IPatient): string {
		return `${ConfigData.environment.cloud_url}/api/apps/${ConfigData.appInfo.appId}/workspaces/${ArrayHelper.getFirstElement(this.isvcWorkspace.getUserWorkspaceIds())}/entities/patients/${poModel._id}?systemId=${C_DESMOS_PERMISSION_ID}`;
	}

	private getHttpOptions(): { headers: HttpHeaders, observe: "response" } {
		return {
			headers: new HttpHeaders({
				appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
				token: ConfigData.authentication.token,
				"api-key": ConfigData.environment.API_KEY,
			}),
			observe: "response"
		};
	}

	/** Exporte un patient vers le logiciel partenaire.
	 * @param poPatient Patient à exporter.
	 * @returns un booléen indiquant si l'export à réussi ou non ; en cas d'erreur, une instance de `ExportError` ou une erreur générique.
	 */
	public exportPatient(poPatient: IPatient): Observable<EExportResult> {
		let loExport$: Observable<EExportResult | never>;

		if (this.mbIsRequestingExportPatient) {
			this.showAlreadyExportingToast("Un export patient est en cours, veuillez patienter ...");
			loExport$ = EMPTY;
		}
		else {
			this.mbIsRequestingExportPatient = true; // Blocage des possibles futures requêtes tant que celle-ci n'est pas terminée.

			let loRequest$: Observable<HttpResponse<IPatient> | HttpResponse<IPatient[]>>;

			if (this.canBillToFsv) {
				if (StringHelper.isBlank(poPatient.externalId))
					loRequest$ = this.ioHttp.post<IPatient[]>(`${this.getExportBaseUrl()}${this.exportSuffixUrl}`, [{ id: poPatient._id, rev: poPatient._rev }], this.getHttpOptions())
						.pipe(
							mergeMap((poResponse: HttpResponse<IPatient[]>) => this.updatePatientWithADRiOrVitale(ArrayHelper.getFirstElement(poResponse.body), EPatientDataSource.ADRi))
						);
				else
					loRequest$ = this.updatePatientWithADRiOrVitale(poPatient, EPatientDataSource.ADRi);
			}
			else {
				if (StringHelper.isBlank(poPatient.externalId)) { // Si pas d'identifiant externe, alors export.
					loRequest$ = this.ioHttp.post<IPatient[]>(
						`${this.getExportBaseUrl()}${this.exportSuffixUrl}`,
						[{ id: poPatient._id, rev: poPatient._rev }],
						this.getHttpOptions()
					) as Observable<HttpResponse<IPatient[]>>;
				}
				else // Sinon, mise à jour du patient.
					loRequest$ = this.ioHttp.put(this.getUpdatePatientUrl(poPatient), StoreHelper.getCleanedDocument(poPatient), this.getHttpOptions()) as Observable<HttpResponse<IPatient>>;
			}

			loExport$ = loRequest$
				.pipe(
					catchError((poError: HttpErrorResponse) => this.throwExportErrorMessage(poError, ExportService.C_EXPORT_PATIENT_ERROR_MESSAGE)),
					mergeMap((poResponse: HttpResponse<IPatient | IPatient[]>) => this.updatePatientFromExport(poPatient, poResponse)),
					finalize(() => this.mbIsRequestingExportPatient = false)
				);
		}

		return loExport$;
	}

	public exportPatientAnakin(poPatient: IPatient, anchorPopover: any): Observable<EExportResult> {
		let loExport$: Observable<EExportResult | never>;

		if (this.mbIsRequestingExportPatient) {
			console.log("Un export patient est en cours, veuillez patienter ...");
			loExport$ = EMPTY;
		}
		else {
			this.mbIsRequestingExportPatient = true; // Blocage des possibles futures requêtes tant que celle-ci n'est pas terminée.

			let loRequest$: Observable<HttpResponse<IPatient> | HttpResponse<IPatient[]>>;

			if (this.canBillToFsv) {
				if (StringHelper.isBlank(poPatient.externalId))
					loRequest$ = this.ioHttp.post<IPatient[]>(`${this.getExportBaseUrl()}${this.exportSuffixUrl}`, [{ id: poPatient._id, rev: poPatient._rev }], this.getHttpOptions())
						.pipe(
							mergeMap((poResponse: HttpResponse<IPatient[]>) => this.updatePatientWithADRiOrVitaleAnakin(ArrayHelper.getFirstElement(poResponse.body), EPatientDataSource.ADRi, anchorPopover))
						);
				else
					loRequest$ = this.updatePatientWithADRiOrVitaleAnakin(poPatient, EPatientDataSource.ADRi, anchorPopover);
			}
			else {
				if (StringHelper.isBlank(poPatient.externalId)) { // Si pas d'identifiant externe, alors export.
					loRequest$ = this.ioHttp.post<IPatient[]>(
						`${this.getExportBaseUrl()}${this.exportSuffixUrl}`,
						[{ id: poPatient._id, rev: poPatient._rev }],
						this.getHttpOptions()
					) as Observable<HttpResponse<IPatient[]>>;
				}
				else // Sinon, mise à jour du patient.
					loRequest$ = this.ioHttp.put(this.getUpdatePatientUrl(poPatient), StoreHelper.getCleanedDocument(poPatient), this.getHttpOptions()) as Observable<HttpResponse<IPatient>>;
			}

			loExport$ = loRequest$
				.pipe(
					take(1),
					catchError((poError: HttpErrorResponse) => this.throwExportErrorMessage(poError, ExportService.C_EXPORT_PATIENT_ERROR_MESSAGE)),
					mergeMap((poResponse: HttpResponse<IPatient | IPatient[]>) => this.updatePatientFromExport(poPatient, poResponse)),
					finalize(() => this.mbIsRequestingExportPatient = false)
				);
		}

		return loExport$;
	}

	public exportPatientXml(poPatient: IPatient, psXmlBase64: string): Observable<IPatient> {
		return this.ioHttp.post<string[]>(`${this.getExportBaseUrl()}${this.exportSuffixUrl}`, [psXmlBase64, ...(poPatient?._id ? [poPatient._id] : [])], this.getHttpOptions())
			.pipe(
				map((poResponse: HttpResponse<string[]>) => {
					const lsExternalId: string = ArrayHelper.getFirstElement(poResponse.body);
					if (!StringHelper.isBlank(lsExternalId))
						poPatient.externalId = lsExternalId;
					return poPatient;
				})
			);
	}

	private updatePatientWithADRiOrVitale(poPatient: IPatient, peDataSource: EPatientDataSource): Observable<HttpResponse<IPatient>> {
		let lsTerminalId: string;

		return this.isvcTerminalInfoModalOpener.open({ title: "Lecture carte CPS", bypassVitale: true })
			.pipe(
				filter((poTerminal: ITerminalInfo) => !!poTerminal),
				tap((poTerminal: ITerminalInfo) => lsTerminalId = poTerminal.terminalId),
				mergeMap(() => this.isvcCouvertures.getPatientSortedCouvertures(poPatient._id)),
				tap((paCouvertures: { AMOPs: AMOP[]; AMCPs: AMCP[]; }) => {
					poPatient.AMO = paCouvertures.AMOPs.map((poAMOP: AMOP) => StoreHelper.getCleanedDocument(poAMOP));
					poPatient.AMC = paCouvertures.AMCPs.map((poAMCP: AMCP) => StoreHelper.getCleanedDocument(poAMCP));
				}),
				mergeMap(() => this.isvcOlaqin.updatePatientWithADRiOrVitale(poPatient, lsTerminalId, peDataSource)),
			);
	}

	private updatePatientWithADRiOrVitaleAnakin(poPatient: IPatient, peDataSource: EPatientDataSource, anchorPopover: any): Observable<HttpResponse<IPatient>> {
		let lsTerminalId: string;
		// On se connecte au terminal et on lit la carte CPS
		this.svcDrawerPopover.open("Lecture carte CPS", "200px", anchorPopover, LectureTerminalComponent, { adri: true });

		// Quand le terminal et la carte sont lus alors on fait l'appel ADRI
		return this.svcDrawerPopover.closePopover$
			.pipe(
				switchMap(() => this.isvcOlaqin.getLastUsedTerminalId()),
				tap((terminalId: string) => lsTerminalId = terminalId.replace("term_", "")),
				switchMap(() => this.isvcCouvertures.getPatientSortedCouvertures(poPatient._id)),
				tap((paCouvertures: { AMOPs: AMOP[]; AMCPs: AMCP[] }) => {
					poPatient.AMO = paCouvertures.AMOPs.map((poAMOP: AMOP) => StoreHelper.getCleanedDocument(poAMOP));
					poPatient.AMC = paCouvertures.AMCPs.map((poAMCP: AMCP) => StoreHelper.getCleanedDocument(poAMCP));
				}),
				switchMap(() => this.isvcOlaqin.updatePatientWithADRiOrVitaleAnakin(poPatient, lsTerminalId, peDataSource))
			);
	}

	/** Vérifie que le patient a été exporté correctement et le met à jour avec celui retournée par l'API.
	 * (mise à jour du modèle mais pas en base de données (la réplication s'en chargera)).
	 * @param poPatient Patient qu'on a voulu exporter.
	 * @param poResponse Réponse de l'API qui contient le nouveau patient.
	 * @returns `true` si le patient a bien été exporté, `false` sinon.
	 */
	private async updatePatientFromExport(poPatient: IPatient, poResponse: HttpResponse<IPatient | IPatient[]>): Promise<EExportResult> {
		// Si le statut n'est pas "created", ou si le tableau de résultats est vide, l'export n'a pas réussi.
		let leResult: EExportResult;

		if (poResponse.status === 201 || poResponse.status === 200) { // Export ou mise à jour réussi.
			let loPatient: IPatient;
			if (poResponse.body instanceof Array) { // Si le corps de la réponse est un tableau alors c'est un export.
				const laExportedPatients: IPatient[] = poResponse.body;

				if (ArrayHelper.hasElements(laExportedPatients)) {
					loPatient = laExportedPatients.find((poExportedPatient: IPatient) => poExportedPatient._id === poPatient._id);
					leResult = EExportResult.patientSuccess;

					if (loPatient) { // Confirmation de l'export réussi.
						Object.assign(poPatient, loPatient);
						leResult = EExportResult.patientSuccess;
					}
				}
			}
			else { // Sinon, c'est une mise à jour.
				leResult = EExportResult.patientUpdated;
				loPatient = poResponse.body;
			}

			const laCouvertures = [];
			if (ArrayHelper.hasElements(loPatient.AMO))
				laCouvertures.push(...loPatient.AMO);
			if (ArrayHelper.hasElements(loPatient.AMC))
				laCouvertures.push(...loPatient.AMC);

			if (ArrayHelper.hasElements(laCouvertures)) {
				await this.isvcStore.syncDocumentsToLocal(
					ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace)),
					laCouvertures
				);
			}
		}

		if (!leResult)
			leResult = EExportResult.patientFailed;

		return leResult;
	}

	/** Exporte la prestation.
	 * @param poPrestation
	 * @returns un booléen indiquant si l'export à réussi ou non ; en cas d'erreur, une instance de `ExportError` ou une erreur générique.
	 */
	public exportPrestations(poPrestation: Prestation): Observable<IExportPrestationResult>;
	/** Exporte les prestations.
	 * @param paPrestations
	 * @returns un booléen indiquant si l'export à réussi ou non ; en cas d'erreur, une instance de `ExportError` ou une erreur générique.
	*/
	public exportPrestations(paPrestations: Prestation[]): Observable<IExportPrestationResult>;
	@LogAction<[Prestation | Prestation[]], ReturnType<ExportService["exportPrestations"]>>({
		actionId: EIdlLogActionId.prestationExport,
		successMessage: "Export des prestations.",
		errorMessage: "Erreur lors de l'export des prestations.",
		dataBuilder: (_, __, poPrestationOrPrestations: Prestation | Prestation[]) =>
			({ prestations: coerceArray(poPrestationOrPrestations).map((poPrestation: Prestation) => StoreDocumentHelper.excludeDocContent(poPrestation)) })
	})
	@CanExecute("create", C_DESMOS_PERMISSION_ID)
	public exportPrestations(poPrestationOrPrestations: Prestation | Prestation[]): Observable<IExportPrestationResult> {
		if (this.mbIsRequestingExportActes) {
			this.showAlreadyExportingToast("Un export de prestations est en cours, veuillez patienter ...");
			return EMPTY;
		}
		else {
			this.mbIsRequestingExportActes = true; // Blocage des possibles futures requêtes tant que celle-ci n'est pas terminée.

			const laExportPrestations: Prestation[] = poPrestationOrPrestations instanceof Array ? poPrestationOrPrestations : [poPrestationOrPrestations];
			const loResult: IExportPrestationResult = this.createExportPrestationResult(laExportPrestations.length);

			return from(laExportPrestations)
				.pipe(
					concatMap((poPrestation: Prestation) => this.innerExportPrestations(poPrestation, loResult)),
					finalize(() => this.mbIsRequestingExportActes = false)
				);
		}
	}

	/** Crée et retourne un nouvel objet de résultat pour l'export de prestations. */
	private createExportPrestationResult(pnTotalPrestationToExport: number): IExportPrestationResult {
		return {
			prestationId: "",
			successIds: [],
			failIds: [],
			totalCount: pnTotalPrestationToExport
		} as IExportPrestationResult;
	}

	private innerExportPrestations(poPrestation: Prestation, poResult: IExportPrestationResult) {
		const lsUrl = `${this.getExportBaseUrl()}/${poPrestation.customerId}/actes${this.exportSuffixUrl}`;
		poResult.prestationId = poPrestation._id;

		return from(this.ioHttp.post(lsUrl, [poPrestation._id], this.getHttpOptions()))
			.pipe(
				tap((poResponse: HttpResponse<Prestation[]>) => {
					// Mise à jour de la séance exportée.
					this.updatePrestationsFromExport(poPrestation, poResponse);
					// Mise à jour du tableau des identifiants de séance qui ont été exportés.
					poResult.successIds.push(poPrestation._id);
				}),
				catchError((poError: HttpErrorResponse) => {
					// Ajout du message d'erreur d'export de la séance dans ses cacheData.
					const loPrestationCacheData: IPrestationCacheData = StoreHelper.getDocumentCacheData(poPrestation) || {};
					loPrestationCacheData.exportErrorMessage = IdlApiHelper.getExportErrorMessage(poError, ExportService.C_EXPORT_ACTES_ERROR_MESSAGE);
					StoreHelper.updateDocumentCacheData(poPrestation, loPrestationCacheData);
					// Mise à jour du tableau des identifiants de séance qui n'ont pas été exportés.
					poResult.failIds.push(poPrestation._id);
					return of(undefined);
				}),
				mapTo(poResult) // Retour du résultat.
			);
	}

	/** Vérifie que la prestation a été exportée correctement et la met à jour avec celle retournée par l'API.
	 * (mise à jour du modèle mais pas en base de données (la réplication s'en chargera)).
	 * @param poPrestation Facture qu'on a voulu exporter.
	 * @param poResponse Réponse de l'API qui contient la nouvelle prestation.
	 */
	private updatePrestationsFromExport(poPrestation: Prestation, poResponse: HttpResponse<Prestation[]>): void {
		if (poResponse.status === 201 || poResponse.status === 206) { // Export réussi ou export partiel.
			const laPrestationResults: Prestation[] = poResponse.body;

			if (ArrayHelper.hasElements(laPrestationResults)) {
				const loExportedPrestation: Prestation = ArrayHelper.getFirstElement(laPrestationResults);
				ObjectHelper.assign(poPrestation, loExportedPrestation); // On met à jour la prestation courante pour le marqueur notamment.
			}
			else
				console.error(`EXP.DEMOS.S:: Export de la prestation '${poPrestation._id}' réussi mais aucun élément dans la réponse :`, poResponse);
		}
		else
			console.warn(`EXP.DEMOS.S:: Code de retour de l'export de la prestation '${poPrestation._id}' : ${poResponse.status}.`);
	}

	/** Crée et lève un message d'erreur d'export.
	 * @param poError Erreur survenue.
	 * @param psDefaultMessage Message par défaut à récupérer.
	 */
	private throwExportErrorMessage(poError: HttpErrorResponse, psDefaultMessage: string): Observable<never> {
		return throwError(new ExportError(IdlApiHelper.getExportErrorMessage(poError, psDefaultMessage)));
	}

	/** Affiche un toast pour signaler à l'utilisateur qu'un export est déjà en cours.
	 * @param psMessage Message à afficher dans le toast.
	 */
	private showAlreadyExportingToast(psMessage: string): void {
		this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: psMessage }));
	}

	/** Récupère le message d'échec de l'export d'une prestation, chaîne vide si pas de message.
	 * @param poPrestation Facture dont il récupérer le message d'échec d'export.
	 */
	public getPrestationExportFailedMessage(poPrestation: Prestation): string {
		return (StoreHelper.getDocumentCacheData(poPrestation) as IPrestationCacheData)?.exportErrorMessage || "";
	}

	//#endregion
}