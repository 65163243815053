import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { Navigation, Router } from '@angular/router';
import { IonItemSliding } from '@ionic/angular';
import { AlertButton } from '@ionic/core';
import { DynamicPageComponent } from '@osapp/components/dynamicPage/dynamicPage.component';
import { ComponentBase, IdHelper, NumberHelper, ObjectHelper, StringHelper } from '@osapp/helpers';
import { EPrefix } from '@osapp/model/EPrefix';
import { ESortOrder } from '@osapp/model/ESortOrder';
import { ListComponentBase2 } from '@osapp/model/ListComponentBase2';
import { EBarElementDock } from '@osapp/model/barElement/EBarElementDock';
import { EBarElementPosition } from '@osapp/model/barElement/EBarElementPosition';
import { IBarElement } from '@osapp/model/barElement/IBarElement';
import { IAvatar } from '@osapp/model/picture/IAvatar';
import { ISearchOptions } from '@osapp/model/search/ISearchOptions';
import { IUiResponse } from '@osapp/model/uiMessage/IUiResponse';
import { ContactsService } from '@osapp/services/contacts.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { C_CONSTANTES_PREFIX, C_INJECTIONS_PREFIX } from 'apps/idl/src/app/app.constants';
import { Observable, ReplaySubject, Subject, of } from 'rxjs';
import { catchError, mapTo, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IPatient } from '../../../model/IPatient';
import { ESurveilancesType } from '../../../model/constantes/ESurveillancesType';
import { IConstantes } from '../../../model/constantes/IConstantes';
import { IConstantesListParams } from '../../../model/constantes/IConstantesListParams';
import { IConstantesModalParams } from '../../../model/constantes/IConstantesModalParams';
import { IInjection, IInjections } from '../../../model/constantes/IInjections';
import { ISurveillances } from '../../../model/constantes/ISurveillances';
import { ConstantesService } from '../../../services/constantes.service';
import { PatientsService } from '../../../services/patients.service';
import { ConstantesModalComponent } from '../constantes-modal/constantes-modal.component';

@Component({
	selector: 'idl-surveillances-list',
	templateUrl: './surveillances-list.component.html',
	styleUrls: ['./surveillances-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveillancesListComponent extends ListComponentBase2<ISurveillances> implements OnInit, OnDestroy {

	//#region FIELDS

	private moWaitingParamsSubject = new ReplaySubject<void>(1);
	private moUpdatingFilterSubject = new Subject<EPrefix>();

	//#endregion

	//#region PROPERTIES

	private moParams: IConstantesListParams;
	public get params(): IConstantesListParams {
		return this.moParams;
	}
	@Input() public set params(poValue: IConstantesListParams) {
		if (poValue && this.moParams !== poValue) {
			this.moParams = poValue;
			this.moWaitingParamsSubject.next();
		}
	}

	private meFilterPrefix: EPrefix;
	public get filterPrefix(): EPrefix {
		return this.meFilterPrefix;
	}
	@Input() public set filterPrefix(peValue: EPrefix) {
		this.meFilterPrefix = peValue;
		this.moUpdatingFilterSubject.next(this.meFilterPrefix);
	}

	/** Map des avatars des auteurs des surveillances, indexés par chemin de l'auteur de l'objet surveillances. */
	public avatarsByAuthorPathMap = new Map<string, IAvatar>();
	/** Map des patients, indexés par identifiant de surveillances. */
	public patientBySurveillanceIds = new Map<string, IPatient>();
	/** Indique si toutes les surveillances sont listées ou non. */
	public isListingAllSurveillances: boolean;

	//#endregion

	//#region METHODS

	constructor(
		private isvcConstantes: ConstantesService,
		private isvcPatients: PatientsService,
		private ioRouter: Router,
		private isvcUiMessage: UiMessageService,
		private isvcContacts: ContactsService,
		@Optional() private moParentComponent: DynamicPageComponent<ComponentBase>,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poChangeDetectorRef);
	}

	public ngOnInit(): void {
		this.searchOptions = this.createSearchOptions();
		this.sortKey = ConstantesService.C_CREATED_DATE_PROPERTY;
		this.meSortOrder = ESortOrder.descending;

		this.moWaitingParamsSubject.asObservable()
			.pipe(
				mergeMap(_ => this.init()),
				mergeMap(() => this.moUpdatingFilterSubject.asObservable()),
				tap((peFilterprefix: EPrefix) => {
					if (peFilterprefix)
						this.onFilteredDocumentsChanged(this.documents.filter((poSurveillances: ISurveillances) => peFilterprefix === IdHelper.getPrefixFromId(poSurveillances._id)));
					else
						this.onFilteredDocumentsChanged(this.documents);

					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		const loNavigation: Navigation = this.ioRouter.getCurrentNavigation();
		if ((loNavigation?.extras?.state as IConstantesListParams)?.allConstantes)
			this.isListingAllSurveillances = (loNavigation.extras.state as IConstantesListParams).allConstantes;

		// Si on veut lister toutes les surveillances on n'est pas censé avoir de `params` donc on initialise le champs pour ne pas avoir d'erreur `undefined`.
		if (this.isListingAllSurveillances && !this.params)
			this.params = {};
	}

	public ngOnDestroy(): void {
		this.moWaitingParamsSubject.complete();
		this.moUpdatingFilterSubject.complete();

		if (this.params?.useToolbar)
			this.moParentComponent?.toolbar.clear(this.msInstanceId);

		super.ngOnDestroy();
	}

	private init(): Observable<boolean> {
		return this.isvcConstantes.getSurveillances(this.params.model)
			.pipe(
				tap(() => {
					if (this.params?.useToolbar) // Si on a le paramètre de renseigner, c'est qu'il faut utiliser la toolbar et pas un bouton ionic.
						this.manageToolbar();
				}),
				switchMap((paResults: ISurveillances[]) => this.initDocuments(paResults)),
				switchMap((paSurveillances: ISurveillances[]) =>
					this.isvcContacts.getAvatarsByContactPaths(paSurveillances.map((poSurveillances: ISurveillances) => poSurveillances.authorPath))
				),
				tap((poAvatarMapResult: Map<string, IAvatar>) => {
					this.avatarsByAuthorPathMap = poAvatarMapResult;
					this.detectChanges();
				}),
				mapTo(true),
				catchError(poError => {
					console.error("SURV.LIST.C:: ", poError);
					return of(false);
				})
			) as Observable<boolean>;
	}

	private initDocuments(paDocuments: ISurveillances[]): Observable<ISurveillances[]> {
		let loInit$: Observable<ISurveillances[]>;

		if (!this.params.model) {
			loInit$ = this.isvcConstantes.getPatientBySurveillanceIds(paDocuments)
				.pipe(
					tap((poPatientBySurveillanceIds: Map<string, IPatient>) => this.patientBySurveillanceIds = poPatientBySurveillanceIds),
					mapTo(paDocuments)
				);
		}
		else {
			loInit$ = of(paDocuments)
				.pipe(tap((paResults: ISurveillances[]) => paResults.forEach((poSurveillances: ISurveillances) => this.patientBySurveillanceIds.set(poSurveillances._id, this.params.model))));
		}

		return loInit$.pipe(tap((paResults: ISurveillances[]) => {
			this.documents = paResults;
			this.moUpdatingFilterSubject.next(this.meFilterPrefix);
		}));
	}

	/** @override */
	protected createSearchOptions(): ISearchOptions<ISurveillances> {
		return {
			hasPreFillData: true,
			searchboxPlaceholder: "Rechercher une surveillance",
			searchFunction: (poSurveillances: ISurveillances, psSearchValue: string) => this.surveillancesSearch(poSurveillances, psSearchValue)
		};
	}

	/** Recherche la correspondance de la recherche avec un objet surveillances.
	 * @param poSurveillances Objet surveillances dans la quelle rechercher une correspondance avec la valeur.
	 * @param psSearchValue Valeur recherchée.
	 */
	private surveillancesSearch(poSurveillances: ISurveillances, psSearchValue: string): boolean {
		const lsSearchValue: string = psSearchValue.toLowerCase();

		return this.checkSearchValueInSurveillances(poSurveillances, lsSearchValue) ||
			this.checkSearchValueInPatient(lsSearchValue, this.patientBySurveillanceIds.get(poSurveillances._id));
	}

	/** Vérifie la correspondance d'un objet surveillances avec la valeur recherchée.
	 * @param poSurveillances Objet surveillances dont il faut vérifier la correspondance avec la valeur recherchée.
	 * @param psSearchValue Valeur recherchée.
	 */
	private checkSearchValueInSurveillances(poSurveillances: ISurveillances, psSearchValue: string): boolean {
		let loConstantes: IConstantes;
		let loInjections: IInjections;

		switch (IdHelper.getPrefixFromId(poSurveillances._id)) {
			case C_CONSTANTES_PREFIX:
				loConstantes = poSurveillances;
				break;

			case C_INJECTIONS_PREFIX:
				loInjections = poSurveillances;
				break;
		}

		if (!ObjectHelper.isNullOrEmpty(loInjections))
			return this.checkSearchValueByStringProperties(loInjections.injections.map((poInjection: IInjection) => poInjection.label), psSearchValue);
		else if (!ObjectHelper.isNullOrEmpty(loConstantes))
			return this.checkSearchValueByStringProperties([loConstantes.bloodPressure, ...loConstantes.others?.map((poOther: { label: string; value: string; }) => poOther.value)], psSearchValue) ||
				this.checkSearchValueByNumberProperties(
					[
						loConstantes.temperature,
						loConstantes.heartRate,
						loConstantes.respiratoryRate,
						loConstantes.bloodSugar,
						loConstantes.diuresis,
						loConstantes.painScale,
						loConstantes.weight
					],
					psSearchValue
				);
		else
			return false;
	}

	/** Vérifie la présence d'au moins une correspondance de propriété avec la valeur recherchée.
	 * @param paProperties Tableau des propriétés dont il faut vérifier une correspondance avec la valeur recherchée.
	 * @param psSearchValue Valeur recherchée.
	 */
	private checkSearchValueByNumberProperties(paProperties: number[], psSearchValue: string): boolean {
		return paProperties.some((pnPropertyValue: number) => NumberHelper.isValid(pnPropertyValue) && pnPropertyValue.toString().includes(psSearchValue));
	}

	/** Vérifie la correspondance d'un patient avec la valeur recherchée.
	 * @param psSearchValue Valeur recherchée.
	 * @param poPatient Patient dont il faut vérifier la correspondance avec la valeur recherchée.
	 */
	private checkSearchValueInPatient(psSearchValue: string, poPatient?: IPatient): boolean {
		// Il faut vérifier le patient car si un nouvel objet surveillances vient d'être créé avec un nouveau patient, on ne l'a pas encore récupéré.
		return poPatient && this.checkSearchValueByStringProperties([poPatient.firstName, poPatient.lastName], psSearchValue);
	}

	/** Vérifie la présence d'au moins une correspondance de propriété avec la valeur recherchée.
	 * @param paProperties Tableau des propriétés dont il faut vérifier une correspondance avec la valeur recherchée.
	 * @param psSearchValue Valeur recherchée.
	 */
	private checkSearchValueByStringProperties(paProperties: string[], psSearchValue: string): boolean {
		return paProperties.some((psPropertyValue: string) => !StringHelper.isBlank(psPropertyValue) && psPropertyValue.toLowerCase().includes(psSearchValue));
	}

	/** Ouvre dans une page ou une modale l'objet surveillances cliqué. */
	public onSurveillancesClicked(poSurveillances: ISurveillances): void {
		if (this.params.openClickedItemAsModal) {
			this.isvcConstantes.openASurveillancesAsModal(ConstantesModalComponent, { surveillances: ObjectHelper.clone(poSurveillances) } as IConstantesModalParams)
				.pipe(takeUntil(this.destroyed$))
				.subscribe();
		}
		else
			this.isvcConstantes.routeToASurveillances(poSurveillances);
	}

	/** Crée un nouvel objet surveillances pour le patient courant s'il y en a un
	 * ou ouvre un sélecteur de patient pour choisir auquel lier le nouvel objet surveillances. */
	public createNewSurveillances(): void {
		const loPatient$: Observable<IPatient> = !this.params.model ?
			this.isvcPatients.selectPatient("Nouvelles surveillances") : of(this.params.model);

		this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
			header: "Quel type de surveillance voulez-vous créer ?",
			buttons: [
				{
					text: "Une constante", handler: () => {
						return loPatient$
							.pipe(tap((poSelectedPatient: IPatient) => this.isvcConstantes.routeToANewSurveillances(poSelectedPatient, ESurveilancesType.constantes)))
							.subscribe();
					}
				},
				{
					text: "Un dosage", handler: () => {
						return loPatient$
							.pipe(tap((poSelectedPatient: IPatient) => this.isvcConstantes.routeToANewSurveillances(poSelectedPatient, ESurveilancesType.injections)))
							.subscribe();
					}
				},
				{ text: "Annuler", role: "cancel" },
			]
		}));
	}

	/** Supprime un objet surveillances spécifique.
	 * @param poSurveillances Objet surveillances à supprimer.
	 * @param poItemSliding ItemSliding à fermer après suppression.
	 */
	public onRemoveSurveillances(poSurveillances: ISurveillances, poItemSliding: IonItemSliding): void {
		this.isvcUiMessage.showAsyncMessage<boolean>(
			new ShowMessageParamsPopup({
				message: "Vous êtes sur le point de supprimer ce relevé de surveillances.",
				header: "Suppression",
				buttons: [
					{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() } as AlertButton,
					{ text: "Confirmer", handler: () => UiMessageService.getTruthyResponse() } as AlertButton
				]
			})
		)
			.pipe(
				mergeMap((poResult: IUiResponse<boolean>) => poResult.response ? this.isvcConstantes.deleteASurveillances(poSurveillances) : of(undefined)),
				tap(_ => this.openOrCloseItemSliding(poItemSliding)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private manageToolbar(): void {
		// Si on n'a pas ajouté la toolbar, on peut l'initialiser.
		if (!this.moParentComponent?.toolbar.eltContainer.some((poBarElement: IBarElement) => poBarElement.componentId === this.msInstanceId)) {
			this.moParentComponent.toolbar.init(
				[
					{
						id: "circle",
						component: "fabButton",
						dock: EBarElementDock.bottom,
						position: EBarElementPosition.right,
						icon: "add",
						onTap: () => this.createNewSurveillances(),
						name:"Ajouter"
					}
				] as IBarElement[],
				this.msInstanceId
			);

			this.detectChanges();
		}
	}

	//#endregion
}
