<div class="container">
	<div class="content">
		<div class="header-icon">
			<lua-icone iconName="location_city" fontSize="50px" color="Blanc">
			</lua-icone>
		</div>
		<h2>Quel est votre cabinet ?</h2>
		<div class="cabinet-list">
			<di-bouton-surface *ngFor="let cabinet of cabinets" mode="outlined" iconName="location_city"
				[title]="cabinet.name" [subtitle]="getSousTitre(cabinet)" (click)="navigateToHome(cabinet)"></di-bouton-surface>
		</div>

		<lua-bouton-texte libelle="Annuler" color="Blanc" (click)="goBack()"></lua-bouton-texte>
	</div>
</div>