<div class="surveillances-list">
	<ion-item *ngIf="!params?.hideSearchbar" lines="none" class="ion-no-padding search-item">
		<!-- Barre de recherche. -->
		<div class="search-box txt-center form-list width100pc">
			<search [data]="documents" [options]="searchOptions"
				(filteredEntriesChanged)="onFilteredDocumentsChanged($event)">
			</search>
		</div>
	</ion-item>

	<!-- Message si aucun résultat. -->
	<div *ngIf="!hasSearchResult" class="empty-screen flex-col">
		<span class="txt-message">
			<ion-icon name="information-circle" class="no-result"></ion-icon>
			Aucune surveillance.
		</span>
	</div>

	<!-- Contenu.-->
	<!-- Liste des surveillances. -->
	<calao-virtual-scroll [maxHeight]="params?.nbItems ? (params.nbItems * 70) + 'px': undefined" itemSize="70"
		class="marg-container" [items]="filteredDocuments">
		<ng-template let-surveillances="$implicit">
			<ion-item-sliding #itemSliding *ngIf="!!surveillances" [disabled]="params.swipeDisabled">

				<ion-item class="ion-no-padding" (click)="onSurveillancesClicked(surveillances)">
					<idl-surveillances-item [model]="surveillances"
						[patient]="(!params.model && patientBySurveillanceIds.has(surveillances._id)) ? patientBySurveillanceIds.get(surveillances._id) : undefined"
						[authorAvatar]="avatarsByAuthorPathMap.get(surveillances.authorPath)">
					</idl-surveillances-item>

					<!-- Bouton des options de l'item. -->
					<ion-button class="btn-no-margin btn-more" fill="clear" slot="end"
						(click)="openOrCloseItemSliding(itemSliding, $event)">
						<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
					</ion-button>
				</ion-item>

				<!-- Options des items.-->
				<ion-item-options side="end" (ionSwipe)="openOrCloseItemSliding(itemSliding)">
					<ion-button class="remove-btn" color="danger" (click)="onRemoveSurveillances(surveillances, itemSliding)">
						<ion-icon name="trash"></ion-icon>
						Supprimer
					</ion-button>
				</ion-item-options>
			</ion-item-sliding>
		</ng-template>
	</calao-virtual-scroll>
</div>

<!-- Bouton de création -->
<div *ngIf="!params?.hideToolbar && !params?.useToolbar" class="fab-space">
	<ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
		<ion-fab-button color="fab-button" (click)="createNewSurveillances()" class="fab-shadow" title="Ajouter">
			<ion-icon name="add"></ion-icon>
		</ion-fab-button>
	</ion-fab>
</div>