<div class="di-facturation-sv"> 
  <lua-message-action-en-cours *ngIf="chargementLecteur"
    [mainText]="textChargementLecteur"
    subText=""
    [loaderSize]="60"
    [loaderSpeed]="1"
  ></lua-message-action-en-cours>
  <ng-container *ngIf="!chargementLecteur">
    <div *ngIf="!facturationTerminee" class="lecture-carte__container">
       <div class="lecteurs__container">
          <h4>Lecteur carte vitale</h4>
          <ng-container *ngIf="listeTerminaux">
            <ng-container *ngFor="let lecteurItem of listeTerminaux">
               <lua-option-data-picker 
                  [label]="lecteurItem.name" 
                  [fullWidth]="true" 
                  iconName="smart_card_reader"
                  [isSelected]="lecteurItem.terminalId === lastTerminalId" 
                  [disabled]="facturationEnCours"
                  (click)="onSelectTerminal(lecteurItem)">
               </lua-option-data-picker>
            </ng-container>
         </ng-container>
       </div>
       <lua-divider-horizontal></lua-divider-horizontal>
       <div class="steps__container">
          <div class="step__container">
             <lua-icone
                iconName="check_circle" mode="filled"
                [color]="(terminal?.cpsInserted || carteCPSAbsente) ? 'Vert' : 'Gris'"
                >
             </lua-icone>
             <div class="double-ligne">
                <strong>Lecture carte CPS</strong>
                <p *ngIf="cpsError && !carteCPSAbsente">Insérer la carte CPS</p>
             </div>
          </div>
          <ng-container *ngIf="cpsError && !carteCPSAbsente">
             <lua-action-secondaire libelle="C'est fait !" size="large" [fullWidth]="true" (click)="getInfo()"></lua-action-secondaire>
             <lua-bouton-texte libelle="Je n'ai pas de CPS" [fullWidth]="true" size="large" (click)="setCPSAbsente()"></lua-bouton-texte>
          </ng-container>
          <ng-container *ngIf="!adri">
             <div class="step__container">
                <lua-icone
                   iconName="check_circle" mode="filled"
                   [color]="(terminal?.vitaleInserted || carteVitaleAbsente) ? 'Vert' : 'Gris'"
                   >
                </lua-icone>
                <div class="double-ligne">
                   <strong [ngClass]="{'disabled' : !terminal?.cpsInserted && !carteCPSAbsente && !terminal?.vitaleInserted && !carteVitaleAbsente }">Lecture carte vitale</strong>
                   <p *ngIf="(!cpsError || carteCPSAbsente) && vitaleError && !carteVitaleAbsente">Insérer la carte vitale du patient</p>
                </div>
             </div>
             <ng-container *ngIf="(!cpsError || carteCPSAbsente) && vitaleError && !carteVitaleAbsente">
                <lua-action-secondaire libelle="C'est fait !" size="large" [fullWidth]="true" (click)="getInfo()"></lua-action-secondaire>
                <lua-bouton-texte libelle="Je n'ai pas la carte vitale" [fullWidth]="true" size="large" (click)="setCVAbsente()"></lua-bouton-texte>
             </ng-container>
          </ng-container>
          <div class="step__container">
            <div class="ligne-loader">
              <div class="ligne-facturation">
                <lua-icone
                   [iconName]="tarificationTerminee && erreurTarification ? 'cancel' : 'check_circle'" mode="filled"
                   [color]="tarificationTerminee && erreurTarification ? 'Rouge' : (tarificationTerminee ? 'Vert' : 'Gris')"
                   >
                </lua-icone>
                <strong [ngClass]="{'disabled' : (cpsError && !carteCPSAbsente) || (vitaleError && !carteVitaleAbsente) }">Calcul des montants et controles</strong>
              </div>
              <lua-loader-horizontal *ngIf="!cpsError && !vitaleError && !tarificationTerminee" [progressBar]="false" [speed]="5"></lua-loader-horizontal>
            </div>
         </div>
          <div class="step__container">         
            <div class="ligne-loader">
              <div class="ligne-facturation">
                <lua-icone
                iconName="check_circle" mode="filled"
                [color]="securisationTerminee ? 'Vert' : 'Gris'"
                >
                </lua-icone>  
                <strong [ngClass]="{'disabled' : !tarificationTerminee }">Sécurisation</strong>
              </div>
              <lua-loader-horizontal *ngIf="tarificationTerminee && !securisationTerminee" [progressBar]="false" [speed]="5"></lua-loader-horizontal>
            </div>
         </div>
       </div>
    </div>
    <div *ngIf="facturationTerminee" class="facturation-terminee">
        <lua-message-info
         *ngIf="!erreurFacturation && invoice && (invoice.securisationMode === 'DEGRADE' || invoice.securisationMode === 'PAPIER')"
         [messageTitle]="titreFacturationOk"
         [messageDetail]="sousTexteFacturationOk">
        </lua-message-info>
        <lua-message-success
        *ngIf="!erreurFacturation && invoice && invoice.securisationMode === 'SECURISE'"
        [messageTitle]="titreFacturationOk"
        [messageDetail]="sousTexteFacturationOk">
       </lua-message-success>
        <lua-action-secondaire *ngIf=" !erreurFacturation && invoice && (invoice.securisationMode === 'DEGRADE' || invoice.securisationMode === 'PAPIER')"
            libelle="Imprimer la feuille de soin"
            size="medium"
            [fullWidth]="true"       
            (click)="printFsp()"
            [avecIcone]="true" 
            nomIcone="print"
            positionIcone="L" 
            color="Info"
        ></lua-action-secondaire>
        <lua-alerte *ngIf="invoice && invoice.securisationMode === 'SECURISE' && !erreurFacturation && !carteVitaleAbsente && (!invoice.documents || invoice.documents.length === 0)"
            type="info" 
            message="L'ordonnance n'étant pas numérisée,          
            vous devrez l'envoyer en papier">
         </lua-alerte>
        <lua-message-erreur
        *ngIf="erreurFacturation"
				[messageTitle]="titreErreur"
				[messageDetail]="messageErreur"
				buttonLabel="Corriger la facturation"
				(onClick)="correctionFacturation()"
			></lua-message-erreur>
         <!-- TODO : Ajouter le bouton "Facturer en papier" en cas d'erreur-->
       <div class="boutons_container" *ngIf="!erreurFacturation">
          <lua-action-secondaire
          libelle="Retour aux ordonnances à facturer"
          size="large"
          [fullWidth]="true"       
          (click)="navigateToOrdonnance()"
          ></lua-action-secondaire>
          <!-- TODO : A décommenter une fois la maquette terminée -->
          <lua-bouton-texte
             libelle="Afficher le détail de la facturation"
             (click)="printInvoice()"
             [fullWidth]="true"
             size="large">
          </lua-bouton-texte>
       </div>
    </div>  
  </ng-container>
</div>