<div class="di-carte-photo">
	<div class="image-container" *ngIf="!isFile && isImageAvailable">
		<img [src]="fileUrl" alt="Photo" (click)="openOverlay()" />
		<div *ngIf="edit" class="delete">
			<lua-bouton-icone [iconName]="'Delete'" [size]="'medium'" [color]="'Blanc'" [isIcon]="false" [mode]="'filled'"
				[isDisabled]="false" (click)="deletePhoto()"></lua-bouton-icone>
		</div>
	</div>

	<lua-overlay *ngIf="isOverlayOpen">
		<div class="overlay" (click)="closeOverlay()">
			<img [src]="fileUrl" class="overlay-image" />
			<div class="close-button">
				<lua-bouton-icone [iconName]="'Close'" [size]="'medium'" [color]="'Blanc'" [isIcon]="false" [mode]="'filled'"
					[isDisabled]="false" (click)="closeOverlay()"></lua-bouton-icone>
			</div>
		</div>
	</lua-overlay>
	<div class="file-container" *ngIf="isFile">
		<div class="file-display">
			<lua-bouton-icone [iconName]="'Draft'" [size]="'medium'" [color]="'CouleurPrimaire'" [isIcon]="false"
				[mode]="'outlined'" (click)="download()"></lua-bouton-icone>
			<p>{{ fileName }}</p>
			<div class="endIcon">
				<lua-bouton-icone *ngIf="edit" [iconName]="'Delete'" [size]="'medium'" [color]="'CouleurPrimaire'"
					[isIcon]="false" [mode]="'outlined'" [isDisabled]="false" (click)="deletePhoto()"></lua-bouton-icone>
			</div>
		</div>
		<div class="overlay" *ngIf="isOverlayOpen" (click)="closeOverlay()">
			<img [src]="fileUrl" class="overlay-image" />
			<div class="close-button">
				<lua-bouton-icone [iconName]="'Close'" [size]="'medium'" [color]="'Blanc'" [isIcon]="false" [mode]="'filled'"
					[isDisabled]="false" (click)="closeOverlay()"></lua-bouton-icone>
			</div>
		</div>
		<div class="offline-container" *ngIf="!isFile && !isImageAvailable">
			<lua-icone *ngIf="!isFile && !isImageAvailable" [iconName]="'Cloud_off'" [size]="'large'" [color]="'Noir60'"
				[isIcon]="false" [mode]="'outlined'" [isDisabled]="true"></lua-icone>
		</div>
	</div>