import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Keyboard } from '@capacitor/keyboard';
import { IonContent, IonInfiniteScroll, Platform, PopoverController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { BehaviorSubject, EMPTY, EmptyError, GroupedObservable, Observable, Subject, defer, from, fromEvent, merge, of, throwError } from 'rxjs';
import { auditTime, catchError, debounceTime, delay, distinctUntilChanged, filter, finalize, groupBy, map, mapTo, mergeMap, reduce, retryWhen, startWith, take, takeUntil, takeWhile, tap, toArray } from 'rxjs/operators';
import { ComponentBase } from '../../helpers/ComponentBase';
import { ConversationHelper } from '../../helpers/ConversationHelper';
import { LifeCycleObserverComponentBase } from '../../helpers/LifeCycleObserverComponentBase';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { ContactHelper } from '../../helpers/contactHelper';
import { DateHelper } from '../../helpers/dateHelper';
import { EntityHelper } from '../../helpers/entityHelper';
import { GuidHelper } from '../../helpers/guidHelper';
import { IdHelper } from '../../helpers/idHelper';
import { MapHelper } from '../../helpers/mapHelper';
import { StoreDocumentHelper } from '../../helpers/storeDocumentHelper';
import { StoreHelper } from '../../helpers/storeHelper';
import { StringHelper } from '../../helpers/stringHelper';
import { UserHelper } from '../../helpers/user.helper';
import { EPrefix } from '../../model/EPrefix';
import { PageInfo } from '../../model/PageInfo';
import { EApplicationEventType } from '../../model/application/EApplicationEventType';
import { ENetworkFlag } from '../../model/application/ENetworkFlag';
import { IApplicationEvent } from '../../model/application/IApplicationEvent';
import { UserData } from '../../model/application/UserData';
import { ConfigData } from '../../model/config/ConfigData';
import { IConversationFormConfig } from '../../model/config/IConversationFormConfig';
import { EContactsType } from '../../model/contacts/EContactsType';
import { IContact } from '../../model/contacts/IContact';
import { IContactsSelectorParams } from '../../model/contacts/IContactsSelectorParams';
import { IGroup } from '../../model/contacts/IGroup';
import { IGroupMember } from '../../model/contacts/IGroupMember';
import { EActivityStatus } from '../../model/conversation/EActivityStatus';
import { EConversationEvent } from '../../model/conversation/EConversationEvent';
import { EConversationType } from '../../model/conversation/EConversationType';
import { EMessageType } from '../../model/conversation/EMessageType';
import { IAttachedFile } from '../../model/conversation/IAttachedFile';
import { IConversation } from '../../model/conversation/IConversation';
import { IConversationActivity } from '../../model/conversation/IConversationActivity';
import { IConversationCacheData } from '../../model/conversation/IConversationCacheData';
import { IConversationEvent } from '../../model/conversation/IConversationEvent';
import { IConversationTask } from '../../model/conversation/IConversationTask';
import { IConversationUiEvent } from '../../model/conversation/IConversationUiEvent';
import { IMessage } from '../../model/conversation/IMessage';
import { IParticipant } from '../../model/conversation/IParticipant';
import { IParticipantIndicator } from '../../model/conversation/IParticipantIndicator';
import { IReadIndicator } from '../../model/conversation/IReadIndicator';
import { Entity } from '../../model/entities/Entity';
import { IEntity } from '../../model/entities/IEntity';
import { IEntityLink } from '../../model/entities/IEntityLink';
import { IEntityLinkPart } from '../../model/entities/IEntityLinkPart';
import { InitComponentError } from '../../model/errors/InitComponentError';
import { IFlag } from '../../model/flag/IFlag';
import { IFormParams } from '../../model/forms/IFormParams';
import { EGalleryCommand } from '../../model/gallery/EGalleryCommand';
import { EGalleryFilesChanged } from '../../model/gallery/EGalleryFilesChanged';
import { IGalleryCommand } from '../../model/gallery/IGalleryCommand';
import { IGalleryFile } from '../../model/gallery/IGalleryFile';
import { ELifeCycleEvent } from '../../model/lifeCycle/ELifeCycleEvent';
import { ILifeCycleEvent } from '../../model/lifeCycle/ILifeCycleEvent';
import { EAvatarSize } from '../../model/picture/EAvatarSize';
import { IAvatar } from '../../model/picture/IAvatar';
import { IPopoverItemParams } from '../../model/popover/IPopoverItemParams';
import { ISelectorParams } from '../../model/selector/ISelectorParams';
import { ICacheData } from '../../model/store/ICacheData';
import { IDataSource } from '../../model/store/IDataSource';
import { IStoreDocument } from '../../model/store/IStoreDocument';
import { IStoreReplicationResponse } from '../../model/store/IStoreReplicationResponse';
import { Loader } from '../../modules/loading/Loader';
import { EPermission } from '../../modules/permissions/models/EPermission';
import { CanExecute, IHasPermission, Permissions, PermissionsService } from '../../modules/permissions/services/permissions.service';
import { Queue } from '../../modules/utils/queue/decorators/queue.decorator';
import { EntityBuilder } from '../../services/EntityBuilder';
import { ApplicationService } from '../../services/application.service';
import { ContactsService } from '../../services/contacts.service';
import { ConversationService } from '../../services/conversation.service';
import { EntityLinkService } from '../../services/entityLink.service';
import { FlagService } from '../../services/flag.service';
import { GroupsService } from '../../services/groups.service';
import { ShowMessageParamsPopup } from '../../services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '../../services/interfaces/ShowMessageParamsToast';
import { LoadingService } from '../../services/loading.service';
import { PageManagerService } from '../../services/pageManager.service';
import { PopoverService } from '../../services/popover.service';
import { Store } from '../../services/store.service';
import { UiMessageService } from '../../services/uiMessage.service';
import { EContactSelectorSort } from '../contacts/contactSelector/econtact-selector-sort';
import { DynamicPageComponent } from '../dynamicPage';
import { FormComponent } from '../forms/form/form.component';
import { PopoverComponent } from '../popover/popover.component';
import { AttachmentsPopoverComponent } from './attachmentsPopover/attachmentsPopover.component';

interface IMessageModelItem {
	index: number;
	message: IMessage;
}
interface IError {
	error: any,
	message: string;
}
interface FirstLinkedEntityAndAvatar {
	avatar: IAvatar;
	linkedEntity: IEntityLink;
}

@Component({
	selector: "calao-conversation",
	templateUrl: './conversation.component.html',
	styleUrls: ['./conversation.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationComponent extends LifeCycleObserverComponentBase implements OnInit, IHasPermission, OnDestroy {

	//#region FIELDS

	private static readonly C_LOG_ID = "CONV.C::";
	private static readonly C_SCROLL_ANIMATION_MS = 200;
	private static readonly C_TAPPING_MS = 2000;
	private static readonly C_WAIT_DEQUEUE_MS = 150;
	private static readonly C_WAIT_NEW_ELEMENT_QUEUE_MS = 500;
	private static readonly C_CHECK_PARTICIPANTS_ACTIVITIES_INTERVAL_MS = 10000;
	private static readonly C_MESSAGES_LIMIT = 10;
	private static readonly C_ACTIVITY_TOUCHSTART_EVENT = "touchstart";
	private static readonly C_DEFAULT_GALLERY_FILES_TYPE = "application/pdf";

	public static readonly C_DELETED_MESSAGE_BODY = "Ce message a été supprimé.";

	private readonly moGalleryCommandSubject = new Subject<IGalleryCommand>();
	private readonly moEntitiesUpdatedSubject = new Subject<void>();

	private msConvGuid: string;
	private moDataSource: IDataSource;
	/** Tableau des indicateurs de chaque participant. */
	private maParticipantIndicators: IParticipantIndicator[] = [];
	private maConversationTasksQueue: Array<IConversationTask> = [];
	private mbIsDequeueEventBusy = true;
	/** Indique si le défilement des tâches doit être stoppé ou non. */
	private mbStopDequeue = false;
	private mnTappingTimer: number;
	private mnChekParticipantsActivitiesInterval: number;
	private mbIsViewDestroy = false;
	/** Indique si des fichiers sont joints ou non (0, 1 ou n). */
	private meFilesChangedLastValue: EGalleryFilesChanged = EGalleryFilesChanged.NoFile;
	/** Indique si la page a été correctement initialisée une première fois ou non (pour éviter de la réinitialiser). */
	private mbInitialized = false;
	/** Indique si le téléchargement des données de conversation est terminé ou non. */
	private mbDownloadConversationDetailsFinished = false;
	private moIsActivePageSubject: Subject<boolean> = new BehaviorSubject(true);
	/** Liste des groupes membres de la conversations. */
	private maGroups: IGroup[];
	private msUrl: string;
	private mbIsDeletedConversation = false;
	/** Indique si la conversation à déjà été initialisée */
	private mbConversationInit: boolean;
	/** Tableau des messages en attente. */
	private maPendingMessages: string[] = [];

	//#endregion

	//#region PROPERTIES

	/** Indique si return envoie le message ou passe à la ligne. */
	@Input() public sendOnReturn: boolean;
	/** Indique les types de fichiers que la galerie doit prendre en charge, pdf par défaut. */
	@Input() public galleryAcceptFiles: string;
	/** Change le titre de la page parente. Par défaut à true, doit être à faux si intégré dans un autre composant. */
	@Input() public changeParentPageTitle: boolean;
	/** Identifiant du contact utilisateur courant. */
	@Input() public currentContactId: string;
	/** Conteneur de la liste de message. */
	@ViewChild("messagesContainer") public messagesContainer: IonContent;

	/** Corps d'un message supprimé. */
	public readonly deletedMessageBody: string = ConversationComponent.C_DELETED_MESSAGE_BODY;
	/** Nombre d'avatars maximum pour les indicateurs de lecture d'un message. */
	public readonly maxReadIndicatorsLength = 8;
	public readonly galleryCommand$: Observable<IGalleryCommand>;
	public readonly permissionScope: EPermission = EPermission.conversations;

	/** Récupération des contacts participants à la conversation (pas les contacts des groupes), sans le contact utilisateur */
	public get contacts(): IContact[] { return this.otherParticipants.map((poParticipant: IParticipant<IContact>) => poParticipant.model); }
	/** Récupération des contacts participants à la conversation (contacts et contacts de groupes), sans le contact utilisateur . */
	public get otherParticipants(): IParticipant<IContact>[] { return MapHelper.valuesToArray(this.otherParticipantsMap); }
	/** Si true, l'utilisateur peut ajouter des entités liées à la conversation via un bouton. */
	public get canAddLink(): boolean { return ConfigData.appInfo.useLinks && !ConfigData.conversation.disableManualsLinks; }
	/** Indique si l'utilisateur peut éditer la conversation. */
	@Permissions("edit")
	public get canEdit(): boolean { return !UserData.current.isGuest; }

	private mbAreAttachmentsSaving = false;
	/** Indique si les pièces jointes du message en cours d'envoi est en cours d'enregistrement ou non. */
	public get areAttachmentsSaving(): boolean { return this.mbAreAttachmentsSaving; }


	/** Identificateur de la conversation. */
	public convId: string;
	public newMessage: string;
	public conversation: IConversation;
	public messages: Array<IMessage> = [];
	/** Tableau des participants sans l'utilisateur. */
	public otherParticipantsMap: Map<string, IParticipant<IContact>> = new Map();
	public files: IGalleryFile[] = [];
	/** Indique si la galerie est cachée ou non. */
	public areAttachmentsHidden = true;
	/** Indique si on peut envoyer un message ou non. */
	public canSend = false;
	/** Indique si le chargement des anciens messages est possible. */
	public infiniteScrollEnabled = true;
	public isDownloading = false;
	public firstLinkedEntityAndAvatar$: Observable<FirstLinkedEntityAndAvatar>;

	private moUserParticipant?: IParticipant<IContact>;
	/** Participant utilisateur, peut-être `undefined`. */
	public get userParticipant(): IParticipant<IContact> | undefined { return this.moUserParticipant; }

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des conversations. */
		private readonly isvcConversation: ConversationService,
		/** Service de gestion des loaders. */
		private readonly isvcLoading: LoadingService,
		/** Service de gestion des requpetes en base de données. */
		private readonly isvcStore: Store,
		/** Service de la plateforme. */
		private readonly ioPlatform: Platform,
		/** Service de gestion des liens */
		private readonly isvcEntityLink: EntityLinkService,
		private readonly isvcContacts: ContactsService,
		private readonly isvcGroups: GroupsService,
		private readonly isvcPageManager: PageManagerService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly ioPopoverCtrl: PopoverController,
		private readonly isvcFlag: FlagService,
		private readonly ioRoute: ActivatedRoute,
		private readonly isvcPopover: PopoverService,
		private readonly ioClipboard: Clipboard,
		private readonly ioRouter: Router,
		public readonly isvcPermissions: PermissionsService,
		poParentPage: DynamicPageComponent<ComponentBase>,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poParentPage, poChangeDetectorRef);
		this.galleryCommand$ = this.moGalleryCommandSubject.asObservable();
	}

	protected onLifeCycleEvent(poValue: IApplicationEvent): void {
		if (poValue.type === EApplicationEventType.LifeCycleEvent) {
			switch ((poValue as ILifeCycleEvent).data.value) {

				case ELifeCycleEvent.viewWillEnter:
					if (this.mbInitialized)
						this.isvcEntityLink.trySetCurrentEntity(this.conversation).pipe(takeUntil(this.destroyed$))
							.subscribe();
					break;

				case ELifeCycleEvent.viewWillLeave:
					this.onViewWillLeave();
					break;
			}
		}
	}

	public ngOnInit(): void {
		this.ioRoute.data
			.pipe(
				tap((poData: { conversation: IConversation }) => {
					this.convId = IdHelper.buildId(EPrefix.conversation, this.ioRoute.snapshot.params.conversationId);
					this.conversation = poData.conversation;

					if (!this.conversation) { // Si la conversation n'est pas renseignée, alors c'est qu'elle a été supprimée.
						this.onConversationDeleted();
						return;
					}

					this.setPropertiesFromConvCacheData();

					this.msConvGuid = IdHelper.getGuidFromId(this.convId, EPrefix.conversation);
					this.detectChanges();
				}),
				filter(() => !this.mbConversationInit),
				tap(() => {
					this.moDataSource = {
						databaseId: this.isvcConversation.databaseId,
						viewParams: {
							include_docs: true,
							startkey: EPrefix.message + this.msConvGuid + Store.C_ANYTHING_CODE_ASCII,
							endkey: EPrefix.message + this.msConvGuid,
							descending: true,
							limit: ConversationComponent.C_MESSAGES_LIMIT
						}
					};

					this.initEventsListeners();
					this.initProperties();
					this.init();

					// Valeur par défaut de changeParentPageTitle à true.
					this.changeParentPageTitle ? this.changeParentPageTitle : true;

					this.mbConversationInit = true;
				}),
				mergeMap(() => this.firstLinkedEntityAndAvatar$ = this.moEntitiesUpdatedSubject.asObservable()
					.pipe(
						startWith(null),
						mergeMap(_ => this.getFirstLinkedEntityAndAvatar())
					)
				),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.initMessagesStatus()
			.pipe(
				tap(_ => this.refresh()),
				catchError(poError => this.isvcConversation.onError(poError, "Erreur lors de l'envoi du message sur le serveur.")),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private setPropertiesFromConvCacheData(): void {
		const loCacheData: IConversationCacheData = StoreHelper.getDocumentCacheData(this.conversation) as IConversationCacheData;

		if (loCacheData) {
			loCacheData.participantIndicators.forEach((poParticipantIndicator: IParticipantIndicator) => poParticipantIndicator.indicator.avatar.size = EAvatarSize.small);
			this.maParticipantIndicators = loCacheData.participantIndicators;
			this.moUserParticipant = loCacheData.userParticipant;
			this.otherParticipantsMap = loCacheData.otherParticipantsMap;
		}
		else
			throw new InitComponentError("Pas de cacheData dans la conversation, mais nécessaire pour l'initialisation !");
	}

	private getFirstLinkedEntityAndAvatar(): Observable<FirstLinkedEntityAndAvatar> {
		return this.isvcEntityLink.getEntityLinks(this.convId)
			.pipe(
				distinctUntilChanged(ArrayHelper.areArraysFromDatabaseEqual),
				map((paLinkedEntities: IEntityLink[]) => ArrayHelper.getFirstElement(paLinkedEntities.sort((poEntityA: IEntityLink, poEntityB: IEntityLink) =>
					DateHelper.compareTwoDates(poEntityA.createDate, poEntityB.createDate)
				))),
				mergeMap((poLinkedEntity: IEntityLink) => {
					if (poLinkedEntity) {
						const loEntityPart: IEntityLinkPart = EntityHelper.getEntityLinkPartFromSourcePrefix(poLinkedEntity, EPrefix.conversation);

						return this.isvcEntityLink.getEntity(loEntityPart.databaseId, loEntityPart.entityId)
							.pipe(
								map((poEntity: Entity) => {
									return {
										avatar: this.isvcEntityLink.getEntityBuilder(poEntity.model._id).entityAvatar(poEntity.model),
										linkedEntity: poLinkedEntity
									} as FirstLinkedEntityAndAvatar;
								})
							);
					}
					else
						return of(undefined);
				}),
				takeUntil(this.destroyed$)
			);
	}

	public ngOnDestroy(): void {
		if (!this.mbIsDeletedConversation)
			this.updateUserActivity(EActivityStatus.quit).subscribe();

		this.moEntitiesUpdatedSubject.complete();
		this.moGalleryCommandSubject.complete();
		this.mbIsViewDestroy = true;
		super.ngOnDestroy();
	}

	/** Ajoute une tâche dans la file des tâches à exécuter de la conversation.
	 * @param pfFunction Fonction à exécuter.
	 * @param psId Identifiant de l'objet manipulé.
	 * @param peType Type d'objet de conversation, optionnel.
	 */
	private addTask(pfFunction: () => Observable<void | IConversationActivity>, psId: string, peType?: EConversationType): void {
		const loConvTask: IConversationTask = {
			function: pfFunction,
			params: {
				_id: psId,
				createDate: new Date(),
				type: peType
			}
		};
		this.maConversationTasksQueue.push(loConvTask);
	}

	/** Ouvre le popover de sélection des pièces jointes.
	 * @param poEvent Evénement de click de l'utilisateur.
	 */
	public async openAttachmentsPopover(poEvent: MouseEvent): Promise<void> {
		try {
			await Keyboard.hide();
		}
		finally {
			return this.innerOpenAttachmentsPopover(poEvent);
		}
	}

	private async innerOpenAttachmentsPopover(poEvent: MouseEvent): Promise<void> {
		const loPopover: HTMLIonPopoverElement = await this.ioPopoverCtrl.create({
			component: AttachmentsPopoverComponent,
			event: poEvent,
			componentProps: { galleryCommandSubject: this.moGalleryCommandSubject },
			keyboardClose: true,
			mode: "ios"
		});

		loPopover.onWillDismiss().then((poValue: OverlayEventDetail<IConversationFormConfig>) => {
			this.detectChanges();
			if (poValue.data)
				this.openForm(undefined, poValue.data);
		});

		await loPopover.present();
		this.areAttachmentsHidden = false;
		this.detectChanges();
	}

	/** Modifie les indicateurs de lecture des messages en fonction d'un participant.
	 * @param poParticipant Participant à la conversation dont il faut modifier l'indicateur du dernier message lu.
	 */
	private changeReadIndicator(poParticipant: IParticipant<IContact>): void {
		const loIndicator: IParticipantIndicator =
			this.maParticipantIndicators.find((poIndicator: IParticipantIndicator) => poIndicator.id === poParticipant.participantId);

		if (loIndicator) // Si un indicateur est déjà présent, il faut le mettre à jour.
			this.updateChangeReadIndicator(poParticipant, loIndicator);
		else // Sinon, il faut créer un nouvel indicateur pour ce participant.
			this.createChangeReadIndicator(poParticipant);

		this.refresh();
	}

	/** Crée un indicateur de message lu pour le participant.
	 * @param poParticipant Participant à la conversation.
	 */
	private createChangeReadIndicator(poParticipant: IParticipant<IContact>): void {
		const loNewIndicator: IParticipantIndicator = {
			indicator: this.createReadIndicator(poParticipant),
			id: poParticipant.participantId,
			lastReadMessageId: poParticipant.activity.lastReadMessageId
		};

		this.maParticipantIndicators.push(loNewIndicator);
	}

	/** Met à jour l'indicateur de message lu du participant.
	 * @param poParticipant Participant à la conversation.
	 * @param poIndicator Indicateur du participant.
	 */
	private updateChangeReadIndicator(poParticipant: IParticipant<IContact>, poIndicator: IParticipantIndicator): void {
		const lsPreviousMessageReadId: string = poIndicator.lastReadMessageId;

		// Si le dernier et l'avant-dernier message lu sont différents alors on met à jour, sinon c'est le même donc pas besoin de mettre à jour.
		if (poParticipant.activity.lastReadMessageId !== lsPreviousMessageReadId)
			poIndicator.lastReadMessageId = poParticipant.activity.lastReadMessageId;
	}

	/** Retrouve les indicateurs de lecture pour un message.
	 * @param psMessageId Identifiant du message.
	 */
	public findReadIndicators(psMessageId: string): IParticipantIndicator[] {
		return this.maParticipantIndicators.filter((poParticipantIndicator: IParticipantIndicator) => poParticipantIndicator.lastReadMessageId === psMessageId);
	}

	/** Défilement : on exécute la fonction contenue dans le premier élément du tableau en bloquant les futures exécutions tant que celle-ci n'est pas terminée. */
	private dequeue(): void {
		const lfRecall: Function = () => {
			this.maConversationTasksQueue.shift();
			this.mbIsDequeueEventBusy = false;
			this.startDequeue();
			this.detectChanges();
		};
		this.mbIsDequeueEventBusy = true;

		this.isvcConversation.asyncHasNetworkConnection()
			.pipe(
				mergeMap((pbHasNetwork: boolean) => {
					if (!pbHasNetwork) 	// si une interruption réseau est survenue.
						this.reorganizeQueue(); // on trie la file dans l'ordre chronologique pour ne pas avoir de problème.

					const loTask: IConversationTask = ArrayHelper.getFirstElement(this.maConversationTasksQueue);
					loTask.params.lastActivityDate = new Date();

					return loTask.function(loTask.params);
				}),
				catchError(poError => this.isvcConversation.onError(poError)),
				tap(_ => lfRecall()),
				takeWhile(_ => !this.mbStopDequeue)
			)
			.subscribe();
	}

	/** Récupération de la conversation. */
	private loadConversation(): Observable<boolean> {
		return this.isvcConversation.getConversation(this.convId, true)
			.pipe(
				catchError(poError => throwError({ error: poError, message: "Une erreur est survenue lors de la récupération de la conversation sur la base de données." } as IError)),
				mergeMap((poConversation: IConversation) => {
					if (!poConversation) {
						return this.moIsActivePageSubject
							.pipe(
								filter((pbIsActivePage: boolean) => pbIsActivePage),
								take(1),
								tap(_ => this.onConversationDeleted())
							);
					}
					else {
						this.initConversation(poConversation);
						this.manageVisuParticipantsActivities();
						return of(true);
					}
				}),
				takeUntil(this.destroyed$)
			);
	}

	private onConversationDeleted(): void {
		this.mbIsDeletedConversation = true;

		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({
				message: "Cette conversation a été supprimée.",
				header: "Erreur",
				backdropDismiss: false,
				buttons: [{ text: "OK", handler: () => { this.isvcPageManager.goBack(); return true; } }]
			})
		);
	}

	/** Initialise la conversation dans laquelle on est entré.
	 * @param poConversation Conversation dans laquelle on est.
	 */
	private initConversation(poConversation: IConversation): void {
		if (!poConversation) {
			const loError: IError = {
				error: "Résultat getConversation() vide.",
				message: "L'initialisation de la conversation a rencontré un problème et ne peut continuer."
			};
			throw loError;
		}
		else {
			if (!StoreDocumentHelper.areDocumentRevisionsEqual(this.conversation, poConversation)) {
				StoreHelper.updateDocumentCacheData(poConversation, StoreHelper.getDocumentCacheData(this.conversation));
				this.conversation = poConversation; // Sauvegarde de la conversation.
			}

			if (this.changeParentPageTitle)
				this.setParentPageTitle();

			this.setPropertiesFromConvCacheData();
			this.detectChanges();
		}
	}

	/** Met à jour le titre de la page.
	 * @param psTitle Si vide, affiche le titre par défaut.
	 */
	private setParentPageTitle(psTitle: string = this.isvcConversation.getDefaultTitle(this.conversation)): void {
		this.moParentPage.title = psTitle;
	}

	/** Récupération de la liste des messages de la conversation. */
	private getMessages(): Observable<boolean> {
		return this.isvcConversation.getMessages(this.moDataSource)
			.pipe(
				catchError(poError =>
					throwError({ error: poError, message: "Une erreur est survenue lors de la récupération des messages de la conversation sur la base de données." } as IError)
				),
				tap((paMessageResults: Array<IMessage>) => {
					this.messages.push(...paMessageResults); // On ajoute les nouveaux messages récupérés de la base de données dans le tableau actuel des messages.
					this.updateMessagesStatus();
				}),
				mapTo(true)
			);
	}

	/** Permet de trouver le participant dans les participants en mémoire.
	 * @param psContactId Id du contact lié au participant.
	 */
	private findParticipant(psContactId: string): IParticipant<IContact> {
		return this.moUserParticipant?.participantId === psContactId ?
			this.moUserParticipant : this.isvcConversation.findParticipant(this.otherParticipants, psContactId);
	}

	/** Ajoute une tâche de mise à jour d'activité.
	 * @param peActivityStatus Statut d'activité à utiliser pour la mise à jour ('o', 'q', 't').
	 */
	private addUpdateUserActivityTask(peActivityStatus: EActivityStatus = EActivityStatus.online): void {
		if (this.moUserParticipant) {
			if (this.moUserParticipant.activity)
				this.addTask(() => this.updateUserActivity(peActivityStatus), this.moUserParticipant.activity._id, EConversationType.activity);
			else
				console.error(`${ConversationComponent.C_LOG_ID}No activity for user participant '${this.moUserParticipant.participantId}', can not update user activity !`);
		}
		else
			console.error(`${ConversationComponent.C_LOG_ID}No user participant declared, can not update user activity for user '${UserData.current?._id}' !`);
	}

	/** Crée l'indicateur de lecture d'un participant.
	 * @param poParticipant Participant dont on veut créer l'indicateur de lecture.
	 */
	private createReadIndicator(poParticipant: IParticipant<IContact>): IReadIndicator {
		return {
			activityId: poParticipant.activity._id,
			avatar: ContactsService.createContactAvatar(poParticipant.model, EAvatarSize.small)
		};
	}

	/** Initialise le composant. */
	private init(): void {
		this.startDequeue();
		this.initConversationLoading();
	}

	private initConversationLoading(): void {
		let loLoader: Loader;

		from(this.isvcLoading.create(ApplicationService.C_LOAD_DATA_LOADER_TEXT))
			.pipe(
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(_ => this.loadConversation()), // Récupération de la conversation une première fois en local.
				filter((_, pnIndex: number) => pnIndex === 0), // On filtre pour n'effectuer la routine d'initialisation que lors de la première réception de résultat.
				mergeMap(_ => this.getMessages()),
				mergeMap(_ => this.isvcEntityLink.trySetCurrentEntity(this.conversation)),
				tap(_ => this.manageLiveReplication()),
				tap(_ => this.initProperties()),
				catchError((poError: IError | any) => {
					const loError: IError = (poError as IError).error ?
						poError : { error: poError, message: "Une erreur est survenue lors de l'initialisation de la conversation." };
					loLoader.dismiss();
					return this.isvcConversation.onError(loError.error)
						.pipe(catchError(poOtherError => { this.isvcPageManager.goBack(); return throwError(poOtherError); }));
				}),
				tap(_ => {
					this.mbInitialized = true;
					this.refresh();
					loLoader.dismiss(); // On retire le loader quand les données sont chargées.
				}),
				finalize(() => {
					loLoader.dismiss(); // On retire le loader en cas d'erreur ou si le flux est clôturé avant d'avoir atteint le tap (ex: Retour avec bouton physique).
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Récupère un template de message qu'on peut remplir
	 * @param psMessage Texte du message.
	 * @param peMessageType Type de message à récupérer.
	 */
	private createMessage(psMessage: string, peMessageType: EMessageType): IMessage {
		const loMessage: IMessage = this.isvcConversation.createMessage(psMessage, peMessageType, this.msConvGuid, this.moUserParticipant?.participantPath);

		if (!this.moUserParticipant)
			console.warn(`${ConversationComponent.C_LOG_ID}Message '${loMessage._id}' created for conv '${this.msConvGuid}' but user participant for user '${UserData.current?._id}' is not valid.`);

		return loMessage;
	}

	/** Initialise les écouteurs. */
	private initEventsListeners(): void {
		this.ioPlatform.pause
			.pipe(
				tap(() => this.onPause()),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.ioPlatform.resume
			.pipe(
				tap(() => this.onResume()),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		// Mise à jour des messages de la conversation à l'aide de l'événement de mise à jour des participants.
		this.isvcConversation.getConversationUiObservable()
			.pipe(
				filter((poEvent: IConversationUiEvent) => poEvent.type === EConversationEvent.update && poEvent.convId === this.convId),
				tap((poEvent: IConversationUiEvent) => {
					this.messages.push(...poEvent.newMessages);
					this.isvcConversation.sortMessages(this.messages);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.msUrl = this.ioRouter.url;

		this.ioRouter.events
			.pipe(
				filter((poEvent: RouterEvent) => poEvent instanceof RouterEvent && !StringHelper.isBlank(poEvent.url)),
				tap((poEvent: RouterEvent) => this.moIsActivePageSubject.next(poEvent.url === this.msUrl)),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		// Met à jour le titre de la conversation dans le header à chaque fois qu'on revient sur la page.
		this.ioRouter.events
			.pipe(
				filter((poNavigation: RouterEvent) => poNavigation instanceof NavigationEnd),
				delay(10),	// Le PageInfo change le titre dans le header après, sauf si on fait un delay.
				tap(_ => this.refreshPageTitle()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Initialise les propriétés. */
	private initProperties(): void {
		this.currentContactId = this.moUserParticipant?.model?._id ?? "";
		this.newMessage = this.newMessage ? this.newMessage : "";

		if (!ArrayHelper.hasElements(this.messages))
			this.messages = [];

		if (this.galleryAcceptFiles === undefined)
			this.galleryAcceptFiles = ConversationComponent.C_DEFAULT_GALLERY_FILES_TYPE;

		this.moParentPage.hasHomeButton = !UserData.current.isGuest;
		this.moParentPage.hasBackButton = !UserData.current.isGuest;

		this.updateGroups().subscribe();
	}

	/** Récupère les groupes à partir des ids des participants et assigne la variable `maGroups`. */
	private updateGroups(): Observable<IGroup[]> {
		return this.isvcGroups.getGroups(this.getGroupMemberIds())
			.pipe(
				tap((paGroups: IGroup[]) => this.maGroups = paGroups),
				takeUntil(this.destroyed$)
			);
	}

	/** Gère la date de dernière activité d'un participant et ajuste son statut ('q'/'o') de manière purement visuelle (pas de modification en base de données). */
	private manageVisuParticipantsActivities(): void {
		const lfManage: () => void = () => {
			this.otherParticipantsMap.forEach((poParticipant: IParticipant) => {
				// Si la date actuelle est supérieure de Xmin ou plus de la date d'activité ; 60000 = ms vers min.
				if (poParticipant.activity.activityExpirationDate && DateHelper.compareTwoDates(new Date(), new Date(poParticipant.activity.activityExpirationDate)) > 0) {
					poParticipant.activity.activity = EActivityStatus.quit;
					this.detectChanges();
				}
			});
		};

		lfManage();
		this.mnChekParticipantsActivitiesInterval = window.setInterval(lfManage, ConversationComponent.C_CHECK_PARTICIPANTS_ACTIVITIES_INTERVAL_MS);
	}

	/** Initialise le traitement des messages en fonction de leur état (envoyés sur le serveur ou non) pour l'affichage des coches. */
	private initMessagesStatus(): Observable<void> {
		return this.isvcConversation.getPendingMessages(this.msConvGuid, true)
			.pipe(
				catchError(poError => this.isvcConversation.onError(
					poError,
					`Une erreur est survenue lors de la récupération des messages non envoyés du statut de conversation ${this.msConvGuid}.`
				)),
				map((paPendings: Array<string>) => {
					this.maPendingMessages = paPendings;
					this.updateMessagesStatus();
				})
			);
	}

	/** Traite les messages en fonction de leur état (envoyés sur le serveur ou non) pour l'affichage des 'coches'. */
	private updateMessagesStatus(): void {
		for (let i = this.messages.length - 1; i >= 0; --i) {
			this.messages[i].isSyncToServer = true; // On donne une valeur par défaut.
			for (let j = this.maPendingMessages.length - 1; j >= 0; --j) {
				if (this.messages[i]._id === this.isvcConversation.getMessageIdFromPendingId(this.maPendingMessages[j]))
					this.messages[i].isSyncToServer = false;
			}
		}

		this.onMessagesStatusUpdated();
	}

	/** Une modification d'activité d'un utilisateur a été détectée, il faut la mettre à jour.
	 * @param poActivity objet correspondant à une activité liée à un utilisateur.
	 */
	private onActivityChanged(poActivity: IConversationActivity): void {
		console.debug(`${ConversationComponent.C_LOG_ID}${poActivity._id === this.moUserParticipant?.activity?._id ? "User" : "Participant"} activity '${poActivity._id}' changed to "${poActivity.activity}".`, poActivity);

		const loParticipant: IParticipant<IContact> = this.findParticipant(ConversationService.getContactIdFromActivity(poActivity));

		if (loParticipant && poActivity._deleted) { // Si le participant existe et que l'activité est à l'état supprimé.
			this.otherParticipantsMap.delete(loParticipant.participantPath);
			ArrayHelper.removeElementByProperty(this.conversation.participants, "participantPath", loParticipant.participantPath);
		}
		// Si la révision de l'activité reçue est supérieure à celle en mémoire.
		else if (loParticipant && StoreDocumentHelper.getRevisionNumber(poActivity) > StoreDocumentHelper.getRevisionNumber(loParticipant.activity)) {
			loParticipant.activity = poActivity;

			// Si c'est pas l'activité de l'utilisateur, màj indicateur dernier message lu.
			if (this.moUserParticipant?.participantId !== loParticipant.participantId)
				this.changeReadIndicator(loParticipant);
		}
		else
			console.debug(`${ConversationComponent.C_LOG_ID}Obsolete activity detected, it will be ignored.`);
	}

	/** Une modification sur un contact a été détectée, il faut le mettre à jour.
	 * @param poContact objet correspondant à un contact.
	 */
	private onContactChanged(poContact: IContact): void {
		// TODO
	}

	/** Une modification sur une conversation a été détectée, il faut la mettre à jour.
	 * @param poConversation objet correspondant à une conversation.
	 */
	private onConversationChanged(poConversation: IConversation): void {
		// Si la modification de la conversation est plus récente que l'actuelle (la réplication peut amener des révisions obsolètes), on fait la modification.
		if (StoreDocumentHelper.getRevisionNumber(poConversation) > StoreDocumentHelper.getRevisionNumber(this.conversation)) {
			this.initConversation(poConversation);

			merge(
				this.updateGroups(),
				this.isvcEntityLink.trySetCurrentEntity(this.conversation)
			)
				.pipe(takeUntil(this.destroyed$))
				.subscribe();
		}
	}

	/** Redirige un événement reçu du service vers la fonction appropriée compte-tenu de son type.
	 * @param poEvent Objet d'événement reçu du service, contenant les changements opérés.
	 */
	private onEventRedirection(poEvent: IConversationEvent): void {
		// Si l'id de cette conversation est inclus dans l'id du document ou l'id reçu, alors il est destiné à cette conversation.
		if ((poEvent.data.document && (poEvent.data.document._id as string).indexOf(this.msConvGuid) >= 0) ||
			(!StringHelper.isBlank(poEvent.data.reSendMessageId) && poEvent.data.reSendMessageId.indexOf(this.msConvGuid) >= 0)) {

			if (poEvent.data.eventType === EConversationEvent.default)
				this.onEventDispatcher(poEvent.data.document);
		}
	}

	/** Réceptionne les modifications qui ont lieu dans une conversation, vérifie que cette modification est destinée à cette instance ou non
	 * et réalise les actions nécessaires.
	 * @param poEvent Objet correspondant à l'événement.
	 * @param poDocument Objet document correspondant au document modifié (ajout, modification, suppression).
	 */
	private onEventDispatcher(poDocument: IStoreDocument & { type: EConversationType }): void {
		let lfFunction: () => Observable<void | IConversationActivity>;

		switch (poDocument.type) {
			case EConversationType.activity:
				lfFunction = () => of(this.onActivityChanged(poDocument as IConversationActivity));
				break;

			case EConversationType.contact:
				lfFunction = () => of(this.onContactChanged(poDocument as any as IContact));
				break;

			case EConversationType.conversation:
				lfFunction = () => of(this.onConversationChanged(poDocument as IConversation));
				break;

			case EConversationType.message:
				lfFunction = () => {
					this.onMessageChanged(poDocument as IMessage);
					this.refreshMessages();

					return of(this.addUpdateUserActivityTask());
				};
				break;

			default:
				console.error(`${ConversationComponent.C_LOG_ID}Unknown conversation document type ${poDocument.type} for document ${poDocument._id}.`);
				break;
		}

		if (lfFunction)
			this.addTask(lfFunction, poDocument._id, poDocument.type);
	}

	/** Événement qui survient lors d'un changement de fichier dans la galerie.
	 * @param peValue Nombre de fichiers dans la galerie.
	 */
	public onFilesChanged(peValue: EGalleryFilesChanged): void {
		// Si la galerie n'a pas de fichier sélectionné ET qu'il n'y a pas de texte entré valide alors on bloque l'envoi de message.
		this.canSend = !(peValue === EGalleryFilesChanged.NoFile && StringHelper.isBlank(this.newMessage));
		this.meFilesChangedLastValue = peValue;
	}

	/** Détruit la conversation proprement (abonnements, ...) lorsque l'événement "willLeave" du cycle de vie ionic est lancé. */
	private onViewWillLeave(): void {
		this.stopTappingTimer();

		clearInterval(this.mnChekParticipantsActivitiesInterval);

		this.isvcEntityLink.clearCurrentEntity(this.conversation._id).subscribe();
	}

	/** Actualise l'UI si nécessaire pour tenir compte du changement détecté sur un message.
	 * @param poProcessingMessage Message à traiter.
	 * @returns `false` si le message a été ignoré, `true` sinon.
	 */
	private onMessageChanged(poProcessingMessage: IMessage): boolean {
		const loCachedMessage: IMessageModelItem = this.getMessageModelItem(poProcessingMessage._id);

		if (loCachedMessage.index >= 0)
			return this.updateMessageModel(loCachedMessage, poProcessingMessage);

		else
			return this.addMessageModel(poProcessingMessage);
	}

	/** Tri la liste des messages, rafraîchit la vue et met à jour l'activité de l'utilisateur. */
	private refreshMessages(): void {
		this.isvcConversation.sortMessages(this.messages);
		this.refresh(true);
	}

	/** Recherche un message parmi ceux présents en cache dans le modèle.
	 * @param psMessageId Identifiant du message à récupérer.
	 * @returns L'index dans le modèle des messages et l'objet message correspondant.
	 */
	private getMessageModelItem(psMessageId: string): IMessageModelItem {
		const lnModelMessageIndex: number = this.messages.findIndex((poMessage: IMessage) => poMessage._id === psMessageId);
		let loModelMessage: IMessage = null;

		if (lnModelMessageIndex >= 0)
			loModelMessage = this.messages[lnModelMessageIndex];

		return { index: lnModelMessageIndex, message: loModelMessage };
	}

	/** Détermine si le changement détecté doit être ignoré ou si le message doit être mis à jour dans le modèle,
	 *  actualise le modèle le cas échéant.
	 * @return `true` si le modèle a été mis à jour, `false` sinon.
	 */
	private updateMessageModel(poMessageModelItem: IMessageModelItem, poProcessingMessage: IMessage): boolean {

		if (StoreDocumentHelper.getRevisionNumber(poProcessingMessage) > StoreDocumentHelper.getRevisionNumber(poMessageModelItem.message)) {
			this.prepareMessage(poProcessingMessage);
			this.messages[poMessageModelItem.index] = poProcessingMessage;

			return true; // Message effectivement mis à jour.
		}
		else
			return false; // Message ignoré.
	}

	/** Ajoute un message au tableau des messages à afficher en ajustant ses propriétés.
	 * @param poMessage Nouveau message qu'il faut traiter et ajouter au tableau des messages à afficher.
	 */
	private addMessageModel(poMessage: IMessage): boolean {
		this.prepareMessage(poMessage);
		this.messages.push(poMessage);

		return true;
	}

	/** Ajoute un tableau d'indicateurs de messages lus au message ainsi qu'un booléen indiquant que le message est synchronisé avec le serveur.
	 * @param poReceivedMessage Message reçu qu'il faut préparer.
	 */
	private prepareMessage(poReceivedMessage: IMessage): void {
		poReceivedMessage.isSyncToServer = true;
	}

	/** Prépare et fait les vérifications nécessaires avant d'envoyer le message entré par l'utilisateur. */
	public onMessageSend(): void {
		if (this.canSend) { // Si on peut envoyer un message (pièce jointe ou texte ou les deux), on poursuit, sinon on ne fait rien (attente).
			this.canSend = false;
			this.stopTappingTimer(); // On supprime le timer afin de ne pas mettre à jour l'activité de l'utilisateur inutilement.

			const leMessageType: EMessageType = ArrayHelper.hasElements(this.files) ? EMessageType.document : EMessageType.text;

			if (leMessageType === EMessageType.document) { // On demande au composant de galerie d'enregistrer les pièces jointes dans le DMS.
				this.moGalleryCommandSubject.next({ type: EGalleryCommand.saveFiles, callback: () => this.innerOnSendMessage(leMessageType) });
				this.mbAreAttachmentsSaving = true;
			}
			else
				this.innerOnSendMessage(leMessageType);
		}
	}

	private innerOnSendMessage(leMessageType: EMessageType): void {
		const loMessage: IMessage = {
			... this.createMessage(this.newMessage, leMessageType),
			attachedFiles: this.files.map((poFile: IGalleryFile): IAttachedFile => ({ guid: poFile.guid, name: poFile.name }))
		};

		this.addUpdateUserActivityTask(EActivityStatus.online);

		this.newMessage = ""; // On réinitialise le message pour en taper un nouveau.
		this.areAttachmentsHidden = true; // On cache la galerie.
		this.mbAreAttachmentsSaving = false; // On n'a fini d'enregistrer les pièces jointes.

		this.sendMessage(loMessage).subscribe();
	}

	/** Application mise en arrière plan. */
	private onPause(): void {
		this.mbStopDequeue = true; // On arrête le défilement des tâches.
		clearInterval(this.mnChekParticipantsActivitiesInterval); // On arrête la vérification des activités des participants.

		this.addUpdateUserActivityTask(EActivityStatus.quit); // On met à jour l'activité utilisateur à 'q' parce qu'on passe en arrière plan.
	}

	/** Application revient au premier plan. */
	private onResume(): void {
		this.mbStopDequeue = false; // On autorise de nouveau le défilement des tâches.
		this.manageVisuParticipantsActivities(); // On reprend la vérification des activités des participants.
		this.startDequeue(); // On reprend le défilement des tâches.
		this.addUpdateUserActivityTask(EActivityStatus.online); // On ajoute une tâche pour mettre l'activité utilisateur à jour ('o').
	}

	/** Termine la récupération des messages en mettant à jour l'activité de l'utilisateur et en rafraîchissant la vue. */
	private onMessagesStatusUpdated(): void {
		if (ArrayHelper.hasElements(this.messages) && this.moUserParticipant?.activity)
			this.moUserParticipant.activity.lastReadMessageId = ArrayHelper.getLastElement(this.messages)._id;

		this.mbIsDequeueEventBusy = false;
	}

	/** Rafraîchit la vue pour mettre à jour les bindings.
	 * @param pbScrollToBottom Indique si l'on doit scroller jusqu'en bas de la page.
	 */
	private refresh(pbScrollToBottom: boolean = false): void {
		if (!this.mbIsViewDestroy) {
			if (pbScrollToBottom && this.messagesContainer)
				this.messagesContainer.scrollToTop(ConversationComponent.C_SCROLL_ANIMATION_MS);

			this.detectChanges();
		}
	}

	/** Trie la file pour remettre dans l'ordre les différents documents modifiés que l'on a reçus.
	 * Le tri est chronologique et par ordre d'importance (importance en fonction du type du document).
	 */
	private reorganizeQueue(): void {
		let lnCurrentIndex = 0;
		let lsOutputId: string;
		let lbIsSorted = false;

		// tri par date.
		this.maConversationTasksQueue.sort((poItemA: IConversationTask, poItemB: IConversationTask) => (+new Date(poItemA.params.lastActivityDate) || +new Date(poItemA.params.createDate) || +new Date()) -
			(+new Date(poItemB.params.lastActivityDate) || +new Date(poItemB.params.createDate) || +new Date()));

		// tri par type, les messages d'abord puis le reste en conservant le tri par date.
		while (lnCurrentIndex < this.maConversationTasksQueue.length && !lbIsSorted) {

			if (this.maConversationTasksQueue[lnCurrentIndex].params.type === EConversationType.message) // si le type de l'élément est un message,
				++lnCurrentIndex; // on passe à l'élément suivant.
			else {

				if (lsOutputId && lsOutputId === this.maConversationTasksQueue[lnCurrentIndex].params._id) // si l'id de sortie vaut l'id courant,
					lbIsSorted = true; // alors on a terminé de trier.
				else {
					// on met l'élément courant à la fin de la file.
					ArrayHelper.moveElement(this.maConversationTasksQueue, lnCurrentIndex, this.maConversationTasksQueue.length - 1);

					if (!lsOutputId) // si l'id de sortie n'est pas valable,
						lsOutputId = ArrayHelper.getLastElement(this.maConversationTasksQueue).params._id; // on le renseigne avec l'id de l'élément actuel.
				}
			}
		}
	}

	/** Envoi le message.
	 * @param poMessage Message à envoyer.
	 */
	private sendMessage(poMessage: IMessage): Observable<boolean> {
		this.messages.push(poMessage);
		this.isvcConversation.sortMessages(this.messages);

		return this.isvcConversation.sendMessage$(this.conversation, poMessage)
			.pipe(
				tap(_ => {
					this.addUpdateUserActivityTask(EActivityStatus.online);
					this.refresh();
				}),
				mapTo(true),
				catchError(poSendError =>
					this.isvcConversation.onError(poSendError, "Une erreur est survenue lors de l'enregistrement du message en base de données.").pipe(mapTo(false))
				),
			);
	}

	/** Simule une file d'attente de fonctions à exécuter afin de ne pas avoir de conflits sur pouch dû à l'asynchronisme
	 * (accès multiples à la bdd). Une exécution à la fois, puis la méthode se rappelle pour passer à l'exécution suivante.
	 */
	private startDequeue(): void {
		// Si la file n'est pas occupée et qu'au moins un élément est présent, on l'exécute.
		if (!this.mbIsDequeueEventBusy && ArrayHelper.hasElements(this.maConversationTasksQueue))
			this.dequeue();

		else if (!this.mbIsViewDestroy) {
			// Temps d'attente opur le défilement : temps si file occupée / temps si file vide.
			const lnWait: number = this.mbIsDequeueEventBusy ? ConversationComponent.C_WAIT_DEQUEUE_MS : ConversationComponent.C_WAIT_NEW_ELEMENT_QUEUE_MS;
			setTimeout(() => this.startDequeue(), lnWait); // on attend un certains temps avant de tenter un nouveau défilement.
		}
	}

	/** Démarre la réplication de la base de données des conversations pour écouter les changements opérés dessus. */
	private startLiveReplication(): Observable<boolean> {
		return defer(() => {
			this.isDownloading = true;
			this.detectChanges();
			return this.isvcConversation.downloadConversationDetails(this.msConvGuid);
		})
			.pipe(
				tap(_ => this.mbDownloadConversationDetailsFinished = true),
				mergeMap((poResponse: IStoreReplicationResponse) => {
					return from(poResponse.docs)
						.pipe(
							groupBy((poDocument: IStoreDocument) => poDocument._id),
							mergeMap((poGroupedDocument$: GroupedObservable<string, IStoreDocument>) => {
								return poGroupedDocument$
									.pipe(
										reduce((poLastDocument: IStoreDocument, poCurrentDocument: IStoreDocument) =>
											poLastDocument = StoreDocumentHelper.getObjectWithMaxRevisionFromObjects(poLastDocument, poCurrentDocument)
										)
									);
							}),
							toArray()
						);
				}),
				tap((paDocuments: IStoreDocument[]) => {
					// Gestion des activités supprimées ailleurs.
					this.processConversationDetailsChanges(
						paDocuments.filter((poDocument: IStoreDocument) => !poDocument._deleted && !IdHelper.hasPrefixId(poDocument._id, EPrefix.activity))
					);
					this.manageUserActivity();
					this.addUpdateUserActivityTask(EActivityStatus.online); // Après que la réplication et les traitements sont terminés, on passe à 'online'.
					this.mbDownloadConversationDetailsFinished = true;
					this.isDownloading = false;
				}),
				mergeMap(_ => this.isvcConversation.startConversationDetailsLiveReplication((poEvent: IConversationEvent) =>
					this.onEventRedirection(poEvent), this.msConvGuid)
				),
				catchError(poError => throwError({ error: poError, message: "Erreur lors du démarrage du canal de discussion." } as IError)),
				mapTo(true)
			)
			.pipe(catchError((poError: IError) => this.isvcConversation.onError(poError.error)));
	}

	/** Met à jour les activités et messages de la conversation avec les documents répliqués.
	 * @param paDocuments Tableau des documents répliqués qu'il faut traiter.
	 */
	private processConversationDetailsChanges(paChangedDocuments: IStoreDocument[]): void {
		if (ArrayHelper.hasElements(paChangedDocuments)) {
			const lsConversationDatabaseId: string = StoreHelper.getDatabaseIdFromCacheData(this.conversation);

			paChangedDocuments.forEach((poDocument: IStoreDocument) => {
				switch (IdHelper.getPrefixFromId(poDocument._id)) {

					case EPrefix.activity:
						this.onActivityChanged(poDocument as IConversationActivity);
						break;

					case EPrefix.message:
						if (!StoreHelper.hasCacheData(poDocument))
							StoreHelper.updateDocumentCacheData(poDocument, { databaseId: lsConversationDatabaseId });

						this.onMessageChanged(poDocument as IMessage);
						break;

					case EPrefix.conversation:
						this.onConversationChanged(poDocument as IConversation);
						break;
				}
			});

			this.refreshMessages(); // On ne rafraîchit l'UI qu'au dernier document traité.
		}
	}

	/** Gère la mise à jour de l'activité de l'utilisateur */
	private manageUserActivity(): void {
		merge( // On fusionne 2 sources d'événements afin de pouvoir mettre à jour l'activité de l'utilisateur à des moments clé.
			fromEvent(document.body, ConversationComponent.C_ACTIVITY_TOUCHSTART_EVENT), // Flux du touché sur l'écran.
			this.isvcFlag.observeFlag(ENetworkFlag.isOnlineReliable).pipe(filter((poFlag: IFlag) => poFlag.value)) // Flux du retour réseau.
		)
			.pipe(
				debounceTime(ConversationComponent.C_CHECK_PARTICIPANTS_ACTIVITIES_INTERVAL_MS),
				filter(_ => !!this.moUserParticipant.activity),
				map(_ => {
					const loCurrentDate = new Date();
					// Si la date de dernière activité + X secondes est inférieure à la date actuelle, alors on met à jour l'activité car l'utilisateur agit.
					if (+(this.moUserParticipant.activity.lastActivityDate) + ConversationComponent.C_CHECK_PARTICIPANTS_ACTIVITIES_INTERVAL_MS < +loCurrentDate) {
						this.moUserParticipant.activity.lastActivityDate = loCurrentDate;
						const loStatus: EActivityStatus = this.moUserParticipant.activity.activity === EActivityStatus.tapping ? EActivityStatus.tapping : EActivityStatus.online;
						this.addUpdateUserActivityTask(loStatus);
					}
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Gère s'il faut débuter une réplication live ou la stopper en fonction des flags du réseau qui arrivent et si l'appareil va en arrière plan. */
	private manageLiveReplication(): void {
		this.isvcFlag.waitForFlag(ENetworkFlag.isOnlineReliable, true) // S'il y a du réseau.
			.pipe(
				mergeMap(_ => this.startLiveReplication()),
				retryWhen((poErrors$: Observable<any>) => this.isvcStore.getRequestRetryStrategy(poErrors$)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** L'utilisateur entre un caractère pour écrire un message. Met à jour le statut de l'utilisateur ('tapping' / 'online').
	 * @param psNewValue Chaîne de caractères correspondant au nouveau contenu du message en cours d'écriture.
	 */
	public onTapping(psNewValue: string): void {
		if (!StringHelper.isBlank(psNewValue)) { // Si le texte entré est valide, on peut envoyer un message et on met à jour notre activité.
			this.canSend = true;

			if (this.mnTappingTimer)  // est en train d'écrire.
				this.stopTappingTimer();

			else // n'écrit pas.
				this.addUpdateUserActivityTask(EActivityStatus.tapping);

			// Timer pour refaire passer l'activité de l'utilisateur à 'online' au lieu de 'tapping'.
			this.mnTappingTimer = window.setTimeout(
				() => {
					this.addUpdateUserActivityTask(EActivityStatus.online);
					this.mnTappingTimer = null;
				},
				ConversationComponent.C_TAPPING_MS
			);
		}
		else // Sinon, on regarde les fichiers joints pour savoir si on peut quand même envoyer un message avec pièce jointe ou s'il faut bloquer l'envoi.
			this.canSend = this.meFilesChangedLastValue !== EGalleryFilesChanged.NoFile;
	}

	/** Arrête le timer indiquant que l'utilisateur est en train d'écrire. */
	private stopTappingTimer(): void {
		clearTimeout(this.mnTappingTimer);
		this.mnTappingTimer = null;
	}

	/** Détection de changement dans une collection de données pour indiquer à Angular quel élément il doit rafraîchir.
	 * @param pnIndex index généré par le ngFor.
	 */
	public trackByIndex(pnIndex: number): number {
		return pnIndex;
	}

	/** Met à jour l'activité de l'utilisateur lorsqu'il y a un nouveau message ou lors de l'ouverture de la conversation.
	 * @param peActivityStatus Nouvelle activité de l'utilisateur, `o` par défaut.
	 */
	@Queue<ConversationComponent, Parameters<ConversationComponent["updateUserActivity"]>, ReturnType<ConversationComponent["updateUserActivity"]>>()
	private updateUserActivity(peActivityStatus: EActivityStatus = EActivityStatus.online): Observable<IConversationActivity> {
		if (!this.moUserParticipant) {
			console.error(`${ConversationComponent.C_LOG_ID}Can not update user (${UserData.current?._id ?? ""}) activity because user participant is not defined !`);
			return of(undefined);
		}
		else if (!this.moUserParticipant.activity) {
			console.error(`${ConversationComponent.C_LOG_ID}Can not update user activity beacuse no activity is defined !`);
			return of(undefined);
		}
		else {
			this.moUserParticipant.activity.activity = peActivityStatus;

			// On peut mettre à jour l'activité de l'utilisateur qu'à partir du moment où le téléchargement des données est terminé, sinon conflits possibles.
			return this.isvcConversation.asyncHasNetworkConnection()
				.pipe(
					mergeMap((pbHasNetwork: boolean) => {
						if (this.canUpdateUserActivity(pbHasNetwork))
							return this.execUpdateUserActivity();
						else {
							this.logCannotUpdateUserActivity();
							return of(undefined);
						}
					})
				);
		}
	}

	private canUpdateUserActivity(pbHasNetwork: boolean): boolean {
		return this.mbDownloadConversationDetailsFinished && !this.mbIsDeletedConversation && pbHasNetwork;
	}

	private execUpdateUserActivity(): Observable<IConversationActivity> {
		const lsLastReadMessageId: string = ArrayHelper.hasElements(this.messages) ? ArrayHelper.getFirstElement(this.messages)._id : "";

		console.debug(`${ConversationComponent.C_LOG_ID}User activity changed to ${this.moUserParticipant.activity.activity || 'Ø'}, last read message ID is ${lsLastReadMessageId || "Ø"}.`);

		if (!StringHelper.isBlank(lsLastReadMessageId))
			this.moUserParticipant.activity.lastReadMessageId = lsLastReadMessageId;

		return defer(() => StringHelper.isBlank(this.moUserParticipant.activity._rev) ?
			this.isvcConversation.createActivity(this.moUserParticipant.activity) : this.isvcConversation.updateActivity(this.moUserParticipant.activity)
		)
			.pipe(
				tap(
					(poConvActivity: IConversationActivity) => this.moUserParticipant.activity = poConvActivity,
					poError => console.error(`${ConversationComponent.C_LOG_ID}Error when update user activity '${this.moUserParticipant.activity._id}' :`, poError)
				)
			);
	}

	private logCannotUpdateUserActivity(): void {
		if (this.mbIsDeletedConversation)
			console.debug(`${ConversationComponent.C_LOG_ID}User activity change canceled, conversation deleted.`);
		else
			console.debug(`${ConversationComponent.C_LOG_ID}User activity change deferred, waiting for network or conversation details sync to complete.`);
	}

	/** Permet d'ajouter ou de supprimer des participants à la conversation. */
	@CanExecute("canEdit")
	public updateParticipants(): void {
		if (StringHelper.isBlank(this.conversation._id)) { // Si _id non valide, c'est que la conversation n'est pas encore initialisée.
			this.isvcUiMessage.showMessage(
				new ShowMessageParamsPopup({ message: "Veuillez attendre l'initialisation complète de la conversation avant d'ajouter de nouveaux participants." })
			);
		}
		else {
			const laOldMembers: Array<IContact | IGroup> = this.getContactMembers();

			this.isvcGroups.getGroups(this.getGroupMemberIds())
				.pipe(
					tap((paGroups: IGroup[]) => laOldMembers.push(...paGroups)),
					mergeMap(_ => this.openContactsSelectorModal(laOldMembers)),
					catchError(poError => this.isvcConversation.onError(poError, "Erreur lors de la mise à jour des participants.")),
					// Permet de ne prendre en compte que si on a validé la sélection de contacts (paContacts sera valide).
					filter((paMembers: Array<IContact | IGroup>) => ArrayHelper.hasElements(paMembers)),
					mergeMap((paMembers: Array<IContact | IGroup>) => this.isvcConversation.updateConversation(this.conversation, paMembers, laOldMembers)),
					mergeMap(_ => this.isvcConversation.hydrateConversation(this.conversation, this.moUserParticipant?.model?._id)),
					tap(_ => {
						this.refreshPageTitle();
						this.detectChanges();
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
	}

	private openContactsSelectorModal(paOldMembers: Array<IContact | IGroup>): Observable<IGroupMember[]> {
		const loContactsSelectorParams: IContactsSelectorParams = {
			userContactVisible: false,
			hasSearchbox: true,
			preSelectedIds: paOldMembers.map((poOldMember: IContact | IGroup) => poOldMember._id),
			type: EContactsType.contacts,
			disableItemFunction: (poContact: IContact) => !ConversationHelper.isParticipantEligible(poContact),
			hideAllSelectionButton: ConfigData.conversation.hideAllSelectionButton,
			sort: EContactSelectorSort.byPreSelectedParticipants,
			selectionMinimum: 1
		};

		return this.isvcContacts.openContactsSelectorAsModal(loContactsSelectorParams);
	}

	/** Retourne les contacts membres de la conversation, sans le contact utilisateur ni les contacts appartenant à un groupe de conversation. */
	private getContactMembers(): IContact[] {
		const lsUserParticipantId: string = this.moUserParticipant?.participantId ?? UserHelper.getUserContactId();
		const laContacts: IContact[] = this.contacts; // Pour éviter d'appeler à chaque fois le getter qui fait des opérations.

		return this.conversation.participants
			.filter((poParticipant: IParticipant) => ContactsService.isContact(poParticipant.participantId) && poParticipant.participantId !== lsUserParticipantId)
			.map((poParticipant: IParticipant) => laContacts.find((poContact: IContact) => poContact._id === poParticipant.participantId))
			.filter((poContact: IContact) => !!poContact);
	}

	/** Retourne les identifiants de groupes membres de la conversation. */
	private getGroupMemberIds(): string[] {
		return this.conversation.participants
			.filter((poParticipant: IParticipant) => GroupsService.isGroup(poParticipant.participantId))
			.map((poParticipant: IParticipant) => poParticipant.participantId);
	}

	/** Récupère l'avatar d'un participant à partir de son chemin de contact.
	 * @param psContactPath Chemin vers le contact dont il faut récupérer l'avatar.
	 */
	public getAvatar(psContactPath: string): IAvatar {
		const loParticipant: IParticipant = this.otherParticipantsMap.get(psContactPath);

		return loParticipant ? loParticipant.avatar : undefined;
	}

	/** Navigue vers la fiche contact d'un participant.
	 * @param poParticipant Participant vers lequel on veut naviguer.
	 */
	public goToParticipant(poParticipant: IParticipant<IContact>): void {
		this.isvcContacts.routeToContact(poParticipant.model);
	}

	/** Affiche un message d'avertissement si la fiche du contact est incomplète.
	 * @param poParticipant Participant pour lequel on souhaite afficher le message d'avertissement.
	 */
	public warningToParticipant(poParticipant: IParticipant<IContact>): void {
		if (StringHelper.isBlank(poParticipant.model?.email) && !UserHelper.isUser(poParticipant.model)) {
			this.isvcUiMessage.showMessage(
				new ShowMessageParamsToast({ message: "Ce contact ne peut pas intervenir dans la conversation, veuillez saisir son adresse email." })
			);
		}
	}

	/** Charge la suite des messages.
	 * @param poInfiniteScroll Composant infiniteScroll qui permet de déclencher cette méthode.
	 */
	public loadMoreMessages(poInfiniteScroll: IonInfiniteScroll): void {
		this.moDataSource.viewParams.startkey = ArrayHelper.getLastElement(this.messages)._id;
		this.moDataSource.viewParams.skip = 1;
		this.getMessages()
			.pipe(
				auditTime(500),
				finalize(() => {
					poInfiniteScroll.complete();
					if (ArrayHelper.hasElements(this.messages) && this.moDataSource.viewParams.startkey === ArrayHelper.getLastElement(this.messages)._id)
						this.infiniteScrollEnabled = false;
					this.detectChanges();
				})
			)
			.subscribe();
	}

	/** Ouvre le menu contextuel d'un message.
	 * @param poEvent Événement reçu de l'appui long.
	 * @param poMessage Message concerné par l'ouverture du menu contextuel.
	 * @param poPopoverAnchor Ancre servant au positionnement du menu contextuel.
	 */
	public openMessagePopover(poEvent: Event, poMessage: IMessage): void {
		this.isvcPopover.showCustomPopover(
			PopoverComponent,
			{ items: this.getOpenMessagePopoverItems(poMessage), componentId: GuidHelper.newGuid() },
			poEvent as MouseEvent
		)
			.pipe(
				mergeMap((poPopover: HTMLIonPopoverElement) => poPopover.onDidDismiss()),
				tap(_ => this.detectChanges())
			)
			.subscribe();
	}

	private getOpenMessagePopoverItems(poMessage: IMessage): IPopoverItemParams[] {
		const laPopoverItems: IPopoverItemParams[] = [];
		let lbIsMessageCreatedByUser: boolean;

		if (poMessage.senderContactPath) // Pour rétro-compat des messages avec uniquement la propriété "sender".
			lbIsMessageCreatedByUser = Store.getDocumentIdFromPath(poMessage.senderContactPath) === this.currentContactId;
		else
			lbIsMessageCreatedByUser = GuidHelper.extractGuid((poMessage as any).sender) === GuidHelper.extractGuid(this.currentContactId);

		// Si l'envoyeur est l'utilisateur et que le message peut être supprimé.
		if (lbIsMessageCreatedByUser && !poMessage.isAutoGenerated)
			laPopoverItems.push(this.getOpenMessagePopoverDeleteOrRestoreItem(poMessage));

		laPopoverItems.push({
			title: "Copier le message",
			icon: "copy-outline",
			action: () => of(this.ioClipboard.copy(poMessage.body))
		});

		return laPopoverItems;
	}

	private getOpenMessagePopoverDeleteOrRestoreItem(poMessage: IMessage): IPopoverItemParams {
		return {
			title: poMessage.deleted ? "Restaurer" : "Supprimer",
			icon: poMessage.deleted ? "refresh-outline" : "trash-outline",
			action: () => {
				return this.isvcConversation.deleteOrRestoreMessage(poMessage)
					.pipe(
						mergeMap(_ => {
							if (this.conversation.lastMessage._id === poMessage._id) {
								this.conversation.lastMessage = poMessage;
								return this.isvcConversation.updateConversation(this.conversation);
							}
							else
								return of(undefined);
						})
					);
			}
		};
	}

	/** Ouvre un sélecteur d'entités pour lier une/des entité(s) à la conversation. */
	@CanExecute("canEdit")
	public selectEntities(): void {
		if (this.canAddLink)
			this.selectAsyncEntities().subscribe();
	}

	private selectAsyncEntities(paEntityBuilders?: EntityBuilder[], poSelectorParams?: ISelectorParams<IEntityLink>): Observable<IEntity[]> {
		return this.isvcEntityLink.selectEntities(this.conversation, paEntityBuilders, poSelectorParams)
			.pipe(
				mergeMap((paSelectedEntities: IEntity[]) => this.isvcConversation.saveConversationLinks(this.conversation).pipe(mapTo(paSelectedEntities))),
				tap(_ => this.moEntitiesUpdatedSubject.next()),
				takeUntil(this.destroyed$)
			);
	}

	/** Ouvre un formulaire afin de l'envoyer par message.
	 * @param poMessage Message contenant le formulaire.
	 * @param poFormConfig Configuration du formulaire pour la conversations.
	 */
	public openForm(poMessage?: IMessage, poFormConfig?: IConversationFormConfig): void {
		const lnConversationFormsLength: number = ConfigData.conversation.forms.length;

		if (lnConversationFormsLength === 1 || poFormConfig) {
			this.innerOpenForm_oneForm(poMessage, poFormConfig)
				.pipe(
					catchError(poError => {
						if (poError instanceof EmptyError)
							return of(false);
						else {
							console.error(`${ConversationComponent.C_LOG_ID}Error opening form with label '${poFormConfig?.label ?? ""}' :`, poError);
							return EMPTY;
						}
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}

		else if (lnConversationFormsLength === 0)
			console.warn(`${ConversationComponent.C_LOG_ID}No form to add to conversation.`);

		else // Plusieurs formulaires présents.
			console.warn(`${ConversationComponent.C_LOG_ID}Some different forms to add to conversation not implemented yet.`);
	}

	private innerOpenForm_oneForm(poMessage: IMessage, poFormConfig?: IConversationFormConfig): Observable<boolean> {
		return this.getFormModel(poMessage)
			.pipe(
				mergeMap((poSourceModel: IStoreDocument) => this.getFormParamsFromConvFormConfig(poSourceModel, poFormConfig)),
				mergeMap((poParams: IFormParams) => {
					return this.isvcPageManager.routePageFromInfo(new PageInfo({
						componentName: "form",
						params: poParams,
						isModal: true
					}));
				})
			);
	}

	/** Met à jour certains éléments de l'IU après modification de la conversation. */
	private refreshPageTitle(): void {
		this.setParentPageTitle();
	}

	/** Permet de transformer la configuration de formulaire pour la conversation en configuration de formulaire valide.
	 * @param poSourceModel Modèle lié au formulaire.
	 * @param poFormConfig Configuration du formulaire pour la conversations.
	 */
	private getFormParamsFromConvFormConfig(poSourceModel: IStoreDocument, poFormConfig: IConversationFormConfig): Observable<IFormParams>;
	/** Permet de transformer la configuration de formulaire pour la conversation en configuration de formulaire valide.
	 * @param poSourceModel Modèle lié au formulaire.
	 * @param pnIndex Index de la configuration du formulaire pour la conversations. 0 par défaut.
	 */
	private getFormParamsFromConvFormConfig(poSourceModel: IStoreDocument, pnIndex?: number): Observable<IFormParams>;
	private getFormParamsFromConvFormConfig(poSourceModel: IStoreDocument, poIndexOrFormConfig?: number | IConversationFormConfig): Observable<IFormParams> {
		const loFormParams: IFormParams = {
			// @see https://dev.azure.com/calaosoft/osapp-project/_workitems/edit/2333
			// Permet de ne pas faire disparaitre le bouton "entités liées" après ouverture du formulaire.
			disableEntityTracking: true
		};

		if (!poIndexOrFormConfig)
			poIndexOrFormConfig = 0;

		const loConvFormConfig: IConversationFormConfig = typeof poIndexOrFormConfig === "number" ? ConfigData.conversation.forms[poIndexOrFormConfig] : poIndexOrFormConfig;
		let loGetFormParams$: Observable<IFormParams> = of(loFormParams);

		if (loConvFormConfig) {
			this.innerGetFormParamsFromConvFormConfig_syncParams(loConvFormConfig, loFormParams, poSourceModel);

			if (loConvFormConfig.getParentEntity)
				loGetFormParams$ = this.innerGetFormParamsFromConvFormConfig_getParentEntity(loConvFormConfig, loFormParams, poSourceModel);
		}

		return loGetFormParams$;
	}

	private innerGetFormParamsFromConvFormConfig_syncParams(poConvFormConfig: IConversationFormConfig, poFormParams: IFormParams, poSourceModel: IStoreDocument): void {
		poFormParams.formDescriptorId = poConvFormConfig.getFormDescId();
		poFormParams.formDefinitionId = poConvFormConfig.getFormDefId();
		poFormParams.model = JSON.parse(JSON.stringify(poSourceModel));

		poFormParams.customSubmit = (poModel: IStoreDocument, poForm: FormComponent, psTargetDatabase?: string, psActionAfterSave?: string) => poForm.submit(poModel, psTargetDatabase, psActionAfterSave)
			.pipe(
				mergeMap((poResponse: IStoreDocument) => {
					if (this.moDataSource.databaseId) {
						// Lie l'entité de formulaire créé, à la conversation.
						return this.linkConvEntityToModel(poModel)
							.pipe(
								mapTo(poResponse),
								catchError((poError: any) => {
									console.error(`${ConversationComponent.C_LOG_ID}Error to link entity `, poModel, "to conversation ", this.conversation, "Error : ", poError);
									return of(poResponse);
								})
							);
					}
					else
						return of(poResponse);
				}),
				mergeMap((poResponse: IStoreDocument) =>
					this.sendFormEditionMessage(
						poConvFormConfig.getFormEditionMessage(poSourceModel, poResponse), `${poForm.formDescriptorId}/${poForm.formDefinitionId}`, Store.getDocumentPath(poResponse)
					)
				));
	}

	/** Fonction permettant de lier une entité du store à la conversation courante. */
	private linkConvEntityToModel(poModel: IStoreDocument): Observable<boolean> {
		this.isvcEntityLink.cacheLinkToAdd(poModel, this.isvcEntityLink.buildEntity(this.conversation));
		return this.isvcEntityLink.saveEntityLinks(poModel);
	}

	private innerGetFormParamsFromConvFormConfig_getParentEntity(poConvFormConfig: IConversationFormConfig, poFormParams: IFormParams, poSourceModel?: IStoreDocument): Observable<IFormParams> {
		return poConvFormConfig.getParentEntity(this.conversation, poSourceModel)
			.pipe(
				mergeMap((poEntity: IEntity) => {
					if (poEntity)
						return of(poEntity);
					else {
						if (!poConvFormConfig.getEntityBuilders)
							console.error(`${ConversationComponent.C_LOG_ID}Conv form config has function 'getParentEntity' but not 'getEntityBuilders'.`);

						return this.selectEntity(poConvFormConfig);
					}
				}),
				tap((poEntity: IEntity) => poFormParams.parentEntity = poEntity),
				mapTo(poFormParams)
			);
	}

	private selectEntity(poConvFormConfig: IConversationFormConfig): Observable<IEntity> {
		const loGetSelectorParamsResult: ISelectorParams<IEntityLink> = poConvFormConfig.getSelectorParams ? poConvFormConfig.getSelectorParams() : undefined;

		return this.selectAsyncEntities(poConvFormConfig.getEntityBuilders(), loGetSelectorParamsResult)
			.pipe(
				filter((paSelectedEntities: IEntity[]) => ArrayHelper.hasElements(paSelectedEntities)),
				mergeMap((paSelectedEntities: IEntity[]) => {
					if (paSelectedEntities.length === 1) {
						const loSelectedEntity: IEntity = ArrayHelper.getFirstElement(paSelectedEntities);
						const loConversation: IEntity = this.isvcEntityLink.buildEntity(this.conversation);
						const loEntityLink: IEntityLink = this.isvcEntityLink.buildEntityLink(loConversation, loSelectedEntity);
						const loEntityCache: ICacheData = StoreHelper.getDocumentCacheData(loSelectedEntity.model);

						if (!loEntityCache || !loEntityCache.databaseId) {
							console.error(`${ConversationComponent.C_LOG_ID}Can not get cacheData for entity `, loSelectedEntity.model);
							return throwError("Impossible de récupérer l'entité liée.");
						}
						else {
							this.isvcEntityLink.buildEntityFromIdAndDatabaseId(loSelectedEntity.id, loEntityCache.databaseId);
							return this.isvcEntityLink.buildEntityFromIdAndDatabaseId(EntityHelper.getEntityLinkPartFromSourcePrefix(loEntityLink, EPrefix.conversation));
						}
					}
					else {
						console.error(`${ConversationComponent.C_LOG_ID}Some selected entities but not implemented yet.`);
						return throwError(`${ConversationComponent.C_LOG_ID}Not implemented !`);
					}
				})
			);
	}

	/** Récupère le modèle lié au message.
	 * @param poMessage
	 */
	private getFormModel(poMessage?: IMessage): Observable<IStoreDocument> {
		if (poMessage && !StringHelper.isBlank(poMessage.formEntryPath)) {
			const loDataSource: IDataSource = {
				databaseId: Store.getDatabaseIdFromDocumentPath(poMessage.formEntryPath),
				viewParams: { include_docs: true, key: Store.getDocumentIdFromPath(poMessage.formEntryPath) }
			};
			return this.isvcStore.getOne<IStoreDocument>(loDataSource)
				.pipe(
					catchError(poError => {
						this.isvcUiMessage.showMessage(
							new ShowMessageParamsPopup({
								header: "Erreur de récupération.",
								message: "Vous n'avez pas accès à cette donnée ou elle n'existe plus.",
								backdropDismiss: false
							})
						);

						console.error(`${ConversationComponent.C_LOG_ID}Can not get form model with path '${poMessage.formEntryPath}'`, poError);
						return throwError(poError);
					})
				);
		}
		else
			return of(null);
	}

	/** Envoi un message de type formulaire.
	 * @param psMessage Texte du message.
	 * @param psFormDefPath Chemin vers la définition du formulaire.
	 * @param psEntryPath Chemin vers la donnée présentée par le formulaire.
	 */
	private sendFormEditionMessage(psMessage: string, psFormDefPath: string, psEntryPath: string): Observable<boolean> {
		const loMessage: IMessage = this.createMessage(psMessage, EMessageType.form);

		loMessage.formDefPath = psFormDefPath;
		loMessage.formEntryPath = psEntryPath;

		return this.sendMessage(loMessage);
	}

	@CanExecute("canEdit")
	public routeToConversationEdit(): void {
		this.isvcConversation.routeToConversationEdit(this.conversation, this.contacts, this.maGroups);
	}

	/** Permet de naviguer vers une entité liée.
	 * @param poLinkedEntity
	 */
	@CanExecute("canEdit")
	public goToLinkedEntity(poLinkedEntity: IEntityLink): void {
		this.isvcEntityLink.routeToLinkedItem(poLinkedEntity, this.convId)
			.pipe(takeUntil(this.destroyed$))
			.subscribe();
	}

	/** Récupère le nom à afficher de l'expéditeur du message.
	 * @param poMessage message envoyé.
	 */
	public getSenderDisplayName(poMessage: IMessage): string {
		return ContactHelper.getCompleteFormattedName(this.otherParticipantsMap.get(poMessage.senderContactPath).model);
	}

	//#endregion

}
