import { coerceArray } from "@angular/cdk/coercion";
import { ArrayHelper, StringHelper } from "@osapp/helpers";
import { IdHelper } from "@osapp/helpers/idHelper";
import { UserData } from "@osapp/model/application/UserData";
import { EPrefix } from "@osapp/model/EPrefix";
import { IStoreDocument } from "@osapp/model/store/IStoreDocument";
import { PrestationsIdBuilder } from "@osapp/modules/prestation/models/prestation-id-builder";
import { C_PREFIX_TRAITEMENT } from "../../../app/app.constants";
import { IIdlPrestationIdBuilderParams } from "./iidl-prestation-id-builder-params";

export class IdlPrestationIdBuilder extends PrestationsIdBuilder<IIdlPrestationIdBuilderParams>{

	//#region METHODS

	public buildId(poParams: IIdlPrestationIdBuilderParams): string {
		return IdHelper.buildChildId(EPrefix.prestation,
			IdHelper.buildChildId(
				C_PREFIX_TRAITEMENT,
				IdHelper.buildVirtualNode([
					, // On injecte le guid du traitement ou le préfixe
					UserData.currentSite._id,
					this.buildPrestationDate(poParams.prestationDate),
					poParams.customerIdOrPrefix,
				]),
				IdHelper.getLastGuidFromId(poParams.traitementId)
			),
			poParams.prestationGuid
		);
	}

	public buildFilterFunction(poParams: IIdlPrestationIdBuilderParams): (poDoc: IStoreDocument) => boolean {
		const laTraitementsGuids: string[] = (StringHelper.isBlank(poParams.traitementId) ? poParams.traitementIds : coerceArray(poParams.traitementId))?.map(
			(psTraitementId: string) => IdHelper.getLastGuidFromId(psTraitementId)
		);

		return super.buildFilterFunction(poParams) ?? (ArrayHelper.hasElements(laTraitementsGuids) ?
			(poDoc: IStoreDocument) => laTraitementsGuids.includes(IdHelper.getLastGuidFromId(IdHelper.extractParentId(poDoc._id))) :
			undefined
		);
	}

	//#endregion

}
