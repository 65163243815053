import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { UserHelper } from '../../../helpers/user.helper';
import { IContact } from '../../../model/contacts/IContact';
import { SectionInfo } from '../../../model/menu/SectionInfo';
import { IAvatar } from '../../../model/picture/IAvatar';
import { ContactsService } from '../../../services/contacts.service';
import { PatternResolverService } from '../../../services/pattern-resolver.service';

@Component({
	selector: 'calao-section',
	templateUrl: 'section.component.html',
	styleUrls: ['section.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionComponent extends ComponentBase implements OnInit {

	//#region PROPERTIES

	/** Id du template à utiliser. */
	public templateId: "default" | "expandable" | "invisible" | "avatar" = "expandable";

	/** Contient les infos de la section, dont les liens qu'il contient. */
	@Input() public sectionInfo: SectionInfo;
	/** Booléen indiquant l'état de la section, fermée ou développée utilisé si expendable. */
	@Input() public isClosed = true;

	/** Avatar du contact utilisateur. */
	public userAvatar: IAvatar;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcPatternResolver: PatternResolverService,
		private readonly iscContacts: ContactsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poChangeDetectorRef);
	}

	/** Endroit où initialiser le composant après sa création. */
	public ngOnInit(): void {
		if (!this.sectionInfo) // Si le paramètre n'est pas renseigné, on l'initialise sans valeur.
			this.sectionInfo = new SectionInfo({} as any);

		if (this.sectionInfo.templateId)
			this.templateId = this.sectionInfo.templateId;

		if (this.sectionInfo.isClosed !== undefined)
			this.isClosed = this.sectionInfo.isClosed;

		// Modifie le label si celui-ci contient des paramètres dynamiques.
		this.sectionInfo.label = this.isvcPatternResolver.replaceDynParams(this.sectionInfo.label);

		if (this.templateId === "expandable") {
			this.iscContacts.getContact(UserHelper.getUserContactId(), undefined, true)
				.pipe(
					tap((poContact: IContact) => {
						this.userAvatar = ContactsService.createContactAvatar(poContact, undefined, true);
						this.detectChanges();
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
	}

	/** Ouvre ou ferme la section. */
	public toggleExtend(): void {
		this.isClosed = !this.isClosed;
	}

	//#endregion
}