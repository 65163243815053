import { DateHelper } from "@osapp/helpers";
import { ETimetablePattern } from "@osapp/model/date/ETimetablePattern";
import { ModelMatch } from "@osapp/modules/utils/models/decorators/model-match.decorator";
import { Type } from "class-transformer";
import { Constraint } from "./constraint";
import { EConstraintType } from "./EConstraintType";
import { IActeOccurrence } from "./iacte-occurrence";
import { IMoveConstraint } from "./imove-constraint";

const C_TYPE = EConstraintType.moveTo;

@ModelMatch((poData: IMoveConstraint) => poData?.type === C_TYPE, Constraint)
export class MoveConstraint extends Constraint implements IMoveConstraint {

	//#region PROPERTIES

	public readonly type: EConstraintType = C_TYPE;

	@Type(() => Date)
	/** @implements */
	public date?: Date;

	//#endregion

	//#region METHODS

	/** @override */
	public action(poActeOccurrence: IActeOccurrence): void {
		if (DateHelper.isDate(this.date))
			poActeOccurrence.date = this.date;
	}

	/** @override */
	public buildlabel(): string {
		if (DateHelper.isDate(this.date))
			return `déplacée le ${DateHelper.transform(this.date, ETimetablePattern.EEE_dd_MMMM_yyyyy_HH_mm)}.`;
		return "non déplacée.";
	}

	//#endregion

}
