import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EPrefix, IContact } from '@osapp/model';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ContactsService } from '@osapp/services';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { map, takeUntil } from 'rxjs/operators';
import { MedecinService } from '../../../shared/services/medecin.service';
import { PanneauService } from '../../../shared/services/panneau.service';

@Component({
  selector: 'di-panneau-choix-medecin',
  templateUrl: './panneau-choix-medecin.component.html',
  styleUrls: ['./panneau-choix-medecin.component.scss'],
})
export class PanneauChoixMedecinComponent extends DestroyableComponentBase implements OnInit {

  public model: IPatient;

  public contacts: IContact[];

  constructor(
    private svcContacts: ContactsService,
    private svcPanneau: PanneauService,
    private svcMedecin: MedecinService,
    private router: Router) {
			super()
		 }

  ngOnInit() {
    this.svcContacts.getSiteContactsAnakin([], EPrefix.contact, true, false).pipe(
      map((contacts: IContact[]) => {
        return contacts.map((contact: IContact) => {
					if(!contact.isDoctor && contact.finess)
         		contact.isDoctor = true;
          return contact;
        });
      }),
			takeUntil(this.destroyed$)
    ).subscribe((contacts: IContact[]) => {
      this.contacts = contacts.filter(contact => contact.isDoctor);
    });
  }

  public selectContact(contactId: string) {
    this.svcMedecin.emitSelectMedecin(contactId)
      this.svcPanneau.close();
  }

  handleClickAddContact() {
    this.svcPanneau.close();
    // this.router.navigate(["contacts", "add", ordoId]);
  }

}
