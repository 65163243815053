<osapp-header-modal [title]="title" [customClose]="customClose"></osapp-header-modal>

<ion-content>
	<idl-patient-selector #selector [params]="params" (selectionChanged)="onPatientSelectionChanged($event)">
	</idl-patient-selector>
</ion-content>


<ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">

	<ion-fab-button *ngIf="canCreatePatient && (!params || params.canCreate !== false)" color="osapp-element"
		(click)="createNewPatient()" data-cy="fab-button-people-add" title="Ajouter">
		<ion-icon name="person-add"></ion-icon>
	</ion-fab-button>
	<ion-fab-button color="success" [disabled]="!selector.isValid" (click)="close(patients)"
		data-cy="fab-button-checkmark" title="Valider">
		<ion-icon name="checkmark"></ion-icon>
	</ion-fab-button>

</ion-fab>