<div class="container">

	<div>
		<osapp-selector displayMode="tags" [options]="typeOptions" scrollWrapper="false"
			(selectionChanged)="onSelectedTypeChanged($event)">
		</osapp-selector>
	</div>

	<div class="list-ctn">
		<ng-container *ngIf="amoPList?.length > 0 && (selectedType === prefixAMOP || selectedType === undefined)">
			<div class="header">
				<ion-label>AMO</ion-label>
				<div class="line"></div>
			</div>

			<div *ngFor="let amoP of amoPList">
				<ng-container *ngTemplateOutlet="couvertureItem; context: { couverture: amoP }">
				</ng-container>
			</div>
		</ng-container>

		<ng-container *ngIf="amcPList?.length > 0 && (selectedType === prefixAMCP || selectedType === undefined)">
			<div class="header">
				<ion-label>AMC</ion-label>
				<div class="line"></div>
			</div>

			<div *ngFor="let amcP of amcPList">
				<ng-container *ngTemplateOutlet="couvertureItem; context: { couverture: amcP }">
				</ng-container>
			</div>
		</ng-container>

		<!-- Message si aucun résultat. -->
		<div *ngIf="!hasSearchResult" class="empty-screen flex-col">
			<span class="txt-message">
				<ion-icon name="information-circle" class="no-result"></ion-icon>
				Aucune couverture.
			</span>
		</div>

	</div>
</div>

<!-- Message si aucun résultat. -->
<ng-template #couvertureItem let-couverture="couverture">
	<ion-item-sliding #itemSliding>
		<ion-item (click)="openCouverture(couverture)" lines="none">
			<ion-grid>
				<ion-row>
					<ion-col size="5">
						<ion-label class="header-label">Tiers</ion-label>
						<ion-label *ngIf="couverture.amoId as amoId">{{ getLabelCouverture(couverture.amoId) }}</ion-label>
						<ion-label *ngIf="couverture.amcId as amcId">{{ etablissementsById.get(amcId)?.label }}</ion-label>
					</ion-col>
					<ion-col size="3">
						<ion-label class="header-label">Garantie</ion-label>
						<ion-label>{{ couverture.garantie }}</ion-label>
					</ion-col>
					<ion-col size="1">
						<ion-label class="header-label">TP</ion-label>
						<ion-label>{{ couverture.tp ? "✓" : "" }}</ion-label>
					</ion-col>
					<ion-col size="3">
						<ion-label class="header-label">Période</ion-label>
						<div>
							<ion-label [ngClass]="!couverture.isActive ? 'warning' : ''">
								{{ couverture.dateDebut ? (couverture.dateDebut | dateWithLocale : 'fr-FR' : 'dd/MM/yy') : "-" }}<br>
								{{ couverture.dateFin ? (couverture.dateFin | dateWithLocale : 'fr-FR' : 'dd/MM/yy') : "-" }}
							</ion-label>
						</div>
					</ion-col>
				</ion-row>
				<ion-row>
					<ion-col size="12">
						<ion-label class="header-label">Complément</ion-label>
						<ion-label>{{ getComplementCouverture(couverture) }}</ion-label>
					</ion-col>
				</ion-row>
			</ion-grid>

			<ion-button class="btn-more" slot="end" fill="clear" (click)="openOrCloseItemSliding(itemSliding, $event)">
				<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
			</ion-button>
		</ion-item>
		<ion-item-options side="end">
			<ion-item-option color="danger" (click)="deleteCouverture(couverture)">
				<ion-icon name="trash" slot="icon-only"></ion-icon>
				<ion-label>Supprimer</ion-label>
			</ion-item-option>
		</ion-item-options>
	</ion-item-sliding>
</ng-template>