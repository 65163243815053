import { StringHelper } from '../../helpers';
import { ISite } from '../../modules/sites/models/isite';
import { EntityBuilder } from '../../services/EntityBuilder';
import { EPrefix } from '../EPrefix';
import { EAvatarSize } from '../picture/EAvatarSize';
import { EDatabaseRole } from '../store/EDatabaseRole';
import { Entity } from './Entity';
import { GroupEntityGuard } from './GroupEntityGuard';

export class SiteEntityBuilder extends EntityBuilder<ISite>{
	constructor() {
		super(
			(psModelId: string) => RegExp(EPrefix.site).test(psModelId),
			(poSite: ISite) => new Entity(
				poSite,
				`sites/${poSite._id}`,
				() => StringHelper.isBlank(poSite.name) ? `Nouveau site` : poSite.name
			),
			(psModelId: string) => "Sites",
			EDatabaseRole.contacts,
			EPrefix.site,
			() => ({
				size: EAvatarSize.medium,
				icon: "center"
			}),
			undefined,
			undefined,
			undefined,
			new GroupEntityGuard()
		);
	}
}