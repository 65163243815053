import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CalaoAvatarModule } from '../avatar/calao-avatar.module';
import { ContactItemComponent } from './components/contact-item/contact-item.component';
import { ContactSelectorItemComponent } from './components/contact-selector-item/contact-selector-item.component';

const components: Type<any>[] = [
	ContactItemComponent,
	ContactSelectorItemComponent
];

@NgModule({
	declarations: components,
	imports: [
		IonicModule,
		CommonModule,
		CalaoAvatarModule
	],
	exports: components
})
export class ContactsModule { }