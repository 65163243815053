import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IdHelper, StringHelper } from '@osapp/helpers';
import { EPrefix, IContact } from '@osapp/model';
import { ContactsService } from '@osapp/services';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { mergeMap } from 'rxjs/operators';
import { EExerciceCondition } from '../../../../../modules/patients/model/EExerciceCondition';
import { ConfirmationSuppressionComponent } from '../../../shared/components/confirmation-suppression/confirmation-suppression.component';
import { DrawerPopoverService } from '../../../shared/services/drawer-popover.service';
import { GeocodingService } from '../../../shared/services/geocoding.service';
import { optsConditionExercice, optsSpeExercice } from "../model/constants";


@Component({
  selector: 'di-form-contacts',
  templateUrl: './form-contacts.component.html',
  styleUrls: ['./form-contacts.component.scss'],
})
export class FormContactsComponent implements OnInit, OnChanges {

  public isMedecin: boolean = false;
  public isCallDisabled: boolean = false;
  public isEmailDisabled: boolean = false;
  public isItineraryDisabled: boolean = false;
  public isMessageDisabled: boolean = true;
  public texteSecondaireIsMedecin: string = "Il sera proposé lors de la saisie d'une ordonnance.";
  public mode: "add" | "edit" = "add";
	
	public optsConditionExercice = optsConditionExercice;
  public optsSpeExercice = optsSpeExercice;

  @Input() public contact?: IContact = null;
  @Input() public patient?: IPatient = null;

	@ViewChild('confirmationSuppression', { static: false }) confirmationSuppression!: ElementRef<HTMLDivElement>;

  @Output() onDeleteContact: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSaveContact: EventEmitter<string> = new EventEmitter<string>();
  @Output() onCancelContact: EventEmitter<Event> = new EventEmitter();


  public contactForm: FormGroup;

  public idRetourPatient: string | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private svcContact: ContactsService,
    private fb: FormBuilder,
		private svcDrawerPopover : DrawerPopoverService,
		private svcGeocoding : GeocodingService
  ) { }

  ngOnInit() {
    this.initContact();
    this.initModeForm();
    this.initFormContact();
    this.route.paramMap.subscribe(params => {
      this.idRetourPatient = params.get('idRetourPatient');
    });
  }

  ngOnChanges() {
    this.initContact();
    this.initModeForm();
    this.initFormContact();
  }

  initFormContact() {
    this.contactForm = this.fb.group({
      firstName: [this.contact.firstName ?? ""],
      lastName: [this.contact.lastName ?? "", Validators.required],
      finess: [this.contact.finess ?? ""],
      facturationNumber: [this.contact.rpps ?? this.contact.adeli ?? ""],
      phone: [this.contact.phone ?? ""],
      email: [this.contact.email ?? "", Validators.email],
      street: [this.contact.street ?? ""],
      zipCode: [this.contact.zipCode ?? ""],
      city: [this.contact.city ?? ""],
      comment: [this.contact.comment ?? ""],
      exerciceCondition: [this.contact.exerciceCondition ?? EExerciceCondition.liberal],
			codeSpecialite: [this.contact.codeSpecialite ?? ""]

    });

    this.initContactInfosMedical();
    this.controlValidationForm();
  }

	public shouldShowSpecialiteSelect(): boolean {
    return this.contactForm.get('exerciceCondition')?.value === EExerciceCondition.salarie || this.contactForm.get('exerciceCondition')?.value === EExerciceCondition.benevole;
  }

  initContact() {
    if (!this.contact) {
      this.initContactDefault();

      //Permet de récupérer le contact si on sélectionne un contact en vue mobile 
      //ou 
      //si un contact est sélectionné et que l'on passe en vue mobile
      this.route.paramMap.subscribe(params => {
        const contactId = params.get('id');
        if (contactId) {
          this.getContact(contactId).then(() => {
						this.initValueErgo()
            this.initModeForm();
            this.initFormContact();
          });
        }
      });
    }
  }

	private initValueErgo() :void{
		this.contact.isDoctor = this.contact.isDoctor || !!this.contact.finess;
	}

  private initContactInfosMedical() {
    this.isMedecin = this.contact.isDoctor;
  }

  initContactDefault() {
    this.contact = {
      _id: "",
      lastName: "",
      firstName: "",
      exerciceCondition: EExerciceCondition.liberal
    };
  }

  initModeForm() {
    this.mode = this.contact._id ? "edit" : "add";
  }

  toggleIsMedecin(isMedecin: boolean) {
    //Le switch me retourne la nouvelle valeur du slider
    this.isMedecin = isMedecin;
    this.resetInfosMedical();
  }

  handleClickSaveBtn() {
    //penser à ajouter les anti-click a répétition
    this.saveContact();
  }

  handleClickCancelBtn() {
    this.initFormContact();
    this.onCancelContact.emit();
  }

  makeCallContact() {
    if (this.contact?.phone) {
      window.location.href = `tel:${this.contact.phone}`;
    }
  }

  sendMailContact() {
    if (this.contact?.email) {
      window.location.href = `mailto:${this.contact?.email}`;
    }
  }

  deleteContact() {
    //TODO: ajouter un loader
		this.svcDrawerPopover.open("Confirmation de suppression","50%",this.confirmationSuppression.nativeElement, ConfirmationSuppressionComponent, {
			onConfirm: () => this.handleConfirm(),
			onCancel: () => this.handleCancel()
			})
	}

	private handleConfirm (){
		this.svcDrawerPopover.close();
		this.svcContact.deleteContactAnakin(this.contact).subscribe((isDeleted: boolean) => {
			if (isDeleted) {
				this.onDeleteContact.emit(this.contact._id);
			}
		});
	}

	private handleCancel (){
		this.svcDrawerPopover.close();
	}




  private resetInfosMedical() {
    this.contactForm.patchValue({
      finess: '',
      facturationNumber: '',
      exerciceCondition: EExerciceCondition.liberal
    });
  }

  private async getContact(idContact) {
    this.contact = await this.svcContact.getContact(idContact).toPromise();
  }

  private controlValidationForm(): void {
    Object.keys(this.contactForm.controls).forEach(field => {
      const control = this.contactForm.get(field);
      control?.updateValueAndValidity();
    });
  }

  private saveContact() {
    this.contact = { ...this.contact, ...this.contactForm.value };
		this.contact = this.svcContact.setRppsAdeliValue(this.contact);
    if (this.isMedecin) {
      //s'il est medecin
      this.contact.isDoctor = this.isMedecin;
      if (!this.contact.exerciceCondition) {
        this.contact.exerciceCondition = null;
      }
    } else {
      //s'il n'est pas medecin il ne doit pas avoir ses champs
      delete this.contact.exerciceCondition;
      delete this.contact.facturationNumber;
      delete this.contact.finess;
      this.contact.isDoctor = this.isMedecin;
    }

    if (StringHelper.isBlank(this.contact._id)) {
      this.contact._id = IdHelper.buildId(EPrefix.contact);
    }

    // Dans le cas où c'est le contact d'un patient
    if (this.patient) {
      this.contact.patient = this.patient._id;
    }

    //TODO: ajouter un loader
    this.svcContact.saveContactAnakin(this.contact).subscribe((isSaved: boolean) => {
      if (isSaved) {
        this.onSaveContact.emit(this.contact._id);
        // si la page n'affiche que le formulaire alors on redirect après la sauvegarde du patient
        if (this.idRetourPatient) {
          this.router.navigate(["patients", this.idRetourPatient])
        } else {
          this.router.navigate(["contacts"])
        }
      }
    });
  }

	public navigateToAddress() {
    const address = `${this.contact.street} ${this.contact.zipCode} ${this.contact.city}`;
    this.svcGeocoding.getGeolocation(address).pipe(
			mergeMap(coords => {
        return this.svcGeocoding.navigateToCoordinates(coords.lat, coords.lng);
      })
    ).subscribe();
  }
}