import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './purchasesPage.component.html'
})
export class PurchasePageComponent implements OnInit {
  //#region FIELDS
  
  //#endregion

  //#region PROPERTIES
  
  //#endregion

  //#region METHODS
  constructor() {
  }

  public ngOnInit(): void {
  }
  //#endregion
}