import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { DateRangePickerComponent } from '../../../../components/date/date-range-picker/date-range-picker.component';
import { IDateRange } from '../../../../components/date/date-range-picker/model/IDateRange';
import { DateHelper } from '../../../../helpers/dateHelper';
import { ETimetablePattern } from '../../../../model/date/ETimetablePattern';
import { DateWithLocalePipe } from '../../../../pipes/dateWithLocale.pipe';
import { FilterbarBaseComponent } from '../../model/FilterbarBaseComponent';
@Component({
	selector: 'calao-filterbar-date-range-picker',
	templateUrl: './filterbar-date-range-picker.component.html',
	styleUrls: ['../filterbar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterbarDateRangePickerComponent<T = IDateRange, U = string> extends FilterbarBaseComponent<T, U> implements OnDestroy {

	//#region FIELDS

	private readonly moDateRangeSubject = new ReplaySubject<T>(1);

	//#endregion

	//#region PROPERTIES

	@ViewChild("dateRange") public readonly dateRangePicker: DateRangePickerComponent;

	//#endregion

	//#region METHODS

	constructor(private ioDateWithLocalePipe: DateWithLocalePipe, poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	/** @implements */
	public ngOnDestroy(): void {
		this.moDateRangeSubject.complete();
		super.ngOnDestroy();
	}

	protected transform(poValue: T): U;
	protected transform(paValues: T[]): U[];
	/** @override */
	protected transform(paValues: T | T[]): U | U[] {
		if (paValues instanceof Array)
			return paValues.map((poItem: T) => this.dateRangeToString(poItem)) as unknown as U[];
		else
			return this.dateRangeToString(paValues) as unknown as U;
	}

	/** @override */
	public action(): Observable<T[]> {
		if (this.dateRangePicker) {
			return of(this.dateRangePicker.pickRange())
				.pipe(
					mergeMap(() => this.moDateRangeSubject.asObservable()),
					mergeMap((poValue: T) => of([poValue])),
					take(1)
				);
		}
		else
			return of([]);
	}

	public onDateRangeChanged(poDateRangeEvent: T): void {
		this.value = poDateRangeEvent;
		this.displayValue = this.dateRangeToString(poDateRangeEvent);
		this.moDateRangeSubject.next(poDateRangeEvent as unknown as T);
	}

	public onClick(): void {
		this.action().subscribe();
	}

	private dateRangeToString(poDateRange: T): U {
		if (poDateRange)
			return this.ioDateWithLocalePipe.transform((poDateRange as unknown as IDateRange).begin, DateHelper.C_LOCAL_FR, ETimetablePattern.dd_MM_yyyy_slash) + ' - ' +
				this.ioDateWithLocalePipe.transform((poDateRange as unknown as IDateRange).end, DateHelper.C_LOCAL_FR, ETimetablePattern.dd_MM_yyyy_slash) as unknown as U;
		else
			return "" as unknown as U
	}

	//#endregion

}
