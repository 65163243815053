import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { IPreferencesConfig } from '../model/IPreferencesConfig';
import { FavoritesService, FAVORITES_CONFIG } from './services/favorites.service';

@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [FavoritesService]
})
export class FavoritesModule {

	public static forRoot(poFavoritesConfig: IPreferencesConfig): ModuleWithProviders<FavoritesModule> {
		return {
			ngModule: FavoritesModule,
			providers: [
				{ provide: FAVORITES_CONFIG, useValue: poFavoritesConfig }
			]
		}
	}

}
