import { Component, Input, OnInit } from '@angular/core';
import { IContact } from '@osapp/model';
import { PanneauService } from '../../shared/services/panneau.service';




@Component({
  selector: 'di-panneau-choix-user',
  templateUrl: './panneau-choix-user.component.html',
  styleUrls: ['./panneau-choix-user.component.scss'],
})
export class PanneauChoixUserComponent implements OnInit {

	@Input() onSelectetUser: (user: IContact) => void;


  constructor(
    private svcPanneau: PanneauService,

  ) { }

  ngOnInit() {

  }

	public setContactSelectedList = (user: IContact): void => {
		if(this.onSelectetUser){  
			this.onSelectetUser(user);
			this.svcPanneau.close();
		}
	}

  public close() {
    this.svcPanneau.close();
  }
}