import { ComponentFactory, ComponentFactoryResolver, ComponentRef, Directive, Input, ViewContainerRef } from '@angular/core';
import { HeaderComponent } from './header.component';
import { IHeaderParams } from './model/IHeaderParams';

/** Directive permettant d'afficher le header d'une application calao.
 *
 * Usage :
 * ```html
 * <ng-container calaoHeader title="page title"></ng-container>
 * ```
 *
 * ### Attention : le composant `<ion-header>` doit être utilisé à la racine d'une page, pour être affiché correctement, et que les animations ionic soit appliquées. C'est pour cela qu'on utilise `<ng-container>` qui sera substitué par le contenu du template.
 *
 * @see https://ionicframework.com/docs/api/header
 * > It's important to note that `ion-header` needs to be the one of the three root elements of a page.
 */
@Directive({
	selector: "[calaoHeader]"
})
export class HeaderDirective implements IHeaderParams {

	//#region FIELDS

	private moHeaderTemplateInstance: HeaderComponent;

	//#endregion

	//#region PROPERTIES

	private msTitle = "";
	/** @override */
	public get title(): string {
		return this.msTitle;
	}
	@Input() public set title(psNewValue: string) {
		this.msTitle = psNewValue;
		this.moHeaderTemplateInstance ? this.moHeaderTemplateInstance.title = psNewValue : this.createHeader();
	}

	private mbHasHomeButton = true;
	/** @override */
	public get hasHomeButton(): boolean {
		return this.mbHasHomeButton;
	}
	@Input() public set hasHomeButton(pbNewValue: boolean) {
		this.mbHasHomeButton = pbNewValue;
		this.moHeaderTemplateInstance ? this.moHeaderTemplateInstance.hasHomeButton = pbNewValue : this.createHeader();
	}

	private mbHasBackButton = true;
	/** @override */
	public get hasBackButton(): boolean {
		return this.mbHasBackButton;
	}
	@Input() public set hasBackButton(pbNewValue: boolean) {
		this.mbHasBackButton = pbNewValue;
		this.moHeaderTemplateInstance ? this.moHeaderTemplateInstance.hasBackButton = pbNewValue : this.createHeader();
	}

	private mbHasMenuButton = true;
	/** @override */
	public get hasMenuButton(): boolean {
		return this.mbHasMenuButton;
	}
	@Input() public set hasMenuButton(pbNewValue: boolean) {
		this.mbHasMenuButton = pbNewValue;
		this.moHeaderTemplateInstance ? this.moHeaderTemplateInstance.hasMenuButton = pbNewValue : this.createHeader();
	}

	private mbHasSyncButton = false;
	/** @override */
	public get hasSyncButton(): boolean {
		return this.mbHasSyncButton;
	}
	@Input() public set hasSyncButton(pbNewValue: boolean) {
		this.mbHasSyncButton = pbNewValue;
		this.moHeaderTemplateInstance ? this.moHeaderTemplateInstance.hasSyncButton = pbNewValue : this.createHeader();
	}

	private mfCustomBackButtonAction: () => void;
	/** @override */
	public get customBackButtonAction(): () => void {
		return this.mfCustomBackButtonAction;
	}
	@Input() public set customBackButtonAction(pfCustomAction: () => void) {
		this.mfCustomBackButtonAction = pfCustomAction;
		this.moHeaderTemplateInstance ? this.moHeaderTemplateInstance.customBackButtonAction = pfCustomAction : this.createHeader();
	}

	//#endregion

	//#region METHODS

	constructor(private viewContainerRef: ViewContainerRef,
		private componentFactoryResolver: ComponentFactoryResolver) { }

	private createHeader(): void {
		const loComponentFactory: ComponentFactory<HeaderComponent> = this.componentFactoryResolver.resolveComponentFactory(HeaderComponent);
		this.viewContainerRef.clear();

		const loComponentRef: ComponentRef<HeaderComponent> = this.viewContainerRef.createComponent(loComponentFactory);
		this.moHeaderTemplateInstance = loComponentRef.instance;

		this.moHeaderTemplateInstance.title = this.msTitle;
		this.moHeaderTemplateInstance.hasHomeButton = this.mbHasHomeButton;
		this.moHeaderTemplateInstance.hasBackButton = this.mbHasBackButton;
		this.moHeaderTemplateInstance.customBackButtonAction = this.mfCustomBackButtonAction;
	}

	//#endregion

}