import { Injectable } from '@angular/core';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { NumberHelper } from '../../../helpers/numberHelper';
import { ConfigData } from '../../../model/config/ConfigData';
import { OsappError } from '../../errors/model/OsappError';
import { OsappApiHelper } from '../../osapp-api/helpers/osapp-api.helper';
import { PerformanceManager } from '../../performance/PerformanceManager';

@Injectable()
export class DownloadService {

	//#region FIELDS

	private static readonly C_LOG_ID = "DL.S::";
	private static readonly C_DEFAULT_TIMEOUT_MS = 30000;

	//#endregion

	//#region METHODS

	constructor(private ioFileTransfer: FileTransfer) { }

	/** Télécharge un fichier.
	 * @param psDownloadUrl Url du fichier.
	 * @param psTargetUrl Chemin de destination.
	 * @param pfOnProgress Callback appelée lors de l'avancement de téléchargement.
	 * @param pnTimeout Durée du timeout en ms.
	 */
	public async download(psDownloadUrl: string,
		psTargetUrl: string,
		pfOnProgress?: (poEvent: ProgressEvent) => void,
		pnTimeout: number = DownloadService.C_DEFAULT_TIMEOUT_MS): Promise<void> {
		if (!FileTransfer.installed())
			throw new OsappError("Plugin FileTransfer non installé.");

		const loFileTransfer: FileTransferObject = this.ioFileTransfer.create();
		const loPerformanceManager = new PerformanceManager;
		let lnTimeout: number;

		loFileTransfer.onProgress((poEvent: ProgressEvent) => {
			lnTimeout = this.clearTimeout(lnTimeout);
			lnTimeout = this.createTimeout(pnTimeout, loFileTransfer);

			if (pfOnProgress)
				pfOnProgress(poEvent);
		});

		loPerformanceManager.markStart();
		try {
			await loFileTransfer.download(psDownloadUrl, psTargetUrl, true, {
				headers: {
					Token: ConfigData.authentication.token, appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo)
				}
			});
			console.debug(`${DownloadService.C_LOG_ID}Téléchargement du fichier sur ${psDownloadUrl} effectué en ${loPerformanceManager.markEnd().measure()}ms.`);
		}
		catch (poError) {
			console.error(`${DownloadService.C_LOG_ID}Téléchargement du fichier sur ${psDownloadUrl} échoué en ${loPerformanceManager.markEnd().measure()}ms.`, poError);
			throw poError;
		}
		finally {
			lnTimeout = this.clearTimeout(lnTimeout);
		}
	}

	private clearTimeout(pnTimeout: number): null {
		if (NumberHelper.isValid(pnTimeout))
			window.clearTimeout(pnTimeout);
		return null;
	}

	private createTimeout(pnTimeout: number, loFileTransfer: FileTransferObject): number {
		return window.setTimeout(() => loFileTransfer.abort(), pnTimeout);
	}

	//#endregion

}
