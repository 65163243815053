import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LightingUpAngularModule } from "lighting-up-angular";
import { SeparateurDateComponent } from "./separateur-date.component";

@NgModule({
	declarations: [SeparateurDateComponent],
	imports: [CommonModule, LightingUpAngularModule],
	exports: [SeparateurDateComponent],
})
export class SeparateurDateModule {}
