import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { DatabaseSynchroService } from '@osapp/modules/database-synchro/services/database-synchro.service';
import { EDatabaseSyncStatus } from '@osapp/modules/store/model/EDatabaseSyncStatus';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { Subscription, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MenuSynchroComponent } from '../../features/home/components/menu-synchro/menu-synchro.component';
import { DeviceService } from '../../features/shared/services/device.service';
import { DrawerPopoverService } from '../../features/shared/services/drawer-popover.service';

@Component({
	templateUrl: './home.page.html',
	styleUrls: ['./home.page.scss'],
})
export class HomePage extends DestroyableComponentBase {

	private subscriptions: Subscription[] = [];
	public showSyncro : boolean = false;
	public showPopover: boolean = false;

	@ViewChild('menuSynchro', { static: false }) menuSynchro!: ElementRef<HTMLDivElement>;


	constructor(
		private svcDevice : DeviceService,
		private router: Router,
		private svcDatabaseSynchro : DatabaseSynchroService,
		private svcDrawerPopover : DrawerPopoverService,
		private ioInAppBrowser : InAppBrowser
	) { 
		super()
	}

	ngOnInit(): void {

		this.svcDrawerPopover.closePopover$
      .pipe(
        takeUntil(this.destroyed$),
        switchMap(() => {
					this.getStatusSync();
          return of(true);
        })
      )
      .subscribe();

		this.subscriptions.push(this.svcDevice.isMobile$.subscribe((flag: boolean) => {
			if(!flag)
				this.router.navigate(['/transmissions']);
			else
			this.getStatusSync();
    }));
	}

	private getStatusSync() {
		this.svcDatabaseSynchro.getDatabasesCommonStatus()
			.pipe(
				distinctUntilChanged(),
				debounceTime(500),
				tap((peDatabaseSyncStatus: EDatabaseSyncStatus) => {
					this.showSyncro = peDatabaseSyncStatus !== EDatabaseSyncStatus.upToDate;
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public handleRedirectAmeli(){
		const url = 'https://www.ameli.fr/meurthe-et-moselle/infirmier/exercice-liberal/prescription-prise-charge/regles-exercice-formalites/bilan-de-soins-infirmiers-bsi';
		this.ioInAppBrowser.create(url);	
	}

	public ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach(sub => sub.unsubscribe());
      this.subscriptions = [];
    }
  }

 	onOpenMenuSynchro = () => {
		this.showPopover = true;
		this.svcDrawerPopover.open("Synchronisation", "60%", this.menuSynchro?.nativeElement, MenuSynchroComponent, {}, () => this.showPopover = false)
	}
}
