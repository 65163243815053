<div class="page-facturation">
	<div class="page-facturation__container">
		<div class="page-facturation__header">
			<div class="page-facturation__titre">
				<h1>Facturation</h1>
				<small *ngIf="nbOrdoActive">{{nbOrdoActive}} ordonnance{{nbOrdoActive > 1 ? 's' : ''}} active{{nbOrdoActive > 1
					? 's' : ''}}</small>
			</div>
			<div class="page-facturation__filtres">
				<div class="selection-filtre">
					<lua-filtre-groupe [filters]="facturationfilters" (filterSelected)="changeFilter($event)"
						[defaultFilter]="defaultFilter">
					</lua-filtre-groupe>
				</div>
				<form [formGroup]="filtreForm">
					<lua-filter-date-picker *ngIf="defaultFilter !== 'FACTURÉES'" label="Date de facturation" fullWidth="isMobile" (valueChange)="onValueChange($event)"
						formControlName="dateFacturation"></lua-filter-date-picker>
				</form>
			</div>
		</div>
		<div class="page-facturation__body">
			<ng-container *ngIf="ordonnances.length > 0">
				<ng-container *ngFor="let ordonnance of ordonnances; let i = index">
					<di-carte-ordonnance [ordonnance]="ordonnance" tagColor="Info" progressBarColor="Indigo"
						buttonIconName="more_vert" [dateFacturation]="dateFacturation" [modeFacturation]="true"
						[onFacturerClick]="handleFacturer">
					</di-carte-ordonnance>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="ordonnances.length === 0">
				<lua-message-aucune-donnee mainText="Aucune ordonnance" iconName="contract"
					[subText]="sousTexteAucuneOrdonnance"></lua-message-aucune-donnee>
			</ng-container>
		</div>
	</div>
</div>