import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { Loader } from '@osapp/modules/loading/Loader';
import { ModalService } from '@osapp/modules/modal/services/modal.service';
import { IHasPermission, PermissionsService } from '@osapp/modules/permissions/services/permissions.service';
import { PrestationService } from '@osapp/modules/prestation/services/prestation.service';
import { ContactsService } from '@osapp/services/contacts.service';
import { LoadingService } from '@osapp/services/loading.service';
import { defer } from 'rxjs';
import { finalize, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { Traitement } from '../../../../model/Traitement';
import { TraitementDatesPipe } from '../../../../pipes/traitementDates.pipe';
import { SeanceService } from '../../../../services/seance.service';
import { TraitementService } from '../../../../services/traitement.service';
import { ActesService } from '../../../actes/actes.service';
import { OrdonnancesService } from '../../../ordonnances/services/ordonnances.service';
import { IPatient } from '../../../patients/model/IPatient';
import { CouverturesService } from '../../../patients/services/couvertures.service';
import { PatientsService } from '../../../patients/services/patients.service';
import { FacturationService } from '../../facturation.service';
import { InvoicingComponentBase } from '../../helpers/InvoicingComponentBase';
import { IdlPrestation } from '../../models/idl-prestation';

interface IPrestationsData {
	traitementId: string;
	prestations: IdlPrestation[];
}

@Component({
	selector: 'idl-invoicing',
	templateUrl: './invoicing.component.html',
	styleUrls: ['./invoicing.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoicingComponent extends InvoicingComponentBase implements OnInit, IHasPermission {

	//#region FIELDS

	//#endregion

	//#region PROPERTIES

	public focusPatientId: string;
	public focusTraitementId: string;
	public focusDone: boolean;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcRoute: ActivatedRoute,
		public readonly isvcSeance: SeanceService,
		public readonly isvcActes: ActesService,
		public readonly ioTraitementsDatesPipe: TraitementDatesPipe,
		public readonly isvcPrestations: PrestationService,
		public readonly isvcPatients: PatientsService,
		public readonly isvcContacts: ContactsService,
		public readonly isvcFacturation: FacturationService,
		public readonly isvcOrdonnances: OrdonnancesService,
		public readonly isvcTraitement: TraitementService,
		public readonly isvcPermissions: PermissionsService,
		public readonly isvcModal: ModalService,
		public readonly isvcLoading: LoadingService,
		public readonly isvcCouvertures: CouverturesService,
		public readonly ioRouter: Router,
		poChangeDetector: ChangeDetectorRef
	) {
		super(
			isvcSeance,
			isvcActes,
			ioTraitementsDatesPipe,
			isvcPrestations,
			isvcPatients,
			isvcContacts,
			isvcFacturation,
			isvcOrdonnances,
			isvcTraitement,
			isvcPermissions,
			isvcModal,
			isvcLoading,
			isvcCouvertures,
			ioRouter,
			poChangeDetector
		);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		let loLoader: Loader;
		defer(() => this.isvcLoading.create("Chargement en cours..."))
			.pipe(
				mergeMap((poLoader: Loader) => poLoader.present()),
				tap((poLoader: Loader) => loLoader = poLoader),
				tap(() => {
					this.focusTraitementId = this.isvcRoute.snapshot.queryParams.traitementId;
					this.focusPatientId = Traitement.extractPatientId(this.focusTraitementId);
				}),
				mergeMap(() => this.init()),
				tap(() => {
					if (!StringHelper.isBlank(this.focusPatientId) && !this.focusDone)
						this.navigateToPatient(this.focusPatientId, this.focusTraitementId);
				}),
				mergeMap(() => loLoader.dismiss()),
				finalize(() => loLoader?.dismiss()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private navigateToPatient(psPatientId: string, psTraitementId?: string): void {
		const laPatients: IPatient[] = [...this.filteredPatients];
		let lnPageIndex = 0;

		while (laPatients.length > 0) {
			const laPagePatient: IPatient[] = laPatients.splice(0, 10);
			const loPatientToFind: IPatient = laPagePatient.find((poPatient: IPatient) => poPatient._id === psPatientId);
			if (!ObjectHelper.isNullOrEmpty(loPatientToFind)) {
				for (let lnIndex = 0; lnIndex < lnPageIndex; ++lnIndex)
					this.paginator.nextPage();

				setTimeout(() => {
					const loPanel = document.getElementById(psPatientId);
					loPanel.scrollIntoView();
					const laPrestations: IdlPrestation[] = this.prestationsDataByPatientId.get(psPatientId)
						?.find((poItem: IPrestationsData) => poItem.traitementId === psTraitementId)
						?.prestations;

					if (psTraitementId && ArrayHelper.hasElements(laPrestations)) {
						this.openPrestations(
							this.traitementsById.get(psTraitementId),
							laPrestations,
							true
						);
						this.focusDone = true;
					}
				}, 1000);

				break;
			}
			else
				lnPageIndex++;
		}
	}

	public arePatientAndAMOUpToDate(poPatient: IPatient): boolean {
		return FacturationService.arePatientAndAMOUpToDate(poPatient, ArrayHelper.getFirstElement(poPatient.AMO));
	}

	//#endregion

}
