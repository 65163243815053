import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { Platform } from '@ionic/angular';
import { defer, Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EApplicationEventType } from '../model/application/EApplicationEventType';
import { INetworkEvent } from '../model/application/INetworkEvent';

@Injectable({ providedIn: "root" })
export class NetworkService {

	//#region FIELDS

	/** Sujet permettant de notifier les changements d'état du réseau. */
	private readonly moEventSubject: Subject<INetworkEvent> = new Subject();

	//#endregion

	//#region METHODS

	constructor(private readonly ioNetwork: Network, poPlatform: Platform) {
		poPlatform.ready().then(_ => this.init());
	}

	public init(): void {
		// On envoie un événement dès l'initialisation pour initialiser les flags de réseau.
		this.raiseNetworkEvent(!this.isNetworkOnline());

		this.ioNetwork.onConnect()
			.pipe(tap(_ => this.raiseNetworkEvent(false), poError => console.error(poError)))
			.subscribe();

		this.ioNetwork.onDisconnect()
			.pipe(tap(_ => this.raiseNetworkEvent(true), poError => console.error(poError)))
			.subscribe();
	}

	/** Retourne Vrai si la connexion courante est connectée à un réseau, sinon Faux.
	 * ### ATTENTION : cette méthode retourne Vrai pour les réseaux mobiles inconnus ou sans data, si les data sont obligatoires, utilisez isNetworkReliable().
	 */
	public isNetworkOnline(): boolean {
		// `unknown`, `ethernet`, `wifi`, `2g`, `3g`, `4g`, `cellular`, `none`.
		return this.ioNetwork.type !== this.ioNetwork.Connection.NONE;
	}

	/** Retourne Vrai si la connexion courante est connectée à un réseau connu 2g minimum, sinon Faux.
	 * !Si une coupure internet vient juste d'avoir lieu, retourne `true`. Il faut faire un delay() avant d'appeler la fonction.
	 * @deprecated Utiliser `asyncIsNetworkReliable`
	 */
	public isNetworkReliable(): boolean {
		const lsType: string = this.ioNetwork.type;
		return lsType !== this.ioNetwork.Connection.NONE; //&& lsType !== this.ioNetwork.Connection.CELL; && lsType !== this.ioNetwork.Connection.UNKNOWN;
	}

	/** Retourne Vrai si la connexion courante est connectée à un réseau connu 3g minimum, sinon Faux. */
	public asyncIsNetworkReliable(): Observable<boolean> {
		return defer(() => of(this.isNetworkReliable()));
	}

	public subscribe(pfNext: Function, pfError?: Function, pfComplete?: Function): void {
		this.moEventSubject.asObservable().subscribe(
			(poResult: INetworkEvent) => pfNext(poResult),
			poError => pfError(poError),
			() => pfComplete()
		);
	}

	/** Lève un événement indiquant l'état du réseau.
	 * @param pbIsOffline Indique si le réseau est offline ou non.
	 */
	private raiseNetworkEvent(pbIsOffline: boolean): void {
		const loEvent: INetworkEvent = {
			type: EApplicationEventType.NetworkEvent,
			createDate: new Date(),
			data: {
				isOffline: pbIsOffline,
				isOnlineReliable: this.isNetworkReliable()
			}
		};

		this.moEventSubject.next(loEvent);
	}

	/** Description textuelle de la connectivité */
	public getNetworkType(): string {
		return this.ioNetwork.type;
	}

	/** Description textuelle de la connectivité */
	public getNetworkDonwlinkMax(): string {
		return this.ioNetwork.downlinkMax;
	}

	//#endregion
}
