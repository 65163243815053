import { StoreHelper } from '../../helpers/storeHelper';
import { StringHelper } from '../../helpers/stringHelper';
import { IEntity } from '../../model/entities/IEntity';
import { IStoreDocument } from '../store';

export class Entity<T extends IStoreDocument = IStoreDocument> implements IEntity {

	//#region FIELDS

	private static readonly C_LOG_ID = "ENTITY::";

	private mfName: () => string;

	//#endregion

	//#region PROPERTIES

	/** @implements */
	public readonly id: string;
	/** @implements */
	public readonly model: T;
	/** @implements */
	public readonly route: string;
	/** @implements */
	public readonly databaseName: string;
	/** @implements */
	public subPath?: string;
	/** @implements */
	public get name(): string { return this.mfName(); }

	//#endregion

	//#region METHODS

	/** Crée un objet entité.
	 * @param poModel Modèle associé à l'entité.
	 * @param psRoute Détermine la route permettant d'afficher le modèle.
	 * @param pfName Expression lambda permettant de déterminer dynamiquement le nom de l'entité en fonction du modèle.
	 * @param psDatabaseName Base de données de l'entité. Si cette valeur est passée à "null", le databaseId est extrait des cacheData du modèle.
	 */
	constructor(
		poModel: T,
		psRoute: string,
		pfName?: () => string,
		psDatabaseName?: string) {

		this.id = poModel._id;
		this.model = poModel;
		this.route = psRoute;
		this.mfName = pfName || (() => { console.warn(`${Entity.C_LOG_ID}Pas de fonction pour récupérer le nom de l'entité '${poModel._id}'.`); return ""; });

		if (StringHelper.isBlank(psDatabaseName))
			this.databaseName = poModel ? StoreHelper.getDatabaseIdFromCacheData(poModel, undefined, false) : "";
		else
			this.databaseName = psDatabaseName;
	}

	/** Clone une entité.
	 * @param poEntity Entité à cloner.
	 */
	public static clone<T extends IStoreDocument>(poEntity: Entity<T>): Entity<T> {
		return new Entity(poEntity.model, poEntity.route, poEntity.mfName, poEntity.databaseName);
	}

	//#endregion

}