<ion-content scroll-y="true">
	<ng-container *ngIf="hasSlides; then slides; else noSlides;"></ng-container>
</ion-content>

<ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
	<ng-container *ngIf="hasContactFabButtons">
		<ion-fab-button *ngIf="isContactsType && showCreateContact" color="osapp-element" (click)="createNewContact()"
			title="Ajouter" data-cy="fab-button-people-add">
			<ion-icon name="person-add"></ion-icon>
		</ion-fab-button>
		<ion-fab-button color="success" [disabled]="!canValidate" (click)="validate()" data-cy="fab-button-checkmark">
			<ion-icon name="checkmark"></ion-icon>
		</ion-fab-button>
	</ng-container>

	<ng-container *ngIf="hasGroupFabButtons">
		<ion-fab-button *ngIf="isGroupsType && showCreateGroup" color="osapp-element" (click)="createNewGroup()"
			title="Ajouter" data-cy="fab-button-group-add">
			<ion-icon name="people"></ion-icon>
		</ion-fab-button>
		<ion-fab-button color="success" [disabled]="!canValidate" (click)="validate()" data-cy="fab-button-group-checkmark"
			title="Valider">
			<ion-icon name="checkmark"></ion-icon>
		</ion-fab-button>
	</ng-container>
</ion-fab>

<!-- TEMPLATE POUR LA LISTE DES GROUPES. -->
<ng-template #groupsList>
	<!-- ZONE DE RECHERCHE -->
	<div *ngIf="params.hasSearchbox" class="search-box txt-center width100pc">
		<search #groupSearch [data]="groupSelections" [options]="groupSearchOptions"
			(filteredEntriesChanged)="onFilteredGroupsChanged($event)" data-cy="search-contact-selector">
		</search>
		<osapp-selector *ngIf="groupsSelectOptions?.length > 0" displayMode="tags" [options]="groupsSelectOptions"
			(selectionChanged)="onGroupRoleSelectionChanged($event, groupSearch)" multiple>
		</osapp-selector>
	</div>
	<div class="selector-list">
		<ng-container *ngIf="!params.hasSearchbox || filteredGroupDataSelections?.length > 0; else emptyList">
			<!-- Sélection de tous les groupes -->
			<ng-container *ngIf="!hideAllSelectionButton">
				<ion-item
					*ngIf="params.allSelectionButton && filteredGroupDataSelections.length > 1 && params.selectionLimit === infinity"
					lines="full" (click)="onGroupAllSelectionChanged()" class="marg-container">
					<ion-checkbox slot="start" [checked]="areAllGroupsSelected" [disabled]="areAllGroupsSelectionDisabled"
						class="ion-margin-top ion-margin-bottom">
					</ion-checkbox>
					<ion-label class="ion-text-wrap group-title">
						Tout sélectionner
					</ion-label>
				</ion-item>
			</ng-container>
			<mat-radio-group>
				<cdk-virtual-scroll-viewport [style.height]="(filteredGroupDataSelections.length * 70) + 'px'" itemSize="70">
					<!-- Autres groupes sélectionnables -->
					<div *cdkVirtualFor="let groupData of filteredGroupDataSelections; let indexDateAndSeances = index"
						(click)="onGroupClicked(groupData)" class="group-wrapper">
						<ion-item data-cy="group-item" (click)="onGroupSelectionChanged(groupData)">
							<ion-checkbox *ngIf="params.selectionLimit > 1" slot="start"
								[disabled]="(!groupData.isSelected && !canSelectMore) || groupData.isDisabled"
								[checked]="groupData.isSelected" data-cy="group-checkbox" class="ion-margin-top ion-margin-bottom">
							</ion-checkbox>
							<mat-radio-button *ngIf="params.selectionLimit === 1" slot="start" [value]="groupData._id"
								[disabled]="groupData.isDisabled" [checked]="groupData.isSelected"></mat-radio-button>
							<ion-grid>
								<ion-row>
									<span class="ion-text-wrap group-title" matBadge="!"
										[matBadgeHidden]="!groupData.disabledMembers || groupData.disabledMembers.length === 0"
										matBadgeColor="warn" matBadgeSize="small">{{
										groupData.data.name }}</span>
								</ion-row>
								<ion-row>
									<ion-note *ngIf="groupData.members.length > 0 || groupData.disabledMembers?.length === 0"
										class="groups-selector">{{ groupData.members | groupMemberNamesEllipsis }}
									</ion-note>
									<ion-note *ngIf="groupData.disabledMembers?.length > 0" color="medium" class="groups-selector">{{
										groupData.disabledMembers | groupMemberNamesEllipsis }}
									</ion-note>
								</ion-row>
							</ion-grid>
						</ion-item>
					</div>
				</cdk-virtual-scroll-viewport>
			</mat-radio-group>
		</ng-container>
	</div>
</ng-template>

<!-- TEMPLATE POUR LA LISTE DES CONTACTS. -->
<ng-template #contactsList>
	<!-- ZONE DE RECHERCHE -->
	<div *ngIf="params.hasSearchbox" class="search-box txt-center width100pc">
		<search #search [data]="contactSelections" [options]="params.searchOptions"
			(filteredEntriesChanged)="onFilteredContactsChanged($event)" data-cy="search-contact-selector">
		</search>
		<osapp-selector *ngIf="params.groupFilterParams && params.groupFilterParams.options.length > 0"
			[options]="params.groupFilterParams.options" (selectionChanged)="onGroupFilterSelectionChanged($event, search)"
			[multiple]="params.groupFilterParams.multiple" [color]="params.groupFilterParams.color"
			[displayMode]="params.groupFilterParams.displayMode || 'tags'" [limit]="params.groupFilterParams.limit"
			[searchOptions]="params.groupFilterParams.searchOptions"
			[preselectedValues]="params.groupFilterParams.preselectedValues" [selectCss]="params.groupFilterParams.selectCss"
			[label]="params.groupFilterParams.label">
		</osapp-selector>
	</div>
	<div class="selector-list">
		<ng-container
			*ngIf="!params.hasSearchbox || filteredContactDataSelections?.length > 0 || (userContact && params.userContactVisible); else emptyList">
			<!-- Sélection de tous les contacts -->
			<ng-container *ngIf="!hideAllSelectionButton">
				<ion-item
					*ngIf="params.allSelectionButton && (filteredContactDataSelections.length + +!!userContact) > 1 && params.selectionLimit === infinity"
					lines="full" (click)="onContactAllSelectionChanged()" class="marg-container border-item-top">
					<ion-checkbox slot="start" [checked]="areAllContactsSelected" [disabled]="areAllContactsSelectionDisabled"
						class="ion-margin-top ion-margin-bottom">
					</ion-checkbox>
					<ion-label>
						Tout sélectionner
					</ion-label>
				</ion-item>
			</ng-container>
			<mat-radio-group>
				<!-- Contact utilisateur -->
				<ion-item *ngIf="userContact && params.userContactVisible" lines="full" class="user-contact"
					data-cy="user-contact-item" (click)="onContactSelectionChanged(userContact)">
					<div>
						<ion-label>MOI</ion-label>
						<ion-label *ngIf="userContact.details as details" class="details">
							<span>{{ details }}</span>
						</ion-label>
					</div>
					<ion-checkbox *ngIf="params.selectionLimit > 1" slot="start" [checked]="userContact.isSelected"
						[disabled]="(!userContact.isSelected && !canSelectMore) || userContact.isDisabled"
						[indeterminate]="userContact.isGroupMember" data-cy="user-contact-checkbox"
						class="ion-margin-top ion-margin-bottom">
					</ion-checkbox>
					<mat-radio-button *ngIf="params.selectionLimit === 1" slot="start" [value]="userContact._id"
						[disabled]="!userContact.isGroupMember || userContact.isDisabled" [checked]="userContact.isSelected">
					</mat-radio-button>
				</ion-item>
				<!-- Autres contacts sélectionnables -->
				<ion-virtual-scroll class="marge-btm-list" [items]="filteredContactDataSelections" [trackBy]="trackById">
					<ng-container *virtualItem="let contactData">
						<ion-item #contactItem (click)="onContactSelectionChanged(contactData, contactItem)">
							<div>
								<osapp-contact-selector-item [contactSelection]="contactData" [params]="params">
								</osapp-contact-selector-item>
							</div>
							<ion-checkbox *ngIf="params.selectionLimit > 1" [checked]="contactData.isSelected"
								[indeterminate]="contactData.isGroupMember"
								[disabled]="(!contactData.isSelected && !canSelectMore) || contactData.isDisabled" slot="start"
								data-cy="contact-checkbox" class="ion-margin-top ion-margin-bottom">
							</ion-checkbox>
							<mat-radio-button *ngIf="params.selectionLimit === 1"
								[disabled]="contactData.isGroupMember || contactData.isDisabled" [value]="contactData._id"
								[checked]="contactData.isSelected" slot="start">
							</mat-radio-button>
						</ion-item>
					</ng-container>
				</ion-virtual-scroll>
			</mat-radio-group>
		</ng-container>
	</div>
</ng-template>

<!-- PAS DE RÉSULTAT DE RECHERCHE. -->
<ng-template #emptyList>
	<div class="empty-screen flex-col empty-container">
		<span class="txt-message">
			<ion-icon class="info-icon" name="information-circle"></ion-icon>
			<ion-note>Pas de résultat.</ion-note>
		</span>
	</div>
</ng-template>

<!-- TEMPLATE POUR LES SLIDES. -->
<ng-template #slides>
	<super-tabs (tabChange)="onTabChanged($event)" [config]="config">
		<super-tabs-toolbar slot="top">
			<super-tab-button>
				<ion-icon name="person"></ion-icon>
				<ion-label>{{activeIndex === 0 ? 'Contacts' : ''}}</ion-label>
			</super-tab-button>
			<super-tab-button>
				<ion-icon name="group"></ion-icon>
				<ion-label>{{activeIndex === 1 ? 'Groupes' : ''}}</ion-label>
			</super-tab-button>
		</super-tabs-toolbar>
		<super-tabs-container>
			<super-tab>
				<ion-content>
					<ng-container *ngTemplateOutlet="contactsList">
					</ng-container>
				</ion-content>
			</super-tab>
			<super-tab>
				<ion-content>
					<ng-container *ngTemplateOutlet="groupsList"></ng-container>
				</ion-content>
			</super-tab>
		</super-tabs-container>
	</super-tabs>
</ng-template>

<!-- TEMPLATE POUR AUCUNE SLIDE. -->
<ng-template #noSlides>
	<ng-container *ngIf="isContactsType; then contactsList; else groupsList;"></ng-container>
</ng-template>