import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LikeComponent } from './like.component';


/**
 * Module de gestion des formlist.
 */
@NgModule({
	imports: [IonicModule, CommonModule],
	exports: [LikeComponent],
	declarations: [LikeComponent]
})
export class LikeModule {
}