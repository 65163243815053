import { Injectable } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { defer, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ArrayHelper } from '../helpers/arrayHelper';
import { ContactHelper } from '../helpers/contactHelper';
import { StringHelper } from '../helpers/stringHelper';
import { EApplicationEventType } from '../model/application/EApplicationEventType';
import { IAppInfo } from '../model/application/IAppInfo';
import { Version } from '../model/application/Version';
import { ConfigData } from '../model/config/ConfigData';
import { EConfigEventType } from '../model/config/EConfigEventType';
import { IConfApp } from '../model/config/IConfApp';
import { IConfig } from '../model/config/IConfig';
import { IConfigEvent } from '../model/config/IConfigEvent';
import { IStatusBarConfig } from '../model/statusBar/IStatusBarConfig';
import { IDataSource } from '../model/store/IDataSource';
import { EFlag } from '../modules/flags/models/EFlag';
import { LoggerService } from '../modules/logger/services/logger.service';
import { VersionsService } from '../modules/versions/services/versions.service';
import { FlagService } from './flag.service';
import { PlatformService } from './platform.service';
import { SecurityService } from './security.service';
import { Store } from './store.service';

@Injectable({ providedIn: "root" })
export class ConfigService {

	//#region PROPERTIES

	private static readonly C_DEFAULT_STATUSBAR_CONFIG: IStatusBarConfig = {
		styleDefault: true,
		styleLightContent: true,
		overlaysWebView: false,
		backgroundColorByHexString: "#f66733"
	};

	private static readonly C_LOG_ID = "CFG.S::";

	/** Sujet pour l'envoi d'événement. */
	private moEventSubject = new Subject<IConfigEvent>();

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion de la plateforme. */
		private isvcPlatform: PlatformService,
		/** Service de gestion des requêtes en base de données. */
		private isvcStore: Store,
		/** Service de gestion de la version d'application. */
		private isvcVersion: VersionsService,
		/** Service de gestion de la sécurité. */
		private isvcSecurity: SecurityService,
		private isvcLogger: LoggerService,
		private readonly isvcFlag: FlagService
	) { }

	/** Initialise les valeurs de l'application contenues dans le fichier de config. */
	private createAppInfo(poAppInfo: IAppInfo): IAppInfo {
		const loAppInfo: IAppInfo = {
			appId: poAppInfo.appId,
			formEngine: poAppInfo.formEngine,
			deviceId: poAppInfo.deviceId,
			appName: poAppInfo.appName,
			supportEmail: poAppInfo.supportEmail,
			firstName: StringHelper.isBlank(poAppInfo.firstName) ? "" : ContactHelper.getFormattedFirstName(poAppInfo.firstName),
			lastName: StringHelper.isBlank(poAppInfo.lastName) ? "" : ContactHelper.getFormattedLastName(poAppInfo.lastName),
			login: poAppInfo.login,
			applicationDatabaseId: poAppInfo.applicationDatabaseId,
			applicationServiceProvider: poAppInfo.applicationServiceProvider,
			domainServiceProvider: poAppInfo.domainServiceProvider,
			useLinks: poAppInfo.useLinks,
			unlockRequired: poAppInfo.unlockRequired,
			coreDatabasePrefix: poAppInfo.coreDatabasePrefix,
			changelogsUrl: poAppInfo.changelogsUrl
		};

		if (this.isvcPlatform.isMobileApp) // this.mbIsMobile && (!this.ioDevice.isVirtual || this.ioDevice.serial.indexOf(...) === 0) pour vérifier le numéro de série et différencier un simulateur d'un émulateur.
			loAppInfo.deviceId = this.isvcPlatform.uuid;

		loAppInfo.model = this.isvcPlatform.model;
		loAppInfo.platform = this.isvcPlatform.platform;

		return loAppInfo;
	}


	/** Lève un événement pour notifier un changement dans le service.
	 * @param poEventType Type d'événement de configuration qu'on souhaite lever.
	 */
	private raiseEvent(poEventType: EConfigEventType): void {
		const loEvent: IConfigEvent = {
			type: EApplicationEventType.ConfigEvent,
			createDate: new Date(),
			data: { configEventType: poEventType }
		};

		this.moEventSubject.next(loEvent);
	}

	/** Récupère les infos nécessaires ainsi que la configuration de la lib pour le bon fonctionnement de l'app
	 * @param poConfig Configs de l'application (config.ts).
	 */
	public init(poConfig: IConfig): Observable<boolean> {
		return defer(() => SplashScreen.hide())
			.pipe(
				tap(() => {
					this.isvcFlag.setFlagValue(EFlag.splashHidden, true);
					console.debug(`CFG.S::Platform is ready (platform:${this.isvcPlatform.platform}, mobile:${this.isvcPlatform.isMobileApp}, virtual:${this.isvcPlatform.isVirtual}).\nCFG.S::Initializing configuration settings...`);
					this.raiseEvent(EConfigEventType.PlatformReady);
					return this.initStaticConfigs(poConfig);
				}),
				mergeMap(() => this.isvcStore.initLocalDatabase()),
				mergeMap(() => this.initVersions()),
				tap(_ => {
					this.raiseEvent(EConfigEventType.StaticConfigReady);
					if (this.isvcPlatform.isMobileApp)
						this.initStatusBar(poConfig);
				}),
				mergeMap(_ => this.isvcStore.initStartupDatabases()),
				mergeMap(_ => this.initDynamicConfig()), // Récupération des config dynamiques de l'application.
				mergeMap(_ => this.isvcSecurity.initUserAuthStatus().pipe(tap(__ => this.raiseEvent(EConfigEventType.ConfigReady)))),
				catchError(poError => {
					console.error(`CFG.S:: Application initialization failed : `, poError);
					return throwError(poError);
				})
			);
	}

	/** Modifie les principales propriétés de ConfigData : 'authentication', 'environment', 'backgroundTask', 'entityBuilders', 'currentEnv'.
	 * On remplit la config de la lib à l'aide des variables de constantes d'application
	 */
	public initStaticConfigs(poConfig: IConfig): void {
		ConfigData.conversation = poConfig.conversation;
		ConfigData.authentication = poConfig.authentication;
		ConfigData.environment = poConfig.environment;
		ConfigData.backgroundTask = poConfig.backgroundTask;
		ConfigData.update = poConfig.update;
		ConfigData.entityBuilders = poConfig.entityBuilders;
		ConfigData.isProductionEnvironment = ConfigData.environment.production;
		ConfigData.security = poConfig.security;

		if (poConfig.oneSignal)
			ConfigData.oneSignal = poConfig.oneSignal;

		ConfigData.appInfo = this.createAppInfo(poConfig.appInfo);
	}

	/** Initialise la barre d'état de l'OS. */
	public initStatusBar(poConfig: IConfig): void {
		const loStatusBarConfig: IStatusBarConfig = ConfigService.C_DEFAULT_STATUSBAR_CONFIG;

		if (poConfig.environment?.statusBar) {
			const loStatusBar: IStatusBarConfig = poConfig.environment.statusBar;

			if (!StringHelper.isBlank(loStatusBar.backgroundColorByHexString))
				loStatusBarConfig.backgroundColorByHexString = loStatusBar.backgroundColorByHexString;

			if (typeof loStatusBar.overlaysWebView === "boolean")
				loStatusBarConfig.overlaysWebView = loStatusBar.overlaysWebView;

			if (typeof loStatusBar.styleLightContent === "boolean")
				loStatusBarConfig.styleLightContent = loStatusBar.styleLightContent;
		}

		StatusBar.setBackgroundColor({ color: loStatusBarConfig.backgroundColorByHexString });

		if (loStatusBarConfig.overlaysWebView === false)
			StatusBar.setOverlaysWebView({ overlay: loStatusBarConfig.overlaysWebView });

		if (loStatusBarConfig.styleDefault)
			StatusBar.setStyle({ style: Style.Default });

		if (loStatusBarConfig.styleLightContent)
			StatusBar.setStyle({ style: Style.Light });
	}

	private initVersions(): Observable<void> {
		return defer(() => {
			console.debug(`${ConfigService.C_LOG_ID}Initializing versions ...`);
			return this.isvcVersion.initVersions();
		})
			.pipe(tap(
				() => {
					ConfigData.appInfo.appVersion = Version.fromString(this.isvcVersion.version).toFormattedString();
					ConfigData.appInfo.previousAppVersion = Version.fromString(this.isvcVersion.lastVersion).toFormattedString();
					console.debug(`${ConfigService.C_LOG_ID}Versions initialized.`);
				},
				(poError: any) => console.error(`${ConfigService.C_LOG_ID}Error while initilizing versions : `, poError)
			));
	}

	public initDynamicConfig(): Observable<void> {
		return this.getDynamicConfig()
			.pipe(
				map((poConfigData: IConfApp) => {
					ConfigData.logger = poConfigData.logger;
					ConfigData.databases.push(...poConfigData.databases);
					ConfigData.workspaceDatabases = poConfigData.workspaceDatabases;
					this.isvcLogger.init(ConfigData.logger);
				})
			);
	}

	private getDynamicConfig(): Observable<IConfApp> {
		const loParams: IDataSource = {
			databaseId: ConfigData.environment.coreRoleAppConfig, // Nom de la base de données de config.
			viewParams: {
				startkey: "conf_app_v0.00.000",
				endkey: `conf_app_v${ConfigData.appInfo.appVersion}`,
				include_docs: true
			}
		};

		// Les infos de la première page se trouvent dans la base "config".
		return this.isvcStore.get(loParams)
			.pipe(
				mergeMap((paConfigData: IConfApp[]) => {
					if (paConfigData.length < 1)
						return throwError(`No dynamic configuration settings found for application version ${ConfigData.appInfo.appVersion} ${JSON.stringify(loParams)}.`);
					else
						return of(ArrayHelper.getLastElement(paConfigData));
				})
			);
	}

	/** Permet de s'abonner aux événements du ConfigService.
	 * @param pfNext Fonction appelée lors du next => function(poResult: IConfigEvent).
	 * @param pfError Fonction appelée lors du error => function(poError: Any).
	 */
	public subscribe(pfNext: Function, pfError?: Function): void {
		this.moEventSubject.asObservable().subscribe(
			(poResult: IConfigEvent) => pfNext(poResult),
			poError => pfError(poError)
		);
	}

	//#endregion

}
