import { HttpErrorResponse } from "@angular/common/http";
import { ArrayHelper } from "@osapp/helpers/arrayHelper";
import { ObjectHelper } from "@osapp/helpers/objectHelper";
import { StringHelper } from "@osapp/helpers/stringHelper";

interface IApiError {
	status: number;
	/** Message d'erreur (peut être un json stringifié de type `IApiContentError`). */
	error: string;
	message: string;
}

interface IApiContentError {
	/** Message d'erreur. */
	message: string;
	/** Tableau des éléments à lister avec le message d'erreur (infos manquantes). */
	elements?: string[];
}

/** Permet de mettre à disposition des méthodes pour aider à manipuler unTruc. */
export abstract class IdlApiHelper {

	//#region METHODS

	/** Retourne le message d'erreur retourné par l'API après une requête vers celle-ci.
		 * @param poError Erreur retournée par l'API.
		 * @param psDefaultMessage Message par défaut à retourner.
		 */
	public static getExportErrorMessage(poError: HttpErrorResponse, psDefaultMessage: string): string {
		try {
			const loError: IApiError = poError.error;
			loError.status = poError.status;
			const lsKey: string = ObjectHelper.isJson(loError.message) ? "message" : ObjectHelper.isJson(loError.error) ? "error" : "";
			let lsErrorMessage: string;

			if (!StringHelper.isBlank(lsKey)) {
				const loContentError: IApiContentError = JSON.parse(loError[lsKey]);
				lsErrorMessage = loContentError.message;

				// Construction du message en listant les infos manquantes.
				if (ArrayHelper.hasElements(loContentError.elements)) {
					const laFormattedElements: string[] = loContentError.elements.map((psElement: string) => `<li>${psElement}`);
					lsErrorMessage += `\n<ul>${laFormattedElements.join("</li>")}</ul>`;
				}
			}
			else
				lsErrorMessage = `${loError.error}<br><br>${loError.message}`;

			if (StringHelper.isBlank(lsErrorMessage))
				lsErrorMessage = psDefaultMessage;

			return lsErrorMessage;
		}
		catch (poCatchedError) {
			console.error("IDLAPI.S:: Erreur parsing erreur retournée par l'API :", poError);
			return psDefaultMessage;
		}
	}

	//#endregion
}