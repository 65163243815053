<div class="marg-btm infos-list">
	<ng-container *ngTemplateOutlet="readOnly ? view : edit"></ng-container>

	<!-- Galerie. -->
	<div class="ordonnances">
		<gallery [files]="model.files" [readOnly]="readOnly" (filesChanged)="onFilesChanged()"></gallery>
	</div>
</div>

<!-- TEMPLATE MODE VISU -->
<ng-template #view>
	<div class="margin-edit">
		<!-- Types de transmissions. -->
		<div lines="none" class="separator">
			<div class="transmission-type-view">
				<ion-label class="label-title">Type</ion-label>
				<div class="divider"></div>
			</div>
			<ion-label class="label-value">{{ model.transmissionType }}</ion-label>
		</div>

		<!-- Énoncé du problème. -->
		<div lines="none" class="separator">
			<div class="transmission-type-view">
				<ion-label class="label-title">Énoncé du problème</ion-label>
				<div class="divider"></div>
			</div>
			<ion-label class="label-value">{{ model.problem }}</ion-label>
		</div>

		<!-- Données. -->
		<div lines="none" class="separator">
			<div class="transmission-type-view">
				<ion-label class="label-title">Données</ion-label>
				<div class="divider"></div>
			</div>
			<ion-label class="label-value">{{ model.data }}</ion-label>
		</div>

		<!-- Interventions. -->
		<div lines="none" class="separator">
			<div class="transmission-type-view">
				<ion-label class="label-title">Interventions</ion-label>
				<div class="divider"></div>
			</div>
			<ion-label class="label-value">{{ model.interventions }}</ion-label>
		</div>

		<!-- Résultats. -->
		<div lines="none" class="separator">
			<div class="transmission-type-view">
				<ion-label class="label-title">Résultats</ion-label>
				<div class="divider"></div>
			</div>
			<ion-label class="label-value">{{ model.results }}</ion-label>
		</div>
	</div>
</ng-template>

<!-- TEMPLATE MODE EDIT -->
<ng-template #edit>
	<!-- Types de transmissions. -->
	<div class="transmission-type">
		<ion-radio-group [(ngModel)]="model.transmissionType">
			<ion-item *ngFor="let type of transmissionTypeValues">
				<ion-radio mode="md" slot="start" [value]="type" class="margin-radio">
				</ion-radio>
				<ion-label>{{ type }}</ion-label>
			</ion-item>
		</ion-radio-group>
	</div>
	<div class="margin-edit">
		<!-- Énoncé du problème. -->
		<mat-form-field appearance="outline" class="center-explains">
			<mat-label>Énoncé du problème</mat-label>
			<textarea matInput matTextareaAutosize matAutosizeMinRows="1" matAutosizeMaxRows="6"
				[(ngModel)]="model.problem"></textarea>
		</mat-form-field>

		<!-- Données. -->
		<mat-form-field appearance="outline" class="center-explains">
			<mat-label>Données</mat-label>
			<textarea matInput matTextareaAutosize matAutosizeMinRows="1" matAutosizeMaxRows="6"
				[(ngModel)]="model.data"></textarea>
		</mat-form-field>

		<!-- Interventions. -->
		<mat-form-field appearance="outline" class="center-explains">
			<mat-label>Interventions</mat-label>
			<textarea matInput matTextareaAutosize matAutosizeMinRows="1" matAutosizeMaxRows="6"
				[(ngModel)]="model.interventions"></textarea>
		</mat-form-field>

		<!-- Résultats. -->
		<mat-form-field appearance="outline" class="center-explains">
			<mat-label>Résultats</mat-label>
			<textarea matInput matTextareaAutosize matAutosizeMinRows="1" matAutosizeMaxRows="6"
				[(ngModel)]="model.results"></textarea>
		</mat-form-field>
	</div>
</ng-template>