import { OSNotificationOpenedResult } from '@awesome-cordova-plugins/onesignal/ngx';
import { Observable, Timestamp } from 'rxjs';
import { tap, timestamp } from 'rxjs/operators';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { INotificationTaskParams } from '../../model/backgroundTask/taskParams/INotificationTaskParams';
import { IConversation } from '../../model/conversation/IConversation';
import { NotificationTaskBase } from './NotificationTaskBase';
import { TaskDescriptor } from './TaskDescriptor';

export class NotificationOpenConversationTask<T extends INotificationTaskParams> extends NotificationTaskBase<T> {

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<T>) {
		super(poDescriptor);
	}

	/** @override */
	public execTask(): Observable<Timestamp<IConversation>> {
		const lnStartTime: number = Date.now();
		const loParams: OSNotificationOpenedResult = this.descriptor.params;
		const lsConvId: string = ArrayHelper.hasElements(loParams.notification["actionButtons"]) ?
			loParams.notification["actionButtons"][0].id : loParams.action.actionID;

		return this.msvcConversation.downloadConversation(lsConvId) // TODO Modif workflow pour passer l'id de la conversation dans les additionnalDatas.
			.pipe(
				tap((poConversation: IConversation) => this.msvcConversation.routeToConversation(poConversation)),
				timestamp(),
				tap((poResult: Timestamp<IConversation>) => console.debug(`NOTIFOP.T:: Temps de redirection (ms) : ${poResult.timestamp - lnStartTime}`))
			);
	}

	//#endregion
}
