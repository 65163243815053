<div class="menu-desktop-container">
	<lua-icone [isIcon]="true" customIconName="desmos" height="50px" width="50px"></lua-icone>
	<div class="menu-desktop-button-list">
		<lua-badge *ngFor="let icone of menuIcones" [number]="icone.badgeCount">
			<lua-bouton-icone [iconName]="icone.iconName" color="Blanc" mode="outlined"
				(click)="redirect(icone.route)"></lua-bouton-icone>
		</lua-badge>
		<lua-badge number="0">
			<lua-bouton-icone iconName="upload" color="Blanc" mode="outlined"
				(click)="redirect(['teletransmission'])"></lua-bouton-icone>
		</lua-badge>
		<lua-badge number="0">
			<lua-bouton-icone iconName="language" color="Blanc" mode="outlined"
				(click)="handleRedirectAmeli()"></lua-bouton-icone>
		</lua-badge>
	</div>
</div>