<ion-header>
	<ion-toolbar color="toolbar">

		<ion-buttons slot="start">
			<!-- Bouton 'back'. -->
			<ion-back-button text="">
			</ion-back-button>
			<!-- Bouton 'menu'. -->
			<ion-menu-toggle>
				<ion-button class="pad-title">
					<ion-icon slot="icon-only" name="menu"></ion-icon>
				</ion-button>
			</ion-menu-toggle>
			<!-- Bouton 'accueil'. -->
			<ion-button (click)="goHome()" class="pad-title">
				<ion-icon slot="icon-only" name="home"></ion-icon>
			</ion-button>
		</ion-buttons>

		<ion-title>{{ title }}</ion-title>

	</ion-toolbar>
</ion-header>

<transmission-second-header *ngIf="!!transmission" [transmission]="transmission" [isCreation]="isCreation">
</transmission-second-header>

<ion-content>
	<transmission [model]="transmission" [readOnly]="isReadOnly"></transmission>
</ion-content>

<ion-fab *ngIf="!isReadOnly" vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
	<ion-fab-button color="success" (click)="saveTransmission()" class="fab-shadow" title="Sauvegarder">
		<ion-icon name="checkmark"></ion-icon>
	</ion-fab-button>
</ion-fab>