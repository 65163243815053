import { Observable } from 'rxjs';
import { DefaultEntityGuard } from '../model/entities/DefaultEntityGuard';
import { Entity } from '../model/entities/Entity';
import { IEntityGuard } from '../model/entities/IEntityGuard';
import { EPrefix } from '../model/EPrefix';
import { IAvatar } from '../model/picture';
import { IDataSource } from '../model/store';
import { EDatabaseRole } from '../model/store/EDatabaseRole';
import { IStoreDocument } from '../model/store/IStoreDocument';
import { InjectorService } from './injector.service';
import { Store } from './store.service';

export class EntityBuilder<T extends IStoreDocument = IStoreDocument> {
	//#region FIELDS

	private msvcStore: Store;

	//#endregion

	//#region PROPERTIES

	private moDataSource: IDataSource<T>;
	/** Source de données pour récupérer cette entité. */
	public get dataSource(): IDataSource<T> {
		if (!this.msvcStore) {
			this.msvcStore = InjectorService.instance.get(Store);
			this.moDataSource.databasesIds = this.msvcStore.getDatabasesIdsByRole(this.role);
		}

		return this.moDataSource;
	}

	//#endregion

	//#region METHODS

	constructor(
		/** Fonction permettant de déterminer si un builder correspond à un modèle. */
		public match: (psModelId: string) => boolean,
		/** Fonction permettant de construire une entité à partir d'un modèle. */
		public build: (poModel: T) => Entity<T>,
		/** Fonction permettant de déterminer la catégorie d'une entité à partir de son modèle. */
		public category: (psModelId?: string) => string,
		/** Rôle de l'entité. */
		public role: EDatabaseRole,
		/** Prefix des entités. */
		public prefix: EPrefix,
		/** Fonction permettant de récupérer l'avatar représentant la catégorie. */
		public categoryAvatar: () => IAvatar,
		/** Fonction permettant de récupérer l'avatar représentant l'entité. */
		public entityAvatar: (poModel: T) => IAvatar = categoryAvatar,
		/** Source de données pour ce constructeur d'entités, pas besoin de préciser l'/les identifiant(s) de base(s) de données. */
		poDataSource?: IDataSource<T>,
		/** Permet de définir une manière spécifique de récupérer une entité à partir d'un lien. */
		public getEntity?: (psDatabaseId: string, psEntityId: string) => Observable<Entity<T>>,
		/** Contrôle les actions possibles sur l'entité. */
		public guard: IEntityGuard = new DefaultEntityGuard()) {

		if (poDataSource)
			this.moDataSource = poDataSource;

		else {
			this.moDataSource = {
				viewParams: {
					include_docs: true,
					startkey: this.prefix,
					endkey: this.prefix + Store.C_ANYTHING_CODE_ASCII
				}
			};
		}
	}

	//#endregion	
}