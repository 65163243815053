<div class="panneau-recherche-actes">
	<div>
		<div class="panneau-recherche-actes__container">
			<div class="container__content">
				<di-choix-user [setContactSelectedList]="setContactSelectedList">
				</di-choix-user>
			</div>
		</div>
	</div>

</div>