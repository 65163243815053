import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BatteryStatus } from '@awesome-cordova-plugins/battery-status/ngx';
import { BatteryService } from './services/battery.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
	providers: [BatteryStatus, BatteryService]
})
export class BatteryModule { }
