import { KeyValue } from "@angular/common";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ContactsService } from "@osapp/services/contacts.service";
import { LoadingService } from "@osapp/services/loading.service";
import { OrdonnancesService } from "../../../ordonnances/services/ordonnances.service";
import { PatientsService } from "../../../patients/services/patients.service";
import { FacturationService } from "../../facturation.service";
import { InvoicesListComponentBase } from "../../helpers/InvoicesListComponentBase";

@Component({
	selector: "idl-closed-invoices-list",
	templateUrl: "./closed-invoices-list.component.html",
	styleUrls: ["./closed-invoices-list.component.scss"],
})
export class ClosedInvoicesListComponent
	extends InvoicesListComponentBase
	implements OnInit
{
	//#region FIELDS

	//#endregion

	//#region PROPERTIES

	//#endregion

	//#region METHODS

	constructor(
		psvcFacturation: FacturationService,
		psvcContacts: ContactsService,
		psvcOrdonnances: OrdonnancesService,
		psvcLoading: LoadingService,
		psvcPatients: PatientsService,
		poRouter: Router,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(
			psvcFacturation,
			psvcContacts,
			psvcOrdonnances,
			psvcLoading,
			psvcPatients,
			poRouter,
			poChangeDetectorRef
		);
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this.init(true);
	}

	sortByPatientLastName = (
		a: KeyValue<string, any[]>,
		b: KeyValue<string, any[]>
	): number => {
		const getLastName = (entry: KeyValue<string, any[]>) =>
			entry.value[0]?.patient?.lastName?.toLowerCase() || "";
		const getFirstName = (entry: KeyValue<string, any[]>) =>
			entry.value[0]?.patient?.firstName?.toLowerCase() || "";

		const lastNameA = getLastName(a);
		const lastNameB = getLastName(b);
		const comparisonResult = lastNameA.localeCompare(lastNameB);

		if (comparisonResult !== 0) {
			return comparisonResult;
		}

		const firstNameA = getFirstName(a);
		const firstNameB = getFirstName(b);
		return firstNameA.localeCompare(firstNameB);
	};
}
