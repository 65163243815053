import { BehaviorSubject, combineLatest, defer, from, Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, map, mapTo, mergeMap, switchMap, tap } from 'rxjs/operators';
import { PouchDB } from '../../../lib/pouchdb';
import { IdHelper } from '../../helpers/idHelper';
import { NumberHelper } from '../../helpers/numberHelper';
import { PerformanceManager } from '../../modules/performance/PerformanceManager';
import { EDatabaseSyncStatus } from '../../modules/store/model/EDatabaseSyncStatus';
import { ELocalToServerReplicationMode } from '../../modules/store/model/elocal-to-server-replication-mode.enum';
import { IDatabaseSyncMarker } from '../../modules/store/model/IDatabaseSyncMarker';
import { IOptimizationConfig } from '../../modules/store/model/ioptimization-config';
import { ISyncronizationEvent } from '../../modules/store/model/isyncronization-event';
import { IDatabaseMeta } from '../databaseDocument/IDatabaseMeta';
import { EPrefix } from '../EPrefix';
import { EDatabaseRole } from './EDatabaseRole';
import { ESyncType } from './ESyncType';
import { ICustomPouchError } from './ICustomPouchError';
import { IStoreDocument } from './IStoreDocument';
import { ILocalDatabaseConfig } from './pouchDB/ILocalDatabaseConfig';
import { IRemoteDatabaseConfig } from './pouchDB/IRemoteDatabaseConfig';

export class Database {

	//#region FIELDS

	private static readonly C_LOG_ID = "DB::";
	private static readonly C_INITIALIZED_MARKER_ID = "_local/initialized";

	/** Instance Pouch locale de la base de données. */
	private moLocalPouchDatabase: PouchDB.Database;
	/** Instance Pouch distante de la base de données. */
	private moRemotePouchDatabase: PouchDB.Database;

	//#endregion

	//#region PROPERTIES

	/** Nom d'accès de la base de données (ex: mig_core_common_config). */
	public readonly id: string;
	/** Type de synchronisation de la bdd (ex: local). */
	public readonly syncType: ESyncType;
	/** Rôle de la base de données ( component | formEntries | formDefinitions ). */
	public readonly roles: EDatabaseRole[];

	private mbIsInitialized: boolean;
	/** Pour les bases non locales, est-ce que l'application est logée pour cette base ? */
	public get isInitialized(): boolean {
		return this.mbIsInitialized;
	}
	public set isInitialized(pbIsInitialized: boolean) {
		if (pbIsInitialized !== this.mbIsInitialized)
			this.mbIsInitialized = pbIsInitialized;
	}

	private mbIsClosed = false;
	/** Indique si la base a été détruite. */
	public get isClosed(): boolean { return this.mbIsClosed; }

	/** Indique si la base de données est prête à être utilisée. */
	public get isReady(): boolean { return this.hasLocalInstance() || this.hasRemoteInstance(); };

	private mbHasDatabaseMeta: boolean;
	/** Indique si la base de données possède des métadonnées. */
	public get hasDatabaseMeta(): boolean { return this.mbHasDatabaseMeta; }
	public set hasDatabaseMeta(pbNewValue: boolean) {
		if (pbNewValue !== this.mbHasDatabaseMeta)
			this.mbHasDatabaseMeta = pbNewValue;
	}

	/** Base de données par défaut sur laquelle faire des requêtes. **/
	public get defaultDatabase(): PouchDB.Database {
		return this.syncType === ESyncType.remote ? this.moRemotePouchDatabase : this.moLocalPouchDatabase;
	}

	private readonly moDatabaseIsSynchronizingFromServerSubject = new BehaviorSubject<ISyncronizationEvent>(undefined);
	private mbIsSynchronizingFromServer: ISyncronizationEvent;
	/** Indique si la base de données est en train de se synchroniser depuis le serveur. */
	public get isSynchronizingFromServer(): ISyncronizationEvent {
		return this.mbIsSynchronizingFromServer;
	}
	public set isSynchronizingFromServer(pbIsSynchronizingFromServer: ISyncronizationEvent) {
		if (pbIsSynchronizingFromServer !== this.mbIsSynchronizingFromServer) {
			if (pbIsSynchronizingFromServer)
				this.isSynchroFromServerOnError = false;
			this.moDatabaseIsSynchronizingFromServerSubject.next(this.mbIsSynchronizingFromServer = pbIsSynchronizingFromServer);
		}
	}
	/** Observe si la base de données est en train de se synchroniser depuis le serveur. */
	public get isSynchronizingFromServer$(): Observable<ISyncronizationEvent> {
		return this.moDatabaseIsSynchronizingFromServerSubject.asObservable();
	}

	private readonly moIsSynchroFromServerOnErrorSubject = new BehaviorSubject<boolean>(false);
	private mbIsSynchroFromServerOnError: boolean;
	/** Indique si la synchronisation de la base de données depuis le serveur est en erreur. */
	public get isSynchroFromServerOnError(): boolean {
		return this.mbIsSynchroFromServerOnError;
	}
	public set isSynchroFromServerOnError(pbIsSynchroFromServerOnError: boolean) {
		if (pbIsSynchroFromServerOnError !== this.mbIsSynchroFromServerOnError) {
			if (pbIsSynchroFromServerOnError)
				this.isSynchronizingFromServer = undefined;
			this.moIsSynchroFromServerOnErrorSubject.next(this.mbIsSynchroFromServerOnError = pbIsSynchroFromServerOnError);
		}
	}
	/** Observe si la synchronisation de la base de données depuis le serveur est en erreur. */
	public get isSynchroFromServerOnError$(): Observable<boolean> {
		return this.moIsSynchroFromServerOnErrorSubject.asObservable();
	}

	private readonly moDatabaseIsSynchronizingToServerSubject = new BehaviorSubject<ISyncronizationEvent>(undefined);
	private mbIsSynchronizingToServer: ISyncronizationEvent;
	/** Indique si la base de données est en train de se synchroniser vers le serveur. */
	public get isSynchronizingToServer(): ISyncronizationEvent {
		return this.mbIsSynchronizingToServer;
	}
	public set isSynchronizingToServer(pbIsSynchronizingToServer: ISyncronizationEvent) {
		if (pbIsSynchronizingToServer !== this.mbIsSynchronizingToServer) {
			if (pbIsSynchronizingToServer)
				this.isSynchroToServerOnError = false;
			this.moDatabaseIsSynchronizingToServerSubject.next(this.mbIsSynchronizingToServer = pbIsSynchronizingToServer);
		}
	}
	/** Observe si la base de données est en train de se synchroniser vers le serveur. */
	public get isSynchronizingToServer$(): Observable<ISyncronizationEvent> {
		return this.moDatabaseIsSynchronizingToServerSubject.asObservable();
	}

	private readonly moIsSynchroToServerOnErrorSubject = new BehaviorSubject<boolean>(false);
	private mbIsSynchroToServerOnError: boolean;
	/** Indique si la synchronisation de la base de données vers le serveur est en erreur. */
	public get isSynchroToServerOnError(): boolean {
		return this.mbIsSynchroToServerOnError;
	}
	public set isSynchroToServerOnError(pbIsSynchroToServerOnError: boolean) {
		if (pbIsSynchroToServerOnError !== this.mbIsSynchroToServerOnError) {
			if (pbIsSynchroToServerOnError)
				this.isSynchronizingToServer = undefined;
			this.moIsSynchroToServerOnErrorSubject.next(this.mbIsSynchroToServerOnError = pbIsSynchroToServerOnError);
		}
	}
	/** Observe si la synchronisation de la base de données vers le serveur est en erreur. */
	public get isSynchroToServerOnError$(): Observable<boolean> {
		return this.moIsSynchroToServerOnErrorSubject.asObservable();
	}

	private readonly moSyncMarkerSubject = new BehaviorSubject<IDatabaseSyncMarker>(undefined);
	private moSyncMarker: IDatabaseSyncMarker;
	/** Marqueur de synchronisation de la base de données. */
	public get syncMarker(): IDatabaseSyncMarker {
		return this.moSyncMarker;
	}
	public set syncMarker(poSyncMarker: IDatabaseSyncMarker) {
		if (poSyncMarker !== this.moSyncMarker && poSyncMarker) {
			console.debug(`${Database.C_LOG_ID}Marqueur de synchronisation de la base '${this.id}' mis à jour.`, poSyncMarker);
			this.moSyncMarkerSubject.next(this.moSyncMarker = poSyncMarker);
			this.localLastSeq = poSyncMarker.localSequenceNumber;
		}
	}
	/** Observe le marqueur de synchronisation de la base de données. */
	public get syncMarker$(): Observable<IDatabaseSyncMarker> {
		return this.moSyncMarkerSubject.asObservable();
	}

	private readonly moLocalLastSeqSubject = new BehaviorSubject<number>(undefined);
	/** Numéro de séquence de la base locale. */
	public get localLastSeq(): number {
		return this.moLocalLastSeqSubject.value;
	}
	public set localLastSeq(pnLocalLastSeq: number) {
		if ((this.moLocalLastSeqSubject.value ?? -1) !== pnLocalLastSeq) {
			console.debug(`${Database.C_LOG_ID}Numéro de séquence local de la base '${this.id}' mis à jour de ${this.moLocalLastSeqSubject.value} à ${pnLocalLastSeq}.`);
			this.moLocalLastSeqSubject.next(pnLocalLastSeq);
		}
	}
	/** Observe le numéro de séquence de la base locale. */
	public get localLastSeq$(): Observable<number> {
		if (this.moLocalLastSeqSubject.value === undefined) {
			return this.getLastSeqFromInstance("local")
				.pipe(
					tap((pnLastSeq: number) => this.localLastSeq = pnLastSeq),
					mergeMap(() => this.localLastSeq$)
				);
		}

		return this.moLocalLastSeqSubject.asObservable();
	}

	/** Statut de synchronisation de la base de données. */
	public get syncStatus$(): Observable<EDatabaseSyncStatus> {
		if (this.syncType === ESyncType.none || this.syncType === ESyncType.remote || this.syncType === ESyncType.replicateOnStart)
			return of(EDatabaseSyncStatus.upToDate);

		return combineLatest([this.isSynchroFromServerOnError$, this.isSynchroToServerOnError$])
			.pipe(
				map((paOnError: boolean[]) => paOnError.includes(true)),
				switchMap((pbOnError: boolean) => {
					if (pbOnError)
						return of(EDatabaseSyncStatus.error);
					return combineLatest([this.isSynchronizingFromServer$, this.isSynchronizingToServer$])
						.pipe(
							map((paSynchronizing: ISyncronizationEvent[]) => paSynchronizing.some((poEvent: ISyncronizationEvent) => !!poEvent)),
							switchMap((pbIsSynchronizing: boolean) => {
								if (pbIsSynchronizing)
									return of(EDatabaseSyncStatus.synchronizing);
								return this.localLastSeq$.pipe(
									switchMap((pnLastSeq: number) => this.syncMarker$.pipe(map((poSyncMarker: IDatabaseSyncMarker) => {
										if (!poSyncMarker && pnLastSeq === 0)
											return EDatabaseSyncStatus.upToDate;
										else if (!NumberHelper.isValid(poSyncMarker?.localSequenceNumber))
											return EDatabaseSyncStatus.upToDate;
										else if (poSyncMarker.localSequenceNumber === pnLastSeq)
											return EDatabaseSyncStatus.upToDate;
										return EDatabaseSyncStatus.obsolete;
									})))
								);
							})
						);
				}),
				distinctUntilChanged()
			);
	}

	private moCanReplicateSubject = new BehaviorSubject<boolean>(true);
	public get canReplicate$(): Observable<boolean> {
		return this.moCanReplicateSubject.asObservable().pipe(distinctUntilChanged());
	}

	public get canReplicate(): boolean {
		return this.moCanReplicateSubject.value;
	}
	public set canReplicate(pbCanReplicate: boolean) {
		if (pbCanReplicate !== this.moCanReplicateSubject.value)
			this.moCanReplicateSubject.next(pbCanReplicate);
	}

	public meta: IDatabaseMeta;

	//#endregion

	//#region METHODS

	constructor(
		psId: string,
		poSyncType: ESyncType,
		paRoles: EDatabaseRole[],
		pbHasDatabaseMeta: boolean = false,
		/** Mode de réplication local -> serveur, 'classic' par défaut. */
		public readonly localToServerReplicationMode: ELocalToServerReplicationMode = ELocalToServerReplicationMode.classic,
		public readonly optimization?: IOptimizationConfig
	) {
		this.id = psId;
		this.syncType = poSyncType;
		this.roles = paRoles;
		this.mbIsInitialized = false;
		this.mbHasDatabaseMeta = pbHasDatabaseMeta;
	}

	/** Crée une base de données Pouch locale.
	 * @param psName Chaîne de caractères correspondant au nom vers la base de données locale.
	 * @param poLocalConfig Objet de config contenant les paramètres nécessaires à la création de la base de données Pouch.
	 */
	public createLocalInstance(psName: string, poLocalConfig: ILocalDatabaseConfig): PouchDB.Database {
		return this.moLocalPouchDatabase = new PouchDB(psName, poLocalConfig);
	}

	/** Crée une base de données Pouch distante.
	 * @param psUrl Chaîne de caractères correspondant au lien internet vers la base de données distante.
	 * @param poRemoteConfig Objet de config contenant les paramètres nécessaires à la création de la base de données Pouch.
	 */
	public createRemoteInstance(psUrl: string, poRemoteConfig: IRemoteDatabaseConfig): PouchDB.Database {
		return this.moRemotePouchDatabase = new PouchDB(psUrl, poRemoteConfig);
	}

	/** Retourne l'instance de la base de données pouch locale.
	 * @returns L'instance locale, `undefined` si non instanciée.
	 */
	public getLocalInstance(): PouchDB.Database {
		return this.moLocalPouchDatabase;
	}

	/** Retourne l'instance de la base de données pouch distante.
	 * @returns L'instance distante, `undefined` si non instanciée.
	 */
	public getRemoteInstance(): PouchDB.Database {
		return this.moRemotePouchDatabase;
	}

	/** Retourne un Observable booléen : `true` si la base Pouch locale est vide, `false` sinon. */
	public isLocalInstanceEmpty(): Observable<boolean> {
		return this.getLocalInstanceInfo()
			.pipe(map((poPouchDatabaseInfo: PouchDB.Core.DatabaseInfo) => poPouchDatabaseInfo.doc_count === 0));
	}

	/** Retourne un Observable booléen : `true` si la base Pouch locale est nouvelle, `false` sinon. */
	public isLocalInstanceNew(): Observable<boolean> {
		return this.getInitializedMarker()
			.pipe(map((poDoc: IStoreDocument) => !poDoc));
	}

	private getInitializedMarker(): Observable<IStoreDocument> {
		return defer(() => this.moLocalPouchDatabase.get(Database.C_INITIALIZED_MARKER_ID))
			.pipe(
				// Si le document n'existe pas, on retourne `undefined`.
				catchError((poError: ICustomPouchError) => poError?.status === 404 ? of(undefined) : throwError(poError))
			);
	}

	/** Marque la base locale comme étant initialisée avec une réplication initiale. */
	public markLocalInstanceAsFirstReplicated(): Observable<boolean> {
		return this.isLocalInstanceNew()
			.pipe(
				mergeMap((pbIsNew: boolean) => pbIsNew ? // Si c'est une nouvelle instance, on sérialise le marqueur sinon c'est ok.
					this.moLocalPouchDatabase.put({ _id: Database.C_INITIALIZED_MARKER_ID }).then((poResponse: PouchDB.Core.Response) => poResponse.ok) : of(true)
				)
			);
	}

	/** Marque la base locale comme n'étant pas initialisée avec une réplication initiale. */
	public markLocalInstanceAsNotFirstReplicated(): Observable<boolean> {
		return this.getInitializedMarker()
			.pipe(
				mergeMap((poInitializedMarker: IStoreDocument) => poInitializedMarker ? // Si le markeur est présent, on le supprime sinon c'est ok.
					this.moLocalPouchDatabase.remove(poInitializedMarker as any).then((poResponse: PouchDB.Core.Response) => poResponse.ok) : of(true)
				)
			);
	}

	private getLocalInstanceInfo(): Observable<PouchDB.Core.DatabaseInfo> {
		return from(this.moLocalPouchDatabase.info())
			.pipe(tap((poInfo: PouchDB.Core.DatabaseInfo) => console.log(`DB:: Database '${this.id}'.info() :`, poInfo)));
	}

	/** Ferme et libère la mémoire occupée par la base de données Pouch locale. */
	public removeLocalInstance(): void {
		if (this.moLocalPouchDatabase)
			this.moLocalPouchDatabase.close();

		this.moLocalPouchDatabase = undefined;
	}

	/** Ferme et libère la mémoire occupée par la base de données Pouch distante. */
	public removeRemoteInstance(): void {
		if (this.moRemotePouchDatabase)
			this.moRemotePouchDatabase.close();

		this.moRemotePouchDatabase = undefined;
	}

	/** Indique si la base de données possède le role passé en paramètre.
	 * @param peRole Role à tester.
	 */
	public hasRole(peRole: EDatabaseRole): boolean {
		return this.roles.some((peDbRole: EDatabaseRole) => peDbRole === peRole);
	}

	/** Retourne `true` si une instance locale de la base de données est présente, `false` sinon. */
	public hasLocalInstance(): boolean {
		return !!this.moLocalPouchDatabase;
	}

	/** Retourne `true` si une instance distante de la base de données est présente, `false` sinon. */
	public hasRemoteInstance(): boolean {
		return !!this.moRemotePouchDatabase;
	}

	/** Récupère le dernier numéro de séquence de la base de données, `undefined` si la base n'est pas instanciée. */
	public getLastSeqFromInstance(psInstance: "local"): Observable<number>
	public getLastSeqFromInstance(psInstance: "server"): Observable<string>
	public getLastSeqFromInstance(psInstance: "local" | "server"): Observable<string | number> {
		const loInstance: PouchDB.Database = psInstance === "local" ? this.moLocalPouchDatabase : this.moRemotePouchDatabase;

		if (loInstance)
			return from(loInstance.info()).pipe(map((poPouchDatabaseInfo: PouchDB.Core.DatabaseInfo) => poPouchDatabaseInfo.update_seq));

		return of(undefined);
	}

	/** Permet de fermer les connexions vers la base de données. */
	public close(): void {
		this.removeLocalInstance();
		this.removeRemoteInstance();

		this.mbIsInitialized = false;
		this.mbIsClosed = true;
		this.completeSubjects();
	}

	/** Retourne `true` si la destruction complète de la base de données locale (plus aucune donnée disponible) a fonctionné
	 * ou si la base locale n'existait pas, `false` sinon.
	 */
	public destroyLocalInstance(): Observable<boolean> {
		if (this.moLocalPouchDatabase) {
			const loPerformanceManager = new PerformanceManager;

			loPerformanceManager.markStart();
			return from(this.moLocalPouchDatabase.destroy())
				.pipe(
					tap(() => {
						this.mbIsInitialized = false;
						this.resetSyncMarker();
						console.debug(`${Database.C_LOG_ID}Destruction de la base de données locale '${this.id}' en ${loPerformanceManager.markEnd().measure()}ms.`);
					}),
					mapTo(true),
					catchError(poError => {
						console.error(`${Database.C_LOG_ID}Erreur lors de la destruction de la base de données locale '${this.id}' en ${loPerformanceManager.markEnd().measure()}ms.`, poError);
						return of(false);
					})
				);
		}
		else
			return of(true);
	}

	/** Termine tous les sujets pour éviter les fuites mémoires. */
	private completeSubjects(): void {
		this.moDatabaseIsSynchronizingFromServerSubject.complete();
		this.moIsSynchroFromServerOnErrorSubject.complete();
		this.moDatabaseIsSynchronizingToServerSubject.complete();
		this.moIsSynchroToServerOnErrorSubject.complete();
		this.moSyncMarkerSubject.complete();
		this.moLocalLastSeqSubject.complete();
	}

	/** Rénitialisation du marquer de synchronisation de la base de données. */
	private resetSyncMarker(): void {
		this.syncMarker = { _id: IdHelper.buildId(EPrefix.sync, this.id), databaseId: this.id };
	}

	//#endregion
}