import { DateFormatter } from "./DateFormatter";

export interface Calendar{
  /** Mode d'affichage du calendrier */
  mode: string,
  /** Date sélectionnée */
  currentDate: Date,
  /** Formateur de la date */
  dateFormatter: DateFormatter,
  /** Localisation de l'utilisateur */
  locale: string, 
  /** Format pour le titre du mode day */
  formatDayTitle: string,
  /** Format pour le titre du mode week */
  formatWeekTitle: string,
  /** Format pour la colonne de l'heure */
  formatHourColumn: string, 
  /** Jour de départ du mode month */
  startingDayMonth: number,
  /** Jour de départ du mode week */
  startingDayWeek: number
}