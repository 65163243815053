import { StringHelper } from '../../helpers';
import { EntityBuilder } from '../../services/EntityBuilder';
import { IGroup } from '../contacts/IGroup';
import { EPrefix } from '../EPrefix';
import { EAvatarSize } from '../picture/EAvatarSize';
import { EDatabaseRole } from '../store/EDatabaseRole';
import { Entity } from './Entity';
import { GroupEntityGuard } from './GroupEntityGuard';

export class GroupEntityBuilder extends EntityBuilder<IGroup>{
	constructor() {
		super(
			(psModelId: string) => RegExp(EPrefix.group).test(psModelId),
			(poGroup: IGroup) => new Entity(
				poGroup,
				`groupes/${poGroup._id}`,
				() => StringHelper.isBlank(poGroup.name) ? `Nouveau groupe` : poGroup.name
			),
			(psModelId: string) => "Groupes",
			EDatabaseRole.contacts,
			EPrefix.group,
			() => ({
				size: EAvatarSize.medium,
				icon: "people"
			}),
			undefined,
			undefined,
			undefined,
			new GroupEntityGuard()
		);
	}
}