import { CommonModule } from '@angular/common';
import { NgModule, Provider, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { Network } from '@ionic-native/network/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { IonicModule } from '@ionic/angular';
import { ROUTE_COMPONENTS } from '../constants';
import { SitesModule } from '../modules/sites/sites.module';
import { PipeModule } from '../pipes/pipes.module';
import { ApplicationSettingsModule } from './applicationSettings';
import { AuthenticatorModule } from './authenticator';
import { RouteComponentRegistries } from './ComponentRegistry';
import { ContactsModule } from './contacts';
import { ConversationModule } from './conversation';
import { DateModule } from './date';
import { DiagnosticsModule } from './diagnostics';
import { DynamicPageModule } from './dynamicPage';
import { EntityModule } from './entity';
import { FilePickerModule } from './filePicker';
import { FormListModule, FormModule } from './forms';
import { GalleryModule } from './gallery';
import { GeolocationModule } from './geolocation';
import { LikeModule } from './like';
import { LinkModule } from './link';
import { LinkedItemsListModule } from './linkedItemsList/linkedItemsList.module';
import { MenuModule } from './menu';
import { PopoverModule } from './popover';
import { SearchModule } from './search';
import { SecurityModule } from './security/security.module';
import { SlideboxModule } from './slidebox';
import { ToolbarModule } from './toolbar';
import { WebViewModule } from './webview';

const modules: Type<any>[] = [
	PipeModule,
	IonicModule,
	FormsModule,
	CommonModule
];
const osappModules: Type<any>[] = [
	ApplicationSettingsModule,
	AuthenticatorModule,
	ContactsModule,
	ConversationModule,
	DateModule,
	DiagnosticsModule,
	DynamicPageModule,
	EntityModule,
	FilePickerModule,
	FormListModule,
	FormModule,
	GalleryModule,
	GeolocationModule,
	LikeModule,
	LinkedItemsListModule,
	LinkModule,
	MenuModule,
	PopoverModule,
	SearchModule,
	SlideboxModule,
	ToolbarModule,
 	WebViewModule,
	SecurityModule,
	SitesModule
];
const providers: Provider[] = [
	SocialSharing,
	Network,
	FingerprintAIO,
	{ provide: ROUTE_COMPONENTS, useValue: RouteComponentRegistries }
];

/** Ce module regroupe tous les composants généraux de la bibliothèque. */
@NgModule({
	imports: [modules, ...osappModules],
	exports: [...osappModules],
	providers: providers
})
export class ComponentsModule { }