import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ConfigData, Credentials, IHttpError } from '../../../model';
import { SecurityService } from '../../../services';
import { AuthenticatedRequestOptionBuilder } from '../../api/models/authenticated-request-option-builder';
import { OsappApiHelper } from '../../osapp-api/helpers/osapp-api.helper';
import { IChangePasswordInfos } from '../model/IChangePasswordInfos';

interface ErrorMatcher {
	[status: number]: string | { [code: string]: string }
}
interface HttpPostError {
	status: number;
	error?: { code: string, message?: string }
}
interface HttpBody {
	email?: string;
	password?: string;
}

@Injectable({
	providedIn: "root"
})
export class PasswordService {

	//#region PROPERTIES

	private get baseUrl(): string {
		return `${ConfigData.environment.cloud_url}/api/apps/${ConfigData.appInfo.appId}/security/users`;
	}

	//#endregion

	//#region METHODS

	constructor(private ioHttpClient: HttpClient, private isvcSecurity: SecurityService) { }

	private httpPost(psTargetUrl: string, poParams: HttpBody | IChangePasswordInfos): Observable<Object> {
		return this.ioHttpClient.post(`${this.baseUrl}/${psTargetUrl}`, poParams, AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions());
	}

	public changePassword(poChangePasswordInfos: IChangePasswordInfos): Observable<Object> {
		return this.isvcSecurity.getUserCredentials()
			.pipe(
				tap((poCredentials: Credentials) => {
					poChangePasswordInfos.login = poCredentials.login;
				}),
				mergeMap(_ => this.httpPost("change-password", poChangePasswordInfos)),
				catchError((poError: HttpPostError) => {
					const loError: ErrorMatcher = {
						401: {
							PASSWORD_INVALID: "Le mot de passe est invalide.",
							TOKEN_INVALID: "Identification invalide. Veuillez vous reconnecter à l'application."
						},
						404: {
							ACCOUNT_NOT_FOUND: "Aucun compte n'existe avec ce login"
						}
					};
					return this.throwErrorWithMessage(poError, loError);
				})
			);
	}

	public sendResetPasswordEmail(psEmail: string): Observable<Object> {
		return this.httpPost("reset-password-email", { email: psEmail })
			.pipe(
				catchError((poError: HttpPostError) => this.throwErrorWithMessage(poError, { 404: "Aucun compte n'est associé à cette adresse.", 500: poError.error.message }))
			);
	}

	public resetPassword(psToken: string, psPassword: string): Observable<Object> {
		return this.httpPost(`reset-password?token=${psToken}`, { password: psPassword })
			.pipe(
				catchError((poError: HttpPostError) => this.throwErrorWithMessage(poError, { 404: "Le jeton de réinitialisation du mot de passe est invalide." }))
			);
	}

	public verifToken(token: string): Observable<any> {
		const headers = new HttpHeaders({
			"Appinfo": OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
			"api-key": ConfigData.environment.API_KEY,
		});

		return this.ioHttpClient.get(`${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_apps_suffix}security/users/resetTokenExpire?token=${token}`, { headers: headers })
			.pipe(
				map((res: any) => res),
				catchError((error: HttpErrorResponse) => throwError(error))
			);
	}

	private throwErrorWithMessage(poError: HttpPostError, poErrorMatcher: ErrorMatcher): Observable<never> {
		const loError: IHttpError = {
			status: poError.status,
			code: poError.error ? poError.error.code : null,
			message: null
		};

		if (poErrorMatcher[loError.status]) {
			if (loError.code && poErrorMatcher[loError.status][loError.code])
				loError.message = poErrorMatcher[loError.status][loError.code];
			else
				loError.message = poErrorMatcher[loError.status] as string;
		}
		else
			loError.message = "Une erreur est survenue lors du changement de mot de passe. Veuillez contacter le support.";

		return throwError(loError);
	}

	//#endregion

}