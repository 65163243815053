import { Injectable } from '@angular/core';
import { Zip } from '@ionic-native/zip/ngx';
import { PlatformService } from '../../../services/platform.service';
import { PerformanceManager } from '../../performance/PerformanceManager';
import { UnzipError } from '../errors/unzip-error';

@Injectable()
export class UnzipService {

	//#region FIELDS

	private static C_LOG_ID = "UNZIP.S::";

	//#endregion

	//#region METHODS

	constructor(private ioZip: Zip, private isvcPlatform: PlatformService) { }

	/** Permet d'extraire des fichiers d'un zip.
	 * @param psSource Localisation du zip.
	 * @param psDestination Dossier cible.
	 * @param pfOnProgress Callback appelée lors de l'avancement de l'extraction.
	 * @returns 
	 */
	public async extract(psSource: string, psDestination: string, pfOnProgress?: (poEvent: ProgressEvent) => void): Promise<void> {
		if (this.isvcPlatform.isMobileApp) {
			const loPerformanceManager = new PerformanceManager;

			loPerformanceManager.markStart();

			await this.innerExtractMobile(psSource, psDestination, pfOnProgress);

			console.debug(`${UnzipService.C_LOG_ID}Unzip executed in ${loPerformanceManager.markEnd().measure()}ms.`);
		}
		else
			throw new UnzipError("Navigateur non supporté.");
	}

	private async innerExtractMobile(psSource: string, psDestination: string, pfOnProgress?: (poEvent: ProgressEvent) => void): Promise<void> {
		try {
			if (await this.ioZip.unzip(psSource, psDestination, pfOnProgress) !== 0) {
				console.error(`${UnzipService.C_LOG_ID}Erreur lors du dézipage sur mobile.`);
				throw new UnzipError;
			}
		} catch (poError) {
			console.error(`${UnzipService.C_LOG_ID}Erreur non gérée lors du dézipage sur mobile.`, poError);
			throw poError;
		}
	}

	//#endregion

}
