import { NumberHelper } from '@osapp/helpers/numberHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { OsappError } from '@osapp/modules/errors/model/OsappError';
import { Exclude, Expose } from 'class-transformer';
import { EWeekRepetitionType } from './EWeekRepetitionType';
import { IWeekRepetition } from './IWeekRepetition';

export class WeekRepetition implements IWeekRepetition {
	//#region FIELDS

	private static readonly C_DEFAULT_VALUE = 1;

	//#endregion

	//#region PROPERTIES

	@Exclude()
	private meType: EWeekRepetitionType;
	@Expose()
	public get type(): EWeekRepetitionType { return this.meType; }
	public set type(peType: EWeekRepetitionType) {
		if (!EWeekRepetitionType.values().includes(peType))
			throw new OsappError(`Le type ${peType} est invalide.`);

		if (peType !== this.meType) {
			this.meType = peType;
			if (this.meType === EWeekRepetitionType.everyWeek)
				delete this.mnValue;
			else if (!NumberHelper.isValidStrictPositive(this.mnValue))
				this.mnValue = WeekRepetition.C_DEFAULT_VALUE;
		}
	}

	@Exclude()
	private mnValue: number;
	@Expose()
	public get value(): number { return this.mnValue; }
	public set value(pnValue: number) {
		if (pnValue !== this.mnValue) {
			if (ObjectHelper.isDefined(pnValue)) {
				if (this.meType === EWeekRepetitionType.everyWeek)
					throw new OsappError(`Impossible de paramétrer la valeur si le type est toutes les semaines.`);
				if (!NumberHelper.isValidStrictPositive(pnValue))
					throw new OsappError(`La valeur ${pnValue} est invalide pour un paramétrage toutes les X semaines.`);
			}
			this.mnValue = pnValue;
		}
	}

	//#endregion

	//#region METHODS

	constructor(poData?: IWeekRepetition) {
		this.type = poData?.type ?? EWeekRepetitionType.everyWeek;
		if (NumberHelper.isValidStrictPositive(poData?.value))
			this.value = poData?.value;
	}

	public static equals(poWeekRepetition: IWeekRepetition, poOtherWeekRepetition: IWeekRepetition): boolean {
		return poWeekRepetition === poOtherWeekRepetition || (poWeekRepetition.value === poOtherWeekRepetition.value && poWeekRepetition.type === poOtherWeekRepetition.type);
	}

	//#endregion
}
