<di-menu-mobile></di-menu-mobile>
<lua-papier class="bouton-surface" ngClass="filled" mode="filled" [fullWidth]="true" (click)="handleRedirectAmeli()">
	<div>
		<lua-icone iconName="language" color="CouleurPrimaire"></lua-icone>
	</div>
	<div class="text-container">
		<div class="title">Accéder à Amelipro</div>
	</div>
	<lua-icone class="hover-icon" color="Noir" iconName="arrow_outward"></lua-icone>
</lua-papier>
<div #menuSynchro>
	<lua-alerte *ngIf="showSyncro" [filled]="true" [fullWidth]="true" type="warning" iconName="cloud_off"
		message="Désynchronisé" buttonText="Synchroniser" [onClickAction]="onOpenMenuSynchro"></lua-alerte>
</div>