<div class="container">
	<di-side-page-desmos></di-side-page-desmos>
	<div class="contenu">
		<div class="text">
			<div class="entete">
				<lua-icone mode="outlined" iconName="key" color="Blanc" fontSize="60px"></lua-icone>
				<h2>{{title}}</h2>
			</div>
			<div class="corps">
				<lua-input-filled [isAutoFocus]="true" [type]="passwordInputTypeNew" [value]="newPassword"
					libelle="Nouveau mot de passe" [iconName]="passwordIconNew" (valueChange)="handleChangeValue($event)"
					(onClickIcon)="handleClickIconNew($event)"></lua-input-filled>
				<div class="control">
					<div class="item-control">
						<lua-icone [attr.mode]="hasError('notSizeValid') ? 'outlined' : 'filled'"
							[iconName]="hasError('notSizeValid') ? 'circle' : 'check_circle'" color="Blanc"
							fontSize="20px"></lua-icone>
						<p [ngClass]="{ 'bold-text': !hasError('notSizeValid') }">plus de 8 caractères.</p>
					</div>
					<div class="item-control">
						<lua-icone [attr.mode]="hasError('notMinusculeValid') ? 'outlined' : 'filled'"
							[iconName]="hasError('notMinusculeValid') ? 'circle' : 'check_circle'" color="Blanc"
							fontSize="20px"></lua-icone>
						<p [ngClass]="{ 'bold-text': !hasError('notMinusculeValid') }">au moins une minuscule.</p>
					</div>
					<div class="item-control">
						<lua-icone [attr.mode]="hasError('notMajusculeValid') ? 'outlined' : 'filled'"
							[iconName]="hasError('notMajusculeValid') ? 'circle' : 'check_circle' " color="Blanc"
							fontSize="20px"></lua-icone>
						<p [ngClass]="{ 'bold-text': !hasError('notMajusculeValid') }">au moins une majuscule.</p>
					</div>
					<div class="item-control">
						<lua-icone [attr.mode]="hasError('notNumberValid') ? 'outlined' : 'filled'"
							[iconName]="hasError('notNumberValid') ? 'circle' : 'check_circle'" color="Blanc"
							fontSize="20px"></lua-icone>
						<p [ngClass]="{ 'bold-text': !hasError('notNumberValid') }">au moins un chiffre.</p>
					</div>
					<div class="item-control">
						<lua-icone [attr.mode]="hasError('notSpecialCarValid') ? 'outlined' : 'filled'"
							[iconName]="hasError('notSpecialCarValid') ? 'circle' : 'check_circle'" color="Blanc"
							fontSize="20px"></lua-icone>
						<p [ngClass]="{ 'bold-text': !hasError('notSpecialCarValid') }">un caractère spécial (#@&é§!%+=)</p>
					</div>
					<div class="item-control">
						<lua-icone [attr.mode]="hasError('notCourantValid') ? 'outlined' : 'filled'"
							[iconName]="hasError('notCourantValid') ? 'circle' : 'check_circle'" color="Blanc"
							fontSize="20px"></lua-icone>
						<p [ngClass]="{ 'bold-text': !hasError('notCourantValid') }">pas trop courant.</p>
					</div>
					<div *ngIf="!isReset" class="item-control">
						<lua-icone [attr.mode]="hasError('notDifLoginValid') ? 'outlined' : 'filled'"
							[iconName]="hasError('notDifLoginValid') ? 'circle' : 'check_circle'" color="Blanc"
							fontSize="20px"></lua-icone>
						<p [ngClass]="{ 'bold-text': !hasError('notDifLoginValid') }">pas trop proche du login.</p>
					</div>
				</div>
				<lua-input-filled [iconName]="passwordIconConfirm" [value]="confirmNewPassword"
					(onClickIcon)="handleClickIconConfirm($event)" (valueChange)="handleChangeValueConfirm($event)"
					[type]="passwordInputTypeConfirm" libelle="Confirmez votre mot de passe"
					[isError]="isPasswordError"></lua-input-filled><br>
				<lua-alerte *ngIf="resetPasswordError" [filled]="true" type="error"
					message="{{ resetPasswordError.message }}"></lua-alerte>
			</div>

			<div class="action">
				<lua-action-secondaire size="large" [isDisabled]="!isValideMdp" color="Blanc" (click)="resetPassword()"
					libelle="Réinitialiser le mot de passe"></lua-action-secondaire><br>
				<lua-bouton-texte (click)="navigateToLogin()" color="Blanc" libelle="Annuler"></lua-bouton-texte>
			</div>
		</div>
	</div>
</div>