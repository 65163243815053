import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DynamicPageComponent } from '@osapp/components/dynamicPage';
import { ArrayHelper, ComponentBase } from '@osapp/helpers';
import { EBarElementDock, EBarElementPosition, ESortOrder, IBarElement } from '@osapp/model';
import { Loader } from '@osapp/modules/loading/Loader';
import { C_ADMINISTRATORS_ROLE_ID, Roles } from '@osapp/modules/permissions/services/permissions.service';
import { Queue } from '@osapp/modules/utils/queue/decorators/queue.decorator';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '@osapp/services/loading.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { EMPTY, Observable, defer } from 'rxjs';
import { finalize, map, mergeMap, mergeMapTo, takeUntil, tap } from 'rxjs/operators';
import { ITraitement } from '../../../../model/ITraitement';
import { Seance } from '../../../../model/Seance';
import { Traitement } from '../../../../model/Traitement';
import { SeanceService } from '../../../../services/seance.service';
import { TraitementService } from '../../../../services/traitement.service';
import { CANNOT_DELETE_TRAITMENT_ERROR_MESSAGE } from '../../../constantes';
import { Ordonnance } from '../../../ordonnances/models/ordonnance';
import { OrdonnancesService } from '../../../ordonnances/services/ordonnances.service';
import { ITraitementsListParams } from '../../model/ITraitementsListParams';

@Component({
	templateUrl: './traitementsList.component.html',
	styleUrls: ['./traitementsList.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TraitementsListComponent extends ComponentBase implements OnInit, OnDestroy {

	//#region PROPERTIES

	public traitements: ITraitement[];
	@Input() public params: ITraitementsListParams;
	ListSeances: any;

	@Roles(C_ADMINISTRATORS_ROLE_ID)
	public get isAdmin(): boolean {
		return true;
	}

	/** Map des Ordonnances par traitement id. */
	public ordonnancesByTraitementId: Map<string, Ordonnance[]>;

	//#endregion

	//#region METHODS

	constructor(
		public readonly isvcTraitement: TraitementService,
		private readonly isvcLoading: LoadingService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcOrdonnances: OrdonnancesService,
		private readonly isvSeances: SeanceService,
		private moParentPage: DynamicPageComponent<ComponentBase>,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);
	}

	public ngOnInit(): void {
		this.isvcTraitement.getPatientTraitements(this.params.model._id, true)
			.pipe(
				map((paResults: ITraitement[]) => ArrayHelper.dynamicSort(paResults, TraitementService.C_BEGIN_DATE_PROPERTY, ESortOrder.descending)),
				tap((paTraitements: ITraitement[]) => this.traitements = paTraitements),
				mergeMap((paResults: ITraitement[]) => this.initTraitementsDetails(paResults)),
				tap(
					_ => { },
					poError => console.error("TRAIT.LIST.C:: ", poError)
				),
				takeUntil(this.destroyed$)
			).subscribe();

		this.initToolbar();
	}

	private initTraitementsDetails(paTraitements: ITraitement[]): Observable<Map<string, Ordonnance[]>> {
		return this.isvcTraitement.fillTraitementsSeancesNumber(paTraitements).pipe(
			mergeMap(() => this.isvcOrdonnances.getOrdonnancesByTraitementId(paTraitements.map((poTraitement: ITraitement) => poTraitement._id))),
			tap((poOrdonnancesByTraitementId: Map<string, Ordonnance[]>) => {
				this.ordonnancesByTraitementId = poOrdonnancesByTraitementId;
				this.detectChanges();
			})
		);
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moParentPage.toolbar.clear(this.getInstanceId());
	}

	public onTraitementClicked(poTraitement: ITraitement): void {
		this.isvcTraitement.openTraitement(poTraitement._id);
	}

	private initToolbar(): void {
		const laBarElements: IBarElement[] = [
			{
				id: "circle",
				component: "fabButton",
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.right,
				icon: "add",
				onTap: () => this.createNewTraitement(),
				name:"Ajouter"
			}
		];

		this.moParentPage.toolbar.init(laBarElements, this.getInstanceId());
	}

	/** Crée un nouveau traitement pour le patient courant. */
	private createNewTraitement(): void {
		this.isvcTraitement.navigateToNewTraitement(this.params.model);
	}

	public onRemoveTraitementClicked(poTraitement: Traitement, poEvent: MouseEvent): void {
		poEvent.stopPropagation();
		this.removeTraitement(poTraitement).subscribe();
	}

	public onDuplicateTraitementClicked(poTraitement: Traitement, poEvent: MouseEvent): void {
		poEvent.stopPropagation();
		this.duplicate(poTraitement).subscribe();
	}

	@Queue<TraitementsListComponent, Parameters<TraitementsListComponent["removeTraitement"]>, ReturnType<TraitementsListComponent["removeTraitement"]>>({
		idBuilder: (_, poTraitement: Traitement) => poTraitement._id,
		paramsReducer: (_, paNewArgs: [Traitement]) => paNewArgs
	})

	private removeTraitement(poTraitement: Traitement): Observable<boolean> {
		this.ListSeances = [];
		let loLoader: Loader;
		const mes = CANNOT_DELETE_TRAITMENT_ERROR_MESSAGE;
		return defer(() => this.isvcLoading.create("Chargement..."))
			.pipe(
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(() => this.isvSeances.getSeances(poTraitement)),
				tap(() => loLoader.dismiss()),
				mergeMap((seances: Seance[]) => {
					this.ListSeances = seances;

					if (this.isvcTraitement.canDeleteTraitement(this.ListSeances)) {
						return this.isvcUiMessage.showAsyncMessage(
							new ShowMessageParamsPopup({
								header: "Suppression de traitement",
								message: `Voulez-vous supprimer ce traitement ${this.isAdmin ? "et toutes ses séances ?" : "?"}`,
								buttons: [
									{ text: "Annuler", role: UiMessageService.C_CANCEL_ROLE },
									{ text: "Supprimer", cssClass: "deleteButton" }
								]
							})
						).pipe(
							mergeMap(() => this.isvcLoading.create("Suppression du traitement")),
							tap((poLoader: Loader) => loLoader = poLoader),
							mergeMap((poLoader: Loader) => poLoader.present()),
							mergeMap(() => this.isvcTraitement.deleteTraitement(poTraitement, this.isAdmin)),
							finalize(() => loLoader?.dismiss())
						);

					} else {
						return this.isvcUiMessage.showAsyncMessage(
							new ShowMessageParamsPopup({
								header: "Erreur",
								message: CANNOT_DELETE_TRAITMENT_ERROR_MESSAGE,
								buttons: [
									{ text: "OK" }
								]
							})
						).pipe(
							mergeMapTo(EMPTY)
						);
					}
				})
			);
	}


	@Queue<TraitementsListComponent, Parameters<TraitementsListComponent["duplicate"]>, ReturnType<TraitementsListComponent["duplicate"]>>({
		idBuilder: (_, poTraitement: Traitement) => poTraitement._id,
		paramsReducer: (_, paNewArgs: [Traitement]) => paNewArgs
	})
	private duplicate(poTraitement: ITraitement): Observable<boolean> {
		let loLoader: Loader;
		return defer(() => this.isvcLoading.create("Duplication du traitement")).pipe(
			tap((poLoader: Loader) => loLoader = poLoader),
			mergeMap((poLoader: Loader) => poLoader.present()),
			mergeMap(() => this.isvcTraitement.duplicateTraitement(poTraitement._id)),
			finalize(() => loLoader?.dismiss())
		);
	}

	//#endregion
}
