import { Injectable } from '@angular/core';
import { ModalService } from '@osapp/modules/modal/services/modal.service';
import { Observable } from 'rxjs';
import { Traitement } from '../../../../../model/Traitement';
import { Ordonnance } from '../../../models/ordonnance';
import { OrdonnanceModalComponent } from '../ordonnance-modal.component';

@Injectable()
export class OrdonnanceModalOpenerService {

	//#region METHODS

	constructor(private readonly isvcModal: ModalService) { }

	public open(poTraitement: Traitement, poOrdonnance: Ordonnance): Observable<Ordonnance> {
		return this.isvcModal.open<Ordonnance>({
			component: OrdonnanceModalComponent,
			componentProps: {
				traitement: poTraitement,
				ordonnance: poOrdonnance
			}
		});
	}

	//#endregion

}
