import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import camelcase from "camelcase";
import canvg from 'canvg';
import * as ionIcons from 'ionicons/icons';

//Permet de conserver l'import du module canvg même s'il n'est pas utilisé
const can = canvg;

/**
 * Template de header standard. Ce header est composé du bouton back, du bouton menu, et d'un titre.
 * Ce template est utilisé grâce à la directive HeaderDirective.
 */
@Component({
	selector: 'calao-png-icon',
	styleUrls: ['./png-icon.component.scss'],
	templateUrl: './png-icon.component.html'
})
export class PngIconComponent implements OnInit {

	@Input() public name = 'alert-circle-outline';

	@Input() public width = 32;

	@Input() public height = 32;

	public svgImg: string;

	constructor(
		private readonly ioSanitizer: DomSanitizer,
	) { }

	public ngOnInit() {

		const canvas = document.createElement('canvas');
		canvas.setAttribute('width', `${this.width}`);
		canvas.setAttribute('height', `${this.height}`);
		const ctx: any = canvas.getContext('2d');
		ctx.lineWidth = 1;

		const svgIcon = ionIcons[camelcase(this.name)];
		if (svgIcon) {
			ctx.drawSvg(svgIcon, 0, 0);
			this.svgImg = canvas.toDataURL("image/png");
		}
		else
			this.svgImg = `assets/custom-icons/${this.name}.svg`;
	}

	public getStyle(): SafeStyle {
		return this.ioSanitizer.bypassSecurityTrustStyle(`width: ${this.width}px; height: ${this.height}px;`);
	}

}
