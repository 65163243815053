<ng-container *ngIf="amcP">

	<ion-grid>
		<ion-row>
			<ion-col size="4">
				<ion-label>Mutuelle</ion-label>
			</ion-col>

			<ion-col size="8">
				<mat-form-field *ngIf="etablissements?.length > 0" appearance="outline">
					<input type="text" matInput [matAutocomplete]="auto" [(ngModel)]="etablissementName"
						(ngModelChange)="updateFilteredEtablissements()" placeholder="Libellé du tiers">
					<mat-autocomplete #auto="matAutocomplete">
						<mat-option *ngFor="let option of filteredEtablissements" [value]="option.label"
							(click)="onOptionClick(option)">
							<div class="auto-option">
								<span>{{option.label}}</span>
								<span class="small">{{ getFormatedId(option) }}</span>
							</div>
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
			</ion-col>
		</ion-row>

		<ion-row>
			<ion-col size="4">
				<ion-label>Tiers payant</ion-label>
			</ion-col>

			<ion-col size="8">
				<mat-form-field appearance="outline">
					<mat-select [(value)]=" amcP.tp">
						<mat-option *ngFor="let item of TPOptions" [value]="item.value">
							{{item.label}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</ion-col>
		</ion-row>

		<ion-row>
			<ion-col size="4">
				<ion-label>Pièce justificative</ion-label>
			</ion-col>

			<ion-col size="8">
				<ion-label *ngIf="isADRiCalled">ADRi</ion-label>
				<mat-form-field *ngIf="!isADRiCalled" appearance="outline">
					<mat-select [(value)]="amcP.pieceJustificative">
						<mat-option *ngFor="let item of pieceJustificativeOptions" [value]="item.value"
							(click)="onPieceJustificativeChange(item.value)">
							{{item.label}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</ion-col>
		</ion-row>

		<ion-row>
			<ion-col size="4">
				<ion-label>Gestion Unique</ion-label>
			</ion-col>

			<ion-col size="8">
				<ion-item lines="none">
					<ion-toggle [(ngModel)]="amcP.gestionUnique"></ion-toggle>
				</ion-item>
			</ion-col>
		</ion-row>

		<ion-row *ngIf="amcP.datePieceJustificative || isADRiCalled">
			<ion-col size="4">
				<ion-label>Date</ion-label>
			</ion-col>

			<ion-col size="8">
				<ion-label *ngIf="isADRiCalled">{{ amcP.updateDate | dateWithLocale : 'fr-FR' : 'dd/MM/yy HH:mm' }}
				</ion-label>
				<osapp-date-time *ngIf="!isADRiCalled" [(model)]="amcP.datePieceJustificative" [params]="datePickerParams">
				</osapp-date-time>
			</ion-col>
		</ion-row>

		<ion-row>
			<ion-col size="4">
				<ion-label>Période de couverture</ion-label>
			</ion-col>

			<ion-col size="8">
				<div class="v-center space-between">
					<div class="v-center">
						<ion-label>Du</ion-label>
						<osapp-date-time [model]=" amcP.dateDebut" (modelChange)="onDateDebutChanged($event)"
							[params]="datePickerParams"></osapp-date-time>
					</div>
					<div class="v-center">
						<ion-label>Au</ion-label>
						<osapp-date-time [model]=" amcP.dateFin" (modelChange)="onDateFinChanged($event)"
							[params]="datePickerParams"></osapp-date-time>
					</div>
				</div>
			</ion-col>
		</ion-row>

		<ion-row>
			<ion-col size="4">
				<ion-label>Numéro adhérent</ion-label>
			</ion-col>

			<ion-col size="8">
				<mat-form-field appearance="outline">
					<input type="number" matInput [(ngModel)]="amcP.numAdherent" (ngModelChange)="updateFilteredEtablissements()"
						placeholder="00000000">
				</mat-form-field>
			</ion-col>
		</ion-row>

		<ion-row *ngIf="amcP.gestionUnique">
			<ion-col size="4">
				<ion-label>Mutnum</ion-label>
			</ion-col>

			<ion-col size="8">
				<mat-form-field appearance="outline">
					<input type="text" matInput [(ngModel)]="amcP.mutnum" (ngModelChange)="updateFilteredEtablissements()"
						placeholder="00000000">
				</mat-form-field>
			</ion-col>
		</ion-row>

	</ion-grid>

	<div *ngIf="getManualCreationDate() as date" class="info">
		<ion-label>Création manuelle le {{ date | dateWithLocale : 'fr-FR' : 'dd/MM/yy' }} à {{ date | dateWithLocale :
			'fr-FR' : 'HH:mm' }}</ion-label>
	</div>

</ng-container>