import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Loader } from '@osapp/modules/loading/Loader';
import { LoggerService } from '@osapp/modules/logger/services/logger.service';
import { FormsService } from '@osapp/services/forms.service';
import { LoadingService } from '@osapp/services/loading.service';
import { SlideboxService } from '@osapp/services/slidebox.service';
import { IdlActionButtonFieldBase } from '../../../model/action-buttons/IdlActionButtonFieldBase';
import { IPatient } from '../../../model/IPatient';
import { IdlActionButtonService } from '../../../services/idl-action-button.service';
import { PatientsService } from '../../../services/patients.service';

@Component({
	selector: 'idl-maj-patient-fsv-action-button',
	templateUrl: './maj-patient-fsv-action-button.component.html',
	styleUrls: ['./maj-patient-fsv-action-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MajPatientFsvActionButtonComponent extends IdlActionButtonFieldBase implements OnInit {

	//#region PROPERTIES

	public icon: string;
	public label: string;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcPatients: PatientsService,
		private readonly isvcLoading: LoadingService,
		/** @implements */
		public readonly isvcLogger: LoggerService,
		psvcSlidebox: SlideboxService,
		psvcActionButton: IdlActionButtonService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcSlidebox, psvcActionButton, psvcForms, poChangeDetectorRef);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		this.icon = "sync-outline";
		this.label = "MAJ Patient";
	}

	/** @override Exporte un patient vers le logiciel partenaire. */
	public async action(): Promise<void> {
		const lsExternalId: string = (this.model as IPatient).externalId;
		if (lsExternalId) {
			const loLoader: Loader = await this.isvcLoading.create("Chargement");
			await loLoader.present();
			try {
				const loPatient: IPatient = await this.isvcPatients.importPatientFromFsv(lsExternalId);
				this.updateValues(loPatient);
			}
			catch (poError) {
				console.error(poError);
			}
			finally {
				loLoader.dismiss();
			}
		}
	}

	//#endregion

}