import { ChangeDetectorRef, Component, ComponentFactoryResolver, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ComponentBase } from '../../helpers/ComponentBase';
import { LifeCycleObserverComponentBase } from '../../helpers/LifeCycleObserverComponentBase';
import { ILifeCycleEvent } from '../../model/lifeCycle/ILifeCycleEvent';
import { PageManagerService } from '../../services/pageManager.service';
import { DynamicPageComponent } from '../dynamicPage/dynamicPage.component';
import { DynHostDirective } from '../dynHost/dynHost.directive';
import { ApplicationSettingsService } from './applicationSettings.service';
import { SettingsInfo } from './sections/settings-info';

@Component({
	selector: 'calao-applicationSettings',
	templateUrl: 'applicationSettings.component.html'
})
export class ApplicationSettingsComponent extends LifeCycleObserverComponentBase implements OnInit {

	//#region PROPERTIES

	@Input() public settingsKey = "settings";
	@ViewChildren("section") public sectionsInputs: QueryList<DynHostDirective>;

	public sections: SettingsInfo[] = [];

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des paramètres de l'application. */
		private isvcApplicationSettings: ApplicationSettingsService,
		/** Service de résolution de fabrique de composant. */
		private ioFactoryResolver: ComponentFactoryResolver,
		private isvcPageManager: PageManagerService,
		poParentPage: DynamicPageComponent<ComponentBase>,
		poChangeDetector: ChangeDetectorRef) {

		super(poParentPage, poChangeDetector);
	}

	public ngOnInit(): void {
		// On récupère toutes les sections en base pour ensuite les ajouter dynamiquement.
		this.isvcApplicationSettings.getSettings(this.settingsKey)
			.subscribe(
				(paResults: Array<SettingsInfo>) => {
					if (paResults) {
						paResults.forEach((poSettingsInfo: SettingsInfo, pnIndex: number) => {
							this.sections.push(poSettingsInfo);
							this.detectChanges();
							this.addSection(poSettingsInfo.settingsComponentName, pnIndex);
						});
					}
				},
				poError => console.error(poError)
			);
	}

	protected onLifeCycleEvent(poEvent: ILifeCycleEvent): void { }

	/** Ajoute dynamiquement une section de paramètre.
	 * @param psComponentName
	 * @param pnIndex
	 */
	public addSection(psComponentName: string, pnIndex: number): void {
		throw new Error(`addSection on ApplicationSettings non réimplémenté (Migration OSApp)`);
		//TODO MigrOSApp décommenté les trois lignes en dessous.
		//const loFactory: ComponentFactory<any> = this.ioFactoryResolver.resolveComponentFactory(this.isvcPageManager.getComponentById(psComponentName));
		//const laSectionContainers: Array<DynHostDirective> = this.sectionsInputs.toArray();

		//let loComponentRef: ComponentRef<any> = laSectionContainers[pnIndex].viewContainerRef.createComponent(loFactory);
		//(loComponentRef.instance as IDynHostComponent).data = this.sectionsInputs[pnIndex];

		//this.detectChanges();
	}

	//#endregion
}
