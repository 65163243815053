import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "di-separateur-date",
	templateUrl: "./separateur-date.component.html",
	styleUrls: ["./separateur-date.component.scss"],
})
export class SeparateurDateComponent implements OnInit {
	constructor() {}

	public ngOnInit(): void {}

	@Input() date : string = "";
}
