<osapp-header-modal title="Filtrer par états de séances"></osapp-header-modal>

<ion-content>
	<ion-list>
		<ion-item *ngFor="let item of dataSelections">
			<ion-checkbox slot="start" [(ngModel)]="item.selected"></ion-checkbox>
			<ion-label position="fixed">{{ item.data.label }}</ion-label>
			<avatar [src]="item.data.avatar" class="round-etat marg-etat"></avatar>
		</ion-item>
	</ion-list>

</ion-content>

<ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
	<ion-fab-button color="success" (click)="validate()" title="Valider">
		<ion-icon name="checkmark"></ion-icon>
	</ion-fab-button>
</ion-fab>