<osapp-header-modal title="Annuler acte"></osapp-header-modal>

<ion-content>
	<ion-radio-group [(ngModel)]="hasToCancelFollowings">
		<ion-item class="purpose center-explains" lines="none">
			<ion-label>Annuler pour les prochaines séances?</ion-label>
			<ion-radio [value]="true" slot="end" color="primary"></ion-radio>
		</ion-item>
		<ion-item class="purpose center-explains" lines="none">
			<ion-label>Annuler pour cette séance uniquement?</ion-label>
			<ion-radio [value]="false" slot="end" color="primary"></ion-radio>
		</ion-item>
	</ion-radio-group>
	<mat-form-field appearance="outline" class="center-explains">
		<mat-label>Explications</mat-label>
		<textarea matInput matTextareaAutosize matAutosizeMinRows="1" matAutosizeMaxRows="6"
			[(ngModel)]="explanation"></textarea>
	</mat-form-field>

</ion-content>

<ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
	<ion-fab-button color="success" (click)="validate()" title="Valider">
		<ion-icon name="checkmark"></ion-icon>
	</ion-fab-button>
</ion-fab>