import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldBase } from '@osapp/model/forms/FieldBase';
import { FormsService } from '@osapp/services/forms.service';

@Component({
	selector: "idl-rpps-field",
	templateUrl: './rpps-field.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RppsFieldComponent extends FieldBase<string> implements OnInit { //todo : params.

	//#region METHODS

	constructor(psvcForms: FormsService) {
		super(psvcForms);
	}

	//#endregion
}