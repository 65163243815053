import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { FieldWrapper } from '@ngx-formly/core';
import { UserHelper } from '@osapp/helpers/user.helper';
@Component({
	selector: 'idl-signature-wrapper',
	templateUrl: './signature-wrapper.component.html',
	styleUrls: ['./signature-wrapper.component.scss'],
})
export class SignatureWrapperComponent extends FieldWrapper implements OnInit {

	//#region PROPERTIES

	/** Conteneur pour le champs de formulaire. */
	@ViewChild("fieldComponent", { read: ViewContainerRef, static: true }) public fieldComponent: ViewContainerRef;

	//#endregion

	//#region METHODS

	constructor(
		private readonly ioRouter: Router
	) {
		super();
	}

	public ngOnInit(): void {
		if (this.field)
			this.field.hide = this.hasToHideWrapper();
	}

	private hasToHideWrapper(): boolean {
		if (!(this.ioRouter.url.includes(UserHelper.getUserContactId()) || this.ioRouter.url.includes("contacts/me/edit")))
			return true;
		return false;
	}

	//#endregion

}
