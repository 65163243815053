<osapp-header-modal [title]="title"></osapp-header-modal>

<idl-terminals-selector></idl-terminals-selector>

<ion-list>
	<ion-item lines="none">
		<ion-icon *ngIf="!!terminal; else loading;" [name]="terminal?.terminalConnected ? 'checkmark' : 'close'"
			[color]="terminal?.terminalConnected ? 'success' : 'danger'" slot="start"></ion-icon>
		<ion-label>Lecteur connecté</ion-label>
	</ion-item>
	<ion-item lines="none">
		<ion-icon *ngIf="!!terminal; else loading;" [name]="terminal?.cpsInserted ? 'checkmark' : 'close'"
			[color]="terminal?.cpsInserted ? 'success' : 'danger'" slot="start"></ion-icon>
		<ion-label>Carte CPS insérée</ion-label>
	</ion-item>
	<ion-item *ngIf="!params?.bypassVitale" lines="none">
		<ion-icon *ngIf="!!terminal; else loading;" [name]="terminal?.vitaleInserted ? 'checkmark' : 'close'"
			[color]="terminal?.vitaleInserted ? 'success' : 'danger'" slot="start"></ion-icon>
		<ion-label>Carte Vitale insérée</ion-label>
	</ion-item>
</ion-list>

<ion-footer>
	<ion-toolbar>
		<ion-buttons slot="primary">
			<ion-button fill="clear" (click)="close()">
				ANNULER
			</ion-button>
			<ion-button *ngIf="!canValidate; else validateButton;" fill="clear" (click)="getInfo()">
				RÉESSAYER
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-footer>

<ng-template #loading>
	<ion-spinner slot="start"></ion-spinner>
</ng-template>

<ng-template #validateButton>
	<ion-button fill="clear" (click)="close(terminal)">
		{{validationButtonText}}
	</ion-button>
</ng-template>