import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { EModalSize } from '../../../modal/model/EModalSize';
import { ModalService } from '../../../modal/services/modal.service';
import { EDatabaseSyncStatus } from '../../../store/model/EDatabaseSyncStatus';
import { DatabaseSynchroService } from '../../services/database-synchro.service';
import { DatabasesSyncStatusModalComponent } from '../databases-sync-status-modal/databases-sync-status-modal.component';

@Component({
	selector: 'calao-database-sync-status-icon',
	templateUrl: './database-sync-status-icon.component.html',
	styleUrls: ['./database-sync-status-icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatabaseSyncStatusIconComponent extends ComponentBase {

	//#region PROPERTIES

	private msIconName: string;
	/** Nom de l'icône à afficher. */
	public get iconName(): string { return this.msIconName; }

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcDatabaseSynchro: DatabaseSynchroService,
		private readonly isvcModal: ModalService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poChangeDetectorRef);
	}

	public ngOnInit(): void {
		this.isvcDatabaseSynchro.getDatabasesCommonStatus()
			.pipe(
				distinctUntilChanged(),
				debounceTime(500),
				tap((peDatabaseSyncStatus: EDatabaseSyncStatus) => {
					this.msIconName = DatabaseSynchroService.getSyncIcon(peDatabaseSyncStatus);
					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public showSyncStatusModal(): void {
		this.isvcModal.open(
			{ component: DatabasesSyncStatusModalComponent },
			EModalSize.small
		).pipe(
			takeUntil(this.destroyed$)
		)
			.subscribe();
	}

	//#endregion

}