import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LightingUpAngularModule } from 'lighting-up-angular';
import { MenuMobileModule } from '../../features/home/components/menu-mobile/menu-mobile.module';
import { HomePage } from './home.page';

@NgModule({
	imports: [
		CommonModule,
		MenuMobileModule,
		LightingUpAngularModule
	],
	exports: [],
	declarations: [HomePage]
})
export class HomePageModule { }
