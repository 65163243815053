import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { EMPTY, from, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ISurveillances } from '../model/constantes/ISurveillances';
import { ConstantesService } from '../services/constantes.service';

@Injectable({ providedIn: "root" })
export class ConstantesResolver implements Resolve<ISurveillances> {

	//#region METHODS

	constructor(private isvcConstantes: ConstantesService, private ioRouter: Router) { }

	public resolve(poRouteSnapshot: ActivatedRouteSnapshot): Observable<ISurveillances> {
		return this.isvcConstantes.getSurveillance(poRouteSnapshot.params.surveillanceId)
			.pipe(mergeMap((poModel: ISurveillances) => poModel ? of(poModel) : from(this.ioRouter.navigate([".."])).pipe(mergeMap(_ => EMPTY))));
	}

	//#endregion
}