<osapp-header-modal title="Ordonnances"></osapp-header-modal>

<ion-content>
	<div class="list-item-ctn" *ngFor="let ordonnance of ordonnances; let i = index">
		<div class="list-item">
			<div class="left">
				<div class="checkbox-ctn" (click)="toggleSelection(ordonnance)">
					<ion-checkbox class="disable-clicks" fill="outline" slot="start"
						[checked]="selectedOrdonnancesIds.includes(ordonnance._id)"></ion-checkbox>
				</div>
				<div class="list-item-detail only-name-ctn" (click)="toggleSelection(ordonnance)">
					<div class="list-item-ordonnance-name only-name">
						{{ ordonnance.prescriptionDate | dateWithLocale : 'fr-FR' : 'dd/MM/yy' }}
					</div>
				</div>
			</div>
			<div class="list-item-icon" (click)="openOrdonnance(ordonnance)">
				<ion-icon slot="icon-only" name="see-document"></ion-icon>
			</div>
		</div>
	</div>
</ion-content>

<ion-fab vertical="bottom" horizontal="end" slot="fixed">
	<ion-fab-button color="success" (click)="close()" [disabled]="selectedOrdonnancesIds.length > 1 && unique"
		title="Valider">
		<ion-icon name="checkmark"></ion-icon>
	</ion-fab-button>
</ion-fab>