import { ConfigData, ITaskParams } from 'libs/osapp/src/model';
import { Observable, throwError, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { NumberHelper } from '../../helpers/numberHelper';
import { ITask } from './ITask';
import { TaskDescriptor } from './TaskDescriptor';

export abstract class TaskBase<T extends ITaskParams = ITaskParams> implements ITask {

	//#region PROPERTIES

	/** @override */
	public descriptor: TaskDescriptor<T>;

	//#endregion

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<T>) {
		this.descriptor = poDescriptor;
	}

	/** @override */
	abstract execTask(): Observable<any>;

	/** @override */
	public retryStrategy(poErrors$: Observable<any>): Observable<any> {
		return poErrors$ // Observable qui fournit chaque erreur de la tâche.
			.pipe(
				mergeMap((poError: any, pnNbError: number) => {
					// Si le nombre d'échecs est égal au nombre max de répétitions accordées et que ce n'est pas une tâche cyclique.
					if (pnNbError >= ConfigData.backgroundTask.maxRepeatTask && !this.descriptor.intervalRepetition)
						return throwError(poError);
					else {
						const lbHasValidIntervalRepetition: boolean = NumberHelper.isValidPositive(this.descriptor.intervalRepetition);

						if (!lbHasValidIntervalRepetition)
							this.descriptor.retryInterval *= this.descriptor.intervalMultiplicator;

						const lnIntervalTime: number = lbHasValidIntervalRepetition ? this.descriptor.intervalRepetition : this.descriptor.retryInterval;

						this.descriptor.lastExec = new Date();

						return timer(lnIntervalTime);
					}
				})
			);
	}

	//#endregion
} 