import { Pipe, PipeTransform } from '@angular/core';
import { IContact } from '@osapp/model/contacts/IContact';
import { IGroup } from '@osapp/model/contacts/IGroup';
import { TraitementService } from '../../services/traitement.service';

@Pipe({
	name: 'intervenantName'
})
export class IntervenantNamePipe implements PipeTransform {

	//#region METHODS	

	constructor(private isvcTraitement: TraitementService) { }

	transform(poIntervenant: IGroup | IContact): string {
		return this.isvcTraitement.getIntervenantName(poIntervenant);
	}

	//#endregion


}
