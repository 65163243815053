import { EntityBuilder } from '../../services/EntityBuilder';
import { ISecurityConfig } from '../../services/security/ISecurityConfig';
import { IAppInfo } from '../application/IAppInfo';
import { IBackgroundTask } from '../backgroundTask/IBackgroundTask';
import { IConfigOneSignal } from '../config/IConfigOneSignal';
import { IEnvironment } from '../environment/IEnvironment';
import { IAuthentication } from '../IAuthentication';
import { IUpdateConfig } from '../update';
import { IConversationConfig } from './IConversationConfig';

export interface IConfig {

	/** Informations de l'application. */
	appInfo: IAppInfo;
	/** Chaînes de connexion aux serveurs. */
	environment: IEnvironment;
	/** Informations sur l'authentification. */
	authentication: IAuthentication;
	/** Informations sur les background tasks. */
	backgroundTask: IBackgroundTask;
	/** Informations pour OneSignal. */
	oneSignal?: IConfigOneSignal;
	/** Générateurs d'entités. */
	entityBuilders: EntityBuilder[];
	/** Information sur les configurations des conversation. */
	conversation?: IConversationConfig;
	/** Paramètres de mise à jour */
	update: IUpdateConfig;
	/** Identifiant du groupe d'utitilisateurs administrateurs */
	security?: ISecurityConfig;
}