<div class="panneau-patient-deces__container">
	<div class="container__content">
		<form [formGroup]="decesForm" (ngSubmit)="handleSubmit($event)">
			<div class="content__form-inputs">
				<div class="form-inputs">
					<lua-switch
						(clickSwitch)="toggleDeces($event)"
						label="Le patient est décédé"
						[isChecked]="deces"
					></lua-switch>
          <small>Les soins réalisés jusqu’à la date et heure du décès sont à facturer</small>
					<h2 class="content__subtitle">Date et heure</h2>
          <lua-input
          [fullWidth]="true"
          placeholder="Date du décès"
          label="Date du décès"
          formControlName="decesDate"
        ></lua-input>
        <lua-input
          [fullWidth]="true"
          placeholder="Heure du décès"
          label="Heure du décès"
          formControlName="decesHeure"
        ></lua-input>
        <h2 class="content__subtitle">Commentaire</h2>
        <lua-input
          [fullWidth]="true"
          [multiline]="true"
          placeholder="Commentaire"
          label="Commentaire"
          formControlName="decesCommentaire"
        ></lua-input>
				</div>
			</div>
			<div class="content__form-buttons">
				<div class="form-buttons__buttons">
					<lua-action-principale
						libelle="Enregistrer"
						[fullWidth]="true"
						(click)="handleSubmit($event)"
						[isDisabled]="decesForm.invalid"
					></lua-action-principale>
					<lua-bouton-texte
						[fullWidth]="true"
						libelle="Annuler"
						(click)="goBack()"
					></lua-bouton-texte>
				</div>
			</div>
		</form>
	</div>
</div>
