<div class="di-menu-synchro">
	<div *ngIf="!isBusy">
		<small *ngIf="!isDone">Vos données ne sont plus synchronisés, car à un moment vous avez travaillé en mode déconnecté
			suite à une
			faible connexion ou à une perte de couverture d’internet. Vous devez synchroniser vos données.</small>
		<lua-alerte *ngIf="isDone" [filled]="false" [fullWidth]="true" type="success" iconName="check_circle"
			message="Synchronisation terminée"></lua-alerte>
		<div class="listEtatDatabase">
			<div class="listEtatDatabase__item" *ngFor="let database of databasesSyncStatus">
				<lua-icone [iconName]="database.status === 0 ? 'cloud_done' : 'cloud_off' " mode="outlined"
					[color]="database.status === 0 ? 'Vert' : 'Orange'">
				</lua-icone>
				<div class="item__text">
					<strong>{{database.title}}</strong>
					<small>{{database.description}}</small>
				</div>
			</div>
			<lua-action-principale *ngIf="!isDone" libelle="Synchroniser" startIcon="cloud_upload"
				(click)="handleClickSynchro()" [fullWidth]="true"></lua-action-principale>
			<lua-action-secondaire *ngIf="isDone" libelle="Fermer" size="large" [fullWidth]="true" positionIcone="L"
				(click)="handleClose()">
			</lua-action-secondaire>
		</div>
	</div>
	<div *ngIf="isBusy">
		<lua-message-action-en-cours mainText="Synchronisation en cours"
			subText="DESMOS synchronise vos données entre la base de donnée et votre appareil." [loaderSize]="60"
			[loaderSpeed]="1"></lua-message-action-en-cours>
	</div>
</div>