import { Observable } from 'rxjs';
import { EntityBuilder } from '../../services/EntityBuilder';
import { IConversation } from '../conversation/IConversation';
import { IEntity } from '../entities/IEntity';
import { IEntityLink } from '../entities/IEntityLink';
import { ISelectorParams } from '../selector/ISelectorParams';
import { IStoreDocument } from '../store/IStoreDocument';

export interface IConversationFormConfig {

	/** Fonction récupérant l'identifiant du descripteur de formulaire. */
	getFormDescId: () => string;
	/** Icône représentant le formulaire. */
	icon: string;
	/** Texte représentant le formulaire. */
	label: string;
	/** Fonction récupérant l'identifiant de la définition de formulaire. */
	getFormDefId: () => string;
	/** Fonction permettant de définir un corps pour le message envoyé lors de l'édition du formulaire. */
	getFormEditionMessage: (poOldModel: IStoreDocument, poNewModel: IStoreDocument, psDefaultMessage?: string) => string;
	/** Fonction récupérant l'entité qui sera le parent du formulaire, retourne `undefined` si aucune entité n'est liée à la conversation.
	 * @param poConversation Conversation dans laquelle est l'utilisateur.
	 * @param poFormModel Modèle du formulaire qu'on veut ouvrir, s'il est renseigné.
	 */
	getParentEntity?: (poConversation: IConversation, poFormModel?: IStoreDocument) => Observable<IEntity>;
	/** Récupère un tableau de constructeurs d'entités pour déterminer quelles entités peuvent être liées à la conversation.
	 * ### ATTENTION : À renseigner uniquement si la fonction `getParentEntity` est renseignée !
	 */
	getEntityBuilders?: () => EntityBuilder[];
	/** Récupère les paramètres pour le sélecteur d'entités.\
	 * À renseigner uniquement si la fonction `getParentEntity` est renseignée et qu'on souhaite paramétrer la sélection d'entités.
	 */
	getSelectorParams?: () => ISelectorParams<IEntityLink>;
}