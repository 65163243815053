<ion-content>
	<div class="content-selector">
		<ion-note>Sélection de l'horaire</ion-note>
		<div class="flex time-selector">
			<div>
				<ion-item lines="none">
					<ion-input #hourInput type="number" [min]="minHours" [max]="maxHours" [(ngModel)]="hourValue"
						(ionChange)="checkValue(hourInput, 'hour')" (ionBlur)="fillInput(hourInput)">
					</ion-input>
				</ion-item>
				<div class="txt-center">
					<span>Heures</span>
				</div>
			</div>
			<span class="seperator">:</span>
			<div>
				<ion-item lines="none">
					<ion-input #minuteInput type="number" [min]="minMinutes" [max]="maxMinutes" [(ngModel)]="minuteValue"
						(ionChange)="checkValue(minuteInput, 'minute')" (ionBlur)="fillInput(minuteInput)">
					</ion-input>
				</ion-item>
				<div class="txt-center">
					<span>Minutes</span>
				</div>
			</div>
		</div>
	</div>

	<div class="buttons flex">
		<ion-button fill="clear" expand="block" color="danger" (click)="cancel()">
			Annuler
		</ion-button>
		<ion-button fill="clear" expand="block" color="primary" (click)="confirm()">
			Valider
		</ion-button>
	</div>
</ion-content>