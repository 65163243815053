import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FinessFieldComponent } from './components/finess-field/finess-field.component';
import { FinessService } from './services/finess.service';
import { FormModule } from '@osapp/components/forms';

@NgModule({
	declarations: [FinessFieldComponent],
	exports: [FinessFieldComponent],
	imports: [
		CommonModule,
		IonicModule,
		FormsModule,
		ReactiveFormsModule,
		FormModule
	],
	providers: [FinessService]
})
export class FinessModule { }
