<div class="container">
	<di-side-page-desmos></di-side-page-desmos>
	<div class="contenu">
		<div class="text">
			<div class="entete">
				<lua-icone mode="outlined" iconName="key" color="Blanc" fontSize="60px"></lua-icone>
				<h2>{{ title }}</h2>
			</div>
			<div class="corps">
				<p>{{ text }}</p>
				<lua-input-filled [value]="email" (valueChange)="handleChangeValue($event)" [isAutoFocus]="true" type="email"
					libelle=" Quel est votre email de connexion ?"></lua-input-filled>
				<lua-alerte *ngIf="requestError" filled="false" type="error" message="{{ requestError.message }}"></lua-alerte>
			</div>
			<div class="action">
				<lua-action-secondaire size="large" disabled color="Blanc" (click)="sendEmailLien()" class="button_blanc"
					libelle="Envoyer le lien pour réinitialiser"></lua-action-secondaire><br>
				<lua-bouton-texte (click)="navigateToLogin()" color="Blanc" libelle="Annuler"></lua-bouton-texte>
			</div>
		</div>
	</div>
</div>