import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { InitComponentError } from '@osapp/model/errors/InitComponentError';

export class InitConstantesItemError extends InitComponentError {

	constructor() {
		super("Un modèle est nécessaire afficher un élément 'surveillances'.");
		// Nécessaire pour que le 'instanceof InitConstantesItemError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, InitConstantesItemError);
	}

}