import { DateHelper } from "@osapp/helpers";
import { IdHelper } from "@osapp/helpers/idHelper";
import { C_PREFIX_AMOP } from "../../../app/app.constants";
import { ESitSupportAT } from "./ESitSupportAT";
import { EGarantie } from "./egarantie.enum";
import { EQualite } from "./equalite.enum";
import { ESituation } from "./esituation.enum";
import { ETypePieceJustificative } from "./etype-piece-justificative.enum";
import { EUpdateMode } from "./eupdate-mode.enum";
import { IAMOP } from "./iamo-p";
import { IUpdateHistory } from "./iupdate-history";
import { IGalleryFile } from "@osapp/model";

export class AMOP implements IAMOP {

	//#region PROPERTIES

	/** @implements */
	public _id: string;
	/** @implements */
	public _rev?: string;
	/** @implements */
	public _deleted?: boolean;
	/** @implements */
	public deleted?: boolean;
	/** @implements */
	public _conflicts?: string[];
	/** @implements */
	public amoId: string;
	/** @implements */
	public datePieceJustificative: Date;
	/** @implements */
	public pieceJustificative: ETypePieceJustificative;
	/** @implements */
	public qualite: EQualite;
	/** @implements */
	public nir: string;
	/** @implements */
	public assurePatientId: string;
	/** @implements */
	public garantie = EGarantie.NONEXO;
	/** @implements */
	public ald = false;
	/** @implements */
	public situation = ESituation.assuranceMaladie;
	/** @implements */
	public situationDate = new Date();
	/** @implements */
	public updateDate?: Date;
	/** @implements */
	public updateMode?: EUpdateMode;
	/** @implements */
	public updateHistory?: IUpdateHistory[];
	/** @implements */
	public dateDebut: Date;
	/** @implements */
	public dateFin: Date;
	/** @implements */
	public tp = true;
	/** @implements */
	public files : IGalleryFile[];
	/** @implements */
	public supportAT: ESitSupportAT;
	/** @implements */
	public numAttestationAT: string;
	/** @implements */
	public destinataireAT: string;
	/** @implements */
	public numPM: string;
	/** @implements */
	public champPM: string;
	public montantSMG: number;

	public get isActive(): boolean {
		return DateHelper.isBetweenTwoDates(new Date(), DateHelper.resetDay(this.dateDebut), DateHelper.fillDay(this.dateFin));
	}

	public get isActiveAnakin(): boolean {
		if(this.dateDebut && this.dateFin)
			return DateHelper.isBetweenTwoDates(new Date(), DateHelper.resetDay(this.dateDebut), DateHelper.fillDay(this.dateFin));
		if(this.dateDebut)
			return DateHelper.isBetweenTwoDates(new Date(), DateHelper.resetDay(this.dateDebut), DateHelper.fillDay(new Date(8640000000000000)));
		if(this.dateFin)
			return DateHelper.isBetweenTwoDates(new Date(), DateHelper.resetDay(new Date(-8640000000000000)), DateHelper.fillDay(this.dateFin));
		// Si il n'y a pas de dateDebut ni de dateFin alors l'AMO est active
		return true
	}

	public get qualiteLabel(): string {
		switch (this.qualite) {
			case EQualite.assure:
				return "Assuré";
			case EQualite.ascendantDescendantCollateraux:
				return "Ascendant, descendant, collatéraux";
			case EQualite.conjoint:
				return "Conjoint";
			case EQualite.conjointDivorce:
				return "Conjoint divorcé";
			case EQualite.concubin:
				return "Concubin";
			case EQualite.conjointSepare:
				return "Conjoint séparé";
			case EQualite.enfant:
				return "Enfant";
			case EQualite.conjointVeuf:
				return "Conjoint veuf";
			case EQualite.autre:
				return "Autre";
		}
	}

	//#endregion

	//#region METHODS

	constructor(psPatientId: string) {
		this._id = IdHelper.buildChildId(C_PREFIX_AMOP, psPatientId);
		this.dateDebut = undefined;
		this.dateFin = undefined;
		this.qualite = EQualite.assure;
	}

	//#endregion

}
