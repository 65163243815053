<ng-container [ngSwitch]="displayMode">
	<!-- Mode tags -->
	<div *ngSwitchCase="'tags'" class="tags-list">
		<ion-label *ngIf="label" class="tags-list-label">{{label}}</ion-label>
		<ng-container *ngIf="scrollWrapper; then scrollTemplate else defaultTemplate"></ng-container>
	</div>

	<!-- Mode liste -->
	<div *ngSwitchCase="'list'" class="list-view">
		<div *ngIf="searchOptions" class="search-box txt-center width100pc">
			<search [data]="values" [options]="searchOptions" (filteredEntriesChanged)="onFilteredValuesChanged($event)">
			</search>
		</div>

		<ion-radio-group *ngIf="!(searchOptions?.hasPreFillData === false && filteredOptions.length === 0)"
			[value]="defaultSingleValue" [allowEmptySelection]="min === 0">
			<ion-item-divider *ngIf="label">
				<ion-label>{{label}}</ion-label>
			</ion-item-divider>
			<ng-container *ngIf="filteredOptions?.length > 0; else emptyScreen">
				<ng-container *ngFor="let option of filteredOptions">
					<ion-item class="radio-item" *ngIf="multiple; else radio" (click)="onListValueSelected(option.value)"
						[disabled]="isDisabled(option)" [matTooltip]="option.tooltip">
						<ion-label>{{option.label}}</ion-label>
						<ion-checkbox slot="start" [checked]="(selectedValues.changes$ | async)?.includes(option.value)"
							class="ion-margin-top ion-margin-bottom">
						</ion-checkbox>
					</ion-item>
					<ng-template #radio>
						<ion-item class="radio-item" (click)="onListValueSelected(option.value)" [matTooltip]="option.tooltip">
							<ion-label>{{option.label}}</ion-label>
							<ion-radio mode="md" slot="start" [value]="option.value"></ion-radio>
						</ion-item>
					</ng-template>
				</ng-container>
			</ng-container>
		</ion-radio-group>
	</div>

	<!-- Mode par défaut (ion-select) -->
	<div *ngSwitchDefault lines="none" class="selector-space">
		<ion-label *ngIf="label" class="label-selector">{{label}}</ion-label>
		<ion-select *ngIf="options?.length > 0"
			[value]="!!multiple ? (selectedValues.changes$ | async) : getFirstElement(selectedValues.changes$ | async)"
			[multiple]="!!multiple" (ionChange)="onPopupValueSelected($event?.detail?.value)" [class]="selectCss"
			[interface]="displayMode" cancelText="Annuler" okText="Valider">
			<ion-select-option *ngFor="let option of options" [value]="option.value" [disabled]="isDisabled(option)"
				[matTooltip]="option.tooltip">
				{{option.label}}
			</ion-select-option>
		</ion-select>
	</div>

</ng-container>

<ng-template #scrollTemplate>
	<div class="tags-list-container">
		<ion-button class="left-button" fill="clear" size="big" (click)="moveLeft(dragScroll)" [hidden]="hidLeftBtn">
			<ion-icon name="chevron-back-outline"></ion-icon>
		</ion-button>
		<mat-chip-list multiple>
			<drag-scroll #dragScroll [ngClass]="isMobile ? '' : 'web-drag-scroll'" (reachesLeftBound)="leftBoundStat($event)"
				(reachesRightBound)="rightBoundStat($event)">
				<div drag-scroll-item *ngFor="let option of options">
					<mat-chip #chip="matChip" class="scroll-item" [ngClass]="!canUnselect ? 'full-opacity' : ''"
						(selectionChange)="onTagValueSelected(option.value, $event.isUserInput, $event.selected)"
						(click)="toggleChip(chip)" color="primary" [value]="option.value"
						[selected]="(selectedValues.changes$ | async)?.includes(option.value)" [disabled]="isDisabled(option)"
						[matTooltip]="option.tooltip">
						<ion-icon *ngIf="option.icon" [name]="option.icon"></ion-icon>
						<ion-label>{{option.label}}</ion-label>
						<ion-icon *ngIf="chip.selected" name="checkmark"></ion-icon>
					</mat-chip>
				</div>

			</drag-scroll>
		</mat-chip-list>
		<ion-button class="right-button" fill="clear" size="big" (click)="moveRight(dragScroll)" [hidden]="hidRightBtn">
			<ion-icon name="chevron-forward-outline"></ion-icon>
		</ion-button>
	</div>
</ng-template>

<ng-template #defaultTemplate>
	<mat-chip-list multiple>
		<mat-chip #chip="matChip" *ngFor="let option of options"
			(selectionChange)="onTagValueSelected(option.value, $event.isUserInput, $event.selected, $event.source)"
			(click)="toggleChip(chip)" color="primary" [value]="option.value" [ngClass]="!canUnselect ? 'full-opacity' : ''"
			[selected]="(selectedValues.changes$ | async)?.includes(option.value)" [disabled]="isDisabled(option)"
			[matTooltip]="option.tooltip">
			<ion-icon *ngIf="option.icon" [name]="option.icon"></ion-icon>
			<ion-label>{{option.label}}</ion-label>
			<ion-icon *ngIf="chip.selected" name="checkmark"></ion-icon>
		</mat-chip>
	</mat-chip-list>
</ng-template>

<ng-template #emptyScreen>
	<!-- Si le composant a terminé de s'initialiser et qu'il y a une barre de recherche et qu'elle n'a retourné aucun résultat. -->
	<div class="empty-screen flex-col">
		<span class="txt-message">
			<ion-icon class="no-result-icon" name="information-circle"></ion-icon>
			Aucun résultat.
		</span>
	</div>
</ng-template>