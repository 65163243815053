import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DateTimePickerComponent } from '@osapp/components/date/dateTimePicker.component';
import { IDynHostComponent } from '@osapp/components/dynHost/IDynHost.component';
import { DynamicPageComponent } from '@osapp/components/dynamicPage/dynamicPage.component';
import { ComponentBase, ObjectHelper } from '@osapp/helpers';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { AvatarHelper } from '@osapp/helpers/avatarHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { StoreHelper } from '@osapp/helpers/storeHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { EPrefix } from '@osapp/model/EPrefix';
import { EBarElementDock } from '@osapp/model/barElement/EBarElementDock';
import { EBarElementPosition } from '@osapp/model/barElement/EBarElementPosition';
import { IBarElement } from '@osapp/model/barElement/IBarElement';
import { IContact } from '@osapp/model/contacts/IContact';
import { IGroup } from '@osapp/model/contacts/IGroup';
import { IGroupMember } from '@osapp/model/contacts/IGroupMember';
import { EDateTimePickerMode } from '@osapp/model/date/EDateTimePickerMode';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { IDateTimePickerParams } from '@osapp/model/date/IDateTimePickerParams';
import { ELifeCycleEvent } from '@osapp/model/lifeCycle/ELifeCycleEvent';
import { ILifeCycleEvent } from '@osapp/model/lifeCycle/ILifeCycleEvent';
import { EAvatarSize } from '@osapp/model/picture/EAvatarSize';
import { IAvatar } from '@osapp/model/picture/IAvatar';
import { IPopoverItemParams } from '@osapp/model/popover/IPopoverItemParams';
import { IUiResponse } from '@osapp/model/uiMessage/IUiResponse';
import { IAvatarsParams } from '@osapp/modules/avatar/models/IAvatarsParams';
import { OsappError } from '@osapp/modules/errors/model/OsappError';
import { ObservableArray } from '@osapp/modules/observable/models/observable-array';
import { C_ADMINISTRATORS_ROLE_ID, Roles } from '@osapp/modules/permissions/services/permissions.service';
import { DateWithLocalePipe } from '@osapp/pipes/dateWithLocale.pipe';
import { ContactsService } from '@osapp/services/contacts.service';
import { GroupsService } from '@osapp/services/groups.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '@osapp/services/interfaces/ShowMessageParamsToast';
import { LoadingService } from '@osapp/services/loading.service';
import { PopoverService } from '@osapp/services/popover.service';
import { SlideboxService } from '@osapp/services/slidebox.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { Observable, defer, of } from 'rxjs';
import { filter, map, mapTo, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Acte } from '../../../../model/Acte';
import { EStatusSeance } from '../../../../model/EStatusSeance';
import { IIdelizyContact } from '../../../../model/IIdelizyContact';
import { Seance } from '../../../../model/Seance';
import { Traitement } from '../../../../model/Traitement';
import { TraitementSlideParentBase } from '../../../../model/TraitementSlideParentBase';
import { IDateWithSeances } from '../../../../model/seances/IDateWithSeances';
import { SeanceService } from '../../../../services/seance.service';
import { TraitementService } from '../../../../services/traitement.service';
import { ActesService } from '../../../actes/actes.service';
import { CancelConstraint } from '../../../actes/model/cancel-constraint';
import { ReactivateConstraint } from '../../../actes/model/reactivate-constraint';
import { PatientsService } from '../../../patients/services/patients.service';
import { EChoiceFilter } from '../../../seances/choose-seances-to-modify-modal/EChoiceFilter';
import { IChoice } from '../../../seances/choose-seances-to-modify-modal/IChoice';
import { EAdminActionOnMultipleSeances } from '../../../seances/model/eadmin-action-on-multiple-seances.enum';
import { IChooseSeancesToModifiyResponse } from '../../../seances/model/ichoose-seances-to-modifiy-response';
import { TraitementDataManager } from '../../traitement-data-manager.service';
import { TraitementSlideComponentBase } from '../traitement-slide-component-base.component';

@Component({
	templateUrl: './seances.component.html',
	styleUrls: ['./seances.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeancesComponent extends TraitementSlideComponentBase implements IDynHostComponent, OnInit {

	//#region FIELDS

	private static readonly C_MOVE_DATE_TIME_SEANCE_TITLE = "Déplacer la/des séance(s) ...";
	private static readonly C_MOVE_TIME_SEANCE_TITLE = "Modifier l'horaire ...";
	private static readonly C_SELECT_INTERVENANT_SEANCE_TITLE = "Modifier l'intervenant ...";
	private static readonly C_NB_MAX_ITEM_DISPLAYED = 7;
	private static readonly C_LOG_ID = "SEANCES.C::";

	/** Map contenant l'avatar de chaque intervenant, indexé par identifiant d'intervenant. */
	private moAvatarByIntervenantId = new Map<string, IAvatar>();

	private moVirtualScrollViewport: CdkVirtualScrollViewport;

	//#endregion

	//#region PROPERTIES

	@Input() public params: any;

	public seanceOrDateList = new ObservableArray<{ date?: Date, seance?: Seance }>();
	public seances: Seance[];
	public dateParams: IDateTimePickerParams;
	public timeParams: IDateTimePickerParams;
	public dateTimeParams: IDateTimePickerParams;
	public expandedSeanceInfo: { seance?: Seance; index?: number } = {};
	public statusSeanceEnum = EStatusSeance;
	public currentScrollIndex: number;

	@ViewChild("virtualScroll") public set virtualScroll(poVirtualScroll: { viewport: CdkVirtualScrollViewport }) {
		if (poVirtualScroll?.viewport) {
			this.moVirtualScrollViewport = poVirtualScroll.viewport;

			this.moVirtualScrollViewport.scrolledIndexChange.pipe(
				tap((pnIndex: number) => {
					this.currentScrollIndex = pnIndex;
					this.refreshExpandedSeances();
				}),
				takeUntil(this.destroyed$)
			)
				.subscribe();
		}
	};

	@ViewChild("datePicker") public readonly datePickerComponent: DateTimePickerComponent;
	/** Paramètres pour les filtres de date. */
	public datepickerParams: IDateTimePickerParams = {
		pickerMode: EDateTimePickerMode.date,
		displayFormat: ETimetablePattern.dd_MM_yyyy_slash,
		hideIcon: true
	};

	// @Roles(C_ADMINISTRATORS_ROLE_ID)
	public get canUpdateActesCotations(): boolean {
		return false; // Masquage de l'option de recotations des séances le temps que la cotation soit revue.
	}

	@Roles(C_ADMINISTRATORS_ROLE_ID)
	public get isAdmin(): boolean {
		return true;
	}

	public get isCoordinator(): boolean {
		return true;
	}

	//#endregion

	//#region METHODS

	constructor(
		private ioRouter: Router,
		private ioDatePipe: DateWithLocalePipe,
		private isvcContacts: ContactsService,
		private isvcPopover: PopoverService,
		private isvcActes: ActesService,
		private isvcUiMessage: UiMessageService,
		psvcPatients: PatientsService,
		psvcTraitementDataManager: TraitementDataManager,
		psvcSeance: SeanceService,
		poParentComponent: TraitementSlideParentBase,
		psvcTraitement: TraitementService,
		psvcLoading: LoadingService,
		poParentPage: DynamicPageComponent<ComponentBase>,
		poChangeDetectorRef: ChangeDetectorRef,
		psvcSlidebox: SlideboxService) {

		super(
			psvcTraitementDataManager,
			psvcSeance,
			poParentComponent,
			psvcTraitement,
			psvcLoading,
			psvcPatients,
			poParentPage,
			poChangeDetectorRef,
			psvcSlidebox
		);

		this.initDateTimePickerParams();
	}

	public ngOnInit(): void {
		super.ngOnInit();

		this.initObservables();
	}

	protected onLifeCycleEvent(poEvent: ILifeCycleEvent): void {
		super.onLifeCycleEvent(poEvent);

		// Si on a l'action de numérisation d'ordonnance en paramètre de requêtes, on slide vers la slide des prescriptions.
		if (poEvent.data.value === ELifeCycleEvent.viewDidEnter &&
			this.moParentComponent.getInitializingSlideActions().some((psAction: string) => psAction === TraitementSlideParentBase.C_ADD_ORDONNANCE_ACTION_ID)) {
			this.mfSlideTo(0); // slide 0 est la slide "Prescriptions".
		}
	}

	/** @override */
	protected createToolbarData(): Array<IBarElement> {
		const lsCircle = "circle";
		const lsFabButton = "fabButton";

		return [
			{
				id: lsCircle,
				component: lsFabButton,
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.left,
				icon: "arrow-back",
				onTap: this.mfPreviousSlide,
				name:"Précédent"
			},
			{
				id: lsCircle,
				component: lsFabButton,
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.middle,
				icon: "timetable",
				onTap: () => this.ioRouter.navigate([
					"tournees",
					DateHelper.toDateUrl(this.traitement.beginDate)
				], { replaceUrl: this.ioRouter.url.includes("new") }),
				name:"Tournées"
			},
			{
				id: lsCircle,
				component: lsFabButton,
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.right,
				icon: "number",
				onTap: this.mfNextSlide,
				name:"Majoration"
			}
		];
	}

	private initDateTimePickerParams(): void {
		this.dateParams = {
			label: "Début du traitement :",
			displayFormat: ETimetablePattern.dd_MMM_yyyy,
			max: DateHelper.addYears(new Date(), 1).toISOString(),
			min: DateHelper.addYears(new Date(), -1).toISOString(),
			pickerMode: EDateTimePickerMode.date
		};

		this.dateTimeParams = {
			max: DateHelper.addYears(new Date(), 1).toISOString(),
			min: DateHelper.addYears(new Date(), -1).toISOString()
		};

		this.timeParams = {
			placeholder: "Planifier",
			displayFormat: ETimetablePattern.HH_mm,
			pickerMode: EDateTimePickerMode.time,
		};
	}

	private initObservables(): void {
		// Raffraichissement de la vue lorsque le traitement a été modifiée.
		this.moParentComponent.getTraitementAsObservable()
			.pipe(
				tap((poTraitement: Traitement) => this.traitement = poTraitement),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		let lbIsFocusedToCurrentDate = false;
		this.traitement.seances$
			.pipe(
				tap((paSeances: Seance[]) => this.refreshSeances(paSeances)),
				switchMap((paSeances: Seance[]) => {
					return this.isvcContacts.getContactsByIds(
						ArrayHelper.unique(ArrayHelper.flat(paSeances.map((poSeance: Seance) => poSeance.intervenantIds ? poSeance.intervenantIds : [])))
					);
				}),
				tap((paIntervenants: IIdelizyContact[]) => {
					this.updateIntervenantsAvatar(paIntervenants);
					if (!lbIsFocusedToCurrentDate) {
						this.focusToCurrentDate(this.seanceOrDateList);
						lbIsFocusedToCurrentDate = true;
					};
					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Met à jour les avatars des intervenants.
	 * @param paIntervenants Tableau des intervenants dont il faut récupérer les avatars.
	 */
	private updateIntervenantsAvatar(paIntervenants: IGroupMember[]): void {
		paIntervenants.forEach((poIntervenant: IGroupMember) => {
			if (!this.moAvatarByIntervenantId.has(poIntervenant._id))
				this.moAvatarByIntervenantId.set(poIntervenant._id, IdHelper.hasPrefixId(poIntervenant._id, EPrefix.contact) ?
					AvatarHelper.createAvatarFromContact(poIntervenant as IContact, EAvatarSize.big) :
					GroupsService.createGroupAvatar(poIntervenant as IGroup, EAvatarSize.big)
				);
		});
	}

	/**
	 * @param pdBeginDateEvent Nouvelle date de début du traitement, optionnelle (événement du dateTimeSpinner).
	 */
	public onStartDateChanged(pdBeginDateEvent: Date): void {
		this.traitement.beginDate = pdBeginDateEvent;
		this.onTraitementChanged();
	}

	private refreshSeances(paSeances: Seance[]): void {
		if (!ArrayHelper.hasElements(paSeances)) // S'il n'y pas eu de génération car aucun acte présent, il faut mettre à jour la date de fin manuellement.
			this.traitement.endDate = this.traitement.beginDate;
		else {
			this.traitement.endDate = this.isvcSeance.getSeancesMaxDate(paSeances);
			this.traitement.beginDate = this.isvcSeance.getSeancesMinDate(paSeances);
		}

		this.seanceOrDateList.resetArray();
		this.isvcSeance.groupSeancesByDays(paSeances).forEach((paDateWithSeances: IDateWithSeances) => {
			this.seanceOrDateList.push({ date: paDateWithSeances.date });
			this.seanceOrDateList.push(...paDateWithSeances.seances.map((poSeance: Seance) => {
				return { seance: poSeance };
			}));
		});

		this.seances = paSeances;
		this.detectChanges();
	}

	/** Permet de scroller au niveau des séances du jour.
	 * @param paItems
	 */
	private focusToCurrentDate(paItems: { date?: Date; seance?: Seance; }[]) {
		const ldCurrentDate: Date = new Date();

		for (let lnIndex = 0; lnIndex < paItems.length; ++lnIndex) {
			const loItem: { date?: Date; seance?: Seance; } = paItems[lnIndex];

			if (loItem.date && DateHelper.diffDays(ldCurrentDate, loItem.date) <= 0) {
				this.moVirtualScrollViewport?.scrollToIndex(lnIndex);
				this.currentScrollIndex = lnIndex;
				break;
			}
		}
	}

	/** Permet de scroller en haut de la liste. */
	public scrollToTop() {
		if (this.moVirtualScrollViewport) {
			this.moVirtualScrollViewport.scrollToIndex(0);
			this.currentScrollIndex = 0;
		}
	}

	/** Permet de fermer la tuile ouvert si elle n'est plus visible. */
	public refreshExpandedSeances(): void {
		if (this.expandedSeanceInfo?.index >= 0) {
			const lnEndIndex = this.currentScrollIndex + SeancesComponent.C_NB_MAX_ITEM_DISPLAYED;
			if (this.expandedSeanceInfo.index < this.currentScrollIndex - 1 || lnEndIndex < this.expandedSeanceInfo.index) {
				this.expandedSeanceInfo = {};
				this.detectChanges();
			}
		}
	}

	/** Permet d'ouvrir ou fermer le menu déroulant d'une séance.
	 * @param poSeance Séance à plier ou déplier.
	 * @param pnIndex Index de la séance à plier ou déplier.
	 */
	public togglePanel(poSeance: Seance, pnIndex: number): void {
		if (ObjectHelper.isNullOrEmpty(this.expandedSeanceInfo) || !this.expandedSeanceInfo.seance?.equals(poSeance)) {
			this.expandedSeanceInfo.seance = poSeance;
			this.expandedSeanceInfo.index = pnIndex;
		}
		else
			this.expandedSeanceInfo = {};
	}

	public onDateModified(ldDate: Date, poSeance: Seance): void {
		if (DateHelper.compareTwoDates(ldDate, poSeance.startDate) === 0)
			return;

		const laChoices: IChoice[] = [];
		if (DateHelper.diffDays(ldDate, poSeance.startDate) !== 0) {
			if (poSeance.scheduled) {
				laChoices.push({
					label: `Toutes les séances de ${this.getFormattedStartDate(ldDate, ETimetablePattern.HH_mm)} et les suivantes à cet horaire`,
					filters: [EChoiceFilter.sameTime, EChoiceFilter.future] as EChoiceFilter[]
				});
			}
			laChoices.push(
				{
					label: "Cette séance et les suivantes",
					filters: [EChoiceFilter.future] as EChoiceFilter[]
				},
				{
					label: `Toutes les séances du ${this.getFormattedStartDate(poSeance.startDate, ETimetablePattern.dd_MM_yyyy_slash)} uniquement`,
					filters: [EChoiceFilter.sameDay] as EChoiceFilter[]
				}
			);
		}
		else {
			laChoices.push({
				label: `Décaler toutes les séances à venir de ${this.getFormattedStartDate(poSeance, ETimetablePattern.HH_mm)} à ${this.getFormattedStartDate(ldDate, ETimetablePattern.HH_mm)}`,
				filters: [EChoiceFilter.sameTime, EChoiceFilter.future] as EChoiceFilter[]
			});
		}

		this.isvcSeance.selectSeancesForUpdateWithModal(
			poSeance,
			this.seances,
			laChoices,
			SeancesComponent.C_MOVE_DATE_TIME_SEANCE_TITLE
		)
			.pipe(
				mergeMap((poResponse: IChooseSeancesToModifiyResponse) => this.isvcTraitement.applyNewDateToSeances(
					ldDate,
					poSeance,
					poResponse,
					this.traitement
				)),
				tap(_ => {
					let lsMessage = "Séance planifiée ";

					if (DateHelper.diffDays(ldDate, poSeance.startDate) !== 0)
						lsMessage += `le ${this.ioDatePipe.transform(ldDate, DateHelper.C_LOCAL_FR, `EEE dd MMM yyyy${poSeance.scheduled ? " HH:mm" : ""}`)}`;
					else
						lsMessage += `à ${this.ioDatePipe.transform(ldDate, DateHelper.C_LOCAL_FR, "HH:mm")}`;

					this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: lsMessage }));
				})
			)
			.subscribe();
	}

	/** Retourne un objet contenant les paramètres du composant `calao-avatars`.
	 * @param poSeanceOrSeances Tableau des séances à partir duquel construire et retourner les paramètres.
	 */
	public getAvatarsParams(poSeanceOrSeances: Seance | Seance[]): IAvatarsParams {
		const laSeances: Seance[] = poSeanceOrSeances instanceof Array ? poSeanceOrSeances : [poSeanceOrSeances];
		const loAvatar: IAvatar = this.getFirstIntervenant(laSeances);
		// Récupération du nombre d'intervenants (sans compter les doublons) hormis le premier.
		const lnAvatarsCount: number = ArrayHelper.unique(ArrayHelper.flat(laSeances.map((poSeance: Seance) => poSeance.intervenantIds || []))).length;

		return {
			avatar: loAvatar,
			avatarsCount: loAvatar ? lnAvatarsCount : 0
		} as IAvatarsParams;
	}

	/** Récupère l'avatar du premier intervenant (premier intervenant qui possède un avatar valide).
	 * @param poSeanceOrSeances Séance ou tableau de séances où il faut récupérer l'avatar du premier intervenant.
	 */
	private getFirstIntervenant(poSeanceOrSeances: Seance | Seance[]): IAvatar {
		const laSeances: Seance[] = poSeanceOrSeances instanceof Array ? poSeanceOrSeances : [poSeanceOrSeances];
		let loAvatar: IAvatar;

		// Récupération de l'avatar du premier intervenant.
		for (let lnSeanceIndex = 0; lnSeanceIndex < laSeances.length; ++lnSeanceIndex) {
			const loSeance: Seance = laSeances[lnSeanceIndex];
			const lnIntervenantIndex: number = loSeance.intervenantIds?.findIndex((psId: string) => this.moAvatarByIntervenantId.has(psId));

			if (lnIntervenantIndex > -1) { // Si on a trouvé un intervenant.
				loAvatar = this.moAvatarByIntervenantId.get(loSeance.intervenantIds[lnIntervenantIndex]);
				break;
			}
		}

		return loAvatar ?? SeanceService.emptyAvatar;
	}

	/** Modifie l'intervenant d'une séance.
	 * @param poSeance Séance dont il faut modifier l'intervenant.
	 */
	public setSeanceIntervenant(poSeance: Seance): void {
		this.isvcSeance.selectIntervenants(poSeance)
			.pipe(
				mergeMap((paIntervenants: IGroupMember[]) => this.innerSetSeanceIntervenant_selectSeances(poSeance, paIntervenants, SeancesComponent.C_SELECT_INTERVENANT_SEANCE_TITLE)),
				tap(
					() => { },
					(poError: any) => {
						if (poError instanceof OsappError) {
							this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: poError.message }));
							console.warn("SEANCES.C::Problème lors de la sélection des intervenants: ", poError);
						}
						else
							console.error("SEANCES.C::Erreur lors de la sélection des intervenants: ", poError);
					}
				),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private innerSetSeanceIntervenant_selectSeances(poSelectedSeance: Seance, paIntervenants: IGroupMember[], psTitle: string): Observable<Seance[]> {
		return this.isvcSeance.selectSeancesForUpdateWithModal(
			poSelectedSeance,
			this.seances,
			[
				{
					label: `Toutes les séances de ${this.getFormattedStartDate(poSelectedSeance, ETimetablePattern.HH_mm)}`,
					filters: [EChoiceFilter.sameTime]
				},
				{
					label: "Cette séance et les suivantes",
					filters: [EChoiceFilter.future]
				}
			] as IChoice[],
			psTitle
		)
			.pipe(
				mergeMap((poResponse: IChooseSeancesToModifiyResponse) => {
					return this.isvcTraitement.changeSeancesWithConstraint(
						poResponse.seances,
						(poSeance: Seance) => {
							this.isvcSeance.applyNewIntervenants(poSeance, paIntervenants);
							return of(poSeance);
						},
						this.traitement,
						() => this.isvcSeance.getIntervenantConstraint(
							poResponse,
							poSelectedSeance,
							paIntervenants
						)
					)
						.pipe(
							tap(_ => {
								this.updateIntervenantsAvatar(paIntervenants);
								this.detectChanges();
							})
						);
				})
			) as Observable<Seance[]>;
	}

	/** Retourne la date de début d'une séance formattée.
	 * @param poSelectedSeanceOrDate Séance sélectionnée dont on veut formatté la date de début ou date que l'on veut formater.
	 * @param pePattern Pattern à appliquer sur la date de début d'une séance.
	 */
	private getFormattedStartDate(poSelectedSeanceOrDate: Seance | Date, pePattern: ETimetablePattern): string {
		const loStartDate: Date = poSelectedSeanceOrDate instanceof Seance ? poSelectedSeanceOrDate.startDate : poSelectedSeanceOrDate;
		return DateHelper.transform(loStartDate, pePattern);
	}

	/** Retourne `true` si le bouton 'plus d'options' peut apparaître, `false` sinon.
	 * @param poSelectedSeance Séance dont on veut vérifier si le bouton 'plus d'options' peut apparaître ou non.
	 */
	public hasPopoverButton(poSelectedSeance: Seance): boolean {
		return !poSelectedSeance.status || poSelectedSeance.status === EStatusSeance.pending;
	}

	/** Modifie l'horaire d'une séance (si elle est en cours, sinon ne fait rien).
	 * @param poSeance Séance dont il faut modifier l'horaire.
	 * @param poTimePickerComponent Composant pour modifier l'horaire de la séance.
	 */
	public editSeanceTime(poSeance: Seance, poTimePickerComponent: DateTimePickerComponent): void {
		if (SeanceService.isOngoingSeance(poSeance))
			poTimePickerComponent.pickDate();
	}

	//#region Action sur un lot de séances (séances d'une journée complète)

	/** Ouvre un menu contextuel d'une séance.
	 * @param poEvent Événement du clic.
	 * @param poSelectedSeance Séance sélectionnée sur laquelle effectuer une action.
	 * @param poMoveDateTimePicker Composant `DateTimePicker` pour choisir une nouvelle date et nouvel horaire.
	 * @param poMoveTimePicker Composant `DateTimePicker` pour choisir un nouvel horaire.
	 */
	public openSeancePopover(poEvent: MouseEvent, poSelectedSeance: Seance, poMoveDateTimePicker: DateTimePickerComponent, poMoveTimePicker: DateTimePickerComponent)
		: void {
		const laItems: IPopoverItemParams[] = [
			this.createSetSeancesHoursPopover(poMoveTimePicker),
			this.createSelectIntervenantPopover(poSelectedSeance),
			this.createMoveSeancesPopover(poMoveDateTimePicker),
			this.createCancelSeancesPopover(poSelectedSeance),
			this.canUpdateActesCotations ? this.createUpdateSeancesCotationsPopover(poSelectedSeance) : null
		];

		this.isvcPopover.showPopover(laItems, poEvent)
			.pipe(takeUntil(this.destroyed$))
			.subscribe();
	}

	/** Crée et retourne un élément pour le menu contextuel permettant de modifier l'horaire d'une/des séance(s).
	 * @param poMoveTimePicker Composant `DateTimePicker` pour choisir un nouvel horaire.
	 */
	private createSetSeancesHoursPopover(poMoveTimePicker: DateTimePickerComponent): IPopoverItemParams {
		return {
			action: () => of(poMoveTimePicker.pickDate()),
			icon: "time-outline",
			title: SeancesComponent.C_MOVE_TIME_SEANCE_TITLE
		} as IPopoverItemParams;
	}

	/** Crée et retourne un élément pour le menu contextuel permettant de sélectionner un intervenant.
	 * @param poSelectedSeance Séance à modifier.
	 */
	private createSelectIntervenantPopover(poSelectedSeance: Seance): IPopoverItemParams {
		const loAvatar: IAvatar = this.getFirstIntervenant(poSelectedSeance);

		return {
			action: () => of(this.setSeanceIntervenant(poSelectedSeance)),
			avatar: loAvatar,
			icon: loAvatar ? undefined : "person",
			title: SeancesComponent.C_SELECT_INTERVENANT_SEANCE_TITLE
		} as IPopoverItemParams;
	}

	/** Crée et retourne un élément pour le menu contextuel permettant de déplacer une/des séance(s).
	 * @param poMoveDateTimePicker Composant `DateTimePicker` pour choisir une nouvelle date et nouvel horaire.
	 */
	private createMoveSeancesPopover(poMoveDateTimePicker: DateTimePickerComponent): IPopoverItemParams {
		return {
			action: () => of(poMoveDateTimePicker.pickDate()),
			icon: "calendar",
			title: SeancesComponent.C_MOVE_DATE_TIME_SEANCE_TITLE
		} as IPopoverItemParams;
	}

	/** Crée et retourne un élément pour le menu contextuel permettant d'annuler une/des séance(s).
	 * @param poSelectedSeance Séance à modifier.
	 */
	private createCancelSeancesPopover(poSelectedSeance: Seance): IPopoverItemParams {
		const lsTitle = "Annuler la séance ...";

		return {
			action: () => this.innerCreateCancelSeancesPopover(poSelectedSeance, lsTitle),
			icon: "date-supp",
			title: lsTitle
		} as IPopoverItemParams;
	}

	/** Crée et retourne un élément pour le menu contextuel permettant d'actualiser la cotation d'une/des séance(s).
	 * @param poSelectedSeance Séance à modifier.
	 */
	private createUpdateSeancesCotationsPopover(poSelectedSeance: Seance): IPopoverItemParams {
		const lsTitle = "Actualiser la cotation de la séance ...";

		return {
			action: () => this.innerCreateUpdateSeancesCotationsPopover(poSelectedSeance, lsTitle),
			icon: "invoice",
			title: lsTitle
		} as IPopoverItemParams;
	}

	private innerChangeSeanceStatus(poSelectedSeance: Seance, peNewStatus: EAdminActionOnMultipleSeances): Observable<boolean> {
		return defer(() => peNewStatus === EAdminActionOnMultipleSeances.validate ?
			this.isvcSeance.validateSeance(poSelectedSeance, this.traitement, true) :
			of(this.isvcSeance.unvalidateSeance(poSelectedSeance)))
			.pipe(
				map((poSeance: Seance) => this.isvcSeance.hasSeanceMultipleIntervenant(poSeance)),
				tap((pbShowWarning: boolean) => {
					// TODO TB Relevé inter
					this.detectChanges();

					if (pbShowWarning)
						this.showMultipleIntervenantWarning("Une séance modifiée comporte plusieurs intervenants.");
				})
			);
	}

	/** Affiche un message si la séance modifié comporte plusieurs intervenants. */
	private showMultipleIntervenantWarning(psMessage?: string): void {
		const psDefaultMessage = "Une/des séance(s) modifiée(s) comporte(nt) plusieurs intervenants.";
		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({
				header: "Attention",
				message: StringHelper.isBlank(psMessage) ? psDefaultMessage : psMessage,
				buttons: [
					{ text: "Ok", role: "cancel" },
				]
			})
		);
	}

	private innerCreateCancelSeancesPopover(poSelectedSeance: Seance, psTitle: string): Observable<Seance[]> {
		return this.isvcSeance.selectSeancesForUpdateWithModal(
			poSelectedSeance,
			this.seances,
			[
				{
					label: "Cette séance et les suivantes",
					filters: [EChoiceFilter.future]
				},
				{
					label: `Toutes les séances du ${this.getFormattedStartDate(poSelectedSeance, ETimetablePattern.dd_MM_yyyy_slash)} uniquement`,
					filters: [EChoiceFilter.sameDay]
				},
				{
					label: `Toutes les séances de ${this.getFormattedStartDate(poSelectedSeance, ETimetablePattern.HH_mm)} et les suivantes à cet horaire`,
					filters: [EChoiceFilter.sameTime]
				}
			] as IChoice[],
			psTitle
		)
			.pipe(
				mergeMap((poResponse: IChooseSeancesToModifiyResponse) =>
					this.isvcTraitement.changeSeancesWithConstraint(
						poResponse.seances,
						(poSeance: Seance) => this.isvcSeance.cancelSeance(poSeance, this.traitement),
						this.traitement,
						() => this.isvcSeance.getConstraint(
							CancelConstraint,
							poResponse,
							poSelectedSeance
						)
					)),
				tap(() => this.detectChanges())
			) as Observable<Seance[]>;
	}

	private innerCreateUpdateSeancesCotationsPopover(poSelectedSeance: Seance, psTitle: string): Observable<Seance[]> {
		return this.isvcSeance.selectSeancesForUpdateWithModal(
			poSelectedSeance,
			this.seances,
			[
				{
					label: "Cette séance et les suivantes jusqu'à la fin du traitement",
					filters: [EChoiceFilter.future]
				}
			] as IChoice[],
			psTitle
		).pipe(
			mergeMap((poResponse: IChooseSeancesToModifiyResponse) => {
				const laActes: Acte[] = ArrayHelper.unique(
					ArrayHelper.flat(poResponse.seances.map((poSeance: Seance) => poSeance.actes)), (poActe: Acte) => poActe.guid
				);

				return this.isvcActes.getActes(ArrayHelper.unique(laActes.map((poActe: Acte) => poActe._id)))
					.pipe(
						tap((paActes: Acte[]) => {
							const lbHasToReplaceForMultipleSeances: boolean = poResponse.seances.length > 1;
							paActes.forEach((poActe: Acte) => {
								const laOldActes: Acte[] = laActes.filter((poOldActe: Acte) => poOldActe.equals(poActe));
								laOldActes.forEach((poOldActe: Acte) => {
									if (lbHasToReplaceForMultipleSeances)
										this.isvcTraitement.replaceActeFrom(this.traitement, poSelectedSeance, this.seances, poOldActe, poActe);
									else
										this.isvcTraitement.replaceActeForSeance(this.traitement, poSelectedSeance, this.seances, poOldActe, poActe);
								});
							});

							if (StoreHelper.isDocumentDirty(this.traitement))
								this.onTraitementChanged();
						}),
						mapTo(poResponse.seances)
					);
			})
		);
	}

	private innerBillSeance(poSelectedSeance: Seance): void {
		this.isvcSeance.adminBillSeance(poSelectedSeance);
		// TODO TB Création facture
		this.detectChanges();
	}

	public getSeanceDescription(poSeance: Seance): string {
		return poSeance.actes.map((poActe: Acte) => this.isvcActes.getActeDescription(poActe, 3)).join(", ");
	}

	public reactivateSeance(poCanceledSeance: Seance): void {
		this.isvcUiMessage.showAsyncMessage(
			new ShowMessageParamsPopup({
				message: "Voulez-vous réactiver la séance ?",
				buttons: [{ text: "Non", handler: () => UiMessageService.getFalsyResponse() },
				{ text: "Oui", handler: () => UiMessageService.getTruthyResponse() }]
			})
		).pipe(
			filter((poUiResponse: IUiResponse<any, any>) => poUiResponse.response),
			tap(() => {
				this.isvcSeance.reactivate(poCanceledSeance);
				poCanceledSeance.actes.forEach((poActe: Acte) => {
					const loTraitementActe: Acte = this.traitement.actes.find((poTraitementActe: Acte) => poActe.guid === poTraitementActe.guid);
					if (loTraitementActe) {
						loTraitementActe.constraints.push(
							this.isvcSeance.getConstraint(ReactivateConstraint, { seances: [poCanceledSeance], filters: [] }, poCanceledSeance)
						);
					}
				});
				StoreHelper.makeDocumentDirty(this.traitement);
				this.onTraitementChanged();
			}),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	//#endregion

	//#endregion
}
