import { IEntityGuard, IGuardResult } from '@osapp/model/entities/IEntityGuard';
import { IEntityLink } from '@osapp/model/entities/IEntityLink';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ITraitement } from '../../../model/ITraitement';
import { Seance } from '../../../model/Seance';

/** Assure les contrôles par défaut avant l'altération d'une entité. */
export class TraitementEntityGuard implements IEntityGuard {

	constructor(protected msLinkedElementMessage: string = "Ce traitement est lié") { }

	/** Retourne un résultat asynchrone : `true` si le traitement peut être supprimé, `false` avec un message d'erreur sinon.
	* @param poTraitement Document dont on veut vérifier si on peut le supprimer ou non.
	* @param paLinks Tableau des entités liées au document.
	*/
	public isDeletable(poTraitement: ITraitement, paLinks: IEntityLink[]): Observable<IGuardResult> {
		let loSeances$: Observable<Seance[]>;

		loSeances$ = of([]);

		return loSeances$
			.pipe(
				mergeMap((paSeances: Seance[]) => {
					if (this.canDelete(paLinks, paSeances))
						return of({ result: true, message: undefined }) as Observable<IGuardResult>;
					else // Sinon, non supprimable.
						return of({ result: false, message: `${this.msLinkedElementMessage} à ${paLinks.length} élément${paLinks.length > 1 ? "s" : ""}.` }) as Observable<IGuardResult>;
				}),
			);
	}

	/** Retourne `true` si on peut supprimer le traitement, `false` sinon.\
	 * Pour supprimer, il faut que le traitement soit lié au plus à un unique patient et que toutes les séances associés ne soient pas planifiées.
	 * @param paLinks Tableau des liens pour vérifier la possibilité de suppression du traitement.
	 */
	private canDelete(paLinks: IEntityLink[], paSeances: Seance[]): boolean {
		// Toutes les séances sont non planifiées ET pas d'autre lien ou un seul lien en plus qui est celui du patient.
		return true; // TODO TB Revoir gestion suppression traitement
	}
}