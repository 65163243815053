import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { IdHelper } from '@osapp/helpers/idHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { ObservableArray } from '@osapp/modules/observable/models/observable-array';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { Queuer } from '@osapp/modules/utils/queue/models/queuer';
import { Observable } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { C_PREFIX_TERMINAL } from '../../../../app/app.constants';
import { ITerminal } from '../../models/iterminal';
import { OlaqinService } from '../../services/olaqin.service';

@Component({
	selector: 'idl-terminals-selector',
	templateUrl: './terminals-selector.component.html',
	styleUrls: ['./terminals-selector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TerminalsSelectorComponent extends ComponentBase {

	//#region FIELDS

	private readonly moSetLastUsedTerminalQueuer = new Queuer<boolean, string>({
		thingToQueue: (psTerminalId: string) => this.isvcOlaqin.setLastUsedTerminalId(psTerminalId)
	})

	//#endregion

	//#region PROPERTIES

	public readonly terminalOptions: ObservableArray<ISelectOption<string>>;

	public readonly preselectedTerminalId$: Observable<string>;

	public hasError = false;

	//#endregion

	//#region METHODS

	constructor(private readonly isvcOlaqin: OlaqinService, poChangeDetector: ChangeDetectorRef) {
		super(poChangeDetector);
		this.preselectedTerminalId$ = isvcOlaqin.getLastUsedTerminalId(true).pipe(
			map((psTerminalId: string) => {
				if (!StringHelper.isBlank(psTerminalId))
					return IdHelper.getGuidFromId(psTerminalId, C_PREFIX_TERMINAL);
				return "";
			}),
			tap(() => { }, () => this.onError())
		);
		this.terminalOptions = new ObservableArray(this.isvcOlaqin.listTerminals().pipe(
			tap(() => { }, () => this.onError()),
			map((paTerminals: ITerminal[]) => paTerminals.map((poTerminal: ITerminal) => ({ label: poTerminal.name, value: poTerminal.terminalId }))),
			takeUntil(this.destroyed$)
		));

		this.setQueuers([this.moSetLastUsedTerminalQueuer]);
	}

	public setLastUsedTerminal(paSelectedTerminals: string[]): void {
		this.moSetLastUsedTerminalQueuer.exec(ArrayHelper.getFirstElement(paSelectedTerminals));
	}

	public onError(): void {
		this.hasError = true;
		this.detectChanges();
	}

	//#endregion

}
