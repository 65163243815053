import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonItemSliding } from '@ionic/angular';
import { DynamicPageComponent } from '@osapp/components/dynamicPage';
import { SearchComponent } from '@osapp/components/search/search.component';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { MapHelper } from '@osapp/helpers/mapHelper';
import { ESortOrder } from '@osapp/model/ESortOrder';
import { ListComponentBase } from '@osapp/model/ListComponentBase';
import { UserData } from '@osapp/model/application/UserData';
import { EBarElementDock } from '@osapp/model/barElement/EBarElementDock';
import { EBarElementPosition } from '@osapp/model/barElement/EBarElementPosition';
import { IBarElement } from '@osapp/model/barElement/IBarElement';
import { IContact } from '@osapp/model/contacts/IContact';
import { IGroup } from '@osapp/model/contacts/IGroup';
import { ISearchOptions } from '@osapp/model/search/ISearchOptions';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { IUiResponse } from '@osapp/model/uiMessage/IUiResponse';
import { EFilterActionReturnType } from '@osapp/modules/filter/model/EFilterActionReturnType';
import { EFilterType } from '@osapp/modules/filter/model/EFilterType';
import { IFilterValuesChangedEvent } from '@osapp/modules/filter/model/IFilterValuesChangedEvent';
import { IFilterbarOptions } from '@osapp/modules/filter/model/IFilterbarOptions';
import { IFilterbarParams } from '@osapp/modules/filter/model/IFilterbarParams';
import { Loader } from '@osapp/modules/loading/Loader';
import { C_ADMINISTRATORS_ROLE_ID, C_SECTORS_ROLE_ID, Roles } from '@osapp/modules/permissions/services/permissions.service';
import { IFavorites } from '@osapp/modules/preferences/favorites/model/IFavorites';
import { FavoritesService } from '@osapp/modules/preferences/favorites/services/favorites.service';
import { ISector } from '@osapp/modules/sectors/models/isector';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { ModelResolver } from '@osapp/modules/utils/models/model-resolver';
import { ApplicationService } from '@osapp/services/application.service';
import { ContactsService } from '@osapp/services/contacts.service';
import { GroupsService } from '@osapp/services/groups.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '@osapp/services/loading.service';
import { PatternResolverService } from '@osapp/services/pattern-resolver.service';
import { Store } from '@osapp/services/store.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { EMPTY, Observable, Subject, defer, of } from 'rxjs';
import { catchError, debounceTime, finalize, map, mapTo, mergeMap, mergeMapTo, switchMap, takeUntil, tap } from 'rxjs/operators';
import { C_PREFIX_ACT, C_PREFIX_PATIENT, C_PREFIX_TRAITEMENT } from '../../app/app.constants';
import { IIdelizyContact } from '../../model/IIdelizyContact';
import { IPatientTraitement } from '../../model/IPatientTraitement';
import { ITraitement } from '../../model/ITraitement';
import { Seance } from '../../model/Seance';
import { Traitement } from '../../model/Traitement';
import { IdlApplicationService } from '../../services/idlApplicationService.service';
import { SeanceService } from '../../services/seance.service';
import { TraitementService } from '../../services/traitement.service';
import { EResumeActeMode } from '../actes/model/EResumeActeMode';
import { CANNOT_DELETE_TRAITMENT_ERROR_MESSAGE } from '../constantes';
import { Ordonnance } from '../ordonnances/models/ordonnance';
import { OrdonnancesService } from '../ordonnances/services/ordonnances.service';
import { ActionButtonFieldTraitementComponent } from '../patients/components/idlActionButtons/idl-action-button-field-traitement/idl-action-button-field-traitement.component';
import { IPatient } from '../patients/model/IPatient';
import { PatientsService } from '../patients/services/patients.service';


@Component({
	templateUrl: './traitements-page.component.html',
	styleUrls: ['./traitements-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TraitementsPageComponent extends ListComponentBase<IPatientTraitement> implements OnInit, OnDestroy {

	//#region FIELDS

	private static readonly C_REMOVE_ACTION = "remove";
	private static readonly C_DUPLICATE_ACTION = "duplicate";
	private static readonly C_FILTER_DATE_ID = "dateFilterId";

	/** Service partagé de l'application. */
	private isvcApplication: IdlApplicationService;
	/** Tableau des recherchers dans les slides. */
	private maSearchValues: string[] = [];
	/** Tableau de tous les patients qui ont un traitement. */
	private maAllPatients: IPatient[] = [];
	/** Tableau des patients sélectionnés pour le filtrage. */
	private maFilterSelectedPatients: IPatient[] = [];
	/** Date sélectionnée pour le filtrage. */
	private mdFilterSelectedDate?: Date;
	/** Tableau des traitements filtrés par la recherche. */
	private maSearchedTraitements: IPatientTraitement[] = [];
	private moGroupIdsByPatientIds = new Map<string, string[]>();
	private maSelectedSectors: IGroup[];
	private moUpdateActeDisplayModeSubject = new Subject<string>();

	//#endregion

	//#region PROPERTIES

	@ViewChild("search") public searchComponent: SearchComponent<IPatientTraitement>;

	/** Tableau des traitements pour la slide 'actifs' : date de fin aujourd'hui minimum, date d'interruption aujourd'hui minimum (s'il y en a une). */
	public actifsTraitements: IPatientTraitement[];
	/** Tableau des traitements pour la slide 'tous'. */
	public allTraitements: IPatientTraitement[];
	/** Nombre de filtres en cours. */
	public filtersCount = 0;
	/** Tableau des paramètres pour la barre de filtrage. */
	public filterbarParams: IFilterbarParams[] = [];
	/** Index de la slide courante. */
	public currentIndex = 0;
	/** Map des Ordonnances par traitement id. */
	public ordonnancesByTraitementId: Map<string, Ordonnance[]>;
	ListSeances: any[];
	traitement: Traitement;

	/** Tableau des traitements en fonction de la slide courante. */
	private get currentTraitements(): IPatientTraitement[] {
		return this.currentIndex === 0 ? this.actifsTraitements : this.allTraitements;
	}
	private set currentTraitements(paNewValues: IPatientTraitement[]) {
		if (this.currentIndex === 0) { // Slide 'actifs'.
			this.actifsTraitements = paNewValues.filter((poDocument: IPatientTraitement) => poDocument.isActive || poDocument.actes?.length === 0);
			this.hasSearchResult = this.actifsTraitements.length > 0;
		}
		else { // Slide 'tous'.
			this.allTraitements = paNewValues;
			this.hasSearchResult = this.allTraitements.length > 0;
		}

		this.detectChanges();
	}

	public groupFilterOptions: ISelectOption<IGroup>[] = [];
	public isToggleChecked: boolean;
	public acteDisplayMode: EResumeActeMode;

	@Roles(C_ADMINISTRATORS_ROLE_ID)
	public get isAdmin(): boolean {
		return true;
	}

	//#endregion

	//#region METHODS

	constructor(
		public isvcTraitement: TraitementService,
		private isvcContacts: ContactsService,
		private isvcLoading: LoadingService,
		private isvcStore: Store,
		private isvcUiMessage: UiMessageService,
		private isvcPatternResolver: PatternResolverService,
		private isvcPatients: PatientsService,
		private isvcGroups: GroupsService,
		private isvcFavorites: FavoritesService,
		private isvcOrdonnances: OrdonnancesService,
		private isvSeances: SeanceService,
		poParentPage: DynamicPageComponent<ComponentBase>,
		poChangeDetectorRef: ChangeDetectorRef,
		psvcApplication: ApplicationService
	) {
		super(poParentPage, poChangeDetectorRef);

		this.isvcApplication = psvcApplication as IdlApplicationService;
	}

	public ngOnInit(): void {
		// Initialise le mode d'affichage du résumé des actes.
		this.isvcFavorites.get(C_PREFIX_ACT, true)
			.pipe(
				tap((poPreferences: IFavorites) => {
					if (poPreferences?.display) {
						this.acteDisplayMode = EResumeActeMode[poPreferences.display];
						this.isToggleChecked = this.acteDisplayMode === EResumeActeMode.lettreCle;
						this.filteredDocuments = Array.from(this.filteredDocuments);
						this.detectChanges();
					}
				}),
				takeUntil(this.destroyed$)
			).subscribe();

		// On initialise la sauvegarde du mode d'affichage du résumé des actes.
		this.moUpdateActeDisplayModeSubject.asObservable()
			.pipe(
				debounceTime(1000),
				switchMap((psActeDisplayMode: string) => this.isvcFavorites.setDisplay(C_PREFIX_ACT, psActeDisplayMode))
			).subscribe();

		this.sortKey = ["patientLastName", "patientFirstName"];
		this.meSortOrder = ESortOrder.ascending;
		this.isvcPatternResolver.setCustomSharedService(this.isvcApplication);
		this.searchOptions = this.createSearchOptions();

		this.init().pipe(takeUntil(this.destroyed$)).subscribe();

		//! Nécessaire pour éviter un bug de liste vide après un retour sur la page.
		this.moParentPage.viewBackTo$
			.pipe(
				tap(_ => this.documents = Array.from(this.documents)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moUpdateActeDisplayModeSubject.complete();
	}

	/** @override */
	protected createBarElements(): IBarElement[] {
		return [
			{
				id: "circle",
				component: "fabButton",
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.right,
				icon: "add",
				onTap: () => this.createNewTraitement(),
				name: "Ajouter"
			}
		];
	}

	/** Crée un nouveau traitement. */
	private createNewTraitement(): void {
		this.isvcPatients.selectPatient("Sélectionnez un patient", true)
			.pipe(
				mergeMap((poSelectedPatient: IPatient) => this.isvcTraitement.checkOngoingTraitements(poSelectedPatient)
					.pipe(
						mergeMap((pbHasOngoingTraitements: boolean) => pbHasOngoingTraitements ? this.displayCreateNewTraitementWarning() : of(true)),
						map((pbHaveToCreate: boolean) => pbHaveToCreate ? this.isvcTraitement.navigateToNewTraitement(poSelectedPatient) : null)
					)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Affiche un avertissement à l'utilisateur indiquant que des traitements sont déjà en cours. */
	private displayCreateNewTraitementWarning(): Observable<boolean> {
		const loAsyncPopupParams = new ShowMessageParamsPopup({ header: "Attention", message: ActionButtonFieldTraitementComponent.C_ONGOING_TRAITEMENTS_SENTENCE });
		//todo ajouter l'option pour aller dans le mode visu du patient sélectionné, slide 'traitements'.
		loAsyncPopupParams.buttons = [
			{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() },
			{ text: "Continuer", handler: () => UiMessageService.getTruthyResponse() }
		];

		return this.isvcUiMessage.showAsyncMessage<boolean>(loAsyncPopupParams)
			.pipe(map((poResult: IUiResponse<boolean>) => poResult.response));
	}

	/** @override */
	protected createSearchOptions(): ISearchOptions<IPatientTraitement> {
		return {
			hasPreFillData: true,
			searchableFields: [{ key: "patientFirstName" }, { key: "patientLastName" }, { key: TraitementService.C_RESUME_ACTES_PROPERTY }],
			searchboxPlaceholder: "Rechercher un traitement"
		};
	}

	/** @override Change de page vers les slides d'un traitement.
	 * @param poTraitementData Données du traitement vers lequel on veut aller, non renseigné si on veut en créer un.
	 */
	public onItemClicked(poTraitementData: IPatientTraitement): void {
		super.onItemClicked(poTraitementData);

		this.isvcTraitement.openTraitement(poTraitementData._id);
	}
	public onRemoveTraitementClicked(poTraitement: IPatientTraitement, poEvent: MouseEvent): void {
		poEvent.stopPropagation();
		this.removeTraitement(poTraitement).subscribe();
	}


	private removeTraitement(poPatientTraitement: IPatientTraitement): Observable<boolean> {
		this.ListSeances = [];
		let loLoader: Loader;
		this.isvcTraitement.getTraitement(poPatientTraitement._id).pipe(
			map((poTraitement: Traitement) => {
				return this.traitement = poTraitement;
			}
			)
		).subscribe();
		const loTraitement: Traitement = ModelResolver.toClass(poPatientTraitement, Traitement);
		return defer(() => this.isvcLoading.create("Chargement..."))
			.pipe(
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(() => this.isvSeances.getSeances(this.traitement)),
				tap(() => loLoader.dismiss()),
				mergeMap((seances: Seance[]) => {
					this.ListSeances = seances;

					if (this.isvcTraitement.canDeleteTraitement(this.ListSeances)) {
						return this.isvcUiMessage.showAsyncMessage(
							new ShowMessageParamsPopup({
								header: "Suppression de traitement",
								message: `Voulez-vous supprimer ce traitement ${this.isAdmin ? "et toutes ses séances ?" : "?"}`,
								buttons: [
									{ text: "Annuler", role: UiMessageService.C_CANCEL_ROLE },
									{ text: "Supprimer", cssClass: "deleteButton" }
								]
							})
						).pipe(
							mergeMap(() => {
								loLoader = undefined;
								return this.isvcLoading.create("Suppression du traitement");
							}),
							mergeMap((poLoader: Loader) => poLoader.present()),
							mergeMap(() => this.isvcTraitement.deleteTraitement(loTraitement, this.isAdmin)),
							finalize(() => {
								if (loLoader) {
									loLoader.dismiss();
								}
							})
						);

					} else {
						return this.isvcUiMessage.showAsyncMessage(
							new ShowMessageParamsPopup({
								header: "Erreur",
								message: CANNOT_DELETE_TRAITMENT_ERROR_MESSAGE,
								buttons: [
									{ text: "OK" }
								]
							})
						).pipe(
							mergeMapTo(EMPTY)
						);
					}
				})
			);
	}


	/** @override */
	public onItemOptionClicked(poPatientTraitement: IPatientTraitement, psAction: string, poItemSliding: IonItemSliding): void {
		super.onItemOptionClicked(poPatientTraitement, psAction, poItemSliding);
		if (psAction === TraitementsPageComponent.C_DUPLICATE_ACTION) {
			return this.isvcUiMessage.showMessage(
				new ShowMessageParamsPopup({
					header: "Duplication de traitement",
					message: "Voulez-vous dupliquer ce traitement ?",
					buttons: [
						{ text: "Annuler" },
						{ text: "Dupliquer", handler: () => this.duplicateTraitement(poPatientTraitement).subscribe() }
					]
				})
			);
		};
	}

	/** @override */
	protected prepareDataSource(paTraitementIds?: string[]): void {
		this.moDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				include_docs: ArrayHelper.hasElements(paTraitementIds),
				startkey: paTraitementIds ? undefined : `${C_PREFIX_TRAITEMENT}${IdHelper.buildVirtualNode([UserData.currentSite._id, C_PREFIX_PATIENT])}`,
				endkey: paTraitementIds ? undefined : `${C_PREFIX_TRAITEMENT}${IdHelper.buildVirtualNode([UserData.currentSite._id, C_PREFIX_PATIENT])}${Store.C_ANYTHING_CODE_ASCII}`,
				keys: paTraitementIds ? paTraitementIds : undefined,
				conflicts: true
			},
			fields: paTraitementIds ? [
				TraitementService.C_CREATE_DATE_PROPERTY,
				TraitementService.C_BEGIN_DATE_PROPERTY,
				TraitementService.C_END_DATE_PROPERTY,
				TraitementService.C_BREAK_DATE_PROPERTY,
				TraitementService.C_ACTES_PROPERTY,
				TraitementService.C_STATE_PROPERTY
			] : undefined,
			live: !ArrayHelper.hasElements(paTraitementIds)
		};
	}

	private init(): Observable<IPatientTraitement[]> {
		return defer(() =>
			this.moParentPage.isActive() || this.moParentPage.isInitializing() ?
				this.isvcLoading.present("Chargement des traitements en cours ...") : of(null)
		)
			.pipe(
				mergeMap(() => this.isvcGroups.getGroupsByRoles([C_SECTORS_ROLE_ID])),
				tap((paSectors: ISector[]) => {
					this.groupFilterOptions = paSectors.filter(((poSector: ISector) => poSector.siteId === UserData.currentSite._id)).map((poSector: ISector) => ({ label: poSector.name, value: poSector }));
					this.prepareDataSource();
				}),
				mergeMap((paSectors: ISector[]) => this.isvcContacts.getSiteContactsIds(paSectors, C_PREFIX_PATIENT)),
				map((paSitePatientIds: string[]) => paSitePatientIds.map((psId: string) => IdHelper.getGuidFromId(psId, C_PREFIX_PATIENT))),
				switchMap((paSitePatientGuids: string[]) => this.isvcStore.get(this.moDataSource)
					.pipe(
						map((paTraitementsWithoutDoc: ITraitement[]) =>
							paTraitementsWithoutDoc.filter((poTraitement: ITraitement) => paSitePatientGuids.some((psPatientId: string) => poTraitement._id.includes(psPatientId)))
						),
						mergeMap((paTraitementsWithoutDoc: ITraitement[]) => {
							this.prepareDataSource(paTraitementsWithoutDoc.map((poTraitementsWithoutDoc: ITraitement) => poTraitementsWithoutDoc._id));
							return this.isvcStore.get(this.moDataSource);
						})
					)
				),
				switchMap((paTraitements: ITraitement[]) => this.initTraitementsDatas(paTraitements)),
				mergeMap((paResults: IPatientTraitement[]) => this.initTraitementsDetails(paResults)),
				catchError(poError => {
					this.isvcLoading.dismiss();
					this.manageToolbar();
					const lsError = "Un problème de chargement des données est survenu.";
					console.error(`IDL.TRAIT.P.C:: ${lsError}`, poError);
					this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: lsError, header: "Erreur" }));
					return EMPTY;
				})
			);
	}

	private getPatientsGroups(paTraitements: ITraitement[]): Observable<ITraitement[]> {
		return this.isvcGroups.getContactsGroupIds(this.isvcTraitement.extractPatientsIds(paTraitements))
			.pipe(
				tap((poGroupIdsByPatientIds: Map<string, string[]>) => this.moGroupIdsByPatientIds = poGroupIdsByPatientIds),
				mapTo(paTraitements)
			);
	}

	/** Récupère un tableau des objets contenant un traitmeent et des informations du patient associé.
	 * @param paTraitements Tableau de tous les traitements récupérés.
	 */
	private getPatientTraitements(paTraitements: ITraitement[]): Observable<IPatientTraitement[]> {
		return this.isvcContacts.getContactsByIds(this.isvcTraitement.extractPatientsIds(paTraitements))
			.pipe(
				map((paPatients: IPatient[]) => this.maAllPatients = ArrayHelper.unique(paPatients)),
				map((paPatients: IPatient[]) => {
					const laPatientsByIds: Map<string, IPatient> = ArrayHelper.groupByUnique(paPatients, (poPatient: IPatient) => poPatient._id);
					return paTraitements.map((poTraitement: ITraitement) => this.createPatientTraitement(poTraitement, laPatientsByIds.get(Traitement.extractPatientId(poTraitement._id))));
				})
			);
	}

	/** Récupère tous les intervenants des séances des traitements.
	 * @param paTraitements Tableau contenant pour chaque objet le traitement et des informations sur le patient associé.
	 */
	private getIntervenants(): Observable<IIdelizyContact[]> {
		return this.isvcGroups.getGroupsByRoles([])
			.pipe(
				mergeMap((paGroups: IGroup[]) => this.isvcGroups.getGroupContacts(paGroups)),
				map((poContactsByGroups: Map<string, IContact[]>) => ArrayHelper.flat(MapHelper.valuesToArray(poContactsByGroups)))
			);
	}

	private initTraitementsDetails(paTraitements: IPatientTraitement[]): Observable<IPatientTraitement[]> {
		return this.isvcTraitement.fillTraitementsSeancesNumber(paTraitements).pipe(
			tap(() => this.detectChanges())
		);
	}

	private initTraitementsDatas(paTraitements: ITraitement[]): Observable<IPatientTraitement[]> {
		return this.getPatientsGroups(paTraitements).pipe(
			switchMap((paTraitementsList: ITraitement[]) => this.getPatientTraitements(paTraitementsList)),
			switchMap((paResults: IPatientTraitement[]) => this.getIntervenants().pipe(mapTo(paResults))),
			switchMap((paResults: IPatientTraitement[]) => this.isvcOrdonnances.getOrdonnancesByTraitementId(paTraitements.map((poTraitement: ITraitement) => poTraitement._id))
				.pipe(
					tap((poOrdonnancesByTraitementId: Map<string, Ordonnance[]>) => this.ordonnancesByTraitementId = poOrdonnancesByTraitementId),
					mapTo(paResults)
				)
			),
			tap((paResults: IPatientTraitement[]) => {
				this.initIsActivePropTraitements(paResults);
				this.currentTraitements = this.documents = paResults;
				this.manageToolbar();
				this.initFilterbarParams();
				this.isvcLoading.dismiss();
				this.detectChanges();
			})
		);
	}

	private initIsActivePropTraitements(paTraitements: IPatientTraitement[]): void {
		const ldToday = new Date();
		paTraitements.forEach((poItem: IPatientTraitement) => {
			poItem.isActive = Traitement.isTraitementActive(poItem, ldToday, false);
		});
	}

	/** Crée un objet `IPatientTraitement` à partir des données d'un traitement et de celles d'un patient.
	 * @param poTraitementData Données du traitement pour créer le nouvel objet.
	 * @param poPatient Données du patient pour créer le nouvel objet.
	 */
	private createPatientTraitement(poTraitementData: ITraitement, poPatient: IPatient): IPatientTraitement {
		return {
			...poTraitementData,
			patientFirstName: poPatient ? poPatient.firstName : "",
			patientLastName: poPatient ? poPatient.lastName : "",
			noPatient: poPatient ? "" : "Patient non défini",
			patientId: poPatient?._id
		} as IPatientTraitement;
	}

	/** Survient lors d'un changement de slide.
	 * @param poEvent Événement indiquant qu'un changement de slide a eu lieu.
	 */
	public onTabChanged(poEvent: CustomEvent<{ changed: boolean, index: number }>): void {
		if (poEvent.detail.changed) {
			this.currentIndex = poEvent.detail.index;
			// On n'utilise pas la propriété bindée au composant [searchValue] car cela donne un rendu qui clignote beaucoup trop.
			this.searchComponent.searchFormControl.setValue(this.maSearchValues[this.currentIndex]);

			this.initFilterbarParams();
		}
	}

	/** Survient lors d'un changement de la valeur de recherche dans la slide courante.
	 * @param psEvent Événement corresponant à la valeur de la recherche pour la slide courante.
	 */
	public onSearchValueChanged(psEvent: string): void {
		this.maSearchValues[this.currentIndex] = psEvent;
	}

	/** @override */
	public onFilteredDocumentsChanged(paChanges?: IPatientTraitement[]): void {
		if (paChanges) // Si on a des changements, il faut impacter le tableau de recherche.
			this.maSearchedTraitements = paChanges;

		// Application des filtres de la barre de filtrage et initialisation du tableau courant en fonction de la slide.
		this.currentTraitements = this.applyFilterValues();
		// On envoie le tableau des traitements mis à jour en fonction de la slide courante.
		super.onFilteredDocumentsChanged(this.filterBySectors(this.currentTraitements, this.maSelectedSectors));
	}

	public isSameDate(poPatientTraitement: IPatientTraitement): boolean {
		const ldBeginDate = new Date(poPatientTraitement.beginDate);
		return DateHelper.diffDays(ldBeginDate, new Date(poPatientTraitement.endDate)) === 0 ||
			DateHelper.diffDays(ldBeginDate, new Date(poPatientTraitement.breakDate)) === 0;
	}

	/** Permute la valeur pour cacher ou non la zone de filtrage. */
	public swapFilterbarHiddenParams(): void {
		const loCurrentFilterbarParams: IFilterbarParams = this.filterbarParams[this.currentIndex];
		loCurrentFilterbarParams.hidden = !loCurrentFilterbarParams.hidden;
	}

	/** Initialise les paramètres de filtrage. */
	private initFilterbarParams(): void {
		this.resetCurrentFilters();

		this.filterbarParams = [
			{
				filters: this.createFilterbarOptions(0),
				hasResetButton: true,
				hidden: true
			} as IFilterbarParams,
			{
				filters: this.createFilterbarOptions(1),
				hasResetButton: true,
				hidden: true
			} as IFilterbarParams
		];
	}

	/** Crée les différentes options de filtrage à l'aide d'un index de suffixe. */
	private createFilterbarOptions(pnSuffixIndex: number): IFilterbarOptions[] {
		return [
			this.createPatientsFilterbarOptions(pnSuffixIndex),
			this.createDateFilterbarOptions(pnSuffixIndex)
		];
	}

	/** Crée les options de filtrage par patients à l'aide d'un index pour le suffixe de l'identifiant. */
	private createPatientsFilterbarOptions(pnSuffixIndex: number): IFilterbarOptions {
		return {
			id: `${PatientsService.C_FILTER_PATIENTS_ID}${pnSuffixIndex}`,
			icon: "person-circle",
			returnType: EFilterActionReturnType.observable,
			filterType: EFilterType.avatar,
			action: () => {
				return this.isvcPatients.selectPatients(
					this.maFilterSelectedPatients.map((poPatient: IPatient) => poPatient._id),
					this.maAllPatients.filter(((poPatient: IPatient, i) => this.maAllPatients.indexOf(poPatient) === i)),
					"Filtrer par patient"
				);
			}
		} as IFilterbarOptions;
	}

	/** Crée les options de filtrage par date à l'aide d'un index pour le suffixe de l'identifiant. */
	private createDateFilterbarOptions(pnSuffixIndex: number): IFilterbarOptions {
		return {
			id: `${TraitementsPageComponent.C_FILTER_DATE_ID}${pnSuffixIndex}`,
			icon: "timetable",
			returnType: EFilterActionReturnType.undefined,
			filterType: EFilterType.datepicker
		} as IFilterbarOptions;
	}

	/** Réinitialise les filtres en cours. */
	private resetCurrentFilters(): void {
		this.maFilterSelectedPatients = [];
		this.mdFilterSelectedDate = undefined;
	}

	/** Événement de réinitialisation du filtrage. */
	public onFilterbarResetEvent(pbIsReset: boolean): void {
		this.filterbarParams[this.currentIndex].hidden = pbIsReset;
		this.resetCurrentFilters();
		this.onFilteredDocumentsChanged(this.documents);
		// On remet la recherche en cours car la réinitialisation la fait sauter.
		this.searchComponent.searchFormControl.setValue(this.maSearchValues[this.currentIndex]);
	}

	/** Événement de mise à jour du nombre de filtrage en cours. */
	public onFilterCountChanged(pnFilterCount: number): void {
		this.filtersCount = pnFilterCount;
	}

	/** Événement de mise à jour des valeurs pour un filtre. */
	public onFilterValuesChanged(poEvent: IFilterValuesChangedEvent<IIdelizyContact | Date | IPatient>): void {
		// On modifie les valeurs des filtres de la barre de filtrage avec les nouveaux changements.
		this.setFilterValuesChanged(poEvent);
		// On appelle la mise à jour des documents filtrés.
		this.onFilteredDocumentsChanged();
	}

	/** Modifie un des champs du comopsant pour garder en mémoire les différents filtres en cours. */
	private setFilterValuesChanged(poEvent: IFilterValuesChangedEvent<IIdelizyContact | Date | IPatient>): void {
		if (poEvent.id.includes(TraitementsPageComponent.C_FILTER_DATE_ID))
			this.mdFilterSelectedDate = ArrayHelper.getFirstElement(poEvent.changes) as Date;

		else if (poEvent.id.includes(PatientsService.C_FILTER_PATIENTS_ID))
			this.maFilterSelectedPatients = poEvent.changes as IPatient[];

		else
			console.warn(`IDL.TRAIT.P.C:: L'identifiant de changements de valeur de filtres '${poEvent.id}' est inconnu.`);
	}

	/** Applique les filtres de la barre de filtrage sur les documents à afficher et retourne le nouveau tableau obtenu. */
	private applyFilterValues(): IPatientTraitement[] {
		let laResults: IPatientTraitement[] = this.maSearchedTraitements;

		// On filtre les patients s'il y en a.
		if (ArrayHelper.hasElements(this.maFilterSelectedPatients)) {
			laResults = laResults.filter((poItem: IPatientTraitement) =>
				this.maFilterSelectedPatients.some((poPatient: IPatient) => poItem.patientId === poPatient._id)
			);
		}

		// On filtre la date s'il y en a une.
		if (DateHelper.isDate(this.mdFilterSelectedDate)) {
			laResults = laResults.filter((poItem: IPatientTraitement) =>
				DateHelper.isBetweenTwoDays(this.mdFilterSelectedDate, poItem.beginDate, poItem.endDate) ||
				(poItem.breakDate && DateHelper.isBetweenTwoDays(this.mdFilterSelectedDate, poItem.beginDate, poItem.breakDate))
			);
		}

		return laResults;
	}

	public onGroupFilterSelectionChanged(paSelectedSectors: IGroup[], poSerchComponent: SearchComponent<IPatientTraitement>): void {
		this.maSelectedSectors = paSelectedSectors;

		this.onFilteredDocumentsChanged(poSerchComponent.search());
	}

	private filterBySectors(paTraitements: IPatientTraitement[], paSectors: IGroup[]): IPatientTraitement[] {
		return !ArrayHelper.hasElements(paSectors) ? paTraitements :
			paTraitements.filter((poTraitement: IPatientTraitement) =>
				paSectors.some((poSector: IGroup) => this.moGroupIdsByPatientIds.get(poTraitement.patientId)?.includes(poSector._id))
			);
	}

	/** @override */
	public sortDocuments(paDocuments: IPatientTraitement[]): IPatientTraitement[] {
		return super.sortDocuments(paDocuments).sort((poTraitementA: IPatientTraitement, poTraitementB: IPatientTraitement) =>
			ArrayHelper.compareByExistingProperty(poTraitementB, poTraitementA, "_conflicts")
		);
	}

	public onToggleChanged(): void {
		this.isToggleChecked = !this.isToggleChecked;

		if (this.isToggleChecked)
			this.moUpdateActeDisplayModeSubject.next(EResumeActeMode.lettreCle);
		else
			this.moUpdateActeDisplayModeSubject.next(EResumeActeMode.label);
	}

	public duplicateTraitement(poPatientTraitement: IPatientTraitement): Observable<boolean> {
		return this.isvcTraitement.duplicateTraitement(poPatientTraitement._id)
			.pipe(
				takeUntil(this.destroyed$)
			);
	}

	public deleteTraitement(poPatientTraitement: IPatientTraitement): Observable<boolean> {
		return this.isvcTraitement.duplicateTraitement(poPatientTraitement._id)
			.pipe(
				takeUntil(this.destroyed$)
			);
	}

	//#endregion
}
