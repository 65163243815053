<div class="component-formulaire-patient__container">
	<div class="component-formulaire-patient__content">
		<form [formGroup]="patientForm" (ngSubmit)="handleSubmit($event)">
			<div class="content__form-inputs" [ngClass]="{'single-column': showLocationInputs !== showIdentityInputs, 'two-columns': showLocationInputs === showIdentityInputs }">
				<div *ngIf="showIdentityInputs" class="form-inputs__left-column" [ngClass]="{'form-inputs__both-columns': showLocationInputs}">
					<div class="form-inputs__identity">
						 <lua-data-picker
						 	[options]="optionsGender"
							[fullWidth]="true"
							formControlName="gender"
						 ></lua-data-picker>
						<lua-input
							[fullWidth]="true"
							placeholder="Nom de naissance (obligatoire)"
							label="Nom de naissance (obligatoire)"
							formControlName="lastName"
						></lua-input>
						<lua-input
							[fullWidth]="true"
							placeholder="Nom usuel (ex: nom d'époux)"
							label="Nom usuel (ex: nom d'époux)"
							formControlName="usualLastName"
						></lua-input>
						<lua-input
							[fullWidth]="true"
							placeholder="Prénom de naissance (obligatoire)"
							label="Prénom de naissance (obligatoire)"
							formControlName="firstName"
						></lua-input>
						<a class="identity__display-more-fields"(click)="displayAdditionalInputs()"
							>prénoms usuels, prénoms de naissance (optionnel)</a
						>
						<ng-container *ngIf="showAdditionalInputs">
							<lua-input
								[fullWidth]="true"
								placeholder="Prénoms usuels (optionnel)"
								label="Prénoms usuels (optionnel)"
								formControlName="usualFirstNames"
							></lua-input>
							<lua-input
								[fullWidth]="true"
								placeholder="Prénoms de naissance (optionnel)"
								label="Prénoms de naissance (optionnel)"
								formControlName="birthFirstNames"
							></lua-input>
						</ng-container>
					</div>
					<div class="form-inputs__birth-info">
						<h2 class="form-inputs__subtitle">Informations de naissance</h2>
						<lua-input
							[fullWidth]="true"
							placeholder="Date de naissance"
							label="Date de naissance"
							type="date"					
							formControlName="birthDate"
						></lua-input>
						<lua-input
							[fullWidth]="true"
							placeholder="Lieu de naissance"
							label="Lieu de naissance"
							formControlName="birthPlace"
						></lua-input>
						<lua-input
							[fullWidth]="true"
							placeholder="Code INSEE du lieu de naissance"
							label="Code INSEE du lieu de naissance"
							formControlName="inseeCodeBirthPlace"
						></lua-input>
					</div>
					<div class="form-inputs__identity-document">
						<div class="identity-document__header">
							<h2 class="form-inputs__subtitle">Pièce d'identité</h2>
							<small>
								Dans le cadre de l'INS vous devez contrôler une pièce d'identité
								du patient
							</small>
							<lua-data-picker
						 	[options]="optionsIdentityDocument"
							direction="vertical"
							formControlName="identityDocument"
						 ></lua-data-picker>
						</div>
					</div>
				</div>
				<div *ngIf="showLocationInputs" [ngClass]="getClassesLocationForm()">
					<div class="form-inputs__location">
						<h2 class="form-inputs__subtitle">Comment y aller ?</h2>
						<div class="form-inputs__input-container">
							<div
								class="input-container__prefix input-container__centered-icon"
							>
								<lua-icone
									iconName="location_on"
									color="Noir"
									mode="outlined"
								></lua-icone>
							</div>
							<lua-input
								[fullWidth]="true"
								placeholder="Rue"
								label="Rue"
								formControlName="street"								
							></lua-input>
						</div>
						<div class="form-inputs__input-container">
							<div class="input-container__prefix"></div>
							<lua-input
								[fullWidth]="true"
								placeholder="Code postal"
								label="Code postal"
								formControlName="zipCode"
							></lua-input>
						</div>
						<div class="form-inputs__input-container">
							<div class="input-container__prefix"></div>
							<lua-input
								[fullWidth]="true"
								placeholder="Ville"
								label="Ville"
								formControlName="city"
							></lua-input>
						</div>
						<div class="form-inputs__input-container">
							<div
								class="input-container__prefix input-container__centered-icon"
							>
								<lua-icone
									iconName="floor"
									color="Noir"
									mode="outlined"
								></lua-icone>
							</div>
							<lua-input
								[fullWidth]="true"
								placeholder="Etage"
								label="Etage"
								formControlName="floor"
							></lua-input>
						</div>
						<div class="form-inputs__input-container">
							<div
								class="input-container__prefix input-container__centered-icon"
							>
								<lua-icone
									iconName="key"
									color="Noir"
									mode="outlined"
								></lua-icone>
							</div>
							<lua-input
								[fullWidth]="true"
								placeholder="Code d'accès"
								label="Code d'accès"
								formControlName="accessCode"
							></lua-input>
						</div>
					</div>
					<div class="form-inputs__contact">
						<h2 class="form-inputs__subtitle">Contacter le patient</h2>
						<div class="form-inputs__input-container">
							<div
								class="input-container__prefix input-container__centered-icon"
							>
								<lua-icone
									iconName="call"
									color="Noir"
									mode="outlined"
								></lua-icone>
							</div>
							<lua-input
								[fullWidth]="true"
								placeholder="Numéro de téléphone"
								label="Numéro de téléphone"
								formControlName="phone"
							></lua-input>
						</div>
						<div class="form-inputs__input-container">
							<div
								class="input-container__prefix input-container__centered-icon"
							>
								<lua-icone
									iconName="mail"
									color="Noir"
									mode="outlined"
								></lua-icone>
							</div>
							<lua-input
								[fullWidth]="true"
								placeholder="Adresse email"
								label="Adresse email"
								formControlName="email"
							></lua-input>
						</div>
					</div>
				</div>
			</div>
			<div class="content__form-buttons">
				<lua-action-principale
					[libelle]="libelleButton"
					(click)="handleSubmit($event)"
					[isDisabled]="patientForm.invalid"
				></lua-action-principale>
				<lua-bouton-texte
					libelle="Annuler"
					(click)="goBack()"
				></lua-bouton-texte>
			</div>
		</form>
	</div>
</div>
