import { Inject, Injectable, InjectionToken, Injector, Optional, Type } from '@angular/core';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { DateHelper } from '../../../helpers/dateHelper';
import { EntityHelper } from '../../../helpers/entityHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { IEntity } from '../../../model/entities/IEntity';
import { IEntityLink } from '../../../model/entities/IEntityLink';
import { IEntityLinkPart } from '../../../model/entities/IEntityLinkPart';
import { EPrefix } from '../../../model/EPrefix';
import { EntityLinkService } from '../../../services/entityLink.service';
import { WorkspaceService } from '../../../services/workspace.service';
import { IDmsMeta } from './IDmsMeta';
import { IMetaEntityBuilderBase } from './IMetaEntityBuilderBase';
import { MetaEntityBuilderBase } from './MetaEntityBuilderBase';

export const CONV_META_CONFIG = new InjectionToken<Type<IMetaEntityBuilderBase>[]>("CONV_META_CONFIG");

@Injectable()
export class MetaConversationBuilder extends MetaEntityBuilderBase {

	//#region FIELDS

	private maBuilders: IMetaEntityBuilderBase[];

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcEntityLink: EntityLinkService,
		private readonly ioInjector: Injector,
		psvcWorkspace: WorkspaceService,
		// On injecte directement les types car on ne peut pas appeler le service dms-meta sans créer de cycle.
		@Inject(CONV_META_CONFIG) @Optional() private readonly iaBuildersTypes?: Type<IMetaEntityBuilderBase>[]
	) {
		super(psvcWorkspace);
	}

	/** Déclenche l'injection des builders. */
	private initBuilders(): void {
		if (this.iaBuildersTypes) {
			this.maBuilders = this.iaBuildersTypes.map((poType: Type<IMetaEntityBuilderBase>) => {
				try {
					return this.ioInjector.get(poType);
				}
				catch (poError) {
					console.error("CONV.META.BUILDER::Erreur lors de l'initialisation du DmsMetaService. Des providers sont manquants pour ce builder.", poType, poError);
					return undefined;
				}
			});

			// On supprime les valeurs undefined du tableau pour fonctionner même si il manque des providers.
			this.maBuilders = this.maBuilders.filter((poBuilder: MetaEntityBuilderBase) => !!poBuilder);
		}
		else
			this.maBuilders = [];
	}

	/** @override */
	public match(psDocumentId: string): boolean {
		return IdHelper.hasPrefixId(psDocumentId, EPrefix.conversation);
	}

	/** @override */
	public async prepareMeta(poEntity: IEntity, poMeta: IDmsMeta): Promise<IDmsMeta> {
		if (!this.maBuilders)
			this.initBuilders();

		const laEntityLinks: IEntityLink[] = await this.isvcEntityLink.getEntityLinks(poEntity.id).toPromise();

		laEntityLinks.sort((poEntityA: IEntityLink, poEntityB: IEntityLink) =>
			DateHelper.compareTwoDates(poEntityA.createDate, poEntityB.createDate)
		);

		const loFirstLink: IEntityLink = ArrayHelper.getFirstElement(laEntityLinks);

		if (loFirstLink) {
			const loEntityPart: IEntityLinkPart = EntityHelper.getEntityLinkPartFromSourcePrefix(loFirstLink, EPrefix.conversation);
			const loLinkedEntity: IEntity = await this.isvcEntityLink.getEntity(loEntityPart.databaseId, loEntityPart.entityId).toPromise();
			let loMeta: IDmsMeta;

			if (loLinkedEntity) {
				const loBuilder: IMetaEntityBuilderBase = this.maBuilders.find((poBuilder: IMetaEntityBuilderBase) => poBuilder.match(loLinkedEntity.id));

				if (loBuilder)
					loMeta = await loBuilder.prepareMeta(loLinkedEntity, poMeta);
				else
					loMeta = await super.prepareMeta(loLinkedEntity, poMeta);
			}
			loMeta.attributes.push({ name: "convId", value: poEntity.id });
			return loMeta;
		}
		return super.prepareMeta(poEntity, poMeta);
	}

	//#endregion

}
