import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentBase } from '../../helpers/ComponentBase';
import { PageInfo } from '../../model/PageInfo';
import { IWebviewParams } from '../../model/webview/IWebviewParams';

@Component({
	selector: 'webView',
	templateUrl: './webview.component.html',
	styleUrls: ['./webview.component.scss']
})
export class WebViewComponent extends ComponentBase {

	//#region PROPERTIES

	public params: IWebviewParams;

	//#endregion

	//#region METHODS

	constructor(poActivatedRoute: ActivatedRoute) {
		super();

		const loPageInfo: PageInfo = poActivatedRoute.snapshot.data.pageInfo;
		if (loPageInfo && loPageInfo.params)
			this.params = loPageInfo.params;
	}

	//#endregion
}