import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FavoritesModule } from '../preferences/favorites/favorites.module';
import { GdprPopupComponent } from './gdpr-popup/gdpr-popup.component';
@NgModule({
	imports: [CommonModule, IonicModule, FavoritesModule],
	exports: [GdprPopupComponent],
	declarations: [GdprPopupComponent],
	providers: []
})
export class GdprModule { }