import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonItemSliding } from '@ionic/angular';
import { ArrayHelper } from 'libs/osapp/src/helpers/arrayHelper';
import { GuidHelper } from 'libs/osapp/src/helpers/guidHelper';
import { EBarElementDock } from 'libs/osapp/src/model/barElement/EBarElementDock';
import { EBarElementPosition } from 'libs/osapp/src/model/barElement/EBarElementPosition';
import { IBarElement } from 'libs/osapp/src/model/barElement/IBarElement';
import { IContact } from 'libs/osapp/src/model/contacts/IContact';
import { IGroup } from 'libs/osapp/src/model/contacts/IGroup';
import { IGroupsDynHostParams } from 'libs/osapp/src/model/contacts/IGroupsDynHostParams';
import { IItemOption } from 'libs/osapp/src/model/forms/IItemOption';
import { IAvatar } from 'libs/osapp/src/model/picture/IAvatar';
import { ERouteUrlPart } from 'libs/osapp/src/model/route/ERouteUrlPart';
import { PatternPipe } from 'libs/osapp/src/pipes/pattern.pipe';
import { EntityLinkService } from 'libs/osapp/src/services/entityLink.service';
import { FormsService } from 'libs/osapp/src/services/forms.service';
import { GroupsService } from 'libs/osapp/src/services/groups.service';
import { ShowMessageParamsPopup } from 'libs/osapp/src/services/interfaces/ShowMessageParamsPopup';
import { PageManagerService } from 'libs/osapp/src/services/pageManager.service';
import { PatternResolverService } from 'libs/osapp/src/services/pattern-resolver.service';
import { Store } from 'libs/osapp/src/services/store.service';
import { UiMessageService } from 'libs/osapp/src/services/uiMessage.service';
import { Observable, defer, of } from 'rxjs';
import { finalize, map, mapTo, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { C_SECTORS_ROLE_ID, PermissionsService } from '../../../modules/permissions/services/permissions.service';
import { FavoritesService } from '../../../modules/preferences/favorites/services/favorites.service';
import { ISelectOption } from '../../../modules/selector/selector/ISelectOption';
import { IApplicationRole } from "../../../services/security/IApplicationRole";
import { DynamicPageComponent } from '../../dynamicPage/dynamicPage.component';
import { FormListComponent } from '../../forms/formList/formList.component';
import { SearchComponent } from '../../search/search.component';
@Component({
	templateUrl: './groupsDynHost.component.html',
	styleUrls: ['./groupsDynHost.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupsDynHostComponent<T extends IGroup> extends FormListComponent<T> implements OnInit {

	//#region FIELDS

	private maSelectedRoles: IApplicationRole[];

	//#endregion

	//#region PROPERTIES

	public readonly C_REMOVE_EVENT_ID = "removeGroup";

	public readonly instanceId: string = GuidHelper.newGuid();

	@Input() public params: IGroupsDynHostParams;

	public contactsByGroupId = new Map<string, IContact[]>();
	public selectOptions: ISelectOption[];

	@ViewChild('search') public searchComponent: SearchComponent<T>;

	//#endregion

	//#region METHODS

	constructor(
		private isvcGroups: GroupsService,
		psvcPermissions: PermissionsService,
		poParentPage: DynamicPageComponent<ComponentBase>,
		psvcPageManager: PageManagerService,
		psvcUiMessage: UiMessageService,
		psvcForms: FormsService,
		psvcEntity: EntityLinkService,
		poPatternPipe: PatternPipe,
		poRenderer: Renderer2,
		psvcPatternResolver: PatternResolverService,
		poChangeDetectorRef: ChangeDetectorRef,
		ioRoute: ActivatedRoute,
		ioRouter: Router,
		psvcStore: Store,
		psvcFavorites: FavoritesService
	) {
		super(poParentPage, psvcPageManager, psvcUiMessage, psvcForms, psvcEntity, poPatternPipe, poRenderer, psvcPatternResolver,
			poChangeDetectorRef, ioRoute, ioRouter, psvcStore, psvcFavorites);

		this.selectOptions = psvcPermissions.getPermissionRoles(null, GroupsService.C_EXCLUDE_ROLE_IDS).map((poRole: IApplicationRole) => ({ label: poRole.label, value: poRole }));
	}

	public ngOnInit(): void {
		this.subscribeToGroupUpdate().pipe(takeUntil(this.destroyed$))
			.subscribe();

		this.idFormDesc = this.params.idFormDesc;
		this.idFormList = this.params.idFormList;

		super.ngOnInit();

		this.customGetEntries = () => this.initGroups();
	}

	/** @override */
	protected createBarElements(): IBarElement[] {
		const laBarElements: IBarElement[] = [];

		if (this.hasAddButton) {
			const loAddBarElement: IBarElement = {
				id: "circle",
				component: "fabButton",
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.right,
				icon: "add",
				onTap: () => this.navigateToGroupEdit(),
				name:"Editer"
			};
			laBarElements.push(loAddBarElement);
		}

		return laBarElements;
	}

	/** @override */
	public onItemOptionClicked(poModel: T, psAction: string, poItemSliding: IonItemSliding, poOption: IItemOption): void {
		super.onItemOptionClicked(poModel, psAction, poItemSliding, poOption);

		if (psAction === this.C_REMOVE_EVENT_ID) { // On veut supprimer le groupe.
			this.removeGroupPopup(poModel).subscribe();
		}

		poItemSliding.close();
	}

	/** Va sur la page de visualisation du groupe cliqué.
	 * @param poClickedGroup Groupe qu'on veut afficher en mode visu.
	 */
	public onItemClicked(poClickedGroup: T): void {
		defer(() => { this.closeKeyboard(); return of(undefined); })
			.pipe(
				tap(_ => this.navigateToGroup(poClickedGroup)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Construit un avatar à partir d'une image. */
	public getGroupAvatar(poGroup: T): IAvatar {
		return GroupsService.createGroupAvatar(poGroup);
	}

	/** Envoie sur le groupe sélectionné.
	 * @param poModel Groupe séléctionné.
	 */
	private navigateToGroupEdit(poModel?: T): void {
		this.subscribeToGroupUpdate().pipe(takeUntil(this.destroyed$))
			.subscribe();

		if (poModel)
			this.ioRouter.navigate(["groupes", poModel._id, ERouteUrlPart.edit]);
		else
			this.ioRouter.navigate(["groupes", ERouteUrlPart.new]);
	}

	/** Navigue sur le groupe sélectionné en mode visualisation.
	 * @param poModel Groupe séléctionné.
	 */
	private navigateToGroup(poModel: T) {
		this.ioRouter.navigate(["groupes", poModel._id]);
	}

	/** Initialise les groupes et leurs contacts. */
	private initGroups(): Observable<Array<T>> {
		return this.isvcGroups.getGroups(true, true)
			.pipe(
				mergeMap((paGroups: Array<T>) => this.isvcGroups.getGroupContacts(paGroups)
					.pipe(
						tap((poLinkedContactsByGroup: Map<string, IContact[]>) => this.contactsByGroupId = poLinkedContactsByGroup),
						mapTo(paGroups.filter((poGroup: IGroup) => !poGroup.roles?.includes(C_SECTORS_ROLE_ID))),
						finalize(() => this.isLoading = false),
					)),
				takeUntil(this.destroyed$)
			);
	}

	/** Récupère les modifications effectuées sur le groupe modifié. */
	private subscribeToGroupUpdate(): Observable<Array<IContact>> {
		return this.isvcGroups.getUpdatedGroup()
			.pipe(
				tap((poUpdatedGroup: T) => {
					const lnGroupIndex: number = this.documents.findIndex((poGroup: IGroup) => poGroup._id === poUpdatedGroup._id);

					if (lnGroupIndex >= 0)
						this.documents[lnGroupIndex] = poUpdatedGroup;
					else
						this.documents.push(poUpdatedGroup);
				}),
				mergeMap((poGroupResult: IGroup) => this.getGroupContacts(poGroupResult)),
				tap(_ => this.onFilteredDocumentsChanged(this.documents))
			);
	}

	/** Récupère les contacts liés au groupe qu'on souhaite modifier.
	 * @param poGroup Groupe qui sera modifié.
	 */
	private getGroupContacts(poGroup: IGroup): Observable<Array<IContact>> {
		return this.isvcGroups.getGroupContacts(poGroup)
			.pipe(tap((paLinkedContacts: Array<IContact>) => this.contactsByGroupId.set(poGroup._id, paLinkedContacts || [])));
	}

	/** Demande la confirmation de la suppression d'un groupe. Si l'utilisateur clique sur "Ok", suppression effective.
	 * @param poGroup Groupe qui doit être supprimé.
	 */
	private removeGroupPopup(poGroup: IGroup): Observable<boolean> {
		/** Valeur de retour. Initialisé à `false` car aucun callback si l'utilisateur clique en dehors de la pop-up. */
		let lbResult = false;

		return this.isvcUiMessage.showAsyncMessage<void>(
			new ShowMessageParamsPopup({
				header: "Suppression",
				message: `Voulez-vous vraiment supprimer le groupe "${poGroup.name}" ?`,
				buttons: [
					{ text: "Annuler", handler: () => { } }, // Déjà initialisé à 'false'.
					{ text: "OK", handler: () => { lbResult = true; } }
				]
			})
		)
			.pipe(
				map(() => lbResult),
				takeUntil(this.destroyed$),
				mergeMap((pbConfirmation: boolean) => pbConfirmation ? this.removeGroup(poGroup) : of(true))
			);
	}

	/** Supprime un groupe.
	 * @param poModel Groupe à supprimer.
	 */
	private removeGroup(poModel: IGroup): Observable<boolean> {
		return this.isvcGroups.deleteGroup(poModel)
			.pipe(
				tap((pbResult: boolean) => {
					if (pbResult) {
						ArrayHelper.removeElement(this.documents, poModel);
						this.contactsByGroupId.delete(poModel._id);
						this.onFilteredDocumentsChanged(this.searchComponent.search());
					}
				})
			);
	}

	/** Permet de traquer l'identifiant du groupe affiché afin de rafraîchir l'affichage si celui-ci change.
	 * @param pnIndex Index du contact.
	 * @param poContact Groupe affiché.
	 */
	public trackById(pnIndex: number, poGroup: IGroup): string {
		return poGroup._id;
	}

	/** @override */
	public onFilteredDocumentsChanged(paChanges: T[]): void {
		super.onFilteredDocumentsChanged(this.filterByRoles(paChanges, this.maSelectedRoles));
	}

	public onRoleSelectionChanged(paRoles: IApplicationRole[], poSearchComponent: SearchComponent<T>): void {
		this.maSelectedRoles = paRoles;
		this.onFilteredDocumentsChanged(poSearchComponent.search());
	}

	private filterByRoles(paDocuments: T[], paRoles: IApplicationRole[]): T[] {
		return !ArrayHelper.hasElements(paRoles) ?
			paDocuments : paDocuments.filter((poDocument: T) => paRoles.every((poRole: IApplicationRole) => poDocument.roles?.includes(poRole.id)));
	}

	public getRolesNames(poGroup: IGroup): string {
		return ArrayHelper.hasElements(poGroup.roles) ?
			this.isvcPermissions.getPermissionRoles(poGroup.roles).map((poRole: IApplicationRole) => poRole.label).join(", ") : "";
	}

	/** @override */
	public sortDocuments(paDocuments: T[]): T[] {
		return super.sortDocuments(paDocuments).sort((poGroupA: T, poGroupB: T) => ArrayHelper.compareByExistingProperty(poGroupB, poGroupA, "_conflicts"));
	}

	//#endregion
}
