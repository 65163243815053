import { Component, Input, OnInit } from '@angular/core';
import { EUpdateMode } from 'apps/idl/src/modules/patients/model/eupdate-mode.enum';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { IUpdateHistory } from 'apps/idl/src/modules/patients/model/iupdate-history';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { PanneauService } from '../../../services/panneau.service';

@Component({
	selector: 'di-panneau-adresse-patient',
	templateUrl: './panneau-adresse-patient.component.html',
	styleUrls: ['./panneau-adresse-patient.component.scss'],
})
export class PanneauAdressePatientComponent implements OnInit {

	@Input()
	public patient: IPatient;

	public model: IPatient;

	constructor(private svcPatients: PatientsService, private svcPanneau: PanneauService) { }

	ngOnInit() { }

	public updatePatient = (patient: IPatient) => {

		this.model = patient;

		const loUpdateHistory: IUpdateHistory = {
			date: this.model.updateDate = new Date(),
			mode: this.model.updateMode = EUpdateMode.manual
		};
		this.model.updateHistory = [loUpdateHistory];
		this.svcPatients.savePatientAnakin(this.model).subscribe(() => {
			this.svcPatients.saveAdressCacheData(this.model);
			this.svcPanneau.close();
		})
	}

	// 	return of(this.model)
	// 		.pipe(
	// 			tap(() => {
	// 				const loUpdateHistory: IUpdateHistory = {
	// 					date: this.model.updateDate = new Date(),
	// 					mode: this.model.updateMode = EUpdateMode.manual
	// 				};
	// 				this.model.updateHistory = [loUpdateHistory];
	// 			}),
	// 			mergeMap(() => this.svcPatients.savePatientAnakin(this.model)),
	// 			tap(_ => this.svcPatients.saveAdressCacheData(this.model)),
	// 			tap(_ => this.svcPanneau.close())
	// 		).subscribe();
	// }

}
