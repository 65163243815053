import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, defaultIfEmpty, map, mergeMap } from 'rxjs/operators';
import { ArrayHelper } from '../helpers/arrayHelper';
import { EPrefix, UserData } from '../model';
import { IReadStatus } from '../model/read-status/IReadStatus';
import { EDatabaseRole } from '../model/store/EDatabaseRole';
import { IDataSource } from '../model/store/IDataSource';
import { IStoreDataResponse } from '../model/store/IStoreDataResponse';
import { IStoreDocument } from '../model/store/IStoreDocument';
import { Store } from './store.service';

@Injectable({
	providedIn: "root"
})
export class ReadStatusService {

	//#region METHODS

	constructor(private isvcStore: Store) { }

	/** Crée un objet de type `IReadStatus` à partir d'une donnée issue de la base de données.
	 * @param poData Donnée issue de la base de données avec laquelle créer un document de statut de lecture.
	 */
	private createReadStatus(poData: IStoreDocument): IReadStatus {
		return {
			_id: this.createReadStatusId(poData),
			dataRev: poData._rev
		} as IReadStatus;
	}

	/** Crée un identifiant pour un document de type `IReadStatus`.
	 * @param poData Donnée issue de la base de données ou identifiant de la donnée.
	 */
	private createReadStatusId(poData: IStoreDocument | string): string {
		return `${EPrefix.readStatus}${UserData.current._id}-${typeof poData === "string" ? poData : poData._id}`;
	}

	/** Récupère un document de staut de lecture associé à une donnée.
	 * @param poData Donnée dont on veut récupérer le statut de lecture.
	 */
	private getReadStatus(poData: IStoreDocument): Observable<IReadStatus> {
		const loDataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.userContext),
			viewParams: {
				key: this.createReadStatusId(poData),
				include_docs: true
			}
		};

		return this.isvcStore.getOne<IReadStatus>(loDataSource, false);
	}

	/** Récupère les statuts de lecture de données, de façon continue ou non.
	 * @param poData Préfixe ou tableau de documents issus de la base de données dont il faut récupérer les statuts de lecture.
	 * @param pbLive Indique si on doit faire une récupération continue des statuts de lecture.
	 */
	private getReadStatuses(poData?: EPrefix | IStoreDocument[], pbLive?: boolean): Observable<IReadStatus[]> {
		const loDataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.userContext),
			live: pbLive,
			viewParams: { include_docs: true }
		};

		if (typeof poData === "string") {
			const lsGeneratedId: string = this.createReadStatusId(poData);
			loDataSource.viewParams.startkey = `${lsGeneratedId}`;
			loDataSource.viewParams.endkey = `${lsGeneratedId}${Store.C_ANYTHING_CODE_ASCII}`;
		}
		else if (ArrayHelper.hasElements(poData))
			loDataSource.viewParams.keys = poData.map((poItem: IStoreDocument) => this.createReadStatusId(poItem));

		return this.isvcStore.get<IReadStatus>(loDataSource);
	}

	/** Extrait l'identifiant du document affilié au statut de lecture.
	 * @param poReadStatus Document de statut de lecture.
	 */
	private extractDataId(poReadStatus: IReadStatus): string {
		return ArrayHelper.getLastElement(/_(.*)-(\w*_.*)/.exec(poReadStatus._id));
	}

	/** Récupère les statuts de lecture par préfixe (ou tous si pas de préfixe de renseigné).
	 * @param pePrefix Préfixe dont il faut récupérer les statuts de lecture qui le possède, récupération générique par défaut.
	 * @param pbLive Indique si la récupération est continue ou non, `false` par défaut.
	 */
	public getReadStatusesById(pePrefix?: EPrefix, pbLive?: boolean): Observable<Map<string, IReadStatus>>;
	/** Récupère les statuts de lecture par tableau de documents (ou tous si tableau non renseigné/vide).
	 * @param paDocuments Tableau des documents dont il faut récupérer les statuts de lecture, récupération générique par défaut.
	 * @param pbLive Indique si la récupération est continue ou non, `false` par défaut.
	 */
	public getReadStatusesById(paDocuments?: IStoreDocument[], pbLive?: boolean): Observable<Map<string, IReadStatus>>;
	public getReadStatusesById(poData?: EPrefix | IStoreDocument[], pbLive?: boolean): Observable<Map<string, IReadStatus>> {
		return this.getReadStatuses(poData, pbLive)
			.pipe(
				map((paReadStatuses: IReadStatus[]) => {
					const loReadStatusesById = new Map<string, IReadStatus>();
					paReadStatuses.forEach((poReadStatus: IReadStatus) => loReadStatusesById.set(this.extractDataId(poReadStatus), poReadStatus));
					return loReadStatusesById;
				})
			);
	}

	/** Marque la donnée comme lue.
	 * @param poData Donnée qu'il faut marquer comme lue.
	 */
	public markAsRead(poData: IStoreDocument): Observable<boolean> {
		return this.getReadStatus(poData)
			.pipe(
				mergeMap((poActivity?: IReadStatus) => {
					let loActivity: IReadStatus;

					if (poActivity?.dataRev === poData._rev)
						return EMPTY;
					else if (poActivity && poActivity.dataRev !== poData._rev) {
						poActivity.dataRev = poData._rev;
						loActivity = poActivity;
					}
					else
						loActivity = this.createReadStatus(poData);

					// TODO : A modifier lorsque l'on saura gérer plusieurs db possibles.
					return this.isvcStore.put(loActivity, ArrayHelper.getLastElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.userContext)));
				}),
				map((poResponse: IStoreDataResponse) => poResponse.ok),
				defaultIfEmpty(false),
				catchError((poError: any) => { console.warn("RS.S:: Échec du marquage 'lu' : ", poError); return of(false); })
			);
	}

	/** Indique si la donnée est lue.
	 * @param poData Document dont il faut vérifier si son marqueur est noté comme 'lu'.
	 */
	public isDocumentRead(poData: IStoreDocument): Observable<boolean> {
		return this.getReadStatus(poData).pipe(map((poActivity: IReadStatus) => poActivity.dataRev === poData._rev));
	}

	//#endregion
}