import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SearchModule } from '../../components/search/search.module';
import { PipeModule } from '../../pipes/pipes.module';
import { CalaoAvatarModule } from '../avatar/calao-avatar.module';
import { ConflictsModule } from '../conflicts/conflicts.module';
import { GroupsListComponent } from './components/groups-list/groups-list.component';

const components: Type<any>[] = [
	GroupsListComponent
]

@NgModule({
	declarations: components,
	imports: [
		CommonModule,
		IonicModule,
		PipeModule,
		CalaoAvatarModule,
		SearchModule,
		ConflictsModule
	],
	exports: components
})
export class GroupsModule { }
