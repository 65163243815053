<!--* Copier-coller en grande partie du formlist parce qu'on hérite de ce-dernier ; on ajoute quelques trucs custom comme la recherche desmos. -->
<div class="dis-flex">
	<div class="title">
		<h5 *ngIf="title">{{ title }}</h5>
	</div>

	<!-- Zone de recherche. -->
	<div *ngIf="hasSearchbox" class="search-box txt-center form-list width100pc">
		<search #search class="search" [data]="documents" [options]="searchOptions"
			(filteredEntriesChanged)="onFilteredDocumentsChanged($event)" [autofocus]="autofocusSearchbox"
			[(searchValue)]="searchValue" (searchValueChange)="onSearchValueChanged()"></search>
		<osapp-selector *ngIf="selectOptions" displayMode="tags" [options]="selectOptions"
			(selectionChanged)="onGroupSelectionChanged($event, search)">
		</osapp-selector>
	</div>

	<ng-container *ngTemplateOutlet="showDesmosPatients ? desmosContent : databaseContent"></ng-container>
</div>

<!-- Template pour le contenu issu de la base de données. -->
<ng-template #databaseContent>
	<!-- Afficher s'il y a un chargement -->
	<ng-template #loadingSpinner>
		<div class="full-width">
			<osapp-spinner [message]="loadingText">
			</osapp-spinner>
		</div>
	</ng-template>

	<!-- Affichage de la liste. -->
	<div *ngIf="filteredDocuments.length !== 0; else emptyScreen;" class="width100pc">
		<ion-virtual-scroll class="dis-flex flex-col list" [items]="filteredDocuments" [trackBy]="trackById"
			osappAutoCloseSlideItem>
			<ion-item-sliding #itemSliding *virtualItem="let itemFormData"
				[class]="getItemCssClass ? getItemCssClass(itemFormData) : ''" (ionDrag)="onDrag($event, itemSliding)">
				<ion-item>
					<calao-conflict-list-marker *ngIf="itemFormData._conflicts"></calao-conflict-list-marker>
					<div class="click-container" (click)="onItemClicked(itemFormData)">

						<!-- Affichage de l'avatar si on a une image à afficher. -->
						<ng-container *ngIf="displayFields[0] as field">
							<div class="avatar-area">
								<ion-avatar *ngIf="field.isPicture" class="round-avatar align-avatar" slot="start">
									<ng-container *ngIf="itemFormData[field.key] as item; else displayNoPicture">
										<!-- Test pour savoir si le champ d'image possède une image à afficher ou s'il faut afficher celle par défaut.
								Est-ce que l'objet est renseigné et est-ce qu'il contient une base64 ou une url ou un guid valide (l'objet peut contenir un tableau d'images). -->
										<ng-container
											*ngIf="((!field.isArray && (item.url || item.base64 || item.guid)) ||
										(field.isArray && (item[0].url || item[0].base64 || item[0].guid))); then displayPicture; else displayNoPicture">
										</ng-container>
										<!-- Si une image doit être affichée. -->
										<ng-template #displayPicture>
											<!-- Affiche l'image du champ qui contient une base64 ou une URL dans le cas où on a une unique image. -->
											<avatar *ngIf="!field.isArray" [src]="getAvatar(item)" [cssClass]="field.cssClass"
												(srcError)="onImgError($event)"></avatar>
											<!-- Affiche la première image du champ qui contient une base64 ou une URL dans le cas où on a un tableau d'images. -->
											<avatar *ngIf="field.isArray" [src]="getAvatar(item[0])" slot="start" [cssClass]="field.cssClass"
												(srcError)="onImgError($event)">
											</avatar>
										</ng-template>
										<!-- Si aucune image n'est à afficher, on affiche celle par défaut. -->
									</ng-container>
									<ng-template #displayNoPicture>
										<ion-icon class="ico-ava" name="patient-outline"></ion-icon>
									</ng-template>
								</ion-avatar>
							</div>
						</ng-container>

						<!-- Affichage des textes. -->
						<ion-text class="text-container">
							<ion-label [ngClass]="displayFields[1]?.cssClass">
								{{ itemFormData[displayFields[1]?.key] | pattern: displayFields[1]?.key : itemFormData | isDate:
								C_IS_DATE_PATTERN }}<span *ngIf="itemFormData[displayFields[6]?.key]"
									[ngClass]="displayFields[6]?.cssClass">, {{
									itemFormData[displayFields[6]?.key] | pattern: displayFields[6]?.key : itemFormData | isDate:
									C_IS_DATE_PATTERN }}
								</span>
							</ion-label>
							<ion-label [ngClass]="displayFields[2]?.cssClass">
								{{ itemFormData[displayFields[2]?.key] | pattern: displayFields[2]?.key : itemFormData | isDate:
								C_IS_DATE_PATTERN }}
							</ion-label>
							<ion-note class="no-margin patient-abstract">
								<span *ngIf="displayFieldsByKey.get('maidenName') as maidenNameField"
									[ngClass]="maidenNameField.cssClass">
									{{ itemFormData[maidenNameField.key] ? (itemFormData[maidenNameField.key] + " - ") : "" }}
								</span>
								<span *ngIf="displayFieldsByKey.get('birthDate') as birthDateField" [ngClass]="birthDateField.cssClass">
									{{ itemFormData[birthDateField.key] | isDate: C_IS_DATE_PATTERN + " - " }}
								</span>
								<span *ngIf="displayFieldsByKey.get('socialNumber') as socialNumber" [ngClass]="socialNumber.cssClass">
									{{ getFormatedSocialNumber(itemFormData[socialNumber.key]) }}
								</span>
							</ion-note>
						</ion-text>
					</div>

					<!-- Bouton d'options. -->
					<ion-button class="itemb btn-more" fill="clear" slot="end"
						*ngIf="itemSlidingEnabled && (!canDisplayOptions || canDisplayOptions(itemFormData))"
						(click)="openOrCloseItemSliding(itemSliding, $event)">
						<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
					</ion-button>
				</ion-item>

				<!-- Options des items. -->
				<ion-item-options *ngIf="!canDisplayOptions || canDisplayOptions(itemFormData)" side="end">
					<div *ngFor="let option of itemOptions">
						<ng-container *ngIf="option.action === 'call'; else elseTemplate">
							<ion-button class="swipe-btn" [color]="option.color ? option.color : 'primary'"
								[href]="'tel:' + itemFormData[option.fieldName]">
								<ion-icon [name]="option.icon"></ion-icon>
								{{ option.label }}
							</ion-button>
						</ng-container>
						<ng-template #elseTemplate>
							<ion-button class="swipe-btn" [color]="option.color ? option.color : 'primary'"
								(click)="onItemOptionClicked(itemFormData, option.action, itemSliding, option)">
								<ion-icon [name]="option.icon"></ion-icon>
								{{ option.label }}
							</ion-button>
						</ng-template>
					</div>
				</ion-item-options>
			</ion-item-sliding>
		</ion-virtual-scroll>
	</div>

	<!-- Si le composant a terminé de s'initialiser et qu'il y a une barre de recherche et qu'elle n'a retourné aucun résultat. -->
	<ng-template #emptyScreen>
		<div class="empty-screen flex-col" *ngIf="!isLoading; else loadingSpinner;">
			<ng-container *ngTemplateOutlet="isSearching ? searchLoader : searchButton"></ng-container>
		</div>
	</ng-template>

	<!-- Si aucun élément, pas de searchbox et un message de liste vide est défini. -->
	<ion-label *ngIf="!isLoading && filteredDocuments.length === 0 && !hasSearchbox && emptyMessage" class="ion-margin">
		{{ emptyMessage }}
	</ion-label>
</ng-template>

<!-- Template pour le contenu issu de desmos. -->
<ng-template #desmosContent>
	<div *ngIf="desmosPatients.length > 0" class="width100pc">
		<ion-virtual-scroll class="dis-flex flex-col list" [items]="desmosPatients" [trackBy]="trackById"
			osappAutoCloseSlideItem>
			<ion-item *virtualItem="let patient">
				<div class="click-container" (click)="onDesmosItemClicked(patient)">
					<!-- Affichage des textes. -->
					<ion-text class="text-container center">
						<ion-label [ngClass]="displayFields[1]?.cssClass">{{ patient | contactName }}</ion-label>
						<ion-label [ngClass]="displayFields[2]?.cssClass">{{ patient.birthDate | date: "dd/MM/yyyy" }}</ion-label>
						<ion-label [ngClass]="displayFields[3]?.cssClass" class="no-margin">{{ patient.socialNumber }}</ion-label>
					</ion-text>
				</div>
			</ion-item>
		</ion-virtual-scroll>
	</div>
</ng-template>

<!-- Template de recherche en cours. -->
<ng-template #searchLoader>
	<ion-spinner></ion-spinner>
	<ion-label>Recherche sur desmos en cours ...</ion-label>
</ng-template>

<!-- Template pour lancer une recherche sur l'api. -->
<ng-template #searchButton>
	<span class="txt-message">
		<ion-note>Aucun résultat.</ion-note>
	</span>
	<idl-desmos-search-button *ngIf="!!searchValue && canUseDesmos" [searchValue]="searchValue"
		(patientsSearched)="onSearchWithDesmos($event)"></idl-desmos-search-button>
</ng-template>