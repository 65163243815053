import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalOptions, OverlayEventDetail } from '@ionic/core';
import { ArrayHelper } from '@osapp/helpers';
import { IdHelper } from '@osapp/helpers/idHelper';
import { IContactsSelectorParams } from '@osapp/model';
import { EPrefix } from '@osapp/model/EPrefix';
import { IIndexedArray } from '@osapp/model/IIndexedArray';
import { EContactsType } from '@osapp/model/contacts/EContactsType';
import { IContact } from '@osapp/model/contacts/IContact';
import { IGroup } from '@osapp/model/contacts/IGroup';
import { IGroupMember } from '@osapp/model/contacts/IGroupMember';
import { IHydratedGroupMember } from '@osapp/modules/groups/model/IHydratedGroupMember';
import { ContactsService } from '@osapp/services';
import { GroupsService } from '@osapp/services/groups.service';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { SelectSeanceStatusModalComponent } from '../components/selects/select-seance-status-modal.component';
import { ISeanceStatusTournee } from '../model/ISeanceStatusTournee';
import { ISelectSeanceStatusModalParams } from '../model/ISelectSeanceStatusModalParams';
import { ISelectSeanceStatusModalResponse } from '../model/ISelectSeanceStatusModalResponse';
import { ITourneeFilterOptions } from '../model/ITourneeFilterOptions';
import { ITourneeFilterValues } from '../model/ITourneeFilterValues';

type TourneeFilter = {
	options: ITourneeFilterOptions,
	values: ITourneeFilterValues
};

@Injectable()
export class TourneeFilterService {

	//#region FIELDS

	/** Libellé pour 'aucun intervenant'. */
	public static readonly C_NO_INTERVENANT_NAME = "Aucun intervenant";
	/** Sujet permettant de manipuler des options et des valeurs de filtrage entre composants. */
	private moFilterSubject = new BehaviorSubject<TourneeFilter>({ values: { filteredSeances: [], isGroupModeGroup: true }, options: null });

	//#endregion

	//#region METHODS

	constructor(
		private isvcContacts: ContactsService,
		private ioModalCtrl: ModalController,
		private isvcGroups: GroupsService
	) { }

	/** Permet d'écouter les changements des options de filtrage. */
	public getFilterOptionsAsObservable(): Observable<ITourneeFilterOptions> {
		return this.moFilterSubject.asObservable()
			.pipe(
				filter((poFilter: TourneeFilter) => !!poFilter.options),
				map((poFilter: TourneeFilter) => poFilter.options)
			);
	}

	/** Permet de lever un événement de changements des options de filtrage. */
	public raiseFilterOptions(poOptions: ITourneeFilterOptions): void {
		this.moFilterSubject.next({ options: poOptions, values: undefined });
	}

	/** Permet d'écouter les changements des valeurs de filtrage. */
	public getFilterValuesAsObservable(): Observable<ITourneeFilterValues> {
		return this.moFilterSubject.asObservable()
			.pipe(
				filter((poFilter: TourneeFilter) => !!poFilter.values),
				map((poFilter: TourneeFilter) => poFilter.values)
			);
	}

	/** Permet de lever un événement de changements des valeurs de filtrage. */
	public raiseFilterValues(poValues: ITourneeFilterValues): void {
		this.moFilterSubject.next({ values: poValues, options: undefined });
	}

	/** Ouvre un sélecteur d'intervenants et renvoie un tableau de ceux sélectionnés.
	 * @param paIntervenants Tableau des intervenants parmi lesquels il faut en choisr un ou plusieurs.
	 * @param paPreSelectedIntervenants Tableau des intervenants présélectionnés.
	 * @param pbAutoSelect Indique si on sélectionne automatiquement les intervenants s'il n'y en a qu'un seul ou non, `false` par défaut.
	 * @param peContactsType Type de contacts à afficher, `contactsAndGroups` par défaut.
	 * @param pnSelectionLimit Indique le nombre de selection possible, `aucune limite` par défaut.
	 */
	public selectIntervenants(paIntervenants: IHydratedGroupMember[], paPreSelectedIntervenants: IHydratedGroupMember[] = [],
		pbAutoSelect: boolean = false, peContactsType: EContactsType = EContactsType.contacts, pnSelectionLimit?: number): Observable<IHydratedGroupMember[]> {

		if (paIntervenants.length === 1 && pbAutoSelect)
			return of(paIntervenants);

		else {
			const loContactsSelectorParams: IContactsSelectorParams = {
				userContactVisible: true,
				hasSearchbox: true,
				preSelectedIds: paPreSelectedIntervenants.map((poItem: IHydratedGroupMember) => poItem.groupMember._id),
				selectionMinimum: 1,
				selectionLimit: pnSelectionLimit,
				contactsData: paIntervenants.filter((poIntervenant: IHydratedGroupMember) => IdHelper.hasPrefixId(poIntervenant.groupMember._id, EPrefix.contact)).map((poIntervenant: IHydratedGroupMember) => poIntervenant.groupMember as IContact),
				allSelectionButton: true,
				type: peContactsType,
				roles: [],
				groupsData: paIntervenants.filter((poIntervenant: IHydratedGroupMember) => IdHelper.hasPrefixId(poIntervenant.groupMember._id, EPrefix.group)).map((poIntervenant: IHydratedGroupMember) => poIntervenant.groupMember as IGroup)
			};

			const lsPageTitle: string = pnSelectionLimit === 1 ? "Sélectionner un intervenant" : "Sélectionner des intervenants";
			return this.isvcContacts.openContactsSelectorAsModal(loContactsSelectorParams, lsPageTitle)
				.pipe(
					mergeMap((paContacts: IGroupMember[]) => {
						if (ArrayHelper.hasElements(paContacts)) {
							const laContacts: IContact[] = [];
							const laGroups: IGroup[] = [];

							paContacts.forEach((poGroupMember: IGroupMember) => {
								if (IdHelper.hasPrefixId(poGroupMember._id, EPrefix.contact))
									laContacts.push(poGroupMember as IContact);
								else if (IdHelper.hasPrefixId(poGroupMember._id, EPrefix.group))
									laGroups.push(poGroupMember as IGroup);
							});

							return this.isvcGroups.getGroupContactsIds(laGroups)
								.pipe(
									map((poContactsIdsByGroupId: IIndexedArray<string[]>) =>
										laContacts.map((poContact: IGroupMember) => paIntervenants.find((poItem: IHydratedGroupMember) => poItem.groupMember._id === poContact._id))
											.concat(
												ArrayHelper.flat(Object.values(poContactsIdsByGroupId))
													.map((psId: string) => paIntervenants.find((poItem: IHydratedGroupMember) => poItem.groupMember._id === psId))
											)
									)
								);
						}
						else
							return of([]);
					})
				);
		}
	}

	/** Ouvre un sélecteur d'états de séances et renvoie un tableau de ceux sélectionnés.
	 * @param paSeanceStatusAvatars Tableau des états de séances parmi lesquels il faut en choisr un ou plusieurs.
	 * @param paPreSelectedSeanceStatusAvatars Tableau des états de séances présélectionnés.
	 */
	public openSeanceStatusesSelectionModal(paSeanceStatusAvatars: ISeanceStatusTournee[], paPreSelectedSeanceStatusAvatars: ISeanceStatusTournee[] = [])
		: Observable<ISeanceStatusTournee[]> {

		const loOptions: ModalOptions = {
			component: SelectSeanceStatusModalComponent,
			componentProps: {
				seanceStatuses: paSeanceStatusAvatars,
				preSeanceStatuses: paPreSelectedSeanceStatusAvatars
			} as ISelectSeanceStatusModalParams
		};

		return from(this.ioModalCtrl.create(loOptions))
			.pipe(
				tap((poModal: HTMLIonModalElement) => poModal.present()),
				mergeMap((poModal: HTMLIonModalElement) => poModal.onDidDismiss()),
				filter((poResponse: OverlayEventDetail<ISelectSeanceStatusModalResponse>) => !!poResponse.data),
				map((poResponse: OverlayEventDetail<ISelectSeanceStatusModalResponse>) => poResponse.data),
				map((poResponse: ISelectSeanceStatusModalResponse) => poResponse.selectedStatuses)
			);
	}

	//#endregion
}