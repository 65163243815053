<ion-grid class="pad-facturation-numbers">
	<ion-row *ngFor="let factNumber of fieldValue">
		<ion-col size="8">
			<div class="flex">
				<ion-input class="input" style="border: none;" readonly [(ngModel)]="factNumber.value" maxlength="8"
					diInputNoSpaces></ion-input>
			</div>
		</ion-col>
		<ion-col size="4">
			<div class="flex">
				<ion-label class="factu-key">Clé:</ion-label>
				<ion-input class="input" style="border: none;" readonly [(ngModel)]="factNumber.key" maxlength="1"
					diInputNoSpaces></ion-input>
			</div>
		</ion-col>
	</ion-row>
</ion-grid>