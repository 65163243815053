
import { Entity } from '@osapp/model/entities/Entity';
import { EAvatarSize } from '@osapp/model/picture/EAvatarSize';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { EntityBuilder } from '@osapp/services/EntityBuilder';
import { C_PREFIX_PATIENT } from 'apps/idl/src/app/app.constants';
import { C_PREFIX_ACT } from '../../app/app.constants';
import { Acte } from '../../model/Acte';

export class ActeEntityBuilder extends EntityBuilder<Acte>{
	constructor() {
		super(
			(psModelId: string) => RegExp(C_PREFIX_ACT).test(psModelId),
			(poActe: Acte) => new Entity(
				poActe,
				``,
				() => `${poActe.keyLetters} ${poActe.priceCoefficient} ${poActe.label}`
			),
			(psModelId: string) => "Actes",
			EDatabaseRole.contacts,
			C_PREFIX_PATIENT,
			() => ({
				size: EAvatarSize.medium,
				icon: "bandage"
			})
		);
	}
}