<div class="ctn">
	<ng-container *ngFor="let marker of markers">
		<div *ngIf="marker" class="marker just-flex flex-col flex-justify-center">
			<div *ngIf="marker.icon || marker.text" [ngClass]="marker.class" class="badge-area" [matTooltip]="marker.tooltip">
				<ion-icon *ngIf="marker.icon" [icon]="marker.icon"></ion-icon>
				<ion-note *ngIf="marker.text as text">{{ text }}</ion-note>
			</div>
			<ion-note *ngIf="marker.label as label">{{ label }}</ion-note>
		</div>
	</ng-container>
</div>