import { UserData } from '../model/application/UserData';
import { IContact } from '../model/contacts/IContact';
import { EPrefix } from '../model/EPrefix';
import { NoCurrentUserDataError } from '../model/errors/NoCurrentUserDataError';
import { ArrayHelper } from './arrayHelper';
import { IdHelper } from './idHelper';
import { StringHelper } from './stringHelper';

export abstract class UserHelper {

	//#region METHODS

	/** Retourne le guid de l'identifiant de l'utilisateur.
	 * @param psUserId Identifiant de l'utilisateur.
	 */
	public static getUserGuid(psUserId: string): string {
		return ArrayHelper.getLastElement(psUserId?.split("_"));
	}

	/** Retourne l'identifiant du contact de l'utilisateur courant. */
	public static getUserContactId(): string {
		if (UserData.current)
			return IdHelper.buildId(EPrefix.contact, UserHelper.getUserGuid(UserData.current.name));
		else
			throw new NoCurrentUserDataError();
	}

	/** Retourne `true` si le contact est un utilisateur, `false` sinon.
	 * @param poContact Contact dont il faut vérifier s'il est ou non un utilisateur.
	 */
	public static isUser<T extends IContact>(poContact?: T): boolean {
		return !StringHelper.isBlank(poContact?.userId);
	}

	/** Retourne `true` si le contact est le contact de l'utilisateur courant, `false` sinon.
	 * @param poContact Contact dont il faut vérifier s'il est l'utilisateur courant.
	 */
	public static isCurrentUserContact<T extends IContact>(poContact?: T): boolean {
		return poContact?.userId === UserData.current?.name;
	}

	//#endregion
}