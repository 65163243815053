import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { NumberHelper } from '@osapp/helpers/numberHelper';
import { ModalComponentBase } from '@osapp/modules/modal/model/ModalComponentBase';
import { PlatformService } from '@osapp/services/platform.service';
import { Observable } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ESecteur } from '../../../../../model/ESecteur';
import { IDeplacementByProfession } from '../../../../../model/IDeplacementByProfession';
import { IndemniteService } from '../../../../../services/indemnite.service';
import { Deplacement } from '../../../model/Deplacement';
import { EIndemniteType } from '../../../model/EIndemniteType';
import { IIKParams } from '../../../model/IIKParams';
import { IIKResponse } from '../../../model/IIKResponse';
import { ISectorDetails } from '../../../model/ISectorDetails';
import { Indemnite } from '../../../model/Indemnite';

@Component({
	templateUrl: './indemnite-kilometrique-modal.component.html',
	styleUrls: ['./indemnite-kilometrique-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndemniteKilometriqueModalComponent extends ModalComponentBase<IIKResponse> implements OnInit {

	//#region FIELDS

	/** Tableau des documents de déplacement par profession. */
	private maDeplacementsByProfession: IDeplacementByProfession[];

	//#endregion

	//#region PROPERTIES

	@Input() public params: IIKParams;

	public get ikPrice(): string {
		const lnIkPriceValue: number = NumberHelper.isValid(this.deplacement.manualPrice) ?
			this.deplacement.manualPrice : this.ikIndemnite?.price ?? 0;

		// On force la localité anglaise pour conserver une séparation par point et non virgule afin de pouvoir caster en nombre.
		return this.ioDecimalPipe.transform(lnIkPriceValue, "1.2-2", "en-US");
	}

	/** @implements */
	public deplacement: Deplacement;
	/** @implements */
	public sectorDetails: ISectorDetails[] = [];
	/** @implements */
	public selectedSectorDetails: ISectorDetails;
	/** @implements */
	public ikIndemnite: Indemnite;
	/** @implements */
	public ikDistance: number;

	//#endregion

	//#region METHODS

	constructor(
		private isvcIndemnite: IndemniteService,
		private ioDecimalPipe: DecimalPipe,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetectorRef);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		// Copie du déplacement du traitement pour ne pas l'impacter.
		this.deplacement = Deplacement.getInstanceFromData(this.params.deplacement);
		this.ikDistance = this.isvcIndemnite.calculateAbattementDistance(this.deplacement);

		this.init().subscribe();
	}

	public validate(): void {
		this.close({ indemnite: this.ikIndemnite, deplacement: this.deplacement } as IIKResponse);
	}

	/** Initialisation du composant (détails secteur, indemnité à manipuler). */
	private init(): Observable<void> {
		return this.isvcIndemnite.getDeplacementsByProfession(this.params.profession)
			.pipe(
				tap((paResults: IDeplacementByProfession[]) => {
					this.maDeplacementsByProfession = paResults;
					this.ikIndemnite = this.isvcIndemnite.createNewIndemnite(
						this.isvcIndemnite.getIndemniteIdFromSecteurType(ESecteur.Plaine),
						EIndemniteType.IK,
						this.maDeplacementsByProfession,
						this.deplacement
					);
				}),
				map((paResults: IDeplacementByProfession[]) => paResults.forEach((poItem: IDeplacementByProfession) => this.initSectorDetails(poItem))),
				tap(() => {
					this.onSelectionChanged(this.sectorDetails.find((poItem: ISectorDetails) => poItem.sectorType === this.deplacement.sectorType));
					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			);
	}

	private initSectorDetails(poItem: IDeplacementByProfession): void {
		// Identifiant sous la forme `IK-1-x` où x correspond au type de secteur.
		const loSecteurValueRegexResult: RegExpExecArray = /[0-9]+$/.exec(poItem.id);

		if (loSecteurValueRegexResult) {
			const leSectorValue: ESecteur = +(ArrayHelper.getFirstElement(loSecteurValueRegexResult));
			const lnDeplacementPrice: number = poItem.tarif[this.params.geoZone - 1];
			let lsSectorLabel: string;

			if (leSectorValue === ESecteur.Plaine)
				lsSectorLabel = "Plaine";
			else if (leSectorValue === ESecteur.Montagne)
				lsSectorLabel = "Montagne";
			else if (leSectorValue === ESecteur.PiedOuSki)
				lsSectorLabel = "Pied / Ski";
			else
				throw new Error("Type de secteur de déplacement non reconnu.");

			this.sectorDetails.push({
				icon: this.isvcIndemnite.getIconSector(leSectorValue),
				label: `${lsSectorLabel} (${+NumberHelper.round(lnDeplacementPrice, 2)}€/km)`,
				price: lnDeplacementPrice,
				sectorType: leSectorValue
			} as ISectorDetails);
		}
	}

	/** Change le secteur de déplacement sélectionné.
	 * @param poNewSelection Nouveau secteur de déplacement sélectionné.
	 */
	public onSelectionChanged(poNewSelection: ISectorDetails): void {
		this.selectedSectorDetails = poNewSelection;
		this.deplacement.sectorPrice = poNewSelection.price;
		this.ikIndemnite = this.isvcIndemnite.createNewIndemnite(
			this.isvcIndemnite.getIndemniteIdFromSecteurType(poNewSelection.sectorType),
			this.isvcIndemnite.getIndemniteTypeFromSecteurType(poNewSelection.sectorType),
			this.maDeplacementsByProfession,
			this.deplacement
		);
		this.updateIkDistance();
	}

	public onToggleManualPriceChanged(): void {
		if (!this.deplacement.isManualPrice) // On recalcule le prix de l'IK car on repasse en mode automatique.
			this.isvcIndemnite.setIndemnitePrice(this.deplacement, this.ikIndemnite, this.maDeplacementsByProfession);
	}

	public setIkPrice(poEvent: CustomEvent<{ value: string }>): void {
		// On remplace la virgule par un point pour convertir le nombre stringifié en nombre.
		this.isvcIndemnite.setIndemnitePrice(this.deplacement, this.ikIndemnite, this.maDeplacementsByProfession, this.deplacement.manualPrice = +poEvent.detail.value);
	}

	private updateIkPrice(): void {
		this.isvcIndemnite.setIndemnitePrice(this.deplacement, this.ikIndemnite, this.maDeplacementsByProfession);
	}

	public updateIkDistance(ev?): void {
		if (ev !== undefined) {
			if (ev.target!.value < 0) {
				ev.target!.value = 0;
				this.deplacement.distance = 0;
			} else if (ev.target!.value > 99) {
				ev.target!.value = 99;
				this.deplacement.distance = 99;
			}
		}
		this.ikDistance = this.isvcIndemnite.calculateAbattementDistance(this.deplacement);
		this.updateIkPrice();
		this.detectChanges();
	}

	//#endregion
}
