import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { ArrayHelper, DateHelper } from "@osapp/helpers";
import { EDuree } from "@osapp/modules/event-markers/models/eduree";
import { ERepetition } from "@osapp/modules/event-markers/models/erepetition";
import { IDayRepetition } from "@osapp/modules/event-markers/models/iday-repetition";
import { IRecurrence } from "@osapp/modules/event-markers/models/irecurrence";
import { Recurrence } from "@osapp/modules/event-markers/models/recurrence";
import { Acte } from "apps/idl/src/model/Acte";
import { EPlace } from "apps/idl/src/model/EPlace";
import { IActe } from "apps/idl/src/model/IActe";
import { EWeekRepetitionType } from "apps/idl/src/modules/actes/model/EWeekRepetitionType";
import { WeekRepetition } from "apps/idl/src/modules/actes/model/week-repetition";
import { EMoments } from "../../../shared/enums/EMoments";

@Component({
	selector: "di-prise-en-charge",
	templateUrl: "./prise-en-charge.component.html",
	styleUrls: ["./prise-en-charge.component.scss"],
})
export class PriseEnChargeComponent implements OnInit {

	@Input() acte?: IActe;
	@Input() index?: number;
	@Input() onSave: (acte: Acte, index: number) => void;
	@Input() onCancel: () => void;

	public model: Acte;

	public priseEnChargeCPAM = false;
	public aPartirDu: string = "";
	public lieu: EPlace[];
	public moments: EMoments[] = [];
	public selectionnerHeure: boolean = false;
	public horairePrecise: string = "";
	// public horaires: string[] = [''];
	public tousLesJours = false;
	public dimanchesEtJoursFeriesInclus = true;
	public jours: number[] = [];
	public selectionnerRepetition = false;
	public repetition: number = 1;
	public repetitionType: ERepetition | EWeekRepetitionType;
	public selectedDuree: EDuree;
	public dureeValue: number | string;

	public lieuOptions = [
		{ label: "Domicile", value: EPlace.home, iconName: "location_on" },
		{ label: "Cabinet", value: EPlace.center, iconName: "apartment" }
	]

	public momentOptions = [
		{ label: EMoments.MATIN, value: EMoments.MATIN },
		{ label: EMoments.APRES_MIDI, value: EMoments.APRES_MIDI },
		{ label: EMoments.SOIR, value: EMoments.SOIR },
		{ label: EMoments.NUIT, value: EMoments.NUIT }
	]

	public jourOptions = [];

	public repetitionOptions = Array.from({ length: 15 }, (_, index) => ({
		label: index + 1,
		value: index + 1
	}));

	public repetitionTypeOptions = [
		{ label: "Jour(s)", value: ERepetition.tsLesXJours },
		{ label: "Semaine(s)", value: EWeekRepetitionType.everyNWeek },
		// { label: "Mois", value: ERepetition.tsLesXMois }
	]

	public dureeOptions = [
		{ label: "jour(s)", value: EDuree.jour, type: "nombre" },
		{ label: "semaine(s)", value: EDuree.semaines, type: "nombre" },
		{ label: "mois(s)", value: EDuree.mois, type: "nombre" },
		{ label: "", value: EDuree.jusquADate, type: "date" },
	]

	constructor(private cdr: ChangeDetectorRef) { }

	ngOnInit() {
		if (this.acte)
			this.model = new Acte(this.acte)
		else
			this.model = new Acte();
		this.initForm()
	}

	initForm() {
		this.aPartirDu = this.acte.startDate ? DateHelper.toDateUrl(this.acte.startDate) : DateHelper.toDateUrl(new Date())
		this.priseEnChargeCPAM = this.acte.isAldExonerante || false
		this.lieu = [this.acte.place]
		this.selectedDuree = EDuree.jusquADate;
		this.dureeValue = DateHelper.toDateUrl(new Date());
		this.repetition = this.acte.weekRepetition?.value
		this.repetitionType = this.acte.weekRepetition?.type
		if (this.acte.weekRepetition?.type === EWeekRepetitionType.everyNWeek) {
			this.selectionnerRepetition = true;
		}
		this.initRecurrences()
		this.updateJourOptions()

		this.cdr.detectChanges()
	}

	initRecurrences() {
		if (this.acte.recurrences.length > 0) {
			const recurrence: Recurrence = new Recurrence(ArrayHelper.getFirstElement(this.acte.recurrences))
			this.dimanchesEtJoursFeriesInclus = recurrence.sundayAndPublicHolidays

			// Initialisation de l'input time pour une horaire de passage précise
			const horaires = recurrence.dayRepetitions.filter((repetition) => repetition.type === "hours-minutes");
			// horaires.forEach((horaire) => {
			// 	const hours = horaire.hours.toString().padStart(2, '0');
			// 	const minutes = horaire.minutes.toString().padStart(2, '0');
			// 	this.horaires.push(`${hours}:${minutes}`); 

			// })
			if (horaires.length > 0) {
				this.selectionnerHeure = true;
				this.cdr.detectChanges()
				const firstHoraire: IDayRepetition = ArrayHelper.getFirstElement(horaires);
				const hours = firstHoraire.hours.toString().padStart(2, '0');
				const minutes = firstHoraire.minutes.toString().padStart(2, '0');
				this.horairePrecise = `${hours}:${minutes}`;
			}

			this.moments = recurrence.getMoments()


			if(!(recurrence.repetitionType === ERepetition.tsLesXJours && recurrence.repetitionValue === 1) && (this.acte.weekRepetition.type === EWeekRepetitionType.everyNWeek || recurrence.repetitionType === ERepetition.tsLesXJours)){
				if(recurrence.repetitionType === ERepetition.tsLesXJours){
					this.showSelectionnerRepetition(Number(recurrence.repetitionValue), recurrence.repetitionType);
				}else{
					this.showSelectionnerRepetition(Number(this.acte.weekRepetition.value), this.acte.weekRepetition.type);
				}
			}

			
			if (recurrence.repetitionType === ERepetition.tsLesXJours && recurrence.repetitionValue === 1) {
				this.toggleTousLesJours(true);
			} 

			this.updateJourOptions();
			this.cdr.detectChanges()

			if (recurrence.repetitionType === ERepetition.jours && Array.isArray(recurrence.repetitionValue)) {
				recurrence.repetitionValue.forEach(element => {
					this.jours.push(element)
				});
			}

			this.selectedDuree = recurrence.durationType
			if (this.selectedDuree === EDuree.jusquADate) {
				this.dureeValue = DateHelper.toDateUrl(this.acte.recurrences[0].durationValue[0])
			} else {
				this.dureeValue = Number(recurrence.durationValue)
			}
		} else {
			this.toggleTousLesJours(true);
		}
	}

	togglePriseEnChargeCPAM() {
		this.priseEnChargeCPAM = !this.priseEnChargeCPAM
	}

	// ajouterHoraire() {
	// 	this.horaires.push(''); 
	// }

	showSelectionnerHeure() {
		this.selectionnerHeure = true;
		this.cdr.detectChanges()
	}

	updateJourOptions() {
		const isDisabled = this.tousLesJours
			|| (this.selectionnerRepetition
				&& this.repetitionType !== EWeekRepetitionType.everyNWeek
				&& this.repetitionType !== EWeekRepetitionType.everyWeek)
		this.jourOptions = [
			{ label: "L", value: 1, disabled: isDisabled },
			{ label: "M", value: 2, disabled: isDisabled },
			{ label: "M", value: 3, disabled: isDisabled },
			{ label: "J", value: 4, disabled: isDisabled },
			{ label: "V", value: 5, disabled: isDisabled },
			{ label: "S", value: 6, disabled: isDisabled },
			{ label: "D", value: 0, disabled: isDisabled }
		];
	}

	showSelectionnerRepetition(repetition?: number, repetitionType?: EWeekRepetitionType | ERepetition) {
		this.selectionnerRepetition = true;
		this.tousLesJours = false;
		this.repetition = repetition ?? 1
		this.repetitionType = repetitionType ?? EWeekRepetitionType.everyNWeek
		this.updateJourOptions()
	}

	onRepetitionChange() {
		this.updateJourOptions();
	}

	toggleTousLesJours(tousLesJours: boolean) {
		this.tousLesJours = tousLesJours;
		this.selectionnerRepetition = false;
		if(tousLesJours){
			this.repetitionType = ERepetition.tsLesXJours
			this.repetition = 1
		}else{
			this.repetitionType = ERepetition.jours
		}		
		this.updateJourOptions()
	}

	toggleDimancheJoursFeriesInclus(dimancheJoursFeries: boolean) {
		this.dimanchesEtJoursFeriesInclus = dimancheJoursFeries;
	}

	selectDuree(duree: EDuree) {
		this.selectedDuree = duree;
		if (this.selectedDuree === EDuree.jusquADate) {
			this.dureeValue = DateHelper.toDateUrl(new Date())
		} else {
			this.dureeValue = 1
		}
	}

	getDureeClass(duree: EDuree) {
		return {
			'radio-field-disabled': this.selectedDuree !== duree,
			'duree-input': true
		}
	}

	public isFormValid(): boolean {
		// TODO : afficher les messages d'erreur
		let valid = true;

		// Date 'à partir du' obligatoire
		if (isNaN(new Date(this.aPartirDu).getDate())) {
			valid = false;
		}

		// Lieu obligatoire
		if (this.lieu.length === 0) {
			valid = false;
		}

		// 'Tous les jours' ou au moins un jour de la semaine doit être sélectionné
		if (!this.tousLesJours && this.jours.length === 0 && this.repetitionType !== ERepetition.tsLesXJours) {
			valid = false;
		}

		// Le type de durée doit être sélectionnée et une valeur pour la durée doit être saisie
		if (!this.selectedDuree || !this.dureeValue) {
			valid = false;
		} else {
			// Si on sélection 'jusqu'au' alors une date valide doit être sélectionnée
			if (this.selectedDuree === EDuree.jusquADate) {
				if (isNaN(new Date(this.dureeValue).getDate())) {
					valid = false;
				} else {
					// La date 'jusqu'au' ne peut pas être inférieur à la date 'à partir du'
					if (new Date(this.aPartirDu) > new Date(this.dureeValue)) {
						valid = false
					}
				}
			}
		}
		return valid;
	}

	public savePriseEnCharge(): void {
		if (this.isFormValid()) {
			// Initialiser startDate avec la date actuelle
			const startDate = new Date(this.aPartirDu);

			// Définir les propriétés de base du modèle
			this.model.startDate = startDate;
			this.model.isAldExonerante = this.priseEnChargeCPAM;
			this.model.place = this.lieu[0];
			this.model.sundayAndPublicHolidays = this.dimanchesEtJoursFeriesInclus;

			let dayRepetitions: IDayRepetition[] = [];
			this.moments.forEach((moment: EMoments) => {
				dayRepetitions.push(Recurrence.getRepetition(moment));
			});

			if (this.selectionnerHeure && this.horairePrecise) {
				dayRepetitions.push({
					type: "hours-minutes",
					hours: this.horairePrecise.split(":")[0],
					minutes: this.horairePrecise.split(":")[1]
				});
			}

			// Mise à jour du startDate si répétition en "range" est présente
			// const rangeRepetition = dayRepetitions.find(repetition => repetition.type === "range");
			// if (rangeRepetition) {
			// 	startDate.setHours(rangeRepetition.to.hours, rangeRepetition.to.minutes);
			// 	this.model.startDate = startDate;
			// }

			let durationValue: number | Date[] = !isNaN(Number(this.dureeValue)) ? Number(this.dureeValue) : [DateHelper.parseReverseDate(String(this.dureeValue).replace(/-/g, ''))];

			let recurrence: IRecurrence = {
				sundayAndPublicHolidays: this.dimanchesEtJoursFeriesInclus,
				dayRepetitions: dayRepetitions,
				durationType: this.selectedDuree,
				durationValue: durationValue,
			};


			if (this.repetitionType === EWeekRepetitionType.everyNWeek) {
				this.model.weekRepetition = new WeekRepetition({
					type: this.repetitionType,
					...(this.repetitionType === EWeekRepetitionType.everyNWeek && { value: this.repetition })
				});
				recurrence = {
					...recurrence,
					repetitionType: ERepetition.jours,
					repetitionValue: this.jours,
				};
			} else {
				this.model.weekRepetition = new WeekRepetition({
					type: EWeekRepetitionType.everyWeek
				});
				if (this.repetitionType !== ERepetition.jours && this.repetitionType !== EWeekRepetitionType.everyWeek) {
					recurrence = {
						...recurrence,
						repetitionType: this.repetitionType as ERepetition,
						repetitionValue: this.repetition,
					};
				} else {
					if (this.tousLesJours) {
						recurrence = {
							...recurrence,
							repetitionType: ERepetition.tsLesXJours,
							repetitionValue: 1,
						};
					} else {
						recurrence = {
							...recurrence,
							repetitionType: ERepetition.jours,
							repetitionValue: this.jours,
						};
					}
				}
			}

			let modelRecurrence: Recurrence = new Recurrence(recurrence);
			modelRecurrence.durationValue = durationValue;

			let recurrences: Recurrence[] = [modelRecurrence];

			this.model.recurrences = recurrences;

			// this.model.moment = [...(this.moments || []), this.horairePrecise].filter(Boolean);

			this.onSave(this.model, this.index);
		}
	}

	public cancelPriseEnCharge(): void {
		this.onCancel();
	}

}
