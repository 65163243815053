<div class="di-liste-facture__container">
	<div *ngIf="mode === 'edit'">
		<div *ngIf="factures.length > 0">
			<ng-container *ngFor="let facture of factures">
				<div class="di-liste-facture__item">
					<p>Facture n° {{facture.invoiceNumber}}</p>
					<lua-bouton-icone *ngIf="facture.securisationMode === 'DEGRADE' || facture.securisationMode === 'PAPIER'"
						iconName="task" mode="filled" (onClick)="handelOpenFsp(facture)"></lua-bouton-icone>
					<lua-bouton-icone iconName="request_page" mode="outlined"
						(onClick)="handelOpenFacture(facture)"></lua-bouton-icone>
				</div>
			</ng-container>
		</div>
	</div>
	<ng-container *ngIf="factures.length === 0">
		<lua-message-aucune-donnee mainText="Aucune facture" iconName="contract" buttonLabel="Retour"
			(onClick)="goBack($event)"></lua-message-aucune-donnee>
	</ng-container>
</div>