export enum EGarantie {
	"TXFRONT" = "TXFRONT",
	"90SAUF" = "90SAUF",
	"NONEXO" = "NONEXO",
	"100SAUF" = "100SAUF",
	"100MAT" = "100MAT",
	"80SAUF" = "80SAUF",
	"75MED" = "75MED",
	"100ALD" = "100ALD",
	"TXALMO" = "TXALMO",
	"100SNCF" = "100SNCF",
	"100SAUFACC" = "100SAUFACC",
	"NONEXOACC" = "NONEXOACC",
	"100TOUT" = "100TOUT",
	"AUTRNONEXO" = "AUTRNONEXO",
	"AUTREGENE" = "AUTREGENE",
	"100TRANS" = "100TRANS",
	"AUTREACC" = "AUTREACC",
	"75TOUT" = "75TOUT",
	"100ALDACC" = "100ALDACC",
	"100ALMO" = "100ALMO",
	"TXFSV" = "TXFSV",
	"REDUIT" = "REDUIT",
	"PASEXO" = "PASEXO"
}
