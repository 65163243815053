import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, takeWhile, tap } from 'rxjs/operators';
import { EFormEventType } from '../../../../model/forms/EFormEventType';
import { FieldBase } from '../../../../model/forms/FieldBase';
import { IFormEvent } from '../../../../model/forms/IFormEvent';
import { IGalleryFieldParams } from '../../../../model/forms/IGalleryFieldParams';
import { EGalleryCommand } from '../../../../model/gallery/EGalleryCommand';
import { IGalleryCommand } from '../../../../model/gallery/IGalleryCommand';
import { IGalleryFile } from '../../../../model/gallery/IGalleryFile';
import { FormsService } from '../../../../services/forms.service';

@Component({
	templateUrl: './galleryField.component.html',
})
export class GalleryFieldComponent extends FieldBase<IGalleryFile[]> implements OnInit, OnDestroy {

	//#region FIELDS

	private readonly moGalleryCommandSubject = new Subject<IGalleryCommand>();

	//#endregion

	//#region PROPERTIES

	public readonly galleryCommand$: Observable<IGalleryCommand>;

	public files: IGalleryFile[];
	/** Paramètres que l'on peut fournir à ce composant. */
	public params: IGalleryFieldParams;

	//#endregion

	//#region METHODS

	constructor(psvcForms: FormsService, poChangeDetector: ChangeDetectorRef) {
		super(psvcForms, poChangeDetector);
		this.galleryCommand$ = this.moGalleryCommandSubject.asObservable();
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this.params = this.to.data;

		if (!this.fieldValue)
			this.fieldValue = [];

		this.files = this.fieldValue;

		this.isvcForms.waitFormEvent(this.model._id, EFormEventType.beforeSubmit)
			.pipe(
				tap(_ => this.fieldValue = this.files.map((poFile: IGalleryFile) => this.createSavedFile(poFile))),
				takeWhile(_ => !this.params.readOnly),
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();

		// Si l'on passe du mode edit au mode visu avec enregistrement, la gallery est mise à jour.
		this.isvcForms.waitFormEvent(this.model._id, EFormEventType.afterSubmit)
			.pipe(
				tap((poEvent: IFormEvent) => {
					if (!this.params.readOnly)
						this.moGalleryCommandSubject.next({ type: EGalleryCommand.saveFiles });
					else {
						this.form.patchValue(poEvent.data.model);
						this.files = this.fieldValue;
					}
				}),
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moGalleryCommandSubject.complete();
	}

	/** Construit la donnée qui sera disponible dans les forms entries dans la base de données.
	 * @param poFile Fichier ajouté au DMS.
	 */
	private createSavedFile(poFile: IGalleryFile): IGalleryFile {
		const loSavedFile: IGalleryFile = { guid: poFile.guid, name: poFile.name, file: undefined };

		//! Rétrocompatibilité: Permet d'ajouter des champs lus par d'anciennes applications.
		if (this.params.labelFieldsName)
			this.params.labelFieldsName.forEach((labelFieldName: string) => { loSavedFile[labelFieldName] = poFile.name; });
		if (this.params.guidFieldsName)
			this.params.guidFieldsName.forEach((guidFieldName: string) => { loSavedFile[guidFieldName] = poFile.guid; });

		return loSavedFile;
	}

	public onFilesChanged(): void {
		this.markAsDirty();
	}

	//#endregion
}