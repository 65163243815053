import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";


@Injectable({
	providedIn: "root"
})
export class ErrorService {

	private errorConnexionSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

	constructor() {
	}

	public getErrorConnexion(): Observable<string | null> {
		return this.errorConnexionSubject.asObservable();
	}

	public setErrorConnexion(error: string | null): void {
		this.errorConnexionSubject.next(error);
	}
}