<ng-container calaoHeader [title]="title" [hasSyncButton]="true"></ng-container>

<ion-content>
	<idl-constantes-details [model]="surveillances" [readOnly]="isReadOnly" [patient]="patientModel">
	</idl-constantes-details>
</ion-content>

<ion-fab *ngIf="!isReadOnly" vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
	<ion-fab-button color="success" (click)="saveConstantes()" class="fab-shadow" title="Sauvegarder">
		<ion-icon name="checkmark"></ion-icon>
	</ion-fab-button>
</ion-fab>