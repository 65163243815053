import { coerceArray } from '@angular/cdk/coercion';
import { Injectable } from '@angular/core';
import { from, Observable, of, Subject, throwError } from 'rxjs';
import { distinctUntilChanged, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { ObjectHelper } from '../../../helpers/objectHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { UserHelper } from '../../../helpers/user.helper';
import { UserData } from '../../../model';
import { EApplicationEventType } from '../../../model/application/EApplicationEventType';
import { ConfigData } from '../../../model/config/ConfigData';
import { EConfigFlag } from '../../../model/config/EConfigFlag';
import { IGroup } from '../../../model/contacts/IGroup';
import { EPrefix } from '../../../model/EPrefix';
import { IFlag } from '../../../model/flag/IFlag';
import { PermissionMissingError } from '../../../model/security/errors/PermissionMissingError';
import { EStoreFlag } from '../../../model/store/EStoreFlag';
import { EntityLinkService } from '../../../services/entityLink.service';
import { FlagService } from '../../../services/flag.service';
import { InjectorService } from '../../../services/injector.service';
import { LoadingService } from '../../../services/loading.service';
import { IApplicationRole } from '../../../services/security/IApplicationRole';
import { Loader } from '../../loading/Loader';
import { EPermission } from '../models/EPermission';
import { EPermissionsFlag } from '../models/EPermissionsFlag';
import { IPermissionEvent } from '../models/IPermissionEvent';
import { IPermissionSet } from '../models/IPermissionSet';
import { IScopePermissions } from '../models/IScopePermissions';

export const C_ADMINISTRATOR_TAG = "administrator";

/** Cache de permissions, susceptible d'être renouvelé durant la vie de l'application. */
interface IPermissionsCache {
	permissions: IPermissionSet;
}

//#region DECORATORS

export type CRUDPermissions = "create" | "read" | "edit" | "delete";
type IPermissionsAccessorDescriptor = TypedPropertyDescriptor<boolean>;
type IPermissionsMethodDescriptor = TypedPropertyDescriptor<any>;

/** Ajoute la vérification d'une permission à un accesseur booleen.
 * @param psPermission
 */
export function Permissions(psPermission: CRUDPermissions, poPermissionScope?: EPermission | EPermission[]):
	(poTarget: IHasPermission, psMethodName: string, poDescriptor: IPermissionsAccessorDescriptor) => IPermissionsAccessorDescriptor;
/** Ajoute la vérification d'une permission à un accesseur booleen.
 * @param psPermission
 */
export function Permissions(psPermission: string, poPermissionScope?: EPermission | EPermission[]):
	(poTarget: IHasPermission, psMethodName: string, poDescriptor: IPermissionsAccessorDescriptor) => IPermissionsAccessorDescriptor;
export function Permissions(psPermission: CRUDPermissions | string, poPermissionScope?: EPermission | EPermission[]):
	(poTarget: IHasPermission, psMethodName: string, poDescriptor: IPermissionsAccessorDescriptor) => IPermissionsAccessorDescriptor {

	return function (poTarget: IHasPermission, psMethodName: string, poDescriptor: IPermissionsAccessorDescriptor): IPermissionsAccessorDescriptor {
		const lfOriginalMethod: () => boolean = poDescriptor.get; // On sauvegarde l'ancienne implémentation du getter.

		poDescriptor.get = function (): boolean {
			const loTarget: IHasPermission = this; // Représente la classe qui appelle le décorateur.

			return lfOriginalMethod.apply(loTarget, arguments) && // On appelle l'ancien getter en le mettant dans le contexte de l'appelant.
				loTarget.isvcPermissions.evaluatePermission(poPermissionScope ?? loTarget.permissionScope, psPermission);
		};

		return poDescriptor;
	};
};

export function Roles(...paRoles: string[]):
	(poTarget: any, psMethodName: string, poDescriptor: IPermissionsAccessorDescriptor) => IPermissionsAccessorDescriptor {

	return function (poTarget: any, psMethodName: string, poDescriptor: IPermissionsAccessorDescriptor): IPermissionsAccessorDescriptor {
		const lfOriginalMethod: () => boolean = poDescriptor.get; // On sauvegarde l'ancienne implémentation du getter.

		poDescriptor.get = function (): boolean {
			const loTarget: any = this; // Représente la classe qui appelle le décorateur.
			const lsvcPermission: PermissionsService = InjectorService.instance?.get(PermissionsService);

			return lfOriginalMethod.apply(loTarget, arguments) && // On appelle l'ancien getter en le mettant dans le contexte de l'appelant.
				paRoles.some((psRole: string) => lsvcPermission?.hasRole(psRole));
		};

		return poDescriptor;
	};
};

/** Ajoute la vérification d'une permission à une méthode.
 * @param psPermission Nom de la permission ou de la propriété représentant la permission.
 */
export function CanExecute(psPermission: CRUDPermissions, poPermissionScope?: EPermission | EPermission[]):
	(poTarget: IHasPermission, psMethodName: string, poDescriptor: IPermissionsMethodDescriptor) => IPermissionsMethodDescriptor;
/** Ajoute la vérification d'une permission à une méthode.
* @param psPermission Nom de la permission ou de la propriété représentant la permission.
*/
export function CanExecute(psPermission: string, poPermissionScope?: EPermission | EPermission[]):
	(poTarget: IHasPermission, psMethodName: string, poDescriptor: IPermissionsMethodDescriptor) => IPermissionsMethodDescriptor;
/** Ajoute la vérification d'une permission à une méthode.
 * @param psPermission Nom de la permission ou de la propriété représentant la permission.
 */
export function CanExecute(psPermission: CRUDPermissions | string, poPermissionScope?: EPermission | EPermission[]):
	(poTarget: IHasPermission, psMethodName: string, poDescriptor: IPermissionsMethodDescriptor) => IPermissionsMethodDescriptor {

	return function (poTarget: IHasPermission, psMethodName: string, poDescriptor: IPermissionsMethodDescriptor): IPermissionsMethodDescriptor {
		const lfOriginalMethod: Function = poDescriptor.value; // On sauvegarde l'ancienne implémentation du getter.

		poDescriptor.value = function (): any {
			const loTarget: IHasPermission = this; // Représente la classe qui appelle le décorateur.

			if ((loTarget[psPermission] === undefined && loTarget.isvcPermissions.evaluatePermission(poPermissionScope ?? loTarget.permissionScope, psPermission)) ||
				loTarget[psPermission]) {
				return lfOriginalMethod.apply(loTarget, arguments); // On appelle l'ancienne méthode en la mettant dans le contexte de l'appelant.
			}
			else {
				console.error(`CanExecute::Impossible d'exécuter cette action, vous n'avez pas les droits.`);
				return () => { }; // On ne lance rien car on n'a pas les droits.
			}
		};

		return poDescriptor;
	};
};

//#endregion

export interface IHasPermission {
	permissionScope?: EPermission | EPermission[];
	isvcPermissions: PermissionsService;
}

export const C_ADMINISTRATORS_ROLE_ID = "administrators";
export const C_SECTORS_ROLE_ID = "sectors";

/** Gestion des permissions de l'application en fonction de l'utilisateur actif. */
@Injectable({ providedIn: "root" })
export class PermissionsService {

	//#region FIELDS

	private static readonly C_LOG_ID = "PERM.S::";

	private readonly moEventSubject = new Subject<IPermissionEvent>();
	private readonly permissionsCache: IPermissionsCache = { permissions: null };

	private maUserRoles: IApplicationRole[] = [];
	/** Indique si les permissions par défaut sont en cours d'utilisation ou non. */
	private mbAreDefaultPermissions = true;

	private isAdmin = false;

	//#endregion

	//#region PROPERTIES

	/** Détermine si le contrôle de permissions est activé pour l'application. */
	public get hasPermissions(): boolean {
		return !!ConfigData.security;
	}

	public get permissionsEvent$(): Observable<IPermissionEvent> {
		return this.moEventSubject.asObservable();
	}

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcEntityLink: EntityLinkService,
		private readonly isvcLoading: LoadingService,
		psvcFlag: FlagService
	) {
		// Initialisation des permissions par défaut.
		psvcFlag.waitForFlag(EConfigFlag.StaticConfigReady, true)
			.pipe(tap(_ => this.initDefaultPermissions()))
			.subscribe();

		// Initialisation des permissions utilisateurs pour surcharger celles par défaut.
		psvcFlag.observeFlag(EStoreFlag.DBInitialized)
			.pipe(
				distinctUntilChanged(),
				mergeMap((poFlag: IFlag) => poFlag.value ? this.initPermissions() : this.resetPermissions())
			)
			.subscribe();
	}

	/** Initialise les permissions par défaut de l'app. */
	private initDefaultPermissions(): void {
		this.permissionsCache.permissions = ObjectHelper.clone(ConfigData.security?.permissions.default || ({} as IPermissionSet));
		this.mbAreDefaultPermissions = true;
	}

	/** Réinitialise les permissions avec celles par défaut. */
	private resetPermissions(): Observable<boolean> {
		this.initDefaultPermissions();
		this.raisePermissionsLoadedEvent(false);

		return of(true);
	}

	private initPermissions(): Observable<boolean> {

		if (UserData.current?.versionAppErgo === "2") {
			return from(this.loadUserPermissionsCache())
				.pipe(
					mergeMap(_ => this.loadUserPermissionsCache()),
					tap(_ => this.raisePermissionsLoadedEvent(true))
				);
		}

		let loLoader: Loader;
		return from(this.isvcLoading.create("Chargement des <br/>permissions ..."))
			.pipe(
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(_ => this.loadUserPermissionsCache()),
				mergeMap(_ => {
					if (!loLoader.isDismissed)
						return loLoader.dismiss();
					return of(null);
				}),
				tap(_ => this.raisePermissionsLoadedEvent(true)),
				finalize(() => {
					if (!loLoader.isDismissed)
						loLoader.dismiss();
				})
			);
	}

	private raisePermissionsLoadedEvent(pbIsLoaded: boolean): void {
		this.moEventSubject.next({
			createDate: new Date,
			type: EApplicationEventType.permissionsEvent,
			data: {},
			alteredFlags: [{ key: EPermissionsFlag.isLoaded, value: pbIsLoaded }]
		});
	}

	/** Charge le jeu de permissions de l'utilisateur actif en fonction du paramétrage de sécurité.
	 * @returns `true` si les permissions de l'utilisateur ont été chargées, `false` si le contrôle de permissions est désactivé.
	 */
	private loadUserPermissionsCache(): Observable<boolean> {
		if (!this.hasPermissions)
			return of(false);
		else
			return this.getUserPermissionSet(true).pipe(map((poPermissionSet: IPermissionSet) => !!poPermissionSet));
	}

	/** Renvoie le jeu de permissions de l'utilisateur actif (chargé au 1er appel, puis mis en cache pour les appels suivants).
	 * @param pbLive
	 * @returns Le jeu de permissions de l'utilisateur.
	 * @throws {PermissionMissingError} Si le contrôle des permissions n'est pas activé pour l'application, une erreur est levée :
	 * un appel préalable à `hasPermissions` est recommandé.
	 */
	private getUserPermissionSet(pbLive?: boolean): Observable<IPermissionSet> {
		if (!this.hasPermissions)
			return throwError(new PermissionMissingError("Permission check was requested but no permissions settings were provided for current application."));
		else {
			return this.permissionsCache.permissions && !this.mbAreDefaultPermissions ?
				of(this.permissionsCache.permissions) : this.innerLoadUserPermissionsCache(pbLive);
		}
	}


	setUserPermissionAdmin(): void {
		this.isAdmin = true;
	}

	/** Charge le jeu de permissions de l'utilisateur actif en fonction du paramétrage de sécurité. */
	private innerLoadUserPermissionsCache(pbLive?: boolean): Observable<IPermissionSet> {

		return this.isvcEntityLink.getLinkedEntities(UserHelper.getUserContactId(), [EPrefix.group], pbLive)
			.pipe(
				mergeMap((paResults: IGroup[]) => {
					const laTags: string[] = ArrayHelper.unique(ArrayHelper.flat(paResults.map((poGroup: IGroup) => poGroup.roles ?? [])));
					//Applique les permissions par défaut
					this.permissionsCache.permissions = { contacts: null };
					const laPermissions: IPermissionSet[] = [ObjectHelper.clone(ConfigData.security.permissions.default)];

					laTags.forEach((psTag: string) => {
						const loPermission: IPermissionSet = ConfigData.security.permissions[psTag];
						if (loPermission)
							laPermissions.push(loPermission);
					});

					if (this.isAdmin)
						laPermissions.push(ConfigData.security.permissions["administrators"]);


					Object.assign(this.permissionsCache.permissions, this.mergePermissions(laPermissions));

					const laUserTags: IApplicationRole[] = [];

					for (const lsKey in ConfigData.security.builtInRoles) {
						const loTag: IApplicationRole = ConfigData.security.builtInRoles[lsKey];
						if (loTag && laTags.includes(loTag.id))
							laUserTags.push(loTag);
					}
					if (this.isAdmin)
						laUserTags.push({ id: "administrators", label: "administrators" });

					this.maUserRoles = laUserTags;

					return of(this.permissionsCache.permissions);
				}),
				tap(_ => this.mbAreDefaultPermissions = false)
			);
	}

	/** Retourne `true` si la permission passée en paramètre est valide. C'est-à-dire si elle est settée, que sa valeur n'est pas `false` et, si c'est un `string`,
	 * si sa valeur est bien présente dans l'arborescence des permissions.
	 * @param poPermissionData Permission ou tableau de permissions (si tableau il y a, parcours en profondeur il y aura).
	 * @param psPermissionType Type de permission comme `create` | `delete` | `edit` | `read`.
	 * @throws Erreur en cas de cache non rempli alors qu'on est censé avoir des permissions.
	 */
	public evaluatePermission(poPermissionData: EPermission | EPermission[], psPermissionType: CRUDPermissions): boolean;
	/** Retourne `true` si la permission passée en paramètre est valide. C'est-à-dire si elle est settée, que sa valeur n'est pas `false` et, si c'est un `string`,
	 * si sa valeur est bien présente dans l'arborescence des permissions.
	 * @param poPermissionData Permission ou tableau de permissions (si tableau il y a, parcours en profondeur il y aura).
	 * @param psPermissionType Type de permission comme `create` | `delete` | `edit` | `read`.
	 * @throws Erreur en cas de cache non rempli alors qu'on est censé avoir des permissions.
	 */
	public evaluatePermission(poPermissionData: EPermission | EPermission[], psPermissionType: string): boolean;
	public evaluatePermission(poPermissionData: EPermission | EPermission[], psPermissionType: CRUDPermissions | string): boolean {
		if (!this.hasPermissions || StringHelper.isBlank(psPermissionType) || !poPermissionData)
			return true;
		else if (!this.permissionsCache.permissions) // Si les permissions ne sont pas en caches mais qu'elles le devraient.
			throw new Error(`PERM.S::Permission check from cache has been called without having loaded the permissions cache.`);
		else {
			const laPermissionKeys: EPermission[] = coerceArray(poPermissionData);
			const leFirstPermission: EPermission = ArrayHelper.getFirstElement(laPermissionKeys);
			let loParentPermission: IScopePermissions;
			const loChildPermission: IScopePermissions = this.permissionsCache.permissions[leFirstPermission];
			let lbResult: boolean;

			if (laPermissionKeys.length === 1) // Un seul élément, pas de parcours à faire on renvoie directement le résultat.
				lbResult = this.innerEvaluatePermission(leFirstPermission, psPermissionType, loChildPermission);
			else
				lbResult = this.pathEvaluatePermission(laPermissionKeys, loChildPermission, loParentPermission, psPermissionType);

			// Si on utilise les permissions par défaut et qu'on n'est pas en mode invité, log d'avertissement.
			if (this.mbAreDefaultPermissions && !UserData.current?.isGuest)
				console.warn(`${PermissionsService.C_LOG_ID}Utilisation permissions par défaut !`);

			return lbResult;
		}
	}

	/** Parcourt récursivement les permissions pour évaluer celle qui nous intéresse et retourne le résultat de l'évaluation de cette permission.
	 * @param paPermissionKeys Tableau des clés de permission.
	 * @param poChildPermission Permission enfant.
	 * @param poParentPermission Permission parente.
	 * @param psPermissionType Type de permission comme `create` | `delete` | `edit` | `read`.
	 */
	private pathEvaluatePermission(paPermissionKeys: EPermission[], poChildPermission: IScopePermissions, poParentPermission: IScopePermissions,
		psPermissionType: CRUDPermissions | string): boolean {

		for (let lnIndex = 1; lnIndex < paPermissionKeys.length; ++lnIndex) {
			// Si le fils est défini et est un objet, on peut le parcourir pour obtenir un nouveau fils.
			if (poChildPermission && typeof poChildPermission === "object") {
				poParentPermission = poChildPermission; // Le fils actuel devient parent.
				poChildPermission = poParentPermission[paPermissionKeys[lnIndex]] as IScopePermissions; // Parcours pour obtenir le nouveau fils.
			}
			else if (poParentPermission) { // Sinon si le parent est défini, on récupère la permission associée du parent à défaut de celle de l'enfant.
				const leDefaultPermission: EPermission = paPermissionKeys[lnIndex - 1];
				console.info(`PERM.S::Permission '${psPermissionType} pour '${paPermissionKeys.join("/")}' impossible à atteindre, utilisation de la permission '${leDefaultPermission}'.`);
				return this.innerEvaluatePermission(leDefaultPermission, psPermissionType, poParentPermission);
			}
			else { // Sinon, fils non défini ou non parcourable -> parcours impossible.
				console.error(`PERM.S::Permission '${psPermissionType}' pour '${paPermissionKeys.join("/")}' non trouvée dans le cache`, this.permissionsCache.permissions);
				return this.innerEvaluatePermission(paPermissionKeys[lnIndex - 1], psPermissionType, poChildPermission);
			}
		}

		return this.innerEvaluatePermission(ArrayHelper.getLastElement(paPermissionKeys), psPermissionType, poChildPermission, poParentPermission);
	}

	private innerEvaluatePermission(pePermission: EPermission, psPermissionType: CRUDPermissions | string, poChildPermission: IScopePermissions,
		poParentPermission?: IScopePermissions): boolean {
		if (!poChildPermission) // Si la permission n'existe pas, elle peut être `undefined`.
			return poParentPermission ? this.innerEvaluatePermission(pePermission, psPermissionType, poParentPermission) : false;
		else {
			const loPermission: boolean | IScopePermissions = poChildPermission[psPermissionType];

			if (typeof loPermission === "boolean") // Permission normale.
				return loPermission;
			else if (loPermission) { // Obtention d'une imbrication de permission et non une permission.
				console.error(`PERM.S::Permission souhaitée : '${pePermission}' pour le type de permission '${psPermissionType}' mais obtenu : `, poChildPermission);
				return false;
			}
			else if (poParentPermission) // Si un parent est renseigné, on évalue la permission du parent à défaut de l'enfant.
				return this.innerEvaluatePermission(pePermission, psPermissionType, poParentPermission);
			else { // La permission n'existe pas dans le PermissionSet => refusée.
				console.error(`PERM.S::Permission '${psPermissionType}' of '${pePermission} not found in `, this.permissionsCache.permissions);
				return false;
			}
		}
	}

	public mergePermissions(paPermissions: IPermissionSet[]): IPermissionSet;
	public mergePermissions(poPermissionA: IPermissionSet, poPermissionB: IPermissionSet): IPermissionSet;
	public mergePermissions(poData: IPermissionSet[] | IPermissionSet, poPermissionB?: IPermissionSet): IPermissionSet {
		if (poData instanceof Array) {
			return poData.reduce((poPreviousPermission: IPermissionSet, poCurrentPermission: IPermissionSet) =>
				this.mergePermissions(poPreviousPermission, poCurrentPermission)
			);
		}
		else if (!poData)
			return poPermissionB;
		else {
			if (poPermissionB)
				Object.keys(poPermissionB).forEach((psKey: string) => poData[psKey] = this.pathPermissionsMerge(poData[psKey], poPermissionB[psKey]));

			return poData;
		}
	}

	/** Parcourt les permissions à fusionner pour une portée donnée de façon récursive.
	 * @param poPermissionScopeA Portée de la permission A.
	 * @param poPermissionScopeB Portée de la permission B.
	 */
	private pathPermissionsMerge(poPermissionScopeA: IScopePermissions, poPermissionScopeB: IScopePermissions): IScopePermissions {
		if (poPermissionScopeB) { // Si des permissions B sont présentes, on peut fusionner.
			if (poPermissionScopeA) { // Si des permissions A sont également présentes, on doit fusionner les permissions A et B.

				Object.keys(poPermissionScopeB).forEach((psScopeKey: string) => {
					const lbIsObjectPermissionA: boolean = typeof poPermissionScopeA[psScopeKey] === "object";
					const lbIsObjectPermissionB: boolean = typeof poPermissionScopeB[psScopeKey] === "object";
					const lbPermissionScopeA: boolean | IScopePermissions = poPermissionScopeA[psScopeKey];
					const lbPermissionScopeB: boolean | IScopePermissions = poPermissionScopeB[psScopeKey];

					if (typeof lbPermissionScopeA === "object" && typeof lbPermissionScopeB === "object") // Permissions A et B sont des objets, on doit les parcourir pour les fusionner.
						poPermissionScopeA[psScopeKey] = this.pathPermissionsMerge(lbPermissionScopeA, lbPermissionScopeB);

					else if (!lbIsObjectPermissionA && !lbIsObjectPermissionB) // Permissions A et B sont des booléens.
						poPermissionScopeA[psScopeKey] = poPermissionScopeA[psScopeKey] || poPermissionScopeB[psScopeKey];

					else if (!lbIsObjectPermissionA && lbIsObjectPermissionB) // Permission A est un booléen (ou non défini) mais permission B est un objet.
						poPermissionScopeA[psScopeKey] = poPermissionScopeB[psScopeKey];

					// Dernier cas : Permission A est un objet mais permission B est un booléen (ou non défini).
					// => rien à faire parce que priorité sur les objets de permissions plutôt que les booléens car plus précis.
				});
			}
			else // Pas de permission A, on retourne les permissions B.
				return poPermissionScopeB;
		}

		return poPermissionScopeA; // Pas de permission B donc on retourne permissions A OU permissions fusionnées dans A.
	}

	/** Retourne les différents tags de permission que l'utilisateur peut assigner. */
	public getPermissionRolesThatUserCanAssign(): IApplicationRole[] {
		const laTags: IApplicationRole[] = [];
		for (const lsKey in ConfigData.security.builtInRoles) {
			const loTag: IApplicationRole = ConfigData.security.builtInRoles[lsKey];
			if (loTag && (loTag.attributors ?? []).concat(C_ADMINISTRATORS_ROLE_ID).some((psTag: string) => this.maUserRoles.some((poTag: IApplicationRole) => poTag.id === psTag)))
				laTags.push(loTag);
		}

		return laTags;
	}

	public canApplyRoles(...paRoles: string[]): boolean {
		const laRolesThatUserCanApply: IApplicationRole[] = this.getPermissionRolesThatUserCanAssign();

		return paRoles?.every((psRole: string) =>
			StringHelper.isBlank(psRole) || laRolesThatUserCanApply.some((poRole: IApplicationRole) => poRole.id === psRole)
		) ?? true;
	}

	/** Retourne tous les rôles de l'application (y compris ceux dont ne fait pas parti l'utilisateur).
	 * @param paIds Tableau des identifiants de rôle qu'on veut garder (permet de filtrer si le rôle associé existe).
	 * @param paExcludeIds Tableau des identifiants de rôle qu'on ne veut pas garder.
	 */
	public getPermissionRoles(paIds?: string[], paExcludeIds: string[] = []): IApplicationRole[] {
		const laTags: IApplicationRole[] = [];

		for (const lsKey in ConfigData.security.builtInRoles) {
			const loRole: IApplicationRole = ConfigData.security.builtInRoles[lsKey];
			if ((!ArrayHelper.hasElements(paIds) || paIds.includes(loRole.id)) && !paExcludeIds.includes(loRole.id))
				laTags.push(loRole);
		}

		return laTags;
	}

	/** Retourne `true` si l'utilisateur possède le rôle demandé, `false` sinon.
	 * @param psRole Rôle dont l'utilisateur doit faire parti.
	 */
	public hasRole(psRole: string): boolean {
		return this.maUserRoles.some((poRole: IApplicationRole) => poRole.id === psRole);
	}

	/** Retourne `true` si l'utilisateur possède au moins l'un des rôles passés en paramètres, `false` sinon.
	 * @param paRoles Tableau des rôles dont l'utilisateur doit en faire parti d'au moins un.
	 */
	public hasRoleOf(paRoles: string[]): boolean {
		return this.maUserRoles.some((poRole: IApplicationRole) => paRoles.some((psRole: string) => psRole === poRole.id));
	}

	//#endregion

}