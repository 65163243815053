import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatBottomSheetConfig } from '@angular/material/bottom-sheet';
import { MatDatepicker } from '@angular/material/datepicker';
import { PickerOptions } from '@ionic/core/dist/types/components/picker/picker-interface';
import { tap } from 'rxjs/operators';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { ComponentBase } from '../../helpers/ComponentBase';
import { DateHelper } from '../../helpers/dateHelper';
import { StringHelper } from '../../helpers/stringHelper';
import { EDateTimePickerMode } from '../../model/date/EDateTimePickerMode';
import { EStartView } from '../../model/date/EStartView';
import { ETimetablePattern } from '../../model/date/ETimetablePattern';
import { IDateTimePickerParams } from '../../model/date/IDateTimePickerParams';
import { BottomSheetService } from '../../modules/bottom-sheet/services/bottom-sheet.service';
import { EModalSize } from '../../modules/modal/model/EModalSize';
import { ModalService } from '../../modules/modal/services/modal.service';
import { PatternsHelper } from '../../modules/utils/helpers/patterns.helper';
import { PatternResolverService } from '../../services/pattern-resolver.service';
import { PlatformService } from '../../services/platform.service';
import { BrowserTimePickerComponent } from './browser-time-picker/browser-time-picker.component';
import { MobileTimePickerComponent } from './mobile-time-picker/mobile-time-picker.component';

interface IDateTime {
	hours: number;
	minutes: number;
}

@Component({
	selector: "osapp-date-time",
	templateUrl: './dateTimePicker.component.html',
	styleUrls: ['./dateTimePicker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateTimePickerComponent extends ComponentBase implements OnInit {

	//#region FIELDS

	/** Heure au début de la création du modèle. Utilisé pour vérifier que l'heure actuelle est celle par défaut.
	 * TODO: S'il y a des intervalles de minutes, et que l'heure actuelle n'est pas dans l'intervalle, alors l'heure sélectionnée sera 0.
	 * TODO: Initialiser l'heure de `mdCurrentDate` à la valeur de l'intervalle le plus proche.
	 */
	private mdCurrentDate = new Date();
	private msSelectedTime: string;

	/** Événement qui notifie que le modèle a changé. */
	@Output("modelChange") private readonly moModelChangeEvent: EventEmitter<Date> = new EventEmitter();
	/** Événement qui notifie qu'une date a été sélectionnée. */
	@Output("dateSelected") private readonly moDateSelectedEvent: EventEmitter<Date> = new EventEmitter();

	//#endregion

	//#region PROPERTIES

	/** Paramètres possibles pour le composant. */
	@Input() public params: IDateTimePickerParams;
	/** Modèle sur lequel se baser pour le fonctionnement du composant. */
	private mdModel: Date = null;
	public get model(): Date {
		return this.mdModel;
	}
	@Input()
	public set model(pdModel: Date) {
		if (DateHelper.isDate(pdModel) && pdModel !== this.mdModel) {
			this.mdModel = new Date(pdModel);
			this.msIsoModel = DateHelper.formatIsoTimeZone(this.mdModel);
			this.msSelectedTime = DateHelper.getHoursAndMinutes(this.mdModel);
			this.detectChanges();
		}
	}

	public get time(): string {
		return this.msSelectedTime;
	}

	@Input() public disabled: boolean;
	@ViewChild(MatDatepicker) public browserDatePicker: MatDatepicker<Date>;


	/** Retourne la date du modèle, si non définit. */
	private msIsoModel = "";
	public get isoModel(): string {
		return this.msIsoModel;
	}
	public set isoModel(psIsoModel: string) {
		if (DateHelper.isDate(psIsoModel) && psIsoModel !== this.msIsoModel) {
			this.msIsoModel = psIsoModel;
			this.mdModel = new Date(this.msIsoModel);
			this.detectChanges();
		}
	}

	public get min(): Date {
		return !StringHelper.isBlank(this.params.min) ? new Date(this.params.min) : undefined;
	}

	public get max(): Date {
		return !StringHelper.isBlank(this.params.max) ? new Date(this.params.max) : undefined;
	}
	public get mobileMinTime(): string {
		return this.min && DateHelper.diffDays(this.model, this.min) === 0 ? this.params.min : undefined;
	}

	public get mobileMaxTime(): string {
		return this.max && DateHelper.diffDays(this.model, this.max) === 0 ? this.params.max : undefined;
	}

	public get minHourMinutes(): string {
		return !StringHelper.isBlank(this.params.minHour) ? this.params.minHour : undefined;
	}

	public get maxHourMinutes(): string {
		return !StringHelper.isBlank(this.params.maxHour) ? this.params.maxHour : undefined;
	}

	public get startView(): string {
		return !StringHelper.isBlank(this.params.startView) ? this.params.startView : EStartView.month;
	}

	public timePickerOptions: PickerOptions;

	//#endregion

	//#region METHODS

	constructor(
		private readonly ioDatePipe: DatePipe,
		private readonly isvcPlatform: PlatformService,
		private readonly isvcModal: ModalService,
		private readonly isvcPatternResolver: PatternResolverService,
		private readonly isvcBottomSheet: BottomSheetService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);
	}

	public ngOnInit(): void {
		if (!this.params)
			this.params = {};

		this.initParams();

		if (!this.model && this.params.autoFill)
			this.model = this.mdCurrentDate;
		else
			this.detectChanges();
	}

	/** Initialise tous les paramètres avec ceux présents dans les data du templateOptions. */
	private initParams(): void {

		if (StringHelper.isBlank(this.params.label))
			this.params.label = "";

		if (StringHelper.isBlank(this.params.doneText))
			this.params.doneText = DateHelper.C_DONE_TEXT;

		if (StringHelper.isBlank(this.params.cancelText))
			this.params.cancelText = DateHelper.C_CANCEL_TEXT;

		if (!this.params.displayFormat)
			this.params.displayFormat = ETimetablePattern.dd_MM_yyyy_HH_mm_slash;

		if (StringHelper.isBlank(this.params.pickerMode))
			this.params.pickerMode = EDateTimePickerMode.datetime;

		if (!ArrayHelper.hasElements(this.params.dayNames))
			this.params.dayNames = DateHelper.C_DAY_NAMES;

		if (!ArrayHelper.hasElements(this.params.dayShortNames))
			this.params.dayShortNames = DateHelper.C_DAY_SHORT_NAMES;

		if (!ArrayHelper.hasElements(this.params.monthNames))
			this.params.monthNames = DateHelper.C_MONTH_NAMES;

		if (!ArrayHelper.hasElements(this.params.monthShortNames))
			this.params.monthShortNames = DateHelper.C_MONTH_SHORT_NAMES;

		// Si la date min est renseignée.
		if (!StringHelper.isBlank(this.params.min)) {
			this.params.min = this.transformDateWithIsoFormat(
				PatternsHelper.hasPattern(this.params.min) ? new Date(this.isvcPatternResolver.replaceDynParams(this.params.min)) : new Date(this.params.min)
			);
		}

		// Si la date max est renseignée.
		if (!StringHelper.isBlank(this.params.max)) {
			this.params.max = this.transformDateWithIsoFormat(
				PatternsHelper.hasPattern(this.params.max) ? new Date(this.isvcPatternResolver.replaceDynParams(this.params.max)) : new Date(this.params.max)
			);
		}

		if (StringHelper.isBlank(this.params.icon))
			this.params.icon = DateHelper.C_DEFAULT_ICON;

		if (this.disabled === undefined)
			this.disabled = false;

		this.timePickerOptions = {
			buttons: [
				{
					role: 'cancel',
					text: this.params.cancelText
				},
				{
					role: 'submit',
					text: this.params.doneText,
					handler: (poNewDate: { hour: { value: number }, minute: { value: number } }) => {
						const ldNewDate: Date = new Date(this.model);
						ldNewDate.setHours(poNewDate.hour.value, poNewDate.minute.value);
						this.model = ldNewDate;
						this.moModelChangeEvent.emit(ldNewDate);
					}
				}
			]
		} as any;
	}

	/** Transforme une date au format ISO8601.
	 * @param pdDate Date à transformer.
	 */
	private transformDateWithIsoFormat(pdDate: Date): string {
		// Retourne un résultat différent si le format contient des heures ou non.
		if (this.params.displayFormat.indexOf("HH") === -1)
			return this.ioDatePipe.transform(pdDate, ETimetablePattern.isoFormat_hyphen);
		else
			return DateHelper.formatIsoTimeZone(pdDate);
	}

	public pickDate(): void {
		if (!this.disabled) { // Si le composant n'est pas désactivé, on poursuit le traitement pour afficher une date.
			if (this.params.pickerMode === "time")
				this.pickHour();
			else
				this.browserDatePicker.open();
		}
	}

	public onDateChanged(poDate: Date): void {
		this.moDateSelectedEvent.emit(new Date(poDate));
		if (DateHelper.compareTwoDates(poDate, this.model) !== 0) {
			this.model = poDate;
			this.moModelChangeEvent.emit(this.model);
		}
	}

	public onTimeChanged(psTime: string) {
		this.msSelectedTime = psTime;
	}

	public onTimeSelected() {
		const loDateTime: IDateTime = this.getSelectedTime(new Date(this.msSelectedTime));
		const ldNewDate: Date = new Date(this.model);
		ldNewDate.setHours(loDateTime.hours, loDateTime.minutes);
		this.model = ldNewDate;
		this.moModelChangeEvent.emit(ldNewDate);
	}

	public onBrowserDateChanged(poDate: Date): void {
		if (this.model)
			poDate.setHours(this.model.getHours(), this.model.getMinutes());

		if (this.params.pickerMode === "datetime") {
			this.model = poDate;
			this.pickHour();
		}
		else
			this.onDateChanged(poDate);
	}

	/** Prend en paramètre une heure ex: `08:30` et retourne un objet de contenant les heures d'un côté et les minutes de l'autre.
	 * @param pdDate
	 */
	private getSelectedTime(pdDate: Date): IDateTime {
		return { hours: pdDate.getHours(), minutes: pdDate.getMinutes() };
	}

	private async pickHour(): Promise<void> {
		if (this.isvcPlatform.isMobileApp) {
			const loBottomsheetInstance = this.isvcBottomSheet.create<string>(
				MobileTimePickerComponent,
				{
					data: {
						value: this.msIsoModel,
						min: this.mobileMinTime,
						max: this.mobileMaxTime,
						cancelText: this.params.cancelText,
						doneText: this.params.doneText
					}
				} as MatBottomSheetConfig
			);

			const lsNewIsoDate: string = await loBottomsheetInstance.afterDismissed().toPromise();
			if (lsNewIsoDate)
				this.onMobileTimeChanged(lsNewIsoDate);
		}
		else
			this.isvcModal.open<Date>(
				{
					component: BrowserTimePickerComponent,
					componentProps: {
						date: this.model,
						min: this.min,
						max: this.max
					}
				},
				EModalSize.timePicker
			)
				.pipe(
					tap((poDate: Date) => {
						if (DateHelper.isDate(poDate)) {
							this.model = poDate;
							this.moModelChangeEvent.emit(this.model);
						}
					})
				)
				.subscribe();
	}

	private onMobileTimeChanged(psTime: string): void {
		this.onTimeChanged(psTime);
		this.onTimeSelected();
	}

	//#endregion
}