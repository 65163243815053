import { Component, ViewChild } from '@angular/core';
import { SlideboxComponent } from '@osapp/components/slidebox';
import { ISlideboxData } from '@osapp/model';
import { Observable } from 'rxjs';
import { Traitement } from './Traitement';

@Component({ template: "" })
export abstract class TraitementSlideParentBase {

	//#region FIELDS

	/** "resetMajoration". */
	public static readonly C_RESET_MAJORATION = "resetMajoration";

	/** Identifiant de la slide de traitement 'Prescriptions'. */
	public static readonly C_PRESCRIPTION_SLIDE_ID = "traitementSlidePrescriptions";
	/** Identifiant de la slide de traitement 'Accord Préalable'. */
	public static readonly C_ACCORD_PREALABLE_SLIDE_ID = "traitementSlideAccordPrealable";
	/** Identifiant de la slide de traitement 'Séances'. */
	public static readonly C_SEANCES_SLIDE_ID = "traitementSlideSeances";
	/** Identifiant de la slide de traitement 'Majorations'. */
	public static readonly C_MAJORATIONS_SLIDE_ID = "traitementSlideMajorations";
	/** Identifiant de la slide de traitement 'Indemnités'. */
	public static readonly C_INDEMNITES_SLIDE_ID = "traitementSlideIndemnites";
	/** Identifiant de la slide de traitement 'Synthèse'. */
	public static readonly C_SYNTHESE_SLIDE_ID = "traitementSlideSynthese";
	/** Identifiant pour l 'action "ajouter une ordonnance". */
	public static readonly C_ADD_ORDONNANCE_ACTION_ID = "addOrdonnance";
	/** Identifiant pour l 'action "ajouter un acte". */
	public static readonly C_ADD_ACTE_ACTION_ID = "addActe";

	/** Tableau des identifiants de slides qui sont bloqués tant qu'aucun acte n'a été ajouté au traitement. */
	public static readonly C_BLOCKED_SLIDE_IDS_IF_NO_ACTE = [
		TraitementSlideParentBase.C_ACCORD_PREALABLE_SLIDE_ID,
		TraitementSlideParentBase.C_SEANCES_SLIDE_ID,
		TraitementSlideParentBase.C_MAJORATIONS_SLIDE_ID,
		TraitementSlideParentBase.C_INDEMNITES_SLIDE_ID,
		TraitementSlideParentBase.C_SYNTHESE_SLIDE_ID
	];

	//#endregion

	//#region PROPERTIES

	/** Identifiant du composant slidebox instancié pour le manipuler. */
	public readonly componentId: string;

	@ViewChild("traitementSlidebox") public slidebox: SlideboxComponent;

	/** Données de la slidebox. */
	public slideboxData: ISlideboxData;
	/** Id qui sera modifié pour avoir le nom du document de description du composant comp_slidebox_ + this.id + version */
	public id: string;
	/** Traitement courant. */
	public traitement: Traitement;
	public lastCreatedActeGuid?: string;

	//#endregion

	//#region METHODS

	/** Retourne le traitement sous forme d'observable afin de s'abonner. */
	public abstract getTraitementAsObservable(): Observable<Traitement>;

	/** Retourne la slide traitement sous forme d'observable afin de s'abonner. */
	public abstract getTraitementSlideAsObservable(): Observable<string>;

	/** Gère le blocage des slides et retourne `true` si les slides sont bloquées, `false` sinon. */
	public abstract manageBlockingSlides(): boolean;

	/** Récupères les actions d'initialisation de slide. */
	public abstract getInitializingSlideActions(): string[];

	/** Récupères les actions d'initialisation de slide sous forme de flux. */
	public abstract getInitializingSlideActions$(): Observable<string[]>;

	/** Supprime des actions d'initialisation de slide.
	 * @param psAction Tableaus d'actions à supprimer du tableau des actions d'initialisation de slide.
	 */
	public abstract removeInitializingSlideActions(paActions: string[]): void;

	//#endregion
}