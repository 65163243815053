import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IDynHostComponent } from '@osapp/components/dynHost';
import { DynamicPageComponent } from '@osapp/components/dynamicPage/dynamicPage.component';
import { FormListComponent } from '@osapp/components/forms/formList/formList.component';
import { SearchComponent } from '@osapp/components/search/search.component';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { GuidHelper } from '@osapp/helpers/guidHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { SocialNumberHelper } from '@osapp/helpers/socialNumberHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { UserData } from '@osapp/model/application/UserData';
import { EBarElementDock } from '@osapp/model/barElement/EBarElementDock';
import { EBarElementPosition } from '@osapp/model/barElement/EBarElementPosition';
import { IBarElement } from '@osapp/model/barElement/IBarElement';
import { IGroup } from '@osapp/model/contacts/IGroup';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { IFormListEvent } from '@osapp/model/forms/IFormListEvent';
import { IListDefinitionsField } from '@osapp/model/forms/IListDefinitionsField';
import { IAvatar } from '@osapp/model/picture/IAvatar';
import { IPicture } from '@osapp/model/picture/IPicture';
import { IStoreDataResponse } from '@osapp/model/store/IStoreDataResponse';
import { IStoreDocument } from '@osapp/model/store/IStoreDocument';
import { C_SECTORS_ROLE_ID, Permissions, PermissionsService } from '@osapp/modules/permissions/services/permissions.service';
import { FavoritesService } from '@osapp/modules/preferences/favorites/services/favorites.service';
import { ISector } from '@osapp/modules/sectors/models/isector';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { PatternPipe } from '@osapp/pipes/pattern.pipe';
import { EntityLinkService } from '@osapp/services/entityLink.service';
import { FormsService } from '@osapp/services/forms.service';
import { GroupsService } from '@osapp/services/groups.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '@osapp/services/loading.service';
import { PageManagerService } from '@osapp/services/pageManager.service';
import { PatternResolverService } from '@osapp/services/pattern-resolver.service';
import { Store } from '@osapp/services/store.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { Observable, ReplaySubject, from, of } from 'rxjs';
import { defaultIfEmpty, filter, map, mergeMap, switchMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { C_DESMOS_PERMISSION_ID, C_PREFIX_AMOP, C_PREFIX_PATIENT } from '../../../../app/app.constants';
import { TerminalInfoModalOpenerService } from '../../../olaqin/components/terminal-info-modal/services/terminal-info-modal-opener.service';
import { ITerminalInfo } from '../../../olaqin/models/iterminal-info';
import { OlaqinService } from '../../../olaqin/services/olaqin.service';
import { IPatient } from '../../model/IPatient';
import { EUpdateMode } from '../../model/eupdate-mode.enum';
import { IAMOP } from '../../model/iamo-p';
import { IUpdateHistory } from '../../model/iupdate-history';
import { CouverturesService } from '../../services/couvertures.service';
import { PatientsService } from '../../services/patients.service';
import { PatientSelectorModalOpenerService } from '../patient-selector-modal/services/patient-selector-modal-opener.service';

@Component({
	templateUrl: './patients-list.component.html',
	styleUrls: ['./patients-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientsListComponent extends FormListComponent<IPatient> implements IDynHostComponent, OnInit, OnDestroy {

	//#region FIELDS

	private moDocumentsSubject = new ReplaySubject<IPatient[]>(1);
	private maSelectedGroups: IGroup[];
	private moGroupsByPatientId = new Map<string, IGroup[]>();

	//#endregion

	//#region PROPERTIES

	public readonly instanceId: string = GuidHelper.newGuid();
	public readonly C_IS_DATE_PATTERN = ETimetablePattern.dd_MMM_yyyy;

	@Input() public params: { idFormDesc: string, idFormList: string };

	/** Valeur recherchée dans la barre de recherche. */
	public searchValue: string;
	/** Indique si une recherche est en cours ou non. */
	public isSearching = false;
	/** Tableau des patients récupérés depuis Desmos. */
	public desmosPatients: IPatient[] = [];
	/** Indique si ce sont les patient issus de Desmos qu'il faut afficher ou ceux issus de la base de données. */
	public showDesmosPatients = false;

	public displayFieldsByKey = new Map<string, IListDefinitionsField<IStoreDocument>>();

	public get canReadVital(): boolean {
		return true;
	}

	public get canReadVitalAgrement(): boolean {
		return false;
	}

	public selectOptions: ISelectOption[];

	public get documents(): IPatient[] {
		return this.maDocuments;
	}
	public set documents(paDocuments: IPatient[]) {
		this.sortDocuments(paDocuments);
		this.maDocuments = paDocuments;
		this.moDocumentsSubject.next(this.maDocuments);
		this.onFilteredDocumentsChanged(Array.from(this.maDocuments)); // `Array.from` permet d'éviter des bugs de rafraîchissement.
	}

	@Permissions("read", C_DESMOS_PERMISSION_ID)
	public get canUseDesmos(): boolean {
		return true;
	}

	//#endregion

	//#region METHODS

	constructor(
		protected readonly isvcPatients: PatientsService,
		private readonly isvcLoading: LoadingService,
		private readonly isvcOlaqin: OlaqinService,
		private readonly isvcGroups: GroupsService,
		public readonly isvcPermissions: PermissionsService,
		private readonly isvcPatientSelectorModalOpener: PatientSelectorModalOpenerService,
		private readonly isvcTerminalInfoModalOpener: TerminalInfoModalOpenerService,
		private readonly isvcCouvertures: CouverturesService,
		poParentPage: DynamicPageComponent<ComponentBase>,
		psvcPageManager: PageManagerService,
		psvcUiMessage: UiMessageService,
		psvcForm: FormsService,
		psvcEntity: EntityLinkService,
		poPatternPipe: PatternPipe,
		poRenderer: Renderer2,
		psvcPatternResolver: PatternResolverService,
		poChangeDetectorRef: ChangeDetectorRef,
		poRoute: ActivatedRoute,
		poRouter: Router,
		psvcStore: Store,
		psvcFavorites: FavoritesService
	) {
		super(poParentPage, psvcPageManager, psvcUiMessage, psvcForm, psvcEntity, poPatternPipe, poRenderer, psvcPatternResolver,
			poChangeDetectorRef, poRoute, poRouter, psvcStore, psvcFavorites);

		psvcForm.onFormListEvent()
			.pipe(
				tap((poEvent: IFormListEvent) => isvcPatients.manageItemOptionEvent(poEvent)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public ngOnInit(): void {
		this.idFormDesc = this.params.idFormDesc;
		this.idFormList = this.params.idFormList;
		this.assignedInstanceId = this.instanceId;
		this.customGetEntries = () => this.isvcPatients.getSitePatients(true);
		this.customItemClicked = (poPatient: IPatient) => this.recordRecentPatientAndRouteToForm(poPatient);

		this.moDocumentsSubject
			.pipe(
				switchMap((paPatients: IPatient[]) => this.isvcGroups.getContactsGroups(paPatients, true)),
				tap((poGroupsByPatientIds: Map<string, IGroup[]>) => {
					poGroupsByPatientIds.forEach((paGroups: IGroup[], psPatientId: string) =>
						poGroupsByPatientIds.set(psPatientId, paGroups?.filter((poGroup: IGroup) => this.isvcGroups.hasRole(poGroup, C_SECTORS_ROLE_ID)) ?? [])
					);

					this.moGroupsByPatientId = poGroupsByPatientIds;
				}),
				switchMap(() => this.isvcGroups.getGroupsByRoles([C_SECTORS_ROLE_ID], true)),
				tap((paSectors: ISector[]) => {
					this.displayFieldsByKey = new Map(this.displayFields.map((poField: IListDefinitionsField<IStoreDocument>) => [poField.key, poField]));
					this.selectOptions = paSectors.filter(((poSector: ISector) => poSector.siteId === UserData.currentSite._id)).map((poSector: ISector) => ({ label: poSector.name, value: poSector }));
					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		super.ngOnInit();
	}

	public ngOnDestroy(): void {
		this.moDocumentsSubject.complete();
		super.ngOnDestroy();
	}

	protected createBarElements(): IBarElement[] {
		const laBarElements: IBarElement[] = super.createBarElements();

		if (this.canReadVital) {
			laBarElements.unshift({
				id: "circle",
				component: "fabButton",
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.right,
				icon: "card",
				onTap: () => this.createPatientFromVitalCard(),
				priority: 0,
				name:"Lire carte vitale"
				
			});
		}

		if (this.canReadVitalAgrement) {
			laBarElements.unshift({
				id: "circle",
				component: "fabButton",
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.right,
				icon: "add",
				onTap: () => this.fsvAddPatientOptions(),
				priority: 0,
							name:"Ajouter via FSV"
			});
		}

		return laBarElements;
	}

	private fsvAddPatientOptions(): void {
		this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
			header: "Mode d'ajout",
			buttons: [
				{
					text: "Manuel", handler: () => this.ioRouter.navigateByUrl("/fsv-patient/new")
				},
				{
					text: "Lecture Carte Vitale ", handler: () => this.ioRouter.navigateByUrl("/fsv-lecture-carte")
				},
				{
					text: "App e-Carte Vitale: QR Code", handler: () => this.ioRouter.navigate(["/fsv-lecture-carte"], {
						queryParams: {
							params: "&isModeApcvQR=True"
						}
					})
				},
				{
					text: "App e-Carte Vitale: NFC ", handler: () => this.ioRouter.navigate(["/fsv-lecture-carte"], {
						queryParams: {
							params: "&isModeApcvNFC=True"
						}
					})
				},
				{
					text: "App e-Carte Vitale: Restitution", handler: () => this.ioRouter.navigate(["/fsv-lecture-carte"], {
						queryParams: {
							params: "&action=Restituer"
						}
					})
				},
			]
		}));
	}

	/** Sauvegarde dans la base de données le patient récemment consulté et route vers le formulaire du patient.
	 * @param poPatient
	 */
	private recordRecentPatientAndRouteToForm(poPatient: IPatient): void {
		if (poPatient) {
			this.isvcPatients.addRecentPatientId(poPatient._id)
				.pipe(
					tap(
						(poStoreDataResponse: IStoreDataResponse) => console.debug(`Patient ${poPatient._id} ajouté à l'historique.`, poStoreDataResponse),
						poError => console.error("FLDYNHOSTWRAP.C:: Erreur ajout id patient récent :", poError)
					),
					mergeMap(_ => this.isvcPatients.goToPatient(poPatient)),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
	}

	private createPatientFromVitalCard(): void {
		this.readDataFromVitalCard()
			.pipe(
				tap(
					() => this.isvcLoading.dismiss(),
					poError => this.onCreatePatientFromVitalCardError(poError),
				),
				mergeMap((paPatients: IPatient[]) => this.isvcOlaqin.selectBeneficiaire(paPatients)),
				filter((poPatient: IPatient) => !!poPatient),
				mergeMap((poPatient: IPatient) => this.mergePatientIfExisting(poPatient)),
				mergeMap((poPatient: IPatient) => {
					if (StringHelper.isBlank(poPatient._id))
						poPatient._id = IdHelper.buildId(C_PREFIX_PATIENT);

					const laAMOPs: IAMOP[] = poPatient.AMO?.map((poAMOP: IAMOP) => {
						poAMOP._id = IdHelper.buildChildId(C_PREFIX_AMOP, poPatient._id);
						const loUpdateHistory: IUpdateHistory = {
							date: poAMOP.updateDate = new Date(),
							mode: poAMOP.updateMode = EUpdateMode.carteVitale
						};
						poAMOP.updateHistory = [loUpdateHistory];
						return poAMOP;
					}) ?? [];
					const lsPatientCarteVitaleXML: string = poPatient.carteVitaleXML; // On sauvergarde le XML ici car il va être enlevé du patient lors de sa sauvegarde.
					delete poPatient.AMO;
					return from(laAMOPs).pipe(
						mergeMap((amop: IAMOP) => this.isvcPatients.savePatient(poPatient).pipe(
							mergeMap(() => this.isvcCouvertures.saveCouverture(amop).pipe(
								toArray(),
								defaultIfEmpty([]),
								mergeMap(() => this.isvcPatients.getPatient(poPatient._id)),
								mergeMap((patient: IPatient) => this.isvcPatients.exportPatientXml(patient, lsPatientCarteVitaleXML)),
								tap((exportedPatient: IPatient) => poPatient = exportedPatient),
								mergeMap(() => this.isvcPatients.goToPatientEdit(poPatient._id, true))
							))
						))
					);
					})
				  )
				  .subscribe();
	}

	private selectExistingPatient(paExisitingPatients: IPatient[]): Observable<IPatient> {
		if (ArrayHelper.hasElements(paExisitingPatients)) {
			if (paExisitingPatients.length === 1)
				return of(ArrayHelper.getFirstElement(paExisitingPatients));
			else {
				return this.isvcPatientSelectorModalOpener.open({ patients: paExisitingPatients, title: "Fusionner", canCreate: false }).pipe(
					filter((paSelectedPatients: IPatient[]) => !!paSelectedPatients),
					map((paSelectedPatients: IPatient[]) => ArrayHelper.getFirstElement(paSelectedPatients))
				);
			}
		}
		else
			return of(undefined);
	}

	private mergePatientIfExisting(poPatient: IPatient): Observable<IPatient> {
		return this.selectExistingPatient(this.isvcPatients.searchPatient(this.documents, {
			...ObjectHelper.pick(poPatient, ["birthDate", "firstName", "lastName", "socialNumber"]),
			fullMatch: !!poPatient.updateDate
		})).pipe(
			map((poExisitingPatient: IPatient) => {
				let loMergedPatient: IPatient;

				if (!poExisitingPatient)
					loMergedPatient = poPatient;
				else
					loMergedPatient = this.isvcPatients.mergePatientsData(poExisitingPatient, poPatient);

				return loMergedPatient;
			})
		);
	}

	private onCreatePatientFromVitalCardError(poError: any): void {
		this.isvcLoading.dismiss();

		this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: poError.error?.message ?? "Erreur lors de la lecture de la carte vitale.", header: "Erreur." }));

		console.error("FLDYNHOSTWRAP.C::", poError);
	}

	private readDataFromVitalCard(): Observable<IPatient[]> {
		return this.isvcTerminalInfoModalOpener.open({ title: "Lecture carte vitale" })
			.pipe(
				filter((poTerminal: ITerminalInfo) => !!poTerminal),
				mergeMap((poTerminal: ITerminalInfo) => this.isvcOlaqin.readVitalCard(poTerminal.terminalId))
			);
	}

	/** Lance une requête de recherche d'un patient usr desmos avec l'api. */
	public onSearchWithDesmos(paResults: IPatient[]): void {
		if (ArrayHelper.hasElements(paResults)) {
			this.showDesmosPatients = true;
			this.desmosPatients = paResults;
		}
	}

	/** Ouverture de la page de création d'un patient avec le patient desmos cliqué.
	 * @param poDesmosPatient Patient issu de Desmos.
	 */
	public onDesmosItemClicked(poDesmosPatient: IPatient): void {
		this.isvcPatients.goToNewPatient(poDesmosPatient);
	}

	public onSearchValueChanged(): void {
		if (this.showDesmosPatients) {
			this.showDesmosPatients = false;
			this.desmosPatients = [];
		}
	}

	public getFormatedSocialNumber(psValue?: string): string {
		return psValue ? SocialNumberHelper.format(psValue) : "";
	}

	/** @override */
	public onFilteredDocumentsChanged(paChanges: IPatient[]): void {
		super.onFilteredDocumentsChanged(this.filterByGroups(paChanges, this.maSelectedGroups));
	}

	public onGroupSelectionChanged(paSelectedGroups: IGroup[], poSearchComponent: SearchComponent<IPatient>): void {
		this.maSelectedGroups = paSelectedGroups;
		this.onFilteredDocumentsChanged(poSearchComponent.search());
	}

	private filterByGroups(paDocuments: IPatient[], paGroups: IGroup[]): IPatient[] {
		return !ArrayHelper.hasElements(paGroups) ? paDocuments : paDocuments.filter((poPatient: IPatient) =>
			paGroups.some((poSelectedGroup: IGroup) =>
				this.moGroupsByPatientId.get(poPatient._id)?.some((poGroup: IGroup) => poGroup._id === poSelectedGroup._id)
			)
		);
	}

	/** @override */
	public getAvatar(poData: IPicture): IAvatar {
		const loAvatar: IAvatar = super.getAvatar(poData);
		loAvatar.icon = "person";

		return loAvatar;
	}

	/** @override */
	public sortDocuments(paDocuments: IPatient[]): IPatient[] {
		return super.sortDocuments(paDocuments).sort((poPatientA: IPatient, poPatientB: IPatient) => ArrayHelper.compareByExistingProperty(poPatientB, poPatientA, "_conflicts"));
	}

	//#endregion
}
