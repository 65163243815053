import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "di-snackbar",
	templateUrl: "./snackbar.component.html",
	styleUrls: ["./snackbar.component.scss"],
})
export class SnackbarComponent implements OnInit {
	@Input() type = "";
	@Input() position = "";
	@Input() message = "";
	@Input() iconName = "";
	@Input() buttonText = "";
	@Input() buttonIconName = "";
	@Input() delay = "";

	constructor() {}

	public ngOnInit(): void {}
}
