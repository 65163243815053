<div class="menu-items">
	<div *ngFor="let tuile of homeTuiles" class="menu-item">
		<lua-tuile
			[label]="tuile.label"
			[iconName]="tuile.iconName"
			[badgeCount]="tuile.badgeCount"
			(click)="redirect(tuile.route)"
		></lua-tuile>
	</div>
</div>
