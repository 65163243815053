import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Acte } from '../../../../../model/Acte';
import { StoredSeance } from '../../../../models/StoredSeance';
import { EEtatActe } from '../../enums/EEtatActe';
import { DeviceService } from '../../services/device.service';
import { DrawerPopoverService } from '../../services/drawer-popover.service';
import { PanneauService } from '../../services/panneau.service';
import { SeanceService } from '../../services/seance.service';
import { AffectationSoinsComponent } from '../affectation-soins/affectation-soins.component';
import { ConfirmationSuppressionComponent } from '../confirmation-suppression/confirmation-suppression.component';
import { ModifierActesComponent } from '../modifier-actes/modifier-actes.component';
import { ModifierHeuresSoinsComponent } from '../modifier-heures-soins/modifier-heures-soins.component';
import { PatientIndisponibleComponent } from '../patient-indisponible/patient-indisponible.component';

import { Router } from '@angular/router';
import { HoursMinutesRepetition } from '@osapp/modules/event-markers/models/hours-minutes-repetition';
import { IDayRepetition } from '@osapp/modules/event-markers/models/iday-repetition';
import { IModifActesSeance } from 'apps/idl/src/anakin/models/IModifActesSeance';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { forkJoin, Observable, of } from 'rxjs';
import { EStatusSeance } from '../../../../../model/EStatusSeance';
import { TraitementService } from '../../../../../services/traitement.service';
import { EMoments } from '../../enums/EMoments';
import { ESeanceOptionModification } from '../../enums/ESeanceOptionModification';
import { ETypeModifSeance } from '../../enums/ETypeModifSeance';
import { SeancesGeneratorService } from '../../services/seances-generator.service';
import { ChoixSeanceComponent } from '../choix-seance/choix-seance.component';
import { ModifierDateSeanceComponent } from '../modifier-date-seance/modifier-date-seance.component';
import { SoinsRealisesComponent } from '../soins-realises/soins-realises.component';


@Component({
	selector: 'di-menu-modifier-seance',
	templateUrl: './menu-modifier-seance.component.html',
	styleUrls: ['./menu-modifier-seance.component.scss'],
})
export class MenuModifierSeanceComponent extends DestroyableComponentBase implements OnInit, OnDestroy {

	constructor(
		private svcDrawerPopover: DrawerPopoverService,
		private svcPanneau: PanneauService,
		private svcDevice: DeviceService,
		private svcSeance: SeanceService,
		private router: Router,
		private svcTraitement: TraitementService,
		private svcSeancesGenerator: SeancesGeneratorService,
		private svcPatient: PatientsService
	) {
		super();
	}
	@Input() seance: StoredSeance;
	@Input() anchorElement?: any;
	public showPopover: boolean = false;
	public isMobile: boolean = false;
	public typeModif: string;

	// Contient la liste des modifications d'acte à appliquer
	public modifActesSeance: IModifActesSeance

	ngOnInit() {
		this.svcDevice.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((flag: boolean) => {
			this.isMobile = flag;
		});
	}


	public editHeure(): void {
		this.closeMenu()
		this.svcPanneau.close();
		this.svcDrawerPopover.open("Modifier l'heure des soins",
			"60%",
			null,
			ModifierHeuresSoinsComponent,
			{
				seance: this.seance,
				onConfirm: (updatedSeance: StoredSeance) => {
					this.typeModif = ETypeModifSeance.Heure;
					this.seance = updatedSeance;
					this.openOccurence()
				},
				onCancel: () => this.closeMenu(),
			})
	}

	public editDate(): void {
		this.closeMenu();
		this.svcPanneau.close();

		const title = 'Modifier date';
		const content = ModifierDateSeanceComponent;
		const contentInputs = {
			seance: this.seance,
			onConfirm: (selectedDate: Date) => this.onConfirmDateChange(selectedDate),
			onCancel: () => this.closeMenu(),
		};

		this.svcDrawerPopover.open(title, "60%", null, content, contentInputs);
	}

	onConfirmDateChange(selectedDate: Date) {
		this.typeModif = ETypeModifSeance.Date;
		this.seance.startDate = selectedDate;
		this.updateSeance();
	}

	public changerInfirmier(): void {
		this.closeMenu();
		this.svcDrawerPopover.open(
			"Affectation soins",
			"60%",
			null,
			AffectationSoinsComponent,
			{
				confirm: (selectedInfirmierId) => {
					this.confirmModifInfirmier(selectedInfirmierId);
				},
				cancel: () => this.closeMenu(),
			}
		);
	}

	confirmModifInfirmier(selectedInfirmierId: string) {
		this.typeModif = ETypeModifSeance.Infirmier;
		this.seance.infirmierId = selectedInfirmierId;
		this.openOccurence()
	}

	confirmPatientIndispo(commentaireIndisponibilitePatient: string) {
		this.seance.commentaireIndisponibilitePatient = commentaireIndisponibilitePatient;
		this.seance.status = EStatusSeance.canceled;
		this.seance.actes.map((acte: Acte) => {
			acte.etat = EEtatActe.not_done
		})
		this.updateSeance();
	}

	public deletePatientDispo() {
		delete this.seance.commentaireIndisponibilitePatient;
		this.seance.actes.map((acte: Acte) => {
			acte.etat = EEtatActe.to_be_done
		})
		this.updateSeance();

	}


	public confirmSoins(seance: StoredSeance) {
		this.updateSeance();
	}

	public deleteConfirmSoins(seance: StoredSeance) {
		this.updateSeance();
	}

	private updateSeance() {

		switch (this.typeModif) {
			case ETypeModifSeance.Heure:
			case ETypeModifSeance.Infirmier:
			default:
				this.svcSeance.updateSeance(this.seance).pipe(
					tap(() => {
						this.closeMenu();
					}),
				).subscribe();
				break;
			case ETypeModifSeance.Actes:
				this.svcPatient.getPatient(this.seance.patientId).pipe(
					switchMap((patient: IPatient) => this.updateActesSeance(this.seance, patient)),
					tap(() => {
						this.closeMenu();
					}),
				).subscribe();
				break;					
		}
	}

	private updateSeances(onlyFutur: boolean) {
		let patientSeance: IPatient;
		this.svcPatient.getPatient(this.seance.patientId).pipe(
			tap((patient: IPatient) => {
				patientSeance = patient
			}),
			switchMap(() => {
				if (onlyFutur) {
					return this.svcSeance.getFuturSeances(this.seance)
				} else {
					return this.svcSeance.getSeancesNonFactures(this.seance)
				}
			}),
			switchMap((seances: StoredSeance[]) => {

				let seanceUpdates = [];

				let seancesUpdated: StoredSeance[] = [];
				seances.forEach((seance: StoredSeance) => {
					switch (this.typeModif) {
						case ETypeModifSeance.Heure:
							const moment: IDayRepetition = this.seance.moment;
							seance.moment = moment
							if (moment.type == "range") {
								seance.startDate = StoredSeance.getStartDateFromMoment(StoredSeance.determineMoment(moment) as EMoments, seance.startDate)
							}
							if (moment.type == "hours-minutes") {
								seance.startDate = StoredSeance.getStartDateFromMoment(moment as HoursMinutesRepetition, seance.startDate)
							}
							seanceUpdates.push(this.svcSeance.updateSeance(seance));
							break;
						case ETypeModifSeance.Infirmier:
							const infirmierId: string = this.seance.infirmierId;
							seance.infirmierId = infirmierId
							seanceUpdates.push(this.svcSeance.updateSeance(seance));
							break;
						case ETypeModifSeance.Actes:
							seance = this.svcSeance.updateActesSeance(seance, this.modifActesSeance)
							seance.actes = seance.actes.map((acte: Acte) => new Acte(acte))
							seancesUpdated.push(seance)
							break;
					}
				});
				if (this.typeModif === ETypeModifSeance.Actes){
					return this.svcSeancesGenerator.applyTaxAllowance(seancesUpdated, patientSeance).pipe(
						switchMap((updatedSeances: StoredSeance[]) => {
							updatedSeances.forEach((seance: StoredSeance) => {
								seanceUpdates.push(this.svcSeance.updateSeance(seance));
							})
							return forkJoin(seanceUpdates);
						})
					)
				}
				if (seanceUpdates.length > 0) {
					return forkJoin(seanceUpdates);
				} else {
					return of(false);
				}
			})
		).subscribe()
	}

	// Met à jour les actes d'une séance et recalcul les cotations
	public updateActesSeance(seance: StoredSeance, patient: IPatient): Observable<boolean> {
		seance = this.svcSeance.updateActesSeance(seance, this.modifActesSeance)
		return this.svcSeancesGenerator.applyTaxAllowance([seance], patient).pipe(
			switchMap((updatedSeances: StoredSeance[]) => {
				const updatedSeance = updatedSeances[0];
				return this.svcSeance.updateSeance(updatedSeance);
			})
		)
	}

	public editActes(): void {
		this.closeMenu()
		if (this.isMobile) {
			this.svcPanneau.close();
			this.svcDrawerPopover.open("Modifier les actes",
				"60%",
				null,
				ModifierActesComponent,
				{
					seance: this.seance,
					confirm: (seance: StoredSeance) => {
						this.seance = seance;
						this.typeModif = ETypeModifSeance.Actes;
						this.openOccurence();
					},
					cancel: () => this.closeMenu()
				})
		} else {
			const panneauTitle = "Modifier les actes";
			const panneauContent = ModifierActesComponent;
			const panneauInputs = {
				seance: this.seance,
				confirm: (seance: StoredSeance, modifActesSeance: IModifActesSeance) => {
					this.modifActesSeance = modifActesSeance;
					this.seance = seance;
					this.typeModif = ETypeModifSeance.Actes;
					this.openOccurence();
				},
				cancel: () => this.closeMenu()
			};
			this.svcPanneau.open(panneauTitle, panneauContent, panneauInputs);
		}
	}


	public openMenuSoins(): void {
		//open menu soins
		this.closeMenu()
		//open menu edit actes
		if (this.isMobile) {
			this.svcPanneau.close();
			this.svcDrawerPopover.open("Soins réalisés",
				"60%",
				null,
				SoinsRealisesComponent,
				{
					seance: this.seance,
					confirmSoins: (seance) => {
						this.confirmSoins(seance);
					},
					deleteConfirmSoins: (seance) => {
						this.deleteConfirmSoins(seance);
					},
					cancel: () => this.closeMenu(),
				})
		} else {
			const panneauTitle = "Soins réalisés";
			const panneauContent = SoinsRealisesComponent;
			const panneauInputs = {
				seance: this.seance,
				confirmSoins: (seance) => {
					this.confirmSoins(seance);
				},
				deleteConfirmSoins: (seance) => {
					this.deleteConfirmSoins(seance);
				},
				cancel: () => this.closeMenu(),
			};
			this.svcPanneau.open(panneauTitle, panneauContent, panneauInputs);

		}
	}

	public editPatient(): void {
		this.closeMenu();
		this.svcDrawerPopover.open(
			"Affectation soins",
			"60%",
			null,
			PatientIndisponibleComponent,
			{
				seance: this.seance,
				confirm: (commentaire) => {
					this.confirmPatientIndispo(commentaire);
				},
				deletePatientIndispo: () => {
					this.deletePatientDispo();
				},
				cancel: () => this.closeMenu(),
			}
		);
	}

	public editLocal(): void {
		//open menu changer local
	}

	public afficheOrdo(): void {
		this.closeMenu()
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this.router.navigate(['ordonnances/edit/', this.seance.traitementId], { state: { tabSelected: "Préscription" } });
		});
	}

	public ouvrirDossierPatient(): void {
		this.closeMenu()
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this.router.navigate(["patients", this.seance.patientId])
		});
	}

	handleSupprimerClick(event: MouseEvent) {
		this.showPopover = true;
		this.svcDrawerPopover.open(
			"Confirmation de suppression",
			"50%",
			this.anchorElement?.nativeElement,
			ConfirmationSuppressionComponent,
			{
				onConfirm: () => this.onDeleteSeance(),
				onCancel: () => this.closeMenu(),
			},
			() => (this.showPopover = false)
		);
	}

	handleFacturationClick(event: Event)
	{
		this.closeMenu()
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this.router.navigate(["facturation/controle", this.seance.traitementId])
		});
	}

	public onDeleteSeance() {
		this.svcSeance.deleteSeance(this.seance).subscribe(
			() => this.closeMenu(),
			//todo ajout d'un snackbar pour notification du delete
			(error) => {
				console.error('Erreur lors de la suppression de la séance', error);
			}
		);
	}

	public closeMenu(): void {
		this.showPopover = false;
		this.svcDrawerPopover.close();
		this.svcPanneau.close();
	}


	openOccurence(): void {
		this.closeMenu();
		const drawerPanneauTitle = "Quelle(s) séance(s) modifier";
		const drawerPanneauContent = ChoixSeanceComponent;
		const drawerPanneauInputs = {
			seance: this.seance,
			onSave: this.confirmOccurence,
			onCancel: this.cancelOccurence,
		};
		if (this.isMobile) {
			this.svcPanneau.close();
			this.svcDrawerPopover.open(
				drawerPanneauTitle,
				"85%",
				null,
				drawerPanneauContent,
				drawerPanneauInputs
			);
		} else {
			this.svcPanneau.open(
				drawerPanneauTitle,
				drawerPanneauContent,
				drawerPanneauInputs
			);
		}
	}

	confirmOccurence = (option) => {
		switch (option) {
			case ESeanceOptionModification.Seance:
				this.updateSeance();
				break;
			case ESeanceOptionModification.AllSeances:
				this.updateSeances(false);
				break;
			case ESeanceOptionModification.OnlyFutur:
				this.updateSeances(true);
				break;
		}
		this.closeMenu();
	};

	cancelOccurence = () => {
		this.closeMenu();
	};

}
