import { Exclude, Expose } from 'class-transformer';
import { ObjectHelper } from '../../../helpers/objectHelper';
import { ModelMatch } from '../../utils/models/decorators/model-match.decorator';
import { IPrestationLine } from './iprestation-line';

@ModelMatch((poData: IPrestationLine) => !!poData, PrestationLine)
export class PrestationLine implements IPrestationLine {

	//#region PROPERTIES

	/** @implements */
	public ref: string;
	/** @implements */
	public price: number;
	/** @implements */
	public category?: string;
	/** @implements */
	public group?: string;
	/** @implements */
	public description?: string;
	/** @implements */
	public quantity?: number;
	/** @implements */
	public extraCharge?: number;
	/** @implements */
	public deleted?: boolean;
	@Exclude({ toPlainOnly: true })
	private mnDiscountRate: number;
	/** @implements */
	@Expose()
	public get discountRate(): number {
		return this.mnDiscountRate ?? 0;
	}
	public set discountRate(pnDiscountRate: number) {
		if (typeof pnDiscountRate !== "undefined" && pnDiscountRate !== this.mnDiscountRate) {
			if (pnDiscountRate > 1)
				this.mnDiscountRate = 1;
			else if (pnDiscountRate < 0)
				this.mnDiscountRate = 0;
			else
				this.mnDiscountRate = pnDiscountRate;
		}
	}

	public get total(): number {
		return (((this.quantity ?? 1) * this.price) + (this.extraCharge ?? 0)) * Math.abs(1 - (this.discountRate));
	}

	/** @implements */
	public isAldExonerante: boolean;

	//#endregion

	//#region METHODS

	constructor(poPrestationLine?: IPrestationLine) {
		if (poPrestationLine)
			ObjectHelper.assign(this, poPrestationLine);
	}

	/** Réinitialise la ligne de facture. */
	public reset(): PrestationLine {
		return this;
	}

	//#endregion

}
