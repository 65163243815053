<osapp-header-modal title="Facturer" [customClose]="customClose">
</osapp-header-modal>

<ion-content>
	<div *ngIf="invoicesToCreate" class="main-ctn">
		<div class="info">
			<ion-label *ngIf="!invoicesCreated">
				{{ invoicesToCreate.length }}
				{{ invoicesToCreate.length > 1 ? "factures vont être générées" : "facture va être générée" }} :
			</ion-label>
			<ng-container *ngIf="invoicesCreated">
				<ion-label>
					{{ invoicesToCreate.length }}
					{{ invoicesToCreate.length > 1 ? "factures générées" : "facture générée" }}
				</ion-label>
				<ion-label class="important">
					Sélectionnez la facture à tarifer :
				</ion-label>
			</ng-container>
		</div>

		<div *ngFor="let invoice of invoicesToCreate" class="invoice" (click)="goToInvoice(invoice)">
			<div class="invoice-detail">
				<ion-label>Facture</ion-label>
				<ion-label *ngIf="ordonnancesById.get(invoice.ordonnanceId) as ordonnance; else noOrdonnance"
					class="invoice-label">
					Ordonnance du {{ ordonnance.prescriptionDate | dateWithLocale: 'fr-FR' : 'dd/MM/yyyy' }}
				</ion-label>
				<ng-template #noOrdonnance>
					<ion-label class="invoice-label">Aucune ordonnance</ion-label>
				</ng-template>
				<ion-label class="date-invoice">
					Du {{ invoice.minDate | dateWithLocale: 'fr-FR' : 'dd/MM/yyyy'}}
					au {{ invoice.maxDate | dateWithLocale: 'fr-FR' : 'dd/MM/yyyy'}}
				</ion-label>
			</div>
			<div class="avatar">
				<avatar [src]="getContactAvatar(contactsById.get(invoice.intervenantId))" cssClass="round">
				</avatar>
			</div>
		</div>
	</div>
</ion-content>

<ion-fab *ngIf="!invoicesCreated" vertical="bottom" horizontal="end" slot="fixed">
	<ion-fab-button color="success" (click)="bill()" title="Valider">
		<ion-icon name="checkmark"></ion-icon>
	</ion-fab-button>
</ion-fab>