import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertButton } from '@ionic/core';
import { IDynHostComponent } from '@osapp/components/dynHost/IDynHost.component';
import { DynamicPageComponent } from '@osapp/components/dynamicPage/dynamicPage.component';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { MapHelper } from '@osapp/helpers/mapHelper';
import { StoreHelper } from '@osapp/helpers/storeHelper';
import { UserHelper } from '@osapp/helpers/user.helper';
import { ConfigData } from '@osapp/model';
import { EBarElementDock } from '@osapp/model/barElement/EBarElementDock';
import { EBarElementPosition } from '@osapp/model/barElement/EBarElementPosition';
import { IBarElement } from '@osapp/model/barElement/IBarElement';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { ObservableArray } from '@osapp/modules/observable/models/observable-array';
import { C_ADMINISTRATORS_ROLE_ID, Roles } from '@osapp/modules/permissions/services/permissions.service';
import { IFavorites } from '@osapp/modules/preferences/favorites/model/IFavorites';
import { FavoritesService } from '@osapp/modules/preferences/favorites/services/favorites.service';
import { ApplicationService } from '@osapp/services/application.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '@osapp/services/loading.service';
import { SlideboxService } from '@osapp/services/slidebox.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { C_PREFIX_ACT } from '../../../../app/app.constants';
import { EPlace } from '../../../../model/EPlace';
import { ESecteur } from '../../../../model/ESecteur';
import { EStatusSeance } from '../../../../model/EStatusSeance';
import { IDeplacementByProfession } from '../../../../model/IDeplacementByProfession';
import { Seance } from '../../../../model/Seance';
import { TraitementSlideParentBase } from '../../../../model/TraitementSlideParentBase';
import { IdlModalService } from '../../../../services/idl-modal.service';
import { IdlApplicationService } from '../../../../services/idlApplicationService.service';
import { IndemniteService } from '../../../../services/indemnite.service';
import { SeanceService } from '../../../../services/seance.service';
import { TraitementService } from '../../../../services/traitement.service';
import { EResumeActeMode } from '../../../actes/model/EResumeActeMode';
import { PatientsService } from '../../../patients/services/patients.service';
import { EIndemniteType } from '../../model/EIndemniteType';
import { IIKParams } from '../../model/IIKParams';
import { IIKResponse } from '../../model/IIKResponse';
import { Indemnite } from '../../model/Indemnite';
import { TraitementDataManager } from '../../traitement-data-manager.service';
import { TraitementSlideComponentBase } from '../traitement-slide-component-base.component';

interface IIndemniteSeance {
	seance: Seance;
	IFIEligible: boolean;
}

type IScrollItem = string | IIndemniteSeance;

@Component({
	selector: "indemnites",
	templateUrl: "./indemnites.component.html",
	styleUrls: ["./indemnites.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndemnitesComponent extends TraitementSlideComponentBase implements IDynHostComponent, OnInit {

	//#region FIELDS

	/** Service contenant toutes les méthodes partagées pour IDELizy. */
	private isvcApplication: IdlApplicationService;
	/** Indique si la première modale d'activation des ik a été validée ou non (pour ne pas réafficher la modale à chaque fois). */
	private mbIsFirstIKModalDone = false;

	//#endregion

	//#region PROPERTIES

	@Input() public params: any;

	/** Map contenant un tableau de séances (valeur) pour un jour donné (clé). */
	public indemniteSeancesByDayMap: Map<string, IIndemniteSeance[]> = new Map();
	public ikIndemnite: Indemnite;
	/** Distance après abattement. */
	public ikDistance = 0;
	/** Tableau des documents de déplacements par profession. */
	public deplacementsByProfession: IDeplacementByProfession[];
	/** Indique si on peut sélectionner les cases 'toutes' des indemnités ; `false` si toutes les séances sont validées ou annulées. */
	public canSelectAllIndemnites: boolean;

	/** Retourne tous les objets de la Map sous forme d'un tableau à une dimension. */
	private get indemniteSeancesMapValues(): IIndemniteSeance[] {
		return ArrayHelper.flat(MapHelper.valuesToArray(this.indemniteSeancesByDayMap));
	}
	/** Retourne toutes les séances de la Map sous forme d'un tableau à une dimension. */
	private get seancesMapValues(): Seance[] {
		return this.indemniteSeancesMapValues.map((poItem: IIndemniteSeance) => poItem.seance);
	}

	/** Indique si la coche 'tous les IFD' est (dé)cochable : `true` si au moins une séance est présente, `false` sinon. */
	public get allIFDEnabled(): boolean {
		return this.indemniteSeancesByDayMap.size > 0;
	}
	/** Indique si la coche 'tous les IFI' est (dé)cochable : `true` si au moins une séance est éligible à l'IFI, `false` sinon. */
	public get allIFIEnabled(): boolean {
		return this.indemniteSeancesByDayMap.size > 0;
	}
	/** Indique si tous les IFD sont sélectionnés. */
	public get allIFDSelected(): boolean {
		const laActiveIndemnites: IIndemniteSeance[] = this.indemniteSeancesMapValues.filter((poItem: IIndemniteSeance) => !this.isIndemniteDisabled(poItem.seance));
		return ArrayHelper.hasElements(laActiveIndemnites) &&
			laActiveIndemnites.every((poItem: IIndemniteSeance) => this.hasIFDIndemnite(poItem.seance));
	}
	/** Indique si tous les IFI sont sélectionnés. */
	public get allIFISelected(): boolean {
		const laActiveIndemnites: IIndemniteSeance[] = this.indemniteSeancesMapValues.filter((poItem: IIndemniteSeance) => !this.isIndemniteDisabled(poItem.seance));
		return ArrayHelper.hasElements(laActiveIndemnites) &&
			laActiveIndemnites.every((poItem: IIndemniteSeance) => this.hasIFIIndemnite(poItem.seance));
	}
	/** Indique si tous les IK sont sélectionnés. */
	public get allIKSelected(): boolean {
		const laActiveIndemnites: IIndemniteSeance[] = this.indemniteSeancesMapValues.filter((poItem: IIndemniteSeance) => !this.isIndemniteDisabled(poItem.seance));
		return ArrayHelper.hasElements(laActiveIndemnites) &&
			laActiveIndemnites.every((poItem: IIndemniteSeance) => this.hasIKIndemnite(poItem.seance));
	}

	public acteDisplayMode: EResumeActeMode;
	public readonly virtualScrollItems = new ObservableArray<IScrollItem>();

	@Roles(C_ADMINISTRATORS_ROLE_ID)
	public get canEditIndemnite(): boolean {
		return true;
	}

	//#endregion

	//#region METHODS

	constructor(
		private ioRouter: Router,
		private isvcIndemniteModal: IdlModalService,
		public readonly isvcIndemnite: IndemniteService,
		private isvcUiMessage: UiMessageService,
		private isvcFavorites: FavoritesService,
		psvcPatients: PatientsService,
		psvcTraitementDataManager: TraitementDataManager,
		psvcSeance: SeanceService,
		poParentComponent: TraitementSlideParentBase,
		psvcTraitement: TraitementService,
		psvcLoading: LoadingService,
		poParentPage: DynamicPageComponent<ComponentBase>,
		poChangeDetectorRef: ChangeDetectorRef,
		psvcSlidebox: SlideboxService,
		psvcAppShared: ApplicationService) {

		super(
			psvcTraitementDataManager,
			psvcSeance,
			poParentComponent,
			psvcTraitement,
			psvcLoading,
			psvcPatients,
			poParentPage,
			poChangeDetectorRef,
			psvcSlidebox
		);

		this.isvcApplication = psvcAppShared as IdlApplicationService;
	}

	public ngOnInit(): void {
		super.ngOnInit();

		// Initialise le mode d'affichage du résumé des actes.
		this.isvcFavorites.get(C_PREFIX_ACT, true)
			.pipe(
				tap((poPreferences: IFavorites) => {
					if (poPreferences?.display) {
						this.acteDisplayMode = EResumeActeMode[poPreferences.display];
						this.fillScrollItems(this.indemniteSeancesByDayMap);
						this.detectChanges();
					}
				}),
				takeUntil(this.destroyed$)
			).subscribe();

		this.ikDistance = this.isvcIndemnite.calculateAbattementDistance(this.traitement.deplacement);

		this.defineSecteurPrice()
			.pipe(
				switchMap(() => this.traitement.seances$),
				tap((paSeances: Seance[]) => {
					this.indemniteSeancesByDayMap.clear();
					paSeances.forEach((poSeance: Seance) => {
						if (poSeance.status !== EStatusSeance.canceled)
							this.fillIndemniteSeancesByDayMap(poSeance);
					});

					this.isvcIndemnite.initIkIndemnite(this.traitement, this.seancesMapValues, this.deplacementsByProfession);
					this.canSelectAllIndemnites = this.seancesMapValues.some((poSeance: Seance) => !poSeance.isProtected);
					this.fillScrollItems(this.indemniteSeancesByDayMap);

					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private getIndemniteTypeFromSectorType(peSectorType: ESecteur): EIndemniteType {
		switch (peSectorType) {
			case ESecteur.Plaine:
				return EIndemniteType.IK;

			case ESecteur.Montagne:
				return EIndemniteType.IKM;

			case ESecteur.PiedOuSki:
				return EIndemniteType.IKS;
		}
	}

	private fillScrollItems(poIndemniteSeancesByDayMap: Map<string, IIndemniteSeance[]>): void {
		const laNewItems: IScrollItem[] = [];
		poIndemniteSeancesByDayMap.forEach((paIndemniteSeances: IIndemniteSeance[], psKey: string) => {
			if (ArrayHelper.hasElements(paIndemniteSeances)) {
				laNewItems.push(psKey);
				paIndemniteSeances.forEach((poIndemniteSeance: IIndemniteSeance) => laNewItems.push(poIndemniteSeance));
			}
		});

		let lbHasChanges = false;
		const lnMaxLength: number = Math.max(laNewItems.length, this.virtualScrollItems.length);
		for (let lnIndex = 0; lnIndex < lnMaxLength; ++lnIndex) {
			const loNewScrollItem: IScrollItem = laNewItems[lnIndex];
			const loOldScrollItem: IScrollItem = this.virtualScrollItems[lnIndex];

			if (typeof loNewScrollItem === "string" && typeof loOldScrollItem === "string")
				lbHasChanges = loNewScrollItem !== loOldScrollItem;
			else if (typeof loNewScrollItem === "object" && typeof loOldScrollItem === "object") {
				const lnIndemniteMaxLength: number = Math.max(loNewScrollItem.seance.indemnites.length, loOldScrollItem.seance.majorations.length);
				for (let lnIndemniteIndex = 0; lnIndemniteIndex < lnIndemniteMaxLength; lnIndemniteIndex++) {
					const loNewIndemnite: Indemnite = loNewScrollItem.seance.indemnites[lnIndemniteIndex];
					const loOldIndemnite: Indemnite = loOldScrollItem.seance.indemnites[lnIndemniteIndex];

					lbHasChanges = loNewIndemnite?.type !== loOldIndemnite?.type || loNewIndemnite?.disabled !== loOldIndemnite?.disabled;
					if (lbHasChanges)
						break;
				}
			}
			else
				lbHasChanges = true;

			if (lbHasChanges)
				break;
		}

		if (lbHasChanges)
			this.virtualScrollItems.resetArray(laNewItems);
	}

	/** @override */
	protected createToolbarData(): Array<IBarElement> {
		const lsCircle = "circle";
		const lsFabButton = "fabButton";

		return [
			{
				id: lsCircle,
				component: lsFabButton,
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.left,
				icon: "arrow-back",
				onTap: this.mfPreviousSlide,
				name:"Précédent"
			},
			{
				id: lsCircle,
				component: lsFabButton,
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.middle,
				icon: "timetable",
				onTap: () => this.ioRouter.navigate([
					"tournees",
					DateHelper.toDateUrl(this.traitement.beginDate)
				]),
				name:"Tournées"
			},
			{
				id: lsCircle,
				component: lsFabButton,
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.right,
				icon: "arrow-forward",
				onTap: this.mfNextSlide,
				name:"Suivant"
			}
		];
	}

	private fillIndemniteSeancesByDayMap(poSeance: Seance): void {
		const loIndemniteSeance: IIndemniteSeance = {
			seance: poSeance,
			IFIEligible: this.isvcSeance.isIFIEligible(poSeance)
		};
		const lsSeanceDate: string = this.getSeanceDate(poSeance); // Pour avoir une majuscule.

		if (this.indemniteSeancesByDayMap.has(lsSeanceDate))
			ArrayHelper.replaceElementByFinder(this.indemniteSeancesByDayMap.get(lsSeanceDate),
				(poIndemniteSeance: IIndemniteSeance) => poIndemniteSeance.seance.equals(loIndemniteSeance.seance), loIndemniteSeance);
		else
			this.indemniteSeancesByDayMap.set(lsSeanceDate, [loIndemniteSeance]);
	}

	private getSeanceDate(poSeance: Seance): string {
		const lsSeanceDate: string = DateHelper.transform(poSeance.startDate, ETimetablePattern.EEEE_dd_MMMM_yyyy);
		return lsSeanceDate[0].toUpperCase() + lsSeanceDate.substring(1);// Pour avoir une majuscule.
	}

	/** Récupère toutes les séances éligibles à l'IFI d'une journée donnée.
	 * @param psDate Date de la journée parmi laquelle récupérer les séances éligibles à l'IFI.
	 */
	private getEligibleSeancesFromDate(psDate: string): IIndemniteSeance[] {
		return this.indemniteSeancesByDayMap.get(psDate).filter((poItem: IIndemniteSeance) => poItem.IFIEligible);
	}

	/** Récupère le nombre de séances ayant une IFI pour une journée donnée.
	 * @param paIndemniteSeances Tableau dans lequel compter le nombre de séances ayant une IFI.
	 */
	private getIFICounter(paIndemniteSeances: IIndemniteSeance[]): number {
		return paIndemniteSeances.filter((poItem: IIndemniteSeance) => this.isvcIndemnite.isIndemniteEnabled(poItem.seance.indemnites, EIndemniteType.IFI)).length;
	}

	/** Retourne `true` si on doit désactiver la (dé)coche de l'IFI d'une séance, `false` sinon.
	 * Désactivation dans le cas où la séance n'est pas éligible à l'IFI ou si elle n'a pas d'IFI et que le nombre maximum d'IFI journalier est atteint.
	 * @param psDate Date de la journée parmi laquelle récupérer les séances éligibles à l'IFI.
	 */
	public mustDisableSelection(poIndemniteSeance: IIndemniteSeance): boolean {
		return (!this.isvcIndemnite.isIndemniteEnabled(poIndemniteSeance.seance.indemnites, EIndemniteType.IFI) &&
			this.getIFICounter(this.getEligibleSeancesFromDate(this.getSeanceDate(poIndemniteSeance.seance))) === IndemniteService.C_MAX_DAILY_IFI);
	}

	/** Définit le prix du secteur pour le déplacement. */
	private defineSecteurPrice(): Observable<void> {
		// On met à jour le tarif si on n'est PAS en mode manuel.
		if (!this.traitement.deplacement.isManualPrice) {
			return this.isvcIndemnite.getDeplacementsByProfession(this.isvcApplication.profession)
				.pipe(
					catchError(poError => this.calculatePriceError(poError)),
					tap((paResults: IDeplacementByProfession[]) => this.deplacementsByProfession = paResults),
					mergeMap((paResults: IDeplacementByProfession[]) => {
						const lsCorrectDeplacementId = `IK-${this.isvcApplication.profession}-${this.traitement.deplacement.sectorType}`;
						// Récupération du tarif en fonction de la zone géographique.
						const loDeplacement: IDeplacementByProfession = paResults.find((poItem: IDeplacementByProfession) => poItem.id === lsCorrectDeplacementId);
						const lnSectorPrice: number = loDeplacement ? loDeplacement.tarif[ConfigData.geoZoneApp - 1] : -1;

						// Si le prix du secteur est valide et différent de celui en cours pour le traitement.
						if (lnSectorPrice > -1 && lnSectorPrice !== this.traitement.deplacement.sectorPrice) {
							this.traitement.deplacement.sectorPrice = lnSectorPrice;
							this.onTraitementChanged();
						}

						this.detectChanges();

						return of(undefined);
					})
				);
		}
		else
			return of(undefined);
	}

	/** Affiche un message d'erreur si la récupération des déplacements par profession a échoué. */
	private calculatePriceError(poError: any): Observable<never> {
		console.error("IDL.DPCM.C:: Erreur récupération tarifs déplacements : ", poError);

		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({
				message: "Erreur lors de la récupération des tarifs de déplacements.",
				header: "Erreur",
				buttons: [{ text: "Ok" } as AlertButton]
			})
		);

		return EMPTY;
	}

	/** Permet de boucler avec le `*ngFor` en conservant les clé par ordre d'insertion (par date)
	 * plutôt que l'ordre par défaut du pipe `keyvalue` (ordre alphabétique des clés).
	 */
	public conserveInsertionSort(poItemA: KeyValue<string, Seance[]>, poItemB: KeyValue<string, Seance[]>): number {
		return 1;
	}

	/** Enregistre les changements opérés sur les séances. */
	private update(): void {
		this.detectChanges();
		this.onTraitementChanged();
	}

	/** Impacte les changements après avoir (dé)coché la case 'toutes' des IFD. */
	public onAllIFDSelectionClicked(): void {
		if (this.allIFDEnabled && this.canSelectAllIndemnites) {
			this.isvcTraitement.addIndemnite(
				this.traitement,
				this.seancesMapValues,
				{
					createDate: new Date,
					createUserContactId: UserHelper.getUserContactId(),
					id: EIndemniteType.IFD,
					disabled: this.allIFDSelected
				},
				this.deplacementsByProfession
			);

			this.update();
		}
	}

	/** Impacte les changements après avoir demandé de (dé)cocher la case IFD d'une séance.
	 * @param poIndemniteSeance IndemniteSeance dont la case IFD veut être (dé)cochée.
	 */
	public onIFDSelectionClicked(poIndemniteSeance: IIndemniteSeance): void {
		if ((!poIndemniteSeance.seance.isProtected || this.canEditIndemnite) && poIndemniteSeance.seance.place === EPlace.home) { // Si la séance est protégée et qu'elle ne se déroule pas à domicile, on ne peut pas modifier.
			this.isvcTraitement.addIndemnite(
				this.traitement,
				this.seancesMapValues,
				{
					createDate: new Date,
					createUserContactId: UserHelper.getUserContactId(),
					id: EIndemniteType.IFD,
					disabled: this.isvcIndemnite.isIndemniteEnabled(poIndemniteSeance.seance.indemnites, EIndemniteType.IFD),
					matcher: poIndemniteSeance.seance.startDate
				},
				this.deplacementsByProfession
			);

			this.update();
		}
	}

	/** Impacte les changements après avoir (dé)coché la case 'toutes' des IFI. */
	public onAllIFISelectionClicked(): void {
		if (this.allIFIEnabled && this.canSelectAllIndemnites) {
			this.isvcTraitement.addIndemnite(
				this.traitement,
				this.seancesMapValues,
				{
					createDate: new Date,
					createUserContactId: UserHelper.getUserContactId(),
					id: EIndemniteType.IFI,
					disabled: this.allIFISelected
				},
				this.deplacementsByProfession
			);

			this.update();
		}
	}

	/** Impacte les changements après avoir demander de (dé)cocher la case IFI d'une séance.
	 * @param poIndemniteSeance IndemniteSeance dont la case IFI veut être (dé)cochée.
	 */
	public onIFISelectionClicked(poIndemniteSeance: IIndemniteSeance): void {
		if ((!poIndemniteSeance.seance.isProtected || this.canEditIndemnite) && poIndemniteSeance.seance.place === EPlace.home) { // Si la séance est protégée et qu'elle ne se déroule pas à domicile, on ne peut pas modifier.
			if (this.mustDisableSelection(poIndemniteSeance)) {
				this.isvcUiMessage.showMessage(
					new ShowMessageParamsPopup({
						message: `La limite de ${IndemniteService.C_MAX_DAILY_IFI} IFI journalière est atteinte !<br/>Si vous souhaitez ajouter celle-ci, veuillez d'abord en supprimer une du même jour.`
					})
				);
			}
			else {
				this.isvcTraitement.addIndemnite(
					this.traitement,
					this.seancesMapValues,
					{
						createDate: new Date,
						createUserContactId: UserHelper.getUserContactId(),
						id: EIndemniteType.IFI,
						disabled: this.isvcIndemnite.isIndemniteEnabled(poIndemniteSeance.seance.indemnites, EIndemniteType.IFI),
						matcher: poIndemniteSeance.seance.startDate
					},
					this.deplacementsByProfession
				);

				this.update();
			}
		}
	}

	/** Impacte les changements après avoir (dé)coché la case 'toutes' des IK. */
	public onAllIKSelectionClicked(): void {
		if (this.canSelectAllIndemnites) {

			const lfAllIKSelection: () => void = () => {
				this.isvcTraitement.addIndemnite(
					this.traitement,
					this.seancesMapValues,
					{
						createDate: new Date,
						createUserContactId: UserHelper.getUserContactId(),
						id: this.getIkIndemniteType(),
						disabled: this.allIKSelected
					},
					this.deplacementsByProfession
				);
			};

			// Si tout est sélectionné, c'est qu'on désélectionne, donc faut inverser la condition.
			this.changeIKSelection(lfAllIKSelection, !this.allIKSelected);
		}
	}

	private getIkIndemniteType(): EIndemniteType {
		return this.ikIndemnite?.type ?? this.getIndemniteTypeFromSectorType(this.traitement.deplacement.sectorType);
	}

	/** Impacte les changements après avoir demander de (dé)cocher la case IK d'une séance.
	 * @param poIndemniteSeance IndemniteSeance dont la case IK veut être (dé)cochée.
	 */
	public onIKSelectionClicked(poIndemniteSeance: IIndemniteSeance): void {
		if ((!poIndemniteSeance.seance.isProtected || this.canEditIndemnite) && poIndemniteSeance.seance.place === EPlace.home) { // Si la séance est protégée et qu'elle ne se déroule pas à domicile, on ne peut pas modifier.
			const lfIKSelection: () => void = () => {
				this.isvcTraitement.addIndemnite(
					this.traitement,
					this.seancesMapValues,
					{
						createDate: new Date,
						createUserContactId: UserHelper.getUserContactId(),
						id: this.getIkIndemniteType(),
						disabled: this.hasIKIndemnite(poIndemniteSeance.seance),
						matcher: poIndemniteSeance.seance.startDate
					},
					this.deplacementsByProfession
				);
			};

			// Si c'est sélectionné, c'est qu'on désélectionne, donc faut inverser la condition.
			this.changeIKSelection(lfIKSelection, !this.hasIKIndemnite(poIndemniteSeance.seance));
		}
	}

	/** Applique la fonction de (dé)sélection, puis ouvre une modale si nécessaire pour valider ou annuler l'action.
	 * @param pfSelection Fonction à appeler pour mettre à jour la (dé)sélection (ou l'annuler).
	 * @param pbIsSelection Indique s'il s'agit d'une sélection `true` ou d'une désélection `false`.
	 */
	private changeIKSelection(pfSelection: () => void, pbIsSelection: boolean): void {
		if (!this.mbIsFirstIKModalDone && pbIsSelection) { // On affiche la modale que dans le cas d'une première sélection.
			this.openIKModal(false, false) // Approbation, on change le booléen et on réalise la sélection ; annulation, on annule la sélection.
				.pipe(
					tap((poResponse?: IIKResponse) => {
						if (poResponse) {
							this.mbIsFirstIKModalDone = true;
							pfSelection();
							this.update();
							this.detectChanges();
						};
					})
				)
				.subscribe();
		}
		else {
			pfSelection();
			this.update();
			this.detectChanges();
		}
	}

	/** Ouvre la modale des IKs et retourne les résultats.
	 * @param pbHasToFilterResponse Indique s'il faut filtrer les absences de réponse de la modale ou non, true par défaut.
	 */
	private openIKModal(pbHasToFilterResponse: boolean = true, pbSave: boolean): Observable<IIKResponse> {
		const loParams: IIKParams = {
			deplacement: this.traitement.deplacement,
			profession: this.isvcApplication.profession,
			geoZone: ConfigData.geoZoneApp
		};

		return this.isvcIndemniteModal.openIndemniteKilometriqueModal(loParams, pbHasToFilterResponse)
			.pipe(
				tap((poResponse?: IIKResponse) => {
					if (poResponse) {
						this.traitement.deplacement = poResponse.deplacement;
						this.ikIndemnite = poResponse.indemnite;
						this.ikDistance = this.isvcIndemnite.calculateAbattementDistance(this.traitement.deplacement);
						StoreHelper.makeDocumentDirty(this.traitement);

						// Pour chaque séance, si elle n'est pas validée, on met à jour les IK avec la nouvelle IK obtenue depuis la modale.
						this.indemniteSeancesMapValues.forEach((poIndemniteSeance: IIndemniteSeance) => {
							if (!poIndemniteSeance.seance.isProtected && this.hasIKIndemnite(poIndemniteSeance.seance))
								ArrayHelper.replaceElementByFinder(poIndemniteSeance.seance.indemnites, (poItem: Indemnite) => poItem.isIKType, this.ikIndemnite);
						});

						if (pbSave)
							this.update();
						this.detectChanges();
					}
				}),
				takeUntil(this.destroyed$)
			);
	}

	/** Ouvre la modale des IKs suite à un clic. */
	public onOpenIKModalClicked(): void {
		this.openIKModal(true, true).subscribe();
	}

	/** Retourne `true` si la séance en paramètre contient une indemnité de type IFD, `false` sinon. */
	public hasIFDIndemnite(poSeance: Seance): boolean {
		return this.isvcIndemnite.isIndemniteEnabled(poSeance.indemnites, EIndemniteType.IFD);
	}

	/** Retourne `true` si la séance en paramètre contient une indemnité de type IFI, `false` sinon. */
	public hasIFIIndemnite(poSeance: Seance): boolean {
		return this.isvcIndemnite.isIndemniteEnabled(poSeance.indemnites, EIndemniteType.IFI);
	}

	/** Retourne `true` si la séance en paramètre contient une indemnité de type IK, `false` sinon. */
	public hasIKIndemnite(poSeance: Seance): boolean {
		return this.isvcIndemnite.isIndemniteEnabled(poSeance.indemnites, [EIndemniteType.IKM, EIndemniteType.IK, EIndemniteType.IKS]);
	}

	/** Retourne `true` si les indemnités sont désactivées pour la séance. */
	public isIndemniteDisabled(poSeance: Seance): boolean {
		return (poSeance.isProtected && !this.canEditIndemnite) || poSeance.place === 'center';
	}

	public isDate(poItem: string | IIndemniteSeance): boolean {
		return typeof poItem === "string";
	}

	//#endregion

}
