import { AbstractControl, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { ConfigOption, FormlyFieldConfig } from '@ngx-formly/core';
import { InputFieldComponent } from '@osapp/components/forms/form/customFields/inputs/inputField.component';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { IContact, RouteComponent } from '@osapp/model';
import { PermissionsService } from '@osapp/modules/permissions/services/permissions.service';
import { InjectorService } from '@osapp/services/injector.service';
import { C_DESMOS_PERMISSION_ID } from '../app/app.constants';
import { IdlContactsHelper } from '../helpers/idl-contacts.helper';
import { PurchasePageComponent } from '../modules/achats/purchasesPage.component';
import { SearchActeComponent } from '../modules/actes/search-acte/search-acte.component';
import { AdeliHelper } from '../modules/adeli/helpers/adeli.helper';
import { ContactsBookPageComponent } from '../modules/contactsBook/contactsBookPage.component';
import { FinessFieldComponent } from '../modules/finess/components/finess-field/finess-field.component';
import { FinessHelper } from '../modules/finess/helpers/finess.helper';
import { ConfidentialityPageComponent } from '../modules/mentionsLegales/confidentialityPage.component';
import { TermsOfSalesPageComponent } from '../modules/mentionsLegales/termesOfSalesPage.component';
import { AdministratifWrapperComponent } from '../modules/patients/components/administratif-wrapper/administratif-wrapper.component';
import { ConstantesPageComponent } from '../modules/patients/components/constantes/constantes-page/constantes-page.component';
import { SurveillanceComponent } from '../modules/patients/components/constantes/surveillance/surveillance.component';
import { IdlConversationTabComponent } from '../modules/patients/components/conversation-tab/conversation-tab.component';
import { IdlConversationsListComponent } from '../modules/patients/components/conversationsList/idlConversationsList.component';
import { CouverturesListComponent } from '../modules/patients/components/couvertures/couvertures-list/couvertures-list.component';
import { DirectivesComponent } from '../modules/patients/components/directives/directives/directives.component';
import { ExerciceConditionFieldComponent } from '../modules/patients/components/exercice-condition-field/exercice-condition-field.component';
import { ADRiCallActionButtonComponent } from '../modules/patients/components/idlActionButtons/adri-call-action-button/adri-call-action-button.component';
import { ExportActionButtonComponent } from '../modules/patients/components/idlActionButtons/export-action-button/export-action-button.component';
import { ActionButtonFieldTraitementComponent } from '../modules/patients/components/idlActionButtons/idl-action-button-field-traitement/idl-action-button-field-traitement.component';
import { IdlActionButtonLectureCVComponent } from '../modules/patients/components/idlActionButtons/idl-action-button-lecture-cv/idl-action-button-lecture-cv.component';
import { MajPatientFsvActionButtonComponent } from '../modules/patients/components/idlActionButtons/maj-patient-fsv-action-button/maj-patient-fsv-action-button.component';
import { PatientComponent } from '../modules/patients/components/patient/patient.component';
import { RemplacantWrapperComponent } from '../modules/patients/components/remplacant-wrapper/remplacant-wrapper.component';
import { RppsFieldComponent } from '../modules/patients/components/rpps-field/rpps-field.component';
import { SignatureWrapperComponent } from '../modules/patients/components/signature-wrapper/signature-wrapper.component';
import { SpeExerciceConditionFieldComponent } from '../modules/patients/components/spe-exercice-condition-field/spe-exercice-condition-field.component';
import { TraitementsListComponent } from '../modules/patients/components/traitementsList/traitementsList.component';
import { TransmissionPageComponent } from '../modules/patients/components/transmissions/transmission-page/transmission-page.component';
import { TransmissionsListComponent } from '../modules/patients/components/transmissions/transmissions-list.component';
import { EExerciceCondition } from '../modules/patients/model/EExerciceCondition';
import { PatientsBookComponent } from '../modules/patients/patients-book.component';
import { SettingsPageComponent } from '../modules/reglages/settingsPage.component';
import { RppsHelper } from '../modules/rpps/helpers/rpps.helper';
import { SurveysPageComponent } from '../modules/sondages/surveysPage.component';
import { TraitementSlideComponent } from '../modules/traitement/traitement-slide-page.component';
import { TraitementsPageComponent } from '../modules/traitements/traitements-page.component';
import { W3pIFrameComponent } from '../modules/w3pIFramePage/w3pIFrame.component';

export abstract class IdlComponentRegister {

	//#region FIELDS

	/** Tableau de tous les composants d'Idlizy. */
	private static readonly maComponents: RouteComponent[] = [
		{ id: "confidentiality-page", componentType: ConfidentialityPageComponent },
		{ id: "contacts-book-page", componentType: ContactsBookPageComponent },
		{ id: "traitement-slide-page", componentType: TraitementSlideComponent },
		{ id: "traitements-page", componentType: TraitementsPageComponent },
		{ id: "patients-book-page", componentType: PatientsBookComponent },
		{ id: 'purchases-page"', componentType: PurchasePageComponent },
		{ id: "search-acte", componentType: SearchActeComponent },
		{ id: "settings-page", componentType: SettingsPageComponent },
		{ id: "surveys-page", componentType: SurveysPageComponent },
		{ id: "terms-of-sales-page", componentType: TermsOfSalesPageComponent },
		{ id: "w3p-iframe", componentType: W3pIFrameComponent },
		{ id: "traitements-list", componentType: TraitementsListComponent },
		{ id: "idl-conversations-list", componentType: IdlConversationsListComponent },
		{ id: "idl-conversations-tab", componentType: IdlConversationTabComponent },
		{ id: "transmissions-list", componentType: TransmissionsListComponent },
		{ id: "transmission-page", componentType: TransmissionPageComponent },
		{ id: "idl-constantes-page", componentType: ConstantesPageComponent },
		{ id: "idl-couvertures-list", componentType: CouverturesListComponent },
		{ id: "idl-patient-form", componentType: PatientComponent },
		{ id: "idl-surveillance", componentType: SurveillanceComponent },
		{ id: "idl-directives", componentType: DirectivesComponent },
	];

	//#endregion

	//#region METHODS

	/** Retourne tous les composants spécifiques d'Idlizy, avec leurs id. */
	public static getIdlRouteComponents(): RouteComponent[] {
		return this.maComponents;
	}

	//#endregion

}

//#region FORMLY

//#region RPPS

/** Fonction qui retourne un message (d'erreur) si le rpps est invalide. */
export function getRppsMessageErrorFunction(poError, poField: FormlyFieldConfig): string {
	return `Numéro RPPS non valide (11 chiffres uniquement).`;
};
/** Fonction qui retourne `true` si le champ RPPS est correct, `false` sinon. */
export function isRppsValidFunction(poControl: AbstractControl): boolean {
	return StringHelper.isBlank(poControl?.value) || RppsHelper.isValid(poControl?.value);
};
/** Fonction qui retourne un message (d'erreur) si le numéro ADELI est invalide. */
export function getADELIMessageErrorFunction(poError, poField: FormlyFieldConfig): string {
	return `Numéro ADELI non valide (ex: 758035885 ou 2B6021939).`;
};
/** Fonction qui retourne `true` si le champ ADELI est correct, `false` sinon. */
export function isADELIValidFunction(poControl: AbstractControl): boolean {
	return StringHelper.isBlank(poControl?.value) || AdeliHelper.isValid(poControl?.value);
};

/** Fonction qui retourne `true` si le champ FINESS est correct, `false` sinon. */
export function isFINESSValidFunction(poControl: AbstractControl): boolean {
	return StringHelper.isBlank(poControl?.value) || FinessHelper.isValid(poControl?.value);
};

/** Fonction qui retourne un message (d'erreur) si le numéro FINESS est invalide. */
export function getFINESSMessageErrorFunction(poError, poField: FormlyFieldConfig): string {
	return `Numéro FINESS non valide (ex: 758035885 ou 2B6021939).`;
};

export function mustHideIntervenantFieldFunction(poModel: IContact): boolean {
	return false;
};

/** Fonction qui retourne `true` si un médecin doit être caché, `false` sinon. */
export function mustHideMedcinFieldFunction(poModel: IContact): boolean {
	return false;
};

export function mustHideActionButtonFieldConversationFunction(): boolean {
	return false;
};

export function mustHideActionButtonFSVFunction(): boolean {
	return false;
};

/** Fonction qui retourne `true` si un champ desmos doit être caché, `false` sinon. */
export function mustHideDesmosFieldFunction(): boolean {
	return !InjectorService.instance.get(PermissionsService).evaluatePermission(C_DESMOS_PERMISSION_ID, "edit");
};

/** Fonction qui retourne `true` si un champ desmos doit être caché, `false` sinon. */
export function mustHideFSVFieldFunction(): boolean {
	return false;
};

/** Fonction qui retourne `true` si un champ agrément doit être caché, `false` sinon. */
export function mustHideAdminAgrementFieldFunction(poModel: IContact): boolean {
	return true;
};

//#endregion

//#region NIR

/** Fonction de validation du nir, retourne `null` si non renseigné, un objet d'erreur sinon. */
export function nirValidatorFunction(poFormControl: UntypedFormControl): ValidationErrors {
	const lsNir: string = poFormControl.value;

	if (StringHelper.isBlank(lsNir)) // Si nir vide alors le champ n'est pas obligatoire.
		return null;
	else // Si le nir est renseigné, il faut qu'il soit valide pour que le champ soit valide.
		return IdlContactsHelper.checkNIR(poFormControl.value) ? null : { nirMessage: true };
};

//#endregion

//#endregion

/** Objet de configuration spécifiques à IDL concernant les formulaires (champs, validateurs, ...). */
export const IDL_FORMLY_CONFIG_OPTION: ConfigOption = {
	types: [
		{
			name: "exportActionButton",
			component: ExportActionButtonComponent,
			defaultOptions: {
				hideExpression: mustHideDesmosFieldFunction
			}
		},
		{
			name: "ADRiCallActionButton",
			component: ADRiCallActionButtonComponent,
			defaultOptions: {
				hideExpression: mustHideFSVFieldFunction
			}
		},
		{
			name: "MajPatientFsvActionButton",
			component: MajPatientFsvActionButtonComponent,
			defaultOptions: {
				hideExpression: mustHideAdminAgrementFieldFunction
			}
		},
		{ name: "actionButtonTraitement", component: ActionButtonFieldTraitementComponent },
		{
			name: "actionButtonLectureCV",
			component: IdlActionButtonLectureCVComponent,
			defaultOptions: {
				hideExpression: mustHideActionButtonFSVFunction
			}
		},
		{
			name: "adeli",
			component: InputFieldComponent,
			wrappers: ["form-field"],
			defaultOptions: {
				validators: {
					adeli: {
						expression: isADELIValidFunction,
						message: getADELIMessageErrorFunction
					}
				},
				validation: {
					show: true
				},
				hideExpression: mustHideIntervenantFieldFunction
			}
		},
		{
			name: "finess",
			component: FinessFieldComponent,
			wrappers: ["form-field"],
			defaultOptions: {
				validators: {
					adeli: {
						expression: isFINESSValidFunction,
						message: getFINESSMessageErrorFunction
					}
				},
			validation: {
					show: true
			},
			hideExpression: mustHideIntervenantFieldFunction,
			}
		},
		{
			name: "exerciceCondition",
			component: ExerciceConditionFieldComponent,
			wrappers: ["form-field"],
			defaultOptions: {
				hideExpression: mustHideMedcinFieldFunction
			}
		},
		{
			name: "codeSpecialite",
			component: SpeExerciceConditionFieldComponent,
			wrappers: ["form-field"],
			defaultOptions: {
				expressions: {
					hide: (field: FormlyFieldConfig) => field.form.value.exerciceCondition == EExerciceCondition.liberal,
				},
			}
		},
		{
			name: "rpps",
			component: RppsFieldComponent,
			wrappers: ["form-field"],
			defaultOptions: {
				validators: {
					rpps: {
						expression: isRppsValidFunction,
						message: getRppsMessageErrorFunction
					}
				},
				validation: {
					show: true
				},
				hideExpression: mustHideMedcinFieldFunction
			}
		},
	],
	wrappers: [
		{
			name: "administratif",
			component: AdministratifWrapperComponent
		},
		{
			name: "remplacant",
			component: RemplacantWrapperComponent
		},
		{
			name: "signature",
			component: SignatureWrapperComponent
		},
	],
	validators: [
		{ name: "nir", validation: nirValidatorFunction }
	],
	validationMessages: [
		{ name: "nirMessage", message: "Le numéro est incorrect." }
	]
};