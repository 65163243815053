import { coerceArray } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Keyboard } from '@capacitor/keyboard';
import { IonItemSliding } from '@ionic/angular';
import { DynamicPageComponent } from '../components/dynamicPage/dynamicPage.component';
import { ToolbarComponent } from '../components/toolbar/toolbar.component';
import { ArrayHelper } from '../helpers/arrayHelper';
import { ComponentBase } from '../helpers/ComponentBase';
import { LifeCycleObserverComponentBase } from '../helpers/LifeCycleObserverComponentBase';
import { IBarElement } from './barElement/IBarElement';
import { ESortOrder } from './ESortOrder';
import { IItemOption } from './forms/IItemOption';
import { ELifeCycleEvent } from './lifeCycle';
import { ILifeCycleEvent } from './lifeCycle/ILifeCycleEvent';
import { ISearchOptions } from './search/ISearchOptions';
import { IDataSource } from './store/IDataSource';
import { IStoreDocument } from './store/IStoreDocument';

@Component({ template: "" })
export abstract class ListComponentBase<T extends IStoreDocument> extends LifeCycleObserverComponentBase implements OnDestroy {

	//#region FIELDS

	/** Indique de quelle manière on veut trier les éléments. */
	protected meSortOrder: ESortOrder;
	/** Source de données pour les requêtes sur les bases de données. */
	protected moDataSource: IDataSource<T>;

	//#endregion

	//#region PROPERTIES

	/** Contient tous les documents. */
	protected maDocuments: T[];
	public get documents(): T[] {
		return this.maDocuments;
	}
	public set documents(paDocuments: T[]) {
		this.sortDocuments(paDocuments);
		this.maDocuments = paDocuments;
		this.onFilteredDocumentsChanged(Array.from(this.maDocuments)); // `Array.from` permet d'éviter des bugs de rafraîchissement.
	}

	/** Contient les documents filtrés. */
	public filteredDocuments: T[] = [];
	/** Options pour le composant de recherche. */
	public searchOptions: ISearchOptions<T>;
	/** Indique si on a des résultats après une recherche ou non. */
	public hasSearchResult = true;
	protected msSortKey: (keyof T)[];
	/** Clé désignant l'ordre de tri des documents. */
	public set sortKey(psSortKey: keyof T | (keyof T)[]) {
		if (psSortKey)
			this.msSortKey = coerceArray(psSortKey);
	}
	/** Clé désignant l'ordre de tri des documents. */
	public get sortKey(): keyof T {
		return this.msSortKey[0];
	}


	//#endregion

	//#region METHODS

	constructor(
		poParentPage: DynamicPageComponent<ComponentBase>,
		poChangeDetectorRef: ChangeDetectorRef) {

		super(poParentPage, poChangeDetectorRef);
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.getParentToolbar().clear(this.getInstanceId());
	}

	/** Traitement d'un événement du cycle de vie.
	 * ### ATTENTION : Les classes filles doivent appeler le super de cette méthode pour appliquer les modifs du dataContext !
	 * @param poEvent Événement de cycle de vie reçu qu'il faut traiter.
	 */
	protected onLifeCycleEvent(poEvent: ILifeCycleEvent): void {
		switch (poEvent.data.value) {

			case ELifeCycleEvent.viewBackTo:
				//! La taille des items est de 0 si la page active n'est pas celle-ci, il faut forcer son raffraîchissement pour recalculer la taille des items.
				// TODO Supprimer cette ligne lorsque les bugs sur le virtual scroll seront fixés par Ionic
				this.filteredDocuments = Array.from(this.filteredDocuments);
				this.detectChanges();
				break;
		}
	}

	/** Crée et retourne un talbeau de 'IBarElement' afin de pouvoir initialiser la toolbar du composant. */
	protected abstract createBarElements(): IBarElement[];

	/** Crée et retourne un objet d'options pour le composant de recherche qui s'appuiera sur les valeurs de cet objet. */
	protected abstract createSearchOptions(): ISearchOptions<T>;

	/** Prépare la source de données pour requêter en base de données.
	 * @param paArgs Paramètres nécessaires pour préparer la source de données, dépend du composant, peut varier de 0 à n paramètres.
	 */
	protected abstract prepareDataSource(...paArgs: any[]): void;

	/** Ferme le clavier. */
	protected closeKeyboard(): void {
		Keyboard.hide().catch(() => { });
	}

	/** Gère la toolbar du composant en l'ajoutant ou l'initialisant en fonction de sa présence ou non. */
	protected manageToolbar(): void {
		const loParentToolbar: ToolbarComponent = this.getParentToolbar();
		const lsComponentId: string = this.getInstanceId();

		// Si on n'a pas ajouté la toolbar, on peut l'initialiser.
		if (!loParentToolbar.eltContainer.some((poBarElement: IBarElement) => poBarElement.componentId === lsComponentId)) {
			const laElements: IBarElement[] = this.createBarElements();
			// Si le formList est instancié par une classe fille, il peut déjà y avoir une toolbar, il faut donc ajouter et non initialiser.
			loParentToolbar.hasElements() ? loParentToolbar.add(laElements, lsComponentId) : loParentToolbar.init(laElements, lsComponentId);
		}
	}

	/** Réception du changement du tableau des documents filtrés depuis le composant de recherche.
	 * @param paChanges Nouveau tableau contenant les documents filtrés.
	 */
	public onFilteredDocumentsChanged(paChanges: T[]): void {
		// Si les deux tableaux ne sont pas identiques, on modifie le tableau filtré.
		if (!ArrayHelper.areArraysFromDatabaseEqual(this.filteredDocuments, paChanges)) {
			this.filteredDocuments = paChanges;
			this.hasSearchResult = ArrayHelper.hasElements(this.filteredDocuments);
			this.detectChanges();
		}
	}

	/** Ouvre une nouvelle page pour afficher un document après un clic sur l'item associé.
	 * @param poDocument Document qu'on souhaite afficher dans une nouvelle page.
	 */
	public onItemClicked(poDocument: T): void {
		this.closeKeyboard();
	}

	/** Lance les traitements de l'option cliquée.
	 * @param poDocument Document sur lequel on a cliqué l'option à effectuer.
	 * @param psAction Action à effectuer.
	 * @param poItemSliding Objet d'options sur lequel on a cliqué.
	 * @param poOption Objet contenant les informations de l'objet d'options cliqué, optionnel.
	 */
	public onItemOptionClicked(poDocument: T, psAction: string, poItemSliding: IonItemSliding, poOption?: IItemOption): void {
		console.debug(`LCB.C:: itemOption cliqué sur l'instance '${this.getInstanceId()}' ; Action '${psAction} à réaliser sur le document :`, poDocument, "avec l'option :", poOption);
		this.openOrCloseItemSliding(poItemSliding);
	}

	/** Ouvre ou ferme un itemSliding en fonction de l'état avant slide.\
	 * On peut également stopper la propagation de l'événement de clic.
	 * @param poItemSliding Objet d'options qu'on veut ouvrir ou fermer (animation de swipe).
	 * @param poEvent Événement de clic à stopper si renseigné.
	 */
	public async openOrCloseItemSliding(poItemSliding: IonItemSliding, poEvent?: MouseEvent): Promise<void> {
		if (poEvent)
			poEvent.stopPropagation();	// Empêche la possible navigation vers l'item cliqué.

		// Si l'item est ouvert, la valeur est strictement supérieure à 0, sinon c'est que l'item est fermé.
		const lnAmountOpenPixels: number = await poItemSliding.getOpenAmount();

		if (lnAmountOpenPixels > 0) // Item ouvert, on veut le fermer
			poItemSliding.close();
		else // Item fermé, on veut l'ouvrir.
			poItemSliding.open("end");
	}

	/** Trie le tableau des documents en fonction d'une clé.
	 * @param paDocuments Tableau de documents à trier.
	 */
	protected sortDocuments(paDocuments: T[] = this.filteredDocuments): T[] {
		const lnStart: number = performance.now();

		ArrayHelper.dynamicSortMultiple(paDocuments, this.msSortKey, this.meSortOrder);
		console.debug(`LCB.C:: Array sorted in ${Math.round(performance.now() - lnStart)}ms.`);
		return paDocuments;
	}

	/** Permet de traquer l'identifiant du document afin de rafraîchir l'affichage si celui-ci change.
	 * @param pnIndex Index du traitement.
	 * @param poDocument Données du document.
	 */
	public trackById(pnIndex: number, poDocument: T): string {
		return poDocument._id;
	}

	//#endregion
}
