<div>
	<ng-container *ngIf="true">
		<div class="filters-ctn">
			<div class="left">
				<!-- Barre de recherche. -->
				<div class="search-box txt-center form-list width100pc">
					<search #search [options]="searchOptions" [data]="patients"
						(filteredEntriesChanged)="onFilteredPatientsChanged($event)" [(searchValue)]="searchValue">
					</search>
				</div>
				<!-- Barre de filtrage des états. -->
				<div *ngIf="canBillToDesmos">
					<div *ngIf="!isSearchOpen && !isSmallScreen" class="center-v">
						<osapp-selector [options]="seanceStatusOptions" [preselectedValues]="preselectedPrestationStatus" multiple
							(selectionChanged)="onStatusSelectionChanged($event)" displayMode="tags">
						</osapp-selector>
					</div>
				</div>


				<span class="seperator"></span>

				<!-- Bouton de filtrage. -->
				<ion-button (click)="toggleFilterbarVisibility()" size="small" class="filter-button-right"
					color="osapp-element">
					<ion-icon slot="icon-only" name="filter">
					</ion-icon>
					<span *ngIf="filtersCount > 0" class="badge">{{ filtersCount }}</span>
				</ion-button>
			</div>
		</div>

		<!-- Barre de filtrage des états. -->
		<div *ngIf="isSmallScreen && canBillToDesmos">
			<osapp-selector [options]="seanceStatusOptions" [preselectedValues]="preselectedPrestationStatus" multiple
				(selectionChanged)="onStatusSelectionChanged($event)" displayMode="tags" scrollWrapper="true">
			</osapp-selector>
		</div>

		<!-- Barre de filtrage. -->
		<ng-container *ngIf="filterbarParams">
			<osapp-filterbar #filterbarComponent [filters]="filterbarParams.filters"
				[hasResetButton]="filterbarParams.hasResetButton" [hidden]="filterbarParams.hidden"
				(onReset)="onFilterbarResetEvent($event)" (onFilterCountChanged)="onFilterCountChanged($event)"
				(onFilterValuesChanged)="onFilterValuesChanged($event)">
			</osapp-filterbar>
		</ng-container>
	</ng-container>

	<!-- Liste des patients. -->
	<div class="list-ctn marg-container" *ngIf="(filteredPatients?.length$ | async) > 0; else empty">
		<mat-accordion multi>
			<ng-container
				*ngFor="let patient of (filteredPatients?.changes$ | async) | slice:startIndex:startIndex+nbItemsByPage">
				<mat-expansion-panel #panel [id]="patient._id" togglePosition="after"
					[expanded]="patient._id === focusPatientId" class="patient-panel" (opened)="onPanelOpened(patient)">

					<mat-expansion-panel-header class="patient-header">
						<ion-item lines="none" mode="md">
							<div class="panel-header">
								<ion-label>{{ patient | contactName }}</ion-label>
								<ng-container *ngIf="!isModeAgrement">
									<idl-couverture-status label="AMO" [couvertures]="patient.AMO" [patient]="patient">
									</idl-couverture-status>
									<idl-couverture-status label="AMC" [couvertures]="patient.AMC" [patient]="patient">
									</idl-couverture-status>
								</ng-container>
							</div>
						</ion-item>
					</mat-expansion-panel-header>

					<ng-container *ngIf="panel.expanded && itemsbyPatientId?.get(patient._id) as items">
						<calao-virtual-scroll [maxHeight]="(151 * 4) + 'px'" itemSize="151" [items]="items">
							<ng-template let-item="$implicit">
								<ng-container *ngIf="!item._id?.includes('invoice_') && item.prestations">
									<ng-container *ngTemplateOutlet="prestas, context: { item: item }">
									</ng-container>
								</ng-container>

								<ng-container *ngIf="item._id?.includes('invoice_') && !item.prestations">
									<ng-container *ngTemplateOutlet="invoice, context: { item: item }">
									</ng-container>
								</ng-container>

								<ng-container *ngIf="!item._id?.includes('invoice_') && !item.prestations">
									<ng-container *ngTemplateOutlet="traitement, context: { item: item }">
									</ng-container>
								</ng-container>
							</ng-template>
						</calao-virtual-scroll>
					</ng-container>

					<ng-template #traitement let-traitementItem="item">
						<ng-container>
							<di-traitement-item [traitement]="traitementItem" [patient]="patient" [contactsParId]="contactsById"
								(onSeancesValidees)="onSeancesValidees($event)">
							</di-traitement-item>
						</ng-container>
					</ng-template>

					<ng-template #prestas let-prestaData="item">
						<ng-container>
							<idl-prestations-item [prestations]="prestaData.prestations"
								[nbOrdonnances]="ordonnancesByTraitementId.get(prestaData.traitementId)?.length || 0"
								[traitement]="traitementsById?.get(prestaData.traitementId)"
								[intervenants]="getPrestationsIntervenants(prestaData.prestations)">
							</idl-prestations-item>
						</ng-container>
					</ng-template>

					<ng-template #invoice let-invoice="item">
						<idl-invoice-item [invoice]="invoice" [ordonnance]="ordonnancesById.get(invoice.ordonnanceId)"
							[prescripteur]="contactsById.get(ordonnancesById.get(invoice.ordonnanceId)?.prescripteurContactId)"
							[intervenant]="contactsById.get(invoice.intervenantId)" (onInvoiceCanceled)="onInvoiceCanceled($event)"
							[arePatientAndAMOUpToDate]="arePatientAndAMOUpToDate(patient)">
						</idl-invoice-item>
					</ng-template>
				</mat-expansion-panel>
			</ng-container>
		</mat-accordion>

		<mat-paginator #paginator [length]="filteredPatients?.length" [pageSize]="nbItemsByPage"
			[pageSizeOptions]="[5, 10, 15, 20]" (page)="onPageChange($event)">
		</mat-paginator>
	</div>

	<!-- Message si aucun résultat. -->
	<ng-template #empty>
		<div class="empty-screen flex-col">
			<span class="txt-message">
				<ion-icon name="information-circle" class="no-result"></ion-icon>
				Aucun résultat.
			</span>
		</div>
	</ng-template>
</div>