import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { FieldWrapper } from '@ngx-formly/core';
import { StringHelper } from '@osapp/helpers/stringHelper';

@Component({
	selector: 'idl-administratif-wrapper',
	templateUrl: './administratif-wrapper.component.html',
	styleUrls: ['./administratif-wrapper.component.scss'],
})
export class AdministratifWrapperComponent extends FieldWrapper implements OnInit {

	//#region PROPERTIES

	/** Conteneur pour le champs de formulaire. */
	@ViewChild("fieldComponent", { read: ViewContainerRef, static: true }) public fieldComponent: ViewContainerRef;

	//#endregion

	//#region METHODS

	constructor(
		private readonly ioRouter: Router
	) {
		super();
	}

	public ngOnInit(): void {
		if (this.field)
			this.field.hide = false;
	}

	private hasToHideWrapper(): boolean {
		if (StringHelper.isBlank(this.model._rev))
			return true;
		return false;
	}

	//#endregion
}
