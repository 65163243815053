import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonItemSliding } from '@ionic/angular';
import { DynamicPageComponent } from '@osapp/components/dynamicPage/dynamicPage.component';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { EPrefix } from '@osapp/model/EPrefix';
import { EBarElementDock } from '@osapp/model/barElement/EBarElementDock';
import { EBarElementPosition } from '@osapp/model/barElement/EBarElementPosition';
import { IBarElement } from '@osapp/model/barElement/IBarElement';
import { ModalService } from '@osapp/modules/modal/services/modal.service';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { of } from 'rxjs';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { C_PREFIX_AMCP, C_PREFIX_AMOP } from '../../../../../app/app.constants';
import { AMCP } from '../../../model/amc-p';
import { AMOP } from '../../../model/amo-p';
import { EUpdateMode } from '../../../model/eupdate-mode.enum';
import { IAMC } from '../../../model/iamc';
import { IAMO } from '../../../model/iamo';
import { ICouvertureP } from '../../../model/icouverture-p';
import { IUpdateHistory } from '../../../model/iupdate-history';
import { CouverturesService } from '../../../services/couvertures.service';
import { CouvertureModalComponent } from '../couverture-modal/couverture-modal.component';

@Component({
	selector: 'idl-couvertures-list',
	templateUrl: './couvertures-list.component.html',
	styleUrls: ['./couvertures-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouverturesListComponent extends ComponentBase implements OnInit, OnDestroy {
	

	

	//#region FIELDS


	//#endregion

	//#region PROPERTIES

	public patientId: string;
	public selectedType: EPrefix;
	public amoPList: AMOP[];
	public amcPList: AMCP[];
	public hasSearchResult: boolean;
	public etablissementsById = new Map<string, IAMO | IAMC>();

	public readonly prefixAMOP: string = C_PREFIX_AMOP;
	public readonly prefixAMCP: string = C_PREFIX_AMCP;
	public readonly typeOptions: ReadonlyArray<ISelectOption<string>> = [
		{ label: "AMO", value: C_PREFIX_AMOP },
		{ label: "AMC", value: C_PREFIX_AMCP }
	];

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcCouvertures: CouverturesService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcModal: ModalService,
		private readonly ioRoute: ActivatedRoute,
		@Optional() private moParentComponent: DynamicPageComponent<ComponentBase>,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);
	}

	public ngOnInit(): void {
		this.patientId = this.ioRoute.snapshot.params.contactId;
		this.updateToolbar();

		this.isvcCouvertures.getPatientSortedCouvertures(this.patientId, true)
			.pipe(
				mergeMap((poCouvertures: {
					AMOPs: AMOP[];
					AMCPs: AMCP[];
				}) => {
					this.amoPList = poCouvertures.AMOPs;
					this.amcPList = poCouvertures.AMCPs;
					this.hasSearchResult = this.amoPList.length > 0 || this.amcPList.length > 0;
					const laEtablissementsIds: string[] = [];

					this.amoPList.forEach((poAMOP: AMOP) => laEtablissementsIds.push(poAMOP.amoId));
					this.amcPList.forEach((poAMCP: AMCP) => laEtablissementsIds.push(poAMCP.amcId));

					return laEtablissementsIds.length > 0 ? this.isvcCouvertures.getEtablissementsByIds(laEtablissementsIds) : of([]);
				}),
				tap((paEtablissements: (IAMO | IAMC)[]) => {
					if (paEtablissements.length > 0)
						this.etablissementsById = new Map(paEtablissements.map((poEtablissement: IAMO | IAMC) => [poEtablissement._id, poEtablissement]));

					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	

	public ngOnDestroy(): void {
		if (this.moParentComponent)
			this.moParentComponent.toolbar.clear(this.msInstanceId);
	}

	public getLabelCouverture(amoId: string): string | undefined {
		return this.isvcCouvertures.getLabelCouverture(amoId,this.etablissementsById)
	}

	/** Met à jour le bouton flottant. */
	private updateToolbar(): void {
		if (this.moParentComponent)
			this.moParentComponent.toolbar.clear(this.msInstanceId);
		// Si on n'a pas ajouté la toolbar, on peut l'initialiser.
		if (!this.moParentComponent?.toolbar.eltContainer.some((poBarElement: IBarElement) => poBarElement.componentId === this.msInstanceId)) {
			this.moParentComponent?.toolbar.init(
				[
					{
						id: "circle",
						component: "fabButton",
						dock: EBarElementDock.bottom,
						position: EBarElementPosition.right,
						icon: "add",
						onTap: () => this.addCouverture(),
						name: "Ajouter"
					}
				] as IBarElement[],
				this.msInstanceId
			);

			this.detectChanges();
		}
	}

	public addCouverture(): void {
		this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
			header: "Ajouter une couverture",
			buttons: [
				{
					text: "AMO", handler: () => {
						const loAmoP = new AMOP(this.patientId);
						const loUpdateHistory: IUpdateHistory = {
							date: loAmoP.updateDate = new Date(),
							mode: loAmoP.updateMode = EUpdateMode.manual
						};
						loAmoP.updateHistory = [loUpdateHistory];
						this.openCouverture(loAmoP);
					}
				},
				{
					text: "AMC",
                handler: () => {
                        const loAmcP = new AMCP(this.patientId);
                        const loUpdateHistory: IUpdateHistory = {
                            date: loAmcP.updateDate = new Date(),
                            mode: loAmcP.updateMode = EUpdateMode.manual
                        };
                        loAmcP.updateHistory = [loUpdateHistory];
                        this.openCouverture(loAmcP);
                }
            },
            { text: "Annuler", role: "cancel" }
			]
		}));
	}

	public openCouverture(poCouverture: AMOP | AMCP): void {
		this.isvcModal.open({
			component: CouvertureModalComponent,
			componentProps: {
				couverture: poCouverture,
				couvertures: poCouverture instanceof AMOP ? this.amoPList : this.amcPList,
				couvertureAMO : this.amoPList,
			}
		})
			.pipe(takeUntil(this.destroyed$))
			.subscribe();
	}

	public deleteCouverture(poCouverture: ICouvertureP) {
		this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
			header: "Suppression",
			message: "Êtes-vous sûr de vouloir supprimer cette couverture ?",
			buttons: [
				{ text: "Annuler", role: "cancel" },
				{ text: "Oui", handler: () => this.isvcCouvertures.deleteCouverture(poCouverture).toPromise() }
			]
		}));
	}

	public getComplementCouverture(poCouverture: AMOP | AMCP): string {
		const laComplements: string[] = [];

		if (poCouverture instanceof AMOP) {
			laComplements.push(`Qualité: ${poCouverture.qualiteLabel}`);
			if(poCouverture.ald) laComplements.push(`ALD: Oui`);
			laComplements.push(`Situation: ${poCouverture.situation ?? "Aucune"}`);
		}
		else {
			laComplements.push(`Numéro adhérent: ${poCouverture.numAdherent ?? "Aucun"}`);
			laComplements.push(`Mutnum: ${poCouverture.mutnum ?? "Aucun"}`);
			laComplements.push(`Gestion: ${poCouverture.gestionUniqueLabel}`);
			laComplements.push(`STS: ${poCouverture.sts ? "Activée" : "Désactivée"}`);
		}

		return laComplements.join(" - ");
	}

	/** Lève un événement pour indiquer que le chois de l'affichage a changé.
	 * @param paTypes
	 */
	public onSelectedTypeChanged(paTypes: EPrefix[]): void {
		this.selectedType = ArrayHelper.getFirstElement(paTypes);
	}

	/** Ouvre ou ferme un itemSliding en fonction de l'état avant slide.\
	 * On peut également stopper la propagation de l'événement de clic.
	 * @param poItemSliding Objet d'options qu'on veut ouvrir ou fermer (animation de swipe).
	 * @param poEvent Événement de clic à stopper si renseigné.
	 */
	public async openOrCloseItemSliding(poItemSliding: IonItemSliding, poEvent?: MouseEvent): Promise<void> {
		if (poEvent)
			poEvent.stopPropagation();	// Empêche la possible navigation vers l'item cliqué.

		// Si l'item est ouvert, la valeur est strictement supérieure à 0, sinon c'est que l'item est fermé.
		const lnAmountOpenPixels: number = await poItemSliding.getOpenAmount();

		if (lnAmountOpenPixels > 0) // Item ouvert, on veut le fermer
			poItemSliding.close();
		else // Item fermé, on veut l'ouvrir.
			poItemSliding.open("end");
	}

	//#endregion

}
