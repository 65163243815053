import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PngIconComponent } from './png-icon.component';

@NgModule({
	declarations: [PngIconComponent],
	exports: [PngIconComponent],
	imports: [CommonModule]
})
export class PngIconModule { }
