<div class="page-tournees__container">
  <div class="page-tournees__header">
      <div class="page-tournees__titre">
        <h1>Soins</h1>
        <small *ngIf="nbRdvPatient">{{nbRdvPatient}} rendez-vous patient{{nbRdvPatient > 1 ? 's' : ''}}</small>
        <small *ngIf="totalPrevu">{{ totalPrevu | currency: 'EUR' : 'symbol' : '1.2-2' : 'fr-FR' }} prévu</small>
      </div>
      <div class="page-tournees__filtres">
        <!-- A décommenter une fois l'agenda fait => apres la sortie de la v2 -->
        <!-- <div class="selection-filtre" *ngIf="!isMobile">
          <lua-filtre-groupe 
            [filters]="tourneesfilters" 
            (filterSelected)="changeFilter($event)"
            [defaultFilter]="defaultFilter">
          </lua-filtre-groupe>
        </div> -->
      </div>
  </div>
  <div class="page-tournees__body">
    <div class="page-tournees__selection-date">
      <div class="date-jour">
        <h2>{{ dateSelected | date:'EEE d MMM':'':'fr-FR' }}</h2>
      </div>
      <div class="selection-jour-semaine">
        <lua-bouton-icone iconName="arrow_back" (onClick)="previousDay()"></lua-bouton-icone>
        <lua-action-secondaire *ngIf="!isMobile" size="large" libelle="Aujourd'hui" (click)="handleClickToday()"></lua-action-secondaire>
        <lua-bouton-icone iconName="arrow_forward" (onClick)="nextDay()"></lua-bouton-icone>
      </div>
    </div>
    <div class="page-tournees__selection-infirmier">
      <!-- A décommenter une fois le composant filtre fait -->
      <!-- <lua-filtre></lua-filtre> -->
      <lua-bouton-texte (click)="openMenuSelectionInfirmier($event)" color="Blanc" libelle="{{infirmierSelected?.firstName || ''}} {{infirmierSelected?.lastName || '' | uppercase}}" [fullWidth]="isMobile"></lua-bouton-texte>
    </div>    
    <di-liste-seance-tournees [seances]="seances"></di-liste-seance-tournees>
  </div>
</div>