import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalComponentBase } from '@osapp/modules/modal/model/ModalComponentBase';
import { PlatformService } from '@osapp/services/platform.service';
import { Seance } from 'apps/idl/src/model/Seance';
import { ISeancesConflicts } from '../../model/ISeancesConflicts';

@Component({
	selector: 'idl-seance-conflicts-modal',
	templateUrl: './seance-conflicts-modal.component.html',
	styleUrls: ['./seance-conflicts-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeanceConflictsModalComponent extends ModalComponentBase<Seance[]> {

	//#region PROPERTIES

	@Input() public seancesConflicts: ISeancesConflicts;

	//#endregion

	//#region METHODS

	constructor(
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetector);
	}

	//#endregion

}
