import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IGalleryFile } from '../../../../model/gallery/IGalleryFile';
import { ModalComponentBase } from '../../../../modules/modal/model/ModalComponentBase';
import { PlatformService } from '../../../../services/platform.service';
import { IGallerySelectorParams } from '../../models/IGallerySelectorParams';

@Component({
	selector: 'calao-gallery-selector-modal',
	templateUrl: './gallery-selector-modal.component.html',
	styleUrls: ['./gallery-selector-modal.component.scss'],
})
export class GallerySelectorModalComponent extends ModalComponentBase<IGalleryFile[]> implements IGallerySelectorParams {

	//#region PROPERTIES

	/** @implements */
	@Input() public files: IGalleryFile[];
	/** @implements */
	@Input() public preselectedFilesGuids?: string[];
	/** @implements */
	@Input() public customTitle?: string;

	public selectedFiles: IGalleryFile[];

	//#endregion

	//#region METHODS

	constructor(
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService
	) {
		super(poModalCtrl, psvcPlatform);
	}

	/** @override */
	public close() {
		return super.close(this.selectedFiles);
	}

	public onFileSelectionChanged(paSelectedFiles: IGalleryFile[]): void {
		this.selectedFiles = paSelectedFiles;
	}

	//#endregion

}
