import { IStoreDocument } from '../store/IStoreDocument';

export interface CalendarEvent extends IStoreDocument {

	/** Titre de l'événement. */
	title?: string;
	/** Date de début de l'événement. */
	startTime?: Date;
	/** Date de fin de l'événement. */
	endTime?: Date;
	/** Permet de savoir si l'événement est sur toute la journée. */
	allDay?: boolean;
	/** Style de l'événement. */
	style?: string;
}