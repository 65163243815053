import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalComponentBase } from '@osapp/modules/modal';
import { PlatformService } from '@osapp/services/platform.service';
import { Acte } from '../../../model/Acte';
import { Traitement } from '../../../model/Traitement';

@Component({
	templateUrl: './actes-list-modale.component.html',
	styleUrls: ['./actes-list-modale.component.scss']
})
export class ActesListModaleComponent extends ModalComponentBase<Acte> {

	//#region PROPERTIES

	@Input() public mode: "select" | "read";
	@Input() public acts: Acte[];

	@Input() public traitement?: Traitement;

	//#endregion

	//#region METHODS

	constructor(poModalCtrl: ModalController, psvcPlatform: PlatformService) {
		super(poModalCtrl, psvcPlatform);
	}

	public onActeSelected(poActe: Acte): void {
		if (this.mode === "select")
			this.close(poActe);
	}

	//#endregion

}