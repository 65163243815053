import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, switchMapTo, take } from 'rxjs/operators';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { EApplicationEventType } from '../../model/application/EApplicationEventType';
import { IApplicationEvent } from '../../model/application/IApplicationEvent';
import { Database } from '../../model/store/Database';
import { EDatabaseRole } from '../../model/store/EDatabaseRole';
import { EStoreEventStatus } from '../../model/store/EStoreEventStatus';
import { EStoreEventType } from '../../model/store/EStoreEventType';
import { IDataSource } from '../../model/store/IDataSource';
import { IStoreEvent } from '../../model/store/IStoreEvent';
import { ApplicationService } from '../../services/application.service';
import { Store } from '../../services/store.service';

/** Le service permet de faire une requête pour récupérer un token pour qu'il soit utilisé dans toutes les requêtes pouchDB. */
@Injectable({ providedIn: 'root' })
export class ApplicationSettingsService {

	//#region METHODS

	constructor(
		/** Service de gestion des requêtes en base de données. */
		private isvcStore: Store,
		/** Service de l'application. */
		private isvcApplication: ApplicationService) { }

	/** Récupération des settings en fonction de la clé.
	 * @param psSettingsKey Identifiant des settings.
	 */
	public getSettings(psSettingsKey: string): Observable<Array<any>> {
		const loDatabase: Database = this.isvcStore.getDatabaseById(ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.config)));

		if (loDatabase && loDatabase.id && loDatabase.isReady)
			return this.getConfigDescriptor(psSettingsKey, loDatabase.id);
		else {
			return this.isvcApplication.appEvent$
				.pipe(
					filter((poEvent: IApplicationEvent) => this.filterConfigDatabaseInitApplicationEvent(poEvent, loDatabase)),
					take(1),
					switchMapTo(this.getConfigDescriptor(psSettingsKey, loDatabase.id))
				);
		}
	}

	/** Filtre les événements d'application pour ne garder que celui d'initialisation de la base de données de configuration.
	 * @param poEvent Événement d'application reçu.
	 * @param poWantedDatabase Base de données permettant de vérifier si l'identifiant de la base de données traitées est celui souhaité.
	 */
	private filterConfigDatabaseInitApplicationEvent(poEvent: IApplicationEvent, poWantedDatabase: Database): boolean {
		let lbIsFilterOkay = false;

		if (poEvent.type === EApplicationEventType.StoreEvent && (poEvent as IStoreEvent).data.status === EStoreEventStatus.successed &&
			(poEvent as IStoreEvent).data.storeEventType === EStoreEventType.Init) {

			poWantedDatabase = this.isvcStore.getDatabaseById(ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.config)));

			lbIsFilterOkay = poWantedDatabase ? (poEvent as IStoreEvent).data.databaseId === poWantedDatabase.id : false;
		}

		return lbIsFilterOkay;
	}

	/** Récupère les descripteurs des menus dans la base de données et à l'aide du Subscriber qui lui est passé en paramètre envoie les descripteurs aux observeurs.
	 * @param psSettingsKey Clé que l'on veut initialiser.
	 * @param psDatabaseId Id de la base de données à récupérer.
	 */
	private getConfigDescriptor(psSettingsKey: string, psDatabaseId: string): Observable<Array<any>> {
		const loParams: IDataSource = { // params pour le get.
			databaseId: psDatabaseId,
			viewName: "config/by_type",
			viewParams: {
				key: psSettingsKey
			}
		};

		return this.isvcStore.get(loParams)
			.pipe(
				catchError(poError => {
					console.error(`Erreur récupération base de données ${psDatabaseId} : `, poError);
					return throwError(poError);
				})
			);
	}

	//#endregion
}