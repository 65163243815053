import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
	selector: 'idl-remplacant-wrapper',
	templateUrl: './remplacant-wrapper.component.html',
	styleUrls: ['./remplacant-wrapper.component.scss'],
})
export class RemplacantWrapperComponent extends FieldWrapper implements OnInit {

	//#region PROPERTIES
	
	private DROIT_HIDE_ELEMENT = "AfficheBlocRemplacantContact";

	/** Conteneur pour le champs de formulaire. */
	@ViewChild("fieldComponent", { read: ViewContainerRef, static: true }) public fieldComponent: ViewContainerRef;

	//#endregion

	//#region METHODS

	constructor(
	) {
		super();
	}

	public ngOnInit(): void {
		if (this.field) {
			this.field.hide = this.hasToHideWrapper();
		}
	}

	private hasToHideWrapper(): boolean {
		return (this.model.roles ? this.model.roles.indexOf(this.DROIT_HIDE_ELEMENT) === -1 :true);
	}
	//#endregion
}
