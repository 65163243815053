<div class="filters-ctn">
	<div class="left">
		<!-- Barre de recherche. -->
		<div class="search-box search-open txt-center form-list width100pc">
			<search #search [options]="searchOptions" [data]="patients"
				(filteredEntriesChanged)="onFilteredPatientsChanged($event)" [(searchValue)]="searchValue">
			</search>
		</div>
	</div>

	<span class="seperator"></span>

	<!-- Bouton de filtrage. -->
	<ion-button mode="md" (click)="toggleFilterbarVisibility()" size="small" class="filter-button-right"
		color="osapp-element">
		<ion-icon slot="icon-only" name="filter">
		</ion-icon>
		<span *ngIf="filtersCount > 0" class="badge">{{ filtersCount }}</span>
		<ion-label *ngIf="filterRangeDates" class="dates-filter-label"></ion-label>
	</ion-button>
</div>

<!-- Barre de filtrage. -->
<ng-container *ngIf="filterbarParams">
	<osapp-filterbar #filterbarComponent [filters]="filterbarParams.filters"
		[hasResetButton]="filterbarParams.hasResetButton" [hidden]="filterbarParams.hidden"
		(onReset)="onFilterbarResetEvent($event)" (onFilterCountChanged)="onFilterCountChanged($event)"
		(onFilterValuesChanged)="onFilterValuesChanged($event)">
	</osapp-filterbar>
</ng-container>
<div class="list-ctn marg-container" *ngIf="invoicesByPatientId?.size > 0; else empty">
	<mat-accordion multi>
		<ng-container
			*ngFor="let item of invoicesByPatientId | keyvalue:sortByPatientLastName | slice:startIndex:startIndex+nbItemsByPage">
			<mat-expansion-panel class="patient-panel" togglePosition="after" [expanded]="false">
				<mat-expansion-panel-header class="patient-hearder">
					<ion-item lines="none" mode="md">
						<ion-label>{{ patientsByIds.get(item.key) | contactName }}</ion-label>
					</ion-item>
				</mat-expansion-panel-header>

				<calao-virtual-scroll [maxHeight]="(151 * 4) + 'px'" itemSize="151" [items]="item.value">
					<ng-template let-invoice="$implicit">
						<idl-invoice-item [invoice]="invoice" [ordonnance]="invoice.ordonnance" [intervenant]="invoice.intervenant"
							[readonly]="true">
						</idl-invoice-item>
					</ng-template>
				</calao-virtual-scroll>
			</mat-expansion-panel>
		</ng-container>
	</mat-accordion>
	<div id="totalMontantfacture">Total : {{montantTotal | priceFormat:2 }} €</div>
	<mat-paginator #paginator [length]="invoicesByPatientId?.size" [pageSize]="nbItemsByPage"
		[pageSizeOptions]="[5, 10, 15, 20]" (page)="onPageChange($event)">
	</mat-paginator>
</div>

<!-- Message si aucun résultat. -->
<ng-template #empty>
	<div class="empty-screen flex-col">
		<span class="txt-message">
			<ion-icon name="information-circle" class="no-result"></ion-icon>
			Aucun résultat.
		</span>
	</div>
</ng-template>