import { Injectable } from '@angular/core';
import { EDatabaseRole, IDataSource } from '@osapp/model';
import { Loader } from '@osapp/modules/loading/Loader';
import { ApplicationService, LoadingService, Store } from '@osapp/services';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { Acte } from '../../model/Acte';
import { IActeDocumentByLc } from '../../model/IActeDocumentByLc';

/** Gère les traitements de l'app. */
@Injectable({ providedIn: 'root' })
export class TraitementDataManager {

	//#region FIELDS

	/** Texte pour le loader lors d'une compilation de vue. */
	private static readonly C_VIEW_COMPILATION_LOADER_TEXT = "Indexation des tarifs des actes : cette étape peut nécessiter un moment lors du premier lancement de l'application.";
	/** Vue pour les prix des actes. */
	private static readonly C_ACTE_PRICES_VIEW_NAME = "entry/by_LC";

	//#endregion

	//#region PROPERTIES

	/** Indique si on doit créer automatiquement les majorations ou non. */
	public shouldAutoCreateMajoration = true;
	/** Indique si on applique les règles de calcul ou non. */
	public isAutoRulesEnable = true;

	//#endregion

	//#region METHODS

	constructor(private isvcStore: Store, private isvcLoading: LoadingService) { }

	/** Récupère les prix d'un acte.
	 * @param poActe Acte sont il faut récupérer les prix.
	 */
	public getActePrices(poActe: Acte): Observable<IActeDocumentByLc[]> {
		const loParams: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.formsEntries),
			viewName: TraitementDataManager.C_ACTE_PRICES_VIEW_NAME,
			viewParams: { key: poActe.keyLetters }
		};
		let loLoader: Loader;

		return this.isvcStore.isViewCompiled(TraitementDataManager.C_ACTE_PRICES_VIEW_NAME)
			.pipe(
				mergeMap((pbIsCompiled: boolean) => this.isvcLoading.create(pbIsCompiled ? ApplicationService.C_LOAD_DATA_LOADER_TEXT : TraitementDataManager.C_VIEW_COMPILATION_LOADER_TEXT)),
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(_ => this.isvcStore.get(loParams)),
				catchError(poError => { console.error(`IDL.MAM.C:: Erreur récupération données pour calculer le prix de l'acte : `, poError); return throwError(poError); }),
				map((paResults: IActeDocumentByLc[]) => paResults),
				finalize(() => loLoader?.dismiss())
			);
	}

	//#endregion
}