<ion-toolbar [hidden]="hidden" color="osapp-element" class="filter size-icon">


	<ng-container *ngFor="let item of filters; let idx = index">
		<ng-container *ngIf="item">
			<ion-grid class="just-flex">
				<!-- Bouton des filtres. -->
				<ion-col *ngIf="!item.isResetButton" (click)="!item.tags ? execute(item, $event) : ''">
					<ion-buttons>
						<ion-button>
							<ion-icon *ngIf="!!item.icon" slot="icon-only" [name]="item.icon"></ion-icon>
							{{ item.text ? item.text : '' }}
						</ion-button>
						<ion-label>&nbsp;:&nbsp;</ion-label>

						<!-- Composant de filtre à instancier en fonction de son type. -->
						<ng-container
							*ngTemplateOutlet="filterComponent; context: { items : filterValuesByFilterIdMap.get(item.id), item: item }">
						</ng-container>
					</ion-buttons>
				</ion-col>
			</ion-grid>
			<!-- Bouton de réinitialisation des filtres. -->
			<ion-buttons *ngIf="item.isResetButton" (click)="resetFilters()">
				<ion-button mode="md" class="filter-button delete-button">
					<ion-icon slot="icon-only" name="close">
					</ion-icon>
					<ion-label class="reset-label">Réinitialiser les filtres</ion-label>
				</ion-button>
			</ion-buttons>
		</ng-container>
	</ng-container>


</ion-toolbar>

<ng-template #filterComponent let-itemValues="items" let-filterOptions="item">
	<!-- Sélection du template à appeler. -->
	<ng-container [ngSwitch]="filterOptions.filterType">
		<osapp-filterbar-contact-avatar *ngSwitchCase="filterTypeEnum.avatar" [values]="itemValues">
		</osapp-filterbar-contact-avatar>

		<!-- On affecte l'id de l'item d'option du filtrage au composant afin de pouvoir simuler un clic dessus
			si on clique sur le bouton du filtre et non la zone du composant elle-même. -->
		<osapp-filterbar-datepicker #datePicker (click)="datePicker.onClick()" *ngSwitchCase="filterTypeEnum.datepicker"
			[id]="filterOptions.id + instanceId" [value]="itemValues[0]" (valueChange)="onValueChange($event, filterOptions)"
			class="parent-size">
		</osapp-filterbar-datepicker>

		<calao-filterbar-date-range-picker #dateRangePicker [id]="filterOptions.id + instanceId"
			(click)="dateRangePicker.onClick($event)" *ngSwitchCase="filterTypeEnum.dateRangePicker" [value]="itemValues[0]"
			(valueChange)="onValueChange($event, filterOptions)" class="parent-size">
		</calao-filterbar-date-range-picker>

		<osapp-filterbar-tags #tagsFilter *ngSwitchCase="filterTypeEnum.tags"
			(onTagClicked)="onFilterbarBaseComponentClicked(tagsFilter); execute(filterOptions)" [values]="itemValues"
			[tags]="filterOptions.tags">
		</osapp-filterbar-tags>

		<ion-label *ngSwitchDefault>
			{{ itemValues.length + ' élément' + (itemValues.length > 1 ? 's' : '') }}
		</ion-label>
	</ng-container>
</ng-template>