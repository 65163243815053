import { Component, OnInit } from '@angular/core';
import { ObjectHelper } from '@osapp/helpers';
import { FieldBase } from '@osapp/model/forms/FieldBase';
import { FormsService } from '@osapp/services/forms.service';
import { IFacturationNumber } from '../../model/IFacturationNumber';

@Component({
	selector: 'idl-facturation-number-field',
	templateUrl: './facturation-number-field.component.html',
	styleUrls: ['./facturation-number-field.component.scss'],
})
export class FacturationNumberFieldComponent extends FieldBase<IFacturationNumber[]> implements OnInit {

	constructor(psvcForms: FormsService) {
		super(psvcForms);
	}

	public ngOnInit(): void {
		if (ObjectHelper.isNullOrEmpty(this.fieldValue))
			this.fieldValue = [{ value: "", key: "" }];
	}

}
