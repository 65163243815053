import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertButton } from '@ionic/core';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '@osapp/services/interfaces/ShowMessageParamsToast';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { takeUntil, tap } from 'rxjs/operators';
import { SearchPatientsError } from '../../../../model/errors/SearchPatientsError';
import { IPatient } from '../../model/IPatient';
import { PatientsService } from '../../services/patients.service';

@Component({
	selector: 'idl-desmos-search-button',
	templateUrl: './desmos-search-button.component.html',
	styleUrls: ['./desmos-search-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		"class": "just-flex flex-col"
	}
})
export class DesmosSearchButtonComponent extends ComponentBase {

	//#region FIELDS

	@Output("patientsSearched") private readonly moPatientsSearchedEventEmitter = new EventEmitter<IPatient[]>();

	//#endregion

	//#region PROPERTIES

	@Input() public searchValue: string;

	//#endregion

	//#region METHODS

	constructor(
		private isvcPatients: PatientsService,
		private isvcUiMessage: UiMessageService
	) {
		super();
	}

	public onClick(): void {
		this.isvcPatients.searchPatients(this.searchValue)
			.pipe(
				tap(
					(paResults: IPatient[]) => {
						if (!ArrayHelper.hasElements(paResults)) // Pas de correspondance.
							this.searchWithDesmosNoResult();
						this.moPatientsSearchedEventEmitter.emit(paResults);
					},
					(poError: string | SearchPatientsError) => this.displaySearchPatientsError(poError)
				),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Recherche Desmos qui n'a rien donnée : popup informative avec possibilité de créer manuellement un nouveau patient. */
	private searchWithDesmosNoResult(): void {
		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({
				message: "Aucun patient ne correspond sur Desmos.",
				buttons: [
					{ text: "Annuler" } as AlertButton,
					{ text: "Créer manuellement", handler: () => { this.isvcPatients.goToNewPatient(); } } as AlertButton
				]
			})
		);
	}

	/** Affiche un toast ou une popup d'erreur, en fonction de la gravité de l'erreur, pour informer l'utilisateur d'un problème lors de la recherche.
	 * @param poError Erreur lors de la recherche de patients sur l'API sous forme de chaîne de caractères (erreur légère) ou d'objet d'erreur (erreur grave).
	 */
	private displaySearchPatientsError(poError: string | SearchPatientsError): void {
		if (typeof poError === "string") // Cas d'erreur "légère", pas de réseau par exemple, un toast suffit.
			this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: poError }));

		else // Cas d'erreur "grave", il faut afficher une popup.
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Erreur", message: poError.message }));
	}

	//#endregion

}
