import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { IContact } from '@osapp/model';

@Component({
  selector: 'di-ligne-contact',
  templateUrl: './ligne-contact.component.html',
  styleUrls: ['./ligne-contact.component.scss'],
})
export class LigneContactComponent implements OnInit, OnChanges {
  public contactName: string = "";

  @Input() public selected: boolean = false;
  @Input() public withTooltip: boolean = false;
  @Input() public contact: IContact = {
    _id: "",
    lastName: "",
    firstName: ""
  };
  @Input() public selectable: boolean = true;

  @Output() clickLine: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
    this.getContactName();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['contact']) {
      this.contact = changes['contact'].currentValue
      this.getContactName()
    }
  }

  onClickLine(idLigne: string): void {
    this.clickLine.emit(idLigne);
    if(this.selectable){
      this.selected = true;
    }
  }

  getContactName() {
    this.contactName = this.contact.lastName?.toUpperCase() + ' ' + this.contact.firstName;
  }

  getClasses(): { [key: string]: boolean } {
    return {
      'selectionnee': this.selected,
    };
  }

  makeCallContact() {
    if (this.contact?.phone) {
      window.location.href = `tel:${this.contact.phone}`;
    }
  }

  sendMailContact() {
    if (this.contact?.email) {
      window.location.href = `mailto:${this.contact?.email}}`;
    }
  }

}
