<div class="container">
	<di-side-page-desmos></di-side-page-desmos>
	<div class="contenu">
		<div class="text">
			<div class="entete">
				<lua-icone mode="outlined" iconName="key" color="Blanc" fontSize="60px"></lua-icone>
				<h2>Mot de passe réinitialisé !</h2>
			</div>
			<div class="corps">
				<p>Votre mot de passe a été réinitialisé avec succès ! Vous devez vous reconnecter.</p>
			</div>
			<div class="action">
				<lua-action-secondaire size="large" color="Blanc" (click)="navigateToLogin()"
					libelle="Page de connexion"></lua-action-secondaire>
			</div>
		</div>
	</div>
</div>