<div class="page-contacts__container">
  <div class="page-contacts__page-gauche">
     <di-liste-contacts 
      (clickAddContact)="handleClickAddContact($event)"
      (contactSelection)="handleSelectionContact($event)" 
      (onSearchTerm)="handleSearchTerm($event)"
      [selectedContactId]="contactSelectionne?._id ?? null"
      [contactsList]="contactsList">
    </di-liste-contacts>
  </div>
  <div class="page-contacts__page-droite" *ngIf="showPageFormContacts">
     <di-form-contacts 
     (onDeleteContact)="handleDeleteContact($event, $event)" 
     (onCancelContact)="handleCancelContact($event)" 
     [contact]="contactSelectionne"
     (onSaveContact)="handleSaveContact($event)"
     ></di-form-contacts>
  </div>
  <lua-alerte *ngIf="messageSuccessContact$" class="form-contact-alert" [filled]="true" type="success" [message]="messageSuccessContact$" buttonIconName="add"></lua-alerte>
</div>
<lua-bouton-floating 
   class="btn-ajout-contact-mobile" 
   bottom="40px" 
   right="40px" 
   iconName="add"
   (click)="handleClickAddContactOnMobile()">
</lua-bouton-floating>