<div class="marg-search">
	<div class="just-flex">
		<!-- Barre de recherche. -->
		<div class="search-box txt-center form-list width100pc">
			<search #search [options]="searchOptions" [data]="interStatesWithPatient"
				(filteredEntriesChanged)="onFilteredInterStatesChanged($event)" [(searchValue)]="searchValue">
			</search>
		</div>
		<!-- Bouton de filtrage. -->
		<ion-button mode="md" (click)="toggleFilterbarVisibility()" size="small" class="filter-button-right"
			color="osapp-element">
			<ion-icon slot="icon-only" [name]="filtersCount > 0 ? 'filter-fill' : 'filter'">
			</ion-icon>
			<span *ngIf="filtersCount > 0" class="badge">{{ filtersCount }}</span>
		</ion-button>
	</div>
</div>

<!-- Barre de filtrage. -->
<ng-container *ngIf="filterbarParams">
	<osapp-filterbar #filterbarComponent [filters]="filterbarParams.filters"
		[hasResetButton]="filterbarParams.hasResetButton" [hidden]="filterbarParams.hidden"
		(onReset)="onFilterbarResetEvent($event)" (onFilterCountChanged)="onFilterCountChanged($event)"
		(onFilterValuesChanged)="onFilterValuesChanged($event)">
	</osapp-filterbar>
</ng-container>

<div class="list-ctn marg-container" *ngIf="filteredInterStatesByPatient?.size > 0; else empty">
	<mat-accordion
		*ngFor="let patientWithInterStates of filteredInterStatesByPatient | keyvalue | slice:startIndex:startIndex+nbItemsByPage">
		<mat-expansion-panel #panel togglePosition="after" [expanded]="false" class="patient-panel">
			<mat-expansion-panel-header class="patient-hearder">
				<mat-panel-title>
					<ion-item lines="none" mode="md">
						<ion-label>{{ patientWithInterStates.key | contactName }}</ion-label>
					</ion-item>
				</mat-panel-title>
			</mat-expansion-panel-header>

			<ng-container *ngIf="patientWithInterStates.value as interStates">
				<cdk-virtual-scroll-viewport *ngIf="panel.expanded" [style.height]="getInterStatesHeightInPx(interStates)"
					class="inter-states-list-wrapper" itemSize="75">
					<ion-list class="inter-states-list">
						<ion-item *cdkVirtualFor="let interState of interStates" lines="none" mode="md">
							<div class="inter-state-detail">
								<ion-item-divider lines="none" class="ctn-intitule-inter-state">
									<ion-label slot="start" class="inter-state-title" color="primary">
										Séance du {{ interState.interventionDate | dateWithLocale: 'fr-FR' : 'EEE dd MMMM'}}
									</ion-label>
									<div class="inter-state-btns" slot="end">
										<ion-button *ngIf="interState.observation" size="small" fill="clear"
											(click)="openObservation(interState)">
											<ion-icon slot="icon-only" name="chatbox-ellipses"></ion-icon>
										</ion-button>
										<ion-button fill="clear" size="small" color="primary"
											(click)="openInterStatePopover($event, interState)">
											<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
										</ion-button>
									</div>
								</ion-item-divider>
								<ion-item lines="full" *ngFor="let acte of interState.actesData; trackBy: trackByIndex"
									class="inter-state-detail-item ">
									<ion-note slot="start" class="note">
										<ion-badge *ngIf="acte.keyLetters === 'HN'" mode="md">{{ acte.keyLetters }}</ion-badge>
										{{acte.keyLetters === "HN" ? "" : acte.keyLetters }} {{acte.priceCoefficient > 0 ?
										acte.priceCoefficient : ""}}
										<span *ngIf="acte.taxAllowance !== 1"> {{acte.taxAllowance >= 0 ? "x" + acte.taxAllowance :
											""}}</span>
									</ion-note>
									<ion-label>
										{{ acte.label }}
									</ion-label>
								</ion-item>
							</div>
						</ion-item>
					</ion-list>
				</cdk-virtual-scroll-viewport>
			</ng-container>

		</mat-expansion-panel>
	</mat-accordion>
	<mat-paginator #paginator [length]="filteredInterStatesByPatient?.size" [pageSize]="nbItemsByPage"
		[pageSizeOptions]="[5, 10, 15, 20]" (page)="onPageChange($event)">
	</mat-paginator>
</div>

<!-- Message si aucun résultat. -->
<ng-template #empty>
	<div class="empty-screen flex-col">
		<span class="txt-message">
			<ion-icon name="information-circle" class="no-result"></ion-icon>
			Aucun résultat.
		</span>
	</div>
</ng-template>