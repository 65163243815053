import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { IContact } from '../../model/contacts/IContact';
import { IGroup } from '../../model/contacts/IGroup';
import { IGroupDetailsModel } from '../../model/contacts/IGroupDetailsModel';
import { GroupsService } from '../../services';

@Injectable({
	providedIn: 'root'
})
export class GroupResolver implements Resolve<IGroupDetailsModel> {

	//#region METHODS

	constructor(private isvcGroups: GroupsService) { }

	public resolve(poRoute: ActivatedRouteSnapshot): Observable<IGroupDetailsModel> {
		const lsGroupId: string = poRoute.params.groupId;

		return this.isvcGroups.getGroup(lsGroupId)
			.pipe(
				mergeMap((poGroup: IGroup) => this.isvcGroups.getGroupContacts(poGroup)
					.pipe(map((paContacts: Array<IContact>) => ({ ...poGroup, contacts: paContacts } as IGroupDetailsModel))))
			);
	}

	//#endregion

}