import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Type } from '@angular/core';
import { FavoritesService } from '@osapp/modules/preferences/favorites/services/favorites.service';
import { IPreferences } from '@osapp/modules/preferences/model/IPreferences';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { C_PREFIX_ACT } from 'apps/idl/src/anakin/anakin.constants';
import { Acte } from 'apps/idl/src/model/Acte';
import { IActe } from 'apps/idl/src/model/IActe';
import { ITraitement } from 'apps/idl/src/model/ITraitement';
import { Traitement } from 'apps/idl/src/model/Traitement';
import { ActesService } from 'apps/idl/src/modules/actes/actes.service';
import { TraitementService } from 'apps/idl/src/services/traitement.service';
import { Subject } from 'rxjs';
import { mergeMap, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { ArrayHelper } from '../../../../../../../../../cloud/osapp.api.node.nest/src/helpers/array.helper';
import { ConfirmationSuppressionComponent } from '../../../shared/components/confirmation-suppression/confirmation-suppression.component';
import { PanneauRechercheActesComponent } from '../../../shared/components/panneaux/panneau-recherche-actes/panneau-recherche-actes.component';
import { DeviceService } from '../../../shared/services/device.service';
import { DrawerPopoverService } from '../../../shared/services/drawer-popover.service';
import { PanneauService } from '../../../shared/services/panneau.service';
import { PanneauActePriseEnChargeComponent } from '../panneau-acte-prise-en-charge/panneau-acte-prise-en-charge.component';
import { PriseEnChargeComponent } from '../prise-en-charge/prise-en-charge.component';
@Component({
  selector: 'di-liste-actes',
  templateUrl: './liste-actes.component.html',
  styleUrls: ['./liste-actes.component.scss'],
})
export class ListeActesComponent extends DestroyableComponentBase implements OnInit, OnDestroy {

  @Input() ordonnance: ITraitement;
  @Input() mode: "add" | "edit";
  @Output() onGenerateSeance: EventEmitter<void> = new EventEmitter<void>();

  public panneauTitle = "";
  public panneauContent: Type<PanneauRechercheActesComponent> | Type<PanneauActePriseEnChargeComponent>;
  public panneauInputs: object;

  public listFavoritesIdActe: IPreferences;
  public listeActes: Acte[] = [];
  public isMobile: boolean = false;
  public showPriseEnChargeWarning: boolean = false;
  public priseEnChargeMessage: string = "La prise en charge n'est pas renseigné";
  public showEntentePrealable: boolean = false;
  public isActeUrgent: boolean = false;
  public showPopover: boolean = false;

  private ordonnanceObj: Traitement;

  public get isBtnGenerateDisabled(): boolean {
    const hasActes = this.listeActes.length > 0;
    const shouldShowEntente = this.showEntentePrealable && !this.isActeUrgent;
    return !hasActes || this.showPriseEnChargeWarning && (shouldShowEntente || !this.showEntentePrealable);
  }

  private favoritesClickSubject = new Subject<IActe>();

  constructor(
    private svcPanneau: PanneauService,
    private svcActe: ActesService,
    private svcDevice: DeviceService,
    private svcFavorites: FavoritesService,
    private svcDrawerPopover: DrawerPopoverService,
    private svcTraitement: TraitementService,
  ) {
    super();
  }

  ngOnInit() {
    this.svcActe.selectActe$.pipe(takeUntil(this.destroyed$)).subscribe((acteSelected) => {
      //avant d'ajouter l'acte on regarde si on a deja un acte dans l'ordonnance
      //si c'est le cas on récupère automatiquement sa prise en charge et on l'affecte au nouveau
      //sans ouvrir le panneau de prise en charge
      const acteWithPrice = new Acte(acteSelected);
      const afficherPanneauPEC = !this.setPriseEnChargeFromActe(acteWithPrice);
      this.listeActes.push(acteWithPrice);
      const indexOfNewActe = this.listeActes.length - 1;
      //On enregistre l'acte dans l'ordonnance
      this.saveActe();

      //TODO : vérifier les regles de cotation, pour que le montant de l'acte soit correct

      if (afficherPanneauPEC) {
        this.openPanneauPriseEnCharge(acteWithPrice, indexOfNewActe);
      } else {
        this.svcPanneau.close();
      }
    });

    this.svcDevice.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((flag: boolean) => {
      this.isMobile = flag;
    });

    this.favoritesClickSubject
      .pipe(
        throttleTime(500),
        mergeMap((acte: IActe) => this.svcFavorites.toggle(acte._id)),
        takeUntil(this.destroyed$)
      )
      .subscribe();

    this.svcFavorites.get(C_PREFIX_ACT, true)
      .pipe(
        tap((favoritesIds: IPreferences) => {
          this.listFavoritesIdActe = favoritesIds;
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();

    this.initOrdonnanceObj();
    this.initListeActes();
    this.verifierActesAvecPriseEnCharge();
    this.verifierEntentePrealable(this.ordonnanceObj?.actes);
  }

  initOrdonnanceObj() {
    this.ordonnanceObj = Traitement.createFromData(this.ordonnance);
  }

  initListeActes() {
    this.ordonnance.actes?.forEach(acte => {
      const acteObj = new Acte(acte);
      this.listeActes.push(acteObj);
    });
  }

  /**
   * Affecte à un acte les infos de prise en charge à partir d'un autre acte
   * Retourne true si une valeur a été affectée
   */
  setPriseEnChargeFromActe(acteSelected: Acte): boolean {
    if (this.listeActes.length == 0) return false;
    const lastActe = ArrayHelper.getLastElement(this.listeActes);
    if (lastActe.recurrences.length == 0) return false;
    //si j'ai une prise en charge sur le dernier acte alors je l'affecte directement au nouvel acte
    acteSelected.duration = lastActe.duration;
    acteSelected.isAldExonerante = lastActe.isAldExonerante;
    acteSelected.recurrences = lastActe.recurrences;
    acteSelected.weekRepetition = lastActe.weekRepetition;
    acteSelected.startDate = lastActe.startDate;
    acteSelected.place = lastActe.place;
    acteSelected.recurrences = lastActe.recurrences;
		acteSelected.sundayAndPublicHolidays = lastActe.sundayAndPublicHolidays;
    return true;
  }

  getTexteSecondairePriseEnCharge(acte: Acte): string {
    const texteSec = "";
    return texteSec;
  }

  showListeActes() {
    return this.listeActes && this.listeActes.length > 0;
  }

  openPanneauActes(): void {
    this.panneauTitle = "Actes";
    this.panneauContent = PanneauRechercheActesComponent;
    this.panneauInputs = {
			ordonnance : this.ordonnance
		};
    this.openPanneau();
  }

  openPanneauPriseEnCharge(acte: Acte, index: number): void {
    if (this.isMobile) {
      this.svcPanneau.close();
      this.svcDrawerPopover.open("Panneau prise en charge",
        "85%",
        null,
        PriseEnChargeComponent,
        {
          acte: acte,
          index: index,
          onSave: this.saveActeAvecPriseEnCharge,
          onCancel: this.cancelPriseEnCharge
        })
    } else {
      this.panneauTitle = "Prise en charge";
      this.panneauContent = PanneauActePriseEnChargeComponent;
      this.panneauInputs = {
        acte: acte,
        index: index,
        onSave: this.saveActeAvecPriseEnCharge,
        onCancel: this.cancelPriseEnCharge
      };
      this.openPanneau();
    }
  }

  openPanneau() {
    this.svcPanneau.open(this.panneauTitle, this.panneauContent, this.panneauInputs);
  }

  onFavoritesClicked(acte: IActe): void {
    this.favoritesClickSubject.next(acte);
  }

  toggleActeUrgent() {
    this.isActeUrgent = !this.isActeUrgent;
    //TODO : attente que les maquettes d'entente préalable soit terminée    
  }

  saisirDemandeAccordPrealable() {
    //TODO : attente que les maquettes d'entente préalable soit terminée    
  }

  getFavoriteStatus(acte: Acte): string {
    return this.isMobile ? '' :
      this.listFavoritesIdActe?.ids?.includes(acte._id) ?
        'done' : 'to be done';
  }

  //TODO : A remettre en place une fois la maquette terminée car NIE voudra à nouveau les 3 petits points
  // openMenu(event: Event, acte: Acte, index: number): void {
  //   this.showPopover = true;
  //   this.svcDrawerPopover.open("",
  //     "25%",
  //     event.currentTarget,
  //     MenuOptionsActeComponent,
  //     {
  //       ordonnance: this.ordonnanceObj,
  //       acte: acte,
  //       index: index,
  //       onDeleteClick: () => { this.handleDeleteActe(index) },
  //       onEditClick: (acte: Acte) => { console.log("onEditClick : ", acte) /* TODO: a modifier une fois le retour de NIE */ }
  //     },
  //     () => this.showPopover = false)
  // }

  openConfirmDeleteActe(event: Event, index: number) {
    this.showPopover = true;
    this.svcDrawerPopover.open(
      "Confirmation de suppression",
      "30%",
      event.currentTarget,
      ConfirmationSuppressionComponent,
      {
        onConfirm: () => this.handleConfirmDeleteActe(index),
        onCancel: () => this.closeMenu(),
      },
      () => (this.showPopover = false)
    );
  }

  handleDeleteActe(index: number) {
    this.listeActes.splice(index, 1);
    this.verifierEntentePrealable(this.ordonnanceObj.actes);
    this.verifierActesAvecPriseEnCharge();
  }

  handleConfirmDeleteActe(index: number) {
    this.svcDrawerPopover.close();
    //On retire l'acte de la liste d'acte de l'ordonnance
    this.ordonnanceObj.actes.splice(index, 1);
    //On sauvegarde l'ordonnance
    this.svcTraitement.saveTraitementANAKIN(this.ordonnanceObj).subscribe({
      next: () => {
        this.handleDeleteActe(index);
      },
      error: (error) => {
        console.error("Une erreur est survenue lors de la suppression de l'acte de l'ordonnance : ", error);
      }
    });
  }

  closeMenu(): void {
    this.showPopover = false;
    this.svcDrawerPopover.close();
  }

  generateSeance() {
    this.onGenerateSeance.emit();
  }

  saveActeAvecPriseEnCharge = (acteToSave: Acte, index: number): void => {
    //on met à jour l'acte dans le traitement
    this.ordonnanceObj.actes[index] = acteToSave;
    this.listeActes[index] = acteToSave;

    //A voir si c'est obligatoire
    // StoreHelper.makeDocumentDirty(this.ordonnanceObj);

    // //puis je sauvegarde l'ordonnance avec l'acte 
    this.svcTraitement.saveTraitementANAKIN(this.ordonnanceObj).pipe(takeUntil(this.destroyed$)).subscribe(_ => {
      //si il y a l'option d'entente préalable, je dois afficher le contenu correspondant
      this.verifierEntentePrealable(this.ordonnanceObj.actes);
      this.verifierActesAvecPriseEnCharge();
      this.closePanneauSwiperDrawable();
    });
  }

  cancelPriseEnCharge = () => {
    this.closePanneauSwiperDrawable();
  }

  saveActe() {
		this.ordonnanceObj.actes = this.listeActes;
    this.svcTraitement.saveTraitementANAKIN(this.ordonnanceObj).pipe(takeUntil(this.destroyed$)).subscribe(_ => {
      //si il y a l'option d'entente préalable, je dois afficher le contenu correspondant
      this.verifierEntentePrealable(this.ordonnanceObj.actes);
      //on vérifie si on doit afficher le warning ou non
      this.verifierActesAvecPriseEnCharge();
    });
  }

  private closePanneauSwiperDrawable() {
    if (this.isMobile) {
      this.svcDrawerPopover.close();
    } else {
      this.svcPanneau.close();
    }
  }

  private verifierEntentePrealable(actes: Acte[]) {
    this.showEntentePrealable = actes && actes.some(x => x.isPriorAgreement);
  }

  private verifierActesAvecPriseEnCharge() {
    this.showPriseEnChargeWarning = this.listeActes.length > 0 && this.listeActes.some(x => x.recurrences.length === 0 || !x.recurrences);
  }
}
