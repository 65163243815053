import { Component, Input, OnInit } from '@angular/core';
import { Acte } from 'apps/idl/src/model/Acte';
import { IActe } from 'apps/idl/src/model/IActe';

@Component({
  selector: 'di-panneau-acte-prise-en-charge',
  templateUrl: './panneau-acte-prise-en-charge.component.html',
  styleUrls: ['./panneau-acte-prise-en-charge.component.scss'],
})
export class PanneauActePriseEnChargeComponent implements OnInit {

  @Input() acte?: IActe;
  @Input() index?: number;
  @Input() onSave: (acte: Acte) => void;
  @Input() onCancel: () => void;

  constructor(
  ) { }

  ngOnInit() {

  }
}