import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalComponentBase } from '../../../modules/modal';
import { ISelectOption } from '../../../modules/selector/selector/ISelectOption';
import { PlatformService } from '../../../services';


@Component({
	templateUrl: './gallery-modal.component.html',
	styleUrls: ['./gallery-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryModalComponent extends ModalComponentBase<number> implements OnInit {

	//#endregion

	public readonly documentList: Array<ISelectOption<number>> = [
	];

	public documentSelected : number;

	//#region METHODS

	constructor(
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetectorRef);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		this.detectChanges();	}

	/** @override */
	public close(): Promise<boolean> {
		return super.close(this.documentSelected);
	}
	
	public onSelectImage(doc: number) : void{
		this.documentSelected = doc;
	}
}
