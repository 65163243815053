import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { UserData } from '@osapp/model';
import { ENavigationType } from '@osapp/model/navigation/ENavigationType';
import { ICoordinates } from '@osapp/model/navigation/ICoordinates';
import { ModalComponentBase } from '@osapp/modules/modal/model/ModalComponentBase';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { Site } from '@osapp/modules/sites/models/site';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { NavigationService } from '@osapp/services/navigation.service';
import { PlatformService } from '@osapp/services/platform.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { EPlace } from '../../../../model/EPlace';
import { ISeanceTournee } from '../../../../model/seances/ISeanceTournee';
import { SeanceService } from '../../../../services/seance.service';

@Component({
	selector: 'idl-seance-place-modal',
	templateUrl: './seance-place-modal.component.html',
	styleUrls: ['./seance-place-modal.component.scss'],
})
export class SeancePlaceModalComponent extends ModalComponentBase<void> implements OnInit {

	//#region PROPERTIES

	@Input() public seanceTournee: ISeanceTournee;
	@Input() public selectedPlaceSubject: BehaviorSubject<EPlace>;
	public selectedPlace: EPlace;
	public currentSite: Site = UserData.currentSite;

	public readonly placeOptions: ReadonlyArray<ISelectOption<string>> = [
		{ label: "Domicile", value: "home", icon: "home" },
		{ label: "Cabinet", value: "center", icon: "center" }
	];

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcSeance: SeanceService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcNavigation: NavigationService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService
	) {
		super(poModalCtrl, psvcPlatform);
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this.selectedPlace = this.seanceTournee.seance.place;
	}

	public onPlaceChanged(paPlaces: EPlace[]): void {
		this.selectedPlace = ArrayHelper.getFirstElement(paPlaces);
		this.selectedPlaceSubject.next(this.selectedPlace);
	}

	public onNavigateToClicked(): void {
		this.isvcSeance.getSeanceCoordinates(this.seanceTournee.seance, true)
			.pipe(
				catchError(poError => {
					if (typeof poError === "string")
						this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Erreur", message: poError }));

					return throwError(poError);
				}),
				mergeMap((poCoordinates: ICoordinates) => {
					this.isvcNavigation.resetConfig(ENavigationType.waze);
					return this.isvcNavigation.navigateToCoordinates(poCoordinates.latitude, poCoordinates.longitude);
				}),
				tap(
					_ => { },
					poError => console.error("SEANCE-PLACE-M.C::", poError)
				),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	//#endregion

}
