import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IonicModule } from '@ionic/angular';
import { ContactsModule } from '@osapp/components/contacts/contacts.module';
import { DateModule } from '@osapp/components/date';
import { GalleryModule } from '@osapp/components/gallery';
import { ModalModule } from '@osapp/modules/modal/modal.module';
import { SelectorModule } from '@osapp/modules/selector/selector.module';
import { PipeModule } from '@osapp/pipes/pipes.module';
import { OrdonnanceModalComponent } from './components/ordonnance-modal/ordonnance-modal.component';
import { OrdonnanceModalOpenerService } from './components/ordonnance-modal/services/ordonnance-modal-opener.service';
import { OrdonnanceStatusIconsComponent } from './components/ordonnance-status-icons/ordonnance-status-icons.component';
import { OrdonnancesGallerySelectorModalComponent } from './components/ordonnances-gallery-selector-modal/ordonnances-gallery-selector-modal.component';
import { OrdonnanceGallerySelectorModalOpenerService } from './components/ordonnances-gallery-selector-modal/services/ordonnance-gallery-selector-modal-opener.service';
import { OrdonnancesGalleryComponent } from './components/ordonnances-gallery/ordonnances-gallery.component';
import { OrdonnancesService } from './services/ordonnances.service';

const components: Type<any>[] = [
	OrdonnanceModalComponent,
	OrdonnancesGalleryComponent,
	OrdonnancesGallerySelectorModalComponent,
	OrdonnanceStatusIconsComponent
];

@NgModule({
	exports: components,
	declarations: components,
	imports: [
		CommonModule,
		IonicModule,
		PipeModule,
		FormsModule,
		SelectorModule,
		MatChipsModule,
		DateModule,
		ModalModule,
		ContactsModule,
		GalleryModule,
		MatTooltipModule
	],
	providers: [OrdonnancesService, OrdonnanceModalOpenerService, OrdonnanceGallerySelectorModalOpenerService]
})
export class OrdonnancesModule { }
