import { IdHelper } from "@osapp/helpers/idHelper";
import { Prestation } from "@osapp/modules/prestation/models/prestation";
import { ModelMatch } from "@osapp/modules/utils/models/decorators/model-match.decorator";
import { Exclude } from "class-transformer";
import { C_PREFIX_TRAITEMENT } from "../../../app/app.constants";
import { EPlace } from "../../../model/EPlace";
import { IdlPrestationLine } from "./idl-prestation-line";
import { IIdlPrestation } from "./iidl-prestation";

@ModelMatch((poData: IdlPrestation) => !!poData, Prestation)
export class IdlPrestation extends Prestation<IdlPrestationLine> implements IIdlPrestation {

	//#region PROPERTIES

	/** @implements */
	public place: EPlace;
	/** @override */
	public lines: IdlPrestationLine[];
	/** @override */
	public originalLines: IdlPrestationLine[];

	@Exclude()
	private msTraitementId: string;
	public get traitementId(): string {
		if (!this.msTraitementId)
			this.msTraitementId = IdlPrestation.extractTraitementId(this._id);

		return this.msTraitementId;
	}

	//#endregion

	//#region METHODS

	constructor(poData?: IIdlPrestation) {
		super(poData);
	}

	public static extractTraitementId(psPrestationId: string): string {
		const lsTraitementId: string = IdHelper.extractAllIds(psPrestationId).find((psPartId: string) => psPartId.startsWith(C_PREFIX_TRAITEMENT));
		return IdHelper.removeDateFromId(lsTraitementId);
	}

	//#endregion

}
