import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { combineLatest, defer, forkJoin, from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, defaultIfEmpty, filter, finalize, map, mapTo, mergeMap, mergeMapTo, switchMap, take, tap, toArray } from 'rxjs/operators';
import { ArrayHelper } from '../helpers/arrayHelper';
import { EntityHelper } from '../helpers/entityHelper';
import { IdHelper } from '../helpers/idHelper';
import { StoreHelper } from '../helpers/storeHelper';
import { StringHelper } from '../helpers/stringHelper';
import { UserData } from '../model/application/UserData';
import { EBarElementAction } from '../model/barElement/EBarElementAction';
import { ConfigData } from '../model/config/ConfigData';
import { EEntityLinkCacheData } from '../model/entities/EEntityLinkCacheData';
import { Entity } from '../model/entities/Entity';
import { IEntity } from '../model/entities/IEntity';
import { IGuardResult } from '../model/entities/IEntityGuard';
import { IEntityLink } from '../model/entities/IEntityLink';
import { IEntityLinkCache } from '../model/entities/IEntityLinkCache';
import { IEntityLinkPart } from '../model/entities/IEntityLinkPart';
import { IEntitySelectorParams } from '../model/entities/IEntitySelectorParams';
import { IEntityTypeSelectorParams } from '../model/entities/IEntityTypeSelectorParams';
import { EPrefix } from '../model/EPrefix';
import { ELinkAction } from '../model/link/ELinkAction';
import { ELinkTemplate } from '../model/link/ELinkTemplate';
import { LinkInfo } from '../model/link/LinkInfo';
import { ILinkedItemsListParams } from '../model/linkedItemsList/ILinkedItemsListParams';
import { INavbarEvent } from '../model/navbar/INavbarEvent';
import { PageInfo } from '../model/PageInfo';
import { ISelectorParams } from '../model/selector/ISelectorParams';
import { EDatabaseRole } from '../model/store/EDatabaseRole';
import { ICacheData } from '../model/store/ICacheData';
import { ICustomPouchError } from '../model/store/ICustomPouchError';
import { IDataSource } from '../model/store/IDataSource';
import { IStoreDataResponse } from '../model/store/IStoreDataResponse';
import { IStoreDocument } from '../model/store/IStoreDocument';
import { EntityBuilder } from './EntityBuilder';
import { IEntityLinkService } from './interfaces/IEntityLinkService';
import { ShowMessageParamsPopup } from './interfaces/ShowMessageParamsPopup';
import { PageManagerService } from './pageManager.service';
import { Store } from './store.service';
import { UiMessageService } from './uiMessage.service';
import { WorkspaceService } from './workspace.service';

@Injectable({ providedIn: "root" })
export class EntityLinkService implements IEntityLinkService {

	//#region FIELDS

	/** Liste des liens associés */
	private static readonly C_LINKED_ITEMS_LIST_NAV_BUTTON_ID = "linkedItemsList";
	/** Identifiant du service pour les logs. */
	private static readonly C_LOG_ID = "EL.S::";
	/** Base de données relative pour les liens. */
	private static readonly C_RELATIVE_DATABASE_SOURCE = ".";

	/** Tableau contenant les entités courantes. La dernière est la plus récente. */
	private maCurrentEntityStack: IEntity[] = [];

	//#endregion

	//#region PROPERTIES

	public static readonly C_DEEPLINK_SUB_PATH_SEPARATOR = "/";

	private maEntityBuilders: EntityBuilder[];
	private get entityBuilders(): EntityBuilder[] {
		if (!this.maEntityBuilders)
			this.maEntityBuilders = this.loadEntityBuilders();

		return this.maEntityBuilders;
	}

	/** Retourne l'entité courante. */
	public get currentEntity(): IEntity { return ArrayHelper.getLastElement(this.maCurrentEntityStack); }

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des requêtes en base de données. */
		private readonly isvcStore: Store,
		/** Service de gestion des pages. */
		private readonly isvcPageManager: PageManagerService,
		/** Service de gestion des popups et toasts. */
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcWorkspace: WorkspaceService,
		private readonly ioModalCtrl: ModalController,
		private readonly ioRouter: Router
	) { }

	//#region Links

	/** Création de documents de liaison entre un sujet et un ensemble de cibles.
	 * Ex: on veut créer une conversation à partir d'un contact (la conversation sera liée au contact) : lnk_cont_guid-conv_guid.
	 * @param poSomething Source du lien, quelque chose va lui être lié.
	 * @param paSomethingElse Tableau des éléments qui vont être liées à la source.
	 */
	private createEntityLinks(poSomething: IEntity, paSomethingElse: IEntityLinkCache[]): Observable<Array<IStoreDataResponse | ICustomPouchError>> {
		const laItemLinks: IEntityLink[] = ArrayHelper.flat(paSomethingElse.map((poEntity: IEntityLinkCache) => {
			poSomething.subPath = poEntity.targetSubPath;
			const laEntityLinks: IEntityLink[] = this.buildEntityLinks(poSomething, poEntity.entity);
			const loCacheData: ICacheData = {
				databaseId: this.isvcWorkspace.isDocumentFromWorkspace(poSomething.model) ?
					poSomething.databaseName : this.isvcWorkspace.getWorkspaceDatabaseIdFromDatabaseId(poEntity.entity.databaseName)
			};

			laEntityLinks.forEach((poEntityLink: IEntityLink) => StoreHelper.updateDocumentCacheData(poEntityLink, loCacheData));

			return laEntityLinks;
		}));

		return this.isvcStore.putMultipleDocuments(laItemLinks, undefined, true)
			.pipe(
				tap((paResults: IStoreDataResponse[]) => console.debug(`${EntityLinkService.C_LOG_ID}Création des documents de lien : '${paResults.every((poResult: IStoreDataResponse) => poResult.ok)}'.`)),
				catchError(poError => {
					console.error(`${EntityLinkService.C_LOG_ID}Erreur de création des liens (Avez-vous ajouté l'entité au config.ts de l'application ?) :`, poError);
					return throwError(poError);
				})
			);
	}

	/** Retourne l'ensemble des liens depuis les workspaces qui ont 'psItemId' comme document lié.
	 * @param psItemId Identifiant de l'item pour lequel on veut tous les domaines associés.
	 * @param paLinkedEntityPrefixes Tableau des préfixes de liens d'entités qu'il faut récupérer, optionnel (les récupère tous dans ce cas).
	 * @param pbLive Indique si la requête est live.
	 * @param pbIncludeDeeplinks Indique si l'on doit inclure les deeplinks. Faux par défaut. (format `lnk_entity_a/sub/subGuid-entity_b`)
	 */
	public getEntityLinks(psItemId: string, paLinkedEntityPrefixes?: Array<EPrefix>, pbLive?: boolean, pbIncludeDeeplinks?: boolean): Observable<Array<IEntityLink>> {
		if (StringHelper.isBlank(psItemId))
			return of([]);

		else {
			return this.isvcStore.get(this.createEntityLinksDataSource(psItemId, paLinkedEntityPrefixes, pbLive, pbIncludeDeeplinks))
				.pipe(
					tap((paEntityLinks: IEntityLink[]) => this.replaceRelativeDatabaseSource(paEntityLinks)),
					mergeMap((paEntityLinks: IEntityLink[]) => {
						return from(paEntityLinks)
							.pipe(
								mergeMap((poEntityLink: IEntityLink) => {
									if (poEntityLink.createDate)
										poEntityLink.createDate = new Date(poEntityLink.createDate);

									return this.addDisplayValueToEntityLink(poEntityLink);
								}),
								toArray()
							);
					})
				);
		}
	}

	/** Remplace les bases de données relatives d'une entité liée si elle en possède.
	 * @param poLink Lien d'entité dont il faut remplacer les bases de données relatives (s'il y en a).
	 */
	private replaceRelativeDatabaseSource(poLink: IEntityLink): void;
	/** Remplace les bases de données relatives d'un tableau d'entités liées si elles en possèdent.
	 * @param paLinks Tableau des liens d'entité dont il faut remplacer les bases de données relatives (s'il y en a).
	 */
	private replaceRelativeDatabaseSource(paLinks: IEntityLink[]): void;
	private replaceRelativeDatabaseSource(poData: IEntityLink | IEntityLink[]): void {
		// On initialise le tableau des liens en vérifiant que le paramètre est valide ou tableau vide par défaut.
		const laLinks: IEntityLink[] = poData instanceof Array ? poData : (poData ? [poData] : []);

		laLinks.forEach((poLink: IEntityLink) => {
			poLink.databasesSource.forEach((psSource: string, pnIndex: number) => {
				if (psSource === EntityLinkService.C_RELATIVE_DATABASE_SOURCE)
					poLink.databasesSource[pnIndex] = StoreHelper.getDatabaseIdFromCacheData(poLink);
			});
		});

	}

	/** Ajoute un nom d'affichage sur un lien d'entité et retourne le lien d'entité modifié.
	 * @param poEntityLink Lien d'entité sur lequel ajouter le nom à afficher.
	 */
	private addDisplayValueToEntityLink(poEntityLink: IEntityLink): Observable<IEntityLink> {
		if (StringHelper.isBlank(poEntityLink.name2)) {
			return this.isvcStore.getOne({
				databaseId: StoreHelper.getDatabaseIdFromCacheData(poEntityLink),
				viewParams: {
					include_docs: true,
					key: EntityHelper.getEntityLinkRightId(poEntityLink)
				}
			}, false)
				.pipe(
					filter((poDocument: IStoreDocument) => !!poDocument),
					tap((poDocument: IStoreDocument) => poEntityLink.displayName = this.getEntityLinkDisplayValue(poDocument)),
					mapTo(poEntityLink)
				);
		}
		else {
			poEntityLink.displayName = this.getEntityLinkDisplayValue(
				ArrayHelper.getLastElement(EntityHelper.getIdsFromLinkId(poEntityLink._id)), poEntityLink.name2
			);

			return of(poEntityLink);
		}
	}

	/** Crée la dataSource pour récupérer les liens d'entités.
	 * @param psItemId Identifiant de l'item pour lequel on veut tous les domaines associés.
	 * @param paLinkedEntityPrefixes Tableau des préfixes de liens d'entités qu'il faut récupérer, optionnel (les récupère tous dans ce cas).
	 * @param pbLive Indique si la requête est live.
	 * @param pbIncludeDeeplinks Indique si l'on doit inclure les deeplinks. Faux par défaut. (format `lnk_entity_a/sub/subGuid-entity_b`)
	 */
	private createEntityLinksDataSource(psItemId: string, paLinkedEntityPrefixes?: Array<EPrefix>, pbLive?: boolean, pbIncludeDeeplinks?: boolean): IDataSource {
		const lsId: string =
			`${IdHelper.buildId(EPrefix.link, psItemId)}${pbIncludeDeeplinks ? "" : `-${paLinkedEntityPrefixes?.length === 1 ? ArrayHelper.getFirstElement(paLinkedEntityPrefixes) : ""}`}`;

		const loDataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				startkey: lsId,
				endkey: lsId + Store.C_ANYTHING_CODE_ASCII,
				include_docs: true
			},
			live: pbLive,
			filter: ArrayHelper.hasElements(paLinkedEntityPrefixes) && pbIncludeDeeplinks ? this.filterEntityLinksFunction(paLinkedEntityPrefixes) : undefined
		};

		return loDataSource;
	}

	/** Retourne une fonction permettant de filtrer les liens d'entités en fonction d'un tableau de préfixes.
	 * @param paLinkedEntityPrefixes Tableau des préfixes de liens d'entités qu'il faut récupérer, optionnel (les récupère tous dans ce cas).
	 */
	private filterEntityLinksFunction(paLinkedEntityPrefixes: Array<EPrefix>): (poDoc: IEntityLink) => boolean {
		return (poDoc: IEntityLink) => paLinkedEntityPrefixes.some((pePrefix: EPrefix) => poDoc._id.indexOf(`-${pePrefix}`) >= 0);
	}

	/** Permet de récupérer l'identifiant de la cible du lien.
	 * ### Attention, utiliser de préférence la méthode *getEntityLinkPartFromPrefix(entityLink, prefix?)* sauf si le lien est de type `lnk_a-a`.
	 * @param poItemLink Lien.
	 */
	public getLinkTargetId(poItemLink: IEntityLink): string {
		return EntityHelper.getIdsFromLinkId(poItemLink._id)[1];
	}

	/** Permet de récupérer l'identifiant de la base de données où se trouve la cible du lien.
	 * ### Attention, utiliser de préférence la méthode `getEntityLinkPartFromPrefix()` sauf si le lien est de type `lnk_a-a`.
	 * @param poItemLink Lien.
	 */
	public getLinkTargetDatabaseId(poItemLink: IEntityLink): string {
		return poItemLink.databasesSource[1];
	}

	/** Permet de récupérer l'identifiant de la source du lien.
	 * ### Attention, utiliser de préférence la méthode `getEntityLinkPartFromPrefix()` sauf si le lien est de type `lnk_a-a`.
	 * @param poItemLink Lien.
	 */
	public getLinkSourceId(poItemLink: IEntityLink): string {
		return EntityHelper.getIdsFromLinkId(poItemLink._id)[0];
	}

	/** Permet de récupérer l'identifiant de la base de données où se trouve la source du lien.
	 * ### Attention, utiliser de préférence la méthode `getEntityLinkPartFromPrefix()` sauf si le lien est de type `lnk_a-a`.
	 * @param poItemLink Lien.
	 */
	public getLinkSourceDatabaseId(poItemLink: IEntityLink): string {
		return poItemLink.databasesSource[0];
	}

	/** Supprime un lien.
	 * @param poLink lien a supprimer
	 * @param psDatabaseId base de donnée du lien, si le paramètre n'est pas fournit il sera pris dans le $cacheData
	 */
	public deleteItemLink(poLink: IEntityLink, psDatabaseId?: string): Observable<IStoreDataResponse> {
		return this.isvcStore.delete(poLink, psDatabaseId)
			.pipe(
				tap(
					_ => console.debug(`${EntityLinkService.C_LOG_ID}Le lien: ${poLink._id} à été supprimé.`),
					poError => console.error(`${EntityLinkService.C_LOG_ID}Erreur pendant la suppression du lien: ${poLink._id}.`, poError)
				)
			);
	}

	/** Permet de naviguer vers le formulaire pour afficher la donnée ciblée par le lien.
	 * @param poLink Lien vers la donnée ciblée.
	 * @param psSourceId Identifiant de la source du lien afin de récupérer l'autre.
	 */
	public routeToLinkedItem(poLink: IEntityLink, psSourceId: string): Observable<IStoreDocument> {
		const loEntityPart: IEntityLinkPart = EntityHelper.getEntityLinkPartFromSourcePrefix(poLink, IdHelper.getPrefixFromId(psSourceId));

		if (!StringHelper.isBlank(loEntityPart.route))
			return from(this.ioRouter.navigateByUrl(loEntityPart.route)).pipe(map(_ => null as IStoreDocument));
		else {
			const loDataSource: IDataSource = {
				databaseId: loEntityPart.databaseId,
				viewParams: {
					key: loEntityPart.entityId,
					include_docs: true
				}
			};

			return this.isvcStore.getOne<IStoreDocument>(loDataSource)
				.pipe(
					tap(
						(poDocument: IStoreDocument) => this.ioRouter.navigateByUrl(this.buildEntity(poDocument).route),
						poError => {
							const lsMessage = poError.isEmptyResult ? "La cible du lien est introuvable." : "Erreur lors de la récupération de la cible du lien. ";
							this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: lsMessage, buttons: [{ text: "OK", cssClass: "button-assertive" }] }));
							console.error(`${EntityLinkService.C_LOG_ID}${lsMessage}`, poError);
						}
					)
				);
		}
	}

	/** Supprime tous les liens d'une entité en fonction d'un identifiant.
	 * @param psEntityId Identifiant de l'entité dont il faut supprimer tous les liens.
	 * @returns `true` si la suppression a réussi, `false` sinon.
	 */
	public deleteEntityLinksById(poData: string | string[]): Observable<boolean> {
		let loLinkedIdsBySourceIds$: Observable<Map<string, string[]>>;

		if (poData instanceof Array)
			loLinkedIdsBySourceIds$ = this.getLinkedEntityIds(poData);
		else {
			loLinkedIdsBySourceIds$ = this.getLinkedEntityIds(poData).pipe(map((paLinkedIds: string[]) => {
				const loMap = new Map<string, string[]>();

				loMap.set(poData, paLinkedIds);

				return loMap;
			}));
		}

		return loLinkedIdsBySourceIds$
			.pipe(
				mergeMap((poLinkedIdsBySourceIds: Map<string, string[]>) => {
					const laKeys: string[] = [];

					poLinkedIdsBySourceIds.forEach((paLinkedIds: string[], psSourceId: string) => {
						paLinkedIds.forEach((psLinkedId: string) =>
							laKeys.push(this.createEntityLinkId(psLinkedId, psSourceId), this.createEntityLinkId(psSourceId, psLinkedId))
						);
					});

					const loDataSource: IDataSource = {
						databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
						viewParams: { keys: laKeys }
					};

					return this.isvcStore.get<IEntityLink>(loDataSource);
				}),
				mergeMap((paResults: Array<IEntityLink>) => this.isvcStore.deleteMultipleDocuments(paResults))
			);
	}

	//#endregion

	//#region Entity

	/** Lève un événement de portée application pour notifier un changement d'entité active.
	 * @param poOldEntity Ancienne entité
	 * @param poNewEntity Nouvelle entité
	 */
	private onCurrentEntityChanged(poOldEntity: IEntity, poNewEntity: IEntity): Observable<void> {
		return of(poNewEntity)
			.pipe(
				mergeMap((poEntity: IEntity) => !!poEntity ? this.getEntityLinks(poEntity.id) : of(null)),
				catchError(poError => {
					console.error(`${EntityLinkService.C_LOG_ID}Erreur lors de la récupération des liens.`, poError);
					// En cas d'erreur on notifie l'événement sans entité liée.
					return this.updateCurrentEntityLinks(poOldEntity, poNewEntity).pipe(mergeMapTo(throwError(poError)));
				}),
				mergeMap((paResults: IEntityLink[]) => this.updateCurrentEntityLinks(poOldEntity, poNewEntity, paResults))
			);
	}

	private updateCurrentEntityLinks(poOldEntity: IEntity, poNewEntity: IEntity, paLinkedEntities: IEntityLink[] = []): Observable<void> {
		console.debug(
			`${EntityLinkService.C_LOG_ID}Current entity changed from ${poOldEntity != null ? poOldEntity.id : "none"} to ${poNewEntity != null ? poNewEntity.id : "none"}.\nNew entity:`,
			poNewEntity,
			"\nLinked entities :",
			paLinkedEntities
		);

		if (poNewEntity)
			return this.updateNavbar();
		else
			return of(this.removeLinkedEntitiesNavButton());
	}

	/** Construit une entité à partir d'un modèle.
	 * @param poModel Modèle à résoudre.
	 */
	public buildEntity<T extends IStoreDocument>(poModel: T): Entity<T> {
		return this.getEntityBuilder<T>(poModel._id)?.build(poModel);
	}

	/** Récupère l'entité constructrice d'un modèle.
	 * @param poModel Modèle de l'entité constructrice à récupérer.
	 */
	public getEntityBuilder<T extends IStoreDocument>(psModelId: string): EntityBuilder<T> {
		const loBuilder: EntityBuilder<T> = this.entityBuilders.find((poBuilder: EntityBuilder) => poBuilder.match(psModelId)) as unknown as EntityBuilder<T>;

		if (!loBuilder)
			throw new Error(`Cannot build entity for model: ${psModelId}`);

		return loBuilder;
	}

	/** Fixe l'entité courante de l'application.
	 * @param poModel Modèle utilisé
	 * @param pbCheckModelDiffers Indique si on doit vérifier que le modèle est différent de l'actuel ou non, `true` par défaut.
	 */
	private setCurrentEntity(poModel: IStoreDocument, pbCheckModelDiffers: boolean = true): Observable<boolean> {
		if (!poModel || StringHelper.isBlank(poModel._id))
			return throwError("No model was provided to set the current entity. Please use clearCurrentEntity() if you intend to reset it.");

		else if (UserData.current.isGuest) {
			console.debug(`${EntityLinkService.C_LOG_ID}Current entity will be not set because user is guest.`);
			return of(false);
		}

		const loCurrentEntity: IEntity = this.currentEntity;

		// On s'assure qu'il ne s'agisse pas de la même entité avant de faire quoi que ce soit.
		if (!pbCheckModelDiffers || !loCurrentEntity ||
			(loCurrentEntity.model && (loCurrentEntity.model as IStoreDocument)._id !== (poModel as IStoreDocument)._id)) {

			const loOldEntity: IEntity = loCurrentEntity;
			this.maCurrentEntityStack.push(this.buildEntity(poModel));
			return this.onCurrentEntityChanged(loOldEntity, this.currentEntity).pipe(mapTo(true));
		}
		else {
			console.debug(`${EntityLinkService.C_LOG_ID}Current entity was already set to the same model.`);
			return of(true);
		}
	}

	/** @implements */
	public trySetCurrentEntity(poModel: IStoreDocument): Observable<boolean> {
		return this.setCurrentEntity(poModel)
			.pipe(
				catchError((poError) => {
					console.warn(`${EntityLinkService.C_LOG_ID}Cannot set current entity for `, poModel, "Erreur :", poError);
					return of(false);
				})
			);
	}

	/** @implements */
	public clearCurrentEntity(psModelId: string): Observable<boolean> {
		const loOldEntity: IEntity = this.currentEntity;

		if (ArrayHelper.removeElementByFinder(this.maCurrentEntityStack, (poItem: IEntity) => poItem?.model?._id === psModelId)) {
			console.debug(`${EntityLinkService.C_LOG_ID}Current entity was cleared.`);
			return this.onCurrentEntityChanged(loOldEntity, this.currentEntity).pipe(mapTo(true));
		}
		else {
			console.debug(`${EntityLinkService.C_LOG_ID}Current entity was already cleared.`);
			return of(false);
		}
	}

	/** Vérifie si le modèle est valide.
	 * @param poModel Modèle à vérifier
	 */
	public isValidEntityModel(poModel: IStoreDocument): boolean {
		return poModel && !StringHelper.isBlank(poModel._id);
	}

	/** Construit une entité depuis l'identifiant de l'entité et sa base de données de provenance.
	 * @param psEntityId Identifiant de l'entité.
	 * @param psDatabaseId Identifiant de base de données de provenance de l'entité à construire.
	 */
	public buildEntityFromIdAndDatabaseId(psEntityId: string, psDatabaseId: string): Observable<IEntity>;
	/** Construit une entité depuis l'identifiant de l'entité et sa base de données de provenance.
	 * @param poEntityIdAndDatabaseId Objet regroupant l'identifiant de l'entité et sa base de données de provenance.
	 */
	public buildEntityFromIdAndDatabaseId(poEntityIdAndDatabaseId: IEntityLinkPart): Observable<IEntity>;
	public buildEntityFromIdAndDatabaseId(poEntityIdAndDatabaseIdOrEntityId: IEntityLinkPart | string, psDatabaseId?: string): Observable<IEntity> {
		const loEntityIdAndDatabaseId: IEntityLinkPart = typeof poEntityIdAndDatabaseIdOrEntityId === "string" ?
			{ entityId: poEntityIdAndDatabaseIdOrEntityId, databaseId: psDatabaseId } : poEntityIdAndDatabaseIdOrEntityId;

		const loDataSource: IDataSource = {
			databaseId: loEntityIdAndDatabaseId.databaseId,
			viewParams: {
				include_docs: true,
				key: loEntityIdAndDatabaseId.entityId
			}
		};

		return this.isvcStore.getOne<IStoreDocument>(loDataSource)
			.pipe(map((poDocument: IStoreDocument) => this.buildEntity(poDocument)));
	}

	/** Récupère une entité.
	 * @param psDatabaseId Identifiant de la base de données où récupérer l'entité.
	 * @param psEntityId Identifiant de l'entité à récupérer.
	 */
	public getEntity<T extends IStoreDocument>(psDatabaseId: string, psEntityId: string): Observable<Entity<T>> {
		const loBuilder: EntityBuilder<T> = this.getEntityBuilder(psEntityId);

		if (loBuilder.getEntity) // Une récupération personnalisée a été fournie.
			return loBuilder.getEntity(psDatabaseId, psEntityId);

		else { // Utilisation de la récupération par défaut.
			return this.getModelEntity(psDatabaseId, psEntityId)
				.pipe(map((poModel: T) => loBuilder.build(poModel)));
		}
	}

	/** Récupère le modèle d'une entité en recherchant un document correspondant à son identifiant dans la base de données indiquée.
	 * @param psDatabaseId Identifiant de la base de données où récupérer le modèle.
	 * @param psEntityId Identifiant du modèle à récupérer.
	 */
	private getModelEntity(psDatabaseId: string, psEntityId: string): Observable<IStoreDocument> {
		const loDataSource: IDataSource = {
			databaseId: psDatabaseId,
			viewParams: {
				key: psEntityId,
				include_docs: true
			}
		};

		return this.isvcStore.getOne(loDataSource, false);
	}

	//#endregion

	//#region EntityLinks

	/** Crée l'identifiant d'une entité liée à partir de deux entités.
	 * @param psSourceId Identifiant de l'entité source (partie de gauche de l'identifiant).
	 * @param psTargetId Identifiant de l'entité cible qui sera liée à la source (partie de droite de l'identifiant).
	 */
	public createEntityLinkId(psSourceId: string, psTargetId: string): string;
	/** Crée l'identifiant d'une entité liée à partir de deux entités.
	 * @param poSourceEntity Entité source (partie de gauche de l'identifiant).
	 * @param poTargetEntity Entité cible qui sera liée à la source (partie de droite de l'identifiant).
	 */
	public createEntityLinkId(poSourceEntity: IEntity, poTargetEntity: IEntity): string;
	/** Crée l'identifiant d'une entité liée à partir de deux documents issus de la base de données.
	 * @param poSourceDocument Document source (partie de gauche de l'identifiant).
	 * @param poTargetDocument Document cible qui sera lié à la source (partie de droite de l'identifiant).
	 */
	public createEntityLinkId(poSourceDocument: IStoreDocument, poTargetDocument: IStoreDocument): string;
	/** Crée l'identifiant d'une entité liée à partir d'une entité source et d'un document cible (issu de la base de données).
	 * @param poSourceEntity Entité source (partie de gauche de l'identifiant).
	 * @param poTargetDocument Document cible qui sera lié à la source (partie de droite de l'identifiant).
	 */
	public createEntityLinkId(poSourceEntity: IEntity, poTargetDocument: IStoreDocument): string;
	/** Crée l'identifiant d'une entité liée à partir d'un document source (issu de la base de données) et d'une entité cible.
	 * @param poSourceDocument Document source (partie de gauche de l'identifiant).
	 * @param poTargetEntity Entité cible qui sera liée à la source (partie de droite de l'identifiant).
	 */
	public createEntityLinkId(poSourceDocument: IStoreDocument, poTargetEntity: IEntity): string;
	public createEntityLinkId(poSource: IEntity | IStoreDocument | string, poTarget: IEntity | IStoreDocument | string): string {
		let lsLeftPartId: string;
		let lsRightPartId: string;

		if (typeof poSource === "string")
			lsLeftPartId = poSource;
		else if (StringHelper.isBlank((poSource as IStoreDocument)._id))
			lsLeftPartId = this.createIdFromEntity(poSource as IEntity);
		else
			lsLeftPartId = (poSource as IStoreDocument)._id;

		if (typeof poTarget === "string")
			lsRightPartId = poTarget;
		else if (StringHelper.isBlank((poTarget as IStoreDocument)._id))
			lsRightPartId = this.createIdFromEntity(poTarget as IEntity);
		else
			lsRightPartId = (poTarget as IStoreDocument)._id;

		return `${IdHelper.buildId(EPrefix.link, lsLeftPartId)}-${lsRightPartId}`;
	}

	private createIdFromEntity(poEntity: IEntity): string {
		return `${poEntity.id}${StringHelper.isBlank(poEntity.subPath) ? "" : `/${poEntity.subPath}`}`;
	}

	/** Construit un lien avec les éléments source et cible.
	 * @param poCurrentEntity Entité courante.
	 * @param poEntity Entité cible.
	 */
	public buildEntityLinks(poCurrentEntity: IEntity, poEntity: IEntity): IEntityLink[] {
		return [this.buildEntityLink(poCurrentEntity, poEntity), this.buildEntityLink(poEntity, poCurrentEntity)];
	}

	/** Construit un lien avec les éléments source et cible.
	 * @param poCurrentEntity Entité courante.
	 * @param poEntity Entité cible.
	 */
	public buildEntityLink(poCurrentEntity: IEntity, poEntity: IEntity): IEntityLink {
		const laDBSources: Array<string> = [];

		if (!StringHelper.isBlank(poCurrentEntity.databaseName))
			laDBSources.push(poCurrentEntity.databaseName);

		if (!StringHelper.isBlank(poEntity.databaseName))
			laDBSources.push(poEntity.databaseName);

		// Construit le document lien.
		return {
			_id: this.createEntityLinkId(poCurrentEntity, poEntity),
			name1: poCurrentEntity.name,
			name2: poEntity.name,
			createDate: new Date(),
			databasesSource: laDBSources,
			routes: [poCurrentEntity.route, poEntity.route]
		};
	}

	/** Marque des liens à ajouter dans les données de cache du modèle.
	 * @param poModel Modèle à sauvegarder.
	 * @param paEntities Tableau des entités à ajouter.
	 * @param psSubPath Chemin pour retrouver l'entité ciblée dans le modèle.
	 */
	public cacheLinkToAdd(poModel: IStoreDocument, paEntities: IEntity[], psSubPath?: string): void;
	/** Marque un lien à ajouter dans les données de cache du modèle.
	 * @param poModel Modèle à sauvegarder.
	 * @param poEntity Entité à ajouter.
	 * @param psSubPath Chemin pour retrouver l'entité ciblée dans le modèle.
	 */
	public cacheLinkToAdd(poModel: IStoreDocument, poEntity: IEntity, psSubPath?: string): void;
	public cacheLinkToAdd(poModel: IStoreDocument, poEntityData: IEntity | IEntity[], psSubPath?: string): void {
		if (!(poEntityData instanceof Array))
			poEntityData = [poEntityData];

		poEntityData.forEach((poEntity: IEntity) => this.cacheLinkToAddRemoveInternal(poModel, poEntity, EEntityLinkCacheData.Add, psSubPath));
	}

	/** Méthode interne d'ajout d'un lien dans le cacheData.
	 * @param poModel Modèle à sauvegarder.
	 * @param poItemLink Lien à mettre à jour.
	 * @param peCacheDataState Action à réaliser sur le lien.
	 * @param psSubPath Chemin pour retrouver l'entité ciblée dans le modèle.
	 */
	private cacheLinkToAddRemoveInternal(poModel: IStoreDocument, poEntity: IEntity, peCacheDataState: EEntityLinkCacheData, psSubPath?: string): void {
		if (!poEntity)
			return;

		const loModelCacheData: ICacheData = StoreHelper.getDocumentCacheData(poModel);

		if (loModelCacheData?.links) {
			const lnIndex: number = loModelCacheData.links.findIndex((poEntityLinkCache: IEntityLinkCache) => poEntityLinkCache.entity.id === poEntity.id &&
				psSubPath === poEntityLinkCache.targetSubPath);

			if (lnIndex >= 0) { // Entité déjà présente dans la cacheData, il faut metttre à jour.
				// Si les états sont différents, c'est une annulation (add/remove disponibles => add + remove = annulation). Sinon, pas besoin de le réajouter.
				if (loModelCacheData.links[lnIndex].cacheDataState !== peCacheDataState)
					loModelCacheData.links.splice(lnIndex, 1);
			}
			else  // Entité non présente dans la cacheData, il faut l'ajouter.
				loModelCacheData.links.push({ entity: poEntity, cacheDataState: peCacheDataState, targetSubPath: psSubPath });
		}
		else {
			const loCacheData: ICacheData = { links: [{ entity: poEntity, cacheDataState: peCacheDataState, targetSubPath: psSubPath }] };
			StoreHelper.updateDocumentCacheData(poModel, loCacheData);
		}
	}

	/** Marque des liens à supprimer dans le $cacheData du modèle.
	 * @param poModel Modèle à sauvegarder.
	 * @param paEntity Entités à supprimer.
	 * @param psSubPath Chemin pour retrouver l'entité ciblée dans le modèle.
	 */
	public cacheLinkToRemove(poModel: IStoreDocument, paEntity: IEntity[], psSubPath?: string): void;
	/** Marque un lien à supprimer dans le $cacheData du modèle.
	 * @param poModel Modèle à sauvegarder.
	 * @param poEntity Entité à supprimer.
	 * @param psSubPath Chemin pour retrouver l'entité ciblée dans le modèle.
	 */
	public cacheLinkToRemove(poModel: IStoreDocument, poEntity: IEntity, psSubPath?: string): void;
	public cacheLinkToRemove(poModel: any, poEntityData: IEntity | IEntity[], psSubPath?: string): void {
		if (!(poEntityData instanceof Array))
			poEntityData = [poEntityData];

		poEntityData.forEach((poEntity: IEntity) => this.cacheLinkToAddRemoveInternal(poModel, poEntity, EEntityLinkCacheData.Remove, psSubPath));
	}

	/** Retourne la liste des liens à créer ou supprimer.
	 * @param poModel Modèle à enregistrer.
	 * @param peEntityLinkCacheData État du cacheData (ajout ou suppression).
	 */
	private getCachedEntities(poModel: IStoreDocument, peEntityLinkCacheData: EEntityLinkCacheData): Array<IEntityLinkCache> {
		const loCacheData: ICacheData = StoreHelper.getDocumentCacheData(poModel);

		if (loCacheData?.links) {
			return loCacheData.links
				.filter((poEntityLink: IEntityLinkCache) => poEntityLink.cacheDataState === peEntityLinkCacheData)
				.map((poEntityLink: IEntityLinkCache) => poEntityLink);
		}
		else
			return [];
	}

	/** Persistence de sauvegarde et de suppression des liens.
	 * @param poModel Modèle a sauvegarder.
	 */
	public saveEntityLinks(poModel: IStoreDocument): Observable<boolean>;
	/** Persistence de sauvegarde et de suppression des liens.
	 * @param poEntity Entité a sauvegarder.
	 */
	public saveEntityLinks<T extends IStoreDocument = IStoreDocument>(poEntity: Entity<T>): Observable<boolean>;
	public saveEntityLinks<T extends IStoreDocument = IStoreDocument>(poData: IStoreDocument | Entity<T>): Observable<boolean> {
		const loModelEntity: IEntity = poData instanceof Entity ? poData : this.buildEntity(poData);

		return forkJoin([this.saveEntityLinkAdded(loModelEntity), this.saveEntityLinkRemoved(loModelEntity)])
			.pipe(
				mergeMap((paResults: boolean[]) => this.updateNavbar().pipe(mapTo(paResults))),
				map((paResults: boolean[]) => {
					const lbResult: boolean = paResults.every((pbResult: boolean) => pbResult);
					if (lbResult)
						EntityLinkService.deleteLinksFromDocumentCacheData(poData instanceof Entity ? poData.model : poData);
					return lbResult;
				})
			);
	}

	/** Persistence des liens à ajouter.
	 * @param poModelEntity Entité du modèle.
	 */
	private saveEntityLinkAdded(poModelEntity: IEntity): Observable<boolean> {
		const laCachedLinks: IEntityLinkCache[] = this.getCachedEntities(poModelEntity.model, EEntityLinkCacheData.Add);

		if (!ArrayHelper.hasElements(laCachedLinks)) // Si tableau vide, alors succès.
			return of(true);

		else {
			return this.createEntityLinks(poModelEntity, laCachedLinks)
				.pipe(
					catchError(poError => { console.error(`${EntityLinkService.C_LOG_ID}Error create entityLinks`, poError); return of({ id: poModelEntity.id, ok: false } as IStoreDataResponse); }),
					map((paResults: Array<IStoreDataResponse | ICustomPouchError>) => {
						const laCorrectCreatedLinks: IStoreDataResponse[] = [];
						const laErrors: ICustomPouchError[] = [];

						paResults.forEach((poItem: IStoreDataResponse | ICustomPouchError) => {
							if ((poItem as IStoreDataResponse).ok)
								laCorrectCreatedLinks.push(poItem as IStoreDataResponse);
							else if ((poItem as ICustomPouchError).error)
								laErrors.push(poItem as ICustomPouchError);
						});

						if (laCorrectCreatedLinks.length === paResults.length)
							return true;
						else {
							if (ArrayHelper.hasElements(laErrors))
								console.error(`${EntityLinkService.C_LOG_ID}Erreur création des liens d'entités :`, laErrors);
							return false;
						}
					})
				);
		}
	}

	/** Persistence des liens à supprimer.
	 * @param poModelEntity Entité du modèle.
	 */
	private saveEntityLinkRemoved(poModelEntity: IEntity): Observable<boolean> {
		const laCachedEntities: IEntityLinkCache[] = this.getCachedEntities(poModelEntity.model, EEntityLinkCacheData.Remove);

		if (ArrayHelper.hasElements(laCachedEntities)) { // Si on a des entités liées à supprimer on les supprime, sinon on a terminé.
			const loDataSource: IDataSource = {
				databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
				viewParams: {
					include_docs: true,
					keys: ArrayHelper.flat(laCachedEntities.map((poEntity: IEntityLinkCache) => {
						poModelEntity.subPath = poEntity.targetSubPath;
						return this.buildEntityLinks(poModelEntity, poEntity.entity).map((poEntityLink: IEntityLink) => poEntityLink._id);
					}))
				}
			};

			return this.isvcStore.get(loDataSource)
				.pipe(mergeMap((paResults: IStoreDocument[]) => this.isvcStore.deleteMultipleDocuments(paResults)));
		}

		else
			return of(true);
	}

	/** Renvoie le préfixe d'une entité.
	 * @param psEntityId Identifiant de l'entité dont veut récupérer le préfixe.
	 */
	public getEntityPrefix(psEntityId: string): string {
		return psEntityId.substring(0, psEntityId.indexOf("_") + 1);
	}

	/** Charge le mappage entité-page à partir du fichier de configuration. */
	private loadEntityBuilders(): EntityBuilder[] {
		if (!ConfigData.entityBuilders) {
			console.warn(`${EntityLinkService.C_LOG_ID}No configuration found for entityBuilders. Empty configuration will be used.`);
			return [];
		}
		else
			return ConfigData.entityBuilders;
	}

	/** Supprime les liens du cacheData du document fourni.
	 * @param poDocument Document dont il faut supprimer les liens de son cacheData.
	 */
	public static deleteLinksFromDocumentCacheData(poDocument: IStoreDocument): void {
		const loDocumentCacheData: ICacheData = StoreHelper.getDocumentCacheData(poDocument);

		if (loDocumentCacheData)
			delete loDocumentCacheData.links;
	}

	/** Détermine la catégorie à laquelle appartient une entité. */
	public getEntityCategory(psEntityId: string): string {
		return this.getEntityBuilder(psEntityId).category(psEntityId);
	}

	/** Met à jour le nombre d'entités liées dans la barre de navigation.*/
	private updateNavbar(poEntity?: IEntity): Observable<void> {
		if (this.currentEntity || poEntity) { // Si une entité courante est définie, il faut aller chercher ses entités liées.
			return this.getLinkedEntities((poEntity ?? this.currentEntity).id)
				.pipe(
					tap((paResults: IStoreDocument[]) => this.setNavbar(this.currentEntity, paResults)),
					mapTo(undefined)
				);
		}
		else // Sinon on ne met pas à jour la navbar.
			return of(undefined);
	}

	private setNavbar(poEntity: IEntity, paLinks: IStoreDocument[]): void {
		const loLinkInfo = new LinkInfo({
			meta: { schemaVersion: "2.0.0" },
			id: EntityLinkService.C_LINKED_ITEMS_LIST_NAV_BUTTON_ID,
			label: paLinks.length.toString(),
			templateId: ELinkTemplate.icon,
			params: { icon: "link" },
			action: ELinkAction.callback,
			actionParams: { function: () => this.routeToLinkedItemsList(poEntity) }
		});
		const loNavbarEvent: INavbarEvent = {
			id: EntityLinkService.C_LINKED_ITEMS_LIST_NAV_BUTTON_ID,
			action: EBarElementAction.add,
			links: [loLinkInfo]
		};

		this.isvcPageManager.raiseNavbarEvent(loNavbarEvent);
	}

	/** Supprime le bouton d'ouverture de la popup des liens. */
	private removeLinkedEntitiesNavButton(): void {
		this.isvcPageManager.raiseNavbarEvent({
			id: EntityLinkService.C_LINKED_ITEMS_LIST_NAV_BUTTON_ID,
			action: EBarElementAction.clear
		} as INavbarEvent);
	}

	/** Navigue vers la page de la liste des items liés.
	 * @param poEntity Entité courante.
	 */
	private routeToLinkedItemsList(poEntity: IEntity): void {
		const loPageInfo: PageInfo = new PageInfo({
			componentName: "linkedItemsList",
			isModal: true,
			title: "Informations liées",
			params: {
				params: {
					itemId: poEntity.id
				} as ILinkedItemsListParams
			}
		});

		this.isvcPageManager.routePageFromInfo(loPageInfo);
	}

	/** Permet de sélectionner des entités à lier à une entité source.
	 * @param poEntityModel Modèle de l'entité.
	 * @param paEntityBuilders Liste des constructeurs d'entités disponibles pour la sélection.
	 * @param poSelectorParams Objet des paramètres du sélecteur d'entités, optionnel.
	 */
	public selectEntities(poEntityModel: IStoreDocument, paEntityBuilders: EntityBuilder[] = ConfigData.entityBuilders,
		poSelectorParams: ISelectorParams<IEntityLink> = {}): Observable<IEntity[]> {

		return of(ArrayHelper.hasElements(paEntityBuilders) && paEntityBuilders.length === 1)
			.pipe(
				mergeMap((pbHasOneElement: boolean) => pbHasOneElement ? of(ArrayHelper.getFirstElement(paEntityBuilders)) : this.selectEntityType(paEntityBuilders)),
				mergeMap((poEntityBuilder: EntityBuilder) => this.getEntityLinks(poEntityModel._id, [poEntityBuilder.prefix as EPrefix])
						.pipe(mergeMap((paLinkedEntities: IEntityLink[]) => this.openEntitySelector(poEntityModel, poEntityBuilder, paLinkedEntities, poSelectorParams)))),
				finalize(() => EntityLinkService.deleteLinksFromDocumentCacheData(poEntityModel))
			);
	}

	private openEntitySelector(poEntityModel: IStoreDocument, poEntityBuilder: EntityBuilder, paLinkedEntities: IEntityLink[],
		poSelectorParams: ISelectorParams<IEntityLink>): Observable<IEntity[]> {

		const loEntitySubject = new Subject<IEntity[]>();
		const loPageInfo: PageInfo = new PageInfo({
			componentName: "entitySelector",
			params: {
				params: {
					entitySelectionSubject: loEntitySubject,
					entityDataSource: poEntityBuilder.dataSource,
					model: poEntityModel,
					preSelectedIds: poSelectorParams.preSelectedIds ? poSelectorParams.preSelectedIds : paLinkedEntities.map((poEntityLink: IEntityLink) => poEntityLink._id),
					hasSearchbox: poSelectorParams.hasSearchbox !== undefined ? poSelectorParams.hasSearchbox : true,
					selectionLimit: poSelectorParams.selectionLimit,
					selectionMinimum: poSelectorParams.selectionMinimum,
					formParams: poSelectorParams.formParams,
					searchOptions: poSelectorParams.searchOptions
				} as IEntitySelectorParams
			},
			isModal: true,
			title: poEntityBuilder.category()
		});

		return from(this.isvcPageManager.routePageFromInfo(loPageInfo))
			.pipe(
				mergeMap(_ => loEntitySubject.asObservable().pipe(take(1))),
				finalize(() => {
					this.ioModalCtrl.dismiss();
					loEntitySubject.complete();
				})
			);
	}

	/** Permet de sélectionner le type d'entité. */
	private selectEntityType(paEntityBuilders: EntityBuilder[]): Observable<EntityBuilder> {
		const loEntityTypeSubject = new Subject<EntityBuilder>();
		const loPageInfo = new PageInfo(
			{
				componentName: "entityTypeSelector",
				params: {
					params: {
						entityTypeSelectionSubject: loEntityTypeSubject,
						selectionLimit: 1,
						entityBuilders: paEntityBuilders
					} as IEntityTypeSelectorParams
				},
				isModal: true,
				title: "Types d'entités"
			}
		);

		return from(this.isvcPageManager.routePageFromInfo(loPageInfo))
			.pipe(
				mergeMap(_ => loEntityTypeSubject.asObservable()),
				tap(_ => {
					this.ioModalCtrl.dismiss();
					loEntityTypeSubject.complete();
				})
			);
	}

	/** Marque les liens vers les entités à mettre à jour dans un modèle.
	 * @param poModel Modèle dont il faut mettre à jour les liens.
	 * @param paOldEntity Liste des entités préalables.
	 * @param paNewEntity Liste des entités sélectionnés.
	 */
	public updateCachedEntityLinks(poModel: IStoreDocument, paOldEntity: Array<IEntity>, paNewEntity: Array<IEntity>): void {

		// Si une entité n'est pas dans l'ancienne liste mais dans la nouvelle alors il a été ajouté.
		const laAddLinks: Array<IEntity> = paNewEntity.filter((poNew: IEntity) => paOldEntity.findIndex((poOld: IEntity) => poOld.id === poNew.id) === -1);
		this.cacheLinkToAdd(poModel, laAddLinks);

		// Si une entité n'est pas dans la nouvelle liste mais dans la vieille alors il a été supprimé.
		const laRemoveLinks: Array<IEntity> = paOldEntity.filter((poOld: IEntity) => paNewEntity.findIndex((poNew: IEntity) => poOld.id === poNew.id) === -1);
		this.cacheLinkToRemove(poModel, laRemoveLinks);
	}

	/** Retourne une chaîne représentant le lien en fonction de la valeur de `name`.
	 * À défaut de valeur name, propose une autre représentation par défaut.
	 * @param psEntityId Identifiant de l'entité.
	 * @param psName Nom du lien (peut être non défini).
	 */
	public getEntityLinkDisplayValue(psEntityId: string, psName: string): string;
	/** Récupère le nom à afficher d'un lien d'entité.
	* Retourne `psName` s'il n'est pas possible de récupérer le nom à partir du modèle, ou la catégorie par défaut.
	* @param poModel Modèle de l'entité.
	* @param psName Nom du lien (peut être non défini).
	*/
	public getEntityLinkDisplayValue<T extends IStoreDocument = IStoreDocument>(poModel: T, psName?: string): string;
	public getEntityLinkDisplayValue<T extends IStoreDocument = IStoreDocument>(poData: string | T, psName?: string): string {
		let lsResult: string | undefined;

		if (typeof poData !== "string") {
			try { lsResult = this.buildEntity(poData).name; }
			catch (poError) { console.warn(`${EntityLinkService.C_LOG_ID}Aucun entity builder pour l'entité '${poData._id}' !`, poError); }
		}

		if (!StringHelper.isBlank(lsResult))
			return lsResult;
		else if (StringHelper.isBlank(psName))
			return this.getEntityDefaultDisplayValue(typeof poData === "string" ? poData : poData._id);
		else
			return psName;
	}

	/** Propose une représentation textuelle à partir d'un ID d'entité.
	 * @param psEntityId Identifiant de l'entité.
	 */
	private getEntityDefaultDisplayValue(psEntityId: string): string {
		try {
			return `(${this.getEntityBuilder(psEntityId).category()})`;
		} catch (poError) {
			return psEntityId;
		}
	}

	/** Détermine si l'entité indiquée peut être supprimée.
	 * @param psEntityId Identifiant de l'entité qu'on veut supprimer.
	 */
	public ensureIsDeletableEntity(poEntity: IStoreDocument, paLinkedDocs: IStoreDocument[] = []): Observable<boolean> {
		return this.getEntityLinks(poEntity._id)
			.pipe(
				mergeMap((paLinks: IEntityLink[]) => this.getEntityBuilder(poEntity._id).guard.isDeletable(poEntity, paLinks, paLinkedDocs)),
				map((poGuardResult: IGuardResult) => {
					if (poGuardResult.result)
						return true;
					else {
						this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: poGuardResult.message, header: "Suppression interdite" }));
						return false;
					}
				})
			);
	}

	/** Récupère les identifiants des entités liées à un identifiant.
	 * @param psItemId Identifiant de la données source des liens.
	 * @param paLinkedEntityPrefixes Préfixes des données liées.
	 * @param pbLive Indique si la requête doit être live ou non.
	 */
	public getLinkedEntityIds(psItemId: string, paLinkedEntityPrefixes?: Array<EPrefix>, pbLive?: boolean): Observable<string[]>;
	/** Récupère les identifiants des entités liées à un tableau d'identifiants.
	 * @param paItemIds Tableau des identifiants des données source des liens.
	 * @param paLinkedEntityPrefixes Préfixes des données liées.
	 * @param pbLive Indique si la requête doit être live ou non.
	 */
	public getLinkedEntityIds(paItemIds: string[], paLinkedEntityPrefixes?: Array<EPrefix>, pbLive?: boolean): Observable<Map<string, string[]>>;
	public getLinkedEntityIds(poData: string | string[], paLinkedEntityPrefixes?: Array<EPrefix>, pbLive?: boolean): Observable<string[] | Map<string, string[]>> {
		if (poData instanceof Array)
			return this.getLinkedEntityIdsForMultipleItems(poData, paLinkedEntityPrefixes, pbLive);
		else
			return this.getEntityLinks(poData, paLinkedEntityPrefixes, pbLive)
				.pipe(map((paEntityLinks: IEntityLink[]) => paEntityLinks.map((poEntityLink: IEntityLink) => this.getLinkTargetId(poEntityLink))));
	}

	private getLinkedEntityIdsForMultipleItems(paItemIds: string[], paLinkedEntityPrefixes: EPrefix[] = [], pbLive?: boolean): Observable<Map<string, string[]>> {
		return this.getEntityLinksForMultipleItems(paItemIds, paLinkedEntityPrefixes, pbLive, false)
			.pipe(map((paEntityLinks: IEntityLink[]) => this.groupTargetIdsBySourceId(paEntityLinks)));
	}

	private getEntityLinksForMultipleItems(paItemIds: string[], paLinkedEntityPrefixes: EPrefix[] = [], pbLive?: boolean, pbIncludeDocs?: boolean,
		pbIncludeDeeplinks?: boolean): Observable<IEntityLink[]> {

		return defer(() => {
			const laGetEntityLinks$: Observable<IEntityLink[]>[] = [];

			ArrayHelper.groupBy(paItemIds.filter((psId: string) => !StringHelper.isBlank(psId)), (psId: string) => IdHelper.getPrefixFromId(psId))
				.forEach((paIds: string[], psPrefix: string) => laGetEntityLinks$.push(
					this.buildGetEntityLinksObservable(paIds, psPrefix, pbIncludeDeeplinks, paLinkedEntityPrefixes, pbLive, pbIncludeDocs)
				));

			return combineLatest(laGetEntityLinks$);
		})
			.pipe(
				map((paEntityLinks: IEntityLink[][]) => ArrayHelper.flat(paEntityLinks)),
				defaultIfEmpty([])
			);
	}

	private buildGetEntityLinksObservable(paIds: string[], psPrefix: string, pbIncludeDeeplinks: boolean, paLinkedEntityPrefixes: EPrefix[], pbLive: boolean,
		pbIncludeDocs: boolean): Observable<IEntityLink[]> {

		return defer(() => {
			const lsStartKey = `${EPrefix.link}${paIds.length === 1 ? ArrayHelper.getFirstElement(paIds) : psPrefix}`;

			const lfFilter: (poEntityLink: IEntityLink) => boolean = (poEntityLink: IEntityLink) => (pbIncludeDeeplinks || !poEntityLink._id.includes(EntityLinkService.C_DEEPLINK_SUB_PATH_SEPARATOR)) &&
				paIds.some((poItemId: string) => poEntityLink._id.includes(poItemId));

			const loDataSource: IDataSource<IEntityLink> = {
				databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
				viewParams: {
					startkey: lsStartKey,
					endkey: lsStartKey + Store.C_ANYTHING_CODE_ASCII
				},
				live: pbLive,
				filter: ArrayHelper.hasElements(paLinkedEntityPrefixes) ?
					(poEntityLink: IEntityLink) => lfFilter(poEntityLink) && this.filterEntityLinksFunction(paLinkedEntityPrefixes)(poEntityLink) : lfFilter
			};

			return this.isvcStore.get(loDataSource)
				.pipe(
					switchMap((paEntityLinks: IStoreDocument[]) => (!pbIncludeDocs || !ArrayHelper.hasElements(paEntityLinks)) ?
						of(paEntityLinks as IEntityLink[]) : this.getEntityLinksDetails(paEntityLinks)
					),
					tap((paResults: IEntityLink[]) => {
						if (pbIncludeDocs)
							this.replaceRelativeDatabaseSource(paResults);
					})
				);
		});
	}

	private getEntityLinksDetails(paEntityLinks: IStoreDocument[]): Observable<IEntityLink[]> {
		const loDocsDataSource: IDataSource = {
			databasesIds: ArrayHelper.unique(paEntityLinks.map((poEntityLink: IStoreDocument) => StoreHelper.getDatabaseIdFromCacheData(poEntityLink))),
			viewParams: {
				keys: paEntityLinks.map((poEntityLink: IStoreDocument) => poEntityLink._id),
				include_docs: true
			}
		};

		return this.isvcStore.get<IEntityLink>(loDocsDataSource);
	}

	/** Récupère les entités liées à un identifiant ou à un document issu de la base de données.
	 * @param poItem Identifiant ou document issu de la base de données dont il faut récupérer les liens associés.
	 * @param paLinkedEntityPrefixes Préfixe ou tableau de préfixes des données liées.
	 * @param pbLive Indique si la requête doit être live ou non.
	 * @param pbConflicts Indique si l'on doit récupérer les conflits ou non.
	 */
	public getLinkedEntities<T extends IStoreDocument = IStoreDocument>(poItem: string | IStoreDocument,
		paLinkedEntityPrefixes?: EPrefix | EPrefix[], pbLive?: boolean, pbConflicts?: boolean): Observable<T[]>;
	/** Récupère les entités liées à un tableau de documents issus du de bases de données ou d'idnetifiants.
	 * @param paItems Tableau des documents (ou identifiants) dont il faut récupérer les liens.
	 * @param paLinkedEntityPrefixes Préfixe ou tableau des préfixes des données liées à récupérer.
	 * @param pbLive Indique si la requête doit être live ou non.
	 * @param pbConflicts Indique si l'on doit récupérer les conflits ou non.
	 */
	public getLinkedEntities<T extends IStoreDocument = IStoreDocument>(paItems: string[] | IStoreDocument[],
		paLinkedEntityPrefixes?: EPrefix | EPrefix[], pbLive?: boolean, pbConflicts?: boolean): Observable<Map<string, T[]>>;
	public getLinkedEntities<T extends IStoreDocument = IStoreDocument>(poData: string | string[] | IStoreDocument | IStoreDocument[],
		paLinkedEntityPrefixes?: EPrefix | EPrefix[], pbLive?: boolean, pbConflicts?: boolean): Observable<T[] | Map<string, T[]>> {
		if (!poData)
			return of(undefined);

		const laPrefixes: EPrefix[] = paLinkedEntityPrefixes instanceof Array ? paLinkedEntityPrefixes :
			paLinkedEntityPrefixes ? [paLinkedEntityPrefixes] : undefined;

		if (poData instanceof Array) {
			let laDataIds: string[];

			if (typeof ArrayHelper.getFirstElement(poData as string[]) === "string")
				laDataIds = poData as string[];
			else
				laDataIds = (poData as IStoreDocument[]).map((poItem: IStoreDocument) => poItem._id);

			return this.getLinkedEntitiesForMultipleItems<IStoreDocument>(laDataIds, laPrefixes, pbLive, pbConflicts) as any as Observable<Map<string, T[]>>;
		}
		else {
			const lsDataId: string = typeof poData === "string" ? poData : poData._id;

			return this.getEntityLinks(lsDataId, laPrefixes, pbLive)
				.pipe(switchMap((paEntityLinks: IEntityLink[]) => this.innerGetLinkedEntities_getEntities<IStoreDocument>(paEntityLinks, pbLive, pbConflicts))) as any as Observable<T[]>;
		}
	}

	private getLinkedEntitiesForMultipleItems<T extends IStoreDocument = IStoreDocument>(paItemIds: string[], paLinkedEntityPrefixes?: EPrefix[],
		pbLive?: boolean, pbConflicts?: boolean): Observable<Map<string, T[]>> {

		return this.getEntityLinksForMultipleItems(paItemIds, paLinkedEntityPrefixes, pbLive, true)
			.pipe(
				switchMap((paEntityLinks: IEntityLink[]) => {
					return this.innerGetLinkedEntities_getEntities<T>(paEntityLinks, pbLive, pbConflicts)
						.pipe(map((paEntities: T[]) => this.groupEntitiesBySourceId<T>(paEntities, this.groupSourceIdsByTargetId(paEntityLinks))));
				})
			);
	}

	private groupEntitiesBySourceId<T extends IStoreDocument = IStoreDocument>(paEntities: T[], poSourceIdsByTargetIds: Map<string, string[]>): Map<string, T[]> {
		const loEntitiesByItemIdMap = new Map<string, T[]>();

		paEntities.forEach((poEntity: T) => {
			poSourceIdsByTargetIds.get(poEntity._id)
				.forEach((psSourceId: string) => {
					if (loEntitiesByItemIdMap.has(psSourceId))
						loEntitiesByItemIdMap.get(psSourceId).push(poEntity);
					else
						loEntitiesByItemIdMap.set(psSourceId, [poEntity]);
				});
		});

		return loEntitiesByItemIdMap;
	}

	/** Regroupe et retourne la map des identifiants cibles des liens d'entités en fonction de leur identifiant source.
	 * @param paEntityLinks Tableau des entités liées dont il faut regrouper les identifiants cibles par l'identifiant source.
	 */
	private groupTargetIdsBySourceId(paEntityLinks: IEntityLink[]): Map<string, string[]> {
		const loTargetIdsBySourceIds = new Map<string, string[]>();

		paEntityLinks.forEach((poEntityLink: IEntityLink) => {
			const lsSourceId: string = this.getLinkSourceId(poEntityLink);
			const laTargetIds: string[] = loTargetIdsBySourceIds.get(lsSourceId) ?? [];

			laTargetIds.push(this.getLinkTargetId(poEntityLink));

			loTargetIdsBySourceIds.set(lsSourceId, laTargetIds);
		});

		return loTargetIdsBySourceIds;
	}

	/** Regroupe et retourne la map des identifiants sources des liens d'entités en fonction de leur identifiant cible.
	 * @param paEntityLinks Tableau des entités liées dont il faut regrouper les identifiants sources par l'identifiant cible.
	 */
	private groupSourceIdsByTargetId(paEntityLinks: IEntityLink[]): Map<string, string[]> {
		const loSourceIdsByTargetIds = new Map<string, string[]>();

		paEntityLinks.forEach((poEntityLink: IEntityLink) => {
			const lsTargetId: string = this.getLinkTargetId(poEntityLink);
			const laSourceIds: string[] = loSourceIdsByTargetIds.get(lsTargetId) ?? [];

			laSourceIds.push(this.getLinkSourceId(poEntityLink));
			loSourceIdsByTargetIds.set(lsTargetId, laSourceIds);
		});

		return loSourceIdsByTargetIds;
	}

	private innerGetLinkedEntities_getEntities<T extends IStoreDocument = IStoreDocument>(paEntityLinks: IEntityLink[], pbLive: boolean, pbConflicts?: boolean)
		: Observable<T[]> {

		if (!ArrayHelper.hasElements(paEntityLinks))
			return of([]);

		const loEntitiesByDatabaseId = new Map<string, IEntityLink[]>();
		const laEntities$: Observable<T[]>[] = [];

		paEntityLinks.forEach((poEntityLink: IEntityLink) => {
			const lsEntityLinkDatabaseId: string = this.getLinkTargetDatabaseId(poEntityLink);
			const laEntities: IEntityLink[] = loEntitiesByDatabaseId.get(lsEntityLinkDatabaseId);

			laEntities ? laEntities.push(poEntityLink) : loEntitiesByDatabaseId.set(lsEntityLinkDatabaseId, [poEntityLink]);
		});

		loEntitiesByDatabaseId.forEach((paEntityLinks: IEntityLink[], psDatabaseId: string) => {
			const loDataSource: IDataSource = {
				databaseId: psDatabaseId,
				viewParams: {
					include_docs: true,
					keys: paEntityLinks.map((poEntityLink: IEntityLink) => this.getLinkTargetId(poEntityLink)),
					conflicts: pbConflicts
				},
				live: pbLive
			};

			laEntities$.push(this.isvcStore.get<T>(loDataSource));
		});

		return combineLatest(laEntities$)
			.pipe(
				map((paResults: T[][]) => ArrayHelper.flat(paResults)),
				defaultIfEmpty([])
			);
	}

	//#endregion

	//#endregion
}
