import { Component, OnInit } from '@angular/core';
import { Database } from '@osapp/model';
import { IDatabaseGroupingConfiguration } from '@osapp/modules/database-synchro/model/IDatabaseGroupingConfiguration';
import { IDatabaseSyncStatus } from '@osapp/modules/database-synchro/model/IDatabaseSyncStatus';
import { DatabaseSynchroService } from '@osapp/modules/database-synchro/services/database-synchro.service';
import { EDatabaseSyncStatus } from '@osapp/modules/store/model/EDatabaseSyncStatus';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { Observable, TimeInterval, forkJoin, of, timer } from 'rxjs';
import { concatMap, finalize, mapTo, takeUntil, tap, throttleTime, timeInterval } from 'rxjs/operators';
import { DrawerPopoverService } from '../../../shared/services/drawer-popover.service';




@Component({
	selector: "di-menu-synchro",
	templateUrl: './menu-synchro.component.html',
	styleUrls: ['./menu-synchro.component.scss'],
})
export class MenuSynchroComponent extends DestroyableComponentBase implements  OnInit {

	public isBusy: boolean;
	public isDone : boolean;
	private groupingConfigs: IDatabaseGroupingConfiguration[];
	public databasesSyncStatus: IDatabaseSyncStatus[] = [];



	constructor(
		private svcDatabaseSynchro : DatabaseSynchroService,
		private svcDrawerPopover : DrawerPopoverService

		) {
			super()
		 }

	ngOnInit() {

		this.isBusy = false;
		this.isDone = false;

		this.groupingConfigs = this.svcDatabaseSynchro.getDatabasesGroupingConfigs();

		this.groupingConfigs.forEach((poConfig: IDatabaseGroupingConfiguration, pnIndex: number) => {
			this.svcDatabaseSynchro.getSyncStatus(poConfig.roles).pipe(
				tap((peDatabasesSyncStatus: EDatabaseSyncStatus) => {
					this.databasesSyncStatus[pnIndex] = { title: poConfig.title, description: poConfig.description, status: peDatabasesSyncStatus };
					this.databasesSyncStatus = Array.from(this.databasesSyncStatus);
				}),
				takeUntil(this.destroyed$)
			)
				.subscribe();
		});

		this.svcDatabaseSynchro.getDmsSyncStatus()
			.pipe(
				tap((peDatabaseSyncStatus: EDatabaseSyncStatus) => {
					this.databasesSyncStatus[this.groupingConfigs.length] = { ...this.svcDatabaseSynchro.getDmsSyncConfig(), status: peDatabaseSyncStatus };
					this.databasesSyncStatus = Array.from(this.databasesSyncStatus);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public handleClickSynchro(){
		this.isBusy = true;
		this.synchronize().pipe(throttleTime(500)).subscribe();
	}

	public handleClose()
	{
		this.svcDrawerPopover.close();
	}

private synchronize(): Observable<string[]> {
    const laDatabases: Database[] = this.svcDatabaseSynchro.getGroupingConfigsDatabases();
    const lnTotalDb = laDatabases.length;

    const syncObservables = laDatabases.map((poDatabase: Database) => 
        this.svcDatabaseSynchro.syncDatabase(poDatabase).pipe(
            timeInterval(),
            concatMap((poValue: TimeInterval<string>) => {
                const lnDifferedTime: number = 500 - poValue.interval;
                if (lnDifferedTime <= 0) {
                    return of(poValue.value);
                }
                return timer(lnDifferedTime).pipe(mapTo(poValue.value));
            }),
            tap((psText: string) => {
            })
        )
    );

    return forkJoin(syncObservables).pipe(
        finalize(() => {
            this.isBusy = false;
						this.isDone = true
        })
    );
	}


}
