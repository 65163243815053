<osapp-header-modal [hasCloseButton]="true" [title]="'Couverture ' + title"></osapp-header-modal>

<ion-content>
	<idl-couverture-amo-p *ngIf="couvertureType === 'AMOP'" [amoP]="couverture" [etablissements]="etablissements">
	</idl-couverture-amo-p>
	<idl-couverture-amc-p *ngIf="couvertureType === 'AMCP'" [amcP]="couverture" [etablissements]="etablissements">
	</idl-couverture-amc-p>
</ion-content>

<ion-fab vertical="bottom" horizontal="end" slot="fixed">
	<ion-fab-button color="success" (click)="submit()" title="Valider">
		<ion-icon name="checkmark"></ion-icon>
	</ion-fab-button>
</ion-fab>