import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FieldBase } from '@osapp/model/forms/FieldBase';
import { FormsService } from '@osapp/services/forms.service';

@Component({
	selector: 'idl-finess-field',
	templateUrl: './finess-field.component.html',
	styleUrls: ['./finess-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinessFieldComponent extends FieldBase<string> {

	//#region METHODS

	constructor(
		psvcForms: FormsService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetector);
	}

	//#endregion

}
