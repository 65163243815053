import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonItemSliding } from '@ionic/angular';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { ContactHelper } from '../../../helpers/contactHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { StoreHelper } from '../../../helpers/storeHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { UserHelper } from '../../../helpers/user.helper';
import { EPrefix } from '../../../model/EPrefix';
import { UserData } from '../../../model/application/UserData';
import { ETaskPrefix } from '../../../model/backgroundTask/ETaskPrefix';
import { IStoreTaskParams } from '../../../model/backgroundTask/taskParams/IStoreTaskParams';
import { EBarElementDock } from '../../../model/barElement/EBarElementDock';
import { EBarElementPosition } from '../../../model/barElement/EBarElementPosition';
import { IBarElement } from '../../../model/barElement/IBarElement';
import { IContact } from '../../../model/contacts/IContact';
import { IContactsDynHostParams } from '../../../model/contacts/IContactsDynHostParams';
import { IGetGroupMembersOptions } from '../../../model/contacts/IGetGroupMembersOptions';
import { IGroup } from '../../../model/contacts/IGroup';
import { IItemOption } from '../../../model/forms/IItemOption';
import { IListDefinitionsField } from '../../../model/forms/IListDefinitionsField';
import { EAvatarSize } from '../../../model/picture/EAvatarSize';
import { IAvatar } from '../../../model/picture/IAvatar';
import { ERouteUrlPart } from '../../../model/route/ERouteUrlPart';
import { PermissionsService } from '../../../modules/permissions/services/permissions.service';
import { FavoritesService } from '../../../modules/preferences/favorites/services/favorites.service';
import { ISelectOption } from '../../../modules/selector/selector/ISelectOption';
import { PatternPipe } from '../../../pipes/pattern.pipe';
import { BackgroundTaskService } from '../../../services/backgroundTask.service';
import { TaskDescriptor } from '../../../services/backgroundTask/TaskDescriptor';
import { ContactsService } from '../../../services/contacts.service';
import { EntityLinkService } from '../../../services/entityLink.service';
import { FormsService } from '../../../services/forms.service';
import { GroupsService } from '../../../services/groups.service';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { PageManagerService } from '../../../services/pageManager.service';
import { PatternResolverService } from '../../../services/pattern-resolver.service';
import { PlatformService } from '../../../services/platform.service';
import { IApplicationRole } from '../../../services/security/IApplicationRole';
import { Store } from '../../../services/store.service';
import { UiMessageService } from '../../../services/uiMessage.service';
import { DynamicPageComponent } from '../../dynamicPage/dynamicPage.component';
import { FormListComponent } from '../../forms/formList/formList.component';
import { SearchComponent } from '../../search/search.component';

interface IContactItem {
	/** Première ligne du contact, correspond à son `lastName` ou `displayName`. */
	mainContent: string;
	/** Classe css à appliquer pour la première ligne du contact. */
	mainCssClassContent?: string;
	/** Deuxième ligne du contact, correspond à son `firstName` si la première est `lastName`, sinon prend le premier champs dynamique trouvé (hors `lastName` et `firstName`). */
	subContent?: string;
	/** Classe css à appliquer pour la deuxième ligne du contact. */
	subCssClassContent?: string;
	/** Troisième ligne du contact (note), correspond à la concaténation des noms des groupes auxquels appartient le contact, chaîne vide si pas d'appartenance. */
	groupsName: string;
}

@Component({
	selector: "contacts-dyn-host",
	templateUrl: 'contactsDynHost.component.html',
	styleUrls: ['./contactsDynHost.component.scss', '../../forms/formList/formList.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsDynHostComponent<T extends IContact> extends FormListComponent<T> implements OnInit, OnDestroy {

	//#region FIELDS

	private static readonly C_REMOVE_EVENT_ID = "removeContact";

	private readonly moRefreshGroups = new Subject<IContact[]>();

	private maSelectedRoles?: IApplicationRole[];

	//#endregion

	//#region PROPERTIES

	@Input() public params: IContactsDynHostParams;

	private moGroupsByContactId = new Map<string, IGroup[]>();
	public get groupsByContactId(): Map<string, IGroup[]> { return this.moGroupsByContactId; }

	private moUserContact: T;
	public get userContact(): T { return this.moUserContact; }

	private maSelectOptions: ISelectOption[];
	public get selectOptions(): ISelectOption[] { return this.maSelectOptions; }

	/** Indique s'il faut afficher le message de aucun résultat (composant terminé de s'initialiser, barre de recherche sans résultat). */
	public get showNoResultMessage(): boolean { return !this.isLoading && this.hasSearchbox && !this.hasSearchResult; }

	/** Indique s'il faut afficher le message vide (aucun élément, pas de searchbox et message de liste vide défini) ou non. */
	public get showEmptyMessage(): boolean {
		return !this.isLoading && !ArrayHelper.hasElements(this.filteredDocuments) && !this.hasSearchbox && !StringHelper.isBlank(this.emptyMessage);
	}

	/** Concaténation des noms des groupes auxquels appartient le contact utilisateur. */
	public get userGroupsName(): string { return this.getGroupsName(this.groupsByContactId.get(this.userContact?._id ?? "")); }

	//#endregion

	//#region METHODS

	constructor(
		private isvcContacts: ContactsService,
		private isvcGroups: GroupsService,
		private isvcBackgroundTask: BackgroundTaskService,
		private isvcPlatform: PlatformService,
		psvcPermissions: PermissionsService,
		poParentPage: DynamicPageComponent<ComponentBase>,
		psvcPageManager: PageManagerService,
		psvcUiMessage: UiMessageService,
		psvcForms: FormsService,
		psvcEntity: EntityLinkService,
		poPatternPipe: PatternPipe,
		poRenderer: Renderer2,
		psvcPatternResolver: PatternResolverService,
		poChangeDetectorRef: ChangeDetectorRef,
		poRoute: ActivatedRoute,
		poRouter: Router,
		/** Service de gestion des requêtes en base de données. */
		psvcStore: Store,
		psvcFavorites: FavoritesService
	) {
		super(poParentPage, psvcPageManager, psvcUiMessage, psvcForms, psvcEntity, poPatternPipe, poRenderer, psvcPatternResolver,
			poChangeDetectorRef, poRoute, poRouter, psvcStore, psvcFavorites);			
	}

	

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moRefreshGroups.complete();
	}

	public ngOnInit(): void {
		this.fetchAndConvertGroups();

		this.idFormDesc = this.params.idFormDesc;
		this.idFormList = this.params.idFormList;

		const loGetGroupMembersOptions: IGetGroupMembersOptions = {
			prefix: EPrefix.contact,
			live: true,
			conflicts: true
		};

		this.customGetEntries = () => this.isvcContacts.getContactsByPrefix(loGetGroupMembersOptions);

		this.canDisplayOptions = (poItem: T) => !UserHelper.isCurrentUserContact(poItem);

		this.moRefreshGroups.asObservable()
			.pipe(
				switchMap((paContacts: IContact[]) => this.isvcGroups.getContactsGroups(paContacts, true)),
				tap((poResult: Map<string, IGroup[]>) => {
					this.moGroupsByContactId = poResult;
					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		super.ngOnInit();
	}

	private async fetchAndConvertGroups(): Promise<void> {
  try {
    const groupsArray: IGroup[] = await this.isvcGroups.getGroups().toPromise();
    
    // Filtrer les groupes qui n'ont pas le rôle 'sectors'
    const filteredGroups: IGroup[] = groupsArray.filter((groupe: IGroup) => !groupe.roles.includes('sectors'));

    // Mapper les groupes en options de sélection
    this.maSelectOptions = filteredGroups.map((groupe: IGroup) => ({ label: groupe.name, value: groupe }));
  } catch (error) {
    console.error('Une erreur s\'est produite lors de la récupération des groupes :', error);
  }
}

	/** @override */
	public onItemOptionClicked(poModel: T, psAction: string, poItemSliding: IonItemSliding, poOption: IItemOption): void {
		super.onItemOptionClicked(poModel, psAction, poItemSliding, poOption);

		if (psAction === ContactsDynHostComponent.C_REMOVE_EVENT_ID) // On veut supprimer le contact.
			this.removeContactPopup(poModel);

		poItemSliding.close();
	}

	/** Crée une tâche de fond de synchro.
	 * @param psDatabaseId Identifiant de la base données où se trouve le modèle.
	 */
	private addSyncTask(poContact: T): void {
		const lsDatabaseId: string = StoreHelper.getDatabaseIdFromCacheData(poContact);

		if (!StringHelper.isBlank(lsDatabaseId)) {
			this.isvcBackgroundTask.addTask(new TaskDescriptor<IStoreTaskParams>(
				ETaskPrefix.dbSync + ContactsDynHostComponent.C_REMOVE_EVENT_ID,
				`synchro ${ContactsDynHostComponent.C_REMOVE_EVENT_ID}`,
				"DbSyncTask",
				{ dbId: lsDatabaseId },
				true
			));
		}
	}

	/** @override Va sur la page de visualisation du contact cliqué.
	 * @param poContact Contact à récupérer pour aller en mode visu.
	 */
	public onItemClicked(poContact: T): void {
		this.closeKeyboard();
		this.isvcContacts.routeToContact(poContact);
	}

	/** Va sur la page des contactgetDatabasesIdsByRoleContact sélectionné et en affichant le bouton enregistrer.
	 * @param poContact Contact sélectionné.
	 */
	private importContact(poContact: T): void {
		poContact._id = IdHelper.buildId(EPrefix.contact); // Crée un id pour le contact.

		this.ioRouter.navigate(
			["contacts", ERouteUrlPart.new],
			{
				state: { model: poContact }
			}
		);
	}

	/** Importe un contact depuis l'appareil et le transforme en contact d'application. */
	private importDeviceContact(): Observable<T> {
		return this.isvcContacts.getIContactFromDeviceContactSelector()
			.pipe(
				catchError(poError => {
					if (poError === 6) // ContactError.OPERATION_CANCELLED_ERROR (code 6)
						return EMPTY;
					else {
						console.error("CDH.C::", poError);
						return throwError(poError);
					}
				}),
				tap((poContact: T) => this.importContact(poContact)),
				takeUntil(this.destroyed$)
			);
	}

	/** @implements */
	public createBarElements(): IBarElement[] {
		const laBarElements: IBarElement[] = [];
		if (this.hasAddButton && this.isvcPlatform.isAndroid) {
			laBarElements.push({
				id: "circle",
				component: "fabButton",
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.right,
				icon: "person-circle",
				onTap: () => this.importDeviceContact().subscribe(),
				priority: 0,
				name : "Importer"
			});
		}

		return [...laBarElements, ...super.createBarElements()];
	}

	/** Demande la confirmation de la suppression d'un contact. Si l'utilisateur clique sur "Ok", suppression effective.
	 * @param poContact Contact qui doit être supprimé.
	 */
	private removeContactPopup(poContact: T): void {
		const lsContactCompleteName: string = ContactHelper.getCompleteFormattedName(poContact);
		/** Valeur de retour. Initialisé à `false` car aucun callback si l'utilisateur clique en dehors de la pop-up. */
		let lbResult = false;

		this.isvcUiMessage.showAsyncMessage<void>(
			new ShowMessageParamsPopup({
				header: "Suppression",
				message: `Voulez-vous vraiment supprimer le contact "${lsContactCompleteName}" ?`,
				buttons: [
					{ text: "Annuler", handler: () => { } }, // Déjà initialisé à 'false'.
					{ text: "OK", handler: () => { lbResult = true; } }
				]
			})
		)
			.pipe(
				map(() => lbResult),
				takeUntil(this.destroyed$),
				mergeMap((pbConfirmation: boolean) => pbConfirmation ? this.remove(poContact) : EMPTY)
			)
			.subscribe();
	}

	/** Supprime un contact.
	 * @param poContact Contact à supprimer.
	 * @param psCompleteName Nom complet du contact à supprimer.
	 */
	private remove(poContact: T): Observable<boolean> {
		const lsContactCompleteName: string = ContactHelper.getCompleteFormattedName(poContact);

		return this.isvcContacts.deleteContact(poContact)
			.pipe(
				catchError(poError => {
					console.error("CDH.C:: Erreur suppression contact", poError);
					this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: `Erreur lors de la suppresion du contact ${lsContactCompleteName}.` }));
					return throwError(poError);
				}),
				map((pbResult: boolean) => {
					if (pbResult)
						this.addSyncTask(poContact);
					else
						this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Erreur", message: `Suppression du contact "${lsContactCompleteName}" échouée.` }));

					return pbResult;
				}),
				takeUntil(this.destroyed$)
			);
	}

	/** @override */
	public onFilteredDocumentsChanged(paChanges: T[]): void {
		if (!ArrayHelper.areArraysFromDatabaseEqual(paChanges, this.filteredDocuments) && UserData.current) {
			const lsUserContactId: string = ContactsService.getContactIdFromUserId(UserData.current.name);
			// On supprime le contact de l'utilisateur de la liste avant affectation pour éviter des problèmes de rendu.
			this.moUserContact = ArrayHelper.removeElementByFinder(paChanges, (poContact: T) => poContact._id === lsUserContactId);
			super.onFilteredDocumentsChanged(this.filterByGroupe(paChanges, this.maSelectedRoles));
			this.hasSearchResult = ArrayHelper.hasElements(this.filteredDocuments) || !!this.userContact;
			this.moRefreshGroups.next(this.documents);
		}
	}

	public onRoleSelectionChanged(paRoles: IApplicationRole[], poSearchComponent: SearchComponent<T>): void {
		this.maSelectedRoles = paRoles;
		this.onFilteredDocumentsChanged(poSearchComponent.search());
	}

	private filterByRoles(paDocuments: T[], paRoles: IApplicationRole[]): T[] {
		return !ArrayHelper.hasElements(paRoles) ? paDocuments : paDocuments.filter((poDocument: T) => {
			const laGroups: IGroup[] = this.groupsByContactId.get(poDocument._id);
			return ArrayHelper.hasElements(laGroups) && paRoles.every((poRole: IApplicationRole) => laGroups.some((poGroup: IGroup) => poGroup.roles?.includes(poRole.id)));
		});
	}

	private filterByGroupe(paDocuments: T[], paGroup: IApplicationRole[]): T[] {
		return !ArrayHelper.hasElements(paGroup) ? paDocuments : paDocuments.filter((poDocument: T) => {
			const laGroups: IGroup[] = this.groupsByContactId.get(poDocument._id);
			const result = ArrayHelper.hasElements(laGroups) && paGroup.every((groupSelect) => laGroups.some(group => group._id === groupSelect["_id"]));
			return result;
		});
	}

	/** Récupère la concaténation des noms des groupes en paramètre, chaîne vide s'il n'y en a pas.
	 * @param paGroups Tableau des groupes à concaténer.
	 */
	private getGroupsName(paGroups?: IGroup[]): string {
		return paGroups ? paGroups.map((poGroup: IGroup) => poGroup.name).join(", ") : "";
	}

	/** Crée et retourne un avatar depuis les données passées en paramètre.
	 * @param poContact
	 */
	public getContactAvatar(poContact: IContact): IAvatar {
		return ContactsService.createContactAvatar(poContact, EAvatarSize.big, true);
	}

	/** Récupère les éléments à afficher d'un contact.
	 * @param poContact Contact dont il faut récupérer les informations.
	 */
	public getContactItem(poContact: T): IContactItem {
		const lsGroupsName: string = this.getGroupsName(this.groupsByContactId.get(poContact._id));

		if (this.displayFields.find((poField: IListDefinitionsField<T>) => poField.key === "displayName" && !poField.hidden))
			return { groupsName: lsGroupsName, mainContent: ContactHelper.getCompleteFormattedName(poContact) };
		else {
			return {
				groupsName: lsGroupsName,
				mainContent: poContact.lastName,
				mainCssClassContent: "last-name",
				subContent: poContact.firstName,
				subCssClassContent: "first-name"
			};
		}
	}

	/** @override */
	public sortDocuments(paDocuments: T[]): T[] {
		return super.sortDocuments(paDocuments).sort((poContactA: T, poContactB: T) => ArrayHelper.compareByExistingProperty(poContactB, poContactA, "_conflicts"));
	}

	//#endregion

}
