import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'di-confirmation-email',
  templateUrl: './confirmation-email.page.html',
  styleUrls: ['./confirmation-email.page.scss'],
})

export class ConfirmationEmailPage implements OnInit {
	email:string;

	constructor(
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private router: Router
	) { }
		
	ngOnInit(): void {
		this.email = this.activatedRoute.snapshot.queryParamMap.get("email") || "";
	}
	
	public resendEmail() {
    this.location.back();
  }

	public navigateToLogin(){
		this.router.navigate(['/authenticator']);
	}
}
