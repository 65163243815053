import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { IonicModule } from '@ionic/angular';
import { OsappBottomSheetModule } from '../../modules/bottom-sheet/bottom-sheet.module';
import { CustomDateAdapter } from '../../modules/date/model/CustomDateAdapter';
import { ModalModule } from '../../modules/modal/modal.module';
import { PipeModule } from '../../pipes/pipes.module';
import { BrowserTimePickerComponent } from './browser-time-picker/browser-time-picker.component';
import { DateRangePickerModalComponent } from './date-range-picker-modal/date-range-picker-modal.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DateTimePickerComponent } from './dateTimePicker.component';
import { MobileTimePickerComponent } from './mobile-time-picker/mobile-time-picker.component';

const modules: Array<Type<any>> = [
	IonicModule,
	CommonModule,
	PipeModule,
	MatFormFieldModule,
	MatDatepickerModule,
	MatNativeDateModule,
	MatInputModule,
	ModalModule,
	FormsModule,
	OsappBottomSheetModule
];
const components: Array<Type<any>> = [
	DateTimePickerComponent,
	DateRangePickerComponent,
	DateRangePickerModalComponent,
	BrowserTimePickerComponent,
	MobileTimePickerComponent
];

/** Module de gestion des contacts. */
@NgModule({
	imports: modules,
	exports: components,
	declarations: components,
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
		{ provide: DateAdapter, useClass: CustomDateAdapter }
	]
})
export class DateModule { }