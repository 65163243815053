import { ILinkInfo } from '../link/ILinkInfo';
import { LinkInfo } from '../link/LinkInfo';
import { ISectionInfo } from './ISectionInfo';

export class SectionInfo implements ISectionInfo {

	//#region PROPERTIES

	/** Format du json utilisé. */
	public meta: { schemaVersion: string };
	/** Identifiant de la section. */
	public id: string;
	/** Label affiché de la section. */
	public label: string;
	/** Identifiant du template html utilisé. */
	public templateId: "default" | "expandable" | "invisible" | "avatar" = "default";
	/** Nom du template utilisé. */
	public templateName = "default template name";
	/** Booléen indiquant si la section peut être réduite. */
	public isClosable = true;
	/** Dans le cas d'un menu extensible, indique s'il est fermé ou ouvert. */
	public isClosed: boolean;
	/** Listes des liens accessibles depuis la section. */
	public links: Array<LinkInfo> = [];

	//#endregion

	//#region METHODS

	constructor(poSectionInfo: ISectionInfo) {
		for (const lsKey in poSectionInfo) {
			if (lsKey !== "links")
				this[lsKey] = poSectionInfo[lsKey];
		}

		if (poSectionInfo.links)
			poSectionInfo.links.forEach((poLink: ILinkInfo) => this.links.push(new LinkInfo(poLink)));
	}

	//#endregion
}