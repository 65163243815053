import {
	Component,
	Input,
	OnChanges,
	OnInit
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { IUser } from "@osapp/model";
import { ISite } from "@osapp/modules/sites/models/isite";
import { SitesService } from "@osapp/modules/sites/services/sites.service";
import { DestroyableComponentBase } from "@osapp/modules/utils/components/destroyable-component-base";
import { WorkspaceService } from "@osapp/services";
import { throwError } from "rxjs";
import { catchError, takeUntil, tap } from "rxjs/operators";


@Component({
	selector: "di-form-inviter-utilisateur",
	templateUrl: "./form-inviter-utilisateur.component.html",
	styleUrls: ["./form-inviter-utilisateur.component.scss"],
})
export class FormInviterUtilisateurComponent extends DestroyableComponentBase implements OnInit, OnChanges {
	@Input() public userFind?: IUser;
	@Input() onSaveUtilisateur:() => void;
	@Input() onCancelForm:() => void;

	public utilisateurForm: FormGroup;
	public errorMessage : string ="";
	public isError : boolean = false;
	public idRetourPatient: string | null = null;

	constructor(
		private fb: FormBuilder,
		private svcSite : SitesService,
		private svcWorkspace : WorkspaceService,
		private router : Router
		) {
			super();
		}
	infirmierObj: { value: string; label: string; disabled: boolean };
	remplacantObj: { value: string; label: string; disabled: boolean };
	options: any[];
	texteSecondaireGereCabinet = "Permet à l’utilisateur de gérer le cabinet";
	texteSecondaireGereCompte = "Vous pouvez désactiver temporairement un compte";
	public selectedOptions : string;
	public cabinets : ISite[] = [];

	public colors = [
		{
			name: "Indigo",
			value: "Indigo",
		},
		{
			name: "Raspberry",
			value: "Raspberry",
		},
		{
			name: "OrangeClair",
			value: "OrangeClair",
		},
		{
			name: "Warning",
			value: "Warning",
		},
		{ name: "OK", value: "OK" },
		{ name: "BleuVif", value: "BleuVif" },
	];
	public selectedColor : string;
	public selectedCabinet = [];
	public isAdmin: boolean = false;
	public isActif: boolean = false;
	ngOnInit() {
		this.infirmierObj = {
			value: `grp_titulaire`,
			label: `Infirmier (ère)`,
			disabled: false,
		};

		this.remplacantObj = {
			value: `grp_remplacant`,
			label: `Remplacant`,
			disabled: false,
		};

		this.options = [this.infirmierObj, this.remplacantObj];
		this.selectedOptions = this.options[0].value;
		this.initFormContact();

		this.svcSite.getSites().pipe(
			tap( (sites :ISite[]) => {
				this.cabinets = sites;
			}),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	ngOnChanges() {
		this.initFormContact();
	}

	initFormContact() {
		this.utilisateurForm = this.fb.group({
			email: [this.userFind?.email],
		});

		this.controlValidationForm();
	}

	onDataSelect(option: string[]) {
		this.selectedOptions = option[0];
	}

	onCabinetSelect(event: Event, option: ISite) {
		const siteIdSelected = option._id;
		if(this.selectedCabinet.includes(siteIdSelected))
			this.selectedCabinet = this.selectedCabinet.filter(siteId => siteId != siteIdSelected);
		else this.selectedCabinet.push(siteIdSelected);
	}

	onOptionClick = (colColorOptior: any[]) => {
		if(colColorOptior.length == 0) 
			this.selectedColor= "";
		else
			this.selectedColor = colColorOptior[0].value;
	}

	toggleIsAdmin(checked: boolean) {
		this.isAdmin = checked;
	}
	toggleIsActif(checked: boolean) {
		this.isActif = checked;
	}

	handleClickSaveBtn() {
		this.svcWorkspace.inviteUserToWorkspaceANAKIN(this.userFind.email,this.selectedCabinet,this.selectedOptions,this.selectedColor,this.isAdmin).pipe(
			tap( (response: any) => {
				this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
					this.router.navigate(['/parametres']);
				});			
			}),
			catchError(error => {
				this.errorMessage = error.error.message;
				this.isError = true;
				return throwError(error);
			})
		).subscribe()
	}

	handleClickCancelBtn() {
		if(this.onCancelForm)
			this.onCancelForm();
	}

	allValidForm() : boolean {
		return this.selectedCabinet.length == 0 || this.selectedOptions == "" ||this.selectedColor == "";
	}

	private controlValidationForm(): void {
		Object.keys(this.utilisateurForm.controls).forEach((field) => {
			const control = this.utilisateurForm.get(field);
			control?.updateValueAndValidity();
		});
	}
}
