<div class="di-carte-transmission">
	<div *ngIf="!isComment" class="header">
		<div *ngIf="patient" class="patient-info">
			<lua-objet-patient size="ESize.S" [firstName]="patient.firstName | firstUpperCase" [lastName]="patient.lastName"
				[info]="getAdressePatient(patient)"></lua-objet-patient>
		</div>
		<lua-tag *ngIf="isNew" label="Nouveau" color="Info"></lua-tag>
	</div>
	<lua-divider-horizontal *ngIf="!isComment" [size]="dividerSize"></lua-divider-horizontal>
	<div class="body">
		<strong *ngIf="!isComment" class="title">{{ transmission.titre }}</strong>
		<div class="images-container">
			<div class="images">
				<ng-container *ngIf="!isComment">
					<ng-container *ngFor="let image of transmission.files">
						<di-carte-photo [photoFile]="image"></di-carte-photo>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="isComment">
					<ng-container *ngFor="let image of commentaire.files">
						<di-carte-photo [photoFile]="image"></di-carte-photo>
					</ng-container>
				</ng-container>
			</div>
			<lua-action-secondaire *ngIf="!isComment && !isMobileView" [avecIcone]="true" libelle="Commenter" nomIcone="reply"
				[fullWidth]="false" positionIcone="L" (click)="handleCommentClick($event)"></lua-action-secondaire>
		</div>
		<p class="description">
			{{ isComment ? commentaire.description : transmission.description }}
		</p>
		<div *ngIf="!isComment && isMobileView" class="button-container">
			<lua-action-secondaire [avecIcone]="true" libelle="Commenter" nomIcone="reply" [fullWidth]="false"
				positionIcone="L" (click)="handleCommentClick($event)"></lua-action-secondaire>
		</div>
	</div>
	<div class="footer">
		<div *ngIf="contact" class="avatar-container">
			<lua-avatar [firstName]="contact.firstName" [lastName]="contact.lastName" [size]="avatarSize"
				[withTooltip]="false" [color]="getAvatarColor()"></lua-avatar>
			<small>
				{{ contact.firstName }} .
				{{ (isComment ? commentaire.dateLastModification : transmission.dateLastModification) | date: 'dd/MM/yyyy HH:mm'
				}}
			</small>

		</div>
		<div #menuTransmission>
			<lua-bouton-icone (click)="openMenu()" iconName="more_vert" [size]="boutonIconeSize"
				[isDisabled]="!getIsMeMaker()"></lua-bouton-icone>
		</div>

	</div>


</div>