import { Injectable } from '@angular/core';
import { NumberHelper } from '@osapp/helpers/numberHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';

@Injectable()
export abstract class IdlContactsHelper {

	//#region METHODS

	/** Vérifie que la clé présente dans le numéro de sécurité sociale est correcte.
	 * @param psNIR Numéro de sécurité sociale sous forme de chaîne de caractères.
	 */
	public static checkNIR(psNIR: string): boolean {
		// Un numéro valide est composé de 15 chiffres, dont les deux derniers servent de clé.
		let lbCheckResult = false;

		if (!StringHelper.isBlank(psNIR)) { // Chaîne de caractères valide.
			const lsFormattedNIR: string = psNIR.replace(/2[Aa]/, "19").replace(/2[Bb]/, "18"); // Remplacement des départements corses par des nombres.

			if (lsFormattedNIR.length === 15) { // Chaîne de caractères = 15 caractères.
				const lnKey: number = +lsFormattedNIR.substring(13);

				if (NumberHelper.isValid(lnKey)) { // Transformation de la clé en nombre est valide.
					const lnNIR: number = +lsFormattedNIR.substring(0, 13);

					if (NumberHelper.isValid(lnNIR)) // Transformation du NIR en nombre est valide.
						lbCheckResult = 97 - (lnNIR % 97) === lnKey; // Algo calcul clé NIR => 97 - (NIR modulo 97)
				}
			}
		}

		return lbCheckResult;
	}

	//#endregion

}