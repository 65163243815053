import { IConversationFormConfig } from './IConversationFormConfig';

export interface IConversationConfig {

	/** Délai d'expiration de l'activité. */
	conversationActivityExpirationDelaySeconds: number;
	/** Liste des formulaires disponibles pour l'envoi de messages. */
	forms: IConversationFormConfig[];
	/** Désactive la gestion manuelle des entitiés (impossible de lier/supprimer une entité à une conversation manuellement). */
	disableManualsLinks?: boolean;
	/** Masque le bouton de sélection de tous les contacts. */
	hideAllSelectionButton?: boolean;
}