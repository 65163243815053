import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { IFilterbarBaseComponent } from './IFilterbarBaseComponent';

@Component({ template: "" })
export abstract class FilterbarBaseComponent<T = any, U = any> extends ComponentBase implements IFilterbarBaseComponent<T> {

	//#region FIELDS

	@Output("valueChange") private readonly moValueChangeEventEmitter = new EventEmitter<T[]>();

	//#endregion

	//#region PROPERTIES

	/** @implements */
	protected moValue: T;
	public get value(): T { return this.moValue; }
	@Input() public set value(poNewValue: T) {
		if (this.moValue !== poNewValue) {
			this.moValue = poNewValue;
			this.moValueChangeEventEmitter.emit(!!this.moValue ? [this.moValue] : []);
			this.displayValue = this.transform(poNewValue);
			this.detectChanges();
		}
	}

	/** @implements */
	private maValues: T[];
	public get values(): T[] { return this.maValues; }
	@Input() public set values(paNewValues: T[]) {
		if (paNewValues) {
			this.maValues = paNewValues;
			this.moValueChangeEventEmitter.emit(this.maValues);
			this.displayValues = this.transform(paNewValues);
			this.detectChanges();
		}
	}

	public displayValue: U;
	public displayValues: U[];

	//#endregion

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	/** Retourne un objet transformé en type de `U` à partir de la valeur reçue en Input (type de `T`).
	 * @param poValue Valeur à transformer.
	 */
	protected abstract transform(poValue: T): U;
	/** Retourne un tableau d'objets transformés en type de `U` à partir des valeurs reçues en Input (type de `T`).
	 * @param paValues Tableau des valeurs à transformer.
	 */
	protected abstract transform(paValues: T[]): U[];
	protected abstract transform(paValues: T | T[]): U | U[];

	/** On simule un clic sur le composant pour exécuter une action propre au composant de filtrage.
	 * ### Le retour DOIT être un tableau d'éléments sur lequel filtrer.
	 */
	public abstract action(...paArgs: any[]): Observable<T[]>;

	//#endregion

}