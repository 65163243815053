import { IDmsDocument } from './IDmsDocument';

export class DmsDocument implements IDmsDocument {

	//#region PROPERTIES

	/** @implements */
	public _id: string;
	/** @implements */
	public _rev?: string;
	/** @implements */
	public _deleted?: boolean;
	/** @implements */
	public deleted?: boolean;
	/** @implements */
	public _conflicts?: string[];
	/** @implements */
	public description?: string;
	/** @implements */
	public type?: string;
	/** @implements */
	public subType?: string;
	/** @implements */
	public name: string;
	/** @implements */
	public guid?: string;

	//#endregion

	//#region METHODS

	constructor(poData: IDmsDocument) {
		if (poData) {
			this._id = poData._id;
			this._rev = poData._rev;
			this.description = poData.description;
			this.type = poData.type;
			this.subType = poData.subType;
			this.name = poData.name;
			this.guid = poData.guid;
		}
	}

	//#endregion

}