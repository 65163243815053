<super-tabs #tabs [activeTabIndex]="defaultTabIndex">
	<super-tabs-toolbar slot="top">
		<super-tab-button>
			<ion-label>Actives</ion-label>
		</super-tab-button>
		<super-tab-button>
			<ion-label>Archivées</ion-label>
		</super-tab-button>
	</super-tabs-toolbar>

	<super-tabs-container>
		<!-- Première slide (actives). -->
		<super-tab>
			<ion-content>
				<idl-conversations-list *ngIf="tabs.activeTabIndex === 0" [params]="params"
					[conversationsStatusFilter]="getStatusFilter(tabs)">
				</idl-conversations-list>
			</ion-content>
		</super-tab>
		<!-- Seconde slide (archivées). -->
		<super-tab>
			<ion-content>
				<idl-conversations-list *ngIf="tabs.activeTabIndex === 1" [params]="params"
					[conversationsStatusFilter]="getStatusFilter(tabs)">
				</idl-conversations-list>
			</ion-content>
		</super-tab>
	</super-tabs-container>
</super-tabs>