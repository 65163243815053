<osapp-header-modal [title]="title"></osapp-header-modal>

<ion-content>
	<osapp-selector #selector [preselectedValues]="preselectedValues" [color]="color" [options]="options"
		[searchOptions]="searchOptions" [selectCss]="selectCss" [label]="label" [limit]="limit" [displayMode]="displayMode"
		[multiple]="multiple" (selectionChanged)="onSelectionChanged($event)"></osapp-selector>
</ion-content>

<ion-fab vertical="bottom" horizontal="end" slot="fixed">
	<ng-container *ngIf="customButtons">
		<ion-fab-button *ngFor="let button of customButtons" [color]="button.color" (click)="onCustomButtonClicked(button)">
			<ion-icon [name]="button.icon"></ion-icon>
		</ion-fab-button>
	</ng-container>
	<ion-fab-button color="success" (click)="close(selectedValues)" title="Fermer">
		<ion-icon name="checkmark"></ion-icon>
	</ion-fab-button>
</ion-fab>