import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IonItemSliding } from '@ionic/angular';
import { SearchComponent } from '@osapp/components/search';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { EnumHelper } from '@osapp/helpers/enumHelper';
import { StoreHelper } from '@osapp/helpers/storeHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { ISearchOptions } from '@osapp/model/search/ISearchOptions';
import { ModalService } from '@osapp/modules/modal/services/modal.service';
import { FavoritesService } from '@osapp/modules/preferences/favorites/services/favorites.service';
import { IPreferences } from '@osapp/modules/preferences/model/IPreferences';
import { RecentsService } from '@osapp/modules/preferences/recents/services/recents.service';
import { IAutocompleteOptionClickedEvent } from '@osapp/modules/search/models/iautocomplete-option-clicked-event';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { defer, of, Subject } from 'rxjs';
import { mergeMap, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { C_PREFIX_ACT } from '../../../app/app.constants';
import { Acte } from '../../../model/Acte';
import { EPathologie } from '../../../model/EPathologies';
import { IActe } from '../../../model/IActe';
import { IChapter } from '../../../model/IChapter';
import { IChapterContent } from '../../../model/IChapterContent';
import { Seance } from '../../../model/Seance';
import { Traitement } from '../../../model/Traitement';
import { SeanceService } from '../../../services/seance.service';
import { TraitementService } from '../../../services/traitement.service';
import { ActePersonalizationModalComponent } from '../acte-personalization-modal/acte-personalization-modal.component';
import { ActesService } from '../actes.service';
import { ENgapFilters } from '../model/ENgapFilters';

enum ESearchableActeKey {
	keyLetters = "keyLetters",
	priceCoefficient = "priceCoefficient",
	label = "label"
}

@Component({
	selector: "idl-actes-list",
	templateUrl: './actes-list.component.html',
	styleUrls: ['./actes-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActesListComponent extends ComponentBase implements OnInit, OnDestroy {

	//#region FIELDS

	private moFavoritesClickSubject = new Subject<Acte>();

	private maAllActes: IActe[];
	private maAllChapters: IChapter[];

	private maFilters: string[];

	//#endregion

	//#region PROPERTIES

	public readonly C_UNDEFINED_ACT_ID = ActesService.C_UNDEFINED_ACT_ID;
	public actesByChapter = new Map<IChapter, IChapterContent>();
	public opennedChaptersIds: string[] = [];
	public hasSearchValue = false;
	public filteredActs: Acte[] = [];
	public searchOptions: ISearchOptions<Acte> = {
		searchboxPlaceholder: "Rechercher un acte",
		searchableFields: [
			{ key: "ngapLabel", searchable: true },
			{ key: "personalizedLabel", searchable: true },
			{ key: ESearchableActeKey.priceCoefficient, searchable: true },
			{ key: ESearchableActeKey.keyLetters, searchable: true },
			{ key: "tags", searchable: true }
		]
	};
	/** Clé du tri des actes. */
	public sortKey: ESearchableActeKey = ESearchableActeKey.label;
	/** Indique si le tri est inversé `true` (alphabétique inversé) ou non `false` (alphabétique). */
	public isReverseSort = false;
	/** Énumération pour les différentes clés recherchable d'un acte. */
	public searchableActeKeyEnum = ESearchableActeKey;
	/** Paramètres du composant osapp-selector pour la selection des filtres. */
	public filtersOptions: ISelectOption[] = [
		{ label: "Diabète insulino-traité", value: "diabetic" },
		{ label: "Cancer / Immunodépression", value: "cancer" },
		{ label: "Mucoviscidose", value: "mucoviscidose" },
		{ label: "Patient dépendant", value: "dependant" },
		{ label: "HAD / SSIAD", value: "hadssiad" },
		{ label: "HN", value: "hn" },
	];
	@Input() public acts: Acte[] = [];
	@Input() public traitement?: Traitement;
	@Output("onActeSelected") public readonly onActeSelectedEventEmitter: EventEmitter<Acte> = new EventEmitter();

	public favorites: IPreferences;

	//#endregion

	//#region METHODS

	constructor(
		private isvcActes: ActesService,
		private isvcFavorites: FavoritesService,
		private isvcRecents: RecentsService,
		private isvcModal: ModalService,
		private isvcTraitement: TraitementService,
		private isvcSeance: SeanceService,
		poChangeDetector: ChangeDetectorRef) {
		super(poChangeDetector);
	}

	public ngOnInit(): void {
		this.maFilters = this.traitement?.pathologies ?? [];

		defer(() => {
			if (ArrayHelper.hasElements(this.acts))
				return of(this.acts);
			return this.isvcActes.getActes(undefined, true);
		})
			.pipe(
				tap((paActes: Acte[]) => this.onFilteredEntriesChanged(this.acts = ArrayHelper.dynamicSort(paActes, this.sortKey))),
				mergeMap(() => this.isvcActes.getChapters()),
				tap((paChapters: IChapter[]) => {
					this.maAllActes = this.acts;
					this.maAllChapters = paChapters;
					this.actesByChapter = this.isvcActes.groupActsByChapter(this.maAllChapters, this.filteredActs);
					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.isvcFavorites.get(C_PREFIX_ACT, true)
			.pipe(
				tap((poFavorites: IPreferences) => {
					this.favorites = poFavorites;
					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.moFavoritesClickSubject
			.pipe(
				throttleTime(500),
				mergeMap((poAct: Acte) => this.isvcFavorites.toggle(poAct._id))
			)
			.subscribe();
	}

	public ngOnDestroy(): void {
		this.moFavoritesClickSubject.complete();
		super.ngOnDestroy();
	}

	public onFilteredEntriesChanged(paActes: Acte[], poSearchComponent?: SearchComponent<IActe>): void {
		this.filteredActs = this.isvcActes.removeActsBySelectedPathologies(paActes, this.maFilters as any as ENgapFilters[]);
		this.undefinedActFirstRow(this.filteredActs);
		if (ArrayHelper.hasElements(this.maAllChapters))
			this.actesByChapter = this.isvcActes.groupActsByChapter(this.maAllChapters, this.filteredActs);
		this.hasSearchValue = !StringHelper.isBlank(poSearchComponent?.searchValue);
		this.opennedChaptersIds = this.hasSearchValue ? this.maAllChapters.map((poChapter: IChapter) => poChapter._id) : [];
		this.detectChanges();
	}

	public onActSelected(poActe: Acte): void {
		this.isvcRecents.add(poActe._id)
			.pipe(
				mergeMap(() => this.isvcActes.applyPriceToActe(poActe)),
				tap((poActeWithPrice: Acte) => this.onActeSelectedEventEmitter.emit(poActeWithPrice)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Ordonne les actes avec la clé en paramètre. Si la clé est déjà celle utilisée, inverse la liste.
	 * @param poCol Clé de tri des données.
	 */
	public orderOrReverse(peKey: ESearchableActeKey): void {
		if (this.sortKey === peKey) { // Même clé, on inverse le tri.
			this.isReverseSort = !this.isReverseSort;
			this.filteredActs.reverse();
		}
		else { // Clé différente, on trie avec la nouvelle clé.
			this.sortKey = peKey;
			this.filteredActs = ArrayHelper.dynamicSort(this.filteredActs, peKey);
			this.isReverseSort = false; // On réinitialise le reverse.
		}

		this.undefinedActFirstRow(this.filteredActs);
		this.detectChanges();
	}

	/** Permet de traquer l'identifiant du document afin de rafraîchir l'affichage si celui-ci change.
	 * @param pnIndex Index du traitement.
	 * @param poDocument Données du document.
	 */
	public trackById(pnIndex: number, poDocument: Acte): string {
		return poDocument._id;
	}

	/** Ouvre ou ferme un itemSliding en fonction de l'état avant slide.\
 * On peut également stopper la propagation de l'événement de clic.
 * @param poItemSliding Objet d'options qu'on veut ouvrir ou fermer (animation de swipe).
 * @param poEvent Événement de clic à stopper si renseigné.
 */
	public async openOrCloseItemSliding(poItemSliding: IonItemSliding, poEvent?: MouseEvent): Promise<void> {
		if (poEvent)
			poEvent.stopPropagation();	// Empêche la possible navigation vers l'item cliqué.

		// Si l'item est ouvert, la valeur est strictement supérieure à 0, sinon c'est que l'item est fermé.
		const lnAmountOpenPixels: number = await poItemSliding.getOpenAmount();

		if (lnAmountOpenPixels > 0) // Item ouvert, on veut le fermer
			poItemSliding.close();
		else // Item fermé, on veut l'ouvrir.
			poItemSliding.open("end");
	}

	public onFavoritesClicked(poAct: Acte): void {
		this.moFavoritesClickSubject.next(poAct);
	}

	/** Place en première position l'acte non défini.
	 * @param paActs La liste des actes.
	 */
	private undefinedActFirstRow(paActs: Acte[]): void {
		let loUndefinedAct: Acte;

		paActs.forEach((poActe: Acte) => {
			if (poActe._id === this.C_UNDEFINED_ACT_ID) {
				loUndefinedAct = poActe;
				paActs.splice(paActs.indexOf(poActe), 1);
			}
		});

		if (loUndefinedAct)
			paActs.unshift(loUndefinedAct);
	}

	/** Ouvre une modale de personnalisation d'un acte.
	 * @param poActe l'acte à personnaliser.
	 */
	public openPersonalizeActeModal(poActe: Acte, psMode: string): void {
		this.isvcActes.applyPriceToActe(poActe)
			.pipe(
				mergeMap((poActeWithPrice: Acte) => this.isvcModal.open({
					component: ActePersonalizationModalComponent,
					componentProps: {
						acte: poActeWithPrice,
						mode: psMode,
						actes: this.maAllActes,
						chapters: this.maAllChapters
					}
				}))
			)
			.subscribe();
	}

	public onChapterClicked(poChapter: IChapter, pnChapterLvl: number): void {
		if (this.hasSearchValue) {
			if (this.opennedChaptersIds.includes(poChapter._id))
				ArrayHelper.removeElement(this.opennedChaptersIds, poChapter._id);
			else
				this.opennedChaptersIds.push(poChapter._id);
		}
		else {
			if (this.opennedChaptersIds.includes(poChapter._id))
				ArrayHelper.removeSection(this.opennedChaptersIds, pnChapterLvl);
			else {
				ArrayHelper.removeSection(this.opennedChaptersIds, pnChapterLvl);
				this.opennedChaptersIds[pnChapterLvl] = poChapter._id;
			};
		}
	}

	public onPathologieFilterChanged(paFilters: ENgapFilters[]): void {
		this.maFilters = paFilters;
		this.filteredActs = this.isvcActes.removeActsBySelectedPathologies(this.acts, this.maFilters as any as ENgapFilters[]);
		if (ArrayHelper.hasElements(this.maAllChapters))
			this.actesByChapter = this.isvcActes.groupActsByChapter(this.maAllChapters, this.filteredActs);
	}

	public onPathologieChanged(paFilters: ENgapFilters[]): void {
		this.onPathologieFilterChanged(paFilters);

		const laPathologies: EPathologie[] = paFilters.filter((peFilter: ENgapFilters) => EnumHelper.getValues(EPathologie).includes(peFilter)) as any as EPathologie[];
		if (!ArrayHelper.areArraysEqual(this.traitement.pathologies, laPathologies)) {
			this.isvcSeance.getSeances(this.traitement)
				.pipe(
					mergeMap((paSeances: Seance[]) => {
						return this.isvcTraitement.onTraitementPathologiesChanged(this.traitement, paSeances, laPathologies);
					}),
					tap(_ => StoreHelper.makeDocumentDirty(this.traitement)),
					mergeMap(() => this.isvcTraitement.saveTraitement(this.traitement)),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		};
	}

	public onAutocompleteOptionClicked(poEvent: IAutocompleteOptionClickedEvent<Acte>): void {
		this.onActSelected(poEvent.data);
	}

	//#endregion

}