import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IonRadioGroup, ModalController } from '@ionic/angular';
import { EGalleryDisplayMode } from '@osapp/components/gallery/models/EGalleryDisplayMode';
import { ArrayHelper, ObjectHelper } from '@osapp/helpers';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { EPrefix } from '@osapp/model/EPrefix';
import { IContactsListParams } from '@osapp/model/contacts/IContactsListParams';
import { EDateTimePickerMode } from '@osapp/model/date/EDateTimePickerMode';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { IDateTimePickerParams } from '@osapp/model/date/IDateTimePickerParams';
import { IGalleryFile } from '@osapp/model/gallery/IGalleryFile';
import { IUiResponse } from '@osapp/model/uiMessage/IUiResponse';
import { ModalComponentBase } from '@osapp/modules/modal/model/ModalComponentBase';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { ContactsService } from '@osapp/services/contacts.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { PlatformService } from '@osapp/services/platform.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { EContactType } from '../../../../model/EContactType';
import { IIdelizyContact } from '../../../../model/IIdelizyContact';
import { Traitement } from '../../../../model/Traitement';
import { EOriginePrescription } from '../../models/eorigine-prescription.enum';
import { Ordonnance } from '../../models/ordonnance';

@Component({
	selector: 'idl-ordonnance-modal',
	templateUrl: './ordonnance-modal.component.html',
	styleUrls: ['./ordonnance-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdonnanceModalComponent extends ModalComponentBase<Ordonnance> implements OnInit, OnDestroy {

	//#region PROPERTIES

	@Input() public traitement: Traitement;
	@Input() public ordonnance: Ordonnance;

	/** Paramètres du composant contactsList pour le prescripteur. */
	public contactsListParamsForPrescriptor: IContactsListParams;
	/** Paramètres du composant osapp-selector pour la selection de l'origine. */
	public originesOptions: ISelectOption[] = [
		{ label: "T (Médecin traitant)", value: EOriginePrescription.medTraitant },
		{ label: "O (Médecin correspondant)", value: EOriginePrescription.medCorrespondant },
		{ label: "P (Autre situation parcours de soin)", value: EOriginePrescription.autreSituationParcours },
		{ label: "S (Hors parcours de soin)", value: EOriginePrescription.horsParcoursDeSoin },
		{ label: "A (Renouvellement adapté)", value: EOriginePrescription.renouvellementAdapte },
		{ label: "I (Renouvellement identique)", value: EOriginePrescription.renouvellementIdentique }
	];
	public documents: IGalleryFile[];
	/** Paramètres du composant dateTimeSpinner pour la date de prescription. */
	public dateParamsForPrescriptionDate: IDateTimePickerParams;
	/** Paramètres du composant dateTimeSpinner. */
	public dateParams: IDateTimePickerParams;
	/** Mode d'affichage de la galerie */
	public docDisplayMode: EGalleryDisplayMode = EGalleryDisplayMode.list;

	public hasADC: boolean;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcContacts: ContactsService,
		private readonly isvcUiMessage: UiMessageService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetectorRef);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		this.documents = this.ordonnance.documents ? [...this.ordonnance.documents] : [];
		this.hasADC = DateHelper.isDate(this.ordonnance.adcDate);

		this.prepareDateTimeSpinnerParams();

		this.contactsListParamsForPrescriptor = this.createContactsListParams(EPrefix.contact, EContactType.contact, "Sélectionner le prescripteur");
		this.contactsListParamsForPrescriptor.contactsSelectorParams.roles = [];
		this.contactsListParamsForPrescriptor.contactsSelectorParams.hasGroupSelector = true;

		this.detectChanges();
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	/** Crée et retourne les paramètres nécessaires pour le composant contactsList.
	 * @param peContactPrefix Préfixe à utiliser pour récupérer des contacts spécifiques.
	 * @param peContactType Indique pour quel type de contact on crée les paramètres.
	 * @param psSearchPlaceholder Placeholder de la barre de recherche du sélecteur de contacts.
	 * @param pbReadOnly Indique si le contact list doit être en lecture seule.
	 */
	private createContactsListParams(peContactPrefix: EPrefix, peContactType: EContactType, psSearchPlaceholder: string,
		pbReadOnly: boolean = false): IContactsListParams {

		return {
			contactsAsLinks: false,
			displayProperties: [],
			displayIcons: [],
			pageTitle: "Prescripteur",
			readOnly: pbReadOnly,
			contactsById: false,
			contactsSelectorParams: this.isvcContacts.createContactSelectorParams(peContactPrefix, psSearchPlaceholder),
			contactsContainer: this.traitement,
			addButtonIcon: "person",
			addButtonText: "Sélectionner",
			disbaleRouteToContact: true
		};
	}

	/** Initialise les paramètres pour les différents dateTimeSpinner du composant. */
	public prepareDateTimeSpinnerParams(): void {
		const leDisplayFormat = ETimetablePattern.dd_MMM_yyyy;

		this.dateParamsForPrescriptionDate = {
			displayFormat: leDisplayFormat,
			dateTimeCss: "edit-color",
			iconCss: "edit-color",
			iconSlot: "end",
			pickerMode: EDateTimePickerMode.date
		};

		this.dateParams = {
			displayFormat: leDisplayFormat,
			dateTimeCss: "edit-color",
			iconCss: "edit-color",
			iconSlot: "end",
			pickerMode: EDateTimePickerMode.date
		};
	}

	public toggleRenouvellement(): void {
		if (ObjectHelper.isNullOrEmpty(this.ordonnance.renouvellement))
			this.ordonnance.renouvellement = {
				date: new Date(),
				index: 1
			};
		else {
			const loPopupParams = new ShowMessageParamsPopup({
				header: "Attention !",
				message: "Cette action va enlever les informations de renouvellement enregistrées. Voulez vous continuer ?",
				buttons: [
					{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() },
					{ text: "Oui", handler: () => UiMessageService.getTruthyResponse() }
				]
			});

			this.isvcUiMessage.showAsyncMessage<boolean, any>(loPopupParams)
				.pipe(
					filter((poResponse: IUiResponse<boolean, any>) => poResponse.response),
					tap(() => {
						this.ordonnance.renouvellement = undefined;
						this.detectChanges();
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
	}

	/** Appelée lors d'un changement du modèle prescripteur.
	 * @param paSelectedContacts Tableau des prescripteurs nouvellement sélectionnés.
	 */
	public onPrescriptorModelChanged(paSelectedContacts: Array<IIdelizyContact>): void {
		const loFirstContact: IIdelizyContact = ArrayHelper.getFirstElement(paSelectedContacts);
		const lsFirstContactId: string = loFirstContact?._id;

		if (lsFirstContactId !== this.ordonnance.prescripteurContactId) {
			this.ordonnance.prescripteurContactId = lsFirstContactId;
			// this.ordonnance.facturationNumber = ArrayHelper.getFirstElement(loFirstContact?.facturationNumbers);
			this.detectChanges();
		}
	}

	/** Gestion des changements au niveaux de la galerie d'ordonnances.
	 * @param paOrdonnances
	 */
	public onDocumentsModelChanged(paOrdonnances: IGalleryFile[]): void {
		this.ordonnance.documents = [...(paOrdonnances ?? [])];
		this.detectChanges();
	}

	/** Appelée lors de la modification de la date de prescription.
	* @param pdDate Nouvelle date de prescription.
	*/
	public onPrescriptionDateChanged(pdDate: Date): void {
		this.ordonnance.prescriptionDate = pdDate;
		this.detectChanges();
	}

	/** Appelée lors de la modification de la date de renouvellement.
	* @param pdDate Nouvelle date de renouvellement.
	*/
	public onRenouvellementDateChanged(pdDate: Date): void {
		this.ordonnance.renouvellement.date = pdDate;
		this.detectChanges();
	}

	/** Appelée lors de la modification de la date d'accident de droit commun.
	* @param pdDate Nouvelle date.
	*/
	public onADCDateChanged(pdDate: Date): void {
		this.ordonnance.adcDate = pdDate;
		this.detectChanges();
	}

	public onOriginSelectionChanged(paSelectedOrgin: EOriginePrescription[]): void {
		const lsSelectedOrigin: EOriginePrescription = ArrayHelper.getFirstElement(paSelectedOrgin);

		if (this.ordonnance.originePrescription !== lsSelectedOrigin) {
			this.ordonnance.originePrescription = lsSelectedOrigin;
		}

		this.detectChanges();
	}

	public toggleALD(): void {
		this.ordonnance.isAld = !this.ordonnance.isAld;
		this.detectChanges();
	}

	public toggleADC(poRadioGroup: IonRadioGroup): void {
		if (poRadioGroup.value === false)
			delete this.ordonnance.adcDate;
		else
			this.ordonnance.adcDate = this.ordonnance.adcDate ?? new Date();

		this.detectChanges();
	}

	public toggleDocDisplayMode(): void {
		if (this.docDisplayMode === EGalleryDisplayMode.list)
			this.docDisplayMode = EGalleryDisplayMode.thumbnails;
		else
			this.docDisplayMode = EGalleryDisplayMode.list;

		this.detectChanges();
	}

	/** @override */
	public close(): Promise<boolean> {
		return super.close(this.ordonnance);
	}

	//#endregion

}
