import { coerceArray } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { from, Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { GuidHelper } from '../../../../helpers/guidHelper';
import { MapHelper } from '../../../../helpers/mapHelper';
import { InitComponentError } from '../../../../model/errors/InitComponentError';
import { EFilterActionReturnType } from '../../model/EFilterActionReturnType';
import { EFilterType } from '../../model/EFilterType';
import { FilterbarBaseComponent } from '../../model/FilterbarBaseComponent';
import { IFilterbarOptions } from '../../model/IFilterbarOptions';
import { IFilterbarParams } from '../../model/IFilterbarParams';
import { IFilterValuesChangedEvent } from '../../model/IFilterValuesChangedEvent';
import { FilterbarTagsComponent } from '../filterbar-tags/filterbar-tags/filterbar-tags.component';

@Component({
	selector: "osapp-filterbar",
	templateUrl: './filterbar.component.html',
	styleUrls: ['../filterbar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterbarComponent<T = any> extends ComponentBase implements IFilterbarParams, OnInit {

	//#region FIELDS

	/** Composant sur lequel on vient de cliquer afin de pouvoir exécuter une action depuis le composant. */
	private moClickedComponent: FilterbarBaseComponent;

	/** Composant de filtre par tags. */
	@ViewChild(FilterbarTagsComponent) private moFilterbarTagsComponent: FilterbarTagsComponent;

	//#endregion

	//#region PROPERTIES

	public instanceId: string = GuidHelper.newGuid();

	/** Énumération des différents type de filtrages possibles pour instancier le bon composant. */
	public readonly filterTypeEnum = EFilterType;

	/** @implements */
	private maFilters: IFilterbarOptions[];
	public get filters(): IFilterbarOptions[] { return this.maFilters; }
	@Input() public set filters(paNewValues: IFilterbarOptions[]) {
		if (!this.maFilters) {
			if (!paNewValues)
				this.throwInitComponentError();
			else // Initialisation de la map (pour éviter des erreurs dans le template html).
				paNewValues.forEach((poOptions: IFilterbarOptions) => this.filterValuesByFilterIdMap.set(poOptions.id, []));
		}

		if (this.maFilters !== paNewValues) {
			this.maFilters = paNewValues;
			this.resetFilters(false);
			this.detectChanges();
		}
	}
	/** @implements */
	private mbHasResetButton?: boolean;
	public get hasResetButton(): boolean { return this.mbHasResetButton; }
	@Input() public set hasResetButton(pbNewValue: boolean) {
		this.mbHasResetButton = pbNewValue;
		this.addResetButtonIfNeeded();
		this.detectChanges();
	}
	/** @implements */
	private mbHidden?: boolean;
	public get hidden(): boolean { return this.mbHidden; }
	@Input() public set hidden(pbNewValue: boolean) {
		this.mbHidden = pbNewValue;
	}

	/** Objet de levé d'événement lors d'une réinitialisation des filtres. */
	@Output("onReset") private readonly moOnResetEvent = new EventEmitter<boolean>();
	/** Objet de levé d'événement lors de la mise à jour du nombre de filtres actifs. */
	@Output("onFilterCountChanged") private readonly moOnFilterCountChangedEvent = new EventEmitter<number>();
	/** Objet de levé d'événement lors de la mise à jour des valeurs d'un filtre. */
	@Output("onFilterValuesChanged") private readonly moOnValuesChangedEvent = new EventEmitter<IFilterValuesChangedEvent>();

	/** Map associant un identifiant de filtre aux valeurs pour ce filtre. */
	public filterValuesByFilterIdMap: Map<string, T[]> = new Map();

	//#endregion

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	public ngOnInit(): void {
		if (!this.filters)
			this.throwInitComponentError();
	}

	/** Lève une erreur de type `InitComponentError`. */
	private throwInitComponentError(): void {
		throw new InitComponentError("Le composant 'filterbar' nécessite un tableau de filtres !");
	}

	/** Ajoute un bouton de réinitialisation de filtre s'il est requis et qu'il n'y en a pas déjà dans le tableau des boutons. */
	private addResetButtonIfNeeded(): void {
		if (this.mbHasResetButton && !this.filters.some((poOptions: IFilterbarOptions) => poOptions.isResetButton)) {
			this.filters.push({
				id: "reset",
				icon: "",
				action: () => { },
				returnType: EFilterActionReturnType.void,
				isResetButton: true
			} as IFilterbarOptions);
		}
	}

	/** Exécute l'action d'un élément de filtrage.
	 * @param poFilterOptions Options de filtre dont il faut exécuter l'action associée.
	 */
	public execute(poFilterOptions: IFilterbarOptions, poEvent: MouseEvent): void {
		const loGetActionResult: T[] | Observable<T[]> = this.getActionResult(poFilterOptions);

		if ((!loGetActionResult && poFilterOptions.action) || loGetActionResult) // Si une action a été définie, il faut traiter son résultat.
			this.executeFromAction(poFilterOptions, loGetActionResult);
		else // Pas d'action associée à cet élément de filtrage, on appelle directement le composant.
			this.executeFromComponent(poFilterOptions, poEvent);
	}

	/** Termine d'exécuter l'action associée à l'option de filtrage et notifie des changements survenus.
	 * @param poFilterOptions Options de filtre dont on doit terminer d'exécuter l'action associée.
	 * @param loGetActionResult Résultat de l'action.
	 */
	private executeFromAction(poFilterOptions: IFilterbarOptions, loGetActionResult: T[] | Observable<T[]>): void {
		//! Problème du champ `hidden` qui est modifié en exécutant une action, on garde donc une trace pour le restaurer comme avant après exécution de l'action.
		const lbTmpHidden: boolean = this.hidden;

		if (loGetActionResult instanceof Array) { // Méthode synchrone exécutée, on a un tableau de résultats.
			this.raiseChanges(loGetActionResult, poFilterOptions);
			this.hidden = lbTmpHidden;
		}
		else { // Sinon, méthode asynchrone, un observable a été retourné.
			loGetActionResult
				.pipe(
					tap((paResults: T[]) => {
						this.raiseChanges(paResults, poFilterOptions);
						this.hidden = lbTmpHidden;
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
	}

	/** Exécute l'action du filtrage depuis le composant associé lui-même.
	 * @param poFilterOptions Options de filtre dont on doit terminer d'exécuter l'action associée.
	 */
	private executeFromComponent(poFilterOptions: IFilterbarOptions, poEvent: MouseEvent): void {
		//! Problème du champ `hidden` qui est modifié en exécutant une action, si on a cliqué sur un filtre, on ne veut pas cacher la barre de filtrage.
		this.hidden = false;

		if (this.moClickedComponent) {
			this.moClickedComponent.action(poEvent)
				.pipe(
					tap((paResults: T[]) => {
						this.raiseChanges(paResults, poFilterOptions);
						// Voir doc `onFilterbarBaseComponentClicked`.
						this.moClickedComponent = undefined;
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
		else {
			// Si on trouve le composant dans le template html, on simul un clic dessus pour récupérer le composant associé ()`onFilterbarBaseComponentClicked`).
			const loHtmlElement: HTMLElement = document.getElementById(poFilterOptions.id + this.instanceId);

			if (loHtmlElement)
				loHtmlElement.click();
			else
				throw new Error("FB.C:: Exécution action depuis composant impossible.");
		}
	}

	public onValueChange(paValues: T[], poFilterOptions: IFilterbarOptions): void {
		this.raiseChanges(paValues, poFilterOptions);
	}

	/** Exécute l'action d'un élément de filtrage et retourne son résultat.
	 * @param poFilterOptions Élément de filtrage dont il faut exécuter l'action.
	 */
	private getActionResult(poFilterOptions: IFilterbarOptions): Observable<T[]> | T[] {
		switch (poFilterOptions.returnType) {

			case EFilterActionReturnType.object:
				return (poFilterOptions.action() as T[]);

			case EFilterActionReturnType.observable:
				return (poFilterOptions.action() as Observable<T[]>);

			case EFilterActionReturnType.promise:
				return from((poFilterOptions.action() as Promise<T[]>));

			case EFilterActionReturnType.void:
				return (poFilterOptions.action() as never);

			case EFilterActionReturnType.undefined:
				return undefined;

			default:
				throw new Error("FB.C:: Exécution action, type de retour de la méthode inconnu.");
		}
	}

	/** Réinitialise les filtres et envoie un événement.
	 * @param pbHasToRaiseEvent Indique si on doit lever l'événement de réinitialisation des filtres ou non, `true` par défaut.
	*/
	public resetFilters(pbHasToRaiseEvent: boolean = true): void {
		MapHelper.clearValues(this.filterValuesByFilterIdMap, []);

		if (this.moFilterbarTagsComponent)
			this.moFilterbarTagsComponent.resetSelection();

		if (pbHasToRaiseEvent)
			this.moOnResetEvent.emit(true); // On envoie l'événement indiquant qu'on a réinitialisé le filtrage.

		this.raiseFilterCountChanged(); // On envoie l'événement indiquant qu'il n'y a plus de filtre en cours.
		this.addResetButtonIfNeeded();
	}

	/** Met à jour le nombre de filtres actifs et envoie un événement. */
	private raiseFilterCountChanged(): void {
		let lnCount = 0;

		this.filterValuesByFilterIdMap.forEach((poValue: T[]) => lnCount += poValue.length);

		this.moOnFilterCountChangedEvent.emit(lnCount);
	}

	/** Met à jour le contenu filtré et envoie un événement.
	 * @param poFilterOptions Options du filtre.
	 * @param paChanges Tableau des nouvelles valeurs, peut être `undefined` si aucune valeur de présente.
	 */
	private raiseValuesChanged(poFilterOptions: IFilterbarOptions, paChanges: T[]): void {
		this.filterValuesByFilterIdMap.set(poFilterOptions.id, paChanges);

		const loEvent: IFilterValuesChangedEvent = {
			id: poFilterOptions.id,
			changes: paChanges
		};

		this.moOnValuesChangedEvent.emit(loEvent);
	}

	/** Lève les différents événements de changement qui ont eu lieu (compteur de filtres et valeurs).
	 * @param poEvent Événement correspondant à la nouvelle valeur du filtre.
	 * @param poFilterOptions Élément d'options de filtre qui a levé l'événement.
	 */
	public raiseChanges(poEvent: T | T[], poFilterOptions: IFilterbarOptions): void {
		const laResults: T[] = !poEvent ? [] : coerceArray(poEvent);
		this.raiseValuesChanged(poFilterOptions, laResults);
		this.raiseFilterCountChanged();
	}

	/** Événement lors d'un clic sur un composant de filtrage, on stocke le composant cliqué pour l'utiliser.
	 * ### Méthode à utiliser sur tous les composants dont il faut exécuter une méthode d'instance et non une action paramétrée.
	 * ! Le fonctionnement n'est pas fou fou mais il y a des problèmes avec le passage en paramètre du composant directement depuis le clic où 'execute' est appelée.
	 * @param poComponent Composant de filtrage sur lequel on a cliqué.
	 */
	public onFilterbarBaseComponentClicked(poComponent: FilterbarBaseComponent): void {
		this.moClickedComponent = poComponent;
	}

	/** Ajoute une nouvelle valeur à un filtre grâce à son identifiant.
	 * @param psId Identifiant du filtre dans lequel il faut ajouter une nouvelle valeur.
	 * @param poValue Valeur à ajouter au filtre.
	 */
	public addFilterValuesById(psId: string, poValue: T): void;
	/** Ajoute une nouvelle valeur à des filtres grâce à leur identifiant.
	 * @param paIds Tableau des identifiants des filtres dans lesquels il faut ajouter une nouvelle valeur.
	 * @param paValues Tableau des valeurs à ajouter aux filtres.
	 */
	public addFilterValuesById(paIds: string[], paValues: T[]): void;
	/** Ajoute de nouvelles valeurs à un filtre grâce à son identifiant.
	 * @param psId Identifiant du filtre dans lequel il faut ajouter une nouvelle valeur.
	 * @param paValues Tableau des nouvelles valeur à ajouter au filtre.
	 */
	public addFilterValuesById(psId: string, paValues: T[]): void;
	public addFilterValuesById(poIds: string | string[], poValues: T | T[]): void {
		let laIds: string[];
		let laNewValues: T[][];

		if (poValues instanceof Array) {
			if (typeof poIds === "string") { // Cas nouvelles valeurs à un filtre.
				laIds = [poIds];
				laNewValues = [poValues];
			}
			else { // Cas nouvelle valeur à plusieurs filtres.
				laIds = poIds;
				laNewValues = poValues.map((poValue: T) => [poValue]);
			}
		}
		else { // Cas nouvelle valeur à un filtre.
			laIds = [poIds as string];
			laNewValues = [[poValues]];
		}

		this.innerAddFilterValuesById(laIds, laNewValues);
		this.detectChanges();
	}

	private innerAddFilterValuesById(paIds: string[], paNewValuesArrays: T[][]): void {
		paIds.forEach((psId: string, pnIndex: number) => {
			if (this.filterValuesByFilterIdMap.has(psId)) { // Si l'identifiant existe, alors on traite, sinon on ne fait rien.
				const loFilterOptions: IFilterbarOptions = this.filters.find((poItem: IFilterbarOptions) => poItem.id === psId);

				switch (loFilterOptions.filterType) {

					case EFilterType.avatar:
						this.filterValuesByFilterIdMap.get(psId).push(...(paNewValuesArrays[pnIndex] ?? []));
						break;

					case EFilterType.datepicker:
					case EFilterType.dateRangePicker:
						this.filterValuesByFilterIdMap.set(psId, paNewValuesArrays[pnIndex]);
						break;

					case EFilterType.tags:
						this.filterValuesByFilterIdMap.set(psId, paNewValuesArrays[pnIndex]);
						break;

					default:
						this.filterValuesByFilterIdMap.get(psId).push(...(paNewValuesArrays[pnIndex] ?? []));
						break;
				}
			}
		});
		this.raiseFilterCountChanged();
	}

	//#endregion

}