import { Component, OnDestroy } from '@angular/core';
import { EntityHelper } from 'libs/osapp/src/helpers/entityHelper';
import { IContact, IEntity, IEntitySelectorParams, ISelectorValue, IStoreDocument, NoCurrentUserDataError, SelectorWrapperComponentBase, UserData } from 'libs/osapp/src/model';
import { ContactsService, EntityLinkService, Store } from 'libs/osapp/src/services';
import { Observable, throwError } from 'rxjs';
import { map, mergeMap, tap, toArray } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { UserHelper } from '../../../helpers/user.helper';

@Component({
	selector: 'osapp-entity-selector',
	templateUrl: './entitySelector.component.html',
	styleUrls: ['./entitySelector.component.scss']
})
export class EntitySelectorComponent extends SelectorWrapperComponentBase<IEntity, IEntitySelectorParams> implements OnDestroy {

	//#region METHODS

	constructor(
		private isvcStore: Store,
		private isvcEntityLink: EntityLinkService,
		private isvcContacts: ContactsService) {

		super();
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		if (this.params && this.params.entitySelectionSubject)
			this.params.entitySelectionSubject.complete();
	}

	/** @override */
	protected initSearchOptions(): void {
		if (this.params.hasSearchbox) {
			if (!this.params.searchOptions) {
				this.params.searchOptions = {
					hasPreFillData: true,
					isSearchPanelEnable: false,
					searchFunction: (poEntity: ISelectorValue<IEntity>, psSearchValue: string) =>
						poEntity.displayValue.toLowerCase().indexOf(psSearchValue.toLowerCase()) >= 0,
					searchboxPlaceholder: "Rechercher",
				};
			}
		}
	}

	/** @override */
	protected initValues(): void {
		let loGetValues$: Observable<IStoreDocument[]>;

		// TODO Rendre le composant générique.
		if (ContactsService.isContact(this.params.entityDataSource.viewParams.startkey)) {
			if (UserData.current) {
				loGetValues$ = this.isvcContacts.getContactsByPrefix()
					.pipe(map((paContacts: IContact[]) => paContacts.filter((poContact: IContact) => !UserHelper.isCurrentUserContact(poContact))));
			}
			else
				loGetValues$ = throwError(new NoCurrentUserDataError());
		}
		else
			loGetValues$ = this.isvcStore.get(this.params.entityDataSource);

		loGetValues$
			.pipe(
				mergeMap((paResults: IStoreDocument[]) => paResults),
				map((poResult: IStoreDocument) => {
					const loEntity: IEntity = this.isvcEntityLink.buildEntity(poResult);
					return {
						value: loEntity,
						displayValue: this.getDisplayValue(loEntity),
						isSelected: ArrayHelper.hasElements(this.params.preSelectedIds) &&
							this.params.preSelectedIds.some((psPreselectedId: string) => {
								const laEntityIds: string[] = EntityHelper.getIdsFromLinkId(psPreselectedId);

								return loEntity.id === laEntityIds[0] || loEntity.id === laEntityIds[1];
							})
					} as ISelectorValue<IEntity>;
				}),
				toArray(),
				tap((paEntities: ISelectorValue<IEntity>[]) => this.values = paEntities)
			)
			.subscribe();
	}

	/** @override */
	protected getDisplayValue(poValue: IEntity): string {
		return this.isvcEntityLink.getEntityLinkDisplayValue(poValue.model, poValue.name);
	}

	/** @override */
	public onSelectionValidated(paSelectedValues: ISelectorValue<IEntity>[]): void {

		if (this.params.model) { // Si le modèle est renseigné, on peut ajouter/supprimer des liens.
			this.getSelectorValuesForLinksPersistance(paSelectedValues).forEach((poSelectedValue: ISelectorValue<IEntity>) => {
				if (poSelectedValue.isSelected)
					this.isvcEntityLink.cacheLinkToAdd(this.params.model, poSelectedValue.value);
				else
					this.isvcEntityLink.cacheLinkToRemove(this.params.model, poSelectedValue.value);
			});
		}

		this.params.entitySelectionSubject.next(this.getValuesFromSelectorValues(paSelectedValues));
	}

	/** Permet de récupérer les valeurs du sélecteur à utiliser pour la persistances des liens.
	 * @param paSelectedValues Valeurs sélectionnées dans le sélecteur.
	 */
	private getSelectorValuesForLinksPersistance(paSelectedValues: ISelectorValue<IEntity>[]): ISelectorValue<IEntity>[] {
		// Si il n'y a pas d'anciennes valeurs sélectionnées alors on retourne le tableau de valeurs reçues du sélecteur.
		if (!ArrayHelper.hasElements(this.params.preSelectedIds))
			return paSelectedValues;

		// On récupère les nouvelles valeurs sélectionnées.
		const laSelectedValues: ISelectorValue<IEntity>[] = paSelectedValues.filter((poValue: ISelectorValue<IEntity>) =>
			this.params.preSelectedIds.some((psPreselectedId: string) => psPreselectedId.indexOf(poValue.value.id) < 0) &&
			poValue.isSelected
		);

		// On récupère les valeurs supprimées par rapport aux anciennes valeurs.
		const laRemovedValues: ISelectorValue<IEntity>[] = this.values.filter((poValue: ISelectorValue<IEntity>) =>
			this.params.preSelectedIds.some((psPreselectedId: string) => psPreselectedId.indexOf(poValue.value.id) >= 0) &&
			!poValue.isSelected
		);

		// On retourne la concaténation des 2 tableaux.
		return [...laSelectedValues, ...laRemovedValues];
	}

	//#endregion
}