<!--Contenu de la page-->
<ion-content *ngIf="params" [forceOverscroll]="false" class="ion-padding gradient-back" style="height: 100%;"
	[ngClass]="params.backgroundStyles">
	<div class="member">
		<ion-list>
			<ion-item lines="none" style="--background: transparent;">
				<ion-thumbnail class="logo-size">
					<ion-img *ngIf="params.imgEnabled" [src]="params.thumbnailPicture"></ion-img>
				</ion-thumbnail>
			</ion-item>
			<!-- Connexion par mot de passe. -->
			<ion-item class="input-authentification mode-autentification ion-no-padding transparent-item"
				(click)="toggleLoginSection()" data-cy="login-section">
				<ion-icon class="ion-no-padding" [name]="loginCollapsed ? 'key' : 'close'"></ion-icon>
				<ion-label class="">Connexion par mot de passe</ion-label>
			</ion-item>

			<!-- Connexion par biométrie. -->
			<ion-item *ngIf="unlockMode && loginCollapsed && pinCollapsed" [disabled]="expiredToken"
				class="input-authentification mode-autentification ion-no-padding transparent-item"
				(click)="onFingerprintUnlockButtonClick()">
				<ion-icon [name]="biometricIconName" [class]="biometricIconClass"></ion-icon>
				<ion-label>Déverrouillage par {{ biometricType }}</ion-label>
			</ion-item>

			<!-- Déverrouillage par PIN uniquement sur mobile -->
			<ion-item *ngIf="params.pinEnabled && loginCollapsed && !expiredToken && isSavedPin && isMobileApp"
				class="input-authentification mode-autentification ion-no-padding transparent-item" (click)="onPinClicked()">
				<ion-icon class="ion-no-padding" name="keypad"></ion-icon>
				<ion-label class="">Déverrouillage par code</ion-label>
			</ion-item>
		</ion-list>

		<div [ngClass]="loginCollapsed ? 'collapsed' : ''">
			<div class="content-input">
				<ion-item lines="none" class="input-authentification">
					<ion-label *ngIf="!params.loginEditEnabled" position="floating" [(ngModel)]="credentials.login"
						ngDefaultControl>
					</ion-label>
					<ion-input *ngIf="params.loginEditEnabled" class="centera" inputmode="email" placeholder="Identifiant"
						(keyup.enter)="setPasswordFocus()" [(ngModel)]="credentials.login" ngDefaultControl data-cy="login">
					</ion-input>
				</ion-item>
			</div>
			<div class="content-input">
				<ion-item lines="none" class="input-authentification">
					<ion-input #passwordInput style="text-align:center" [type]="passwordInputType" placeholder="Mot de passe"
						[(ngModel)]="credentials.password" (keyup.enter)="validateUser()" ngDefaultControl data-cy="password"
						(ionFocus)="onPasswordFocused()">
					</ion-input>
					<ion-icon class="pswd-visibility" [name]="passwordVisibilityIconName" (click)="togglePasswordVisibility()">
					</ion-icon>
				</ion-item>
			</div>
			<div class="password-forgotten"
				*ngIf="params.passwordForgottenEnabled === undefined || params.passwordForgottenEnabled === true">
				<ion-button routerLink="/password/reset-password-request" [queryParams]="{ email: credentials.login }"
					class="btn-invite" fill="clear" color="light">Mot de passe oublié ?</ion-button>
			</div>
			<p class="centera">
				<ion-button *ngIf="params.newAccountEnabled" fill="clear" color="light" routerLink="/signup">
					Créer un compte
				</ion-button>
				<ion-button color="primary" (click)="validateUser()" data-cy="connexion">
					Connexion
				</ion-button>
			</p>
		</div>

		<div *ngIf="isStoreRelease && (params.demoEnabled || params.infosEnabled)" class="forms-links">
			<ion-button *ngIf="params.demoEnabled" routerLink="/demo" class="btn-invite" fill="clear" color="light">J'aimerais
				une démo</ion-button>
			<ion-button *ngIf="params.infosEnabled" routerLink="/infos" class="btn-invite" fill="clear" color="light">Je
				souhaiterais des informations</ion-button>
		</div>

		<div *ngIf="!expiredToken && params.pinEnabled && isSavedPin && isMobileApp"
			[ngClass]="pinCollapsed || !loginCollapsed ? 'collapsed' : ''">
			<div class="content-input">
				<ion-item lines="none" class="input-authentification">
					<ion-input #pinInput style="text-align:center" type="password" placeholder="Code" maxlength="4"
						[(ngModel)]="pin" (keyup.enter)="unlockPin()" ngDefaultControl>
					</ion-input>
				</ion-item>
			</div>
			<p class="centera">
				<ion-button color="primary" (click)="unlockPin()">
					Déverrouiller
				</ion-button>
			</p>
		</div>
	</div>
	<div class="top-right">
		<div class="number">
			<ion-label>{{versionNumber}}</ion-label>
		</div>
		<div *ngIf="isDevMode" (click)="debugComponent()" class="number">
			<ion-label>debug</ion-label>
		</div>
		<div *ngIf="changelogsUrl" class="news">
			<ion-icon name="information-circle"></ion-icon>
			<ion-note (click)="raiseChangelogsClickedAction()">Nouveautés</ion-note>
		</div>
	</div>
</ion-content>