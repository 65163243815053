import { Component, Input, OnInit } from '@angular/core';
import { ITraitement } from 'apps/idl/src/model/ITraitement';
import { PanneauService } from '../../../services/panneau.service';

@Component({
	selector: 'di-panneau-ordonnance',
	templateUrl: './panneau-ordonnance.component.html',
	styleUrls: ['./panneau-ordonnance.component.scss'],
})
export class PanneauOrdonnanceComponent implements OnInit {

	@Input()
	public ordonnance: ITraitement;

	constructor(private svcPanneau: PanneauService) { }

	ngOnInit() { }

	public handleSaveOrdonnance() {
		this.svcPanneau.close()
	}

}
