import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '@osapp/helpers';
import { ETraitementState } from '../model/ETraitementState';
import { ITraitement } from '../model/ITraitement';

@Pipe({ name: 'traitementState' })
export class TraitementStatePipe implements PipeTransform {

	//#region METHODS

	constructor() { }

	/** Transforme la valeur reçue pour l'afficher.
	 * @param poValue Valeur à transformer/vérifier.
	 */
	public transform(poTraitement: ITraitement): string {
		const ldToday = new Date();
		const ldTraitementBeginDate = new Date(poTraitement.beginDate);

		// Si la date d'interruption est renseignée alors le traitement est "interrompu".
		if (poTraitement.breakDate)
			return ETraitementState.interrompu;

		//Utilisé uniquement pour la fin de traitement
		else if (poTraitement.state)
			return poTraitement.state;

		// Si la date de fin du traitement est plus tard qu'aujourd'hui
		// et si la date de début du traitement est celle de demain ou plus tard alors le traitement est "à venir".
		else if (DateHelper.diffDays(ldTraitementBeginDate, ldToday) >= 1)
			return ETraitementState.aVenir;

		// Si la date de fin du traitement est plus tard qu'aujourd'hui
		// et si la date de début du traitement est inférieure ou égale à aujourd'hui alors elle est "en cours".
		else if (ldTraitementBeginDate < ldToday || DateHelper.areDayEqual(ldTraitementBeginDate, ldToday))
			return ETraitementState.enCours;

		// Sinon, on ne met rien, ce n'est pas normal.
		else
			return ETraitementState.inconnu;
	}

	//#endregion
}