import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormComponent } from '@osapp/components/forms/form/form.component';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { StoreHelper } from '@osapp/helpers/storeHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { ESuffix } from '@osapp/model/ESuffix';
import { UserData } from '@osapp/model/application/UserData';
import { IContact } from '@osapp/model/contacts/IContact';
import { IContactCacheData } from '@osapp/model/contacts/IContactCacheData';
import { IFormDescriptor } from '@osapp/model/forms/IFormDescriptor';
import { IStoreDocument } from '@osapp/model/store/IStoreDocument';
import { IUiResponse } from '@osapp/model/uiMessage/IUiResponse';
import { Loader } from '@osapp/modules/loading/Loader';
import { ELogActionId } from '@osapp/modules/logger/models/ELogActionId';
import { LoggerService } from '@osapp/modules/logger/services/logger.service';
import { ContactsService } from '@osapp/services/contacts.service';
import { EntityLinkService } from '@osapp/services/entityLink.service';
import { FormsService } from '@osapp/services/forms.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '@osapp/services/loading.service';
import { NavigationService } from '@osapp/services/navigation.service';
import { PlatformService } from '@osapp/services/platform.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { Observable, from, of } from 'rxjs';
import { defaultIfEmpty, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { EIdlLogActionId } from '../../../logger/models/EIdlLogActionId';
import { IPatient } from '../../model/IPatient';
import { EUpdateMode } from '../../model/eupdate-mode.enum';
import { IUpdateHistory } from '../../model/iupdate-history';
import { PatientsService } from '../../services/patients.service';
@Component({
	selector: "idl-patient-form",
	templateUrl: 'patient.component.html',
	styleUrls: ['./patient.component.scss']
})
export class PatientComponent extends ComponentBase implements OnInit, OnDestroy {

	//#region FIELDS

	private static readonly FORM_DESCRIPTOR_ID = "formDesc_patientsBook";
	private static readonly C_RECALCULATE_GPS_POPUP_MESSAGE = "Il est nécessaire de recalculer les coordonnées GPS pour pouvoir naviguer jusqu'au patient";

	/** Événement levé lors de la soumission du formulaire. */
	@Output("onSubmit") private readonly moOnSubmitEvent = new EventEmitter<IPatient>();

	//#endregion

	public model: IPatient;
	/** Paramètres du formulaire. */
	public formDefinitionId = `patient${ESuffix.edit}`;
	public formDescriptor: IFormDescriptor;
	public customSubmit: (poModel: IPatient, poForm: FormComponent<IPatient>, psTargetDatabase?: string, psActionAfterSave?: string) => Observable<any> =
		(poModel: IPatient) => this.save(poModel);

	@Input() public hasToBackAfterSave = true;

	public readonly logSourceId = "IDL.PAT.C::";

	//#region METHODS

	constructor(
		private readonly isvcPatients: PatientsService,
		private readonly isvcForms: FormsService,
		private readonly isvcEntityLink: EntityLinkService,
		private readonly isvcLoading: LoadingService,
		private readonly isvcNavigation: NavigationService,
		private readonly isvcLogger: LoggerService,
		private readonly isvcPlatform: PlatformService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly ioRoute: ActivatedRoute
	) {
		super();
	}

	public ngOnInit(): void {
		if (!this.model)
			this.model = {} as IPatient;
		else
			Number(this.model.ald) > 1 && (this.model.ald = "Oui")

		// On met à jour l'entité active.
		this.isvcEntityLink.trySetCurrentEntity(this.model).pipe(takeUntil(this.destroyed$)).subscribe();
		// Sauvegarde en cache les informations de l'adresse du patient pour détecter une modification;
		this.isvcPatients.saveAdressCacheData(this.model);

		this.init().pipe(takeUntil(this.destroyed$)).subscribe();
	}

	public ngOnDestroy(): void {
		// On supprime l'entité active.
		this.isvcEntityLink.clearCurrentEntity(this.model._id).subscribe();

		super.ngOnDestroy();
	}

	private init(): Observable<IFormDescriptor<IStoreDocument>> {
		return this.isvcForms.getFormDescriptor(PatientComponent.FORM_DESCRIPTOR_ID)
			.pipe(
				tap((poResult: IFormDescriptor) => this.formDescriptor = poResult)
			);
	}

	private save(poPatient: IPatient): Observable<boolean> {
		let loRecalculateGPSData$: Observable<boolean>;
		this.isvcPatients.deleteGPSDataIfNeeded(this.model);
		this.isvcPatients.deleteMaidenNameIfNeeded(this.model);
		delete this.model["sector"];

		if (StringHelper.isBlank(this.model._rev))
			this.isvcLogger.action(this.logSourceId, "Création du patient.", EIdlLogActionId.patientCreate as unknown as ELogActionId, { userId: UserData.current?._id, patientId: poPatient._id });
		else
			this.isvcLogger.action(this.logSourceId, "Mise à jour du patient.", EIdlLogActionId.patientUpdate as unknown as ELogActionId, { userId: UserData.current?._id, patientId: poPatient._id });

		if (!StringHelper.isBlank(this.model._rev) && NavigationService.canShowRecalculateGPSDataPopup(this.model)) {
			const loPatientCacheData: IContactCacheData = StoreHelper.getDocumentCacheData(this.model);
			if (ContactsService.isAddressDifferent(this.model, loPatientCacheData))
				loRecalculateGPSData$ = this.isvcNavigation.showRecalculateGPSDataPopup(PatientComponent.C_RECALCULATE_GPS_POPUP_MESSAGE);
			else
				loRecalculateGPSData$ = of(false);
		}
		else
			loRecalculateGPSData$ = of(false);

		return loRecalculateGPSData$
			.pipe(
				mergeMap((pbResponse: boolean) => {
					if (pbResponse) {
						let loLoader: Loader;
						return from(this.isvcLoading.create("Récupération des données GPS..."))
							.pipe(
								mergeMap((poLoader: Loader) => {
									loLoader = poLoader;
									return poLoader.present();
								}),
								mergeMap(_ => this.isvcNavigation.recalculateContactGPSData(this.model)),
								tap(() => loLoader.dismiss())
							);
					}
					return of(this.model);
				}),
				tap((poUpdatedModel: IContact) => {
					if (!ObjectHelper.isNullOrEmpty(poUpdatedModel))
						this.model = poUpdatedModel;

					const loUpdateHistory: IUpdateHistory = {
						date: this.model.updateDate = new Date(),
						mode: this.model.updateMode = EUpdateMode.manual
					};
					ArrayHelper.hasElements(this.model.updateHistory) ? this.model.updateHistory.unshift(loUpdateHistory) : this.model.updateHistory = [loUpdateHistory];
				}),
				mergeMap(() => {
					if (this.isvcPlatform.isMobileApp || !this.ioRoute.snapshot.queryParams.askExport)
						return of(false);
					else {
						return this.isvcUiMessage.showAsyncMessage<boolean>(new ShowMessageParamsPopup({
							message: `Voulez-vous faire un appel ADRi ?`,
							buttons: [{ text: "Non", handler: UiMessageService.getFalsyResponse }, { text: "Oui", handler: UiMessageService.getTruthyResponse }],
						})).pipe(
							map((poResponse: IUiResponse<boolean, any>) => poResponse.response)
						);
					}
				}),
				mergeMap((pbExport: boolean) => this.isvcPatients.savePatient(this.model, pbExport)),
				tap(_ => this.isvcPatients.saveAdressCacheData(this.model)),
				defaultIfEmpty(true),
				tap(() => this.moOnSubmitEvent.emit(this.model))
			);
	}

	//#endregion
}