import { Component } from "@angular/core";

@Component({
  templateUrl: 'authenticatorSettings.component.html'
})
export class AuthenticatorSettingsComponent {

  //#region PROPERTIES

  public account: { login: string, password: string, confirmPassword: string } = {
    login: "",
    password: "",
    confirmPassword: ""
  };

  //#endregion

  //#region METHODS

  constructor() { }

  public cancel(): void {
    alert("cancel");
  }

  public createAccount(): void {
    alert(JSON.stringify(this.account));
  }

  //#endregion
}