import { Component, OnInit } from '@angular/core';
import { DateHelper } from '@osapp/helpers';
import { EPrefix, IContact, UserData } from '@osapp/model';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ContactsService } from '@osapp/services';
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MenuSelectionInfirmierComponent } from '../../features/shared/components/menu-selection-infirmier/menu-selection-infirmier.component';
import { DeviceService } from '../../features/shared/services/device.service';
import { DrawerPopoverService } from '../../features/shared/services/drawer-popover.service';
import { SeanceService } from '../../features/shared/services/seance.service';
import { StoredSeance } from '../../models/StoredSeance';

@Component({
  selector: 'di-tournees',
  templateUrl: './tournees.page.html',
  styleUrls: ['./tournees.page.scss'],
})
export class TourneesPage extends DestroyableComponentBase implements OnInit {
  public tourneesfilters: Array<string> = ["Liste", "Agenda"];
  public defaultFilter = this.tourneesfilters[0];
  public isMobile: boolean = false;
  public nbRdvPatient: number = 0;
  public totalPrevu: number = 0;
  public today: Date = new Date();
  public seances: StoredSeance[] = [];
  public infirmierSelected: IContact;

  public infirmiersWS: IContact[];

  public dateSelected: Date;
  private dateSelectedSubject = new Subject<Date>();

  constructor(
    private svcDevice: DeviceService,
    private svcSeance: SeanceService,
    private svcDrawerPopover: DrawerPopoverService,
    private svcContact: ContactsService
  ) { super(); }

  ngOnInit() {
    this.initDateSelected();
    this.initInfirmier();

    this.svcDevice.isMobile$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((flag: boolean) => {
        this.isMobile = flag;
      });

    // Ecoute les changements de date et récupère les séances correspondantes
    this.dateSelectedSubject
      .pipe(
        takeUntil(this.destroyed$),
        tap((date: Date) => this.dateSelected = date),
        switchMap((date: Date) => this.svcSeance.selectSeancesByDateAndInfirmier(date, ContactsService.getContactIdFromUserId(this.infirmierSelected._id))),
        tap((seances: StoredSeance[]) => {
          this.seances = seances;
          this.nbRdvPatient = seances.length;
        })
      )
      .subscribe();

  }

  handleClickToday() {
    this.dateSelectedSubject.next(this.today);
  }

  openMenuSelectionInfirmier(event: Event) {
    //On regarde si on a déjà récupérer les autres infirmiers du WS
    this.getInfirmiersWorkspace();

    this.svcDrawerPopover.open("",
      "400px",
      event.currentTarget,
      MenuSelectionInfirmierComponent,
      {
        infirmiers: this.infirmiersWS,
        onSelection: this.selectionNewInfirmier
      })
  }

  selectionNewInfirmier = (infirmier: IContact): void => {
    this.infirmierSelected = infirmier;
		this.svcDrawerPopover.close();
		this.dateSelectedSubject.next(this.dateSelected);
  }

  private getInfirmiersWorkspace() {
    if (!this.infirmiersWS || this.infirmiersWS.length === 0) {
      //On récupère les infirmiers en bdd
      this.svcContact.getSiteContactsAnakin([], EPrefix.contact, true, true)
        .pipe(
          map((contacts: IContact[]) => {
            return contacts.filter((contact: IContact) => {
              return contact.userId;
            });
          }),
          tap((contacts: IContact[]) => { this.infirmiersWS = contacts; }),
          takeUntil(this.destroyed$)
        ).subscribe();
    }
  }

  previousDay() {
    const newDate = DateHelper.addDays(this.dateSelected, -1);
    this.dateSelectedSubject.next(newDate);
  }

  nextDay() {
    const newDate = DateHelper.addDays(this.dateSelected, 1);
    this.dateSelectedSubject.next(newDate);
  }

  changeFilter(filtre: string) {
    this.defaultFilter = filtre;
    //il faut changer de vue et recharger toutes les séances d'une semaine
  }

  private initDateSelected() {
    this.dateSelected = this.today;
  }

  private initInfirmier() {
    this.svcContact.getContactFromUserId(UserData.current?.name)
      .pipe(
        takeUntil(this.destroyed$)
      ).subscribe((contact) => {
        this.infirmierSelected = contact;
				this.dateSelectedSubject.next(this.dateSelected);
      });
  }
}
