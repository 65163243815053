<div class="di-modifier-heures-soins">
	<div class="moment">
		<h4>Moment</h4>
		<div class="moments">
			<div *ngFor="let moment of moments">
				<lua-option-data-picker [label]="moment" [isSelected]="moment == selectedMoment"
					(click)="onSelectMoment(moment)"></lua-option-data-picker>
			</div>
		</div>
	</div>
	<div class="heure-personnalisee" [formGroup]="modifForm">
		<h4>Heure personnalisée</h4>
		<lua-input size="medium" type="time" [(ngModel)]="horairePrecise" formControlName="heure"
			name="horairePrecise"></lua-input>
	</div>

	<lua-action-secondaire libelle="Changer l'heure" color="CouleurPrimaire" [fullWidth]="true"
		(click)="handleConfirm()"></lua-action-secondaire>
	<lua-bouton-texte libelle="Annuler" color="CouleurPrimaire" [fullWidth]="true" (click)="cancel()"></lua-bouton-texte>
</div>