import { ChangeDetectorRef, Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, ElementRef, Input, OnDestroy, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonSlides, NavController } from '@ionic/angular';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { filter, map, mapTo, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { SwiperOptions } from 'swiper';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { ComponentBase } from '../../helpers/ComponentBase';
import { NumberHelper } from '../../helpers/numberHelper';
import { StringHelper } from '../../helpers/stringHelper';
import { EBarElementAction } from '../../model/barElement/EBarElementAction';
import { DynHostItem } from '../../model/DynHostItem';
import { IIndexedArray } from '../../model/IIndexedArray';
import { ICustomLifeCycleComponent } from '../../model/lifeCycle/ICustomLifeCycleComponent';
import { IBlockedSlidesEvent } from '../../model/slidebox/IBlockedSlidesEvent';
import { ISlidebox } from '../../model/slidebox/ISlidebox';
import { ISlideboxData } from '../../model/slidebox/ISlideboxData';
import { ISlideboxEvent } from '../../model/slidebox/ISlideboxEvent';
import { ISlideboxState } from '../../model/slidebox/ISlideboxState';
import { SlideboxService } from '../../services/slidebox.service';
import { DynamicPageComponent } from '../dynamicPage/dynamicPage.component';
import { DynHostDirective } from '../dynHost/dynHost.directive';
import { IDynHostComponent } from '../dynHost/IDynHost.component';

@Component({
	selector: 'calao-slidebox',
	templateUrl: 'slidebox.component.html',
	styleUrls: ['./slidebox.component.scss']
})
export class SlideboxComponent extends ComponentBase implements OnDestroy, ICustomLifeCycleComponent {

	//#region FIELDS

	private msPreviousSlideId = "";
	/** Objet-état du composant qui permet d'effectuer des actions avec le service des slidebox. */
	private moSlideboxState: ISlideboxState;

	private moCurrentComponent: IDynHostComponent;
	private msRouteSlideId: string;
	//#endregion

	//#region PROPERTIES

	@ViewChild("sliderContainer") public sliderContainer: IonSlides;
	@ViewChild("sliderTabBar", { read: ElementRef }) public sliderTabBar: ElementRef<HTMLDivElement>;
	@ViewChildren("sliderTabs", { read: ElementRef }) public sliderTabs: QueryList<ElementRef<HTMLIonButtonElement>>;
	@ViewChildren(DynHostDirective) public dynHosts: QueryList<DynHostDirective>;
	@Input("slideEvent") public slideEvent: Function;
	@Input("dataTemplate") public dataTemplate: ISlideboxData;
	/** Identifiant du composant (différent de l'identifiant d'instance). */
	@Input("componentId") public componentId: string;

	public currentSlideId = "";
	public childId: string;
	public instanceId: string;
	public options: SwiperOptions = { threshold: 50 };
	public isSlideBoxInit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public static readonly C_SLIDE_QUERY_PARAM_ID = "slide";

	//#endregion

	//#region METHODS

	constructor(
		private ioParentPage: DynamicPageComponent<ComponentBase>,
		public ioNavController: NavController,
		private isvcSlidebox: SlideboxService,
		private ioComponentFactoryResolver: ComponentFactoryResolver,
		private readonly ioRoute: ActivatedRoute,
		poChangeDetectorRef: ChangeDetectorRef) {

		super(poChangeDetectorRef);

		this.instanceId = this.msInstanceId;
		this.msRouteSlideId = this.ioRoute.snapshot.queryParamMap.get(SlideboxComponent.C_SLIDE_QUERY_PARAM_ID);
		this.dataTemplate = { _id: "", id: "default", defaultSlideId: "", areSwipesLocked: false, slideBoxes: [], pageTitle: "" };
	}

	/** Endroit où se désabonner et se détacher des événements pour éviter des fuites mémoires, juste avant la destruction du composant. */
	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.isSlideBoxInit$.complete();

		if (this.sliderContainer) { // Vérifier dans le cas où on fait un back très vite, ce n'est pas setté.
			const lbRemoved: boolean = this.isvcSlidebox.removeSlideContainer(this.componentId);
			if (!lbRemoved)
				console.warn(`SB.C:: La slidebox '${this.getInstanceId()}' n'a pas pu être supprimée du service car son componentId = ${this.componentId}) est introuvable.`);
		}
	}

	public onSlideboxLoaded(): void {
		this.moSlideboxState = {
			componentId: this.componentId,
			container: this.sliderContainer,
			data: this.dataTemplate
		};
		this.isvcSlidebox.setSlideboxState(this.moSlideboxState);

		this.getDataTemplate()
			.pipe(
				mergeMap((poSlideboxData: ISlideboxData) => this.manageAfterInit()),
				takeUntil(this.destroyed$)
			)
			.subscribe(
				(pbResult: boolean) => { },
				poError => console.error("SB.C::", poError)
			);

		if (!this.slideEvent)
			this.slideEvent = () => { };
	}

	private getDataTemplate(): Observable<ISlideboxData> {
		if (this.dataTemplate)
			return of(this.dataTemplate);
		else {
			return this.isvcSlidebox.getSlideboxData(this.componentId)
				.pipe(
					take(1),
					tap((poEvent: ISlideboxEvent) => {
						this.moSlideboxState.data = this.dataTemplate = poEvent.params;
						this.isvcSlidebox.setSlideboxState(this.moSlideboxState);
					}),
					mapTo(this.dataTemplate)
				);
		}
	}

	/** Injecte le html du composant dans le DOM.
	 * @param psId Identifiant de la slide dans laquelle on veut injecter le composant dynamique.
	 */
	private injection(): void {
		const laViewContainerRefs: Array<DynHostDirective> = this.dynHosts.toArray();

		const loSlidebox: ISlidebox = this.dataTemplate.slideBoxes.find((poSlidebox: ISlidebox) => poSlidebox.id === this.currentSlideId);
		const lnCurrentSlideboxIndex: number = this.isvcSlidebox.searchIndexById(this.currentSlideId, this.componentId);
		const lnPreviousSlideboxIndex: number = this.isvcSlidebox.searchIndexById(this.msPreviousSlideId, this.componentId);
		const loComponentFactory: ComponentFactory<any> = this.ioComponentFactoryResolver.resolveComponentFactory((loSlidebox.slideItem as DynHostItem).component);

		// On teste s'il y a des des paramètres dans le dynHost data mais aussi les paramètres dans le champ "params" que l'on récupère de la base de données.
		// Si params existe il est prioritaire sur les data venant de la data du dynHost.
		if (loSlidebox.params)
			(loSlidebox.slideItem as DynHostItem).params = loSlidebox.params;

		// On crée une référence sur le slide précédent et sur le courant.
		const loCurrentViewContainerRef: ViewContainerRef = laViewContainerRefs[lnCurrentSlideboxIndex].viewContainerRef;
		const loPreviousViewContainerRef: ViewContainerRef = laViewContainerRefs[lnPreviousSlideboxIndex].viewContainerRef;

		// On nettoie le contenu possible de ces deux composants pour pouvoir injecter le nouveau slide.
		loCurrentViewContainerRef.clear();
		loPreviousViewContainerRef.clear();

		const loComponentRef: ComponentRef<any> = loCurrentViewContainerRef.createComponent(loComponentFactory);
		this.moCurrentComponent = (loComponentRef.instance as IDynHostComponent);
		(loComponentRef.instance as IDynHostComponent).params = (loSlidebox.slideItem as DynHostItem).params;
		this.childId = (loComponentRef.instance as IDynHostComponent).instanceId;

		this.sliderContainer.lockSwipes(this.dataTemplate.areSwipesLocked);
		this.slideTabBar(lnCurrentSlideboxIndex);

		// On rafraîchit les composants fils qui viennent d'être injectés.
		this.detectChanges();
	}

	public getCurrentSlideComponent<T extends IDynHostComponent = IDynHostComponent>(): T {
		return this.moCurrentComponent as T;
	}

	/** Détecte les changements dans la vue, vérifie l'index de la slide par défaut, inject le html dans le DOM,
	 * se déplace vers la slide par défaut après que la vue ait été chargée correctement.
	 */
	private manageAfterInit(): Observable<boolean> {

		if (this.dataTemplate) {
			if (StringHelper.isBlank(this.dataTemplate.defaultSlideId)) // Pas d'identifiant pour la slide par défaut.
				this.dataTemplate.defaultSlideId = ArrayHelper.getFirstElement(this.dataTemplate.slideBoxes).id;

			this.currentSlideId = this.msPreviousSlideId =
				this.dataTemplate.slideBoxes.find((poSlidebox: ISlidebox) => poSlidebox.id === this.msRouteSlideId)?.id ?? this.dataTemplate.defaultSlideId;
			this.detectChanges();

			this.injection();	// On injecte le slide qui correspond au slide de départ.
			this.slideTo(this.currentSlideId); // On slide vers la slide courante.
			this.subscribeToBlockedSlideIdsUpdate(); // On s'abonne aux événement de notifications des identifiants de slide bloqués.
			this.isSlideBoxInit$.next(true);

			return this.isvcSlidebox.getSlideboxData(this.componentId)
				.pipe(
					filter((poEvent: ISlideboxEvent) => poEvent.action === EBarElementAction.reset),
					map((poEvent: ISlideboxEvent) => {
						this.injection();
						this.ioParentPage.title = this.dataTemplate.pageTitle;
						return true;
					}),
					takeUntil(this.destroyed$)
				);
		}
		else
			return of(false);
	}

	/** Appelée au moment où un changement de slide est réalisé. */
	public slideChanging(): void {
		from(this.sliderContainer.getActiveIndex())
			.pipe(
				tap((pnCurrentSlideIndex: number) => {
					this.slideEvent(); // Exécution de la méthode fournie par le parent.

					if (pnCurrentSlideIndex < this.dataTemplate.slideBoxes.length) {
						this.msPreviousSlideId = this.currentSlideId;
						this.currentSlideId = this.moSlideboxState.data.slideBoxes[pnCurrentSlideIndex].id;
						this.injection();
					}

				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Change de slide depuis l'actuelle vers une autre.
	 * @param psId Identifiant de la slide sur laquelle on veut aller.
	 */
	public slideTo(psId: string): void {
		// Si aucun identifiant de slide n'est bloqué OU que l'identifiant de la slide de destination n'est pas un des identifiants de slides bloquées, on peut naviguer.
		if (!(ArrayHelper.hasElements(this.dataTemplate.blockedIds) && this.dataTemplate.blockedIds.some((psBlockedId: string) => psBlockedId === psId))) {
			const lnSlideToIndex: number = this.isvcSlidebox.searchIndexById(psId, this.componentId);

			if (NumberHelper.isValid(lnSlideToIndex)) {
				this.slideTabBar(lnSlideToIndex);

				from(this.isvcSlidebox.slideTo(psId, this.componentId))
					.pipe(takeUntil(this.destroyed$))
					.subscribe();
			}
		}
	}

	/** Fait défiler la tabbar jusqu'à l'index voulu.
	 * @param pnIndex Index de la slide sur laquelle on se situe.
	 */
	private slideTabBar(pnIndex: number): void {
		if (pnIndex > 0) {
			let lnScrollingWidth = 0;
			const laItemsBefore: Array<ElementRef<HTMLIonButtonElement>> = this.sliderTabs.toArray().slice(0, pnIndex - 1); // Boutons avant celui sur lequel on est actuellement.
			laItemsBefore.forEach((poTab: ElementRef<HTMLIonButtonElement>) => lnScrollingWidth += poTab.nativeElement.clientWidth);
			this.sliderTabBar.nativeElement.scrollLeft = lnScrollingWidth;
		}
	}

	/** Abonnement aux notifications de mises à jour des identifiants de slides bloqués qui doit être lancé après complète initialisation du composant. */
	private subscribeToBlockedSlideIdsUpdate(): void {
		this.isvcSlidebox.getBlockedSlideIdsUpdatedObservable(this.componentId)
			.pipe(
				tap((poEvent: IBlockedSlidesEvent) => this.manageSwipeFromBlockedSlideIds(poEvent.blockedSlideIds)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Gère le blocage du swipe précédent et suivant en fonction des identifiants de slides bloqués.
	 * @param paBlockedSlideIds Tableau des identifiants de slides bloqués.
	 */
	public manageSwipeFromBlockedSlideIds(paBlockedSlideIds: string[] = this.dataTemplate.blockedIds): void {
		if (ArrayHelper.hasElements(this.dataTemplate.blockedIds)) {
			const lnCurrentSlideIndex: number = this.isvcSlidebox.searchIndexById(this.currentSlideId, this.componentId);
			const loBlockedSlideIdsWithIndex: IIndexedArray<number> = {};
			paBlockedSlideIds.forEach((psId: string) => loBlockedSlideIdsWithIndex[psId] = this.isvcSlidebox.searchIndexById(psId, this.componentId));

			for (const lsKey in loBlockedSlideIdsWithIndex) {
				if (loBlockedSlideIdsWithIndex[lsKey]) {
					const lbIsPreviousOrNexSlideId: boolean = this.dataTemplate.blockedIds.some((psId: string) => psId === lsKey);

					// Si l'index de slide parcouru est celui qui se trouve après l'actuel et qu'il correspond à un identifiant de slide bloqué, alors on bloque le swipe suivant.
					if (loBlockedSlideIdsWithIndex[lsKey] === lnCurrentSlideIndex + 1 && lbIsPreviousOrNexSlideId)
						this.sliderContainer.lockSwipeToNext(true);

					// Si l'index de slide parcouru est celui qui se trouve avant l'actuel et qu'il correspond à un identifiant de slide bloqué, alors on bloque le swipe précédent.
					else if (loBlockedSlideIdsWithIndex[lsKey] === lnCurrentSlideIndex - 1 && lbIsPreviousOrNexSlideId)
						this.sliderContainer.lockSwipeToPrev(true);
				}
			}
		}
		else // Si pas de slide bloquées, on autorise le swipe.
			this.sliderContainer.lockSwipes(false);
	}

	//#endregion
}
