import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoggerService } from '@osapp/modules/logger/services/logger.service';
import { FormsService } from '@osapp/services/forms.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '@osapp/services/interfaces/ShowMessageParamsToast';
import { SlideboxService } from '@osapp/services/slidebox.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, mergeMap, takeUntil } from 'rxjs/operators';
import { IdlActionButtonFieldBase } from '../../../model/action-buttons/IdlActionButtonFieldBase';
import { EExportResult } from '../../../model/EExportResult';
import { ExportError } from '../../../model/ExportError';
import { IPatient } from '../../../model/IPatient';
import { ExportService } from '../../../services/export.service';
import { IdlActionButtonService } from '../../../services/idl-action-button.service';

@Component({
	selector: "idl-export-action-button",
	templateUrl: './export-action-button.component.html',
	styleUrls: ['./export-action-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportActionButtonComponent extends IdlActionButtonFieldBase<IPatient> implements OnInit {

	//#region PROPERTIES

	public icon: string;
	public label: string;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcExport: ExportService,
		private readonly isvcUiMessage: UiMessageService,
		/** @implements */
		public readonly isvcLogger: LoggerService,
		psvcSlidebox: SlideboxService,
		psvcActionButton: IdlActionButtonService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcSlidebox, psvcActionButton, psvcForms, poChangeDetectorRef);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		this.icon = "desmos";
		this.label = "Actualisation Desmos";
	}

	/** @override Exporte un patient vers le logiciel partenaire. */
	public action(): void {
		this.isvcExport.exportPatient(this.model)
			.pipe(
				catchError((poError: ExportError | any) => this.displayExportErrorMessage(poError)),
				mergeMap((peResult: EExportResult) => this.managePatientExportResult(peResult)),
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();
	}

	private managePatientExportResult(peResult: EExportResult): Observable<void | never> {
		if (peResult === EExportResult.patientSuccess || peResult === EExportResult.patientUpdated) {
			this.isvcUiMessage.showMessage(
				new ShowMessageParamsToast({ message: peResult === EExportResult.patientSuccess ? "Le patient a bien été exporté." : "Le patient a bien été mis à jour." })
			);

			return of(this.detectChanges());
		}
		else
			return this.displayExportErrorMessage();
	}

	private displayExportErrorMessage(poError?: ExportError | any): Observable<never> {
		if (poError)
			console.error(`IDL.EXP.C:: Erreur export patient : `, poError);

		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({
				message: (poError && poError instanceof ExportError) ? poError.message : ExportService.C_EXPORT_PATIENT_ERROR_MESSAGE,
				header: "Erreur d'export"
			})
		);

		return EMPTY;
	}

	//#endregion

}