<ion-list [ngClass]="!readonly && showActionButtons ? 'align-top ion-no-padding' : 'ion-no-padding'">
	<!-- Sélectionner tout -->
	<ion-item *ngIf="!readonly && hasElements && itemsLength > 1 && showCheckbox" (click)="onSelectAllChanged()"
		lines="none" class="ion-no-padding">
		<div slot="end" class="act-buttons">
			<ion-button [disabled]="readonly" (click)="$event.stopPropagation(); addAct()"
				class="btn-add-acte blue-fab-button">
				<ion-icon slot="icon-only" name="add"></ion-icon>
				Ajouter un acte
			</ion-button>
		</div>
	</ion-item>

	<!-- Actes -->
	<ng-container *ngTemplateOutlet="actesWithCheckStatus.length > 0 ? actesContent : emptyActe">
	</ng-container>

	<!-- Affichage des majorations et indemnités. -->
	<ng-container *ngIf="showMajorationsAndIndemnites">
		<!-- Majorations -->
		<ng-container
			*ngTemplateOutlet="majorationsWithCheckStatus.length > 0 ? majorationsContent : emptyMajorationOrIndemnite; context: { isMajoration: true }">
		</ng-container>

		<!-- Indemnités -->
		<ng-container
			*ngTemplateOutlet="indemnitesWithCheckStatus.length > 0 ? indemnitesContent : emptyMajorationOrIndemnite; context: { isMajoration: false }">
		</ng-container>
	</ng-container>
</ion-list>

<osapp-date-time #seanceDateTimePicker class="hidden" [model]="seanceStartDate" [params]="dateTimePickerParams"
	[disabled]="readonly" (modelChange)="onSeanceMoved($event)">
</osapp-date-time>

<!--#region TEMPLATES -->

<!-- Template de contenu des actes. -->
<ng-template #actesContent>
	<div class="just-flex flex-row act-title">
		<ion-label>Acte</ion-label>
		<div class="line"></div>
	</div>
	<ion-item *ngFor="let actWithCheckStatus of actesWithCheckStatus; let actIndex = index;"
		[disabled]="!readonly && actWithCheckStatus.acte.canceled" lines="none" class="ion-no-padding"
		(click)="showCheckbox ? onItemSelectionChanged(actWithCheckStatus) : ''">
		<!-- Label -->
		<ion-label>
			<ion-note class="acte" [ngClass]="actWithCheckStatus.acte.canceled ? 'crossed' : ''">
				{{ actWithCheckStatus.acte.keyLetters.toString() }} {{ actWithCheckStatus.acte.priceCoefficient }} {{
				actWithCheckStatus.acte.label }}
			</ion-note>
			<ion-note *ngIf="actWithCheckStatus.acte.observations" class="observations">
				{{ actWithCheckStatus.acte.observations }}
			</ion-note>
		</ion-label>
		<!-- Boutons -->
		<div *ngIf="!readonly && !actWithCheckStatus.checked && !actWithCheckStatus.acte.canceled" slot="end"
			class="act-buttons">
			<ion-fab-button size="small" (click)="$event.stopPropagation(); moveAct(actWithCheckStatus.acte)"
				class="ion-no-margin orange-fab-button" title="Modifier">
				<ion-icon name="arrow-redo"></ion-icon>
			</ion-fab-button>
			<ion-fab-button size="small" color="danger"
				(click)="$event.stopPropagation(); cancelAct(actWithCheckStatus.acte, actIndex)" class="ion-no-margin"
				title="Retirer">
				<ion-icon name="remove-outline"></ion-icon>
			</ion-fab-button>
			<ion-fab-button size="small" (click)="$event.stopPropagation(); changeActe(actWithCheckStatus.acte)" fill="clear"
				class="ion-no-margin blue-fab-button" title="Ajouter">
				<ion-icon name="create"></ion-icon>
			</ion-fab-button>
		</div>
	</ion-item>
</ng-template>

<!-- Template d'acte vide. -->
<ng-template #emptyActe>
	<div class="just-flex flex-row act-title">
		<ion-label>Acte</ion-label>
		<div class="line"></div>
	</div>
	<ion-item lines="none">
		<ion-label>Aucun acte.</ion-label>
	</ion-item>
</ng-template>

<!-- Template de contenu des majorations. -->
<ng-template #majorationsContent>
	<div class="just-flex flex-row act-title">
		<ion-label>Majoration</ion-label>
		<div class="line"></div>
	</div>
	<ion-item *ngFor="let majorationWithCheckStatus of majorationsWithCheckStatus; let majorationIndex = index"
		(click)="onItemSelectionChanged(majorationWithCheckStatus)" lines="none" class="ion-no-padding">
		<!-- Label -->
		<ion-label>
			<ion-note class="majoration">{{ majorationWithCheckStatus.majoration.description }}</ion-note>
		</ion-label>
		<!-- Boutons -->
		<div *ngIf="!readonly && !majorationWithCheckStatus.checked" slot="end" class="majoration-buttons">
			<ion-fab-button [disabled]="!isOngoing" size="small" (click)="$event.stopPropagation(); goToMajoration()"
				class="ion-no-margin blue-fab-button" title="Ajouter">
				<ion-icon name="create"></ion-icon>
			</ion-fab-button>
			<ion-fab-button size="small" color="danger"
				(click)="$event.stopPropagation(); removeMajoration(majorationWithCheckStatus.majoration, majorationIndex)"
				class="ion-no-margin" title="Retirer">
				<ion-icon name="remove-outline"></ion-icon>
			</ion-fab-button>
		</div>
	</ion-item>
</ng-template>

<!-- Template de contenu des indemnités. -->
<ng-template #indemnitesContent>
	<div class="just-flex flex-row act-title">
		<ion-label>Indemnité</ion-label>
		<div class="line"></div>
	</div>
	<ion-item *ngFor="let indemniteWithCheckStatus of indemnitesWithCheckStatus; let indemniteIndex = index"
		(click)="onItemSelectionChanged(indemniteWithCheckStatus)" lines="none" class="ion-no-padding">
		<!-- Label -->
		<ion-label>
			<ion-note class="indemnite">{{ indemniteWithCheckStatus.indemnite.description }}</ion-note>
		</ion-label>
		<!-- Boutons -->
		<div *ngIf="!readonly && !indemniteWithCheckStatus.checked" slot="end" class="indemnite-buttons">
			<ion-fab-button [disabled]="!isOngoing" size="small" (click)="$event.stopPropagation(); goToIndemnite()"
				class="ion-no-margin blue-fab-button" title="Ajouter">
				<ion-icon name="create"></ion-icon>
			</ion-fab-button>
			<ion-fab-button size="small" color="danger"
				(click)="$event.stopPropagation(); removeIndemnite(indemniteWithCheckStatus.indemnite, indemniteIndex)"
				class="ion-no-margin" title="Retirer">
				<ion-icon name="remove-outline"></ion-icon>
			</ion-fab-button>
		</div>
	</ion-item>
</ng-template>

<!-- Template de majoration ou indemnité vide. -->
<ng-template #emptyMajorationOrIndemnite let-isMajoration="isMajoration">
	<div class="just-flex flex-row act-title">
		<ion-label>{{ isMajoration ? "Majoration" : "Indemnité" }}</ion-label>
		<div class="line"></div>
	</div>
	<ion-item lines="none">
		<!-- Label -->
		<ion-label>
			<ion-note [class]="isMajoration ? 'majoration' : 'indemnite'">
				{{ isMajoration ? "Aucune majoration." : "Aucune indemnité." }}</ion-note>
		</ion-label>
		<!-- Bouton -->
		<div *ngIf="!readonly" slot="end" [class]="isMajoration ? 'majoration-buttons' : 'indemnite-buttons'">
			<ion-fab-button [disabled]="!isOngoing" size="small"
				(click)="$event.stopPropagation(); isMajoration ? goToMajoration() : goToIndemnite()"
				class="ion-no-margin blue-fab-button" title="Ajouter">
				<ion-icon name="create"></ion-icon>
			</ion-fab-button>
		</div>
	</ion-item>
</ng-template>

<!--#endregion -->