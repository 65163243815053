import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { TabComponent } from 'lighting-up-angular';
import { forkJoin, Observable, of } from 'rxjs';
import { concatMap, delay, finalize, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ITraitement } from '../../../../model/ITraitement';
import { Traitement } from '../../../../model/Traitement';
import { TraitementService } from '../../../../services/traitement.service';
import { MenuOrdonnanceComponent } from '../../../features/ordonnances/components/menu-ordonnance/menu-ordonnance.component';
import { DrawerPopoverService } from '../../../features/shared/services/drawer-popover.service';
import { SeanceService } from '../../../features/shared/services/seance.service';
import { SeancesGeneratorService } from '../../../features/shared/services/seances-generator.service';
import { StoredSeance } from '../../../models/StoredSeance';
@Component({
	selector: 'di-detail-ordonnance',
	templateUrl: './detail-ordonnance.page.html',
	styleUrls: ['./detail-ordonnance.page.scss'],
})
export class DetailOrdonnancePage extends DestroyableComponentBase implements OnInit {

	constructor(
		private svcPatients: PatientsService,
		private svcOrdonnance: TraitementService,
		private router: Router,
		private route: ActivatedRoute,
		private svcSeancesGenerator: SeancesGeneratorService,
		private svcDrawerPopover: DrawerPopoverService,
		private svcSeance: SeanceService
	) {
		super()
	}
	public showPopover: boolean;

	public mode: "add" | "edit" = "add";
	public patientId: string;
	public currentStep: number = 0;
	public patient: IPatient;
	public ordonnance?: ITraitement;
	public stepNames = ['ordonnance', 'Actes et soins', 'Séances']
	public showTabActe: boolean = false;
	public showTabSeances: boolean = false;
	public showTabFactures: boolean = false;
	public tabSelected: string = "";
	idOrdonnance!: string;
	public seances: StoredSeance[] = [];

	ngOnInit(): void {

		this.idOrdonnance = this.route.snapshot.paramMap.get('id')!;
		this.getTabSelectedFromRoute();
		if (this.idOrdonnance) {
			this.svcOrdonnance.getTraitementANAKIN(this.idOrdonnance).pipe(
				tap((ordonnance: Traitement) => {
					this.ordonnance = ordonnance;
					this.patientId = Traitement.extractPatientId(this.idOrdonnance);
					this.getPatient(this.patientId);
				}),
				tap(() => {
					this.showTabSeances = this.tabSelected === "Séances";
				}),
				takeUntil(this.destroyed$)
			).subscribe();
			this.mode = "edit";
		}
		else {
			const state = this.router.getCurrentNavigation()?.extras.state as { patientId: string };
			this.patientId = state?.patientId;
			if (!this.patientId) {
				this.patientId = history.state.patientId;
			}
			this.getPatient(this.patientId);
		}
	}

	private getTabSelectedFromRoute() {
		const state = this.router.getCurrentNavigation()?.extras.state as { tabSelected: string; };
		this.tabSelected = state?.tabSelected;
		if (!this.tabSelected) {
			this.tabSelected = history.state.tabSelected;
		}
	}
		
	private getPatient(patientId: string) {
		this.svcPatients.getPatient(patientId).pipe(
			tap((patient: IPatient) => {
				this.patient = patient;
			}),
			takeUntil(this.destroyed$)
		).subscribe();
	}
	onStepChange(stepIndex: number): void {
		this.currentStep = stepIndex - 1;  // Adjust step index if needed
	}


	public handleSaveOrdonnance(ordo: Traitement) {
		this.ordonnance = ordo;
		if (this.mode === "add") {
			this.currentStep++;
		}
		if (this.mode === "edit") {
			this.router.navigate(['ordonnances']);
		}
	}

	public handleGenerateSeance() {
		if (this.mode === "add") {
			this.generateSeances().subscribe();

		}
		if (this.mode === "edit") {
			this.deleteAllSeanceAndGenerate();
		}
	}

	public openMenu(event: Event): void {
		this.showPopover = true;
		this.svcDrawerPopover.open("", "50%", event.currentTarget, MenuOrdonnanceComponent, { edit: false }, () => this.showPopover = false)
	}


	handleTabSelection(tab: TabComponent) {
		this.showTabActe = tab && tab.label === "Actes";
		this.showTabSeances = tab && tab.label === "Séances";
		this.showTabFactures = tab && tab.label === "Factures";
	}

	public generateSeances(): Observable<Boolean>{
		return this.svcOrdonnance.getTraitement(this.ordonnance?._id)
			.pipe(
				tap((_) => { this.currentStep++; }),
				tap((traitement: Traitement) => this.ordonnance = traitement),
				mergeMap((traitement: Traitement) => this.svcSeancesGenerator.saveSeances(traitement, this.patient)),
				delay(500),//TODO: a supprimer si trop long, simule une latence pour le moment
				finalize(() => { this.router.navigate(['ordonnances/edit/', this.ordonnance._id], { state: { tabSelected: "Séances" } }); }),
				takeUntil(this.destroyed$)
			);
	}


	public deleteAllSeanceAndGenerate(): void {
    this.svcSeance.selectSeancesByTraitement(this.ordonnance).pipe(
        mergeMap((listSeance: StoredSeance[]) => {
            if (listSeance.length === 0) {
                return of(null);
            }
            const deleteSeanceObservables = listSeance.map(seance => 
                this.svcSeance.deleteSeance(seance)
            );
            return forkJoin(deleteSeanceObservables);
        }),
        concatMap(() => this.generateSeances()),
        delay(500),
        tap(() => {
            this.tabSelected = "Séances";
            this.showTabSeances = true;
            this.showTabActe = false;
        }),
        takeUntil(this.destroyed$),
    ).subscribe();
}

}