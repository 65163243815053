import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { StoredSeance } from 'apps/idl/src/anakin/models/StoredSeance';
import { EStatusSeance } from 'apps/idl/src/model/EStatusSeance';
import { Traitement } from 'apps/idl/src/model/Traitement';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { TraitementService } from 'apps/idl/src/services/traitement.service';
import { concatMap, map } from 'rxjs/operators';

@Component({
  selector: 'di-liste-seance-tournees',
  templateUrl: './liste-seance-tournees.component.html',
  styleUrls: ['./liste-seance-tournees.component.scss'],
})
export class ListeSeanceTourneesComponent implements OnInit, OnChanges {
  @Input() date: Date = new Date();
  @Input() seances: StoredSeance[] = [];

  public traitementIdsTab: string[];
  public ordonnances: Map<string, Traitement> = new Map();
  public sortedSeances: { [key: string]: StoredSeance[] } = {};
  public nbSoinRealise: number = 0;
  public labelSoinRealise: string = "Aucun soin réalisé";
  public patients: Map<string, IPatient>;
  public patientIdsTab: string[];

  public moments = [
    { key: 'matin', label: 'MATIN', labelNoData: "Aucune séance n'est prévue le matin", startHour: 0, endHour: 12 },
    { key: 'apresMidi', label: 'APRES-MIDI', labelNoData: "Aucune séance n'est prévue l'après-midi", startHour: 12, endHour: 20 },
    { key: 'soir', label: 'SOIR', labelNoData: "Aucune séance n'est prévue le soir", startHour: 20, endHour: 23 },
    { key: 'nuit', label: 'NUIT', labelNoData: "Aucune séance n'est prévue la nuit", startHour: 23, endHour: 24 },
  ];

  constructor(
    private svcTraitement: TraitementService,
    private svcPatient: PatientsService,
  ) { }

  ngOnInit() {
    // Initialisation au démarrage du composant
    this.sortSeancesByTimeOfDay();
    this.getSoinRealise();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Appelé chaque fois qu'une des propriétés @Input change
    if (changes['seances']) {
      this.getTraitementIdsAndPatientId();

      this.svcTraitement.getTraitementByIds(this.traitementIdsTab)
        .pipe(
          map(ordonnances => this.createOrdonnanceMap(ordonnances)),
          concatMap(() =>
            this.svcPatient.getPatientsByIds(this.patientIdsTab)
              .pipe(
                map(patients => this.createPatientMap(patients))
              )
          )
        )
        .subscribe(() => {
          this.sortSeancesByTimeOfDay();
          this.getSoinRealise();
        });
    }
  }

  private createOrdonnanceMap(ordonnances: Traitement[]) {
    const ordonnanceMap = new Map<string, Traitement>();
    ordonnances.forEach(ordonnance => {
      ordonnanceMap.set(ordonnance._id, ordonnance); // assuming ordonnance has an id property
    });
    this.ordonnances = ordonnanceMap;
  }

  private createPatientMap(patients: IPatient[]) {
    const patientMap = new Map<string, IPatient>();
    patients.forEach(pat => {
      patientMap.set(pat._id, pat); // assuming ordonnance has an id property
    });
    this.patients = patientMap;
  }

  private getTraitementIdsAndPatientId() {
    const traitementIds = new Set<string>();
    const patientIds = new Set<string>();

    this.seances.forEach(seance => {
      if (seance.traitementId) {
        traitementIds.add(seance.traitementId);
      }

      if (seance.patientId) {
        patientIds.add(seance.patientId);
      }
    });

    if (patientIds.size > 0) {
      this.patientIdsTab = Array.from(patientIds);
    }

    if (traitementIds.size > 0) {
      this.traitementIdsTab = Array.from(traitementIds);
    }
    return Array.from(traitementIds);
  }

  private sortSeancesByTimeOfDay() {
    //TODO : il faut trier pour avoir en premier les séances qui ont des dates
    this.sortedSeances = {}; // Réinitialisation des séances triées
    this.moments.forEach(moment => {
      this.sortedSeances[moment.key] = this.seances.filter(seance => {
        const hour = new Date(seance.startDate).getHours();
        return hour >= moment.startHour && hour < moment.endHour;
      });
    });
  }

  private getSoinRealise() {
    this.nbSoinRealise = this.seances.filter(x => x.status === EStatusSeance.done).length;
    this.getLabelSoinRealise();
  }

  private getLabelSoinRealise() {
    if (this.nbSoinRealise === 0) {
      this.labelSoinRealise = "Aucun soin realisé";
    } else if (this.nbSoinRealise === 1) {
      this.labelSoinRealise = "1 soin realisé";
    } else {
      this.labelSoinRealise = `${this.nbSoinRealise} soins realisés`;
    }
  }
}