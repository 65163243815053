import { Component, OnInit } from '@angular/core';
import { IContact, UserData } from '@osapp/model';
import { ContactsService } from '@osapp/services';
import { Observable, Subscription, of } from 'rxjs';
import { concatMap, mergeMap, reduce, switchMap, tap } from 'rxjs/operators';
import { IAffectation } from '../../../modules/planning-rh/model/IAffectation';
import { IPlanningRH } from '../../../modules/planning-rh/model/IPlanningRH';
import { PlanningRHService } from '../../../modules/planning-rh/services/planning-rh.service';
import { PanneauChoixUserComponent } from '../../features/planning/panneau-choix-user/panneau-choix-user.component';
import { DeviceService } from '../../features/shared/services/device.service';
import { PanneauService } from '../../features/shared/services/panneau.service';

@Component({
  selector: 'di-planning',
  templateUrl: './planning.page.html',
  styleUrls: ['./planning.page.scss'],
})
export class PlanningPage implements OnInit {
  private subscriptions: Subscription[] = [];
  public isMobileView: boolean = false;
  public users: IContact[] = [];
  public userSelected: IContact;
  public showUserName: boolean = false;
  public month1: number;
  public year1: number;
  public month2: number;
  public year2: number;
  public secteur: string = "grp_sectordefault";
  planningCalendar: Map<string, IAffectation[]> = new Map();

  months = [
    "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
    "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
  ];

  constructor(
    private svcDevice: DeviceService,
    private svcPanneau: PanneauService,
		private svcContact: ContactsService,
    private svcPlanningRH: PlanningRHService
  ) { }

  ngOnInit() {
    this.subscriptions.push(this.svcDevice.isMobile$.subscribe((flag: boolean) => {
      this.isMobileView = flag;
    }));
    this.initMonthYear1();
    this.initMonthYear2();
		this.initPlanningMe();
  }
	

  private initMonthYear1() {
    const currentDate = new Date();
    this.month1 = currentDate.getMonth();
    this.year1 = currentDate.getFullYear();
  }

  private initMonthYear2() {
    this.month2 = (this.month1 === 11) ? 0 : this.month1 + 1;
    this.year2 = (this.month1 === 11) ? this.year1 + 1 : this.year1;
  }

  public openpanneauUserSelect() {
    this.svcPanneau.open("Utilisateurs", PanneauChoixUserComponent, { onSelectetUser:this.setContactSelectedList });
  }

	private initPlanningMe() {
		this.svcContact.getContactFromUserId(UserData.current._id).pipe(
			tap((user: IContact) => {
				this.userSelected = user;
				this.showUserName = true;
			}),
			mergeMap(() => this.getCalendarPlanning(this.month1, this.year1, this.month2, this.year2))
		).subscribe((planningCalendar) => {
			this.planningCalendar = planningCalendar;
		});
	}
	

  calendarItemClick = (date: Date, isWorked: boolean, groupId: string ): void => {
    this.updatePlanningGestion(date, isWorked, groupId);
  }

  calendarItemWeekClick = (dates: Date[], isWorked: boolean, groupId: string): void => {
    dates.map((date) => {
      this.updatePlanningGestion(date, isWorked, groupId);
    })
  }

	updatePlanningGestion(date: Date, isWorked: boolean, groupId: string): void {
    this.svcPlanningRH.getPlanning(date).pipe(
			switchMap((planningRH: IPlanningRH) => {
					if (planningRH) {
							this.AddAffectationToPlanning(planningRH, date, groupId, isWorked);
							return of(planningRH);
					} else {
							return this.svcPlanningRH.createPlanning(this.getMondayOfWeek(date)).pipe(
									switchMap((newPlanningRH: IPlanningRH) => {
											this.AddNewAffectation(newPlanningRH, groupId, date);
											return this.svcPlanningRH.updatePlanning(newPlanningRH);
									})
							);
					}
			}),
		).subscribe();
	}


	private AddAffectationToPlanning(planningRH: IPlanningRH, date: Date, groupId: string, isWorked: boolean) {
		const indexOfMatchingDate = planningRH.affectations.findIndex((affectation) => {
			if(affectation.groupId != groupId) return false;
			const dateAffectation = new Date(affectation.date).toLocaleDateString();
			return dateAffectation === date.toLocaleDateString();
		});
		if (isWorked) {
			if (indexOfMatchingDate !== -1) {
				planningRH.affectations[indexOfMatchingDate].contactIds.push(this.userSelected._id);
			} else {
				this.AddNewAffectation(planningRH, groupId, date);
			}
		}

		else {
			planningRH.affectations[indexOfMatchingDate].contactIds = planningRH.affectations[indexOfMatchingDate].contactIds.filter(
				(id) => id !== this.userSelected._id
			);
		}
		this.svcPlanningRH.updatePlanning(planningRH).subscribe();
	}

	private AddNewAffectation(planningRH: IPlanningRH, groupId: string, date: Date) {
		planningRH.affectations.push({
			slotId: "jour",
			groupId: groupId,
			date: date,
			contactIds: [this.userSelected._id]
		});
	}

  public setContactSelectedList = (user: IContact): void => {
    this.userSelected = user;
    this.showUserName = true;
    this.getCalendarPlanning(this.month1, this.year1, this.month2, this.year2).subscribe((planningCalendar) => {
      this.planningCalendar = planningCalendar;
    });
  }

  public previousMonth(): void {
    this.month1 = (this.month1 === 0) ? 11 : this.month1 - 1;
    this.year1 = (this.month1 === 11) ? this.year1 - 1 : this.year1;

    this.month2 = (this.month2 === 0) ? 11 : this.month2 - 1;
    this.year2 = (this.month2 === 11) ? this.year2 - 1 : this.year2;

    this.getCalendarPlanning(this.month1, this.year1, this.month2, this.year2).subscribe((planningCalendar) => {
      this.planningCalendar = planningCalendar;
    });
  }

  public nextMonth(): void {
    this.month1 = (this.month1 === 11) ? 0 : this.month1 + 1;
    this.year1 = (this.month1 === 0) ? this.year1 + 1 : this.year1;

    this.month2 = (this.month2 === 11) ? 0 : this.month2 + 1;
    this.year2 = (this.month2 === 0) ? this.year2 + 1 : this.year2;

    this.getCalendarPlanning(this.month1, this.year1, this.month2, this.year2).subscribe((planningCalendar) => {
      this.planningCalendar = planningCalendar;
    });
  }

  private getCalendarPlanning(moisDebut: number, anneeDebut: number, moisFin: number, anneeFin: number): Observable<Map<string, IAffectation[]>> {
    let dateDebut: Date = new Date(anneeDebut, moisDebut, 1);
    let dateFin: Date = new Date(anneeFin, moisFin + 1, 0);

    return this.svcPlanningRH.getPlannings(dateDebut, dateFin).pipe(
      concatMap((planningRh: IPlanningRH[]) => planningRh),
      reduce((tab: Map<string, IAffectation[]>, planningRh: IPlanningRH) => {
        for (const affectation of planningRh.affectations) {
          if (affectation.contactIds && affectation.contactIds.length > 0) {
            const groupId = affectation.groupId;
            if (this.secteur === groupId) {
              if (!tab.has(groupId)) {
                tab.set(groupId, []);
              }
              tab.get(groupId)!.push(affectation);
            }
          }
        }
        return tab;
      }, new Map<string, IAffectation[]>()),
      tap((planningCalendar: Map<string, IAffectation[]>) => {
      }),
    );
  }

  public getMondayOfWeek(date: Date): Date {
    const currentDate = new Date(date);
    const day = currentDate.getDay();
    const diff = currentDate.getDate() - day + (day === 0 ? -6 : 1);
    const monday = new Date(currentDate.setDate(diff));
    return monday;
  }
}
