<osapp-header-modal title="Export de tournée"></osapp-header-modal>

<ion-content scrollX="true" scrollY="true">
	<ng-container *ngIf="totalPageNumber > 0; then content else nothing">
	</ng-container>
</ion-content>

<!-- FabButton créé que si des séances sont présentes -->
<ion-fab *ngIf="!isvcLoading.isLoaderPresented() && totalPageNumber > 0 && !autoExport" vertical="bottom"
	horizontal="end" slot="fixed">
	<ion-fab-button color="osapp-element" (click)="exportPdf().subscribe()" title="Exporter">
		<ion-icon name="file-pdf"></ion-icon>
	</ion-fab-button>
</ion-fab>

<ng-template #content>

	<ng-container *ngFor="let page of pages | keyvalue">

		<div class="page a4-landscape-mode">
			<ion-grid>
				<!-- En-tête de la page (intervenant, titre, numéro de page) -->
				<ion-row class="ion-justify-content-center ion-align-items-center header-page-separator">
					<ion-col size="3" class="center-title">
						<ion-label class="header-label">{{ page.value.subTitile }}</ion-label>
					</ion-col>
					<ion-col size="6" class="center-title">
						<ion-label class="header-label">{{ page.value.title }}</ion-label>
					</ion-col>
					<ion-col size="3" class="center-title">
						<ion-label class="header-label">page {{ page.key }}/{{ totalPageNumber }}</ion-label>
					</ion-col>
				</ion-row>
				<!-- Séances -->
				<ion-row>
					<!-- Colonne de gauche -->
					<ion-col size="6">
						<!-- En-tête des séances (état, RDV, n°sécu, date fin traitement) -->
						<ng-container *ngTemplateOutlet="headerSeancesGrid"></ng-container>
						<!-- Lignes des différentes séances -->
						<ng-container *ngFor="let item of page.value.leftColumn">
							<!-- Séances -->
							<ion-row class="ion-align-items-center seance-color">
								<!-- état -->
								<ion-col size="1" class="center-title">
									<calao-png-icon [name]="getStatusIcon(item)" width="24" height="24"></calao-png-icon>
								</ion-col>
								<!-- rendez-vous -->
								<ion-col size="4">
									<ion-row>
										<ion-label class="patient-name">
											{{ item.patient.groupMember | patientName }}
										</ion-label>
									</ion-row>
									<ion-row>
										<ion-note class="act-summary">{{ isvcSeances.getResumeActes(item) }}</ion-note>
									</ion-row>
								</ion-col>
								<!-- domicile -->
								<ion-col size="4">
									<ion-label>{{ item.patient.groupMember | contactAddress }}
									</ion-label>
								</ion-col>
								<!-- horaire -->
								<ion-col size="1.2" class="center-title">
									<ion-note>{{ item.seance.scheduled ? (item.seance.startDate | dateWithLocale:'fr-FR':'HH:mm') : '-' }}
									</ion-note>
								</ion-col>
								<!-- date fin traitement -->
								<ion-col size="1.8">
									<ion-note>{{ item.seance.endDate | dateWithLocale:'fr-FR':'dd MMM yyyy' }}</ion-note>
								</ion-col>
							</ion-row>
						</ng-container>
					</ion-col>

					<!-- Colonne de droite -->
					<ion-col size="6">
						<!-- En-tête des séances (état, RDV, n°sécu, date fin traitement) -->
						<ng-container *ngTemplateOutlet="page.value.rightColumn.length > 0 ? headerSeancesGrid : undefined">
						</ng-container>
						<!-- Lignes des différentes séances -->
						<ng-container *ngFor="let item of page.value.rightColumn">
							<!-- Séances -->
							<ion-row class="ion-align-items-center seance-color">
								<!-- état -->
								<ion-col size="1" class="center-title">
									<calao-png-icon [name]="getStatusIcon(item)" width="24" height="24"></calao-png-icon>
								</ion-col>
								<!-- rendez-vous -->
								<ion-col size="4">
									<ion-row>
										<ion-label class="patient-name">
											{{ item.patient.groupMember | patientName }}
										</ion-label>
									</ion-row>
									<ion-row>
										<ion-note class="act-summary">{{ isvcSeances.getResumeActes(item) }}</ion-note>
									</ion-row>
								</ion-col>
								<!-- domicile -->
								<ion-col size="4">
									<ion-label>{{ item.patient.groupMember | contactAddress }}
									</ion-label>
								</ion-col>
								<!-- horaire -->
								<ion-col size="1.2" class="center-title">
									<ion-note>{{ item.seance.scheduled ? (item.seance.startDate | dateWithLocale:'fr-FR':'HH:mm') : '-' }}
									</ion-note>
								</ion-col>
								<!-- date fin traitement -->
								<ion-col size="1.8">
									<ion-note>{{ item.seance.endDate | dateWithLocale:'fr-FR':'dd MMM yyyy' }}</ion-note>
								</ion-col>
							</ion-row>
						</ng-container>
					</ion-col>
				</ion-row>
			</ion-grid>
		</div>

	</ng-container>

</ng-template>

<ng-template #nothing>
	<div class="nothing">
		Aucune tournée de prévue.
	</div>
</ng-template>

<!-- Template d'en-tête des séances (état, RDV, n°sécu, date fin traitement) -->
<ng-template #headerSeancesGrid>
	<ion-row class="ion-align-items-center">
		<ion-col size="1" class="center-title">
			<ion-label>État</ion-label>
		</ion-col>
		<ion-col size="4">
			<ion-label>Rendez-vous</ion-label>
		</ion-col>
		<ion-col size="4">
			<ion-label>Domicile</ion-label>
		</ion-col>
		<ion-col size="1.2" class="center-title">
			<ion-label>Horaire</ion-label>
		</ion-col>
		<ion-col size="1.8">
			<ion-label>Fin traitement</ion-label>
		</ion-col>
	</ion-row>
	<div class="header-grid-separator"></div>
</ng-template>