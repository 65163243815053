import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransmissionService {
	
	private deleteTransmissionAction = new Subject<void>();

	deletion$ = this.deleteTransmissionAction.asObservable();

	emitDeletion() {
		this.deleteTransmissionAction.next();
	}
}