<div class="canva-area">
	<ion-item *ngIf="showUndoButton || showClearButton" lines="none">
		<ion-button *ngIf="showUndoButton" [disabled]="isEmptyBeforeTracing" fill="clear" slot="end" (click)="undo()">
			<ion-icon slot="icon-only" name="arrow-undo-circle-outline"></ion-icon>
		</ion-button>
		<ion-button *ngIf="showClearButton" [disabled]="isEmptyBeforeTracing" fill="clear" slot="end" (click)="clear()">
			<ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
		</ion-button>
	</ion-item>

	<!--
		Dessin en mode edit -> 'edit-canvas'.
		Dessin en mode lecture seule et pas de base64 à afficher -> 'hide-canvas'.
		Dessin en mode lecture seule et base64 à afficher -> pas de classe css supplémentaire à appliquer.
	-->
	<canvas #signaturePadCanvas *ngIf="!readonly"
		[class]="'signature-pad-canvas ' + (!readonly ? 'edit-canvas' : !base64Data ? 'hide-canvas' : '')" width="inherit"
		height="250"></canvas>
	<img *ngIf="readonly && base64Data" [src]="base64Data.base64" />

	<!-- Si mode lecture seule et pas de base64 à afficher et texte à afficher, on fait afficher le texte. -->
	<div *ngIf="readonly && !base64Data && missingDrawingText" class="missing-drawing-text">
		<ion-label>{{ missingDrawingText }}</ion-label>
	</div>
</div>