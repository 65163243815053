import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { map, mapTo, mergeMap } from 'rxjs/operators';
import { IAuthStatus } from '../model/security/IAuthStatus';
import { EPermissionsFlag } from '../modules/permissions/models/EPermissionsFlag';
import { ContactsService } from '../services/contacts.service';
import { FlagService } from '../services/flag.service';
import { SecurityService } from '../services/security.service';

@Injectable({ providedIn: "root" })
export class AuthenticatedGuard implements CanActivate {

	//#region METHODS

	constructor(
		private readonly isvcSecurity: SecurityService,
		private readonly isvcFlag: FlagService,
		private readonly ioMenu: MenuController
	) { }

	/** Garde du routage. */
	public canActivate(poRoute: ActivatedRouteSnapshot, poState: RouterStateSnapshot): Observable<boolean> {
		return this.isvcSecurity.authenticationStatus$
			.pipe(
				map((poAuthStatus: IAuthStatus) => {
					const lbCanActivate: boolean = poAuthStatus.isAuthenticated && !poAuthStatus.isGuest;
					console.debug("AUTH.G:: canActivate", lbCanActivate);

					if (!lbCanActivate)
						this.isvcSecurity.requestAuthentication(poState.url);
					else if (poState.url === `/contacts/${ContactsService.C_CURRENT_USER_ID_FOR_ROUTE}/edit`)
						this.ioMenu.enable(false);
					else
						this.ioMenu.enable(true);

					return lbCanActivate;
				}),
				mergeMap((pbResult: boolean) => {
					if (!pbResult) // Si on ne peut pas passer, on retourne le résultat tel quel.
						return of(pbResult);
					else {
						// Si on peut passer, on s'assure ques les bases de données et les permissions soient initialisées avant de poursuivre.
						// Les permissions sont initialisées après que les bases de données le soient.
						return this.isvcFlag.waitForFlag(EPermissionsFlag.isLoaded, true).pipe(mapTo(pbResult));
					}
				})
			);
	}

	//#endregion

}