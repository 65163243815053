/**
 * Composant de démarrage de l'application
 * Initialisation de la navigation et du menu
 */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { AlertButton } from '@ionic/core';
import { MenuService } from '@osapp/components/menu/menu.service';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { IPopoverItem } from '@osapp/model/IPopoverItem';
import { IAppStartPauseLogData } from '@osapp/model/application/iapp-start-pause-log-data';
import { ConfigData } from '@osapp/model/config/ConfigData';
import { IConfig } from '@osapp/model/config/IConfig';
import { NotSupportedPlatformError } from '@osapp/model/errors/NotSupportedPlatformError';
import { ELinkAction } from '@osapp/model/link/ELinkAction';
import { ELinkTemplate } from '@osapp/model/link/ELinkTemplate';
import { IMailOptions } from '@osapp/model/mail/IMailOptions';
import { ActivePageManager } from '@osapp/model/navigation/ActivePageManager';
import { DeviceNotAuthorizedError } from '@osapp/model/store/error/device-not-authorized-error';
import { BatteryService } from '@osapp/modules/battery/services/battery.service';
import { ConflictsService } from '@osapp/modules/conflicts/services/conflicts.service';
import { DeeplinkService } from '@osapp/modules/deeplink/deeplink.service';
import { EFlag } from '@osapp/modules/flags/models/EFlag';
import { Loader } from '@osapp/modules/loading/Loader';
import { ELogActionId } from '@osapp/modules/logger/models/ELogActionId';
import { LoggerService } from '@osapp/modules/logger/services/logger.service';
import { PatchService } from '@osapp/modules/patch/services/patch.service';
import { PwaUpdateService } from '@osapp/modules/pwa/services/pwa-update.service';
import { AppComponentBase } from '@osapp/modules/utils/components/app-component-base';
import { ApplicationService } from '@osapp/services/application.service';
import { ConfigService } from '@osapp/services/config.service';
import { EntityLinkService } from '@osapp/services/entityLink.service';
import { FlagService } from '@osapp/services/flag.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '@osapp/services/loading.service';
import { MailService } from '@osapp/services/mail.service';
import { PageManagerService } from '@osapp/services/pageManager.service';
import { PlatformService } from '@osapp/services/platform.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { EMPTY, Observable } from 'rxjs';
import { catchError, finalize, mergeMap, tap } from 'rxjs/operators';
import packageJson from '../../package.json';
import * as constants from '../config';
import { IdlComponentRegister } from '../model/IdlComponentRegister';
import { ActesService } from '../modules/actes/actes.service';
import { RapportService } from '../modules/patients/services/rapport.service';
import { TraitementService } from '../services/traitement.service';

@Component({
	selector: "ion-app",
	templateUrl: 'app.component.html'
})
export class AppComponent extends AppComponentBase implements OnInit {

	//#region FIELDS

	private moHomeActivePageManager: ActivePageManager;
	private moTraitementsActivePageManager: ActivePageManager;

	//#endregion

	//#region PROPERTIES

	/** Clé du menu sur le côté. */
	public readonly menuKey = "sideMenu";
	public readonly menuKeyCutomUser = "sideMenuCustomUser";
	/** Titre du menu, si vide, pas de toolbar. */
	public readonly menuTitle = "DESMOS Infirmiers";

	public readonly logSourceId = "IDL.APP.C::";

	//#endregion

	//#region METHODS

	constructor(
		private isvcLoading: LoadingService,
		private isvcConfig: ConfigService,
		private isvcMail: MailService,
		private isvcMenu: MenuService,
		/** Service de menu angular. */
		private ioMenu: MenuController,
		private isvcUiMessage: UiMessageService,
		private isvcDeeplink: DeeplinkService,
		private isvcEntityLink: EntityLinkService,
		private isvcActes: ActesService,
		private isvcLogger: LoggerService,
		private readonly isvcPatch: PatchService,
		private readonly isvcFlag: FlagService,
		private readonly isvcPlatform: PlatformService,
		private readonly isvcPwaUpdate: PwaUpdateService,
		psvcBattery: BatteryService,
		psvcApplication: ApplicationService,
		psvcRapport: RapportService,
		psvcTraitement: TraitementService,
		psvcPageManager: PageManagerService,
		poRouter: Router,
		psvcConflicts: ConflictsService
	) {
		super(psvcApplication, isvcPlatform, psvcBattery);
		this.isvcDeeplink.init();
		psvcPageManager.addComponents(IdlComponentRegister.getIdlRouteComponents());
		psvcRapport.initNumberOfNonCompletedTransmission();
		psvcTraitement.initNumberOfTraitEndingSoon();
		psvcConflicts.initConflictsLogging();

		this.moHomeActivePageManager = new ActivePageManager(this, poRouter, (psNewUrl: string, psPageUrl: string) => psNewUrl === "/home");
		this.moTraitementsActivePageManager = new ActivePageManager(this, poRouter, (psNewUrl: string, psPageUrl: string) => psNewUrl === "/traitements");
	}

	public ngOnInit(): void {
		let loLoader: Loader;
		console.log("IDL.APP.C:: En attente d'initialisation de la plateforme...");

		this.ioMenu.swipeGesture(true);
		this.ioMenu.enable(false);

		constants.environment.version = packageJson.version;
		this.isvcPatch.applyPatches() // Application des patchs.
			.pipe(
				mergeMap(_ => this.isvcLoading.create("Démarrage de l'application ...")),
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(_ => this.isvcConfig.init(constants as IConfig)),
				tap(
					_ => {
						this.isvcLogger.action(this.logSourceId, "Démarrage de l'application.", ELogActionId.appStart, { version: ConfigData.appInfo.appVersion, environment: ConfigData.environment.id } as IAppStartPauseLogData);
						this.initPopovers();
						loLoader.dismiss();
						this.isvcFlag.setFlagValue(EFlag.appInitialized, true);
						console.log("IDL.APP.C:: Application initialized");
					},
					poError => this.initError(poError)
				),
				mergeMap(() => this.initRouteSubscriptions()),
				finalize(() => {
					loLoader.dismiss();
				})
			)
			.subscribe();

		this.isvcPwaUpdate.initUpdateCheck();
	}

	/** Envoie à la lib les popovers qui sont disponible pour l'application Idl. */
	private initPopovers(): void {
		const laPopoverItems: Array<IPopoverItem> = [
			{
				id: "changePassword",
				label: "Changer de mot de passe",
				isPinned: true,
				templateId: ELinkTemplate.itemNoLine,
				action: ELinkAction.navigate,
				actionParams: [
					"password",
					"change-password"
				]
			}
		];

		if (!this.isvcPlatform.isIOS || !this.isvcPlatform.isMobile) {
			laPopoverItems.push({
				id: "remarks",
				label: "Vos remarques",
				isPinned: true,
				templateId: ELinkTemplate.itemNoLine,
				action: ELinkAction.callback,
				actionParams: {
					function: () => this.sendFeedback()
				}
			});
		}

		const traitementsPopoverItem: IPopoverItem = {
			id: "custom-ngap",
			label: "Éditer les actes NGAP",
			icon: "pencil",
			cssClass: "primary",
			isPinned: false,
			templateId: ELinkTemplate.itemNoLine,
			action: ELinkAction.callback,
			actionParams: {
				function: () => this.isvcActes.openActesListModal().subscribe()
			}
		};

		this.moTraitementsActivePageManager.isActive$.pipe(
			tap((pbIsActive: boolean) => {
				const laCustomPopoverItems = Array.from(laPopoverItems);
				if (pbIsActive)
					laCustomPopoverItems.unshift(traitementsPopoverItem);
				this.isvcMenu.clearPopover();
				this.isvcMenu.setCustomPopoverItem(laCustomPopoverItems);
			})
		)
			.subscribe();
	}

	private sendFeedback(): void {
		this.isvcMail.sendMail(
			`Remarque au sujet de ${ConfigData.appInfo.appName}`,
			`Bonjour,\n\nJe souhaiterais vous faire part de mes remarques sur ${ConfigData.appInfo.appName}.\n\nCordialement.`,
			{
				needScreenshot: true,
				needLogFile: true,
				to: [ConfigData.appInfo.supportEmail]
			} as IMailOptions
		)
			.pipe(
				catchError(poError => {
					if (poError instanceof NotSupportedPlatformError) {
						this.isvcUiMessage.showMessage(
							new ShowMessageParamsPopup({ message: "L'envoi de mail n'est pas disponible sur cette plateforme.\nVeuillez contacter le support.", header: "Erreur" })
						);
					}
					else
						console.error("IDL.APP.C:: Erreur envoi feedback : ", poError);

					return EMPTY;
				})
			)
			.subscribe();
	}

	private initError(poError: string | DeviceNotAuthorizedError | any): void {
		console.error(`IDL.APP.C:: Application initialization error.`, poError);

		//! Nécessaire afin de supprimer le loader : si utilisation de l'instance du loader ou si cette ligne de code est appelée dans le finalize() alors le loader restera.
		this.isvcLoading.dismiss();

		/** Message d'erreur à afficher à l'utilisateur. */
		let lsMessage: string;

		// Si l'erreur reçu est de type `string`, elle est générée par OSApp, on l'affiche directement à l'utilisateur.
		if (typeof poError === "string")
			lsMessage = poError;
		// Si c'est un type d'erreur géré par OSApp, on l'affiche.
		else if (poError instanceof DeviceNotAuthorizedError)
			lsMessage = poError.message;
		else	// Type d'erreur non géré.
			lsMessage = "Une erreur critique s'est produite. Veuillez contacter le support technique.";

		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({
				message: lsMessage,
				header: "Erreur",
				buttons: [
					{ text: "Quitter", handler: () => ApplicationService.exitApp() } as AlertButton,
					{ text: "Réessayer", handler: () => { console.warn("IDL.APP.C:: Application will be reloaded."); ApplicationService.reloadApp(); } } as AlertButton
				]
			})
		);
	}

	private initRouteSubscriptions(): Observable<boolean> {
		return this.moHomeActivePageManager.isActive$.pipe(
			mergeMap((pbIsActive: boolean) => {
				if (pbIsActive && !ObjectHelper.isNullOrEmpty(this.isvcEntityLink.currentEntity))
					return this.isvcEntityLink.clearCurrentEntity(this.isvcEntityLink.currentEntity.id);
				return EMPTY;
			})
		);
	}

	//#endregion
}
