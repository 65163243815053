import { Component, Input, OnDestroy, OnInit } from "@angular/core";

import { FormBuilder, FormGroup } from "@angular/forms";
import { NumberHelper } from "@osapp/helpers";
import { UserData } from "@osapp/model";
import { DestroyableComponentBase } from "@osapp/modules/utils/components/destroyable-component-base";
import { ContactsService } from "@osapp/services";
import { takeUntil, tap } from "rxjs/operators";
import { Acte } from "../../../../../model/Acte";
import { EMajorationType } from "../../../../../model/EMajorationType";
import { ESecteur } from "../../../../../model/ESecteur";
import { EStatusSeance } from "../../../../../model/EStatusSeance";
import { IActeDocumentByLc } from "../../../../../model/IActeDocumentByLc";
import { IDeplacementByProfession } from "../../../../../model/IDeplacementByProfession";
import { Majoration } from "../../../../../model/Majoration";
import { Deplacement } from "../../../../../modules/traitement/model/Deplacement";
import { EIndemniteType } from "../../../../../modules/traitement/model/EIndemniteType";
import { Indemnite } from "../../../../../modules/traitement/model/Indemnite";
import { IdlApplicationService } from "../../../../../services/idlApplicationService.service";
import { IndemniteService } from "../../../../../services/indemnite.service";
import { StoredSeance } from "../../../../models/StoredSeance";
import { EEtatActe } from "../../enums/EEtatActe";
import { DeviceService } from "../../services/device.service";
import { SeancesGeneratorService } from "../../services/seances-generator.service";

@Component({
	selector: "di-soins-realises",
	templateUrl: "./soins-realises.component.html",
	styleUrls: ["./soins-realises.component.scss"],
})
export class SoinsRealisesComponent
	extends DestroyableComponentBase
	implements OnInit, OnDestroy
{
	@Input() public seance?: StoredSeance;
	@Input() index?: number;
	@Input() confirmSoins: (seance : StoredSeance) => void;
	@Input() deleteConfirmSoins: (seance : StoredSeance) => void;
	@Input() cancel: () => void;
	public horairePrecise: string = "";
  public total:number;
	showAllMajorations = true;
	checkedIndexes: number[] = [];
	public isMobile: boolean = false;
	public selectedActes: Acte[] = [];
	public selectedMajos: Majoration[] = [];
	public selectedDeplaces: Indemnite[] = [];
	public showAllDeplacement = true;
	public showIkConfig = false;
	public ikForm: FormGroup;
	public abattement : number = 0;
	public deplacement :Deplacement;
	public selectedOption : string = 'IK';
	public majoList : Majoration[] = [];
	public indemList: Indemnite[] = [];
	public IKSelected : Indemnite;
	public deplacementByLC: IDeplacementByProfession[];

	public isIK : boolean = false;


	constructor(
		private svcDevice: DeviceService,
		private svcGenerateSeance : SeancesGeneratorService,
		private svcIndemnite : IndemniteService,
		private fb: FormBuilder,
		private svcApplication: IdlApplicationService

	) {
		super();
	}

	ngOnInit() {
		this.svcDevice.isMobile$
			.pipe(takeUntil(this.destroyed$))
			.subscribe((flag: boolean) => {
				this.isMobile = flag;
			});
			this.ikForm = this.fb.group({
				distance: [0 ?? 0],
				montant: [0 ?? 0],
				heure: [this.horairePrecise],
			});		
			const dateSeance : Date = new Date(this.seance.startDate);
			const hours = dateSeance.getHours().toString().padStart(2, '0');
			const minutes = dateSeance.getMinutes().toString().padStart(2, '0');
			this.horairePrecise = `${hours}:${minutes}`;
			this.deplacement = new Deplacement();
			this.deplacement.sectorType = ESecteur.Plaine;
			this.deplacement.isManualPrice = false
			this.initListMajo();
			this.IKSelected = new Indemnite(
				"IK-1-1",
				this.svcIndemnite.getIndemniteTypeFromId("IK-1-1"),
				'',
				'',);
			this.initListIndem();
			if(this.seance.status == EStatusSeance.done)
			{
				this.selectedActes = [...this.seance.actes];
				this.selectedMajos = [...this.seance.majorations];
				this.selectedDeplaces = [...this.seance.indemnites];
				const ikIndemnite = this.seance.indemnites.find(deplace => 
					deplace.type === EIndemniteType.IK || 
					deplace.type === EIndemniteType.IKM || 
					deplace.type === EIndemniteType.IKS
				);
				if (ikIndemnite) {
						this.isIK = true;
						this.IKSelected = ikIndemnite;
						this.showIkConfig = true;
						this.IKSelected = ikIndemnite;
						this.ikForm.get('distance')?.setValue(this.IKSelected.distance);
						this.selectedOption = this.IKSelected.type;
						this.ikForm.get('montant')?.setValue(NumberHelper.round(this.IKSelected.price,2));


				}
			}
			this.calculateTotal();
	}

	public isSelectedInListIndem(indem :Indemnite)
	{
		return this.selectedDeplaces.find(deplacement => deplacement.type == indem.type)
	}

	public isSelectedInListMajo(majo :Majoration)
	{
		return this.selectedMajos.find(majoration => majoration.type == majo.type)
	}
	

	selectOption(option: string) {
		this.selectedDeplaces =  this.selectedDeplaces.filter(indem => {
			indem.type == this.IKSelected.type
		});
		this.IKSelected.type = this.getTypeIndemSelected(option);
    this.selectedOption = option;
		this.deplacement.sectorType = this.getSecteurSelected(option);
		this.abattement = this.svcIndemnite.calculateAbattementDistance(this.deplacement);
		this.IKSelected.id = "IK-"+this.svcApplication.profession+"-"+this.deplacement.sectorType;
		this.deplacement.sectorPrice = this.svcIndemnite.getDeplacementTarif(
			this.deplacementByLC.find(deplacement => deplacement.id === "IK-"+this.svcApplication.profession+"-"+this.deplacement.sectorType)
		);
		this.svcIndemnite.setIndemnitePrice(this.deplacement,this.IKSelected,this.deplacementByLC);
		this.ikForm.get('montant')?.setValue(NumberHelper.round(this.IKSelected.price,2));
		this.selectedDeplaces.push(this.IKSelected);
		this.calculateTotal();

  }
	getTypeIndemSelected(option: string): EIndemniteType {
		switch(option)
		{
			case 'IK' :
				return EIndemniteType.IK;
			case 'IKM' :
				return EIndemniteType.IKM;
			case 'IKS' :
				return EIndemniteType.IKS;
			default:
				return EIndemniteType.IK;
		}	
	}

	getSecteurSelected(option: string): ESecteur {
		switch(option)
		{
			case 'IK' :
					return ESecteur.Plaine;
			case 'IKM' :
				return ESecteur.Montagne;
			case 'IKS' :
				return ESecteur.PiedOuSki;
			default:
				return ESecteur.Plaine;
		}
	}



	public onInputChange(distance:number){
		this.selectedDeplaces =  this.selectedDeplaces.filter(indem => {
			indem.type == this.IKSelected.type
		});		
		this.deplacement.distance = distance;
		this.IKSelected.distance = distance;
		this.IKSelected.label = `(${distance}km)`;
		this.abattement = this.svcIndemnite.calculateAbattementDistance(this.deplacement);
		this.deplacement.sectorPrice = this.svcIndemnite.getDeplacementTarif(
			this.deplacementByLC.find(deplacement => deplacement.id === "IK-"+this.svcApplication.profession+"-"+this.deplacement.sectorType)
		);
		this.svcIndemnite.setIndemnitePrice(this.deplacement,this.IKSelected,this.deplacementByLC);
		this.ikForm.get('montant')?.setValue(NumberHelper.round(this.IKSelected.price,2));
		this.selectedDeplaces.push(this.IKSelected);
		this.calculateTotal();

	}

	private initListMajo(): void {
    this.svcGenerateSeance.getMajorations().pipe(
        tap((majorations: IActeDocumentByLc[]) => {
					majorations.forEach((majo: IActeDocumentByLc) => {
							const type : EMajorationType = this.svcGenerateSeance.getMajorationTypeFromId(majo.id);
							const loMajoration: Majoration = new Majoration(
									majo.id,
									type,
									this.svcGenerateSeance.getMajorationPriceFromArray([majo], type),
									this.svcGenerateSeance.getMajorationDescriptionFromArray([majo], type)
							);
							this.majoList.push(loMajoration);
					});
        })
    ).subscribe();
			
		//TODO A voir pour l'auto validation des majoration pour une seance
		// return defer(() => paMajorations ? of(paMajorations) : this.getMajorations())
		// 	.pipe(
		// 		tap((paResultMajorations: IActeDocumentByLc[]) => {
		// 			this.majorationHolydays(laSeances, paResultMajorations);
		// 			this.majorationMci(laSeances, paResultMajorations, poTraitement.pathologies);
		// 			this.majorationMau(laSeances, paResultMajorations);
		// 			this.applyNightMajorations(laSeances, paResultMajorations);
		// 			this.applyMajorationMie(laSeances, poPatient, paResultMajorations);
		// 			this.applyTraitementMajorations(laSeances, poTraitement, paResultMajorations);
		// 			this.disableExcludeMajorations(laSeances);
		// 		}),
		// 		mapTo(poData)
		// 	);
	}

	private initListIndem(): void {

		this.svcIndemnite.getDeplacementsByProfession(this.svcApplication.profession).pipe(
			tap((deplacements: IDeplacementByProfession[]) => {
				this.deplacementByLC = deplacements;
				deplacements.forEach((deplacement: IDeplacementByProfession) => {
					if(!deplacement.lettreCle.includes(EIndemniteType.IK)){
						const type : EIndemniteType = EIndemniteType.IFD;
						const loIndemnite = new Indemnite(
							deplacement.id,
							this.svcIndemnite.getIndemniteTypeFromId(deplacement.id),
							deplacement.description,
							'',
							this.svcIndemnite.getDeplacementTarif(deplacement,this.seance.startDate)
							);
						this.indemList.push(loIndemnite);
					}
				});
			})
    ).subscribe();
	}

	toggleIKSelected()
	{
		if(this.showIkConfig)
		{
			this.selectedDeplaces =  this.selectedDeplaces.filter(indem => {
				indem.type == this.IKSelected.type
			} )
		}
		this.showIkConfig = !this.showIkConfig;
		this.calculateTotal();
	}


	toggleMajorations() {
		this.showAllMajorations = !this.showAllMajorations;
	}

	toggleCheckMajoration(event: Event,majo : Majoration) {
		if (this.selectedMajos?.some(a => a.id === majo.id)) {
		  	this.selectedMajos = this.selectedMajos.filter(a => a.id !== majo.id);
		} else {
		  this.selectedMajos.push(majo);
		}
		this.calculateTotal();
	}
	
	toggleDEplacement() {
		this.showAllDeplacement = !this.showAllDeplacement;
	}

	toggleCheckDeplacement(event: Event,depla : Indemnite) {
		if (this.selectedDeplaces?.some(a => a.id === depla.id)) {
			this.selectedDeplaces = this.selectedDeplaces.filter(a => a.id !== depla.id);
	} else {
		this.selectedDeplaces.push(depla);
	}
		this.calculateTotal();
	}

	getSelectedItems() {
		return this.checkedIndexes.map((index) => this.seance.majorations[index]);
	}

	private majSeance()
	{
		this.seance.actes.map(acte => {
			if(this.selectedActes.includes(acte))
				acte.etat = EEtatActe.done;
			else
				acte.etat = EEtatActe.not_done
		})
		const heureRealisation = this.ikForm.get('heure')?.value;
		if (heureRealisation) {
				const [hours, minutes] = heureRealisation.split(':').map(Number);
				const dateSeance = new Date(this.seance.startDate);
				dateSeance.setHours(hours, minutes)
				this.seance.startDate = dateSeance;
		}
		this.seance.majorations = this.selectedMajos;
		this.seance.indemnites = this.selectedDeplaces;
		if(!this.seance.infirmierId)
			this.seance.infirmierId = ContactsService.getContactIdFromUserId(UserData.current._id);
	}

	private majSeanceDelete()
	{
		this.seance.actes.map(acte => {
				acte.etat = EEtatActe.to_be_done
		})
		this.seance.majorations = [];
		this.seance.indemnites = [];
	}

	handleClickConfirm() {
		this.majSeance();
		this.seance.statusChangeDate = new Date();
		this.seance.status = EStatusSeance.done;
		if(this.confirmSoins)
			this.confirmSoins(this.seance)	
		}

	handleClickDelete() {
		this.majSeanceDelete();
		delete this.seance.statusChangeDate;
		this.seance.status = EStatusSeance.to_be_done;
		if(this.deleteConfirmSoins)
			this.deleteConfirmSoins(this.seance);	
	}

	handleClickAnnule() {
		if(this.cancel)
			this.cancel();
	}

	handleCheckboxChange(event: Event, acte: Acte) {
		if (this.selectedActes?.some(a => a.guid === acte.guid)) {
		  this.selectedActes = this.selectedActes.filter(a => a.guid !== acte.guid);
		} else {
		  this.selectedActes.push(acte);
		}
		this.calculateTotal();
	}

	calculateTotal() {
		const acteTotal = this.selectedActes.reduce((sum, acte) => sum + (acte?.price || 0), 0);
		const majorationTotal = this.selectedMajos.reduce((sum, majo) => sum + (majo?.price || 0), 0);
		const indemnityTotal = this.selectedDeplaces.reduce((sum, indem) => sum + (indem?.price || 0), 0);
		
		this.total = acteTotal + majorationTotal + indemnityTotal;
	}
}
