import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { EDatabaseRole, EFormEventType, IDataSource } from '@osapp/model';
import { Loader } from '@osapp/modules/loading/Loader';
import { ShowMessageParamsToast, Store, UiMessageService } from '@osapp/services';
import { FormsService } from '@osapp/services/forms.service';
import { LoadingService } from '@osapp/services/loading.service';
import { SlideboxService } from '@osapp/services/slidebox.service';
import { defer, from, of } from 'rxjs';
import { filter, finalize, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { C_PREFIX_AMOP } from '../../../../../app/app.constants';
import { TerminalInfoModalOpenerService } from '../../../../olaqin/components/terminal-info-modal/services/terminal-info-modal-opener.service';
import { ITerminalInfo } from '../../../../olaqin/models/iterminal-info';
import { OlaqinService } from '../../../../olaqin/services/olaqin.service';
import { IPatient } from '../../../model/IPatient';
import { IdlActionButtonFieldBase } from '../../../model/action-buttons/IdlActionButtonFieldBase';
import { EPatientDataSource } from '../../../model/epatient-data-source.enum';
import { IAMOP } from '../../../model/iamo-p';
import { CouverturesService } from '../../../services/couvertures.service';
import { ExportService } from '../../../services/export.service';
import { IdlActionButtonService } from '../../../services/idl-action-button.service';
import { PatientsService } from '../../../services/patients.service';

@Component({
	selector: 'idl-action-button-lecture-cv',
	templateUrl: '../idl-action-button-field.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdlActionButtonLectureCVComponent extends IdlActionButtonFieldBase<IPatient> implements OnInit {

	constructor(
		private readonly isvcOlaqin: OlaqinService,
		private readonly isvcPatients: PatientsService,
		private readonly isvcExport: ExportService,
		private readonly isvcUiMessage : UiMessageService,
		private readonly isvcTerminalInfoModalOpener: TerminalInfoModalOpenerService,
		private readonly isvcLoading: LoadingService,
		private readonly isvcStore : Store,
		private readonly isvcCouvertures : CouverturesService,
		psvcSlidebox: SlideboxService,
		psvcActionButton: IdlActionButtonService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcSlidebox, psvcActionButton, psvcForms, poChangeDetectorRef);

	}

	public ngOnInit(): void {
		super.ngOnInit();

		this.isvcForms.waitFormEvent(this.model._id, EFormEventType.afterSubmit)
			.pipe(
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();
	}

	public action(): void {
    let loLoader: Loader;
    let lsTerminalId: string;
    defer(() => {
			return this.isvcTerminalInfoModalOpener.open().pipe(
					filter((poTerminalInfo: ITerminalInfo) => !!poTerminalInfo),
					tap((poTerminalInfo: ITerminalInfo) => lsTerminalId = poTerminalInfo.terminalId),
					mergeMap(() => this.isvcOlaqin.readVitalCard(lsTerminalId)),
					mergeMap((paPatients: IPatient[]) => {
							const laPatients: IPatient[] = this.isvcPatients.searchPatient(paPatients, {
									...ObjectHelper.pick(this.model, ["birthDate", "firstName", "lastName", "socialNumber"]),
									fullMatch: !!this.model.updateDate
							});
	
							if (this.model.updateDate && laPatients.length === 1)
									return of(ArrayHelper.getFirstElement(laPatients));
	
							return this.isvcOlaqin.selectBeneficiaire(paPatients);
					}),
					map((poPatient: IPatient) => this.isvcPatients.mergePatientsData(this.model, poPatient)),
					mergeMap((poPatient: IPatient) => from(this.isvcLoading.create(`Sauvegarde du patient ${this.isvcPatients.formatPatientName(this.model)}`)).pipe(
							mergeMap((poLoader: Loader) => (loLoader = poLoader).present()),
							mergeMap(() => {
									if (StringHelper.isBlank(poPatient.externalId))
											return this.isvcPatients.exportPatientXml(poPatient, poPatient.carteVitaleXML);
									else
											return of(poPatient);
							}),
							tap((poUpdatedPatient: IPatient) => poPatient = poUpdatedPatient),
							mergeMap(() => this.isvcPatients.savePatient(poPatient)),
							mergeMap(() => this.isvcOlaqin.updatePatientWithADRiOrVitale(poPatient, lsTerminalId, EPatientDataSource.vitale)),
							finalize(() => loLoader?.dismiss())
					)),
					mergeMap(() => {
							const loAMOPDataSource: IDataSource = this.isvcCouvertures.getCouvertureDataSource(this.model._id, C_PREFIX_AMOP, false);
							return this.isvcStore.get<IAMOP>(loAMOPDataSource).pipe(
									mergeMap(async (laCouvertures: IAMOP[]) => {
											await this.isvcStore.syncDocumentsToLocal(
													ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace)),
													laCouvertures
											);
									})
							);
					}),
					tap(() => {
							this.isvcUiMessage.showMessage(
									new ShowMessageParamsToast({ message: "Le patient a bien été mis à jour." })
							);
					}),
					takeUntil(this.fieldDestroyed$),
					finalize(() => loLoader?.dismiss()),
			);
		}).subscribe();
	}
}
