import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "di-loader",
	templateUrl: "./loader.component.html",
	styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnInit {
	@Input() size = 50;
	@Input() color = "CouleurPrimaire";
	@Input() loaderText = "Chargement...";

	constructor() {}

	public ngOnInit(): void {}
}
