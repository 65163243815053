import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldBase } from '../../../../model/forms/FieldBase';
import { FormsService } from '../../../../services/forms.service';

/** Champs contenant un slider. */
@Component({
	templateUrl: './sliderField.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderFieldComponent extends FieldBase implements OnInit { //todo : typer + params.

	//#region METHODS

	constructor(psvcForms: FormsService) {
		super(psvcForms);
	}

	/** Endroit où initialiser le composant après sa création. */
	public ngOnInit(): void {
		super.ngOnInit();

		if (!this.fieldValue) {

			if (this.to.data) {

				if (this.to.data.range) {
					this.fieldValue = this.to.data.minValue && this.to.data.maxValue ?
						{ lower: this.to.data.minValue, upper: this.to.data.maxValue } : { lower: 0, upper: 10 };
				}
				else
					this.fieldValue = this.to.data.minValue ? this.to.data.minValue : 0;
			}
			else
				this.fieldValue = 0;
		}
	}

	public onModelChanged(): void {
		this.markAsDirty();
	}

	//#endregion
}