import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { DateTimePickerComponent } from '../../../../components/date/dateTimePicker.component';
import { DateHelper } from '../../../../helpers/dateHelper';
import { EDateTimePickerMode } from '../../../../model/date/EDateTimePickerMode';
import { ETimetablePattern } from '../../../../model/date/ETimetablePattern';
import { IDateTimePickerParams } from '../../../../model/date/IDateTimePickerParams';
import { DateWithLocalePipe } from '../../../../pipes/dateWithLocale.pipe';
import { FilterbarBaseComponent } from '../../model/FilterbarBaseComponent';

/** Composant pour afficher un filtrage avec des avatars. */
@Component({
	selector: 'osapp-filterbar-datepicker',
	templateUrl: './filterbar-datepicker.component.html',
	styleUrls: ['../filterbar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterbarDatepickerComponent<T = Date | string | number, U = string> extends FilterbarBaseComponent<T, U> implements OnDestroy {

	//#region FIELDS

	/** Sujet de la date sélectionnée. */
	private readonly moDateSubject = new ReplaySubject<T>(1);

	//#endregion

	//#region PROPERTIES

	@ViewChild("datepicker") public readonly datepickerComponent: DateTimePickerComponent;

	/** Paramètres pour les filtres de date. */
	public datepickerParams: IDateTimePickerParams = {
		pickerMode: EDateTimePickerMode.date,
		displayFormat: ETimetablePattern.dd_MM_yyyy_slash,
		hideIcon: true
	};

	//#endregion

	//#region METHODS

	constructor(private ioDateWithLocalePipe: DateWithLocalePipe, poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	/** @implements */
	public ngOnDestroy(): void {
		this.moDateSubject.complete();
		super.ngOnDestroy();
	}

	protected transform(paValues: T): U;
	protected transform(paValues: T[]): U[];
	/** @override */
	protected transform(paValues: T | T[]): U | U[] {
		if (paValues instanceof Array)
			return paValues.map((poItem: any) => DateHelper.transform(poItem as unknown as Date, ETimetablePattern.dd_MM_yyyy_slash)) as unknown as U[];
		else
			return DateHelper.transform(paValues as unknown as Date, ETimetablePattern.dd_MM_yyyy_slash) as unknown as U;
	}

	/** @override */
	public action(): Observable<T[]> {
		if (this.datepickerComponent) {
			return of(this.datepickerComponent.pickDate())
				.pipe(
					mergeMap(() => this.moDateSubject.asObservable()),
					mergeMap((poValue: T) => of([poValue])),
					take(1)
				);
		}
		else
			return of([]);
	}

	public onDateChanged(pdDateEvent: Date): void {
		// Méthode appelée lorsque la date change, permet d'envoyer un événement au sujet interne pour recevoir la nouvelle date.
		//! Appelée AVANT que l'on s'abonne aux changements de la nouvelle date (ce qui explique le replaySubject).

		this.displayValue = this.ioDateWithLocalePipe.transform(pdDateEvent, DateHelper.C_LOCAL_FR, ETimetablePattern.dd_MM_yyyy_slash) as unknown as U;
		this.moDateSubject.next(pdDateEvent as unknown as T);
	}

	public onClick(): void {
		this.action().subscribe();
	}

	//#endregion
}