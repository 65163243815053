<div class="di-choix-seance_container">
	<div class="radio-container">
		<ng-container *ngFor="let option of options">
			<lua-radio-button [checked]="selectedOption === option"  [fullWidth]="true" [label]="getLabelForOption(option)"
				(click)="selectOption(option)">
			</lua-radio-button>
			<lua-alerte *ngIf="option === 'allSeance' && selectedOption === option" type="info" message="Les séances facturées ne sont pas modifiées."></lua-alerte>
		</ng-container>
	</div>

	<div class="button-container">
		<lua-action-principale class="lua-action-principale" libelle="Enregistrer" color="Blanc"
			colorBackgroundImage="DegradeOrisha3Couleurs" [fullWidth]="true" (click)="save()"></lua-action-principale>

		<lua-bouton-texte libelle="Annuler" [fullWidth]="true" (click)="cancel()"></lua-bouton-texte>
	</div>
</div>