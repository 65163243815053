<section class="list listSeances marge-btm-list-seances">

	<!-- Composant caché de sélection d'une date -->
	<osapp-date-time #datePicker [params]="datepickerParams" hidden>
	</osapp-date-time>

	<header class="ctnEnteteDate dis-flex flex-col row-wrap">
		<osapp-date-time [model]="traitement.beginDate" [params]="dateParams" (modelChange)="onStartDateChanged($event)">
		</osapp-date-time>
	</header>

	<div *ngIf="traitement.endDate" class="ctn-info-synthese dis-flex flex-col">
		<span class="txt-legende">Fin de traitement estimée</span>
		<span class="txt-info txt14px">{{ traitement.endDate | dateWithLocale: "fr-FR" : "EEE dd MMMM yyyy" }}</span>
	</div>

	<div class="ctn-info-seances">
		<div *ngIf="currentScrollIndex >= 5" class="scroll-top-btn">
			<ion-fab-button (click)="scrollToTop()" shape="round">
				<ion-icon name="arrow-up-outline"></ion-icon>
			</ion-fab-button>
		</div>

		<calao-virtual-scroll #virtualScroll [items]="seanceOrDateList" *ngIf="!!seances" class="seance-viewport"
			itemSize="70">
			<!-- Liste des séances -->
			<ng-template let-seanceOrDate="$implicit">
				<!-- En-tête d'une journée. -->
				<div *ngIf="seanceOrDate.date as date" class="item item-divider ctn-date-seance">
					{{ date | dateWithLocale: "fr-FR" : "EEE dd MMMM yyyy" }}
					<div class="divider"></div>
				</div>

				<div *ngIf="seanceOrDate.seance as seance" class="item ctn-seance">
					<div [ngClass]="seance.shadow ? 'disabled' : ''">
						<!-- Div pour montrer/cacher le contenu d'une séance. -->
						<div class="entete-seance" (click)="togglePanel(seance, indexSeance)">

							<!-- Icône pour (dé)plier une séance. -->
							<ion-icon [name]="expandedSeanceInfo?.seance?.equals(seance) ? 'caret-down-outline' : 'caret-forward'"
								class="arrow-space">
							</ion-icon>
							<!-- Indique l'heure à laquelle la séance a été planifiée. -->
							<ion-note (click)="$event.stopPropagation(); editSeanceTime(seance, timePicker)"
								class="ion-padding-end ctn-hour">
								{{ seance.scheduled || seance.isCanceled ? (seance.startDate | dateWithLocale: "fr-FR" : "HH:mm") :
								"(--:--)" }}
							</ion-note>
							<!-- Composant caché de sélection d'un horaire pour une séance non validée. -->
							<osapp-date-time #timePicker class="hidden" [model]="seance.startDate" [params]="timeParams"
								[disabled]="seance.status === statusSeanceEnum.done" (modelChange)="onDateModified($event, seance)">
							</osapp-date-time>

							<!-- Identifiant de la séance et nombre d'actes dans ladite séance. -->
							<ion-label class="ctn-id">{{ seance.actes.length }}
								{{ seance.actes.length > 1 ? "actes" : "acte" }}
							</ion-label>

							<!-- Avatars des intervenants d'une séance. -->
							<ng-container *ngIf="getAvatarsParams(seance) as avatarsParams">
								<calao-avatars *ngIf="avatarsParams.avatar" [avatar]="avatarsParams.avatar"
									[avatarsCount]="avatarsParams.avatarsCount"
									(click)="$event.stopPropagation(); setSeanceIntervenant(seance);">
								</calao-avatars>
							</ng-container>

							<!-- Icône calendrier (annulé, validé, par défaut). -->
							<ng-container [ngSwitch]="seance.status">
								<ion-icon *ngSwitchCase="statusSeanceEnum.canceled" (click)="reactivateSeance(seance)" name="date-supp"
									color="danger" class="ion-padding-end">
								</ion-icon>
								<ion-icon *ngSwitchCase="statusSeanceEnum.done" name="date-validation" color="success"
									class="ion-padding-end"></ion-icon>
								<ion-icon *ngSwitchCase="statusSeanceEnum.completed" name="invoice" class="ion-padding-end"></ion-icon>
								<ion-icon *ngSwitchDefault (click)="$event.stopPropagation(); dateTimePicker.pickDate();"
									name="calendar" class="ion-padding-end">
								</ion-icon>
							</ng-container>
							<!-- Composant caché de sélection de date et heure pour une séance non annulée et non validée. -->
							<osapp-date-time #dateTimePicker class="hidden" [model]="seance.startDate" [params]="dateTimeParams"
								(modelChange)="onDateModified($event, seance)">
							</osapp-date-time>

							<!-- Prix d'une séance. -->
							<div class="ctn-price"> {{ seance.price | priceFormat: 2 }} €</div>

							<!-- Bouton 'plus d'options'. -->
							<ion-button *ngIf="hasPopoverButton(seance)" class="ion-no-margin" fill="clear"
								(click)="$event.stopPropagation(); openSeancePopover($event, seance, dateTimePicker, timePicker);">
								<ion-icon name="ellipsis-vertical"></ion-icon>
							</ion-button>
						</div>

						<!-- Contenu montré/caché d'une séance (permet sa validation ou son report). -->
						<ion-item *ngIf="expandedSeanceInfo?.seance?.equals(seance)" lines="none">
							<div class="width-list">
								<validate-actes [seance]="seance" [seances]="seances" [traitement]="traitement"
									showActionButtons="false" showCheckbox="false" (onTraitementChanged)="onTraitementChanged()"
									(onSeanceMoved)="onDateModified($event, seance)">
								</validate-actes>
							</div>
						</ion-item>

						<!-- Descriptif du ou des actes. -->
						<div *ngIf="!expandedSeanceInfo?.seance?.equals(seance)" class="acte-description">
							<ion-note>{{getSeanceDescription(seance)}}</ion-note>
						</div>
					</div>
				</div>
			</ng-template>
		</calao-virtual-scroll>
	</div>

</section>