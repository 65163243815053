import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LifeCycleObserverComponentBase } from '@osapp/helpers';
import { ILifeCycleEvent, IWorkspaceInfo, UserData } from '@osapp/model';
import { WorkspaceService } from '@osapp/services';
import { SecurityService } from '@osapp/services/security.service';
import { ConnexionService } from '../../../features/connexion/services/connexion.service';
import { AuthenticatorService } from '../../../features/shared/services/authenticator.service';

@Component({
	templateUrl: './workspace-selection.page.html',
	styleUrls: ['./workspace-selection.page.scss'],
})
export class WorkspaceSelectionPage extends LifeCycleObserverComponentBase implements OnInit, OnDestroy, AfterViewInit {

	public workspaces: IWorkspaceInfo[];
	public selectedWorkspace: string;

	constructor(
		changeDetector: ChangeDetectorRef,
		private svcSecurity: SecurityService,
		private svcWorkspace: WorkspaceService,
		private svcConnexion: ConnexionService,		
		private svcAuthenticator: AuthenticatorService,
	) {
		super(null, changeDetector);
	}

	ngOnInit(): void {
		this.workspaces = this.svcWorkspace.getWorkspaces().sort((a, b) => a.name.localeCompare(b.name))
		this.svcConnexion.waitForConnexionFlags();
	}

	protected onLifeCycleEvent(event: ILifeCycleEvent): void {
		throw new Error('Method not implemented.');
	}

	public selectWorkspace(event: Event, workspaceId: string): void {
		this.selectedWorkspace = workspaceId;
		UserData.current.workspaceInfos = UserData.current.workspaceInfos.filter((workspace: IWorkspaceInfo) => workspace.id === workspaceId);
		this.svcConnexion.selectWorkspace(workspaceId);
	}

	public disconnect(): void {
		this.svcAuthenticator.setAuthenticationStatus(false);
		this.svcSecurity.disconnect().subscribe();
	}

}
