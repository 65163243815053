<div class="menu-transmission-container">
	<div>
		<lua-list-item class="list-item" iconColor="CouleurPrimaire" textColor="Noir" iconName="edit" text="Modifier"
			(click)="edit()">
		</lua-list-item>
		<lua-list-item class="list-item" iconColor="CouleurPrimaire" textColor="Noir" iconName="draft"
			text="Ajouter un document" (click)="addDocument()">
		</lua-list-item>
		<lua-list-item class="list-item" iconColor="CouleurPrimaire" textColor="Noir" iconName="photo_camera"
			text="Prendre une photo " (click)="addDocument()">
		</lua-list-item>
		<lua-list-item *ngIf="!commentaire" class="list-item" iconColor="CouleurPrimaire" textColor="Noir" iconName="reply"
			text="Ajouter un Commentaire" (click)="addComment()">
		</lua-list-item>
		<lua-divider-horizontal></lua-divider-horizontal>
		<lua-list-item class="list-item" iconColor="Rouge" textColor="Noir" iconName="delete" text="Supprimer"
			(click)="delete()">
		</lua-list-item>
	</div>
</div>