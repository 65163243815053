import { IIndexedArray } from '../model/IIndexedArray';
import { ObjectHelper } from './objectHelper';
/** Permet de mettre à disposition des méthodes pour aider à manipuler une Map. */
export abstract class MapHelper {

	//#region METHODS

	/** Transforme les valeurs d'une Map en Array.
	 * @param poMap Map à transformer.
	 */
	public static valuesToArray<K, V>(poMap: Map<K, V> | ReadonlyMap<K, V>): V[] {
		return Array.from(poMap.values());
	}

	/** Transforme les clés d'une Map en Array.
	 * @param poMap Map à transformer.
	 */
	public static keysToArray<K, V>(poMap: Map<K, V> | ReadonlyMap<K, V>): K[] {
		return Array.from(poMap.keys());
	}

	/** Ajoute une nouvelle entrée dans une Map, ou retire celle existante pour la remettre à la fin.
	 * @param poMap Map dont on veut mettre une entrée à la fin de son jeu d'entrées.
	 * @param poKey Clé de l'entrée.
	 * @param poValue Valeur de l'entrée, si non renseignée, on récupère celle existante pour la remettre à la fin du jeu d'entrées.
	 */
	public static setToEnd<K, V>(poMap: Map<K, V>, poKey: K, poValue?: V): void {
		if (poMap.has(poKey)) { // Si la clé existe déjà, il faut la supprimer puis la réajouter pour qu'elle soit à la fin.
			const loItem: V = poValue ? poValue : poMap.get(poKey);
			poMap.delete(poKey);
			poMap.set(poKey, loItem);
		}
		else // Sinon (la clé n'existe pas), il suffit d'ajouter la nouvelle entrée.
			poMap.set(poKey, poValue);
	}

	/** Nettoie les valeurs contenue dans une map tout en laissant les clés existantes\
	 * (écrasent les valeurs par une valeur par défaut ; `undefined` si non renseigné).
	 * @param poMap Map dont il faut supprimer toutes les valeurs mais garder les clés.
	 * @param poDefaultValue Valeur par défaut avec laquelle remplacer les anciennes.
	 */
	public static clearValues<K, V>(poMap: Map<K, V>, poDefaultValue: V = undefined): void {
		this.keysToArray(poMap).forEach((poKey: K) => poMap.set(poKey, poDefaultValue));
	}

	/** Ajoute un élément ou ensemble d'éléments dans une map en fonction d'une clé associée ; crée une nouvelle paire clé/valeurs si elle n'existe pas.
	 * @param poMap Map dans laquelle ajouter la/les nouvelle(s) valeur(s).
	 * @param poKey Clé de la paire où ajouter la/les nouvelle(s) valeur(s).
	 * @param poData Donnée à ajouter dans une paire de la map.
	 */
	public static addValues<K, V>(poMap: Map<K, V[]>, poKey: K, poData: V | V[]): void {
		const laNewValues: V[] = poData instanceof Array ? poData : [poData];

		if (poMap.has(poKey)) // Si la map a déjà une paire pour cette clé, on ajoute à l'existant.
			poMap.get(poKey).push(...laNewValues);
		else // Sinon, on crée une nouvelle paire.
			poMap.set(poKey, laNewValues);
	}

	/** Transforme une map en objet indexé.
	 * @param poMap 
	 */
	public static mapToObject<K, V>(poMap: Map<K, V> | ReadonlyMap<K, V>): IIndexedArray<V> {
		const loObject: IIndexedArray<V> = {};

		poMap.forEach((paValues: V, poKey: K) => loObject[poKey as unknown as string] = paValues);

		return loObject;
	}

	/** Permet de transformer la valeur d'une map.
	 * @param poMap Map à transformer.
	 * @param pfMapFunc Fonction de transformation de la valeur de la map.
	 * @returns 
	 */
	public static map<K, V, T>(poMap: Map<K, V> | ReadonlyMap<K, V>, pfMapFunc: (poValue: V) => T): Map<K, T> {
		const loMap = new Map<K, T>();

		poMap.forEach((poValue: V, poKey: K) => loMap.set(poKey, pfMapFunc(poValue)));

		return loMap;
	}

	/** Retourne l'addition de la longueur de chacune des liste de la map.
	 * @param poMap
	 */
	public static totalLenght<K, V extends Array<any>>(poMap: Map<K, V> | ReadonlyMap<K, V>): number {
		let lnTotalLenght: number = 0;

		poMap.forEach((poValue: V) => {
			lnTotalLenght += poValue.length;
		});

		return lnTotalLenght;
	}

	/** Crée une nouvelle map en mettant en clé une valeur donnée par une fonction (en se basant sur la valeur d'une entrée de l'ancienne map) et en valeur la clé associée à l'entrée dans l'ancienne map.
	 * @param poMap 
	 */
	public static reverseWithKeySelector<K, V, T = V>(poMap: Map<K, V> | ReadonlyMap<K, V>, pfKeySelector?: (poItem: V) => T): Map<T, K[]> {
		const loNewMap = new Map<T, K[]>();

		poMap.forEach((poValue: V, poKey: K) => {
			const loKey: T = pfKeySelector ? pfKeySelector(poValue) : poValue as any;
			loNewMap.has(loKey) ? loNewMap.get(loKey).push(poKey) : loNewMap.set(loKey, [poKey]);
		});

		return loNewMap;
	}

	/** Stringifie une map avec ses entrées.
	 * @param poMap Map qu'on veut stringifier.
	 */
	public static toString<K, V>(poMap: Map<K, V> | ReadonlyMap<K, V>): string {
		return JSON.stringify(this.mapToObject(poMap));
	}

	public static merge<K, V>(paMaps: Map<K, V>[], pfMerger: (poAcc: V, poValue: V) => V): Map<K, V> {
		const loMap = new Map<K, V>();

		paMaps.forEach((poMap: Map<K, V>) => {
			poMap.forEach((poValue: V, poKey: K) => {
				const loMergedValue: V = loMap.get(poKey);

				loMap.set(poKey, ObjectHelper.isDefined(loMergedValue) ? pfMerger(loMergedValue, poValue) : poValue);
			});
		});

		return loMap;
	}

	//#endregion

}