import { Component, Input, OnInit } from "@angular/core";
import { EPrefix, IContact, IGroup, IUser } from "@osapp/model";
import { DestroyableComponentBase } from "@osapp/modules/utils/components/destroyable-component-base";
import { ContactsService, GroupsService } from "@osapp/services";
import { Observable, forkJoin } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Component({
  selector: "di-utilisateurs",
  templateUrl: "./utilisateurs.component.html",
  styleUrls: ["./utilisateurs.component.scss"],
})
export class UtilisateursComponent extends DestroyableComponentBase implements OnInit {
  @Input() isMobileView: boolean;

  public adminTag = {
    tagLabel: "Admin",
    tagColor: "Raspberry",
    tagMode: "outlined",
  };

  public remplacantTag = {
    tagLabel: "Remplaçant",
    tagColor: "CouleurPrimaire",
    tagMode: "filled",
  };

  public titulaireTag = {
    tagLabel: "Titulaire",
    tagColor: "CouleurPrimaire",
    tagMode: "filled",
  };

  public utilisateurs$: Observable<IContact[]>;
  public nbUtilisateurs$: Observable<number>; 
	public showInvitation : boolean = false;
	public showFormInvitation : boolean = false;
	public showListUtilisateur : boolean = false;
	public userFind: IUser;


  constructor(
    private svcContacts: ContactsService,
    private svcGroupe: GroupsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.utilisateurs$ = this.loadUtilisateurs();
    this.nbUtilisateurs$ = this.utilisateurs$.pipe(
      map(utilisateurs => utilisateurs.length)
    );
		this.showListUtilisateur = true;
  }

	public handelInvitation()
	{
		this.showInvitation = true;
		this.showListUtilisateur = false;
	}

	public onCancel = () => {
		this.showInvitation = false;
		this.showListUtilisateur = true;
		this.showFormInvitation = false;
	}

	public onCancelForm = () => {
		this.showInvitation = true;
		this.showListUtilisateur = false;
		this.showFormInvitation = false;
	}

	public onShowForm = (user :IUser) => {
		this.userFind = user;	
		this.showInvitation = false;
		this.showListUtilisateur = false;
		this.showFormInvitation = true;
	}

  private loadUtilisateurs(): Observable<IContact[]> {
    return this.svcContacts.getSiteContactsAnakin([], EPrefix.contact, true, true).pipe(
			map((utilisateurs: IContact[]) => 
				utilisateurs.filter(utilisateur => utilisateur.userId)
			),
      switchMap((utilisateurs: IContact[]) => 
        forkJoin(utilisateurs.map(utilisateur => 
          this.getTagsForUtilisateur(utilisateur).pipe(
            map(tags => ({ ...utilisateur, tags }))
          )
        ))
      )
    );
  }

  private getTagsForUtilisateur(utilisateur: IContact): Observable<any[]> {
    const tags = [];

    if (utilisateur.isAdmin) {
      tags.push(this.adminTag);
    }

    return this.svcGroupe.getUserGroups(utilisateur._id).pipe(
      map((groups: IGroup[]) => {
        if (groups.some(group => group._id === 'grp_remplacant')) {
					tags.push(this.remplacantTag);
				}
				if (groups.some(group => group._id === 'grp_titulaire')) {
						tags.push(this.titulaireTag);
				}
        return tags;
      })
    );
  }

  getAvatarColor(utilisateur: IContact) {
    return utilisateur.avatarCouleur ?? 'CouleurPrimaire';
  }
}
