<ion-header>
	<div class="infos-list">
		<ion-toolbar class="toolbar-bloc">
			<div class="infos-content">
				<div class="intervenant-area" slot="end">
					<div class="intervenants-wrapper">
						<avatar class="avatar up" [cssClass]="'round'" [src]="getContactAvatar(author)">
						</avatar>
					</div>
					<div class="intervenants-labels">
						<ion-note>Auteur</ion-note>
						<ion-label>{{ author | contactName }}</ion-label>
					</div>
				</div>
			</div>
		</ion-toolbar>

		<ion-toolbar class="toolbar-bloc">
			<div class="infos-content">
				<div class="bloc-infos">
					<ion-row>
						<ion-col size="12" (click)="goToPatient()">
							<div *ngIf="!!linkedHydratedPatient" class="avatar-infos">
								<div class="patient-wrap" slot="end" (click)="goToPatient()">
									<avatar [src]="linkedHydratedPatient.avatar" [cssClass]="'round'" slot="start">
									</avatar>
								</div>
								<div class="patient-labels">
									<ion-note>Patient</ion-note>
									<ion-label>
										{{ linkedHydratedPatient.groupMember | patientName }}&nbsp;
									</ion-label>
									<ion-label class="maiden-name">
										{{ linkedHydratedPatient.groupMember.maidenName }}
									</ion-label>
								</div>
							</div>
						</ion-col>
					</ion-row>
					<ion-row>
						<ion-col size="12" class="patient-tags">
							<div class="patient-tag width-seance-date">
								<ion-item lines="none" color="osapp-element" class="ion-no-padding">
									<ion-icon slot="start" name="time"></ion-icon>
									<div class="patient-tag-label">
										{{ transmission.createdDate | dateWithLocale:"fr-FR": C_TRANSMISSION_HEURE_FORMAT }}
									</div>
								</ion-item>
							</div>
							<div class="patient-tag width-seance-date">
								<ion-item lines="none" color="osapp-element" class="ion-no-padding">
									<ion-icon slot="start" name="timetable"></ion-icon>
									<div class="patient-tag-label">
										{{ transmission.createdDate | dateWithLocale:"fr-FR": C_TRANSMISSION_DATE_FORMAT }}
									</div>
								</ion-item>
							</div>
						</ion-col>
					</ion-row>
				</div>
			</div>
		</ion-toolbar>
	</div>
</ion-header>