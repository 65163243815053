import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageHelper, StringHelper } from '../../helpers';
import { ComponentBase } from '../../helpers/ComponentBase';
import { BlobUrlManager } from './model/BlobUrlManager';
import { EImageClass } from './model/EImageClass';

@Component({
	selector: "osapp-image",
	templateUrl: './osapp-image.component.html',
	styleUrls: ['./osapp-image.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OsappImageComponent extends ComponentBase {

	//#region FIELDS

	@Output("onImageLoaded") private readonly onImageLoadedEventEmitter = new EventEmitter<CustomEvent>();
	@Output("onImageError") private readonly onImageErrorEventEmitter = new EventEmitter<CustomEvent>();

	private readonly moBlobUrlManager = new BlobUrlManager();

	//#endregion

	//#region PROPERTIES

	private msSrc: string;
	public get src(): string | Blob { return this.msSrc; }
	@Input() public set src(poValue: string | Blob) {
		let lsSrc: string;
		if (poValue) {
			const lsValueToSanitize: string = poValue instanceof Blob ? this.moBlobUrlManager.createUrl(poValue) : poValue;
			lsSrc = this.ioSanitizer.bypassSecurityTrustUrl(lsValueToSanitize) as string;
		}

		if (lsSrc !== this.msSrc) {
			this.msSrc = lsSrc;
			this.detectChanges();
		}
	}

	@Input() public alt: string;

	private meImgClass: EImageClass = EImageClass.fullwidthImg;
	/** Classe à appliquer sur l'élément, 'EImageClass.fullwidthImg' par défaut. */
	public get imgClass(): EImageClass { return this.meImgClass; }
	@Input() public set imgClass(peValue: EImageClass) {
		if (peValue !== this.meImgClass) {
			this.meImgClass = peValue;
			this.detectChanges();
		}
	}

	//#endregion

	//#region METHODS

	constructor(private readonly ioRenderer: Renderer2, private readonly ioSanitizer: DomSanitizer, poChangeDetector: ChangeDetectorRef) {
		super(poChangeDetector);
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moBlobUrlManager.releaseUrls();
	}

	public async onImageLoadedAsync(poEvent: CustomEvent, poImage: HTMLImageElement): Promise<void> {
		this.onImageLoadedEventEmitter.emit(poEvent);
		await this.updateOrientationAsync(poImage);
		this.detectChanges();
	}

	private async updateOrientationAsync(poImage: HTMLImageElement): Promise<void> {
		const lsClass: string = await ImageHelper.getCssOrientationAsync(poImage);

		if (!StringHelper.isBlank(lsClass))
			this.ioRenderer.addClass(poImage, lsClass);
	}

	public onImageError(poEvent: CustomEvent): void {
		this.onImageErrorEventEmitter.emit(poEvent);
	}

	//#endregion
}
