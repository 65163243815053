import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";


@Injectable({
	providedIn: "root"
})
export class AuthenticatorService {

	private authenticationStatusSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor() {
	}

	public getAuthenticationStatus(): Observable<boolean> {
		return this.authenticationStatusSubject.asObservable();
	}

	public setAuthenticationStatus(isAuthenticated: boolean): void {
		this.authenticationStatusSubject.next(isAuthenticated);
	}
}