<osapp-header-modal title="Modification d'une tranche"></osapp-header-modal>

<ion-content>
	<ion-grid>
		<ion-row>
			<ion-col size="6">Nom de la tranche</ion-col>
			<ion-col size="6">
				<ion-input placeholder="Entrez un nom" [(ngModel)]="slotEditData.label"></ion-input>
			</ion-col>
		</ion-row>
		<ion-row>
			<ion-col size="6">Heure de début</ion-col>
			<ion-col size="6">
				<ion-input type="number" placeholder="Entrez un heure" max="23" min="0" [(ngModel)]="slotEditData.startHour">
				</ion-input>
			</ion-col>
		</ion-row>
		<ion-row>
			<ion-col size="6">Heure de fin</ion-col>
			<ion-col size="6">
				<ion-input type="number" placeholder="Entrez un heure" max="23" min="0" [(ngModel)]="slotEditData.endHour">
				</ion-input>
			</ion-col>
		</ion-row>
	</ion-grid>

	<div class="buttons">
		<ion-button (click)="close()" fill="clear" color="primary">
			Annuler
		</ion-button>
		<ion-button (click)="validateEdition()" color="primary" fill="clear">
			Valider
		</ion-button>
	</div>
</ion-content>