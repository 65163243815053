import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DatabaseSynchroModule } from '../../modules/database-synchro/database-synchro.module';
import { LinkModule } from '../link/link.module';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { DynamicPageComponent } from './dynamicPage.component';

@NgModule({
	imports: [
		IonicModule,
		CommonModule,
		LinkModule,
		ToolbarModule,
		DatabaseSynchroModule
	],
	exports: [DynamicPageComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	declarations: [DynamicPageComponent]
})
export class DynamicPageModule { }
