import { Injectable } from '@angular/core';
import { MapHelper, ObjectHelper, StoreHelper } from '@osapp/helpers';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { IStoreDocument } from '@osapp/model';
import { IGalleryFile } from '@osapp/model/gallery/IGalleryFile';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { IDataSource } from '@osapp/model/store/IDataSource';
import { IStoreDataResponse } from '@osapp/model/store/IStoreDataResponse';
import { DmsExternalLinkerService } from '@osapp/modules/dms/services/dms-external-linker.service';
import { SelectorComponent } from '@osapp/modules/selector/selector/selector.component';
import { GalleryService } from '@osapp/services/gallery.service';
import { Store } from '@osapp/services/store.service';
import { plainToClass } from 'class-transformer';
import { EMPTY, Observable, of } from 'rxjs';
import { map, mapTo, mergeMap, tap } from 'rxjs/operators';
import { C_PREFIX_ORDONNANCE } from '../../../app/app.constants';
import { Acte } from '../../../model/Acte';
import { Traitement } from '../../../model/Traitement';
import { ActesService } from '../../actes/actes.service';
import { OrdonnanceModalOpenerService } from '../components/ordonnance-modal/services/ordonnance-modal-opener.service';
import { IOrdonnance } from '../models/iordonnance';
import { Ordonnance } from '../models/ordonnance';

@Injectable()
export class OrdonnancesService {

	//#region METHODS

	constructor(
		private readonly isvcStore: Store,
		private readonly isvcGallery: GalleryService,
		private readonly isvcDmsExternalLinker: DmsExternalLinkerService,
		private readonly isvcActes: ActesService,
		private readonly isvcOrdonnanceModalOpener: OrdonnanceModalOpenerService
	) { }

	/** Récupère toutes les ordonnances d'un traitement.
	 * @param psTraitementId
	 * @param pbLive
	 */
	public getTraitementOrdonnances(psTraitementId: string, pbLive?: boolean): Observable<Ordonnance[]> {
		const loDatasource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: `${C_PREFIX_ORDONNANCE}${psTraitementId}`,
				endkey: `${C_PREFIX_ORDONNANCE}${psTraitementId}${Store.C_ANYTHING_CODE_ASCII}`,
				include_docs: true
			},
			live: pbLive
		};

		return this.isvcStore.get<Ordonnance>(loDatasource)
			.pipe(map((paOrdonnances: IOrdonnance[]) => paOrdonnances.map((poOrdonnance: IOrdonnance) =>
				poOrdonnance instanceof Ordonnance ? poOrdonnance : plainToClass(Ordonnance, poOrdonnance)
			)));
	}

	/** Récupère toutes les ordonnances des traitements.
	 * @param paTraitementsIds
	 * @param pbLive
	 */
	public getOrdonnancesByTraitementId(paTraitementsIds: string[], pbLive?: boolean): Observable<Map<string, Ordonnance[]>> {
		const loDatasource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: `${C_PREFIX_ORDONNANCE}`,
				endkey: `${C_PREFIX_ORDONNANCE}${Store.C_ANYTHING_CODE_ASCII}`
			},
			live: pbLive
		};

		return this.isvcStore.get<IStoreDocument>(loDatasource)
			.pipe(
				map((paOrdonnanceDocs: IStoreDocument[]) => paOrdonnanceDocs
					.map((poDoc: IStoreDocument) => poDoc._id)
					.filter((psOrdonnanceId: string) => paTraitementsIds.some((psTraitementId: string) => psOrdonnanceId.includes(psTraitementId)))
				),
				mergeMap((paOrdonnancesIds: string[]) => this.getOrdonnancesByIds(paOrdonnancesIds)),
				map((paOrdonnances: Ordonnance[]) => {
					const loOrdonnancesByTraitementId = new Map<string, Ordonnance[]>();

					paOrdonnances.forEach((poOrdonnance: Ordonnance) => {
						if (loOrdonnancesByTraitementId.has(poOrdonnance.traitementId))
							loOrdonnancesByTraitementId.set(poOrdonnance.traitementId, ArrayHelper.unique([...loOrdonnancesByTraitementId.get(poOrdonnance.traitementId), poOrdonnance]));
						else
							loOrdonnancesByTraitementId.set(poOrdonnance.traitementId, [poOrdonnance]);
					});

					return loOrdonnancesByTraitementId;
				})
			);
	}

	/** Récupère l'ordonnance selon l'identifiant.
	 * @param psId
	 */
	public getOrdonnance(psId: string, pbLive?: boolean): Observable<Ordonnance> {
		const loDatasource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				key: psId,
				include_docs: true
			},
			live: pbLive
		};

		return this.isvcStore.getOne<Ordonnance>(loDatasource).pipe(map((poOrdonnance: IOrdonnance) => plainToClass(Ordonnance, poOrdonnance)));
	}

	/** Récupère les ordonnances selon les identifiants.
	 * @param paIds
	 */
	public getOrdonnancesByIds(paIds: string[]): Observable<Ordonnance[]> {
		const loDatasource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				keys: paIds,
				include_docs: true
			}
		};

		return this.isvcStore.get<Ordonnance>(loDatasource)
			.pipe(map((paOrdonnances: IOrdonnance[]) => paOrdonnances.map((poOrdonnance: Ordonnance) => plainToClass(Ordonnance, poOrdonnance))));
	}

	/** Sauvegarde plusieurs ordonnances.
	 * @param paOrdonnances
	 */
	public saveOrdonnances(paOrdonnances: Ordonnance[]) {
		const lsDatabaseId: string = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace));

		return this.isvcStore.putMultipleDocuments(paOrdonnances, lsDatabaseId)
			.pipe(
				mapTo(paOrdonnances)
			);
	}

	/** Sauvegarde une ordonnance d'un traitement.
	 * @param poTraitement
	 * @param poOrdonnance
	 */
	public saveOrdonnance(poTraitement: Traitement, poOrdonnance: Ordonnance): Observable<Ordonnance> {
		const lsDatabaseId: string = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace));

		return this.saveDocuments(poTraitement, poOrdonnance)
			.pipe(
				mergeMap(() => {
					poOrdonnance.documents?.forEach((poGalleryFile: IGalleryFile) => {
						delete poGalleryFile.isAvailable;
						delete poGalleryFile.isNew;
						delete poGalleryFile.isLoading;
						delete poGalleryFile.file;
					});
					return this.isvcStore.put(poOrdonnance, lsDatabaseId);
				}),
				mapTo(poOrdonnance)
			);
	}

	/** Sauvegarde les documents d'une ordonnance d'un traitement.
	 * @param poTraitement
	 * @param poOrdonnance Ordonnance dont il faut enregistrer les documents.
	 */
	private saveDocuments(poTraitement: Traitement, poOrdonnance: Ordonnance): Observable<boolean> {
		if (ArrayHelper.hasElements(poOrdonnance.documents)) {
			poOrdonnance.documents.forEach((poDoc: IGalleryFile) => poDoc.type = "");

			return this.isvcGallery.saveFiles(poOrdonnance.documents).pipe(
				mergeMap(() => this.isvcDmsExternalLinker.linkFilesToEntity(poTraitement, poOrdonnance.documents))
			);
		}

		return of(true);
	}

	/** Supprime une ordonnance.
	 * @param poOrdonnance
	 */
	public deleteOrdonnance(poOrdonnance: Ordonnance): Observable<boolean> {
		return this.isvcStore.delete(poOrdonnance).pipe(map((poStoreDataResponse: IStoreDataResponse) => poStoreDataResponse.ok));
	}

	/** Permet de lier des ordonnances à un acte à l'aide d'un selecteur.
	 * @param paOrdonnances
	 * @param poActe
	 * @param poOrdonnancesIdsByActeGuid
	 * @param paPreselectedOrdonnanceIds
	 */
	public updateOrdonnancesLinksToActe(
		paOrdonnances: Ordonnance[],
		paSelectedOrdonnances: Ordonnance[],
		poActe: Acte,
		poOrdonnancesIdsByActeGuid: Map<string, string[]>
	): Observable<Ordonnance[]> {
		const laPreviousSelectedOrdonnancesIds: string[] = poOrdonnancesIdsByActeGuid.get(poActe.guid) ?? [];
		const loOrdonnancesById = new Map<string, Ordonnance>(paOrdonnances.map((poOrdonnance: Ordonnance) => [poOrdonnance._id, poOrdonnance]));

		if (paSelectedOrdonnances === undefined)
			return EMPTY;
		else {
			const laSelectedOrdonnancesIds: string[] = paSelectedOrdonnances.map((poOrdonnance: Ordonnance) => poOrdonnance._id);
			const laOrdonnancesIdsToUnlink: string[] = ArrayHelper.getDifferences(laPreviousSelectedOrdonnancesIds, laSelectedOrdonnancesIds);
			const laOrdonnancesGuidsToLink: string[] = ArrayHelper.getDifferences(laSelectedOrdonnancesIds, laPreviousSelectedOrdonnancesIds);

			const laOrdonnancesToSave: Ordonnance[] = [];

			laOrdonnancesIdsToUnlink.forEach((psOrdonnanceId: string) => {
				const loOrdonnance: Ordonnance = loOrdonnancesById.get(psOrdonnanceId);
				ArrayHelper.removeElement(loOrdonnance.linkedActesGuids, poActe.guid);
				laOrdonnancesToSave.push(loOrdonnance);
			});

			laOrdonnancesGuidsToLink.forEach((psOrdonnanceId: string) => {
				const loOrdonnance: Ordonnance = loOrdonnancesById.get(psOrdonnanceId);
				loOrdonnance.linkedActesGuids.push(poActe.guid);
				laOrdonnancesToSave.push(loOrdonnance);
			});

			return this.saveOrdonnances(laOrdonnancesToSave);
		}
	}

	public createNewOrdonnance(poTraitement: Traitement, paAllOrdonnances: Ordonnance[] = []): Observable<Ordonnance> {
		const laLinkedActesGuids: string[] = ArrayHelper.flat(paAllOrdonnances.map((poOrdonnance: Ordonnance) => poOrdonnance.linkedActesGuids));
		return this.editOrdonnance(poTraitement, new Ordonnance(poTraitement))
			.pipe(
				mergeMap((poOrdonnance: Ordonnance) => {
					if (poOrdonnance && poTraitement.actes?.filter((poActe: Acte) => !laLinkedActesGuids.includes(poActe.guid)).length > 0)
						return this.linkActesToOrdonnanceWithSelector(poOrdonnance, poTraitement, this.getActesByOrdonnanceId(paAllOrdonnances));
					return of(null);
				})
			);
	}

	public editOrdonnance(poTraitement: Traitement, poOrdonnance: Ordonnance): Observable<Ordonnance> {
		return this.isvcOrdonnanceModalOpener.open(poTraitement, poOrdonnance)
			.pipe(
				mergeMap((poEditedOrdonnance: Ordonnance) => {
					if (!ObjectHelper.isNullOrEmpty(poEditedOrdonnance))
						return this.saveOrdonnance(poTraitement, poEditedOrdonnance);
					return of(null);
				})
			);
	}

	/** Permet de lier des actes séléctionnées via une modale à une ordonnance.
	 * @param poOrdonnance
	 * @param poTraitement
	 * @param poActesByOrdonnanceIds
	 * @param	pbForceActeWithoutOrdonnanceSelection Force la présélection des actes sans ordonnances.
	 * @param paPreSelectedActesGuids
	 */
	public linkActesToOrdonnanceWithSelector(
		poOrdonnance: Ordonnance,
		poTraitement: Traitement,
		poActesByOrdonnanceIds: Map<string, string[]>,
		pbForceActeWithoutOrdonnanceSelection?: boolean,
		paPreSelectedActesGuids: string[] = []
	): Observable<Ordonnance> {
		const laActes: Acte[] = poTraitement.actes;
		const laActeGuids: string[] = poActesByOrdonnanceIds.get(poOrdonnance._id) ?? [];
		const laLinkedActes: Acte[] = laActes.filter((poActe: Acte) => laActeGuids.includes(poActe.guid) || paPreSelectedActesGuids.includes(poActe.guid));
		const laActesWithOrdonnancesIds: string[] = ArrayHelper.unique(ArrayHelper.flat(MapHelper.valuesToArray(poActesByOrdonnanceIds)));
		const laActesWithOrdonnances: Acte[] = laActesWithOrdonnancesIds.map((psGuid: string) => laActes.find((poActe: Acte) => poActe.guid === psGuid));
		// On supprime les actes undefined.
		ArrayHelper.removeElementsByFinder(laActesWithOrdonnances, (poActe: Acte) => !ObjectHelper.isDefined(poActe));
		// On récupère les actes sans ordonnances.
		const laActesWithoutOrdonnance: Acte[] = ArrayHelper.getDifferences(laActes, laActesWithOrdonnances);
		const laActeOptions: Acte[] = ArrayHelper.unique([...laLinkedActes, ...laActesWithoutOrdonnance]);

		return this.isvcActes.selectActesWithModal(laActeOptions,
			(pbForceActeWithoutOrdonnanceSelection ? laActeOptions : laLinkedActes).map((poActe: Acte) => poActe.guid),
			[],
			"Rattacher l'ordonnance aux actes suivants ?")
			.pipe(
				mergeMap((paSelectedActes: Acte[]) => {
					ArrayHelper.getDifferences(laLinkedActes, paSelectedActes).forEach((poActe: Acte) =>
						ArrayHelper.removeElement(poOrdonnance.linkedActesGuids, poActe.guid)
					);

					ArrayHelper.getDifferences(paSelectedActes, laLinkedActes).forEach((poActe: Acte) =>
						poOrdonnance.linkedActesGuids.push(poActe.guid)
					);

					return this.saveOrdonnance(poTraitement, poOrdonnance);
				})
			);
	}

	private onAddActeClicked(poSelector: SelectorComponent<string>, poTraitement: Traitement, paActeOptions: Acte[]): void {
		this.isvcActes.selectActeWithModal(poTraitement).pipe(
			tap((poActe: Acte) => {
				poSelector.addOption({ label: poActe.description, value: poActe.guid, selected: true });
				paActeOptions.push(poActe);
				poTraitement.actes.push(poActe);
				StoreHelper.makeDocumentDirty(poTraitement);
			}),
		).subscribe();
	}

	/** Retourne les ids d'ordonnances groupés par guid d'acte lié.
	 * @param paOrdonnances
	 */
	public getOrdonnancesIdsByActeGuid(paOrdonnances: Ordonnance[]): Map<string, string[]> {
		const loOrdonnancesIdsByActeId = new Map<string, string[]>();

		paOrdonnances.forEach((poOrdonnance: Ordonnance) => {
			if (ArrayHelper.hasElements(poOrdonnance.linkedActesGuids)) {
				poOrdonnance.linkedActesGuids.forEach((psActeGuid: string) => {
					if (loOrdonnancesIdsByActeId.has(psActeGuid))
						loOrdonnancesIdsByActeId.set(psActeGuid, ArrayHelper.unique([...loOrdonnancesIdsByActeId.get(psActeGuid), poOrdonnance._id]));
					else
						loOrdonnancesIdsByActeId.set(psActeGuid, [poOrdonnance._id]);
				});
			}
		});

		return loOrdonnancesIdsByActeId;
	}

	/** Retourne les guids d'actes par ordonnance liée.
	 * @param paOrdonnances
	 */
	public getActesByOrdonnanceId(paOrdonnances: Ordonnance[]): Map<string, string[]> {
		const loActesByOrdonnance = new Map<string, string[]>();

		paOrdonnances.forEach((poOrdonnance: Ordonnance) => {
			if (ArrayHelper.hasElements(poOrdonnance.linkedActesGuids)) {
				poOrdonnance.linkedActesGuids.forEach((psActeGuid: string) => {
					if (loActesByOrdonnance.has(poOrdonnance._id))
						loActesByOrdonnance.set(poOrdonnance._id, ArrayHelper.unique([...loActesByOrdonnance.get(poOrdonnance._id), psActeGuid]));
					else
						loActesByOrdonnance.set(poOrdonnance._id, [psActeGuid]);
				});
			}
		});

		return loActesByOrdonnance;
	}

	public linkOrdonnancesToActe(paOrdonnances: Ordonnance[], psActeGuid: string): Observable<Ordonnance[]> {
		paOrdonnances.forEach((poOrdonnance: Ordonnance) => poOrdonnance.linkedActesGuids.push(psActeGuid));
		return this.saveOrdonnances(paOrdonnances);
	}

	public unlinkOrdonnancesToActe(paOrdonnances: Ordonnance[], psActeGuid: string): Observable<Ordonnance[]> {
		paOrdonnances.forEach((poOrdonnance: Ordonnance) => ArrayHelper.removeElement(poOrdonnance.linkedActesGuids, psActeGuid));
		return this.saveOrdonnances(paOrdonnances);
	}

	//#endregion

}