import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { SelectFieldComponent } from '@osapp/components/forms/form/customFields/select-field.component';
import { FormsService } from '@osapp/services/forms.service';
import { EExerciceCondition } from '../../model/EExerciceCondition';

@Component({
	selector: 'idl-exercice-condition-field',
	templateUrl: './exercice-condition-field.component.html',
	styleUrls: ['./exercice-condition-field.component.scss'],
})
export class ExerciceConditionFieldComponent extends SelectFieldComponent implements OnInit, OnDestroy {

	//#region FIELDS

	private readonly C_EXERCICE_CONDITION_OPTIONS: { label: string; value: string; }[] = [
		{ label: "Libéral", value: EExerciceCondition.liberal },
		{ label: "Salarié", value: EExerciceCondition.salarie },
		{ label: "Bénévole", value: EExerciceCondition.benevole }
	];

	//#endregion

	//#region METHODS

	constructor(
		psvcForms: FormsService,
		/** Service de rafraîchissement de la vue. */
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	/** @override */
	public ngOnInit(): void {
		if (this.to.data)
			this.to.data.options = this.C_EXERCICE_CONDITION_OPTIONS;
		else
			this.to.data = { options: this.C_EXERCICE_CONDITION_OPTIONS };

		super.ngOnInit();
	}

	//#endregion

}
