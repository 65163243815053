export interface DateFormatter {
  /** Format du jour dans la vue month */
  formatMonthViewDay: Function,
  /** Format du header du jour dans la vue month */
  formatMonthViewDayHeader: Function,
  /** Format du titre dans la vue month */
  formatMonthViewTitle: Function,
  /** Format de la semaine dans la vue month */
  formatWeekViewDayHeader: Function,
  /** Format du titre dans la vue week */
  formatWeekViewTitle: Function,
  /** Format de l'heure dans la vue week */
  formatWeekViewHourColumn: Function,
  /** Format de l'heure dans la vue day */
  formatDayViewHourColumn: Function,
  /** Format du titre dans la vue day */
  formatDayViewTitle: Function
}