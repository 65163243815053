import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IPatient } from '../../../../../modules/patients/model/IPatient';
import { ITransmissionRapport } from '../../../../../modules/patients/model/ITransmissionRapport';
import { RapportService } from '../../../../../modules/patients/services/rapport.service';
import { ICommentaireTransmission } from '../../../../models/ICommentaireTransmission';
import { ConfirmationSuppressionComponent } from '../../../shared/components/confirmation-suppression/confirmation-suppression.component';
import { DrawerPopoverService } from '../../../shared/services/drawer-popover.service';
import { PanneauService } from '../../../shared/services/panneau.service';
import { TransmissionService } from '../../../shared/services/transmission.service';
import { PanneauCommentTransmissionComponent } from '../panneau-comment-transmission/panneau-comment-transmission.component';
import { PanneauDetailTransmissionComponent } from '../panneau-detail-transmission/panneau-detail-transmission.component';

@Component({
  selector: 'di-menu-transmission',
  templateUrl: './menu-transmission.component.html',
  styleUrls: ['./menu-transmission.component.scss'],
})
export class MenuTransmissionComponent implements OnInit {

  constructor(
		private svcPanneau: PanneauService,
		private svcTransmission : RapportService,
		private svcDrawerPopover : DrawerPopoverService,
		private svcTransmissionService : TransmissionService
  ) { }

	private subscriptions: Subscription[] = [];

	@Input() commentaire? : ICommentaireTransmission ;
	@Input() transmission? : ITransmissionRapport;
	@Input() patient? : IPatient;
	@Input() anchorElement? : any;

  ngOnInit() { 
		this.subscriptions.push(this.svcPanneau.closePanel$.subscribe(() => {
		}));
	}

	public edit(){
		this.svcDrawerPopover.close();
		if(this.commentaire)
			this.updateTransmissionCommentPanneau();			
		else
			this.updateTransmissionPanneau();
	}

	public addDocument()
	{
		this.svcDrawerPopover.close();
		if(this.commentaire)
			this.addDocumenTransmissionCommentPanneau();			
		else
			this.addDocumentTransmissionPanneau();
	}

	public addComment(){
		this.svcDrawerPopover.close();
		this.svcPanneau.open(`${this.patient.firstName} ${this.patient.lastName}`,PanneauCommentTransmissionComponent,{transmission: this.transmission})
	}

	public delete(){
		this.svcDrawerPopover.close();

		this.svcDrawerPopover.open("Confirmation de suppression","50%",this.anchorElement.nativeElement, ConfirmationSuppressionComponent, {
				onConfirm: () => this.handleConfirm(),
				onCancel: () => this.handleCancel()
				})
	}

	private handleConfirm (){
		this.svcDrawerPopover.close();
		if(this.commentaire)
			this.deleteTransmissionComment();			
		else
			this.deleteTransmission();
	}

	private handleCancel (){
		this.svcDrawerPopover.close();
	}

	private updateTransmissionPanneau() {
		this.svcPanneau.open(`${this.patient.firstName} ${this.patient.lastName}`, PanneauDetailTransmissionComponent, { transmission: this.transmission });
	}

	private addDocumentTransmissionPanneau() {
		this.svcPanneau.open(`${this.patient.firstName} ${this.patient.lastName}`, PanneauDetailTransmissionComponent, { transmission: this.transmission ,openDoc : true });
	}

	private addDocumenTransmissionCommentPanneau() {
		this.svcPanneau.open(`${this.patient.firstName} ${this.patient.lastName}`, PanneauCommentTransmissionComponent, { transmission: this.transmission, comment: this.commentaire,openDoc : true });
	}

	private updateTransmissionCommentPanneau() {
		this.svcPanneau.open(`${this.patient.firstName} ${this.patient.lastName}`, PanneauCommentTransmissionComponent, { transmission: this.transmission, comment: this.commentaire });
	}

	private deleteTransmission()
	{
		this.svcTransmission.deleteTransmission(this.transmission).subscribe({
			next: () => {
				this.svcTransmissionService.emitDeletion();
			},
			error: (error) => {
			}
		});
	}

	private deleteTransmissionComment()
	{
		const index = this.transmission.commentaires.findIndex(
			(c: ICommentaireTransmission) => c.id === this.commentaire.id
		);

		if (index !== -1) {
				this.transmission.commentaires.splice(index, 1);
				this.svcTransmission.saveTransmissionANAKIN(this.transmission).subscribe({
					next: () => {
						this.svcTransmissionService.emitDeletion();
					},
					error: (error) => {
					}
				});
		}
	}

}
