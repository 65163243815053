import { Component, OnInit } from '@angular/core';
import { DynamicPageComponent } from '@osapp/components/dynamicPage/dynamicPage.component';
import { MenuService } from '@osapp/components/menu';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { GuidHelper } from '@osapp/helpers/guidHelper';
import { LifeCycleObserverComponentBase } from '@osapp/helpers/LifeCycleObserverComponentBase';
import { EApplicationEventType } from '@osapp/model/application/EApplicationEventType';
import { IApplicationEvent } from '@osapp/model/application/IApplicationEvent';
import { DynHostItem } from '@osapp/model/DynHostItem';
import { ELifeCycleEvent } from '@osapp/model/lifeCycle/ELifeCycleEvent';
import { ILifeCycleEvent } from '@osapp/model/lifeCycle/ILifeCycleEvent';
import { ISlideboxData } from '@osapp/model/slidebox/ISlideboxData';
import { SlideboxService } from '@osapp/services/slidebox.service';
import { takeUntil } from 'rxjs/operators';
import { PatientsListComponent } from './components/patients/patients-list.component';
import { RecentPatientsListComponent } from './components/patients/recent-patients-list.component';

@Component({
	templateUrl: './patients-book.component.html'
})
export class PatientsBookComponent extends LifeCycleObserverComponentBase implements OnInit {

	//#region FIELDS

	// Les clés 'formList' et 'formListRecent' sont en lien avec ce qui est déclaré sur couchDB.
	private static readonly C_MAP_STRING_TO_DYN_HOST_ITEM: { [key: string]: DynHostItem<PatientsListComponent> } = {
		"formList": new DynHostItem(PatientsListComponent),
		"formListRecent": new DynHostItem(RecentPatientsListComponent)
	};

	//#endregion

	//#region PROPERTIES

	/** "patientsBook". */
	public readonly id: string = "patientsBook";
	public readonly componentId: string = GuidHelper.newGuid();

	public slideboxData: ISlideboxData;

	//#endregion

	//#region METHODS

	constructor(
		private isvcSlidebox: SlideboxService,
		private isvcMenu: MenuService,
		poParentPage: DynamicPageComponent<ComponentBase>) {

		super(poParentPage);
	}

	protected onLifeCycleEvent(poValue: IApplicationEvent): void {
		if (poValue.type === EApplicationEventType.LifeCycleEvent) {

			switch ((poValue as ILifeCycleEvent).data.value) {

				case ELifeCycleEvent.viewWillLeave:
					this.onIonViewWillLeave();
					break;
			}
		}
	}

	public ngOnInit(): void {
		this.isvcSlidebox.initSlideboxData(this.id, PatientsBookComponent.C_MAP_STRING_TO_DYN_HOST_ITEM, this.componentId)
			.pipe(takeUntil(this.destroyed$))
			.subscribe(
				(poResult: ISlideboxData) => this.slideboxData = poResult,
				poError => console.error(`PATBOOK.C:: Erreur init slidebox :`, poError)
			);
	}

	/** Quand on sort de la page on reset la toolbar pour ne plus avoir de boutons d'ajout de patients. */
	public onIonViewWillLeave(): void {
		this.isvcMenu.clearPopover();
	}

	//#endregion
}
