export abstract class RppsHelper {

	//#region METHODS

	/** Teste une chaîne est indique si elle correspond au format RPPS (11 chiffres).
	 * @param psValue Texte à valider.
	 * @returns 
	 */
	public static isValid(psValue: string): boolean {
		return /^[0-9]{11}$/.test(psValue);
	}

	//#endregion

}
