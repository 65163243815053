import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { IonicModule } from '@ionic/angular';
import { DateModule } from '@osapp/components/date';
import { ModalModule } from '@osapp/modules/modal';
import { ObservationsModule } from '@osapp/modules/observations/observations.module';
import { InterventionStatementModule } from '../../intervention-statement/intervention-statement.module';
import { ValidateActesComponent } from './validate-actes.component';

@NgModule({
	imports: [
		IonicModule,
		CommonModule,
		FormsModule,
		DateModule,
		ModalModule,
		MatFormFieldModule,
		MatInputModule,
		ObservationsModule,
		InterventionStatementModule
	],
	exports: [ValidateActesComponent],
	declarations: [ValidateActesComponent],
	providers: []
})
export class ValidateActesModule { }