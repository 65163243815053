import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonItemSliding } from '@ionic/angular';
import { DynamicPageComponent } from '@osapp/components/dynamicPage/dynamicPage.component';
import { SearchComponent } from '@osapp/components/search/search.component';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { BooleanHelper } from '@osapp/helpers/boolean.helper';
import { ContactHelper } from '@osapp/helpers/contactHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { MapHelper } from '@osapp/helpers/mapHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { UserHelper } from '@osapp/helpers/user.helper';
import { ListComponentBase2 } from '@osapp/model/ListComponentBase2';
import { UserData } from '@osapp/model/application/UserData';
import { EBarElementDock } from '@osapp/model/barElement/EBarElementDock';
import { EBarElementPosition } from '@osapp/model/barElement/EBarElementPosition';
import { IBarElement } from '@osapp/model/barElement/IBarElement';
import { IContact } from '@osapp/model/contacts/IContact';
import { IContactsSelectorParams } from '@osapp/model/contacts/IContactsSelectorParams';
import { IGroup } from '@osapp/model/contacts/IGroup';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { IAvatar } from '@osapp/model/picture/IAvatar';
import { IReadStatus } from '@osapp/model/read-status/IReadStatus';
import { ISearchOptions } from '@osapp/model/search/ISearchOptions';
import { IUiResponse } from '@osapp/model/uiMessage/IUiResponse';
import { EFilterActionReturnType } from '@osapp/modules/filter/model/EFilterActionReturnType';
import { EFilterType } from '@osapp/modules/filter/model/EFilterType';
import { IFilterValuesChangedEvent } from '@osapp/modules/filter/model/IFilterValuesChangedEvent';
import { IFilterbarOptions } from '@osapp/modules/filter/model/IFilterbarOptions';
import { IFilterbarParams } from '@osapp/modules/filter/model/IFilterbarParams';
import { Loader } from '@osapp/modules/loading/Loader';
import { ObservableArray } from '@osapp/modules/observable/models/observable-array';
import { C_SECTORS_ROLE_ID } from '@osapp/modules/permissions/services/permissions.service';
import { IFavorites } from '@osapp/modules/preferences/favorites/model/IFavorites';
import { FavoritesService } from '@osapp/modules/preferences/favorites/services/favorites.service';
import { ISector } from '@osapp/modules/sectors/models/isector';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { ContactsService } from '@osapp/services/contacts.service';
import { GroupsService } from '@osapp/services/groups.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '@osapp/services/loading.service';
import { ReadStatusService } from '@osapp/services/read-status.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { Observable, ReplaySubject, Subject, from, of } from 'rxjs';
import { catchError, debounceTime, filter, mapTo, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { C_PREFIX_RAPPORT } from '../../../../app/app.constants';
import { IIdelizyContact } from '../../../../model/IIdelizyContact';
import { IPatient } from '../../model/IPatient';
import { ITransmissionRapport } from '../../model/ITransmissionRapport';
import { ITransmissionsListParams } from '../../model/ITransmissionsListParams';
import { PatientsService } from '../../services/patients.service';
import { RapportService } from '../../services/rapport.service';
import { TransmissionModalComponent } from './transmission-modal/transmission-modal.component';

enum ESort {
	patient = "patient",
	date = "date"
}

@Component({
	selector: "transmissions-list",
	templateUrl: './transmissions-list.component.html',
	styleUrls: ['./transmissions-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransmissionsListComponent extends ListComponentBase2<ITransmissionRapport> implements OnInit, OnDestroy {

	//#region FIELDS

	private static readonly C_FILTER_PATIENTS_ID = "patientsFilterId";
	private static readonly C_FILTER_DATE_ID = "dateFilterId";
	private static readonly C_FILTER_AUTHORS_USERS_ID = "authorsUsersFilterId";

	private moWaitingParamsSubject = new ReplaySubject<void>(1);
	/** Tableau des recherchers dans les slides. */
	private maSearchValues: string[] = [];
	/** Tableau de tous les patients qui ont une transmission. */
	private maAllPatients: IPatient[];
	/** Tableau filtré des patients ayant une ou plusieurs transmission(s) associée(s). */
	private maFilterSelectedPatients: IPatient[];
	/** Date sélectionnée pour le filtrage. */
	private mdFilterSelectedDate?: Date;
	/** Tableau de tous les contacts auteurs d'une transmission. */
	private maAllAuthors: IIdelizyContact[];
	/** Tableau filtré des contacts auteurs d'une transmission. */
	private maFilterSelectedAuthors: IIdelizyContact[];
	/** Tableau des transmissions filtrées par la recherche. */
	private maSearchedTransmissions: ITransmissionRapport[] = [];
	private maSelectedSectors: IGroup[];
	private moGroupIdsByPatientId: Map<string, string[]>;
	private moUpdateFavoritesSubject = new Subject<string>();

	//#endregion

	//#region PROPERTIES

	public readonly C_TRANSMISSION_DATE_FORMAT: string = ETimetablePattern.dd_MM_yyyy_HH_mm_slash;

	private moParams: ITransmissionsListParams;
	public get params(): ITransmissionsListParams { return this.moParams; }
	@Input() public set params(poValue: ITransmissionsListParams) {
		if (poValue) {
			this.moParams = poValue;
			this.moWaitingParamsSubject.next();
		}
	}

	@ViewChild("search") public searchComponent: SearchComponent<ITransmissionRapport>;

	public readStatusesByTransmissionId = new Map<string, IReadStatus>();
	/** Map des avatars des auteurs de transmissions, indexés par chemin de l'auteur de la transmission. */
	public avatarsByAuthorPathMap: Map<string, IAvatar> = new Map();
	public patientByTransmissionIds: Map<string, IPatient> = new Map();
	public authorsById: Map<string, IContact> = new Map();
	/** Indique si toutes les transmissions sont listées ou non. */
	public isListingAllTransmissions: boolean;
	/** Tableau des transmissions pour la slide 'non clôturées'. */
	public ongoingTransmissions: ITransmissionRapport[];
	/** Tableau des transmissions pour la slide 'clôturées'. */
	public closedTransmissions: ITransmissionRapport[];
	/** Nombre de filtres en cours. */
	public filtersCount = 0;
	/** Tableau des paramètres pour la barre de filtrage. */
	public filterbarParams: IFilterbarParams[] = [];
	/** Index de la slide courante. */
	public currentIndex = 0;
	public sectorSelectOptions: ISelectOption<IGroup>[] = [];

	/** Tableau des transmissions en fonction de la slide courante. */
	private maCurrentTransmissions: ITransmissionRapport[];
	/** Tableau des transmissions en fonction de la slide courante. */
	private get currentTransmissions(): ITransmissionRapport[] {
		if (this.isListingAllTransmissions)
			return this.currentIndex === 0 ? this.ongoingTransmissions : this.closedTransmissions;
		else
			return this.maCurrentTransmissions;
	}
	private set currentTransmissions(paNewValues: ITransmissionRapport[]) {
		if (this.isListingAllTransmissions) {
			if (this.currentIndex === 0)  // Slide 'non clôturées'.
				this.ongoingTransmissions = paNewValues.filter((poTransmission: ITransmissionRapport) => !poTransmission.closeDate);
			else // Slide 'clôturées'.
				this.closedTransmissions = paNewValues.filter((poTransmission: ITransmissionRapport) => poTransmission.closeDate);
		}
		else
			this.maCurrentTransmissions = paNewValues;
	}

	public get filteredDocuments(): ITransmissionRapport[] {
		return this.filteredDocumentsByIndex[this.currentIndex];
	}
	public set filteredDocuments(paNewValues: ITransmissionRapport[]) {
		if (!ArrayHelper.areArraysFromDatabaseEqual(this.filteredDocuments, paNewValues))
			this.filteredDocumentsByIndex[this.currentIndex].resetArray(paNewValues);
	}

	public filteredDocumentsByIndex: ObservableArray<ITransmissionRapport>[] = [new ObservableArray, new ObservableArray];
	private meSelectedSort = ESort.date;
	public get selectedSort(): ESort {
		return this.meSelectedSort;
	}
	public set selectedSort(peNewSort: ESort) {
		if (peNewSort !== this.meSelectedSort) {
			this.moUpdateFavoritesSubject.next(this.meSelectedSort = peNewSort ?? ESort.date);
			this.sortDocuments();
			this.detectChanges();
		}
	}
	public sortSelectOptions: ISelectOption<ESort>[] = [{ label: "Patient", value: ESort.patient }, { label: "Date", value: ESort.date }];

	//#endregion

	//#region METHODS

	constructor(
		private isvcReadStatus: ReadStatusService,
		private isvcRapport: RapportService,
		private isvcPatients: PatientsService,
		private ioRoute: ActivatedRoute,
		private isvcUiMessage: UiMessageService,
		private isvcContacts: ContactsService,
		private isvcGroups: GroupsService,
		private isvcLoading: LoadingService,
		private isvcFavorites: FavoritesService,
		@Optional() private moParentComponent: DynamicPageComponent<ComponentBase>,
		poChangeDetectorRef: ChangeDetectorRef
	) {

		super(poChangeDetectorRef);
	}

	public ngOnInit(): void {
		// Initialise le tri des transmissions.
		this.isvcFavorites.get(C_PREFIX_RAPPORT, true)
			.pipe(
				filter((poPreferences: IFavorites) => !StringHelper.isBlank(poPreferences?.sort) && poPreferences.sort !== this.selectedSort),
				tap((poPreferences: IFavorites) => this.selectedSort = poPreferences.sort as ESort),
				takeUntil(this.destroyed$)
			).subscribe();

		// On initialise la sauvegarde du tri
		this.moUpdateFavoritesSubject.asObservable()
			.pipe(
				debounceTime(1000),
				switchMap((peSort: ESort) => this.isvcFavorites.setSort(C_PREFIX_RAPPORT, peSort))
			).subscribe();

		this.searchOptions = this.createSearchOptions();

		this.moWaitingParamsSubject.asObservable()
			.pipe(
				mergeMap(_ => this.init()),
				tap(_ => this.detectChanges()),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.isListingAllTransmissions = BooleanHelper.convertToBoolean((this.ioRoute.snapshot.queryParams as ITransmissionsListParams).all ?? false);

		// Si on veut lister toutes les transmissions on n'est pas censé avoir de `params` donc on initialise le champs pour ne pas avoir d'erreur `undefined`.
		if (this.isListingAllTransmissions && !this.params)
			this.params = {};
	}

	public ngOnDestroy(): void {
		this.moWaitingParamsSubject.complete();
		this.moUpdateFavoritesSubject.complete();

		if (this.params?.useToolbar)
			this.moParentComponent?.toolbar.clear(this.msInstanceId);

		super.ngOnDestroy();
	}

	private init(): Observable<boolean> {
		let loLoader: Loader;

		return from(this.isvcLoading.create("Chargement des transmissions..."))
			.pipe(
				mergeMap((poLoader: Loader) => poLoader.present()),
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap(() => this.isvcGroups.getGroupsByRoles([C_SECTORS_ROLE_ID])),
				tap((paSectors: ISector[]) => this.sectorSelectOptions = paSectors.filter(((poSector: ISector) => poSector.siteId === UserData.currentSite._id)).map((poSector: IGroup) => ({ label: poSector.name, value: poSector }))),
				mergeMap(() => this.isvcRapport.getTransmissions(this.params.model?._id)),
				tap(_ => {
					if (this.params?.useToolbar) // Si on a le paramètre de renseigner, c'est qu'il faut utiliser la toolbar et pas un bouton ionic.
						this.manageToolbar();
				}),
				switchMap((paResults: ITransmissionRapport[]) => this.initDocuments(paResults)),
				mergeMap((paResults: ITransmissionRapport[]) => this.getAuthorsAndCloseUsers(paResults).pipe(mapTo(paResults))),
				tap(_ => this.initFilterbarParams()),
				switchMap((paResults: ITransmissionRapport[]) => {
					return this.isvcReadStatus.getReadStatusesById(paResults, true)
						.pipe(
							tap((poReadStatuses: Map<string, IReadStatus>) => this.readStatusesByTransmissionId = poReadStatuses),
							mapTo(paResults)
						);
				}),
				mergeMap((paTransmissions: ITransmissionRapport[]) =>
					this.isvcContacts.getAvatarsByContactPaths(paTransmissions.map((poTransmission: ITransmissionRapport) => poTransmission.authorPath))
				),
				tap((poAvatarMapResult: Map<string, IAvatar>) => this.avatarsByAuthorPathMap = poAvatarMapResult),
				mergeMap(() => this.isvcGroups.getContactsGroupIds(MapHelper.valuesToArray(this.patientByTransmissionIds))),
				tap((poGroupIdsByPatientIds: Map<string, string[]>) => this.moGroupIdsByPatientId = poGroupIdsByPatientIds),
				mergeMap(() => {
					const laAuthorIds: string[] = [];
					this.documents.forEach((poTransmission: ITransmissionRapport) => ArrayHelper.pushIfNotPresent(laAuthorIds, this.getAuthorId(poTransmission)));
					return this.isvcContacts.getContactsByIds(laAuthorIds);
				}),
				tap((paContacts: IContact[]) => paContacts.forEach((poContact: IContact) => this.authorsById.set(poContact._id, poContact))),
				mapTo(true),
				tap(() => loLoader.dismiss()),
				catchError(poError => {
					loLoader?.dismiss();
					console.error("TRANS.LIST.C:: ", poError);
					return of(false);
				})
			) as Observable<boolean>;
	}

	public getAuthorId(poTransmission: ITransmissionRapport): string {
		return poTransmission.authorPath.split("/")[1];
	}

	private initDocuments(paDocuments: ITransmissionRapport[]): Observable<ITransmissionRapport[]> {
		let loInit$: Observable<ITransmissionRapport[]>;

		if (!this.params.model) {
			loInit$ = this.isvcRapport.getPatientByTransmissionsIds(paDocuments, true)
				.pipe(
					tap((poPatientByTransmissionsIds: Map<string, IPatient>) => {
						this.patientByTransmissionIds = poPatientByTransmissionsIds;
						this.maAllPatients = ArrayHelper.unique(MapHelper.valuesToArray(poPatientByTransmissionsIds));
					}),
					mapTo(paDocuments)
				);
		}
		else {
			loInit$ = of(paDocuments)
				.pipe(
					tap((paTransmissions: ITransmissionRapport[]) => {
						paTransmissions.forEach((poTransmission: ITransmissionRapport) => this.patientByTransmissionIds.set(poTransmission._id, this.params.model));
						this.maAllPatients = [this.params.model];
					})
				);
		}

		return loInit$.pipe(tap((paResults: ITransmissionRapport[]) => this.currentTransmissions = this.documents = paResults));
	}

	/** @override */
	protected sortDocuments(paDocuments: ITransmissionRapport[] = this.filteredDocuments): void {
		// Dans tous les cas on tri par date.
		paDocuments
			.sort((poTransmissionA: ITransmissionRapport, poTransmissionB: ITransmissionRapport) =>
				DateHelper.compareTwoDates(new Date(poTransmissionB.createdDate), new Date(poTransmissionA.createdDate))
			);

		if (this.selectedSort === ESort.patient) // Puis par patient si besoin
			paDocuments.sort((poTransmissionA: ITransmissionRapport, poTransmissionB: ITransmissionRapport) =>
				ContactHelper.getCompleteFormattedName(this.patientByTransmissionIds.get(poTransmissionA._id)).toLowerCase().localeCompare(ContactHelper.getCompleteFormattedName(this.patientByTransmissionIds.get(poTransmissionB._id)).toLowerCase())
			);
	}

	/** Récupération des contacts des auteurs et de ceux ayant clôturé une transmission ou plus.
	 * @param paTransmissions Tableau de toutes les transmissions à partir duquel récupérer les contacts.
	 */
	private getAuthorsAndCloseUsers(paTransmissions: ITransmissionRapport[]): Observable<IIdelizyContact[]> {
		return this.isvcContacts.getContactsByPaths(paTransmissions.map((poItem: ITransmissionRapport) => poItem.authorPath))
			.pipe(tap((paContacts: IIdelizyContact[]) => this.maAllAuthors = ArrayHelper.unique(paContacts)));
	}

	/** @override */
	protected createSearchOptions(): ISearchOptions<ITransmissionRapport> {
		return {
			hasPreFillData: true,
			searchboxPlaceholder: "Rechercher une transmission",
			searchFunction: (poTransmission: ITransmissionRapport, psSearchValue: string) => this.transmissionSearch(poTransmission, psSearchValue)
		};
	}

	/** Recherche la correspondance de la recherche avec une transmission.
	 * @param poTransmission Transmission dans la quelle rechercher une correspondance avec la valeur.
	 * @param psSearchValue Valeur recherchée.
	 */
	private transmissionSearch(poTransmission: ITransmissionRapport, psSearchValue: string): boolean {
		const lsSearchValue: string = psSearchValue.toLowerCase();

		return this.checkSearchValueInTransmission(poTransmission, lsSearchValue) ||
			this.checkSearchValueInPatient(lsSearchValue, this.patientByTransmissionIds.get(poTransmission._id));
	}

	/** Vérifie la correspondance d'une transmission avec la valeur recherchée.
	 * @param poTransmission Transmission dont il faut vérifier la correspondance avec la valeur recherchée.
	 * @param psSearchValue Valeur recherchée.
	 */
	private checkSearchValueInTransmission(poTransmission: ITransmissionRapport, psSearchValue: string): boolean {
		return this.checkSearchValueByProperties(
			[poTransmission.problem, poTransmission.data, poTransmission.interventions, poTransmission.results, poTransmission.transmissionType],
			psSearchValue
		);
	}

	/** Vérifie la correspondance d'un patient avec la valeur recherchée.
	 * @param psSearchValue Valeur recherchée.
	 * @param poPatient Patient dont il faut vérifier la correspondance avec la valeur recherchée.
	 */
	private checkSearchValueInPatient(psSearchValue: string, poPatient?: IPatient): boolean {
		// Il faut vérifier le patient car si une nouvelle transmission vient d'être créée avec un nouveau patient, on ne l'a pas encore récupéré.
		return poPatient && this.checkSearchValueByProperties([poPatient.firstName, poPatient.lastName], psSearchValue);
	}

	/** Vérifie la présence d'au moins une correspondance de propriété avec la valeur recherchée.
	 * @param paProperties Tableau des propriétés dont il faut vérifier une correspondance avec la valeur recherchée.
	 * @param psSearchValue Valeur recherchée.
	 */
	private checkSearchValueByProperties(paProperties: string[], psSearchValue: string): boolean {
		return paProperties.some((psProperty: string) => !StringHelper.isBlank(psProperty) && psProperty.toLowerCase().includes(psSearchValue));
	}

	/** Ouvre dans une page ou une modale la transmission cliquée. */
	public onTransmissionClicked(poTransmission: ITransmissionRapport): void {
		if (this.params.openClickedItemAsModal) {
			this.isvcRapport.openTransmissionAsModal(TransmissionModalComponent, { transmission: poTransmission })
				.pipe(takeUntil(this.destroyed$))
				.subscribe();
		}
		else
			this.isvcRapport.routeToTransmission(poTransmission);
	}

	/** Crée une nouvelle transmission pour le patient courant s'il y en a un
	 * ou ouvre un sélecteur de patient pour choisir auquel lier la nouvelle transmission . */
	public createNewTransmission(): void {
		const loPatient$: Observable<IPatient> = !this.params?.model ?
			this.isvcPatients.selectPatient("Nouvelle transmission") : of(this.params?.model);

		loPatient$
			.pipe(tap((poSelectedPatient: IPatient) => this.isvcRapport.routeToNewTransmission(poSelectedPatient)))
			.subscribe();
	}

	/** Supprime une transmission spécifique.
	 * @param poTransmission Transmission à supprimer.
	 * @param poItemSliding ItemSliding à fermer après suppression.
	 */
	public onRemoveTransmission(poTransmission: ITransmissionRapport, poItemSliding: IonItemSliding): void {
		this.isvcUiMessage.showAsyncMessage<boolean>(
			new ShowMessageParamsPopup({
				header: "Attention",
				message: "Vous êtes sur le point de supprimer la transmisison.",
				buttons: [
					{ text: "Annuler", cssClass: "", role: undefined, handler: () => UiMessageService.getFalsyResponse() },
					{ text: "Confirmer", cssClass: "", role: undefined, handler: () => UiMessageService.getTruthyResponse() }
				]
			})
		)
			.pipe(
				mergeMap((poResult: IUiResponse<boolean>) => poResult.response ? this.isvcRapport.deleteTransmission(poTransmission) : of(undefined)),
				tap(_ => this.openOrCloseItemSliding(poItemSliding)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Survient lors d'un changement de slide.
	 * @param poEvent Événement indiquant qu'un changement de slide a eu lieu.
	 */
	public onTabChanged(poEvent: CustomEvent<{ changed: boolean, index: number }>): void {
		if (poEvent.detail.changed) {
			this.currentIndex = poEvent.detail.index;
			// On n'utilise pas la propriété bindée au composant [searchValue] car cela donne un rendu qui clignote beaucoup trop.
			this.searchComponent.searchFormControl.setValue(this.maSearchValues[this.currentIndex]);

			this.initFilterbarParams();
		}
	}

	/** Survient lors d'un changement de la valeur de recherche dans la slide courante.
	 * @param psEvent Événement corresponant à la valeur de la recherche pour la slide courante.
	 */
	public onSearchValueChanged(psEvent: string): void {
		this.maSearchValues[this.currentIndex] = psEvent;
	}

	/** @override */
	public onFilteredDocumentsChanged(paChanges?: ITransmissionRapport[]): void {
		if (paChanges)// Si on a des changements, il faut impacter le tableau de recherche.
			this.maSearchedTransmissions = paChanges;

		// Application des filtres de la barre de filtrage et initialisation du tableau courant en fonction de la slide.
		this.currentTransmissions = this.applyFilterValues();

		// On envoie le tableau des transmissions mis à jour en fonction de la slide courante.
		super.onFilteredDocumentsChanged(this.filterBySectors(this.currentTransmissions, this.maSelectedSectors));
	}

	/** Permute la valeur pour cacher ou non la zone de filtrage. */
	public swapFilterbarHiddenParams(): void {
		const loCurrentFilterbarParams: IFilterbarParams = this.filterbarParams[this.currentIndex];
		loCurrentFilterbarParams.hidden = !loCurrentFilterbarParams.hidden;
	}

	/** Initialise les paramètres de filtrage. */
	private initFilterbarParams(): void {
		this.resetCurrentFilters();

		this.filterbarParams = [
			{
				filters: this.createFilterbarOptions(0),
				hasResetButton: true,
				hidden: true
			} as IFilterbarParams
		];

		if (this.isListingAllTransmissions) {
			this.filterbarParams.push({
				filters: this.createFilterbarOptions(1),
				hasResetButton: true,
				hidden: true
			} as IFilterbarParams);
		}
	}

	/** Crée les différentes options de filtrage à l'aide d'un index de suffixe. */
	private createFilterbarOptions(pnSuffixIndex: number): IFilterbarOptions[] {
		const laFilterbarOptions: IFilterbarOptions[] = [
			this.createDateFilterbarOptions(pnSuffixIndex),
			this.createAuthorsAndUsersFilterbarOptions(pnSuffixIndex)
		];
		if (this.isListingAllTransmissions)
			laFilterbarOptions.unshift(this.createPatientsFilterbarOptions(pnSuffixIndex));
		return laFilterbarOptions;
	}

	/** Crée les options de filtrage par patients à l'aide d'un index pour le suffixe de l'identifiant. */
	private createPatientsFilterbarOptions(pnSuffixIndex: number): IFilterbarOptions {
		return {
			id: `${PatientsService.C_FILTER_PATIENTS_ID}${pnSuffixIndex}`,
			icon: "person-circle",
			returnType: EFilterActionReturnType.observable,
			filterType: EFilterType.avatar,
			action: () => {
				return this.isvcPatients.selectPatients(
					this.maFilterSelectedPatients.map((poPatient: IPatient) => poPatient._id),
					this.maAllPatients,
					"Filtrer par patient"
				);
			}
		} as IFilterbarOptions;
	}

	/** Crée les options de filtrage par date à l'aide d'un index pour le suffixe de l'identifiant. */
	private createDateFilterbarOptions(pnSuffixIndex: number): IFilterbarOptions {
		return {
			id: `${TransmissionsListComponent.C_FILTER_DATE_ID}${pnSuffixIndex}`,
			icon: "timetable",
			returnType: EFilterActionReturnType.undefined,
			filterType: EFilterType.datepicker
		} as IFilterbarOptions;
	}

	/** Crée les options de filtrage par auteurs et "clôtureurs" à l'aide d'un index pour le suffixe de l'identifiant. */
	private createAuthorsAndUsersFilterbarOptions(pnSuffixIndex: number): IFilterbarOptions {
		return {
			id: `${TransmissionsListComponent.C_FILTER_AUTHORS_USERS_ID}${pnSuffixIndex}`,
			icon: "addressbook",
			returnType: EFilterActionReturnType.observable,
			filterType: EFilterType.avatar,
			action: () => {
				const lsUserContactId: string = UserHelper.getUserContactId();
				// On crée les paramètres du sélecteur de prescripteur dans la fonction directement
				// pour prendre en compte les modifications des prescripteurs présélectionnés.
				const loSelectorParams: IContactsSelectorParams = {
					userContactVisible: this.maAllAuthors.some((poContact: IIdelizyContact) => poContact._id === lsUserContactId),
					hasSearchbox: true,
					preSelectedIds: this.maFilterSelectedAuthors.map((poContact: IIdelizyContact) => poContact._id),
					selectionMinimum: 0,
					contactsData: this.maAllAuthors,
					allSelectionButton: true,
					searchOptions: {
						searchboxPlaceholder: "Rechercher un auteur ou clôtureur",
						searchableFields: [{ key: "firstName" }, { key: "lastName" }]
					}
				};

				return this.isvcContacts.openContactsSelectorAsModal(loSelectorParams, "Filtrer par auteur/clôtureur") as Observable<IPatient[]>;
			}
		} as IFilterbarOptions;
	}

	/** Réinitialise les filtres en cours. */
	private resetCurrentFilters(): void {
		this.maFilterSelectedPatients = [];
		this.maFilterSelectedAuthors = [];
		this.mdFilterSelectedDate = undefined;
	}

	/** Événement de réinitialisation du filtrage. */
	public onFilterbarResetEvent(pbIsReset: boolean): void {
		this.filterbarParams[this.currentIndex].hidden = pbIsReset;
		this.resetCurrentFilters();
		this.onFilteredDocumentsChanged(this.documents);
	}

	/** Événement de mise à jour du nombre de filtrage en cours. */
	public onFilterCountChanged(pnFilterCount: number): void {
		this.filtersCount = pnFilterCount;
	}

	/** Événement de mise à jour des valeurs pour un filtre. */
	public onFilterValuesChanged(poEvent: IFilterValuesChangedEvent<IIdelizyContact | Date | IPatient>): void {
		// On modifie les valeurs des filtres de la barre de filtrage avec les nouveaux changements.
		this.setFilterValuesChanged(poEvent);
		// On appelle la mise à jour des documents filtrés.
		this.onFilteredDocumentsChanged();
	}

	/** Modifie un des champs du comopsant pour garder en mémoire les différents filtres en cours. */
	private setFilterValuesChanged(poEvent: IFilterValuesChangedEvent<IIdelizyContact | Date | IPatient>): void {
		if (poEvent.id.includes(TransmissionsListComponent.C_FILTER_AUTHORS_USERS_ID))
			this.maFilterSelectedAuthors = poEvent.changes as IIdelizyContact[];

		else if (poEvent.id.includes(TransmissionsListComponent.C_FILTER_DATE_ID))
			this.mdFilterSelectedDate = ArrayHelper.getFirstElement(poEvent.changes) as Date;

		else if (poEvent.id.includes(TransmissionsListComponent.C_FILTER_PATIENTS_ID))
			this.maFilterSelectedPatients = poEvent.changes as IPatient[];

		else
			console.warn(`TRANS.LIST.C:: L'identifiant de changements de valeur de filtres '${poEvent.id}' est inconnu.`);
	}

	/** Applique les filtres de la barre de filtrage sur les documents à afficher et retourne le nouveau tableau obtenu. */
	private applyFilterValues(): ITransmissionRapport[] {
		let laResults: ITransmissionRapport[] = this.maSearchedTransmissions;

		// On filtre les auteurs/clôtureurs s'il y en a.
		if (ArrayHelper.hasElements(this.maFilterSelectedAuthors)) {
			laResults = laResults.filter((poTransmission: ITransmissionRapport) =>
				this.maFilterSelectedAuthors.some((poContact: IIdelizyContact) => poTransmission.authorPath.includes(poContact._id))
			);
		}

		// On filtre les patients s'il y en a.
		if (ArrayHelper.hasElements(this.maFilterSelectedPatients)) {
			laResults = laResults.filter((poTransmission: ITransmissionRapport) => {
				const lsPatientId: string = this.patientByTransmissionIds.get(poTransmission._id)._id;
				return this.maFilterSelectedPatients.some((poPatient: IPatient) => lsPatientId === poPatient._id);
			}
			);
		}

		// On filtre la date s'il y en a une.
		if (DateHelper.isDate(this.mdFilterSelectedDate))
			laResults = laResults.filter((poItem: ITransmissionRapport) => DateHelper.diffDays(this.mdFilterSelectedDate, poItem.createdDate) === 0);

		return laResults;
	}

	private manageToolbar(): void {
		// Si on n'a pas ajouté la toolbar, on peut l'initialiser.
		if (!this.moParentComponent?.toolbar.eltContainer.some((poBarElement: IBarElement) => poBarElement.componentId === this.msInstanceId)) {
			this.moParentComponent.toolbar.init(
				[
					{
						id: "circle",
						component: "fabButton",
						dock: EBarElementDock.bottom,
						position: EBarElementPosition.right,
						icon: "add",
						onTap: () => this.createNewTransmission(),
						name:"Ajouter"
					}
				] as IBarElement[],
				this.msInstanceId
			);

			this.detectChanges();
		}
	}

	public onSectorSelectionChanged(paSelectedSectors: IGroup[]): void {
		this.maSelectedSectors = paSelectedSectors;

		this.onFilteredDocumentsChanged(this.searchComponent.search());
	}

	private filterBySectors(paTransmissions: ITransmissionRapport[], paSectors: IGroup[]): ITransmissionRapport[] {
		return !ArrayHelper.hasElements(paSectors) ? paTransmissions : paTransmissions.filter((poTransmission: ITransmissionRapport) => {
			const lsPatientId: string = this.patientByTransmissionIds.get(poTransmission._id)?._id;

			return paSectors.some((poSector: IGroup) => this.moGroupIdsByPatientId.get(lsPatientId)?.includes(poSector._id));
		});
	}

	public onSortChanged(paSelectedSorts: ESort[]): void {
		this.selectedSort = ArrayHelper.getFirstElement(paSelectedSorts);
	}

	//#endregion

}
