import { ObjectHelper } from '../../../helpers/objectHelper';

export class MailError extends Error {

	constructor(psMessage: string = "Une erreur est survenue lors de la création de l'email.") {
		super(psMessage);
		// Nécessaire pour que le 'instanceof NotImplementedError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, MailError);
	}

}