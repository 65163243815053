import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastButton } from '@ionic/core';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { MapHelper } from '@osapp/helpers/mapHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { EPrefix } from '@osapp/model/EPrefix';
import { IEventMarker } from '@osapp/model/calendar/IEventMarker';
import { IGroup } from '@osapp/model/contacts/IGroup';
import { IConversation } from '@osapp/model/conversation/IConversation';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { ActivePageManager } from '@osapp/model/navigation/ActivePageManager';
import { EChangeType } from '@osapp/model/store/EChangeType';
import { IChangeEvent } from '@osapp/model/store/IChangeEvent';
import { IUiResponse } from '@osapp/model/uiMessage/IUiResponse';
import { Loader } from '@osapp/modules/loading/Loader';
import { PerformanceManager } from '@osapp/modules/performance/PerformanceManager';
import { C_SECTORS_ROLE_ID, IHasPermission, Permissions, PermissionsService } from '@osapp/modules/permissions/services/permissions.service';
import { IFavorites } from '@osapp/modules/preferences/favorites/model/IFavorites';
import { FavoritesService } from '@osapp/modules/preferences/favorites/services/favorites.service';
import { PrestationService } from '@osapp/modules/prestation/services/prestation.service';
import { ContactsService } from '@osapp/services/contacts.service';
import { EventService } from '@osapp/services/event.service';
import { GroupsService } from '@osapp/services/groups.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '@osapp/services/interfaces/ShowMessageParamsToast';
import { LoadingService } from '@osapp/services/loading.service';
import { PlatformService } from '@osapp/services/platform.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { BehaviorSubject, EMPTY, Observable, Subject, defer, of } from 'rxjs';
import { debounce, filter, finalize, map, mergeMap, skip, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { C_DESMOS_PERMISSION_ID, C_PREFIX_ACT, C_PREFIX_PATIENT, C_PREFIX_TRAITEMENT } from '../../../../app/app.constants';
import { EStatusSeance } from '../../../../model/EStatusSeance';
import { ITourneePageRouteParams } from '../../../../model/ITourneePageRouteParams';
import { ITraitement } from '../../../../model/ITraitement';
import { Seance } from '../../../../model/Seance';
import { ISeanceTournee } from '../../../../model/seances/ISeanceTournee';
import { SeanceService } from '../../../../services/seance.service';
import { EResumeActeMode } from '../../../actes/model/EResumeActeMode';
import { ETourneeDisplayMode } from '../../model/ETourneeDisplayMode';
import { ETourneeMode } from '../../model/ETourneeMode';
import { ITourneeFilterOptions } from '../../model/ITourneeFilterOptions';
import { ITourneeFilterValues } from '../../model/ITourneeFilterValues';
import { TourneeFilterService } from '../../services/tournee-filter.service';
import { TourneesService } from '../../services/tournees.service';


interface ISeanceTourneeGroup {
	key: string;
	seanceTournees: ISeanceTournee[];
}

@Component({
	selector: "idl-tournee-page",
	templateUrl: './tournee-page.component.html',
	styleUrls: ['./tournee-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TourneePageComponent extends ComponentBase implements OnInit, OnDestroy, IHasPermission {

	//#region FIELDS

	private maSeancesTournee: ISeanceTournee[] = [];
	private moGroupsByContactId: Map<string, IGroup[]>;
	private moGroupsByIds: Map<string, IGroup[]>;
	private mbIsGroupModeGroup = true;
	private moRefreshSubject = new Subject<void>();
	private moSeanceTourneesLoadingSubject = new BehaviorSubject<boolean>(false);
	private moActivePageManager = new ActivePageManager(this, this.ioRouter);
	private mbHasModalOpen: boolean;
	private moSeancesByTraitementId: Map<string, ISeanceTournee[]>;

	//#endregion

	//#region PROPERTIES
	private maFilteredSeances: ISeanceTournee[];
	public get filteredSeances(): ISeanceTournee[] {
		return this.maFilteredSeances;
	};
	public set filteredSeances(paFilteredSeances: ISeanceTournee[]) {
		if (!ArrayHelper.areArraysEqual(this.maFilteredSeances, paFilteredSeances)) {
			this.maFilteredSeances = paFilteredSeances?.sort((poSeanceTourneeA: ISeanceTournee, poSeanceTourneeB: ISeanceTournee) =>
				DateHelper.compareTwoDates(poSeanceTourneeA.seance.startDate, poSeanceTourneeB.seance.startDate)
			) ?? [];

			this.groupSeances();
		}
	}
	public groupedFilteredSeances: ISeanceTourneeGroup[] = [];
	/** Indique si on doit afficher les statuts des séances en mode planification ou non. */
	public showPlannedSeanceStatuses: boolean;
	/** Indique si le mode actuel est "planification" ou non (mode tournée). */
	public isPlanificationMode: boolean;
	public seanceTourneeToEdit: ISeanceTournee;

	/** Retourne le nombre de séances filtrées (celles qu'on voit actuellement) validées. */
	public get validatedSeancesCount(): number {
		return this.filteredSeances?.filter((poItem: ISeanceTournee) => poItem.seance.status === EStatusSeance.done).length ?? 0;
	}

	/** Retourne le nombre total de séances filtrées (celles qu'on voit actuellement). */
	public get totalSeancesCount(): number {
		return this.filteredSeances?.length ?? 0;
	}

	private moDisplayModeSubject = new BehaviorSubject<ETourneeDisplayMode>(undefined);
	private meDisplayMode: ETourneeDisplayMode;
	public get displayMode(): ETourneeDisplayMode {
		return this.meDisplayMode;
	}
	public set displayMode(peDisplayMode: ETourneeDisplayMode) {
		if (peDisplayMode !== this.meDisplayMode)
			this.moDisplayModeSubject.next(this.meDisplayMode = peDisplayMode);
	}
	public get displayMode$(): Observable<ETourneeDisplayMode> {
		return this.moDisplayModeSubject.asObservable();
	}

	public acteDisplayMode: EResumeActeMode;
	public isMobileApp: boolean;

	@Permissions("create", C_DESMOS_PERMISSION_ID)
	public get canBillToDesmos(): boolean {
		return true;
	}

	public get canBillToFsv(): boolean {
		return true;
	}

	//#endregion

	//#region METHODS

	constructor(
		private ioRoute: ActivatedRoute,
		private ioRouter: Router,
		public isvcSeance: SeanceService,
		public isvcTournees: TourneesService,
		private isvcTourneeFilter: TourneeFilterService,
		private isvcLoading: LoadingService,
		private isvcUiMessage: UiMessageService,
		private isvcGroups: GroupsService,
		private isvcContacts: ContactsService,
		private isvcPlatform: PlatformService,
		private ioSnackBar: MatSnackBar,
		private readonly isvcPrestation: PrestationService,
		private readonly isvcFavorites: FavoritesService,
		public readonly isvcPermissions: PermissionsService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);
	}

	public ngOnInit(): void {
		this.isMobileApp = this.isvcPlatform.isMobileApp;

		this.initTourneeModeSubscription();
		this.initFilterValuesSubscription();
		this.initSeanceTournees();
		this.initActeDisplayMode();
	}

	private initActeDisplayMode(): void {
		// Initialise le mode d'affichage du résumé des actes.
		this.isvcFavorites.get(C_PREFIX_ACT, true)
			.pipe(
				tap((poPreferences: IFavorites) => {
					if (poPreferences?.display) {
						this.acteDisplayMode = poPreferences.display as EResumeActeMode;

						this.detectChanges();
					}
				}),
				takeUntil(this.destroyed$)
			).subscribe();
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.ioSnackBar.dismiss();
		this.moDisplayModeSubject.complete();
		this.moRefreshSubject.complete();
		this.moSeanceTourneesLoadingSubject.complete();
	}

	/** @override */
	public detectChanges(): void {
		// Mode planif et taille d'écran < 992px  => cas où il ne faut pas afficher : on inverse la condition pour obtenir les conditions d'affichage.
		this.showPlannedSeanceStatuses = !(this.isPlanificationMode && !window.matchMedia("(min-width: 992px)").matches);
		super.detectChanges();
	}

	private groupSeances(): void {
		const loPerformance = new PerformanceManager();

		loPerformance.markStart();

		this.groupedFilteredSeances = [];

		if (!this.meDisplayMode || this.meDisplayMode === ETourneeDisplayMode.day) {
			if (this.isMobileApp)
				this.groupedFilteredSeances.push({ key: "", seanceTournees: [...(this.maFilteredSeances ?? [])] });
			else {
				this.maFilteredSeances.forEach((poSeanceTournee: ISeanceTournee) => {
					const lsIntervenantId: string = this.mbIsGroupModeGroup ? ArrayHelper.getFirstElement(poSeanceTournee.seance.intervenantIds) ?? "" : poSeanceTournee.seance.patientId;
					let laGroups: IGroup[];

					if (IdHelper.hasPrefixId(lsIntervenantId, EPrefix.contact))
						laGroups = this.moGroupsByContactId.get(lsIntervenantId);
					else if (IdHelper.hasPrefixId(lsIntervenantId, EPrefix.group))
						laGroups = this.moGroupsByIds.get(lsIntervenantId);
					else if (IdHelper.hasPrefixId(lsIntervenantId, C_PREFIX_PATIENT))
						laGroups = this.moGroupsByContactId.get(lsIntervenantId)?.filter((poGroup: IGroup) => this.isvcGroups.hasRole(poGroup, C_SECTORS_ROLE_ID));

					if (!ArrayHelper.hasElements(laGroups)) // Si ce contact n'est lié à aucuns groupes, c'est qu'il n'est pas intervenant.
						laGroups = [{ name: this.mbIsGroupModeGroup ? "Non intervenants" : "Non sectorisé" } as any];

					laGroups.forEach((poGroup: IGroup) => {
						const lnIndex: number = this.groupedFilteredSeances
							.findIndex((poGroupedSeancesTournee: ISeanceTourneeGroup) => poGroupedSeancesTournee.key === poGroup.name);
						let loResult: ISeanceTourneeGroup;

						if (lnIndex === -1)
							loResult = { key: poGroup.name, seanceTournees: [] };
						else
							loResult = this.groupedFilteredSeances[lnIndex];

						loResult.seanceTournees.push(poSeanceTournee);

						if (lnIndex === -1)
							this.groupedFilteredSeances.push(loResult);
					});
				});
			}
			ArrayHelper.dynamicSort(this.groupedFilteredSeances, "key");
		}
		else {
			const loGroupedSeanceTournees: Map<string, ISeanceTournee[]> = ArrayHelper.groupBy(this.maFilteredSeances,
				(poSeanceTournee: ISeanceTournee) => DateHelper.transform(poSeanceTournee.seance.startDate, ETimetablePattern.EEEE_dd_MMMM_yyyy)
			);

			loGroupedSeanceTournees.forEach((paSeanceTournees: ISeanceTournee[], psKey: string) => {
				this.groupedFilteredSeances.push({ key: psKey, seanceTournees: paSeanceTournees });
			});
		}

		console.debug(`TOUR.P.C::Group seances duration (ms) : ${loPerformance.markEnd().measure()} ;`);

		this.detectChanges();
	}

	private initTourneeModeSubscription(): void {
		this.isvcTournees.getTourneeModeObservable()
			.pipe(
				tap((peMode: ETourneeMode) => {
					this.isPlanificationMode = peMode === ETourneeMode.planification;
					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private initFilterValuesSubscription(): void {
		let loRunningLoader: Loader;
		this.isvcTourneeFilter.getFilterValuesAsObservable()
			.pipe(
				skip(1), // On ignore le premier résultat car il correspond au dernier résultat envoyé (possiblement la tournée d'une autre date).
				switchMap((poFilterValues: ITourneeFilterValues) => {
					let loLoader: Loader;
					return defer(() => {
						if (loRunningLoader?.isPresented)
							return loRunningLoader.dismiss();
						return of(undefined);
					}).pipe(
						mergeMap(() => {
							if (!this.mbHasModalOpen)
								return this.isvcLoading.create("Affichage de la tournée...");

							return of(undefined);
						}),
						mergeMap((poLoader?: Loader) => {
							if (poLoader) {
								loRunningLoader = loLoader = poLoader;
								return poLoader.present();
							}

							return of(undefined);
						}),
						tap(() => {
							this.mbIsGroupModeGroup = poFilterValues.isGroupModeGroup;
							this.filteredSeances = poFilterValues.filteredSeances;
							this.detectChanges();
						}),
						finalize(() => loLoader?.dismiss())
					);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private getQueryParamsDate(): Date {
		const ldShortDate = new Date((this.ioRoute.snapshot.params as ITourneePageRouteParams).date);

		return DateHelper.isDate(ldShortDate) ? ldShortDate : new Date();
	}

	private getStartDate(): Date {
		let ldStartDate: Date = DateHelper.resetDay(this.getQueryParamsDate());

		if (this.meDisplayMode === ETourneeDisplayMode.week)
			ldStartDate = DateHelper.resetWeek(ldStartDate);

		return ldStartDate;
	}

	private getEndDate(): Date {
		let ldEndDate: Date = this.getStartDate();

		if (this.meDisplayMode === ETourneeDisplayMode.week)
			ldEndDate = DateHelper.addDays(ldEndDate, 6);

		ldEndDate.setHours(23, 59, 59, 999);

		return ldEndDate;
	}

	private initServerChangesListening(): void {
		const laTraitementIds: string[] = this.maSeancesTournee.map((poSeanceTournee: ISeanceTournee) => poSeanceTournee.seance.traitementId);

		this.displayMode$
			.pipe(
				switchMap(() => this.isvcSeance.getSeancesUpdates("local", laTraitementIds, this.getStartDate(), this.getEndDate())),
				tap(() => this.moSeanceTourneesLoadingSubject.next(true)),
				takeUntil(this.destroyed$)
			).subscribe();

		let lbWaiting = false;
		this.displayMode$
			.pipe(
				switchMap(() => this.isvcSeance.getSeancesUpdates("remote", laTraitementIds, this.getStartDate(), this.getEndDate(), this.moActivePageManager)),
				debounce(() => this.moSeanceTourneesLoadingSubject.asObservable().pipe(filter((pbLoading: boolean) => !pbLoading))),
				filter((poChangeEvent: IChangeEvent<ITraitement | IEventMarker>) => {
					if (lbWaiting)
						return false;
					else if (IdHelper.hasPrefixId(poChangeEvent.document._id, C_PREFIX_TRAITEMENT)) {
						if (poChangeEvent.changeType === EChangeType.delete)
							return ArrayHelper.hasElements(this.moSeancesByTraitementId.get(poChangeEvent.document._id));
						else if (poChangeEvent.changeType === EChangeType.update) {
							return this.moSeancesByTraitementId.get(poChangeEvent.document._id)?.some((poSeanceTournee: ISeanceTournee) => poSeanceTournee.seance.traitementRev !== poChangeEvent?.document._rev);
						}
					}
					else if (IdHelper.hasPrefixId(poChangeEvent.document._id, EPrefix.event)) {
						const lsTraitementId: string = EventService.extractSourceObjectIdFromEventMarkerId(poChangeEvent.document._id);
						const ldEventDate: Date = EventService.extractTimeFromEventMarkerId(poChangeEvent.document._id);
						if (poChangeEvent.changeType === EChangeType.delete)
							return this.moSeancesByTraitementId.get(lsTraitementId)?.some((poSeanceTournee: ISeanceTournee) => DateHelper.diffMinutes(poSeanceTournee.seance.startDate, ldEventDate) === 0);
						else if (poChangeEvent.changeType === EChangeType.create)
							return this.moSeancesByTraitementId.get(lsTraitementId)?.every((poSeanceTournee: ISeanceTournee) => DateHelper.diffMinutes(poSeanceTournee.seance.startDate, ldEventDate) !== 0);
					}

					return false;
				}),
				tap(() => lbWaiting = true),
				switchMap(() => this.ioSnackBar.open("La tournée a été modifiée", "Rafraîchir", { horizontalPosition: "center", verticalPosition: "top" }).onAction()),
				tap(() => {
					lbWaiting = false;
					this.moRefreshSubject.next();
				}),
				takeUntil(this.destroyed$)
			).subscribe();
	}

	private initSeanceTournees(): void {
		let loLoader: Loader;

		this.moRefreshSubject.asObservable()
			.pipe(
				startWith(true),
				switchMap(() => this.isvcLoading.create("Chargement de la tournée ...")),
				tap((poLoader: Loader) => {
					this.moSeanceTourneesLoadingSubject.next(true);
					if (loLoader?.isPresented)
						loLoader.dismiss();
					loLoader = poLoader;
				}),
				switchMap((poLoader: Loader) => poLoader.present()),
				switchMap(_ => this.ioRoute.queryParams),
				tap((poParams: Params) => {
					if (!this.isMobileApp)
						this.displayMode = poParams.mode;

					return this.displayMode;
				}),
				switchMap(_ => this.isvcTournees.getSeancesFromDate(this.getStartDate(), this.getEndDate())),
				switchMap((paSeances: Seance[]) => this.isvcTournees.getSeanceTourneesFromSeances(paSeances)),
				tap((paSeanceTournees: ISeanceTournee[]) => this.innerInitSeanceTournees(paSeanceTournees)),
				tap(() => this.initServerChangesListening()),
				tap(() => this.moSeanceTourneesLoadingSubject.next(false)),
				switchMap(_ => this.isvcContacts.getContactsIds(true)),
				map((paContactIds: string[]) =>
					paContactIds.concat(this.maSeancesTournee.filter((poSeanceTournee: ISeanceTournee) => !StringHelper.isBlank(poSeanceTournee.seance.patientId))
						.map((poSeanceTournee: ISeanceTournee) => poSeanceTournee.seance.patientId)
					)
				),
				switchMap((paContactIds: string[]) => this.isvcGroups.getContactsGroups(paContactIds, true)),
				tap((poGroupsByContactId: Map<string, IGroup[]>) => {
					poGroupsByContactId.forEach((paGroups: IGroup[], psContactId: string) =>
						poGroupsByContactId.set(psContactId, paGroups?.filter((poGroup: IGroup) =>
							this.isvcGroups.hasRole(poGroup, C_SECTORS_ROLE_ID)) ?? []
						)
					);

					this.moGroupsByContactId = poGroupsByContactId;

					this.moGroupsByIds = ArrayHelper.groupBy(ArrayHelper.unique(ArrayHelper.flat(MapHelper.valuesToArray(this.moGroupsByContactId))),
						(poGroup: IGroup) => poGroup._id
					);
				}),
				tap(_ => {
					// Puis on change les filtres des nouvelles séances.
					this.setFilterOptions();
					this.detectChanges();
				}),
				mergeMap(() => loLoader.dismiss()),
				finalize(() => loLoader?.dismiss()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Modifie les options de filtre pour notifier les nouvelles séances planifiées/non planifiées de la tournée. */
	private setFilterOptions(): void {
		const loFilterOptions: ITourneeFilterOptions = {
			seances: this.maSeancesTournee
		};

		this.isvcTourneeFilter.raiseFilterOptions(loFilterOptions);
	}

	private innerInitSeanceTournees(paSeanceTournees: ISeanceTournee[]): void {
		const lsSeanceId: string = (this.ioRoute.snapshot.params as ITourneePageRouteParams).seanceId;

		this.maSeancesTournee = paSeanceTournees;
		this.moSeancesByTraitementId = ArrayHelper.groupBy(this.maSeancesTournee, (poSeanceTournee: ISeanceTournee) => poSeanceTournee.seance.traitementId);

		if (!StringHelper.isBlank(lsSeanceId)) {
			const loSeanceTournee: ISeanceTournee = paSeanceTournees.find((poItem: ISeanceTournee) => false);
			if (loSeanceTournee)
				this.seanceTourneeToEdit = loSeanceTournee;
		}
	}

	/** Change de mode de tournée. */
	public toggleMode(): void {
		// Si on passe en mode tournée, on demande validation et si c'est le cas, on crée une conversation sur la tournée.
		if (this.isPlanificationMode) {
			this.displayValidateTourneePopup()
				.pipe(
					mergeMap((poResponse: IUiResponse<boolean>) => {
						// Quoi qu'il en soit on change le mode d'affichage.
						this.isvcTournees.raiseTourneeModeEvent(ETourneeMode.tournee);

						if (poResponse.response) {
							// On notifie les utilisateurs uniquement si l'utilisateur le souhaite.
							return this.notifyIntervenants();
						}
						else
							return EMPTY;
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
		else // Sinon, on passe en mode planification, normalement.
			this.isvcTournees.raiseTourneeModeEvent(ETourneeMode.planification);
	}

	/** Affiche une popup pour valider la tournée et créer une conversation sur celle-ci. */
	private displayValidateTourneePopup(): Observable<IUiResponse<boolean>> {
		return this.isvcUiMessage.showAsyncMessage<boolean>(
			new ShowMessageParamsPopup({
				message: "Si vous avez terminé de préparer votre tournée, voulez-vous en informer les intervenants ?",
				buttons: [
					{ text: "Non", handler: () => UiMessageService.getFalsyResponse() },
					{ text: "Oui", handler: () => UiMessageService.getTruthyResponse() }
				]
			})
		);
	}

	/** Alerte les intervenants présents dans la tournée par le biais d'une conversation et propose de l'afficher. */
	private notifyIntervenants(): Observable<IConversation> {
		return this.isvcTournees.createOrOpenConversation(this.getStartDate(), this.maSeancesTournee, false)
			.pipe(
				tap(_ => {
					this.isvcUiMessage.showMessage(
						new ShowMessageParamsToast({
							message: "Les intervenants ont été notifiés sur la conversation associée à cette tournée !",
							buttons: [{
								text: "Voir la conversation",
								handler: () => {
									this.isvcTournees.createOrOpenConversation(this.getStartDate(), this.maSeancesTournee, true).subscribe();
									return true;
								}
							}] as ToastButton[]
						})
					);
				})
			);
	}

	public goToFacturation(): void {
		this.isvcPrestation.navigateToPrestation(this.getStartDate(), this.getEndDate());
	}

	public conserveInsertionOrder(): number {
		return 1;
	}

	public onModalStatusChange(pbIsModalOpen: boolean): void {
		this.mbHasModalOpen = pbIsModalOpen;
	}

	//#endregion
}