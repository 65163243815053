import { Injectable } from '@angular/core';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { UserData } from '@osapp/model/application/UserData';
import { EPrefix } from '@osapp/model/EPrefix';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { IDataSource } from '@osapp/model/store/IDataSource';
import { IStoreDocument } from '@osapp/model/store/IStoreDocument';
import { EUTCAccuracy } from '@osapp/modules/date/model/eutcaccuracy.enum';
import { IRange } from '@osapp/modules/utils/models/models/irange';
import { Store } from '@osapp/services/store.service';
import { plainToClass } from 'class-transformer';
import { Observable, of } from 'rxjs';
import { map, mapTo, mergeMap, switchMap } from 'rxjs/operators';
import { C_PREFIX_INTER_STATE, C_PREFIX_PATIENT, C_PREFIX_TRAITEMENT } from '../../app/app.constants';
import { Traitement } from '../../model/Traitement';
import { IInterventionStatement } from './models/iintervention-statement';
import { IInterventionStatementActe } from './models/iintervention-statement-acte';
import { InterventionStatement } from './models/intervention-statement';

@Injectable()
export class InterventionStatementService {

	//#region PROPERTIES

	public static C_DEFAULT_SKIP_REASON = "Séance annulée";

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcStore: Store
	) { }

	/** Créé un objet InterventionStatement.
	 * @param psTraitementId Id du traitement.
	 * @param paActes Liste des actes.
	 * @param psIntervenantId Id de l'intervenant en charge des actes.
	 */
	public static createInterventionStatement(pdDate: Date, psTraitementId: string, paActes: IInterventionStatementActe[], psIntervenantId: string): InterventionStatement {
		const lsSiteGuid: string = IdHelper.getGuidFromId(UserData.currentSite._id, EPrefix.site);
		return plainToClass(InterventionStatement, {
			_id: IdHelper.buildChildId(C_PREFIX_INTER_STATE, psTraitementId.replace(lsSiteGuid, `${lsSiteGuid}${IdHelper.C_VIRTUAL_NODE_SEPARATOR}${DateHelper.toUTCString(pdDate, EUTCAccuracy.minutes)}`), DateHelper.toUTCString(new Date(), EUTCAccuracy.milliseconds)),
			actes: paActes,
			intervenantId: psIntervenantId
		});
	}

	/** Tri les relevés d'intervention.
	 * @param paInterStates Liste des relevés d'intervention.
	 */
	public static sortInterStateByDate(paInterStates: InterventionStatement[]): InterventionStatement[] {
		return paInterStates.sort((poInterStateA: InterventionStatement, poInterStateB: InterventionStatement) => DateHelper.compareTwoDates(poInterStateA.interventionDate, poInterStateB.interventionDate));
	}

	/** Récupère tous les relevés d'intervention. */
	public getInterventionStatements(): Observable<InterventionStatement[]> {
		const lsStartKey: string = IdHelper.buildChildId(C_PREFIX_INTER_STATE,
			IdHelper.buildChildId(C_PREFIX_TRAITEMENT, IdHelper.buildVirtualNode([UserData.currentSite?._id ?? "", C_PREFIX_PATIENT]), ""),
			""
		);
		const loIdsDataSource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: lsStartKey,
				endkey: `${lsStartKey}${Store.C_ANYTHING_CODE_ASCII}`,
				include_docs: true
			},
		};
		return this.isvcStore.get(loIdsDataSource)
			.pipe(
				map((paInterStates: InterventionStatement[]) => paInterStates.map((poInterState: InterventionStatement) => this.toClass(poInterState)))
			);
	}

	/** Récupère les relevés d'intervention d'un traitement.
	 * @param psTraitementId
	 */
	public getTraitementInterventionStatements(psTraitementId: string): Observable<InterventionStatement[]> {
		const lsStartKey: string = IdHelper.buildChildId(C_PREFIX_INTER_STATE,
			IdHelper.buildChildId(C_PREFIX_TRAITEMENT, IdHelper.buildVirtualNode([Traitement.extractSiteId(psTraitementId), C_PREFIX_PATIENT]), ""),
			""
		);
		const lsTraitementGuid: string = Traitement.extractGuid(psTraitementId);

		const loIdsDataSource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: lsStartKey,
				endkey: `${lsStartKey}${Store.C_ANYTHING_CODE_ASCII}`
			}
		};

		return this.isvcStore.get(loIdsDataSource)
			.pipe(
				mergeMap((paDocs: IStoreDocument[]) => {
					const laInterventionStatementKeys: string[] = [];

					paDocs.forEach((poDoc: IStoreDocument) => {
						if (poDoc._id.includes(lsTraitementGuid))
							laInterventionStatementKeys.push(poDoc._id);
					});

					return this.isvcStore.get({ role: EDatabaseRole.workspace, viewParams: { include_docs: true, keys: laInterventionStatementKeys } });
				}),
				map((paInterStates: IInterventionStatement[]) => paInterStates.map((poInterState: InterventionStatement) => this.toClass(poInterState)))
			);
	}

	/** Récupère les derniers relevés d'interventions d'un traitement pour chaque date/heure/minute.
	 * @param psTraitementId 
	 * @param pbLive 
	 * @returns 
	 */
	public getTraitementLastInterventionStatements(psTraitementId: string, pbLive?: boolean): Observable<InterventionStatement[]> {
		const lsStartKey: string = IdHelper.buildChildId(C_PREFIX_INTER_STATE,
			IdHelper.buildChildId(C_PREFIX_TRAITEMENT, IdHelper.buildVirtualNode([Traitement.extractSiteId(psTraitementId), C_PREFIX_PATIENT]), ""),
			""
		);
		const lsTraitementGuid: string = Traitement.extractGuid(psTraitementId);

		const loIdsDataSource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: lsStartKey,
				endkey: `${lsStartKey}${Store.C_ANYTHING_CODE_ASCII}`
			},
			live: pbLive
		};

		return this.isvcStore.get(loIdsDataSource)
			.pipe(
				switchMap((paDocs: IStoreDocument[]) => {
					const laInterventionStatementKeys: string[] = [];
					const loInterventionStatementsByTime = new Map<string, string[]>();

					paDocs.forEach((poDoc: IStoreDocument) => {
						if (poDoc._id.includes(lsTraitementGuid)) {
							const lsKey: string = InterventionStatement.extractInterventionDateString(poDoc._id);
							const laInterventionStatementIds: string[] = loInterventionStatementsByTime.get(lsKey) ?? [];
							laInterventionStatementIds.push(poDoc._id);
							loInterventionStatementsByTime.set(lsKey, laInterventionStatementIds);
						}
					});

					loInterventionStatementsByTime.forEach((paIds: string[]) => laInterventionStatementKeys.push(ArrayHelper.getLastElement(paIds)));

					return this.isvcStore.get({ role: EDatabaseRole.workspace, viewParams: { include_docs: true, keys: laInterventionStatementKeys }, live: pbLive });
				}),
				map((paInterStates: IInterventionStatement[]) => paInterStates.map((poInterState: InterventionStatement) => this.toClass(poInterState)))
			);
	}

	/** Récupère les derniers relevés d'interventions pour chaque date/heure/minute.
	 * @param paTraitementIds 
	 * @param pbLive 
	 * @returns 
	 */
	public getTraitementsLastInterventionStatements(paTraitementIds: string[], poRange?: IRange<Date>, pbLive?: boolean): Observable<Map<string, InterventionStatement[]>> {
		const lsStartKey: string = IdHelper.buildChildId(C_PREFIX_INTER_STATE,
			IdHelper.buildChildId(C_PREFIX_TRAITEMENT, IdHelper.buildVirtualNode([UserData.currentSite?._id ?? "", poRange?.from ? DateHelper.toUTCString(poRange.from, EUTCAccuracy.minutes) : "", C_PREFIX_PATIENT]), ""),
			""
		);
		let lsEndKey: string;
		if (poRange?.to) {
			lsEndKey = IdHelper.buildChildId(C_PREFIX_INTER_STATE,
				IdHelper.buildChildId(C_PREFIX_TRAITEMENT, IdHelper.buildVirtualNode([UserData.currentSite?._id ?? "", DateHelper.toUTCString(poRange.to, EUTCAccuracy.minutes), C_PREFIX_PATIENT]), ""),
				""
			);
		}
		else
			lsEndKey = lsStartKey;

		const loIdsDataSource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: lsStartKey,
				endkey: `${lsEndKey}${Store.C_ANYTHING_CODE_ASCII}`
			},
			live: pbLive
		};

		return this.isvcStore.get(loIdsDataSource)
			.pipe(
				switchMap((paDocs: IStoreDocument[]) => {
					const laInterventionStatementKeys: string[] = [];
					const loInterventionStatementsByTimeAndTraitementId = new Map<string, string[]>();

					paDocs.forEach((poDoc: IStoreDocument) => {
						const lsTraitementId: string = InterventionStatement.extractTraitementId(poDoc._id);
						if (paTraitementIds.includes(lsTraitementId)) {
							const lsKey: string = InterventionStatement.extractInterventionDateString(poDoc._id) + InterventionStatement.extractTraitementId(poDoc._id);
							const laInterventionStatementIds: string[] = loInterventionStatementsByTimeAndTraitementId.get(lsKey) ?? [];
							laInterventionStatementIds.push(poDoc._id);
							loInterventionStatementsByTimeAndTraitementId.set(lsKey, laInterventionStatementIds);
						}
					});

					loInterventionStatementsByTimeAndTraitementId.forEach((paIds: string[]) => laInterventionStatementKeys.push(ArrayHelper.getLastElement(paIds)));

					return this.isvcStore.get({ role: EDatabaseRole.workspace, viewParams: { include_docs: true, keys: laInterventionStatementKeys } });
				}),
				map((paInterStates: IInterventionStatement[]) => {
					const loInterventionStatementsByTraitementId = new Map<string, InterventionStatement[]>();

					paInterStates.forEach((poInterState: InterventionStatement) => {
						const loInterventionStatement: InterventionStatement = this.toClass(poInterState);
						const laTraitementInterventionStatements: InterventionStatement[] = loInterventionStatementsByTraitementId.get(loInterventionStatement.traitementId) ?? [];

						laTraitementInterventionStatements.push(loInterventionStatement);

						loInterventionStatementsByTraitementId.set(loInterventionStatement.traitementId, laTraitementInterventionStatements);
					});

					return loInterventionStatementsByTraitementId;
				})
			);
	}

	public deleteInterventionsStatement(interASuppr: InterventionStatement[]): Observable<boolean> {
		if (!interASuppr && interASuppr.length <= 0) return of(true);

		const datebaseId = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace))

		return this.isvcStore.deleteMultipleDocuments(interASuppr, datebaseId)
			.pipe(
				map(_ => true) // Retourne true lorsque la suppression est réussie
			);
	}

	/** Sauvegarde un relevé d'intervention.
	 * @param poInterState 
	 */
	public saveInterventionStatement(poInterState: InterventionStatement): Observable<InterventionStatement> {
		const loInterState: InterventionStatement = poInterState instanceof InterventionStatement ? poInterState : this.toClass(poInterState);
		return this.isvcStore.put(loInterState).pipe(mapTo(loInterState));
	}

	protected toClass(poInterState: IInterventionStatement): InterventionStatement {
		return plainToClass(InterventionStatement, poInterState);
	}

	//#endregion
}
