import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalComponentBase } from '@osapp/modules/modal/model/ModalComponentBase';
import { ShowMessageParamsToast } from '@osapp/services/interfaces/ShowMessageParamsToast';
import { PlatformService } from '@osapp/services/platform.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { ISlotEditData } from '../../model/ISlotEditData';

@Component({
	selector: 'idl-slot-edit-modal',
	templateUrl: './slot-edit-modal.component.html',
	styleUrls: ['./slot-edit-modal.component.scss'],
})
export class SlotEditModalComponent extends ModalComponentBase<ISlotEditData> {

	//#region PROPERTIES

	@Input() public slotEditData: ISlotEditData;

	//#endregion

	//#region METHODS

	constructor(
		private isvcUiMessage: UiMessageService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
	) {
		super(poModalCtrl, psvcPlatform);
	}

	public validateEdition() {
		if (this.slotEditData.label && this.slotEditData.startHour && this.slotEditData.endHour) {
			this.close(this.slotEditData)
		}
		else {
			this.isvcUiMessage.showMessage(new ShowMessageParamsToast({
				header: "Impossible de valider",
				message: "Vous n'avez pas saisi toutes les informations.",
				color: "dark",
				duration: 3000
			}));
		}
	}

	//#endregion

}
