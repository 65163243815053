import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';

@Component({
  templateUrl: './patients.page.html',
  styleUrls: ['./patients.page.scss'],
})
export class PatientsPage implements OnInit {

  public patients: IPatient[] = [];

  constructor(
    private svcPatients: PatientsService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.svcPatients.getSitePatientsAnakin().subscribe((patients: IPatient[]) => {
      this.patients = patients;
    });
  }

  public navigateToCreatePatient(): void {
    this.router.navigate(['patients', 'add']);
  }

	public onClickPatient(patientId :string):void{
		this.router.navigate(['/patients', patientId]);
	}
}
