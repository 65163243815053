import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { IdHelper } from '@osapp/helpers';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { StoreDocumentHelper } from '@osapp/helpers/storeDocumentHelper';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { IAvatar } from '@osapp/model/picture/IAvatar';
import { C_CONSTANTES_PREFIX, C_INJECTIONS_PREFIX } from 'apps/idl/src/app/app.constants';
import { IConstantes } from '../../../model/constantes/IConstantes';
import { IInjections } from '../../../model/constantes/IInjections';
import { InitConstantesItemError } from '../../../model/constantes/InitConstantesItemError';
import { ISurveillances } from '../../../model/constantes/ISurveillances';
import { ISurveillancesItemParams } from '../../../model/constantes/ISurveillancesItemParams';
import { IPatient } from '../../../model/IPatient';

@Component({
	selector: "idl-surveillances-item",
	templateUrl: './surveillances-item.component.html',
	styleUrls: ['./surveillances-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveillancesItemComponent extends ComponentBase implements OnInit, ISurveillancesItemParams {

	//#region PROPERTIES

	public readonly C_CONSTANTES_DATE_FORMAT: string = ETimetablePattern.dd_MM_yyyy_HH_mm_slash;

	/** Liste des differents types de surveillances */
	public constantesModel: IConstantes;
	public injectionsModel: IInjections;

	private moModel: ISurveillances;
	/** @implements */
	public get model(): ISurveillances {
		return this.moModel;
	}
	@Input() public set model(poValue: ISurveillances) {
		if (!StoreDocumentHelper.areDocumentsEquals(this.moModel, poValue)) {
			this.moModel = poValue;
			this.fillSurveillances(this.moModel);
			this.detectChanges();
		}
	}

	private moPatient: IPatient;
	/** @implements */
	public get patient(): IPatient {
		return this.moPatient;
	}
	@Input() public set patient(poValue: IPatient) {
		if (!StoreDocumentHelper.areDocumentsEquals(this.moPatient, poValue)) {
			this.moPatient = poValue;
			this.detectChanges();
		}
	}

	private moAuthorAvatar: IAvatar;
	/** @implements */
	public get authorAvatar(): IAvatar {
		return this.moAuthorAvatar;
	}
	@Input() public set authorAvatar(poValue: IAvatar) {
		if (poValue && this.moAuthorAvatar !== poValue) {
			this.moAuthorAvatar = poValue;
			this.detectChanges();
		}
	}

	//#endregion

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	public ngOnInit(): void {
		if (!this.model)
			throw new InitConstantesItemError();
	}

	private fillSurveillances(poModel: ISurveillances): void {
		this.resetModels();

		switch (IdHelper.getPrefixFromId(poModel._id)) {
			case C_CONSTANTES_PREFIX:
				this.constantesModel = poModel;
				break;

			case C_INJECTIONS_PREFIX:
				this.injectionsModel = poModel;
				break;
		}
	}

	private resetModels(): void {
		this.constantesModel = undefined;
		this.injectionsModel = undefined;
	}

	//#endregion

}