import { ILinkInfo } from '../link/ILinkInfo';

export interface ISectionInfo {

	/** Format du json utilisé. */
	meta: { schemaVersion: string };
	/** Identifiant de la section. */
	id: string;
	/** Label affiché de la section. */
	label: string;
	/** Identifiant du template html utilisé. */
	templateId?: string;
	/** Nom du template utilisé. */
	templateName?: string;
	/** Booléen indiquant si la section peut être réduite. */
	isClosable: boolean;
	/** Dans le cas d'un menu extensible, indique s'il est fermé ou ouvert. */
	isClosed: boolean;
	/** Listes des liens accessibles depuis la section. */
	links: Array<ILinkInfo>;
}