<div class="infos-constantes">
	<!-- Nom du patient. -->
	<ion-label *ngIf="patient">{{ patient | contactName }}</ion-label>

	<div class="align-left">
		<div class="pad-const">
			<ng-container *ngTemplateOutlet="constantesModel ? constantes : injections"></ng-container>
		</div>
		<ion-note *ngIf="constantesModel" class="avatar-align date-note">{{ constantesModel.createdDate |
			dateWithLocale:"fr-FR": C_CONSTANTES_DATE_FORMAT }}</ion-note>
		<ion-note *ngIf="injectionsModel" class="avatar-align date-note">{{ injectionsModel.createdDate |
			dateWithLocale:"fr-FR": C_CONSTANTES_DATE_FORMAT }}</ion-note>
	</div>

	<!-- Avatar de l'auteur. -->
	<div class="constantes-align align-right">
		<ng-container *ngIf="authorAvatar">
			<div class="avatar-align">
				<avatar [src]="authorAvatar" cssClass="round" slot="end">
				</avatar>
			</div>
		</ng-container>
	</div>
</div>

<ng-template #constantes>
	<!-- Température. -->
	<ion-note *ngIf="constantesModel.temperature" class="align-note const-temp" matTooltip="Température">T° : {{
		constantesModel.temperature
		}}</ion-note>
	<!-- Fréquence cardiaque. -->
	<ion-note *ngIf="constantesModel.heartRate" class="align-note const-freq" matTooltip="Fréquence cardiaque">BPM : {{
		constantesModel.heartRate }}</ion-note>
	<!-- Saturation en oxygène du sang. -->
	<ion-note *ngIf="constantesModel.oxygenSaturation?.saturation" class="align-note const-satur"
		matTooltip="Saturation en oxygène">SpO2 : {{
		constantesModel.oxygenSaturation.saturation }}%</ion-note>
	<!-- Quantité d'oxygène inspiré. -->
	<ion-note *ngIf="constantesModel.oxygenSaturation?.saturation" class="align-note const-satur"
		matTooltip="Quantité d'oxygène">
		{{ constantesModel.oxygenSaturation?.oxygenQuantity ? 'Débit 02 : ' +
		constantesModel.oxygenSaturation.oxygenQuantity + 'L' : 'AA'}}
	</ion-note>
	<!-- Pression artérielle. -->
	<ion-note *ngIf="constantesModel.bloodPressure" class="align-note const-press" matTooltip="Pression artérielle">TA :
		{{
		constantesModel.bloodPressure }}</ion-note>
	<!-- Fréquence respiratoire. -->
	<ion-note *ngIf="constantesModel.respiratoryRate" class="align-note const-resp" matTooltip="Fréquence respiratoire">FR
		: {{
		constantesModel.respiratoryRate }}</ion-note>
	<!-- Glycémie. -->
	<ion-note *ngIf="constantesModel.bloodSugar" class="align-note const-gly" matTooltip="Glycémie">DEXTRO : {{
		constantesModel.bloodSugar
		}}</ion-note>
	<!-- Diurèse. -->
	<ion-note *ngIf="constantesModel.diuresis" class="align-note const-diu">Diurèse : {{ constantesModel.diuresis }}
	</ion-note>
	<!-- EVA. -->
	<ion-note *ngIf="constantesModel.painScale >= 0" class="align-note const-eva"
		matTooltip="Échelle visuelle analogique">EVA :
		{{ constantesModel.painScale }}</ion-note>
	<!-- Masse. -->
	<ion-note *ngIf="constantesModel.weight" class="align-note const-mas">Poids : {{ constantesModel.weight }}</ion-note>
	<!-- Autre champ custom. -->
	<ion-note *ngIf="constantesModel.other?.label && !constantesModel.others" class="align-note const-aut">{{
		constantesModel.other.label }} : {{
		constantesModel.other.value }}
	</ion-note>
	<!-- Autre champ custom. -->
	<ion-note *ngFor="let other of constantesModel.others" class="align-note const-aut">
		{{ other.label ? other.label + " : " + other.value : ""}}
	</ion-note>
</ng-template>

<ng-template #injections>
	<!-- Injections. -->
	<ion-note *ngFor="let injection of injectionsModel.injections" class="align-note const-injection">
		{{ injection.label ? injection.label + " : " + injection.quantity : ""}}
	</ion-note>
</ng-template>