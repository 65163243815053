<div class="di-patient-indisponible">
	<div class="info">
		<p>
			Le patient n'est pas disponible, il n'est pas possible de réaliser les
			soins prévus.
		</p>
		<lua-input label="Commentaire" [multiline]="true" [fullWidth]="true" [(ngModel)]="commentaire"></lua-input>
	</div>

	<lua-action-secondaire libelle="Actes non réalisés" [fullWidth]="true" [isDisabled]="!commentaire"
		(click)="onActesNonRealisesClick()"></lua-action-secondaire>
	<lua-action-secondaire *ngIf="modeEdit" libelle="Annuler patient non disponible" color="Raspberry" [fullWidth]="true"
		(click)="onAnnulerPatientNonDisponible()"></lua-action-secondaire>
	<lua-bouton-texte libelle="Annuler" [fullWidth]="true" (click)="onCancel()"></lua-bouton-texte>
</div>