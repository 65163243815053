import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ArrayHelper, DateHelper, UserHelper } from '@osapp/helpers';
import { EDateTimePickerMode, EPrefix, ETimetablePattern, IContactsListParams, IDateTimePickerParams } from '@osapp/model';
import { ModalComponentBase } from '@osapp/modules/modal/model/ModalComponentBase';
import { ContactsService } from '@osapp/services';
import { PlatformService } from '@osapp/services/platform.service';
import { EContactType } from '../../../../../model/EContactType';
import { IIdelizyContact } from '../../../../../model/IIdelizyContact';
import { Traitement } from '../../../../../model/Traitement';
import { Ordonnance } from '../../../../ordonnances/models/ordonnance';
import { OrdonnancesService } from '../../../../ordonnances/services/ordonnances.service';
import { IAccordPrealable } from '../../../../patients/model/IAccordPrealable';
import { IIKParams } from '../../../model/IIKParams';
import { AccordPrealableService } from '../accordPrealable.service';

@Component({
	templateUrl: './accordPrealable-modal.component.html',
	styleUrls: ['./accordPrealable-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordPrealableModalComponent extends ModalComponentBase<IAccordPrealable> implements OnInit {

	/** Paramètres du composant contactsList pour le prescripteur. */
	public contactsListParamsForDemandeur: IContactsListParams;

	public dateParamsDate: IDateTimePickerParams;
	public ordonnance: Ordonnance;

	/** Paramètres du composant osapp-selector pour la selection de l'origine. */

	@Input() public params: IIKParams;
	@Input() public traitement: Traitement;
	@Input() public accord: IAccordPrealable;




	public get filteredActes() {
    return Object.values(this.traitement.actes).filter(acte => acte.isPriorAgreement);
  }


	//#endregion

	//#region METHODS

	constructor(
		private readonly svcContacts: ContactsService,
		private svcAccordPrealable:AccordPrealableService,
		private svcOrdonnance : OrdonnancesService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetectorRef);
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this.prepareDateTimeSpinnerParams();
		if(!this.accord)
		{
			this.accord = { 
					_id: `${EPrefix.accordPrealable}${this.traitement._id}`,
					traitement : this.traitement._id, 
					dateDemande : DateHelper.transform(new Date, ETimetablePattern.isoFormat_hyphen),
					lastUpdate :DateHelper.transform(new Date, ETimetablePattern.isoFormat_hyphen)
			}
			this.accord.demandeurAPId = UserHelper.getUserContactId();
		}
		
		this.contactsListParamsForDemandeur = this.createContactsListParams(EPrefix.contact, EContactType.contact, "Sélectionner le prescripteur");
		this.contactsListParamsForDemandeur.contactsSelectorParams.roles = [];
		this.contactsListParamsForDemandeur.contactsSelectorParams.hasGroupSelector = true;

		this.detectChanges();	}

	/** @override */
	public close(): Promise<boolean> {
		this.svcAccordPrealable.createOrUpdate(this.accord).subscribe();
		return super.close(this.accord);
	}

	public exportAP() :void
	{
		this.svcAccordPrealable.displayAp(this.accord);
	}

	public prepareDateTimeSpinnerParams(): void {
    const leDisplayFormat = ETimetablePattern.dd_MMM_yyyy;

    // On va chercher l'ordonnance du traitement.
    this.svcOrdonnance.getOrdonnancesByTraitementId([this.traitement._id]).subscribe((ordonnances: Map<string, Ordonnance[]>) => {
        // On récupère la première ordonnance dans la liste d'ordonnances.
        const ordonnance: Ordonnance = ordonnances.get(this.traitement._id)?.[0];
        if (ordonnance) {
            this.ordonnance = ordonnance;
            this.dateParamsDate = {
                displayFormat: leDisplayFormat,
                dateTimeCss: "edit-color",
                iconCss: "edit-color",
                iconSlot: "end",
                pickerMode: EDateTimePickerMode.date,
                min: ordonnance.prescriptionDate.toISOString()
            };
        }
    });
}

	private createContactsListParams(peContactPrefix: EPrefix, peContactType: EContactType, psSearchPlaceholder: string,
		pbReadOnly: boolean = false): IContactsListParams {

		return {
			contactsAsLinks: false,
			displayProperties: [],
			displayIcons: [],
			pageTitle: "Demandeur",
			readOnly: pbReadOnly,
			contactsById: false,
			contactsSelectorParams: this.svcContacts.createContactSelectorParams(peContactPrefix, psSearchPlaceholder),
			contactsContainer: this.traitement,
			addButtonIcon: "person",
			addButtonText: "Sélectionner",
			disbaleRouteToContact: true
		};
	}

	public onDemandeDateChanged(pdDate: Date): void {
		this.accord.dateDemande = DateHelper.transform(pdDate,ETimetablePattern.isoFormat_hyphen);
		this.detectChanges();
	}

	public onDemandeurModelChanged(paSelectedContacts: Array<IIdelizyContact>): void {
		const loFirstContact: IIdelizyContact = ArrayHelper.getFirstElement(paSelectedContacts);
		this.accord.demandeurAPId = loFirstContact?._id;
		this.detectChanges();
	}
}
