import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ArrayHelper } from 'libs/osapp/src/helpers';
import { IGalleryFile } from '../../../../model/gallery/IGalleryFile';
import { ModalComponentBase } from '../../../../modules/modal/model/ModalComponentBase';
import { PlatformService } from '../../../../services/platform.service';

@Component({
	selector: 'calao-gallery-add-modal',
	templateUrl: './gallery-add-modal.component.html',
	styleUrls: ['./gallery-add-modal.component.scss'],
})
export class GalleryAddModalComponent extends ModalComponentBase<IGalleryFile> {

	//#region PROPERTIES

	@Input() public defaultOrdoDescription: string;
	@Input() public allowCustomDescription: boolean;
	public files: IGalleryFile[] = [];

	//#endregion

	//#region METHODS

	constructor(
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService
	) {
		super(poModalCtrl, psvcPlatform);
	}

	public onAddFile(paFiles: IGalleryFile[]): void {
		this.close(ArrayHelper.getFirstElement(paFiles));
	}

	//#endregion

}
