import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { EPermission } from '../../modules/permissions/models/EPermission';
import { IHasPermission, Permissions, PermissionsService } from '../../modules/permissions/services/permissions.service';
import { GroupsService } from '../../services/groups.service';
import { UiMessageService } from '../../services/uiMessage.service';

@Injectable({ providedIn: "root" })
export class CanEditGroupGuard implements CanActivate, IHasPermission {

	//#region PROPERTIES

	@Permissions("edit")
	public get canEdit(): boolean { return true; }

	public readonly permissionScope: EPermission[] = [EPermission.contacts, EPermission.groups];

	//#endregion

	//#region METHODS

	constructor(
		public readonly isvcPermissions: PermissionsService,
		private isvcGroups: GroupsService,
		private isvcUiMessage: UiMessageService
	) { }

	public canActivate(poNext: ActivatedRouteSnapshot): Observable<boolean> {
		return of(true)
	}

	//#endregion

}