import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TourneePageComponent } from './pages/tournee/tournee-page.component';
import { TourneesPageComponent, TOURNEE_PAGE_INFO } from './pages/tournees/tournees-page.component';

const routes: Routes = [
	{
		path: '', // La route jusqu'à ce module est déjà '[...]/tournees'.
		component: TourneesPageComponent,
		children: [
			{
				path: ':date',
				component: TourneePageComponent,
				data: {
					pageInfo: TOURNEE_PAGE_INFO
				}
			},
			{
				path: ':date/seances/:seanceId',
				component: TourneePageComponent,
				data: {
					pageInfo: TOURNEE_PAGE_INFO
				}
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: []
})
export class TourneesRoutingModule { }