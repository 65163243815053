import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IUiResponse } from '@osapp/model/uiMessage/IUiResponse';
import { ModalComponentBase } from '@osapp/modules/modal/model/ModalComponentBase';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '@osapp/services/interfaces/ShowMessageParamsToast';
import { PlatformService } from '@osapp/services/platform.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { Acte } from '../../../model/Acte';
import { EModalActeType } from '../../../model/EModalActeType';
import { Traitement } from '../../../model/Traitement';
import { Ordonnance } from '../../ordonnances/models/ordonnance';
import { POPUP_LOST_MODIFICATION_BUTTON_LEAVE, POPUP_LOST_MODIFICATION_BUTTON_STAY, POPUP_LOST_MODIFICATION_HEADER, POPUP_LOST_MODIFICATION_MESSAGE } from '@osapp/constants';


@Component({
	selector: 'idl-acte-edit-modal',
	templateUrl: './acte-edit-modal.component.html',
	styleUrls: ['./acte-edit-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActeEditModalComponent extends ModalComponentBase<boolean> implements OnInit {

	//#region PROPERTIES

	private moActe: Acte;
	public get acte(): Acte { return this.moActe; }
	@Input() public set acte(poActe: Acte) {
		this.moActe = poActe;
		this.detectChanges();
	}

	@Input() public traitement: Traitement;
	@Input() public ordonnance: Ordonnance;
	@Input() public modalType: EModalActeType;
	@Input() public hasValidatedOrCanceledSeancesByActeGuid: Map<string, boolean>;

	public customClose: () => Promise<void>;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcUiMessage: UiMessageService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetector);
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this.customClose = () => this.closeActe();
	}

	public async closeActe(pbSave?: boolean): Promise<void> {
		if (this.isActeDirty()) {
			if (!pbSave) {
				const loResponse: IUiResponse<boolean> = await this.isvcUiMessage.showAsyncMessage<boolean>(new ShowMessageParamsPopup({
					header: POPUP_LOST_MODIFICATION_HEADER,
					message: POPUP_LOST_MODIFICATION_MESSAGE,
					buttons: [
						{ text: POPUP_LOST_MODIFICATION_BUTTON_STAY, handler: UiMessageService.getFalsyResponse },
						{ text: POPUP_LOST_MODIFICATION_BUTTON_LEAVE, handler: UiMessageService.getTruthyResponse }
					],
					backdropDismiss: false
				})).toPromise();

				if (loResponse.response)
					super.close(false);
			}
		}
		else
			super.close(pbSave);
	}

	private isActeDirty(): boolean {
		const loSourceActe: Acte = this.traitement.actes.find((poActe: Acte) => poActe.guid === this.acte.guid);
		return !loSourceActe || !loSourceActe.equals(this.acte);
	}

	public getValidateButtonClass(): string {
		return this.acte.recurrencesValid() ? "" : "validate-disabled";
	}

	public validateActe(): void {
		if (this.acte.recurrencesValid()) {
			this.close(true);
		} else {
			this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: "Veuillez saisir une répétition." }));
		}
	}

	//#endregion METHODS

}
