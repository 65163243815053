<!-- Partie visuelle (affichage de la date, pas sélection). -->
<ng-container [ngSwitch]="params.displayMode">
	<ng-container *ngSwitchCase="'button'">
		<ion-button class="btn" [disabled]="disabled"
			[ngClass]="params.cssClass ? params.cssClass : 'ion-no-padding, center-date'"
			(click)="$event.stopPropagation(); pickDate();" [color]="params.color" shape="round">
			<ng-container *ngTemplateOutlet="content"></ng-container>
		</ion-button>
	</ng-container>

	<ion-item *ngSwitchDefault lines="none" [disabled]="disabled"
		[ngClass]="params.cssClass ? params.cssClass : 'ion-no-padding, center-date'"
		(click)="$event.stopPropagation(); pickDate();" [color]="params.color">
		<ng-container *ngTemplateOutlet="content"></ng-container>
	</ion-item>
</ng-container>

<ng-template #content>
	<ion-icon *ngIf="params.icon && !params.hideIcon" [name]="params.icon"
		[class]="params.cssClass ? params.cssClass : 'ico-date'" [slot]="params.iconSlot ? params.iconSlot : 'start'"
		[color]="params.iconColor"></ion-icon>
	<span class="txt-date" *ngIf="params.label">{{ params.label }}</span>
	<ion-label *ngIf="!model && params.placeholder" class="space-hour"> {{ params.placeholder }}
	</ion-label>
	<ion-label *ngIf="!params.hideValue && model" class="pad-hour">
		{{ model | dateWithLocale : 'fr' : params.displayFormat }}
	</ion-label>
</ng-template>

<!-- Uniquement en mode browser: Quand on clique, ouvre la modale de sélection de dates. -->
<mat-form-field class="date-picker">
	<input matInput [matDatepicker]="datePicker" (dateInput)="onBrowserDateChanged($event.value)" [value]="model"
		[max]="max" [min]="min">
	<mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
	<mat-datepicker #datePicker [startView]="startView" touchUi="true"></mat-datepicker>
</mat-form-field>