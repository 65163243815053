<ion-content [forceOverscroll]="false">
	<div *ngIf="params.hasSearchbox" class="search-box txt-center width100pc">
		<search [data]="values" [options]="params.searchOptions" (filteredEntriesChanged)="onFilteredValuesChanged($event)">
		</search>
	</div>

	<ion-virtual-scroll *ngIf="!(params.searchOptions?.hasPreFillData === false && filteredValues?.length === 0)"
		class="marge-btm-list" [items]="filteredValues">
		<ng-container *ngIf="isSingleSelectionMode; else elseTemplate">
			<ion-item lines="full" *virtualItem="let value" (click)="validateSelection(value)" class="selector">
				<ion-label>{{value.displayValue}}</ion-label>
			</ion-item>
		</ng-container>
		<ng-template #elseTemplate>
			<ion-item *virtualItem="let value" (click)="onValueSelected()" [disabled]="!canSelectMore && !value.isSelected">
				<ion-label>{{value.displayValue}}</ion-label>
				<ion-checkbox *ngIf="!isSingleSelectionMode" slot="start" [(ngModel)]="value.isSelected"
					class="ion-margin-top ion-margin-bottom">
				</ion-checkbox>
			</ion-item>
		</ng-template>
	</ion-virtual-scroll>
</ion-content>

<ion-fab *ngIf="!isSingleSelectionMode" vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
	<ion-fab-button color="success" (click)="validateSelection()" [disabled]="!canValidateSelection" title="Valider">
		<ion-icon name="checkmark"></ion-icon>
	</ion-fab-button>
</ion-fab>