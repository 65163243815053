import { StringHelper } from '../../../helpers/stringHelper';
import { Entity } from '../../../model/entities/Entity';
import { EPrefix } from '../../../model/EPrefix';
import { EAvatarSize } from '../../../model/picture/EAvatarSize';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { EntityBuilder } from '../../../services/EntityBuilder';
import { IDmsDocument } from './IDmsDocument';

export class DmsDocumentEntityBuilder extends EntityBuilder<IDmsDocument>{
	constructor() {
		super(
			(psModelId: string) => RegExp(EPrefix.dmsDoc).test(psModelId),
			(poDocument: IDmsDocument) => new Entity(
				poDocument,
				undefined,
				() => !StringHelper.isBlank(poDocument.description) ? poDocument.description : poDocument.name
			),
			(psModelId: string) => "Documents",
			EDatabaseRole.workspace,
			EPrefix.dmsDoc,
			() => ({
				size: EAvatarSize.medium,
				icon: "document"
			}),
			(poDocument: IDmsDocument) => ({
				guid: poDocument.guid,
				size: EAvatarSize.medium,
				icon: "document"
			})
		);
	}
}