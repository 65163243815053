import { Type } from "@angular/core";
import { plainToClass } from 'class-transformer';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { IConstructor } from "./models/iconstructor";

interface ConstructorWithMatcher {
	match: (poData: any) => boolean;
	constructor: Type<any>;
}

export abstract class ModelResolver {

	//#region FIELDS

	/** Cache des classes triées par classe de base. */
	private static readonly moClassStore: Map<IConstructor<any>, ConstructorWithMatcher[]> = (global as any).classStore ?? new Map<IConstructor<any>, ConstructorWithMatcher[]>();

	//#endregion

	//#region METHODS

	/** Ajoute une classe au cache.
	 * @param pfMatcher
	 * @param poConstructor
	 * @param poBaseType
	 */
	public static addClass<T, V extends T>(pfMatcher: (poData: V) => boolean, poConstructor: Type<V>, poBaseType: IConstructor<T>): void {
		const laConstructorWithMatchers: ConstructorWithMatcher[] = this.moClassStore.get(poBaseType) ?? [];
		laConstructorWithMatchers.push({ constructor: poConstructor, match: pfMatcher });
		this.moClassStore.set(poBaseType, laConstructorWithMatchers);
	}

	/** Transforme la donnée en instance de classe. Si pas de type trouvé, utilise `poBaseType`.
	 * @param poData
	 * @param poBaseType
	 */
	public static toClass<T, V = any>(poData: T, poBaseType: IConstructor<V>): V {
		const loConstructor: Type<V> = ArrayHelper.getLastElement(this.moClassStore.get(poBaseType)
			?.filter((poConstructorWithMatcher: ConstructorWithMatcher) => poConstructorWithMatcher.match(poData)))
			?.constructor;

		if (loConstructor)
			return poData instanceof loConstructor ? poData : plainToClass(loConstructor, poData);
		else
		return ((poData instanceof poBaseType) as any) ? poData : (plainToClass(poBaseType as any, poData) as any);
	}

	//#endregion

}
