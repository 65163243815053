import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Keyboard } from '@capacitor/keyboard';
import { IonItemSliding, PopoverController } from '@ionic/angular';
import { EMPTY, EmptyError, Observable, combineLatest, from, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, mapTo, mergeMap, switchMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { DateHelper } from '../../../helpers/dateHelper';
import { EntityHelper } from '../../../helpers/entityHelper';
import { ObjectHelper } from '../../../helpers/objectHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { EPrefix } from '../../../model/EPrefix';
import { IIndexedArray } from '../../../model/IIndexedArray';
import { EBarElementDock } from '../../../model/barElement/EBarElementDock';
import { EBarElementPosition } from '../../../model/barElement/EBarElementPosition';
import { IBarElement } from '../../../model/barElement/IBarElement';
import { ConfigData } from '../../../model/config/ConfigData';
import { IContact } from '../../../model/contacts/IContact';
import { IGroup } from '../../../model/contacts/IGroup';
import { IGroupMember } from '../../../model/contacts/IGroupMember';
import { EConversationStatus } from '../../../model/conversation/EConversationStatus';
import { IConversation } from '../../../model/conversation/IConversation';
import { IConversationActivity } from '../../../model/conversation/IConversationActivity';
import { IConversationListParams } from '../../../model/conversation/IConversationListParams';
import { IGetConversationOptions } from '../../../model/conversation/IGetConversationOptions';
import { IOpenConversationOptions } from '../../../model/conversation/IOpenConversationOptions';
import { IParticipant } from '../../../model/conversation/IParticipant';
import { IEntity } from '../../../model/entities/IEntity';
import { IEntityLink } from '../../../model/entities/IEntityLink';
import { ELinkAction } from '../../../model/link/ELinkAction';
import { ELinkTemplate } from '../../../model/link/ELinkTemplate';
import { LinkInfo } from '../../../model/link/LinkInfo';
import { ActivePageManager } from '../../../model/navigation/ActivePageManager';
import { EAvatarSize } from '../../../model/picture/EAvatarSize';
import { IAvatar } from '../../../model/picture/IAvatar';
import { ISearchOptions } from '../../../model/search/ISearchOptions';
import { IUiResponse } from '../../../model/uiMessage/IUiResponse';
import { Loader } from '../../../modules/loading/Loader';
import { ObservableArray } from '../../../modules/observable/models/observable-array';
import { EPermission } from '../../../modules/permissions/models/EPermission';
import { IHasPermission, Permissions, PermissionsService } from '../../../modules/permissions/services/permissions.service';
import { ApplicationService } from '../../../services/application.service';
import { ArchivingService } from '../../../services/archiving.service';
import { ContactsService } from '../../../services/contacts.service';
import { ConversationService } from '../../../services/conversation.service';
import { EntityLinkService } from '../../../services/entityLink.service';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '../../../services/loading.service';
import { PatternResolverService } from '../../../services/pattern-resolver.service';
import { Store } from '../../../services/store.service';
import { UiMessageService } from '../../../services/uiMessage.service';
import { WorkspaceService } from '../../../services/workspace.service';
import { DynamicPageComponent } from '../../dynamicPage/dynamicPage.component';
import { LinkPopoverComponent } from '../../popover/linkPopover.component';
import { ToolbarComponent } from '../../toolbar/toolbar.component';
import { ConversationComponent } from '../conversation.component';

interface IHydratedConversation extends IConversation {
	lastMessageAvatar?: IAvatar;
	conversationAvatar?: IAvatar | string;
	subtitle?: string;
	userActivity?: IConversationActivity;
};

@Component({
	selector: "calao-conversation-list",
	templateUrl: './conversations-list.component.html',
	styleUrls: ['./conversations-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationsListComponent extends ComponentBase implements OnInit, OnDestroy, IHasPermission {

	//#region FIELDS

	private static readonly C_LOG_ID = "CONVLIST.C::";

	private readonly moActivePageManager: ActivePageManager;

	/** Objet itemSliding qui est actif. */
	private moActiveItemSliding: IonItemSliding;
	/** Objet de paramètres pour la récupération des conversations. */
	private moGetConversationOptions: IGetConversationOptions;


	//#endregion

	//#region PROPERTIES

	@Input() public params: IConversationListParams;

	public readonly userContactPath: string;
	public readonly userContactId: string = ContactsService.getUserContactId();
	public readonly deletedMessageBody: string = ConversationComponent.C_DELETED_MESSAGE_BODY;

	@Permissions("delete")
	/** Indique si l'utilisateur peut supprimer des conversations. */
	public get canDelete(): boolean { return true; }

	public readonly permissionScope: EPermission = EPermission.conversations;

	/** Tableau de toutes les conversations. */
	public conversations: ObservableArray<IHydratedConversation>;
	/** Liste des participants indexés par identifiant de conversation. */
	public participants: IIndexedArray<IParticipant<IGroupMember>[]> = {};
	/** Permet de savoir si le bouton de suppression doit être affiché */
	public enableDelete = false;
	/** Indique si on est en version de production ou non. */
	public isProductionEnvironment: boolean;
	/** Optionspour le composant de recherche. */
	public searchOptions: ISearchOptions<IConversation>;
	/** Valeur à passer à la barre de recherche. */
	public searchValue = "";

	private mbIsDownloading = false;
	public get isDownloading(): boolean { return this.mbIsDownloading; }
	public set isDownloading(pbNewValue: boolean) {
		if (pbNewValue !== this.mbIsDownloading) {
			this.mbIsDownloading = pbNewValue;
			this.detectChanges();
		}
	}

	/** Conversations filtrées. */
	private maFilteredConversations: IConversation[] = [];
	public get filteredConversations(): IConversation[] { return this.maFilteredConversations; }
	@Input() public set filteredConversations(paConversations: IConversation[]) {
		if (this.maFilteredConversations !== paConversations) {
			this.maFilteredConversations = paConversations;
			this.detectChanges();
		}
	}

	/** Affichage ou non du bouton de création. */
	private mbHideFabButton: boolean;
	public get hideFabButton(): boolean { return this.mbHideFabButton; }
	@Input() public set hideFabButton(pbValue: boolean) {
		if (this.mbHideFabButton !== pbValue) {
			this.mbHideFabButton = coerceBooleanProperty(pbValue);
			this.detectChanges();
		}
	}

	private msConversationsStatusFilter: EConversationStatus;
	public get conversationsStatusFilter(): EConversationStatus { return this.msConversationsStatusFilter; }
	@Input() public set conversationsStatusFilter(psStatus: EConversationStatus) {
		if (this.msConversationsStatusFilter !== psStatus) {
			this.msConversationsStatusFilter = psStatus;
			this.detectChanges();
		}
	}

	/** Activités de l'utilisateur */
	private moUserActivities: IIndexedArray<IConversationActivity>;
	public get userActivities(): IIndexedArray<IConversationActivity> { return this.moUserActivities; }
	public set userActivities(paUserActivities: IIndexedArray<IConversationActivity>) {
		if (this.moUserActivities !== paUserActivities) {
			this.moUserActivities = paUserActivities;
			this.detectChanges();
		}
	}

	//#endregion

	//#region METHODS

	constructor(
		private moParentPage: DynamicPageComponent<ComponentBase>,
		/** Service des conversations. */
		private isvcConversation: ConversationService,
		private isvcUiMessage: UiMessageService,
		private ioPopoverCtrl: PopoverController,
		private isvcLoading: LoadingService,
		private isvcEntityLink: EntityLinkService,
		private isvcContact: ContactsService,
		private isvcPatternResolver: PatternResolverService,
		public readonly isvcPermissions: PermissionsService,
		private isvcArchiving: ArchivingService,
		private isvcWorkspace: WorkspaceService,
		poRouter: Router,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poChangeDetectorRef);

		this.moActivePageManager = new ActivePageManager(this, poRouter);
		this.userContactPath = this.isvcConversation.getCurrentUserContactPath();
		this.isProductionEnvironment = ConfigData.isProductionEnvironment;
		this.initSearchOptions();
	}

	public ngOnInit(): void {
		if (!this.params)
			this.params = {};

		if (!this.hideFabButton)
			this.initToolbarElements();

		this.setLinkedEntities();

		this.conversations = new ObservableArray(this.getConversations().pipe(takeUntil(this.destroyed$)));
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moParentPage.toolbar.clear(this.getInstanceId());
	}

	private initSearchOptions(): void {
		this.searchOptions = {
			searchboxPlaceholder: "Rechercher une conversation",
			hasPreFillData: true,
			searchFunction: (poConversation: IConversation, psSearchValue: string) => this.conversationSearchFunction(poConversation, psSearchValue)
		};
	}

	/** Ferme les options révélées de l'itemSliding. */
	private closeItemSlidingOptions(): void {
		if (this.moActiveItemSliding) {
			this.moActiveItemSliding.close();
			this.moActiveItemSliding = undefined;
		}
	}

	/** Exécute la suppression de la conversation après clic du bouton de confirmation.
	 * @param poConversation Conversation à supprimer.
	 */
	private execDelete(poConversation: IConversation): Observable<boolean> {
		return this.isvcConversation.delete(poConversation)
			.pipe(
				map(_ => {
					this.closeItemSlidingOptions();

					return !!ArrayHelper.removeElementById(this.filteredConversations, poConversation._id);
				}),
				tap(_ => this.detectChanges())
			);
	}

	/** Génère des informations (titre, avatar, dernier message, ...) pour les conversations associées.
	 * @param paConversations Tableau des conversations dont il faut générer des informations.
	 */
	private generateInfosForConversations(paConversations: IHydratedConversation[]): Observable<IHydratedConversation[]> {
		const loConversationsById: Map<string, IHydratedConversation> = ArrayHelper.groupByUnique(
			paConversations,
			(poConversation: IHydratedConversation) => poConversation._id
		);

		return from(paConversations)
			.pipe(
				map((poConversation: IHydratedConversation) => this.generateConversationTitleAndAvatar(poConversation)),
				tap((poConversation: IHydratedConversation) => {
					try { this.generateLastMessageAvatar(poConversation); }
					// Ne pas empêcher le chargement de la liste en cas de problème avec 1 avatar/dernier message.
					catch (poError) { console.error(`${ConversationsListComponent.C_LOG_ID}Error while displaying conversation ${poConversation._id} last message.`, poError); }
				}),
				toArray(),
				mergeMap((paAllConversations: IHydratedConversation[]) => this.isvcConversation.getConversationsUserActivities(paAllConversations)
					.pipe(
						tap((poActivities: IIndexedArray<IConversationActivity>) => {
							for (const lsConvId in poActivities) {
								const loConversation: IHydratedConversation = loConversationsById.get(lsConvId);

								if (loConversation)
									loConversation.userActivity = poActivities[lsConvId];
							}
							this.detectChanges();
						}),
						mapTo(paConversations)
					))
			);
	}

	/** Génère un titre et un avatar pour une conversation donnée.
	 * @param poConversation Conversation dont il faut générer un titre et potentiellement un avatar.
	 */
	private generateConversationTitleAndAvatar(poConversation: IHydratedConversation): IConversation {
		const lsIconContacts = "contacts";
		const lsIconGroups = "groups";
		const laParticipants: IParticipant[] = this.getOtherContactParticipants(this.participants[poConversation._id]);
		const laGroups: IParticipant<IGroup>[] = this.isvcConversation.getGroups(this.participants[poConversation._id]);
		const lnParticipantsLength: number = laParticipants.length;
		const lnGroupsLength: number = laGroups.length;

		poConversation.subtitle = this.isvcConversation.getDefaultTitle(poConversation);

		if (lnParticipantsLength === 1 && lnGroupsLength === 0) { // Un seul participant et aucun groupe.
			const loFirstParticipantAvatar: IAvatar = ArrayHelper.getFirstElement(laParticipants).avatar;
			poConversation.conversationAvatar = loFirstParticipantAvatar ? loFirstParticipantAvatar : lsIconContacts;
		}
		else {
			if (lnGroupsLength === 0) // S'il n'y a pas de groupes et plusieurs contacts.
				poConversation.conversationAvatar = lsIconContacts;
			else { // S'il y a au moins un groupe.
				const loFirstGroupAvatar: IAvatar = ArrayHelper.getFirstElement(laGroups).avatar;
				if (lnGroupsLength === 1 && lnParticipantsLength === 0 && loFirstGroupAvatar) // Un seul groupe et qui possède un avatar, et pas de contact.
					poConversation.conversationAvatar = loFirstGroupAvatar;
				else if (lnGroupsLength > 0) // S'il y a au moins un groupe.
					poConversation.conversationAvatar = lsIconGroups;
			}
		}

		return poConversation;
	}

	/** Génère un titre à partir du dernier message envoyé de la conversation ainsi que l'avatar de l'envoyeur.
	 * @param poConversation Conversation à partir de laquelle on veut générer le titre et l'avatar.
	 */
	private generateLastMessageAvatar(poConversation: IHydratedConversation): void {
		let lsMemberId: string;

		if (poConversation.lastMessage) {
			if (!StringHelper.isBlank(poConversation.lastMessage.senderContactPath))
				lsMemberId = Store.getDocumentIdFromPath(poConversation.lastMessage.senderContactPath);
			else // Pour rétro-compat des messages qui n'ont que le sender.
				lsMemberId = ((poConversation.lastMessage as any).sender as string).replace(EPrefix.user, EPrefix.contact);

			const loParticipant: IParticipant<IGroupMember> = this.isvcConversation.findParticipant(this.participants[poConversation._id], lsMemberId);

			// NB : il est possible qu'un message ait été émis par un contact qui a, depuis, été retiré des participants.
			if (loParticipant) {
				const loAvatar: IAvatar = ObjectHelper.clone(loParticipant.avatar, true);
				loAvatar.size = EAvatarSize.small;
				poConversation.lastMessageAvatar = loAvatar;
			}
			else
				console.debug(`${ConversationsListComponent.C_LOG_ID}Contact ${lsMemberId} is not participant of conversation ${poConversation._id} anymore. Displaying its avatar is not supported.`);
		}
	}

	/** Récupère les participants d'une conversation, hormis l'utilisateur.
	 * @param paParticipants Liste des participants.
	 */
	private getOtherContactParticipants(paParticipants: IParticipant<IGroupMember>[]): IParticipant[] {
		return this.isvcConversation.getContacts(paParticipants)
			.filter((poParticipant: IParticipant<IContact>) => poParticipant.participantId !== this.userContactId);
	}

	/** Récupère les conversations en local et/ou distant suivant l'état du réseau. */
	private getConversations(): Observable<Array<IConversation>> {
		const lbHasLinkedEntities: boolean = ArrayHelper.hasElements(this.params.linkedEntities);

		this.initGetConversationOptions(lbHasLinkedEntities);

		const loGetConversationIds$: Observable<string[]> = lbHasLinkedEntities ? this.getConversationIdsFromParams() : of(undefined);

		return loGetConversationIds$
			.pipe(
				mergeMap(_ => this.inner_getConversations()),
				tap(_ => this.isDownloading = true),
				map((paConversations: IConversation[]) => {
					if (!this.params?.filterConversationsByWorkspace)
						return paConversations;

					const lsWorkspaceDbId: string = this.isvcWorkspace.getDefaultWorkspaceDatabaseId();

					return paConversations.filter((poConversation: IConversation) =>
						poConversation.participants?.some((poParticipant: IParticipant) => poParticipant.participantPath?.startsWith(lsWorkspaceDbId))
					);
				}),
				// On utilise un switchMap car on veut appeler les opérateurs suivants si la liste des conversations est modifiée ou si un document d'activité est modifié.
				// Le bouton d'options est absent pour une nouvelle conversation puis doit apparaître si la conversation est lue (doc d'activité mise à jour).
				// Optimisation possible: Au lieu de faire un getLive à chaque fois que la liste des conversations est modifiée, on devrait faire un getLive si une nouvelle conversation ou si une conversation est supprimée.
				switchMap((paConversation: IConversation[]) => this.isvcConversation.getConversationsUserActivities(paConversation).pipe(
					tap((poUserActivities: IIndexedArray<IConversationActivity>) => this.moUserActivities = poUserActivities),
					mapTo(paConversation)
				)),
				tap(() => this.isDownloading = false),
				mergeMap((paConversations: Array<IConversation>) =>
					this.isvcConversation.getConversationsParticipants(paConversations).pipe(
						tap((poParticipants: IIndexedArray<IParticipant<IContact>[]>) => this.participants = poParticipants),
						mapTo(paConversations)
					)
				),
				mergeMap((paConversations: IConversation[]) => this.generateInfosForConversations(paConversations)),
				catchError(poError => {
					if (poError instanceof EmptyError)
						return of([]);
					else {
						console.error(`${ConversationsListComponent.C_LOG_ID}Erreur getConversations() : `, poError);
						return throwError(poError);
					}
				})
			);
	}

	private initGetConversationOptions(pbHasLinkedEntities: boolean): void {
		if (!this.moGetConversationOptions) {
			this.moGetConversationOptions = {
				sortRequired: true,
				conversationIds: pbHasLinkedEntities ? [] : undefined,
				activePageManager: this.moActivePageManager
			};
		}

		this.moGetConversationOptions.live = true; // On veut récupérer les ajouts/suppressions/modifications locales des conversations.
	}

	private inner_getConversations(): Observable<IConversation[]> {
		let loLoader: Loader;

		return from(this.isvcLoading.create(ApplicationService.C_LOAD_DATA_LOADER_TEXT))
			.pipe(
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(_ => this.isvcConversation.getConversations(this.userContactId, this.moGetConversationOptions)),
				tap(() => {
					if (loLoader?.isPresented) // On dissmiss le loader après premier chargement.
						loLoader?.dismiss(); // On retire le loader quand les données sont chargées.
				}),
				finalize(() => {
					loLoader?.dismiss(); // On retire le loader en cas d'erreur ou si le flux est clôturé avant d'avoir atteint le tap (ex: Retour avec bouton physique).
				})
			);
	}

	/** Récupère les identifiants des conversations à récupérer. */
	private getConversationIdsFromParams(): Observable<string[]> {
		return combineLatest(this.params.linkedEntities.map((poEntityLink: IEntity) => this.isvcEntityLink.getEntityLinks(poEntityLink.id, [EPrefix.conversation], true)))
			.pipe(
				map((paEntityLinks: IEntityLink[][]) => {
					return this.moGetConversationOptions.conversationIds = ArrayHelper.unique(ArrayHelper.flat(paEntityLinks)
						.map((poEntityLink: IEntityLink) => EntityHelper.getEntityLinkPartFromPrefix(poEntityLink, EPrefix.conversation).entityId));
				}),
				takeUntil(this.destroyed$)
			);
	}

	/** Crée les boutons de la toolbar. */
	private initToolbarElements(): void {
		const laBarElements: Array<IBarElement> = [
			{
				id: "circle",
				component: "fabButton",
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.right,
				icon: "add",
				onTap: () => this.createConversation(),
				name:"Ajouter"
			}
		];

		this.moParentPage.toolbar$.pipe(
			takeUntil(this.destroyed$),
			tap((poToolbar: ToolbarComponent) => poToolbar.init(laBarElements, this.getInstanceId()))
		).subscribe();
	}

	public onConversationDelete(poConversation: IConversation): void {
		this.isvcConversation.isDeletable(poConversation)
			.pipe(
				mergeMap((pbCanDelete: boolean) => pbCanDelete ? this.showDeleteConversationPopup() : EMPTY),
				filter((poResponse: IUiResponse<boolean>) => poResponse.response),
				mergeMap(_ => this.execDelete(poConversation)),
				catchError(poError => { console.error(ConversationsListComponent.C_LOG_ID, poError); return EMPTY; }),
				finalize(() => this.closeItemSlidingOptions()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private showDeleteConversationPopup(): Observable<IUiResponse<boolean>> {
		return this.isvcUiMessage.showAsyncMessage<boolean>(
			new ShowMessageParamsPopup({
				message: "Cette conversation sera supprimée pour TOUS les utilisateurs ! <br/> Voulez-vous continuer ?",
				header: "Suppression",
				backdropDismiss: false,
				buttons: [
					{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() },
					{ text: "Continuer", handler: () => UiMessageService.getTruthyResponse() }
				]
			})
		);
	}

	/** Permet de passer à une page de conversation (vue ou création).
	 * @param poConversation Conversation cliquée, si non renseigné alors la conversation sera en mode création.
	 */
	public onItemClicked(poConversation: IConversation): void {
		Keyboard.hide().catch(() => { });
		this.isvcConversation.routeToConversation(poConversation);
	}

	/** Événement levé lors d'un swipe qui permet de fermer les items qui sont ouverts pour qu'un seul ne le soit en même temps.
	 * @param poItemSliding Liste des items pouvant être glissés pour révéler des boutons.
	 */
	public onSwipingEvent(poItemSliding: IonItemSliding): void {
		if (!this.moActiveItemSliding || this.moActiveItemSliding !== poItemSliding) // L'item sélectionné est différent de celui déjà ouvert, on ouvre le nouveau.
			this.moActiveItemSliding = poItemSliding;
		else // L'item cliqué est celui déjà ouvert, on le ferme.
			this.closeItemSlidingOptions();
	}

	/** Crée une nouvelle conversation. */
	private createConversation(): void {
		this.isvcConversation.createOrOpenConversation(this.userContactId, this.createOpenConvOptions())
			.pipe(
				tap(
					_ => { },
					poError => console.error(ConversationsListComponent.C_LOG_ID, poError)
				),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private createOpenConvOptions(pbIsModal: boolean = false): IOpenConversationOptions {
		return {
			galleryAcceptFiles: this.params.galleryAcceptFiles,
			currentContactId: this.userContactId,
			isModal: pbIsModal,
			linkedEntities: this.params.linkedEntities,
			contactSelectorParams: this.params.contactSelectorParams
		} as IOpenConversationOptions;
	}

	/** Ouvre les options de l'itemSliding après un swipe ou clic sur le bouton des options.
	 * @param poItemSliding Liste des items pouvant être glissés pour révéler des boutons.
	 */
	public openItemSlidingOptions(poItemSliding: IonItemSliding & { el: { classList: DOMTokenList } }): void {
		this.onSwipingEvent(poItemSliding);

		// Ouvre l'ItemSliding s'il est fermé, sinon l'ouvre.
		poItemSliding.el.classList.contains("item-sliding-active-slide") ? poItemSliding.close() : poItemSliding.open("end");
	}

	/** Méthode appelée lors du clic sur l'avatar d'une conversation.
	 * @param poConversation Conversation associée à l'avatar.
	 * @param poEvent Événement du clic.
	 */
	public onAvatarClicked(poConversation: IConversation, poEvent: Event): void {
		const laParticipants: IParticipant[] = this.getOtherContactParticipants(this.participants[poConversation._id]);

		if (laParticipants.length === 1) {
			const loPopover: Promise<HTMLIonPopoverElement> = this.ioPopoverCtrl.create({
				component: LinkPopoverComponent,
				componentProps: {
					links: this.createPopoverLinksInfo(ArrayHelper.getFirstElement(laParticipants))
				},
				event: poEvent
			});

			loPopover.then((poPopover: HTMLIonPopoverElement) => poPopover.present());
		}
	}

	/** Crée les informations pour la créations des liens du menu contextuel.
	 * @param poParticipant Participant dont il faut afficher le contact.
	 */
	private createPopoverLinksInfo(poParticipant: IParticipant): LinkInfo[] {
		return [
			new LinkInfo({
				label: "Voir le contact",
				action: ELinkAction.callback,
				templateId: ELinkTemplate.item,
				actionParams: {
					function: () => {
						this.isvcConversation.getContactFromParticipant(poParticipant)
							.pipe(
								tap((poContact: IContact) => this.isvcContact.routeToContact(poContact)),
								takeUntil(this.destroyed$)
							)
							.subscribe();
					}
				}
			}),
			new LinkInfo({
				label: "Filtrer par ce contact",
				action: ELinkAction.callback,
				templateId: ELinkTemplate.item,
				actionParams: {
					function: () => {
						this.searchValue = poParticipant.label;
						this.detectChanges();
					}
				}
			})
		];
	}

	/** Fonction de recherche de conversations.
	 * @param poConversation Conversation sur laquelle rechercher.
	 * @param psSearchValue Valeur à recherche dans la conversation.
	 */
	private conversationSearchFunction(poConversation: IConversation, psSearchValue: string): boolean {
		return this.innerConversationSearchFunction(poConversation.title, psSearchValue) ||
			poConversation.participants.some((poParticipant: IParticipant) => this.innerConversationSearchFunction(poParticipant.label, psSearchValue)) ||
			(poConversation.lastMessage && this.innerConversationSearchFunction(poConversation.lastMessage.body, psSearchValue));
	}

	/** Permet de savoir si le champs correspond à la valeur recherchée.
	 * @param psField Champs de la conversation.
	 * @param psSearchValue Valeur à recherche dans le champs.
	 */
	private innerConversationSearchFunction(psField: string, psSearchValue: string): boolean {
		return !StringHelper.isBlank(psField) && psField.toLowerCase().indexOf(psSearchValue.toLowerCase()) >= 0;
	}

	/** Méthode appelée lors d'un changement dans les conversations filtrées.
	 * @param paFilteredConversations Tableau des conversations répondant au filtre de recherche.
	 */
	public onFilteredConversationsChanged(paFilteredConversations: IConversation[]): void {
		if (!ArrayHelper.areArraysFromDatabaseEqual(this.filteredConversations, paFilteredConversations))
			this.filteredConversations = this.filterConversationByStatus(paFilteredConversations);
	}

	/** Appelle le filtre correspondant au statut.
	 * @param paFilteredConversations Conversations à filtrer.
	 */
	private filterConversationByStatus(paFilteredConversations: IConversation[]): IConversation[] {
		if (this.conversationsStatusFilter === EConversationStatus.active)
			return this.getFilteredActiveConversations(paFilteredConversations);
		else if (this.conversationsStatusFilter === EConversationStatus.archived)
			return this.getFilteredArchivedConversations(paFilteredConversations);
		else
			return paFilteredConversations;
	}

	/** Retourne les conversations actives.
	 * @param paConversations Conversations à filtrer.
	 */
	private getFilteredActiveConversations(paConversations: IConversation[]): IConversation[] {
		return paConversations.filter((poConversation: IConversation) => !this.isConversationArchived(poConversation));
	}

	/** retourne `true` si la conversation est active.
	 * @param poConversation La conversation à vérifier.
	 */
	public isActiveConversation(poConversation: IConversation): boolean {
		return !this.isConversationArchived(poConversation);
	}

	public canArchive(poConversation: IConversation): boolean {
		return this.isActiveConversation(poConversation) && this.hasActivity(poConversation);
	}

	/** Retourne `true` si la conversation est archivée et peut être restaurée. */
	public canRestore(poConversation: IConversation): boolean {
		return !this.isActiveConversation(poConversation);
	}

	/** Retourne `true` si la conversation possède au moins un bouton d'options. */
	public hasOptionButtons(poConversation: IConversation): boolean {
		return this.canDelete || this.canArchive(poConversation) || this.canRestore(poConversation);
	}

	/** Retourne les conversation archivées.
	 * @param paConversations Les conversation à filtrer.
	 */
	private getFilteredArchivedConversations(paConversations: IConversation[]): IConversation[] {
		return paConversations.filter((poConversation: IConversation) => this.isConversationArchived(poConversation));
	}

	/** Retourne `true` si la conversation est archivée, sinon false.
	 * @param poConversation La conversation à vérifier.
	 */
	private isConversationArchived(poConversation: IConversation): boolean {
		return (this.moUserActivities[poConversation._id] && poConversation?.lastMessage && this.moUserActivities[poConversation._id]?.archive &&
			DateHelper.compareTwoDates(this.moUserActivities[poConversation._id]?.archive, poConversation?.lastMessage.createDate) > 0) ||
			(this.moUserActivities[poConversation._id] && this.moUserActivities[poConversation._id]?.archive && !poConversation?.lastMessage);
	}

	/** Retourne la liste des conversations de l'activité la plus récente à la moins récente. */
	public orderConversationsByDate(paConversations: IConversation[]): IConversation[] {
		return paConversations.sort((poConv1: IConversation, poConv2: IConversation) => {
			// On prend l'activité du dernier message s'il existe, sinon celle de la date de création de la conversation.
			const ldConv1LastMessage: Date = new Date(poConv1.lastMessage ? poConv1.lastMessage.createDate : poConv1.createDate);
			const ldConv2LastMessage: Date = new Date(poConv2.lastMessage ? poConv2.lastMessage.createDate : poConv2.createDate);

			return DateHelper.compareTwoDates(ldConv2LastMessage, ldConv1LastMessage);
		});
	}

	/** Remplis le tableau `this.params.linkedEntities` en resolvant les patterns de `this.params.linkedEntitesPattern`. */
	private setLinkedEntities(): void {
		if (this.params && ArrayHelper.hasElements(this.params.linkedEntities)) {
			for (let i = 0; i < this.params.linkedEntities.length; i++) {
				if (typeof this.params.linkedEntities[i] === "string") {
					// On résoud l'élément.
					const loResolvedEntity: IEntity = this.isvcPatternResolver.resolveFormsPattern<IEntity>(this.params.linkedEntities[i] as string, undefined, undefined);

					// Si résolution correct, on l'ajoute au tableau des éléments résolus.
					if (loResolvedEntity)
						this.params.linkedEntities[i] = loResolvedEntity;
					else
						console.error(`${ConversationsListComponent.C_LOG_ID}Entity pattern not resolved correctly : `, this.params.linkedEntities[i]);
				}
			}
		}
	}

	/** Permet de récuperer la classe CSS pour l'affichage de la conversation.
	 * @param poConversation
	 */
	public isRead(poConversation: IHydratedConversation): boolean {
		return !poConversation || this.isvcConversation.isRead(poConversation, poConversation.userActivity);
	}

	/** Retourne `true` si l'utilisateur a une activité sur la conversation, sinon `false`.
	 * @param poConversation La conversation à vérifier.
	 */
	public hasActivity(poConversation: IConversation): boolean {
		return coerceBooleanProperty(this.userActivities[poConversation._id]);
	}

	/** Archive la conversation.
	 * @param poConversation La conversation à archiver.
	 */
	public onConversationArchiveClicked(poConversation: IConversation): void {
		const lsConversationUserActivityId = this.isvcConversation.getConversationUserActivityId(poConversation);
		this.isvcConversation.getConversationUserActivity(lsConversationUserActivityId)
			.pipe(
				mergeMap((poConversationActivity: IConversationActivity) => this.isvcArchiving.archiveConversation(poConversationActivity)),
				map((poUserActivity: IConversationActivity) => {
					if (this.msConversationsStatusFilter)
						this.filteredConversations = this.filteredConversations.filter((poFilteredConversation: IConversation) => poFilteredConversation._id !== poConversation._id);
					else {
						this.userActivities[poConversation._id] = poUserActivity;
						this.userActivities = { ...this.userActivities } as IIndexedArray<IConversationActivity>;
					}
				})
			).subscribe();
	}

	/** Restaure la conversation.
	 * @param poConversation La conversation à restaurer.
	 */
	public onConversationRestoreClicked(poConversation: IConversation): void {
		const lsConversationUserActivityId = this.isvcConversation.getConversationUserActivityId(poConversation);
		this.isvcConversation.getConversationUserActivity(lsConversationUserActivityId)
			.pipe(
				mergeMap((poConversationActivity: IConversationActivity) => this.isvcArchiving.restoreConversation(poConversationActivity)),
				map((poUserActivity: IConversationActivity) => {
					if (this.msConversationsStatusFilter)
						this.filteredConversations = this.filteredConversations.filter((poFilteredConversation: IConversation) => poFilteredConversation._id !== poConversation._id);
					else {
						this.userActivities[poConversation._id] = poUserActivity;
						this.userActivities = { ...this.userActivities } as IIndexedArray<IConversationActivity>;
					}
				})
			).subscribe();
	}

	//#endregion

}
