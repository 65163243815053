<div class="form-group">
	<div class="input-group">

		<!-- barre de recherche -->
		<div #searchDiv style="display: flex;">
			<ion-button *ngIf="isLocationEnabled" size="small" [hidden]="!isPanelCollapsed"
				class="btn button-solid btn-drop-down margin-btn" (click)="getAndFormatLocation()">
				<ion-icon slot="icon-only" name="locate"></ion-icon>
			</ion-button>
			<mat-form-field appearance="outline" class="searchBox-group" [hidden]="!isPanelCollapsed">
				<ion-icon matPrefix name="search-sharp" class="search-icon"></ion-icon>


				<input #searchBarElement type="text" matInput [matAutocomplete]="autoGroup" #trigger="matAutocompleteTrigger"
					[value]="searchValue" autocapitalize="none" [formControl]="searchFormControl"
					[placeholder]="options.searchboxPlaceholder" (click)="openAutocomplete()"
					[matAutocompleteDisabled]="!!searchValue || !(autoCompleteOptions?.size > 0)"
					(keyup.enter)="searchBarElement.blur(); closeKeyboard();" (blur)="onInputBlur($event)" data-cy="search-input">
				<button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="searchValue=''">
					<mat-icon matSuffix>close</mat-icon>
				</button>
				<mat-autocomplete #autoGroup="matAutocomplete">
					<mat-optgroup *ngFor="let item of (autoCompleteOptions | keyvalue : conserveInsertionOrder)"
						[label]="item.key">
						<mat-option *ngFor="let value of item.value" [value]="value.text"
							(click)="onAutocompleteOptionClicked($event, value)" [attr.instanceid]="getInstanceId()">
							{{value.text}}
						</mat-option>
					</mat-optgroup>
				</mat-autocomplete>
			</mat-form-field>

			<div *ngIf="options.isSearchPanelEnable" class="search-box-more-options">
				<!-- plier ou déplier la barre de recherche avancée -->
				<ion-button (click)="toggleCollapse()" size="small" [hidden]="!isPanelCollapsed"
					class="btn button-solid button-clear margin-btn btn-drop-down">
					<ion-icon slot="icon-only" name="caret-down-outline"></ion-icon>
				</ion-button>
			</div>
		</div>

		<!-- partie dépliable de la barre de recherche (mode avancé) -->
		<div *ngIf="options.isSearchPanelEnable">
			<div [hidden]="isPanelCollapsed">
				<div *ngFor="let field of options.searchableFields; let i = index">
					<ion-item *ngIf="field.type !== 'location'">
						<ion-label position="floating" class="label-input">{{field.label}} :</ion-label>
						<ion-input *ngIf="field.type == undefined || field.type == 'text'" type="text" class="form-control"
							class="search-input" [(ngModel)]="searchPanelValues[i]" (ionChange)="onPanelTextChanged()"
							autocapitalize="none" (keyup.enter)="searchBarElement.blur(); closeKeyboard();"></ion-input>
					</ion-item>
				</div>
				<div *ngIf="options.locationSearch" class="searchBox-group">
					<div class="left-search">
						<ion-item>
							<ion-label>
								{{ options.locationSearch.label ? options.locationSearch.label : "Autour de moi :" }}</ion-label>
							<ion-select style="max-width: 100%;" [(ngModel)]="currentLocationRadius"
								(ngModelChange)="getAndFormatLocation()">
								<ion-select-option *ngFor="let radius of options.locationSearch.searchRadiusOptions" [value]=radius>
									{{radius}}
									Km</ion-select-option>
							</ion-select>
						</ion-item>

					</div>
					<div class="right-search">
						<ion-button *ngIf="!isPanelCollapsed" size="small" fill="clear" (click)="toggleCollapse()"
							class="btn button-solid btn-moins margin-btn">
							<ion-icon slot="icon-only" name="caret-up"></ion-icon>
						</ion-button>
						<ion-button size="small" class="btn margin-btn">
							<ion-icon slot="icon-only" name="search"></ion-icon>
						</ion-button>
						<ion-button size="small" (click)="getAndFormatLocation()" class="btn margin-btn">
							<ion-icon slot="icon-only" name="locate"></ion-icon>
						</ion-button>

					</div>
				</div>
			</div>

		</div>

	</div>
</div>