import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';

@Component({
  selector: 'di-liste-patients',
  templateUrl: './liste-patients.component.html',
  styleUrls: ['./liste-patients.component.scss'],
})
export class ListePatientsComponent implements OnInit {

  @Input() 
  public patients: IPatient[];

	@Input()
  public mobileViewForce? = false;

	@Output()
  public onClickLigne = new EventEmitter<string>();

  constructor() { }

	onClick(patientId: string): void {
    this.onClickLigne.emit(patientId);
  }

  ngOnInit() { }

}
