<div class="di-liste-seance-tournees__container">
	<div class="liste-seances-tournees">
    <div class="seance-tournees">
      <div *ngFor="let moment of moments" class="seance-moment">
        <lua-separateur-groupe [letter]="false" [label]="moment.label"></lua-separateur-groupe>
        <!-- A decommenter une fois la v2 sortie -->
        <!-- <lua-separateur-groupe [letter]="false" iconName="more_vert" [label]="moment.label"></lua-separateur-groupe> -->
        <lua-message-aucune-donnee *ngIf="sortedSeances[moment.key]?.length === 0"
				mainText="Aucun soin"
        [subText]="moment.labelNoData"
        buttonLabel=""
        iconName=""
			></lua-message-aucune-donnee>
        <div *ngFor="let seance of sortedSeances[moment.key]">
          <di-carte-seance
          [seance]="seance"
          [patient]="patients.get(seance.patientId)"
          [ordonnance]="ordonnances.get(seance.traitementId)">
        </di-carte-seance>
        </div>
      </div>
      <lua-divider-horizontal size="medium"></lua-divider-horizontal>
      <div class="seance-realisee">
        <strong>{{labelSoinRealise}}</strong>
        <p>0 €</p>
      </div>
    </div>
	</div>
</div>
