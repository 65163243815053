import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { EFormEventType } from '@osapp/model/forms/EFormEventType';
import { IFormEvent } from '@osapp/model/forms/IFormEvent';
import { IUiResponse } from '@osapp/model/uiMessage/IUiResponse';
import { LoggerService } from '@osapp/modules/logger/services/logger.service';
import { FormsService } from '@osapp/services/forms.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '@osapp/services/interfaces/ShowMessageParamsToast';
import { SlideboxService } from '@osapp/services/slidebox.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { defer, EMPTY, of } from 'rxjs';
import { catchError, filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { IdlActionButtonFieldBase } from '../../../model/action-buttons/IdlActionButtonFieldBase';
import { EExportResult } from '../../../model/EExportResult';
import { EUpdateMode } from '../../../model/eupdate-mode.enum';
import { ExportError } from '../../../model/ExportError';
import { IPatient } from '../../../model/IPatient';
import { ExportService } from '../../../services/export.service';
import { IdlActionButtonService } from '../../../services/idl-action-button.service';
import { PatientsService } from '../../../services/patients.service';

@Component({
	selector: "idl-adri-call-action-button",
	templateUrl: './adri-call-action-button.component.html',
	styleUrls: ['./adri-call-action-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ADRiCallActionButtonComponent extends IdlActionButtonFieldBase<IPatient> implements OnInit {

	//#region PROPERTIES

	public hasBeenCalled: boolean;

	public icon: string;
	public label: string;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcExport: ExportService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcPatients: PatientsService,
		/** @implements */
		public readonly isvcLogger: LoggerService,
		psvcSlidebox: SlideboxService,
		psvcActionButton: IdlActionButtonService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcSlidebox, psvcActionButton, psvcForms, poChangeDetectorRef);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		this.icon = "adri";
		this.label = "Appel ADRi";
		this.hasBeenCalled = this.model.updateMode === EUpdateMode.ADRi;

		this.isvcForms.waitFormEvent(this.model._id, EFormEventType.afterSubmit)
			.pipe(
				tap((poEvent: IFormEvent) => this.hasBeenCalled = (poEvent.data.model as IPatient).updateMode === EUpdateMode.ADRi),
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();
	}

	/** @override Exporte un patient vers le logiciel partenaire. */
	public action(): void {
		defer(() => {
			if (this.model.updateMode === EUpdateMode.ADRi && DateHelper.diffHours(new Date(), this.model.updateDate) < 24) {
				return this.isvcUiMessage.showAsyncMessage<boolean>(new ShowMessageParamsPopup({
					header: "Patient à jour.",
					message: "Patient mis à jour il y a moins de 24h. <br>Voulez-vous quand même le mettre à jour ?",
					buttons: [
						{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() },
						{ text: "Oui", handler: () => UiMessageService.getTruthyResponse() }
					]
				}))
					.pipe(map((poResponse: IUiResponse<boolean>) => poResponse.response));
			}
			else
				return of(true);
		})
			.pipe(
				filter((pbResponse: boolean) => pbResponse),
				mergeMap(() => this.isvcPatients.getPatient(this.model._id)),
				mergeMap((poPatient: IPatient) => this.isvcExport.exportPatient(poPatient)),
				catchError((poError: ExportError | any) => {
					this.displayADRiErrorMessage(poError);
					return EMPTY;
				}),
				tap((peResult: EExportResult) => this.managePatientADRiResult(peResult)),
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();
	}

	private managePatientADRiResult(peResult: EExportResult): void {
		if (peResult === EExportResult.patientSuccess || peResult === EExportResult.patientUpdated) {
			this.isvcUiMessage.showMessage(
				new ShowMessageParamsToast({ message: "Le patient a bien été mis à jour." })
			);

			this.hasBeenCalled = true;
			this.detectChanges();
		}
		else
			this.displayADRiErrorMessage();
	}

	private displayADRiErrorMessage(poError?: ExportError | any): void {
		if (poError)
			console.error(`IDL.ADRi.C:: Erreur ADRi patient : `, poError);

		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({
				message: (poError && poError instanceof ExportError) ? poError.message : ExportService.C_ADRI_PATIENT_ERROR_MESSAGE,
				header: "Erreur de mise à jour"
			})
		);
	}

	//#endregion

}