<div class="component-recherche-actes__container">
	<div *ngIf="displayCount && searchedValue.length>2" class="component-recherche-actes__header">
		<small>{{ actesCountMessage }}</small>
	</div>
	<div class="component-recherche-actes__filtres" [ngClass]="{'mobileViewForce': mobileViewForce}">
		<lua-recherche (onValueChange)="filterChapter($event)" iconeDefault="search" [secondaryAction]="false"
			[searchText]="searchedValue">
		</lua-recherche>
	</div>
	<lua-switch (clickSwitch)="toggleIsAMX($event)" label="Patient dépendant" [isChecked]="isDependant"
		texteSecondaire="(Affichage des actes AMX)"></lua-switch>
	<ng-container *ngTemplateOutlet="chapter; context: { actesBySubChapter: actesByChapter, chapterLvl: 0 }">
	</ng-container>
	<ng-template #chapter let-actesBySubChapter="actesBySubChapter" let-chapterLvl="chapterLvl">
		<ng-container>
			<div *ngIf="!selectedChapter; else showActs">
				<div class="list-subChapter">
					<lua-list-item (click)="onClickFav(item)" [iconName]="'Favorite'" [text]="'Favoris' "
						[endIconName]="'arrow_forward_ios'" [iconColor]="'Noir'"></lua-list-item>
				</div>
				<div *ngFor="let item of actesBySubChapter | keyvalue; let i = index;">
					<div class="list-subChapter">
						<lua-list-item (click)="onClickChapter(item)" [text]="item.key.description "
							[endIconName]="'arrow_forward_ios'" [iconColor]="'Noir'"></lua-list-item>
					</div>
				</div>
			</div>
			<ng-template #showActs>
				<div>
					<div class="entete">
						<lua-bouton-icone [iconName]="'arrow_back_ios'" [iconColor]="'Noir'"
							(click)="goBackToChapters()"></lua-bouton-icone>
						<h2>{{selectedChapter.description}}</h2>
					</div>
					<ng-container *ngIf="selectedChapterContent.subChapters">
						<ng-container
							*ngTemplateOutlet="subChapter; context: { actesBySubChapter: selectedChapterContent.subChapters, chapterLvl: 0 }">
						</ng-container>
						<ng-template #subChapter let-actesBySubChapter="actesBySubChapter" let-chapterLvl="chapterLvl">
							<div *ngFor="let item of actesBySubChapter| keyvalue; let i = index;">
								<div class="accordion">
									<lua-accordion [title]="item.key.description " [open]="open" [mode]="'small'" [color]="'Gris'">
										<div *ngFor="let acte of item.value.actes" class="ligne-acte">
											<di-acte [clickable]="true" [favoriteIcon]="true" [acte]="acte"
												(onCheckboxChange)="onFavoritesClicked(acte, $event)"
												[status]="favorites?.ids?.includes(acte._id) ? 'done' : 'to be done'"
												(onActeSelected)="onActeSelected($event)"></di-acte>
										</div>
									</lua-accordion>
								</div>
							</div>
						</ng-template>
					</ng-container>
					<ng-container *ngIf="selectedChapterContent.actes">
						<div *ngFor="let acte of selectedChapterContent.actes" class="ligne-acte">
							<di-acte [favoriteIcon]="true" [clickable]="true" [acte]="acte"
								(onCheckboxChange)="onFavoritesClicked(acte, $event)"
								[status]="favorites?.ids?.includes(acte._id) ? 'done' : 'to be done'"
								(onActeSelected)="onActeSelected($event)"></di-acte>
						</div>
					</ng-container>
					<ng-container *ngIf="selectedChapterContent?.actes?.length === 0">
						<div>
							<lua-message-aucune-donnee [mainText]="noResultMessage" iconName="contract" [subText]=""
								buttonLabel="Retour" (onClick)="goBackToChapters()"></lua-message-aucune-donnee>
						</div>
					</ng-container>
				</div>
			</ng-template>
		</ng-container>
	</ng-template>
</div>