import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { PlatformService } from '@osapp/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GeocodingService {
	
  private apiUrl = 'https://nominatim.openstreetmap.org/search';
	private static readonly C_NAVIGATION_URLS = {
		androidUrl: "geo:${lat},${lng}?q=${lat},${lng}",
		iosUr: "maps://?q=",
		wazeUrl: "https://waze.com/ul?",
	};

	private static readonly C_IN_APP_BROWSER_EXTERN_WINDOW_PARAM = "_system";
	/**  */
	private static readonly C_IN_APP_BROWSER_NO_LOCATION_PARAM = "location=no";

  constructor(private http: HttpClient,
		private ioInAppBrowser: InAppBrowser,
		private svcDeviceService : PlatformService
		) {}

  public getGeolocation(address: string): Observable<{ lat: number, lng: number }> {
    const url = `${this.apiUrl}?q=${encodeURIComponent(address)}&format=json&limit=1`;

    return this.http.get(url).pipe(
      map((response: any) => {
        if (response.length > 0) {
          const location = response[0];
          return { lat: parseFloat(location.lat), lng: parseFloat(location.lon) };
        } else {
          throw new Error('Adresse non trouvée');
        }
      })
    );
  }

	public navigateToCoordinates(lat: number, lng: number): any {
		let url = "";
		if(this.svcDeviceService.isMobileApp)
		{
			if(this.svcDeviceService.isIOS)
			{
				url = `maps://?q=${lat},${lng}`;
			}
			if(this.svcDeviceService.isAndroid)
			{
				url = `geo:${lat},${lng}?q=${lat},${lng}`;
			}
		}
		else
		{
			url = `${GeocodingService.C_NAVIGATION_URLS.wazeUrl}ll=${lat},${lng}&navigate=yes`
		}
		this.ioInAppBrowser.create(
			url,
			GeocodingService.C_IN_APP_BROWSER_EXTERN_WINDOW_PARAM,
			GeocodingService.C_IN_APP_BROWSER_NO_LOCATION_PARAM
		);
	}
}
