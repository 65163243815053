<div class="panneau-choix-medecin__container">
	<div class="container__content">
		<di-liste-contacts
			[contactsList]="contacts"
			(contactSelection)="selectContact($event)"
			[displayHeader]="false"
			(clickAddContact)="handleClickAddContact(model._id)"
		></di-liste-contacts>
	</div>
	<lua-bouton-floating
		class="btn-ajout-contact-mobile"
		bottom="40px"
		right="40px"
		iconName="add"
		(click)="handleClickAddContact(model._id)"
	>
	</lua-bouton-floating>
</div>
