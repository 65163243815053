import { coerceArray } from '@angular/cdk/coercion';
import { Injectable, Type } from '@angular/core';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { GuidHelper } from '@osapp/helpers/guidHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { MapHelper } from '@osapp/helpers/mapHelper';
import { NumberHelper } from '@osapp/helpers/numberHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { ConfigData } from '@osapp/model';
import { EPrefix } from '@osapp/model/EPrefix';
import { ESortOrder } from '@osapp/model/ESortOrder';
import { UserData } from '@osapp/model/application/UserData';
import { IGroupMember } from '@osapp/model/contacts/IGroupMember';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { EAvatarSize } from '@osapp/model/picture/EAvatarSize';
import { IAvatar } from '@osapp/model/picture/IAvatar';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { IDataSource } from '@osapp/model/store/IDataSource';
import { IMinMaxDates } from '@osapp/modules/date/model/IMinMaxDates';
import { EUTCAccuracy } from '@osapp/modules/date/model/eutcaccuracy.enum';
import { DayRepetition } from '@osapp/modules/event-markers/models/day-repetition';
import { C_RANGE_REPETITION_TYPE, RangeRepetition } from '@osapp/modules/event-markers/models/range-repetition';
import { Recurrence } from '@osapp/modules/event-markers/models/recurrence';
import { ILogSource } from '@osapp/modules/logger/models/ILogSource';
import { LogActionHandler } from '@osapp/modules/logger/models/log-action-handler';
import { LoggerService } from '@osapp/modules/logger/services/logger.service';
import { Observation } from '@osapp/modules/observations/models/observation';
import { ObservationsService } from '@osapp/modules/observations/observations.service';
import { PerformanceManager } from '@osapp/modules/performance/PerformanceManager';
import { EPrestationStatus } from '@osapp/modules/prestation/models/eprestation-status.enum';
import { PrestationService } from '@osapp/modules/prestation/services/prestation.service';
import { IRange } from '@osapp/modules/utils/models/models/irange';
import { DestroyableServiceBase } from '@osapp/modules/utils/services/destroyable-service-base';
import { ContactsService } from '@osapp/services';
import { ApplicationService } from '@osapp/services/application.service';
import { Store } from '@osapp/services/store.service';
import { TarifHelper } from 'apps/idl/src/helpers/tarifHelper';
import { Acte } from 'apps/idl/src/model/Acte';
import { EMajorationType } from 'apps/idl/src/model/EMajorationType';
import { EPathologie } from 'apps/idl/src/model/EPathologies';
import { IActeDocumentByLc } from 'apps/idl/src/model/IActeDocumentByLc';
import { IDeplacementByProfession } from 'apps/idl/src/model/IDeplacementByProfession';
import { ISeance } from 'apps/idl/src/model/ISeance';
import { ITarifLettreCle } from 'apps/idl/src/model/ITarifLettreCle';
import { Majoration } from 'apps/idl/src/model/Majoration';
import { Traitement } from 'apps/idl/src/model/Traitement';
import { ActesService } from 'apps/idl/src/modules/actes/actes.service';
import { ECotationAction } from 'apps/idl/src/modules/actes/model/ECotationAction';
import { ECotationConditionPattern } from 'apps/idl/src/modules/actes/model/ECotationConditionPattern';
import { ICotationRule } from 'apps/idl/src/modules/actes/model/ICotationRule';
import { ICotationRuleDocument } from 'apps/idl/src/modules/actes/model/ICotationRuleDocument';
import { ICotationRuleLimit } from 'apps/idl/src/modules/actes/model/ICotationRuleLimit';
import { ActeOccurrenceComparator } from 'apps/idl/src/modules/actes/model/acte-occurrence-comparator';
import { ActeOccurrenceDateTimeComparator } from 'apps/idl/src/modules/actes/model/acte-occurrence-date-time-comparator';
import { ActeOccurrenceIntervenantsComparator } from 'apps/idl/src/modules/actes/model/acte-occurrence-intervenants-comparator';
import { ActeOccurrenceRangeComparator } from 'apps/idl/src/modules/actes/model/acte-occurrence-range-comparator';
import { Constraint } from 'apps/idl/src/modules/actes/model/constraint';
import { IActeOccurrence } from 'apps/idl/src/modules/actes/model/iacte-occurrence';
import { IntervenantConstraint } from 'apps/idl/src/modules/actes/model/intervenant-constraint';
import { EIdlPrestationLineCategory } from 'apps/idl/src/modules/facturation/models/eidl-prestation-line-category.enum';
import { IdlPrestation } from 'apps/idl/src/modules/facturation/models/idl-prestation';
import { IdlPrestationLine } from 'apps/idl/src/modules/facturation/models/idl-prestation-line';
import { IIdlPrestationIdBuilderParams } from 'apps/idl/src/modules/facturation/models/iidl-prestation-id-builder-params';
import { IIdlPrestationLine } from 'apps/idl/src/modules/facturation/models/iidl-prestation-line';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { IPlanningRH } from 'apps/idl/src/modules/planning-rh/model/IPlanningRH';
import { IDaySectorAffectationsMap } from 'apps/idl/src/modules/planning-rh/model/iday-sector-affectations-map ';
import { PlanningRHService } from 'apps/idl/src/modules/planning-rh/services/planning-rh.service';
import { EChoiceFilter } from 'apps/idl/src/modules/seances/choose-seances-to-modify-modal/EChoiceFilter';
import { SeancesHelper } from 'apps/idl/src/modules/seances/helpers/seances.helper';
import { IChooseSeancesToModifiyResponse } from 'apps/idl/src/modules/seances/model/ichoose-seances-to-modifiy-response';
import { EIndemniteType } from 'apps/idl/src/modules/traitement/model/EIndemniteType';
import { Indemnite } from 'apps/idl/src/modules/traitement/model/Indemnite';
import { ExtraCharge } from 'apps/idl/src/modules/traitement/model/extra-charge';
import { IdlApplicationService } from 'apps/idl/src/services/idlApplicationService.service';
import { IndemniteService } from 'apps/idl/src/services/indemnite.service';
import { SeanceService } from 'apps/idl/src/services/seance.service';
import { findLast } from 'lodash';
import { Observable, defer, from, of, throwError } from 'rxjs';
import { catchError, map, mapTo, mergeMap, tap } from 'rxjs/operators';
import { EStatusSeance } from '../../../../model/EStatusSeance';
import { C_PREFIX_PATIENT, C_PREFIX_SEANCE, C_PREFIX_TRAITEMENT } from '../../../anakin.constants';
import { StoredSeance as Seance, StoredSeance } from '../../../models/StoredSeance';
import { EEtatActe } from '../enums/EEtatActe';
import { SeanceService as SeanceServiceAnakin } from './seance.service';



// interface ITraitementWithDatas {
// 	traitement: Traitement;
// 	prestations: Prestation[];
// 	plannings: IPlanningRH[];
// 	patient: IPatient;
// 	sectorId: string;
// 	cotationRules: ICotationRuleDocument[];
// 	majorations: IActeDocumentByLc[];
// 	indemnites: IDeplacementByProfession[];
// 	interventionStatements: InterventionStatement[];
// 	affectationsBySectorAndDays: IDaySectorAffectationsMap;
// }

// type IGetTraitementDatasResult = [
// 	Prestation[],
// 	IPlanningRH[],
// 	IPatient[],
// 	Map<string, string>,
// 	ICotationRuleDocument[],
// 	IDeplacementByProfession[],
// 	IActeDocumentByLc[],
// 	Map<string, InterventionStatement[]>
// ];

class SeanceKey {

	id: string;
	traitementId: string;
	patientId: string;

	// constructor(idSeance){
	// 	if(!StringHelper.isBlank(idSeance)){
	// 		this._id = idSeance;
	// 	}
	// }

	constructor(traitementId: string, patientId: string) {
		this.id = GuidHelper.newGuid(),
			this.traitementId = traitementId,
			this.patientId = patientId
	}

	public toString(): string {
		return `${C_PREFIX_SEANCE}${this.id}~${this.traitementId}~${this.patientId}`;
	}
}

@Injectable({ providedIn: "root" })
export class SeancesGeneratorService extends DestroyableServiceBase implements ILogSource {

	//#region FIELDS

	/** Heure de la 1e séance d'une tournée' */
	// private static readonly C_DAY_TOUR_START_HOURS = 6;
	// /** Minute de la 1e séance d'une tournée */
	// private static readonly C_DAY_TOUR_START_MINUTES = 0;
	// /** Durée par défaut d'une séance */
	// private static readonly C_DELAY_BETWEEN_SEANCES_MINUTES = 5;
	private isvcApplication: IdlApplicationService;

	// @Roles(C_ADMINISTRATORS_ROLE_ID)
	// private get isAdmin(): boolean {
	// 	return true;
	// }

	//#endregion

	//#region PROPERTIES

	/** @implements */
	public readonly logSourceId = "IDL.SEANCE.S::";
	/** @implements */
	public readonly logActionHandler = new LogActionHandler(this);

	public static readonly emptyAvatar: IAvatar = {
		size: EAvatarSize.big,
		icon: "help",
		color: "danger",
		useIconAsDefaultFallback: true,
		text: "Non affecté"
	};

	//#endregion

	//#region METHODS

	constructor(
		private isvcStore: Store,
		private isvcPlanningRH: PlanningRHService,
		private isvcPatients: PatientsService,
		private isvcIndemnite: IndemniteService,
		private isvcActes: ActesService,
		private svcPrestation: PrestationService,
		private svcSeance: SeanceServiceAnakin,
		private svcObservation: ObservationsService,
		/** @implements */
		public readonly isvcLogger: LoggerService,
		psvcApplication: ApplicationService) {
		super();
		this.isvcApplication = psvcApplication as IdlApplicationService;
	}

	public saveSeances(traitement: Traitement, patient: IPatient): Observable<Boolean> {
		const databaseId = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace));

		return this.generateSeances(traitement, patient, true, true).pipe(
			mergeMap((seances: ISeance[]) => {
				return from(seances).pipe(
					mergeMap((seance: ISeance) => {
						const storedSeance: StoredSeance = { ...seance } as StoredSeance;

						const key: SeanceKey = new SeanceKey(seance.traitementId, seance.patientId)

						storedSeance._id = key.toString()
						return this.isvcStore.put(storedSeance, databaseId);
					})
				);
			}),
			map(() => true),
			catchError(() => of(false))
		);
	}

	//#region Séances

	/** Parcourt les actes ajoutés au traitement et détermine les dates des actes pour les séances. */
	private createTraitementActeOccurrences(
		poTraitement: Traitement,
		poAffectationsBySectorAndDays?: IDaySectorAffectationsMap,
		psSectorId?: string,
		poRange?: IRange<Date>
	): Observable<IActeOccurrence[]> {
		const loTraitementActesOccurrencesByDay: Map<number, Map<Acte, IActeOccurrence[]>> = this.generateActesOccurrences(poTraitement, psSectorId);
		let lbAllIntervenantsLocked = true;

		const loPerformanceManager = new PerformanceManager;
		loPerformanceManager.markStart();

		loTraitementActesOccurrencesByDay.forEach((poActeOccurrencesByActe: Map<Acte, IActeOccurrence[]>, pnDayIndex: number) => {
			const laDayActeOccurrences: IActeOccurrence[] = ArrayHelper.flat(MapHelper.valuesToArray(poActeOccurrencesByActe));
			if (ArrayHelper.hasElements(laDayActeOccurrences)) {
				let lbHasToReexecute = false;
				do {
					lbHasToReexecute = this.applyRangeRecurrence(laDayActeOccurrences); // On applique les récurrences de range avant l'application du planning pour que les itérations soient sur le bon horaire.

					laDayActeOccurrences.forEach((poActeOccurrence: IActeOccurrence, pnActeIndex: number, paDayActeOccurrences: IActeOccurrence[]) => {
						const lbHasExecutedConstraint: boolean = this.execActeConstraints(poActeOccurrence);

						if (lbHasExecutedConstraint) { // Si une contrainte a été exécutée
							const lnNewDayIndex: number = DateHelper.diffDays(poActeOccurrence.date, poTraitement.beginDate);
							if (lnNewDayIndex !== pnDayIndex) { // Si l'occurence a changée de jour, alors on la déplace de groupe dans la Map pour rester cohérent
								ArrayHelper.removeElementByIndex(paDayActeOccurrences, pnActeIndex);
								this.moveActeOccurrenceToNewDay(loTraitementActesOccurrencesByDay, lnNewDayIndex, poActeOccurrence, poActeOccurrencesByActe);
							}
						}

						lbHasToReexecute = lbHasExecutedConstraint || lbHasToReexecute;
						if (lbAllIntervenantsLocked)
							lbAllIntervenantsLocked = poActeOccurrence.areIntervenantsLocked;
					});

				} while (lbHasToReexecute); // Si il y a une modification au niveau de l'application des contraintes ou des plage de date on recommence.
			}
		});

		console.debug(`${this.logSourceId}Application des contraintes et plages horaires en ${loPerformanceManager.markEnd().measure()}ms.`);

		const laActeOccurrences: IActeOccurrence[] = [];
		let lnMinIndex: number, lnMaxIndex: number;

		loTraitementActesOccurrencesByDay.forEach((poActeOccurrencesByActe: Map<Acte, IActeOccurrence[]>, pnDayIndex: number) => {
			if (poActeOccurrencesByActe.size === 0) // Si pas d'éléments dans la journée on skip
				return;

			if (lnMaxIndex === undefined || pnDayIndex > lnMaxIndex)
				lnMaxIndex = pnDayIndex;
			if (lnMinIndex === undefined || pnDayIndex < lnMinIndex)
				lnMinIndex = pnDayIndex;

			poActeOccurrencesByActe.forEach((paActeOccurrences: IActeOccurrence[], poActe: Acte) => {
				if (ArrayHelper.hasElements(paActeOccurrences)) {
					// Si l'acte à une date de fin antérieure à la date d'interruption du traitement, on utilise la date de fin de l'acte, sinon on utilise celle du traitement.
					let ldEndDate: Date = poActe.endDate && (!poTraitement.breakDate || DateHelper.compareTwoDates(poActe.endDate, poTraitement.breakDate) < 0) ? poActe.endDate : poTraitement.breakDate;
					if (!ldEndDate || (poRange?.to && DateHelper.compareTwoDates(poRange?.to, ldEndDate) < 0))
						ldEndDate = poRange?.to;

					let ldStartDate: Date = poActe.startDate && (!poTraitement.beginDate || DateHelper.compareTwoDates(poActe.startDate, poTraitement.beginDate) < 0) ? poActe.startDate : poTraitement.beginDate;
					if (!ldStartDate || (poRange?.from && DateHelper.compareTwoDates(poRange?.from, ldStartDate) > 0))
						ldStartDate = poRange?.from;

					const lnRangeEndIndex: number = ldEndDate ? DateHelper.diffDays(ldEndDate, poTraitement.beginDate) : undefined;
					const lnRangeStartIndex: number = ldStartDate ? DateHelper.diffDays(ldStartDate, poTraitement.beginDate) : undefined;

					if (NumberHelper.isInRange(pnDayIndex, { from: lnRangeStartIndex, to: lnRangeEndIndex }))
						laActeOccurrences.push(...paActeOccurrences);
				}
			});
		});

		poTraitement.beginDate = DateHelper.addDays(poTraitement.beginDate, lnMinIndex ?? 0);
		poTraitement.endDate = DateHelper.addDays(poTraitement.beginDate, lnMaxIndex ?? 0);

		if (lbAllIntervenantsLocked)
			return of(laActeOccurrences);

		// Si un secteur est passé, même vide, on laisse passer.
		return defer(() => !ObjectHelper.isDefined(psSectorId) ? this.isvcPatients.getPatientSectorId(poTraitement.patientId) : of(psSectorId)).pipe(
			mergeMap((psResultSectorId: string) => {
				const loResult: IMinMaxDates = DateHelper.getMinAndMaxDates(laActeOccurrences.map((poActeOccurrence: IActeOccurrence) => poActeOccurrence.date));
				return defer(() => ObjectHelper.isDefined(poAffectationsBySectorAndDays) ?
					of(poAffectationsBySectorAndDays) :
					this.isvcPlanningRH.getPlannings(loResult.min, loResult.max).pipe(map((paPlannings: IPlanningRH[]) => PlanningRHService.groupPlanningsBySectorAndDays(paPlannings)))
				).pipe(
					map((poResult: IDaySectorAffectationsMap) => {
						laActeOccurrences.forEach((poActeOccurrence: IActeOccurrence) => {
							if (poResult.size > 0) {
								if (poActeOccurrence.acte.usePlanning && !poActeOccurrence.areIntervenantsLocked) {
									const laIntervenantIds: string[] =
										PlanningRHService.getIntervenantByAffectationsGroupedBySectorAndDays(poResult, poActeOccurrence.date, psResultSectorId);

									if (ArrayHelper.hasElements(laIntervenantIds)) {
										poActeOccurrence.intervenantIds = laIntervenantIds;
										poActeOccurrence.areIntervenantsLocked = true;
									}
								}
							}

							if (!poActeOccurrence.areIntervenantsLocked && !StringHelper.isBlank(psResultSectorId))
								poActeOccurrence.intervenantIds = [psResultSectorId];
						});

						return laActeOccurrences;
					})
				);
			})
		);
	}

	private moveActeOccurrenceToNewDay(
		poTraitementActesOccurrencesByDay: Map<number, Map<Acte, IActeOccurrence[]>>,
		pnNewDayIndex: number,
		poActeOccurrence: IActeOccurrence,
		poActeOccurrencesByActe: Map<Acte, IActeOccurrence[]>
	): void {
		const loNewDayActeOccurrencesByActe: Map<Acte, IActeOccurrence[]> = poTraitementActesOccurrencesByDay.get(pnNewDayIndex) ?? new Map;
		const laNewDayActeOccurrences: IActeOccurrence[] = loNewDayActeOccurrencesByActe.get(poActeOccurrence.acte) ?? [];

		const laActeOccurrences: IActeOccurrence[] = poActeOccurrencesByActe.get(poActeOccurrence.acte);
		laNewDayActeOccurrences.push(ArrayHelper.removeElement(laActeOccurrences, poActeOccurrence));

		if (!ArrayHelper.hasElements(laActeOccurrences)) // Si il n'y a plus d'occurrences pour l'acte dans la journée, on peut supprimer l'entré pour l'acte dans la map du jour
			poActeOccurrencesByActe.delete(poActeOccurrence.acte);

		loNewDayActeOccurrencesByActe.set(poActeOccurrence.acte, laNewDayActeOccurrences);
		poTraitementActesOccurrencesByDay.set(pnNewDayIndex, loNewDayActeOccurrencesByActe);
	}

	/** Crée les différentes séances dans le traitement.
	 * @param paActeOccurrences Tableau de séances temporaires.
	 * @param poTraitement
	 */
	private createSeances(paActeOccurrences: Array<IActeOccurrence>, poTraitement: Traitement): Seance[] {
		const laCreatedSeances: Seance[] = []; // Tableau des séances crées.

		ArrayHelper.dynamicSortMultiple(paActeOccurrences, ["date", "iterationIndex", "intervenantIds"]); // Tri du tableau des séances générées.

		const loGroupedActeOccurrences: Map<string, IActeOccurrence[]> = ArrayHelper.groupBy(paActeOccurrences,
			(poActeOccurrence: IActeOccurrence) => (poActeOccurrence.intervenantIds?.join() ?? "") + poActeOccurrence.date.getTime()
		);

		// On effectue un tri pour faire passer les itérations d'actes avec une séance en premières (pour la génération de séances).
		loGroupedActeOccurrences.forEach((paGroupedActesIterations: IActeOccurrence[]) => {
			ArrayHelper.dynamicSort(paGroupedActesIterations, "canceled");

			paGroupedActesIterations.forEach((poActeOccurrence: IActeOccurrence, pnIndex: number) => {
				const loSeance: Seance = this.innerCreateSeances(poTraitement, laCreatedSeances, poActeOccurrence, paGroupedActesIterations[pnIndex - 1]);
				const loActe = new Acte(poActeOccurrence.acte);
				loActe.etat = EEtatActe.to_be_done
				loActe.canceled = poActeOccurrence.canceled;
				loSeance.place = poActeOccurrence.place;
				loSeance.actes.push(loActe);
			});
		});

		return laCreatedSeances;
	}

	private applyRangeRecurrence(paDayActeOccurrences: IActeOccurrence[]): boolean {
		let lbDateModified = false;

		const laActesIterationsThatMatch: IActeOccurrence[][] = [];
		paDayActeOccurrences.forEach((poGroupedActeOccurrence: IActeOccurrence) => {
			const laLocalActesIterationsThatMatch: IActeOccurrence[] = [poGroupedActeOccurrence];
			laActesIterationsThatMatch.push([poGroupedActeOccurrence]); // On ajoute au minimum l'acte seul car il match au moins avec lui même.

			paDayActeOccurrences.forEach((poActeOccurrence: IActeOccurrence) => {
				if (
					poGroupedActeOccurrence !== poActeOccurrence &&
					poGroupedActeOccurrence.guid !== poActeOccurrence.guid &&
					laLocalActesIterationsThatMatch.every((poActeOccurrenceThatMatch: IActeOccurrence) =>
						this.getMatchingDate([poActeOccurrenceThatMatch, poActeOccurrence])
					)
				) {
					laLocalActesIterationsThatMatch.push(poActeOccurrence);
					laActesIterationsThatMatch.push([...laLocalActesIterationsThatMatch]);
				}
			});
		});

		ArrayHelper.dynamicSort(laActesIterationsThatMatch, "length", ESortOrder.descending);

		let ldMatchingDate: Date;
		const laAlreadyParsedActeOccurrences: IActeOccurrence[] = [];
		for (let lnIndex = 0; lnIndex < laActesIterationsThatMatch.length; ++lnIndex) {
			const laMatchingActesOccurrence: IActeOccurrence[] = laActesIterationsThatMatch[lnIndex];

			if (!ArrayHelper.hasElements(ArrayHelper.intersection(laAlreadyParsedActeOccurrences, laMatchingActesOccurrence))) {
				ldMatchingDate = this.getMatchingDate(laMatchingActesOccurrence);
				if (ldMatchingDate) {
					laMatchingActesOccurrence.forEach((poMatchingActeOccurrence: IActeOccurrence) => {
						if (DateHelper.diffMinutes(poMatchingActeOccurrence.date, ldMatchingDate) !== 0) {
							poMatchingActeOccurrence.date = ldMatchingDate;
							lbDateModified = true;
						}
					});

					laAlreadyParsedActeOccurrences.push(...laMatchingActesOccurrence);
				}
			}
		}

		return lbDateModified;
	}

	private getMatchingDate(paMatchingActeOccurrences: IActeOccurrence[]): Date {
		let ldMinDate: Date;
		let ldMaxDate: Date;
		ArrayHelper.dynamicSort(paMatchingActeOccurrences, "isDateLocked", ESortOrder.descending);
		for (let lnIndex = 0; lnIndex < paMatchingActeOccurrences.length; ++lnIndex) {
			const loActeOccurrence: IActeOccurrence = paMatchingActeOccurrences[lnIndex];
			let ldActeMinDate: Date;
			let ldActeMaxDate: Date;

			if (!loActeOccurrence.isDateLocked) {
				const loRepetition: DayRepetition = loActeOccurrence.recurrence.dayRepetitions[loActeOccurrence.iterationIndex];

				if (loRepetition) {
					ldActeMinDate = new Date(loActeOccurrence.date);
					ldActeMaxDate = new Date(loActeOccurrence.date);
					if (loRepetition.type === C_RANGE_REPETITION_TYPE) { // TODO Remplacer par instanceof lorsque le bug du ModelResolver dans jest sera corrigé.
						const loRangeRepetition: RangeRepetition = loRepetition as RangeRepetition;
						if (loRangeRepetition.from)
							ldActeMinDate.setHours(loRangeRepetition.from?.hours, loRangeRepetition.from?.minutes);
						if (loRangeRepetition.to) {
							ldActeMaxDate.setHours(loRangeRepetition.to?.hours, loRangeRepetition.to?.minutes);
							if (DateHelper.compareTwoDates(ldActeMaxDate, ldActeMinDate) < 0) // Cas d'une plage d'horaire à cheval sur 2 jours
								ldActeMaxDate = DateHelper.addDays(ldActeMaxDate, 1);
							ldActeMaxDate = DateHelper.addMinutes(ldActeMaxDate, -1);
						}
					}
				}
			}
			else {
				ldActeMinDate = new Date(loActeOccurrence.date);
				ldActeMaxDate = new Date(loActeOccurrence.date);
			}

			if (!ldActeMinDate)
				ldActeMinDate = new Date(loActeOccurrence.date);

			if (!ldMinDate || DateHelper.compareTwoDates(ldActeMinDate, ldMinDate) > 0)
				ldMinDate = ldActeMinDate;
			if (ldActeMaxDate && (!ldMaxDate || DateHelper.compareTwoDates(ldActeMaxDate, ldMaxDate) < 0))
				ldMaxDate = ldActeMaxDate;

			// Si la date min est supérieure ou égale à la date max, on retourne `undefined`.
			if (ldMaxDate && DateHelper.compareTwoDates(ldMaxDate, ldMinDate) < 0)
				return undefined;
		}

		return ldMinDate;
	}

	/** Crée les différentes séances dans le traitement.
	 * @param poTraitement
	 * @param paCreatedSeances Tableau des séances créées.
	 * @param poCurrentActeOccurrence Acte actuel à partir duquel créer une nouvelle séance.
	 * @param poBeforeLastActeOccurrence Avant dernier acte avec lequel créer la nouvelle (fusion de séance si même créneau horaire par exemple).
	 */
	private innerCreateSeances(poTraitement: Traitement, paCreatedSeances: Seance[], poCurrentActeOccurrence: IActeOccurrence, poBeforeLastActeOccurrence?: IActeOccurrence): Seance {
		let loSeance: Seance = ArrayHelper.getLastElement(paCreatedSeances);

		// // Si dates ou itérations différentes -> création d'une nouvelle séance.
		const moment: DayRepetition = poCurrentActeOccurrence.moment;
		// const dateSeance = Seance.getStartDateFromMoment("", poCurrentActeOccurrence.date);
		if (!loSeance || !poBeforeLastActeOccurrence || !this.isSeanceMatchingActeOccurrence(loSeance, poCurrentActeOccurrence)) {
			loSeance = new Seance(
				[],
				paCreatedSeances.length,
				poCurrentActeOccurrence.date,
				moment
			);

			loSeance.traitementId = poTraitement._id;
			loSeance.traitementRev = poTraitement._rev;
			loSeance.scheduled = true;
			loSeance.status = EStatusSeance.to_be_done;
			loSeance.intervenantIds = poCurrentActeOccurrence.intervenantIds;
			loSeance.infirmierId = poCurrentActeOccurrence.intervenantIds.length === 0
				? ''
				: ArrayHelper.getFirstElement(
					loSeance.intervenantIds.filter(item => item.startsWith(EPrefix.contact))
				) || '';
			paCreatedSeances.push(loSeance);
		}

		return loSeance;
	}

	private isSeanceMatchingActeOccurrence(poSeance: Seance, poActeOccurrence: IActeOccurrence): boolean {
		return DateHelper.compareTwoDates(poSeance.startDate, poActeOccurrence.date) === 0 &&
			poSeance.actes.every((poActe: Acte) => poActe.guid !== poActeOccurrence.guid);
	}

	/** Calcul des dates pour les séances et génère toutes les séances pour un traitement, groupé par jour.
	 * @param poTraitement
	 */
	private generateActesOccurrences(poTraitement: Traitement, psSectorId?: string): Map<number, Map<Acte, IActeOccurrence[]>> {
		const loPerformanceManager = new PerformanceManager;
		loPerformanceManager.markStart();

		// On crée la liste des dates groupées par jour pour lesquels on va vouloir effectuer l'acte.
		const loOccurrencesByDay = new Map<number, Map<Acte, IActeOccurrence[]>>();

		poTraitement.actes.forEach((poActe: Acte) => {
			this.createActeOccurrences(
				poActe,
				poTraitement,
				(pnDayIndex: number, paActeOccurrences: IActeOccurrence[]) => {
					const loOccurrencesByActe: Map<Acte, IActeOccurrence[]> = loOccurrencesByDay.get(pnDayIndex) ?? new Map();

					loOccurrencesByActe.set(poActe, paActeOccurrences);

					loOccurrencesByDay.set(pnDayIndex, loOccurrencesByActe);
				},
				psSectorId
			);
		});

		console.debug(`${this.logSourceId}Génération des occurrences d'actes du traitement ${poTraitement._id} en ${loPerformanceManager.markEnd().measure()}ms.`);

		return loOccurrencesByDay;
	}

	private execActeConstraints(poActeOccurrence: IActeOccurrence): boolean {
		let lbHasExecutedConstraint = false;
		for (let lnIndex = 0; lnIndex < poActeOccurrence.acte.constraints.length; ++lnIndex) {
			const loConstraint: Constraint = poActeOccurrence.acte.constraints[lnIndex];

			if (!poActeOccurrence.executedConstraints.includes(loConstraint) && loConstraint.match(poActeOccurrence)) {
				const ldOldDate: Date = poActeOccurrence.date;
				const laOldIntervenants: string[] = [...(poActeOccurrence.intervenantIds ?? [])];
				loConstraint.action(poActeOccurrence);

				if (!poActeOccurrence.areIntervenantsLocked)
					poActeOccurrence.areIntervenantsLocked = !ArrayHelper.areArraysEqual(laOldIntervenants, poActeOccurrence.intervenantIds);

				if (!poActeOccurrence.isDateLocked)
					poActeOccurrence.isDateLocked = DateHelper.diffMinutes(ldOldDate, poActeOccurrence.date) !== 0;

				poActeOccurrence.executedConstraints.push(loConstraint);
				lbHasExecutedConstraint = true;
				lnIndex = 0;
			}
		}

		return lbHasExecutedConstraint;
	}

	/** Crée les dates de réalisation d'un acte en fonction de son paramètrage.
	 * @param poActe
	 * @returns Les dates groupées par jour.
	 */
	private createActeOccurrences(
		poActe: Acte,
		poTraitement: Traitement,
		pfOnDateFullyGenerated: (pnDayIndex: number, paActeOccurrences: IActeOccurrence[]) => void,
		psSectorId?: string
	): void {
		const laRecurrences: Recurrence[] = [...(poActe.recurrences ?? [])];
		if (poActe.acteModalEditBinding) {
			const loRecurrence: Recurrence = ArrayHelper.getFirstElement(poActe.recurrences) ?? new Recurrence;
			loRecurrence.durationValue = poActe.acteModalEditBinding.durationValue;
			loRecurrence.durationType = poActe.acteModalEditBinding.durationType;
			loRecurrence.repetitionType = poActe.acteModalEditBinding.repetitionType;
			loRecurrence.repetitionValue = poActe.acteModalEditBinding.repetitionValue;
			ArrayHelper.pushIfNotPresent(laRecurrences, loRecurrence);
		}
		const loDates = new Map<number, IActeOccurrence[]>();
		let lnPreviousDay: number;

		let recurrenceIndex = 0;
		let listRecurrencesDayRepetitions = [...poActe.recurrences[0].dayRepetitions];

		laRecurrences.forEach((poRecurrence: Recurrence) =>
			poRecurrence.generateDates(
				poActe.startDate ?? poTraitement.beginDate,
				poActe.weekRepetition?.value,
				(pdDate: Date, pnIndex: number, pbLocked?: boolean) => {
					if (pdDate && (poActe.startOffset ?? 0) <= pnIndex) {
						const lnDiffDays: number = DateHelper.diffDays(pdDate, poTraitement.beginDate);
						const lbNewDay: boolean = lnPreviousDay !== lnDiffDays;
						if (lbNewDay && lnPreviousDay !== undefined)
							recurrenceIndex = 0;

						const laGroupedDates: IActeOccurrence[] = lbNewDay ? [] : loDates.get(lnDiffDays);

						const currentDayRepetition: DayRepetition = listRecurrencesDayRepetitions[recurrenceIndex]

						laGroupedDates.push({
							date: pdDate,
							iterationIndex: laGroupedDates.length,
							acte: poActe,
							intervenantIds: [],
							guid: poActe.guid,
							place: poActe.place,
							canceled: poActe.canceled,
							recurrence: poRecurrence,
							executedConstraints: [],
							isDateLocked: pbLocked,
							moment: currentDayRepetition
						});
						if (listRecurrencesDayRepetitions.length != 1) {
							recurrenceIndex++;
						}


						if (lbNewDay) {
							pfOnDateFullyGenerated(lnDiffDays, laGroupedDates);
							loDates.set(lnDiffDays, laGroupedDates);
							lnPreviousDay = lnDiffDays;
						}
					}
				}
			)
		);

		this.applyEndOffset(poActe, loDates);
	}

	private applyEndOffset(poActe: Acte, loActeOccurrenceByDay: Map<number, IActeOccurrence[]>): void {
		if (poActe.endOffset > 0) {
			const laKeys: number[] = MapHelper.keysToArray(loActeOccurrenceByDay);
			let lnEndOffset: number = poActe.endOffset;

			for (let lnIndex = laKeys.length - 1; lnIndex > 0; lnIndex--) {
				const lnKey: number = laKeys[lnIndex];
				const laActeOccurrence: IActeOccurrence[] = loActeOccurrenceByDay.get(lnKey);

				if (lnEndOffset > laActeOccurrence.length)
					lnEndOffset = lnEndOffset - ArrayHelper.clear(laActeOccurrence).length;
				else {
					laActeOccurrence.splice(laActeOccurrence.length - lnEndOffset);
					break;
				}
			}
		}
	}

	// private generateTraitementsSeances(paTraitements: Traitement[], pbLive?: boolean, pdMin?: Date, pdMax?: Date): Observable<Seance[]> {
	// 	if (!ArrayHelper.hasElements(paTraitements))
	// 		return of([]);

	// 	const laTraitementsDates: Date[] = [];
	// 	const laTraitementsPatientsIds: string[] = [];
	// 	const loTraitementsWithDataSubjectByTraitementId = new Map<string, Subject<ITraitementWithDatas>>();
	// 	const laTraitementIds: string[] = [];
	// 	const loUnsubscribeSubject = new Subject();

	// 	paTraitements.forEach((poTraitement: Traitement) => {
	// 		laTraitementsDates.push(poTraitement.beginDate, poTraitement.endDate);
	// 		laTraitementsPatientsIds.push(poTraitement.patientId);
	// 		loTraitementsWithDataSubjectByTraitementId.set(poTraitement._id, new Subject<ITraitementWithDatas>());
	// 		laTraitementIds.push(poTraitement._id);
	// 	});

	// 	const loMinAndMaxDates: IMinMaxDates = DateHelper.getMinAndMaxDates(laTraitementsDates);

	// 	return combineLatest(MapHelper.valuesToArray(loTraitementsWithDataSubjectByTraitementId)
	// 		.map((poTraitementWithDataSubject: Subject<ITraitementWithDatas>) =>
	// 			poTraitementWithDataSubject.asObservable()
	// 			.pipe(
	// 				distinctUntilChanged((poOld: ITraitementWithDatas, poNew: ITraitementWithDatas) => StoreDocumentHelper.areDocumentsEquals(poOld.traitement, poNew.traitement) &&
	// 					ArrayHelper.areArraysFromDatabaseEqual(poOld.prestations, poNew.prestations) &&
	// 					ArrayHelper.areArraysFromDatabaseEqual(poOld.plannings, poNew.plannings) &&
	// 					ArrayHelper.areArraysFromDatabaseEqual(poOld.interventionStatements, poNew.interventionStatements)
	// 				),
	// 				concatMap((poTraitementWithData: ITraitementWithDatas) => {
	// 					return this.generateSeances(
	// 						poTraitementWithData.traitement,
	// 						poTraitementWithData.patient,
	// 						undefined,
	// 						undefined,
	// 						poTraitementWithData.affectationsBySectorAndDays,
	// 						poTraitementWithData.sectorId,
	// 						poTraitementWithData.prestations,
	// 						poTraitementWithData.interventionStatements,
	// 						poTraitementWithData.cotationRules,
	// 						poTraitementWithData.majorations,
	// 						poTraitementWithData.indemnites,
	// 						{ from: pdMin, to: pdMax }
	// 					)
	// 						.pipe(catchError((poError: any) => {
	// 							console.warn(`${this.logSourceId}Erreur lors de la génération des séances du traitement ${poTraitementWithData.traitement._id}. Les séances de ce traitement seront masquées.`, poError);
	// 							return of([]);
	// 						}));
	// 				})
	// 			))).pipe(
	// 				afterSubscribe(() => {
	// 					combineLatest([ // On récupère toutes les données des traitements.
	// 						this.innerGenerateTraitementsSeances_getPrestations(paTraitements, pdMin, pdMax, pbLive),
	// 						this.isvcPlanningRH.getPlannings(loMinAndMaxDates.min, loMinAndMaxDates.max, pbLive),
	// 						this.isvcPatients.getPatientsByIds(laTraitementsPatientsIds, pbLive),
	// 						this.isvcPatients.getPatientsSectorIds(laTraitementsPatientsIds, pbLive),
	// 						this.isvcActes.getCotationRules(ArrayHelper.unique(ArrayHelper.flat(ArrayHelper.flat(paTraitements.map((poTraitement: Traitement) =>
	// 							poTraitement.actes.map((poActe: Acte) => poActe.cotationRulesIds ?? [])))))),
	// 						this.isvcIndemnite.getDeplacementsByProfession(this.isvcApplication.profession),
	// 						this.getMajorations(),
	// 						this.isvcInterventionStatement.getTraitementsLastInterventionStatements(laTraitementIds, { from: pdMin, to: pdMax }, pbLive)
	// 					]).pipe(tap((paResults: IGetTraitementDatasResult) => {
	// 						const loAffectationsBySectorAndDays: IDaySectorAffectationsMap = PlanningRHService.groupPlanningsBySectorAndDays(paResults[1]);
	// 						paTraitements.forEach((poTraitement: Traitement) =>
	// 							loTraitementsWithDataSubjectByTraitementId.get(poTraitement._id).next(this.innerGenerateTraitementsSeances_buildTraitementDatas(poTraitement, paResults, loAffectationsBySectorAndDays))
	// 						);
	// 					})).pipe(takeUntil(loUnsubscribeSubject.asObservable())).subscribe();
	// 				}),
	// 				map((paSeancesArrays: Seance[][]) => SeanceService.sortSeancesByDate(ArrayHelper.flat(paSeancesArrays))),
	// 				finalize(() => {
	// 					loUnsubscribeSubject.next();
	// 					loUnsubscribeSubject.complete();
	// 				})
	// 			);
	// }

	// private innerGenerateTraitementsSeances_getPrestations(paTraitements: Traitement[], pdMin: Date, pdMax: Date, pbLive: boolean): Observable<Prestation[]> {
	// 	return this.isvcPrestation.getPrestations(
	// 		{
	// 			customerIdOrPrefix: C_PREFIX_PATIENT,
	// 			traitementIds: paTraitements.map((poTraitement: Traitement) => poTraitement._id)
	// 		} as IIdlPrestationIdBuilderParams,
	// 		pdMin, pdMax, pbLive
	// 	);
	// }

	// private innerGenerateTraitementsSeances_buildTraitementDatas(
	// 	poTraitement: Traitement,
	// 	paResults: IGetTraitementDatasResult,
	// 	poAffectationsBySectorAndDays: IDaySectorAffectationsMap
	// ): ITraitementWithDatas {
	// 	return {
	// 		traitement: poTraitement,
	// 		prestations: this.filterPrestationsByTraitement(paResults[0], poTraitement),
	// 		plannings: this.filterPlannings(paResults[1], poTraitement),
	// 		patient: paResults[2].find((poPatient: IPatient) => poPatient._id === poTraitement.patientId),
	// 		sectorId: paResults[3].get(poTraitement.patientId) ?? "",
	// 		cotationRules: paResults[4],
	// 		indemnites: paResults[5],
	// 		majorations: paResults[6],
	// 		interventionStatements: paResults[7].get(poTraitement._id) ?? [],
	// 		affectationsBySectorAndDays: poAffectationsBySectorAndDays
	// 	};
	// }

	// private filterPlannings(paPlannings: IPlanningRH[], poTraitement: Traitement): IPlanningRH[] {
	// 	if (!ArrayHelper.hasElements(paPlannings))
	// 		return [];

	// 	const lsPlanningStartId: string = this.isvcPlanningRH.getPlanningIdFromDate(poTraitement.beginDate);
	// 	const lsPlanningEndId: string = this.isvcPlanningRH.getPlanningIdFromDate(poTraitement.endDate);

	// 	return paPlannings.filter((poPlanning: IPlanningRH) => poPlanning._id >= lsPlanningStartId && poPlanning._id <= lsPlanningEndId);
	// }

	/** Génère les séances pour le traitement en cours (et efface les précédentes).
	 * @param poTraitement
	 * @param poPatient Patient lié au traitement.
	 * @param pbShouldAutoCreateMajoration
	 * @param pbIsAutoRulesEnable
	 * @param psSectorId
	 * @param paPrestations
	 * @param paCotationRules
	 * @param paMajorations
	 * @param paIndemnites
	 */
	public generateSeances(
		poTraitement: Traitement,
		poPatient: IPatient,
		pbShouldAutoCreateMajoration: boolean = true,
		pbIsAutoRulesEnable: boolean = true,
		poAffectationsBySectorAndDays?: IDaySectorAffectationsMap,
		psSectorId?: string,
		paCotationRules?: ICotationRuleDocument[],
		paMajorations?: IActeDocumentByLc[],
		paIndemnites?: IDeplacementByProfession[],
		poRange?: IRange<Date>
	): Observable<Seance[]> {
		if (ObjectHelper.isNullOrEmpty(poPatient))
			return of([]);

		const loPerformanceManager = new PerformanceManager;

		return defer(() => {
			loPerformanceManager.markStart();
			return this.createTraitementActeOccurrences(poTraitement, poAffectationsBySectorAndDays, psSectorId, poRange);
		})
			.pipe(
				mergeMap((paActeOccurences: IActeOccurrence[]) => this.initSeances(
					poTraitement,
					poPatient,
					this.createSeances(paActeOccurences, poTraitement), // On crée les séances à partir des actes.
					pbShouldAutoCreateMajoration,
					pbIsAutoRulesEnable,
					paCotationRules,
					paMajorations,
					paIndemnites
				)),
				// switchMap((paSeances: Seance[]) => {
				// 	const loSeanceByMinuteDiff: Map<string, Seance[]> = ArrayHelper.groupBy(paSeances, (poSeance: Seance) => DateHelper.toUTCString(poSeance.startDate, EUTCAccuracy.day));
				// 	return this.prepareSeancesWithInterventionStatements(paInterventionStatements, poTraitement, loSeanceByMinuteDiff, paSeances).pipe(
				// 		mergeMap((paPreparedSeances: Seance[]) => this.prepareSeancesWithPrestations(paPrestations, poTraitement, loSeanceByMinuteDiff, paPreparedSeances))
				// 	);
				// }),
				// tap((paSeances: Seance[]) => {
				// 	poTraitement.seances = paSeances;
				// 	const loLastSeance: Seance = ArrayHelper.getLastElement(poTraitement.seances);
				// 	if (loLastSeance)
				// 		loLastSeance.isLast = poTraitement.isLastSeance(loLastSeance);
				// 	console.debug(`${this.logSourceId}Génération des séances du traitement ${poTraitement._id} en ${loPerformanceManager.markEnd().measure()}ms.`);
				// })
			);
	}

	// private prepareSeancesWithInterventionStatements(
	// 	paInterventionStatements: InterventionStatement[],
	// 	poTraitement: Traitement,
	// 	loSeanceByMinuteDiff: Map<string, Seance[]>,
	// 	paSeances: Seance[]
	// ): Observable<Seance[]> {
	// 	return defer(() => paInterventionStatements ? of(paInterventionStatements) : this.isvcInterventionStatement.getTraitementInterventionStatements(poTraitement._id)).pipe(
	// 		map((paTraitementInterventionStatements: InterventionStatement[]) => {

	// 			//On regroupe les interventions qui ont été crées à des moments différents pour une même séance
	// 			//Cela peut arriver si on rajoute un acte à la même date d'une séance qu'on a déjà validé
	// 			const grpInterventionsIdentiques = this.regrouperInterventionsParSeancesIdentiques(paTraitementInterventionStatements);

	// 			grpInterventionsIdentiques.forEach((poInterventionStatement: InterventionStatement, key: string) => {
	// 				const laSeances: Seance[] = loSeanceByMinuteDiff.get(DateHelper.toUTCString(DateHelper.parseReverseDate(poInterventionStatement.interventionDateString), EUTCAccuracy.day));
	// 				const dateSeanceIntervention: Date = IdHelper.extractDatesFromId(poInterventionStatement._id)[0];
	// 				const loSeance: Seance = laSeances?.find((poSeance: Seance) =>
	// 					ArrayHelper.areArraysEqual(poSeance.actes, poInterventionStatement.actes, (poActe: Acte, poInterventionActe: IInterventionStatementActe) => {
	// 						//On filtre également sur la date de la séance
	// 						return poActe.guid === poInterventionActe.acteGuid && DateHelper.compareTwoDates(dateSeanceIntervention, poSeance.startDate) == 0;
	// 					}
	// 					));

	// 				if (!ObjectHelper.isNullOrEmpty(loSeance)) {
	// 					loSeance.setStatus(poInterventionStatement.canceled ? EStatusSeance.canceled : EStatusSeance.done, poInterventionStatement.createDate);
	// 					loSeance.intervenantIds = [poInterventionStatement.intervenantId];
	// 				}
	// 				else if (!poInterventionStatement.canceled) {
	// 					const laInterActeGuids: string[] = poInterventionStatement.actes.map((poInterActe: IInterventionStatementActe) => poInterActe.acteGuid)
	// 					if (ArrayHelper.hasElements(laSeances)) {
	// 						const laSortedSeances: Seance[] = SeanceService.sortSeancesByDate(laSeances);

	// 						laSortedSeances.forEach((poSeance: Seance) => {
	// 							poSeance.actes.forEach((poActe: Acte, pnSeanceActeIndex: number, paActes: Acte[]) => {
	// 								laInterActeGuids.forEach((psInterActeGuid: string, pnInterActeIndex: number, paInterActeGuids: string[]) => {
	// 									if (psInterActeGuid === poActe.guid) {
	// 										ArrayHelper.removeElementByIndex(paInterActeGuids, pnInterActeIndex);
	// 										ArrayHelper.removeElementByIndex(paActes, pnSeanceActeIndex);
	// 									}
	// 								});
	// 							});
	// 						});
	// 					}

	// 					const loTraitementActeByGuid: Map<string, Acte> = ArrayHelper.groupByUnique(poTraitement.actes, (poActe: Acte) => poActe.guid);
	// 					const laActes: Acte[] = [];
	// 					poInterventionStatement.actes.forEach((poInterventionActe: IInterventionStatementActe) => {
	// 						const loActe: Acte = loTraitementActeByGuid.get(poInterventionActe.acteGuid);
	// 						if (loActe)
	// 							laActes.push(loActe);
	// 					});

	// 					const loShadowSeance = new Seance(
	// 						laActes,
	// 						0,
	// 						poInterventionStatement.interventionDate
	// 					);

	// 					loShadowSeance.scheduled = true;
	// 					loShadowSeance.shadow = ArrayHelper.hasElements(laInterActeGuids);
	// 					loShadowSeance.setStatus(EStatusSeance.done, poInterventionStatement.createDate);
	// 					loShadowSeance.patientId = poTraitement.patientId;
	// 					loShadowSeance.traitementId = poTraitement._id;
	// 					loShadowSeance.intervenantIds = [poInterventionStatement.intervenantId];

	// 					paSeances.push(loShadowSeance);
	// 				}
	// 			});
	// 			return paSeances;
	// 		})
	// 	);
	// }

	// private regrouperInterventionsParSeancesIdentiques(paTraitementInterventionStatements: InterventionStatement[]) {
	// 	return paTraitementInterventionStatements.reduce((prevInter, nvlleInter) => {
	// 		//on regarde la racine de l'id de l'intervention (sans le suffixe de l'id), si c'est similaire on les regroupe en une seule intervention
	// 		const racineIdObj = IdHelper.getPrefixFromId(nvlleInter._id) + IdHelper.extractParentId(nvlleInter._id);
	// 		const matchingKey = Array.from(prevInter.keys()).find(key => key.startsWith(racineIdObj));
	// 		if (!matchingKey) {
	// 			prevInter.set(nvlleInter._id, nvlleInter);
	// 		} else {
	// 			const interExistante: InterventionStatement = prevInter.get(matchingKey || nvlleInter._id);
	// 			//On rassemble les actes dans une meme intervention
	// 			const newTabActes = [...interExistante.actes, ...nvlleInter.actes];
	// 			interExistante.actes = newTabActes;
	// 		}
	// 		return prevInter;
	// 	}, new Map<string, InterventionStatement>());
	// }

	// private prepareSeancesWithPrestations(paPrestations: Prestation[], poTraitement: Traitement, loSeanceByMinuteDiff: Map<string, Seance[]>, paSeances: Seance[]): Observable<Seance[]> {
	// 	return defer(() => paPrestations ? of(paPrestations) : this.getTraitementPrestations(poTraitement)) // Si un tableau est passé, même vide, on ne requête pas
	// 		.pipe(
	// 			map((paTraitementPrestations: Prestation[]) => {
	// 				paTraitementPrestations.forEach((poPrestation: Prestation) => {
	// 					if (poPrestation.status === EPrestationStatus.sent) {
	// 						const loSeance: Seance = ArrayHelper.getFirstElement(loSeanceByMinuteDiff.get(poPrestation.dateStringFromId));
	// 						if (!ObjectHelper.isNullOrEmpty(loSeance))
	// 							loSeance.setStatus(EStatusSeance.completed, poPrestation.createDate);
	// 					}
	// 				});
	// 				return paSeances;
	// 			})
	// 		);
	// }

	public enableMajoration(poSeance: Seance, poMajorationToEnable: Majoration): Seance {
		const laMajorations: Majoration[] = poSeance.majorations;

		let lbIsMajorationAlreadyAdded = false;

		// On désactive la majoration dim et jf si on n'est pas sur un jour le permettant.
		if (poMajorationToEnable.type === EMajorationType.SundayAndHolyday && !this.isSundayOrPublicHoliday(poSeance.startDate))
			poMajorationToEnable.disabled = true;

		// Si le tableau de majorations de cette séance contient déjà la majoration qu'on veut ajouter, pas besoin de l'ajouter mais on la réactive si elle ne l'est pas.
		laMajorations.forEach((poMajoration: Majoration) => {
			if (poMajoration.type === poMajorationToEnable.type) {
				lbIsMajorationAlreadyAdded = true;

				poMajoration.disabled = poMajorationToEnable.disabled;
			}
		});

		if (!lbIsMajorationAlreadyAdded)
			laMajorations.push(poMajorationToEnable);

		return poSeance;
	}

	/** Permet d'ajouter une indemnité ou de l'activer si elle était déjà présente mais désactivée.
	 * @param poTraitement
	 * @param poSeance
	 * @param psIndemniteType
	 * @param paDeplacementsByProfession
	 * @param pbForce
	 */
	public enableIndemnite(
		poTraitement: Traitement,
		poSeance: Seance,
		psIndemniteType: EIndemniteType,
		paDeplacementsByProfession: IDeplacementByProfession[],
		pbForce?: boolean
	): void {

		let lbIsIndemniteAlreadyAdded = false;
		poSeance.indemnites.forEach((poIndemnite: Indemnite) => {
			if (poIndemnite.type === psIndemniteType) {
				poIndemnite.disabled = false;
				poIndemnite.force = pbForce;
				lbIsIndemniteAlreadyAdded = true;
			}
		});

		if (!lbIsIndemniteAlreadyAdded) {
			const loIndemnite: Indemnite = this.isvcIndemnite.createNewIndemnite(
				this.isvcIndemnite.getIndemniteIdFromSecteurType(poTraitement.deplacement.sectorType),
				psIndemniteType,
				paDeplacementsByProfession,
				poTraitement.deplacement,
				poSeance.startDate
			);

			loIndemnite.force = pbForce;

			poSeance.indemnites.push(loIndemnite);
		}

		// On gère le cas IFI/IFD
		if (psIndemniteType === EIndemniteType.IFI)
			this.disableIndemnite(poSeance, EIndemniteType.IFD);
		else if (psIndemniteType === EIndemniteType.IFD)
			this.disableIndemnite(poSeance, EIndemniteType.IFI);
	}

	public disableMajoration(poSeance: Seance, poMajorationToDisable: Majoration | EMajorationType): Seance {
		const loMajorationToDisable: Majoration = typeof poMajorationToDisable === "string" ? poSeance.majorations.find((poMajoration: Majoration) => poMajoration.type === poMajorationToDisable) : poMajorationToDisable;

		if (loMajorationToDisable)
			loMajorationToDisable.disabled = true;

		return poSeance;
	}

	/** Supprime ou désactive une indemnité du tableau passé en paramètres.
	 * @param poSeance
	 * @param peIndemniteType Type d'indemnité à supprimer.
	 * @returns L'indemnité supprimée.
	 */
	public disableIndemnite(poSeance: Seance, peIndemniteType: EIndemniteType): Indemnite {
		const loIndemnites: Indemnite = findLast(poSeance.indemnites, (poIndemnite: Indemnite) => poIndemnite.type === peIndemniteType);

		if (loIndemnites)
			loIndemnites.disabled = true;

		return loIndemnites;
	}

	/** Supprime une indemnité de la séance.
	 * @param poSeance
	 * @param peIndemniteType Type d'indemnité à supprimer.
	 * @returns L'indemnité supprimée.
	 */
	public removeIndemnite(poSeance: Seance, peIndemniteType: EIndemniteType): Indemnite {
		return ArrayHelper.removeElementByFinder(poSeance.indemnites, (poItem: Indemnite) => poItem.type === peIndemniteType);
	}

	/** Supprime une majoration de la séance.
	 * @param poSeance
	 * @param peMajorationType Type de majoration à supprimer.
	 * @returns La majoration supprimée.
	 */
	public removeMajoration(poSeance: Seance, peMajorationType: EMajorationType): Majoration {
		return ArrayHelper.removeElementByFinder(poSeance.majorations, (poItem: Majoration) => poItem.type === peMajorationType);
	}

	/** Initialise les séances du traitement en paramètre. */
	private initSeances(poTraitement: Traitement,
		poPatient: IPatient,
		paNewSeances: Seance[],
		pbShouldAutoCreateMajoration: boolean,
		pbIsAutoRulesEnable: boolean,
		paCotationRules?: ICotationRuleDocument[],
		paMajorations?: IActeDocumentByLc[],
		paIndemnites?: IDeplacementByProfession[]
	): Observable<Seance[]> {
		const laSeances: Seance[] = this.getInitSeances(paNewSeances, poTraitement);
		return defer(() => pbShouldAutoCreateMajoration && pbIsAutoRulesEnable ? this.applyTaxAllowance(laSeances, poPatient, paCotationRules) : of(laSeances))
		// 	.pipe(
		// 		mergeMap((paSeances: Seance[]) => this.resetMajorations(paSeances, poTraitement, poPatient, paMajorations)),
		// 		map((paSeances: Seance[]) => SeanceService.sortSeancesByDate(paSeances)),
		// 		mergeMap((paSeances: Seance[]) => this.initIndemnites(paSeances, poTraitement, paIndemnites))
		// 	);
	}

	private initIndemnites(paSeances: Seance[], poTraitement: Traitement, paIndemnites?: IDeplacementByProfession[]): Observable<Seance[]> {
		if (ArrayHelper.hasElements(paSeances))
			return defer(() => paIndemnites ? of(paIndemnites) : this.isvcIndemnite.getDeplacementsByProfession(this.isvcApplication.profession))
				.pipe(
					tap((paDeplacementsByProfession: IDeplacementByProfession[]) => {
						const loSeancesByDay: Map<string, Seance[]> = ArrayHelper.groupBy(paSeances, (poSeance: Seance) => DateHelper.transform(poSeance.startDate, ETimetablePattern.dd_MM_yyyy));
						paSeances.forEach((poSeance: Seance) => this.initIndemnite(poTraitement, poSeance, loSeancesByDay, paDeplacementsByProfession));
					}),
					mapTo(paSeances)
				);

		return of(paSeances);
	}

	private initIndemnite(poTraitement: Traitement, poSeance: Seance, poSeancesByDay: Map<string, Seance[]>, paDeplacementsByProfession: IDeplacementByProfession[]): void {
		if (!this.isIFIEligible(poSeance) && !this.isvcIndemnite.hasIndemnite(poSeance.indemnites, EIndemniteType.IFI)) {
			if (!this.isvcIndemnite.hasIndemnite(poSeance.indemnites, EIndemniteType.IFD))
				this.enableIndemnite(poTraitement, poSeance, EIndemniteType.IFD, paDeplacementsByProfession);
			this.removeIndemnite(poSeance, EIndemniteType.IFI);
		}
		else if (this.canAddIFI(poSeancesByDay, poSeance) && !this.isvcIndemnite.hasIndemnite(poSeance.indemnites, EIndemniteType.IFI)) {
			this.enableIndemnite(poTraitement, poSeance, EIndemniteType.IFI, paDeplacementsByProfession);
			this.removeIndemnite(poSeance, EIndemniteType.IFD);
		}

		poTraitement.indemnites?.forEach((poIndemnites: ExtraCharge<EIndemniteType>) => {
			if (poIndemnites.match(poSeance.startDate)) {
				if (poIndemnites.disabled)
					this.removeIndemnite(poSeance, poIndemnites.id);
				else
					this.enableIndemnite(poTraitement, poSeance, poIndemnites.id, paDeplacementsByProfession);
			}
		});
	}

	private canAddIFI(poSeancesByDay: Map<string, Seance[]>, poSeance: Seance) {
		return poSeancesByDay.get(DateHelper.transform(poSeance.startDate, ETimetablePattern.dd_MM_yyyy))
			?.filter((poDaySeance: Seance) => this.isvcIndemnite.isIndemniteEnabled(poDaySeance.indemnites, EIndemniteType.IFI))
			.length < IndemniteService.C_MAX_DAILY_IFI;
	}

	private getInitSeances(paSeances: Seance[], poTraitement: Traitement): Seance[] {
		const laSeances: Seance[] = [];

		paSeances.forEach((poSeance: Seance) => {
			if (poSeance) {
				poSeance.patientId = poTraitement.patientId;
				laSeances.push(poSeance);
			}
		});

		return this.mergeSeances(laSeances);
	}

	private mergeSeances(laSeances: Seance[]): Seance[] {
		return this.groupMergeableSeances(laSeances)
			.map((paSeances: Seance[]) => {
				return paSeances.reduce((poPreviousSeance: Seance, poCurrentSeance: Seance) => {
					if (!poPreviousSeance)
						return poCurrentSeance;

					if (ArrayHelper.hasElements(poCurrentSeance.majorations)) {
						poPreviousSeance.majorations = ArrayHelper.unique((poPreviousSeance.majorations ?? []).concat(poCurrentSeance.majorations),
							(poMajoration: Majoration) => poMajoration.id);
					}

					poPreviousSeance.actes.push(...(poCurrentSeance.actes ?? []));

					return poPreviousSeance;
				});
			});
	}

	private groupMergeableSeances(paSeances: Seance[]): Seance[][] {
		return ArrayHelper.flat(MapHelper.valuesToArray(
			ArrayHelper.groupBy(paSeances, (poSeance: Seance) => poSeance.startDate.getTime() + (poSeance.intervenantIds?.sort().join() ?? "") + poSeance.status)
		).map((paDaySeances: Seance[]) => {
			const loMap = new Map<Acte[], Seance[]>();

			paDaySeances.forEach((poSeance: Seance) => {
				if (loMap.size === 0)
					loMap.set([...(poSeance.actes ?? [])], [poSeance]);
				else {
					const laMatchingActes: Acte[] = MapHelper.keysToArray(loMap)
						.find((paActes: Acte[]) => !ArrayHelper.hasElements(ArrayHelper.intersection(paActes, poSeance.actes, (poActeA: Acte, poActeB: Acte) => poActeA.guid === poActeB.guid))
						) ?? [];

					laMatchingActes.push(...(poSeance.actes ?? []));

					const laSeances: Seance[] = loMap.get(laMatchingActes) ?? [];

					laSeances.push(poSeance);

					loMap.set(laMatchingActes, laSeances);
				}
			});

			return MapHelper.valuesToArray(loMap);
		}));
	}

	// public groupSeancesByDays(paSeances: Seance[]): IDateWithSeances[] {
	// 	const datesAndSeances: { date: Date, seances: Seance[] }[] = [];
	// 	let loCurrentDate: Date;

	// 	for (let lnIndex = 0, lnLength = paSeances.length; lnIndex < lnLength; ++lnIndex) {
	// 		const loSeance: Seance = paSeances[lnIndex];

	// 		if (loCurrentDate === undefined || DateHelper.diffDays(loSeance.startDate, loCurrentDate) !== 0) {
	// 			loCurrentDate = loSeance.startDate;

	// 			const loDateAndSeances: { date: Date, seances: Array<Seance> } = {
	// 				date: loSeance.startDate,
	// 				seances: [loSeance]
	// 			};
	// 			datesAndSeances.push(loDateAndSeances);
	// 		}
	// 		else
	// 			ArrayHelper.getLastElement(datesAndSeances).seances.push(loSeance);
	// 	}

	// 	return datesAndSeances;
	// }

	public generateSeanceEventIdStart(pdEvent?: Date): string {
		if (!pdEvent)
			return IdHelper.buildChildId(EPrefix.event, IdHelper.buildChildId(C_PREFIX_TRAITEMENT, IdHelper.buildVirtualNode([UserData.currentSite._id, C_PREFIX_PATIENT]), ""), "");

		return IdHelper.buildChildId(
			EPrefix.event,
			IdHelper.buildChildId(
				C_PREFIX_TRAITEMENT,
				IdHelper.buildVirtualNode([UserData.currentSite._id, DateHelper.toUTCString(pdEvent, EUTCAccuracy.minutes), C_PREFIX_PATIENT]
				),
				""
			),
			""
		);
	}
	// evt_trait_site~pat_default~201601050446~pcuoezI4iRv0q0nXSNmJT_uvUaNyTu7EtJxgF384FxJ_3dVyUa6oFbloHAJ1fPoEN
	public generateSeanceEventId(pdEvent: Date, poTraitement: Traitement): string {
		return IdHelper.buildChildId(
			EPrefix.event,
			IdHelper.buildChildId(
				C_PREFIX_TRAITEMENT,
				IdHelper.buildVirtualNode([poTraitement.siteId, DateHelper.toUTCString(pdEvent, EUTCAccuracy.minutes), poTraitement.patientId]
				),
				poTraitement.guid
			)
		);
	}

	// /** Récupère toutes les séances d'un patient dans un interval.
	//  * @param psPatientId
	//  * @param pdFrom
	//  * @param pdTo
	//  * @param pbLive
	//  * @returns
	//  */
	// public getPatientSeances(psPatientId: string, pdFrom: Date, pdTo?: Date, pbLive?: boolean): Observable<Seance[]> {
	// 	return this.innerGetSeances_interval(pdFrom, pdTo, pbLive, psPatientId);
	// }

	/** Permet de récupérer toutes les séances comprises dans un interval.
	 * @param pdMin Limite basse de l'interval.
	 * @param pdMax Limite haute de l'interval.
	 */
	// public getSeances(pdMin: Date, pdMax: Date, pbLive?: boolean): Observable<Seance[]>;
	// /** Permet de récupérer toutes les séances d'un traitement.
	//  * @param poTraitement
	//  */
	// public getSeances(poTraitement: Traitement, pbLive?: boolean): Observable<Seance[]>;
	// /** Permet de récupérer toutes les séances d'une liste de traitements.
	//  * @param paTraitements
	//  */
	// public getSeances(paTraitements: Traitement[], pbLive?: boolean): Observable<Seance[]>;
	// public getSeances(poData: Traitement | Date | Traitement[], poMaxTimeOrLive?: Date | boolean, pbLive?: boolean): Observable<Seance[]> {
	// 	if (poData instanceof Date)
	// 		return this.innerGetSeances_interval(poData, poMaxTimeOrLive as Date, pbLive);
	// 	else if (poData instanceof Array)
	// 		return this.innerGetSeances_traitements(poData, poMaxTimeOrLive as boolean);
	// 	else if (poData)
	// 		return this.innerGetSeances_traitement(poData, poMaxTimeOrLive as boolean);
	// 	else
	// 		return of([]);
	// }

	// public getSeancesUpdates(psMode: "local", paTraitementIds: string[], pdMinDate?: Date, pdMaxDate?: Date): Observable<IChangeEvent<ITraitement | IEventMarker>>;
	// public getSeancesUpdates(psMode: "remote", paTraitementIds: string[], pdMinDate: Date, pdMaxDate: Date, poActivePageManager: ActivePageManager): Observable<IChangeEvent<ITraitement | IEventMarker>>;
	// public getSeancesUpdates(psMode: "local" | "remote", paTraitementIds: string[], pdMinDate: Date, pdMaxDate: Date, poActivePageManager?: ActivePageManager): Observable<IChangeEvent<ITraitement | IEventMarker>> {
	// 	const loDataSource: IDataSourceRemoteChanges = {
	// 		databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
	// 		remoteChanges: psMode === "remote",
	// 		activePageManager: poActivePageManager,
	// 		viewParams: { include_docs: true }
	// 	};

	// 	const lsEventsStartKey: string = this.generateSeanceEventIdStart(pdMinDate);
	// 	const lsEventsEndKey = `${this.generateSeanceEventIdStart(pdMaxDate)}${Store.C_ANYTHING_CODE_ASCII}`;

	// 	return defer(() => {
	// 		if (psMode === "remote") {
	// 			loDataSource.selector = {
	// 				$or: [
	// 					{
	// 						_id: {
	// 							$gte: lsEventsStartKey,
	// 							$lte: lsEventsEndKey
	// 						}
	// 					},
	// 					{
	// 						_id: {
	// 							$in: ArrayHelper.unique(paTraitementIds)
	// 						}
	// 					}
	// 				]
	// 			};

	// 			return this.isvcStore.changes<ITraitement | IEventMarker>(loDataSource);
	// 		}
	// 		else if (psMode === "local") {
	// 			return merge(
	// 				this.isvcStore.localChanges<IEventMarker>({
	// 					...loDataSource,
	// 					viewParams: { startkey: lsEventsStartKey, endkey: lsEventsEndKey },
	// 					filter: (poEventMarker: IEventMarker) => poEventMarker._id.includes(C_PREFIX_TRAITEMENT)
	// 				}),
	// 				this.isvcStore.localChanges<ITraitement>({ ...loDataSource, viewParams: { keys: paTraitementIds } })
	// 			);
	// 		}

	// 		return EMPTY;
	// 	});
	// }

	// private innerGetSeances_traitements(paDatas: Traitement[], pbLive: boolean): Observable<Seance[]> {
	// 	return defer(() => {
	// 		if (pbLive) {
	// 			return this.isvcStore.localChanges({
	// 				role: EDatabaseRole.workspace, viewParams: {
	// 					keys: paDatas.map((poTraitement: Traitement) => poTraitement._id)
	// 				}
	// 			});
	// 		}
	// 		return EMPTY;
	// 	}).pipe(
	// 		map((poChangeEvent: IChangeEvent<ITraitement>) => {
	// 			poChangeEvent.document = Traitement.createFromData(poChangeEvent.document);
	// 			this.isvcStore.handleChangeEvent(poChangeEvent, paDatas);
	// 		}),
	// 		startWith(paDatas),
	// 		switchMap(() => this.generateTraitementsSeances(paDatas, pbLive))
	// 	);
	// }

	// private innerGetSeances_interval(pdMin: Date, pdMax: Date, pbLive?: boolean, psPatientId?: string): Observable<Seance[]> {
	// 	return defer(() => {
	// 		if (StringHelper.isBlank(psPatientId))
	// 			return this.isvcEvents.getEventMarkers({ from: pdMin, to: pdMax }, pbLive, (pdEvent?: Date) => this.generateSeanceEventIdStart(pdEvent));
	// 		return this.isvcEvents.getEntityEventMarkers(IdHelper.getLastGuidFromId(psPatientId), { from: pdMin, to: pdMax }, pbLive, (pdEvent?: Date) => this.generateSeanceEventIdStart(pdEvent));
	// 	})
	// 		.pipe(
	// 			switchMap((paResults: IEventMarker[]) => {
	// 				if (!ArrayHelper.hasElements(paResults))
	// 					return of([]);

	// 				const loResultsGroupedByDatabaseId: Map<string, IEventMarker[]> = ArrayHelper.groupBy(paResults, (poEventMarker: IEventMarker) => StoreHelper.getDatabaseIdFromCacheData(poEventMarker));
	// 				const laEventsObservables: Observable<ITraitement[]>[] = [];

	// 				loResultsGroupedByDatabaseId.forEach((paEventMarkers: IEventMarker[], psDatabaseId: string) => {
	// 					const loDataSource: IDataSource = {
	// 						databaseId: psDatabaseId,
	// 						viewParams: {
	// 							include_docs: true,
	// 							keys: paEventMarkers.map((poEventMarker: IEventMarker) => {
	// 								const lsParentId: string = IdHelper.extractParentId(poEventMarker._id);
	// 								return Traitement.createId(Traitement.extractPatientId(lsParentId), Traitement.extractSiteId(lsParentId), Traitement.extractGuid(lsParentId));
	// 							}),
	// 							conflicts: true
	// 						},
	// 						live: pbLive
	// 					};
	// 					laEventsObservables.push(this.isvcStore.get<ITraitement>(loDataSource));
	// 				});

	// 				return combineLatest(laEventsObservables).pipe(
	// 					map((paTraitementsArrays: ITraitement[][]) => ArrayHelper.flat(paTraitementsArrays)),
	// 					defaultIfEmpty([])
	// 				);
	// 			}),
	// 			map((paTraitements: ITraitement[]) => paTraitements.map((poTraitements: ITraitement) => Traitement.createFromData(poTraitements))),
	// 			switchMap((paTraitements: Traitement[]) => this.generateTraitementsSeances(paTraitements, pbLive, pdMin, pdMax)),
	// 			map((paSeances: Seance[]) => paSeances.filter((poSeance: Seance) => DateHelper.isBetweenTwoDates(poSeance.startDate, pdMin, pdMax)))
	// 		);
	// }

	// private innerGetSeances_traitement(poTraitement: Traitement, pbLive: boolean): Observable<Seance[]> {
	// 	return defer(() => pbLive ?
	// 		this.isvcStore.getOne({ databaseId: StoreHelper.getDatabaseIdFromCacheData(poTraitement), viewParams: { key: poTraitement._id, include_docs: true }, live: pbLive }) :
	// 		of(poTraitement)
	// 	).pipe(
	// 		mergeMap((poResultTraitement: Traitement) => this.isvcPatients.getPatient(poResultTraitement.patientId).pipe(
	// 			mergeMap((poPatient: IPatient) => this.generateSeances(poResultTraitement, poPatient))
	// 		)),
	// 		distinctUntilChanged((paOldSeances: Seance[], paNewSeances: Seance[]) => ArrayHelper.areArraysEqual(paOldSeances, paNewSeances,
	// 			(poOldSeance: Seance, poNewSeance: Seance) => poNewSeance.equals(poOldSeance)
	// 		))
	// 	);
	// }

	/** Récupére la date de fin de la dernière séance.
	 * @param paSeances
	 */
	public getSeancesMaxDate(paSeances: ISeance[]): Date {
		if (!ArrayHelper.hasElements(paSeances))
			return null;
		else {
			return ArrayHelper.getFirstElement(
				paSeances.sort((poSeanceA: ISeance, poSeanceB: ISeance) => DateHelper.compareTwoDates(poSeanceB.endDate, poSeanceA.endDate))
			)
				.endDate;
		}
	}

	/** Récupére la date de début de la première séance.
	 * @param paSeances
	 */
	public getSeancesMinDate(paSeances: ISeance[]): Date {
		if (!ArrayHelper.hasElements(paSeances))
			return null;
		else {
			return ArrayHelper.getFirstElement(
				paSeances.sort((poSeanceA: ISeance, poSeanceB: ISeance) => DateHelper.compareTwoDates(poSeanceA.startDate, poSeanceB.startDate))
			)
				.startDate;
		}
	}

	/** Détermine la prochaine date/heure disponible dans un planning de séance.
	 * @param pdMinDate Date/heure de début de planification, utilisée si aucune séance n'est planifiée.
	 * @param paPlannedSeances État du planning.
	 */
	// public getNextScheduleDate(pdMinDate: Date, paPlannedSeances: ISeance[]): Date {
	// 	if (ArrayHelper.hasElements(paPlannedSeances))
	// 		return DateHelper.addMinutes(ArrayHelper.getLastElement(paPlannedSeances).endDate, SeanceService.C_DELAY_BETWEEN_SEANCES_MINUTES);
	// 	else
	// 		return DateHelper.addMinutes(pdMinDate, (SeanceService.C_DAY_TOUR_START_HOURS * 60) + SeanceService.C_DAY_TOUR_START_MINUTES);
	// }

	/** Planifie une séance à la date/heure indiquée et marque la séance comme planifiée. */
	// public scheduleSeanceAt(poSeance: Seance, pdStartDate: Date, pdEndDate?: Date): void {
	// 	this.setSeanceDates(poSeance, pdStartDate, pdEndDate);
	// 	poSeance.scheduled = true;
	// }

	/** Applique à une séance la date/heure indiquée. */
	// public setSeanceDates(poSeance: Seance, pdStartDate: Date, pdEndDate?: Date): void {
	// 	if (!pdEndDate)
	// 		pdEndDate = DateHelper.addMinutes(pdStartDate, Seance.C_DEFAULT_DURATION);

	// 	if (DateHelper.compareTwoDates(poSeance.startDate, pdStartDate) !== 0 || DateHelper.compareTwoDates(poSeance.endDate, pdEndDate ?? poSeance.endDate) !== 0) {
	// 		poSeance.startDate = pdStartDate;
	// 		poSeance.duration = DateHelper.diffMinutes(pdEndDate, pdStartDate);
	// 	}
	// }

	// public getSeanceCoordinates(poSeance: ISeance, pbRecalculateIfNoData?: boolean): Observable<ICoordinates> {
	// 	if (StringHelper.isBlank(poSeance.patientId))
	// 		return throwError("Aucun patient n'est associé à cette séance.");
	// 	else
	// 		return this.isvcContacts.getContact(poSeance.patientId)
	// 			.pipe(
	// 				mergeMap((poPatient: IPatient) => {
	// 					if (poPatient.latitude && poPatient.longitude)
	// 						return of({ latitude: poPatient.latitude, longitude: poPatient.longitude });
	// 					else {
	// 						if (pbRecalculateIfNoData) {
	// 							if (NavigationService.canShowRecalculateGPSDataPopup(poPatient)) {
	// 								return this.isvcNavigation.recalculateContactGPSData(poPatient)
	// 									.pipe(
	// 										mergeMap((poUpdatedModel: IContact) => {
	// 											if (!ObjectHelper.isNullOrEmpty(poUpdatedModel))
	// 												poPatient = poUpdatedModel;
	// 											return this.isvcPatients.savePatient(poPatient);
	// 										}),
	// 										mergeMap(_ => {
	// 											this.isvcPatients.saveAdressCacheData(poPatient);
	// 											return of({ latitude: poPatient.latitude, longitude: poPatient.longitude });
	// 										})
	// 									);
	// 							}
	// 						}

	// 						return throwError("Aucune donnée GPS disponible pour ce patient et aucune adresse n'est renseignée.");
	// 					}
	// 				})
	// 			);
	// }

	/** Récupère un objet contenant la possible séance précédente et possible séance suivante en fonction d'une séance donnée.
	 * @param poSelectedSeance
	 * @param paSeances
	 */
	// public getPreviousAndNextSeances(poSelectedSeance: Seance, paSeances: Seance[]): IPreviousAndNextSeances {
	// 	ArrayHelper.dynamicSort(paSeances, "startDate");
	// 	const lnCurrentSeanceIndex: number = paSeances.findIndex((poSeance: Seance) => poSelectedSeance.equals(poSeance));
	// 	const loData: IPreviousAndNextSeances = {};

	// 	if (lnCurrentSeanceIndex !== -1) {
	// 		if (lnCurrentSeanceIndex !== paSeances.length - 1) { // L'index de la séance courante n'est pas la dernière alors on peut aller sur la suivante.
	// 			let lnSearchIndex: number = lnCurrentSeanceIndex + 1;
	// 			while (lnSearchIndex <= paSeances.length) {
	// 				if (paSeances[lnSearchIndex].status !== EStatusSeance.canceled) {
	// 					loData.nextSeance = paSeances[lnSearchIndex];
	// 					break;
	// 				};
	// 				lnSearchIndex++;
	// 			};
	// 		};

	// 		if (lnCurrentSeanceIndex > 0) { // Si la séance actuelle n'est pas la première alors on peut aller sur la précédente.
	// 			let lnSearchIndex: number = lnCurrentSeanceIndex - 1;
	// 			while (lnSearchIndex >= 0) {
	// 				if (paSeances[lnSearchIndex].status !== EStatusSeance.canceled) {
	// 					loData.previousSeance = paSeances[lnSearchIndex];
	// 					break;
	// 				};
	// 				lnSearchIndex--;
	// 			};
	// 		};
	// 	};

	// 	return loData;
	// }

	// public insertSeance(paSeances: Seance[], poSeance: Seance): Seance[] {
	// 	const laSeances: Seance[] = [...paSeances];
	// 	ArrayHelper.replaceElementByFinder(laSeances, (poListSeance: Seance) => poSeance.id === poListSeance.id, poSeance);
	// 	return laSeances;
	// }

	/** Annule la séance.
	 * @param poSeance
	 */
	// @LogAction<Parameters<SeanceService["cancelSeance"]>, ReturnType<SeanceService["cancelSeance"]>>({
	// 	actionId: EIdlLogActionId.seancesCancel,
	// 	successMessage: "Annulation de la séance.",
	// 	errorMessage: "Échec de l'annulation de la séance.",
	// 	dataBuilder: (_, __, poSeance: Seance) => ({ seanceDate: poSeance.startDate, traitementId: poSeance.traitementId })
	// })
	// public cancelSeance(poSeance: Seance, poTraitement: Traitement, pbCreatePrestation: boolean = true): Observable<Seance> {
	// 	return defer(() => {
	// 		poSeance.setStatus(EStatusSeance.canceled);
	// 		poSeance.scheduled = false;
	// 		poSeance.actes.forEach((poActe: Acte) => poActe.canceled = true);

	// 		if (pbCreatePrestation) {
	// 			return this.createAndSavePrestation(poSeance, poTraitement)
	// 				.pipe(
	// 					mergeMap(() => this.isvcInterventionStatement.saveInterventionStatement(InterventionStatementService.createInterventionStatement(
	// 						poSeance.startDate,
	// 						poTraitement._id,
	// 						poSeance.actes.map((poActe: Acte) => ({ acteId: poActe._id, skipReason: InterventionStatementService.C_DEFAULT_SKIP_REASON, acteGuid: poActe.guid } as IInterventionStatementActe)),
	// 						ArrayHelper.getFirstElement(poSeance.intervenantIds)
	// 					))),
	// 				);
	// 		}
	// 		return of(undefined);
	// 	}).pipe(mapTo(poSeance));
	// }

	public createAndSavePrestation(seance: Seance, traitement: Traitement): Observable<IdlPrestation> {
		let observationPresta: string;
		//On retire les actes annulés et non réalisés
		seance.actes = seance.actes.filter((poActe: Acte) => !poActe.canceled && poActe.etat !== EEtatActe.not_done);

		return this.svcObservation.getEntityObservation(traitement._id, seance.startDate)
			.pipe(
				tap((observation: Observation) => observationPresta = observation?.description),
				mergeMap(() => {
					const prestation: IdlPrestation = this.createSeancePrestation(traitement, seance, observationPresta);
					// seance.status = EStatusSeance.inProgress;
					// seance.statusChangeDate = prestation.createDate;

					// Sauvegarde la prestation, puis retourne la prestation une fois sauvegardée
					return this.svcPrestation.savePrestation(prestation).pipe(
						mergeMap(() => {
							// Sauvegarde de la séance après avoir sauvegardé la prestation
							return this.svcSeance.updateSeance(seance).pipe(
								map(() => prestation) // Retourne la prestation
							);
						}));
				}),
			);
	}

	/** Annule les séances.
	 * @param paSeances Tableau des séances à annuler.
	 */
	// public cancelSeances(paSeances: Seance[], poTraitement: Traitement, pbCreatePrestation?: boolean): Observable<Seance[]> {
	// 	if (!ArrayHelper.hasElements(paSeances))
	// 		return of([]);

	// 	return concat(...paSeances.map((poSeance: Seance) => this.cancelSeance(poSeance, poTraitement, pbCreatePrestation)))
	// 		.pipe(toArray());
	// }

	/** Réactive une séance.
	 * @param poSeance
	 * @returns
	 */
	// @LogAction<Parameters<SeanceService["reactivate"]>, ReturnType<SeanceService["reactivate"]>>({
	// 	actionId: EIdlLogActionId.seancesReactivate,
	// 	successMessage: "Réactivation de la séance.",
	// 	errorMessage: "Erreur lors de la réactivation de la séance.",
	// 	dataBuilder: (_, __, poSeance: Seance) => ({ seanceDate: poSeance.startDate, traitementId: poSeance.traitementId })
	// })
	// public reactivate(poSeance: Seance): Seance {
	// 	poSeance.setStatus(EStatusSeance.pending);
	// 	poSeance.scheduled = true;
	// 	poSeance.actes.forEach((poActe: Acte) => poActe.canceled = false);
	// 	return poSeance;
	// }

	/** Valide la séance.
	 * @param poSeance
	 */
	// @LogAction<Parameters<SeanceService["validateSeance"]>, ReturnType<SeanceService["validateSeance"]>>({
	// 	actionId: EIdlLogActionId.seancesValidate,
	// 	successMessage: "Validation de la séance.",
	// 	errorMessage: "Erreur lors de la validation de la séance.",
	// 	dataBuilder: (_, __, poSeance: Seance, poTraitement: ITraitement, pbKeepIntervenant?: boolean) => ({
	// 		seanceDate: poSeance.startDate,
	// 		traitementId: poSeance.traitementId,
	// 		keepIntervenant: !!pbKeepIntervenant
	// 	})
	// })
	// public validateSeance(poSeance: Seance, poTraitement: Traitement, pbKeepIntervenant?: boolean): Observable<Seance> {
	// 	if (!pbKeepIntervenant)
	// 		poSeance.intervenantIds = [ContactsService.getUserContactId()]; // La séance appartient à celui qui la valide.
	// 	poSeance.setStatus(EStatusSeance.done);

	// 	return this.createAndSavePrestation(poSeance, poTraitement)
	// 		.pipe(
	// 			mergeMap(() => this.isvcInterventionStatement.saveInterventionStatement(InterventionStatementService.createInterventionStatement(
	// 				poSeance.startDate,
	// 				poTraitement._id,
	// 				poSeance.actes.map((poActe: Acte) => ({ acteId: poActe._id, skipReason: poActe.canceled ? "Annulée" : undefined, acteGuid: poActe.guid } as IInterventionStatementActe)),
	// 				ArrayHelper.getFirstElement(poSeance.intervenantIds)
	// 			))),
	// 			mapTo(poSeance)
	// 		);
	// }

	/** Permet à un administrateur de dévalider une séance.
	 * @param poSeance
	 * @returns `true` si la séance comporte plusieurs intervenants.
	//  */
	// @LogAction<Parameters<SeanceService["unvalidateSeance"]>, ReturnType<SeanceService["unvalidateSeance"]>>({
	// 	actionId: EIdlLogActionId.seancesValidate,
	// 	successMessage: "Dévalidation de la séance.",
	// 	errorMessage: "Erreur lors de la dévalidation de la séance.",
	// 	dataBuilder: (_, __, poSeance: Seance) => ({ userId: UserData.current?._id, seanceDate: poSeance.startDate, traitementId: poSeance.traitementId })
	// })
	// public unvalidateSeance(poSeance: Seance): Seance {
	// 	poSeance.setStatus(undefined);
	// 	poSeance.scheduled = true;
	// 	// TODO TB Correction dévalidation
	// 	return poSeance;
	// }

	/** Permet à un administrateur de passer à l'état facturée une séance.
	 * @param poSeance
	 */
	// @LogAction<Parameters<SeanceService["adminBillSeance"]>, ReturnType<SeanceService["adminBillSeance"]>>({
	// 	actionId: EIdlLogActionId.seancesBill,
	// 	successMessage: "Passage de la séance à l'état facturé.",
	// 	errorMessage: "Erreur lors du passage à l'état facturé de la séance.",
	// 	dataBuilder: (_, __, poSeance: Seance) => ({ userId: UserData.current?._id, seanceDate: poSeance.startDate, traitementId: poSeance.traitementId })
	// })
	// public adminBillSeance(poSeance: Seance): void {
	// 	poSeance.setStatus(EStatusSeance.completed);
	// 	// TODO TB Correction action admin
	// }

	// public hasSeanceMultipleIntervenant(poSeance: Seance): boolean {
	// 	return !(poSeance.intervenantIds?.length === 1 && IdHelper.getPrefixFromId(poSeance.intervenantIds[0]) === EPrefix.contact);
	// }

	// public getResumeActes(poSeance: Seance): string;
	// public getResumeActes(poSeanceTournee: ISeanceTournee): string;
	// public getResumeActes(poData: Seance | ISeanceTournee): string {
	// 	const loSeance: Seance = poData instanceof Seance ? poData : poData.seance;
	// 	return this.ioConcatActesPipe.transform(loSeance.actes, ",");
	// }

	/** Retourne `true` si la séance est éligible à l'IFI, `false` sinon.
	 * @param poSeance Séance dont on veut vérifier l'éligibilité à l'IFI en fonction de ses actes.
	 */
	public isIFIEligible(poSeance: Seance): boolean {
		if (ArrayHelper.hasElements(poSeance.actes)) {
			// On regroupe toutes les lettre-clés dans un seul tableau sans doublon dans lequel on vérifie s'il y a des lettre-clés éligibles à l'IFI.
			return ArrayHelper.unique(poSeance.actes.map((poActe: Acte) => poActe.keyLetters))
				.some((psKeyLetter: string) => IdlApplicationService.C_IFI_ELIGIBLE_KEY_LETTERS.some((psEligible: string) => psEligible === psKeyLetter));
		}
		else
			return false;
	}

	// public getSeancesPatients(paSeances: ISeance[]): Observable<IPatient[]> {
	// 	return defer(() => of(paSeances.map((poSeance: ISeance) => poSeance.patientId)))
	// 		.pipe(mergeMap((paPatientsIds: string[]) => this.isvcContacts.getContactsByIds(paPatientsIds)));
	// }

	public static sortSeancesByDate<T extends ISeance>(paSeances: T[]): T[] {
		return SeancesHelper.sortSeancesByDate(paSeances);
	}

	/** Permet de sélectionner des séances à mettre à jour parmis un lot. Si une séance source est donnée alors elle sera considérée comme sélectionnée.
	 * @param poData Séance ou date visée à l'origine par la modification.
	 * @param paSeances Tableau des séances pouvant être la cible des modifications.
	 * @param paChoices Tableau des choix disponibles pour sélectionner les séances à modifier.
	 * @param psModalTitle Titre de la modale.
	 * @param poActe Acte concerné par la modification.
	 */
	// public selectSeancesForUpdateWithModal(
	// 	poData: Date | Seance,
	// 	paSeances: Seance[],
	// 	paChoices: IChoice[],
	// 	psModalTitle: string,
	// 	poActe?: Acte,
	// 	//pbFilterAvailableSeances: boolean = true
	// ): Observable<IChooseSeancesToModifiyResponse> {
	// 	const ldStartDate: Date = poData instanceof Date ? new Date(poData) : new Date(poData.startDate);
	// 	const laChoices: IChoice[] = ArrayHelper.getValidValues(paChoices);
	// 	this.initChoices(laChoices, paSeances, ldStartDate, poData instanceof Seance ? poData : undefined, poActe);

	// 	if (paSeances.length === 1)
	// 		return of({ seances: paSeances, filters: [] });

	// 	if (!ArrayHelper.hasElements(laChoices) || !ArrayHelper.hasElements(paSeances) || laChoices.every((poChoice: IChoice) => !ArrayHelper.hasElements(poChoice.value)))
	// 		return poData instanceof Seance ? of({ seances: [poData], filters: [] }) : of({ seances: [], filters: [] });

	// 	const loModalOptions: ModalOptions = {
	// 		component: ChooseSeancesToModifyModalComponent,
	// 		componentProps: {
	// 			data: poData,
	// 			choices: laChoices,
	// 			title: psModalTitle
	// 		} as IChooseSeancesToModifyModalParams
	// 	};

	// 	return defer(() => this.ioModalCtrl.create(loModalOptions))
	// 		.pipe(
	// 			tap((poModal: HTMLIonModalElement) => poModal.present()),
	// 			mergeMap((poModal: HTMLIonModalElement) => poModal.onDidDismiss()),
	// 			map((poResult: OverlayEventDetail<IChooseSeancesToModifiyResponse>) => poResult.data),
	// 			filter((poResponse: IChooseSeancesToModifiyResponse) => ArrayHelper.hasElements(poResponse?.seances)),
	// 			map((poResponse: IChooseSeancesToModifiyResponse) => {
	// 				if (poData instanceof Seance)
	// 					ArrayHelper.pushIfNotPresent(poResponse.seances, poData, (poSeance: Seance) => poSeance.equals(poData));

	// 				return poResponse;
	// 			})
	// 		);
	// }

	// private initChoices(paChoices: IChoice[], paSeances: Seance[], pdStartDate: Date, poSeance?: Seance, poActe?: Acte): void {
	// 	paChoices.forEach((poChoice: IChoice) => {
	// 		if (!poChoice.value) {
	// 			poChoice.value = paSeances;

	// 			if (ArrayHelper.hasElements(poChoice.filters))
	// 				poChoice.filters.forEach((peFilter: EChoiceFilter) => poChoice.value = this.applyChoiceFilter(poChoice.value, peFilter, pdStartDate, poSeance, poActe));
	// 		}
	// 	});
	// }

	// private applyChoiceFilter(paSeances: Seance[], peFilter: EChoiceFilter, pdStartDate: Date, poSourceSeance?: Seance, poActe?: Acte): Seance[] {
	// 	switch (peFilter) {
	// 		case EChoiceFilter.sameIntervenant:
	// 			return poSourceSeance ?
	// 				paSeances.filter((poSeance: Seance) => ArrayHelper.getFirstElement(poSeance.intervenantIds) === ArrayHelper.getFirstElement(poSourceSeance.intervenantIds)) :
	// 				paSeances;

	// 		case EChoiceFilter.samePatient:
	// 			return poSourceSeance ? paSeances.filter((poSeance: Seance) => poSeance.patientId === poSourceSeance.patientId) : paSeances;

	// 		case EChoiceFilter.sameDay:
	// 			return paSeances.filter((poSeance: Seance) => DateHelper.diffDays(poSeance.startDate, pdStartDate) === 0);

	// 		case EChoiceFilter.sameTime:
	// 			return paSeances.filter((poSeance: Seance) =>
	// 				poSeance.startDate.getHours() === pdStartDate.getHours() && poSeance.startDate.getMinutes() === pdStartDate.getMinutes()
	// 			);

	// 		case EChoiceFilter.future:
	// 			return paSeances.filter((poSeance: Seance) => DateHelper.compareTwoDates(poSeance.startDate, pdStartDate) >= 0);

	// 		case EChoiceFilter.current:
	// 			return poSourceSeance ? [poSourceSeance] : [];

	// 		case EChoiceFilter.sameActe:
	// 			return poActe ? paSeances.filter((poSeance: Seance) => poSeance.actes.some((poSeanceActe: Acte) => poActe._id === poSeanceActe._id)) : paSeances;

	// 		default:
	// 			return paSeances;
	// 	}
	// }

	public getConstraint<T extends Constraint>(
		poConstraintType: Type<T>,
		poResponse: IChooseSeancesToModifiyResponse,
		poStartSeance: Seance,
		pdEnd?: Date
	): T {
		const loConstraint: T = new poConstraintType;
		loConstraint.occurrenceComparators.push(
			...this.getActeOccurrenceComparatorsFrom(poResponse, poStartSeance.startDate, poStartSeance.intervenantIds, pdEnd)
		);
		return loConstraint;
	}

	public getActeOccurrenceComparatorsFrom(
		poResponse: IChooseSeancesToModifiyResponse,
		pdStart: Date,
		paIntervenants?: (IGroupMember | string)[],
		pdEnd?: Date
	): ActeOccurrenceComparator[] {
		const laOccurrenceComparators: ActeOccurrenceComparator[] = [];

		const lbHasSameTime: boolean = poResponse.filters.includes(EChoiceFilter.sameTime);
		const lbHasSameDay: boolean = poResponse.filters.includes(EChoiceFilter.sameDay);
		const lbHasSameIntervant: boolean = poResponse.filters.includes(EChoiceFilter.sameIntervenant);
		const lbHasFuture: boolean = poResponse.filters.includes(EChoiceFilter.future);

		if (lbHasSameIntervant && ArrayHelper.hasElements(paIntervenants)) {
			laOccurrenceComparators.push(new ActeOccurrenceIntervenantsComparator({
				intervenantIds: paIntervenants.map((poIntervenant: (IGroupMember | string)) =>
					typeof poIntervenant === "string" ? poIntervenant : poIntervenant._id)
			}));
		}
		if (lbHasSameTime || lbHasSameDay) {
			laOccurrenceComparators.push(new ActeOccurrenceDateTimeComparator({
				day: lbHasSameDay ? pdStart : undefined,
				hours: lbHasSameTime ? pdStart.getHours() : undefined,
				minutes: lbHasSameTime ? pdStart.getMinutes() : undefined
			}));
		}
		if (lbHasFuture || pdEnd)
			laOccurrenceComparators.push(new ActeOccurrenceRangeComparator({ from: pdStart, to: pdEnd }));

		if (!ArrayHelper.hasElements(laOccurrenceComparators)) {
			laOccurrenceComparators.push(new ActeOccurrenceDateTimeComparator({
				day: pdStart,
				hours: pdStart.getHours(),
				minutes: pdStart.getMinutes()
			}));
		}

		return laOccurrenceComparators;
	}

	/** Retourne `true` si la séance est dite 'en cours', `false` sinon.
	 * @param poSeance Séance dont il faut vérifier si elle est en cours ou non.
	 */
	// public static isOngoingSeance(poSeance: ISeance): boolean {
	// 	return !poSeance.status || poSeance.status === EStatusSeance.pending;
	// }

	/** Créé la synthèse d'une séance.
	 * @param poTraitement
	 * @param poSeance
	 * @param paMajorations
	 */
	// public createSyntheseSeance(poTraitement: Traitement, poSeance: Seance, paMajorations: Majoration[]): ISyntheseSeance {
	// 	const laActes: Acte[] = poSeance.actes.filter((poActe: Acte) => !poActe.canceled);
	// 	return {
	// 		id: poSeance.id,
	// 		actes: laActes,
	// 		acteLength: laActes.length,
	// 		majorations: paMajorations.filter((poMajoration: Majoration) => !poMajoration.disabled),
	// 		indemnitePrice: this.isvcIndemnite.getIndemnitesTotalPrice(poSeance),
	// 		deplacementDistance: poTraitement.deplacement.distance,
	// 		startDate: poSeance.startDate,
	// 		endDate: poSeance.endDate,
	// 		deplacementDetail: poSeance.getDeplacementDetail(poTraitement.deplacement.distance),
	// 		seanceStatus: poSeance.status,
	// 		intervenantId: ArrayHelper.getFirstElement(poSeance.intervenantIds)
	// 	} as ISyntheseSeance;
	// }

	/** Méthode qui calcule et affecte le prix total de la synthèse séance.
	 * @param poSyntheseSeance Synthèse séance (format adapté pour la synthèse d'une séance).
	 */
	// public setSyntheseSeanceTotalPrice(poSyntheseSeance: ISyntheseSeance): void {
	// 	poSyntheseSeance.totalPrice = 0;

	// 	// Gestion des ajouts des tarifs d'actes.
	// 	poSyntheseSeance.actes.forEach((poActe: Acte) => poSyntheseSeance.totalPrice += poActe.price);

	// 	// Gestion des tarifs des majorations.
	// 	const lnMajorationTotalPrice: number = poSyntheseSeance.majorations.map((poMajoration: Majoration) => poMajoration.price)
	// 		.reduce((pnPreviousPrice: number, pnCurrentPrice: number) => pnPreviousPrice + pnCurrentPrice, 0);
	// 	poSyntheseSeance.totalPrice += lnMajorationTotalPrice;

	// 	// Gestion des IK.
	// 	poSyntheseSeance.totalPrice += poSyntheseSeance.indemnitePrice;
	// }

	//#endregion

	//#region Majorations

	private resetMajorations(paSeances: Seance[], poTraitement: Traitement, poPatient: IPatient, paMajorations?: IActeDocumentByLc[]): Observable<Seance[]>;
	private resetMajorations(poSeance: Seance, poTraitement: Traitement, poPatient: IPatient, paMajorations?: IActeDocumentByLc[]): Observable<Seance>;
	private resetMajorations(poData: Seance[] | Seance, poTraitement: Traitement, poPatient: IPatient, paMajorations?: IActeDocumentByLc[]): Observable<Seance[] | Seance> {
		const laSeances: Seance[] = coerceArray(poData);

		return defer(() => paMajorations ? of(paMajorations) : this.getMajorations())
			.pipe(
				tap((paResultMajorations: IActeDocumentByLc[]) => {
					this.majorationHolydays(laSeances, paResultMajorations);
					this.majorationMci(laSeances, paResultMajorations, poTraitement.pathologies);
					this.majorationMau(laSeances, paResultMajorations);
					this.applyNightMajorations(laSeances, paResultMajorations);
					this.applyMajorationMie(laSeances, poPatient, paResultMajorations);
					this.applyTraitementMajorations(laSeances, poTraitement, paResultMajorations);
					this.disableExcludeMajorations(laSeances);
				}),
				mapTo(poData)
			);
	}

	private applyTraitementMajorations(paSeances: Seance[], poTraitement: Traitement, paMajorations: IActeDocumentByLc[]): void {
		paSeances.forEach((poSeance: Seance) => {
			poTraitement.majorations?.forEach((poMajoration: ExtraCharge<EMajorationType>) => {
				if (poMajoration.match(poSeance.startDate)) {
					if (poMajoration.disabled)
						this.removeMajoration(poSeance, poMajoration.id);
					else {
						const loMajoration: Majoration = new Majoration(this.getMajorationIdFromType(poMajoration.id),
							poMajoration.id,
							this.getMajorationPriceFromArray(paMajorations, poMajoration.id),
							this.getMajorationDescriptionFromArray(paMajorations, poMajoration.id)
						);

						this.enableMajoration(poSeance, loMajoration);
					}
				}
			});
		});
	}

	private disableExcludeMajorations(paSeances: Seance[]): void {
		paSeances.forEach((poSeance: Seance) => {
			const laExcludeMajoration: EMajorationType[] = [];
			poSeance.actes.forEach((poActe: Acte) => {
				if (ArrayHelper.hasElements(poActe.excludeMajorations))
					laExcludeMajoration.push(...poActe.excludeMajorations as EMajorationType[]);
			});
			ArrayHelper.unique(laExcludeMajoration).forEach((peMajorationType: EMajorationType) => this.disableMajoration(poSeance, peMajorationType));
		});
	};

	/** Applique les majorations de nuit.
	 * @param paSeances Liste des séances sur lesquelles appliquer les majorations.
	 * @param paMajorations
	 */
	private applyNightMajorations(paSeances: Seance[], paMajorations: IActeDocumentByLc[]): void {
		paSeances.forEach((poSeance: Seance) => {
			if (poSeance.isProtected)
				return;

			const ldStartDate = new Date(poSeance.startDate);
			const lnHours: number = ldStartDate.getHours();

			// 20h-23h & 5h-8h
			if (!this.isProtectedMajorationType(EMajorationType.NightFirstHalf, poSeance.majorations)) {
				const loMajoration = new Majoration(this.getMajorationIdFromType(EMajorationType.NightFirstHalf),
					EMajorationType.NightFirstHalf,
					this.getMajorationPriceFromArray(paMajorations, EMajorationType.NightFirstHalf),
					this.getMajorationDescriptionFromArray(paMajorations, EMajorationType.NightFirstHalf));

				if (((lnHours >= 5 && lnHours < 8) || (lnHours >= 20 && lnHours < 23)))
					this.enableMajoration(poSeance, loMajoration);
				else
					this.disableMajoration(poSeance, loMajoration);
			}
			// 23h-5h
			if (!this.isProtectedMajorationType(EMajorationType.NightSecondHalf, poSeance.majorations)) {
				const loMajoration = new Majoration(this.getMajorationIdFromType(EMajorationType.NightSecondHalf),
					EMajorationType.NightSecondHalf,
					this.getMajorationPriceFromArray(paMajorations, EMajorationType.NightSecondHalf),
					this.getMajorationDescriptionFromArray(paMajorations, EMajorationType.NightSecondHalf));

				if ((lnHours >= 23 || lnHours < 5))
					this.enableMajoration(poSeance, loMajoration);
				else
					this.disableMajoration(poSeance, loMajoration);
			}

			// Si on a une majoration de nuit on doit la privilégier face à la majoration dim/jf
			if (poSeance.majorations.some((poMajoration: Majoration) =>
				!poMajoration.disabled && (poMajoration.type === EMajorationType.NightFirstHalf || poMajoration.type === EMajorationType.NightSecondHalf))
			)
				this.disableMajoration(poSeance, EMajorationType.SundayAndHolyday);
		});
	}

	/** Applique l'abattement sur les actes des séances.
	 * @param paSeances
	 * @param poPatient
	 * @returns
	 */
	public applyTaxAllowance(paSeances: Seance[], poPatient: IPatient, paCotationRules?: ICotationRuleDocument[]): Observable<Seance[]> {
		const laCotationRulesIds: string[] = ArrayHelper.unique(ArrayHelper.flat(ArrayHelper.flat(paSeances.map((poSeance: Seance) =>
			poSeance.actes.map((poActe: Acte) => poActe.cotationRulesIds ?? [])))));

		SeanceService.sortSeancesByDate(paSeances);
		return defer(() => ArrayHelper.hasElements(laCotationRulesIds) && !paCotationRules ? this.isvcActes.getCotationRules(laCotationRulesIds) : of(paCotationRules ?? []))
			.pipe(
				map((paCotationRulesResult: ICotationRuleDocument[]) => {
					const loCotationRulesByIds: Map<string, ICotationRuleDocument[]> = ArrayHelper.groupBy(paCotationRulesResult, (poCotationRule: ICotationRuleDocument) => poCotationRule._id);
					const laCheckedSeances: Seance[] = [];

					paSeances.forEach((poSeance: Seance) => {
						this.resetTaxAllowance(poSeance);
						this.inner_applyTaxAllowance_initialApply(poSeance, poPatient, loCotationRulesByIds, laCheckedSeances);

						// On groupe les actes par packageId sinon on ne les groupes avec aucun autre.
						const laActesByPackageId: Acte[][] = MapHelper.valuesToArray(ArrayHelper.groupBy(poSeance.actes, (poActe: Acte) => poActe.packageId ?? GuidHelper.newGuid()));

						// On commence par trier pour avoir les forfaits puis les groupes les plus cher.
						this.inner_applyTaxAllowance_initialSort(laActesByPackageId);

						const pdsAvecCoeffSup = this.inner_applyTaxAllowance_getPdsWithHighestCoeff(poSeance?.actes);

						let lnCpt = 0;
						let lbHasPackage = false;
						// De base on a le droit de facturer 2 actes.
						let lnActeToPrestation = 2;

						// On applique les abattements par groupe.
						laActesByPackageId.forEach((paActes: Acte[]) => {
							let lbIncrementCpt = false;

							paActes.forEach((poActe: Acte) => {
								if (pdsAvecCoeffSup && pdsAvecCoeffSup._id !== poActe._id && lnCpt === 1) {
									//Si on un acte de prise de sang dans la séance et qu'il a le plus gros coefficient
									//On ignore la regle de l'abattement de 50% sur le deuxieme acte
									lbIncrementCpt = true;
								} else if (poActe.taxAllowance > 0) { // Si le taux est de 0, c'est que l'acte a été exclu du calcul.
									if (poActe.isPackage)
										lbHasPackage = true;

									// On ne compte pas le forfait dans le nombre d'acte pour calculer l'abattement ni les actes exclus du calcul d'abattement.
									this.inner_applyTaxAllowance_apply(lbHasPackage, poActe, poSeance, poPatient, lnCpt, loCotationRulesByIds, laCheckedSeances);

									if (!poActe.isPackage && !poActe.excludeFromTaxAllowance)
										lbIncrementCpt = true;
									else // Si c'est un forfait ou un acte à exclure du calcul de l'abattement, on prestation.
										lnActeToPrestation++;
								}
							});

							// On incrémente une fois par groupe.
							if (lbIncrementCpt)
								lnCpt++;
						});

						// On trie par prix total.
						laActesByPackageId.sort((paActesA: Acte[], paActesB: Acte[]) => ActesService.getTotalPrice(paActesB) - ActesService.getTotalPrice(paActesA));

						// On élimine les moins cher pour facturer de manière optimisée.
						laActesByPackageId.slice(lnActeToPrestation).forEach((paActes: Acte[]) => paActes.forEach((poActe: Acte) => poActe.taxAllowance = 0));
						laCheckedSeances.push(poSeance);
					});

					return paSeances;
				})
			);
	}

	private resetTaxAllowance(poSeance: Seance): void {
		// On remet les abattement à leur valeur initiale.
		poSeance.actes.forEach((poActe: Acte) => poActe.taxAllowance = 1);
		// On trie pour avoir les packages en premier puis les actes par prix.
		poSeance.actes.sort((poActeA: Acte, poActeB: Acte) => {
			if (poActeA.isPackage && !poActeB.isPackage)
				return -1;
			else if (poActeB.isPackage && !poActeA.isPackage)
				return 1;
			return poActeB.price - poActeA.price;
		});
	}

	private inner_applyTaxAllowance_initialApply(
		poSeance: Seance,
		poPatient: IPatient,
		poCotationRulesByIds: Map<string, ICotationRuleDocument[]>,
		paCheckedSeances: Seance[]
	): void {
		poSeance.actes.forEach((poActe: Acte) => {
			// On nettoie les anciennes informations de groupement pour le calcul de l'abattement.
			poActe.packageId = undefined;
			// On applique les règles de cotation une première fois pour avoir les packageIds.
			this.applyCotationRules(poActe, poSeance, poPatient, poCotationRulesByIds, paCheckedSeances);
		});
	}

	private inner_applyTaxAllowance_initialSort(laActesByPackageId: Acte[][]): void {
		laActesByPackageId.sort((paActesA: Acte[], paActesB: Acte[]) => {
			if (paActesA.some((poActe: Acte) => poActe.isPackage) && !paActesB.some((poActe: Acte) => poActe.isPackage))
				return -1;
			else if (paActesB.some((poActe: Acte) => poActe.isPackage) && !paActesA.some((poActe: Acte) => poActe.isPackage))
				return 1;
			else
				return ActesService.getTotalPrice(paActesB) - ActesService.getTotalPrice(paActesA);
		});
	}

	private inner_applyTaxAllowance_getPdsWithHighestCoeff(actesSeance: Acte[]): Acte {
		if (actesSeance.length === 0) return null;

		const pdsTag = "PDS, prise de sang";
		// Recherche la plus grande cotation
		const maxCoeff = Math.max(...actesSeance.map(acte => acte.initialPriceCoefficient));
		// Vérifie si un acte "PDS" a la plus grande cotation
		return actesSeance.find(acte => acte.tags === pdsTag && acte.initialPriceCoefficient === maxCoeff);
	}

	private inner_applyTaxAllowance_apply(
		lbHasPackage: boolean,
		poActe: Acte,
		loSeance: Seance,
		poPatient: IPatient,
		lnCpt: number,
		poCotationRulesByIds: Map<string, ICotationRuleDocument[]>,
		paCheckedSeances: Seance[]
	): void {
		if ((lbHasPackage && !poActe.forceRullInPackage) || !this.applyCotationRules(poActe, loSeance, poPatient, poCotationRulesByIds, paCheckedSeances)) { // Si forfait ou si pas de règle de cotation appliquée.
			if (poActe.excludeFromTaxAllowance || lnCpt === 0)
				poActe.taxAllowance = 1; // Le premier acte hors forfait est à 100%.
			else if (lnCpt > 0)
				poActe.taxAllowance = 0.5;
		}
	}

	private isCotationRuleConditionValid(
		poCotationRule: ICotationRule,
		poActe: Acte,
		poSeanceToCheck: Seance,
		poPatient: IPatient,
		paCheckedSeances: Seance[]
	): boolean {
		// Si on a atteint le nombre d'acte limite pour cette règle on stoppe ici.
		if (!StringHelper.isBlank(poCotationRule.packageId) && ArrayHelper.hasElements(poCotationRule.limits) &&
			this.isCotationRuleLimitReached(poCotationRule, poActe, poSeanceToCheck))
			return false;
		else if (StringHelper.isBlank(poCotationRule.condition))
			return true;
		else if (poCotationRule.condition?.startsWith(ECotationConditionPattern.patientPathologiesContains)) {
			return !ArrayHelper.hasElements(ArrayHelper.getDifferences(
				this.extractValueFromCondition(poCotationRule.condition), coerceArray(poPatient?.pathologies ?? [])
			));
		}
		else if (poCotationRule.condition?.startsWith(ECotationConditionPattern.seanceActesContains)) {
			return !ArrayHelper.hasElements(ArrayHelper.getDifferences(
				this.extractValueFromCondition(poCotationRule.condition), poSeanceToCheck.actes.map((poSeanceActe: Acte) => poSeanceActe._id)
			));
		}
		else if (poCotationRule.condition?.startsWith(ECotationConditionPattern.seanceActesSome)) {
			return ArrayHelper.hasElements(ArrayHelper.intersection(
				this.extractValueFromCondition(poCotationRule.condition), poSeanceToCheck.actes.map((poSeanceActe: Acte) => poSeanceActe._id)
			));
		}
		else if (poCotationRule.condition?.startsWith(ECotationConditionPattern.daySeancesActesExceeds)) {
			const laValues: string[] = this.extractValueFromCondition(poCotationRule.condition);
			const lnCount: number = +laValues[1];

			if (!NumberHelper.isValidStrictPositive(lnCount) || poActe._id !== laValues[0])
				return false;

			return paCheckedSeances.filter((poSeance: Seance) =>
				DateHelper.diffDays(poSeance.startDate, poSeanceToCheck.startDate) === 0 && ActesService.hasActe(poSeance.actes, poActe._id)
			).length >= lnCount;
		}
		return false;
	}

	public isCotationRuleLimitReached(poCotationRule: ICotationRule, poActe: Acte, poSeance: Seance): boolean {
		const loLimit: ICotationRuleLimit = poCotationRule.limits?.find((poLimit: ICotationRuleLimit) => poLimit.id === poActe._id);

		return loLimit && poSeance.actes.filter((poSeanceActe: Acte) =>
			poSeanceActe._id === loLimit.id && poSeanceActe.packageId === poCotationRule.packageId
		).length >= loLimit.value;
	}

	private extractValueFromCondition(psCondition: string): string[] {
		return ArrayHelper.getLastElement(/\((.*)\)/.exec(psCondition))?.replace(/\s/g, "").split(",");
	}

	private applyCotationRules(
		poActe: Acte,
		poSeance: Seance,
		poPatient: IPatient,
		poCotationRulesByIds: Map<string, ICotationRuleDocument[]>,
		paCheckedSeances: Seance[]
	): boolean {
		let lbCotationRuleApplied = false;
		let lbPackageIdApplied = false;

		poActe.cotationRulesIds?.forEach((psId: string) => {
			const loCotationRule: ICotationRule = ArrayHelper.getFirstElement(poCotationRulesByIds.get(psId));
			if (loCotationRule && this.isCotationRuleConditionValid(loCotationRule, poActe, poSeance, poPatient, paCheckedSeances)) {
				lbCotationRuleApplied = true;
				switch (loCotationRule.action) {
					case ECotationAction.cotation100:
						poActe.taxAllowance = 1;
						break;
					case ECotationAction.cotation50:
						poActe.taxAllowance = 0.5;
						break;
					case ECotationAction.cotation0:
						poActe.taxAllowance = 0;
						break;
					case ECotationAction.excludeFromTaxAllowance:
						poActe.taxAllowance = 1;
						poActe.excludeFromTaxAllowance = true;
						break;
					default:
						lbCotationRuleApplied = false;
						break;
				}
				if (!StringHelper.isBlank(loCotationRule.packageId)) {
					poActe.packageId = loCotationRule.packageId;
					lbPackageIdApplied = true;
				}
				if (loCotationRule.forceInPackage)
					poActe.forceRullInPackage = loCotationRule.forceInPackage;
			}
			else if (!lbPackageIdApplied)
				poActe.packageId = undefined;
		});

		return lbCotationRuleApplied;
	}

	/** Ajout des majorations automatiques en fonction du jour (dim. et jours fériés) et aussi des actes uniques.
	 * @param paSeances
	 * @param paMajorations
	*/
	private majorationHolydays(paSeances: Seance[], paMajorations: IActeDocumentByLc[]): void {
		for (let lnIndex = 0, lnLength = paSeances.length; lnIndex < lnLength; ++lnIndex) {
			const loSeance: Seance = paSeances[lnIndex];
			const loMajoration: Majoration = new Majoration(`MAJ-${this.isvcApplication.profession}-${EMajorationType.SundayAndHolyday}`,
				EMajorationType.SundayAndHolyday,
				this.getMajorationPriceFromArray(paMajorations, EMajorationType.SundayAndHolyday),
				this.getMajorationDescriptionFromArray(paMajorations, EMajorationType.SundayAndHolyday)
			);

			if (loSeance.isProtected || this.isProtectedMajorationType(loMajoration.type, loSeance.majorations))
				continue;

			const ldStart: Date = new Date(loSeance.startDate);

			if (this.isSundayOrPublicHoliday(ldStart))
				this.enableMajoration(loSeance, loMajoration);
			else
				this.removeMajoration(loSeance, loMajoration.type);
		}
	}

	private isSundayOrPublicHoliday(pdDate: Date): boolean {
		// dimanche = 0.
		return pdDate.getDay() === 0 || DateHelper.isPublicHoliday(pdDate);
	}

	/** Ajout des majorations d'actes uniques.
	 * @param paSeances
	 * @param paMajorations
	*/
	private majorationMau(paSeances: Seance[], paMajorations: IActeDocumentByLc[]): void {
		for (let lnIndex = paSeances.length - 1; lnIndex >= 0; --lnIndex) {
			const loSeance: Seance = paSeances[lnIndex];
			const loMajoration: Majoration = new Majoration(
				"MAU",
				EMajorationType.Mau,
				this.getMajorationPriceFromArray(paMajorations, EMajorationType.Mau),
				this.getMajorationDescriptionFromArray(paMajorations, EMajorationType.Mau)
			);

			if (loSeance.isProtected || this.isProtectedMajorationType(loMajoration.type, loSeance.majorations))
				continue;

			if (loSeance.actes.filter((poActe: Acte) => !poActe.canceled).length === 1 && // il faut qu'il n'y ait qu'un seul acte dans la séance.
				loSeance.actes.some((poActe: Acte) => poActe.keyLetters === "AMI" && poActe.priceCoefficient < 2 && !poActe.isMciEligible)) // AMI && coef < 2 et pas de maj MCI = on applique la majo MAU
				this.enableMajoration(loSeance, loMajoration);
			else
				this.removeMajoration(loSeance, loMajoration.type);
		}
	}

	/** Ajout des majorations si acte éligible MCI.
	 * @param paSeances
	 * @param paMajorations
	*/
	private majorationMci(paSeances: Seance[], paMajorations: IActeDocumentByLc[], paPathologies?: EPathologie[]): void {
		for (let lnIndex = paSeances.length - 1; lnIndex >= 0; --lnIndex) {
			const loSeance: Seance = paSeances[lnIndex];
			const loMajoration: Majoration = new Majoration(
				"MCI",
				EMajorationType.Mci,
				this.getMajorationPriceFromArray(paMajorations, EMajorationType.Mci),
				this.getMajorationDescriptionFromArray(paMajorations, EMajorationType.Mci)
			);

			if (loSeance.isProtected || this.isProtectedMajorationType(loMajoration.type, loSeance.majorations))
				continue;

			if (loSeance.actes.some((poActe: Acte) => poActe.isMciEligible) || paPathologies?.includes(EPathologie.palliatif))
				this.enableMajoration(loSeance, loMajoration);
			else
				this.removeMajoration(loSeance, loMajoration.type);
		}
	}

	/** Ajout majoration si patient de moins de 7 ans.
	 * @param paSeances Liste des séances sur lesquelles appliquer les majorations.
	 * @param poPatient
	 * @param paMajorations
	*/
	private applyMajorationMie(paSeances: Seance[], poPatient: IPatient, paMajorations: IActeDocumentByLc[]): Seance[] {
		for (let lnIndex = paSeances.length - 1; lnIndex >= 0; --lnIndex) {
			const loSeance: Seance = paSeances[lnIndex];
			const loMajoration: Majoration = new Majoration(
				"MIE",
				EMajorationType.Mie,
				this.getMajorationPriceFromArray(paMajorations, EMajorationType.Mie),
				this.getMajorationDescriptionFromArray(paMajorations, EMajorationType.Mie)
			);

			if (loSeance.isProtected || this.isProtectedMajorationType(loMajoration.type, loSeance.majorations))
				continue;


			if (DateHelper.isDate(poPatient?.birthDate) && DateHelper.diffYear(new Date(loSeance.startDate), new Date(poPatient.birthDate)) < 7)
				this.enableMajoration(loSeance, loMajoration);
			else
				this.removeMajoration(loSeance, loMajoration.type);
		};
		return paSeances;
	}

	/** Vérifie si un certain type de majoration est présent dans un tableau de majorations et si il est protégé.
	 * @param poMajorationType Type de la majoration à vérifier.
	 * @param paMajorations Tableau des majorations à vérifier.
	 * @returns `true` si présent et protégé, `false` sinon.
	 */
	private isProtectedMajorationType(poMajorationType: EMajorationType, paMajorations: Array<Majoration>): boolean {
		return paMajorations?.some((poMajoration: Majoration) => poMajoration.type === poMajorationType && poMajoration.isProtected) ?? false;
	}

	/**
	 * Récupère l'id d'une majoration en fonction de son type
	 * @param poType Type de majoration
	 */
	public getMajorationIdFromType(poType: EMajorationType): string {
		switch (poType) {
			case EMajorationType.Mau:
				return `MAU`;
			case EMajorationType.Mci:
				return `MCI`;
			case EMajorationType.Mie:
				return `MIE`;
			case EMajorationType.SundayAndHolyday:
				return `MAJ-${this.isvcApplication.profession}-Dim`;
			case EMajorationType.NightFirstHalf:
				return `MAJ-${this.isvcApplication.profession}-N1`;
			case EMajorationType.NightSecondHalf:
				return `MAJ-${this.isvcApplication.profession}-N2`;
		}
		return '';
	}

	public getMajorationTypeFromId(id: string): EMajorationType | null {
		switch (id) {
			case 'MAU':
				return EMajorationType.Mau;
			case 'MCI':
				return EMajorationType.Mci;
			case 'MIE':
				return EMajorationType.Mie;
			case `MAJ-${this.isvcApplication.profession}-Dim`:
				return EMajorationType.SundayAndHolyday;
			case `MAJ-${this.isvcApplication.profession}-N1`:
				return EMajorationType.NightFirstHalf;
			case `MAJ-${this.isvcApplication.profession}-N2`:
				return EMajorationType.NightSecondHalf;
			default:
				return null;
		}
	}


	/** Récupération de la description d'une majoration.
	 * @param paResults tableau des résultats des majorations du métier concerné.
	 * @param peMajorationType
	 */
	public getMajorationDescriptionFromArray(paResults: IActeDocumentByLc[], peMajorationType: EMajorationType): string {
		return this.getMajorationFromArray(paResults, peMajorationType)?.description;
	}

	/** Récupération du prix d'une majoration.
	 * @param paResults tableau des résultats des majorations du métier concerné.
	 * @param peMajorationType
	 */
	public getMajorationPriceFromArray(paResults: IActeDocumentByLc[], peMajorationType: EMajorationType): number {
		let lc_majo: IActeDocumentByLc = this.getMajorationFromArray(paResults, peMajorationType);
		if (lc_majo) {
			if (ArrayHelper.hasElements(lc_majo.tarif)) {
				return lc_majo.tarif[ConfigData.geoZoneApp - 1];
			} else if (ArrayHelper.hasElements(lc_majo.tarifs)) {
				const tarifIndemnite: ITarifLettreCle = lc_majo.tarifs.find((tar: ITarifLettreCle) => {
					return TarifHelper.getFiltreTarifLettreCleParDate(tar, new Date());
				});
				return tarifIndemnite ? tarifIndemnite.montant : 0;
			}
		}
		return 0;
	}

	/** Récupère une majoration depuis un tableau en fonction de son type.
	 * @param paResults
	 * @param peMajorationType
	 * @returns Majoration correspondant au type ou `undefined`.
	 */
	private getMajorationFromArray(paResults: IActeDocumentByLc[], peMajorationType: EMajorationType): IActeDocumentByLc {
		return paResults.find((poResult: IActeDocumentByLc) => poResult.id.indexOf(peMajorationType) !== -1);
	}

	/** Initialise le prix des différentes majorations. */
	public getMajorations(): Observable<IActeDocumentByLc[]> {
		const loParams: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.formsEntries),
			viewName: "majoration/by_profession",
			viewParams: { key: this.isvcApplication.profession }
		};

		return this.isvcStore.get<IActeDocumentByLc>(loParams) // Récupère la liste des majorations du métier concerné.
			.pipe(
				catchError(poError => {
					console.error(`${this.logSourceId}Erreur récupération données sur la base ${JSON.stringify(loParams.databasesIds)} : `, poError);
					return throwError(poError);
				})
			);
	}


	public getDeplacements(): Observable<IActeDocumentByLc[]> {
		const loParams: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.formsEntries),
			viewName: "deplacement/by_profession",
			viewParams: { key: this.isvcApplication.profession }
		};

		return this.isvcStore.get<IActeDocumentByLc>(loParams)
			.pipe(
				catchError(poError => {
					console.error(`${this.logSourceId}Erreur récupération données sur la base ${JSON.stringify(loParams.databasesIds)} : `, poError);
					return throwError(poError);
				})
			);
	}

	//#endregion

	//#region Actes

	/** Annule l'acte en paramètre dans la séance.
	 * @param poSeance Séance de départ.
	 * @param poActe Acte à annuler.
	 */
	// public cancelActe(poTraitement: Traitement, poSeance: Seance, poActe: Acte): Observable<Seance>;
	// /** Annule l'acte en paramètre dans les séances en commençant par la première passée en paramètre.
	//  * @param poSeance Séance de départ.
	//  * @param paSeances Liste de toutes les séances.
	//  * @param poActe Acte à annuler.
	//  */
	// public cancelActe(poTraitement: Traitement, poSeance: Seance, paSeances: Seance[], poActe: Acte): Observable<Seance[]>;
	// public cancelActe(poTraitement: Traitement, poSeance: Seance, poData: Acte | Seance[], poActe?: Acte): Observable<Seance[] | Seance> {
	// 	if (poData instanceof Array) {
	// 		if (poActe instanceof Acte) {
	// 			poData = SeanceService.sortSeancesByDate(poData);
	// 			const lnStartIndex: number = poData.indexOf(poSeance);

	// 			return concat(...poData.slice(lnStartIndex).map((poOtherSeance: Seance) => this.cancelActe(poTraitement, poOtherSeance, poActe)))
	// 				.pipe(toArray(), mapTo(poData));
	// 		}
	// 		else
	// 			throw new OsappError("Le 3eme paramètre doit être un acte.");
	// 	}
	// 	else if (poData instanceof Acte) {
	// 		const laSeanceActes: Acte[] = poSeance.actes.filter((poInnerActe: Acte) => (poData as Acte).guid === poInnerActe.guid);
	// 		this.isvcLogger.action(this.logSourceId, "Annulation de l'acte.", EIdlLogActionId.acteCancel as unknown as ELogActionId, { userId: UserData.current?._id, acteId: (poData as Acte)._id });

	// 		if (ArrayHelper.hasElements(laSeanceActes)) {
	// 			laSeanceActes.forEach((poSeanceActe: Acte) => poSeanceActe.canceled = true);
	// 			if (poSeance.actes.every((poSeanceActe: Acte) => poSeanceActe.canceled))
	// 				return this.cancelSeance(poSeance, poTraitement);
	// 		}

	// 		return of(poSeance);
	// 	}
	// 	else
	// 		throw new OsappError("Le 2eme paramètre doit être un acte ou un tableau de séances.");
	// }

	public getIntervenantConstraint(
		poResponse: IChooseSeancesToModifiyResponse,
		poStartSeance: Seance,
		paNewIntervenants: (IGroupMember | string)[],
		pdEnd?: Date
	): IntervenantConstraint {
		const loConstraint = this.getConstraint(
			IntervenantConstraint,
			poResponse,
			poStartSeance,
			pdEnd
		);

		loConstraint.intervenantIds = paNewIntervenants.map((poIntervenant: IGroupMember | string) =>
			typeof poIntervenant === "string" ? poIntervenant : poIntervenant._id
		);

		return loConstraint;
	}

	/** Permet d'afficher la modale de déplacement d'acte.
	 * @param poSeance
	 * @param paSeances
	 * @param poActe
	 * @param poTraitement
	 */
	// public moveActeWithModal(poSeance: Seance, paSeances: Seance[], poActe: Acte, poTraitement: Traitement): Observable<Seance[]> {
	// 	const loModalOptions: ModalOptions = {
	// 		component: MoveActeModalComponent,
	// 		componentProps: {
	// 			acte: poActe,
	// 			seances: paSeances
	// 		}
	// 	};

	// 	return defer(() => this.ioModalCtrl.create(loModalOptions))
	// 		.pipe(
	// 			tap((poModal: HTMLIonModalElement) => poModal.present()),
	// 			mergeMap((poModal: HTMLIonModalElement) => poModal.onDidDismiss()),
	// 			filter((poResult: OverlayEventDetail<IMoveActeModalResponse>) => !!poResult.data),
	// 			map((poResult: OverlayEventDetail<IMoveActeModalResponse>) => {
	// 				const laSeances: Seance[] = [...paSeances];

	// 				ArrayHelper.removeElementByFinder(poSeance.actes, (poSeanceActe: Acte) => poActe.guid === poSeanceActe.guid);

	// 				if (!ArrayHelper.hasElements(poResult.data.seance.intervenantIds))
	// 					poResult.data.seance.intervenantIds = poSeance.intervenantIds;

	// 				ArrayHelper.replaceElementByFinder(laSeances, (poOtherSeance: Seance) => poOtherSeance.id === poResult.data.seance.id, poResult.data.seance);
	// 				ArrayHelper.replaceElementByFinder(laSeances, (poOtherSeance: Seance) => poOtherSeance.id === poSeance.id, poSeance);

	// 				// On ajoute la contrainte dans l'acte du traitement.
	// 				const loTraitementActe: Acte = poTraitement.actes.find((poTraitementActe: Acte) => poTraitementActe.guid === poActe.guid);
	// 				const loConstraint = new MoveConstraint();
	// 				loConstraint.occurrenceComparators.push(new ActeOccurrenceDateTimeComparator({ day: poSeance.startDate, hours: poSeance.startDate.getHours(), minutes: poSeance.startDate.getMinutes() }));
	// 				loConstraint.date = poResult.data.seance.startDate;
	// 				loTraitementActe.constraints.push(loConstraint);
	// 				StoreHelper.makeDocumentDirty(poTraitement);

	// 				return laSeances.filter((poSeanceToFilter: Seance) => ArrayHelper.hasElements(poSeanceToFilter.actes));
	// 			})
	// 		);
	// }

	//#endregion

	//#region Intervenants

	// public selectIntervenants(poSeance: Seance, psTitle = "Sélectionner des intervenants"): Observable<IGroupMember[]> {
	// 	const lsErrorMessage = "Impossible de sélectionner les intervenants d'une séance ";
	// 	if (!this.isAdmin) {
	// 		if (poSeance.isProtected)
	// 			return throwError(new OsappError(`${lsErrorMessage}protégée.`));
	// 		else if (poSeance.status === EStatusSeance.done)
	// 			return throwError(new OsappError(`${lsErrorMessage}validée.`));
	// 	}

	// 	return this.isvcGroups.getContactGroupsIds(poSeance.patientId)
	// 		.pipe(
	// 			map((paPatientGroupIds: string[]) => ArrayHelper.getFirstElement(paPatientGroupIds)),
	// 			mergeMap((psPatientSectorId: string) =>
	// 				this.isvcGroups.getGroups()
	// 					.pipe(
	// 						mergeMap((paGroups: IGroup[]) => {
	// 							const laSectors: IGroup[] = paGroups.filter((poGroup: IGroup) => this.isvcGroups.hasRole(poGroup, C_SECTORS_ROLE_ID));
	// 							const laIntervenants: IGroup[] = paGroups.filter((poGroup: IGroup) => this.isvcGroups.hasRole!(poGroup, C_SECTORS_ROLE_ID));

	// 							return this.isvcGroups.getGroupContacts(laIntervenants)
	// 								.pipe(
	// 									map((poContactsByGroupsIds: Map<string, IContact[]>) => ArrayHelper.unique(ArrayHelper.flat(MapHelper.valuesToArray(poContactsByGroupsIds)))),
	// 									mergeMap((paContacts: IContact[]) => {
	// 										const loContactsSelectorParams: IContactsSelectorParams = {
	// 											hasSearchbox: true,
	// 											selectionMinimum: 1,
	// 											type: EContactsType.contacts,
	// 											userContactVisible: true,
	// 											preSelectedIds: poSeance.intervenantIds,
	// 											contactsData: paContacts,
	// 											groupsData: laIntervenants.concat(laSectors),
	// 											groupFilterParams: {
	// 												options: laSectors.map((poGroup: IGroup) => ({ label: poGroup.name, value: poGroup })),
	// 												preselectedValues: !StringHelper.isBlank(psPatientSectorId) ? laSectors.find((poGroup: IGroup) => poGroup._id === psPatientSectorId) : undefined
	// 											}
	// 										};

	// 										return this.isvcContacts.openContactsSelectorAsModal<IGroupMember>(loContactsSelectorParams, psTitle);
	// 									})
	// 								);
	// 						})
	// 					)
	// 			)
	// 		);
	// }

	// public extractIntervenantsFromSeancesTournee<T extends IGroupMember = IGroupMember>(paSeancesTournee: ISeanceTournee[]): T[] {
	// 	const laIntervenants: T[] = ArrayHelper.flat(
	// 		paSeancesTournee.map((poSeanceTournee: ISeanceTournee) =>
	// 			poSeanceTournee.intervenants.map((poHydratedContact: IHydratedGroupMember) => poHydratedContact.groupMember))
	// 	) as any as T[];

	// 	return ArrayHelper.unique(laIntervenants, (poIntervenant: T) => poIntervenant._id);
	// }

	/** Récupère tous les intervenants des séances.
	 * @param paSeances
	 */
	// public getIntervenants(paSeances: ISeance[]): Observable<IIdelizyContact[]> {
	// 	return defer(() => {
	// 		return of(ArrayHelper.flat(paSeances
	// 			.filter((poSeance: Seance) => ArrayHelper.hasElements(poSeance.intervenantIds))
	// 			.map((poSeance: Seance) => poSeance.intervenantIds)));
	// 	})
	// 		.pipe(mergeMap((paIntervenantIds: string[]) => this.isvcContacts.getContactsByIds(paIntervenantIds)));
	// }

	// public applyNewIntervenants(paSeances: Seance[], paNewIntervenants: IGroupMember[]): void;
	// public applyNewIntervenants(paSeancesTournees: ISeanceTournee[], paNewIntervenants: IGroupMember[]): void;
	// public applyNewIntervenants(poSeance: Seance, paNewIntervenants: IGroupMember[]): void;
	// public applyNewIntervenants(poSeanceTournee: ISeanceTournee, paNewIntervenants: IGroupMember[]): void;
	// public applyNewIntervenants(poSeance: ISeanceTournee | Seance, paNewIntervenants: IGroupMember[]): void;
	// public applyNewIntervenants(poData: Seance | ISeanceTournee | Seance[] | ISeanceTournee[], paNewIntervenants: IGroupMember[]): void {
	// 	if (poData instanceof Array)
	// 		poData.forEach((poItem: Seance | ISeanceTournee) => this.applyNewIntervenants(poItem, paNewIntervenants));
	// 	else if (poData instanceof Seance)
	// 		poData.intervenantIds = paNewIntervenants.map((poIntervenant: IIdelizyContact) => poIntervenant._id);
	// 	else {
	// 		poData.intervenants = paNewIntervenants.map((poIntervenant: IIdelizyContact) =>
	// 			({ avatar: AvatarHelper.createAvatarFromContact(poIntervenant, EAvatarSize.big), groupMember: poIntervenant })
	// 		);
	// 	}
	// }

	//#endregion

	/** Récupère un traitement depuis un identifiant de séance.
	 * @param poSeance Séance dont on veut récupérer le traitement associé.
	 */
	// public getTraitementBySeance(poSeance: Seance): Observable<Traitement> {
	// 	return this.isvcStore.getOne<ITraitement>({
	// 		role: EDatabaseRole.workspace,
	// 		viewParams: {
	// 			key: poSeance.traitementId,
	// 			include_docs: true
	// 		}
	// 	}).pipe(
	// 		map((poTraitement: ITraitement) => Traitement.createFromData(poTraitement))
	// 	);
	// }

	// public groupSeancesByTraitementId(paSeances: Seance[]): Map<string, Seance[]> {
	// 	return ArrayHelper.groupBy(paSeances, (poSeance: Seance) => poSeance.traitementId);
	// }

	/** Applique un nouvel acte à un tableau de séances.
	 * @param poData
	 * @param poOldActe
	 * @param poNewActe
	 * @returns
	 */
	// public applyNewActe(poData: Seance | Seance[], poOldActe: Acte, poNewActe: Acte): void {
	// 	coerceArray(poData).forEach((poSeance: Seance) =>
	// 		ArrayHelper.replaceElementByFinder(poSeance.actes, (poActe: Acte) => poActe.guid === poOldActe.guid, poNewActe, false)
	// 	);
	// }

	/** Rècupère les conflits entre des séances et des plannings.
	 * @param paSeances
	 * @param paPlanningsRH
	 * @returns
	 */
	// public getConflicts(paSeances: Seance[], poPlanningRH: IPlanningRH | IPlanningRH[]): ISeancesConflicts {
	// 	const laPlanningsRH: IPlanningRH[] = !poPlanningRH ? [] : coerceArray(poPlanningRH);
	// 	const loConflicts: ISeancesConflicts = {
	// 		noIntervenants: [],
	// 		noPlanning: [],
	// 		multipleIntervenants: []
	// 	};

	// 	paSeances.filter((poSeance: Seance) => !poSeance.isCanceled).forEach((poSeance: Seance) => {
	// 		const loPlanningRH: IPlanningRH = PlanningRHService.getMatchingPlanning(poSeance.startDate, laPlanningsRH);

	// 		if (!loPlanningRH)
	// 			loConflicts.noPlanning.push(poSeance);

	// 		if (!ArrayHelper.hasElements(poSeance.intervenantIds) || ArrayHelper.areAllValuesEmpty(poSeance.intervenantIds))
	// 			loConflicts.noIntervenants.push(poSeance);
	// 		else if (poSeance.intervenantIds.length > 1 || poSeance.intervenantIds.some((psIntervenantId: string) => IdHelper.hasPrefixId(psIntervenantId, EPrefix.group)))
	// 			loConflicts.multipleIntervenants.push(poSeance);
	// 	});

	// 	return loConflicts;
	// }

	/** Ajoute un acte dans une séance uniquement.
	 * @param poSeance
	 * @param poTraitement
	 * @param poActe
	 */
	// public addActe(poSeance: Seance, poTraitement: Traitement, poActe: Acte): void {
	// 	poActe.recurrences.push(
	// 		new Recurrence({ dayRepetitions: [new HoursMinutesRepetition({ hours: poSeance.startDate.getHours(), minutes: poSeance.startDate.getMinutes() })], durationType: EDuree.dates, durationValue: [poSeance.startDate] })
	// 	);

	// 	if (poTraitement.actes.length !== ArrayHelper.pushIfNotPresent(poTraitement.actes, poActe, (poTraitementActe: Acte) => poActe.guid === poTraitementActe.guid))
	// 		StoreHelper.makeDocumentDirty(poTraitement);

	// 	ArrayHelper.pushIfNotPresent(poSeance.actes, poActe, (poSeanceActe: Acte) => poActe.guid === poSeanceActe.guid);
	// }

	/** Créé les documents de facturation pour chaque séance.
	 * @param poSeance Séance à facturer.
	 */
	public createSeancePrestation(traitement: Traitement, seance: Seance, observation?: string): IdlPrestation {
		const prestationLines: IIdlPrestationLine[] = [];

		seance.actes.forEach((acte: Acte) => {
			prestationLines.push(this.createPrestationLineFromActe(acte, seance.label, traitement._id));
		});

		seance.majorations?.forEach((majoration: Majoration) => {
			if (!majoration.disabled)
				prestationLines.push(this.createPrestationLineFromMajoration(majoration, seance.label, traitement._id));
		});

		seance.indemnites?.forEach((indemnite: Indemnite) => {
			if (!indemnite.disabled)
				prestationLines.push(this.createPrestationLineFromIndemnite(traitement, indemnite, seance.label, traitement._id));
		});

		const currentDate = new Date();
		return new IdlPrestation({
			_id: this.svcPrestation.buildId({ traitementId: seance.traitementId, customerIdOrPrefix: Traitement.extractPatientId(seance.traitementId), prestationDate: DateHelper.toUTCString(seance.startDate, EUTCAccuracy.minutes) } as IIdlPrestationIdBuilderParams),
			originalLines: prestationLines,
			lines: prestationLines,
			originalStatus: seance.status === EStatusSeance.canceled ? EPrestationStatus.canceled : EPrestationStatus.created,
			status: seance.status === EStatusSeance.canceled ? EPrestationStatus.canceled : EPrestationStatus.created,
			originalVendorId: ContactsService.getUserContactId(),
			vendorId: ContactsService.getUserContactId(),
			authorId: seance.infirmierId,
			createDate: currentDate,
			lastUpdateDate: currentDate,
			place: seance.place,
			observation: observation
		});
	}

	public createPrestationLineFromActe(poActe: Acte, psSeanceLabel: string, psOrdonnanceId: string = ""): IdlPrestationLine {
		return new IdlPrestationLine({
			ref: poActe._id,
			price: +NumberHelper.round(poActe.initialPrice * poActe.priceCoefficient, 2),
			discountRate: Math.abs(1 - poActe.taxAllowance),
			extraCharge: poActe.extraCharge,
			description: poActe.description,
			category: EIdlPrestationLineCategory.actes,
			group: psSeanceLabel,
			coefficient: poActe.priceCoefficient,
			lettreCle: poActe.keyLetters,
			ordonnanceId: psOrdonnanceId,
			isAldExonerante: poActe.isAldExonerante
		});
	}

	public createPrestationLineFromMajoration(poMajoration: Majoration, psSeanceLabel: string, psOrdonnanceId: string = ""): IdlPrestationLine {
		return new IdlPrestationLine({
			ref: poMajoration.type,
			lettreCle: poMajoration.type,
			price: poMajoration.price,
			description: poMajoration.description,
			category: EIdlPrestationLineCategory.majorations,
			group: psSeanceLabel,
			ordonnanceId: psOrdonnanceId
		});
	}

	public createPrestationLineFromIndemnite(poTraitement: Traitement, poIndemnite: Indemnite, psSeanceLabel: string, psOrdonnanceId: string = ""): IdlPrestationLine {
		const lnDistance: number = poIndemnite.isIKType ? this.isvcIndemnite.calculateAbattementDistance(poTraitement.deplacement) : 1;

		return new IdlPrestationLine({
			ref: poIndemnite.type,
			lettreCle: poIndemnite.type,
			price: +NumberHelper.round(poIndemnite.price / lnDistance, 2),
			quantity: lnDistance,
			description: poIndemnite.description,
			category: EIdlPrestationLineCategory.indemnites,
			group: psSeanceLabel,
			ordonnanceId: psOrdonnanceId
		});
	}

	/** Récupère les prestations d'un traitement.
	 * @param poTraitement
	 */
	// public getTraitementPrestations(poTraitement: Traitement): Observable<Prestation[]> {
	// 	return this.isvcPrestation.getPrestations({ traitementId: poTraitement._id, customerIdOrPrefix: poTraitement.patientId } as IIdlPrestationIdBuilderParams);
	// }

	// private filterPrestationsByTraitement(paPrestations: Prestation[], poTraitement: Traitement): Prestation[] {
	// 	const lsTraitementGuid: string = IdHelper.getLastGuidFromId(poTraitement._id);
	// 	return paPrestations.filter((poPrestation: Prestation) => poPrestation._id.includes(lsTraitementGuid));
	// }

	//#endregion
}
