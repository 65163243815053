import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ArrayHelper } from '@osapp/helpers';
import { ModalComponentBase } from '@osapp/modules/modal/model/ModalComponentBase';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { PlatformService } from '@osapp/services/platform.service';


@Component({
	templateUrl: './convention-modal.component.html',
	styleUrls: ['./convention-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConventionModalComponent extends ModalComponentBase<number> implements OnInit {

	public readonly conventionList: Array<ISelectOption<number>> = [
	];

	public conventionSelected : number;

	//#endregion

	//#region METHODS

	constructor(
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetectorRef);
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this.conventionSelected = ArrayHelper.getFirstElement(this.conventionList).value;

		this.detectChanges();	}

	/** @override */
	public close(): Promise<boolean> {
		return super.close(this.conventionSelected);
	}
	


	public onConventionChanged(conv: number) : void{
		this.conventionSelected = conv;
	}
}
