import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IdHelper } from '@osapp/helpers';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { EPrefix } from '@osapp/model/EPrefix';
import { ModalComponentBase } from '@osapp/modules/modal';
import { PlatformService } from '@osapp/services/platform.service';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { C_CONSTANTES_PREFIX, C_INJECTIONS_PREFIX } from '../../../../../app/app.constants';
import { IPatient } from '../../../model/IPatient';
import { IConstantesModalParams } from '../../../model/constantes/IConstantesModalParams';
import { ISurveillances } from '../../../model/constantes/ISurveillances';
import { InitConstantesError } from '../../../model/constantes/InitConstantesError';
import { ConstantesService } from '../../../services/constantes.service';

@Component({
	templateUrl: './constantes-modal.component.html',
	styleUrls: ['./constantes-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConstantesModalComponent extends ModalComponentBase<never> implements OnInit, IConstantesModalParams {

	//#region PROPERTIES

	/** @implements */
	@Input() public surveillances?: ISurveillances;
	/** @implements */
	@Input() public patientModel?: IPatient;
	/** @implements */
	/** @implements */
	@Input() public surveillancesPrefix?: EPrefix;

	/** Titre de la modale. */
	public title: string;
	/** Indique si on est en mode création. */
	public isCreation: boolean;

	//#endregion

	//#region METHODS

	constructor(
		private isvcConstantes: ConstantesService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetectorRef);
	}

	public ngOnInit(): void {
		super.ngOnInit();
		let lsPrefix: EPrefix = IdHelper.getPrefixFromId(this.surveillances?._id);
		lsPrefix = !StringHelper.isBlank(lsPrefix) ? lsPrefix : this.surveillancesPrefix ?? C_CONSTANTES_PREFIX;

		if (this.surveillances) // Si un objet surveillances est renseigné, mode édition.
			this.title = lsPrefix === C_CONSTANTES_PREFIX ? ConstantesService.C_EDIT_CONSTANTES_TITLE : ConstantesService.C_EDIT_INJECTIONS_TITLE;
		if (StringHelper.isBlank(this.title)) { // Le titre n'a pas été initialisé donc mode création.
			this.title = lsPrefix === C_CONSTANTES_PREFIX ? ConstantesService.C_CREATE_CONSTANTES_TITLE : ConstantesService.C_CREATE_INJECTIONS_TITLE;
			this.isCreation = true;

			if (!this.patientModel) // Si le modèle du patient n'est pas renseigné pour une création => erreur car obligatoire.
				throw new InitConstantesError();
			else {
				switch (lsPrefix) {
					case C_CONSTANTES_PREFIX:
						this.surveillances = this.isvcConstantes.createAConstantes(this.patientModel);
						break;
					case C_INJECTIONS_PREFIX:
						this.surveillances = this.isvcConstantes.createAnInjections(this.patientModel);
						break;
				};
			}
		}
	}

	/** Enregistre l'objet constantes. */
	public saveConstantes(): void {
		this.isvcConstantes.saveSurveillances(this.surveillances)
			.pipe(
				// delay(250), //todo : https://dev.azure.com/calaosoft/osapp-project/_workitems/edit/3499
				mergeMap(_ => this.close()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	//#endregion

}