import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IdHelper } from '@osapp/helpers';
import { EPrefix, IContact, UserData } from '@osapp/model';
import { ContactsService } from '@osapp/services';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IPatient } from '../../../../../modules/patients/model/IPatient';
import { ITransmissionRapport } from '../../../../../modules/patients/model/ITransmissionRapport';
import { PatientsService } from '../../../../../modules/patients/services/patients.service';
import { ICommentaireTransmission, } from '../../../../models/ICommentaireTransmission';
import { DrawerPopoverService } from '../../../shared/services/drawer-popover.service';
import { PanneauService } from '../../../shared/services/panneau.service';
import { MenuTransmissionComponent } from '../menu-transmission/menu-transmission.component';
import { PanneauCommentTransmissionComponent } from '../panneau-comment-transmission/panneau-comment-transmission.component';

@Component({
  selector: 'di-carte-transmission',
  templateUrl: './carte-transmission.component.html',
  styleUrls: ['./carte-transmission.component.scss'],
})
export class CarteTransmissionComponent implements OnInit {

	private subscriptions: Subscription[] = [];


  constructor(
		private svcContact : ContactsService,
		private svcPatient : PatientsService,
		private svcPanneau: PanneauService,
		private svcDrawerPopover : DrawerPopoverService
	) {}

	public isMe? = false;

	@Input() commentaire? : ICommentaireTransmission ;
	@Input() transmission? : ITransmissionRapport;
	@Input() isMobileView : boolean = false;
  @Input() isComment = false;
  @Input() isNew = false;

	@Output() ondeleteTransmission = new EventEmitter<void>();


	@ViewChild('menuTransmission', { static: false }) menuTransmission!: ElementRef<HTMLDivElement>;



  dividerSize = 'small';
  avatarSize = 'small';
  boutonIconeSize = 'large';

	public contact : IContact;
  public patient: IPatient;
	public patientId : string;
	public contactId : string ;
	public showPopover: boolean= false;

	ngOnInit() {

		this.subscriptions.push(this.svcPanneau.closePanel$.subscribe(() => {
		}));
		if(this.transmission){
			if(!this.isComment)
				this.initErgoValue();
			this.patientId = this.transmission.patientId ?? this.getIdPatient(this.transmission._id);
			this.svcPatient.getPatient(this.patientId).pipe(
				tap((patient:IPatient) => {
					this.patient = patient;
					this.getContact(this.transmission.authorPath);
				})
			).subscribe();
		}
		
		if(this.commentaire)
		{
			this.getContact(this.commentaire.auteurId);
		}
	}


	private initErgoValue(): void {
		if(!this.transmission.titre && !this.transmission.description){
			this.transmission.titre = this.transmission.transmissionType;
			this.transmission.description = [
				this.transmission.problem,
				this.transmission.data,
				this.transmission.interventions,
				this.transmission.results
			].filter(value => value).join(', ');
		}
		if(!this.transmission.dateLastModification)
			this.transmission.dateLastModification = this.transmission.createdDate;
		if(!this.transmission.patientId)
			this.transmission.patientId = this.getIdPatient(this.transmission._id);
		if(!this.transmission.contactId)
			this.transmission.contactId = this.getIdContact(this.transmission.authorPath);
  }

	private getContact(id :string) {
		this.contactId = this.getIdContact(id);
		this.svcContact.getContact(this.contactId).pipe(
			tap((contact: IContact) => {
				this.contact = contact;
			})
		).subscribe();
	}

	public getIsMeMaker(): boolean {
		if(this.isComment)
			return this.commentaire.auteurId === UserData.current._id.replace(EPrefix.user,EPrefix.contact);
		else
			return this.transmission.contactId === UserData.current._id.replace(EPrefix.user,EPrefix.contact);

	}

  getAvatarColor() {
		return this.contact.avatarCouleur ?? 'CouleurPrimaire'
  }

	public getIdContact(string:string) : string{
		return `${EPrefix.contact}${IdHelper.getGuidFromId(string, EPrefix.contact)}`;
	}

	public getIdPatient(string:string) : string{
		return `${EPrefix.patient}${IdHelper.getGuidFromId(string, EPrefix.patient).split(IdHelper.C_ID_SEPARATOR)[0]}`;
	}

	public getAdressePatient(pat :IPatient) :string{
		const addressParts: string[] = [];
    if (pat.street) {
      addressParts.push(pat.street);
    }
		if (pat.zipCode) {
      addressParts.push(pat.zipCode);
    }
    if (pat.city) {
      addressParts.push(pat.city);
    }

    return addressParts.join(' ');	
	}

	public handleCommentClick()
	{
		this.svcPanneau.open(`${this.patient.firstName} ${this.patient.lastName}`,PanneauCommentTransmissionComponent,{transmission: this.transmission})
	}

	public openMenu(): void{
		if(this.getIsMeMaker())
		{
			this.showPopover =true;
			this.svcDrawerPopover.open("","50%",this.menuTransmission?.nativeElement, MenuTransmissionComponent, {transmission:this.transmission,commentaire:this.commentaire,patient:this.patient,anchorElement: this.menuTransmission}, ()=>this.showPopover = false)
  	}
	}

  public closeMenu(): void{
    this.showPopover = false;
    this.svcDrawerPopover.close();
  }

	// public getFirstUpper(string :string ) : string {
	// 	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
	// }
}
