import { Injectable } from '@angular/core';
import { WorkspaceService } from '@osapp/services/workspace.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IIdelizyWorkspace } from '../../workspaces/model/IIdelizyWorkspace';

@Injectable()
export class FinessService {

	//#region METHODS

	constructor(
		private isvcWorkspace: WorkspaceService
	) { }

	public getWorkspaceFiness(psWorkspaceId: string): Observable<string> {
		return this.isvcWorkspace.getWorkspaceDocument<IIdelizyWorkspace>(psWorkspaceId)
			.pipe(map((poWorkspaceDocument: IIdelizyWorkspace) => poWorkspaceDocument?.finess));
	}

	//#endregion

}
