import { Expose } from 'class-transformer';
import { ModelResolver } from '../model-resolver';
import { IConstructor } from '../models/iconstructor';

/** Permet de résoudre automatiquement le type réel d'une donnée.
 *  ### Il faut gérer un constructeur sans paramètre.
 * @param pfBaseType 
 */
export function ResolveModel(poBaseType: IConstructor): PropertyDecorator {
	return function (poTarget: Object, psPropertyKey: string, poDescriptor?: TypedPropertyDescriptor<any>) {
		Expose()(poTarget, psPropertyKey); // Par défaut expose la valeur car on la transforme en getter/setter		

		const lfGet = function () { return this[`#${psPropertyKey}`]; };
		const lfOriginalSet = poDescriptor?.set;
		const lfSet = function (poNewVal: any) {
			let loValue: any;

			if (poNewVal instanceof Array)
				loValue = poNewVal.map((poVal: any) => ModelResolver.toClass(poVal, poBaseType));
			else
				loValue = ModelResolver.toClass(poNewVal, poBaseType);

			this[`#${psPropertyKey}`] = loValue;
			lfOriginalSet?.apply(this, [loValue]);
		};

		if (lfOriginalSet)
			poDescriptor.set = lfSet;

		// On surcharge la propriété avec les méthodes get et set custom si ce n'était pas déjà une property, sinon le descriptor fera la surcharge.
		if (!lfOriginalSet)
			Object.defineProperty(poTarget, psPropertyKey, { get: lfGet, set: lfSet });

		return poDescriptor;
	};
};
