import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormListComponent } from '@osapp/components/forms/formList/formList.component';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { IFormListEvent } from '@osapp/model/forms/IFormListEvent';
import { FavoritesService } from '@osapp/modules/preferences/favorites/services/favorites.service';
import { RecentsService } from '@osapp/modules/preferences/recents/services/recents.service';
import { ApplicationService } from '@osapp/services';
import { FormsService } from '@osapp/services/forms.service';
import { PatternResolverService } from '@osapp/services/pattern-resolver.service';
import { concatMap, filter, takeUntil, tap } from 'rxjs/operators';
import { Acte } from '../../../model/Acte';
import { ISearchActe } from '../../../model/ISearchActe';
import { ISearchActeParams } from '../../../model/ISearchActeParams';
import { IdlApplicationService } from '../../../services/idlApplicationService.service';
import { ActesService } from '../actes.service';

@Component({
	templateUrl: './search-acte.component.html'
})
export class SearchActeComponent extends ComponentBase implements OnInit {

	//#region FIELDS

	/** Service partagé de l'application. */
	private isvcApplication: IdlApplicationService;

	//#endregion

	//#region PROPERTIES

	public idFormDesc: string;
	public onItemClicked: (poSearchActe: ISearchActe) => void = (poSearchActe: ISearchActe) => this.onActClicked(poSearchActe);

	@Input() public params: ISearchActeParams;

	//#endregion

	//#region METHODS

	constructor(
		psvcApplication: ApplicationService,
		private ioModalCtrl: ModalController,
		private isvcActes: ActesService,
		private isvcPatternResolver: PatternResolverService,
		private isvcForms: FormsService,
		private isvcFavorites: FavoritesService,
		private isvcRecents: RecentsService
	) {
		super();
		this.isvcApplication = psvcApplication as IdlApplicationService;
		this.idFormDesc = "formDesc_traitement_rechActes";
	}

	public ngOnInit(): void {
		this.isvcPatternResolver.setCustomSharedService(this.isvcApplication);

		this.isvcForms.onFormListEvent()
			.pipe(
				filter((poEvent: IFormListEvent) => poEvent.id === FormListComponent.C_FAVORITES_EVENT_ID),
				concatMap((poEvent: IFormListEvent) => this.isvcFavorites.toggle(poEvent.model)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Gestion du clic sur un acte.
	 * @param poSearchActe
	 */
	private onActClicked(poSearchActe: ISearchActe): void {
		const loActe: Acte = this.isvcActes.transformSearchActeToActe(poSearchActe);

		this.isvcRecents.add(loActe._id)
			.pipe(
				tap(() => this.ioModalCtrl.dismiss(loActe)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	//#endregion
}