import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LightingUpAngularModule } from 'lighting-up-angular';
import { AppInfoPage } from './app-info.page';

@NgModule({
	declarations: [AppInfoPage],
	imports: [CommonModule, LightingUpAngularModule],
	exports: [AppInfoPage]
})
export class AppInfoModule { }
