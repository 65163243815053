import { EBarElementAction } from './EBarElementAction';
import { IBarElement } from './IBarElement';

export interface IBarElementEvent {

	/** Identifiant du composant associé à la toolbar, pour que plusieurs composants puissent ajouter leur propre bouton. */
	id?: string;
	/** Type de l'action. */
	action?: EBarElementAction;
  /** Tableau des éléments (boutons) à ajouter dans la 'IBarElement' (toolbar par exemple). Si plusieurs éléments au même endroit (en-bas à droite par exemple)
   * alors les éléments s'afficheront du premier au dernier de gauche à droite respectivement. */
	elements?: IBarElement[];
}