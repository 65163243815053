import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertButton } from '@ionic/core';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { UserData } from '@osapp/model/application/UserData';
import { IConversation } from '@osapp/model/conversation/IConversation';
import { IGetConversationOptions } from '@osapp/model/conversation/IGetConversationOptions';
import { IOpenConversationOptions } from '@osapp/model/conversation/IOpenConversationOptions';
import { ContactsService } from '@osapp/services/contacts.service';
import { ConversationService } from '@osapp/services/conversation.service';
import { EntityLinkService } from '@osapp/services/entityLink.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { Observable } from 'rxjs';
import { map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { IActionButtonConversationParams } from '../../../model/action-buttons/IActionButtonConversationParams';
import { IPatient } from '../../../model/IPatient';
import { IdlActionButtonService } from '../../../services/idl-action-button.service';
import { PatientsService } from '../../../services/patients.service';

@Component({
	selector: "idl-action-button-conversation",
	templateUrl: './idl-action-button-conversation.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdlActionButtonConversationComponent extends ComponentBase implements IActionButtonConversationParams {

	//#region FIELDS

	/** Phrase indiquant que des conversations existent pour un patient. */
	private static readonly C_PATIENT_CONVERSATION_SENTENCE = "Il existe des conversations au sujet de ce patient.\nSélectionnez une conversation existante ou créez-en une nouvelle.";

	/** Émetteur d'événement pour indique si l'action s'est passée correctement `true` ou s'il y a eu un problème (conversation existante par exempole) `false`. */
	@Output("onActionDone") private readonly moOnActionDone = new EventEmitter<boolean>();

	//#endregion

	//#region PROPERTIES

	/** @implements */
	@Input() public label?: string;

	private moPatient: IPatient;
	/** @implements */
	public get patient(): IPatient { return this.moPatient; }
	@Input() public set patient(poValue: IPatient) {
		if (poValue !== this.moPatient) {
			this.moPatient = poValue;
			this.msPatientId = poValue._id;
			this.detectChanges();
		}
	}

	private msPatientId: string;
	/** @implements */
	public get patientId(): string { return this.msPatientId; }
	@Input() public set patientId(psValue: string) {
		if (psValue !== this.msPatientId) {
			this.msPatientId = psValue;

			this.isvcPatients.getPatient(psValue)
				.pipe(
					tap((poPatient: IPatient) => {
						this.patient = poPatient;
						this.detectChanges();
					})
				)
				.subscribe();
		}
	}

	private mbClickDisabled: boolean;
	/** @implements */
	public get clickDisabled(): boolean { return this.mbClickDisabled; }
	@Input() public set clickDisabled(pbClickDisabled: boolean) {
		if (pbClickDisabled !== this.mbClickDisabled) {
			this.mbClickDisabled = pbClickDisabled;
			this.detectChanges();
		}
	}

	//#endregion

	//#region METHODS

	constructor(
		private isvcConversation: ConversationService,
		private isvcEntityLink: EntityLinkService,
		private isvcActionButton: IdlActionButtonService,
		private isvcPatients: PatientsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poChangeDetectorRef);
	}

	/** @override Ouvre un composant. */
	public action(): void {
		if (!this.clickDisabled)
			this.createOrOpenConversation().subscribe();
	}

	/** Crée ou ouvre une conversation à propos d'un patient.
	 * @returns `Observable<true>` si la création/ouverture s'est bien passée ; `Observable<fals>` si un problème est survenu.
	 */
	public createOrOpenConversation(): Observable<boolean> {
		const lsUserContactId: string = ContactsService.getContactIdFromUserId(UserData.current.name);

		return this.isvcConversation.getConversations(lsUserContactId, { links: [this.patient._id] } as IGetConversationOptions)
			.pipe(
				mergeMap((paLinkedConversations: IConversation[]) => {
					const loOptions: IOpenConversationOptions = {
						routeToConversationAfterCreation: true,
						searchForMatchingConversation: false,
						linkedEntities: [this.isvcEntityLink.buildEntity(this.patient)]
					};

					if (ArrayHelper.hasElements(paLinkedConversations)) {
						const laButtons: AlertButton[] = [
							{
								text: "Annuler",
								role: UiMessageService.C_CANCEL_ROLE
							},
							{
								text: "Créer une nouvelle conversation",
								handler: () => this.isvcConversation.createOrOpenConversation(lsUserContactId, loOptions).subscribe()
							}
						];

						return this.isvcActionButton.warningCreationFromActionButton(IdlActionButtonConversationComponent.C_PATIENT_CONVERSATION_SENTENCE, laButtons);
					}
					else {
						return this.isvcConversation.createOrOpenConversation(lsUserContactId, loOptions)
							.pipe(map((poConversation: IConversation) => !!poConversation));
					}
				}),
				tap((pbResult: boolean) => this.moOnActionDone.emit(pbResult)),
				take(1),
				takeUntil(this.destroyed$)
			);
	}

	//#endregion
}