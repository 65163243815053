import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { SelectFieldComponent } from '@osapp/components/forms/form/customFields/select-field.component';
import { FormsService } from '@osapp/services/forms.service';
import { ESpeExerciceCondition } from '../../model/ESpeExerciceCondition';

@Component({
	selector: 'idl-spe-exercice-condition-field',
	templateUrl: './spe-exercice-condition-field.component.html',
	styleUrls: ['./spe-exercice-condition-field.component.scss'],
})
export class SpeExerciceConditionFieldComponent extends SelectFieldComponent implements OnInit, OnDestroy {

	//#region FIELDS

	private readonly C_SPE_EXERCICE_CONDITION_OPTIONS: { label: string; value: number; }[] = [
    { label: "Médecine générale", value: ESpeExerciceCondition.MedecineGenerale },
    { label: "Anesthésie-Réanimation", value: ESpeExerciceCondition.AnesthesieReanimation },
    { label: "Cardiologie", value: ESpeExerciceCondition.Cardiologie },
    { label: "Chirurgie générale", value: ESpeExerciceCondition.ChirurgieGenerale },
    { label: "Dermatologie et Vénéréologie", value: ESpeExerciceCondition.DermatologieVenereologie },
    { label: "Radiologie", value: ESpeExerciceCondition.Radiologie },
    { label: "Gynécologie obstétrique", value: ESpeExerciceCondition.GynecologieObstetrique },
    { label: "Gastro-Entérologie et Hépathologie", value: ESpeExerciceCondition.GastroEnterologieHepathologie },
    { label: "Médecine interne", value: ESpeExerciceCondition.MedecineInterne },
    { label: "Neurochirurgie", value: ESpeExerciceCondition.Neurochirurgie },
    { label: "Oto-Rhino-Laryngologie", value: ESpeExerciceCondition.OtoRhinoLaryngologie },
    { label: "Pédiatrie", value: ESpeExerciceCondition.Pediatrie },
    { label: "Pneumologie", value: ESpeExerciceCondition.Pneumologie },
    { label: "Rhumatologie", value: ESpeExerciceCondition.Rhumatologie },
    { label: "Ophtalmologie", value: ESpeExerciceCondition.Ophtalmologie },
    { label: "Chirurgie urologique", value: ESpeExerciceCondition.ChirurgieUrologique },
    { label: "Neuropsychiatrie", value: ESpeExerciceCondition.Neuropsychiatrie },
    { label: "Stomatologie", value: ESpeExerciceCondition.Stomatologie },
    { label: "Chirurgien dentiste", value: ESpeExerciceCondition.ChirurgienDentiste },
    { label: "Réanimation médicale", value: ESpeExerciceCondition.ReanimationMedicale },
    { label: "Sage-femme", value: ESpeExerciceCondition.SageFemme },
    { label: "Spécialiste en Médecine générale avec diplôme", value: ESpeExerciceCondition.MedecineGeneraleAvecDiplome },
    { label: "Spécialiste en Médecine générale reconnu par l’ordre", value: ESpeExerciceCondition.MedecineGeneraleReconnuParOrdre },
    { label: "Infirmier", value: ESpeExerciceCondition.Infirmier },
    { label: "Masseur Kinésithérapeute", value: ESpeExerciceCondition.MasseurKinesitherapeute },
    { label: "Pédicure Podologue", value: ESpeExerciceCondition.PedicurePodologue },
    { label: "Orthophoniste", value: ESpeExerciceCondition.Orthophoniste },
    { label: "Orthoptiste", value: ESpeExerciceCondition.Orthoptiste },
    { label: "Laboratoire d’analyses médicales", value: ESpeExerciceCondition.LaboratoireAnalysesMedicales },
    { label: "Rééducation Réadaptation Fonctionnelle", value: ESpeExerciceCondition.ReeducationReadaptationFonctionnelle },
    { label: "Neurologie", value: ESpeExerciceCondition.Neurologie },
    { label: "Psychiatrie", value: ESpeExerciceCondition.Psychiatrie },
    { label: "Gériatrie", value: ESpeExerciceCondition.Geriatrie },
    { label: "Néphrologie", value: ESpeExerciceCondition.Nephrologie },
    { label: "Chirurgien dentiste spécialité O.D.F.", value: ESpeExerciceCondition.ChirurgienDentisteSpecialiteODF },
    { label: "Anatomo-Cyto-Pathologie", value: ESpeExerciceCondition.AnatomoCytoPathologie },
    { label: "Médecin biologiste", value: ESpeExerciceCondition.MedecinBiologiste },
    { label: "Laboratoire Polyvalent", value: ESpeExerciceCondition.LaboratoirePolyvalent },
    { label: "Laboratoire d’anatomo-cyto-pathologie", value: ESpeExerciceCondition.LaboratoireAnatomoCytoPathologie },
    { label: "Chirurgie orthopédique et traumatologie", value: ESpeExerciceCondition.ChirurgieOrthopediqueTraumatologie },
    { label: "Endocrinologie, et Métabolisme", value: ESpeExerciceCondition.EndocrinologieMetabolisme },
    { label: "Chirurgie infantile", value: ESpeExerciceCondition.ChirurgieInfantile },
    { label: "Chirurgie maxillo-faciale", value: ESpeExerciceCondition.ChirurgieMaxilloFaciale },
    { label: "Chirurgie Maxillo-faciale, et stomatologie", value: ESpeExerciceCondition.ChirurgieMaxilloFacialeStomatologie },
    { label: "Chirurgie plastique reconstructrice et esthétique", value: ESpeExerciceCondition.ChirurgiePlastiqueReconstructriceEsthetique },
    { label: "Chirurgie thoracique et cardio-vasculaire", value: ESpeExerciceCondition.ChirurgieThoraciqueCardioVasculaire },
    { label: "Chirurgie vasculaire", value: ESpeExerciceCondition.ChirurgieVasculaire },
    { label: "Chirurgie viscérale et digestive", value: ESpeExerciceCondition.ChirurgieVisceraleDigestive },
    { label: "Pharmacie d’officine", value: ESpeExerciceCondition.PharmacieOfficine },
    { label: "Pharmacie mutualiste", value: ESpeExerciceCondition.PharmacieMutualiste },
    { label: "Chirurgien Dentiste spécialité C.O.", value: ESpeExerciceCondition.ChirurgienDentisteSpecialiteCO },
    { label: "Chirurgien Dentiste spécialité M.B.D.", value: ESpeExerciceCondition.ChirurgienDentisteSpecialiteMBD },
    { label: "Prestataire de type société", value: ESpeExerciceCondition.PrestataireTypeSociete },
    { label: "Prestataire de type artisan", value: ESpeExerciceCondition.PrestataireTypeArtisan },
    { label: "Prestataire de type association", value: ESpeExerciceCondition.PrestataireTypeAssociation },
    { label: "Orthésistes", value: ESpeExerciceCondition.Orthesistes },
    { label: "Opticien", value: ESpeExerciceCondition.Opticien },
    { label: "Audioprothésistes", value: ESpeExerciceCondition.Audioprothesistes },
    { label: "Epithésiste Oculariste", value: ESpeExerciceCondition.EpithesisteOculariste },
    { label: "Podo-orthésistes", value: ESpeExerciceCondition.PodoOrthesistes },
    { label: "Orthoprothésistes", value: ESpeExerciceCondition.Orthoprothesistes },
    { label: "Chirurgie orale", value: ESpeExerciceCondition.ChirurgieOrale },
    { label: "Gynécologie médicale", value: ESpeExerciceCondition.GynecologieMedicale },
    { label: "Hématologie", value: ESpeExerciceCondition.Hematologie },
    { label: "Médecine nucléaire", value: ESpeExerciceCondition.MedecineNucleaire },
    { label: "Oncologie médicale", value: ESpeExerciceCondition.OncologieMedicale },
    { label: "Oncologie radiothérapique", value: ESpeExerciceCondition.OncologieRadiotherapique },
    { label: "Psychiatrie de l’enfant et de l’adolescent", value: ESpeExerciceCondition.PsychiatrieEnfantAdolescent },
    { label: "Radiothérapie", value: ESpeExerciceCondition.Radiotherapie },
    { label: "Obstétrique", value: ESpeExerciceCondition.Obstetrique },
    { label: "Génétique médicale", value: ESpeExerciceCondition.GenetiqueMedicale },
    { label: "Obstétrique et Gynécologie médicale", value: ESpeExerciceCondition.ObstetriqueGynecologieMedicale },
    { label: "Santé publique et médecine sociale", value: ESpeExerciceCondition.SantePubliqueMedecineSociale },
    { label: "Médecine des Maladies infectieuses et tropicales", value: ESpeExerciceCondition.MedecineMaladiesInfectieusesTropicales },
    { label: "Médecine légale et expertises médicales", value: ESpeExerciceCondition.MedecineLegaleExpertisesMedicales },
    { label: "Médecine d’urgence", value: ESpeExerciceCondition.MedecineUrgence },
    { label: "Médecine vasculaire", value: ESpeExerciceCondition.MedecineVasculaire },
    { label: "Allergologie", value: ESpeExerciceCondition.Allergologie },
    { label: "Infirmier exerçant en Pratiques Avancées (IPA)", value: ESpeExerciceCondition.InfirmierPratiquesAvancees }
];



	//#endregion

	//#region METHODS

	constructor(
		psvcForms: FormsService,
		/** Service de rafraîchissement de la vue. */
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	/** @override */
	public ngOnInit(): void {
		if (this.to.data)
			this.to.data.options = this.C_SPE_EXERCICE_CONDITION_OPTIONS;
		else
			this.to.data = { options: this.C_SPE_EXERCICE_CONDITION_OPTIONS };

		super.ngOnInit();
	}

	//#endregion

}
