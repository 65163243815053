import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ERouteUrlPart } from '@osapp/model/route/ERouteUrlPart';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Traitement } from '../../model/Traitement';
import { TraitementService } from '../../services/traitement.service';
import { ITraitementSlidePageParams } from './model/ITraitementSlidePageParams';

@Injectable({ providedIn: "root" })
export class TraitementResolver implements Resolve<Traitement> {

	//#region FIELDS

	private static readonly C_LOG_ID = "TRAIT.R::";

	//#endregion

	//#region METHODS

	constructor(
		private isvcTraitement: TraitementService,
		private ioRouter: Router,
		private isvcUiMessage: UiMessageService
	) { }

	public resolve(poRoute: ActivatedRouteSnapshot): Observable<Traitement> {
		if (ArrayHelper.getLastElement(poRoute.url).path === ERouteUrlPart.new) { // Navigation vers un nouveau traitement (création).
			const loExtrasStateParams: ITraitementSlidePageParams = this.ioRouter.getCurrentNavigation()?.extras?.state as ITraitementSlidePageParams;

			if (loExtrasStateParams) {
				return this.isvcTraitement.createTraitement(loExtrasStateParams.patient)
					.pipe(
						mergeMap((poTraitement: Traitement) => this.isvcTraitement.saveTraitement(poTraitement)
							.pipe(
								mergeMap(() => this.ioRouter.navigate(["/traitements", poTraitement._id, "edit"])),
								map(() => poTraitement)
							)),
						catchError((poError: any) => {
							const lsError = "Erreur lors de la création du traitement.";
							console.error(`${TraitementResolver.C_LOG_ID}${lsError}`, poError);
							this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Erreur", message: lsError }));
							return EMPTY;
						})
					)
			}
			else {
				const lsError = "Le patient est obligatoire lors de la création d'un traitement.";
				this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Erreur", message: lsError }));
				console.error(`TRAIT.R:: ${lsError}`);
				this.ioRouter.navigate(["/home"]);
				return EMPTY;
			}
		}
		else { // Navigation vers un traitement existant (édition ou visu).
			return this.isvcTraitement.getTraitement(poRoute.params.traitementId)
				.pipe(
					mergeMap((poTraitement: Traitement) => {
						if (poTraitement)
							return of(poTraitement);
						else {
							this.ioRouter.navigate([".."]);
							return EMPTY;
						}
					})
				);
		}
	}

	//#endregion

}