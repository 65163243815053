import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, filter, mergeMap, take } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { ObjectHelper } from '../../../helpers/objectHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { UserData } from '../../../model/application/UserData';
import { ConfigData } from '../../../model/config/ConfigData';
import { ILoggerConfig } from '../../../model/config/ILoggerConfig';
import { afterSubscribe } from '../../utils/rxjs/operators/after-subscribe';
import { ELogActionId } from '../models/ELogActionId';
import { ELogLevel } from '../models/ELogLevel';
import { ILogEntry } from '../models/ILogEntry';

interface ILogWriteParams {
	arguments: IArguments;
	level: ELogLevel;
	actionId?: ELogActionId;
	data?: any;
	error?: any;
	stateSuffix?: boolean;
}

interface IDefaultCloneData {
	defaultData: string;
}

/** Service d'interception des sorties vers la console Javascript :
 * [lien](http://www.damirscorner.com/blog/posts/20180119-SharingConsoleLogFromIonicApps.html).
 */
@Injectable({ providedIn: "root" })
export class LoggerService {

	//#region FIELDS

	/** Nombre maximum de stringification d'objets avant d'afficher `[Unknown]`.\
	 * Si sa valeur est importante, on aura accès à plus d'informations, mais le risque d'avoir une structure circulaire et d'arrêter la sérialisation augmente.
	 */
	private static readonly C_MAX_DISPLAYED_ATTRIBUTES = 100;
	private static readonly C_CONSOLE_INFO = console.info;
	private static readonly C_LOG_STORE_NAME = "logger_db";
	private static readonly C_LOG_OBJECT_NAME = "logs";
	private static readonly C_LOG_ID = "LOGGER.S::";

	private readonly moWriteLogSubject = new Subject<ILogWriteParams>();
	private readonly moLogEntriesSubject = new Subject<ILogEntry>();
	private readonly moIDBDatabaseSubject = new BehaviorSubject<IDBDatabase>(undefined);
	private readonly maLast100LogEntries: ILogEntry[] = [];

	/** Dernier ID de log utilisé : permet d'éviter les conflits d'id entre 2 logs très rapprochés. */
	private msLastLogId: string;

	//#endregion

	//#region PROPERTIES

	public static readonly C_FLUSH_LOG_INTERVAL_MS = 30000;
	public static readonly C_LOGS_DEFAULT_BUFFER_SIZE = 500;

	public get logEntries$(): Observable<ILogEntry> { return this.moLogEntriesSubject.asObservable(); }

	//#endregion

	//#region METHODS

	constructor(private readonly ioRouter: Router) {
		// On initialise la base de cache des logs.
		const loOpenDbRequest: IDBOpenDBRequest = indexedDB.open(LoggerService.C_LOG_STORE_NAME);

		loOpenDbRequest.onerror = (poEvent: Event) => alert((poEvent.target as IDBOpenDBRequest).error);
		loOpenDbRequest.onsuccess = (poEvent: Event) => this.moIDBDatabaseSubject.next((poEvent.target as IDBOpenDBRequest).result);

		loOpenDbRequest.onupgradeneeded = (poEvent: Event) => {
			const loDb: IDBDatabase = (poEvent.target as IDBOpenDBRequest).result;

			const loCreateTransaction: IDBTransaction = loDb.createObjectStore(LoggerService.C_LOG_OBJECT_NAME, { keyPath: "_id" }).transaction;
			loCreateTransaction.oncomplete = () => this.moIDBDatabaseSubject.next(loDb);
			loCreateTransaction.onerror = (poEvent: Event) => alert((poEvent.target as IDBTransaction).error);
		};

		// On se met en attente des demandes d'écriture en cache.
		this.moWriteLogSubject.asObservable()
			.pipe(
				concatMap((poWriteParams: ILogWriteParams) =>
					this.write(this.generateEntry(poWriteParams.arguments, poWriteParams.level, poWriteParams.actionId, poWriteParams.data, poWriteParams.error, poWriteParams.stateSuffix))
				)
			).subscribe(
				() => { },
				(poError: any) => console.error(`${LoggerService.C_LOG_ID}Crash du flux d'écriture des logs en cache.`, poError)
			);
	}

	public init(poConfig: ILoggerConfig): void {
		// On ne met en place l'interception des sorties de la console qu'à condition que :
		// - le paramétrage ait été correctement défini,
		// - au moins 1 niveau de logging est activé.
		if (poConfig && !StringHelper.isBlank(poConfig.databaseId) && ArrayHelper.hasElements(poConfig.levels))
			this.initConsoleInterceptor(poConfig.levels);
	}

	private initConsoleInterceptor(paLogLevels: ELogLevel[]): void {
		console.debug(`${LoggerService.C_LOG_ID}Setting console logs interception for levels `, paLogLevels);

		paLogLevels.forEach((peLevel: ELogLevel) => this.overrideConsoleMethod(peLevel));
	}

	/** Surcharge une méthode `console.xxx()`.
	 * @param peLevel Niveau du log dont il faut surcharger la méthode associée.
	 * @param pfOriginalConsoleMethod Méthode originale à utiliser si renseignée.
	 */
	private overrideConsoleMethod(peLevel: ELogLevel, pfOriginalConsoleMethod?: (...data: any[]) => void): void {
		const lfOriginalMethod: (...data: any[]) => void | ((message?: any, ...optionalParams: any[]) => void) = pfOriginalConsoleMethod ?
			pfOriginalConsoleMethod : console[peLevel];
		const lsvcLogger = this;

		// `function` permet de pouvoir accéder à la variable `arguments` qui n'est pas dispo dans les méthodes/lambdas, mais empêche l'accès à `this`.
		console[peLevel] = function () {
			lsvcLogger.moWriteLogSubject.next({ arguments: arguments, level: peLevel });
			lfOriginalMethod.apply ? lfOriginalMethod.apply(console, arguments) : lfOriginalMethod(lsvcLogger.generateMessage(arguments));
		};
	}

	/** Transforme un objet en `string`.
	 * ### Si la "stringification" n'est pas possible, renvoie un message par défaut.
	 * @param poObject Objet à stringifier.
	 */
	private safeStringify(poObject: any): string {
		let lsResult: string;

		// On fait un stringify.
		try { lsResult = JSON.stringify(poObject); }
		catch {
			// Si on a une erreur, comme une structure circulaire, on tente de stringifier avec des règles pour se protéger.
			try { lsResult = JSON.stringify(poObject, this.innerSafeStringify(poObject)); }
			catch {
				// On peut obtenir une erreur si `lnMaxAttributesDisplayed` est trop élevé.
				lsResult = "[Impossible de stringifier l'objet]";
			}
		}

		return lsResult;
	}

	/** Stringify un objet avec des attributs circulaires sans planter l'application.
	 * @see [post original](https://stackoverflow.com/a/9653082/6595016)
	 */
	private innerSafeStringify(poObject: any): (psKey: string, poValue: any) => any {
		let lnIndex = 0;

		return (psKey: string, poValue: any) => {
			if (lnIndex !== 0 && typeof poObject === "object" && typeof poValue === "object" && poObject === poValue)
				return "[Circular]";

			if (lnIndex >= LoggerService.C_MAX_DISPLAYED_ATTRIBUTES) // seems to be a harded maximum of 30 serialized objects?
				return "[Unknown]";

			++lnIndex; // so we know we aren't using the original object anymore.

			return poValue;
		};
	}

	/** Créée la tâche pour envoyer les logs. */
	private write(poLogEntry: ILogEntry): Observable<boolean> {
		this.addToLast100LogEntries(poLogEntry);

		return this.innerWrite_browser(poLogEntry).pipe(
			catchError(poError => { console.error(`${LoggerService.C_LOG_ID}Erreur lors de l'écriture du log en cache.`, poError); return of(false); })
		);
	}

	private innerWrite_browser(poLogEntry: ILogEntry): Observable<boolean> {
		return this.waitLogDb().pipe(
			mergeMap((poDb: IDBDatabase) => {
				const loWriteSubject = new Subject<boolean>();

				return loWriteSubject.asObservable().pipe(
					afterSubscribe(() => {
						// On ouvre une transaction qui va se terminer de manière automatique lors de la sortie de la callback.
						const loTransaction: IDBTransaction = poDb.transaction([LoggerService.C_LOG_OBJECT_NAME], "readwrite");

						loTransaction.oncomplete = () => {
							this.moLogEntriesSubject.next(poLogEntry);
							loWriteSubject.next(true);
							loWriteSubject.complete();
						};
						loTransaction.onerror = (poEvent: Event) => {
							console.error(`${LoggerService.C_LOG_ID}`, (poEvent.target as IDBTransaction).error);
							loWriteSubject.next(false);
							loWriteSubject.complete();
						};

						try { loTransaction.objectStore(LoggerService.C_LOG_OBJECT_NAME).add(poLogEntry); }
						catch (poError) {
							loTransaction.objectStore(LoggerService.C_LOG_OBJECT_NAME).add(
								this.generateEntry(poError.message, ELogLevel.error, ELogActionId.loggerAddEntryFailed, this.safeClone(poLogEntry.data), poError)
							);
						}
					})
				);
			})
		);
	}

	/** Récupère les logs à envoyer au serveur par lots.
	 * @param psStartId Identifiant de départ.
	 * @param pnBufferSize Taille des lots
	 */
	public getLogsToFlush(psStartId?: string, pnBufferSize: number = LoggerService.C_LOGS_DEFAULT_BUFFER_SIZE): Observable<ILogEntry[]> {
		return this.waitLogDb().pipe(mergeMap((poDb: IDBDatabase) => {
			const loEntriesSubject = new Subject<ILogEntry[]>();

			return loEntriesSubject.asObservable().pipe(
				afterSubscribe(() => {
					const laLogEntries: ILogEntry[] = [];
					const loTransaction: IDBTransaction = poDb.transaction([LoggerService.C_LOG_OBJECT_NAME]);

					loTransaction.oncomplete = () => loEntriesSubject.complete();

					loTransaction.onerror = (poEvent: Event) => {
						console.error(`${LoggerService.C_LOG_ID}`, (poEvent.target as IDBTransaction).error);
						loEntriesSubject.error((poEvent.target as IDBTransaction).error);
					};

					let loLowerBound: IDBKeyRange;

					if (!StringHelper.isBlank(psStartId))
						loLowerBound = IDBKeyRange.lowerBound(psStartId, true);

					loTransaction.objectStore(LoggerService.C_LOG_OBJECT_NAME).openCursor(loLowerBound).onsuccess = (poEvent: Event) => {
						const loCursor: IDBCursorWithValue = (poEvent.target as IDBRequest).result;
						if (loCursor && laLogEntries.length < pnBufferSize) {
							laLogEntries.push(loCursor.value);
							loCursor.continue();
						}
						else
							loEntriesSubject.next(laLogEntries);
					};
				})
			);
		}));
	}

	/** Supprime les identifiants de logs passés en paramètre.
	 * @param paLogEntryIds
	 */
	public deleteLogs(paLogEntryIds: string[]): Observable<boolean> {
		return this.waitLogDb().pipe(mergeMap((poDb: IDBDatabase) => {
			const loEntriesSubject = new Subject<boolean>();

			return loEntriesSubject.asObservable().pipe(
				afterSubscribe(() => {
					const loTransaction: IDBTransaction = poDb.transaction([LoggerService.C_LOG_OBJECT_NAME], "readwrite");

					loTransaction.oncomplete = () => {
						loEntriesSubject.next(true);
						loEntriesSubject.complete();
					};

					loTransaction.onerror = (poEvent: Event) => {
						console.error(`${LoggerService.C_LOG_ID}`, (poEvent.target as IDBTransaction).error);
						loEntriesSubject.next(false);
						loEntriesSubject.complete();
					};

					loTransaction.objectStore(LoggerService.C_LOG_OBJECT_NAME).openCursor(
						IDBKeyRange.bound(ArrayHelper.getFirstElement(paLogEntryIds), ArrayHelper.getLastElement(paLogEntryIds))
					).onsuccess = (poEvent: Event) => {
						const loCursor: IDBCursorWithValue = (poEvent.target as IDBRequest).result;
						if (loCursor) {
							if (paLogEntryIds.includes(loCursor.key as string))
								loCursor.delete(); // Supprime la donnée pointée par le curseur.
							loCursor.continue();
						}
					};
				})
			);
		}));
	}

	private waitLogDb(): Observable<IDBDatabase> {
		return this.moIDBDatabaseSubject.asObservable()
			.pipe(
				filter((poDb: IDBDatabase) => !!poDb),
				take(1)
			);
	}

	private addToLast100LogEntries(poLogEntry: ILogEntry): void {
		if (this.maLast100LogEntries.length >= 100)
			this.maLast100LogEntries.pop();
		this.maLast100LogEntries.unshift(poLogEntry);
	}

	public getLast100LogEntries(): ReadonlyArray<ILogEntry> {
		return this.maLast100LogEntries;
	}

	/** Génère une entrée de Log à partir des paramètres de la console et du niveau de log. */
	private generateEntry<T>(poArguments: IArguments, peLevel: ELogLevel, peActionId?: ELogActionId, poData?: T, poError?: any, pbStateSuffix: boolean = false): ILogEntry {
		return {
			_id: this.generateId(peLevel),
			message: this.generateMessage(poArguments),
			level: peLevel,
			route: this.ioRouter.url,
			actionId: !StringHelper.isBlank(peActionId) ? `${peActionId}${(pbStateSuffix ? (poError ? "-error" : "-success") : "")}` as ELogActionId : undefined,
			data: poData,
			error: poError,
			userId: UserData.current?._id,
			version: ConfigData.appInfo.appVersion
		} as ILogEntry;
	}

	private generateMessage(poArguments: IArguments | string): string {
		const laLines: string[] = [];

		if (typeof poArguments === "string")
			laLines.push(poArguments);
		else {
			for (let lnIndex = 0; lnIndex < poArguments.length; lnIndex++) {
				const loArgument = poArguments[lnIndex];

				// Évite d'avoir des messages contenant [Object object].
				if (typeof loArgument === "object") {
					if (loArgument instanceof (Error)) {
						laLines.push(loArgument.message);
						laLines.push(loArgument.stack);
					}
					else
						laLines.push(this.safeStringify(loArgument));
				}
				else
					laLines.push(loArgument);
			}
		}

		return laLines.join(" ");
	}

	/** Génère l'identifiant d'un log en fonction de son contenu.\
	 * L'identifiant est composé de la date au format lexicographique, l'identifiant de l'appareil et un suffixe d'unicité,
	 * pour ne pas avoir le même identifiant si deux sont générés au même moment.
	 * @param peLogLevel Niveau de log.
	 */
	private generateId(peLogLevel: ELogLevel): string {
		/** Le suffixe unique du dernier log. */
		const lsLastSuffixId: string = this.msLastLogId ? IdHelper.getSuffix(this.msLastLogId) : "";
		/** La date à appliquer à l'_id du nouveau login. */
		const lsDate: string = new Date().toISOString().replace(/-|T|:|\.|Z/g, ""); // Date format 20211013072217047 en UTC (sans timezone).
		/** Id généré sans le suffixe d'unicité. */
		const lsBaseId = `${lsDate}_${ConfigData.appInfo.deviceId}_${peLogLevel}`;

		// Si la partie sans le suffixe d'unicité est la même, on le rajoute avec la valeur 1.
		if (this.msLastLogId && lsBaseId !== this.msLastLogId.substring(0, this.msLastLogId.length - lsLastSuffixId.length - 1))
			this.msLastLogId = `${lsBaseId}_1`;
		else // Sinon on incrémente le suffixe d'unicité.
			this.msLastLogId = `${lsBaseId}_${(+lsLastSuffixId) + 1}`;

		return this.msLastLogId;
	}

	/** Affiche un log applicatif et enregistre en base de données le log.
	 * @param psLogSenderId Identifiant de log de l'appelant (par exemple : `BTS.S`).
	 * @param psMessage Message du log.
	 * @param peActionId Type d'action pour le log (création d'une conversation, suppression d'un rapport, ...).
	 * @param poData Donnée à ajouter au log pour laisser une trace.
	 * @param poError Erreur à ajouter au log.
	 * @param pbStateSuffix Indique si l'on veut suffixer le log généré avec `-success` ou `-error`.
	 * @see Pour les autres méthodes à ajouter plus tard :
	 */
	public action<T>(psLogSenderId: string, psMessage: string, peActionId?: ELogActionId, poData?: T, poError?: any, pbStateSuffix?: boolean): void {
		// Typage en `any` car on surcharge les arguments originaux pour formater le log.
		const laArguments: any = [
			`${peActionId ? (peActionId).replace("::", "") : ""}::${psLogSenderId ? (psLogSenderId as string).replace("::", "") : ""}::${psMessage}`
		];

		LoggerService.C_CONSOLE_INFO.apply(console, [...laArguments, `route=${this.ioRouter.url}`, poData]);

		this.moWriteLogSubject.next({ arguments: laArguments, level: ELogLevel.action, actionId: peActionId, data: poData, error: poError, stateSuffix: pbStateSuffix });
	}

	/** Retourne un clone de la donnée.\
	 * Si la donnée ne peut être clonée normalement (cyclique, ...) un objet `IDefaultCloneData` est retourné.
	 * @param poData Données à cloner.
	 */
	private safeClone(poData: any): any | IDefaultCloneData {
		const lsSafeStringifyData: string = this.safeStringify(poData);

		return ObjectHelper.isJson(lsSafeStringifyData) ? JSON.parse(lsSafeStringifyData) : this.createDefaultCloneData(lsSafeStringifyData);
	}

	/** Crée et retourne un objet de clone par défaut.
	 * @param psStringifiedData Données stringifiée dont il faut retourner un objet par défaut.
	 */
	private createDefaultCloneData(psStringifiedData: string): IDefaultCloneData {
		return { defaultData: psStringifiedData };
	}

	//#endregion

}