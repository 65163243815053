import { Injectable } from '@angular/core';
import { IdHelper } from '@osapp/helpers/idHelper';
import { IEntity } from '@osapp/model/entities/IEntity';
import { MetaEntityBuilderBase } from '@osapp/modules/dms/model/MetaEntityBuilderBase';
import { WorkspaceService } from '@osapp/services/workspace.service';
import { C_CONSTANTES_PREFIX } from '../../../app/app.constants';
import { ISurveillances } from '../../patients/model/constantes/ISurveillances';
import { ConstantesService } from '../../patients/services/constantes.service';

@Injectable()
export class MetaConstantesBuilder extends MetaEntityBuilderBase {

	//#region METHODS

	constructor(
		private readonly isvcConstantes: ConstantesService,
		psvcWorkspace: WorkspaceService
	) {
		super(psvcWorkspace);
	}

	/** @override */
	public match(psDocumentId: string): boolean {
		return IdHelper.hasPrefixId(psDocumentId, C_CONSTANTES_PREFIX);
	}

	/** @override */
	protected getEntityId(poEntity: IEntity<ISurveillances>): Promise<string> {
		return Promise.resolve(this.isvcConstantes.getPatientIdBySurveillances(poEntity.model));
	}

	//#endregion

}
