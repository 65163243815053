import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { IDateTimePickerParams } from '@osapp/model';
import { ModalComponentBase } from '@osapp/modules/modal';
import { PlatformService } from '@osapp/services/platform.service';
import { IInterruption } from '../../../model/interruption/IInterruption';

@Component({
	templateUrl: './interruptionModal.component.html',
	styleUrls: ['./interruptionModal.component.scss']
})
export class InterruptionModalComponent extends ModalComponentBase<IInterruption>   {

	//#region PROPERTIES

	@Input() public dateTimeSpinnerParams: IDateTimePickerParams;

	public breakDate = new Date;
	public explanation: string;

	//#endregion

	//#region METHODS

	constructor(poModalCtrl: ModalController, psvcPlatform: PlatformService) {
		super(poModalCtrl, psvcPlatform);
	}

	public validate(): void {
		const loResponse: IInterruption = {
			breakDate: DateHelper.resetDay(this.breakDate),
			explanation: this.explanation
		};

		this.close(loResponse);
	}

	//#endregion
}