import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { IonItemSliding } from "@ionic/angular";
import { ComponentBase } from "@osapp/helpers/ComponentBase";
import { ArrayHelper } from "@osapp/helpers/arrayHelper";
import { DateHelper } from "@osapp/helpers/dateHelper";
import { NumberHelper } from "@osapp/helpers/numberHelper";
import { ETimetablePattern } from "@osapp/model";
import { IContact } from "@osapp/model/contacts/IContact";
import { EAvatarSize } from "@osapp/model/picture/EAvatarSize";
import { IAvatar } from "@osapp/model/picture/IAvatar";
import { IAvatarsParams } from "@osapp/modules/avatar/models/IAvatarsParams";
import { Loader } from "@osapp/modules/loading/Loader";
import { ModalService } from "@osapp/modules/modal/services/modal.service";
import { PermissionsService } from "@osapp/modules/permissions/services/permissions.service";
import { PrestationService } from "@osapp/modules/prestation/services/prestation.service";
import { LoadingService } from "@osapp/services";
import { ContactsService } from "@osapp/services/contacts.service";
import { ShowMessageParamsPopup } from "@osapp/services/interfaces/ShowMessageParamsPopup";
import { ShowMessageParamsToast } from "@osapp/services/interfaces/ShowMessageParamsToast";
import { UiMessageService } from "@osapp/services/uiMessage.service";
import { C_PREFIX_PATIENT } from "apps/idl/src/app/app.constants";
import { ETraitementState } from "apps/idl/src/model/ETraitementState";
import { Majoration } from "apps/idl/src/model/Majoration";
import { Seance } from "apps/idl/src/model/Seance";
import { Traitement } from "apps/idl/src/model/Traitement";
import { SeanceService } from "apps/idl/src/services/seance.service";
import { TraitementService } from "apps/idl/src/services/traitement.service";
import { EMPTY, Subject, from } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { forkJoin } from "rxjs/internal/observable/forkJoin";
import { of } from "rxjs/internal/observable/of";
import { catchError, filter, mergeMap, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { IAccordPrealable } from "../../../patients/model/IAccordPrealable";
import { IPatient } from "../../../patients/model/IPatient";
import { Indemnite } from "../../../traitement/model/Indemnite";
import { AccordPrealableService } from "../../../traitement/slides-traitement/accordPrealable/accordPrealable.service";
import { FacturationService } from "../../facturation.service";
import { IdlPrestation } from "../../models/idl-prestation";
import { IIdlPrestationIdBuilderParams } from "../../models/iidl-prestation-id-builder-params";
import { Invoice } from "../../models/invoice";

@Component({
    selector: 'di-traitement-item',
    templateUrl: './traitement-item.component.html',
    styleUrls: ['./traitement-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TraitementItemComponent extends ComponentBase {

    //#region PROPERTIES
    @Input() public traitement: Traitement;
    @Input() public patient: IPatient;
    @Input() public isVisible: boolean;
    @Input() public contactsParId: Map<string, IContact> = new Map<string, IContact>();

    @Output("onSeancesValidees") private readonly onSeancesValideesEventEmitter = new EventEmitter<Traitement>();

    public nbSeancesAFacturer: number = 0;
    public nbSeancesTotalAValider: number = 0;
    public mtTotalSeancesAFacturer: number = 0;
    public avatarIntervenant: IAvatarsParams = null;
    public afficherBtnFacturer: Boolean = true;

    private initTrigger$: Subject<void> = new Subject<void>();
    //#endregion

    //#region METHODS
    constructor(
        private readonly svcModal: ModalService,
        private readonly svcUiMessage: UiMessageService,
        private readonly svcAccordPrealable: AccordPrealableService,
        public readonly svcPermissions: PermissionsService,
        public readonly svcSeance: SeanceService,
        public readonly svcPrestation: PrestationService,
        public readonly svcFacturation: FacturationService,
        public readonly svcTraitement: TraitementService,
				private readonly svcLoading : LoadingService,
        private router: Router,
        public readonly poChangeDetector: ChangeDetectorRef,
    ) {
        super(poChangeDetector);
    }


    public ngOnInit(): void {
        this.init();
    }

    private init(): void {
        //On vide le tableau des séances
        this.traitement.seances = [];

        this.initTrigger$.pipe(
            switchMap(() => this.svcSeance.generateSeances(this.traitement, this.patient, true, true)
                .pipe(
                    tap((seances: Seance[]) => this.initCalculSeancesAFacturer(seances)),
                    tap((seances: Seance[]) => this.initAvatarIntervenant(seances)),
                    tap(() => this.detectChanges()),
                )),
            takeUntil(this.destroyed$)
        ).subscribe();

        //Permet de mettre à jour la ligne lorsque l'on annule une facture depuis la liste des factures
        this.svcFacturation.majPatientPanel$.pipe(
            filter(idTraitement => idTraitement === this.traitement._id),
            tap(() => this.initTrigger$.next()),
            takeUntil(this.destroyed$)
        ).subscribe();

        //Permet de mettre à jour la ligne lorsque l'on revient de la facturation (permet de gérer l'annulation de la facture)
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            filter((event: NavigationEnd) => event.url === "/facturation"),
            tap(() => this.initTrigger$.next()), // Déclenche un nouvel appel à init()
            takeUntil(this.destroyed$)
        ).subscribe();

        // Cela permet d'éviter la multiplication des subscribes à chaque initialisation
        this.initTrigger$.next();
    }

    private initCalculSeancesAFacturer(seances: Seance[]): void {
        if (!ArrayHelper.hasElements(seances)) return;

        this.mtTotalSeancesAFacturer = 0;
        const dateActuelle: Date = new Date();

        const seancesAValider: Seance[] = seances.filter(seance => !seance.isProtected);
        this.nbSeancesTotalAValider = seancesAValider.length;

        if (this.nbSeancesTotalAValider <= 0) {
            //On a plus de séances à valider on vient donc mettre à jour le traitement car il est terminé            
            this.svcTraitement.modifierStateTraitement(this.traitement, ETraitementState.termine).pipe(takeUntil(this.destroyed$)).subscribe();
        }

        const seancesAFacturer: Seance[] = seancesAValider.filter(seance => DateHelper.compareTwoDates(dateActuelle, seance.startDate) >= 0);
        this.nbSeancesAFacturer = seancesAFacturer.length;

        //Permet d'éviter le pb d'affichage/masquage du bouton facturer
        if (this.nbSeancesAFacturer > 0) {
            this.afficherBtnFacturer = true;
        } else {
            this.afficherBtnFacturer = false;
        }

        //On calcule le montant total des séances à facturer
        seancesAFacturer.forEach((seance: Seance) => {
            //Montant des actes
            this.mtTotalSeancesAFacturer += seance.price;
            //Montant des majorations
            this.mtTotalSeancesAFacturer += NumberHelper.reduceNumbers(seance.majorations.map((majo: Majoration) => majo.price), 0);
            //Montant des indemnités
            this.mtTotalSeancesAFacturer += NumberHelper.reduceNumbers(seance.indemnites.map((indemnite: Indemnite) => indemnite.price), 0);
        });
    }

    /**
     * Récupère l'avatar du premier intervenant des séances
     */
    public initAvatarIntervenant(seances: Seance[]): void {
        if (!ArrayHelper.hasElements(seances)) return;

        const intervenantsIds: string[] = seances[0].intervenantIds;
        const premierIntervenant: IContact = this.contactsParId.get(intervenantsIds[0]);
        const avatar: IAvatar = this.getContactAvatar(premierIntervenant);
        const nbAvatars: number = intervenantsIds.length;

        this.avatarIntervenant = {
            avatar: avatar,
            avatarsCount: avatar ? nbAvatars : 0
        } as IAvatarsParams;
    }

    private getContactAvatar(poContact: IContact): IAvatar {
        return ContactsService.createContactAvatar(poContact, EAvatarSize.big);
    }

    public async validateAndBillPrestations(): Promise<void> {
			if (!this.traitement) {
					this.svcUiMessage.showMessage(new ShowMessageParamsToast({ message: "Le traitement a été supprimé." }));
					return;
			}
	
			if (!Object.values(this.traitement.actes).some(acte => acte.isPriorAgreement)) {
					// Pas besoin de vérifier l'accord préalable s'il n'y a pas d'actes nécessitant un accord préalable
					this.generateSeanceFromDate();
					return;
			}
	
			let notCompleted = false;
			this.svcAccordPrealable.get(this.traitement._id).pipe(
				tap((accord: IAccordPrealable) => {
					if (!accord.dateResultat && !accord.resultat) {
						notCompleted = true
						this.svcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Accord Préalable", message: "L'accord préalable en lien avec le traitement est incomplet." }));
					}
				}),
				catchError((error: any) => {
					this.svcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Accord Préalable", message: "L'accord préalable en lien avec le traitement est incomplet." }));
					return EMPTY;
				}),
				takeUntil(this.destroyed$)
			).subscribe(() => {
				if(!notCompleted)
					this.generateSeanceFromDate();
			});
		}

		private generateSeanceFromDate() {
			const dateValidation: Date = new Date();
			if (!ArrayHelper.hasElements(this.traitement.seances)) {
				this.preparationSeances(this.svcSeance.generateSeances(this.traitement, this.patient, true, true), dateValidation).subscribe();
			} else {
				this.traitement.seances$.pipe(
					take(1),
					mergeMap((listeSeances: Seance[]) => this.preparationSeances(of(listeSeances), dateValidation))
				).subscribe();
			}
		}

    /**
     * Fonction pour la préparation commune des séances
     *  */
    private preparationSeances(seancesObservable: Observable<Seance[]>, dateValidation: Date): Observable<any> {
			let loader: Loader;
	
			return seancesObservable.pipe(
					switchMap((listeSeances: Seance[]) => 
							from(this.svcLoading.create()).pipe(
									tap((createdLoader: Loader) => loader = createdLoader),
									switchMap(() => from(loader.present()).pipe(
											switchMap(() => {
													// Filtrer les séances antérieures à aujourd'hui et qui ne sont pas validées/terminées/en cours de facturation...
													const seancesAvantAujourdhui: Seance[] = listeSeances.filter(seance => DateHelper.compareTwoDates(seance.startDate, dateValidation) < 0);
													const seancesAValider: Seance[] = seancesAvantAujourdhui.filter(seance => !seance.isProtected);
													const seancesValidees: Seance[] = seancesAvantAujourdhui.filter(seance => seance.isProtected);
	
													if (!ArrayHelper.hasElements(seancesAvantAujourdhui)) {
															// On a aucune séance à valider avant le moment du click sur le bouton facturer
															this.svcUiMessage.showMessage(new ShowMessageParamsPopup({
																	header: "Facturation",
																	message: "Aucune séance n'est à facturer pour le moment sur ce traitement"
															}));
															return of([]);
													}
	
													// Vérifier les seances validées avec celle à valider, si on a des séances à la même date et heure et que la séance n'est pas annulée alors il faut annuler la séance validée et la fusionner  
													const seancesConcurrentes = seancesValidees.filter(seanceValidee => !seanceValidee.isCanceled && seancesAValider.some(seanceAValider => DateHelper.compareTwoDates(seanceValidee.startDate, seanceAValider.startDate) === 0));
													const nbSeancesConcurrentes = seancesConcurrentes.length;
													if (nbSeancesConcurrentes > 0) {
															const dateSeancesConcurrente = ArrayHelper.getFirstElement(seancesConcurrentes).startDate;
															// Il faut vérifier si des factures sont rattachées à ces séances
															return this.svcFacturation.getInvoicesByTraitement(this.traitement._id).pipe(
																	switchMap((factures: Invoice[]) => {
																			const factAvecSeancesConcurrentes = factures.filter(facture => facture.actes.some(acte => DateHelper.compareTwoDates(dateSeancesConcurrente, acte.date) === 0));
																			const existeFactAvecSeancesConcurrentes = factAvecSeancesConcurrentes && factAvecSeancesConcurrentes.length > 0;
	
																			if (existeFactAvecSeancesConcurrentes) {
																					const numFactureConcurrentes = ArrayHelper.getFirstElement(factAvecSeancesConcurrentes).invoiceNumber;
	
																					this.svcUiMessage.showMessage(new ShowMessageParamsPopup({
																							header: "Facturation",
																							message: `Il existe ${nbSeancesConcurrentes} ${nbSeancesConcurrentes > 1 ? "séances déjà validées et facturées" : "séance déjà validée et facturée"} sur ce créneau (${DateHelper.transform(dateSeancesConcurrente, ETimetablePattern.dd_MM_yyyy_HH_mm_slash)}). Veuillez annuler la facture ${numFactureConcurrentes && numFactureConcurrentes.length > 0 ? "n°" + numFactureConcurrentes + " " : ""} correspondante avant de relancer le processus.`
																					}));
																					return of([]);
																			} else {
																					// Continuer le traitement des séances
																					return this.continuerTraitementSeances(seancesAValider, dateValidation);
																			}
																	})
															);
													} else {
															// Continuer le traitement des séances
															return this.continuerTraitementSeances(seancesAValider, dateValidation);
													}
											}),
											catchError(error => {
													console.error(error);
													return of([]); // Retourner un observable vide en cas d'erreur
											}),
											tap(() => loader.dismiss())
									))
							)
					),
					takeUntil(this.destroyed$)
			);
		}

    private continuerTraitementSeances(seancesAValider: Seance[], dateValidation: Date): Observable<any> {
        // Trouver la séance la plus antérieure à aujourd'hui
        const dateDebutPremiereSeance: Date = seancesAValider.reduce((seancePrecedente, seanceActuelle) =>
            DateHelper.compareTwoDates(seanceActuelle.startDate, seancePrecedente.startDate) < 0 ? seanceActuelle : seancePrecedente
        ).startDate;

        // Valider les séances et récupérer les résultats
        return forkJoin(
            seancesAValider.map(seance => this.svcSeance.validateSeance(seance, this.traitement, true))
        ).pipe(
            mergeMap(() =>
                // Appeler la méthode getPrestations avec la date de début déterminée
                this.svcPrestation.getPrestations({
                    customerIdOrPrefix: C_PREFIX_PATIENT,
                    traitementIds: [this.traitement._id]
                } as IIdlPrestationIdBuilderParams, dateDebutPremiereSeance, dateValidation)
            ),
            tap((listePrestations: IdlPrestation[]) => {
                //Si on a que des actes à 0€, on ne va pas à la facturation
                if (ArrayHelper.hasElements(listePrestations) && this.mtTotalSeancesAFacturer > 0) {
                    //On met à jour le traitement dans le composant parent
                    this.onSeancesValideesEventEmitter.emit(this.traitement);
                }
								this.initTrigger$.next();
            })
        );
    }

    /** Ouvre ou ferme un itemSliding en fonction de l'état avant slide.\
     * On peut également stopper la propagation de l'événement de clic.
     * @param poItemSliding Objet d'options qu'on veut ouvrir ou fermer (animation de swipe).
     * @param poEvent Événement de clic à stopper si renseigné.
     */
    public async openOrCloseItemSliding(poItemSliding: IonItemSliding, poEvent?: MouseEvent): Promise<void> {
        if (poEvent)
            poEvent.stopPropagation();	// Empêche la possible navigation vers l'item cliqué.

        // Si l'item est ouvert, la valeur est strictement supérieure à 0, sinon c'est que l'item est fermé.
        const lnAmountOpenPixels: number = await poItemSliding.getOpenAmount();

        if (lnAmountOpenPixels > 0) // Item ouvert, on veut le fermer
            poItemSliding.close();
        else // Item fermé, on veut l'ouvrir.
            poItemSliding.open("end");
    }

    //#endregion
}