import { HttpHeaders } from '@angular/common/http';
import { StringHelper } from '../../../helpers/stringHelper';
import { ConfigData } from '../../../model/config/ConfigData';
import { IHttpOptions } from '../../../model/IHttpOptions';
import { OsappApiHelper } from '../../osapp-api/helpers/osapp-api.helper';

export abstract class AuthenticatedRequestOptionBuilder {

	//#region METHODS

	public static buildAuthenticatedRequestOptions(): IHttpOptions {
		let lsToken: string;

		if (ConfigData.authentication)
			lsToken = ConfigData.authentication.token;

		if (!StringHelper.isBlank(lsToken)) {
			return {
				headers: new HttpHeaders({
					appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
					token: lsToken,
					"api-key": ConfigData.environment.API_KEY
				})
			} as IHttpOptions;

		}
		else
			return this.buildNonAuthenticatedRequestOptions();
	}

	public static buildNonAuthenticatedRequestOptions(): IHttpOptions {
		return {
			headers: new HttpHeaders({
				appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
				"api-key": ConfigData.environment.API_KEY
			}),
			responseType: undefined
		};
	}


	//#endregion

}
