import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldBase } from '../../../../../model/forms/FieldBase';

@Component({
	templateUrl: './email-field.component.html',
	styleUrls: ['./inputs.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailFieldComponent extends FieldBase<string>{

	//#region METHODS

	public emailChanged(poEvent: CustomEvent<{ value: string }>): void {
		if (!this.formControl.touched)
			this.markAsDirty();
	}

	//#endregion
}