import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IDateRange } from '@osapp/components/date/date-range-picker/model/IDateRange';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { IContact } from '@osapp/model/contacts/IContact';
import { ModalComponentBase } from '@osapp/modules/modal/model/ModalComponentBase';
import { ShowMessageParamsToast } from '@osapp/services/interfaces/ShowMessageParamsToast';
import { PlatformService } from '@osapp/services/platform.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { IPlanificationData } from '../../model/IPlanificationData';
import { ISlot } from '../../model/ISlot';

@Component({
	selector: 'idl-planification-modal',
	templateUrl: './planification-modal.component.html',
	styleUrls: ['./planification-modal.component.scss'],
})
export class PlanificationModalComponent extends ModalComponentBase<IPlanificationData> {

	//#region PROPERTIES

	@Input() public contact: IContact;
	@Input() public slots: ISlot[];
	@Input() public groupId: string;
	@Input() public set defaultDateRange(poDefaultDateRange: IDateRange) {
		if (!ObjectHelper.isNullOrEmpty(poDefaultDateRange))
			this.dateRange = poDefaultDateRange;
	}

	public dateRange: IDateRange = { begin: DateHelper.resetDay(new Date()), end: DateHelper.fillDay(new Date()) };
	public selectedSlot: ISlot;

	//#endregion

	//#region METHODS

	constructor(
		private isvcUiMessage: UiMessageService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
	) {
		super(poModalCtrl, psvcPlatform);
	}

	/** Valide la planification et envoie les données à la page principale. */
	public validatePlanification() {
		if (!ObjectHelper.isNullOrEmpty(this.dateRange) && !ObjectHelper.isNullOrEmpty(this.selectedSlot)) {
			const loPlanificationData: IPlanificationData = {
				contactId: this.contact._id,
				groupId: this.groupId,
				startDate: this.dateRange.begin.toISOString(),
				endDate: this.dateRange.end.toISOString(),
				slot: this.selectedSlot
			};

			this.close(loPlanificationData);
		}
		else {
			this.isvcUiMessage.showMessage(new ShowMessageParamsToast({
				header: "Impossible de valider",
				message: "Vous n'avez pas saisi toutes les informations.",
				color: "dark",
				duration: 3000
			}));
		};
	}

	public onSlotSelectionChange(poSelectedSlot: ISlot): void {
		this.selectedSlot = poSelectedSlot;
	}

	public onDateRangeChanged(poDateRange: IDateRange): void {
		this.dateRange = poDateRange;
	}

	//#endregion

}
