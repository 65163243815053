import { IPrestationLine } from '@osapp/modules/prestation/models/iprestation-line';
import { PrestationLine } from '@osapp/modules/prestation/models/prestation-line';
import { ModelMatch } from '@osapp/modules/utils/models/decorators/model-match.decorator';
import { IIdlPrestationLine } from './iidl-prestation-line';

@ModelMatch((poData: IPrestationLine) => !!poData, PrestationLine)
export class IdlPrestationLine extends PrestationLine implements IIdlPrestationLine {

	//#region PROPERTIES

	/** @implements */
	public originalLineRef?: string;

	/** @implements */
	public ordonnanceId: string;

	/** @implements */
	public lettreCle?: string;

	/** @implements */
	public coefficient?: number;

	//#endregion

	//#region METHODS

	constructor(poData?: IIdlPrestationLine) {
		super(poData);
	}

	/** @override */
	public reset(): PrestationLine {
		this.originalLineRef = undefined;
		return super.reset();
	}

	//#endregion

}
