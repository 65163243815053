import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { StringHelper } from '../helpers/stringHelper';
import { UserData } from '../model/application/UserData';
import { IAuthStatus } from '../model/security/IAuthStatus';
import { EStoreFlag } from '../model/store/EStoreFlag';
import { FlagService } from '../services/flag.service';
import { NetworkService } from '../services/network.service';
import { SecurityService } from '../services/security.service';
import { AuthenticatedGuard } from './authenticated.guard';

@Injectable({ providedIn: "root" })
export class GuestGuard implements CanActivate {

	//#region METHODS

	constructor(
		private isvcSecurity: SecurityService,
		private ioMenu: MenuController,
		private isvcNetwork: NetworkService,
		private isvcFlag: FlagService,
		private ioAuthenticatedGuard: AuthenticatedGuard
	) { }

	public canActivate(poRoute: ActivatedRouteSnapshot, poState: RouterStateSnapshot): Observable<boolean> {
		return this.isvcSecurity.authenticationStatus$
			.pipe(
				mergeMap((poAuthStatus: IAuthStatus) => {
					if (!poAuthStatus.isAuthenticated) {
						return this.isvcFlag.waitForFlag(EStoreFlag.DBStartupInitialized, true)
							.pipe(
								mergeMap(_ => this.isvcNetwork.asyncIsNetworkReliable()),
								mergeMap((pbHasNetwork: boolean) => {
									if (pbHasNetwork) {
										return StringHelper.isBlank(poRoute.queryParams.userId) ?
											this.ioAuthenticatedGuard.canActivate(poRoute, poState) : this.isvcSecurity.remoteUserExists(poRoute.queryParams.userId);
									}
									else
										return of(true);
								}),
								mergeMap((pbRemoteUserExist: boolean) =>
									pbRemoteUserExist ? this.isvcSecurity.initUserAuthStatus() : this.isvcSecurity.initGuest(poRoute.queryParams.workspaceId)
								));
					}
					else
						return of(true);
				}),
				tap(
					(pbIsAuthenticated: boolean) => pbIsAuthenticated ? this.ioMenu.enable(!UserData.current?.isGuest) : this.isvcSecurity.requestAuthentication(poState.url),
					poError => console.error("GUEST.G:: ", poError)
				)
			);
	}

	//#endregion

}