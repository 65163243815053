import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PipeModule } from "@osapp/pipes";
import { LightingUpAngularModule } from "lighting-up-angular";
import { ActeComponent } from "./acte.component";

@NgModule({
	declarations: [ActeComponent],
	imports: [CommonModule, LightingUpAngularModule,PipeModule,
	],
	exports: [ActeComponent],
})
export class ActeModule {}
