import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicPageComponent } from '@osapp/components/dynamicPage/dynamicPage.component';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { PermissionsService } from '@osapp/modules/permissions/services/permissions.service';
import { FavoritesService } from '@osapp/modules/preferences/favorites/services/favorites.service';
import { PatternPipe } from '@osapp/pipes/pattern.pipe';
import { EntityLinkService } from '@osapp/services/entityLink.service';
import { FormsService } from '@osapp/services/forms.service';
import { GroupsService } from '@osapp/services/groups.service';
import { LoadingService } from '@osapp/services/loading.service';
import { PageManagerService } from '@osapp/services/pageManager.service';
import { PatternResolverService } from '@osapp/services/pattern-resolver.service';
import { Store } from '@osapp/services/store.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { Observable } from 'rxjs';
import { TerminalInfoModalOpenerService } from '../../../olaqin/components/terminal-info-modal/services/terminal-info-modal-opener.service';
import { OlaqinService } from '../../../olaqin/services/olaqin.service';
import { IPatient } from '../../model/IPatient';
import { CouverturesService } from '../../services/couvertures.service';
import { PatientsService } from '../../services/patients.service';
import { PatientSelectorModalOpenerService } from '../patient-selector-modal/services/patient-selector-modal-opener.service';
import { PatientsListComponent } from './patients-list.component';

@Component({
	templateUrl: './patients-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecentPatientsListComponent extends PatientsListComponent implements OnInit {

	//#region PROPERTIES

	public customEntries$: Observable<IPatient[]>;

	//#endregion

	//#region METHODS

	constructor(
		psvcPatients: PatientsService,
		psvcLoading: LoadingService,
		psvcGroups: GroupsService,
		poOlaqin: OlaqinService,
		poParentPage: DynamicPageComponent<ComponentBase>,
		psvcPageManager: PageManagerService,
		psvcUiMessage: UiMessageService,
		psvcForm: FormsService,
		psvcEntity: EntityLinkService,
		poPatternPipe: PatternPipe,
		poRenderer: Renderer2,
		psvcPatternResolver: PatternResolverService,
		poChangeDetectorRef: ChangeDetectorRef,
		poRoute: ActivatedRoute,
		poRouter: Router,
		psvcStore: Store,
		psvcFavorites: FavoritesService,
		poPermissions: PermissionsService,
		psvcPatientSelectorModalOpener: PatientSelectorModalOpenerService,
		psvcTerminalInfoModalOpener: TerminalInfoModalOpenerService,
		psvcCouvertures: CouverturesService
	) {
		super(psvcPatients, psvcLoading, poOlaqin, psvcGroups, poPermissions, psvcPatientSelectorModalOpener, psvcTerminalInfoModalOpener, psvcCouvertures, poParentPage, psvcPageManager, psvcUiMessage, psvcForm,
			psvcEntity, poPatternPipe, poRenderer, psvcPatternResolver, poChangeDetectorRef, poRoute, poRouter, psvcStore, psvcFavorites);
	}

	public ngOnInit(): void {
		this.customEntries$ = this.isvcPatients.getRecentPatients();
		super.ngOnInit();
	}

	//#endregion
}
