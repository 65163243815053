import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IonItemSliding } from '@ionic/angular';
import { takeUntil } from 'rxjs/internal/operators';
import { finalize, tap } from 'rxjs/operators';
import { SearchComponent } from '../../../../components/search/search.component';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { IContact } from '../../../../model/contacts/IContact';
import { IGroup } from '../../../../model/contacts/IGroup';
import { IAvatar } from '../../../../model/picture/IAvatar';
import { ISearchOptions } from '../../../../model/search/ISearchOptions';
import { GroupsService } from '../../../../services/groups.service';
import { IApplicationRole } from '../../../../services/security/IApplicationRole';
import { PermissionsService } from '../../../permissions/services/permissions.service';

@Component({
	selector: 'calao-groups-list',
	templateUrl: './groups-list.component.html',
	styleUrls: ['./groups-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupsListComponent extends ComponentBase {

	//#region PROPERTIES

	/** Liste des groupes. */
	private maGroups: IGroup[];
	public get groups(): IGroup[] {
		return this.maGroups;
	}
	@Input() public set groups(paGroups: IGroup[]) {
		if (paGroups) {
			this.maGroups = paGroups;
			this.onFilteredDocumentsChanged(this.maGroups);
			this.initContacts(paGroups);
		}
	}
	/** Liste des groupes à afficher. */
	public filteredGroups: IGroup[];
	public contactsByGroupId = new Map<string, IContact[]>();
	/** Indique si on a une searchBox. */
	public hasSearchbox = true;
	/** Indique si l'itemSliding est activé. */
	public itemSlidingEnabled = true;

	@ViewChild('search') public searchComponent: SearchComponent<IGroup>;
	/** Valeur recherchée dans l'input de recherche. */
	public searchValue: string;
	public searchOptions: ISearchOptions<IGroup> = {
		hasPreFillData: true,
		searchboxPlaceholder: "Rechercher",
		searchFunction: (poItem: IGroup, psSearchValue: string) => this.groupMatch(psSearchValue, poItem)
	}

	@Output("onGroupSelected") public readonly onGroupSelectedEventEmitter: EventEmitter<IGroup> = new EventEmitter();
	@Output("onGroupDeleted") public readonly onGroupDeletedEventEmitter: EventEmitter<IGroup> = new EventEmitter();

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcGroups: GroupsService,
		private readonly isvcPermissions: PermissionsService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);
	}

	/** Initialise les contacts.
	 * @param paGroups
	 */
	private initContacts(paGroups: IGroup[]): void {
		this.isvcGroups.getGroupContacts(paGroups)
			.pipe(
				tap((poLinkedContactsByGroup: Map<string, IContact[]>) => this.contactsByGroupId = poLinkedContactsByGroup),
				finalize(() => this.detectChanges()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Réception du changement du tableau des documents filtrés depuis le composant de recherche.
	 * @param paChanges Nouveau tableau contenant les documents filtrés.
	 */
	public onFilteredDocumentsChanged(paChanges: IGroup[]): void {
		if (!ArrayHelper.areArraysFromDatabaseEqual(this.filteredGroups, paChanges)) {
			this.filteredGroups = paChanges;
			this.detectChanges();
		}
	}

	public onItemClicked(poGroup: IGroup): void {
		this.onGroupSelectedEventEmitter.next(poGroup);
	}

	/** Construit un avatar à partir d'une image. */
	public getGroupAvatar(poGroup: IGroup): IAvatar {
		return GroupsService.createGroupAvatar(poGroup);
	}

	public getRolesNames(poGroup: IGroup): string {
		return ArrayHelper.hasElements(poGroup.roles) ?
			this.isvcPermissions.getPermissionRoles(poGroup.roles).map((poRole: IApplicationRole) => poRole.label).join(", ") : "";
	}

	/** Ouvre ou ferme un itemSliding en fonction de l'état avant slide.\
	 * On peut également stopper la propagation de l'événement de clic.
	 * @param poItemSliding Objet d'options qu'on veut ouvrir ou fermer (animation de swipe).
	 * @param poEvent Événement de clic à stopper si renseigné.
	 */
	public async openOrCloseItemSliding(poItemSliding: IonItemSliding, poEvent?: MouseEvent): Promise<void> {
		if (poEvent)
			poEvent.stopPropagation();	// Empêche la possible navigation vers l'item cliqué.

		// Si l'item est ouvert, la valeur est strictement supérieure à 0, sinon c'est que l'item est fermé.
		const lnAmountOpenPixels: number = await poItemSliding.getOpenAmount();

		if (lnAmountOpenPixels > 0) // Item ouvert, on veut le fermer
			poItemSliding.close();
		else // Item fermé, on veut l'ouvrir.
			poItemSliding.open("end");
	}

	private groupMatch(psSearchValue: string, poItem: IGroup) {
		return psSearchValue.toLowerCase()
			.split(" ")
			.some((psWord: string) => poItem.name.toLowerCase().includes(psWord));
	}

	public emitRemoveGroup(poGroup: IGroup, poItemSliding: IonItemSliding, poEvent?: MouseEvent): void {
		this.openOrCloseItemSliding(poItemSliding, poEvent)
		this.onGroupDeletedEventEmitter.next(poGroup);
	}

	//#endregion

}
