import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { IAvatar } from '../../../../model/picture/IAvatar';
import { ISearchOptions } from '../../../../model/search/ISearchOptions';
import { PlatformService } from '../../../../services/platform.service';
import { ModalComponentBase } from '../../../modal/model/ModalComponentBase';
import { ISite } from '../../models/isite';
import { SitesService } from '../../services/sites.service';


@Component({
	selector: 'calao-site-selector-modal',
	templateUrl: './site-selector-modal.component.html',
	styleUrls: ['./site-selector-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiteSelectorModalComponent extends ModalComponentBase<ISite> implements OnInit {

	//#region PROPERTIES

	public sites: ISite[];
	public searchAttributes: string[] = ["name"];
	public filteredSites: ISite[];

	/** Valeur recherchée dans l'input de recherche. */
	public searchValue: string;
	public searchOptions: ISearchOptions<ISite> = {
		hasPreFillData: true,
		searchboxPlaceholder: "Rechercher un site"
	}

	@Input() public hasCloseButton: boolean;
	@Input() public title: string;
	@Input() public sitesIds: string[];

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcSites: SitesService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetector);
	}

	public ngOnInit(): void {
		super.ngOnInit();
		const sites$: Observable<ISite[]> = this.sitesIds?.length > 0 ? this.isvcSites.getSitesFromIds(this.sitesIds) : this.isvcSites.getSites(true);

		sites$.pipe(
			tap((paSites: ISite[]) => {
				this.sites = paSites;
				this.filteredSites = this.sites;
				this.detectChanges();
			}),
			takeUntil(this.destroyed$)
		)
			.subscribe();
	}

	public onFilteredSitesChanged(paSites: ISite[]): void {
		this.filteredSites = paSites;
		this.detectChanges();
	}

	public getSiteAvatar(poSite: ISite): IAvatar {
		return SitesService.createSiteAvatar(poSite);
	}

	public selectSite(poSite: ISite): void {
		this.close(poSite);
	}

	//#endregion

}