import { Observable, of } from 'rxjs';
import { ArrayHelper } from "../../helpers/arrayHelper";
import { EntityHelper } from '../../helpers/entityHelper';
import { EPrefix } from "../EPrefix";
import { IStoreDocument } from '../store';
import { IEntityGuard, IGuardResult } from "./IEntityGuard";
import { IEntityLink } from "./IEntityLink";

/** Implémente les régles de contrôle d'une entité ayant un lien de dépendance parent/enfant avec une autre,
 * par exemple le traitement d'un patient. */
export class ChildEntityGuard implements IEntityGuard {

	constructor(private linkedElementMessage: string, private parentPrefix: EPrefix) { }

	/** Une entité peut être supprimée si :
	 * - elle n'a aucun élément lié
	 * - OU SINON est n'est liée qu'à son parent uniquement.
	 */
	public isDeletable(_: IStoreDocument, paLinks: IEntityLink[]): Observable<IGuardResult> {
		if (!ArrayHelper.hasElements(paLinks) || this.isLinkedToParentOnly(paLinks))
			return of({ result: true, message: undefined });
		else
			return of({ result: false, message: `${this.linkedElementMessage} à ${paLinks.length} élément${paLinks.length > 1 ? "s" : ""}.` });
	}

	private isLinkedToParentOnly(paLinks: IEntityLink[]): boolean {
		return paLinks.length === 1 &&
			EntityHelper.getEntityLinkPartFromPrefix(
				paLinks[0],
				this.parentPrefix
			).entityId.startsWith(this.parentPrefix);
	}

}