import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { InitComponentError } from '../../../model/errors/InitComponentError';
import { IGeolocData } from '../../../model/navigation/IGeolocData';
import { IReverseGeocodingData } from '../../../model/navigation/IReverseGeocodingData';
import { ModalComponentBase } from '../../../modules/modal/model/ModalComponentBase';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { PlatformService } from '../../../services/platform.service';
import { UiMessageService } from '../../../services/uiMessage.service';

@Component({
	templateUrl: 'reverse-geocoding-modal.component.html',
	styleUrls: ['./reverse-geocoding-modal.component.scss']
})
export class ReverseGeocodingModalComponent extends ModalComponentBase<IGeolocData> implements OnInit {

	//#region FIELDS

	/** Texte du bouton d'annulation. */
	private static readonly C_NO_BUTTON = "Non";
	/** Texte du bouton de validation. */
	private static readonly C_YES_BUTTON = "Oui";

	//#endregion

	//#region PROPERTIES

	@Input() public data: IReverseGeocodingData[];

	public selectedAddress: IGeolocData;
	public selectedIndex: number;

	//#endregion

	//#region METHODS

	constructor(private isvcUiMessage: UiMessageService, poModalCtrl: ModalController, psvcPlatform: PlatformService) {
		super(poModalCtrl, psvcPlatform);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		if (!this.data)
			throw new InitComponentError("Aucun paramètre présent pour le composant de reverseGeocoding.");
		else if (ArrayHelper.hasElements(this.data))
			this.onItemSelected(ArrayHelper.getFirstElement(this.data), 0);
	}

	/** Mise à jour des champs avec l'adresse sélectionnée. */
	public onUpdateClick(): void {
		if (this.data.length > 1) {
			this.isvcUiMessage.showMessage(
				new ShowMessageParamsPopup({
					message: "Vous allez modifier l'adresse actuellement enregistrée, voulez-vous continuer ?",
					buttons: [
						{ text: ReverseGeocodingModalComponent.C_NO_BUTTON, cssClass: "button-negative" },
						{ text: ReverseGeocodingModalComponent.C_YES_BUTTON, cssClass: "button-positive", handler: () => { this.close(this.selectedAddress); } }
					]
				})
			);
		}
		else
			this.close(this.selectedAddress);
	}

	/** Sélection d'une adresse de la liste.
	 * @param poItem Données GPS de l'adresse sélectionnée.
	 * @param pnIndex Index de l'adresse sélectionnée.
	 */
	public onItemSelected(poItem: IReverseGeocodingData, pnIndex: number): void {
		this.selectedIndex = pnIndex;
		this.selectedAddress = {
			street: poItem.street,
			zipCode: poItem.zipCode,
			city: poItem.city,
			latitude: poItem.latitude,
			longitude: poItem.longitude
		} as IGeolocData;
	}

	//#endregion
}