import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { ETimetablePattern } from 'libs/osapp/src/model/date/ETimetablePattern';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { DateHelper } from '../../../helpers/dateHelper';
import { IDateRange } from './model/IDateRange';
@Component({
	selector: 'calao-date-range-picker',
	templateUrl: './date-range-picker.component.html',
	styleUrls: ['./date-range-picker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangePickerComponent extends ComponentBase {

	//#region FIELDS

	@Output("dateRangeChanged") private readonly moDateRangeChangedEventEmitter = new EventEmitter<IDateRange>();

	//#endregion

	//#region PROPERTIES

	/** Modèle sur lequel se baser pour le fonctionnement du composant. */
	private moModel: IDateRange = null;
	public get model(): IDateRange {
		return this.moModel;
	}
	@Input()
	public set model(poModel: IDateRange) {
		if (poModel) {
			poModel.begin = DateHelper.resetDay(poModel.begin);
			poModel.end = DateHelper.fillDay(poModel.end);
		}
		this.moModel = poModel;
		this.moDateRangeChangedEventEmitter.emit(this.moModel);
		this.detectChanges();
	}

	@ViewChild("picker") public dateRangeToggle: MatDateRangePicker<Date>;

	//#endregion

	//#region METHODS

	constructor(
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);
	}

	public pickRange(): void {
		this.dateRangeToggle.open();
	}

	public dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement): void {
		this.model = { begin: DateHelper.parseStringDate(dateRangeStart.value, ETimetablePattern.dd_MM_yyyy_slash), end: DateHelper.parseStringDate(dateRangeEnd.value, ETimetablePattern.dd_MM_yyyy_slash) };
	}

	//#endregion

}
