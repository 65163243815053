import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { EnumHelper, IdHelper } from '@osapp/helpers';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { NumberHelper } from '@osapp/helpers/numberHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { ConfigData, EDatabaseRole, EPrefix, IDataSource, IStoreDataResponse } from '@osapp/model';
import { ModalService } from '@osapp/modules/modal/services/modal.service';
import { ISelectorModalButton } from '@osapp/modules/selector/models/iselector-modal-button';
import { ISelectorModalParams } from '@osapp/modules/selector/selector-modal/ISelectorModalParams';
import { SelectorModalComponent } from '@osapp/modules/selector/selector-modal/selector-modal.component';
import { ESelectorDisplayMode } from '@osapp/modules/selector/selector/ESelectorDisplayMode';
import { Store } from '@osapp/services/store.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { Observable, Subject, defer, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { C_PREFIX_ACT, C_PREFIX_CHAPTER, C_PREFIX_COTATION_RULE, C_PREFIX_LC } from '../../app/app.constants';
import { TarifHelper } from '../../helpers/tarifHelper';
import { Acte } from '../../model/Acte';
import { EProfession } from '../../model/EProfession';
import { IActe } from '../../model/IActe';
import { IActeDocumentByLc } from '../../model/IActeDocumentByLc';
import { IChapter } from '../../model/IChapter';
import { IChapterContent } from '../../model/IChapterContent';
import { IPersonalizedActe } from '../../model/IPersonalizedActe';
import { ISearchActe } from '../../model/ISearchActe';
import { ITarifLettreCle } from '../../model/ITarifLettreCle';
import { ITraitement } from '../../model/ITraitement';
import { IdlApplicationService } from '../../services/idlApplicationService.service';
import { ActesListModaleComponent } from './actes-list-modale/actes-list-modale.component';
import { ENgapFilters } from './model/ENgapFilters';
import { ICotationRuleDocument } from './model/ICotationRuleDocument';

@Injectable()
export class ActesService {

	//#region FIELDS

	/** Préfixe d'id d'un acte personnalisé : "perso_". */
	private static readonly C_PREFIX_PERSONALIZED_ACT = "perso_";
	private selectActeAction = new Subject<IActe>();

	//#endregion

	//#region PROPERTIES

	public static readonly C_UNDEFINED_ACT_ID = "act_0000";
	public static readonly C_HORS_NOMENCLATURE_ACT_ID = "act_9999";

	public static readonly C_AIS_CHAPTER_ID = "chapter_0011";
	public static readonly C_BSI_CHAPTER_ID = "chapter_0012";
	public static readonly C_CANCER_CHAPTER_ID = "chapter_0018";
	public static readonly C_DIABETIC_CHAPTER_ID = "chapter_0020";
	public static readonly C_MUCOVISCIDOSE_CHAPTER_ID = "chapter_0019";
	public static readonly C_HAD_SSIAD_CHAPTER_ID = "chapter_0032";
	public static readonly C_HN_CHAPTER_ID = "chapter_0033";

	public selectActe$ = this.selectActeAction.asObservable();

	//#endregion

	//#region METHODS

	constructor(
		private isvcStore: Store,
		private ioModalCtrl: ModalController,
		private isvcApplication: IdlApplicationService,
		private readonly isvcModale: ModalService
	) { }

	public emitSelectActe(acte: IActe) {
		this.selectActeAction.next(acte);
	}

	public getActes(paActesIds?: string[], pbLive?: boolean): Observable<Acte[]> {
		const loDataSource: IDataSource = {
			role: EDatabaseRole.formsEntries,
			viewParams: {
				include_docs: true,
				startkey: paActesIds ? undefined : C_PREFIX_ACT,
				endkey: paActesIds ? undefined : C_PREFIX_ACT + Store.C_ANYTHING_CODE_ASCII,
				keys: paActesIds
			},
			live: pbLive
		};

		const loWorkspaceDataSource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				include_docs: true,
				startkey: ActesService.C_PREFIX_PERSONALIZED_ACT,
				endkey: ActesService.C_PREFIX_PERSONALIZED_ACT + Store.C_ANYTHING_CODE_ASCII
			},
			live: pbLive
		};

		return this.isvcStore.get<IPersonalizedActe>(loWorkspaceDataSource)
			.pipe(
				switchMap((paPersonalizedActes: IPersonalizedActe[]) =>
					this.isvcStore.get<ISearchActe>(loDataSource).pipe(
						map((paResults: ISearchActe[]) =>
							paResults.filter((poAct: ISearchActe) => poAct.professions.includes(this.isvcApplication.profession))
						),
						map((paCurrent: ISearchActe[]) => paCurrent.map((poActe: ISearchActe) => this.transformSearchActeToActe(poActe))),
						tap((paCommonActes: Acte[]) => {
							const laActesToAdd: Acte[] = [];
							paPersonalizedActes.forEach((poPersonalizedActe: IPersonalizedActe) => {
								const loActe: Acte = paCommonActes.find((poActe: Acte) => poPersonalizedActe._id.includes(poActe._id));
								if (!ObjectHelper.isNullOrEmpty(loActe)) {
									if (this.isDuplicatedActe(poPersonalizedActe)) {
										const loActeToAdd: Acte = new Acte(loActe);
										loActeToAdd._id = IdHelper.extractIdWithoutPrefix(poPersonalizedActe._id, "perso_" as EPrefix);
										loActeToAdd.extraCharge = poPersonalizedActe.extraCharge;
										loActeToAdd.chapters = poPersonalizedActe.chapters ?? [];
										this.fillPersonalizedActe(loActeToAdd, poPersonalizedActe);
										laActesToAdd.push(loActeToAdd);
									}
									else {
										this.fillPersonalizedActe(loActe, poPersonalizedActe);
									};
								};
							});
							paCommonActes.push(...laActesToAdd);
						})
					))
			);
	}

	public getActeById(psActeId: string): Observable<Acte> {
		const loDataSource: IDataSource = {
			role: EDatabaseRole.formsEntries,
			viewParams: {
				include_docs: true,
				key: psActeId
			}
		};

		return this.isvcStore.getOne<ISearchActe>(loDataSource)
			.pipe(
				filter((poSearchActe: ISearchActe) => poSearchActe.professions.includes(this.isvcApplication.profession)),
				map((poSearchActe: ISearchActe) => this.transformSearchActeToActe(poSearchActe))
			);
	}

	private isDuplicatedActe(poPersonalizedActe: IPersonalizedActe): boolean {
		return !StringHelper.isBlank(poPersonalizedActe._id.split("-")[1]);
	}

	private fillPersonalizedActe(poActe: Acte, poPersonalizedActe: IPersonalizedActe): void {
		poActe.personalizedActeId = poPersonalizedActe._id;
		poActe.personalizedLabel = poPersonalizedActe.description;
		poActe.tags = poPersonalizedActe.tags;
		poActe.duration = poPersonalizedActe.duration;
	}

	/** Retourne un acte personnalisé si un correspond.
	 * @param psPersonalizedActeId Id de l'acte original.
	 */
	public getPersonalizedActe(psPersonalizedActeId: string): Observable<IPersonalizedActe> {
		const loDataSource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				include_docs: true,
				key: psPersonalizedActeId
			}
		};

		return this.isvcStore.getOne<IPersonalizedActe>(loDataSource, false);
	}

	public transformSearchActeToActe(poSearchActe: ISearchActe): Acte {
		return new Acte({
			_id: poSearchActe._id,
			ngapLabel: poSearchActe.libelle,
			initialPriceCoefficient: poSearchActe.coefficient,
			keyLetters: poSearchActe.lettreCle[poSearchActe.professions.indexOf(this.isvcApplication.profession)],
			isPriorAgreement: poSearchActe.accordPrealable,
			isMciEligible: poSearchActe.eligibleMci,
			tags: poSearchActe.tags,
			jobs: poSearchActe.professions,
			cotationRules: poSearchActe.cotationRules,
			_rev: poSearchActe._rev,
			id: poSearchActe._id,
			isPackage: poSearchActe.isPackage,
			cotationRulesIds: poSearchActe.cotationRulesIds,
			duration: poSearchActe.duration,
			extraCharge: poSearchActe.supplement,
			codeExoneration: poSearchActe.codeExoneration,
			place: poSearchActe.place,
			chapters: poSearchActe.chapters,
			acteAMX: poSearchActe.acteAMX,
			acteAMI: poSearchActe.acteAMI,
			excludeMajorations: poSearchActe.excludeMajorations
		});
	}

	public selectActeWithModal(poTraitement?: ITraitement, paActes?: Acte[]): Observable<Acte> {
		return defer(() => this.ioModalCtrl.create({
			component: ActesListModaleComponent,
			componentProps: {
				mode: "select",
				acts: paActes,
				traitement: poTraitement
			}
		}))
			.pipe(
				tap((poModal: HTMLIonModalElement) => poModal.present()),
				mergeMap((poModal: HTMLIonModalElement) => poModal.onDidDismiss()),
				filter((poResult: OverlayEventDetail<Acte>) => !!poResult.data),
				map((poResult: OverlayEventDetail<Acte>) => poResult.data)
			);
	}

	public selectActesWithModal(
		paActes?: Acte[],
		paAlreadySelectedActeGuids: string[] = [],
		paCustomButtons?: ISelectorModalButton[],
		psTitle: string = "Sélection des actes"
	): Observable<Acte[]> {
		const loSelectorParams: ISelectorModalParams<string> = {
			options: paActes.map((poActe: Acte) => ({ label: poActe.description, value: poActe.guid })),
			preselectedValues: [...paAlreadySelectedActeGuids],
			displayMode: ESelectorDisplayMode.list,
			multiple: true,
			title: psTitle,
			customButtons: paCustomButtons
		};

		return this.isvcModale.open<string[]>({
			component: SelectorModalComponent,
			componentProps: loSelectorParams
		}).pipe(
			filter((paActesGuids: string[]) => !!paActesGuids),
			map((paActesGuids: string[]) => paActes.filter((poActe: Acte) => paActesGuids.includes(poActe.guid)))
		);
	}

	public openActesListModal(paActes?: Acte[]): Observable<HTMLIonModalElement> {
		return defer(() => this.ioModalCtrl.create({
			component: ActesListModaleComponent,
			componentProps: { mode: "read", acts: paActes }
		}))
			.pipe(
				tap((poModal: HTMLIonModalElement) => poModal.present())
			);
	}

	/** Applique les prix à un acte.
	 * @param poActe Acte dont il faut récupérer les prix.
	 */
	public applyPriceToActe(poActe: Acte): Observable<Acte> {
		const loParams: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.formsEntries),
			viewParams: {
				startkey: C_PREFIX_LC,
				endkey: C_PREFIX_LC + Store.C_ANYTHING_CODE_ASCII,
				include_docs: true
			}
		};

		return this.isvcStore.get<IActeDocumentByLc>(loParams)
			.pipe(
				catchError(poError => {
					console.error(`IDL.ACT.S:: Erreur récupération données pour calculer le prix de l'acte : `, poError);
					return throwError(poError);
				}),
				map((paResults: IActeDocumentByLc[]) => {
					const loCurrentActeDocument: IActeDocumentByLc = paResults.find((poItem: IActeDocumentByLc) =>
						poActe.keyLetters === poItem.lettreCle &&
						poItem.profession.findIndex((peValue: EProfession) => peValue === this.isvcApplication.profession) >= 0
					);

					if (loCurrentActeDocument) {
						if (ArrayHelper.hasElements(loCurrentActeDocument.tarif)) {
							poActe.initialPrice = loCurrentActeDocument.tarif[ConfigData.geoZoneApp - 1];
						} else if (ArrayHelper.hasElements(loCurrentActeDocument.tarifs)) {
							const tarifIndemnite: ITarifLettreCle = loCurrentActeDocument.tarifs.find((tar: ITarifLettreCle) => {
								return TarifHelper.getFiltreTarifLettreCleParDate(tar, new Date());
							});
							poActe.initialPrice = tarifIndemnite ? tarifIndemnite.montant : 0;
						}
						poActe.keyLetters = loCurrentActeDocument.lettreCle;
						poActe.calculatePrice();
					}
					return poActe;
				})
			);
	}

	/** Permet d'appliquer un acte en conservant la planification et les contraintes.
	 * @param poOldActe
	 * @param poNewActe
	 * @returns
	 */
	public applyNewActe(poOldActe: Acte, poNewActe: Acte): Acte {
		if (!poOldActe || !poNewActe)
			throw new Error("Impossible d'appliquer un nouvel acte si l'ancien acte ou le nouveau est non défini.");

		poNewActe.constraints = poOldActe.constraints;
		poNewActe.recurrences = poOldActe.recurrences;
		poNewActe.startDate = poOldActe.startDate;
		poNewActe.sundayAndPublicHolidays = poOldActe.sundayAndPublicHolidays;
		poNewActe.observations = poOldActe.observations;
		poNewActe.isAldExonerante = poOldActe.isAldExonerante;
		poNewActe.place = poOldActe.place;

		return poNewActe;
	}

	/** Permet de raccourcir la description d'un acte pour ne garder qu'un certain nombre de mots.
	 * @param poActe Acte pour lequel on veut raccourcir la description.
	 * @param pnNumberOfWords Nombre de mots à prendre.
	 * @returns Description de l'acte raccourcie.
	 */
	public getActeDescription(poActe: Acte, pnNumberOfWords: number): string {
		const lsCleanedActeLabel: string = poActe.label.replace(/\[.*\]/, '').trim();
		const lsShortDescription: string = lsCleanedActeLabel.split(" ").slice(0, pnNumberOfWords).join(" ");

		return `${lsShortDescription}${lsCleanedActeLabel === lsShortDescription ? "" : " ..."}`;
	}

	/** Retourne le prix total d'une liste d'actes.
	 * @param paActes
	 * @returns
	 */
	public static getTotalPrice(paActes: Acte[]): number {
		let lnPrice = 0;

		paActes.forEach((poActe: Acte) => lnPrice += poActe.price);

		return lnPrice;
	}

	/** Retournes les règles de cotations.
	 * @param paCotationRulesIds
	 * @returns
	 */
	public getCotationRules(paCotationRulesIds?: string[]): Observable<ICotationRuleDocument[]> {
		const lbHasCotationRulesIds: boolean = ArrayHelper.hasElements(paCotationRulesIds);
		const loDataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.formsEntries),
			viewParams: {
				keys: paCotationRulesIds,
				startkey: !lbHasCotationRulesIds ? C_PREFIX_COTATION_RULE : undefined,
				endkey: !lbHasCotationRulesIds ? C_PREFIX_COTATION_RULE + Store.C_ANYTHING_CODE_ASCII : undefined,
				include_docs: true
			}
		};

		return this.isvcStore.get(loDataSource);
	}

	/** Sauvegarde un acte personnalisé
	 * @param poPersonalizedActe
	 */
	public savePersonalizedActe(poPersonalizedActe: IPersonalizedActe): Observable<IStoreDataResponse> {
		return this.isvcStore.put(
			poPersonalizedActe,
			ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace))
		);
	}

	/** Supprime un acte personnalisé
	 * @param poPersonalizedActe
	 */
	public deletePersonalizedActe(poPersonalizedActe: IPersonalizedActe): Observable<IStoreDataResponse> {
		return this.isvcStore.delete(poPersonalizedActe, ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace)));
	}

	public static hasActe(paActes: IActe[], psActeId: string): boolean {
		return paActes.some((poActe: IActe) => poActe._id === psActeId);
	}

	public static sort<T extends IActe = IActe>(paActes: T[]): T[] {
		return paActes.sort((poActeA: T, poActeB: T) => NumberHelper.substractTwoNumbers(poActeB.price, poActeA.price));
	}

	public getChapters(paChaptersIds?: string[], pbLive?: boolean): Observable<IChapter[]> {
		const loDataSource: IDataSource = {
			role: EDatabaseRole.formsEntries,
			viewParams: {
				include_docs: true,
				startkey: paChaptersIds ? undefined : C_PREFIX_CHAPTER,
				endkey: paChaptersIds ? undefined : C_PREFIX_CHAPTER + Store.C_ANYTHING_CODE_ASCII,
				keys: paChaptersIds
			},
			live: pbLive
		};

		return this.isvcStore.get<IChapter>(loDataSource);
	}

	public groupActsByChapter(paChapters: IChapter[], paActes: IActe[]): Map<IChapter, IChapterContent> {
		const loResMap = new Map<IChapter, IChapterContent>();
		const loChapterByChapterId = new Map<string, IChapter>();

		paChapters.forEach((poChapter: IChapter) => loChapterByChapterId.set(poChapter._id, poChapter));

		const laActesWithoutChapter: IActe[] = [];
		paActes.forEach((poActe: IActe) => {
			const lnNbChapters: number = poActe.chapters.length;

			if (lnNbChapters > 0) {
				const lsLastChapterId: string = ArrayHelper.getLastElement(poActe.chapters);
				let loCurrentChapter: Map<IChapter, IChapterContent> = loResMap;

				poActe.chapters.forEach((psChapterId: string) => {
					const loChapter: IChapter = loChapterByChapterId.get(psChapterId);

					if (psChapterId !== lsLastChapterId) {
						if (loCurrentChapter.has(loChapter)) {
							loCurrentChapter = loCurrentChapter.get(loChapter).subChapters ?? loCurrentChapter;
						}
						else {
							const loSubChapter = new Map<IChapter, IChapterContent>();
							loCurrentChapter.set(loChapter, { subChapters: loSubChapter });
							loCurrentChapter = loSubChapter;
						}
					}
					else {
						if (loCurrentChapter.has(loChapter))
							loCurrentChapter.set(loChapter, { actes: ActesService.sort([...loCurrentChapter.get(loChapter).actes, poActe]) });
						else
							loCurrentChapter.set(loChapter, { actes: [poActe] });
						loCurrentChapter = loResMap;
					}
				});
			}
			else
				laActesWithoutChapter.push(poActe);
		});

		if (ArrayHelper.hasElements(laActesWithoutChapter))
			loResMap.set({ _id: "chapter_9999", description: "Autres" }, { actes: laActesWithoutChapter });

		return ActesService.sortChapterEntries(loResMap);
	}

	private static sortChapterEntries(poChapter: Map<IChapter, IChapterContent>): Map<IChapter, IChapterContent> {
		poChapter.forEach((poChapterContent: IChapterContent) => {
			if (poChapterContent.subChapters)
				poChapterContent.subChapters = ActesService.sortChapterEntries(poChapterContent.subChapters);
		});

		return new Map([...Array.from(poChapter.entries()).sort((poEntryA: [IChapter, IChapterContent], poEntryB: [IChapter, IChapterContent]) => {
			return +IdHelper.extractIdWithoutPrefix(poEntryA[0]._id, C_PREFIX_CHAPTER) - +IdHelper.extractIdWithoutPrefix(poEntryB[0]._id, C_PREFIX_CHAPTER);
		})]);
	}

	public removeActsBySelectedPathologies(paActes: Acte[], paSelectedFilters: ENgapFilters[]): Acte[] {
		const laUnselectedPathologies: ENgapFilters[] = ArrayHelper.getDifferences(EnumHelper.getKeys(ENgapFilters) as ENgapFilters[], paSelectedFilters);

		if (laUnselectedPathologies.includes(ENgapFilters.cancer))
			paActes = paActes?.filter((poActe: IActe) => !poActe.chapters?.includes(ActesService.C_CANCER_CHAPTER_ID));

		if (laUnselectedPathologies.includes(ENgapFilters.diabetic))
			paActes = paActes?.filter((poActe: IActe) => !poActe.chapters?.includes(ActesService.C_DIABETIC_CHAPTER_ID));

		if (laUnselectedPathologies.includes(ENgapFilters.mucoviscidose))
			paActes = paActes?.filter((poActe: IActe) => !poActe.chapters?.includes(ActesService.C_MUCOVISCIDOSE_CHAPTER_ID));

		if (laUnselectedPathologies.includes(ENgapFilters.hadssiad))
			paActes = paActes?.filter((poActe: IActe) => !poActe.chapters?.includes(ActesService.C_HAD_SSIAD_CHAPTER_ID));

		if (laUnselectedPathologies.includes(ENgapFilters.hn))
			paActes = paActes?.filter((poActe: IActe) => !poActe.chapters?.includes(ActesService.C_HN_CHAPTER_ID));

		if (laUnselectedPathologies.includes(ENgapFilters.dependant))
			paActes = paActes?.filter((poActe: IActe) =>
				(!(poActe.keyLetters.includes("AMX") && !StringHelper.isBlank(poActe.acteAMI))) &&
				!poActe.chapters?.includes(ActesService.C_AIS_CHAPTER_ID) &&
				!poActe.chapters?.includes(ActesService.C_BSI_CHAPTER_ID)
			);
		else
			paActes = paActes?.filter((poActe: IActe) => !(poActe.keyLetters === "AMI" && !StringHelper.isBlank(poActe.acteAMX)));

		return paActes;
	}

	public canChangeActe(poActeId: string): boolean {
		return poActeId.includes(ActesService.C_UNDEFINED_ACT_ID);
	}

	//#endregion

}
