import { DateHelper } from "@osapp/helpers/dateHelper";
import { ETimetablePattern } from "@osapp/model/date/ETimetablePattern";
import { Entity } from "@osapp/model/entities/Entity";
import { EAvatarSize } from "@osapp/model/picture/EAvatarSize";
import { EntityBuilder } from "@osapp/services";
import { C_PLANNING_DB_ROLE, C_PREFIX_PLANNING_RH } from "../../../app/app.constants";
import { IPlanningRH } from './IPlanningRH';

export class PlanningRHEntityBuilder extends EntityBuilder<IPlanningRH>{
	constructor() {
		super(
			(psModelId: string) => RegExp(C_PREFIX_PLANNING_RH).test(psModelId),
			(poPlanningRH: IPlanningRH) => new Entity(
				poPlanningRH,
				`planning-rh/${DateHelper.transform(poPlanningRH.startDate, ETimetablePattern.isoFormat_hyphen)}`,
				() => `Planning du ${DateHelper.transform(poPlanningRH.startDate, ETimetablePattern.dd_MMMM_yyyy)}`
			),
			(psModelId: string) => "Plannings",
			C_PLANNING_DB_ROLE,
			C_PREFIX_PLANNING_RH,
			() => ({
				size: EAvatarSize.medium,
				icon: "planning-rh"
			})
		);
	}
}