import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { IDataSource } from '@osapp/model/store/IDataSource';
import { Store } from '@osapp/services/store.service';
import { Observable } from 'rxjs';
import { IDemandePreparationMedicaments } from '../../model/IDemandePreparationMedicaments';

@Injectable({
	providedIn: 'root'
})
export class PharmaResolver implements Resolve<IDemandePreparationMedicaments> {

	//#region METHODS

	constructor(protected isvcStore: Store) { }

	public resolve(poSnapshotRoute: ActivatedRouteSnapshot): Observable<IDemandePreparationMedicaments> {
		const loPharmaDataSource: IDataSource = { // Source de données permettant de récupérer le document de pharma.
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				key: poSnapshotRoute.params.pharmaId, // Identifiant du document de pharma à récupérer.
				include_docs: true
			}
		};

		return this.isvcStore.getOne<IDemandePreparationMedicaments>(loPharmaDataSource, false);
	}

	//#endregion
}