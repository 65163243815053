<osapp-header-modal title="Lieu de réalisation"></osapp-header-modal>

<ion-content>
	<div *ngIf="seanceTournee">
		<div class="tags">
			<osapp-selector displayMode="tags" [options]="placeOptions" [preselectedValues]="seanceTournee.seance.place"
				scrollWrapper="false" (selectionChanged)="onPlaceChanged($event)" [min]="1">
			</osapp-selector>
		</div>
		<p *ngIf="selectedPlace === 'home'" class="place">
			{{ seanceTournee.patient.groupMember.street }} {{ seanceTournee.patient.groupMember.zipCode }} {{
			seanceTournee.patient.groupMember.city }}
		</p>
		<p *ngIf="selectedPlace === 'center'" class="place">
			{{ seanceTournee.centerName }}
			{{ currentSite.street }} {{ currentSite.zipCode }} {{ currentSite.city }}
		</p>
	</div>
</ion-content>

<ion-footer class="ion-padding ion-no-border" *ngIf="selectedPlace === 'home'">
	<ion-button expand="block" (click)="onNavigateToClicked()">
		<ion-icon slot="icon-only" name="navigation"></ion-icon>
		<ion-label>Lancer la navigation</ion-label>
	</ion-button>
</ion-footer>