import { Type } from '@angular/core';
import { isEqual, pick } from 'lodash';

export abstract class ObjectHelper {

	//#region METHODS

	/** Copie les données d'un objet (propriétés et méthodes) vers un nouvel objet (classe ou interface).
	 * Dans le cas d'une interface, seule la donnée source est nécessaire.
	 * Dans le cas d'une copie de classe, renseigner une instance du type de la classe afin de correctement l'instancier.
	 * ### ATTENTION : Ne gère pas les listes d'objets complexes. Constructeur vide nécessaire pour les classes.
	 * @param poSource Données à copier vers le nouvel objet.
	 * @param pbDataOnly Permet de ne copier que les données, 'true' par défaut.
	 * @param poTarget Nouvel objet qui doit être affecté, nécessaire pour une classe, inutile pour une interface.
	 */
	public static clone<T>(poSource: T, pbDataOnly: boolean = true, poTarget?: T): T {
		const loTarget: any = poTarget ? poTarget : Object.create(poSource as any);

		if (pbDataOnly)
			return JSON.parse(JSON.stringify(poSource));

		else if (loTarget) {
			// On parcourt chaque propriété de l'objet.
			for (const lsProperty in poSource) {
				const loField: any = poSource[lsProperty]; // On récupère le champs correspondant à la propriété.
				const loTargetField: any = loTarget[lsProperty]; // On récupère le champs de l'objet target.

				if (typeof loField === "string" || typeof loField === "number" || typeof loField === "boolean")
					loTarget[lsProperty] = loField;
				else if (loField instanceof Function)
					loTarget[lsProperty] = loField;
				else if (loField instanceof Date)
					loTarget[lsProperty] = new Date(loField);
				else if (loField instanceof Array)
					loTarget[lsProperty] = Array.from(ObjectHelper.clone(loField, false, loTargetField ? loTargetField.constructor() : null));
				else if (loField instanceof Object)
					loTarget[lsProperty] = ObjectHelper.clone(loField, false, loTargetField ? loTargetField.constructor() : null);
				else
					loTarget[lsProperty] = loField;
			}
		}
		else
			console.error("Pour permettre le clonage, chaque objet doit avoir un constructeur vide.");

		return loTarget;
	}

	/** Vérifie si un objet est bien du type passé en paramètre ou non.
	 * @param poObject Objet dont on veut vérifier le type.
	 * @param peType Valeur d'énumération correspondant au type à vérifier.
	 */
	public static checkType<T>(poObject: T, peType: string): boolean {
		return typeof poObject === peType;
	}

	/** Retourne un booléen qui indique si la valeur est de type primitif ou non.
	 * @param poValue Valeur à déterminer si elle est primitive ou non.
	 */
	public static isPrimitive<T>(poValue: T): boolean {
		return typeof poValue === "string" || typeof poValue === "boolean" || typeof poValue === "number" ||
			typeof poValue === "function" || typeof poValue === "symbol" || typeof poValue === "undefined";
	}

	/** Retourne `true` si le paramètre est un objet vide. */
	public static isEmpty<T>(poValue: T): boolean {
		return poValue && Object.entries(poValue).length === 0;
	}

	/** Initialise la possibilité d'utiliser le mot-clé `instanceof` pour une classe.
	 * @param poThis `this` du constructeur de la classe.
	 * @param poClass Type de la classe qui veut activer l'utilisation du `instanceof`.
	 */
	public static initInstanceOf(poThis: any, poClass: Type<any>): void {
		Object.setPrototypeOf(poThis, poClass.prototype); // Permet d'utiliser `instanceof MaClasse`.
	}

	public static isNullOrEmpty(poValue: any): boolean {
		return !poValue || this.isEmpty(poValue);
	}

	/** Indique si le paramètre a une valeur (non undefined et non null).
	 * @param poValue
	 * @returns
	 */
	public static isDefined(poValue: any): boolean {
		return poValue !== undefined && poValue !== null;
	}

	/** Vérifie si une chaîne de caratères peut être transformée en JSON.
	 * @param psString Chaîne de caractères à vérifier.
	 */
	public static isJson(psString: string): boolean {
		try {
			JSON.parse(psString);
			return true;
		}
		catch (poException) {
			return false;
		}
	}

	/** Indique si une propriété est modifiable.
	 * @param poObject
	 * @param psKey
	 */
	public static isWritable<T extends Object>(poObject: T, psKey: keyof T): boolean {
		const loDescriptor: PropertyDescriptor = Object.getOwnPropertyDescriptor(poObject, psKey) ||
			Object.getOwnPropertyDescriptor(Object.getPrototypeOf(poObject), psKey) ||
			{ writable: true };

		return !!loDescriptor.writable || !!loDescriptor.set;
	}

	/** Copie toutes les propriétés de `poObjectB` dans `poObjectA` si elles sont éditables ou non définies.
	 * @param poObjectA
	 * @param poObjectB
	 * @returns `poObjectA`
	 */
	public static assign<T extends Object, V extends Object>(poObjectA: T, poObjectB: V): T & V {
		if (!poObjectA)
			poObjectA = {} as T;

		let loObject: T & V = poObjectA as T & V;

		if (typeof poObjectB === "object") {
			for (const lsKey in poObjectB) {
				const loAttribute: any = poObjectB[lsKey];

				if (ObjectHelper.isDefined(loAttribute) && ObjectHelper.isWritable(poObjectA, lsKey as keyof T)) {
					if (typeof loAttribute === "object") {
						if (loAttribute instanceof Array) {
							const laOldArray: any[] = loObject[lsKey] as any ?? [];
							loObject[lsKey] = loAttribute.map((poValue: any, pnIndex: number) => this.assign(laOldArray[pnIndex], poValue)) as any;
						}
						else if (loAttribute instanceof Date)
							loObject[lsKey] = new Date(loAttribute) as any;
						else
							loObject[lsKey] = Object.setPrototypeOf(Object.assign(loObject[lsKey] ?? {}, loAttribute ?? {}), Object.getPrototypeOf(loAttribute));
					}
					else
						loObject[lsKey] = loAttribute;
				}
			}
		}
		else
			loObject = poObjectB;

		return loObject;
	}

	/** Teste l'égalité entre 2 objets.
	 * @param poObjectA
	 * @param poObjectB
	 */
	public static areEquals(poObjectA: any, poObjectB: any): boolean {
		return isEqual(poObjectA instanceof Object ? JSON.parse(JSON.stringify(poObjectA)) : poObjectA, poObjectB instanceof Object ? JSON.parse(JSON.stringify(poObjectB)) : poObjectB);
	}

	public static pick<T extends object, U extends keyof T>(poObject: T, paKeys: U[]): Pick<T, U> {
		return pick(poObject, ...paKeys);
	}

	//#endregion
}