import { IdHelper } from '@osapp/helpers';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ContactHelper } from '@osapp/helpers/contactHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { EPrefix } from '@osapp/model';
import { IContact } from '@osapp/model/contacts/IContact';
import { IGroup } from '@osapp/model/contacts/IGroup';
import { ModelMatch } from "@osapp/modules/utils/models/decorators/model-match.decorator";
import { ActeOccurrenceComparator } from "./acte-occurrence-comparator";
import { EOccurrenceType } from './EOccurrenceType';
import { IActeOccurrence } from "./iacte-occurrence";
import { IActeOccurrenceIntervenantsComparatorParams } from "./iacte-occurrence-intervenants-comparator-params";
import { IConstraintLabelParams } from './iconstraint-label-params';

const C_TYPE = EOccurrenceType.intervenants;

@ModelMatch((poData: ActeOccurrenceIntervenantsComparator) => poData?.type === C_TYPE, ActeOccurrenceComparator)
export class ActeOccurrenceIntervenantsComparator extends ActeOccurrenceComparator<IActeOccurrenceIntervenantsComparatorParams> {

	//#region PROPERTIES

	public readonly type: EOccurrenceType = C_TYPE;

	//#endregion

	//#region METHODS

	/** @override */
	public getLabel(poParams: IConstraintLabelParams): string {
		const laIntervenantsAndGroups: Array<IContact | IGroup> = [];

		this.params?.intervenantIds.forEach((psIntervenantId: string) => {
			const loIntervenantOrGroup: IContact | IGroup = poParams.intervenantsAndGroups.find((poContact: IContact) => psIntervenantId === poContact._id);
			if (!ObjectHelper.isNullOrEmpty(loIntervenantOrGroup))
				laIntervenantsAndGroups.push(loIntervenantOrGroup);
		});

		return `${this.params.intervenantIds?.length > 1 ? "des intervenants" : "de l'intervenant"} ${laIntervenantsAndGroups.map((poIntervenantOrGroup: IContact | IGroup) => {
			if (IdHelper.getPrefixFromId(poIntervenantOrGroup._id) === EPrefix.contact)
				return ContactHelper.getCompleteFormattedName(poIntervenantOrGroup as IContact);
			else
				return (poIntervenantOrGroup as IGroup).name;
		}).join(", ")}`;
	}

	/** @override */
	public match(poActeOccurrence: IActeOccurrence): boolean {
		return ArrayHelper.areArraysEqual(poActeOccurrence.intervenantIds, this.params?.intervenantIds);
	}

	/** @override */
	public getIntervenantsIds(): string[] {
		return this.params?.intervenantIds ?? [];
	}

	//#endregion

}
