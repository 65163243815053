import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { IPrestationConfig } from './models/iprestation-config';
import { PrestationService, PRESTATION_CONFIG } from './services/prestation.service';

@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [PrestationService]
})
export class PrestationModule {
	public static forRoot(poFavoritesConfig: IPrestationConfig): ModuleWithProviders<PrestationModule> {
		return {
			ngModule: PrestationModule,
			providers: [
				{ provide: PRESTATION_CONFIG, useValue: poFavoritesConfig }
			]
		};
	}
}
