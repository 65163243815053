import { Injectable } from '@angular/core';
import { ArrayHelper } from '../helpers/arrayHelper';
import { ContactHelper } from '../helpers/contactHelper';
import { GuidHelper } from '../helpers/guidHelper';
import { StringHelper } from '../helpers/stringHelper';
import { UserData } from '../model/application/UserData';
import { ConfigData } from '../model/config/ConfigData';
import { IEntity } from '../model/entities/IEntity';
import { EPattern } from '../model/EPattern';
import { NoCurrentUserDataError } from '../model/errors/NoCurrentUserDataError';
import { PatternsHelper } from '../modules/utils/helpers/patterns.helper';
import { ApplicationService } from './application.service';
import { EntityLinkService } from './entityLink.service';

@Injectable({ providedIn: "root" })
export class PatternResolverService {

	//#region FIELDS

	/** Identifiant du service pour les logs. */
	private static readonly C_LOG_ID = "PAT.RSLV.S::";
	/** Pattern pour se servir du service spécifique de l'application : "app". */
	private static readonly C_CUSTOM_SERVICE_KEYWORD = "app";

	/** Instance du service partagé personnalisé. */
	private moCustomSharedService: ApplicationService;

	//#endregion

	//#region METHODS

	constructor(private readonly isvcEntityLink: EntityLinkService) { }

	/** Modifie la réference interne du service custom où le resolver de paramètres dynamique peut aller chercher les informations
	 * @param poCustomSharedService Service custom venant de l'application utilisant la lib.
	 */
	public setCustomSharedService(poCustomSharedService: ApplicationService): void {
		this.moCustomSharedService = poCustomSharedService;
	}

	/** Génère un id personnalisé.
	 * @param psIdPattern Pattern d'identifiant à parser.
	 * @param poModel Modèle, optionnel (si on souhaite juste générer un guid personnalisé).
	 * @param poParentEntity
	 */
	public generateCustomId(psIdPattern: string, poModel: any = {}, poParentEntity?: IEntity): string {
		let lsGeneratedId: string = this.replaceDynParams(psIdPattern, poModel, poParentEntity);

		if (psIdPattern === lsGeneratedId) // Sinon, c'est qu'il n'y a pas de pattern à appliquer.
			lsGeneratedId = GuidHelper.newGuid(); // On génère donc un nouveau guid.

		return lsGeneratedId; // On fixe l'id couchdb avec l'id que l'on vient de générer.
	}

	/** Remplace les paramètres dynamiques {{test.valeur}} => "42".
	 * @param psInput Chaîne de caractères avec pattern qu'il faut remplacer.
	 * @param poModel Modèle dans lequel on doit remplacer les pattern.
	 * @param poParentEntity Entité parente.
	 */
	public replaceDynParams(psInput: string, poModel: any = {}, poParentEntity?: IEntity): string {
		let lsParam = "";

		// Si un pattern est à appliquer.
		if (!StringHelper.isBlank(psInput)) {
			const laFirstSplittedStrings: Array<string> = psInput.split(PatternsHelper.C_START_PATTERN);

			// Si plusieurs éléments alors pattern à appliquer (ex : ["cont_", "guid}}"]).
			if (laFirstSplittedStrings.length > 1) {
				// Si le premier élément du tableau n'a pas d'accolades fermantes, c'est qu'il faut prendre telle quelle la chaîne de caractères (ex: "cont_").
				if (laFirstSplittedStrings[0].indexOf(PatternsHelper.C_END_PATTERN) === -1) {
					lsParam += laFirstSplittedStrings[0];
					laFirstSplittedStrings.splice(0, 1); // On supprime du tableau la chaîne de caractères que l'on vient de recopier dans le paramètre dynamique.
				}

				lsParam = this.completeReplaceDynParams(lsParam, laFirstSplittedStrings, poModel, poParentEntity);
			}
			else // Sinon, un seul élément est présent dans le tableau parce qu'il n'y avait pas de pattern à appliquer (ex: cont_jdffhdf).
				lsParam = laFirstSplittedStrings[0]; // On recopie donc telle quelle la chaîne de caractères.
		}
		else // Sinon, c'est qu'il n'y a pas de pattern à appliquer.
			lsParam = psInput;

		return lsParam; // On  retourne le résultat.
	}

	/** Termine de résoudre les patterns et renvoie le résultat.
	 * @param psResult Résultat obtenu pour l'instant.
	 * @param paPatternIdStrings Tableau regroupant les chaînes de caractères restantes à parser.
	 * @param poModel Modèle.
	 * @param poParentEntity Entité parente.
	 */
	private completeReplaceDynParams(psResult: string, paPatternIdStrings: Array<string>, poModel: any, poParentEntity?: IEntity): string {

		// Boucle qui va terminer de parser les patterns restants.
		for (let lnIndex = 0, lnLength = paPatternIdStrings.length; lnIndex < lnLength; ++lnIndex) {
			const laSecondSplittedStrings: Array<string> = paPatternIdStrings[lnIndex].split(PatternsHelper.C_END_PATTERN);

			psResult += this.resolveFormsPattern(laSecondSplittedStrings[0], poModel, poModel[laSecondSplittedStrings[0]], poParentEntity);

			if (laSecondSplittedStrings.length > 1)
				psResult += laSecondSplittedStrings[1];
		}

		return psResult;
	}

	/** Résoud un pattern en appliquant la valeur associée à celui-ci.
	 * @param psPattern Chaîne de caratères contenant un pattern, sans les accolades.
	 * @param poModel Modèle
	 * @param poDefault Objet par défaut qu'il faut retourner si les pattern ne collent pas.
	 * @param poParentEntity Entité parente.
	 */
	public resolveFormsPattern<T>(psPattern: string, poModel: any, poDefault: any, poParentEntity?: IEntity): T {
		const lsParentEntityUndefinedMessage = `${PatternResolverService.C_LOG_ID}L'entité parente n'est pas définie. Impossible de résoudre le pattern.`;
		let loValue: T;

		if (StringHelper.isBlank(psPattern))
			psPattern = "";

		switch (psPattern) {
			case EPattern.entityGuid:
				loValue = GuidHelper.extractGuid(this.isvcEntityLink.currentEntity.id) as any;
				break;

			case EPattern.entity:
				loValue = this.isvcEntityLink.currentEntity as any;
				break;

			case EPattern.entityId:
				loValue = this.isvcEntityLink.currentEntity.id as any;
				break;

			case EPattern.entityModel:
				loValue = (this.isvcEntityLink.currentEntity.model || {} as any);
				break;

			case EPattern.parentEntityGuid:
				if (poParentEntity)
					loValue = GuidHelper.extractGuid(poParentEntity.id) as any;
				else {
					console.error(lsParentEntityUndefinedMessage, psPattern);
					loValue = poDefault;
				}
				break;

			case EPattern.parentEntityId:
				if (poParentEntity)
					loValue = poParentEntity.id as any;
				else {
					console.error(lsParentEntityUndefinedMessage, psPattern);
					loValue = poDefault;
				}
				break;

			case EPattern.parentEntityModel:
				if (poParentEntity)
					loValue = (poParentEntity.model || {}) as any;
				else {
					console.error(lsParentEntityUndefinedMessage, psPattern);
					loValue = poDefault;
				}
				break;

			case EPattern.guid:
				loValue = GuidHelper.newGuid() as any;
				break;

			case EPattern.guidWithHyphens:
				loValue = GuidHelper.newGuid({ withHyphens: true }) as any;
				break;

			case EPattern.completeName:
				if (UserData.current)
					loValue = ContactHelper.getCompleteFormattedName(UserData.current.firstName, UserData.current.lastName) as any;
				else {
					console.error(`${PatternResolverService.C_LOG_ID}${new NoCurrentUserDataError().message}. Impossible de résoudre le pattern '${psPattern}'.`);
					loValue = poDefault;
				}
				break;

			case EPattern.login:
				loValue = ConfigData.authentication.credentials.login as any;
				break;

			case EPattern.date:
				loValue = new Date().toJSON() as any;
				break;

			default:
				loValue = this.innerDefaultResolveFormsPattern(psPattern, poParentEntity);
				break;
		}

		if (loValue)
			return loValue;
		else {
			this.printPatternError(psPattern);
			return poDefault;
		}
	}

	/** Résoud un pattern en appliquant la valeur associée à celui-ci, cas 'default' du switch.
	 * @param psPattern Chaîne de caratères contenant un pattern, sans les accolades.
	 * @param poParentEntity 
	 */
	private innerDefaultResolveFormsPattern<T>(psPattern: string, poParentEntity?: IEntity): T {
		let loValue: T;

		// Remplace "app.toto" par la valeur contenue dans la propriété "toto" dans le service spécifique de l'application.
		if (psPattern.startsWith(PatternResolverService.C_CUSTOM_SERVICE_KEYWORD))
			loValue = this.moCustomSharedService[ArrayHelper.getLastElement(psPattern.split(`${PatternResolverService.C_CUSTOM_SERVICE_KEYWORD}.`))];

		else if (psPattern.startsWith(EPattern.entityModel))
			loValue = this.isvcEntityLink.currentEntity.model[ArrayHelper.getLastElement(psPattern.split(`${EPattern.entityModel}.`))];

		else if (psPattern.startsWith(EPattern.parentEntityModel) && poParentEntity)
			loValue = poParentEntity.model[ArrayHelper.getLastElement(psPattern.split(`${EPattern.parentEntityModel}.`))];

		return loValue;
	}

	/** Afficher une erreur de résolution de pattern dans la console.
	 * @param psPattern Pattern qui n'a pu être résolu.
	 */
	private printPatternError(psPattern: string): void {
		console.error(`${PatternResolverService.C_LOG_ID}Le pattern "${psPattern}" n'est pas pris en compte.`);
	}

	//#endregion

}