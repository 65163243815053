import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ArrayHelper, StringHelper } from '@osapp/helpers';
import { IContact } from '@osapp/model/contacts/IContact';
import { EAvatarSize } from '@osapp/model/picture/EAvatarSize';
import { IAvatar } from '@osapp/model/picture/IAvatar';
import { Loader } from '@osapp/modules/loading/Loader';
import { ModalComponentBase } from '@osapp/modules/modal/model/ModalComponentBase';
import { PermissionsService } from '@osapp/modules/permissions/services/permissions.service';
import { EPrestationStatus } from '@osapp/modules/prestation/models/eprestation-status.enum';
import { PrestationService } from '@osapp/modules/prestation/services/prestation.service';
import { ContactsService } from '@osapp/services/contacts.service';
import { LoadingService } from '@osapp/services/loading.service';
import { PlatformService } from '@osapp/services/platform.service';
import { defer, of } from 'rxjs';
import { finalize, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { Ordonnance } from '../../../ordonnances/models/ordonnance';
import { OrdonnancesService } from '../../../ordonnances/services/ordonnances.service';
import { FacturationService } from '../../facturation.service';
import { IdlPrestation } from '../../models/idl-prestation';
import { Invoice } from '../../models/invoice';


@Component({
	selector: 'idl-facturation-modal',
	templateUrl: './facturation-modal.component.html',
	styleUrls: ['./facturation-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacturationModalComponent extends ModalComponentBase<boolean> implements OnInit {

	//#region PROPERTIES

	@Input() public prestations: IdlPrestation[];
	@Input() public patientId: string;

	/** Factures créées en fonction des prestations */
	public invoicesToCreate: Invoice[];
	public invoicesCreated: boolean;
	/** Map associant un id d'ordonnance avec une ordonnance. */
	public ordonnancesById = new Map<string, Ordonnance>();
	/** Map associant un id de contact avec un contact. */
	public contactsById = new Map<string, IContact>();

	public get isModeAgrement(): boolean {
		return false;
	}

	public customClose: () => Promise<boolean>;

	//#endregion

	//#region METHODS

	constructor(
		public readonly isvcPermissions: PermissionsService,
		private readonly isvcFacturation: FacturationService,
		private readonly isvcPrestations: PrestationService,
		private readonly isvcOrdonnances: OrdonnancesService,
		private readonly isvcContacts: ContactsService,
		private readonly isvcLoading: LoadingService,
		private readonly ioRouter: Router,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetectorRef);
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this.customClose = () => this.close(this.invoicesCreated);

		let laInvoices: Invoice[];
		let laOrdonnanceIds: string[];
		let laContactsIds: string[];

		let loLoader: Loader;
		defer(() => this.isvcLoading.create("Chargement en cours..."))
			.pipe(
				mergeMap((poLoader: Loader) => poLoader.present()),
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap(() => this.isvcFacturation.createInvoicesFromPrestations(this.patientId, this.prestations)),
				tap((paInvoices: Invoice[]) => {
					laInvoices = paInvoices;
					laContactsIds = laInvoices.map((poInvoice: Invoice) => poInvoice.intervenantId);
					laOrdonnanceIds = laInvoices
						.map((poInvoice: Invoice) => poInvoice.ordonnanceId)
						.filter((psOrdonnanceId: string) => !StringHelper.isBlank(psOrdonnanceId));
				}),
				mergeMap(() => this.isvcContacts.getContactsByIds(ArrayHelper.unique(laContactsIds))),
				tap((paContacts: IContact[]) => this.contactsById = new Map(paContacts.map((poContact: IContact) => [poContact._id, poContact]))),
				mergeMap(() => ArrayHelper.hasElements(laOrdonnanceIds) ? this.isvcOrdonnances.getOrdonnancesByIds(laOrdonnanceIds) : of([])),
				tap((paOrdonnances: Ordonnance[]) => {
					this.ordonnancesById = new Map(paOrdonnances.map((poOrdonnance: Ordonnance) => [poOrdonnance._id, poOrdonnance]));
					this.invoicesToCreate = laInvoices;
					this.detectChanges();
				}),
				mergeMap(() => loLoader.dismiss()),
				finalize(() => loLoader?.dismiss()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public bill() {
		let loLoader: Loader;

		defer(() => this.isvcLoading.create("Préparation des factures en cours"))
			.pipe(
				mergeMap((poLoader: Loader) => poLoader.present()),
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap(() => this.isvcFacturation.saveInvoices(this.invoicesToCreate)),
				mergeMap(() => {
					this.prestations.forEach((poPrestation: IdlPrestation) => poPrestation.status = EPrestationStatus.sent);
					return this.isvcPrestations.savePrestations(this.prestations);
				}),
				tap(() => {
					this.invoicesCreated = true;
					loLoader.dismiss();
					this.detectChanges();
					if (this.invoicesToCreate.length === 1)
						this.goToInvoice(ArrayHelper.getFirstElement(this.invoicesToCreate));
					else
						this.close(true);
				},
					_ => loLoader.dismiss()
				),
				finalize(() => loLoader?.dismiss()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public goToInvoice(poInvoice: Invoice): void {
		if (this.invoicesCreated) {
			this.close(true);
			if (!this.isModeAgrement)
				this.ioRouter.navigate(["/invoices", poInvoice._id]);
		}
	}

	public getContactAvatar(poContact: IContact): IAvatar {
		return ContactsService.createContactAvatar(poContact, EAvatarSize.big);
	}

	//#endregion

}
