import { IStoreDocument } from '../modules/database/IStoreDocument';
import { StringHelper } from './stringHelper';

export abstract class ArrayHelper {

	//#region FIELDS

	/** _index */
	private static readonly C_INDEX_SORT = "_index";

	//#endregion

	//#region METHODS

	/** ### ATTENTION !!! NON TESTÉE !!!
	 * Supprime les doublons de deux tableaux (à l'aide d'une clé), puis retourne le résultat de la concaténation de ces deux tableaux.
	 * @param paArray1 Tableau prioritaire dont il faut supprimer les doublons.
	 * @param paArray2 Tableau dont il faut également supprimer les doublons.
	 * @param pfGetItemKey Lambda permettant de récupérer la clé d'un élément.
	 * @param plPreviousMap Map possédant d'anciennes valeurs si déjà utilisée par l'appelant, optionnel.
	 */
	public static deduplicateElements<T>(paArray1: T[], paArray2: T[], pfGetItemKey: (poItem: T) => string, plPreviousMap?: Map<string, T>): Array<T> {

		if (!plPreviousMap) {
			plPreviousMap = new Map<string, T>(); // Initialisation de la map avec les données du premier tableau.

			paArray1.forEach((poArray1Item: T) => {
				const lsArray1ItemId: string = pfGetItemKey(poArray1Item); // Extrait la clé à utiliser pour cet élément.

				if (!plPreviousMap.get(lsArray1ItemId)) // La map ne contient pas déjà d'élément pour cette clé => on l'ajoute.
					plPreviousMap.set(lsArray1ItemId, poArray1Item);
			});
		}

		// Recherche d'éléments nouveaux dans le deuxième tableau.
		paArray2.forEach((poArray2Item: T) => {
			const lsArray2ItemId: string = pfGetItemKey(poArray2Item); // Extrait la clé à utiliser pour cet élément.
			// Recherche un élément existant pour la même clé, ajouté précédemment (à partir du premier tableau ou d'un autre appel à la méthode).
			const loConcurrentItem: T = plPreviousMap.get(lsArray2ItemId);

			if (!loConcurrentItem) // Pas de doublon => on l'ajoute aux résultats.
				plPreviousMap.set(lsArray2ItemId, poArray2Item);
		});

		return Array.from(plPreviousMap.values());
	}

	/** Récupère le premier élément d'un tableau, `undefined` si le tableau est vide.
	 * @param paArray Tableau dont il faut récupérer le premier élément.
	 */
	public static getFirstElement<T>(paArray: T[]): T;
	/** Récupère le premier élément d'un tableau, `undefined` si le tableau est vide.
	 * @param paArray Tableau dont il faut récupérer le premier élément.
	 */
	public static getFirstElement<T>(paArray: ReadonlyArray<T>): T;
	public static getFirstElement<T>(paArray: T[] | ReadonlyArray<T>): T {
		return this.hasElements(paArray) ? paArray[0] : undefined;
	}

	/** Récupère le dernier élément d'un tableau, `undefined` si aucun élément ou si ce n'est pas un tableau.
	 * @param paArray Tableau dont il faut récupérer le dernier élément.
	 */
	public static getLastElement<T>(paArray: T[]): T;
	/** Récupère le dernier élément d'un tableau, `undefined` si aucun élément ou si ce n'est pas un tableau.
	 * @param paArray Tableau dont il faut récupérer le dernier élément.
	 */
	public static getLastElement<T>(paArray: ReadonlyArray<T>): T;
	public static getLastElement<T>(paArray: T[] | ReadonlyArray<T>): T {
		return this.hasElements(paArray) ? paArray[paArray.length - 1] : undefined;
	}

	/** Supprime un élément spécifique du tableau et le retourne si celui-ci a été supprimé, retourne `undefined` si l'élément n'a pas été trouvé.
	 * @param paArray Tableau contenant l'élément à supprimer.
	 * @param poElementToRemove Objet qu'il faut supprimer du tableau.
	 */
	public static removeElement<T>(paArray: Array<T>, poElementToRemove: T): T {
		let loRemovedElement: T;

		const lnIndex: number = paArray.indexOf(poElementToRemove);
		if (lnIndex !== -1)
			loRemovedElement = this.getFirstElement(paArray.splice(lnIndex, 1));

		return loRemovedElement;
	}

	/** Supprime un élément spécifique du tableau à l'aide d'une fonction et le retourne si celui-ci a été supprimé, retourne `undefined` si l'élément n'a pas été trouvé.
	 * @param paArray Tableau contenant l'élément à supprimer.
	 * @param pfFinder Fonction permettant de trouver l'objet à supprimer.
	 */
	public static removeElementByFinder<T>(paArray: Array<T>, pfFinder: (poItem: T) => boolean): T {
		let loRemovedElement: T;
		const lnIndex: number = paArray.findIndex(pfFinder);

		if (lnIndex !== -1)
			loRemovedElement = this.getFirstElement(paArray.splice(lnIndex, 1));

		return loRemovedElement;
	}

	/** Supprime un élément du tableau à partir de `id` ou `_id` et le retourne si celui-ci a été supprimé, retourne `undefined` si l'id n'est pas valide ou non trouvé).
	 * @param paArray Tableau contenant l'élément à supprimer.
	 * @param psIdToRemove Id de l'objet à supprimer.
	 */
	public static removeElementById<T>(paArray: Array<T>, psIdToRemove: string | number): T {
		return this.removeElementByIndex(paArray, paArray.findIndex((poItem: T) => poItem["_id"] === psIdToRemove || poItem["id"] === psIdToRemove));
	}

	/** Supprime un élément d'un tableau à partir d'un index et le retourne si celui-ci a été supprimé, retourne `undefined` si l'index n'est pas valide.
	 * @param paArray Tableau contenant l'élément à supprimer.
	 * @param pnIndexToRemove Index de l'objet à supprimer.
	 */
	public static removeElementByIndex<T>(paArray: Array<T>, pnIndexToRemove: number): T {
		let loRemovedElement: T;

		if (!isNaN(pnIndexToRemove) && pnIndexToRemove >= 0)
			loRemovedElement = this.getFirstElement(paArray.splice(pnIndexToRemove, 1));

		return loRemovedElement;
	}

	/** Supprimer un élément d'un tableau à partir d'une propriété donnée et la valeur qui identifie l'objet à supprimer, retourne l'élément supprimé ou `undefined` sinon.
	 * @param paArray Tableau contenant l'élément à supprimer.
	 * @param psProperty Propriété avec laquelle on vérifie la présence de l'objet à supprimer.
	 * @param poValue Objet qu'il faut supprimer du tableau.
	 */
	public static removeElementByProperty<T>(paArray: Array<T>, psProperty: string, poValue: any): T {
		let loRemovedElement: T;

		for (let lnIndex = 0; lnIndex < paArray.length; ++lnIndex) {

			if (paArray[lnIndex][psProperty] && paArray[lnIndex][psProperty] === poValue) {
				loRemovedElement = this.getFirstElement(paArray.splice(lnIndex, 1));
				break;
			}
		}

		return loRemovedElement;
	}

	/** Remplace un élément spécifique du tableau à l'aide d'une fonction et retourne l'élément supprimé ou
	 * `undefined` et ajoute à la fin si l'élément n'a pas été remplacé.
	 * @param paArray Tableau contenant l'élément à supprimer.
	 * @param pfFinder Fonction permettant de trouver l'objet à supprimer.
	 * @param poCandidateItem Élément qu'on souhaite placer dans le tableau à la place de celui remplissant le critère de la fonction.
	 * @param pbMustPushCandidateItem Indique si on doit ajouter l'élément candidat dans le tableau s'il n'a pas été trouvé, `true` par défaut.
	 */
	public static replaceElementByFinder<T>(paArray: Array<T>, pfFinder: (poItem: T) => boolean, poCandidateItem: T, pbMustPushCandidateItem: boolean = true): T {
		let loRemovedElement: T;
		const lnIndex: number = paArray.findIndex(pfFinder);

		if (lnIndex === -1) {
			if (pbMustPushCandidateItem)
				paArray.push(poCandidateItem);
		}
		else if (paArray[lnIndex] !== poCandidateItem)
			loRemovedElement = this.getFirstElement(paArray.splice(lnIndex, 1, poCandidateItem));

		return loRemovedElement;
	}

	/** Détermine s'il existe au moins 1 élément dans la liste.
	 * Si la liste n'est pas initialisée, elle est considérée comme vide, et on retourne `false`.
	 */
	public static hasElements<T>(paArray: T[]): boolean;
	/** Détermine s'il existe au moins 1 élément dans la liste.
	 * Si la liste n'est pas initialisée, elle est considérée comme vide, et on retourne `false`.
	 */
	public static hasElements<T>(paArray: ReadonlyArray<T>): boolean;
	public static hasElements<T>(paArray: T[] | ReadonlyArray<T>): boolean {
		return !!paArray && paArray.length > 0;
	}

	/** Permet de comparer 2 tableaux.
	 * @param paArrayA Premier tableau.
	 * @param paArrayB Deuxième tableau.
	 * @param pfPredicate Fonction de comparaison des éléments, par défaut c'est une comparaison simple (itemA === itemB).
	 */
	public static areArraysEqual<T, U = T>(paArrayA: T[], paArrayB: U[], pfPredicate?: (poItemA: T, poItemB: U) => boolean): boolean;
	/** Permet de comparer 2 tableaux.
	 * @param paArrayA Premier tableau.
	 * @param paArrayB Deuxième tableau.
	 * @param pfPredicate Fonction de comparaison des éléments, par défaut c'est une comparaison simple (itemA === itemB).
	 */
	public static areArraysEqual<T, U = T>(paArrayA: ReadonlyArray<T>, paArrayB: ReadonlyArray<U>, pfPredicate?: (poItemA: T, poItemB: U) => boolean): boolean;
	public static areArraysEqual<T, U = T>(paArrayA: T[] | ReadonlyArray<T>, paArrayB: U[] | ReadonlyArray<U>, pfPredicate?: (poItemA: T, poItemB: U) => boolean): boolean {
		if (!pfPredicate)
			pfPredicate = (poItemA: T, poItemB: U) => poItemA === poItemB as unknown as T;

		return (
			!paArrayA && !paArrayB) || (paArrayA && paArrayB && paArrayA.length === paArrayB.length &&
				paArrayA.every((poItemA: T) => paArrayB.some((poItemB: U) => pfPredicate(poItemA, poItemB))) &&
				paArrayB.every((poItemB: U) => paArrayA.some((poItemA: T) => pfPredicate(poItemA, poItemB)))
			);
	}

	/** Verifie si les valeurs du tableau sont toutes vides.
	 * @param paValues Tableau de valeurs de n'importe quel type.
	 */
	public static areAllValuesEmpty<T>(paValues?: T[]): boolean;
	/** Verifie si les valeurs du tableau sont toutes vides.
	 * @param paValues Tableau de valeurs de n'importe quel type.
	 */
	public static areAllValuesEmpty<T>(paValues?: ReadonlyArray<T>): boolean;
	public static areAllValuesEmpty<T>(paValues?: T[] | ReadonlyArray<T>): boolean {

		if (!this.hasElements(paValues))
			return true;
		else {
			return paValues.every((poValue) => {

				if (typeof poValue === "boolean")
					return false;

				else if (!poValue || (poValue instanceof Array && !ArrayHelper.hasElements(poValue)))
					return true;

				else if (typeof poValue === "string")
					return StringHelper.isBlank(poValue);

				else if (typeof poValue === "object")
					return Object.keys(poValue).length === 0;

				else
					return false;
			});
		}
	}

	/** Applique la dernière révision aux éléments du tableau à partir d'un tableau à jour.
	 * @param paMineDocuments Tableau des documents dont il faut mettre à jour les révisions.
	 * @param paStoredDocuments Tableau source des révisions des documents (documents à jour).
	 * @returns Tableau des documents qui ont été mis à jour.
	 */
	public static applyLastRevisions<T extends IStoreDocument>(paMineDocuments: T[], paStoredDocuments: T[]): T[] {
		const laChangedDocuments: T[] = [];

		paStoredDocuments.forEach((poStoredDocument: T) => {
			const loMyDocument: T = paMineDocuments.find((poMyDocument: T) => poMyDocument._id === poStoredDocument._id);

			if (loMyDocument._rev !== poStoredDocument._rev) {
				loMyDocument._rev = poStoredDocument._rev;
				laChangedDocuments.push(loMyDocument);
			}
		});

		return laChangedDocuments;
	}

	/** Aplanit un tableau de tableaux en un unique tableau regroupant tous les éléments de chaque tableau.
	 * @param paArrayOfArrays Tableau (qui peut contenir des tableaux) qu'il faut aplanir (transformer en un seul tableau d'éléments).
	 */
	public static flat<T>(paArrayOfArrays: Array<T | T[]>): T[];
	/** Aplanit un tableau de tableaux en un unique tableau regroupant tous les éléments de chaque tableau.
	 * @param paArrayOfArrays Tableau de tableaux qu'il faut aplanir (transformer en un seul tableau d'éléments).
	 */
	public static flat<T>(paArrayOfArrays: ReadonlyArray<T | T[] | ReadonlyArray<T>>): T[];
	public static flat<T>(paArrayOfArrays: Array<T | T[]> | ReadonlyArray<T | T[] | ReadonlyArray<T>>): T[] {
		// Équivalent à : `return paArrayOfArrays.reduce((poPrevious: T[], poCurrent: T[]) => poPrevious.concat(poCurrent), []);`
		return [].concat(...paArrayOfArrays);
	}

	/** Vide le tableau et retourne les éléments supprimés.
	 * @param paArray 
	 */
	public static clear<T>(paArray: T[]): T[] {
		return this.hasElements(paArray) ? paArray.splice(0, paArray.length) : [];
	}

	/** Permet de récupérer les membres qui sont présents dans `paArrayA` et pas dans `paArrayB`.
	 * @param paArrayA 
	 * @param paArrayB 
	 * @param pfPredicate Fonction qui défini l'égalité entre 2 membres.
	 */
	public static getDifferences<T>(paArrayA: T[], paArrayB: T[], pfPredicate?: (poItemA: T, poItemB: T) => boolean): T[];
	/** Permet de récupérer les membres qui sont présents dans `paArrayA` et pas dans `paArrayB`.
	 * @param paArrayA 
	 * @param paArrayB 
	 * @param pfPredicate Fonction qui défini l'égalité entre 2 membres.
	 */
	public static getDifferences<T>(paArrayA: ReadonlyArray<T>, paArrayB: ReadonlyArray<T>, pfPredicate?: (poItemA: T, poItemB: T) => boolean): T[];
	public static getDifferences<T>(paArrayA: T[] | ReadonlyArray<T>, paArrayB: T[] | ReadonlyArray<T>, pfPredicate?: (poItemA: T, poItemB: T) => boolean): T[] {
		if (!pfPredicate)
			pfPredicate = (poItemA: T, poItemB: T) => poItemA === poItemB;

		return paArrayA.filter((poItemA: T) => !paArrayB.some((poItemB: T) => pfPredicate(poItemA, poItemB)));
	}

	/** Permet de récupérer les membres qui sont présents dans `paArrayA` et dans `paArrayB`.
	 * @param paArrayA 
	 * @param paArrayB 
	 * @param pfPredicate Fonction qui défini l'égalité entre 2 membres.
	 */
	public static intersection<T, U = T>(paArrayA: T[], paArrayB: U[], pfPredicate?: (poItemA: T, poItemB: U) => boolean): T[];
	/** Permet de récupérer les membres qui sont présents dans `paArrayA` et dans `paArrayB`.
	 * @param paArrayA 
	 * @param paArrayB 
	 * @param pfPredicate Fonction qui défini l'égalité entre 2 membres.
	 */
	public static intersection<T, U = T>(paArrayA: ReadonlyArray<T>, paArrayB: ReadonlyArray<U>, pfPredicate?: (poItemA: T, poItemB: U) => boolean): T[];
	public static intersection<T, U = T>(paArrayA: T[] | ReadonlyArray<T>, paArrayB: U[] | ReadonlyArray<U>, pfPredicate?: (poItemA: T, poItemB: U) => boolean): T[] {
		if (!pfPredicate)
			pfPredicate = (poItemA: T, poItemB: U) => poItemA === poItemB as any as T;

		return paArrayA.filter((poItemA: T) => paArrayB.some((poItemB: U) => pfPredicate(poItemA, poItemB)));
	}

	public static getType<T>(paArray: T[] | ReadonlyArray<T>): string {
		if (!ArrayHelper.hasElements(paArray))
			return "void";

		const lsFirstType: string = typeof ArrayHelper.getFirstElement(paArray);
		if (paArray.every((poValue: T) => typeof poValue === lsFirstType))
			return lsFirstType;
		else
			return "multiple";
	}

	/** Ajoute un élément dans un tableau s'il n'est pas déjà présent.
	 * @param paArray Tableau dans lequel ajouter un élément si non présent.
	 * @param poElement Élément à ajouter au tableau s'il n'est pas déjà présent.
	 * @param pfIsPresent Prédicat permettant de tester si l'élément est déjà présent (est égal à un autre) ou non.
	 * @returns Le nombre d'éléments dans le tableau.
	 */
	public static pushIfNotPresent<T>(paArray: T[] | ReadonlyArray<T>, poElement: T, pfIsPresent: (poItem: T) => boolean): number {
		if (!paArray.some(pfIsPresent))
			(paArray as T[]).push(poElement);

		return paArray.length;
	}

	/** Groupe les membres d'un tableau en fonction du selecteur passé en paramètre.
	 * @param paArray 
	 * @param pfKeySelector 
	 */
	public static groupBy<K, V>(paArray: V[], pfKeySelector: (poItem: V) => K): Map<K, V[]> {
		const loMap = new Map<K, V[]>();
		const laKeys: K[] = paArray.map(pfKeySelector);

		laKeys.forEach((poKey: K, pnIndex: number) => {
			let laResults: V[] = loMap.get(poKey);

			if (!ArrayHelper.hasElements(laResults))
				laResults = [];

			laResults.push(paArray[pnIndex]);
			loMap.set(poKey, laResults);
		});

		return loMap;
	}

	/** Retourne un tableau d'objets uniques à partir d'un tableau d'objets.
	 * @param paArray Tableau dont il faut récupérer les objets de manière unique.
	 */
	public static unique<T extends IStoreDocument | string | number | boolean>(paArray: T[]): T[];
	/** Retourne un tableau d'objets uniques à partir d'un tableau d'objets readonly.
	 * @param paArray Tableau readonly dont il faut récupérer les objets de manière unique.
	 */
	public static unique<T extends IStoreDocument | string | number | boolean>(paArray: ReadonlyArray<T>): T[];
	/** Retourne un tableau d'objets uniques à partir d'un tableau d'objets.
	 * @param paArray Tableau dont il faut récupérer les objets de manière unique.
	 * @param pfGetKey Fonction permettant de récupérer une clé unique associé à un objet du tableau.
	 */
	public static unique<T>(paArray: T[], pfGetKey: (poItem: T) => string): T[];
	/** Retourne un tableau d'objets uniques à partir d'un tableau d'objets readonly.
	 * @param paArray Tableau dont il faut récupérer les objets de manière unique.
	 * @param pfGetKey Fonction permettant de récupérer une clé unique associé à un objet du tableau.
	 */
	public static unique<T>(paArray: ReadonlyArray<T>, pfGetKey: (poItem: T) => string): T[]
	public static unique<T>(paArray: T[] | ReadonlyArray<T>, pfGetKey?: (poItem: T) => string): T[] {
		if (!this.hasElements(paArray))
			return [];
		else if (typeof this.getFirstElement(paArray) === "object") {
			const loMap: Map<string, T> = new Map();

			if (!pfGetKey) // Si on manipule un tableau de `IStoreDocument`.
				pfGetKey = (poItem: T) => (poItem as any as IStoreDocument)._id; // On définit la méthode d'obtention de clé pour n'avoir qu'un chemin d'obtention.

			paArray.forEach((poItem: T) => {
				const lsKey: string = pfGetKey(poItem);
				if (!loMap.has(lsKey))
					loMap.set(lsKey, poItem);
			});

			return Array.from(loMap.values());
		}
		else
			return Array.from(new Set(paArray));
	}

	//#endregion
}