import { Inject, Injectable, InjectionToken, Injector, Optional, Type } from '@angular/core';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { IEntity } from '../../../model/entities/IEntity';
import { DmsFileHelper } from '../helpers/dmsFileHelper';
import { IDmsMeta } from '../model/IDmsMeta';
import { IMetaEntityBuilderBase } from '../model/IMetaEntityBuilderBase';
import { MetaConversationBuilder } from '../model/MetaConversationBuilder';
import { MetaEntityBuilderBase } from '../model/MetaEntityBuilderBase';

export const DMS_META_CONFIG = new InjectionToken<Type<IMetaEntityBuilderBase>[]>("DMS_META_CONFIG");

@Injectable()
export class DmsMetaService {

	//#region FIELDS

	private maMetaEntityInfosBuilders: IMetaEntityBuilderBase[] = [];

	//#endregion

	//#region PROPERTIES



	//#endregion

	//#region METHODS	

	constructor(
		private readonly ioMetaEntityBuilderBase: MetaEntityBuilderBase,
		private readonly ioMetaConversationBuilder: MetaConversationBuilder,
		private readonly ioInjector: Injector,
		@Inject(DMS_META_CONFIG) @Optional() private readonly iaMetaEntityInfosBuildersTypes: Type<IMetaEntityBuilderBase>[]
	) { }

	private initBuilders(): void {
		if (ArrayHelper.hasElements(this.iaMetaEntityInfosBuildersTypes)) {
			this.maMetaEntityInfosBuilders = this.iaMetaEntityInfosBuildersTypes.map((poType: Type<IMetaEntityBuilderBase>) => {
				try {
					return this.ioInjector.get(poType);
				}
				catch (poError) {
					console.error("DMS.META.S::Erreur lors de l'initialisation du DmsMetaService. Des providers sont manquants pour ce builder.", poType, poError);
					return undefined;
				}
			});
		}

		// On supprime les valeurs undefined du tableau pour fonctionner même si il manque des providers.
		this.maMetaEntityInfosBuilders = this.maMetaEntityInfosBuilders.filter((poBuilder: MetaEntityBuilderBase) => !!poBuilder);
		this.maMetaEntityInfosBuilders.push( // On ajoute les builders par défaut de la lib.
			this.ioMetaConversationBuilder,
			this.ioMetaEntityBuilderBase // On l'ajoute à la fin pour être sûr d'avoir un builder qui match.
		);
	}

	/** Prépare les meta du DMS en ajoutant les données relatives à l'entité concernée.
	 * @param poMeta 
	 * @param poEntity
	 */
	public prepareEntityMeta(poMeta: IDmsMeta, poEntity: IEntity): Promise<IDmsMeta> {
		if (ArrayHelper.hasElements(poMeta.attributes) ||
			(!StringHelper.isBlank(poMeta.documentType) && DmsFileHelper.getDefaultDocumentType() !== poMeta.documentType))
			return Promise.resolve(poMeta);

		return this.getBuilderForEntity(poEntity).prepareMeta(poEntity, poMeta);
	}

	private getBuilderForEntity(poEntity: IEntity): IMetaEntityBuilderBase {
		if (!ArrayHelper.hasElements(this.maMetaEntityInfosBuilders))
			this.initBuilders();

		return this.maMetaEntityInfosBuilders.find((poBuilder: MetaEntityBuilderBase) => poBuilder.match(poEntity.id));
	}

	//#endregion

}
