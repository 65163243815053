<div class="page-detail-patient__container">
	<div class="page-detail-patient__content">
		<div class="content__buttons">
			<lua-bouton-icone
				[isDisabled]="!patient?.phone"
				iconName="phone"
				mode="outlined"
			></lua-bouton-icone>
			<div #menuPatient>
				<lua-bouton-icone (click)="openMenu()" iconName="more_vert"></lua-bouton-icone>
			</div>
		</div>
		<div class="content__title">
			<div class="title__container">
				<div class="container__icon">
					<lua-icone [isIcon]="true" [customIconName]="getPatientGender(patient?.gender) || 'homme'" width="60px"
						height="60px" [color]="genderColor"></lua-icone>
				</div>
				<div class="container__patient-info">
					<div class="patient-info__name-container" [ngClass]="{'patient-info__deceased' : patient?.deceased}">
						<h1 class="name-container__last-name">
							{{ patient?.lastName | uppercase }}
						</h1>
						<h1 class="name-container__first-name">{{ patient?.firstName }}</h1>
					</div>
					<small>{{ age + ' - ' + birthDate }}</small>
				</div>
				<div class="container__tags">
					<lua-tag
						*ngIf="patient?.deceased"
						color="Noir"
						label="Patient décédé"
						mode="outlined"
					></lua-tag>
					<lua-tag
						*ngIf="patient?.ald && (patient?.ald=='1')"
						color="CouleurPrimaire"
						label="ALD"
						mode="filled"
					></lua-tag>
					<ng-container *ngFor="let pathologie of patient?.pathologies">
						<lua-tag color="CouleurPrimaire" [label]="pathologie" mode="outlined"></lua-tag>
					</ng-container>
				</div>
			</div>
		</div>
		<div class="content__body">
			<div class="body__header">
				<div class="header__options">
					<lua-tab-group>
						<lua-tab label="Informations" [active]="true"></lua-tab>
						<lua-tab label="Fil de soins" [active]="false"></lua-tab>
						<lua-tab label="Surveillance" [active]="false"></lua-tab>
					</lua-tab-group>
				</div>
			</div>
			<div class="body__container">
				<div class="container__column">
					<div class="column__section">
						<h2 class="section__title">Comment y aller ?</h2>
						<div class="section__body">
							<div class="body__bouton-panneau">
								<di-bouton-panneau
									iconName="location_on"
									placeHolder="Ajouter l'adresse"
									[content]="getAddress()"
									(click)="openPanneauAdresse()"
								></di-bouton-panneau>
							</div>
							<lua-divider-horizontal size="small"></lua-divider-horizontal>
							<div class="body__bouton-panneau">
								<di-bouton-panneau iconName="floor" placeHolder="Ajouter l'étage" [content]="patient?.floor"
									(click)="openPanneauAdresse()"></di-bouton-panneau>
							</div>
							<lua-divider-horizontal size="small"></lua-divider-horizontal>
							<div class="body__bouton-panneau">
								<di-bouton-panneau iconName="key" placeHolder="Ajouter le code d'accès" [content]="patient?.accessCode"
									(click)="openPanneauAdresse()"></di-bouton-panneau>
							</div>
						</div>
					</div>
					<div class="column__section">
						<h2 class="section__title">Contacter le patient</h2>
						<div class="section__body">
							<div class="body__bouton-panneau">
								<di-bouton-panneau iconName="phone" placeHolder="Ajouter le téléphone" [content]="patient?.phone"
									(click)="openPanneauAdresse()">
								</di-bouton-panneau>
							</div>
							<lua-divider-horizontal size="small"></lua-divider-horizontal>

							<div class="body__bouton-panneau">
								<di-bouton-panneau iconName="key" placeHolder="Ajouter l'email" [content]="patient?.email"
									(click)="openPanneauAdresse()"></di-bouton-panneau>
							</div>
						</div>
					</div>
				</div>
				<div class="container__column">
					<div class="column__section">
						<h2 class="section__title">Médecin traitant</h2>
						<div class="section__body">
							<di-bouton-panneau [large]="true" [dotted]="!doctor" placeHolder="Ajouter le médecin traitant"
								(click)="openPanneauMedecin()" [hasContent]="!!doctor">
								<di-ligne-contact *ngIf="!!doctor" [contact]="doctor" [selectable]="false">
								</di-ligne-contact>
							</di-bouton-panneau>
						</div>
					</div>
					<div class="column__section">
						<h2 class="section__title">Qui doit on contacter ?</h2>
						<div class="section__body">
							<di-bouton-panneau [large]="true" [dotted]="!contact" placeHolder="Ajouter un contact"
								(click)="openPanneauContact()" [hasContent]="!!contact">
								<di-ligne-contact *ngIf="!!contact" [contact]="contact" [selectable]="false">
								</di-ligne-contact>
							</di-bouton-panneau>
						</div>
					</div>
				</div>
				<div class="container__column">
					<div class="column__section">
						<h2 class="section__title">Sécurité sociale</h2>
						<div class="section__body">
							<div class="body__bouton-panneau">
								<di-bouton-panneau
									[large]="!patient?.socialNumber"
									[dotted]="!patient?.socialNumber"
									placeHolder="Renseigner le régime obligatoire"
									(click)="openPanneauSecuriteSociale(activeAmo)"
									[content]="formatSocialSecurityNumber()"
								>
								</di-bouton-panneau>
							</div>
							<ng-container *ngIf="etablissementAmo">
								<lua-divider-horizontal size="small"></lua-divider-horizontal>
								<div class="body__bouton-panneau">
									<di-bouton-panneau [large]="false" (click)="openPanneauSecuriteSociale(activeAmo)"
										[content]="etablissementAmo.label + '<br>' + getFormatedId(etablissementAmo)"></di-bouton-panneau>
								</div>
							</ng-container>
						</div>
					</div>
					<div class="column__section">
						<h2 class="section__title">Mutuelle</h2>
						<div class="section__body">
							<div class="body__bouton-panneau">
								<di-bouton-panneau *ngIf="etablissementsAmc?.length == 0" [large]="true" [dotted]="true"
									placeHolder="Renseigner la mutuelle" (click)="openPanneauMutuelle()"></di-bouton-panneau>
							</div>
							<ng-container *ngFor="let amc of etablissementsAmc">
								<div class="body__bouton-panneau">
									<di-bouton-panneau [large]="false" (click)="openPanneauMutuelle(amcMap.get(amc._id))"
										[content]="amc.label + '<br>' + getNumAmc(amc._id)"></di-bouton-panneau>
									<lua-divider-horizontal *ngIf="etablissementsAmc?.length > 1" size="small"></lua-divider-horizontal>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
			<lua-alerte *ngIf="errorMessage" type="error" [message]="errorMessage" [filled]="true"></lua-alerte>
		</div>
	</div>
</div>