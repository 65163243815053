<ion-item-sliding #itemSliding *ngIf="traitement">
	<ion-item color="tertiary" class="treatment-item transform-lg-up">
		<div class="item-ctn">
			<div class="avatar-area">
				<calao-avatars *ngIf="avatarIntervenant && avatarIntervenant.avatar" [avatar]="avatarIntervenant.avatar"
					[avatarsCount]="avatarIntervenant.avatarsCount">
				</calao-avatars>
			</div>

			<div class="item-detail">
				<ion-label *ngIf="nbSeancesTotalAValider > 0" class="title">Traitement en cours</ion-label>
				<ion-label *ngIf="nbSeancesTotalAValider <= 0" class="title">Traitement terminé</ion-label>
				<ion-label>
					<span *ngIf="nbSeancesTotalAValider > 0">
						{{ nbSeancesAFacturer }}/{{ nbSeancesTotalAValider }} {{nbSeancesAFacturer < 2 ? 'séance' : 'séances' }} à facturer </span> 
						<span *ngIf="nbSeancesTotalAValider > 0">: {{ mtTotalSeancesAFacturer | priceFormat:2 }} €</span>
						<span *ngIf="nbSeancesTotalAValider <= 0">Toutes les séances de ce traitement ont été validées/facturées </span> 
				</ion-label>
				<ion-label>
					Du {{ traitement?.beginDate | dateWithLocale: 'fr-FR' : 'dd/MM/yyyy' }}
					<span *ngIf="traitement?.endDate as endDate">
						au {{ endDate | dateWithLocale: 'fr-FR' : 'dd/MM/yyyy' }}
					</span>
				</ion-label>
			</div>
		</div>

		<!-- Bouton des options de l'item. -->
		<ion-button *ngIf="afficherBtnFacturer" class="btn-no-margin btn-more btn-white ion-hide-lg-up" fill="clear" slot="end"
			(click)="openOrCloseItemSliding(itemSliding, $event)">
			<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
		</ion-button>

		<div *ngIf="afficherBtnFacturer" class="buttons ion-hide-lg-down">
			<!-- A conserver jusqu'à décision du btn éditer -->
			<!-- <ion-button shape="round" fill="clear" color="dark" size="big"
				(click)="validateAndOpenPrestations(traitement);">
				<ion-icon slot="start" name="create"></ion-icon>
				<ion-label>Éditer</ion-label>
			</ion-button> -->
			<ng-container>
				<ion-button color="success" shape="round" size="big"
					(click)="validateAndBillPrestations(traitement)">
					<ion-icon slot="start" name="invoice"></ion-icon>
					<ion-label>Préparer</ion-label>
				</ion-button>
			</ng-container>
		</div>
	</ion-item>

	<!-- Options des items.-->
	<ion-item-options *ngIf="afficherBtnFacturer" class="ion-hide-lg-up" side="end" (ionSwipe)="openOrCloseItemSliding(itemSliding)">
		<!-- A conserver jusqu'à décision du btn éditer -->
		<!-- <ion-button class="option-btn edit-btn" fill="clear" color="dark"
			(click)="itemSliding.close(); validateAndOpenPrestations(traitement);">
			<ion-icon slot="icon-only" name="create"></ion-icon>
			<ion-label class="ion-hide-sm-down">Éditer</ion-label>
		</ion-button> -->

		<ng-container>
			<ion-button class="option-btn" color="success"
				(click)="itemSliding.close(); validateAndBillPrestations(traitement)">
				<ion-icon slot="icon-only" name="invoice"></ion-icon>
				<ion-label class="ion-hide-sm-down">Préparer</ion-label>
			</ion-button>
		</ng-container>
	</ion-item-options>
</ion-item-sliding>