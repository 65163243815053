import { ChangeDetectorRef, Component } from '@angular/core';
import { ActionButtonFieldBaseComponent } from '@osapp/components/forms/form/customFields/actionButtons/actionButtonFieldBase.component';
import { IStoreDocument } from '@osapp/model/store/IStoreDocument';
import { FormsService } from '@osapp/services/forms.service';
import { SlideboxService } from '@osapp/services/slidebox.service';
import { EMPTY, Observable } from 'rxjs';
import { IdlActionButtonService } from '../../services/idl-action-button.service';

@Component({ template: "" })
export abstract class IdlActionButtonFieldBase<T extends IStoreDocument = IStoreDocument> extends ActionButtonFieldBaseComponent<void, T> {

	//#region METHODS

	constructor(
		protected readonly isvcSlidebox: SlideboxService,
		protected readonly isvcActionButton: IdlActionButtonService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	/** Erreur lors d'un slide après un clic sur un bouton d'action.
	 * @param poError Erreur survenue lors du slide.
	 * @param psSlideId Identifiant de la slide vers laquelle on devait aller.
	 */
	protected onSlideToError(poError: any, psSlideId: string): Observable<never> {
		console.error(`IDL.ABF.S:: slide '${psSlideId}' introuvable, erreur :`, poError);
		return EMPTY;
	}

	//#endregion
}