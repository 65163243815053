import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IonicModule } from '@ionic/angular';
import { DateModule } from '@osapp/components/date/date.module';
import { SearchModule } from '@osapp/components/search';
import { SharedComponentsModule } from '@osapp/components/sharedComponents.module';
import { CalaoAvatarModule } from '@osapp/modules/avatar/calao-avatar.module';
import { ModalModule } from '@osapp/modules/modal';
import { FavoritesModule } from '@osapp/modules/preferences/favorites/favorites.module';
import { RecentsModule } from '@osapp/modules/preferences/recents/recents.module';
import { SelectorModule } from '@osapp/modules/selector/selector.module';
import { PipeModule } from '@osapp/pipes/pipes.module';
import { ActeEditComponent } from './acte-edit/acte-edit.component';
import { ActePersonalizationModalComponent } from './acte-personalization-modal/acte-personalization-modal.component';
import { ActesListModaleComponent } from './actes-list-modale/actes-list-modale.component';
import { ActesListComponent } from './actes-list/actes-list.component';
import { ActesService } from './actes.service';
import { ConcatActesPipe, C_RESUME_ACTE__MODE } from './concat-actes.pipe';
import { IntervenantNamePipe } from './intervenant-name.pipe';
import { EResumeActeMode } from './model/EResumeActeMode';
import { SearchActeComponent } from './search-acte/search-acte.component';

const components: Type<any>[] = [
	ActesListComponent,
	ActesListModaleComponent,
	ConcatActesPipe,
	ActeEditComponent,
	IntervenantNamePipe,
	ActePersonalizationModalComponent,
	SearchActeComponent
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		CommonModule,
		IonicModule,
		SearchModule,
		ModalModule,
		RecentsModule.forRoot({ limit: 3 }),
		FavoritesModule,
		FormsModule,
		CalaoAvatarModule,
		PipeModule,
		DateModule,
		MatTooltipModule,
		SelectorModule,
		MatFormFieldModule,
		MatInputModule,
		MatChipsModule,
		MatExpansionModule,
		ModalModule,
		MatSelectModule,
		SharedComponentsModule
	],
	providers: [
		ConcatActesPipe,
		ActesService,
		DecimalPipe,
		IntervenantNamePipe,
		{ provide: C_RESUME_ACTE__MODE, useValue: EResumeActeMode.label }
	]
})
export class ActesModule { }