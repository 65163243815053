export class Page {
  //#region FIELDS
  
  //#endregion
  
  //#region PROPERTIES
  
  //#endregion
  
  //#region METHODS
  constructor(public pageId: string, public components: string[]) {
  }
  //#endregion
}