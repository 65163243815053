import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ModalComponentBase } from '@osapp/modules/modal/model/ModalComponentBase';
import { PlatformService } from '@osapp/services/platform.service';
import { takeUntil } from 'rxjs/operators';
import { Traitement } from '../../../../model/Traitement';
import { Ordonnance } from '../../models/ordonnance';
import { OrdonnancesService } from '../../services/ordonnances.service';

@Component({
	selector: 'idl-ordonnances-gallery-selector-modal',
	templateUrl: './ordonnances-gallery-selector-modal.component.html',
	styleUrls: ['./ordonnances-gallery-selector-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdonnancesGallerySelectorModalComponent extends ModalComponentBase<Ordonnance[]> {

	//#region PROPERTIES

	@Input() public ordonnances: Ordonnance[];
	@Input() public traitement: Traitement;
	@Input() public unique: boolean;

	private maPreselectedOrdonnancesIds: string[];
	public get preselectedOrdonnancesIds(): string[] {
		return this.maPreselectedOrdonnancesIds;
	}
	@Input() public set preselectedOrdonnancesIds(paPreselectedOrdonnancesIds: string[]) {
		if (paPreselectedOrdonnancesIds) {
			this.maPreselectedOrdonnancesIds = paPreselectedOrdonnancesIds;
			this.selectedOrdonnancesIds.push(...this.maPreselectedOrdonnancesIds);
			this.selectedOrdonnancesIds = ArrayHelper.unique(this.selectedOrdonnancesIds);
			this.detectChanges();
		}
	}

	public selectedOrdonnancesIds: string[] = [];

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcOrdonnance: OrdonnancesService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService
	) {
		super(poModalCtrl, psvcPlatform);
	}

	public openOrdonnance(poOrdonnance: Ordonnance): void {
		this.isvcOrdonnance.editOrdonnance(this.traitement, poOrdonnance)
			.pipe(takeUntil(this.destroyed$))
			.subscribe();
	}

	public toggleSelection(poOrdonnance: Ordonnance): void {
		if (this.selectedOrdonnancesIds.includes(poOrdonnance._id))
			ArrayHelper.removeElement(this.selectedOrdonnancesIds, poOrdonnance._id);
		else
			this.selectedOrdonnancesIds.push(poOrdonnance._id);
		this.detectChanges();
	}

	/** @override */
	public close(): Promise<boolean> {
		return super.close(this.ordonnances.filter((poOrdonnance: Ordonnance) => this.selectedOrdonnancesIds.includes(poOrdonnance._id)));
	}

	//#endregion

}
