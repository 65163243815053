<osapp-header-modal title="Demande d'accord préalable"></osapp-header-modal>

<ion-content>
	<ion-grid fixed>
		<ion-row class="input-row">
			<ion-col size="auto" class="label">
				<ion-label>Date de la demande : </ion-label>
			</ion-col>
			<ion-col size="auto">
				<osapp-date-time [model]="accord.dateDemande ?? traitement.beginDate" [params]="dateParamsDate"
					(modelChange)="onDemandeDateChanged($event)">
				</osapp-date-time>
			</ion-col>
		</ion-row>

		<ion-row class="input-row">
			<ion-col size="auto" class="label">
				<ion-label>Demandeur : </ion-label>
			</ion-col>
			<ion-col size="auto">
				<contacts-list [params]="contactsListParamsForDemandeur" [model]="accord.demandeurAPId"
					(modelChange)="onDemandeurModelChanged($event)">
				</contacts-list>
			</ion-col>
		</ion-row>
		<ion-row class="input-row">
			<ion-col size="auto">
				<ion-list>
					<ion-item *ngFor="let acte of filteredActes">
						<ion-note slot="end">{{ acte.keyLetters }} {{ acte.priceCoefficient }}</ion-note>
						<ion-label>{{ acte.ngapLabel }}</ion-label>
					</ion-item>
				</ion-list>
			</ion-col>
		</ion-row>
		<ion-row class="input-row">
			<ion-col size="auto" class="label">
				<ion-label>Actes urgents : </ion-label>
			</ion-col>
			<ion-col size="auto">
				<ion-toggle [(ngModel)]="accord.isUrgent"></ion-toggle>
			</ion-col>
		</ion-row>
		<ion-row class="input-row">
			<ion-col size="auto" class="label">
				<ion-label>Actes réalisés en série : </ion-label>
			</ion-col>
			<ion-col size="auto">
				<ion-toggle [(ngModel)]="accord.isSerie"></ion-toggle>
			</ion-col>
		</ion-row>
		<ion-row class="input-row">
			<ion-col size="auto" class="label elementMedicaux">
				<ion-label>Eléments médicaux justifiants les actes : </ion-label>
				<br>
				<mat-form-field appearance="outline" class="observation-field">
					<mat-label>Ajoutez les justificatifs</mat-label>
					<textarea [(ngModel)]="accord.elementMedicaux" matInput></textarea>
				</mat-form-field>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-content>

<ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
	<ion-fab-button *ngIf="accord._rev" color="primary" (click)="exportAP()" title="Exporter">
		<!-- <ion-fab-button *ngIf="false" color="primary" (click)="exportAP()" title="Exporter"> -->
		<ion-icon name="document"></ion-icon>
	</ion-fab-button>
	<ion-fab-button color="success" (click)="close()" title="Valider">
		<ion-icon name="checkmark"></ion-icon>
	</ion-fab-button>
</ion-fab>