import { BehaviorSubject, defer, Observable, of, Subject, timer } from "rxjs";
import { concatMap, filter, finalize, tap } from "rxjs/operators";
import { ArrayHelper } from "../../../../helpers/arrayHelper";
import { NumberHelper } from "../../../../helpers/numberHelper";
import { bufferUntil } from "../../rxjs/operators/buffer-until";
import { IQueuerParams } from "./iqueuer-params";

export class Queuer<T, P = void> {

	//#region FIELDS

	private moQueueSubject?: Subject<P>;
	private moActionRunningCounterSubject?: BehaviorSubject<number>;
	private moExecSubject?: Subject<P>;

	//#endregion

	//#region METHODS

	constructor(private readonly moParams: IQueuerParams<P, T>) { }

	/** Débute la file. */
	public start(): Observable<T> {
		this.initSubjects();

		this.moQueueSubject.asObservable()
			.pipe(
				filter(() => !this.moParams.excludePendings || this.moActionRunningCounterSubject.value === 0),
				bufferUntil(() => this.moActionRunningCounterSubject.asObservable().pipe(filter((pnRunningCounter: number) => pnRunningCounter === 0))),
				tap((paValues: P[]) => {
					if (this.moParams.keepOnlyLastPending)
						this.sendExecRequest(ArrayHelper.getLastElement(paValues));
					else
						paValues.forEach((poParams: P) => this.sendExecRequest(poParams));
				})
			)
			.subscribe();

		return this.execQueue();
	}

	private initSubjects(): void {
		this.moQueueSubject = new Subject<P>();
		this.moActionRunningCounterSubject = new BehaviorSubject<number>(0);
		this.moExecSubject = new Subject<P>();
	}

	private sendExecRequest(poParams: P): void {
		this.moExecSubject.next(poParams);
	}

	private execQueue(): Observable<T> {
		return this.moExecSubject.asObservable()
			.pipe(
				tap(() => this.moActionRunningCounterSubject.next(this.moActionRunningCounterSubject.value + 1)),
				concatMap((poParams: P) =>
					defer(() => this.moParams.thingToQueue(poParams)).pipe(finalize(() => this.waitBeforeEndEvent()))
				)
			);
	}

	private waitBeforeEndEvent(): void {
		// Si on veut un écart minimum entre 2 exécutions, on attend avant d'envoyer l'événement de fin.
		defer(() => NumberHelper.isValid(this.moParams.minimumGapMs) ? timer(this.moParams.minimumGapMs) : of(null))
			.pipe(finalize(() => this.moActionRunningCounterSubject.next(this.moActionRunningCounterSubject.value - 1)))
			.subscribe();
	}

	/** Ajoute une exécution dans la file. */
	public exec(poParams: P): void {
		this.moQueueSubject?.next(poParams);
	}

	/** Termine la file. */
	public end(): void {
		this.moQueueSubject?.complete();
		this.moExecSubject?.complete();
		this.moActionRunningCounterSubject?.complete();
	}

	//#endregion

}