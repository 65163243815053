import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { UserContactModule } from '../../modules/contacts/userContact/user-contact.module';
import { AuthenticatorComponent } from './authenticator.component';
import { AuthenticatorSettingsComponent } from './authenticatorSettings/authenticatorSettings.component';


/**
 * Module de gestion des formlist
 */
@NgModule({
	imports: [IonicModule, CommonModule, FormsModule, RouterModule, UserContactModule],
	exports: [AuthenticatorComponent, AuthenticatorSettingsComponent],
	declarations: [AuthenticatorComponent, AuthenticatorSettingsComponent],
	providers: []
})
export class AuthenticatorModule {
}
