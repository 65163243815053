<div class="di-inviter-utilisateur">
	<h1 class="title">Inviter un utilisateur</h1>
	<small>Vous pouvez inviter un utilisateur ayant déjà un compte DESMOS à vous
		rejoindre pour travailler avec vous (par exemple pour les
		remplaçants)</small>
	<div class="form">
		<lua-input label="Email de connexion" [fullWidth]="true" [value]="email"
			(valueChange)="handleEmailChange($event)"></lua-input>
		<div class="content" *ngIf="showInviterUtilisateur">
			<lua-action-principale libelle="Inviter" [fullWidth]="true"
				(click)="rechercherUtilisateurByMail()"></lua-action-principale>
			<!-- à activer plus tard-->
			<!-- <lua-action-secondaire
				libelle="Pas encore de compte DESMOS ?"
				[fullWidth]="true"
				(click)="createCompte()"
			></lua-action-secondaire> -->
			<lua-bouton-texte libelle="Annuler" [fullWidth]="true" (click)="handelAnnuler()"></lua-bouton-texte>
		</div>
		<lua-message-action-en-cours *ngIf="showLoader" mainText="Recherche de l'utilisateur"
			subText="Nous cherchons le compte utilisateur correspondant à cet email"></lua-message-action-en-cours>
		<lua-message-aucune-donnee *ngIf="showAucunUtilisateur" mainText="Aucun utilisateur trouvé"
			subText="Nous n'avons pas trouvé d'utilisateur DESMOS correspondant à cette adresse email."></lua-message-aucune-donnee>
	</div>
</div>