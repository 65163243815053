import { Injectable } from '@angular/core';
import { compare, hash } from 'bcryptjs';
import { StringHelper } from '../../../../helpers/stringHelper';
import { ELogActionId } from '../../../logger/models/ELogActionId';
import { IPinLogAction } from '../../../logger/models/ipin-log-action';
import { LoggerService } from '../../../logger/services/logger.service';
import { IPin } from '../models/ipin';

@Injectable({ providedIn: "root" })
export class PinService {

	//#region FIELDS

	private static readonly C_STORAGE_LAST_PIN_KEY = "last_pin";
	private static readonly C_LOG_ID = "PIN.S::";
	private static readonly C_STORAGE_ATTEMPTS_KEY = "pin_attempts";
	private static readonly C_MAX_ATTEMPTS = 3;

	//#endregion

	//#region PROPERTIES

	/** Vérifie qu'un PIN est sauvegardé.
	 * @returns `true` si un PIN est sauvegardé `false` sinon.
	 */
	public get isSaved(): boolean {
		const loLastPin: IPin = this.getSaved();
		return (StringHelper.isValid(loLastPin.login) && StringHelper.isValid(loLastPin.hashedPin));
	}

	//#endregion

	//#region METHODS

	constructor(private readonly isvcLogger: LoggerService) { }

	/** Retourne vrai si le mot de passe est un PIN à 4 chiffres, faux sinon.
	 * @param psPassword est le mot de passe.
	 * @returns `true` si le mot de passe est un code PIN valide sinon `false`.
	 */
	public isValid(psPassword: string): boolean {
		return /^[0-9]{4}$/.test(psPassword);
	}

	/** Sauvegarde le hash d'un code PIN dans le LocalStorage.
	 * @param psLogin identifiant de l'utilisateur.
	 * @param psPassword mot de passe.
	 */
	public save(psLogin: string, psPassword: string): Promise<void> {
		this.setAttempts(0);
		return hash(psPassword, 10).then((psHash: string) => {
			try {
				// Le localStorage n'enregistre que des strings il faut donc parser pour récupérer l'objet.
				localStorage.setItem(PinService.C_STORAGE_LAST_PIN_KEY, JSON.stringify({ login: psLogin, hashedPin: psHash } as IPin));
			} catch (poErr) {
				console.error(`${PinService.C_LOG_ID}Erreur lors de l'enregistrement du pin:`, poErr);
			}
		});
	}

	/** Supprime le PIN sauvegardé. */
	private delete(): void {
		localStorage.removeItem(PinService.C_STORAGE_LAST_PIN_KEY);
	}

	/** Compare le PIN entré avec le hash stocké.
	 * @param psLogin le login de l'utilisateur.
	 * @param psPin le PIN entré.
	 * @returns une promesse `true` si le PIN correspond au hash stocké `false` sinon.
	*/
	public check(psLogin: string, psPin: string): Promise<boolean> {
		const loLastSaved: IPin = this.getSaved();

		if (loLastSaved.login === psLogin)
			return compare(psPin, loLastSaved.hashedPin).then((pbSamePin: boolean) => {
				if (pbSamePin) {
					this.setAttempts(0);
					return true;
				}
				else {
					this.updateFailedAttempt(psLogin);
					return false;
				}
			});
		else {
			this.updateFailedAttempt(psLogin);
			return Promise.resolve(false);
		}
	}

	/** Incrémente le nombre de tentatives échouées et supprime le PIN stocké lorsque le maximum est dépassé.
	 * @param psLogin Login de l'utilisateur.
	 */
	private updateFailedAttempt(psLogin: string): void {
		this.incrementAttempts();

		if (!this.hasRemainingAttempts())
			this.delete();

		this.isvcLogger.action(
			PinService.C_LOG_ID,
			`Unlock by pin failed`,
			ELogActionId.unlockPinAttemptFailed,
			{ login: psLogin, attempts: this.getAttempts(), remainingAttempts: this.getRemainingAttempts() } as IPinLogAction
		);
	}

	/** Retourne le Pin sauvegardé.
	 * @param psKey (optionnel) La clé de stockage.
	 * @returns `IPin` sauvegardé où les champs sont `undefined` si aucun Pin sauvegardé.
	 */
	private getSaved(psKey: string = PinService.C_STORAGE_LAST_PIN_KEY): IPin {
		const lsSavedPin: string | null = localStorage.getItem(psKey);
		return lsSavedPin ? JSON.parse(lsSavedPin) : { login: undefined, hashedPin: undefined };
	}

	/** Incrémente le nombre de tentatives de déverrouillage PIN. */
	private incrementAttempts(): void {
		this.setAttempts(this.getAttempts() + 1);
	}

	/** Fixe le nombre de tentatives de déverrouillage PIN.
	 * @param pnAttempts le nombre de tentatives.
	 */
	private setAttempts(pnAttempts: number): void {
		localStorage.setItem(PinService.C_STORAGE_ATTEMPTS_KEY, String(pnAttempts));
	}

	/** Retourne le nombre de tentatives de déverrouillage PIN. */
	private getAttempts(): number {
		return +localStorage.getItem(PinService.C_STORAGE_ATTEMPTS_KEY); // +null === 0.
	}

	/** Retourne le nombre de tentatives restantes */
	public getRemainingAttempts(): number {
		const lnAttempts: number = this.getAttempts();
		return lnAttempts > PinService.C_MAX_ATTEMPTS ? 0 : PinService.C_MAX_ATTEMPTS - lnAttempts;
	}

	/** Vérifie que le nombre de tentatives est inférieur au nombre maximum .
	 * @returns `true` si ne nombre de tentatives est inférieur au nombre maximum `false` sinon.
	 */
	private hasRemainingAttempts(): boolean {
		return this.getRemainingAttempts() > 0;
	}

	//#endregion

}