import { Injectable } from '@angular/core';
import { ConfigData, IWorkspace } from '@osapp/model';
import { ApplicationService, DomainService, Store } from '@osapp/services';
import { C_PREFIX_PATIENT } from '../app/app.constants';

@Injectable({ providedIn: "root" })
export class IdlDomainService extends DomainService {

	//#region METHODS

	constructor(poStore: Store, psvcApplication: ApplicationService) {
		super(poStore, psvcApplication);
	}

	/** Crée le workspace de l'utilisateur */
	public createUserWorkspace(psUserId: string, psName: string, psWorkspaceId: string): IWorkspace {
		const loWorkspace: IWorkspace = super.createUserWorkspace(psUserId, psName, psWorkspaceId);

		loWorkspace.databases.push(this.createWorkspaceDatabaseName(psUserId, "soins"));
		return loWorkspace;
	}

	public createWorkspaceDatabaseName(psWorkspaceId: string, psDataBaseRole: string): string {
		return `${ConfigData.appInfo.appId}_custom_ws${psWorkspaceId}${C_PREFIX_PATIENT}${psDataBaseRole}`;
	}

	//#endregion
}