import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LightingUpAngularModule } from 'lighting-up-angular';
import { MenuMobileComponent } from './menu-mobile.component';

@NgModule({
	declarations: [MenuMobileComponent],
	imports: [CommonModule, LightingUpAngularModule],
	exports: [MenuMobileComponent]
})
export class MenuMobileModule { }
