import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ImageModule } from '../../components/image/image.module';
import { DmsImageComponent } from './components/dms-image/dms-image.component';
import { IMetaEntityBuilderBase } from './model/IMetaEntityBuilderBase';
import { MetaConversationBuilder } from './model/MetaConversationBuilder';
import { MetaEntityBuilderBase } from './model/MetaEntityBuilderBase';
import { DmsExternalLinkerService } from './services/dms-external-linker.service';
import { DmsMetaService, DMS_META_CONFIG } from './services/dms-meta.service';
import { DmsService } from './services/dms.service';

@NgModule({
	declarations: [
		DmsImageComponent
	],
	imports: [
		CommonModule,
		ImageModule,
		IonicModule
	],
	providers: [
		DmsMetaService,
		MetaEntityBuilderBase,
		MetaConversationBuilder,
		DmsService,
		DmsExternalLinkerService
	],
	exports: [
		DmsImageComponent
	]
})
export class DmsModule {

	public static forRoot(poDmsMetaConfig: Type<IMetaEntityBuilderBase>[]): ModuleWithProviders<DmsModule> {
		return {
			ngModule: DmsModule,
			providers: [
				{ provide: DMS_META_CONFIG, useValue: poDmsMetaConfig }
			]
		};
	}

}
