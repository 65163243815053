import { Exclude, Expose } from "class-transformer";
import { EInvoiceStatus } from "./einvoice-status";
import { IInvoiceStatus } from "./iinvoice-status";

export class InvoiceStatus implements IInvoiceStatus {

	//#region PROPERTIES

	@Exclude()
	private meValue?: EInvoiceStatus;
	@Expose()
	/** @implements */
	public get value(): EInvoiceStatus {
		return this.meValue ?? (this.meValue = EInvoiceStatus.created);
	}
	public set value(peValue: EInvoiceStatus) {
		if (peValue !== this.meValue)
			this.meValue = peValue;
	}

	//#endregion

}
