import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ArrayHelper, ComponentBase, FileHelper, IdHelper, ObjectHelper, StringHelper } from '@osapp/helpers';
import { StoredSeance } from 'apps/idl/src/anakin/models/StoredSeance';
import { ITerminalInfoModalParams } from 'apps/idl/src/modules/olaqin/components/terminal-info-modal/models/iterminal-info-modal-params';
import { ITerminalInfo } from 'apps/idl/src/modules/olaqin/models/iterminal-info';
import { EMPTY, Observable, combineLatest, defer, forkJoin, from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { DrawerPopoverService } from '../../../shared/services/drawer-popover.service';
import { OlaqinService } from 'apps/idl/src/modules/olaqin/services/olaqin.service';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { Queuer } from '@osapp/modules/utils/queue/models/queuer';
import { Invoice } from 'apps/idl/src/modules/facturation/models/invoice';
import { IdlPrestation } from 'apps/idl/src/modules/facturation/models/idl-prestation';
import { ITraitement } from 'apps/idl/src/model/ITraitement';
// import { FacturationService } from 'apps/idl/src/modules/facturation/facturation.service';
import { EPrestationStatus } from '@osapp/modules/prestation/models/eprestation-status.enum';
import { AMCP } from 'apps/idl/src/modules/patients/model/amc-p';
import { PrestationService } from '@osapp/modules/prestation/services/prestation.service';
import { InterventionStatementService } from 'apps/idl/src/modules/intervention-statement/intervention-statement.service';
import { SnackbarService } from '../../../shared/services/snackbar.service';
import { Traitement } from 'apps/idl/src/model/Traitement';
import { EStatusSeance } from 'apps/idl/src/model/EStatusSeance';
import { SeancesGeneratorService } from '../../../shared/services/seances-generator.service';
import { TraitementService } from 'apps/idl/src/services/traitement.service';
import { ETraitementState } from 'apps/idl/src/model/ETraitementState';
import { IInterventionStatementActe } from 'apps/idl/src/modules/intervention-statement/models/iintervention-statement-acte';
import { Acte } from 'apps/idl/src/model/Acte';
import { EInvoiceStatus } from 'apps/idl/src/modules/facturation/models/einvoice-status';
import { IFsvErrorSV } from 'apps/idl/src/modules/facturation/models/ifsv-error-sv';
import { PanneauService } from '../../../shared/services/panneau.service';
import { FacturationService } from 'apps/idl/src/modules/facturation/facturation.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EModeSecurisation } from 'apps/idl/src/modules/facturation/models/emode-securisation.enum';
import { Directory } from '@capacitor/filesystem';
import { FilesystemService } from '@osapp/modules/filesystem/services/filesystem.service';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { DeviceService } from '../../../shared/services/device.service';
import { SeanceService } from '../../../shared/services/seance.service';
import { EUpdateMode } from 'apps/idl/src/modules/patients/model/eupdate-mode.enum';
import { AMOP } from 'apps/idl/src/modules/patients/model/amo-p';
import { ITerminal } from 'apps/idl/src/modules/olaqin/models/iterminal';
import { C_PREFIX_TERMINAL } from 'apps/idl/src/anakin/anakin.constants';

interface IFspItem {
  blob: Blob;
  name: string;
  url: string;
}

@Component({
  selector: 'di-facturation-sv',
  templateUrl: './facturation-sv.component.html',
  styleUrls: ['./facturation-sv.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacturationSvComponent extends ComponentBase implements OnInit {
  @Input() public seancesAFacturer: StoredSeance[] = [];
  @Input() public seances: StoredSeance[] = [];
  public invoice: Invoice;
  @Input() public ordonnance: ITraitement;
  @Input() public patient?: IPatient;
  @Input() public adri = false;

  public prestations: IdlPrestation[] = [];

  public sousTexteFacturationOk = "Une feuille de soin électronique à été généré, ainsi qu'une DRE pour la mutuelle. Vous devrez les envoyer via le module sesam-vitale";
  public titreFacturationOk = "Facturation terminée";
  public iconeFacturationOk = "check_circle";

  public facturationTerminee: boolean = false;
  public erreurFacturation: boolean = false;
  public titreErreur = "Une erreur est survenue";
  public messageErreur: string = "";
  public readonly terminalId$: Observable<string>;
  public terminal?: ITerminalInfo;
  public canValidate: boolean = false;
  public facturationEnCours: boolean = false;

  public terminalError: boolean = false;
  public cpsError: boolean = false;
  public vitaleError: boolean = false;
  public lastTerminalId: string;
  public tarificationTerminee: boolean = false;
  public securisationTerminee: boolean = false;
  public carteVitaleAbsente: boolean = false;
  public carteCPSAbsente: boolean = false;
  public chargementLecteur: boolean = true;
  public textChargementLecteur: string = "Chargement des informations du lecteur...";
  public isMobile: boolean = true;
  public listeTerminaux: ITerminal[] = [];

  constructor(
    private svcOlaqin: OlaqinService,
    public changeDetector: ChangeDetectorRef,
    private svcDrawerPopover: DrawerPopoverService,
    private readonly svcPrestation: PrestationService,
    private svcInterventionStatement: InterventionStatementService,
    private svcSnackbar: SnackbarService,
    private svcSeanceGenerator: SeancesGeneratorService,
    private svcTraitement: TraitementService,
    private svcPanneau: PanneauService,
    private readonly svcFacturation: FacturationService,
    private readonly svcFilesystem: FilesystemService,
    private readonly ioFileOpener: FileOpener,
    private readonly svcDevice: DeviceService,
    private readonly svcSeance: SeanceService,
    private router: Router
  ) {
    super(changeDetector);
    this.terminalId$ = this.svcOlaqin.getLastUsedTerminalId(true).pipe(takeUntil(this.destroyed$));
  }

  navigateToOrdonnance() {
    this.svcDrawerPopover.close();
    this.svcPanneau.close();
    this.router.navigate(["facturation"]);
  }

  public ngOnInit(): void {
    this.svcDevice.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((flag: boolean) => {
      this.isMobile = flag;
    });
    this.setQueuers([this.queuer]);

    combineLatest([this.terminalId$, this.svcOlaqin.listTerminals()]).pipe(
      tap(([terminalId, list]: [string, ITerminal[]]) => {
        this.listeTerminaux = list;
        this.lastTerminalId = IdHelper.getGuidFromId(terminalId, C_PREFIX_TERMINAL) || '';
        this.getInfo(this.lastTerminalId);
        this.detectChanges();
      }),
      takeUntil(this.destroyed$)
    ).subscribe();
  }

  public getInfo(psTerminalId: string = this.lastTerminalId): void {
    this.chargementLecteur = true;
    this.queuer.exec(psTerminalId);
  }

  setCardAbsent(isCVAbsente: boolean) {
    if (isCVAbsente) {
      this.carteVitaleAbsente = true;
    } else {
      this.carteCPSAbsente = true;
    }
    this.chargementLecteur = true;
    this.textChargementLecteur = "Vérification des informations du lecteur...";
    this.detectChanges();
    this.queuer.exec(this.lastTerminalId);
  }

  setCVAbsente() {
    this.setCardAbsent(true);
  }

  setCPSAbsente() {
    this.setCardAbsent(false);
  }

  onSelectTerminal(terminal: ITerminal) {
    this.lastTerminalId = terminal.terminalId;
    this.svcOlaqin.setLastUsedTerminalId(this.lastTerminalId).subscribe(() => {
      this.detectChanges();
    });
  }

  private isTerminalReady(): boolean {
    this.terminalError = false
    this.cpsError = false
    this.vitaleError = false
    this.chargementLecteur = false;
    return this.terminal?.terminalConnected
      && (this.terminal?.cpsInserted || this.carteCPSAbsente)
      && (this.terminal?.vitaleInserted || this.adri || this.carteVitaleAbsente);
  }

  private readonly queuer = new Queuer({
    thingToQueue: (psTerminalId: string) => {
      this.terminal = undefined;
      this.detectChanges();
      return defer(
        () => StringHelper.isBlank(psTerminalId) ? of({}) : this.svcOlaqin.getTerminalInfos(psTerminalId))
        .pipe(
          catchError((poError: any) => {
            return of({});
          }),
          switchMap((terminal: ITerminalInfo) => {
            this.terminal = terminal;
            this.canValidate = this.isTerminalReady();
            if (this.canValidate) {
              this.facturationEnCours = true;
              this.detectChanges();
              return of(true);
            } else {
              this.facturationEnCours = false;
              this.terminalError = !this.terminal?.terminalConnected;
              this.cpsError = !this.terminal?.cpsInserted;
              this.vitaleError = !this.terminal?.vitaleInserted;
              this.detectChanges();
              return EMPTY;
            }
          }),
          // Valide et facture toutes les prestations
          mergeMap(() => this.validateAndBillPrestations()),
          // Créer une seule Invoice pour toutes les prestations
          tap((prestations) => {
            this.prestations = prestations;  // On s'assure que toutes les prestations sont présentes
            const invoice = this.createInvoice();
            if (this.carteCPSAbsente) {
              invoice.securisationMode = EModeSecurisation.PAPIER;
            }
            this.invoice = invoice;
          }),
          // Sauvegarde l'Invoice
          mergeMap(() => this.svcFacturation.saveInvoice(this.invoice)),
          // Sauvegarde des prestations
          mergeMap((invoice) => {
            this.invoice = invoice;
            this.prestations.forEach((x) => x.status = EPrestationStatus.sent);
            return this.svcPrestation.savePrestations(this.prestations);
          }),
          // Rempli l'Invoice avec des informations des sts et gu
          tap(() => {
            const amc: AMCP = this.patient.AMC?.find(x => x.isActive);
            this.svcFacturation.fillInvoiceSTSAndGU(this.invoice, amc);
          }),
          // Lance la tarification (dans tous les modes de sécurisation DEGRADEE, PAPIER et SV)
          mergeMap(() => {
            return this.tarification();
          }),
          switchMap((invoice) => this.verifierErreurSV(invoice)),
          tap((invoice) => {
            this.invoice = invoice;
            this.tarificationTerminee = true;
            this.detectChanges();
          }),
          //On exporte la facture dans Intellio editeur (pour les modes de sécurisation DEGRADEE et SV)
          mergeMap(() => {
            return this.facturation();
          }),
          tap((invoice: Invoice) => {
            this.majFacture(invoice);
            this.detectChanges();

          }),
          //On sécurise la facture dans Intellio editeur (pour les modes de sécurisation DEGRADEE et SV)
          mergeMap(() => {
            return this.securisation();
          }),
          tap((invoice: Invoice) => {
            //TODO : Il faut mettre a jour le status des seances facturées en completed
            this.majFacture(invoice);
            if (this.invoice.securisationMode === EModeSecurisation.DEGRADE) {
              this.titreFacturationOk = "Facturation dégradée";
              this.sousTexteFacturationOk = "La facturation est dégradé car vous n'aviez pas la carte vitale du patient. Vous devez envoyer la feuille de soins.";
            } else if (this.invoice.securisationMode === EModeSecurisation.SECURISE) {
              this.sousTexteFacturationOk = "Une feuille de soins électronique a été générée, ainsi qu'une DRE pour la mutuelle. Vous devrez les envoyer via le module sesam-vitale";
              this.titreFacturationOk = "Facturation terminée";
            } else if (this.invoice.securisationMode === EModeSecurisation.PAPIER) {
              this.titreFacturationOk = "Facturation papier";
              this.sousTexteFacturationOk = "La facturation est au format papier car vous n'aviez pas votre carte CPS. Il n'aura pas de feuille soins électronique. Vous devez envoyer la feuille de soins manuellement.";
            }
            this.facturationTerminee = true;
            this.messageErreur = "";
            this.titreErreur = "";
            this.erreurFacturation = false;
            this.facturationEnCours = false;
            this.detectChanges();

          }),
          mergeMap(() => {
            return from(this.seancesAFacturer).pipe(
              filter((seance) => seance.status === EStatusSeance.done),
              mergeMap((seance: StoredSeance) => {
                // Mise à jour du statut de la séance
                seance.status = EStatusSeance.completed;
                // seance.statusChangeDate = new Date();

                // Sauvegarde de chaque séance individuellement
                return this.svcSeance.updateSeance(seance);
              })
            );
          }),
          mergeMap(() => {
            if (this.invoice.securisationMode === EModeSecurisation.PAPIER) {
              //facturation papier, on doit maj le statut de l'invoice car on n'exporte plus la facture dans intellio editeur
              this.invoice.status.value = this.invoice.totalPartPatient > 0 ? EInvoiceStatus.payment : EInvoiceStatus.closed;
              return this.svcFacturation.saveInvoices([this.invoice]);
            }
            return of(EMPTY);
          }),
          catchError((error) => {
            console.error('Erreur lors du process de facturation:', error);
            this.svcSnackbar.showToast("error", "bottom center", "Erreur lors du processus de facturation.");
            this.erreurFacturation = true;
            this.tarificationTerminee = true;
            this.facturationTerminee = true;
            this.messageErreur = error?.error?.message || error?.error?.error || error.message;
            this.titreErreur = "Une erreur est survenue";
            this.facturationEnCours = false;
            this.detectChanges();
            return EMPTY;
          })
        );
    },
    excludePendings: true
  });

  private async downloadPdfAsync(poInvoice: Invoice, poFsp: IFspItem): Promise<void> {
    const lsInvoicePdfName = `Facture n°${poInvoice.invoiceNumber} - ${poFsp.name}.pdf`;
    if (this.isMobile) {
      const lsPath = `Download/${lsInvoicePdfName}`;

      const lsUri = await this.svcFilesystem.createFileAsync(lsPath, poFsp.blob, Directory.ExternalStorage, true);
      await this.ioFileOpener.open(lsUri, "application/pdf");
    }
    else
      FileHelper.downloadBlob(poFsp.blob, lsInvoicePdfName);
  }

  public async printFsp(): Promise<void> {
    try {
      // Assure que la méthode attendue est asynchrone et renvoie une Map<Invoice, IFspItem[]>
      const fsps = await this.svcFacturation.displayFspAnakin([this.invoice]);

      // Récupére la valeur de la Map correspondant à l'Invoice, qui est un tableau
      const fspItems = fsps ? fsps.get(this.invoice) : null;

      if (fspItems && fspItems.length > 0) {
        // Utilise le premier élément du tableau
        const fsp: IFspItem = fspItems[0];

        // Télécharge et ouvre le PDF correspondant
        await this.downloadPdfAsync(this.invoice, fsp);
      } else {
        console.error("Aucune FSP trouvé pour cette facture.");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération ou du traitement des FSP : ", error);
    }
  }

  async printInvoice() {
    await this.svcFacturation.exportInvoicePdfAnakin(this.invoice, this.isMobile);
  }

  private majFacture(invoice: Invoice) {
    if (invoice) {
      this.invoice = invoice;
    }
  }

  private verifierErreurSV(invoice: Invoice): Observable<Invoice | never> {
    if (!invoice) return EMPTY;

    if (invoice.status.value === EInvoiceStatus.priced && ArrayHelper.hasElements(invoice.erreurSv)) {
      const erreurSV: string = invoice.erreurSv
        .map((error: IFsvErrorSV) => `${error.bloquant ? "Bloquant : " : "Non bloquant : "}${error.libelle}`)
        .join();
      this.facturationTerminee = true;
      this.erreurFacturation = true;
      this.messageErreur = erreurSV;
      this.titreErreur = "Une erreur SV est survenue";
      this.detectChanges();

      from(this.svcFacturation.cancelActionAnakin(this.patient._id, this.invoice, this.seancesAFacturer))
        .pipe(
          tap(() => console.log("annulation facture ok"))
        );

      // Si des erreurs bloquantes existent, arrête le flux en renvoyant EMPTY
      return EMPTY;
    }

    // Continue le flux avec la facture
    return of(invoice);
  }

  private validateAndBillPrestations(): Observable<IdlPrestation[]> {
    if (!this.ordonnance) {
      this.svcSnackbar.showToast("error", "bottom center", "Le traitement a été supprimé");
      return of([]);
    }

    if (!Object.values(this.ordonnance.actes).some(acte => acte.isPriorAgreement)) {
      // Pas besoin de vérifier l'accord préalable s'il n'y a pas d'actes nécessitant un accord préalable
      return this.generatePrestationFromSeance(this.seancesAFacturer);
    }


    //TODO : a dev une fois l'entente préalable developpée
    // let notCompleted = false;
    // this.svcAccordPrealable.get(this.traitement._id).pipe(
    //   tap((accord: IAccordPrealable) => {
    //     if (!accord.dateResultat && !accord.resultat) {
    //       notCompleted = true
    //       this.svcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Accord Préalable", message: "L'accord préalable en lien avec le traitement est incomplet." }));
    //     }
    //   }),
    //   catchError((error: any) => {
    //     this.svcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Accord Préalable", message: "L'accord préalable en lien avec le traitement est incomplet." }));
    //     return EMPTY;
    //   }),
    //   takeUntil(this.destroyed$)
    // ).subscribe(() => {
    //   if(!notCompleted)
    //     this.generateSeanceFromDate();
    // });

    // Si d'autres actions sont nécessaires, les ajouter ici.
    return of([]); // Retourne une Observable vide si rien à faire.
  }

  private generatePrestationFromSeance(seances: StoredSeance[]): Observable<IdlPrestation[]> {
    if (!ArrayHelper.hasElements(seances)) {
      this.svcSnackbar.showToast("error", "bottom center", "Aucune séance validée à facturer");
      return of([]);  // Retourne un tableau vide si aucune séance n'est disponible
    }

    const traitement: Traitement = Traitement.createFromData(this.ordonnance);
    //séances à facturer sur la plage donnée
    const seancesValidees: StoredSeance[] = seances.filter(x => x.status === EStatusSeance.done);
    //séances restantes à faire dans tout le traitement
    const seancesAFaire: StoredSeance[] = this.seances.filter(seance => seance.status === EStatusSeance.to_be_done);
    //On regarde s'il reste des séances à facturer dans tout le traitement apres celle des séances que l'on va facturer
    const seancesRestantes: StoredSeance[] = this.seances.filter(seance =>
      !seancesValidees.some(seanceValidee => seanceValidee.id === seance.id) &&
      seance.status !== EStatusSeance.canceled &&
      seance.status !== EStatusSeance.inProgress &&
      seance.status !== EStatusSeance.completed
    );

    // Création des prestations pour toutes les séances
    return from(seancesValidees).pipe(
      mergeMap((seance: StoredSeance) =>
        this.svcSeanceGenerator.createAndSavePrestation(seance, traitement).pipe(
          map((presta) => presta),
          mergeMap((presta: IdlPrestation) => {
            this.prestations.push(presta);

            // Création et sauvegarde de l'InterventionStatement pour chaque séance
            const interventionStatement = InterventionStatementService.createInterventionStatement(
              seance.startDate,
              traitement._id,
              seance.actes.map((poActe: Acte) => ({
                acteId: poActe._id,
                skipReason: poActe.canceled ? "Annulée" : undefined,
                acteGuid: poActe.guid
              }) as IInterventionStatementActe),
              seance.infirmierId
            );

            return this.svcInterventionStatement.saveInterventionStatement(interventionStatement).pipe(
              map(() => presta),
              mergeMap(() => {
                if (seancesRestantes.length > 0 || seancesAFaire.length > 0) return of(presta);  // Si des séances sont en cours, renvoie la prestation
                return this.svcTraitement.modifierStateTraitement(traitement, ETraitementState.termine).pipe(
                  map(() => presta)
                );
              })
            );
          })
        )
      ),
      toArray(), // Collecte toutes les prestations dans un tableau
      catchError((error) => {
        this.svcSnackbar.showToast("error", "bottom center", error.message || "Une erreur est survenue");
        return of([]);  // Retourne un tableau vide en cas d'erreur
      })
    );
  }


  private createInvoice(): Invoice {
    const invoice = this.svcFacturation.createInvoiceFromPrestations(this.patient, this.prestations, this.ordonnance);
    return invoice;
  }

  private facturation(): Observable<Invoice> {
    //Pas d'export si papier
    // if (this.carteCPSAbsente) return of(null);
    const couvAmo = this.patient.AMO?.find(x => x.isActiveAnakin);

    return from(this.svcFacturation.forwardActionAnakin(
      this.patient._id,
      this.invoice,
      this.arePatientAndAMOUpToDate(this.patient, couvAmo),

    )).pipe(
      switchMap((reponseInvoice: Invoice) => {
        if (!reponseInvoice) {
          // Si reponseInvoice est null ou undefined, obtenir l'Invoice via getInvoice
          return from(this.svcFacturation.getInvoice(this.invoice._id).toPromise());
        } else {
          // Sinon, utiliser reponseInvoice directement
          return of(reponseInvoice);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        // Gérer les erreurs ici
        console.error('Erreur lors de la facturation:', error);
        this.svcSnackbar.showToast("error", "bottom center", "Erreur lors de la facturation.");
        this.erreurFacturation = true;
        this.tarificationTerminee = true;
        this.facturationTerminee = true;
        this.messageErreur = error?.error?.message || error?.error?.error || error.message;
        this.titreErreur = "Une erreur est survenue lors de la facturation";
        this.facturationEnCours = false;
        this.detectChanges();
        //TODO : Rollback pas encore parfait, probleme avec les séances qu'il reapsse tout en status : done, à voir pq
        //Version promesse
        from(this.svcFacturation.cancelActionAnakin(this.patient._id, this.invoice, this.seancesAFacturer))
          .pipe(
            tap(() => console.log("annulation facture ok"))
          );

        //Version Observable
        // return this.svcFacturation.cancelActionAnakin$(this.patient._id, this.invoice, this.seancesAFacturer).pipe(
        //   tap(() => console.log("annulation facture ok")),
        //   // Si tu veux retourner une valeur par défaut après l'annulation, utilise `switchMap` ou `map`.
        //   switchMap(() => of(null))  // Retourner une valeur par défaut après l'annulation
        // );
        return of(null);  // Retourner une valeur par défaut ou gérer l'erreur comme nécessaire
      })
    );
  }

  private securisation(): Observable<Invoice> {
    if (this.carteCPSAbsente) return of(null);

    const couvAmo = this.patient.AMO?.find(x => x.isActiveAnakin);
    return from(this.svcFacturation.forwardActionAnakin(
      this.patient._id,
      this.invoice,
      this.arePatientAndAMOUpToDate(this.patient, couvAmo),
      this.terminal.terminalId
    )).pipe(
      switchMap((reponseInvoice: Invoice) => {
        if (!reponseInvoice) {
          // Si reponseInvoice est null ou undefined, obtenir l'Invoice via getInvoice
          return from(this.svcFacturation.getInvoice(this.invoice._id).toPromise());
        } else {
          // Sinon, utiliser reponseInvoice directement
          return of(reponseInvoice);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        // Gérer les erreurs ici
        console.error('Erreur lors de la securisation:', error);
        this.svcSnackbar.showToast("error", "bottom center", "Erreur lors de la sécurisation.");
        this.erreurFacturation = true;
        this.tarificationTerminee = true;
        this.facturationTerminee = true;
        this.messageErreur = error?.error?.message || error?.error?.error || error.message;
        this.titreErreur = "Une erreur est survenue lors de la sécurisation";
        this.facturationEnCours = false;
        this.detectChanges();
        //TODO : Rollback pas encore parfait, probleme avec les séances qu'il reapsse tout en status : done, à voir pq
        //Version promesse
        from(this.svcFacturation.cancelActionAnakin(this.patient._id, this.invoice, this.seancesAFacturer))
          .pipe(
            tap(() => console.log("annulation facture ok"))
          );

        //Version Observable
        // return this.svcFacturation.cancelActionAnakin$(this.patient._id, this.invoice, this.seancesAFacturer).pipe(
        //   tap(() => console.log("annulation facture ok")),
        //   // Si tu veux retourner une valeur par défaut après l'annulation, utilise `switchMap` ou `map`.
        //   switchMap(() => of(null))  // Retourner une valeur par défaut après l'annulation
        // );
        return of(null);  // Retourner une valeur par défaut ou gérer l'erreur comme nécessaire
      })
    );

  }

  public arePatientAndAMOUpToDate(poPatient: IPatient, poAMOP: AMOP): boolean {
    if (!poAMOP)
      return false;

    if (this.carteVitaleAbsente)
      return false;

    return !StringHelper.isBlank(poPatient.externalId) && poAMOP.updateMode !== EUpdateMode.manual;
  }

  private tarification(): Observable<Invoice> {
    const couvAmo = this.patient.AMO?.find(x => x.isActiveAnakin);

    return from(this.svcFacturation.forwardActionAnakin(
      this.patient._id,
      this.invoice,
      this.arePatientAndAMOUpToDate(this.patient, couvAmo)
    )).pipe(
      switchMap((reponseInvoice: Invoice) => {
        if (!reponseInvoice) {
          // Si reponseInvoice est null ou undefined, obtenir l'Invoice via getInvoice
          return from(this.svcFacturation.getInvoice(this.invoice._id).toPromise());
        } else {
          // Sinon, utiliser reponseInvoice directement
          return of(reponseInvoice);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        // Gérer les erreurs ici
        console.error('Erreur lors de la tarification:', error);
        this.svcSnackbar.showToast("error", "bottom center", "Erreur lors de la tarification.");
        this.erreurFacturation = true;
        this.tarificationTerminee = true;
        this.facturationTerminee = true;
        this.messageErreur = error?.error?.message || error?.error?.error || error.message;
        this.titreErreur = "Une erreur est survenue lors de la tarification";
        this.facturationEnCours = false;
        this.detectChanges();
        //TODO : Rollback pas encore parfait, probleme avec les séances qu'il reapsse tout en status : done, à voir pq
        //Version promesse
        from(this.svcFacturation.cancelActionAnakin(this.patient._id, this.invoice, this.seancesAFacturer))
          .pipe(
            tap(() => console.log("annulation facture ok"))
          );

        //Version Observable
        // return this.svcFacturation.cancelActionAnakin$(this.patient._id, this.invoice, this.seancesAFacturer).pipe(
        //   tap(() => console.log("annulation facture ok")),
        //   // Si tu veux retourner une valeur par défaut après l'annulation, utilise `switchMap` ou `map`.
        //   switchMap(() => of(null))  // Retourner une valeur par défaut après l'annulation
        // );
        return of(null);  // Retourner une valeur par défaut ou gérer l'erreur comme nécessaire
      })
    );
  }

  correctionFacturation() {
    this.svcDrawerPopover.close();
    this.svcPanneau.close();
  }

}
