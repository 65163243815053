import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { UserContactModule } from '@osapp/modules/contacts/userContact/user-contact.module';
import { AuthenticatorComponent } from './authenticator.component';
import { SidePageDesmosModule } from '../../features/connexion/components/side-page-desmos/side-page-desmos.module';
import { LightingUpAngularModule } from 'lighting-up-angular';
import { MatIconModule } from '@angular/material/icon';


/**
 * Module de gestion des formlist
 */
@NgModule({
	imports: [
		IonicModule,
		CommonModule,
		FormsModule,
		RouterModule,
		UserContactModule,
		SidePageDesmosModule,
		LightingUpAngularModule,
		MatIconModule
	],
	exports: [AuthenticatorComponent],
	declarations: [AuthenticatorComponent],
	providers: []
})
export class AuthenticatorModule {
}
