<div class="menu-ordonnance">
	<div>
		<lua-list-item *ngIf="edit" class="list-item" text="Modifier" iconName="edit"
			(click)="handleEditClick($event)"></lua-list-item>
		<lua-list-item class="list-item" text="Renouveler" iconName="edit"
			(click)="handleRenouvelerClick()"></lua-list-item>
		<lua-list-item class="list-item" text="Interrompre" iconName="warning" iconMode="filled" iconColor="Error"
			(click)="handleInterrompreClick($event)"></lua-list-item>
		<lua-divider-horizontal></lua-divider-horizontal>
		<lua-list-item class="list-item" text="Facturer le patient" iconColor="CouleurPrimaire" iconName="request_quote"
			(click)="handleFacturationClick($event)"></lua-list-item>
		<lua-divider-horizontal></lua-divider-horizontal>
		<lua-list-item class="list-item" text="Supprimer" iconName="delete" iconColor="Error"
			(click)="handleSupprimerClick($event)"></lua-list-item>
	</div>
</div>