import { Component, OnInit } from '@angular/core';
import { FormHelper } from '../../../../helpers/formHelper';
import { FieldBase } from '../../../../model/forms/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { PatternResolverService } from '../../../../services/pattern-resolver.service';
import { FormComponent } from '../form.component';

@Component({
	template: ''
})
export class HiddenFieldComponent extends FieldBase<string> implements OnInit { //todo : params.

	//#region METHODS

	constructor(
		private readonly isvcPatternResolver: PatternResolverService,
		private readonly ioParentForm: FormComponent,
		psvcForms: FormsService
	) {
		super(psvcForms);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		if (this.to.data.value) {
			if (FormHelper.canBeFilled(this.to.data.oneTimeFilled, this.model, this.fieldValue)) {
				this.to.data.value = this.replaceDynValues();
				this.fieldValue = this.to.data.value;
			}
		}
		else if (!this.to.data.readOnly)
			console.error(`Le hiddenField ${this.field.key} n'as pas de value.`);
	}

	/**Remplace le pattern par une valeur dynamique*/
	private replaceDynValues(): string {
		let lnIndex = 0;

		return (this.to.data.value as string)
			.split((/[{{}}]+/))
			.reduce((psAccumulator: string, psCurrentValue: string) => {
				if (lnIndex % 2 === 0) {
					++lnIndex;
					return psAccumulator + psCurrentValue;
				}
				else {
					++lnIndex;
					return psAccumulator + this.isvcPatternResolver.resolveFormsPattern(psCurrentValue, "", this.ioParentForm.parentEntity);
				}
			}, "");
	}

	//#endregion
}