import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IDynHostComponent } from '@osapp/components/dynHost/IDynHost.component';
import { DynamicPageComponent } from '@osapp/components/dynamicPage';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { MapHelper } from '@osapp/helpers/mapHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { UserHelper } from '@osapp/helpers/user.helper';
import { EBarElementDock } from '@osapp/model/barElement/EBarElementDock';
import { EBarElementPosition } from '@osapp/model/barElement/EBarElementPosition';
import { IBarElement } from '@osapp/model/barElement/IBarElement';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { ObservableArray } from '@osapp/modules/observable/models/observable-array';
import { C_ADMINISTRATORS_ROLE_ID, Roles } from '@osapp/modules/permissions/services/permissions.service';
import { VirtualScrollComponent } from '@osapp/modules/virtual-scroll/components/virtual-scroll/virtual-scroll.component';
import { LoadingService } from '@osapp/services/loading.service';
import { SlideboxService } from '@osapp/services/slidebox.service';
import { defer, of } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { EMajorationType } from '../../../../model/EMajorationType';
import { EStatusSeance } from '../../../../model/EStatusSeance';
import { IActeDocumentByLc } from '../../../../model/IActeDocumentByLc';
import { IMajorationDetails } from '../../../../model/IMajorationDetails';
import { Majoration } from '../../../../model/Majoration';
import { Seance } from '../../../../model/Seance';
import { TraitementSlideParentBase } from '../../../../model/TraitementSlideParentBase';
import { IDateWithSeances } from '../../../../model/seances/IDateWithSeances';
import { SeanceService } from '../../../../services/seance.service';
import { TraitementService } from '../../../../services/traitement.service';
import { PatientsService } from '../../../patients/services/patients.service';
import { TraitementDataManager } from '../../traitement-data-manager.service';
import { TraitementSlideComponentBase } from '../traitement-slide-component-base.component';

interface ISeanceMajoree {
	_id: string;
	/** Id de la seance majorée. */
	id: number;
	/** Indique si la séance est protégée ou non ; si elle l'est, on ne peut pas modifier les majorations de cette séance. */
	isProtected: boolean;
	/** Date de début de la séance. */
	startDate: Date;
	/** Texte du status. */
	statusLabel: string;
	seance: Seance;
}

type IScrollItem = string | ISeanceMajoree;

@Component({
	templateUrl: './majorations.component.html',
	styleUrls: ['./majorations.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MajorationsComponent extends TraitementSlideComponentBase implements IDynHostComponent, OnInit {

	//#region FIELDS

	private maSeances: Seance[];
	private msAutoFocusedSeanceId: string;
	private maMajorations: IActeDocumentByLc[];
	@ViewChild("virtualscroll") private moVirtualScroll: VirtualScrollComponent<IScrollItem>;

	//#endregion

	//#region PROPERTIES

	public static readonly C_MAJORATION_DETAILS: IMajorationDetails[] = [
		{
			keyType: "SundayAndHolyday",
			valueType: EMajorationType.SundayAndHolyday,
			label: "Dimanche et jours fériés",
			fullLabel: "Dimanche et jours fériés",
			shortLabel: "Dim. / JF",
			cssClass: "item bg-dark-green",
			color: "bg-dark-green",
			tabLabel: "Dim/JF"
		},
		{
			keyType: "NightFirstHalf",
			valueType: EMajorationType.NightFirstHalf,
			label: "Nuit (20h-23h & 5h-8h)",
			fullLabel: "Nuit (20h-23h & 5h-8h)",
			shortLabel: "Nuit 1",
			cssClass: "item bg-purple",
			color: "bg-purple"
		},
		{
			keyType: "NightSecondHalf",
			valueType: EMajorationType.NightSecondHalf,
			label: "Nuit (23h-5h)",
			fullLabel: "Nuit (23h-5h)",
			shortLabel: "Nuit 2",
			cssClass: "item bg-light-red",
			color: "bg-light-red"
		},
		{
			keyType: "Mau",
			valueType: EMajorationType.Mau,
			label: "MAU",
			fullLabel: "Majoration acte unique",
			shortLabel: "MAU",
			cssClass: "item bg-orange",
			color: "bg-orange"
		},
		{
			keyType: "Mci",
			valueType: EMajorationType.Mci,
			label: "MCI",
			fullLabel: "Majoration de coordination infirmière",
			shortLabel: "MCI",
			cssClass: "item bg-sky-blue",
			color: "bg-sky-blue"
		},
		{
			keyType: "Mie",
			valueType: EMajorationType.Mie,
			label: "MIE",
			fullLabel: "Majoration jeunes enfants",
			shortLabel: "MIE",
			cssClass: "item bg-yellow",
			color: "bg-yellow"
		}
	];


	@Input() public params: any;

	/** Tableau d'objets contenant une date avec les séances majorées associées. */
	public seancesMajoreesByDates = new Map<string, ISeanceMajoree[]>();
	/** Représente l'enum avec tous les types de majoration (pour le select du DOM). */
	public majorationTypes: Map<string, EMajorationType> = new Map();
	/** Map des détails des majorations, indexé par valeur de l'enum MajorationType (string représentant un type de majoration). */
	public majorationsDetails: IMajorationDetails[] = MajorationsComponent.C_MAJORATION_DETAILS;
	/** Indique si toutes les majorations sont appliquées. */
	public isAllSelected = false;
	/** Objet regroupant les informations de la majoration en cours. */
	public currentMajorationDetails: IMajorationDetails;

	public readonly virtualScrollItems = new ObservableArray<IScrollItem>();

	@Roles(C_ADMINISTRATORS_ROLE_ID)
	public get canEditMajorations(): boolean {
		return true;
	}

	//#endregion

	//#region METHODS

	constructor(
		private ioRouter: Router,
		private ioRoute: ActivatedRoute,
		psvcPatients: PatientsService,
		psvcTraitementDataManager: TraitementDataManager,
		psvcSeance: SeanceService,
		poParentComponent: TraitementSlideParentBase,
		psvcTraitement: TraitementService,
		psvcLoading: LoadingService,
		poParentPage: DynamicPageComponent<ComponentBase>,
		poChangeDetectorRef: ChangeDetectorRef,
		psvcSlidebox: SlideboxService) {

		super(
			psvcTraitementDataManager,
			psvcSeance,
			poParentComponent,
			psvcTraitement,
			psvcLoading,
			psvcPatients,
			poParentPage,
			poChangeDetectorRef,
			psvcSlidebox
		);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		this.msAutoFocusedSeanceId = this.ioRoute.snapshot.queryParamMap.get("autoFocus");

		this.moParentComponent.getTraitementSlideAsObservable()
			.pipe(
				tap((psResult: string) => this.onTraitementSlideEvent(psResult)),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.majorationsDetails.forEach((poMajorationDetails: IMajorationDetails) => {
			this.majorationTypes.set(poMajorationDetails.keyType, poMajorationDetails.valueType);
		});

		this.setCurrentMajorationDetails(EMajorationType.SundayAndHolyday);

		this.traitement.seances$
			.pipe(
				tap(_ => this.init()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private setCurrentMajorationDetails(peMajorationType: EMajorationType): void {
		this.currentMajorationDetails = this.majorationsDetails.find((poItem: IMajorationDetails) => poItem.valueType === peMajorationType);
	}

	/** Change la majoration en cours après le clic.
	 * @param pnMajorationType Type de majoration.
	 */
	public changeCurrentMajoration(peMajorationType: EMajorationType): void {
		this.setCurrentMajorationDetails(peMajorationType);
		this.checkAllSelectionButtonIfRequired();
		this.detectChanges();
	}

	/** Vérifie que le bouton "toutes" doit être coché ou non, et le coche si nécessaire. */
	private checkAllSelectionButtonIfRequired(): void {
		let lbAreAllSelected = true;
		const laKeys: string[] = MapHelper.keysToArray(this.seancesMajoreesByDates);

		for (let lnIndexKeys = laKeys.length - 1; lnIndexKeys >= 0; --lnIndexKeys) {
			const laSeancesMajorees: ISeanceMajoree[] = this.seancesMajoreesByDates.get(laKeys[lnIndexKeys]);

			for (let lnIndexSeance = laSeancesMajorees.length - 1; lnIndexSeance >= 0; --lnIndexSeance) {
				const loSeanceMajoree: ISeanceMajoree = laSeancesMajorees[lnIndexSeance];

				if (this.canEditMajoration(loSeanceMajoree, this.currentMajorationDetails?.valueType) && !loSeanceMajoree.seance.hasMajoration(this.currentMajorationDetails?.valueType)) {
					lbAreAllSelected = false;
					break;
				}
			}

			if (!lbAreAllSelected) // Si on a rompu la boucle, c'est que le bouton "toutes" ne doit pas être coché.
				break;
		}

		this.isAllSelected = lbAreAllSelected;
	}

	/** @override */
	protected createToolbarData(): Array<IBarElement> {
		const lsCircle = "circle";
		const lsFabButton = "fabButton";

		return [
			{
				id: lsCircle,
				component: lsFabButton,
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.left,
				icon: "arrow-back",
				onTap: this.mfPreviousSlide,
				name:"Précédent"
			},
			{
				id: lsCircle,
				component: lsFabButton,
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.middle,
				icon: "timetable",
				onTap: () => this.ioRouter.navigate([
					"tournees",
					DateHelper.toDateUrl(this.traitement.beginDate)
				]),
				name:"Tournées"
			},
			{
				id: lsCircle,
				component: lsFabButton,
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.right,
				icon: "arrow-forward",
				onTap: this.mfNextSlide,
				name:"Suivant"
			}
		];
	}

	/** Initialisation du slide majoration. */
	private init(): void {
		defer(() => {
			if (this.maMajorations)
				return of(this.maMajorations);
			return this.isvcSeance.getMajorations().pipe(tap((paMajorations: IActeDocumentByLc[]) => this.maMajorations = paMajorations));
		})
			.pipe(
				tap(() => {
					this.maSeances = this.traitement.seances;
					const laSeancesByDays: IDateWithSeances[] = this.isvcSeance.groupSeancesByDays(this.maSeances);
					this.seancesMajoreesByDates.clear();
					laSeancesByDays.forEach((poDateWithSeances: IDateWithSeances) => {
						const lsDate: string = DateHelper.transform(poDateWithSeances.date, ETimetablePattern.EEE_dd_MMMM_yyyy);
						const laNewSeancesMajorees: ISeanceMajoree[] = [];
						poDateWithSeances.seances.forEach((poSeance: Seance) => {
							if (poSeance.status !== EStatusSeance.canceled)
								laNewSeancesMajorees.push(this.initSeance(poSeance));
						});

						this.seancesMajoreesByDates.set(lsDate, laNewSeancesMajorees);
					});

					this.checkAllSelectionButtonIfRequired();

					if (!StringHelper.isBlank(this.msAutoFocusedSeanceId)) {
						let lnIndex = -1;
						this.seancesMajoreesByDates.forEach((paSeancesMajorees: ISeanceMajoree[]) => {
							lnIndex++;
							if (paSeancesMajorees.some((poSeanceMajoree: ISeanceMajoree) => poSeanceMajoree._id === this.msAutoFocusedSeanceId))
								this.moVirtualScroll.scrollToIndex(lnIndex);
						});
					}

					this.fillScrollItems(this.seancesMajoreesByDates);
					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private fillScrollItems(poSeancesMajoreesByDates: Map<string, ISeanceMajoree[]>): void {
		const laItems: IScrollItem[] = [];
		poSeancesMajoreesByDates.forEach((paSeancesMajorees: ISeanceMajoree[], psKey: string) => {
			if (ArrayHelper.hasElements(paSeancesMajorees)) {
				laItems.push(psKey);
				paSeancesMajorees.forEach((poSeanceMajoree: ISeanceMajoree) => laItems.push(poSeanceMajoree));
			}
		});

		let lbHasChanges = false;
		const lnMaxLength: number = Math.max(laItems.length, this.virtualScrollItems.length);
		for (let lnIndex = 0; lnIndex < lnMaxLength; ++lnIndex) {
			const loNewScrollItem: IScrollItem = laItems[lnIndex];
			const loOldScrollItem: IScrollItem = this.virtualScrollItems[lnIndex];

			if (typeof loNewScrollItem === "string" && typeof loOldScrollItem === "string")
				lbHasChanges = loNewScrollItem !== loOldScrollItem;
			else if (typeof loNewScrollItem === "object" && typeof loOldScrollItem === "object") {
				const lnMajoMaxLength: number = Math.max(loNewScrollItem.seance.majorations.length, loOldScrollItem.seance.majorations.length);
				for (let lnMajoIndex = 0; lnMajoIndex < lnMajoMaxLength; lnMajoIndex++) {
					const loNewMajo: Majoration = loNewScrollItem.seance.majorations[lnMajoIndex];
					const loOldMajo: Majoration = loOldScrollItem.seance.majorations[lnMajoIndex];

					lbHasChanges = loNewMajo?.type !== loOldMajo?.type || loNewMajo?.disabled !== loOldMajo?.disabled;
					if (lbHasChanges)
						break;
				}
			}
			else
				lbHasChanges = true;

			if (lbHasChanges)
				break;
		}

		if (lbHasChanges)
			this.virtualScrollItems.resetArray(laItems);
	}

	private initSeance(poSeance: Seance): ISeanceMajoree {
		return {
			_id: `${poSeance.startDate.getTime()}`,
			id: poSeance.id,
			isProtected: poSeance.isProtected,
			startDate: poSeance.startDate,
			statusLabel: poSeance.statusLabel,
			seance: poSeance
		};
	}

	/** Toutes les majorations changent (toutes cochées ou décochées). */
	public onAllMajorationsChanged(): void {
		this.isvcTraitement.addMajoration(
			this.traitement,
			this.traitement.seances,
			{
				createDate: new Date,
				createUserContactId: UserHelper.getUserContactId(),
				id: this.currentMajorationDetails?.valueType,
				disabled: this.isAllSelected // On veut la valeur d'avant modification
			},
			this.createMajoration(this.currentMajorationDetails?.valueType, this.currentMajorationDetails.label)
		);

		this.isAllSelected = !this.isAllSelected;

		this.onTraitementChanged();
	}

	/** Le composant a reçu un événement indiquant un changement.
	 * @param psKey Chaîne de caractères correspondant à une clé, pour identifier l'abonnement.
	 */
	private onTraitementSlideEvent(psKey: string): void {
		if (psKey === TraitementSlideParentBase.C_RESET_MAJORATION)
			this.resetMajorations();
	}

	private resetMajorations(): void {
		this.seancesMajoreesByDates.clear();
		this.init();
	}

	/** Une modification a lieu dans l'onglet de majoration actuel.
	 * @param poSeanceMajoree Séance où a lieu la modification.
	 */
	public onMajorationChanged(poSeanceMajoree: ISeanceMajoree): void {
		this.isvcTraitement.addMajoration(
			this.traitement,
			this.traitement.seances,
			{
				createDate: new Date,
				createUserContactId: UserHelper.getUserContactId(),
				id: this.currentMajorationDetails.valueType,
				disabled: poSeanceMajoree.seance.hasMajoration(this.currentMajorationDetails.valueType), // On veux l'ancienne valeur
				matcher: poSeanceMajoree.startDate
			},
			this.createMajoration(this.currentMajorationDetails.valueType, this.currentMajorationDetails.label)
		);

		this.onTraitementChanged();
		this.checkAllSelectionButtonIfRequired();
	}

	/** Crée une majoration.
	 * @param peMajorationType Clé du type de la majoration à créer.
	 * @param psMajorationLabel Label de la majoration à créer.
	 */
	private createMajoration(peMajorationType: EMajorationType, psMajorationLabel: string): Majoration {
		return new Majoration(
			this.isvcSeance.getMajorationIdFromType(peMajorationType),
			peMajorationType,
			this.isvcSeance.getMajorationPriceFromArray(this.maMajorations, peMajorationType),
			psMajorationLabel,
			true
		);
	}

	/** Détection de changement dans une collection de données pour indiquer à Angular quel élément il doit rafraîchir.
	 * @param pnIndex Index généré par le ngFor.
	 */
	public trackByIndex(pnIndex: number): number {
		return pnIndex;
	}

	/** Permet de boucler avec le `*ngFor` en conservant les clé par ordre d'insertion dans la map
	 * plutôt que l'ordre par défaut du pipe `keyvalue` (ordre alphabétique des clés).
	 */
	public conserveInsertionSort(): number {
		return 1;
	}

	public canEditMajoration(poSeanceMajoree: ISeanceMajoree, peMajorationType: EMajorationType): boolean {
		if (peMajorationType === EMajorationType.SundayAndHolyday)
			return DateHelper.isPublicHoliday(poSeanceMajoree.startDate) || poSeanceMajoree.startDate.getDay() === 0;
		return true;
	}

	public isDate(poItem: IScrollItem): boolean {
		return typeof poItem === "string";
	}

	//#endregion
}
