import { Component, Input, OnInit } from '@angular/core';
import { PanneauService } from '../../../services/panneau.service';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { Router } from '@angular/router';

@Component({
  selector: 'di-panneau-choix-contact',
  templateUrl: './panneau-choix-contact.component.html',
  styleUrls: ['./panneau-choix-contact.component.scss'],
})
export class PanneauChoixContactComponent implements OnInit {

  @Input()
  public patient: IPatient;

  public model: IPatient;


  constructor(
    private svcPanneau: PanneauService,
    private svcPatients: PatientsService,
    private router: Router) { }

  ngOnInit() {
    this.model = this.patient;
  }

  public udpdateContact(contactId: string) {
    this.model = {
      ...this.model,
      contacts: [contactId]
    }
    this.svcPatients.savePatientAnakin(this.model).subscribe(() => {
      this.svcPanneau.close();
    })
  }

  handleClickAddContactOnMobile() {
    this.svcPanneau.close();
  }

}
