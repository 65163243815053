import { DateHelper } from "@osapp/helpers";
import { ConfigData } from "@osapp/model";
import { ITarifLettreCle } from "../model/ITarifLettreCle";

export class TarifHelper {
	static getFiltreTarifLettreCleParDate(tar: ITarifLettreCle, dateAComparer: number | Date): boolean {
			const compDateDebut = tar.dateDebut ? DateHelper.compareTwoDates(dateAComparer, tar.dateDebut) : 0;
			const compDateFin = tar.dateFin ? DateHelper.compareTwoDates(dateAComparer, tar.dateFin) : -1;
			return tar.zoneGeographique == ConfigData.geoZoneApp
					&& (compDateDebut > 0 || compDateDebut == 0)
					&& compDateFin < 0;
	}
}