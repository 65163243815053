<div class="di-modifier-date-seance">
	<div class="reporter-date">
		<h4>Réporter les soins à :</h4>
		<div class="actions">
			<lua-data-picker
				label=""
				[options]="options"
				direction="vertical"
				[resetSelection]="!!customDate"
				(selectedOptionsChange)="onDateSelect($event)"
			></lua-data-picker>
		</div>
	</div>
	<div class="date-personnalisee">
		<h4>Date personnalisée</h4>
		<lua-input
			type="date"
			label="Sélectionner une date"
			[fullWidth]="true"
			[(ngModel)]="customDate"
			(ngModelChange)="onCustomDateChange($event)"
		></lua-input>
	</div>
	<div class="actions">
		<lua-action-secondaire
			libelle="Changer la date"
			[fullWidth]="true"
			[isDisabled]="!selectedDate"
			(click)="handleConfirm()"
		></lua-action-secondaire>
		<lua-bouton-texte
			libelle="Annuler"
			[fullWidth]="true"
			(click)="handleCancel()"
		></lua-bouton-texte>
	</div>
</div>
