import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IHttpError } from '@osapp/model';
import { ConnexionService } from '../../../features/connexion/services/connexion.service';


@Component({
  selector: 'di-lien-expire',
  templateUrl: './lien-expire.page.html',
  styleUrls: ['./lien-expire.page.scss'],
})

export class LienExpirePage implements OnInit {
	public requestError: IHttpError;
	public email: string = "";

	constructor(
		private svcConnexion: ConnexionService,
		private router: Router
	) { }
		
	ngOnInit(): void {

	}

	public handleChangeValue(email: string): void {
		this.email = email;
	}
	
	public async sendEmailLien() {
		this.requestError = undefined;
		const error = await this.svcConnexion.sendMailRequest(this.email);
		if (error)
      this.requestError = error;
	}

	public navigateToLogin(){
		this.router.navigate(['/authenticator']);
	}
}
