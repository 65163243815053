import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Directory } from '@capacitor/filesystem';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { ModalController } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';
import { EMPTY, EmptyError, Observable, defer, from, fromEvent, of, throwError } from 'rxjs';
import { catchError, filter, finalize, last, mapTo, mergeMap, take, tap } from 'rxjs/operators';
import { GalleryModalComponent } from '../components/gallery/gallery-modal/gallery-modal.component';
import { GedMetaDocument } from '../components/gallery/models/GedMetaDocument';
import { ListGedMetaDocument } from '../components/gallery/models/ListGedMetaDocument';
import { ServerDmsBase64 } from '../components/gallery/models/server-dms-base64';
import { OsappImageComponent } from '../components/image/osapp-image.component';
import { ArrayHelper, DateHelper } from '../helpers';
import { FileHelper } from '../helpers/fileHelper';
import { StringHelper } from '../helpers/stringHelper';
import { ConfigData, ETimetablePattern } from '../model';
import { IGalleryFile } from '../model/gallery/IGalleryFile';
import { DmsFile } from '../modules/dms/model/DmsFile';
import { IDmsData } from '../modules/dms/model/IDmsData';
import { DmsService } from '../modules/dms/services/dms.service';
import { FilesystemService } from '../modules/filesystem/services/filesystem.service';
import { ModalService } from '../modules/modal/services/modal.service';
import { OsappApiHelper } from '../modules/osapp-api/helpers/osapp-api.helper';
import { ShowMessageParamsPopup } from './interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from './interfaces/ShowMessageParamsToast';
import { PlatformService } from './platform.service';
import { UiMessageService } from './uiMessage.service';
import { WorkspaceService } from './workspace.service';

@Injectable()
export class GalleryService {

	//#region FIELDS

	private static readonly C_GALLERY_MODAL_CSS_CLASS = "gallery-modal";

	//#endregion

	//#region METHODS

	constructor(
		private isvcDms: DmsService,
		private ioFileOpener: FileOpener,
		private readonly ioHttp: HttpClient,
		private isvcModal : ModalService,
		private ioModalCtrl: ModalController,
		private isvcPlatform: PlatformService,
		private isvcUiMessage: UiMessageService,
		private readonly isvcWorkspace: WorkspaceService,
		private readonly isvcFilesystem: FilesystemService
	) { }

	/** Permet de nettoyer un fichier de la galerie avant sauvegarde.
	 * @param poFile Fichier à nettoyer.
	 */
	public cleanGalleryFile(poFile: IGalleryFile): void {
		delete poFile.isNew;
		delete poFile.isAvailable;
		delete poFile.isLoading;
	}

	/** Enregistre dans le DMS, des fichiers provenant du composant `GalleryComponent`.
	 * @param paFiles Tableau des fichiers de la galerie à enregistrer.
	 */
	public saveFiles(paFiles: IGalleryFile[]): Observable<boolean> {
		return from(Array.from(paFiles))
			.pipe(
				filter((poFile: IGalleryFile) => poFile.isNew),
				tap((poFile: IGalleryFile) => this.cleanGalleryFile(poFile)),
				mergeMap((poFile: IGalleryFile) => this.isvcDms.save(poFile.file, poFile.file.createDmsMeta(poFile.guid)).pipe(mapTo(poFile))),
				last(),
				mapTo(true),
				catchError(poError => poError instanceof EmptyError ? of(true) : throwError(poError))
			);
	}

	public saveFilesANAKIN(paFiles: IGalleryFile[], entityId? :string): Observable<boolean> {
		return from(Array.from(paFiles))
			.pipe(
				filter((poFile: IGalleryFile) => poFile.isNew),
				tap((poFile: IGalleryFile) => this.cleanGalleryFile(poFile)),
				mergeMap((poFile: IGalleryFile) => this.isvcDms.saveANAKIN(poFile.file, poFile.file.createDmsMeta(poFile.guid),entityId).pipe(mapTo(poFile))),
				last(),
				mapTo(true),
				catchError(poError => poError instanceof EmptyError ? of(true) : throwError(poError))
			);
	}

	public openFile(poFile: IGalleryFile, pbIsFileOpening?: boolean): Observable<any> {
		// Si le fichier est valide et possède un chemin OU si le fichier est valide et qu'on n'est pas sur mobile (pas besoin de chemin en webapp).
		if (poFile.file &&
			(!StringHelper.isBlank(poFile.file.Path) || (StringHelper.isBlank(poFile.file.Path) && !this.isvcPlatform.isMobileApp))) {

			return this.innerOpenFile(poFile.file, poFile.name, pbIsFileOpening);
		}

		// Si le fichier n'est pas nouveau et n'est pas en cours de chargement et possède un GUID, on demande le téléchargement du fichier pour l'ouvrir.
		else if (!poFile.isNew && !poFile.isLoading && poFile.guid) {
			return defer(() => of(poFile.isLoading = true))
				.pipe(
					mergeMap(_ => this.isvcDms.get(poFile.guid)), // Récupère méta dans bdd locale, le télécharge si besoin.
					catchError(poError => { this.onOpenError(poError, poFile.name); return EMPTY; }),
					tap(_ => poFile.isAvailable = true),
					mergeMap((poResult: IDmsData) => this.innerOpenFile(poResult.file, poFile.name, pbIsFileOpening)),
					finalize(() => poFile.isLoading = false)
				);
		}

		else if (poFile.isLoading)
			this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: "Le fichier est en cours de chargement." }));

		return EMPTY;
	}

	/** Ouvre le fichier souhaité.
	 * @param poFile Fichier à ouvrir.
	 * @param psName Nom du fichier à ouvrir.
	 */
	public innerOpenFile(poFile: DmsFile, psName: string, pbIsFileOpening?: boolean): Observable<true> {
		let loOpenFile$: Observable<true | never>;

		if (!poFile) // Si le fichier n'existe pas.
			loOpenFile$ = this.onOpenError(`Fichier "${psName}" inexistant`, psName);

		else if (this.isvcPlatform.isMobileApp && !StringHelper.isBlank(poFile.Path)) { // Si on est sur mobile.
			pbIsFileOpening = true;

			loOpenFile$ = defer(() => StringHelper.isBlank(poFile.Path) ? poFile.Path$.pipe(take(1)) : of(poFile.Path))
				.pipe(
					mergeMap(async (psPath: string) => await this.isvcFilesystem.getFileUriAsync(psPath, Directory.External)),
					mergeMap((psPath: string) => this.ioFileOpener.open(psPath, poFile.MimeType)),
					catchError(poError => this.onOpenError(poError, psName)),
					finalize(() => pbIsFileOpening = false),
					mapTo(true)
				);
		}

		else // Si on est sur webapp.
			loOpenFile$ = this.innerOpenFile_browser(poFile);

		return loOpenFile$;
	}

	/** Ouvre le fichier souhaité en mode browser.
 * @param poFile Fichier à ouvrir.
 */
	private innerOpenFile_browser(poFile: DmsFile): Observable<true> {
		let loOpenFile$: Observable<true | never>;

		if (!StringHelper.isBlank(poFile.MimeType) && poFile.MimeType.indexOf("image") >= 0) { // Si on veut ouvrir une image, aucun soucis.
			const loModalOptions: ModalOptions = {
				component: OsappImageComponent,
				componentProps: { src: poFile.File },
				cssClass: GalleryService.C_GALLERY_MODAL_CSS_CLASS
			};

			loOpenFile$ = from(this.ioModalCtrl.create(loModalOptions))
				.pipe(
					tap((poModal: HTMLIonModalElement) => poModal.present()),
					mergeMap((poModal: HTMLIonModalElement) => fromEvent(poModal, "click").pipe(take(1), mergeMap(_ => poModal.dismiss()))),
					mapTo(true)
				) as Observable<true>;
		}
		else { // Sinon, on télécharge le document.
			FileHelper.downloadBlob(poFile.File as Blob, poFile.Name);
			loOpenFile$ = EMPTY;
		}

		return loOpenFile$;
	}

	/** Affiche une popup pour indiquer un problème lors de l'ouverture d'un nouveau fichier dans la galerie en mode édition.
	 * @param poOpenError Objet correspondant à l'erreur survenue lors de l'ouverture du fichier.
	 * @param psName Nom du fichier qui n'a pas pu être ouvert.
	 */
	private onOpenError(poOpenError: any, psName: string): Observable<never> {
		console.error("GLR.S::", poOpenError || "Erreur ouverture fichier ajouté dans gallerie en mode édition (pas encore sauvegardé).");

		this.isvcUiMessage.showMessage(this.getOnOpenErrorPopupParams(poOpenError, psName));

		return EMPTY;
	}

	private getOnOpenErrorPopupParams(poOpenError: any, psName: string): ShowMessageParamsPopup {
		const loParams = new ShowMessageParamsPopup();

		if (!poOpenError) { // Erreur undefined = fichier ajouté pendant modif.
			loParams.message = "Veuillez enregistrer avant de pouvoir visualiser le fichier.";
			loParams.header = "Erreur de téléchargement";
		}
		else {
			const lsMessageTitle = "Erreur d'ouverture";

			if (poOpenError.status === 9) {
				loParams.message = "Aucune application disponible permettant d’ouvrir ce type de fichier.";
				loParams.header = lsMessageTitle;
			}
			else if (poOpenError.code === "extension") {
				loParams.message = poOpenError.meta;
				loParams.header = lsMessageTitle;
			}
			else {
				loParams.message = `Le fichier "${psName}" n'est pas disponible pour le moment.`;
				loParams.header = lsMessageTitle;
			}
		}

		return loParams;
	}

	private get baseUrl(): string {
		return `${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_apps_suffix}workspaces/${ArrayHelper.getFirstElement(this.isvcWorkspace.getUserWorkspaceIds())}`;
	}

	private getHttpOptions(): { headers: HttpHeaders } {
		return {
			headers: new HttpHeaders({
				appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
				token: ConfigData.authentication.token,
				"api-key": ConfigData.environment.API_KEY,
			})
		};
	}


	public async consulterDmsPatient(psPatientId: string): Promise<ListGedMetaDocument> {
		try {
      const response = await this.ioHttp.get<ListGedMetaDocument>(
        `${this.baseUrl}/entities/patients/${psPatientId}/documents`,
        this.getHttpOptions()
      ).toPromise();
      return response;
    } catch (error) {
			this.isvcUiMessage.showMessage(
				new ShowMessageParamsPopup({
					header: "Erreur dans l'appel du dossier patient.",
					message: error.error.message,
					buttons: [{ text: "Ok" }]
				})
			);  
			return null;  
		}
	}


	public openModalDms(patientId: string, listDocContainer: GedMetaDocument[]): Promise<ServerDmsBase64 | undefined> {
    return new Promise((resolve, reject) => {
        from(this.isvcModal.open({
            component: GalleryModalComponent,
            componentProps: {
                documentList: listDocContainer.map(doc => ({
                    label: [
                        	doc.NomDoc?.trim(),
                        	DateHelper.transform(doc.dateDepot?.trim(), ETimetablePattern.dd_MM_yyyy_HH_mm_slash)
                    			].filter(Boolean).join(" - "),
                    value: doc.ID_doc
                }))
            }
        })).subscribe(
            async (documentSelected: number[] | null) => {
                if (documentSelected && documentSelected.length > 0) {
                    try {
                        const documentData = await this.getDocumentGed(documentSelected[0]);
                        resolve(documentData);
                    } catch (error) {
                        reject(error);
                    }
                } else {
                    resolve(undefined);
                }
            },
            error => {
                console.error('Erreur lors de l\'ouverture de la modal : ', error);
                reject(error);
            }
        );
    });
	}

	public async getDocumentGed(docId :number): Promise<ServerDmsBase64> {
		return this.ioHttp.get<ServerDmsBase64>(
			`${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_apps_suffix}dms/documents/${docId}/doc?systemId=fsv`,
			this.getHttpOptions()).toPromise();
	}

	//#endregion

}