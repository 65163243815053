import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { ConfigData, IContact, UserData } from '@osapp/model';
import { ContactsService } from '@osapp/services';
import { tap } from 'rxjs/operators';
import { IIdlEnvironment } from '../../../model/IIdlEnvironment';

@Component({
  selector: 'di-teletransmisson',
  templateUrl: './teletransmisson.page.html',
  styleUrls: ['./teletransmisson.page.scss'],
})
export class TeletransmissonPage implements OnInit  {

	public iframeSrc: SafeUrl;
	private firstLoad = true;


	private iframePrivate: ElementRef<HTMLIFrameElement>;
	@ViewChild('iframe') public set iframe(iframe: ElementRef<HTMLIFrameElement>) {
		if (!this.iframePrivate && iframe) {
			this.iframePrivate = iframe;
			this.initIFrame();
		}
	};


  constructor(
		private svcContacts : ContactsService,
		private readonly ioSanitizer: DomSanitizer,
		private route : Router,
		private cdr: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {

		this.svcContacts.getContactFromUserId(UserData.current?.name)
			.pipe(
				tap((poContact: IContact) => {
					let numNatPs: string = StringHelper.isBlank(poContact.rpps) ? poContact.adeli : poContact.rpps;
					const numFiness: string = poContact.finess;

					if (numNatPs && numFiness) {
						numNatPs = numNatPs + numNatPs.charAt(numNatPs.length - 1);
						this.iframeSrc = this.ioSanitizer.bypassSecurityTrustResourceUrl(`${(ConfigData.environment as IIdlEnvironment).fsv_teletransmission_url}?URLRetour=${location.origin}&numNatPs=${numNatPs}&numFiness=${numFiness}`);
					}
				})
			)
			.subscribe();
	}

	private async initIFrame(): Promise<void> {
		this.iframePrivate.nativeElement.addEventListener("load", () => {
			if (!this.firstLoad) {
				this.route.navigate(["home"])			
			}
			this.firstLoad = false;
		});
	}
}
