import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { IContact } from '@osapp/model/contacts/IContact';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { EAvatarSize } from '@osapp/model/picture/EAvatarSize';
import { IAvatar } from '@osapp/model/picture/IAvatar';
import { IHydratedGroupMember } from '@osapp/modules/groups/model/IHydratedGroupMember';
import { ContactsService } from '@osapp/services/contacts.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { merge, Observable, of } from 'rxjs';
import { catchError, filter, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { IPatient } from '../../../model/IPatient';
import { ITransmissionRapport } from '../../../model/ITransmissionRapport';
import { PatientsService } from '../../../services/patients.service';
import { RapportService } from '../../../services/rapport.service';

@Component({
	selector: "transmission-second-header",
	templateUrl: './transmission-second-header.component.html',
	styleUrls: ['./transmission-second-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransmissionSecondHeaderComponent extends ComponentBase implements OnInit {

	//#region PROPERTIES

	public readonly C_TRANSMISSION_DATE_FORMAT: string = ETimetablePattern.dd_MM_yyyy_slash;
	public readonly C_TRANSMISSION_HEURE_FORMAT: string = ETimetablePattern.HH_mm;

	/** Transmission à afficher. */
	private moTransmission: ITransmissionRapport;
	/** Transmission à afficher. */
	public get transmission(): ITransmissionRapport {
		return this.moTransmission;
	}
	@Input() public set transmission(poNewValue: ITransmissionRapport) {
		// Si on a une nouvelle valeur valide et qui n'est pas la même que l'ancienne (on s'appuie sur la révision).
		if (poNewValue && (!this.moTransmission || (this.moTransmission._rev !== poNewValue._rev))) {
			this.moTransmission = poNewValue; // On affecte la nouvelle nouvelle transmission,
			this.getCloseUser().pipe(tap(_ => this.detectChanges()), take(1))
				.subscribe(); // On récupère le contact qui l'a clôturée et on rafraîchit la vue.
		}
	}

	@Input()
	public set patient(poPatient: IPatient) {
		if (poPatient)
			this.setLinkedHydratedPatient(poPatient);
	}

	/** Indique si on a un header de transmission pour une création ou non (affichage de l'avatar du patient lié). */
	@Input() public readonly isCreation: boolean;

	/** Entité patient hydratée liée à la transmission (contient le patient et son avatar). */
	public linkedHydratedPatient: IHydratedGroupMember<IPatient>;
	/** Auteur du rapport. */
	public author: IContact;
	/** Utilisateur qui a clôturé la transmission. */
	public closeUser: IContact;

	//#endregion

	//#region METHODS

	constructor(
		private isvcRapport: RapportService,
		private isvcUiMessage: UiMessageService,
		private isvcPatients: PatientsService,
		private isvcContacts: ContactsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {

		super(poChangeDetectorRef);
	}

	public ngOnInit(): void {
		// On a deux flux en un donc on doit prendre deux émissions de valeurs (ou on coupe le flux si le composant se détruit avant).
		merge(this.getAuthor(), this.getCloseUser())
			.pipe(
				take(2),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private getAuthor(): Observable<Map<string, IPatient>> {
		return this.isvcContacts.getContactByPath(this.transmission.authorPath)
			.pipe(
				catchError(poError => {
					console.error(`TRANS.P.C:: Erreur récupération auteur '${ArrayHelper.getLastElement(this.transmission.authorPath.split("/"))}' de la transmission '${this.transmission._id}'.`, poError);

					this.isvcUiMessage.showMessage(
						new ShowMessageParamsPopup({ header: "Erreur", message: "Erreur lors de la récupération de l'auteur de cette transmission." })
					);

					return of(undefined); // On continue pour ne pas casser le pipeline.
				}),
				tap((poAuthor: IContact) => {
					this.author = poAuthor;
					this.detectChanges();
				}),
				// On filtre si on n'a pas encore récupéré le patient lié ET qu'on n'est pas en création pour éviter des requêtes multiples.
				filter(_ => !this.isCreation),
				mergeMap(_ => this.isvcRapport.getPatientByTransmissionsIds([this.transmission])),
				tap((poPatientTransmissionsMap: Map<string, IPatient>) => {
					this.setLinkedHydratedPatient(poPatientTransmissionsMap.get(this.transmission._id));
					this.detectChanges();
				})
			);
	}

	public getContactAvatar(poContact: IContact): IAvatar {
		return ContactsService.createContactAvatar(poContact, EAvatarSize.big);
	}

	private setLinkedHydratedPatient(poPatient: IPatient): void {
		this.linkedHydratedPatient = {
			groupMember: poPatient,
			avatar: poPatient ? ContactsService.createContactAvatar(poPatient) : undefined
		};
	}

	/** Récupère le contact qui a clôturé la transmission, si elle est clôturée. */
	private getCloseUser(): Observable<IContact> {
		// Si aucun utilisateur n'a clôturé la transmission ou si la transmission n'a pas de date de clôture, on ne fait rien.
		if (StringHelper.isBlank(this.transmission.closeUserId) || !DateHelper.isDate(this.transmission.closeDate))
			return of(undefined);
		else { // Sinon, on récupère le contact qui l'a clôturée.
			return this.isvcContacts.getContact(this.transmission.closeUserId)
				.pipe(tap((poResult: IContact) => this.closeUser = poResult));
		}
	}

	/** Permet de naviguer vers le patient lié à la transmission. */
	public goToPatient(): void {
		if (this.linkedHydratedPatient)
			this.isvcPatients.goToPatient(this.linkedHydratedPatient.groupMember._id);
	}

	//#endregion
}