import { NumberHelper } from "@osapp/helpers";
import { InvoiceLine } from "./invoice-line";

export class InvoiceSeance {

	//#region PROPERTIES

	public date: Date;

	private maActes: InvoiceLine[] = [];
	public get actes(): InvoiceLine[] { return this.maActes; }
	public set actes(paActes: InvoiceLine[]) {
		if (paActes !== this.maActes)
			this.maActes = paActes;
	}

	private maMajorations: InvoiceLine[] = [];
	public get majorations(): InvoiceLine[] { return this.maMajorations; }
	public set majorations(paMajorations: InvoiceLine[]) {
		if (paMajorations !== this.maMajorations)
			this.maMajorations = paMajorations;
	}

	private maIndemnites: InvoiceLine[] = [];
	public get indemnites(): InvoiceLine[] { return this.maIndemnites; }
	public set indemnites(paIndemnites: InvoiceLine[]) {
		if (paIndemnites !== this.maIndemnites)
			this.maIndemnites = paIndemnites;
	}


	public totalPartAMO = 0;
	public totalPartAMC = 0;
	public totalPartPP = 0;
	public honoraires = 0;

	//#endregion

	//#region METHODS

	constructor(pdDate: Date) {
		this.date = pdDate;
	}

	public calculate(): void {
		const laPartAMO: number[] = [];
		const laPartAMC: number[] = [];
		const laPartPP: number[] = [];
		const laHonoraires: number[] = [];

		[...this.actes, ...this.majorations, ...this.indemnites].forEach((poLine: InvoiceLine) => {
			laPartAMO.push(poLine.partAMO);
			laPartAMC.push(poLine.partAMC);
			laPartPP.push(poLine.partPatient);
			laHonoraires.push(poLine.honoraires);
		});

		this.totalPartAMO = NumberHelper.reduceNumbers(laPartAMO, 0);
		this.totalPartAMC = NumberHelper.reduceNumbers(laPartAMC, 0);
		this.totalPartPP = NumberHelper.reduceNumbers(laPartPP, 0);
		this.honoraires = NumberHelper.reduceNumbers(laHonoraires, 0);
	}

	//#endregion

}
