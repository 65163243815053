import { ChangeDetectorRef, Component } from '@angular/core';
import { FormsService } from '@osapp/services/forms.service';
import { SlideboxService } from '@osapp/services/slidebox.service';
import { EMPTY, from } from 'rxjs';
import { catchError, mergeMap, take, takeUntil } from 'rxjs/operators';
import { TraitementService } from '../../../../../services/traitement.service';
import { IdlActionButtonFieldBase } from '../../../model/action-buttons/IdlActionButtonFieldBase';
import { IPatient } from '../../../model/IPatient';
import { IdlActionButtonService } from '../../../services/idl-action-button.service';

@Component({
	templateUrl: '../idl-action-button-field.component.html'
})
export class ActionButtonFieldTraitementComponent extends IdlActionButtonFieldBase {

	//#region FIELDS

	/** Identifiant de la slide des traitements. */
	private static readonly C_TRAITEMENT_VIEWPATIENT_SLIDEBOX_ID = "viewPatientSlideTraitements";
	/** Phrase indiquant que des traitements sont en cours pour un patient. */
	public static readonly C_ONGOING_TRAITEMENTS_SENTENCE = "Des traitements sont en cours pour ce patient.\nSélectionnez un traitement existant ou créez-en un nouveau.";

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcTraitement: TraitementService,
		psvcSlidebox: SlideboxService,
		psvcActionButton: IdlActionButtonService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcSlidebox, psvcActionButton, psvcForms, poChangeDetectorRef);
	}

	/** @override Ouvre un composant. */
	public action(): void {
		const lsSlideId: string = ActionButtonFieldTraitementComponent.C_TRAITEMENT_VIEWPATIENT_SLIDEBOX_ID;

		from(this.isvcSlidebox.slideTo(lsSlideId, this.isvcSlidebox.getLastSlideContainer().componentId))
			.pipe(
				catchError(poError => {
					console.error(`IDL.ABFT.C:: slide '${lsSlideId}' introuvable, slide vers les traitements liés impossible.`, poError);
					return EMPTY;
				}),
				mergeMap(_ => this.isvcTraitement.checkOngoingTraitements(this.model as IPatient)),
				mergeMap((pbHasOngoingTraitements: boolean) => {
					if (pbHasOngoingTraitements)
						return this.isvcActionButton.warningCreationFromActionButton(ActionButtonFieldTraitementComponent.C_ONGOING_TRAITEMENTS_SENTENCE);
					else {
						this.isvcTraitement.navigateToNewTraitement(this.model as IPatient);
						return EMPTY;
					}
				}),
				take(1),
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();
	}

	//#endregion
}