import { ContentChildren, Directive, Input, OnDestroy, OnInit, Optional, QueryList } from '@angular/core';
import { IonContent, IonItemSliding } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { tap, throttleTime } from 'rxjs/operators';

@Directive({
	selector: '[osappAutoCloseSlideItem]'
})
export class AutoCloseSlideItemDirective implements OnInit, OnDestroy {

	//#region FIELDS

	private moScrollSubscription: Subscription;

	//#endregion

	//#region PROPERTIES

	@ContentChildren(IonItemSliding) public itemsSliding: QueryList<IonItemSliding>;
	@Input() public ionContent: IonContent;

	//#endregion

	//#region METHODS

	constructor(@Optional() private ioIonContent: IonContent) {

	}

	public ngOnInit(): void {
		if (!this.ionContent)
			this.ionContent = this.ioIonContent;

		if (this.ionContent) {
			this.ionContent.scrollEvents = true;
			this.moScrollSubscription = this.ionContent.ionScroll
				.pipe(
					throttleTime(500),
					tap(_ => {
						if (this.itemsSliding)
							this.itemsSliding.forEach((poItemSliding: IonItemSliding) => poItemSliding.close());
					})
				)
				.subscribe();
		}
	}

	public ngOnDestroy(): void {
		if (this.ionContent)
			this.ionContent.scrollEvents = false;
		if (this.moScrollSubscription)
			this.moScrollSubscription.unsubscribe();
	}

	//#endregion
}
