import { Component } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { IDestroyable } from "../lifecycle/models/IDestroyable";

@Component({ template: "" })
export abstract class DestroyableComponentBase implements IDestroyable {

	//#region FIELDS

	private readonly moDestroySubject = new ReplaySubject<void>(1);

	//#endregion

	//#region PROPERTIES

	/** @implements */
	public get destroyed$(): Observable<void> { return this.moDestroySubject.asObservable(); }

	private mbDestroyed = false;
	/** @implements */
	public get destroyed(): boolean { return this.mbDestroyed; }

	//#endregion PROPERTIES

	//#region METHODS

	public ngOnDestroy(): void {
		this.mbDestroyed = true;
		this.moDestroySubject.next();
		this.moDestroySubject.complete();
	}

	//#endregion METHODS

}