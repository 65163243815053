import { Component, Input, OnInit } from '@angular/core';
import { DateHelper } from '@osapp/helpers';
import { StoredSeance } from 'apps/idl/src/anakin/models/StoredSeance';
import { getHours, getMinutes, getSeconds, set } from 'date-fns';

@Component({
	selector: 'di-modifier-date-seance',
	templateUrl: './modifier-date-seance.component.html',
	styleUrls: ['./modifier-date-seance.component.scss'],
})
export class ModifierDateSeanceComponent implements OnInit {
	@Input() seance?: StoredSeance;
	@Input() onConfirm: (date: Date) => void;
	@Input() onCancel: () => void;

	originalDate: Date;
	selectedDate: Date | null = null;
	demainObj: { value: string; label: string; disabled: boolean };
	apresDemainObj: { value: string; label: string; disabled: boolean };
	options: any[];
	customDate: string = '';

	constructor() {}

	ngOnInit(): void {
		this.originalDate = new Date(this.seance?.startDate);
		const demain = DateHelper.transform(
			DateHelper.addDays(this.originalDate, 1),
			'EEE d MMMM yyyy'
		);
		const apresDemain = DateHelper.transform(
			DateHelper.addDays(this.originalDate, 2),
			'EEE d MMMM yyyy'
		);

		this.demainObj = {
			value: `Demain (${demain})`,
			label: `Demain (${demain})`,
			disabled: false,
		};

		this.apresDemainObj = {
			value: `Après-demain (${apresDemain})`,
			label: `Après-demain (${apresDemain})`,
			disabled: false,
		};

		this.options = [this.demainObj, this.apresDemainObj];
	}

	onCustomDateChange(newDate: string): void {
		this.customDate = newDate;

		if (this.customDate) {
			this.demainObj = { ...this.demainObj, disabled: true };
			this.apresDemainObj = { ...this.apresDemainObj, disabled: true };
			this.selectedDate = set(new Date(this.customDate), {
				hours: getHours(this.originalDate),
				minutes: getMinutes(this.originalDate),
				seconds: getSeconds(this.originalDate),
			});
			this.options = [this.demainObj, this.apresDemainObj];
		} else {
			this.demainObj = { ...this.demainObj, disabled: false };
			this.apresDemainObj = { ...this.apresDemainObj, disabled: false };
			this.selectedDate = null;
			this.options = [this.demainObj, this.apresDemainObj];
		}
	}

	onDateSelect(option: string[]) {
		if (option.length === 0) {
			this.selectedDate = null;
		} else if (option[0]?.startsWith('Demain')) {
			this.selectedDate = DateHelper.addDays(this.originalDate, 1);
		} else {
			this.selectedDate = DateHelper.addDays(this.originalDate, 2);
		}
	}

	handleConfirm() {
		this.onConfirm && this.onConfirm(this.selectedDate);
	}

	handleCancel() {
		this.onCancel && this.onCancel();
	}
}
