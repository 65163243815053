<ion-item-sliding #itemSliding *ngIf="invoice">
	<ion-item class="item transform-lg-up">
		<div class="item-ctn">
			<div class="avatar-area">
				<avatar [src]="getContactAvatar(intervenant)" cssClass="round">
				</avatar>
			</div>

			<div class="item-detail facture-detail">
				<ion-label class="title">{{ invoice.label }}
				</ion-label>
				<div *ngIf="ordonnance; else noOrdonnance" class="ordonnance-ctn">
					<ion-label class="label-info">
						Ordonnance du {{ ordonnance.prescriptionDate | dateWithLocale: 'fr-FR' : 'dd/MM/yyyy' }}
					</ion-label>
					<idl-ordonnance-status-icons [ordonnance]="ordonnance"></idl-ordonnance-status-icons>
				</div>
				<ng-template #noOrdonnance>
					<div class="ordonnance-ctn">
						<ion-label class="label-info">Aucune ordonnance</ion-label>
						<idl-ordonnance-status-icons></idl-ordonnance-status-icons>
					</div>
				</ng-template>
				<ion-label class="label-info">
					{{ invoice.nbSeances }} {{ invoice.nbSeances === 1 ? 'séance' : 'séances' }} à facturer
					<span>: {{ invoice.isPriced ? (invoice.honoraires | priceFormat:2) : "-,--" }} €</span>
				</ion-label>
				<div class="flex-gap italic label-info">
					<ion-label>
						Du {{ invoice.minDate | dateWithLocale: 'fr-FR' : 'dd/MM/yyyy'}}
						au {{ invoice.maxDate | dateWithLocale: 'fr-FR' : 'dd/MM/yyyy'}}
					</ion-label>

					<ion-label *ngIf="prescripteur">
						Prescripteur: {{ prescripteur | contactName }}
					</ion-label>
				</div>
			</div>
		</div>
		<div *ngIf="!isModeAgrement" class="price-detail flex-gap" [ngClass]="invoice.isPriced ? 'tarification': ''">
			<ion-label>
				AMO: {{ invoice.isPriced ? (invoice.totalPartAMO | priceFormat:2) : "-,--" }} €
			</ion-label>
			<ion-label>
				AMC: {{ invoice.isPriced ? (invoice.totalPartAMC | priceFormat:2) : "-,--" }} €
			</ion-label>
			<ion-label>
				PP: {{ invoice.isPriced ? (invoice.totalPartPatient | priceFormat:2) : "-,--" }} €
			</ion-label>
		</div>

		<!-- Bouton des options de l'item. -->
		<ion-button class="btn-no-margin btn-more ion-hide-lg-up" fill="clear" slot="end"
			(click)="openOrCloseItemSliding(itemSliding, $event)">
			<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
		</ion-button>

		<div class="invoice-actions ion-hide-lg-down">
			<div class="status">
				<div class="icon-area">
					<ion-icon [name]="invoice.statusIcon"></ion-icon>
				</div>
				<ion-note>{{invoice.statusLabel}}</ion-note>
			</div>

			<div class="buttons">
				<ion-button *ngIf="!isModeAgrement || (isModeAgrement && invoice.isClosed)" (click)="goToInvoice(invoice._id)"
					fill="clear" shape="round" color="primary" size="big">
					<ion-icon slot="start" name="eye"></ion-icon>
					<ion-label>Voir</ion-label>
				</ion-button>

				<ion-button *ngIf="invoice.isClosed" (click)="$event.stopPropagation(); exportInvoicePdf(invoice);"
					shape="round" color="primary" size="big">
					<ion-icon slot="start" name="file-pdf"></ion-icon>
					<ion-label>Exporter en PDF</ion-label>
				</ion-button>

				<ion-button *ngIf="!readonly && invoice?.canCancel" fill="clear" shape="round" color="danger" size="big"
					(click)="$event.stopPropagation(); invoiceCancelAction(invoice)">
					<ion-icon slot="start" [name]="invoice.cancelIcon"></ion-icon>
					{{invoice.cancelLabel}}
				</ion-button>

				<ion-button *ngIf="!readonly && invoice?.canGoForward && !isModeAgrement"
					(click)="$event.stopPropagation(); invoiceAction(invoice);" shape="round" color="success" size="big">
					<ion-icon slot="start" [name]="invoice.nextActionIcon"></ion-icon>
					<ion-label>{{invoice.nextActionLabel}}</ion-label>
				</ion-button>

				<ion-button *ngIf="!readonly && isModeAgrement"
					(click)="$event.stopPropagation(); exportInvoiceAgrement(invoice);" shape="round" color="success" size="big">
					<ion-icon slot="start" name="invoice"></ion-icon>
					<ion-label>Facturer</ion-label>
				</ion-button>
			</div>
		</div>
	</ion-item>

	<!-- Options des items.-->
	<ion-item-options class="ion-hide-lg-up" side="end" (ionSwipe)="openOrCloseItemSliding(itemSliding)">
		<ion-button *ngIf="!isModeAgrement || (isModeAgrement && invoice.isClosed)" class="option-btn" color="primary"
			(click)="itemSliding.close(); goToInvoice(invoice._id);">
			<ion-icon slot="icon-only" name="create"></ion-icon>
			<ion-label class="ion-hide-sm-down">Afficher</ion-label>
		</ion-button>

		<ion-button *ngIf="invoice.isClosed" (click)="$event.stopPropagation(); exportInvoicePdf(invoice);"
			class="option-btn" color="primary">
			<ion-icon slot="icon-only" name="file-pdf"></ion-icon>
			<ion-label class="ion-hide-sm-down">Exporter en PDF</ion-label>
		</ion-button>

		<ion-button *ngIf="!readonly && invoice?.canCancel" class="option-btn" color="danger"
			(click)="itemSliding.close(); invoiceCancelAction(invoice)">
			<ion-icon slot="icon-only" [name]="invoice.cancelIcon"></ion-icon>
			<ion-label class="ion-hide-sm-down">{{invoice.cancelLabel}}</ion-label>
		</ion-button>

		<ion-button *ngIf="!readonly && invoice?.canGoForward && !isModeAgrement" class="option-btn" color="success"
			(click)="itemSliding.close(); invoiceAction(invoice)">
			<ion-icon slot="icon-only" [name]="invoice.nextActionIcon"></ion-icon>
			<ion-label class="ion-hide-sm-down">{{invoice.nextActionLabel}}</ion-label>
		</ion-button>

		<ion-button *ngIf="!readonly && isModeAgrement" (click)="itemSliding.close(); exportInvoiceAgrement(invoice);"
			class="option-btn" color="success">
			<ion-icon slot="start" name="invoice"></ion-icon>
			<ion-label class="ion-hide-sm-down">Facturer</ion-label>
		</ion-button>
	</ion-item-options>
</ion-item-sliding>

<div *ngIf="isModeAgrement && invoice && !invoice.isClosed" class="tp-ctn">
	<div class="tp">
		<span>AMO TP :</span>
		<div>
			<osapp-selector [options]="TPOptions" [preselectedValues]="[invoice.amoTp]"
				(selectionChanged)="onAmoTPChanged($event)" limit="1">
			</osapp-selector>
		</div>
	</div>
	<div class="tp">
		<span>AMC TP :</span>
		<div>
			<osapp-selector [options]="TPOptions" [preselectedValues]="[invoice.amcTp]"
				(selectionChanged)="onAmcTPChanged($event)" limit="1">
			</osapp-selector>
		</div>
	</div>
</div>