import { coerceArray } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SearchComponent } from '@osapp/components/search/search.component';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ContactHelper } from '@osapp/helpers/contactHelper';
import { NumberHelper } from '@osapp/helpers/numberHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { IContact } from '@osapp/model/contacts/IContact';
import { IGroup } from '@osapp/model/contacts/IGroup';
import { EAvatarSize } from '@osapp/model/picture/EAvatarSize';
import { IAvatar } from '@osapp/model/picture/IAvatar';
import { Permissions, PermissionsService } from '@osapp/modules/permissions/services/permissions.service';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { ContactsService } from '@osapp/services/contacts.service';
import { GroupsService } from '@osapp/services/groups.service';
import { defer, of } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { C_DESMOS_PERMISSION_ID } from '../../../../app/app.constants';
import { IdlApplicationService } from '../../../../services/idlApplicationService.service';
import { IPatient } from '../../model/IPatient';
import { IPatientSelectorParams } from '../../model/IPatientSelectorParams';
import { PatientsService } from '../../services/patients.service';

@Component({
	selector: 'idl-patient-selector',
	templateUrl: './patient-selector.component.html',
	styleUrls: ['./patient-selector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientSelectorComponent extends ComponentBase implements OnInit {

	//#region FIELDS

	private moGroupIdsByPatientId: Map<string, string[]>;
	private maSelectedGroups: IGroup[];
	@Output("selectionChanged") private readonly moSelectionChangedEventEmitter = new EventEmitter<IPatient[]>();
	private maSelectedPatients: IPatient[] = [];

	//#endregion

	//#region PROPERTIES

	/** Paramètres du composant. */
	@Input() public params: IPatientSelectorParams;

	public patients: IPatient[] = [];
	public filteredPatientOptions: ISelectOption<IPatient>[] = [];
	/** Tableau des patients récupérés depuis Desmos. */
	public desmosPatients: IPatient[] = [];
	/** Indique si ce sont les patients issus de Desmos qu'il faut afficher ou ceux issus de la base de données. */
	public showDesmosPatients = false;

	public get areAllPatientSelected(): boolean {
		return this.filteredPatientOptions.every((poPatientOption: ISelectOption<IPatient>) => poPatientOption.selected);
	}

	public get canSelectMore(): boolean {
		return !NumberHelper.isValid(this.params.selectionLimit) ||
			this.getSelectedValues().length < this.params.selectionLimit;
	}

	public get isValid(): boolean {
		const lnSelectedLength: number = this.getSelectedValues().length;

		return lnSelectedLength >= this.params.selectionMinimum && lnSelectedLength <= this.params.selectionLimit;
	}

	@Permissions("read", C_DESMOS_PERMISSION_ID)
	public get canUseDesmos(): boolean {
		return true;
	}

	public searchValue: string;
	public isLoading = true;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcContacts: ContactsService,
		private readonly isvcPatients: PatientsService,
		private readonly isvcGroups: GroupsService,
		public readonly isvcPermissions: PermissionsService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);
	}

	public ngOnInit(): void {
		this.initParams();

		// On initialise les patients
		defer(() => {
			if (ArrayHelper.hasElements(this.params.contactsData))
				return of(this.params.contactsData);
			return this.isvcPatients.getSitePatients();
		})
			.pipe(
				tap((paPatients: IPatient[]) => {
					this.isLoading = false;
					this.onFilteredPatientsChanged(this.patients = paPatients);
				}),
				filter(() => !!this.params.groupFilterParams), // Si il n'y a pas de filtrage par secteur on arrête là.
				switchMap((paPatients: IPatient[]) => this.isvcGroups.getContactsGroupIds(paPatients.map((poPatient: IPatient) => poPatient._id), true)),
				tap((poGroupIdsByPatientId: Map<string, string[]>) => {
					poGroupIdsByPatientId.forEach((paGroupIds: string[]) =>
						ArrayHelper.removeElementsByFinder(paGroupIds,
							(psGroupId: string) => !this.params.groupFilterParams.options.some((poOption: ISelectOption<IGroup>) => poOption.value._id === psGroupId)
						)
					);

					this.moGroupIdsByPatientId = poGroupIdsByPatientId;
				}),
				takeUntil(this.destroyed$)
			).subscribe();
	}

	private initParams(): void {
		if (!this.params)
			this.params = {};

		if (!NumberHelper.isValidPositive(this.params.selectionMinimum))
			this.params.selectionMinimum = 0;

		if (!NumberHelper.isValidPositive(this.params.selectionLimit))
			this.params.selectionLimit = Infinity;

		if (!this.params.preSelectedIds)
			this.params.preSelectedIds = [];

		if (!this.params.contactsData)
			this.params.contactsData = [];

		if (this.params.groupFilterParams?.preselectedValues)
			this.maSelectedGroups = coerceArray(this.params.groupFilterParams.preselectedValues);

		this.params.allSelectionButton = this.params.allSelectionButton ?? true;

		this.prepareSearchOptions();
	}

	/** Prépare les options pour le composant 'Search'. */
	private prepareSearchOptions(): void {
		if (!this.params.searchOptions)
			this.params.searchOptions = {};

		this.params.searchOptions = {
			hasPreFillData: this.params.searchOptions.hasPreFillData !== undefined ? this.params.searchOptions.hasPreFillData : true,
			searchboxPlaceholder: !StringHelper.isBlank(this.params.searchOptions.searchboxPlaceholder) ?
				this.params.searchOptions.searchboxPlaceholder : "Rechercher un patient",
			searchableFields: [{ key: "firstName" }, { key: "lastName" }, { key: "socialNumber" }, { key: "birthDate" }],
			...this.params.searchOptions
		};
	}

	private transformPatientToSelectOptions(paPatients: IPatient[]): ISelectOption<IPatient>[] {
		return paPatients.map((poPatient: IPatient) => ({
			label: ContactHelper.getCompleteFormattedName(poPatient),
			value: poPatient,
			selected: this.maSelectedPatients.includes(poPatient)
		}));
	}

	public onFilteredPatientsChanged(paNewFilteredPatients: IPatient[]): void {
		this.isvcContacts.sortMembers(paNewFilteredPatients);
		this.filteredPatientOptions = this.filterByGroups(this.transformPatientToSelectOptions(paNewFilteredPatients), this.maSelectedGroups);

		this.detectChanges();
	}

	/** Lance une requête de recherche d'un patient usr desmos avec l'api. */
	public onSearchWithDesmos(paResults: IPatient[]): void {
		if (ArrayHelper.hasElements(paResults)) {
			this.showDesmosPatients = true;
			this.desmosPatients = paResults;
		}
	}

	public onPatientSelectionChanged(poPatientOption: ISelectOption<IPatient>): void {
		if (!poPatientOption.disabled) {
			if (this.params.selectionLimit === 1) {
				this.filteredPatientOptions.forEach((poFilteredPatientOption: ISelectOption<IPatient>) => {
					if (poFilteredPatientOption !== poPatientOption) {
						poFilteredPatientOption.selected = false;
						this.updateSelectedPatientIds(poFilteredPatientOption);
					}
				});
			}

			poPatientOption.selected = !poPatientOption.selected;

			this.updateSelectedPatientIds(poPatientOption);

			this.moSelectionChangedEventEmitter.emit(this.getSelectedValues());
			this.detectChanges();
		}
	}

	private updateSelectedPatientIds(poPatientOption: ISelectOption<IPatient>): void {
		if (poPatientOption.selected)
			this.maSelectedPatients.push(poPatientOption.value);
		else
			ArrayHelper.removeElement(this.maSelectedPatients, poPatientOption.value);
	}

	private getSelectedValues(): IPatient[] {
		return this.patients
			.filter((poPatient: IPatient) => this.maSelectedPatients.includes(poPatient));
	}

	/** Ouverture de la page de création d'un patient avec le patient desmos cliqué.
	 * @param poDesmosPatient Patient issu de Desmos.
	 */
	public onDesmosItemClicked(poDesmosPatient: IPatient): void {
		this.isvcContacts.openCreateContactModal({
			model: poDesmosPatient,
			formDefinitionId: IdlApplicationService.C_PATIENTS_BOOK_FORMDEF_ID_EDIT,
			formDescriptorId: IdlApplicationService.C_PATIENTS_BOOK_FORMDESC_ID
		})
			.pipe(
				tap(() => {
					this.showDesmosPatients = false;
					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public onGroupFilterSelectionChanged(paGroups: IGroup[], poSearchComponent: SearchComponent<IPatient>): void {
		this.maSelectedGroups = paGroups;
		this.onFilteredPatientsChanged(poSearchComponent.search());
	}

	private filterByGroups(paOptions: ISelectOption<IPatient>[], paGroups: IGroup[]): ISelectOption<IPatient>[] {
		return !ArrayHelper.hasElements(paGroups) ? paOptions : paOptions.filter((poOption: ISelectOption<IPatient>) =>
			paGroups.some((poSelectedGroup: IGroup) =>
				this.moGroupIdsByPatientId.get(poOption.value._id)?.some((psGroupId: string) => psGroupId === poSelectedGroup._id)
			)
		);
	}

	public onSelectAllPatientChanged(): void {
		const lbIsSelected: boolean = !this.areAllPatientSelected;
		this.filteredPatientOptions.forEach((poPatientOption: ISelectOption<IPatient>) => {
			poPatientOption.selected = lbIsSelected;
			this.updateSelectedPatientIds(poPatientOption);
		});
		this.moSelectionChangedEventEmitter.emit(this.getSelectedValues());
		this.detectChanges();
	}

	public onSearchValueChanged(psSearchValue: string): void {
		this.searchValue = psSearchValue;
		this.detectChanges();
	}

	public getContactAvatar(poContact: IContact): IAvatar {
		return ContactsService.createContactAvatar(poContact, EAvatarSize.big);
	}

	//#endregion

}
