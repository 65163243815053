import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { IdHelper } from '../../helpers/idHelper';
import { EPrefix } from '../../model';
import { IConversation } from '../../model/conversation/IConversation';
import { ContactsService } from '../../services/contacts.service';
import { ConversationService } from '../../services/conversation.service';
import { NetworkService } from '../../services/network.service';

@Injectable({
	providedIn: "root"
})
export class ConversationResolver implements Resolve<IConversation> {

	//#region METHODS

	constructor(protected ioConversationService: ConversationService, private isvcNetwork: NetworkService) { }

	public resolve(poRouteSnapshot: ActivatedRouteSnapshot): Observable<IConversation> {
		const lsConversationId: string = IdHelper.buildId(EPrefix.conversation, poRouteSnapshot.params.conversationId);
		const lsContactId: string = IdHelper.buildId(EPrefix.contact, poRouteSnapshot.queryParams.userId ?? ContactsService.getUserContactId());

		return this.ioConversationService.getConversation(lsConversationId)
			.pipe(
				mergeMap((poConversation: IConversation) => {
					if (!poConversation) // Si on a une conversation, on la retourne, sinon on tente un téléchargement.
						return this.isvcNetwork.asyncIsNetworkReliable().pipe(
							mergeMap((pbIsNetworkReliable: boolean) => pbIsNetworkReliable ? this.ioConversationService.downloadConversation(lsConversationId) : of(poConversation))
						);

					return of(poConversation);
				}),
				mergeMap((poConversation: IConversation) => this.ioConversationService.hydrateConversation(poConversation, lsContactId))
			);
	}

	//#endregion

}