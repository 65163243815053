import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { StringHelper } from '../../../../helpers/stringHelper';
import { PlatformService } from '../../../../services/platform.service';
import { BottomSheetComponentBase } from '../../models/BottomSheetComponentBase';
import { IBottomSheetHeaderParams } from '../../models/IBottomSheetHeaderParams';

@Component({
	selector: "osapp-bottom-sheet-header",
	templateUrl: './bottom-sheet-header.component.html',
	styleUrls: ['./bottom-sheet-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BottomSheetHeaderComponent extends BottomSheetComponentBase<void, void> implements IBottomSheetHeaderParams, OnInit {

	//#region FIELDS

	/** Couleur par défaut du texte de la toolbar. */
	private static readonly C_DEFAULT_TOOLBAR_TEXT_COLOR = "toolbar";

	//#endregion

	//#region PROPERTIES

	private msTitle: string;
	/** @implements */
	public get title(): string { return this.msTitle; }
	@Input() public set title(psValue: string) {
		if (psValue !== this.msTitle) {
			this.msTitle = psValue;
			this.detectChanges();
		}
	}

	private msToolbarTextColor: string;
	/** @implements */
	public get toolbarTextColor(): string { return this.msToolbarTextColor; }
	@Input() public set toolbarTextColor(psValue: string) {
		if (psValue !== this.msToolbarTextColor && !StringHelper.isBlank(psValue)) {
			this.msToolbarTextColor = psValue;
			this.detectChanges();
		}
	}

	private msCloseIcon: string;
	/** @implements */
	public get closeIcon(): string { return this.msCloseIcon; }
	@Input() public set closeIcon(psValue: string) {
		if (psValue !== this.msCloseIcon && !StringHelper.isBlank(psValue)) {
			this.msCloseIcon = psValue;
			this.detectChanges();
		}
	}

	private mbHasBorder: boolean = false;
	/** @implements */
	public get hasBorder(): boolean { return this.mbHasBorder; }
	@Input() public set hasBorder(pbValue: boolean) {
		if (pbValue !== this.mbHasBorder) {
			this.mbHasBorder = pbValue;
			this.detectChanges();
		}
	}

	//#endregion

	//#region METHODS

	constructor(poMatBottomSheet: MatBottomSheet, psvcPlatform: PlatformService, poChangeDetector: ChangeDetectorRef) {
		super(poMatBottomSheet, psvcPlatform, poChangeDetector);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		if (StringHelper.isBlank(this.toolbarTextColor))
			this.toolbarTextColor = BottomSheetHeaderComponent.C_DEFAULT_TOOLBAR_TEXT_COLOR;
	}

	//#endregion

}