<osapp-header-modal [title]="title"></osapp-header-modal>

<ion-content>
	<ion-list>
		<ion-radio-group #radioGroup>
			<ng-container *ngFor="let choice of choices">
				<ion-item *ngIf="choice.value?.length > 0">
					<ion-label>{{ choice.label }}</ion-label>
					<ion-radio mode="md" slot="start" [value]="choice"></ion-radio>
				</ion-item>
			</ng-container>
		</ion-radio-group>
	</ion-list>

</ion-content>

<ion-footer class="ion-no-border">
	<ion-toolbar>
		<ion-label *ngIf="radioGroup.value?.value?.length > 1" color="danger">Attention, {{
			getNbSeances(radioGroup.value.value) }} séances vont
			être impactées.</ion-label>
		<ion-buttons slot="end">
			<ion-button shape="round" color="fab-button" (click)="close()">Annuler</ion-button>
			<ion-button [disabled]="!radioGroup.value?.value || radioGroup.value.value.length === 0" shape="round"
				color="fab-button" (click)="onClose(radioGroup.value.value, radioGroup.value.filters)">Ok</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-footer>