/** Enum pour les formattages de dates et ou horaires. */
export enum ETimetablePattern {

	/** Formattage de date sous la forme `14 07 2020`. */
	dd_MM_yyyy = "dd MM yyyy",
	/** Formattage de date sous la forme `14 juil. 2020`. */
	dd_MMM_yyyy = "dd MMM yyyy",
	/** Formattage de date sous la forme `14/07/2020`. */
	dd_MM_yyyy_slash = "dd/MM/yyyy",
	/** Formattage de date sous la forme `14 07 2020 19 45`. */
	dd_MM_yyyy_HH_mm = "dd MM yyyy HH mm",
	/** Formattage de date sous la forme `14/07/2020 19:45`. */
	dd_MM_yyyy_HH_mm_slash = "dd/MM/yyyy HH:mm",
	/** Formattage de date sous la forme `14 juillet 2020`. */
	dd_MMMM_yyyy = "dd MMMM yyyy",
	/** Formattage de date sous la forme `mar. 14 juillet 2020`. */
	EEE_dd_MMMM_yyyy = "EEE dd MMMM yyyy",
	/** Formattage de date sous la forme `mardi 14 juillet`. */
	EEEE_dd_MMMM = "EEEE dd MMMM",
	/** Formattage de date sous la forme `mardi 14/07`. */
	EEEE_dd_MM_slash = "EEEE dd/MM",
	/** Formattage de date sous la forme `mardi 14 juillet 2020`. */
	EEEE_dd_MMMM_yyyy = "EEEE dd MMMM yyyy",
	/** Formattage de date sous la forme `mar. 14 juillet 2020 14:20`. */
	EEE_dd_MMMM_yyyyy_HH_mm = "EEE dd MMMM yyyy HH:mm",
	/** Formattage de date sous la forme `mar. 14 juillet 14:20`. */
	EEE_dd_MMMM_HH_mm = "EEE dd MMMM HH:mm",
	/** Formattage d'horaire sous la forme `19:45`. */
	HH_mm = "HH:mm",
	/** Formattage de date sous la forme `20200714194559999`. */
	yyyyMMddHHmmssSSS = "yyyyMMddHHmmssSSS",
	/** Formattage de date sous la forme `20200714194559`. */
	yyyyMMddHHmmss = "yyyyMMddHHmmss",
	/** Formattage de date sous la forme `20210527`. */
	yyyyMMdd = "yyyyMMdd",
	/** Formattage de date sous la forme `2021/05/27`. */
	yyyyMMdd_slash = "yyyy/MM/dd",
	/** Format de date `12/22` (mois/année). */
	MM_yy_slash = "MM/yy",

	/** Formattage d'une date au format ISO 8601 avec tirets : `2020-07-14`. */
	isoFormat_hyphen = "yyyy-MM-dd",
	/** Formattage d'une date au format ISO 8601 avec tirets : `2020-07-14 19:45`. */
	isoFormat_hyphenWithHours = "yyyy-MM-dd HH:mm",
	/** Formattage d'une date au format ISO 8601 avec slashs : `2020/07/14`. */
	isoFormat_slash = "yyyy/MM/dd",
	/** Formattage d'une date au format ISO 8601 avec slashs : `2020/07/14 19:45`. */
	isoFormat_slashWithHours = "yyyy/MM/dd HH:mm"
}