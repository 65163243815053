import { EMajorationType } from './EMajorationType';
import { IMajoration } from './IMajoration';

export class Majoration implements IMajoration {

	//#region PROPERTIES

	/** Identifiant de la majoration */
	public id: string;
	/** Type de majoration. */
	public type: EMajorationType;
	/** Prix de la majoration. */
	public price: number;
	/** Description de la majoration. */
	public description: string;
	/** @implements */
	public addedByUser?: boolean;
	/** @implements */
	public desmosId?: string;
	/** @implements */
	public externalId?: string;
	/** @implements */
	public disabled?: boolean;
	/** Indique si la majoration est protégée. */
	public get isProtected(): boolean {
		return this.addedByUser || this.type === EMajorationType.Mie || this.disabled;
	}

	//#endregion

	//#region METHODS

	constructor(psId: string, poType: EMajorationType, poPrice: number, psDescription: string = "", pbAddedByUser?: boolean, pbDisabled?: boolean) {
		this.id = psId;
		this.type = poType;
		this.price = poPrice;
		this.description = psDescription;
		this.addedByUser = pbAddedByUser;
		this.disabled = pbDisabled;
	}

	/** Crée une instance de majoration à partir des données d'un objet similaire récupérées en base de données.
	 * @param poData Données de l'objet récupérées en bases de données dont il faut créer une instance de `Majoration`.
	 */
	public static getInstanceFromData(poData: IMajoration): Majoration {
		return new Majoration(poData.id, poData.type, poData.price, poData.description, poData.addedByUser, poData.disabled);
	}

	//#endregion
}