import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserData } from "@osapp/model";
import { ISite } from "@osapp/modules/sites/models/isite";
import { SitesService } from "@osapp/modules/sites/services/sites.service";
import { SecurityService } from "@osapp/services";
import { AuthenticatorService } from "../../../features/shared/services/authenticator.service";

@Component({
	selector: "di-selection-cabinet",
	templateUrl: "./selection-cabinet.page.html",
	styleUrls: ["./selection-cabinet.page.scss"],
})
export class SelectionCabinetPage implements OnInit {
	cabinetlistId: string[];
	cabinets: ISite[];

	constructor(
		private location: Location,
		private router: Router,
		private route: ActivatedRoute,
		private svcSites: SitesService,
		private svcAuthenticator: AuthenticatorService,
		private svcSecurity: SecurityService,


	) { }

	ngOnInit(): void {
		this.route.queryParams.subscribe(params => {
			if (params['cabinetlistId']) {
				this.cabinetlistId = params['cabinetlistId'];
				this.svcSites.getSitesFromIds(this.cabinetlistId).subscribe((sites: ISite[]) => {
					this.cabinets = sites.sort((a, b) => a.name.localeCompare(b.name));
				});
			}
		});
	}

	public goBack() {
			this.svcAuthenticator.setAuthenticationStatus(false);
			this.svcSecurity.disconnect().subscribe();
	}


	public getSousTitre(cabinet: ISite) {
		return cabinet.city && cabinet.street ? `${cabinet.city} - ${cabinet.street}` : cabinet.city || cabinet.street || "";
	}

	public navigateToHome(cabinet: ISite) {
		UserData.currentSite = cabinet;
		this.svcAuthenticator.setAuthenticationStatus(true);
		// UserData.currentSite.isDefaultSite = cabinet._id === lsDefaultSiteId;
		this.router.navigate(["/home"]);
	}
}
