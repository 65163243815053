<div class="di-carte-ordonnance">
	<div class="header">
		<ng-container *ngIf="patient">
			<lua-objet-patient size="ESize.S" [firstName]="patient.firstName | firstUpperCase" [lastName]="patient.lastName"
				[info]="getAdressePatient(patient)"></lua-objet-patient>
			<div class="tag-container">
				<lua-tag *ngIf="inCabinet" label="Cabinet"></lua-tag>
				<lua-tag *ngIf="!!getSoinNumber()" [label]="getSoinNumber()" mode="outlined" color="Raspberry"></lua-tag>
				<lua-tag *ngIf="isNew" label="Nouveau" color="Info"></lua-tag>
			</div>
		</ng-container>
	</div>
	<lua-divider-horizontal></lua-divider-horizontal>
	<strong *ngIf="ordonnance.description" class="titre-carte">{{ ordonnance.description }}</strong>
	<lua-message-aucune-donnee *ngIf="actes.length === 0" mainText="Aucun acte"
		subText="Ajoutez des actes pour lister les soins à réaliser" iconName="" buttonLabel=""></lua-message-aucune-donnee>
	<ng-container *ngIf="actes.length > 0">
		<ng-container *ngFor="let acte of actes">
			<di-acte [ellipsis]="true" [acte]="acte" noCheckForce="true" [priseEnCharge]="true"></di-acte>
		</ng-container>
	</ng-container>
	<div class="progress">
		<lua-loader-horizontal *ngIf="status === 'active' && actes.length > 0" [progressBar]="true"
			[color]="getProgressColor()" [initialProgress]="currentSeance"
			[targetProgress]="totalSeance"></lua-loader-horizontal>
		<div *ngIf="status === 'active' && actes.length === 0" class="placeholder"></div>
		<div *ngIf="status === 'finished'" class="finished">
			<strong *ngIf="totalSeance > 0">{{ totalSeance }} {{ totalSeance === 1 ? 'soin terminé' : 'soins terminés' }}</strong>
			<strong *ngIf="finishDate">le {{ finishDate }}</strong>
		</div>
		<div #menuOrdonnance>
			<lua-bouton-icone (click)="openMenu()" iconName="more_vert"></lua-bouton-icone>
		</div>
	</div>
	<div *ngIf="modeFacturation" class="etat-facturation">
		<lua-divider-horizontal></lua-divider-horizontal>
		<di-etat-facturation [billedStatus]="etatFacturation" [totalSeance]="aFacturer"
			(click)="handleFacturerClick($event)"></di-etat-facturation>
	</div>
</div>