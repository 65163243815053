import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObjectHelper, StoreHelper } from '@osapp/helpers';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { ETimetablePattern, UserData } from '@osapp/model';
import { ActivePageManager } from '@osapp/model/navigation/ActivePageManager';
import { LogAction } from '@osapp/modules/logger/decorators/log-action.decorator';
import { ILogSource } from '@osapp/modules/logger/models/ILogSource';
import { LogActionHandler } from '@osapp/modules/logger/models/log-action-handler';
import { LoggerService } from '@osapp/modules/logger/services/logger.service';
import { PerformanceManager } from '@osapp/modules/performance/PerformanceManager';
import { Store } from '@osapp/services/store.service';
import { WorkspaceService } from '@osapp/services/workspace.service';
import { from, Observable, of } from 'rxjs';
import { concatMap, toArray } from 'rxjs/operators';
import { C_PLANNING_DB_ROLE, C_PREFIX_PLANNING_RH } from '../../../app/app.constants';
import { EIdlLogActionId } from '../../logger/models/EIdlLogActionId';
import { IAffectation } from '../model/IAffectation';
import { IDaySectorAffectationsMap } from '../model/iday-sector-affectations-map ';
import { IPlanificationData } from '../model/IPlanificationData';
import { IPlanningRH } from '../model/IPlanningRH';
import { ISlot } from '../model/ISlot';
import { ISlotAffectation } from '../model/islot-affectation';
import { PlanningApiClient } from './planningApiClient';

@Injectable()
export class PlanningRHService implements ILogSource {

	//#region FIELDS

	private moApiClient: PlanningApiClient;
	private static readonly C_LOG_ID = "IDL.PLANRH.S::";

	//#endregion

	//#region PROPERTIES

	/** @implements */
	public readonly logSourceId = PlanningRHService.C_LOG_ID;
	/** @implements */
	public readonly logActionHandler = new LogActionHandler(this);

	//#endregion

	//#region METHODS

	constructor(
		private ioHttpClient: HttpClient,
		private isvcStore: Store,
		private isvcWorkspace: WorkspaceService,
		/** @implements */
		public readonly isvcLogger: LoggerService,
	) {
		this.moApiClient = new PlanningApiClient(this.ioHttpClient);
	}

	/** Retourne le planning de la date passé en paramètre.
	 * @param poDate une date.
	 * @param pbLive
	 */
	public getPlanning(poDate: string | Date): Observable<IPlanningRH>;
	public getPlanning(poDate: string | Date, poActivePageManager: ActivePageManager): Observable<IPlanningRH>;
	public getPlanning(poDate: string | Date, poActivePageManager?: ActivePageManager): Observable<IPlanningRH> {
		return this.isvcStore.getOne(
			{
				role: C_PLANNING_DB_ROLE,
				remoteChanges: !!poActivePageManager,
				activePageManager: poActivePageManager,
				viewParams: {
					include_docs: true,
					key: this.getPlanningIdFromDate(poDate)
				}
			},
			false
		);
	}

	public getPlanningIdFromDate(poDate: string | Date): string {
		const ldResetedDate: Date = this.getPlanningStartDate(poDate);
		return `${C_PREFIX_PLANNING_RH}${UserData.currentSite._id}_${DateHelper.transform(ldResetedDate, ETimetablePattern.yyyyMMdd)}`;
	}

	private getPlanningStartDate(poDate: string | Date): Date {
		return DateHelper.resetWeek(poDate);
	}

	/** Retourne les plannings entre 2 dates.
	 * @param pdFrom
	 * @param pdTo
	 * @returns
	 */
	public getPlannings(pdFrom: Date, pdTo: Date): Observable<IPlanningRH[]>;
	/** Retourne les plannings entre 2 dates.
	 * @param pdFrom
	 * @param pdTo
	 * @param pbLive
	 * @param poActivePageManager
	 * @returns
	 */
	public getPlannings(pdFrom: Date, pdTo: Date, pbLive: boolean, poActivePageManager?: ActivePageManager): Observable<IPlanningRH[]>;
	public getPlannings(pdFrom: Date, pdTo: Date, pbLive = false, poActivePageManager?: ActivePageManager): Observable<IPlanningRH[]> {
		if (!DateHelper.isDate(pdFrom) && !DateHelper.isDate(pdTo))
			return of([]);

		return this.isvcStore.get(
			{
				role: C_PLANNING_DB_ROLE,
				remoteChanges: pbLive && !!poActivePageManager,
				live: pbLive,
				activePageManager: poActivePageManager,
				viewParams: {
					include_docs: true,
					startkey: this.getPlanningIdFromDate(pdFrom),
					endkey: this.getPlanningIdFromDate(pdTo)
				}
			}
		);
	}

	/** Crée et retourne un planning à partir de la date passée en paramètre.
	 * @param poDate une date.
	 */
	@LogAction<Parameters<PlanningRHService["createPlanning"]>, ReturnType<PlanningRHService["createPlanning"]>>({
		actionId: EIdlLogActionId.planningCreate,
		successMessage: "Création du planning.",
		errorMessage: "Echec de la création du planning.",
		dataBuilder: (_, poPlanningRH: IPlanningRH) => ({ userId: UserData.current?._id, planningId: poPlanningRH._id })
	})
	public createPlanning(poDate: string | Date): Observable<IPlanningRH> {
		return this.moApiClient.createPlanning(ArrayHelper.getFirstElement(this.isvcWorkspace.getUserWorkspaceIds()), new Date(poDate).toISOString());
	}

	/** Met à jour le planning.
	 * @param poPlanningRH le planning à mettre à jour.
	 */
	public updatePlanning(poPlanningRH: IPlanningRH): Observable<IPlanningRH> {
		return this.moApiClient.updatePlanning(ArrayHelper.getFirstElement(this.isvcWorkspace.getUserWorkspaceIds()), StoreHelper.getCleanedDocument(poPlanningRH));
	}

	/** Met à jour les plannings.
	 * @param paPlanningsRH les plannings à mettre à jour.
	 */
	public updatePlannings(paPlanningsRH: IPlanningRH[]): Observable<IPlanningRH[]> {
		return from(paPlanningsRH)
			.pipe(
				concatMap((poPlanningRH: IPlanningRH) => this.moApiClient.updatePlanning(ArrayHelper.getFirstElement(this.isvcWorkspace.getUserWorkspaceIds()), StoreHelper.getCleanedDocument(poPlanningRH))),
				toArray()
			);
	}

	/** Supprime le planning et le retourne.
	 * @param poPlanningRH le planning à supprimer.
	 */
	@LogAction<Parameters<PlanningRHService["deletePlanning"]>, ReturnType<PlanningRHService["deletePlanning"]>>({
		actionId: EIdlLogActionId.planningDelete,
		successMessage: "Suppression du planning.",
		errorMessage: "Echec de la suppression du planning.",
		dataBuilder: (_, poPlanningRH: IPlanningRH) => ({ userId: UserData.current?._id, planningId: poPlanningRH._id })
	})
	public deletePlanning(poPlanningRH: IPlanningRH): Observable<IPlanningRH> {
		return this.moApiClient.deletePlanning(ArrayHelper.getFirstElement(this.isvcWorkspace.getUserWorkspaceIds()), StoreHelper.getCleanedDocument(poPlanningRH));
	}

	/** Indique si une date rentre dans un slot.
	 * @param pdDate
	 * @param poSlot
	 * @returns
	 */
	public static isInSlot(pdDate: Date, poSlot: ISlot): boolean {
		const ldStart: Date = DateHelper.resetDay(pdDate);
		let ldEnd: Date = poSlot.startHour > poSlot.endHour ? DateHelper.addDays(new Date(ldStart), 1) : new Date(ldStart);

		ldStart.setHours(poSlot.startHour);
		ldEnd.setHours(poSlot.endHour);
		ldEnd = DateHelper.addMilliseconds(ldEnd, -1); // On exclu la borne supérieure.

		return DateHelper.isBetweenTwoDates(pdDate, ldStart, ldEnd);
	}

	/** Planifie les affectations du contact, retourne le planning courant si besoin pour afficher les modifications.
	 * @param poPlanificationData les données nécessaires à l'affectation d'un contact à une plage horaire pour un groupe donné..
	 * @param poCurrentPlanning le planning courant.
	 */
	public async planContactAffectations(poPlanificationData: IPlanificationData, poCurrentPlanning?: IPlanningRH): Promise<IPlanningRH> {
		const lnDiffDays: number = DateHelper.diffDays(poPlanificationData.endDate, poPlanificationData.startDate);
		const laPlanningsToUpdate: IPlanningRH[] = [];
		const laDates: Date[] = DateHelper.getDatesFrom(poPlanificationData.startDate, lnDiffDays);
		let loPlannigToEdit: IPlanningRH = poCurrentPlanning;

		for (const pdDate of laDates) {
			if (ObjectHelper.isNullOrEmpty(loPlannigToEdit) || !DateHelper.isBetweenTwoDates(pdDate, loPlannigToEdit.startDate, loPlannigToEdit.endDate)) {
				loPlannigToEdit = await this.getPlanning(pdDate.toISOString()).toPromise();
				if (ObjectHelper.isNullOrEmpty(loPlannigToEdit))
					loPlannigToEdit = await this.createPlanning(pdDate).toPromise();
			}

			if (loPlannigToEdit) {
				const loSlot: ISlot = loPlannigToEdit.slots.find((poSlot: ISlot) => poSlot.slotId === poPlanificationData.slot.slotId);
				if (!ObjectHelper.isNullOrEmpty(loSlot)) {
					const loAffectation: IAffectation = loPlannigToEdit.affectations.find((poAffectation: IAffectation) => poAffectation.slotId === poPlanificationData.slot.slotId && poAffectation.date === pdDate.toISOString() && poAffectation.groupId === poPlanificationData.groupId);
					if (!ObjectHelper.isNullOrEmpty(loAffectation)) {
						if (!loAffectation.contactIds.includes(poPlanificationData.contactId))
							loAffectation.contactIds.push(poPlanificationData.contactId);
					}
					else {
						loPlannigToEdit.affectations.push({
							slotId: loSlot.slotId,
							groupId: poPlanificationData.groupId,
							date: pdDate.toISOString(),
							contactIds: [poPlanificationData.contactId]
						});
					}
				}
				else {
					loPlannigToEdit.slots.push(poPlanificationData.slot);
					loPlannigToEdit.affectations.push({
						slotId: poPlanificationData.slot.slotId,
						groupId: poPlanificationData.groupId,
						date: pdDate.toISOString(),
						contactIds: [poPlanificationData.contactId]
					});
				}

				ArrayHelper.pushIfNotPresent(laPlanningsToUpdate, loPlannigToEdit);
			}
		}

		for (const poPlanningToUpdate of laPlanningsToUpdate) {
			await this.updatePlanning(poPlanningToUpdate).toPromise();
		}

		return laPlanningsToUpdate.find((poPlanningRH: IPlanningRH) => poPlanningRH._id === poCurrentPlanning._id);
	}

	/** Duplique le planning entre les deux dates passées en paramètre.
	 * @param poPlanning Planning à dupliquer.
	 * @param pdFromDate Date de début.
	 * @param pdToDate Date de fin.
	 */
	public async duplicatePlanning(poPlanning: IPlanningRH, pdFromDate: Date, pdToDate: Date): Promise<void> {
		const lnDiffDays: number = DateHelper.diffDays(pdToDate, pdFromDate);
		const laPlanningsToUpdate: IPlanningRH[] = [];
		let loPlannigToEdit: IPlanningRH;
		const laDates: Date[] = DateHelper.getDatesFrom(pdFromDate, lnDiffDays);

		for (const pdDate of laDates) {
			if (ObjectHelper.isNullOrEmpty(loPlannigToEdit) ||
				!DateHelper.isBetweenTwoDates(pdDate, loPlannigToEdit.startDate, loPlannigToEdit.endDate)) {
				loPlannigToEdit = await this.getPlanning(pdDate).toPromise();

				if (ObjectHelper.isNullOrEmpty(loPlannigToEdit))
					loPlannigToEdit = await this.createPlanning(pdDate).toPromise();
				else
					loPlannigToEdit.affectations = [];

				laPlanningsToUpdate.push(loPlannigToEdit);
			}

			poPlanning.affectations.forEach((poAffectation: IAffectation) => {
				if (DateHelper.diffWeekDays(poAffectation.date, pdDate) === 0 && ArrayHelper.hasElements(poAffectation.contactIds))
					loPlannigToEdit.affectations.push({ ...poAffectation, date: pdDate });
			});

			loPlannigToEdit.slots = poPlanning.slots;
		}

		for (const poPlanningToUpdate of laPlanningsToUpdate) {
			await this.updatePlanning(poPlanningToUpdate).toPromise();
		}
	}

	/** Récupère les intervenants plannifiés sur un créneau horaire en fonction d'une date.
	 * @param pdDate
	 * @param psPatientSectorId
	 * @param paPlannings
	 * @returns
	 */
	public static getIntervenantIdsFromPlanning(pdDate: Date, psPatientSectorId?: string, paPlannings?: IPlanningRH[]): string[] {
		const loPerformanceManager = new PerformanceManager;
		loPerformanceManager.markStart();
		const loPlanning: IPlanningRH = this.getMatchingPlanning(pdDate, paPlannings);

		if (!loPlanning)
			return [];

		const loAffectation: IAffectation = loPlanning.affectations.find((poAffectation: IAffectation) => {
			if (psPatientSectorId === poAffectation.groupId &&
				DateHelper.diffDays(poAffectation.date, pdDate) === 0 &&
				loPlanning.slots.some((poSlot: ISlot) => poSlot.slotId === poAffectation.slotId && !poSlot.disabledSectorIds?.includes(psPatientSectorId) && this.isInSlot(pdDate, poSlot))) {


				return ArrayHelper.hasElements(poAffectation.contactIds);
			}

			return false;
		});

		const laDefaultIntervenants: string[] = [];

		if (!StringHelper.isBlank(psPatientSectorId))
			laDefaultIntervenants.push(psPatientSectorId);

		const laContactIds: string[] = (loAffectation?.contactIds ?? laDefaultIntervenants).filter(
			(psIntervenantId: string) => !StringHelper.isBlank(psIntervenantId)
		);

		console.debug(`${this.C_LOG_ID} Récupération des contacts pour une date en ${loPerformanceManager.markEnd().measure()}ms.`);

		return laContactIds;
	}

	public static getIntervenantByAffectationsGroupedBySectorAndDays(poAffectationsBySectorAndDays: IDaySectorAffectationsMap, pdDate: Date, psPatientSectorId?: string): string[] {
		const loPerformanceManager = new PerformanceManager;
		loPerformanceManager.markStart();
		const laDefaultIntervenants: string[] = [];
		let loAffectation: IAffectation;
		if (!StringHelper.isBlank(psPatientSectorId)) {
			loAffectation = poAffectationsBySectorAndDays.get(psPatientSectorId)?.get(DateHelper.transform(pdDate, ETimetablePattern.yyyyMMdd))?.find(
				(poAffectationWithDatas: ISlotAffectation) =>
					ArrayHelper.hasElements(poAffectationWithDatas.affectation.contactIds) &&
					!poAffectationWithDatas.slot.disabledSectorIds?.includes(psPatientSectorId) &&
					PlanningRHService.isInSlot(pdDate, poAffectationWithDatas.slot)
			)?.affectation;

			laDefaultIntervenants.push(psPatientSectorId);
		}

		const laContactIds: string[] = (loAffectation?.contactIds ?? laDefaultIntervenants).filter(
			(psIntervenantId: string) => !StringHelper.isBlank(psIntervenantId)
		);

		console.debug(`${PlanningRHService.C_LOG_ID} Récupération des contacts pour une date depuis les affectations groupées en ${loPerformanceManager.markEnd().measure()}ms.`);

		return laContactIds;
	}

	/** Groupe les affectations des plannings passés en paramètres par secteurs et par jours (format yyyyMMdd).
	 * @param paPlannings
	 */
	public static groupPlanningsBySectorAndDays(paPlannings: IPlanningRH[]): IDaySectorAffectationsMap {
		const loPerformanceManager = new PerformanceManager;
		loPerformanceManager.markStart();
		const loMap = new Map<string, Map<string, ISlotAffectation[]>>();

		paPlannings.forEach((poPlanning: IPlanningRH) => {
			const loSlotsByIds: Map<string, ISlot> = ArrayHelper.groupByUnique(poPlanning.slots, (poSlot: ISlot) => poSlot.slotId);
			poPlanning.affectations.forEach((poAffectation: IAffectation) => {
				const loAffectationsByDate: Map<string, ISlotAffectation[]> = loMap.get(poAffectation.groupId) ?? new Map<string, ISlotAffectation[]>();

				const lsDate: string = DateHelper.transform(poAffectation.date, ETimetablePattern.yyyyMMdd);
				const laAffectations: ISlotAffectation[] = loAffectationsByDate.get(lsDate) ?? [];
				laAffectations.push({ affectation: poAffectation, slot: loSlotsByIds.get(poAffectation.slotId) });
				loAffectationsByDate.set(lsDate, laAffectations);

				loMap.set(poAffectation.groupId, loAffectationsByDate);
			});
		});

		console.debug(`${PlanningRHService.C_LOG_ID} Groupement des affectations en ${loPerformanceManager.markEnd().measure()}ms.`);

		return loMap;
	}

	/** Récupère le planning en fonction d'un date.
	 * @param pdDate
	 * @param paPlannings
	 * @returns
	 */
	public static getMatchingPlanning(pdDate: Date, paPlannings?: IPlanningRH[]): IPlanningRH {
		return paPlannings?.find((poPlanning: IPlanningRH) =>
			DateHelper.isBetweenTwoDays(pdDate, poPlanning.startDate, poPlanning.endDate)
		);
	}

	public areSlotsEqual(poSlotA: ISlot, poSlotB: ISlot): boolean {
		return poSlotA.slotId === poSlotB.slotId &&
			poSlotA.label === poSlotB.label &&
			poSlotA.startHour === poSlotB.startHour &&
			poSlotA.endHour === poSlotB.endHour;
	}

	public areAffectationsEqual(poAffectationA: IAffectation, poAffectationB: IAffectation): boolean {
		return poAffectationA.slotId === poAffectationB.slotId &&
			poAffectationA.groupId === poAffectationB.groupId &&
			DateHelper.areDayEqual(poAffectationA.date, poAffectationB.date) &&
			ArrayHelper.areArraysEqual(poAffectationA.contactIds, poAffectationB.contactIds);
	}
	//#endregion
}
