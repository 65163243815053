import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatChipListChange } from '@angular/material/chips';
import { PickerController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { NumberHelper } from '@osapp/helpers/numberHelper';
import { EDateTimePickerMode } from '@osapp/model/date/EDateTimePickerMode';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { IDateTimePickerParams } from '@osapp/model/date/IDateTimePickerParams';
import { IUiResponse } from '@osapp/model/uiMessage/IUiResponse';
import { IHoursMinutes } from '@osapp/modules/date/model/ihours-minutes';
import { DayRepetition } from '@osapp/modules/event-markers/models/day-repetition';
import { HoursMinutesRepetition } from '@osapp/modules/event-markers/models/hours-minutes-repetition';
import { IDayRepetition } from '@osapp/modules/event-markers/models/iday-repetition';
import { RangeRepetition } from '@osapp/modules/event-markers/models/range-repetition';
import { Recurrence } from '@osapp/modules/event-markers/models/recurrence';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { SelectorComponent } from '@osapp/modules/selector/selector/selector.component';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '@osapp/services/interfaces/ShowMessageParamsToast';
import { PlatformService } from '@osapp/services/platform.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { Observable, from } from 'rxjs';
import { defaultIfEmpty, filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { Acte } from '../../../model/Acte';
import { EDuree } from '../../../model/EDuree';
import { EModalActeType } from '../../../model/EModalActeType';
import { EPlace } from '../../../model/EPlace';
import { ERepetition } from '../../../model/ERepetition';
import { Traitement } from '../../../model/Traitement';
import { ActesService } from '../actes.service';
import { EWeekRepetitionType } from '../model/EWeekRepetitionType';
import { PlaceConstraint } from '../model/place-constraint';
import { Ordonnance } from '../../ordonnances/models/ordonnance';

@Component({
	selector: "idl-acte-edit",
	templateUrl: './acte-edit.component.html',
	styleUrls: ['./acte-edit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActeEditComponent extends ComponentBase implements OnInit {

	//#region FIELDS

	private static readonly C_NUMBER_INPUT_NAME = "number";
	private static readonly C_LOG_ID = "ACTE-EDIT.C::";

	@ViewChild("rangeSelector") private moRangeSelectorComponent: SelectorComponent;

	//#endregion

	//#region PROPERTIES

	@Input() public acte: Acte;
	@Output() private readonly acteChange = new EventEmitter<Acte>();
	@Input() public traitement: Traitement;
	@Input() public ordonnance: Ordonnance;
	@Input() public modalType: EModalActeType;
	@Input() public hasValidatedOrCanceledSeancesByActeGuid: Map<string, boolean>;
	/** Représente l'enum avec tous les type de modal (pour le select du DOM). */
	public readonly modalActeType = EModalActeType;
	/** Représente l'enum avec tous les types de durée (pour le select du DOM). */
	public readonly dureeEnum = EDuree;
	/** Représente l'enum avec tous les types de répétition (pour le select du DOM). */
	public readonly repetitionEnum = ERepetition;
	public readonly C_DAY_DATE_FORMAT: string = ETimetablePattern.dd_MM_yyyy_slash;
	/** Paramètres pour le composant de sélection de contrainte horaire de l'acte. */
	public readonly dateTimeParams: IDateTimePickerParams = {
		displayFormat: ETimetablePattern.HH_mm,
		hideIcon: true,
		iconSlot: "end",
		pickerMode: EDateTimePickerMode.time,
		placeholder: "-- h --"
	};

	public readonly fixedDateParams: IDateTimePickerParams = {
		displayFormat: ETimetablePattern.dd_MM_yyyy_slash,
		pickerMode: EDateTimePickerMode.date
	};
	/** Paramètres pour le composant de sélection de l'heure de début de l'acte. */
	public readonly startDateParams: IDateTimePickerParams = {
		displayFormat: ETimetablePattern.dd_MM_yyyy_slash,
		iconSlot: "end",
		pickerMode: EDateTimePickerMode.date,
		label: "le"
	};

	public timeRecurrences: HoursMinutesRepetition[] = [];

	public rangeRecurrences: string[] = [];

	public readonly placeOptions: ReadonlyArray<ISelectOption<string>> = [
		{ label: "Domicile", value: "home", icon: "home" },
		{ label: "Cabinet", value: "center", icon: "center" }
	];
	public readonly rangeOptions: ReadonlyArray<ISelectOption<string> & { fullLabel: string }> = [
		{ label: "à jeun", fullLabel: "à jeun", value: "06h00-10h00" },
		{ label: "matin", fullLabel: "matin", value: "10h00-12h00" },
		{ label: "midi", fullLabel: "midi", value: "12h00-14h00" },
		{ label: "pm", fullLabel: "après midi", value: "14h00-18h00" },
		{ label: "soir", fullLabel: "soir", value: "18h00-20h00" },
		{ label: "coucher", fullLabel: "au coucher", value: "20h00-23h00" },
		{ label: "nuit", fullLabel: "nuit", value: "23h00-06h00" }
	];
	public readonly dayOptions: ReadonlyArray<ISelectOption<number>> = [
		{ label: "lun", value: 1 },
		{ label: "mar", value: 2 },
		{ label: "mer", value: 3 },
		{ label: "jeu", value: 4 },
		{ label: "ven", value: 5 },
		{ label: "sam", value: 6 },
		{ label: "dim", value: 0 }
	];
	public readonly weekRepetitionType = EWeekRepetitionType;

	public get recurrence(): Recurrence {
		if (!ArrayHelper.hasElements(this.acte.recurrences))
			this.acte.recurrences.push(new Recurrence);

		return ArrayHelper.getFirstElement(this.acte.recurrences);
	}

	//#endregion

	//#region METHODS

	constructor(
		private isvcUiMessage: UiMessageService,
		private ioDecimalPipe: DecimalPipe,
		private isvcPlatform: PlatformService,
		private ioPickerCtrl: PickerController,
		private isvcActes: ActesService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);
	}

	public ngOnInit(): void {
		this.init();

		if (this.modalType === EModalActeType.Creation) {
			this.isvcActes.applyPriceToActe(this.acte).subscribe();// Récupération du prix de l'acte en cours.
			this.checkOrdoIsAld();
		}
	}

	private init(): void {
		this.rangeRecurrences = this.recurrence.dayRepetitions.filter((poRepetition: DayRepetition) => poRepetition instanceof RangeRepetition)
			.map((poRepetition: RangeRepetition) => this.rangeToString(poRepetition));

		this.updateTimeConstraints();
	}

	/**
	 * Méthode qui vérifie (à la création de l'acte) si l'ordonnance est en ALD
	 * si oui on applique à tous les actes l'option "ALD Exonérante" par défaut
	 */
	private checkOrdoIsAld(): void {
		if (this.ordonnance?.isAld) {
			this.acte.isAldExonerante = this.ordonnance.isAld;
		}
	}

	private emitActe(): void {
		this.acteChange.emit(this.acte);
	}

	/** On applique la nouvelle valeur de répétition. */
	public onRepetitionValueChanged(poRepetitionValue: number | number[]): void {
		this.recurrence.repetitionValue = poRepetitionValue;
	}

	/** Calcule le prix de l'acte en fonction de la lettre-clé. */
	public calculatePrice(): void {
		this.acte.calculatePrice();
		this.emitActe();
		this.detectChanges();
	}

	/** Lorsque le type de répétition change on vérifie s'il y a le mot "sur" ou non afin de rendre visible la partie coefficient en fonction. */
	public onRepetitionChanged(peRepetition: ERepetition): void {
		if (this.hasEndDate(this.acte))
			return;

		if (peRepetition !== this.recurrence.repetitionType) {
			this.recurrence.repetitionType = peRepetition;
			this.recurrence.repetitionValue = undefined;

			if (peRepetition === ERepetition.jours) {
				let dateDebut: Date = this.acte.startDate ?? this.traitement.beginDate
				let dateDebutDay: number = dateDebut.getDay();
				this.recurrence.repetitionValue = [];
				const joursRestants = typeof this.recurrence.durationValue === "number" ? this.recurrence.durationValue : 1;
				for (let i = 0; i < joursRestants; i++) {
					const prochainJour = (dateDebutDay + i) % 7;
					this.recurrence.repetitionValue.push(prochainJour);
				}
			}
			if (this.recurrence.repetitionType !== ERepetition.jours)
				this.onWeekRepetitionChanged(EWeekRepetitionType.everyWeek);
		}

		if (peRepetition === ERepetition.tsLesXJours || peRepetition === ERepetition.tsLesXMois || peRepetition === ERepetition.xFoisParMois) {
			this.pickNumber(peRepetition)
				.pipe(
					tap((pnNumber: number) => {
						this.recurrence.repetitionValue = pnNumber;
						this.detectChanges();
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}

	}

	public onWeekRepetitionChanged(peWeekRepetition: EWeekRepetitionType): void {
		if (this.hasEndDate(this.acte))
			return;

		this.acte.weekRepetition.type = peWeekRepetition;

		if (this.acte.weekRepetition.type === EWeekRepetitionType.everyNWeek) {
			this.pickNumber()
				.pipe(
					tap((pnNumber: number) => {
						this.acte.weekRepetition.value = pnNumber;
						this.detectChanges();
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
		else
			this.detectChanges();
	}

	private updateTimeConstraints(): void {
		this.timeRecurrences = this.recurrence.dayRepetitions?.filter((poRepetition: DayRepetition) =>
			poRepetition instanceof HoursMinutesRepetition
		) as HoursMinutesRepetition[];
	}

	private rangeToString(poRangeRepetition: RangeRepetition): string {
		return !poRangeRepetition ? "" : `${this.timeToString(poRangeRepetition.from)}-${this.timeToString(poRangeRepetition.to)}`;
	}

	public timeToString(poTimeRepetition: IHoursMinutes): string {
		return !poTimeRepetition ? "" : `${this.ioDecimalPipe.transform(poTimeRepetition.hours, "2.0")}h${this.ioDecimalPipe.transform(poTimeRepetition.minutes, "2.0")}`;
	}

	private addRepetition(poRepetition: IDayRepetition): void {
		this.recurrence.dayRepetitions.push(poRepetition);
		this.emitActe();
	}

	private getDateForAddingRepetition(poRecurrence: HoursMinutesRepetition): Date {
		const ldLastConstraintDate: Date = new Date();

		ldLastConstraintDate.setHours(poRecurrence.hours);
		ldLastConstraintDate.setMinutes(poRecurrence.minutes);

		return ldLastConstraintDate;
	}

	/** Crée et retourne une contrainte d'acte.
	 * @param pdDate Contrainte d'horaire.
	 */
	private createTimeConstraint(pdDate?: Date): HoursMinutesRepetition {
		return pdDate ? this.createTimeConstraintFromDate(pdDate) : undefined;
	}

	private createTimeConstraintFromDate(pdDate: Date): HoursMinutesRepetition {
		return new HoursMinutesRepetition({ hours: pdDate.getHours(), minutes: pdDate.getMinutes() });
	}

	/** Modifie la contrainte d'un acte lors d'un changement d'horaire.
	 * @param pdEvent Événement reçu après un changement d'horaire.
	 */
	public onTimeConstraintChanged(pdEvent: Date): void {
		if (this.recurrence.dayRepetitions.every((poItem: DayRepetition) =>
			poItem instanceof HoursMinutesRepetition ? DateHelper.diffMinutes(this.getDateForAddingRepetition(poItem), pdEvent) !== 0 : true)
		) {
			if (!this.isSelectedRangesContainsTimeConstraint(this.createTimeConstraintFromDate(pdEvent))) {
				// On crée une nouvelle contrainte à partir du nouvel horaire et du possible intervenant associé.
				this.addRepetition(this.createTimeConstraint(pdEvent));
			}
			else
				this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: "L'heure choisie est déjà contenue dans un intervalle sélectionné.", duration: 3000 }));
		}
		else {
			this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: "Une contrainte horaire similaire existe déjà." }));
			this.detectChanges();
		}

		this.updateTimeConstraints();
		this.emitActe();
	}

	public removeTimeConstraint(poActeConstraint: HoursMinutesRepetition): void {
		ArrayHelper.removeElement(this.recurrence.dayRepetitions, poActeConstraint);

		this.updateTimeConstraints();
		this.emitActe();
		this.detectChanges();
	}

	/** Indique si la nouvelle contrainte de date est déjà contenue dans un intervalle déjà selectionné.
	 * @param poTimeConstraint
	 */
	private isSelectedRangesContainsTimeConstraint(poTimeConstraint: IHoursMinutes): boolean {
		return this.recurrence.dayRepetitions
			.filter((poRepetition: DayRepetition) => poRepetition instanceof RangeRepetition)
			.some((poRepetition: RangeRepetition) => {
				const lnFrom: number = poRepetition.from.hours + (poRepetition.from.minutes / 60);
				const lnTo: number = poRepetition.to.hours + (poRepetition.to.minutes / 60);
				const lnNewTime: number = poTimeConstraint.hours + (poTimeConstraint.minutes / 60);

				return (lnTo < lnFrom && (lnFrom <= lnNewTime || lnNewTime <= lnTo)) || // Cas où l'intervalle est à cheval sur deux jours.
					(lnFrom <= lnNewTime && lnNewTime <= lnTo); // Cas où l'intervalle est contenu sur une journée.
			});
	}

	public onDureeSelected(peDuree: EDuree): void {
		if (this.hasEndDate(this.acte))
			return;

		if (peDuree !== this.recurrence.durationType) {
			this.recurrence.durationType = peDuree;
			this.recurrence.durationValue = undefined;
		}

		if (peDuree === EDuree.jour || peDuree === EDuree.mois) {
			this.pickNumber(peDuree)
				.pipe(
					tap((pnDuration: number) => {
						this.recurrence.durationValue = pnDuration;
						this.emitActe();
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
		else if (peDuree === EDuree.jusquADate) {
			this.recurrence.durationValue = [];
			this.addDateValue(this.acte.startDate ?? this.traitement.endDate);
			this.emitActe();
		}
		else if (peDuree === EDuree.dates) {
			this.recurrence.repetitionType = ERepetition.tsLesXJours;

			this.recurrence.durationValue = [];
			this.addDateValue(this.acte.startDate ?? this.traitement.beginDate);
			this.emitActe();
		}
	}

	private pickNumber(peRepetition?: ERepetition | EDuree): Observable<number> {
		let valueInput: any = 1;
		if (peRepetition === EDuree.jour || peRepetition === EDuree.mois) {
			valueInput = this.recurrence.durationValue ?? 1;
		}
		else if (peRepetition === ERepetition.tsLesXJours || peRepetition === ERepetition.tsLesXMois || peRepetition === ERepetition.xFoisParMois) {
			valueInput = this.recurrence.repetitionValue ?? 1;
		}
		if (this.isvcPlatform.isMobileApp) {
			return from(this.ioPickerCtrl.create({
				columns: [{
					name: ActeEditComponent.C_NUMBER_INPUT_NAME,
					selectedIndex: valueInput - 1,
					options: Array.from(new Array(100).keys()).map((pnIndex: number) => ({ text: `${pnIndex + 1}`, value: pnIndex + 1 }))
				}],
				buttons: [{
					text: "ANNULER",
					role: UiMessageService.C_CANCEL_ROLE,
					handler: () => {
						if ((peRepetition === EDuree.jour || peRepetition === EDuree.mois) && !this.recurrence.durationValue) {
							this.recurrence.durationType = undefined;
						}
						else if ((peRepetition === ERepetition.tsLesXJours || peRepetition === ERepetition.tsLesXMois || peRepetition === ERepetition.xFoisParMois) && !this.recurrence.repetitionValue) {
							this.recurrence.repetitionType = undefined;
						}
					}
				},
				{ text: "OK" }],
			}))
				.pipe(
					tap((poElement: HTMLIonPickerElement) => poElement.present()),
					mergeMap((poElement: HTMLIonPickerElement) => poElement.onDidDismiss()),
					map((poResponse: OverlayEventDetail<{ [prop in typeof ActeEditComponent.C_NUMBER_INPUT_NAME]: { text: string, value: number, columnIndex: number } }>) =>
						poResponse.data.number.value
					),
					filter((pnDuration: number) => NumberHelper.isValid(pnDuration)),
					defaultIfEmpty(valueInput),
				);
		}
		else {
			return this.isvcUiMessage.showAsyncMessage(
				new ShowMessageParamsPopup({
					header: "Durée",
					inputs: [{ type: "number", name: ActeEditComponent.C_NUMBER_INPUT_NAME, value: valueInput }],
					buttons: [{
						text: "ANNULER",
						role: UiMessageService.C_CANCEL_ROLE,
						handler: () => {
							if ((peRepetition === EDuree.jour || peRepetition === EDuree.mois) && !this.recurrence.durationValue) {
								this.recurrence.durationType = undefined;
							}
							else if ((peRepetition === ERepetition.tsLesXJours || peRepetition === ERepetition.tsLesXMois || peRepetition === ERepetition.xFoisParMois) && !this.recurrence.repetitionValue) {
								this.recurrence.repetitionType = undefined;
							}
						}
					},
					{ text: "OK" }],
				})
			)
				.pipe(
					map((poUiResponse: IUiResponse<any, { [prop in typeof ActeEditComponent.C_NUMBER_INPUT_NAME]: string }>) =>
						Math.abs(+poUiResponse.values.number)
					),
					filter((pnDuration: number) => NumberHelper.isValid(pnDuration)),
					defaultIfEmpty(valueInput),
					takeUntil(this.destroyed$)
				);
		}
	}

	public onStartDateChanged(pdStartDate: Date): void {
		const ldLastDate = new Date(this.acte.startDate ?? this.traitement.beginDate);
		this.acte.startDate = DateHelper.compareTwoDates(this.traitement.beginDate, pdStartDate) === 0 ? undefined : pdStartDate;

		if (this.recurrence.repetitionType === ERepetition.tsLesXJours && this.recurrence.durationValue instanceof Array) {
			ArrayHelper.removeElementByFinder(this.recurrence.durationValue, (pdDate: Date) => DateHelper.diffDays(pdDate, ldLastDate) === 0);
			this.addDateValue(pdStartDate);
		};

		this.emitActe();
	}

	public onPlaceChanged(paPlaces: EPlace[]): void {
		const leNewPlace: EPlace = ArrayHelper.getFirstElement(paPlaces);

		if (leNewPlace && leNewPlace !== this.acte.place) {
			const loConstraint = new PlaceConstraint;
			loConstraint.place = this.acte.place = leNewPlace;
			this.acte.constraints.push(loConstraint);
			this.emitActe();
		}
	}

	public onRangesChanged(paRanges: string[]): void {
		if (this.rangeRecurrences.length > paRanges.length) {
			this.recurrence.dayRepetitions.forEach((poRecurrence: DayRepetition, pnIndex: number) => {
				if (poRecurrence instanceof RangeRepetition && !paRanges.includes(this.rangeToString(poRecurrence)))
					ArrayHelper.removeElementByIndex(this.recurrence.dayRepetitions, pnIndex);
			});
		}
		else if (!ArrayHelper.areArraysEqual(paRanges, this.rangeRecurrences)) {
			const laRangeParts: string[][] = ArrayHelper.getLastElement(paRanges)?.split("-").map((psPart: string) => psPart.split("h"));

			if (laRangeParts?.length === 2) {
				const loRange = new RangeRepetition({
					from: {
						hours: +laRangeParts[0][0],
						minutes: +laRangeParts[0][1],
					},
					to: {
						hours: +laRangeParts[1][0],
						minutes: +laRangeParts[1][1],
					}
				});

				if (!this.isRangeRecurrenceContainsSelectedTimes(loRange))
					this.addRepetition(loRange);
				else {
					ArrayHelper.removeElementByFinder(paRanges, (psRangeValue: string) => psRangeValue === this.rangeToString(loRange));
					this.moRangeSelectorComponent.unselectLastValue();
					this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: "L'intervalle choisi contient une ou plusieurs heures déjà sélectionnées.", duration: 3000 }));
				}
			}
		}
		this.rangeRecurrences = [...paRanges];
	}

	/** Indique si la nouvelle intervalle contient une heure selectionnée.
	 * @param poRangeRecurrence
	 */
	private isRangeRecurrenceContainsSelectedTimes(poRangeRecurrence: RangeRepetition): boolean {
		return this.recurrence.dayRepetitions
			.filter((poRecurrence: DayRepetition) => poRecurrence instanceof HoursMinutesRepetition)
			.some((poRecurrence: HoursMinutesRepetition) => {
				const lnFrom: number = poRangeRecurrence.from.hours + (poRangeRecurrence.from.minutes / 60);
				const lnTo: number = poRangeRecurrence.to.hours + (poRangeRecurrence.to.minutes / 60);
				const lnTime: number = poRecurrence.hours + (poRecurrence.minutes / 60);

				return (lnTo < lnFrom && (lnFrom <= lnTime || lnTime <= lnTo)) || // Cas où l'intervalle est à cheval sur deux jours.
					(lnFrom <= lnTime && lnTime <= lnTo); // Cas où l'intervalle est contenu sur une journée.
			});
	}

	public removeTimeRecurrence(poRepetition: HoursMinutesRepetition): void {
		ArrayHelper.removeElement(this.recurrence.dayRepetitions, poRepetition);

		this.updateTimeConstraints();
		this.detectChanges();
	}

	public getRangeFullLabel(psRange: string): string {
		return this.rangeOptions.find((poRangeOption: ISelectOption<string>) => poRangeOption.value === psRange)?.fullLabel ?? psRange;
	}

	public onDurationTypeChange(poEvent: MatChipListChange): void {
		console.log(poEvent);
	}

	public addDateValue(pdDate: Date): void {
		if (this.recurrence.durationValue instanceof Array) {
			if (!this.recurrence.durationValue.some((pdDurationValue: Date) => DateHelper.compareTwoDates(pdDate, pdDurationValue) === 0)) {
				if (this.isSameDay(pdDate, this.acte.startDate ?? this.traitement.beginDate))
					this.recurrence.durationValue.unshift(pdDate);
				else
					this.recurrence.durationValue.push(pdDate);
			}
			else {
				console.warn("ACT.EDIT.C:: Cette date est déjà planifiée.");
				this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: "Cette date a déjà été planifiée" }));
			}
		}
		else
			console.warn("ACT.EDIT.C:: La valeur de durée est invalide.");
	}

	public removeDateValue(pdDate: Date): void {
		if (this.hasEndDate(this.acte))
			return;

		if ((this.recurrence.durationValue as Date[]).length >= 1) {
			if (this.recurrence.durationValue instanceof Array)
				ArrayHelper.removeElement(this.recurrence.durationValue, pdDate);
			else
				console.warn("ACT.EDIT.C:: La valeur de durée est invalide.");
		}
	}

	public isSameDay(pdDate1: Date, pdDate2: Date): boolean {
		return DateHelper.diffDays(pdDate1, pdDate2) === 0;
	}

	public hasEndDate(poActe: Acte): boolean {
		return DateHelper.isDate(poActe.endDate);
	}

	public onActeDateClick(): void {
		if (this.hasValidatedOrCanceledSeancesByActeGuid)
			this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: "La date de démarrage de cet acte ne peut être modifiée, des soins ayant déjà été réalisés ou annulés.", duration: 3000 }));
	}

	//#endregion

}
