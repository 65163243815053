import PCancelable from 'p-cancelable';
import { Observable, of, throwError } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { NotImplementedError } from "../../../../model/errors/NotImplementedError";
import { Database } from "../../../../model/store/Database";
import { IStoreDocument } from "../../../../model/store/IStoreDocument";
import { IStoreReplicationResponse } from "../../../../model/store/IStoreReplicationResponse";
import { NetworkService } from "../../../../services/network.service";
import { NoDatabaseInternetConnectionError } from "../../model/errors/no-database-internet-connection-error";
import { IOnProgressFunction } from "./ion-progress-function";
import { IReplicatorOptions } from "./ireplicator-options";

export abstract class ReplicatorBase {

	constructor(protected readonly isvcNetwork: NetworkService) { }

	/** Réplique une base de données.
	 *
	 * @param poDatabase
	 * @param poSource
	 * @param poTarget
	 * @param poOptions
	 * @param pfOnProgress
	 */
	public abstract replicateAsync(
		poDatabase: Database,
		poSource: PouchDB.Database<{}>,
		poTarget: PouchDB.Database<{}>,
		poOptions?: IReplicatorOptions,
		pfOnProgress?: IOnProgressFunction
	): PCancelable<IStoreReplicationResponse<IStoreDocument>>;

	/** Réplique en continue une base de données.
	 *
	 * @param poDatabase
	 * @param poSource
	 * @param poTarget
	 * @param poOptions
	 */
	public replicateLive$(
		poDatabase: Database,
		poSource: PouchDB.Database<{}>,
		poTarget: PouchDB.Database<{}>,
		poOptions?: IReplicatorOptions): Observable<IStoreReplicationResponse<IStoreDocument>> {
		throw new NotImplementedError("replicateLive not implemented");
	}

	protected async handleNetworkAsync(poDatabase: Database): Promise<void> {
		if (!await this.isvcNetwork.asyncIsNetworkReliable().toPromise())
			throw this.createConnectionError(poDatabase);
	}

	private createConnectionError(poDatabase: Database): NoDatabaseInternetConnectionError {
		return new NoDatabaseInternetConnectionError(poDatabase.id, "La connexion réseau est indispensable pour une synchronisation.");
	}

	protected handleNetwork$(poDatabase: Database): Observable<void> {
		return this.isvcNetwork.asyncIsNetworkReliable().pipe(
			mergeMap((pbHasNetwork: boolean) => pbHasNetwork ?
				of(undefined) :
				throwError(this.createConnectionError(poDatabase))
			)
		);
	}

}
