import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, of } from 'rxjs';
import { mapTo, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { ObjectHelper } from '../../../helpers/objectHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { EPrefix } from '../../../model/EPrefix';
import { UserData } from '../../../model/application/UserData';
import { IContact } from '../../../model/contacts/IContact';
import { IGroup } from '../../../model/contacts/IGroup';
import { IGroupSelection } from '../../../model/contacts/IGroupSelection';
import { IGroupsChecklistParams } from '../../../model/contacts/IGroupsChecklistParams';
import { C_SECTORS_ROLE_ID, PermissionsService } from '../../../modules/permissions/services/permissions.service';
import { ISelectOption } from '../../../modules/selector/selector/ISelectOption';
import { ISite } from '../../../modules/sites/models/isite';
import { SitesService } from '../../../modules/sites/services/sites.service';
import { ContactsService } from '../../../services/contacts.service';
import { GroupsService } from '../../../services/groups.service';
import { EDynamicTitle } from '../../dynamicPage/EDynamicTitle';

interface IGroupOptions {
	/** Titre du regroupement. */
	title: string;
	/** Liste des options. */
	groupOptions: ISelectOption<IGroup>[];
	/** Mode d'affichage, `liste` par défaut. */
	mode?: "liste" | "tags";
}
/** Composant pour lister des groupes avec une case à cocher. */
@Component({
	selector: "calao-groups-checklist",
	templateUrl: './groups-checklist.component.html',
	styleUrls: ['./groups-checklist.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupsChecklistComponent<T extends IGroup> extends ComponentBase implements OnInit, OnDestroy, IGroupsChecklistParams<T> {

	//#region FIELDS

	/** Texte par défaut du bouton de création d'un groupe. */
	private static readonly C_DEFAULT_NEW_GROUP_BUTTON_TEXT = "Nouveau groupe";
	/** Texte par défaut du bouton de création d'un groupe. */
	private static readonly C_DEFAULT_TITLE = "Groupes";
	/** Texte par défaut si aucun groupe. */
	private static readonly C_DEFAULT_EMPTY_TEXT = "Aucun groupe";

	/** Événement qui envoie le tableau des éléments sélectionnés à chaque changement opéré. */
	@Output("onSelectionChanged") private readonly moOnSelectionChangedEvent = new EventEmitter<IGroupSelection<T>[]>();

	/** Raccourci vers l'identifiant du modèle. */
	private msModelId: string;
	private moInitGroupsSubject = new ReplaySubject<T[] | string[]>();
	private maSelectedGroups: T[] = [];
	private moContactsByGroupId = new Map<string, IContact[]>();

	//#endregion

	//#region PROPERTIES

	private maData: T[];
	/** @implements */
	public get data(): T[] {
		return this.maData;
	}
	@Input() public set data(poData: T[]) {
		if (!ArrayHelper.areArraysFromDatabaseEqual(this.maData, poData)) {
			this.maData = poData;
			this.moInitGroupsSubject.next(poData);
		}
	}

	private maGroupIds: string[];
	/** @implements */
	public get groupIds(): string[] {
		return this.maGroupIds;
	}
	@Input() public set groupIds(paGroupIds: string[]) {
		if (!ArrayHelper.areArraysEqual(this.maGroupIds, paGroupIds)) {
			this.maGroupIds = paGroupIds;
			this.moInitGroupsSubject.next(paGroupIds);
		}
	}

	/** @implements */
	@Input() public model?: IContact | ISite;
	/** @implements */
	@Input() public createButtonText?: string;
	/** @implements */
	@Input() public readOnly?: boolean;
	/** @implements */
	@Input() public title?: string;
	/** @implements */
	@Input() public multiple?: boolean;
	/** @implements */
	@Input() public hideTitle?: boolean;
	/** @implements */
	@Input() public roles?: string[];
	/** @implements */
	@Input() public emptyText?: string;
	/** @implements */
	@Input() public sortGroupsByType?: boolean;

	private mnMin: number;
	public get min(): number {
		return this.mnMin;
	}
	@Input()
	public set min(pnMin: number) {
		if (pnMin !== this.mnMin) {
			this.mnMin = pnMin;
			this.detectChanges();
		}
	}


	/** Indique si l'utilisateur peut créer un nouveau groupe. */
	public canCreate = false;
	/** Tableau des groupes sélectionnables à manipuler. */
	public groupOptions: ISelectOption<T>[] = [];
	/** Tableau des groupes sélectionnables à manipuler triés par type. */
	private maGroupsOptionsByTypes: IGroupOptions[] = [];
	public get groupsOptionsByTypes(): IGroupOptions[] { return this.maGroupsOptionsByTypes; }
	public preSelectedGroups: T[] = [];

	public get tagsDisplayMode(): string {
		return "tags";
	}

	//#endregion

	//#region METHODS

	constructor(
		private isvcPermissions: PermissionsService,
		private isvcGroups: GroupsService,
		private isvcContacts: ContactsService,
		private ioRouter: Router,
		private isvcSites: SitesService,
		poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	/** @implements */
	public ngOnInit(): void {
		// Sélection multiple par défaut.
		this.multiple = this.multiple ?? true;

		if (StringHelper.isBlank(this.createButtonText))
			this.createButtonText = GroupsChecklistComponent.C_DEFAULT_NEW_GROUP_BUTTON_TEXT;
		if (StringHelper.isBlank(this.title))
			this.title = GroupsChecklistComponent.C_DEFAULT_TITLE;
		else {
			if (this.title.includes(EDynamicTitle.site))
				this.title = this.title.replace(EDynamicTitle.site, !ObjectHelper.isNullOrEmpty(UserData.currentSite) ? UserData.currentSite.name : "");
		}
		if (StringHelper.isBlank(this.emptyText))
			this.emptyText = GroupsChecklistComponent.C_DEFAULT_EMPTY_TEXT;

		if (this.model)
			this.msModelId = this.model._id;

		this.canCreate = this.isvcContacts.checkCreatePermission() && !this.readOnly;

		this.moInitGroupsSubject
			.pipe(
				switchMap((paData: T[] | string[]) => this.initGroups(paData)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moInitGroupsSubject.complete();
	}

	/** Initialise les groupes sélectionnables ou non et les retourne. */
	private initGroups(paData: T[] | string[]): Observable<boolean> {
		let loGroups$: Observable<T[]>;
		const lbHasCurrentSite: boolean = !ObjectHelper.isNullOrEmpty(UserData.currentSite);

		if (lbHasCurrentSite && ArrayHelper.getFirstElement(this.roles) === C_SECTORS_ROLE_ID)
			loGroups$ = (this.isvcSites.getSiteSectors(UserData.currentSite._id, true) as Observable<T[]>);
		else if (ArrayHelper.hasElements(paData) && typeof ArrayHelper.getFirstElement(paData as string[]) !== "string")
			loGroups$ = of(this.data);
		else if (this.model._id.startsWith(EPrefix.site))
			loGroups$ = (this.isvcSites.getSiteSectors(this.model._id, true) as Observable<T[]>);
		else
			loGroups$ = this.isvcGroups.getGroups(paData as string[]) as Observable<T[]>;

		return loGroups$
			.pipe(
				tap((paResults: T[]) => {
					this.groupOptions = this.sortOptions(
						paResults.map((poGroup: T) => {
							return ({
								label: poGroup.name,
								value: poGroup,
								disabled: false
							} as ISelectOption<T>);
						})
					);
					this.maGroupsOptionsByTypes = this.getGroupOptionsByTypes(this.groupOptions);
				}),
				mergeMap((paResults: T[]) => {
					return this.isvcGroups.getGroupContacts(paResults, [IdHelper.getPrefixFromId(this.msModelId)])
						.pipe(
							tap((poContactsByGroupId: Map<string, IContact[]>) => {
								this.preSelectedGroups = [];
								this.moContactsByGroupId = poContactsByGroupId;

								paResults.forEach((poGroup: T) => {
									if (this.model && poContactsByGroupId.get(poGroup._id)?.some((poContact: IContact) => this.msModelId === poContact._id))
										this.preSelectedGroups.push(poGroup);
								});

								this.maSelectedGroups = [...this.preSelectedGroups];
								this.moOnSelectionChangedEvent.emit(this.getGroupSelections());
							})
						);
				}),
				mapTo(true),
				tap(_ => this.detectChanges()),
				takeUntil(this.destroyed$)
			);
	}

	private sortOptions(paOptions: ISelectOption<T>[]): ISelectOption<T>[] {
		return paOptions.sort((poItemA: ISelectOption<T>, poItemB: ISelectOption<T>) => poItemA.label.localeCompare(poItemB.label));
	}

	/** Transforme des groupes en groupe avec un champ de sélection. */
	private getGroupSelections(): IGroupSelection<T>[] {
		return this.groupOptions.map((poGroupOption: ISelectOption<T>) => {
			return {
				group: poGroupOption.value,
				selected: this.maSelectedGroups.includes(poGroupOption.value),
				contacts: this.moContactsByGroupId.get(poGroupOption.value._id) || [],
				disabled: false
			} as IGroupSelection<T>;
		});
	}

	/** Traitement d'un groupe sélectionné (préparation de nouveaux liens).
	 * @param paGroups Liste des groupes sélectionnés.
	 */
	public onGroupSelectionChanged(paGroups: T[]): void {
		if (!ArrayHelper.areArraysEqual(this.maSelectedGroups, paGroups)) {
			this.maSelectedGroups = [...paGroups];
			this.moOnSelectionChangedEvent.emit(this.getGroupSelections());
		}
	}

	/** Création d'un nouveau groupe. */
	public createNewGroup(): void {
		this.isvcGroups.openCreateGroupeModal({
			_id: IdHelper.buildId(EPrefix.group),
			name: "",
			roles: this.roles
		}) // On ouvre la modale de création d'un groupe.
			.pipe(
				mergeMap((poNewGroup: T) => {
					if (ArrayHelper.hasElements(this.roles)) {
						poNewGroup.roles = ArrayHelper.unique([...(poNewGroup.roles ?? []), ...(this.roles ?? [])]);
						return this.isvcGroups.updateGroup(poNewGroup).pipe(mapTo(poNewGroup));
					}
					return of(poNewGroup);
				}),
				mergeMap((poNewGroup: T) => {
					return this.isvcGroups.getGroupContacts(poNewGroup, [IdHelper.getPrefixFromId(this.msModelId)]) // On récupère les contacts liés au nouveau groupe.
						.pipe(
							tap((paNewGroupContacts: IContact[]) => {
								// On transforme le nouveau groupe en donnée exploitable et on l'ajoute au tableau existant.
								ArrayHelper.pushIfNotPresent(this.groupOptions,
									({ label: poNewGroup.name, value: poNewGroup, disabled: !this.isvcPermissions.canApplyRoles(...(poNewGroup.roles ?? [])) }),
									(poGroupOption: ISelectOption<T>) => poGroupOption.value._id === poNewGroup._id
								);

								this.groupOptions = this.sortOptions(this.groupOptions);
								this.maGroupsOptionsByTypes = this.getGroupOptionsByTypes(this.groupOptions);

								if (!!this.multiple) {
									this.preSelectedGroups.push(poNewGroup);
									this.maSelectedGroups.push(poNewGroup);
								}
								else {
									this.preSelectedGroups = [poNewGroup];
									this.maSelectedGroups = [poNewGroup];
								}

								this.moContactsByGroupId[poNewGroup._id] = paNewGroupContacts;
								console.log();

								this.onGroupSelectionChanged(this.maSelectedGroups);
								this.detectChanges();
							})
						);
				}),
				tap(_ => this.moOnSelectionChangedEvent.emit(this.getGroupSelections())),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Va sur la page de visualisation du groupe cliqué.
	 * @param poClickedGroup Groupe qu'on veut afficher en mode visu.
	 */
	public onItemClicked(poClickedGroup: T): void {
		this.ioRouter.navigate(["groupes", poClickedGroup._id]);
	}

	/** Retourne un tableau des groupes sélectionnables trier par type.
	 * @param paGroupOptions Tableau des groupes sélectionnables à manipuler.
	 */
	private getGroupOptionsByTypes(paGroupOptions: ISelectOption<T>[]): IGroupOptions[] {
		const laGroupOptionsByTypes: IGroupOptions[] = [];
		const laProfilOptions: ISelectOption<T>[] = [];
		const laGroupOptions: ISelectOption<T>[] = [];
		const laSectorOptions: ISelectOption<T>[] = [];

		paGroupOptions.forEach((poSelectOption: ISelectOption<T>) => {
			if (ArrayHelper.hasElements(poSelectOption.value.roles) && poSelectOption.value.roles.includes(C_SECTORS_ROLE_ID))
					laSectorOptions.push(poSelectOption);
			else
				laProfilOptions.push(poSelectOption);		});

		if (ArrayHelper.hasElements(laProfilOptions))
			laGroupOptionsByTypes.push({ title: "Profils", groupOptions: laProfilOptions, mode: "tags" });
		if (ArrayHelper.hasElements(laGroupOptions))
			laGroupOptionsByTypes.push({ title: this.title, groupOptions: laGroupOptions });
		if (ArrayHelper.hasElements(laSectorOptions))
			laGroupOptionsByTypes.push({ title: "Secteurs", groupOptions: laSectorOptions });

		return laGroupOptionsByTypes;
	}

	//#endregion

}