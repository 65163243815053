import { EPrefix } from '../EPrefix';
export class EntityLinkIdParts {

	//#region PROPERTIES

	public readonly entityId1: string;
	public readonly entityPrefix1: string; //NB : On n'utilise pas EPrefix car le nom des entrées de l'énumération et leur valeur sont différentes et pose un pb de transformations string => EPrefix. (EPrefix["conv_"]=>undefined car l'entrée est "conversation" et non "conv_").
	public readonly entityId2: string;
	public readonly entityPrefix2: string;

	//#endregion

	//#region METHODS

  /** Crée un objet entité.
   * @param id Identifiant de l'entité.
   */
	constructor(
		public readonly id: string) {

		try {
			const laSplit = id.substring(EPrefix.link.length).split("-");

			this.entityId1 = laSplit[0];
			this.entityPrefix1 = `${this.entityId1.split("_")[0]}_`;
			this.entityId2 = laSplit[1];
			this.entityPrefix2 = `${this.entityId2.split("_")[0]}_`;
		}
		catch (poError) {
			throw new Error(`Cannot parse link id: ${id}. ${poError.toString()}`);
		}
	}

	//#endregion
}