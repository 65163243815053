import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ComponentBase, DateHelper } from '@osapp/helpers';
import { IEvent } from '@osapp/model';

@Component({
	selector: 'idl-timelined-event',
	templateUrl: './timelined-event.component.html',
	styleUrls: ['./timelined-event.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelinedEventComponent extends ComponentBase {

	//#region FIELDS

	private mdNow: Date = new Date;

	//#endregion

	//#region PROPERTIES

	private moEvent: IEvent;
	public get event(): IEvent {
		return this.moEvent;
	}
	@Input() public set event(poEvent: IEvent) {
		if (poEvent && poEvent !== this.moEvent) {
			this.moEvent = poEvent;
			this.detectChanges();
		}
	}

	private moPreviousEvent: IEvent;
	public get previousEvent(): IEvent {
		return this.moPreviousEvent;
	}
	@Input() public set previousEvent(poPreviousEvent: IEvent) {
		if (poPreviousEvent && poPreviousEvent !== this.moPreviousEvent) {
			this.moPreviousEvent = poPreviousEvent;
			this.detectChanges();
		}
	}

	public get timelineExists(): boolean {
		const ldNow = new Date();

		return this.innerTimelineExists(ldNow, this.event) && !this.innerTimelineExists(ldNow, this.previousEvent);
	}

	//#endregion

	//#region METHODS

	constructor(poChangeDetector: ChangeDetectorRef) {
		super(poChangeDetector);
	}

	/** Renvoi la distance entre le début de la pause et la timeline (en pourcentage).\
	 * Cette distance est calculée de la façon suivante : ((date courante - date de début de la pause) / 60000) * 100 / durée de la pause.
	 */
	public getTimelineFromTop(): number {
		return (DateHelper.compareTwoDates(this.mdNow, this.event.startDate) / 60000) * 100 / this.event.duration;
	}

	private innerTimelineExists(pdNow: Date, poEvent: IEvent): boolean {
		return poEvent && DateHelper.compareTwoDates(pdNow, poEvent.startDate) > 0 &&
			DateHelper.compareTwoDates(pdNow, poEvent.endDate) < 0;
	}

	//#endregion
}
