import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { ChangelogsModule } from '@osapp/modules/about/changelogs/changelogs.module';
import { BatteryModule } from '@osapp/modules/battery/battery.module';
import { ConflictsModule } from '@osapp/modules/conflicts/conflicts.module';
import { IDatabaseGroupingConfiguration } from '@osapp/modules/database-synchro/model/IDatabaseGroupingConfiguration';
import { DATABASES_GROUPING_CONFIG, DMS_SYNC_CONFIG } from '@osapp/modules/database-synchro/services/database-synchro.service';
import { DeeplinkModule } from '@osapp/modules/deeplink/deeplink.module';
import { GdprModule } from '@osapp/modules/gdpr/gdpr.module';
import { GuidModule } from '@osapp/modules/guid/guid.module';
import { ShortGuidBuilder } from '@osapp/modules/guid/models/short-guid-builder';
import { PatchModule } from '@osapp/modules/patch/patch.module';
import { Sqlite2ToSqliteEvcoreExtbuildFreePatch } from '@osapp/modules/patch/patches/Sqlite2ToSqliteEvcoreExtbuildFreePatch';
import { PermissionsModule } from '@osapp/modules/permissions/permissions.module';
import { PrestationModule } from '@osapp/modules/prestation/prestation.module';
import { OsappModule } from '@osapp/osapp.module';
import * as constants from '../config';
import { CustomModule } from '../modules/customElements.module';
import { IdlPrestationIdBuilder } from '../modules/facturation/models/idl-prestation-id-builder';
import { IdlDmsModule } from '../modules/idl-dms/idl-dms.module';
import { SeancesModule } from '../modules/seances/seances.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

const databasesGroupingConfigs: IDatabaseGroupingConfiguration[] = [
	{
		roles: [EDatabaseRole.workspace, EDatabaseRole.userContext],
		title: "Espace de travail",
		description: "contacts, patients, traitements, ..."
	}
];

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserAnimationsModule,
		IonicModule.forRoot(),
		OsappModule,
		GuidModule.forRoot({ builderType: ShortGuidBuilder }),
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		CustomModule,
		DeeplinkModule,
		SeancesModule, // TODO TB : En attente du redécoupage des modules
		ServiceWorkerModule.register("ngsw-worker.js", { enabled: true, registrationStrategy: "registerImmediately" }),
		IdlDmsModule,
		PatchModule.forRoot([Sqlite2ToSqliteEvcoreExtbuildFreePatch]),
		GdprModule,
		ConflictsModule,
		PermissionsModule,
		BatteryModule,
		PrestationModule.forRoot({ idBuilderType: IdlPrestationIdBuilder }),
		ChangelogsModule
	],
	bootstrap: [AppComponent],
	providers: [
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: DATABASES_GROUPING_CONFIG, useValue: databasesGroupingConfigs },
		{ provide: DMS_SYNC_CONFIG, useValue: { title: "Documents", description: "ordonnances, photos, ..." } },
		constants.appInfo.applicationServiceProvider,
		constants.appInfo.domainServiceProvider
	]
})
export class AppModule { 
	constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
		matIconRegistry.addSvgIcon(
			'desmos',
			domSanitizer.bypassSecurityTrustResourceUrl('../assets/anakin/custom-icons/desmos.svg')
		);
	}
}
