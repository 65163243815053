import { DateHelper } from "@osapp/helpers/dateHelper";
import { IdHelper } from "@osapp/helpers/idHelper";
import { IGalleryFile } from "@osapp/model";
import { ResolveModel } from "@osapp/modules/utils/models/decorators/resolve-model.decorator";
import { Exclude, Expose } from "class-transformer";
import { C_PREFIX_ORDONNANCE } from "../../../app/app.constants";
import { Traitement } from "../../../model/Traitement";
import { IFacturationNumber } from "../../patients/model/IFacturationNumber";
import { EOriginePrescription } from "./eorigine-prescription.enum";
import { IOrdonnance } from "./iordonnance";
import { RenouvellementOrdonnance } from "./renouvellement-ordonnance";

export class Ordonnance implements IOrdonnance {

	//#region PROPERTIES

	/** @implements */
	public _id: string;
	/** @implements */
	public _rev?: string;
	/** @implements */
	public _deleted?: boolean;
	/** @implements */
	public deleted?: boolean;
	/** @implements */
	public _conflicts?: string[];
	@Exclude()
	private mdPrescriptionDate: Date;
	/** @implements */
	@Expose()
	public get prescriptionDate(): Date { return this.mdPrescriptionDate; }
	public set prescriptionDate(pdPrescriptionDate: Date) {
		this.mdPrescriptionDate = DateHelper.resetDay(pdPrescriptionDate);
	}
	@ResolveModel(RenouvellementOrdonnance)
	/** @implements */
	public renouvellement?: RenouvellementOrdonnance;
	/** @implements */
	public prescripteurContactId?: string;
	/** @implements */
	public originePrescription?: EOriginePrescription;
	/** @implements */
	public isAld?: boolean = false;
	/** @implements */
	public adcDate?: Date;
	/** @implements */
	public documents: IGalleryFile[];
	/** @implements */
	public linkedActesGuids?: string[] = [];
	/** @implements */
	public facturationNumber?: IFacturationNumber;

	private msTraitementId: string;
	public get traitementId(): string {
		if (!this.msTraitementId)
			this.msTraitementId = Ordonnance.extractTraitementId(this._id);

		return this.msTraitementId;
	}

	//#endregion

	//#region METHODS

	constructor(poTraitement?: Traitement) {
		if (poTraitement)
			this._id = IdHelper.buildChildId(C_PREFIX_ORDONNANCE, poTraitement._id);
		this.prescriptionDate = new Date();
		this.originePrescription = EOriginePrescription.medTraitant;
	}

	public static extractTraitementId(psInvoiceId: string): string {
		return IdHelper.extractParentId(psInvoiceId);
	}

	//#endregion

}
