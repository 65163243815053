<div *ngIf="traitement">
	<div class="pathologie-title">Prises en charge spécifiques :</div>
	<osapp-selector displayMode="tags" [options]="filtersOptions" [preselectedValues]="traitement.pathologies"
		scrollWrapper="false" (selectionChanged)="onPathologieChanged($event)" multiple>
	</osapp-selector>
</div>

<search #search [data]="acts" (filteredEntriesChanged)="onFilteredEntriesChanged($event, search)"
	[options]="searchOptions" (autocompleteOptionClicked)="onAutocompleteOptionClicked($event)"></search>

<osapp-selector *ngIf="!traitement" displayMode="tags" [options]="filtersOptions" scrollWrapper="false"
	(selectionChanged)="onPathologieFilterChanged($event)" multiple>
</osapp-selector>

<ng-container *ngTemplateOutlet="chapter; context: { actesBySubChapter: actesByChapter, chapterLvl: 0 }">
</ng-container>

<ng-template #chapter let-actesBySubChapter="actesBySubChapter" let-chapterLvl="chapterLvl">
	<ion-list [ngClass]="chapterLvl === 0 ? 'first-list' : 'others-list'">
		<div *ngFor="let item of actesBySubChapter | keyvalue; let i = index;"
			[class]="i !== actesBySubChapter.size - 1 &&  !opennedChaptersIds.includes(item.key._id) ? 'white-border' : ''">
			<ion-item lines="none" mode="md" (click)="onChapterClicked(item.key, chapterLvl)"
				[ngClass]="chapterLvl === 0 ? 'chapter-first-level' : 'chapter-other-level'">
				<ion-icon *ngIf="item.key.icon" class="chapter-icon" slot="start" [name]="item.key.icon" size="small">
				</ion-icon>
				<ion-label class="chapter-label">{{ item.key.description }}</ion-label>
				<ion-icon class="chevron-icon" slot="end"
					[name]="opennedChaptersIds.includes(item.key._id) ? 'chevron-up-outline' : 'chevron-down-outline'"
					size="small"></ion-icon>
			</ion-item>

			<ng-container *ngIf="opennedChaptersIds.includes(item.key._id)">

				<ng-container *ngIf="item.value.subChapters">
					<ng-container
						*ngTemplateOutlet="chapter; context: { actesBySubChapter: item.value.subChapters, chapterLvl: chapterLvl + 1 }">
					</ng-container>
				</ng-container>

				<ng-container *ngIf="item.value.actes">
					<ng-container *ngTemplateOutlet="actesList; context: { actes: item.value.actes }">
					</ng-container>
				</ng-container>

			</ng-container>
		</div>
	</ion-list>
</ng-template>

<ng-template #actesList let-actes="actes">
	<ion-grid>
		<ion-row class="header-actes marg-fav">
			<ion-col [sizeLg]="0.5" [sizeXl]="0.5" [sizeSm]="2" [sizeMd]="0.5" [sizeXs]="2"></ion-col>
			<ion-col [size]="2" class="text-center" (click)="orderOrReverse(searchableActeKeyEnum.keyLetters)">
				<a class="ion-text-nowrap grid-header">
					Lettre-clé
					<ion-icon [hidden]="sortKey !== searchableActeKeyEnum.keyLetters"
						[name]="isReverseSort ? 'caret-down-outline' : 'caret-up'"></ion-icon>
				</a>
			</ion-col>

			<ion-col [size]="2" class="text-center" (click)="orderOrReverse(searchableActeKeyEnum.priceCoefficient)">
				<a class="ion-text-nowrap grid-header">
					Coef.
					<ion-icon [hidden]="sortKey !== searchableActeKeyEnum.priceCoefficient"
						[name]="isReverseSort ? 'caret-down-outline' : 'caret-up'"></ion-icon>
				</a>
			</ion-col>

			<ion-col [sizeLg]="7" [sizeXl]="7" [sizeSm]="4" [sizeMd]="7" [sizeXs]="4" class="text-left"
				(click)="orderOrReverse(searchableActeKeyEnum.label)">
				<a class="ion-text-nowrap grid-header">
					Description
					<ion-icon [hidden]="sortKey !== searchableActeKeyEnum.label"
						[name]="isReverseSort ? 'caret-down-outline' : 'caret-up'"></ion-icon>
				</a>

			</ion-col>
			<ion-col [sizeLg]="0.5" [sizeXl]="0.5" [sizeSm]="2" [sizeMd]="0.5" [sizeXs]="2"></ion-col>
		</ion-row>

		<ion-virtual-scroll [items]="actes" [trackBy]="trackById">
			<ion-item-sliding #itemSliding *virtualItem="let act; let i = index;">
				<ion-item lines="full" [color]="i % 2 === 0 ? 'light' : '' ">
					<ion-row class="grid-content" [ngClass]="i % 2 === 0 ? 'odd' : 'even'">
						<ion-col [sizeLg]="0.5" [sizeXl]="0.5" [sizeSm]="2" [sizeMd]="0.5" [sizeXs]="2" class="vertical-align">
							<ion-button fill="clear" (click)="onFavoritesClicked(act)">
								<ion-icon [name]="favorites?.ids?.includes(act._id) ? 'heart' : 'heart-outline'" slot="icon-only">
								</ion-icon>
							</ion-button>
						</ion-col>
						<ion-col [size]="2" class="text-center" (click)="onActSelected(act)">
							<p>{{ act.keyLetters }}</p>
						</ion-col>
						<ion-col [size]="2" class="text-center" (click)="onActSelected(act)">
							<p>{{ act.priceCoefficient }}</p>
						</ion-col>
						<ion-col [sizeLg]="7" [sizeXl]="7" [sizeSm]="4" [sizeMd]="7" [sizeXs]="4" class="vertical-align col-flex"
							(click)="onActSelected(act)">
							<p>{{ act.label }}</p>
						</ion-col>
						<ion-col [sizeLg]="0.5" [sizeXl]="0.5" [sizeSm]="2" [sizeMd]="0.5" [sizeXs]="2"
							class="more-col vertical-align">
							<ion-button class="itemb btn-more" fill="clear" (click)="openOrCloseItemSliding(itemSliding, $event)">
								<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
							</ion-button>
						</ion-col>
					</ion-row>
				</ion-item>

				<ion-item-options>
					<ion-item-option color="danger" (click)="itemSliding.close(); onFavoritesClicked(act)">
						<ion-icon [name]="favorites?.ids?.includes(act._id) ? 'heart' : 'heart-outline'" slot="icon-only">
						</ion-icon>
						<ion-label class="option-label">Favori</ion-label>
					</ion-item-option>
					<ion-item-option (click)="itemSliding.close(); openPersonalizeActeModal(act, 'personalize');">
						<ion-icon name="pencil" slot="icon-only"></ion-icon>
						<ion-label class="option-label">Personnaliser</ion-label>
					</ion-item-option>
					<ion-item-option color="medium" (click)="itemSliding.close(); openPersonalizeActeModal(act, 'duplicate');">
						<ion-icon name="duplicate" slot="icon-only"></ion-icon>
						<ion-label class="option-label">Dupliquer</ion-label>
					</ion-item-option>
				</ion-item-options>
			</ion-item-sliding>

		</ion-virtual-scroll>
	</ion-grid>
</ng-template>