import { Component, forwardRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AlertButton } from '@ionic/core';
import { DynamicPageComponent } from '@osapp/components/dynamicPage';
import { SlideboxComponent } from '@osapp/components/slidebox';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { GuidHelper } from '@osapp/helpers/guidHelper';
import { LifeCycleObserverComponentBase } from '@osapp/helpers/LifeCycleObserverComponentBase';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { StoreDocumentHelper } from '@osapp/helpers/storeDocumentHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { IContact } from '@osapp/model/contacts/IContact';
import { DynHostItem } from '@osapp/model/DynHostItem';
import { WorkspaceSelectionCancelledError } from '@osapp/model/errors/WorkspaceSelectionCancelledError';
import { IIndexedArray } from '@osapp/model/IIndexedArray';
import { ELifeCycleEvent } from '@osapp/model/lifeCycle/ELifeCycleEvent';
import { ILifeCycleEvent } from '@osapp/model/lifeCycle/ILifeCycleEvent';
import { ISlideboxData } from '@osapp/model/slidebox/ISlideboxData';
import { IStoreDocument } from '@osapp/model/store/IStoreDocument';
import { Queuer } from '@osapp/modules/utils/queue/models/queuer';
import { ContactsService } from '@osapp/services/contacts.service';
import { EntityLinkService } from '@osapp/services/entityLink.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '@osapp/services/interfaces/ShowMessageParamsToast';
import { LoadingService } from '@osapp/services/loading.service';
import { PageManagerService } from '@osapp/services/pageManager.service';
import { PlatformService } from '@osapp/services/platform.service';
import { SlideboxService } from '@osapp/services/slidebox.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { combineLatest, EMPTY, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IPrescriptionsParams } from '../../model/IPrescriptionsParams';
import { ISaveTraitementParams } from '../../model/ISaveTraitementParams';
import { ITraitementWithSeances } from '../../model/ITraitementWithSeances';
import { Traitement } from '../../model/Traitement';
import { TraitementSlideParentBase } from '../../model/TraitementSlideParentBase';
import { SeanceService } from '../../services/seance.service';
import { TraitementService } from '../../services/traitement.service';
import { PrestationsListComponentBase } from '../facturation/helpers/PrestationsListComponentBase';
import { IPatient } from '../patients/model/IPatient';
import { PatientsService } from '../patients/services/patients.service';
import { AccordPrealableComponent } from './slides-traitement/accordPrealable/accordPrealable.component';
import { IndemnitesComponent } from './slides-traitement/indemnites/indemnites.component';
import { MajorationsComponent } from './slides-traitement/majorations/majorations.component';
import { PrescriptionsComponent } from './slides-traitement/prescriptions/prescriptions.component';
import { SeancesComponent } from './slides-traitement/seances/seances.component';
import { SyntheseComponent } from './slides-traitement/synthese/synthese.component';
import { TraitementSlideComponentBase } from './slides-traitement/traitement-slide-component-base.component';

@Component({
	templateUrl: './traitement-slide-page.component.html',
	styleUrls: ['./traitement-slide-page.component.scss'],
	providers: [{ provide: TraitementSlideParentBase, useExisting: forwardRef(() => TraitementSlideComponent) }]
})
export class TraitementSlideComponent extends LifeCycleObserverComponentBase implements OnInit, OnDestroy, TraitementSlideParentBase {

	//#region FIELDS

	/** Chaîne de caractères indiquant une erreur lors de l'enregistrement du traitement. */
	private static readonly C_SAVE_ERROR_TITLE = "Erreur lors de l'enregistrement";
	/** Chaîne de caractères indiquant une erreur lors de l'enregistrement du traitement. */
	private static readonly C_SAVE_ERROR_MESSAGE = "L'application n'a pas pu enregistrer cet élément, veuillez réessayer.";

	/** Abonnement aux événements du bouton 'back' physique. */
	private readonly moBackButtonSubscription: Subscription;

	private moMapStringToDynHostItem: IIndexedArray<DynHostItem<TraitementSlideComponentBase>>;
	private mbSaveTraitementRequested = false;
	/** Sujet du traitement qui permet de gérer les changements du traitement. */
	private moTraitementSubject: Subject<Traitement> = new Subject();
	/** Sujet pour tous les changements opérés concernant les traitements. */
	private moTraitementSlideSubject: Subject<string> = new Subject();

	//#endregion

	//#region PROPERTIES

	@ViewChild("traitementSlidebox") public slidebox: SlideboxComponent;

	/** Traitement courant. */
	private moTraitementWithSeances: ITraitementWithSeances;
	private static readonly C_LOG_ID = "IDL.CSP.C::";

	public get traitementWithSeances(): ITraitementWithSeances {
		if (!this.moTraitementWithSeances)
			this.moTraitementWithSeances = { traitement: null, seances: null };

		return this.moTraitementWithSeances;
	}
	public set traitementWithSeances(poTraitementWithSeances: ITraitementWithSeances) {
		this.moTraitementWithSeances = poTraitementWithSeances;
	}

	public set traitement(poTraitement: Traitement) {
		if (poTraitement)
			this.traitementWithSeances.traitement = poTraitement;
	}
	public get traitement(): Traitement { return this.traitementWithSeances.traitement; }

	public patient: IContact;

	/** Méthode à appeler lorsqu'il y a un changement de slide. */
	public slideEvent: () => void = () => {
		console.debug(`IDL.CSP.C:: événement slide levé`);
	}

	/** @implements */
	public id = "traitement";
	/** @implements */
	public readonly componentId: string = GuidHelper.newGuid();
	/** @implements */
	public slideboxData: ISlideboxData;

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion du slideBox. */
		private isvcSlidebox: SlideboxService,
		/** Service de gestion du PageManager. */
		private isvcPageManager: PageManagerService,
		/** Service de gestion des séances. */
		private isvcSeances: SeanceService,
		private isvcEntityLink: EntityLinkService,
		private ioRouter: Router,
		private isvcTraitement: TraitementService,
		private isvcUiMessage: UiMessageService,
		private ioRoute: ActivatedRoute,
		private ioModalCtrl: ModalController,
		private isvcLoading: LoadingService,
		private isvcContacts: ContactsService,
		private isvcPatient: PatientsService,
		poParentPage: DynamicPageComponent<ComponentBase>,
		psvcPlatform: PlatformService
	) {
		super(poParentPage);
		if (this.ioRoute.snapshot.queryParamMap.get('startDate')) {
			this.ioRoute.snapshot.data.traitement.beginDate = new Date(this.ioRoute.snapshot.queryParamMap.get('startDate'));
			this.ioRoute.snapshot.data.traitement.endDate = new Date(this.ioRoute.snapshot.queryParamMap.get('startDate'));
		}

		this.traitement = this.ioRoute.snapshot.data.traitement;

		this.moBackButtonSubscription = psvcPlatform.getBackButtonSubscription(() => {
			this.ioModalCtrl.dismiss()
				.catch(_ => {
					if (this.slidebox.currentSlideId !== ArrayHelper.getFirstElement(this.slideboxData.slideBoxes).id)
						this.isvcSlidebox.goToPreviousSlide(this.componentId);
					else
						this.isvcPageManager.goBack();
				});
		});
	}

	public ngOnInit(): void {
    this.setGoHomeAndGoBack();

    if (!this.traitementWithSeances)
      this.traitementWithSeances = { traitement: this.traitement, seances: null };

    this.defineSaveTraitement();

    combineLatest([this.isvcContacts.getContact(this.traitement.patientId), this.isvcTraitement.getTraitement(this.traitement._id, true)])
      .pipe(
        filter((paResults: [IPatient, Traitement]) => {
          let lbHasChanged = false;

          if (this.patient !== paResults[0]) {
            this.patient = paResults[0];
            lbHasChanged = true;
          }

          if (this.traitement !== paResults[1]) {
            this.moTraitementSubject.next(ObjectHelper.assign(this.traitement, paResults[1]));
            lbHasChanged = true;
          }

          return lbHasChanged;
        }),
        mergeMap(() => this.isvcPatient.getPatient(this.patient._id)),
        mergeMap(patient => {
          this.patient = patient;
          return this.isvcSeances.generateSeances(this.traitement, patient, true, true); 
        }),
        tap(() => { }, (poError: any) => console.error(`${TraitementSlideComponent.C_LOG_ID}Erreur lors de l'init des seances.`, poError)),
        takeUntil(this.destroyed$)
      )
      .subscribe();

    this.initSlidebox()
      .pipe(
        catchError(poError => { console.error(`IDL.CSP.C:: Erreur init slidebox : `, poError); return EMPTY; }),
        tap((poResult: ISlideboxData) => {
          this.slideboxData = poResult;
          this.manageBlockingSlides();
        }),
        mergeMap(_ => this.slidebox.isSlideBoxInit$),
        filter((pbInitialized: boolean) => pbInitialized),
        switchMap(() => this.getInitializingSlideActions$()),
        tap((paInitializingSlideActions: string[]) => {
          if (this.ioRoute.snapshot.queryParamMap.has("tab")) {
            if (this.ioRoute.snapshot.queryParamMap.get("tab") === PrestationsListComponentBase.C_SYNTHESE_TAB_NAME)
              this.slidebox.slideTo(TraitementSlideParentBase.C_SYNTHESE_SLIDE_ID);
          };

          if (ArrayHelper.hasElements(paInitializingSlideActions)) {
            paInitializingSlideActions.forEach((psAction: string) => {
              switch (psAction) {
                case TraitementSlideParentBase.C_MAJORATIONS_SLIDE_ID:
                  this.slidebox.slideTo(TraitementSlideParentBase.C_MAJORATIONS_SLIDE_ID);
                  break;

                case TraitementSlideParentBase.C_INDEMNITES_SLIDE_ID:
                  this.slidebox.slideTo(TraitementSlideParentBase.C_INDEMNITES_SLIDE_ID);
                  break;
              }
            });
          }
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moTraitementSubject.complete();
		this.moTraitementSlideSubject.complete();
		this.isvcLoading.dismiss();
		this.moBackButtonSubscription.unsubscribe();
	}

	private setGoHomeAndGoBack(): void {
		const lfPresentLoader: () => void = () => this.isvcLoading.present(TraitementService.C_TRAITEMENT_SAVE_TEXT);

		// On modifie la méthode `goHome` pour présenter le loader si nécessaire avant de quitter la page.
		this.moParentPage.goHome = () => {
			if (this.mbSaveTraitementRequested)
				lfPresentLoader();
			this.isvcPageManager.goHome();
		};

		// On s'abonne aux événements du bouton 'back' ionic pour présenter le loader si nécessaire avant de quitter la page.
		this.moParentPage.viewBackFrom$
			.pipe(
				filter(_ => this.mbSaveTraitementRequested),
				tap(_ => lfPresentLoader()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Initialise la slidebox pour les traitements. */
	private initSlidebox(): Observable<ISlideboxData> {
		this.moMapStringToDynHostItem = {
			"Prescriptions": new DynHostItem(PrescriptionsComponent, { patientId: this.traitementWithSeances.traitement.patientId } as IPrescriptionsParams),
			"AccordPrealable": new DynHostItem(AccordPrealableComponent),
			"Seances": new DynHostItem(SeancesComponent),
			"Majorations": new DynHostItem(MajorationsComponent),
			"Indemnites": new DynHostItem(IndemnitesComponent),
			"Synthese": new DynHostItem(SyntheseComponent)
		};

		return this.isvcSlidebox.initSlideboxData(this.id, this.moMapStringToDynHostItem, this.componentId);
	}

	protected onLifeCycleEvent(poEvent: ILifeCycleEvent): void {
		switch (poEvent.data.value) {

			case ELifeCycleEvent.viewWillEnter:
				// Pour éviter d'avoir le bouton des liens dans la navbar si le traitement est en cours de création.
				if (!StringHelper.isBlank(this.traitement._rev))
					this.isvcEntityLink.trySetCurrentEntity(this.traitement).subscribe();
				break;

			case ELifeCycleEvent.viewWillLeave:
				this.isvcEntityLink.clearCurrentEntity(this.traitement._id).subscribe();
				break;
		}
	}

	/** @implements */
	public getTraitementAsObservable(): Observable<Traitement> {
		return this.moTraitementSubject.asObservable().pipe(startWith(this.traitement));
	}

	/** @implements */
	public getTraitementSlideAsObservable(): Observable<string> {
		return this.moTraitementSlideSubject.asObservable();
	}

	/** On sauvegarde le nouveau traitement, ou on demande à l'utilisateur de confirmer la mise à jour d'un traitement existant.
	 * @param poParams Paramètres pour enregistrer le traitement.
	 */
	private saveTraitement(poParams: ISaveTraitementParams): Observable<boolean> {
		return this.isvcTraitement.saveTraitement(poParams.traitement)
			.pipe(
				catchError(poError => {
					if (poError instanceof WorkspaceSelectionCancelledError) { // Cas d'annulation d'enregistrement.
						this.isvcTraitement.raiseSaveTraitementRequest(poParams);
						return EMPTY;
					}
					else {
						console.error(`La sauvegarde du traitement ${poParams.traitement._id} a échoué : `, poError);
						this.displaySaveMessage(true, TraitementSlideComponent.C_SAVE_ERROR_MESSAGE, TraitementSlideComponent.C_SAVE_ERROR_TITLE);
						return throwError(poError);
					}
				})
			);
	}

	/** Affiche une popup ou un toast pour avertir l'utilisateur.
	 * @param pbIsPopup L'affichage doit être une popup ou non.
	 * @param psMessage Message à afficher.
	 * @param psTitle Titre de l'affichage.
	 */
	private displaySaveMessage(pbIsPopup: boolean, psMessage: string, psTitle: string = "", pfFunction?: (value: any) => boolean | void): void {
		if (pbIsPopup) {
			this.isvcUiMessage.showMessage(
				new ShowMessageParamsPopup({
					header: psTitle,
					message: psMessage,
					buttons: [{ text: "OK", cssClass: "button-positive", role: undefined, handler: () => pfFunction }] as AlertButton[]
				})
			);
		}
		else
			this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ header: psTitle, message: psMessage }));
	}

	/** Définit la manière d'enregistrer le traitement. */
	private defineSaveTraitement(): void {
    const loQueuer = new Queuer({
      thingToQueue: (poParams: ISaveTraitementParams) => this.save(poParams).pipe(
        mergeMap(() => this.isvcPatient.getPatient(this.patient._id)),
        mergeMap(patient => {
          this.patient = patient;
          return this.isvcSeances.generateSeances(this.traitement, patient, true, true); 
        }),
        catchError(poError => {
          console.error("IDL.TSP.C:: Erreur lors de l'enregistrement du traitement.", poError);
          return of(null);
        }),
        finalize(() => this.mbSaveTraitementRequested = false)
      ),
      keepOnlyLastPending: true
    });

    this.routingAwaiter.navigationStart$.pipe(takeUntil(this.destroyed$)).subscribe(() => loQueuer.end());

    this.initQueue(loQueuer).pipe(takeUntil(this.destroyed$)).subscribe();

    this.isvcTraitement.getSaveTraitementRequestAsObservable(this.traitement._id).pipe(
      tap((poParams: ISaveTraitementParams) => {
        this.mbSaveTraitementRequested = true;
        loQueuer.exec(poParams);
        console.debug("IDL.CDM.S:: Request traitement save.");
      }),
      takeUntil(this.destroyed$),
    ).subscribe();
  }

	private initQueue(loQueuer: Queuer<any, ISaveTraitementParams>): Observable<any> {
		return loQueuer.start().pipe(
			tap(() => { }, () => { }, () => {
				this.moParentPage.allowNavigation(true);
				if (!this.destroyed) // Si le composant n'est pas détruit, on doit se préparer à un retour sur la page.
					this.initQueue(loQueuer).pipe(takeUntil(this.destroyed$)).subscribe();
				else
					loQueuer.end();
			})
		);
	}

	private save(poParams: ISaveTraitementParams): Observable<boolean> {
		// Si on a une divergence entre les rev, on aura un conflits, donc on met à jour la rev.
		if (this.traitement && !StoreDocumentHelper.areDocumentRevisionsEqual(this.traitement, poParams.traitement)) {
			poParams.traitement._rev = this.traitement._rev;
			console.warn(`${TraitementSlideComponent.C_LOG_ID}divergence entre les révisions du traitement à sauvegarder.`, poParams.traitement);
		}

		return this.saveTraitement(poParams)
			.pipe(
				tap(_ => this.mbSaveTraitementRequested = false),
				catchError(poError => this.onTraitementSaveFailed(poError))
			);
	}

	public route(poData: IStoreDocument): void {
		this.ioRouter.navigateByUrl(this.isvcEntityLink.buildEntity(poData).route);
	}

	/** @override */
	protected onNavigating(): void {
		this.routingAwaiter.sendRoutingStartEvent();
	}

	private onTraitementSaveFailed(poError: any): Observable<boolean> {
		console.error(`${TraitementSlideComponent.C_LOG_ID} Erreur enregistrement traitement :`, poError);
		this.mbSaveTraitementRequested = false;
		return of(false);
	}

	/** @implements */
	public manageBlockingSlides(): boolean {
		let lbAreSlideIdsBlocked: boolean;

		if (ArrayHelper.hasElements(this.traitement.actes))
			this.slideboxData.blockedIds = [];
		else {
			this.slideboxData.blockedIds = TraitementSlideParentBase.C_BLOCKED_SLIDE_IDS_IF_NO_ACTE;
			lbAreSlideIdsBlocked = true;
		}

		this.isvcSlidebox.raiseBlockedSlideIdsUpdated(this.slideboxData.blockedIds, this.componentId);

		return lbAreSlideIdsBlocked;
	}

	/** @implements */
	public getInitializingSlideActions(): string[] {
		return this.prepareActions(this.ioRoute.snapshot.queryParams?.actions);
	}

	public getInitializingSlideActions$(): Observable<string[]> {
		return this.ioRoute.queryParams.pipe(map((poParams: Params) => this.prepareActions(poParams?.actions)));
	}

	private prepareActions(psActions: string): string[] {
		return psActions?.split(",") ?? [];
	}

	/** @implements */
	public removeInitializingSlideActions(paActions: string[]): void {
		// On récupère les possibles queryParams pour supprimer les actions réalisées.
		const laInitializingSlideActions: string[] = this.getInitializingSlideActions();

		// On supprime les actions déjà réalisées.
		paActions.forEach((psAction: string) => ArrayHelper.removeElement(laInitializingSlideActions, psAction));

		// On modifie les queryParams pour ne remettre que les actions non réalisées.
		this.ioRoute.snapshot.queryParams = { actions: laInitializingSlideActions.join(",") };
	}

	//#endregion
}
