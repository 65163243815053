import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'di-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements AfterViewInit {
  @ViewChild('canvas', { static: true }) canvasEl: ElementRef<HTMLCanvasElement>;
  private signaturePad: SignaturePad;

  @Input() set image(dataUrl: string | null) {
    if (dataUrl) {
      this.loadImage(dataUrl);
    }
  }

  @Input() readOnly: boolean = false;

  @Input() options?: { penColor?: string, backgroundColor?: string };

  // ngOnInit(): void {
  //   const canvas = this.canvasEl.nativeElement;
  //   this.signaturePad = new SignaturePad(canvas);

  //   window.addEventListener('resize', this.resizeCanvas.bind(this));
  //   this.readOnly ? this.signaturePad.off() : this.signaturePad.on();
  //   this.resizeCanvas();
  // }

  public ngAfterViewInit(): void {
    const canvas = this.canvasEl.nativeElement;
    this.signaturePad = new SignaturePad(
      canvas,
      { ...this.options }
    );

    window.addEventListener('resize', this.resizeCanvas.bind(this));
    this.readOnly ? this.signaturePad.off() : this.signaturePad.on();
    this.resizeCanvas();
    this.signaturePad.clear();
  }

  private resizeCanvas(): void {
    const canvas = this.canvasEl.nativeElement;
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d')?.scale(ratio, ratio);
  }

  clear(): void {
    this.signaturePad.clear();
  }

  save(): string {
    if (this.signaturePad.isEmpty()) {
      return '';
    }
    return this.signaturePad.toDataURL('image/png');
  }

  private loadImage(dataUrl: string): void {
    const image = new Image();
    image.src = dataUrl;
    image.onload = () => {
      this.signaturePad.fromDataURL(dataUrl);
    };
  }
}