import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalComponentBase } from '@osapp/modules/modal';
import { PlatformService } from '@osapp/services/platform.service';

export type CancelSeanceResponse = {
	hasToStopTreatment: boolean,
	explanation: string
};

@Component({
	templateUrl: './cancel-seance-modal.component.html',
	styleUrls: ['./cancel-seance-modal.component.scss']
})
export class CancelSeanceModalComponent extends ModalComponentBase<CancelSeanceResponse> {

	//#region PROPERTIES

	public hasToStopTreatment = false;
	public explanation: string;

	//#endregion

	//#region METHODS

	constructor(poModalCtrl: ModalController, psvcPlatform: PlatformService) {
		super(poModalCtrl, psvcPlatform);
	}

	public validate(): void {
		const loResponse: CancelSeanceResponse = {
			hasToStopTreatment: this.hasToStopTreatment,
			explanation: this.explanation
		};

		this.close(loResponse);
	}

	//#endregion
}