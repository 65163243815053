import { Component, Input, OnInit } from "@angular/core";
import { DestroyableComponentBase } from "@osapp/modules/utils/components/destroyable-component-base";
import { StoredSeance } from "apps/idl/src/anakin/models/StoredSeance";
import { takeUntil } from "rxjs/operators";
import { DeviceService } from "../../services/device.service";

@Component({
	selector: "di-patient-indisponible",
	templateUrl: "./patient-indisponible.component.html",
	styleUrls: ["./patient-indisponible.component.scss"],
})
export class PatientIndisponibleComponent
	extends DestroyableComponentBase
	implements OnInit
{
	@Input() confirm: (commentaire :string) => void;
	@Input() deletePatientIndispo: () => void;
	@Input() cancel: () => void;
	@Input() seance: StoredSeance;
	public isMobileView: boolean = false;
	commentaire: string = "";
	modeEdit = false;

	constructor(
		private svcDevice: DeviceService,
	) {
		super();
	}

	ngOnInit(): void {
		this.commentaire = this.seance.commentaireIndisponibilitePatient?? '';
		this.modeEdit = !!this.seance.commentaireIndisponibilitePatient;
		this.svcDevice.isMobile$
			.pipe(takeUntil(this.destroyed$))
			.subscribe((flag: boolean) => {
				this.isMobileView = flag;
			});
	}

	onActesNonRealisesClick() {
		if(this.confirm)
			this.confirm(this.commentaire);
	}
	onAnnulerPatientNonDisponible() {
		if(this.deletePatientIndispo)
			this.deletePatientIndispo();
	}

	onCancel() {
		if(this.cancel)
			this.cancel();
	}
}
