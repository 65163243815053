<div [ngSwitch]="templateId">

	<ng-template [ngSwitchCase]="'expandable'">
		<div class="user-part">
			<ion-item-divider [color]="sectionInfo.color" (click)="toggleExtend()" slot="end">
				<avatar *ngIf="userAvatar" [src]="userAvatar"></avatar>
				<ion-label>{{ sectionInfo.label }}</ion-label>
				<ion-icon *ngIf="isClosed && sectionInfo.isClosable" name="caret-forward-circle-outline"></ion-icon>
				<ion-icon *ngIf="!isClosed && sectionInfo.isClosable" name="caret-down-circle-outline"></ion-icon>
			</ion-item-divider>
			<ng-template [ngIf]="!isClosed || !sectionInfo.isClosable">
				<ion-menu-toggle auto-hide="false">
					<calao-link menuClose *ngFor="let link of sectionInfo.links" [linkInfo]="link"></calao-link>
				</ion-menu-toggle>
			</ng-template>
		</div>
	</ng-template>

	<ng-template [ngSwitchCase]="'invisible'">
		<calao-link menuClose *ngFor="let link of sectionInfo.links" [linkInfo]="link"></calao-link>
	</ng-template>

	<ng-template [ngSwitchCase]="'avatar'">
		<div class="menu-part">
			<ion-item-divider>
				<!-- TODO : Supprimer 'params' / ajouter 'params' dans l'interface/classe ? https://dev.azure.com/calaosoft/osapp-project/_workitems/edit/2374 -->
				<ion-avatar slot="start" *ngIf="sectionInfo.params?.src">
					<img [src]="sectionInfo.params.src">
				</ion-avatar>
				<ion-avatar slot="start" *ngIf="sectionInfo.params?.icon">
					<ion-icon [name]="sectionInfo.params.icon"></ion-icon>
				</ion-avatar>
				<div *ngIf="sectionInfo.params?.iconClass">
					<i [ngClass]="sectionInfo.params?.src"></i>
				</div>
				<ion-label>{{sectionInfo.label}}</ion-label>
			</ion-item-divider>
			<ion-menu-toggle auto-hide="false">
				<calao-link menuClose *ngFor="let link of sectionInfo.links" [linkInfo]="link"></calao-link>
			</ion-menu-toggle>
		</div>
	</ng-template>

	<ng-template *ngSwitchDefault>Pas de template nommée {{templateId}}</ng-template>

</div>