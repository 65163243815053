import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ArrayHelper, DateHelper, StringHelper } from '@osapp/helpers';
import { ModalComponentBase } from '@osapp/modules/modal';
import { ShowMessageParamsToast } from '@osapp/services/interfaces/ShowMessageParamsToast';
import { PlatformService } from '@osapp/services/platform.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { C_PREFIX_AMCP, C_PREFIX_AMOP } from '../../../../../app/app.constants';
import { AMCP } from '../../../model/amc-p';
import { AMOP } from '../../../model/amo-p';
import { ECouvertureType } from '../../../model/ecouverture-type.enum';
import { EUpdateMode } from '../../../model/eupdate-mode.enum';
import { IAMC } from '../../../model/iamc';
import { IAMO } from '../../../model/iamo';
import { ICouvertureP } from '../../../model/icouverture-p';
import { IUpdateHistory } from '../../../model/iupdate-history';
import { CouverturesService } from '../../../services/couvertures.service';

@Component({
	selector: 'idl-couverture-modal',
	templateUrl: './couverture-modal.component.html',
	styleUrls: ['./couverture-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouvertureModalComponent extends ModalComponentBase<any> implements OnInit {

	//#region PROPERTIES

	@Input() public couverture: ICouvertureP;
	@Input() public couvertures: ICouvertureP[];
	@Input() public couvertureAMO : IAMO[];

	private msCouvertureType: ECouvertureType;
	public get couvertureType(): ECouvertureType { return this.msCouvertureType; }

	private maEtablissements: (IAMO | IAMC)[];
	public get etablissements(): (IAMO | IAMC)[] { return this.maEtablissements; }

	private msTitle: string;
	public get title(): string { return this.msTitle; }

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcCouvertures: CouverturesService,
		private readonly isvcUiMessage: UiMessageService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetectorRef);
	}

	public ngOnInit(): void {
		super.ngOnInit();
		if (this.couverture?._id.startsWith(C_PREFIX_AMOP)) {
			this.msCouvertureType = ECouvertureType.AMOP;
			this.msTitle = "AMO";
		}
		else {
			this.msCouvertureType = ECouvertureType.AMCP;
			this.msTitle = "AMC";
		}
		this.isvcCouvertures.getEtablissements(this.couvertureType)
			.pipe(
				tap((paEtablissements: (IAMO | IAMC)[]) => {
					this.maEtablissements = paEtablissements;
					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public submit(): Promise<any> {
		let lsErrorMessage: string = '';
		if (this.couverture?._id.startsWith(C_PREFIX_AMOP) && StringHelper.isBlank((this.couverture as AMOP).amoId))
			lsErrorMessage = "Vous devez sélectionner une CPAM.";
		if (this.couverture?._id.startsWith(C_PREFIX_AMCP)) {
			const amcp = this.couverture as AMCP;
			if (StringHelper.isBlank(amcp.amcId))
					lsErrorMessage = "Vous devez sélectionner une mutuelle.";
			if(amcp.gestionUnique  && this.couvertureAMO.length == 0)
				lsErrorMessage = "Vous devez avoir une couverture AMO pour valider une AMC en gestion unique.";
			if (StringHelper.isBlank(amcp.mutnum) && amcp.gestionUnique)
					lsErrorMessage += (!StringHelper.isBlank(lsErrorMessage) ? " et " : "Vous devez ") + "remplir un mutnum.";
    }
		if (!this.areDatesValid())
			lsErrorMessage = "Les dates saisies chevauchent les dates d'une autre couverture.";

		if (lsErrorMessage) {
			return this.isvcUiMessage.showAsyncMessage(new ShowMessageParamsToast({
				header: "Validation impossible !",
				message: lsErrorMessage
			})).toPromise();
		}

		return this.close();
	}

	/** @override */
	public close(): Promise<boolean> {
		const loUpdateHistory: IUpdateHistory = {
			date: this.couverture.updateDate = new Date(),
			mode: this.couverture.updateMode = EUpdateMode.manual
		};
		ArrayHelper.hasElements(this.couverture.updateHistory) ? this.couverture.updateHistory.unshift(loUpdateHistory) : this.couverture.updateHistory = [loUpdateHistory];


		return this.isvcCouvertures.saveCouverture(this.couverture)
			.pipe(mergeMap(_ => super.close()))
			.toPromise();
	}

	private areDatesValid(): boolean {
		if (!ArrayHelper.hasElements(this.couvertures))
			return true;

		const ldCurrentDate = new Date();

		return this.couvertures.some((poCouverture: ICouvertureP) =>
			poCouverture._id === this.couverture._id ||
			(!DateHelper.isBetweenTwoDates(this.couverture.dateDebut, poCouverture.dateDebut ?? ldCurrentDate, poCouverture.dateFin ?? ldCurrentDate) &&
				!DateHelper.isBetweenTwoDates(this.couverture.dateFin, poCouverture.dateDebut ?? ldCurrentDate, poCouverture.dateFin ?? ldCurrentDate))
		);
	}

	//#endregion

}
