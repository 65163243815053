import { Subscription } from 'rxjs';
import { IdHelper } from '../../helpers';
import { EPrefix, IFlag, ITaskParams } from '../../model';

export class TaskDescriptor<T extends ITaskParams = ITaskParams> {

	//#region FIELDS

	private static readonly C_INTERVAL_MULTIPLICATOR = 1.3;

	//#endregion

	//#region PROPERTIES

	/** Identifiant du document pour la sérialisation. */
	public _id: string;
	/** Révision du document. */
	public _rev?: string;

	//#endregion

	//#region METHODS

	public constructor(
		/** Id de la tâche. */
		public id: string,
		/** Nom de la tâche. */
		public name: string,
		/** Type de la tâche à exécuter. */
		public taskType: string,
		/** Paramètre de la tâche à exécuter. */
		public params?: T,
		/** Permet de savoir si on doit persister la tâche. */
		public enableTaskPersistance: boolean = false,
		/** Intervalle de temps en milliseconde entre deux exécutions de la tâche, optionnel. */
		public intervalRepetition?: number,
		/** Multiplie la valeur de RetryInterval à chaque échec si non cyclique. */
		public intervalMultiplicator: number = TaskDescriptor.C_INTERVAL_MULTIPLICATOR,
		/** Liste des identifiants de flags et valeurs attendues pour exécuter la tâche, optionnel. */
		public execAfter?: Array<IFlag>,
		/** Intervalle pour rejouer la tâche. */
		public replayInterval?: number,
		/** Date de la dernière exécution de la tâche, optionnel. */
		public lastExec?: Date,
		/** Intervalle de temps en ms avant de réessayer d'exécuter une tâche qui a échoué, 30sec par défaut. */
		public retryInterval?: number,
		/** Souscription à l'execution de la tâche. */
		public subscription?: Subscription) {

		this._id = IdHelper.buildId(EPrefix.task, this.id);
	}

	//#endregion
}