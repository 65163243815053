import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { BooleanHelper } from '@osapp/helpers/boolean.helper';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { EDateTimePickerMode } from '@osapp/model/date/EDateTimePickerMode';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { IDateTimePickerParams } from '@osapp/model/date/IDateTimePickerParams';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { AMCP } from '../../../model/amc-p';
import { ETypeConvention } from '../../../model/etype-convention.enum';
import { ETypePieceJustificative } from '../../../model/etype-piece-justificative.enum';
import { EUpdateMode } from '../../../model/eupdate-mode.enum';
import { IAMC } from '../../../model/iamc';

@Component({
	selector: 'idl-couverture-amc-p',
	templateUrl: './couverture-amc-p.component.html',
	styleUrls: ['./couverture-amc-p.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouvertureAMCPComponent extends ComponentBase {

	//#region PROPERTIES

	private moAmcP: AMCP;
	public get amcP(): AMCP { return this.moAmcP; }
	@Input() public set amcP(poAmcP: AMCP) {
		if (poAmcP !== this.moAmcP) {
			this.moAmcP = poAmcP;
			this.moAmcP.tp = BooleanHelper.convertToBoolean(this.moAmcP.tp);
			this.isADRiCalled = this.moAmcP.updateMode === EUpdateMode.ADRi;
		}
	}

	private maEtablissements: IAMC[];
	public get etablissements(): IAMC[] { return this.maEtablissements; }
	@Input() public set etablissements(paEtablissements: IAMC[]) {
		if (paEtablissements !== this.maEtablissements) {
			this.maEtablissements = paEtablissements;
			this.filteredEtablissements = paEtablissements;
			if (this.amcP?.amcId)
				this.etablissementName = this.maEtablissements.find((poEtablissement: IAMC) => poEtablissement._id === this.amcP.amcId)?.label;
			this.detectChanges();
		}
	}

	public filteredEtablissements: IAMC[];
	public etablissementName: string;
	public isADRiCalled: boolean;

	public readonly datePickerParams: IDateTimePickerParams = DateHelper.datePickerParamsFactory(ETimetablePattern.dd_MM_yyyy_slash, EDateTimePickerMode.date);
	public readonly TPOptions: ReadonlyArray<ISelectOption<boolean>> = [
		{ label: "Oui, je pratique le tiers payant", value: true },
		{ label: "Non, je ne pratique pas le tiers payant", value: false }
	];
	public readonly pieceJustificativeOptions: ReadonlyArray<ISelectOption<ETypePieceJustificative>> = [
		{ label: "Aucune pièce", value: ETypePieceJustificative.aucunePièce },
		{ label: "Attestation de droits", value: ETypePieceJustificative.attestationDeDroits },
		{ label: "Carte d'assuré social", value: ETypePieceJustificative.carteAssureSocial }
	];
	public readonly conventionOptions: ReadonlyArray<ISelectOption<ETypeConvention>> = [];


	//#endregion

	//#region METHODS

	constructor(
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poChangeDetectorRef);
	}

	public updateFilteredEtablissements() {
		delete this.amcP.amcId;
		this.filteredEtablissements = this.maEtablissements.filter((poEtablissement: IAMC) => {
			return poEtablissement.label.toLowerCase().includes(this.etablissementName.toLowerCase()) ||
				poEtablissement._id.replace("-", "").includes(this.etablissementName);
		});
		this.detectChanges();
	}

	public getFormatedId(poEtablissement: IAMC): string {
		return poEtablissement._id.split("_")[1].split("-").join(" ");
	}

	public onOptionClick(poOption: IAMC): void {
		this.amcP.amcId = poOption._id;
		this.amcP.mutnum = ArrayHelper.getFirstElement(poOption.mutnum);
		this.detectChanges();
	}

	public onDateDebutChanged(pdDate: Date): void {
		this.amcP.dateDebut = DateHelper.resetDay(pdDate);

		if (DateHelper.compareTwoDates(this.amcP.dateDebut, this.amcP.dateFin) > 0)
			this.onDateFinChanged(new Date(this.amcP.dateDebut));
	}

	public onDateFinChanged(pdDate: Date): void {
		this.amcP.dateFin = DateHelper.fillDay(pdDate);

		if (DateHelper.compareTwoDates(this.amcP.dateDebut, this.amcP.dateFin) > 0)
			this.onDateDebutChanged(new Date(this.amcP.dateFin));
	}

	public getManualCreationDate(): Date {
		if (this.amcP.updateMode === EUpdateMode.manual && this.amcP._rev)
			return this.amcP.updateDate;
		return undefined;
	}

	//#endregion

}
