import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { IonItemSliding } from '@ionic/angular';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { IContact } from '@osapp/model/contacts/IContact';
import { EAvatarSize } from '@osapp/model/picture/EAvatarSize';
import { IAvatar } from '@osapp/model/picture/IAvatar';
import { IAvatarsParams } from '@osapp/modules/avatar/models/IAvatarsParams';
import { Loader } from '@osapp/modules/loading/Loader';
import { ModalService } from '@osapp/modules/modal/services/modal.service';
import { IHasPermission, Permissions, PermissionsService } from '@osapp/modules/permissions/services/permissions.service';
import { PrestationService } from '@osapp/modules/prestation/services/prestation.service';
import { ContactsService } from '@osapp/services/contacts.service';
import { ShowMessageParamsToast } from '@osapp/services/interfaces/ShowMessageParamsToast';
import { LoadingService } from '@osapp/services/loading.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { defer } from 'rxjs';
import { finalize, mergeMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { C_DESMOS_PERMISSION_ID } from '../../../../app/app.constants';
import { Traitement } from '../../../../model/Traitement';
import { IExportPrestationResult } from '../../../patients/model/IExportPrestationResult';
import { ExportService } from '../../../patients/services/export.service';
import { IdlPrestation } from '../../models/idl-prestation';
import { FacturationModalComponent } from '../facturation-modal/facturation-modal.component';
import { TraitementPrestationsModalComponent } from '../traitement-prestations-modal/traitement-prestations-modal.component';

@Component({
	selector: 'idl-prestations-item',
	templateUrl: './prestations-item.component.html',
	styleUrls: ['./prestations-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrestationsItemComponent extends ComponentBase implements IHasPermission {

	//#region PROPERTIES

	@Input() public prestations: IdlPrestation[];
	@Input() public traitement: Traitement;
	@Input() public nbOrdonnances = 0;
	@Input() public intervenants: IContact[];

	@Permissions("create", C_DESMOS_PERMISSION_ID)
	public get canBillToDesmos(): boolean {
		return true;
	}

	public get canBillToFsv(): boolean {
		return true;
	}

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcExport: ExportService,
		private readonly isvcModal: ModalService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcLoading: LoadingService,
		public readonly isvcPermissions: PermissionsService,
		poChangeDetectorRef: ChangeDetectorRef,
	) {
		super(poChangeDetectorRef);
	}

	public openPrestations(poTraitement: Traitement, paPrestations: IdlPrestation[]): void {
		if (!poTraitement) {
			this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: "Le traitement a été supprimé." }));
		}
		else {
			this.isvcModal.open({
				component: TraitementPrestationsModalComponent,
				componentProps: {
					traitement: poTraitement,
					prestations: paPrestations,
				}
			})
				.pipe(
					tap(() => this.detectChanges()),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}

	}

	public billPrestations(paPrestations: IdlPrestation[]): void {
		if (!this.traitement) {
			this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: "Le traitement a été supprimé." }));
		} else {
			this.isvcModal.open({
				component: FacturationModalComponent,
				componentProps: {
					patientId: this.traitement.patientId,
					prestations: PrestationService.getBillablePrestations(paPrestations)
				}
			})
				.pipe(
					tap(() => this.detectChanges()),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
	}

	/** Envoie les prestations à Desmos  */
	public sendToDesmos(paPrestations: IdlPrestation[]): void {
		const lfGetLoaderMessage: (pnCurrent: number) => string = (pnCurrent: number) => `Export de la facture n° ${pnCurrent} sur ${paPrestations.length} ...`;
		let lnCurrentExportCount = 1;
		let loLoader: Loader;

		defer(() => this.isvcLoading.create(lfGetLoaderMessage(lnCurrentExportCount)))
			.pipe(
				mergeMap((poLoader: Loader) => poLoader.present()),
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap(_ => this.isvcExport.exportPrestations(PrestationService.getBillablePrestations(paPrestations))),
				tap((poResult: IExportPrestationResult) => {
					if (++lnCurrentExportCount < poResult.totalCount)
						loLoader.text = lfGetLoaderMessage(++lnCurrentExportCount);
				}),
				toArray(),
				tap((paResults: IExportPrestationResult[]) => this.showSendingResultMessage(ArrayHelper.getLastElement(paResults), paPrestations)),
				tap(() => {
					loLoader.dismiss();
				},
					_ => loLoader.dismiss()
				),
				finalize(() => loLoader?.dismiss()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Affiche un message informant l'utilisateur du nombre de prestations transmises et non transmises. */
	private showSendingResultMessage(poResult: IExportPrestationResult, paPrestations: IdlPrestation[]): void {
		const lnExportedPrestationsCount: number = poResult.successIds.length;
		const lsExportedPlurial: string = lnExportedPrestationsCount > 1 ? "s" : "";
		const loToastParams = new ShowMessageParamsToast({ message: `${lnExportedPrestationsCount} prestation${lsExportedPlurial} transmise${lsExportedPlurial}.` });

		if (lnExportedPrestationsCount < poResult.totalCount) { // Si le nombre de succès est inférieur au nombre total, il y a eu des erreurs.
			const lnNotExportedPrestationsCount: number = poResult.failIds.length;
			const lsNotExportedPlurial: string = lnNotExportedPrestationsCount > 1 ? "s" : "";

			loToastParams.message += `\n<span style="color:red">${lnNotExportedPrestationsCount} prestation${lsNotExportedPlurial} non transmise${lsNotExportedPlurial}.</span>`;
		}

		if (ArrayHelper.hasElements(poResult.successIds)) {
			ArrayHelper.removeElementsByFinder(paPrestations, (poPrestation: IdlPrestation) => poResult.successIds.includes(poPrestation._id));
		}

		this.isvcUiMessage.showMessage(loToastParams);
	}

	public getPrestationsEmptyOrdonnances(paPrestations: IdlPrestation[]): number {
		return paPrestations.filter((poPrestation: IdlPrestation) => StringHelper.isBlank(poPrestation.originalLines[0].ordonnanceId)).length;
	}

	public getPrestationsTotalPrice(paPrestations: IdlPrestation[]): number {
		return paPrestations.map((poPrestation: IdlPrestation) => poPrestation.total).reduce((pnPreviousPrice: number, pnCurrentPrice: number) => pnPreviousPrice + pnCurrentPrice, 0);
	}

	public getBillablePrestations(paPrestations: IdlPrestation[]): IdlPrestation[] {
		return PrestationService.getBillablePrestations(paPrestations) as IdlPrestation[];
	}

	/** Retourne un objet contenant les paramètres du composant `calao-avatars`.
	 * @param paPrestations Tableau des prestations à partir duquel construire et retourner les paramètres.
	 */
	public getAvatarsParams(paPrestations: IdlPrestation[]): IAvatarsParams {
		const laIntervenantsIds: string[] = ArrayHelper.unique(paPrestations.map((poPrestation: IdlPrestation) => poPrestation.vendorId));
		const loAvatar: IAvatar = this.getContactAvatar(ArrayHelper.getFirstElement(this.intervenants));
		const lnAvatarsCount: number = laIntervenantsIds.length;

		return {
			avatar: loAvatar,
			avatarsCount: loAvatar ? lnAvatarsCount : 0
		} as IAvatarsParams;
	}

	public getContactAvatar(poContact: IContact): IAvatar {
		return ContactsService.createContactAvatar(poContact, EAvatarSize.big);
	}

	/** Ouvre ou ferme un itemSliding en fonction de l'état avant slide.\
	 * On peut également stopper la propagation de l'événement de clic.
	 * @param poItemSliding Objet d'options qu'on veut ouvrir ou fermer (animation de swipe).
	 * @param poEvent Événement de clic à stopper si renseigné.
	 */
	public async openOrCloseItemSliding(poItemSliding: IonItemSliding, poEvent?: MouseEvent): Promise<void> {
		if (poEvent)
			poEvent.stopPropagation();	// Empêche la possible navigation vers l'item cliqué.

		// Si l'item est ouvert, la valeur est strictement supérieure à 0, sinon c'est que l'item est fermé.
		const lnAmountOpenPixels: number = await poItemSliding.getOpenAmount();

		if (lnAmountOpenPixels > 0) // Item ouvert, on veut le fermer
			poItemSliding.close();
		else // Item fermé, on veut l'ouvrir.
			poItemSliding.open("end");
	}

	//#endregion METHODS

}
