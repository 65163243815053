import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IdHelper, StoreDocumentHelper, StringHelper } from '@osapp/helpers';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { IDateTimePickerParams } from '@osapp/model/date/IDateTimePickerParams';
import { C_CONSTANTES_PREFIX, C_INJECTIONS_PREFIX } from 'apps/idl/src/app/app.constants';
import { defer, Observable, of, Subject } from 'rxjs';
import { auditTime, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { IConstantes, IOther } from '../../../model/constantes/IConstantes';
import { ISurveillancesParams } from '../../../model/constantes/IConstantesParams';
import { IInjection, IInjections } from '../../../model/constantes/IInjections';
import { InitConstantesError } from '../../../model/constantes/InitConstantesError';
import { ISurveillances } from '../../../model/constantes/ISurveillances';
import { IPatient } from '../../../model/IPatient';
import { ConstantesService } from '../../../services/constantes.service';

@Component({
	selector: "idl-constantes-details",
	templateUrl: './constantes-details.component.html',
	styleUrls: ['./constantes-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConstantesDetailsComponent extends ComponentBase implements OnInit, OnDestroy, ISurveillancesParams {

	//#region FIELDS

	/** Sujet qui sert à tempérer le nombre d'événements envoyés pour indiquer des changements du modèle. */
	private readonly moModelChangedAuditTimeSubject = new Subject<void>();

	/** Émetteur d'événement lorsque le modèle change. */
	@Output("onModelChanged") private readonly moModelChangedEvent = new EventEmitter<ISurveillances>();

	/** Tableau des objets constantes précédents. */
	private maPreviousSurveillances: ISurveillances[] = [];

	//#endregion

	//#region PROPERTIES

	/** Taille de la colonne pour les labels. */
	public readonly C_LABEL_COL_SIZE = 3;
	/** Taille de la colonne pour les précédentes valeurs. */
	public readonly C_PREVIOUS_VALUE_COL_SIZE = 6;
	/** Taille de la colonne pour les valeurs courantes. */
	public readonly C_CURRENT_VALUE_COL_SIZE = 3;
	/** Pattern d'affichage de la constante précédente. */
	public readonly C_CONSTANTE_DATE_FORMAT: string = ETimetablePattern.dd_MM_yyyy_HH_mm_slash;

	/** Liste des differents types de surveillances */
	public constantesModel: IConstantes;
	public injectionsModel: IInjections;

	private moModel: ISurveillances;
	/** @implements */
	public get model(): ISurveillances {
		return this.moModel;
	}
	@Input() public set model(poValue: ISurveillances) {
		if (!StoreDocumentHelper.areDocumentsEquals(this.moModel, poValue)) {
			this.moModel = poValue;
			this.fillSurveillances(this.moModel);
			this.detectChanges();
		}
	}

	/** @implements */
	@Input() public patient?: IPatient;

	private mbReadOnly = false;
	/** @implements */
	public get readOnly(): boolean {
		return this.mbReadOnly;
	}
	@Input() public set readOnly(pbValue: boolean) {
		if (typeof pbValue === "boolean" && pbValue !== this.mbReadOnly) {
			this.mbReadOnly = pbValue;
			this.detectChanges();
		}
	}

	/** Longueur du tableau contenant les constantes précédentes. */
	public get previousConstantesLength(): number {
		return this.maPreviousSurveillances.length;
	}

	/** Indique si le composant est en train de charger des données. */
	private mbIsLoading: boolean;
	/** Indique si le composant est en train de charger des données. */
	public get isLoading(): boolean {
		return this.mbIsLoading;
	}

	/** Objet constantes précédent à afficher. */
	public previousItem: ISurveillances;
	/** Index courant de l'objet constantes précédent. */
	public previousItemIndex: number;

	public readonly dateTimeParams: IDateTimePickerParams = {
		max: new Date().toISOString()
	};

	//#endregion

	//#region METHODS

	constructor(private isvcConstantes: ConstantesService, poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	public ngOnInit(): void {
		if (!this.model)
			throw new InitConstantesError();

		this.mbIsLoading = true;

		this.initModelChangedEvent().pipe(takeUntil(this.destroyed$)).subscribe();
		this.initPreviousSurveillances().pipe(takeUntil(this.destroyed$)).subscribe();
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moModelChangedAuditTimeSubject.complete();
	}

	/** Initialise le tableau des constantes précédentes et réinitialise en fonction du changement des inputs. */
	private initPreviousSurveillances(): Observable<ISurveillances[]> {
		return defer(() => this.patient ? of(this.patient) : this.isvcConstantes.getPatientBySurveillances(this.constantesModel ?? this.injectionsModel)) // Récupération du patient lié aux constantes.
			.pipe(
				tap((poPatient: IPatient) => this.patient = poPatient),
				mergeMap(_ => this.isvcConstantes.getPreviousSurveillances(this.constantesModel ?? this.injectionsModel, this.patient)), // Récupération des constantes précédentes s'il y en a.
				tap((paResults: ISurveillances[]) => {
					this.maPreviousSurveillances = paResults;

					if (ArrayHelper.hasElements(paResults)) {
						this.previousItem = ArrayHelper.getLastElement(paResults);
						this.previousItemIndex = paResults.length - 1;
						this.preFilledSurveillancesRows(this.previousItem);
					}
					else {
						this.previousItem = undefined;
						this.previousItemIndex = undefined;
					}

					this.mbIsLoading = false;
					this.detectChanges();
				},
					poError => {
						console.error("IDL.CONSTDET.C::", poError);
						this.mbIsLoading = false;
					}
				)
			);
	}

	/** Initialise la levée d'événement après modification du modèle de façon tempérée. */
	private initModelChangedEvent(): Observable<void> {
		return this.moModelChangedAuditTimeSubject.asObservable()
			.pipe(
				auditTime(1000), // Limite le nombre d'événements rapprochés en récupérant la dernière valeur durant un laps de temps défini.
				tap(() => this.moModelChangedEvent.emit(this.constantesModel ?? this.injectionsModel))
			);
	}

	/** Modifie l'objet constantes précédent à afficher ainsi que son index correspondant.
	 * @param pnNewIndex Nouvel index de l'objet constantes précédent.
	 */
	public onPreviousItemIndexChanged(pnNewIndex: number): void {
		if (pnNewIndex >= 0 || pnNewIndex < this.maPreviousSurveillances.length) { // Si l'index est dans le bon intervalle.
			this.previousItem = this.maPreviousSurveillances[pnNewIndex];
			this.previousItemIndex = pnNewIndex;
			this.preFilledSurveillancesRows(this.previousItem);
			this.detectChanges();
		}
	}

	/** Lève un événement pour indiquer que le modèle a changé. */
	public onModelChanged(): void {
		if (this.injectionsModel)
			this.refreshInjectionRows(this.injectionsModel.injections);
		if (this.constantesModel && !this.constantesModel.oxygenSaturation.saturation) {
			delete this.constantesModel.oxygenSaturation.oxygenQuantity;
			this.refreshOthersConstantesRows(this.constantesModel.others);
		}
		this.moModelChangedAuditTimeSubject.next();
	}

	/** Lève un événement pour indiquer que la date a changé.
	 * @param pdDate La nouvelle date.
	 */
	public onDateChanged(pdDate: Date): void {
		(this.constantesModel ?? this.injectionsModel).createdDate = new Date(pdDate);
		this.moModelChangedAuditTimeSubject.next();
	}

	/** Gère les lignes des injections.
	 * @param paInjections Liste des injections.
	 */
	private refreshInjectionRows(paInjections: IInjection[]): void {
		const loEmptyInjection: IInjection = { label: "", quantity: "" };
		if (ArrayHelper.getLastElement(paInjections)?.label === "" && ArrayHelper.getLastElement(paInjections)?.quantity === "")
			this.injectionsModel.injections.pop();
		if (!ArrayHelper.hasElements(this.injectionsModel.injections))
			this.injectionsModel.injections = [];
		if (ArrayHelper.getLastElement(paInjections)?.label !== "" || ArrayHelper.getLastElement(paInjections)?.quantity !== "")
			this.injectionsModel.injections.push(loEmptyInjection);
	}

	/** Gère les lignes des 'autres' constantes.
	 * @param paOthersConstantes Liste des 'autres' constantes.
	 */
	private refreshOthersConstantesRows(paOthersConstantes: IOther[]): void {
		const loEmptyOtherConstante: IOther = { label: "", value: "" };
		if (ArrayHelper.getLastElement(paOthersConstantes)?.label === "" && ArrayHelper.getLastElement(paOthersConstantes)?.value === "")
			this.constantesModel.others.pop();
		if (!ArrayHelper.hasElements(this.constantesModel.others))
			this.constantesModel.others = [];
		if (ArrayHelper.getLastElement(paOthersConstantes)?.label !== "" || ArrayHelper.getLastElement(paOthersConstantes)?.value !== "")
			this.constantesModel.others.push(loEmptyOtherConstante);
	}

	/** Pré-rempli les lignes des injections en fonction des dernières injections.
	 * @param poPreviousSurveillances Injections précédentes.
	 */
	private preFilledSurveillancesRows(poPreviousSurveillances: ISurveillances): void {
		if (this.injectionsModel) {
			if (this.injectionsModel.injections.some((poInjection: IInjection) => !StringHelper.isBlank(poInjection.quantity))) {
				let laInjectionsToKeep: IInjection[] = [];
				this.injectionsModel.injections.forEach((poInjection: IInjection) => {
					if (!StringHelper.isBlank(poInjection.quantity))
						laInjectionsToKeep.push(poInjection);
				});
				this.injectionsModel.injections = laInjectionsToKeep;
			}
			else
				this.injectionsModel.injections = [];
			(poPreviousSurveillances as IInjections).injections.forEach((poInjectionToAdd: IInjection) => {
				if (!this.injectionsModel.injections.some((poInjection: IInjection) => poInjection.label === poInjectionToAdd.label))
					this.injectionsModel.injections.push({ label: poInjectionToAdd.label, quantity: "" })
			});
			this.refreshInjectionRows(this.injectionsModel.injections)
		}
		else if (this.constantesModel) {
			if (this.constantesModel.others.some((poOther: IOther) => !StringHelper.isBlank(poOther.value))) {
				let laOthersToKeep: IOther[] = [];
				this.constantesModel.others.forEach((poOther: IOther) => {
					if (!StringHelper.isBlank(poOther.value))
						laOthersToKeep.push(poOther);
				});
				this.constantesModel.others = laOthersToKeep;
			}
			else
				this.constantesModel.others = [];
			(poPreviousSurveillances as IConstantes).others.forEach((poOtherToAdd: IOther) => {
				if (!this.constantesModel.others.some((poOther: IOther) => poOther.label === poOtherToAdd.label))
					this.constantesModel.others.push({ label: poOtherToAdd.label, value: "" })
			});
			this.refreshOthersConstantesRows(this.constantesModel.others)
		};
	}

	public getPreviousOtherConstanteValue(psOtherConstanteLabel: string): string {
		if (ArrayHelper.hasElements((this.previousItem as IConstantes)?.others)) {
			const loPreviousOtherConstante: IOther = (this.previousItem as IConstantes)?.others.find((poOtherConstante: IOther) => poOtherConstante.label === psOtherConstanteLabel);

			if (!ObjectHelper.isNullOrEmpty(loPreviousOtherConstante))
				return loPreviousOtherConstante.value;
		};

		return "";
	}

	public getPreviousInjectionQuantity(psInjectionLabel: string): string {
		if (ArrayHelper.hasElements((this.previousItem as IInjections)?.injections)) {
			const loPreviousInjection: IInjection = (this.previousItem as IInjections)?.injections.find((poInjection: IInjection) => poInjection.label === psInjectionLabel);

			if (!ObjectHelper.isNullOrEmpty(loPreviousInjection))
				return loPreviousInjection.quantity;
		};

		return "";
	}

	private fillSurveillances(poModel: ISurveillances): void {
		this.resetModels();

		switch (IdHelper.getPrefixFromId(poModel._id)) {
			case C_CONSTANTES_PREFIX:
				this.constantesModel = poModel;
				this.constantesModel.oxygenSaturation = ObjectHelper.isNullOrEmpty(this.constantesModel.oxygenSaturation) ? {} : this.constantesModel.oxygenSaturation;
				this.refreshOthersConstantesRows(this.constantesModel.others);
				break;

			case C_INJECTIONS_PREFIX:
				this.injectionsModel = poModel;
				this.refreshInjectionRows(this.injectionsModel.injections);
				break;
		};
	}

	private resetModels(): void {
		this.constantesModel = undefined;
		this.injectionsModel = undefined;
	}

	//#endregion

}