<ion-item *ngIf="!isListMode && !params.divider && (fieldValue || params.forceDisplaying)"
	style="--background: transparent;" lines="none" [class]="params.cssClass? 'id-client' : 'ion-no-padding just-flex'">
	<ion-label *ngIf="to.label" style="text-align: left;"
		[class]="params.labelCssClass ? params.labelCssClass : 'label-group txt10px margin-b min-w width50pc ion-text-wrap'"
		position="stacked">
		{{ to.label }}
	</ion-label>
	<ion-icon *ngIf="to.icon" class="margelect" [name]="to.icon"></ion-icon>
	<div *ngIf="fieldValue" class="just-flex marg-infos">
		<a *ngIf="isPhone" href="tel:{{ fieldValue }}">{{ fieldValue }}</a>
		<a *ngIf="isMail" href="mailto:{{ fieldValue }}">{{ fieldValue }}</a>
		<span *ngIf="!isPhone && !isMail" style="white-space: pre-wrap;" [class]="to.className">{{fieldValue}}</span>
	</div>
</ion-item>

<!-- Label avec divider -->
<ion-item *ngIf="!isListMode && params.divider && (fieldValue || params.forceDisplaying)"
	style="--background: transparent;" lines="none" [class]="params.cssClass? 'id-client' : 'ion-no-padding just-flex'">
	<div class="label-with-divider">
		<div class="label">
			<ion-label *ngIf="to.label" style="text-align: left;"
				[class]="params.labelCssClass ? params.labelCssClass : 'label-group txt10px margin-b min-w width50pc ion-text-wrap'"
				position="stacked">
				{{ to.label }}
			</ion-label>
		</div>
		<div class="divider"></div>
	</div>
	<ion-icon *ngIf="to.icon" class="margelect" [name]="to.icon"></ion-icon>
	<div *ngIf="fieldValue" class="just-flex marg-infos">
		<a *ngIf="isPhone" href="tel:{{ fieldValue }}">{{ fieldValue }}</a>
		<a *ngIf="isMail" href="mailto:{{ fieldValue }}">{{ fieldValue }}</a>
		<span *ngIf="!isPhone && !isMail" style="white-space: pre-wrap;" [class]="to.className">{{fieldValue}}</span>
	</div>
</ion-item>

<div *ngIf="isListMode" class="dis-flex">
	<div *ngIf="to.label" class="txt10px min-w width50pc">{{to.label}}</div>
	<div *ngFor="let fieldValue of fieldValue" [class]="cssClass">
		<span>{{fieldValue}}</span>
	</div>
</div>