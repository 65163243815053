<div class="marg-container boxes">

	<!-- Header (tabs) -->
	<div class="ctn-header-majo dis-flex flex-col">
		<!-- Menu -->
		<div class="ctn-menu-majo">
			<div class="txt-label tabs">
				<ng-container *ngFor="let majoDetail of majorationsDetails">
					<a [class]="'tab-item ' + majoDetail.color"
						[ngClass]="{'active': currentMajorationDetails?.valueType === majoDetail.valueType}"
						(click)="changeCurrentMajoration(majoDetail.valueType)">
						{{ majoDetail.tabLabel || majoDetail.label }}</a>
				</ng-container>
			</div>
		</div>
		<!-- Titre -->
		<div [ngClass]="currentMajorationDetails?.cssClass" class="ctn-titre-majo dis-flex flx-grow1 row-center">
			<div class="dis-flex">
				<ion-item lines="none" (click)="onAllMajorationsChanged()">
					<ion-checkbox class="disable-pointer" [checked]="isAllSelected"></ion-checkbox>
					<ion-label class="dis-flex white row-center">{{ currentMajorationDetails.valueType === 'Dim' ?
						'TOUS LES DIM/JF' : 'TOUTES' }}</ion-label>
				</ion-item>
			</div>

			<div class="ctn-libelle-majo flx-grow1">
				<label>{{currentMajorationDetails?.fullLabel}}</label>
			</div>
		</div>
	</div>

	<!-- Liste des séances majorations -->
	<section *ngIf="virtualScrollItems?.length > 0" class="list list-seances-majo">
		<calao-virtual-scroll #virtualscroll [items]="virtualScrollItems" itemSize="50" class="list-seances-majo-item">
			<ng-template let-virtualScrollItem="$implicit">
				<div *ngIf="isDate(virtualScrollItem); else seance" class="item item-divider ctn-date-seance">
					<span>{{ virtualScrollItem }}</span>
				</div>

				<ng-template #seance>
					<div class="ctn-seance"
						(click)="(!virtualScrollItem.isProtected || canEditMajorations) && canEditMajoration(virtualScrollItem, currentMajorationDetails.valueType) ? onMajorationChanged(virtualScrollItem) : undefined">
						<ion-item>
							<ion-checkbox class="disable-pointer"
								[checked]="virtualScrollItem.seance.hasMajoration(currentMajorationDetails.valueType)"
								[disabled]="virtualScrollItem.isProtected && !canEditMajorations || !canEditMajoration(virtualScrollItem, currentMajorationDetails.valueType)">
							</ion-checkbox>
							<ion-label class="info">Séance de {{ virtualScrollItem.startDate | dateWithLocale: 'fr-FR' :
								'HH:mm'}} {{ virtualScrollItem.statusLabel ? ("(" + virtualScrollItem.statusLabel + ")") : "" }}
							</ion-label>

							<!-- Liste des pastilles des majorations -->
							<ul class="ul-puces-majo">
								<ng-container *ngFor="let majoDetail of majorationsDetails">
									<li class="li-puce-majo"
										[ngClass]="virtualScrollItem.seance.hasMajoration(majoDetail.valueType) ? majoDetail.color : 'grey'">
									</li>
								</ng-container>
							</ul>
						</ion-item>
					</div>
				</ng-template>
			</ng-template>
		</calao-virtual-scroll>
	</section>
</div>