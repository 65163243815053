<osapp-header-modal title="Feuilles de soins papier"></osapp-header-modal>

<ion-content>
	<div *ngFor="let item of fspListByInvoice | keyvalue" class="main-ctn">
		<div class="info">
			<ion-label>
				Facture n°{{ item.key.invoiceNumber }}
			</ion-label>
		</div>

		<div *ngFor="let fsp of item.value" class="fsp">
			<div class="fsp-detail">
				<ion-label>{{ fsp.name }}</ion-label>

			</div>
			<div class="btns">
				<ion-icon *ngIf="canPreview" (click)="goToPdf(fsp)" color="osapp-element" name="see-document">
				</ion-icon>
				<ion-icon (click)="onDownloadPdfClicked(item.key, fsp)" color="osapp-element" name="download">
				</ion-icon>
			</div>
		</div>
	</div>
</ion-content>