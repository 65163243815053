<div class="panneau-patient-prise-en-charge__container">
	<div class="container__content">
		<form [formGroup]="priseEnChargeForm" (ngSubmit)="handleSubmit($event)">
			<div class="content__form-inputs">
				<div class="form-inputs">
					<p>
						La prise en charge spécifique du patient, influe les actes proposés
						et le conditions de remboursement.
					</p>
					<h2>ALD</h2>
					<div class="form-inputs__ald">
						<p>Affection de longue durée</p>
						<lua-data-picker
							[options]="optionsAld"
							formControlName="ald"
						></lua-data-picker>
					</div>
					<h2 class="content__subtitle">Prise en charge</h2>
					<lua-data-picker
						[options]="optionsPriseEnCharge"
						formControlName="priseEnCharge"
						[multiSelection]="true"
						direction="vertical"
					></lua-data-picker>
				</div>
			</div>
			<div class="content__form-buttons">
				<div class="form-buttons__buttons">
					<lua-action-principale
						libelle="Enregistrer"
						[fullWidth]="true"
						(click)="handleSubmit($event)"
						[isDisabled]="priseEnChargeForm.invalid"
					></lua-action-principale>
					<lua-bouton-texte
						[fullWidth]="true"
						libelle="Annuler"
						(click)="goBack()"
					></lua-bouton-texte>
				</div>
			</div>
		</form>
	</div>
</div>
