<div class="panneau-soins-realises">
	<div class="soins-realises-container">
		<form [formGroup]="ikForm" class="form-ik-form">
			<div class="soins-realises-content">
				<div class="info-content">
					<div class="header">
						<h2 class="title">Soins réalisé à</h2>
						<lua-input [(ngModel)]="horairePrecise" formControlName="heure" name="horairePrecise" type="time"
							placeholder="hh:mm" [fullWidth]="true" size="medium"></lua-input>
					</div>
					<div *ngFor="let item of seance?.actes">
						<div class="acte-container">
							<di-acte [acte]="item" (onCheckboxChange)="handleCheckboxChange($event,item)" [ellipsis]="true">
							</di-acte>
						</div>
					</div>
				</div>
				<div class="info-content">
					<h2>Majorations ?</h2>

					<div *ngFor="let item of majoList; let i = index">
						<div *ngIf="i < 2 || showAllMajorations" class="majoration">
							<lua-checkbox [checked]="isSelectedInListMajo(item)"
								(checkedChange)="toggleCheckMajoration(event,item)"></lua-checkbox>
							<div class="content-majoration">
								<di-majoration [type]="item.type" [price]="item.price"></di-majoration>
							</div>
						</div>
					</div>

					<!-- <lua-bouton-texte class="bouton-majoration" *ngIf="seance.majorations.length > 2"
					[libelle]="showAllMajorations ? 'Afficher moins' : 'Afficher plus'" color="CouleurPrimaire" [fullWidth]="true"
					[startIcon]="showAllMajorations ? 'expand_less' : 'expand_more'" type="button" size="medium"
					(click)="toggleMajorations()"></lua-bouton-texte> -->
				</div>
				<div class="info-content">
					<h2>Déplacement</h2>

					<div *ngFor="let item of indemList; let i = index">
						<div *ngIf="i < 1 || showAllDeplacement" class="majoration">
							<lua-checkbox [checked]="isSelectedInListIndem(item)"
								(checkedChange)="toggleCheckDeplacement(event,item)"></lua-checkbox>
							<div class="content-majoration">
								<di-majoration [indemnity]="true" [indemnityLabel]="item.type +' '+item.label"
									[price]="item.price"></di-majoration>
							</div>
						</div>
					</div>
					<ng-container *ngIf="IKSelected">
						<div class="majoration">
							<lua-checkbox [checked]="isIK" (checkedChange)="toggleIKSelected()"></lua-checkbox>
							<div class="content-majoration">
								<di-majoration [indemnity]="true" [indemnityLabel]="IKSelected.type +' '+IKSelected.label"
									[price]="IKSelected.price | number:'1.2-2':'fr-FR'"></di-majoration>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="showIkConfig">
						<div class="ik-config-container">
							<lua-input (valueChange)="onInputChange($event)" label="Distance réelle (aller-retour)" type="number"
								formControlName="distance" placeholder="Cabinet au lieu de traitement du patient"
								[fullWidth]="true"></lua-input>
							<small>Distance après abattement : {{abattement}} Km </small>
							<div class="container-item">
								<lua-radio-button [checked]="selectedOption === 'IK'" (click)="selectOption('IK')"></lua-radio-button>
								<p>Plaine (0,35€ / Km)</p>
							</div>
							<div class="container-item">
								<lua-radio-button [checked]="selectedOption === 'IKM'" (click)="selectOption('IKM')"></lua-radio-button>
								<p>Montagne (0,50€ / Km)</p>
							</div>
							<div class="container-item">
								<lua-radio-button [checked]="selectedOption === 'IKS'" (click)="selectOption('IKS')"></lua-radio-button>
								<p>Pied / Ski (3,40€ / Km)</p>
							</div>

							<lua-input label="Montant indemnité kilométrique" formControlName="montant" placeholder=""
								[fullWidth]="true"></lua-input>
						</div>
					</ng-container>




					<!-- <lua-bouton-texte class="bouton-majoration" *ngIf="seance.majorations.length > 2"
					[libelle]="showAllDeplacement ? 'Afficher moins' : 'Afficher plus'" color="CouleurPrimaire" [fullWidth]="true"
					[startIcon]="showAllDeplacement ? 'expand_less' : 'expand_more'" type="button" size="medium"
					(click)="toggleDEplacement()"></lua-bouton-texte> -->
				</div>
				<div class="actions">
					<lua-action-principale
						libelle="Confirmer les soins ( {{ total | currency: 'EUR' : 'symbol' : '1.2-2' : 'fr-FR' }} )"
						startIcon="check_circle" [fullWidth]="true" (click)="handleClickConfirm()"></lua-action-principale>

					<lua-action-secondaire *ngIf="seance.status == 2" libelle="Annuler les soins réalisés" color="Error"
						[fullWidth]="true" (click)="handleClickDelete()"></lua-action-secondaire>
					<lua-bouton-texte libelle="Annuler" color="CouleurPrimaire" [fullWidth]="true"
						(click)="handleClickAnnule()"></lua-bouton-texte>
				</div>
			</div>
		</form>
	</div>
</div>