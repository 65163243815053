<div class="component-liste-patients__container" [ngClass]="{'mobileViewForce': mobileViewForce}">
	<div class="component-liste-patients__header">
		<lua-tableau-entete [triee]=false entete="PATIENT">
		</lua-tableau-entete>
		<lua-tableau-entete [triee]=false entete="AGE">
		</lua-tableau-entete>
		<lua-tableau-entete [triee]=false entete="TELEPHONE">
		</lua-tableau-entete>
	</div>
	<di-ligne-patient *ngFor="let patient of patients" [patient]="patient" (onClickLigne)="onClick(patient._id)"
		[mobileViewForce]="mobileViewForce"></di-ligne-patient>
</div>