<calao-tool-bar #toolbar></calao-tool-bar>

<ion-header>
	<ng-container *ngTemplateOutlet="isModal ? modalToolbar : pageToolbar"></ng-container>
</ion-header>

<!--Contenu de la page-->
<ion-content [forceOverscroll]="false" [id]="cssId">
	<ng-container #target></ng-container>
</ion-content>

<!-- Template de la toolbar d'une page. -->
<ng-template #pageToolbar>
	<ion-toolbar color="toolbar">

		<ion-buttons slot="start">
			<!-- Bouton 'back'. -->
			<ion-back-button #backButton *ngIf="hasBackButton" (click)="onBackButtonClicked()" text="">
			</ion-back-button>

			<!-- Bouton 'menu'. -->
			<ion-menu-toggle>
				<ion-button *ngIf="hasMenuButton">
					<ion-icon slot="icon-only" name="menu"></ion-icon>
				</ion-button>
			</ion-menu-toggle>
		</ion-buttons>

		<!-- Bouton 'accueil'. -->
		<ion-buttons slot="start">
			<ion-button *ngIf="hasHomeButton" (click)="goHome()">
				<ion-icon slot="icon-only" name="home"></ion-icon>
			</ion-button>
		</ion-buttons>

		<!-- Titre. -->
		<ion-title *ngIf="hasToolbarTitle" data-cy="page-title">{{ title }}</ion-title>

		<!-- Autres boutons. -->
		<ion-buttons slot="end" class="button-md" *ngFor="let linkInfo of customButtons">
			<calao-link *ngIf="!!linkInfo" [linkInfo]="linkInfo"></calao-link>
		</ion-buttons>

		<!-- Bouton d'état de la synchronisation -->
		<ion-buttons *ngIf="hasSyncButton" slot="end">
			<calao-database-sync-status-icon></calao-database-sync-status-icon>
		</ion-buttons>

		<!-- Bouton 'réglages'. -->
		<ion-buttons *ngIf="hasSettingButton" slot="end">
			<ion-button (click)="openSettings($event)">
				<ion-icon name="options" slot="icon-only"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>

	<!-- Titre. -->
	<ion-title *ngIf="!hasToolbarTitle" data-cy="page-title" class="page-title">{{ title }}</ion-title>
</ng-template>

<!-- Template de la toolbar d'une modale. -->
<ng-template #modalToolbar>
	<ion-toolbar color="toolbar" mode="md">

		<!-- Bouton 'fermer'. -->
		<ion-buttons slot="start" *ngIf="pageInfo.isClosable">
			<ion-button (click)="closeModal()">
				<ion-icon slot="icon-only" name="close"></ion-icon>
			</ion-button>
		</ion-buttons>

		<!-- Titre. -->
		<ion-title>{{ title }}</ion-title>

	</ion-toolbar>
</ng-template>