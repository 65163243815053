import { DateHelper, StringHelper } from "../../../helpers";
import { IdHelper } from "../../../helpers/idHelper";
import { UserData } from "../../../model/application/UserData";
import { EPrefix } from "../../../model/EPrefix";
import { IStoreDocument } from "../../../model/store/IStoreDocument";
import { EUTCAccuracy } from "../../date/model/eutcaccuracy.enum";
import { IPrestationIdBuilderParams } from "./iprestation-id-builder-params";

export class PrestationsIdBuilder<T extends IPrestationIdBuilderParams = IPrestationIdBuilderParams> {

	//#region METHODS

	public buildId(poParams: T): string {
		return IdHelper.buildChildId(EPrefix.prestation,
			IdHelper.buildVirtualNode([UserData.currentSite?._id, this.buildPrestationDate(poParams.prestationDate), poParams.customerIdOrPrefix]),
			poParams.prestationGuid);
	}

	protected buildPrestationDate(poDate: string | Date): string {
		let lsDate: string;

		if (poDate instanceof Date)
			lsDate = DateHelper.toUTCString(poDate, EUTCAccuracy.minutes);
		else
			lsDate = poDate;

		return lsDate;
	}

	/** Construit une fonction de filtrage utile pour le requêtage lorsque la requête `startkey` / `endkey` sur id ne suffit pas.
	 * ### Attention, ici seul le `_id` et le `_rev` sont disponibles.
	 * @param poParams 
	 * @returns Peut retourner `undefined` si il n'y a pas besoin de fonction de filtrage.
	 */
	public buildFilterFunction(poParams: T): (poDoc: IStoreDocument) => boolean {
		const lsCustomerGuid: string = IdHelper.getLastGuidFromId(poParams.customerIdOrPrefix);
		if (!StringHelper.isBlank(lsCustomerGuid))
			return (poDoc: IStoreDocument) => poDoc._id.includes(lsCustomerGuid);
		return undefined;
	}

	//#endregion
}
