import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { HeaderModule } from '../../modules/header/header.module';
import { ModalModule } from '../../modules/modal/modal.module';
import { PipeModule } from '../../pipes/pipes.module';
import { FilePickerModule } from '../filePicker';
import { GoogleMapComponent } from './googleMap.component';
import { ReverseGeocodingModalComponent } from './reverse-geocoding-modal/reverse-geocoding-modal.component';

@NgModule({
	imports: [
		IonicModule,
		CommonModule,
		FilePickerModule,
		PipeModule,
		ModalModule,
		HeaderModule
	],
	exports: [GoogleMapComponent, ReverseGeocodingModalComponent],
	declarations: [GoogleMapComponent, ReverseGeocodingModalComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GeolocationModule { }