<div class="page-patients__container">
	<div class="page-patients_content">
		<div class="content__title">
			<h1 class="content__title-text">Patients</h1>
		</div>
		<lua-action-principale class="content__add-button--desktop" startIcon="add" libelle="Patient"
			(click)="navigateToCreatePatient()"></lua-action-principale>
		<di-recherche-patients [displayCount]="true" (onClickLigne)="onClickPatient($event)"></di-recherche-patients>
	</div>
	<lua-bouton-floating class="page-patients__add-button--mobile" iconName="add" right="30px" bottom="30px"
		(click)="navigateToCreatePatient()"></lua-bouton-floating>
</div>