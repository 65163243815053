import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { Seance } from '../../../../model/Seance';
import { ISeancesConflicts } from '../../model/ISeancesConflicts';

interface ISeancesWithMessage {
	seances: Seance[];
	message: string;
}

@Component({
	selector: 'idl-seance-conflicts',
	templateUrl: './seance-conflicts.component.html',
	styleUrls: ['./seance-conflicts.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeanceConflictsComponent extends ComponentBase {

	//#region FIELDS

	@Output("messageClicked") private readonly moMessageClickedEventEmitter = new EventEmitter<Seance[]>();

	//#endregion

	//#region PROPERTIES

	@Input()
	public set seancesConflicts(poSeancesConflicts: ISeancesConflicts) {
		if (ArrayHelper.hasElements(poSeancesConflicts.multipleIntervenants))
			this.seancesWithMessages.push(this.generateSeancesWithMessage(poSeancesConflicts.multipleIntervenants, "avec plusieurs intervenants", "Séance"));
		if (ArrayHelper.hasElements(poSeancesConflicts.noIntervenants))
			this.seancesWithMessages.push(this.generateSeancesWithMessage(poSeancesConflicts.noIntervenants, "sans intervenants", "Séance"));
		if (ArrayHelper.hasElements(poSeancesConflicts.noPlanning))
			this.seancesWithMessages.push(this.generateSeancesWithMessage(poSeancesConflicts.noPlanning, "Aucun planning généré"));
	}

	public seancesWithMessages: ISeancesWithMessage[] = [];

	//#endregion

	//#region METHODS

	constructor(poChangeDetector: ChangeDetectorRef) {
		super(poChangeDetector);
	}

	private generateSeancesWithMessage(paSeances: Seance[], psMessage: string, psTextToMakePlural?: string): ISeancesWithMessage {
		let lsMessage = "";

		if (!StringHelper.isBlank(psTextToMakePlural)) {
			if (paSeances.length > 1)
				lsMessage = psTextToMakePlural.split(" ").map((psWord: string) => `${psWord}s`).join(" ");
			else
				lsMessage = psTextToMakePlural;
		}

		lsMessage += " " + psMessage;

		return {
			seances: paSeances,
			message: lsMessage
		};
	}

	public onMessageClicked(poSeancesWithMessage: ISeancesWithMessage): void {
		this.moMessageClickedEventEmitter.emit(poSeancesWithMessage.seances);
	}

	//#endregion

}
