export enum EInvoiceStatus {
	created = "created",
	priced = "priced",
	invoicing = "invoicing",
	checked = "checked",
	payment = "payment",
	closed = "closed",
	archived = "archived",
	canceled = "canceled",
	aborted = "aborted"
}
