import { ErrorHandler, ModuleWithProviders, NgModule, Provider, Type } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Contacts } from '@ionic-native/contacts/ngx';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { SharedComponentsModule } from '@osapp/components/sharedComponents.module';
import { ConflictsModule } from '@osapp/modules/conflicts/conflicts.module';
import { FilterModule } from '@osapp/modules/filter/filter.module';
import { IntegrityModule } from '@osapp/modules/integrity/integrity.module';
import { SelectorModule } from '@osapp/modules/selector/selector.module';
import { OsappModule } from '@osapp/osapp.module';
import { NgCalendarModule } from 'ionic2-calendar';
import { IDL_FORMLY_CONFIG_OPTION } from '../model/IdlComponentRegister';
import { IdlPipeModule } from '../pipes/idlPipes.module';
import { PurchasePageComponent } from './achats/purchasesPage.component';
import { ActesModule } from './actes/actes.module';
import { ContactsBookPageComponent } from './contactsBook/contactsBookPage.component';
import { FinessModule } from './finess/finess.module';
import { ConfidentialityPageComponent } from './mentionsLegales/confidentialityPage.component';
import { TermsOfSalesPageComponent } from './mentionsLegales/termesOfSalesPage.component';
import { AdministratifWrapperComponent } from './patients/components/administratif-wrapper/administratif-wrapper.component';
import { ExerciceConditionFieldComponent } from './patients/components/exercice-condition-field/exercice-condition-field.component';
import { FacturationNumberFieldComponent } from './patients/components/facturation-number-field/facturation-number-field.component';
import { RppsFieldComponent } from './patients/components/rpps-field/rpps-field.component';
import { SignatureWrapperComponent } from './patients/components/signature-wrapper/signature-wrapper.component';
import { SpeExerciceConditionFieldComponent } from './patients/components/spe-exercice-condition-field/spe-exercice-condition-field.component';
import { PatientsModule } from './patients/patients.module';
import { PharmaModule } from './pharma/pharma.module';
import { SettingsPageComponent } from './reglages/settingsPage.component';
import { SurveysPageComponent } from './sondages/surveysPage.component';
import { TraitementSlideModule } from './traitement/traitement-slide-page.module';
import { TraitementsPageComponent } from './traitements/traitements-page.component';
import { W3pIFrameComponent } from './w3pIFramePage/w3pIFrame.component';

const importModules: Array<Type<any> | ModuleWithProviders<any>> = [
	IonicModule,
	OsappModule,
	NgCalendarModule,
	SharedComponentsModule,
	IdlPipeModule,
	FormlyModule.forChild(IDL_FORMLY_CONFIG_OPTION),
	SuperTabsModule,
	FilterModule,
	SelectorModule,
	FinessModule,
	MatSlideToggleModule,
	ActesModule,
	ConflictsModule,
	IntegrityModule,
	PatientsModule
];
const exportModules: Type<any>[] = [
	TraitementSlideModule,
	PatientsModule,
	PharmaModule
];
const components: Type<any>[] = [
	W3pIFrameComponent,
	TraitementsPageComponent,
	PurchasePageComponent,
	SurveysPageComponent,
	SettingsPageComponent,
	ConfidentialityPageComponent,
	TermsOfSalesPageComponent,
	ContactsBookPageComponent,
	ExerciceConditionFieldComponent,
	SpeExerciceConditionFieldComponent,
	RppsFieldComponent,
	FacturationNumberFieldComponent,
	AdministratifWrapperComponent,
	SignatureWrapperComponent
];
const providers: Provider[] = [
	{ provide: ErrorHandler, useClass: ErrorHandler },
	Contacts
];

@NgModule({
	imports: importModules,
	exports: exportModules,
	declarations: components,
	providers: providers
})
export class CustomModule { }