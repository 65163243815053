<div class="bouton-panneau-component__container" [ngClass]="getClasses()">
	<div class="container__start">
		<ng-container *ngIf="!hasContent">
			<ng-container *ngIf="!!iconName">
				<div class="container__icon-container">
					<lua-icone
						[iconName]="iconName"
						mode="outlined"
						color="Noir"
					></lua-icone>
				</div>
			</ng-container>
			<p
				class="container__text-content"
				[innerHTML]="content || placeHolder"
			></p>
		</ng-container>
		<ng-content></ng-content>
	</div>
	<div class="container__end">
		<div class="container__icon-container">
			<lua-icone iconName="chevron_right" color="Gris2"></lua-icone>
		</div>
	</div>
</div>
