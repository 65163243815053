import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { BooleanHelper } from '@osapp/helpers/boolean.helper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { IContactsListParams } from '@osapp/model/contacts/IContactsListParams';
import { EDateTimePickerMode } from '@osapp/model/date/EDateTimePickerMode';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { IDateTimePickerParams } from '@osapp/model/date/IDateTimePickerParams';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { ContactsService } from '@osapp/services/contacts.service';
import { C_PREFIX_PATIENT } from '../../../../../app/app.constants';
import { ESitSupportAT } from '../../../model/ESitSupportAT';
import { IPatient } from '../../../model/IPatient';
import { AMOP } from '../../../model/amo-p';
import { EGarantie } from '../../../model/egarantie.enum';
import { EQualite } from '../../../model/equalite.enum';
import { ESituation } from '../../../model/esituation.enum';
import { ETypePieceJustificative } from '../../../model/etype-piece-justificative.enum';
import { EUpdateMode } from '../../../model/eupdate-mode.enum';
import { IAMO } from '../../../model/iamo';
import { IUpdateHistory } from '../../../model/iupdate-history';
import { CouverturesService } from '../../../services/couvertures.service';

@Component({
	selector: 'idl-couverture-amo-p',
	templateUrl: './couverture-amo-p.component.html',
	styleUrls: ['./couverture-amo-p.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouvertureAMOPComponent extends ComponentBase implements OnInit {

	//#region PROPERTIES

	private moAmoP: AMOP;
	public get amoP(): AMOP { return this.moAmoP; }
	@Input() public set amoP(poAmoP: AMOP) {
		if (poAmoP) {
			this.moAmoP = poAmoP;
			this.moAmoP.tp = BooleanHelper.convertToBoolean(this.moAmoP.tp);
			this.isADRi = this.moAmoP.updateMode === EUpdateMode.ADRi;
			this.isVitale = this.moAmoP.updateMode === EUpdateMode.carteVitale;
			this.isSecured = this.isADRi || this.isVitale;
			if (this.moAmoP.amoId)
				this.garantieOptions = CouverturesService.getFilteredGarantieOptions(this.moAmoP.amoId);
		}
	}

	private maEtablissements: IAMO[];
	public get etablissements(): IAMO[] { return this.maEtablissements; }
	@Input() public set etablissements(paEtablissements: IAMO[]) {
		if (paEtablissements !== this.maEtablissements) {
			this.maEtablissements = paEtablissements;
			this.filteredEtablissements = paEtablissements;
			if (this.amoP?.amoId) {
				this.etablissementName = this.maEtablissements.find((poEtablissement: IAMO) => poEtablissement._id === this.amoP.amoId)?.label;
				if (this.etablissementName == undefined) {
					const modifiedAmoId = this.amoP.amoId.replace(/-(\d+)$/, '-0000');
					this.etablissementName = this.maEtablissements.find((poEtablissement: IAMO) => poEtablissement._id === modifiedAmoId)?.label;
				}
			}
			this.detectChanges();
		}
	}

	public isSecured: boolean;
	public isADRi: boolean;
	public isVitale: boolean;
	public filteredEtablissements: IAMO[];
	public etablissementName: string;

	public patientsListParams: IContactsListParams;
	public readonly datePickerParams: IDateTimePickerParams = DateHelper.datePickerParamsFactory(ETimetablePattern.dd_MM_yyyy_slash, EDateTimePickerMode.date,true);
	public readonly qualiteOptions: ReadonlyArray<ISelectOption<EQualite>> = [
		{ label: "Assuré", value: EQualite.assure },
		{ label: "Ascendant, descendant, collatéraux", value: EQualite.ascendantDescendantCollateraux },
		{ label: "Conjoint", value: EQualite.conjoint },
		{ label: "Conjoint divorcé", value: EQualite.conjointDivorce },
		{ label: "Concubin", value: EQualite.concubin },
		{ label: "Conjoint Séparé", value: EQualite.conjointSepare },
		{ label: "Enfant", value: EQualite.enfant },
		{ label: "Conjoint Veuf", value: EQualite.conjointVeuf },
		{ label: "Autre", value: EQualite.autre },
	];
	public readonly TPOptions: ReadonlyArray<ISelectOption<boolean>> = [
		{ label: "Oui, je pratique le tiers payant", value: true },
		{ label: "Non, je ne pratique pas le tiers payant", value: false }
	];
	public readonly ALDOptions: ReadonlyArray<ISelectOption<boolean>> = [
		{ label: "Oui", value: true },
		{ label: "Non", value: false }
	];
	public readonly pieceJustificativeOptions: ReadonlyArray<ISelectOption<ETypePieceJustificative>> = [
		{ label: "Aucune pièce", value: ETypePieceJustificative.aucunePièce },
		{ label: "Attestation de droits", value: ETypePieceJustificative.attestationDeDroits },
		{ label: "Carte d'assuré social", value: ETypePieceJustificative.carteAssureSocial }
	];
	public readonly situationOptions: ReadonlyArray<ISelectOption<ESituation>> = [
		{ label: "Assurance maladie", value: ESituation.assuranceMaladie },
		{ label: "Accident du travail", value: ESituation.accidentTravail },
		{ label: "Maternité", value: ESituation.maternite },
		{ label: "Prévention maladie", value: ESituation.preventionMaladie },
		{ label: "Soins médicaux gratuits", value: ESituation.soinsMedicauxGratuits }
	];
	public readonly supportATOptions: ReadonlyArray<ISelectOption<ESitSupportAT>> = [
		{ label: "Feuillet AT", value: ESitSupportAT.feuilletAT },
		{ label: "Attestation d'affiliation", value: ESitSupportAT.attestationAffiliation },
		{ label: "Courrier Caisse", value: ESitSupportAT.courrierCaisse },
		{ label: "Déclaration de la victime", value: ESitSupportAT.declarationDeLaVictime },
	];
	public garantieOptions: ISelectOption<EGarantie>[] = [];

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcContacts: ContactsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poChangeDetectorRef);
	}

	public ngOnInit(): void {
		this.patientsListParams = this.createPatientsListParams();
		this.detectChanges();
	}

	public updateFilteredEtablissements() {
		delete this.amoP.amoId;
		this.filteredEtablissements = this.maEtablissements.filter((poEtablissement: IAMO) => {
			return poEtablissement.label.toLowerCase().includes(this.etablissementName.toLowerCase()) ||
				poEtablissement._id.replace("-", "").includes(this.etablissementName);
		});
		this.detectChanges();
	}

	public getFormatedId(poEtablissement: IAMO): string {
		return poEtablissement._id.split("_")[1].split("-").join(" ");
	}

	public onPatientAssureModelChanged(paSelectedContacts: Array<IPatient>): void {
		const loFirstContact: IPatient = ArrayHelper.getFirstElement(paSelectedContacts);
		const lsFirstContactId: string = loFirstContact?._id;

		if (lsFirstContactId !== this.amoP.assurePatientId) {
			this.amoP.assurePatientId = lsFirstContactId;
			this.detectChanges();
		}
	}

	public onOptionClick(poOption: IAMO): void {
		this.amoP.amoId = poOption._id;
		this.garantieOptions = CouverturesService.getFilteredGarantieOptions(poOption._id);
	}

	public onDateDebutChanged(pdDate: Date): void {
		this.amoP.dateDebut = DateHelper.resetDay(pdDate);

		if (DateHelper.compareTwoDates(this.amoP.dateDebut, this.amoP.dateFin) > 0)
			this.onDateFinChanged(new Date(this.amoP.dateDebut));
	}

	public onDateFinChanged(pdDate: Date): void {
		this.amoP.dateFin = DateHelper.fillDay(pdDate);

		if (DateHelper.compareTwoDates(this.amoP.dateDebut, this.amoP.dateFin) > 0)
			this.onDateDebutChanged(new Date(this.amoP.dateFin));
	}

	public onPieceJustificativeChange(poOption: ETypePieceJustificative): void {
		if (poOption === ETypePieceJustificative.aucunePièce)
			delete this.amoP.datePieceJustificative;
		else if (!this.amoP.datePieceJustificative)
			this.amoP.datePieceJustificative = new Date();

		this.patientsListParams = this.createPatientsListParams();
	}

	public onQualiteOptionChange(): void {
		if (this.amoP.qualite === EQualite.assure || StringHelper.isBlank(this.amoP.qualite))
			delete this.amoP.assurePatientId;
	}

	public onSituationChange(): void {
		if (!StringHelper.isBlank(this.amoP.situation)) {
			this.amoP.garantie = EGarantie.NONEXO;
			this.amoP.situationDate = new Date();
			if (this.amoP.situation === ESituation.accidentTravail) {
				this.initDataAccidentTravail();
			} else {
				this.resetDataAccidentTravail();
			}
		}
		else {
			delete this.amoP.situationDate;
			this.resetDataAccidentTravail();
		}
	}

	private initDataAccidentTravail(): void {
		this.amoP.supportAT = ESitSupportAT.feuilletAT;
	}

	private resetDataAccidentTravail(): void {
		delete this.amoP.supportAT;
		delete this.amoP.numAttestationAT;
		delete this.amoP.destinataireAT;
	}

	private createPatientsListParams(): IContactsListParams {
		return {
			contactsAsLinks: false,
			displayProperties: [],
			displayIcons: [],
			pageTitle: "Patient",
			readOnly: this.isSecured,
			contactsById: false,
			contactsSelectorParams: this.isvcContacts.createContactSelectorParams(C_PREFIX_PATIENT, "Sélectionnez un patient"),
			contactsContainer: this.amoP,
			addButtonIcon: "person",
			addButtonText: "Sélectionner",
			disbaleRouteToContact: true
		};
	}

	public getManualCreationDate(): Date {
		const loHistory: IUpdateHistory = ArrayHelper.getFirstElement(this.amoP.updateHistory);
		if (loHistory && loHistory.mode === EUpdateMode.manual && this.amoP._rev)
			return loHistory.date;
		return undefined;
	}

	//#endregion

}
