<div class="step-actes__container">
	<div class="step-actes__body">
		<h2>Actes</h2>
		<div class="step-actes__listeActes">
			<ng-container *ngIf="showListeActes()">
				<ng-container *ngFor="let acte of listeActes; let i = index">
					<div class="ligne-acte">
						<di-acte [favoriteIcon]="!isMobile" [acte]="acte" [status]="getFavoriteStatus(acte)"
							(onCheckboxChange)="onFavoritesClicked(acte, $event)" [priseEnCharge]="true">
						</di-acte>
						<ng-container>
							<div class="options-icones">
								<lua-bouton-icone (click)="openPanneauPriseEnCharge(acte, i)" iconName="edit"></lua-bouton-icone>
								<lua-bouton-icone (click)="openConfirmDeleteActe($event, i)" iconName="delete"
									color="Error"></lua-bouton-icone>
							</div>
						</ng-container>
						<!-- <ng-container *ngIf="!isMobile">
              <div class="options-icones">
                  <lua-bouton-icone iconName="more_vert" (click)="openMenu($event, acte, i)"></lua-bouton-icone>
              </div>
            </ng-container> -->
					</div>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="!showListeActes()">
				<div>
					<lua-message-aucune-donnee mainText="Aucun acte" iconName="contract"
						subText="Ajoutez des actes pour lister les soins à réaliser" buttonLabel="Ajouter un acte"
						(onClick)="openPanneauActes()">
					</lua-message-aucune-donnee>
				</div>
			</ng-container>
		</div>
		<div *ngIf="showPriseEnChargeWarning">
			<lua-alerte [fullWidth]="true" type="warning" [message]="priseEnChargeMessage"></lua-alerte>
		</div>
		<div class="step-actes__ajout-acte" *ngIf="showListeActes()">
			<lua-action-secondaire class="btn-ajouter-acte" libelle="Ajouter un acte" (click)="openPanneauActes()"
				positionIcone="L" nomIcone="add" [avecIcone]="true" [isDisabled]="showPriseEnChargeWarning"
				[fullWidth]="isMobile" size="large">
			</lua-action-secondaire>
		</div>
		<div class="step-actes__entente-prealable" *ngIf="showEntentePrealable">
			<div>
				<h2>Demande d'entente préalable</h2>
				<small>Des actes sélectionnés demandent une entete préalable avant d'effectuer les soins</small>
			</div>
			<lua-switch label="Actes urgents" texteSecondaire="Dans ce cas vous pouvez générer les soins"
				[isChecked]="isActeUrgent" (clickSwitch)="toggleActeUrgent()">
			</lua-switch>
			<div class="saisir-demande-ap-container">
				<lua-action-secondaire libelle="Saisir la demande" (click)="saisirDemandeAccordPrealable()"
					[fullWidth]="isMobile" size="large">
				</lua-action-secondaire>
			</div>
		</div>
	</div>
	<div class="step-actes__footer">
		<lua-action-principale (click)="generateSeance()" libelle="Générer les séances" [isDisabled]="isBtnGenerateDisabled"
			endIcon="arrow_forward" [fullWidth]="isMobile">
		</lua-action-principale>
	</div>
</div>