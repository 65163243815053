export enum EQualite {
	assure = "00",
	ascendantDescendantCollateraux = "01",
	conjoint = "02",
	conjointDivorce = "03",
	concubin = "04",
	conjointSepare = "05",
	enfant = "06",
	conjointVeuf = "08",
	autre = "09",
}
