import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, finalize, takeUntil, tap } from 'rxjs/operators';
import { StringHelper } from '../../helpers/stringHelper';
import { IDestroyable } from '../../modules/utils/lifecycle/models/IDestroyable';

export class ActivePageManager {

	//#region FIELDS

	private msUrl: string;
	private moIsActivePageSubject = new BehaviorSubject<boolean>(true);
	/** Raccourci vers l'observable de destruction de l'objet pour éviter de vérifier le type (ComponentBase ou FieldBase). */
	private moDestroyed$: Observable<void>;
	private mbIsActive = true;

	//#endregion

	//#region PROPERTIES

	public get isActive$(): Observable<boolean> {
		return this.moIsActivePageSubject.asObservable().pipe(distinctUntilChanged());
	}

	public get isActive(): boolean {
		return this.mbIsActive;
	}

	//#endregion

	//#region METHODS

	constructor(
		poComponent: IDestroyable,
		poRouter: Router,
		pfUrlPredicate: (msNewUrl: string, msPageUrl: string) => boolean = (msNewUrl: string, msPageUrl: string) => msNewUrl === msPageUrl
	) {

		this.msUrl = poRouter.url;
		this.moDestroyed$ = poComponent.destroyed$;

		poRouter.events
			.pipe(
				filter((poEvent: RouterEvent) => poEvent instanceof NavigationEnd && !StringHelper.isBlank(poEvent.url)),
				distinctUntilChanged((poOldEvent: NavigationEnd, poNewEvent: NavigationEnd) => poOldEvent.url === poNewEvent.url),
				tap((poEvent: NavigationEnd) => this.moIsActivePageSubject.next(this.mbIsActive = pfUrlPredicate(poEvent.url, this.msUrl))),
				takeUntil(this.moDestroyed$),
				finalize(() => this.moIsActivePageSubject.complete())
			)
			.subscribe();
	}

	//#endregion
}