import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ObservationsService } from './observations.service';

@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [ObservationsService]
})
export class ObservationsModule { }
