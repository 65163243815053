import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ArrayHelper, ComponentBase, DateHelper } from '@osapp/helpers';
import { ITraitement } from 'apps/idl/src/model/ITraitement';
import { Traitement } from '../../../../model/Traitement';
import { Ordonnance } from '../../../ordonnances/models/ordonnance';

interface IMarker {
	class?: string;
	icon?: string;
	label?: string;
	text?: string;
	tooltip?: string;
}
@Component({
	selector: 'idl-traitements-list-marker',
	templateUrl: './traitements-list-marker.component.html',
	styleUrls: ['./traitements-list-marker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TraitementsListMarkerComponent extends ComponentBase {

	//#region FIELDS

	private moActivityMarker: IMarker;
	private moOrdonnancesMarker: IMarker;
	private moConflictsMarker: IMarker;

	//#endregion

	//#region PROPERTIES

	public get markers(): IMarker[] {
		return [this.moConflictsMarker, this.moOrdonnancesMarker, this.moActivityMarker];
	}

	@Input() public set traitement(poTraitement: ITraitement) {
		if (poTraitement) {
			if (poTraitement._conflicts)
				this.moConflictsMarker = { class: "conflicts", icon: "git-branch", tooltip: "Conflits" };
			if (Traitement.isTraitementActive(poTraitement, new Date(), false)) {
				if (DateHelper.isDate(poTraitement.breakDate))
					this.moActivityMarker = { class: "interrupted", icon: "alert", tooltip: "Interrompu" };
				else if (Traitement.endsSoon(poTraitement)) {
					const joursDiff: number = Math.ceil(DateHelper.diffDays(poTraitement.endDate, new Date()));
					this.moActivityMarker = { class: "end-soon", text: `J${joursDiff >= 0 ? '-' + joursDiff : '+' + Math.abs(joursDiff)}`, tooltip: "Fin de traitement" };
				}
				else
					this.moActivityMarker = { class: "active", icon: "active", label: "En cours" };
			}
			else
				this.moActivityMarker = { label: "Terminé" };
		}

		this.detectChanges();
	}

	@Input() public set ordonnances(paOrdonnances: Ordonnance[]) {
		if (paOrdonnances === undefined || (paOrdonnances && !ArrayHelper.hasElements(paOrdonnances)))
			this.moOrdonnancesMarker = { class: "no-doc", icon: "no-document", tooltip: "Aucune ordonnance" };
		else
			this.moOrdonnancesMarker = undefined;

		this.detectChanges();
	}

	//#endregion

	//#region METHODS

	constructor(
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poChangeDetectorRef);
	}

	//#endregion

}
