import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { defer, EMPTY, Observable, of, throwError, TimeInterval, timer } from 'rxjs';
import { catchError, concatMap, finalize, mapTo, mergeMap, takeUntil, tap, timeInterval, toArray } from 'rxjs/operators';
import { Database } from '../../../../model/store/Database';
import { IUiResponse } from '../../../../model/uiMessage/IUiResponse';
import { ApplicationService } from '../../../../services/application.service';
import { ShowMessageParamsPopup } from '../../../../services/interfaces/ShowMessageParamsPopup';
import { PlatformService } from '../../../../services/platform.service';
import { UiMessageService } from '../../../../services/uiMessage.service';
import { ModalComponentBase } from '../../../modal/model/ModalComponentBase';
import { NoOnlineReliableNetworkError } from '../../../network/models/errors/NoOnlineReliableNetworkError';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { EDatabaseSyncStatus } from '../../../store/model/EDatabaseSyncStatus';
import { IPouchDBFailedToFetchError } from '../../../store/model/errors/ipouchdb-failed-to-fetch-error';
import { NoDatabaseLocalInstanceError } from '../../../store/model/errors/NoDatabaseLocalInstanceError';
import { NoDatabaseRemoteInstanceError } from '../../../store/model/errors/NoDatabaseRemoteInstanceError';
import { IResetDatabasesResult } from '../../../store/model/IResetDatabasesResult';
import { Queue } from '../../../utils/queue/decorators/queue.decorator';
import { ResetDatabasesError } from '../../model/errors/ResetDatabasesError';
import { IDatabaseGroupingConfiguration } from '../../model/IDatabaseGroupingConfiguration';
import { IDatabaseSyncStatus } from '../../model/IDatabaseSyncStatus';
import { DatabaseSynchroService } from '../../services/database-synchro.service';

@Component({
	selector: 'calao-databases-sync-status-modal',
	templateUrl: './databases-sync-status-modal.component.html',
	styleUrls: ['./databases-sync-status-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatabasesSyncStatusModalComponent extends ModalComponentBase<boolean> implements OnInit, OnDestroy {

	//#region FIELDS

	private static readonly C_LOG_ID = "SYNCSTAT.C::";

	private static readonly C_DEFAULT_SYNC_TEXT = "Synchroniser";

	private maGroupingConfigs: IDatabaseGroupingConfiguration[];

	//#endregion

	//#region PROPERTIES

	public databasesSyncStatus: IDatabaseSyncStatus[] = [];

	private static readonly moSyncText = new ObservableProperty<string>(DatabasesSyncStatusModalComponent.C_DEFAULT_SYNC_TEXT);
	public get syncText$(): Observable<string> { return DatabasesSyncStatusModalComponent.moSyncText.value$; }

	private static readonly moIsBusy = new ObservableProperty<boolean>();
	/** Indique si des actions sont en cours de déoulement (pour bloquer les autres actions tant que l'actuelle n'est pas terminée). */
	public get isBusy$(): Observable<boolean> { return DatabasesSyncStatusModalComponent.moIsBusy.value$; }
	private set isBusy(pbNewValue: boolean) { DatabasesSyncStatusModalComponent.moIsBusy.value = pbNewValue; }

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcDatabaseSynchro: DatabaseSynchroService,
		private readonly isvcUiMessage: UiMessageService,
		public readonly isvcPlatform: PlatformService,
		poModalCtrl: ModalController,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poModalCtrl, isvcPlatform, poChangeDetectorRef);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		this.maGroupingConfigs = this.isvcDatabaseSynchro.getDatabasesGroupingConfigs();

		this.maGroupingConfigs.forEach((poConfig: IDatabaseGroupingConfiguration, pnIndex: number) => {
			this.isvcDatabaseSynchro.getSyncStatus(poConfig.roles).pipe(
				tap((peDatabasesSyncStatus: EDatabaseSyncStatus) => {
					this.databasesSyncStatus[pnIndex] = { title: poConfig.title, description: poConfig.description, status: peDatabasesSyncStatus };
					this.databasesSyncStatus = Array.from(this.databasesSyncStatus);
					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
				.subscribe();
		});

		this.isvcDatabaseSynchro.getDmsSyncStatus()
			.pipe(
				tap((peDatabaseSyncStatus: EDatabaseSyncStatus) => {
					this.databasesSyncStatus[this.maGroupingConfigs.length] = { ...this.isvcDatabaseSynchro.getDmsSyncConfig(), status: peDatabaseSyncStatus };
					this.databasesSyncStatus = Array.from(this.databasesSyncStatus);
					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	public syncDatabases(): void {
		this.synchronize().subscribe();
	}

	@Queue({ paramsReducer: (_, paNewArgs: any[]) => paNewArgs })
	private synchronize(): Observable<string[]> {
		let lnTotalDb: number;

		return defer(() => {
			this.isBusy = true;
			const laDatabases: Database[] = this.isvcDatabaseSynchro.getGroupingConfigsDatabases();
			lnTotalDb = laDatabases.length;
			return laDatabases;
		}).pipe(
			concatMap((poDatabase: Database, pnIndex: number) => this.isvcDatabaseSynchro.syncDatabase(poDatabase).pipe(
				timeInterval(),
				concatMap((poValue: TimeInterval<string>) => {
					const lnDifferedTime: number = 500 - poValue.interval;
					if (lnDifferedTime <= 0)
						return of(poValue.value);

					return timer(lnDifferedTime).pipe(mapTo(poValue.value));
				}),
				tap((psText: string) => {
					DatabasesSyncStatusModalComponent.moSyncText.value = `Base ${pnIndex + 1}/${lnTotalDb} : ${psText}.`;
					this.detectChanges();
				})
			)),
			toArray(),
			finalize(() => {
				DatabasesSyncStatusModalComponent.moSyncText.value = DatabasesSyncStatusModalComponent.C_DEFAULT_SYNC_TEXT;
				this.isBusy = false;
			})
		);
	}

	//#region Synchronisations

	/** Optimise (Réinitialise) les bases de données d'espace de travail éligibles (réplication montante + téléchargement dump + suppression bdd locale + chargement dump). */
	public resetWorkspaceDatabases(): void {
		this.showResetDatabasesPopup()
			.pipe(
				concatMap((poResponse: IUiResponse<boolean>) => poResponse.response ? this.isvcDatabaseSynchro.resetDatabases() : EMPTY),
				mergeMap((poResult: IResetDatabasesResult) => this.onResetDatabasesResult(poResult)),
				catchError((poError: NoOnlineReliableNetworkError | NoDatabaseLocalInstanceError | NoDatabaseRemoteInstanceError | ResetDatabasesError | IPouchDBFailedToFetchError | any) =>
					this.showResetDatabasesFailedPopup(poError)
				)
			)
			.subscribe();
	}

	/** Affiche la popup de réinitialisation des bases de données. */
	private showResetDatabasesPopup(): Observable<IUiResponse<boolean>> {
		return this.isvcUiMessage.showAsyncMessage<boolean>(new ShowMessageParamsPopup({
			header: "Optimisation de vos données",
			message: `Nous allons mettre en sécurité et optimiser vos données : vous devez disposer d'une connexion internet fiable.<br/>Votre application sera momentanément indisponible et va ensuite redémarrer.<br/><br/>Voulez-vous continuer ?`,
			backdropDismiss: false,
			buttons: [
				{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() },
				{ text: "Optimiser", handler: () => UiMessageService.getTruthyResponse() }
			]
		}));
	}

	/** Gère le résultat de réinitialisation des bases de données : relance l'app en cas de réussite ou lève une erreur le cas échéant.
	 * @param poResult Objet résultat de la réinitialisation des bases de données.
	 * @returns `EMPTY` si la réinitialisation est ok, erreur `ResetDatabasesError` sinon.
	 */
	private onResetDatabasesResult(poResult: IResetDatabasesResult) {
		if (poResult.successCount === poResult.totalCount) // Toutes les bases de données ont été optimisées.
			return this.reloadApp();
		else { // Des bases de données n'ont pas été optimisées.
			return throwError(new ResetDatabasesError(
				`${poResult.failedCount} ${poResult.failedCount > 1 ? "bases de données n'ont pas pu être optimisées" : "base de données n'a pas pu être optimisée"}.<br/>Si le problème persiste, veuillez contacter le support technique.`
			));
		}
	}

	/** Recharge l'app suite à la bonne ou mauvaise réinitialisation des bases de données. */
	private reloadApp(): Observable<never> {
		ApplicationService.reloadApp();
		return EMPTY;
	}

	/** Affiche une popup d'erreur à l'utilisateur pour l'informer de l'échec de réinitialisation des bases de données (partiel ou complet).
	 * @param poError Erreur survenue lors de la réinitialisation des bases de données.
	 */
	private showResetDatabasesFailedPopup(poError: NoOnlineReliableNetworkError | NoDatabaseLocalInstanceError | NoDatabaseRemoteInstanceError | ResetDatabasesError |
		IPouchDBFailedToFetchError | any): Observable<never> {
		let lsErrorMessage: string;

		if (poError instanceof NoOnlineReliableNetworkError || poError instanceof NoDatabaseRemoteInstanceError || poError instanceof ResetDatabasesError)
			lsErrorMessage = poError.message;
		else {
			lsErrorMessage = "L'optimisation des données a échoué.<br/>";

			if ((poError as IPouchDBFailedToFetchError).message === "Failed to fetch") // À priori micro-coupure réseau qui donne cette erreur.
				lsErrorMessage += "Veuillez vous assurer d'avoir une connexion internet fiable.";
			else
				lsErrorMessage += "Si le problème persiste, veuillez contacter le support technique.";
		}

		console.error(`${DatabasesSyncStatusModalComponent.C_LOG_ID}Database reset operation failed.`, poError);

		return this.isvcUiMessage.showAsyncMessage(new ShowMessageParamsPopup({ message: lsErrorMessage, header: "Erreur", buttons: [UiMessageService.createOkButton()] }))
			.pipe(mergeMap(_ => this.reloadApp()));
	}

	//#endregion


	//#endregion

}