import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ETraitementState } from 'apps/idl/src/model/ETraitementState';
import { Traitement } from 'apps/idl/src/model/Traitement';
import { TraitementService } from 'apps/idl/src/services/traitement.service';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { DeviceService } from '../../features/shared/services/device.service';
import { LoaderService } from '../../features/shared/services/loader.service';

@Component({
  selector: 'di-facturation',
  templateUrl: './facturation.page.html',
  styleUrls: ['./facturation.page.scss'],
})
export class FacturationPage extends DestroyableComponentBase implements OnInit {

  public nbOrdoActive: number = 0;
  public dateFacturation: Date = new Date();
  public facturationfilters: Array<string> = ["A FACTURER", "FACTURÉES"];
  public defaultFilter = this.facturationfilters[0];
  public ordonnances: Traitement[] = [];
  public isMobile: boolean = false;
  public sousTexteAucuneOrdonnance: string = "Vous allez retrouver ici toutes les ordonnances patients en cours.";

  public filtreForm: FormGroup;


  constructor(
    private svcTraitement: TraitementService,
    private svcDevice: DeviceService,
    private fb: FormBuilder,
    private router: Router,
    private svcLoader: LoaderService
  ) { super(); }

  ngOnInit() {
    this.filtreForm = this.fb.group({
      dateFacturation: [this.dateFacturation ? this.dateFacturation : new Date(), []],
    });


    this.svcDevice.isMobile$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((flag: boolean) => {
        this.isMobile = flag;
      });
		
    this.loadListOrdonnance();
  }

	private loadListOrdonnance() {
		this.svcLoader.showLoader();
		//Récupérer les ordonnances avec les factu
		this.svcTraitement.getTraitementsByStatus(false, null, ETraitementState.termine, (this.defaultFilter != "FACTURÉES"))
			.pipe(
				tap((ordo) => {
					this.ordonnances = ordo;
					this.nbOrdoActive = ordo.length;
				}),
				finalize(() => {
					this.svcLoader.hideLoader();
				})
			)
			.subscribe();
	}

  changeFilter(filtreSelected: string) {
		this.defaultFilter = filtreSelected;
		this.loadListOrdonnance();
  }

  onValueChange(newDate: string) {
    console.log("onValueChange : ", newDate);
    const newDateFormated = new Date(newDate);
    this.dateFacturation = newDateFormated;
    this.filtreForm.setValue({
      dateFacturation: newDateFormated,
    });
  }

  handleFacturer = (idOrdonnance: string): void => {
    if (!idOrdonnance) return;
    // this.router.navigate(["facturation", "controle", idOrdonnance]);
    this.router.navigate(["facturation", "controle", idOrdonnance], { state: { dateFacturation: this.dateFacturation } });
  }

}
