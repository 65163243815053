<super-tabs (tabChange)="onTabChanged($event)">
	<super-tabs-toolbar slot="top">
		<super-tab-button>
			<ion-label>Actifs</ion-label>
		</super-tab-button>
		<super-tab-button>
			<ion-label>Tous</ion-label>
		</super-tab-button>
	</super-tabs-toolbar>

	<super-tabs-container>
		<!-- Première slide (actifs). -->
		<super-tab>
			<ion-content #actifsContainer>
				<ng-container *ngTemplateOutlet="content; context: { ionContent: actifsContainer, index: 0 }">
				</ng-container>
			</ion-content>
		</super-tab>
		<!-- Seconde slide (tous). -->
		<super-tab>
			<ion-content #tousContainer>
				<ng-container *ngTemplateOutlet="content; context: { ionContent: tousContainer, index: 1 }">
				</ng-container>
			</ion-content>
		</super-tab>
	</super-tabs-container>
</super-tabs>

<ng-template #content let-ionContent="ionContent" let-contentIndex="index">

	<div class="ion-no-padding">
		<!-- Recherche. -->
		<div class="just-flex">
			<div class="search-box txt-center form-list width100pc">
				<search #search style="width: 100%" [data]="documents" [options]="searchOptions"
					(filteredEntriesChanged)="onFilteredDocumentsChanged($event)"
					(searchValueChange)="onSearchValueChanged($event)">
				</search>
			</div>
			<!-- Bouton de filtrage. -->
			<ion-button mode="md" (click)="swapFilterbarHiddenParams()" size="small" class="filter-button-right"
				color="osapp-element">
				<ion-icon slot="icon-only" [name]="filtersCount > 0 ? 'filter-fill' : 'filter'">
				</ion-icon>
				<span *ngIf="filtersCount > 0" class="badge">{{ filtersCount }}</span>
			</ion-button>
		</div>

		<osapp-selector [options]="groupFilterOptions" (selectionChanged)="onGroupFilterSelectionChanged($event, search)"
			displayMode="tags">
		</osapp-selector>
		<mat-slide-toggle labelPosition="before" [checked]="isToggleChecked" (toggleChange)="onToggleChanged()"
			color="primary">
			Afficher les codes actes
		</mat-slide-toggle>
	</div>

	<!-- Barre de filtrage. -->
	<ng-container *ngIf="filterbarParams[contentIndex] as currentFilterbarParams">
		<osapp-filterbar [filters]="currentFilterbarParams.filters" [hasResetButton]="currentFilterbarParams.hasResetButton"
			[hidden]="currentFilterbarParams.hidden" (onReset)="onFilterbarResetEvent($event)"
			(onFilterCountChanged)="onFilterCountChanged($event)" (onFilterValuesChanged)="onFilterValuesChanged($event)">
		</osapp-filterbar>
	</ng-container>

	<!-- Liste des traitements. -->
	<ion-virtual-scroll *ngIf="filteredDocuments?.length > 0" class="dis-flex flex-col marge-btm-list marg-container"
		[items]="filteredDocuments" [trackBy]="trackById" osappAutoCloseSlideItem [ionContent]="ionContent">
		<ion-item-sliding #itemSliding *virtualItem="let patientTraitement">

			<!-- Item à slider -->
			<ion-item class="ion-no-padding">
				<ion-grid (click)="onItemClicked(patientTraitement)" class="cursor treatment-item">
					<div class="pad-infos-traitements">
						<!-- Ligne Prénom et nom du patient -->
						<ion-row>
							<!-- Prénom NOM du patient. -->
							<ion-row size="auto">
								<ion-text *ngIf="!patientTraitement.noPatient; else noPatient">
									<ion-label>{{patientTraitement.patientLastName}}</ion-label>
									<ion-label class="first-name">{{patientTraitement.patientFirstName}}</ion-label>
								</ion-text>
								<ng-template #noPatient>
									<ion-label *ngIf="patientTraitement.noPatient" style="font-style: italic;">
										{{patientTraitement.noPatient}}
									</ion-label>
								</ng-template>
							</ion-row>
						</ion-row>

						<!-- Nombre de séances prévues et résumé des actes. -->
						<ion-row>
							<div class="seance-number">
								<ion-badge *ngIf="patientTraitement.actes.length === 0" class="warn-badge">!</ion-badge>
								<ion-note *ngIf="patientTraitement.actes.length === 0" class="warn-message">
									{{ 'Aucun acte enregistré (traitement vide)' }}
								</ion-note>
								<ion-note *ngIf="patientTraitement.actes.length > 0" class="number-seances">
									<span *ngIf="patientTraitement.nbSeances > 0">{{patientTraitement.nbSeances}}
										{{patientTraitement.nbSeances === 1 ? 'séance' : 'séances'}}</span>
									({{patientTraitement.actes | concatActes: "," : acteDisplayMode }})
								</ion-note>
							</div>
						</ion-row>

						<!-- Date de création du traitement. -->
						<ion-row>
							<div class="date-align">
								<ion-note class="txt10px marge-auto ion-no-padding" slot="end">
									{{patientTraitement.beginDate | date: 'dd/MM/yyyy'}}
								</ion-note>
								<div *ngIf="!isSameDate(patientTraitement)" class="vertical-line"></div>
								<ion-note *ngIf="!isSameDate(patientTraitement)" class="txt10px marge-auto ion-no-padding" slot="end">
									au {{(patientTraitement.endDate ? patientTraitement.endDate : patientTraitement.breakDate) | date:
									'dd/MM/yyyy'}}
								</ion-note>
							</div>
						</ion-row>
					</div>

					<!-- Icones status. -->
					<ion-row class="treatment-icons-area">
						<idl-traitements-list-marker [traitement]="patientTraitement"
							[ordonnances]="ordonnancesByTraitementId.get(patientTraitement._id)">
						</idl-traitements-list-marker>
					</ion-row>
				</ion-grid>

				<!-- Bouton d'options. -->
				<ion-button class="ion-no-margin btn-more" fill="clear" slot="end"
					(click)="openOrCloseItemSliding(itemSliding, $event)">
					<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
				</ion-button>
			</ion-item>

			<!-- Options des items. -->
			<ion-item-options side="end">
				<ion-button class="swipe-btn" color="danger" (click)="onRemoveTraitementClicked(patientTraitement, $event)">
					<ion-icon name="trash"></ion-icon>
					Supprimer
				</ion-button>
				<ion-button *ngIf="isAdmin" class="swipe-btn" color="primary"
					(click)="onItemOptionClicked(patientTraitement, 'duplicate', itemSliding)">
					<ion-icon name="duplicate"></ion-icon>
					Dupliquer
				</ion-button>
			</ion-item-options>

		</ion-item-sliding>
	</ion-virtual-scroll>

	<!-- Message si aucun résultat. -->
	<div *ngIf="!hasSearchResult" class="empty-screen flex-col">
		<span class="txt-message">
			<ion-icon style="margin: auto; margin-right: 1rem;" name="information-circle"></ion-icon>
			Aucun traitement.
		</span>
	</div>
</ng-template>