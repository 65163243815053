<!-- ZONE DE RECHERCHE -->
<div *ngIf="hasSearchbox" class="search-box txt-center form-list width100pc">
	<search #search class="search" [data]="documents" [options]="searchOptions"
		(filteredEntriesChanged)="onFilteredDocumentsChanged($event)"></search>
	<osapp-selector *ngIf="selectOptions" displayMode="tags" [options]="selectOptions"
		(selectionChanged)="onRoleSelectionChanged($event, search)" multiple>
	</osapp-selector>
</div>

<div *ngIf="displayFields" class="width100pc">
	<!-- CONTACT UTILISATEUR -->
	<ion-item *ngIf="userContact" (click)="onItemClicked(userContact)" class="user-contact" lines="none">
		<calao-conflict-list-marker *ngIf="userContact._conflicts"></calao-conflict-list-marker>
		<ng-container *ngIf="displayFields[0] as field">
			<ion-avatar *ngIf="field.isPicture" class="avatar" [calaoBadgeIcon]="params.userIcon"
				calaoBadgeIconPosition="below after" [calaoBadgeIconHidden]="!userContact.userId">
				<avatar [src]="getContactAvatar(userContact)" [cssClass]="field.cssClass" (srcError)="onImgError($event)">
				</avatar>
			</ion-avatar>
		</ng-container>
		<!-- Affichage des textes. -->
		<ion-text class="text-container">
			<ion-label ngClass="first-name">MOI</ion-label>
			<ion-note class="no-margin contact-groups">{{ userGroupsName }}</ion-note>
		</ion-text>

	</ion-item>
	<!-- AUTRES CONTACTS -->
	<ion-virtual-scroll *ngIf="filteredDocuments?.length > 0" class="dis-flex flex-col marge-btm-list"
		[items]="filteredDocuments" [trackBy]="trackById" osappAutoCloseSlideItem>
		<ion-item-sliding #itemSliding *virtualItem="let itemFormData"
			[class]='getItemCssClass ? getItemCssClass(itemFormData) : ""' [disabled]="params.swipeDisabled">
			<ion-item #item lines="full" (click)="onItemClicked(itemFormData)">
				<calao-conflict-list-marker *ngIf="itemFormData._conflicts"></calao-conflict-list-marker>
				<!-- Affichage de l'avatar si on a une image à afficher. -->
				<ng-container *ngIf="displayFields[0] as field">
					<ion-avatar *ngIf="field.isPicture" [calaoBadgeIcon]="params.userIcon" calaoBadgeIconPosition="below after"
						[calaoBadgeIconHidden]="!itemFormData.userId" class="avatar">
						<avatar [src]="getContactAvatar(itemFormData)" [cssClass]="field.cssClass"
							(srcError)="onImgError($event, itemFormData)"></avatar>
					</ion-avatar>
				</ng-container>

				<!-- Affichage des textes. -->
				<ion-text *ngIf="getContactItem(itemFormData) as itemData">
					<ion-label [ngClass]="itemData.mainCssClassContent">{{ itemData.mainContent }}</ion-label>
					<ion-label *ngIf="itemData.subContent" [ngClass]="itemData.subCssClassContent">
						{{ itemData.subContent }}
					</ion-label>
					<ion-note class="no-margin contact-groups">{{ itemData.groupsName }}</ion-note>
				</ion-text>

				<!-- Bouton d'options. -->
				<ion-button slot="end" class="itemb btn-more" fill="clear"
					*ngIf="itemSlidingEnabled && (!canDisplayOptions || canDisplayOptions(itemFormData))"
					(click)="openOrCloseItemSliding(itemSliding, $event)">
					<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
				</ion-button>

			</ion-item>

			<!-- Options des items. -->
			<ion-item-options *ngIf="!canDisplayOptions || canDisplayOptions(itemFormData)" side="end">
				<div *ngFor="let option of itemOptions">
					<ng-container *ngIf="option.action === 'call'; else elseTemplate">
						<ion-button class="swipe-btn" [color]="option.color ? option.color : 'primary'"
							[href]="'tel:' + itemFormData[option.fieldName]">
							<ion-icon [name]="option.icon"></ion-icon>
							{{option.label}}
						</ion-button>
					</ng-container>
					<ng-template #elseTemplate>
						<ion-button class="swipe-btn" [color]="option.color ? option.color : 'primary'"
							(click)="onItemOptionClicked(itemFormData, option.action, itemSliding, option)">
							<ion-icon [name]="option.icon"></ion-icon>
							{{option.label}}
						</ion-button>
					</ng-template>
				</div>
			</ion-item-options>
		</ion-item-sliding>
	</ion-virtual-scroll>
</div>

<!-- Si le composant a terminé de s'initialiser et qu'il y a une barre de recherche et qu'elle n'a retourné aucun résultat. -->
<div *ngIf="showNoResultMessage" class="empty-screen flex-col">
	<span class="txt-message">
		<ion-icon class="no-result-icon" name="information-circle"></ion-icon>
		Aucun résultat.
	</span>
</div>

<!-- Si aucun élément, pas de searchbox et un message de liste vide est défini. -->
<ion-label *ngIf="showEmptyMessage" class="ion-margin">
	{{ emptyMessage }}
</ion-label>