import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ImageOptions } from '@capacitor/camera';
import { Observable, Subscription, defer, from, of, throwError, timer } from 'rxjs';
import { catchError, concatMap, delay, filter, finalize, map, mergeMap, retryWhen, take, takeUntil, tap, toArray } from 'rxjs/operators';
import { ComponentBase } from '../../helpers/ComponentBase';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { FileHelper } from '../../helpers/fileHelper';
import { StringHelper } from '../../helpers/stringHelper';
import { ENetworkFlag } from '../../model/application/ENetworkFlag';
import { IFlag } from '../../model/flag/IFlag';
import { EGalleryCommand } from '../../model/gallery/EGalleryCommand';
import { EGalleryFilesChanged } from '../../model/gallery/EGalleryFilesChanged';
import { IGalleryCommand } from '../../model/gallery/IGalleryCommand';
import { IGalleryFile } from '../../model/gallery/IGalleryFile';
import { IGalleryParams } from '../../model/gallery/IGalleryParams';
import { IUiResponse } from '../../model/uiMessage/IUiResponse';
import { CameraService } from '../../modules/camera/services/camera.service';
import { DmsFile } from '../../modules/dms/model/DmsFile';
import { IDmsData } from '../../modules/dms/model/IDmsData';
import { DmsService } from '../../modules/dms/services/dms.service';
import { LongGuidBuilder } from '../../modules/guid/models/long-guid-builder';
import { ISelectOption } from '../../modules/selector/selector/ISelectOption';
import { ApplicationService } from '../../services/application.service';
import { GalleryService } from '../../services/gallery.service';
import { ShowMessageParamsPopup } from '../../services/interfaces/ShowMessageParamsPopup';
import { UiMessageService } from '../../services/uiMessage.service';
import { FilePickerComponent } from '../filePicker/filePicker.component';
import { EGalleryDisplayMode } from './models/EGalleryDisplayMode';
import { GedMetaDocument } from './models/GedMetaDocument';
import { ServerDmsBase64 } from './models/server-dms-base64';

/** Ce composant permet de gérer différents type de documents et leur synchronisation avec le DMS. */
@Component({
	selector: "gallery",
	templateUrl: './gallery.component.html',
	styleUrls: ['./gallery.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryComponent extends ComponentBase implements OnInit, AfterViewInit, IGalleryParams {

	//#region FIELDS

	/** Identifiant du composant pour les logs. */
	private static readonly C_LOG_ID = "GLR.C::";
	private static readonly C_DEFAULT_IMAGE_TYPE = "image/*";
	private static readonly C_DEFAULT_IMAGE_PICKER_ICON = "images";
	private static readonly C_DEFAULT_FILE_PICKER_ICON = "document";

	/** Indique si un fichier est en cours d'ouverture ou non dans toutes les galeries créées. */
	private static isFileOpening = false;

	/** Notifie que le composant a ajouté un ou plusieurs fichiers. */
	@Output("filesChanged") private readonly moFilesChangedEvent = new EventEmitter<EGalleryFilesChanged>();
	@Output("onFilesChanged") private readonly moOnFilesChangedEvent = new EventEmitter<IGalleryFile[]>();
	@Output("onFileDeleted") private readonly moOnFileDeletedEvent = new EventEmitter<IGalleryFile>();

	private maFilesToDelete: string[] = [];
	/** On doit garder un longGuidBuilder pour être compatible avec la GED. */
	private readonly moGuidBuilder = new LongGuidBuilder();

	//#endregion

	//#region PROPRTIES

	/** @implements */
	@Input() public displayMode: EGalleryDisplayMode = EGalleryDisplayMode.thumbnails;
	/** @implements */
	@Input() public fileFilterPattern?: string; // TODO : utiliser ce filtre dans l'input
	/** @implements */
	@Input() public maxSizeKb?: number;
	/** @implements */
	@Input() public readOnly?: boolean;
	/** @implements */
	@Input() public filePickerVisible?: boolean;
	/** @implements */
	@Input() public imagePickerVisible?: boolean;
	/** @implements */
	@Input() public filePickerIcon?: string;
	/** @implements */
	@Input() public imagePickerIcon?: string;
	/** @implements */
	@Input() public filePickerFilesButtonText?: string;
	/** @implements */
	@Input() public filePickerImagesButtonText?: string;
	/** @implements */
	@Input() public hideFileDetails?: boolean;
	/** @implements */
	@Input() public command$?: Observable<IGalleryCommand>;
	/** @implements */
	@Input() public guidWithHyphens?: boolean;
	/** @implements */
	@Input() public guidUpperCase = false;
	/** @implements */
	@Input() public cameraButtonVisible?: boolean;
	/** @implements */
	@Input() public cameraButtonText?: string;
	/** @implements */
	@Input() public cameraOptions?: ImageOptions;
	/** @implements */
	@Input() public acceptedFiles?: string;
	/** @implements */
	@Input() public acceptedImages?: string;
	/** @implements */
	@Input() public limit?: number;
	/** @implements */
	@Input() public hideNoFileText?: boolean;
	/** @implements */
	@Input() public allowCustomDescription?: boolean;
	/** @implements */
	@Input() public defaultCustomDescription?: string;
		/** @implements */
	@Input() public patientId?: string;
	/** @implements */
	@Input() public showDisplayModeSelector = false;
	/** @implements */
	@Input() public excludeFileGuids: string[] = [];
	@Input() public customButtonsTemplate: TemplateRef<any>;

	/** Liste des fichiers qu'il faut charger. */
	private maFiles: IGalleryFile[];
	public get files(): IGalleryFile[] { return this.maFiles; }
	/** @implements */
	@Input() public set files(paNewFiles: IGalleryFile[]) {
		if (!ArrayHelper.areArraysEqual(this.maFiles, paNewFiles, (poFileA: IGalleryFile, poFileB: IGalleryFile) => poFileA.guid === poFileB.guid)) {
			this.maFiles = paNewFiles;
			this.initFiles();
		}
	}

	/** `true` si la limite de fichiers est atteinte, `false` sinon. */
	public get reachedLimit(): boolean { return this.files?.length === this.limit; }

	@ViewChild("filePicker") public filePicker: FilePickerComponent;
	@ViewChild("imagePicker") public imagePicker: FilePickerComponent;

	/** Indique si le filepicker charge une image ou non. */
	public isLoading = false;
	/** Indique si internet est disponible pour le téléchargement d'image. */
	public networkAvailable = false;
	/** Paramètres du composant osapp-selector pour la selection du mode d'affichage. */
	public readonly displayModeOptions: ISelectOption[] = [
		{ label: "miniatures", value: "thumbnails" },
		{ label: "liste", value: "list" }
	];

	//#endregion

	//#region METHODS

	constructor(
		private isvcDms: DmsService,
		private isvcUiMessage: UiMessageService,
		private isvcApplication: ApplicationService,
		private isvcGallery: GalleryService,
		private isvcCamera: CameraService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poChangeDetectorRef);
	}

	public ngOnInit(): void {
		this.initSubscriptions();
		this.initInputs();
	}

	public ngAfterViewInit(): void {
		this.detectChanges();
		super.ngAfterViewInit();
	}

	/** Prend une photo à l'aide du plugin camera et l'ajoute au modèle. */
	public takePicture(): void {
		const loTimerSubscription: Subscription = timer(500).pipe(tap((pnValue: number) => this.onLoadingChanged(true)))
			.subscribe();

		this.isvcCamera.takePicture(this.cameraOptions, this.maxSizeKb)
			.pipe(
				tap(
					(poFile: File) => this.addToModel(poFile),
					poError => {
						console.error(GalleryComponent.C_LOG_ID, poError);
						this.onLoadingChanged(false); // Dans le cas d'une erreur, on enlève le loader.
						this.isvcUiMessage.showMessage(
							new ShowMessageParamsPopup({ message: "Une erreur est survenue lors du traitement de l'image.", header: "Erreur" })
						);
					}
				),
				takeUntil(this.destroyed$),
				finalize(() => loTimerSubscription.unsubscribe()) // On se désabonne du timer à la fin (prise de photo ou erreur).
			)
			.subscribe();
	}

	/** Initialise les fichiers de la galerie en les récupérant/téléchargeant s'il y en a. */
	private initFiles(): void {
		if (!this.files)
			this.files = [];

		defer(() => {
			this.files = this.prepareFiles(this.files);
			return from(this.files);
		})
			.pipe(
				filter((poGalleryFile: IGalleryFile) => !StringHelper.isBlank(poGalleryFile.guid)),
				tap((poFile: IGalleryFile) => poFile.isLoading = true),
				concatMap((poFile: IGalleryFile) => this.initGetFile(poFile)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private initGetFile(poFile: IGalleryFile): Observable<IDmsData> {
		return this.isvcDms.get(poFile.guid)
			.pipe(
				tap(
					(poGetResult: IDmsData) => {
						poFile.file = poGetResult.file;
						poFile.isAvailable = true;
						this.detectChanges();
					},
					poError => {
						poFile.isAvailable = false;
						console.error(GalleryComponent.C_LOG_ID, poError);
					}
				),
				retryWhen((poErrors$: Observable<any>) => poErrors$.pipe(take(2), delay(2000))),
				finalize(() => poFile.isLoading = false)
			);
	}

	/** Tranforme le format des fichiers de manière à ce qu'ils soient traités uniformément pour chaque version du logiciel.
	 * @param paFiles Liste des fichiers.
	 */
	private prepareFiles(paFiles: IGalleryFile[]): IGalleryFile[] {
		//! Pour la rétrocompatibilité.
		for (let index = 0; index < paFiles.length; index++) {
			// Remplace l'attribut "name" par l'attribut "label" s'il est présent.
			const lsName: string = paFiles[index].name;
			const lsLabel: string = (paFiles[index] as any).label;
			if (StringHelper.isBlank(lsName) && !StringHelper.isBlank(lsLabel))
				paFiles[index].name = lsLabel;

			// Remplace l'attribut "guid" par l'attribut "dmsId" s'il est présent.
			const lsGuid: string = paFiles[index].guid;
			const lsDmsId: string = (paFiles[index] as any).dmsId;
			if (StringHelper.isBlank(lsGuid) && !StringHelper.isBlank(lsDmsId))
				paFiles[index].guid = lsDmsId;
		}

		return paFiles;
	}

	/** Initialise les différents abonnements nécessaires au bon fonctionnement du composant. */
	private initSubscriptions(): void {
		// Abonnement pour le réseau.
		this.isvcApplication.observeFlag(ENetworkFlag.isOnlineReliable)
			.pipe(
				map((poFlag: IFlag) => poFlag.value),
				tap((pbValue: boolean) => {
					this.networkAvailable = pbValue;
					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		// Abonnement sur une gestion externe du composant via des commandes.
		if (this.command$) {
			this.command$
				.pipe(
					tap((poValue: IGalleryCommand) => this.manageReceivedCommand(poValue)),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
	}

	/** Initialise les différents inputs nécessaires au bon fonctionnement du composant. */
	private initInputs(): void {
		if (!this.maxSizeKb)
			this.maxSizeKb = CameraService.C_MAX_PICTURE_SIZE_KB;

		if (this.filePickerVisible === undefined)
			this.filePickerVisible = true;

		if (this.cameraButtonVisible === undefined)
			this.cameraButtonVisible = true;

		if (this.imagePickerVisible === undefined)
			this.imagePickerVisible = true;

		if (StringHelper.isBlank(this.acceptedImages))
			this.acceptedImages = GalleryComponent.C_DEFAULT_IMAGE_TYPE;

		if (StringHelper.isBlank(this.filePickerIcon))
			this.filePickerIcon = GalleryComponent.C_DEFAULT_FILE_PICKER_ICON;

		if (StringHelper.isBlank(this.imagePickerIcon))
			this.imagePickerIcon = GalleryComponent.C_DEFAULT_IMAGE_PICKER_ICON;

		this.cameraOptions = this.isvcCamera.getAndSetCameraOptions(this.cameraOptions);

		// Façon intéressante pour initialiser les inputs booléen, permet de les utiliser sans obligatoirement passer de valeur dans le template appelant.
		this.hideFileDetails = typeof this.hideFileDetails === "boolean" ? this.hideFileDetails : this.hideFileDetails !== undefined;
	}

	/** Gère les différentes actions possible en fonction de la commande reçue.
	 * @param poCommand Commande de la galerie à appliquer.
	 */
	private manageReceivedCommand(poCommand: IGalleryCommand): void {

		switch (poCommand.type) {

			case EGalleryCommand.saveFiles:
				this.onValidate(poCommand);
				break;

			case EGalleryCommand.pickFile:
				if (this.filePicker)
					this.filePicker.pickFiles();
				break;

			case EGalleryCommand.takePicture:
				this.takePicture();
				break;

			case EGalleryCommand.pickImage:
				if (this.imagePicker)
					this.imagePicker.pickFiles();
				break;
		}
	}

	/** Reçoit un événement qui indique que le chargement d'un fichier est en cours (`true`) ou non (`false`).
	 * @param pbIsLoading Indique si le composant est en train de charger une image ou non.
	 */
	public onLoadingChanged(pbIsLoading: boolean): void {
		if (this.isLoading !== pbIsLoading) {
			this.isLoading = pbIsLoading;
			this.detectChanges();
		}
	}

	/** Traite les fichiers lorsque l'enregistrement est demandé.
	 * @param poCommand Objet permettant d'exécuter une méthode après la fin des enregistrements si renseignée.
	 */
	public onValidate(poCommand: IGalleryCommand): void {
		if (ArrayHelper.hasElements(this.files)) {
			this.saveFiles(poCommand);
			this.removeFiles();
		}
	}

	/** Enregistre les fichiers dans le dms.
	 * @param poCommand Objet permettant de lancer une méthode à la fin des enregistrement si renseignée.
	 */
	private saveFiles(poCommand: IGalleryCommand): void {
		from(this.files)
			.pipe(
				filter((poFile: IGalleryFile) => poFile.isNew || !poFile.isAvailable),
				tap((poFile: IGalleryFile) => this.isvcGallery.cleanGalleryFile(poFile)),
				mergeMap((poFile: IGalleryFile) => {
					return this.isvcDms.save(poFile.file, poFile.file.createDmsMeta(poFile.guid))
						.pipe(
							catchError(poError => {
								console.error(`${GalleryComponent.C_LOG_ID}Error when submitting file '${poFile.name}'.`, poError);
								this.isvcUiMessage.showMessage(
									new ShowMessageParamsPopup({ header: "Erreur de téléversement", message: `Erreur lors de la soumission du fichier ${poFile.name}.` })
								);
								return throwError(poError);
							})
						);
				}),
				toArray(),
				tap(_ => {
					if (poCommand.callback)
						poCommand.callback();

					ArrayHelper.clear(this.files);
				})
			)
			.subscribe();
	}

	/** Supprime les fichiers et les méta associés. */
	private removeFiles(): void {
		if (ArrayHelper.hasElements(this.maFilesToDelete)) {
			const laFailedDeleteFiles: string[] = []; // Tableau des fichiers qui n'ont pas pu être supprimés.

			from(this.maFilesToDelete)
				.pipe(
					mergeMap((psIdToDelete: string) => this.isvcDms.delete(psIdToDelete)
						.pipe(
							catchError((poError: any) => {
								console.error(`${GalleryComponent.C_LOG_ID}Error when deleting file '${psIdToDelete}'.`, poError);

								this.isvcUiMessage.showMessage(
									new ShowMessageParamsPopup({
										header: "Erreur de suppression",
										message: "Erreur lors de la suppression du fichier.",
										buttons: [{ text: "Ok" }]
									})
								);

								laFailedDeleteFiles.push(psIdToDelete); // On ajoute les identifiants de fichiers qui n'ont pas pu être supprimés.
								return of(undefined);
							})
						)),
					toArray(),
					// Le tableau de fichiers à supprimer est vide si les suppressions se sont bien passées, ou contient les fichiers qui n'ont pu être supprimés.
					tap(_ => this.maFilesToDelete = laFailedDeleteFiles),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
	}

	/** Fonction d'ajout au modèle du fichier séléctionné.
	 * @param poFile Objet correspondant au fichier sélectionné.
	 */
	public async addToModel(poFile: File): Promise<void> {
		if (poFile) {
			const loDmsFile: DmsFile = new DmsFile(poFile, poFile.name);
			const loGalleryFile: IGalleryFile = {
				file: loDmsFile,
				isNew: true,
				name: loDmsFile.Name,
				description: "",
				guid: this.moGuidBuilder.build({ withHyphens: this.guidWithHyphens, upperCase: this.guidUpperCase })
			};

			if (this.allowCustomDescription)
				await this.editFileDescription(loGalleryFile).toPromise();

			this.files.push(loGalleryFile);

			this.emitFilesChangedEvent(); // Évite de lever un événement si on n'a pas ajouté de fichier finalement.
		}

		this.onLoadingChanged(false);
		this.detectChanges();
	}

	private editFileDescription(poGalleryFile: IGalleryFile): Observable<IUiResponse<any, { description: string; }>> {
		const loPopupParams = new ShowMessageParamsPopup({
			header: "Nom du document",
			inputs: [{ type: "text", name: "description", value: StringHelper.isBlank(poGalleryFile.description) ? this.defaultCustomDescription : poGalleryFile.description }],
			buttons: [{ text: "Annuler", role: "cancel" }, { text: "Valider", role: "submit" }]
		});

		return this.isvcUiMessage.showAsyncMessage<any, { description: string }>(loPopupParams)
			.pipe(
				tap((poResponse: IUiResponse<any, { description: string }>) => {
					if (!StringHelper.isBlank(poResponse.values?.description)) {
						poGalleryFile.description = poResponse.values.description;
					};
				}),
				takeUntil(this.destroyed$)
			);
	}

	public editDescription(poFile: IGalleryFile): void {
		if (this.allowCustomDescription) {
			const lsTempDescription: string = poFile.description;

			this.editFileDescription(poFile)
				.pipe(
					tap(() => {
						if (lsTempDescription !== poFile.description)
							this.emitFilesChangedEvent();
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		};
	}

	/** Supprime le fichier uploadé du modèle en fonction de l'index de celui-ci.
	 * @param pnIndex Index correspondant au fichier qu'il faut supprimer.
	 */
	public deleteItem(pnIndex: number): void {
		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({
				header: "Suppression",
				message: "Êtes-vous sûr de vouloir supprimer cet élément ?",
				buttons: [
					{ text: "Annuler" },
					{ text: "Supprimer", handler: () => this.deleteFileAt(pnIndex) }
				]
			})
		);
	}

	/** Supprime du model l'item à l'index donné tout en mettant de côté cet item s'il a été sauvegardé dans le DMS pour le supprimer
	 * de la bdd locale et sur le serveur lors de l'enregistrement.
	 */
	private deleteFileAt(pnIndex: number): void {
		const loItem: IGalleryFile = this.files[pnIndex];

		if (!loItem.isNew)
			this.maFilesToDelete.push(loItem.guid);

		this.files.splice(pnIndex, 1); // Retire 1 élément à l'index pnIndex du tableau.

		this.moOnFileDeletedEvent.next(loItem);
		this.emitFilesChangedEvent();
	}

	/** Ouvre le fichier selectioné avec l'application par défaut du smartphone.
	 * @param pnItemIndex Index de l'item.
	 */
	public openFileAt(pnItemIndex: number): void {
		if (!GalleryComponent.isFileOpening) { // Si on n'a pas d'ouverture de fichier en cours alors on peut ouvrir celui-ci, sinon, il faut attendre.
			const loCurrentFile: IGalleryFile = this.files[pnItemIndex];

			this.isvcGallery.openFile(loCurrentFile, GalleryComponent.isFileOpening)
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
	}

	/** Récupère le nom du fichier sans son extension.
	 * @param psItemName Nom du fichier.
	 */
	public getItemNameWithoutExtension(psItemName: string): string {
		return FileHelper.getFileNameWithoutExtension(psItemName);
	}

	/** Récupère l'extension du fichier à partir de son nom, `undefined` si non trouvé.
	 * @param psItemName Nom du fichier.
	 */
	public getItemExtensionFromName(psItemName: string): string | undefined {
		const lsFileExtension: string | undefined = FileHelper.getFileExtensionFromFileName(psItemName);
		return lsFileExtension ? `.${lsFileExtension}` : undefined;
	}

	/** Émet un événement indiquant que les fichiers de la galerie ont changé. */
	private emitFilesChangedEvent(): void {
		let leEmitValue: EGalleryFilesChanged;

		if (this.files.length === 0)
			leEmitValue = EGalleryFilesChanged.NoFile;
		else if (this.files.length === 1)
			leEmitValue = EGalleryFilesChanged.OneFile;
		else
			leEmitValue = EGalleryFilesChanged.SomeFiles;

		this.moOnFilesChangedEvent.emit(this.files);
		this.moFilesChangedEvent.emit(leEmitValue);
	}


	public async selectImageDossierPatient() : Promise<void>{
		let listDoc = await this.isvcGallery.consulterDmsPatient(this.patientId);
		let list : GedMetaDocument[];
		if(listDoc){
			if(!Array.isArray(listDoc.Document))
			{
				list = [listDoc.Document];
			}
			else
			{
				list = listDoc.Document;
			}
			let document : ServerDmsBase64 = await this.isvcGallery.openModalDms(this.patientId,list);
			if(document){
				const blob : Blob = FileHelper.base64toBlob(document.document,FileHelper.extractMimeTypeFromFileNameWithExtension(document.name),document.size )
				const file = FileHelper.blobToFile(blob,undefined,document.name)
				await this.addToModel(file);
			}
		}
	}
	//#endregion
}
