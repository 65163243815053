import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ArrayHelper, ComponentBase } from '@osapp/helpers';
import { IContact } from '@osapp/model/contacts/IContact';
import { ContactsService } from '@osapp/services/contacts.service';
import { takeUntil, tap } from 'rxjs/operators';
import { Acte } from '../../../../model/Acte';
import { Traitement } from '../../../../model/Traitement';
import { ConcatActesPipe } from '../../../actes/concat-actes.pipe';
import { EResumeActeMode } from '../../../actes/model/EResumeActeMode';
import { Ordonnance } from '../../models/ordonnance';
import { OrdonnancesService } from '../../services/ordonnances.service';

@Component({
	selector: 'idl-ordonnances-gallery',
	templateUrl: './ordonnances-gallery.component.html',
	styleUrls: ['./ordonnances-gallery.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdonnancesGalleryComponent extends ComponentBase {

	//#region FIELDS

	private moActesByOrdonnanceId: Map<string, string[]>;

	//#endregion

	//#region PROPERTIES

	private maOrdonnances: Ordonnance[];
	public get ordonnances(): Ordonnance[] { return this.maOrdonnances; }
	@Input()
	public set ordonnances(paOrdonnances: Ordonnance[]) {
		if (paOrdonnances !== this.maOrdonnances) {
			this.maOrdonnances = paOrdonnances;
			this.moActesByOrdonnanceId = this.isvcOrdonnances.getActesByOrdonnanceId(this.ordonnances);
			this.setContacts();
		}
	}

	@Input() public traitement: Traitement;
	@Input() public excludeOrdonnancesIds: string[];

	/** Map des contacts liés aux ordonnances. */
	public contactsById = new Map<string, IContact>();

	@Output("onLinkToActClicked") private readonly moOnLinkToActClickedEvent = new EventEmitter<string>();

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcOrdonnances: OrdonnancesService,
		private readonly isvcContacts: ContactsService,
		private readonly ioConcatActesPipe: ConcatActesPipe,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poChangeDetectorRef);
	}

	public setContacts(): void {
		const laContactsIds: string[] = ArrayHelper.unique(this.ordonnances.map((poOrdonnance: Ordonnance) => poOrdonnance.prescripteurContactId));

		if (ArrayHelper.hasElements(laContactsIds)) {
			this.isvcContacts.getContactsByIds(laContactsIds)
				.pipe(
					tap((paContacts: IContact[]) => {
						paContacts.forEach((poContact: IContact) => this.contactsById.set(poContact._id, poContact));
						this.detectChanges();
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
	}

	public getResumeActes(poOrdonnance: Ordonnance): string {
		const laActesIds: string[] = this.moActesByOrdonnanceId.get(poOrdonnance._id);
		if (ArrayHelper.hasElements(laActesIds)) {
			const laActes: Acte[] = laActesIds.map((psId: string) => this.traitement.actes.find((poActe: Acte) => poActe.guid === psId)).filter((poActe: Acte) => !!poActe);
			return this.ioConcatActesPipe.transform(laActes, ",", EResumeActeMode.lettreCle);
		}
		return "";
	}

	public openOrdonnance(poOrdonnance: Ordonnance): void {
		this.isvcOrdonnances.editOrdonnance(this.traitement, poOrdonnance)
			.pipe(takeUntil(this.destroyed$))
			.subscribe();
	}

	public onLinkToActClicked(poOrdonnance: Ordonnance, pbForceActeWithoutOrdonnanceSelection?: boolean): void {
		const laOldActesGuids: string[] = this.traitement.actes?.map((poActe: Acte) => poActe.guid) ?? [];

		this.isvcOrdonnances.linkActesToOrdonnanceWithSelector(poOrdonnance, this.traitement,
			this.moActesByOrdonnanceId, pbForceActeWithoutOrdonnanceSelection)
			.pipe(
				tap(() => {
					const laNewActesGuids: string[] = ArrayHelper.getDifferences(this.traitement.actes?.map((poActe: Acte) => poActe.guid), laOldActesGuids);
					let lsLastCreatedActeGuid: string;
					if (ArrayHelper.hasElements(laNewActesGuids))
						lsLastCreatedActeGuid = ArrayHelper.getLastElement(laNewActesGuids);
					this.moOnLinkToActClickedEvent.next(lsLastCreatedActeGuid);
				})
			).subscribe();
	}

	/** Supprime l'ordonnance.
	 * @param poOrdonnance
	 */
	public async onDeleteOrdonnance(poOrdonnance: Ordonnance): Promise<void> {
		await this.isvcOrdonnances.deleteOrdonnance(poOrdonnance).toPromise();
	}

	//#endregion

}
