import { Injectable } from '@angular/core';
import { EModalSize } from '@osapp/modules/modal';
import { ModalService } from '@osapp/modules/modal/services/modal.service';
import { Observable } from 'rxjs';
import { Traitement } from '../../../../../model/Traitement';
import { Ordonnance } from '../../../models/ordonnance';
import { OrdonnancesGallerySelectorModalComponent } from '../ordonnances-gallery-selector-modal.component';

@Injectable()
export class OrdonnanceGallerySelectorModalOpenerService {

	//#region METHODS

	constructor(private readonly isvcModal: ModalService) { }

	public open(poTraitement: Traitement, paOrdonnances: Ordonnance[], paPreselectedOrdonnancesIds?: string[], pbUnique: boolean = false): Observable<Ordonnance[]> {
		return this.isvcModal.open<Ordonnance[]>({
			component: OrdonnancesGallerySelectorModalComponent,
			componentProps: {
				ordonnances: paOrdonnances,
				traitement: poTraitement,
				preselectedOrdonnancesIds: paPreselectedOrdonnancesIds,
				customTitle: "Ordonnances",
				unique: pbUnique
			}
		}, EModalSize.medium);
	}

	//#endregion

}
