import { DateHelper } from "@osapp/helpers";
import { ArrayHelper } from "@osapp/helpers/arrayHelper";
import { IdHelper } from "@osapp/helpers/idHelper";
import { StringHelper } from "@osapp/helpers/stringHelper";
import { Traitement } from "apps/idl/src/model/Traitement";
import { Exclude } from "class-transformer";
import { IInterventionStatement } from "./iintervention-statement";
import { IInterventionStatementActe } from "./iintervention-statement-acte";

export class InterventionStatement implements IInterventionStatement {

	//#region PROPERTIES

	/** @implements */
	public _id: string;
	/** @implements */
	public _rev?: string;
	/** @implements */
	public _deleted?: boolean;
	/** @implements */
	public deleted?: boolean;
	/** @implements */
	public _conflicts?: string[];
	/** @implements */
	public actes: IInterventionStatementActe[];
	/** @implements */
	public intervenantId: string;

	@Exclude()
	private mdInterventionDate: Date;
	/** Date de l'intervention. */
	public get interventionDate(): Date {
		if (!this.mdInterventionDate)
			this.mdInterventionDate = DateHelper.parseReverseDate(this.interventionDateString);

		return this.mdInterventionDate;
	}

	@Exclude()
	private mdInterventionDateString: string;
	/** Date de l'intervention. */
	public get interventionDateString(): string {
		if (!this.mdInterventionDateString)
			this.mdInterventionDateString = InterventionStatement.extractInterventionDateString(this._id);

		return this.mdInterventionDateString;
	}

	@Exclude()
	private mdCreateDate: Date;
	/** Date de création du relevé d'intervention. */
	public get createDate(): Date {
		if (!this.mdCreateDate)
			this.mdCreateDate = InterventionStatement.extractCreateDate(this._id);

		return this.mdCreateDate;
	}

	@Exclude()
	private msSiteId: string;
	/** Id du Site */
	public get siteId(): string {
		if (!this.msSiteId)
			this.msSiteId = InterventionStatement.extractSiteId(this._id);

		return this.msSiteId;
	}

	@Exclude()
	private msTraitementId: string;
	/** Id du Traitement. */
	public get traitementId(): string {
		if (!this.msTraitementId)
			this.msTraitementId = InterventionStatement.extractTraitementId(this._id);

		return this.msTraitementId;
	}

	@Exclude()
	private msPatientId: string;
	/** Id du Patient. */
	public get patientId(): string {
		if (!this.msPatientId)
			this.msPatientId = Traitement.extractPatientId(this.traitementId);

		return this.msPatientId;
	}

	public get canceled(): boolean {
		return this.actes.every((poActe: IInterventionStatementActe) => !StringHelper.isBlank(poActe.skipReason));
	}

	//#endregion

	//#region METHODS

	public static extractCreateDate(psInterventionStatementId: string): Date {
		return ArrayHelper.getLastElement(IdHelper.extractDatesFromId(psInterventionStatementId));
	}

	public static extractInterventionDate(psInterventionStatementId: string): Date {
		return ArrayHelper.getFirstElement(IdHelper.extractDatesFromId(psInterventionStatementId));
	}

	public static extractInterventionDateString(psInterventionStatementId: string): string {
		return ArrayHelper.getFirstElement(IdHelper.extractDatesStringsFromId(psInterventionStatementId));
	}

	public static extractSiteId(psInterventionStatementId: string): string {
		return Traitement.extractSiteId(this.extractTraitementId(psInterventionStatementId));
	}

	public static extractPatientId(psInterventionStatementId: string): string {
		return Traitement.extractPatientId(this.extractTraitementId(psInterventionStatementId));
	}

	public static extractTraitementId(psInterventionStatementId: string): string {
		const lsParentId: string = IdHelper.extractParentId(psInterventionStatementId);
		const lsSiteGuid: string = IdHelper.getLastGuidFromId(Traitement.extractSiteId(lsParentId));

		return lsParentId.replace(new RegExp(`${lsSiteGuid}${IdHelper.C_VIRTUAL_NODE_SEPARATOR}\\w+${IdHelper.C_VIRTUAL_NODE_SEPARATOR}`),
			`${lsSiteGuid}${IdHelper.C_VIRTUAL_NODE_SEPARATOR}`);
	}

	//#endregion

}
