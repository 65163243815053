import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EPathologie } from 'apps/idl/src/model/EPathologies';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { PanneauService } from '../../../services/panneau.service';

@Component({
  selector: 'di-panneau-patient-allergies',
  templateUrl: './panneau-patient-allergies.component.html',
  styleUrls: ['./panneau-patient-allergies.component.scss'],
})
export class PanneauPatientAllergiesComponent implements OnInit {

  @Input()
  public patient: IPatient;

  public model: IPatient;

  public allergiesForm: FormGroup;


  constructor(private svcPatients: PatientsService, private svcPanneau: PanneauService, private fb: FormBuilder) { }

  ngOnInit() {
    this.allergiesForm = this.fb.group({
      allergies: [this.patient.allergies || ""],
      antecedentsPersonnels: [this.patient.personalant || ""],
      antecedentsFamiliaux: [this.patient.familyant || ""],
      facteursRisques: [this.patient.riskfactor || ""],
      vaccinations: [this.patient.vaccination || ""],
    });
    this.model = this.patient;
  }

  public goBack(): void {
    this.svcPanneau.close();
  }

  public handleSubmit(event: Event): void {
    event.preventDefault();
    if (this.allergiesForm.valid) {
      const formValues = this.allergiesForm.value;
      this.model = {
        ...this.model,
        allergies: formValues.allergies,
        personalant: formValues.antecedentsPersonnels,
        familyant: formValues.antecedentsFamiliaux,
        riskfactor: formValues.facteursRisques,
        vaccination: formValues.vaccinations
      }
      this.svcPatients.savePatientAnakin(this.model).subscribe(() => {
        this.svcPatients.saveAdressCacheData(this.model);
        this.svcPanneau.close();
      })
    }
  }

}
