import { DateHelper } from '@osapp/helpers/dateHelper';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { ModelMatch } from '@osapp/modules/utils/models/decorators/model-match.decorator';
import { ActeOccurrenceComparator } from './acte-occurrence-comparator';
import { EOccurrenceType } from './EOccurrenceType';
import { IActeOccurrence } from './iacte-occurrence';
import { IDateRangeComparatorParams } from './idate-range-comparator-params';

const C_TYPE = EOccurrenceType.range;

@ModelMatch((poData: ActeOccurrenceRangeComparator) => poData?.type === C_TYPE, ActeOccurrenceComparator)
export class ActeOccurrenceRangeComparator extends ActeOccurrenceComparator<IDateRangeComparatorParams> {

	//#region PROPERTIES

	public readonly type: EOccurrenceType = C_TYPE;

	//#endregion

	//#region METHODS

	/** @override */
	public getLabel(): string {
		return (DateHelper.isDate(this.params?.from) ? ` du ${DateHelper.transform(this.params.from, ETimetablePattern.dd_MM_yyyy_HH_mm_slash)} ` : "") +
			(DateHelper.isDate(this.params?.to) ? `jusqu'au ${DateHelper.transform(this.params.to, ETimetablePattern.dd_MM_yyyy_HH_mm_slash)}` : "jusqu'à la fin du traitement");
	}

	/** @override */
	public match(poActeOccurrence: IActeOccurrence): boolean {
		return DateHelper.isBetweenTwoDates(poActeOccurrence.date, this.params?.from, this.params?.to);
	}

	//#endregion

}
