import { Injectable } from '@angular/core';
import { IdHelper } from '@osapp/helpers/idHelper';
import { IEntity } from '@osapp/model/entities/IEntity';
import { MetaEntityBuilderBase } from '@osapp/modules/dms/model/MetaEntityBuilderBase';
import { C_PREFIX_INVOICE } from '../../../app/app.constants';
import { Invoice } from '../../facturation/models/invoice';

@Injectable()
export class MetaInvoiceBuilder extends MetaEntityBuilderBase {

	//#region METHODS

	/** @override */
	public match(psDocumentId: string): boolean {
		return IdHelper.hasPrefixId(psDocumentId, C_PREFIX_INVOICE);
	}

	/** @override */
	protected getEntityId(poEntity: IEntity<Invoice>): Promise<string> {
		return Promise.resolve(poEntity.model.patientId);
	}

	/** @override */
	protected getEntityDocumentSubType(): string {
		return "OFS";
	}

	//#endregion
}
