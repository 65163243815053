import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { IUiResponse } from '@osapp/model/uiMessage/IUiResponse';
import { Loader } from '@osapp/modules/loading/Loader';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '@osapp/services/loading.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { from, Observable, of } from 'rxjs';
import { finalize, map, mergeMap, tap } from 'rxjs/operators';
import { IPatient } from '../modules/patients/model/IPatient';
import { PatientsService } from '../modules/patients/services/patients.service';
import { TraitementService } from '../services/traitement.service';

@Injectable({ providedIn: "root" })
export class FirstRdvGuard implements CanActivate {

	//#region PROPERTIES

	/** Phrase indiquant que des traitements sont en cours pour un patient. */
	public static readonly C_ONGOING_TRAITEMENTS_SENTENCE = "Des traitements sont en cours pour ce patient.\nSélectionnez un traitement existant ou créez-en un nouveau.";

	//#endregion

	//#region METHODS

	constructor(private isvcPatients: PatientsService, private isvcTraitement: TraitementService, private isvcUiMessage: UiMessageService, private isvcLoading: LoadingService) { }

	/** Garde du routage. */
	public canActivate(poRoute: ActivatedRouteSnapshot): Observable<boolean> {
		this.createNewTraitement(poRoute.queryParamMap.get("date"));
		return of(false);
	}

	/** Crée un nouveau traitement. */
	private createNewTraitement(psTraitememntStartDate: string): void {
		let loLoader: Loader;
		this.isvcPatients.selectPatient("Patient", true)
			.pipe(
				mergeMap((poSelectedPatient: IPatient) => from(this.isvcLoading.create("Chargement en cours..."))
					.pipe(
						tap((poLoader: Loader) => loLoader = poLoader),
						mergeMap(() => loLoader.present()),
						mergeMap(() => this.isvcTraitement.checkOngoingTraitements(poSelectedPatient)),
						mergeMap((pbHasOngoingTraitements: boolean) => pbHasOngoingTraitements ? this.displayCreateNewTraitementWarning() : of(true)),
						map((pbHaveToCreate: boolean) => pbHaveToCreate ? this.isvcTraitement.navigateToNewTraitement(poSelectedPatient, psTraitememntStartDate, true) : null),
					)
				),
				tap(() => loLoader.dismiss()),
				finalize(() => loLoader?.dismiss())
			)
			.subscribe();
	}

	/** Affiche un avertissement à l'utilisateur indiquant que des traitements sont déjà en cours. */
	private displayCreateNewTraitementWarning(): Observable<boolean> {
		return this.isvcUiMessage.showAsyncMessage(
			new ShowMessageParamsPopup({
				header: "Attention",
				message: FirstRdvGuard.C_ONGOING_TRAITEMENTS_SENTENCE,
				buttons: [
					{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() },
					{ text: "Continuer", handler: () => UiMessageService.getTruthyResponse() }
				],
				cssClass: "z-index-max"
			})
		)
			.pipe(map((poResult: IUiResponse<boolean>) => poResult.response));
	}

	//#endregion

}