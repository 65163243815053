<div class="top-toolbar">
	<div class="navigation flex">
		<ion-button (click)="addWeeks(-1)" color="osapp-element" fill="clear" shape="round">
			<ion-icon slot="icon-only" name="arrow-back"></ion-icon>
		</ion-button>
		<div class="flex date-navigation" *ngIf="!!C_PLANNING_HEADER_TITLE">
			<p>Planning du <span class="date">{{ C_PLANNING_HEADER_TITLE['monday'] | dateWithLocale : 'fr-FR' :
					C_PLANNING_TITLE_DATE_FORMAT }}</span>
				au <span class="date">{{ C_PLANNING_HEADER_TITLE['sunday'] | dateWithLocale : 'fr-FR' :
					C_PLANNING_TITLE_DATE_FORMAT
					}}
				</span>
			</p>
			<ion-button (click)="onChoosePlanningDate(planningDatePicker)" size="small" color="osapp-element" fill="clear"
				shape="round">
				<ion-icon slot="icon-only" name="calendar"></ion-icon>
			</ion-button>
			<osapp-date-time #planningDatePicker class="hidden" [model]="date" [params]="datePickerParams"
				(modelChange)="onDateChanged($event)">
			</osapp-date-time>
		</div>
		<ion-button (click)="addWeeks(1)" color="osapp-element" fill="clear" shape="round">
			<ion-icon slot="icon-only" name="arrow-forward"></ion-icon>
		</ion-button>
	</div>
	<ion-buttons *ngIf="planningRH">
		<ion-button (click)="routeToTournee()" color="osapp-element" fill="clear" shape="round"
			matTooltip="Ouvrir la tournée">
			<ion-icon slot="icon-only" name="timetable"></ion-icon>
		</ion-button>
		<ion-button (click)="createOrOpenConversation()" color="osapp-element" fill="clear" shape="round"
			matTooltip="Ouvrir une conversation">
			<ion-icon slot="icon-only" name="chatbubbles"></ion-icon>
		</ion-button>
		<ion-button (click)="showDuplicationModal()" color="osapp-element" fill="clear" shape="round"
			matTooltip="Dupliquer le planning">
			<ion-icon slot="icon-only" name="copy"></ion-icon>
		</ion-button>
		<ion-button (click)="clearPlanning()" color="osapp-element" fill="clear" shape="round"
			matTooltip="Effacer le planning">
			<ion-icon slot="icon-only" name="eraser"></ion-icon>
		</ion-button>
		<ion-button (click)="deletePlanning()" color="danger" fill="clear" shape="round" matTooltip="Supprimer le planning">
			<ion-icon slot="icon-only" name="trash"></ion-icon>
		</ion-button>
	</ion-buttons>
</div>

<osapp-selector [preselectedValues]="preselectedSectorsIds" [options]="sectorsOptions" displayMode="tags"
	(selectionChanged)="onSectorSelectionChanged($event)" multiple>
</osapp-selector>

<div *ngIf="planningRH; else noPlanning;" cdkDropListGroup class="content-planning">
	<div *ngFor="let sector of filteredSectors" class="planning">
		<div class="left-table">
			<div class="sector" [style.background]="sector.color">
				<div class="sector-label">
					<ion-label>{{ sector.name }}</ion-label>
				</div>
			</div>
			<div class="contacts-basket">
				<div class="avatars-container drop-list-wrapper" cdkDropList id="basket" [cdkDropListOrientation]=""
					[cdkDropListData]="contactsBySectorIds?.get(sector._id)" (cdkDropListDropped)="onElementDropped($event)">
					<ng-container *ngFor="let contactId of contactsBySectorIds?.get(sector._id)">
						<avatar cssClass="round" *ngIf="contactsByIds?.get(contactId) as contacts"
							(click)="showPlanificationModal(contacts[0]?.groupMember, sector._id)" [src]="contacts[0]?.avatar"
							#tooltip="matTooltip" [matTooltip]="contacts[0]?.groupMember | contactName" cdkDrag></avatar>
					</ng-container>
				</div>
				<div class="button-container">
					<ion-button (click)="clearSector(sector._id)" color="osapp-element" fill="clear" shape="round"
						matTooltip="Vider les affectations du secteur">
						<ion-icon slot="start" name="eraser"></ion-icon>
						<ion-label>Effacer</ion-label>
					</ion-button>
				</div>
			</div>
		</div>

		<div>
			<mat-table [dataSource]="getFilteredDataSource(slotsDataSource, sector._id)">
				<ng-container *ngFor="let column of displayedColumns; let columnIndex = index;">
					<ng-container [matColumnDef]="column">
						<mat-header-cell *matHeaderCellDef [class]="columnIndex === 0 ? 'first-header-column' : ''">
							<p class="header-title">
								{{ C_PLANNING_HEADER_TITLE[column] | dateWithLocale : 'fr-FR' : C_PLANNING_HEADER_DATE_FORMAT |
								titlecase }}
							</p>
						</mat-header-cell>
						<mat-cell *matCellDef="let rowData; let rowIndex = index" [class]="getCellCssClass(columnIndex, rowIndex)">
							<ng-container *ngIf="column !== 'slotLabel'; else slotLabelCell;">
								<ng-container
									*ngIf="this.getCellAffectation(sector._id, C_PLANNING_HEADER_TITLE[column], rowData.slotId) as affectation; else newCell">
									<ng-container *ngIf="affectation.contactIds?.length > 0; else emptyCell">
										<div class="drop-list-wrapper">
											<ng-container *ngFor="let contactId of affectation.contactIds; let i = index">
												<div class="avatar-wrapper" cdkDropList [cdkDropListData]="affectation.contactIds" [id]="i"
													(cdkDropListDropped)="onElementDropped($event, affectation.contactIds)"
													*ngIf="contactsByIds?.get(contactId) as contacts">
													<avatar cssClass="round" [src]="contacts[0]?.avatar" #tooltip="matTooltip"
														[matTooltip]="contacts[0]?.groupMember | contactName" cdkDrag>
													</avatar>
												</div>
											</ng-container>
										</div>
									</ng-container>
									<ng-template #emptyCell>
										<div class="emptyCell" cdkDropList [id]="0" [cdkDropListData]="affectation.contactIds"
											(cdkDropListDropped)="onElementDropped($event, affectation.contactIds)">
										</div>
									</ng-template>
								</ng-container>

								<ng-template #newCell>
									<div class="emptyCell" cdkDropList
										(cdkDropListDropped)="createAffectation($event, sector._id, C_PLANNING_HEADER_TITLE[column], rowData.slotId)">
									</div>
								</ng-template>
							</ng-container>

							<ng-template #slotLabelCell>
								<div class="slot-cell flex">
									<div class="time">
										<ion-label>{{ rowData.label }}</ion-label>
										<ion-label class="hour">{{ rowData.startHour + "h-" + rowData.endHour + "h"}}</ion-label>
									</div>
									<div class="just-flex">
										<ion-button (click)="showSlotEditModal(sector._id, rowData)" size="small" fill="clear"
											color="osapp-element">
											<ion-icon slot="icon-only" name="create"></ion-icon>
										</ion-button>

										<ion-button (click)="presentDeleteSlotPopup(rowData, sector._id)" size="small" fill="clear"
											color="danger">
											<ion-icon slot="icon-only" name="trash"></ion-icon>
										</ion-button>
									</div>
								</div>
							</ng-template>

						</mat-cell>
					</ng-container>
				</ng-container>

				<mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row [class]="getRowCssClass(rowIndex)" mat-row
					*matRowDef="let row; let rowIndex = index; columns: displayedColumns;"></mat-row>
			</mat-table>

			<div class="add-row">
				<ion-fab-button (click)="showSlotEditModal(sector._id)" color="fab-button" shape="round">
					<ion-icon name="add"></ion-icon>
				</ion-fab-button>
			</div>
		</div>
	</div>
</div>

<ng-template #noPlanning>
	<div class="no-planning">
		<div class="content">
			<p>Planning inexistant</p>
			<ion-fab-button (click)="createPlanning()" color="success" shape="round">
				<ion-icon name="add-outline"></ion-icon>
			</ion-fab-button>
			<p>Créer ce planning</p>
		</div>
	</div>
</ng-template>