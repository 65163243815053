import { ChangeDetectorRef, Component } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { DynamicPageComponent } from '../components/dynamicPage/dynamicPage.component';
import { ToolbarComponent } from '../components/toolbar/toolbar.component';
import { ILifeCycleEvent } from '../model/lifeCycle/ILifeCycleEvent';
import { ComponentBase } from './ComponentBase';
import { LifeCycleObserver } from './LifeCycleObserver';

@Component({ template: "" })
export abstract class LifeCycleObserverComponentBase extends ComponentBase {

	//#region FIELDS

	protected moLifeCycleObs: LifeCycleObserver;
	protected moParentPage: DynamicPageComponent<ComponentBase>;

	//#endregion

	//#region METHODS

	constructor(
		poParentPage: DynamicPageComponent<ComponentBase>,
		poChangeDetectorRef?: ChangeDetectorRef) {

		super(poChangeDetectorRef);

		this.moParentPage = poParentPage;
		this.msInstanceId = `${this.constructor["name"]}-${this.msInstanceId}`;

		this.moLifeCycleObs = new LifeCycleObserver();

		this.moLifeCycleObs.setComponent(this);

		if (this.moParentPage)
			this.moParentPage.getNavigatingObservable().pipe(takeUntil(this.destroyed$)).subscribe((psNextUrl: string) => this.onNavigating(psNextUrl));
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.destroyLifeCycleSubscription();
	}

	/** Se désabonne du cycle de vie ionic. */
	protected destroyLifeCycleSubscription(): void {
		this.moLifeCycleObs.unsubscribeLifeCycle();
	}

	/** Exécutée lors de la réception d'un événement.
	 * @param poEvent Événement reçu.
	 */
	protected abstract onLifeCycleEvent(poEvent: ILifeCycleEvent): void;

	/** Exécutée lors de la réception d'une erreur.
	 * @param poEvent Événement reçu.
	 */
	protected onLifeCycleError(poError: any): void { /*Rien*/ };

	/** Modifie la valeur de l'instanceId du composant et modifie l'instance de l'observeur du cycle de vie pour le garder à jour.
	 * @param psId Nouvel identifiant de l'instance unique du composant.
	 */
	protected setInstanceId(psId: string): void {
		this.msInstanceId = psId;
	}

	public getParentPage(): DynamicPageComponent<ComponentBase> {
		return this.moParentPage;
	}

	/** Envoie un événement de type lifeCycleEvent à tous les abonnés aux événements.
	 * @param poEvent
	 * @param peEvent
	 * @param psPageId
	 */
	public raiseLifeCycleEvent(poEvent: ILifeCycleEvent): void {
		console.debug(`LCB.C::${poEvent.data.pageId}/${this.msInstanceId}\t${poEvent.data.value}\tHandling ${poEvent.data.value} event from ${poEvent.data.pageId}.`);
		this.onLifeCycleEvent(poEvent);
	}

	protected getParentToolbar(): ToolbarComponent {
		return this.getParentPage().toolbar;
	}

	/** Exécutée lors de la navigation.
	 * ### ATTENTION : Il faut utiliser la méthode `this.moParentPage.allowNavigation()` pour permettre de terminer la navigation ou non.
	 */
	protected onNavigating(psNextUrl?: string): void {
		this.moParentPage.allowNavigation(true);
	}

	//#endregion
}
