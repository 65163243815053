import { DateHelper } from '@osapp/helpers/dateHelper';
import { NumberHelper } from '@osapp/helpers/numberHelper';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { ModelMatch } from '@osapp/modules/utils/models/decorators/model-match.decorator';
import { ActeOccurrenceComparator } from './acte-occurrence-comparator';
import { EOccurrenceType } from './EOccurrenceType';
import { IActeOccurrence } from './iacte-occurrence';
import { IActeOccurenceDateTimeComparatorParams } from './iacte-occurrence-date-time-comparator-params';

const C_TYPE = EOccurrenceType.datetime;

@ModelMatch((poData: ActeOccurrenceDateTimeComparator) => poData?.type === C_TYPE, ActeOccurrenceComparator)
export class ActeOccurrenceDateTimeComparator extends ActeOccurrenceComparator<IActeOccurenceDateTimeComparatorParams> {

	//#region PROPERTIES

	public readonly type: EOccurrenceType = C_TYPE;

	//#endregion

	//#region METHODS

	/** @override */
	public getLabel(): string {
		const ldDate: Date = DateHelper.isDate(this.params?.day) ? new Date(this.params.day) : new Date();
		if (DateHelper.isDate(this.params?.day))
			return `du ${DateHelper.transform(ldDate, ETimetablePattern.dd_MM_yyyy_HH_mm_slash)}`;
		else {
			ldDate.setHours(this.params?.hours ?? ldDate.getHours(), this.params?.minutes ?? ldDate.getMinutes());
			return `de ${DateHelper.transform(ldDate, ETimetablePattern.HH_mm)}`;
		};
	}

	/** @override */
	public match(poActeOccurrence: IActeOccurrence): boolean {
		return (!DateHelper.isDate(this.params?.day) || DateHelper.areDayEqual(poActeOccurrence.date, this.params?.day)) &&
			(!NumberHelper.isValid(this.params?.hours) || this.params.hours === poActeOccurrence.date.getHours()) &&
			(!NumberHelper.isValid(this.params?.minutes) || this.params.minutes === poActeOccurrence.date.getMinutes());
	}

	//#endregion

}