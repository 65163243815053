import { DateHelper } from "@osapp/helpers/dateHelper";
import { ResolveModel } from "@osapp/modules/utils/models/decorators/resolve-model.decorator";
import { IRange } from "@osapp/modules/utils/models/models/irange";
import { Exclude, Expose, Transform, TransformFnParams } from "class-transformer";
import { IExtraCharge } from "./iextra-charge";

export class ExtraCharge<T extends string = string> implements IExtraCharge<T> {

	//#region PROPERTIES

	/** @implements */
	public id: T;

	/** @implements */
	@Transform(({ value }: TransformFnParams) => value || undefined, { toPlainOnly: true }) // On ignore si false pour ne pas charger les documents inutilement.
	public disabled: boolean;

	@Exclude()
	private moMatcher: Date | IRange<Date>;
	/** @implements */
	@Expose()
	public get matcher(): Date | IRange<Date> { return this.moMatcher; }
	public set matcher(poMatcher: Date | IRange<Date>) {
		if (poMatcher !== this.moMatcher) {
			if (typeof poMatcher === "string") {
				if (DateHelper.isDate(poMatcher))
					this.moMatcher = new Date(poMatcher);
			}
			else
				this.moMatcher = poMatcher;
		}
	}

	/** @implements */
	public createUserContactId: string;

	/** @implements */
	public createDate: Date;

	@ResolveModel(Date)
	/** @implements */
	public updateDate?: Date;

	/** @implements */
	public updateUserContactId?: string;

	//#endregion

	//#region METHODS

	public match(pdDate: Date): boolean {
		if (!this.matcher)
			return true;
		else if (this.matcher instanceof Date)
			return DateHelper.diffMinutes(this.matcher, pdDate) === 0;
		else
			return DateHelper.isBetweenTwoDates(pdDate, this.matcher.from, this.matcher.to);
	}

	//#endregion

}
