<div class="di-interruption-ordonnance">
	<div class="dialog-header">
		<h4>{{ title }}</h4>
		<lua-bouton-icone
			*ngIf="withIcon"
			(click)="onCancel()"
			iconName="Close"
			mode="filled"
			[color]="'CouleurPrimaire'"
		></lua-bouton-icone>
	</div>
	<div class="dialog-content">
		<p>{{ description }}</p>
		<lua-input
			label="Commentaire"
			[multiline]="true"
			[fullWidth]="true"
		></lua-input>
		<lua-action-principale
			[libelle]="labelActionPrincipale"
			[fullWidth]="true"
			(click)="onConfirm()"
		></lua-action-principale>
		<lua-bouton-texte
			libelle="Annuler"
			[color]="'CouleurPrimaire'"
			[fullWidth]="true"
			[isDisabled]="false"
			[endIcon]="''"
			[type]="'button'"
			[size]="'medium'"
			(click)="onCancel()"
		></lua-bouton-texte>
	</div>
</div>
