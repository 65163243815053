import { Pipe, PipeTransform } from '@angular/core';
import { NumberHelper } from '../helpers/numberHelper';

@Pipe({ name: "priceFormat" })
export class PriceFormatPipe implements PipeTransform {

	//#region METHODS

	/** Transforme un prix avec un nombre de décimale passé en paramètre.
	 * Usage: variableBindée | priceFormat: décimales
	 * Exemple: {{ 42.13374269 | priceFormat: 4 }}
	 * => 42,1337
	 * @param poPrice Prix à transformer.
	 * @param pnTrunkValue Nombre de décimale.
	 */
	public transform(poPrice: number | string, pnTrunkValue: number): string {
		return NumberHelper.round(poPrice, pnTrunkValue)
	}

	//#endregion

}