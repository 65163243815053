import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { NavigationEnd, Router } from '@angular/router';
import { IonItemSliding } from '@ionic/angular';
import { IDateRange } from '@osapp/components/date/date-range-picker/model/IDateRange';
import { SearchComponent } from '@osapp/components/search/search.component';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { EnumHelper } from '@osapp/helpers/enumHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { IContact } from '@osapp/model/contacts/IContact';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { EAvatarSize } from '@osapp/model/picture/EAvatarSize';
import { IAvatar } from '@osapp/model/picture/IAvatar';
import { ISearchOptions } from '@osapp/model/search/ISearchOptions';
import { IFilterValuesChangedEvent } from '@osapp/modules/filter/model/IFilterValuesChangedEvent';
import { IFilterbarOptions } from '@osapp/modules/filter/model/IFilterbarOptions';
import { IFilterbarParams } from '@osapp/modules/filter/model/IFilterbarParams';
import { Loader } from '@osapp/modules/loading/Loader';
import { ModalService } from '@osapp/modules/modal/services/modal.service';
import { ObservableArray } from '@osapp/modules/observable/models/observable-array';
import { IHasPermission, Permissions, PermissionsService } from '@osapp/modules/permissions/services/permissions.service';
import { EPrestationStatus } from '@osapp/modules/prestation/models/eprestation-status.enum';
import { IPrestationLine } from '@osapp/modules/prestation/models/iprestation-line';
import { PrestationService } from '@osapp/modules/prestation/services/prestation.service';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { ContactsService } from '@osapp/services/contacts.service';
import { LoadingService } from '@osapp/services/loading.service';
import { Observable, combineLatest, defer, from, of } from 'rxjs';
import { filter, finalize, map, mapTo, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { C_DESMOS_PERMISSION_ID, C_PREFIX_PATIENT } from '../../../app/app.constants';
import { Acte } from '../../../model/Acte';
import { IIdelizyContact } from '../../../model/IIdelizyContact';
import { ISeancesExportRow } from '../../../model/ISeancesExportRow';
import { ITraitement } from '../../../model/ITraitement';
import { Traitement } from '../../../model/Traitement';
import { TraitementDatesPipe } from '../../../pipes/traitementDates.pipe';
import { SeanceService } from '../../../services/seance.service';
import { TraitementService } from '../../../services/traitement.service';
import { ActesService } from '../../actes/actes.service';
import { Ordonnance } from '../../ordonnances/models/ordonnance';
import { OrdonnancesService } from '../../ordonnances/services/ordonnances.service';
import { IPatient } from '../../patients/model/IPatient';
import { AMCP } from '../../patients/model/amc-p';
import { ISortedCouvertures } from '../../patients/model/isorted-couvertures';
import { CouverturesService } from '../../patients/services/couvertures.service';
import { PatientsService } from '../../patients/services/patients.service';
import { ETraitementTags } from '../../traitement/model/ETraitementTags';
import { TraitementPrestationsModalComponent } from '../components/traitement-prestations-modal/traitement-prestations-modal.component';
import { FacturationService } from '../facturation.service';
import { EIdlPrestationLineCategory } from '../models/eidl-prestation-line-category.enum';
import { EInvoiceStatus } from '../models/einvoice-status';
import { IdlPrestation } from '../models/idl-prestation';
import { IdlPrestationLine } from '../models/idl-prestation-line';
import { IIdlPrestationLine } from '../models/iidl-prestation-line';
import { Invoice } from '../models/invoice';
import { IPaginatorInfos } from '../models/ipaginator-infos';

@Component({
	template: ""
})
export abstract class InvoicingComponentBase extends ComponentBase implements OnInit, IHasPermission {

	//#region FIELDS

	private readonly C_MAX_SMALL_SCREEN_SIZE = 930;

	/** Tableau de tous les ids de patients. */
	private maAllPatientsIds = new Set<string>();

	/** Tableau de tous les contacts. */
	private maAllContacts: IPatient[];
	/** Tableau de tous les ids de contacts. */
	private maAllContactsIds = new Set<string>();

	/** Tableau de tous les ids d'ordonnances. */
	private maAllOrdonnancesIds = new Set<string>();

	/** Tableau de toutes les factures. */
	private maAllInvoices: Invoice[] = [];

	/** Tableau de toutes les prestations. */
	private maAllPrestations: IdlPrestation[] = [];
	/** Tableau de tous les traitements en cours*/
	private tabAllTraitementEnCours: Traitement[] = [];

	/** Tableau des patients sélectionnés pour le filtrage. */
	private maFilterSelectedPatients: IPatient[] = [];
	/** Tableau des contacts intervenants sélectionnés pour le filtrage. */
	private maFilterSelectedIntervenants: IIdelizyContact[] = [];
	/** Tableau des autres filtres possibles, ici 'Acte non défini' ou 'Observations'. */
	private maFilterSelectedOthers: string[] = [];
	/** Tableau des états des prestations à afficher. */
	private maSelectedStatus: EPrestationStatus[];

	/** Tableau de tous les tags traitement. */
	private maTraitementTagsKeys: string[] = EnumHelper.getKeys(ETraitementTags);

	/**Tableau qui va définir les id de traitements */
	private maTraitementIds: string[] = [];

	//#endregion

	//#region PROPERTIES

	/** Tableau de tous les patients. */
	public patients: IPatient[] = [];
	/** Tableau des patients à afficher. */
	public filteredPatients = new ObservableArray<IPatient>([]);
	/** Liste des items à afficher par id de patient. */
	public itemsbyPatientId = new Map<string, Array<{ traitementId: string, prestations: IdlPrestation[] } | Invoice | Traitement>>();
	/** Prestations triées par traitementId et patient. */
	public prestationsDataByPatientId = new Map<string, { traitementId: string, prestations: IdlPrestation[] }[]>();
	/** Factures triées par patient. */
	public invoicesByPatientId: Map<string, Invoice[]>;
	/**Traitements triés par patient */
	public traitementByPatientId: Map<string, Traitement[]>;
	/** Ordonnances triées par traitement. */
	public ordonnancesByTraitementId = new Map<string, Ordonnance[]>();

	/** Map associant un id de contact avec un contact. */
	public contactsById = new Map<string, IContact>();
	/** Map associant un id de traitement avec un traitement. */
	public traitementsById = new Map<string, Traitement>();
	/** Map associant un id d'ordonnance avec une ordonnance. */
	public ordonnancesById = new Map<string, Ordonnance>();

	@Permissions("create", C_DESMOS_PERMISSION_ID)
	public get canBillToDesmos(): boolean {
		return true;
	}

	public get canBillToFsv(): boolean {
		return true;
	}

	public get isModeAgrement(): boolean {
		return false;
	}

	private moSearchComponent: SearchComponent<IPatient>;
	public get searchComponent(): SearchComponent<IPatient> {
		return this.moSearchComponent;
	}
	@ViewChild("search") public set searchComponent(poSearchComponent: SearchComponent<IPatient>) {
		if (poSearchComponent !== this.moSearchComponent) {
			this.moSearchComponent = poSearchComponent;
			this.setSearchInputListener();
		}
	}
	public isSearchOpen: boolean;
	private mbIsSmallScreen: boolean = window.innerWidth < this.C_MAX_SMALL_SCREEN_SIZE;
	public get isSmallScreen(): boolean { return this.mbIsSmallScreen; }

	/** Valeur recherchée dans l'input de recherche. */
	public searchValue: string;
	/** Options de recherche. */
	public searchOptions: ISearchOptions<IPatient> = {
		hasPreFillData: true,
		searchboxPlaceholder: "Rechercher un patient",
		searchFunction: (poPatient: IPatient, psSearchValue: string) => this.isvcFacturation.patientMatchSearchValue(psSearchValue, poPatient)
	};
	/** Paramètres pour la barre de filtrage. */
	public filterbarParams: IFilterbarParams;
	/** Intervalle de dates sélectionnée pour le filtrage. */
	public filterRangeDates?: IDateRange;
	/** Nombre de filtres en cours. */
	public filtersCount: number;

	public readonly seanceStatusOptions: ReadonlyArray<ISelectOption<EPrestationStatus>> = [
		{ label: "Réalisé", value: EPrestationStatus.created },
		{ label: "Annulé", value: EPrestationStatus.canceled },
		{ label: "Vérifié", value: EPrestationStatus.checked },
		{ label: "Bloqué", value: EPrestationStatus.locked }
	];
	public readonly preselectedPrestationStatus: EPrestationStatus[] = [EPrestationStatus.created, EPrestationStatus.canceled, EPrestationStatus.checked, EPrestationStatus.locked];

	/** Composants de pagination. */
	@ViewChild("paginator") public paginator: MatPaginator;
	public nbItemsByPage = 10;
	public startIndex = 0;

	//#endregion

	//#region METHODS

	constructor(
		public readonly isvcSeance: SeanceService,
		public readonly isvcActes: ActesService,
		public readonly ioTraitementsDatesPipe: TraitementDatesPipe,
		public readonly isvcPrestations: PrestationService,
		public readonly isvcPatients: PatientsService,
		public readonly isvcContacts: ContactsService,
		public readonly isvcFacturation: FacturationService,
		public readonly isvcOrdonnances: OrdonnancesService,
		public readonly isvcTraitement: TraitementService,
		public readonly isvcPermissions: PermissionsService,
		public readonly isvcModal: ModalService,
		public readonly isvcLoading: LoadingService,
		public readonly isvcCouvertures: CouverturesService,
		public readonly ioRouter: Router,
		poChangeDetectorRef: ChangeDetectorRef,
	) {
		super(poChangeDetectorRef);
	}

	public ngOnInit(): void {
		this.initFilterbarParams();
	}

	public init() {
		//Permet de mettre à jour la ligne lorsque l'on revient de la facturation (permet de gérer l'annulation de la facture)
		this.ioRouter.events.pipe(
			filter(event => event instanceof NavigationEnd),
			filter((event: NavigationEnd) => event.url === "/facturation"),
			tap(() => this.majLignesFactures()),
			takeUntil(this.destroyed$)
		).subscribe();

		return combineLatest([
			this.getPrestations(),
			this.canBillToFsv || this.isModeAgrement ? this.getInvoices() : of(undefined),
			this.getTraitementsEnCours()
		])
			.pipe(
				switchMap(() => this.getTraitementIds()),
				tap(() => this.prepare()),
				switchMap(() => combineLatest([
					this.getPatients(),
					this.getOrdonnances()
				])),
				switchMap(() => this.getContacts()),
				tap(() => {
					this.onFilteredPatientsChanged(Array.from(this.patients.sort((poPatientA: IPatient, poPatientB: IPatient) => poPatientA.lastName.toLowerCase().localeCompare(poPatientB.lastName.toLowerCase()))));
					this.refresh();
					this.detectChanges();
				})
			);
	}

	public onSeancesValidees(traitementAMaj: Traitement): void {
		//Une fois que les séances ont été validées, il faut mettre à jour la nouvelle ligne de prestation en mettant à jour la liste de traitements récupérés lorsque l'on déplie le panel du patient dans la page de facturation
		//si jamais on a fermé la pop après le clic sur "Facturer" d'une ligne de traitement en cours		
		if (!this.traitementsById.has(traitementAMaj._id)) {
			this.traitementsById.set(traitementAMaj._id, traitementAMaj);
		}
	}

	private getTraitementIds(): Observable<string[]> {
		return this.isvcTraitement.getTraitementIds(
			[...this.maAllPrestations.map((prestation) => {
				return prestation.traitementId;
			}), ...this.maAllInvoices.map((invoice) => {
				return invoice.traitementId;
			})]).pipe(
				tap((paTraitementIds) => {
					this.maTraitementIds = paTraitementIds;
				})
			);
	}

	/** Initialise les paramètres de filtrage. */
	private initFilterbarParams(): void {
		this.resetCurrentFilters();

		this.filterbarParams = {
			filters: this.createFilterbarOptions(this.maFilterSelectedIntervenants),
			hasResetButton: true,
			hidden: true
		};

		this.detectChanges();
	}

	/** Crée les différentes options de filtrage. */
	public createFilterbarOptions(paSelectedIntervenants: IContact[]): IFilterbarOptions[] {
		return [
			this.isvcFacturation.createDatesRangeFilterbarOptions(),
			this.isvcFacturation.createIntervenantFilterbarOptions(paSelectedIntervenants),
		];
	}

	public onInvoiceCanceled(invoice: Invoice): void {
		//On met à jour les lignes de factures annulées (qui avaient été facturées)
		if (invoice && invoice.status.value === EInvoiceStatus.checked) {
			this.majLignesFactures();
		}
		//On met à jour les lignes de factures annulées (qui avaient été facturées)
		if (invoice && invoice.status.value === EInvoiceStatus.aborted) {
			this.ngOnInit()
		}
	}

	private majLignesFactures() {
		this.getInvoices().pipe(
			tap(() => {
				this.prepare();
				this.refresh();
			}),
			tap(() => this.detectChanges()),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	public onPanelOpened(poPatient: IPatient): void {
		let loLoader: Loader;
		const laPrestations: IdlPrestation[] = this.maAllPrestations.filter((poPrestation: IdlPrestation) => poPrestation.customerId === poPatient._id);
		const laTraitementIds: string[] = [];
		laPrestations.forEach((poPrestation: IdlPrestation) => {
			if (!this.traitementsById.has(poPrestation.traitementId) && !laTraitementIds.includes(poPrestation.traitementId))
				laTraitementIds.push(poPrestation.traitementId);
		});

		if (ArrayHelper.hasElements(laTraitementIds))
			defer(() => this.isvcLoading.create("Chargement en cours..."))
				.pipe(
					mergeMap((poLoader: Loader) => poLoader.present()),
					tap((poLoader: Loader) => loLoader = poLoader),
					mergeMap(() => this.isvcTraitement.getTraitementByIds(laTraitementIds)),
					tap((paTraitements: Traitement[]) => {
						paTraitements.forEach((poTraitement: Traitement) => this.traitementsById.set(poTraitement._id, poTraitement));
						this.detectChanges();
					}),
					mergeMap(() => loLoader.dismiss()),
					finalize(() => loLoader?.dismiss()),
					takeUntil(this.destroyed$)
				)
				.subscribe();
	}

	private refresh(): void {
		this.fillPrestations(this.maAllPrestations);
		this.itemsbyPatientId.clear();
		this.regrouperTraitementParPatient();

		this.patients?.forEach((poPatient: IPatient) => {
			let laPrestationsData: { traitementId: string; prestations: IdlPrestation[]; }[] = this.prestationsDataByPatientId.get(poPatient._id) ?? [];
			laPrestationsData = laPrestationsData.filter((poItem: { traitementId: string; prestations: IdlPrestation[]; }) => ArrayHelper.hasElements(poItem.prestations));

			const loAMCP: AMCP = ArrayHelper.getFirstElement(poPatient.AMC);
			const laInvoices: Invoice[] = FacturationService.sortInvoices(this.invoicesByPatientId?.get(poPatient._id) ?? []);
			laInvoices.forEach((poInvoice: Invoice) => this.isvcFacturation.fillInvoiceSTSAndGU(poInvoice, loAMCP));

			//récupération des traitements en cours par patient
			const traitementsDuPatient: Traitement[] = this.traitementByPatientId.get(poPatient._id) ?? [];
			//On tri les lignes du plus ancien traitement en cours au plus récent
			const traitementsEnCours: Traitement[] = ArrayHelper.dynamicSort(traitementsDuPatient, "beginDate") ?? [];

			this.itemsbyPatientId.set(poPatient._id, [...laPrestationsData, ...traitementsEnCours, ...laInvoices]);
		});
	}

	private regrouperTraitementParPatient(): void {

		this.traitementByPatientId = this.tabAllTraitementEnCours.reduce((mapTraitParPat, trait) => {
			const key = trait.patientId;

			if (!mapTraitParPat.has(key)) {
				mapTraitParPat.set(key, [trait]);
			} else {
				mapTraitParPat.get(key)?.push(trait);
			}

			return mapTraitParPat;
		}, new Map<string, Traitement[]>());

	}

	private prepare(): void {
		this.maAllInvoices = this.maAllInvoices.filter((poInvoice) => {
			if (!this.maTraitementIds.includes(poInvoice.traitementId)) return false;

			if (!StringHelper.isBlank(poInvoice.intervenantId))
				this.maAllContactsIds.add(poInvoice.intervenantId);

			this.maAllPatientsIds.add(poInvoice.patientId);
			this.maAllOrdonnancesIds.add(poInvoice.ordonnanceId);

			if (this.invoicesByPatientId.has(poInvoice.patientId)) {
				const invoiceByPatient: Invoice[] = this.invoicesByPatientId.get(poInvoice.patientId);
				if (!invoiceByPatient.includes(poInvoice)) invoiceByPatient.push(poInvoice);
			}
			else {
				this.invoicesByPatientId.set(poInvoice.patientId, [poInvoice]);
			}

			return true;
		})

		this.maAllPrestations = this.maAllPrestations.filter((poPrestation) => {
			return this.maTraitementIds.includes(poPrestation.traitementId) && poPrestation.lines.some((prestaLigne) => { return prestaLigne.total > 0 });
		})

		//On ajoute les patients qui n'ont pas déjà été facturés + qui n'ont pas de prestation (séances validées)
		this.tabAllTraitementEnCours.forEach((trait: Traitement) => {
			if (!this.maAllPatientsIds.has(trait.patientId)) {
				this.maAllPatientsIds.add(trait.patientId);
			}
		})

		this.fillPrestations(this.maAllPrestations);
	}

	private getInvoices(pbClosedInvoices?: boolean): Observable<Invoice[]> {
    return this.isvcFacturation.getInvoices(true)
        .pipe(
            map((paInvoices: Invoice[]) => {
                // Filtrer les factures annulées
                const filteredInvoices = paInvoices.filter((poInvoice: Invoice) => !poInvoice.isCancel);
                
                // Vérifier s'il y a des factures non annulées
                if (filteredInvoices.length > 0) {
                    this.invoicesByPatientId = new Map();
                    const laInvoices: Invoice[] = [];

                    filteredInvoices.forEach((poInvoice: Invoice) => {
                        if (pbClosedInvoices ? poInvoice.isClosed : !poInvoice.isClosed) {
                            laInvoices.push(poInvoice);
                        }
                    });

                    return this.maAllInvoices = laInvoices;
                } else {
                    return [];
                }
            })
        );
}

	private getPrestations(pbSentPrestations?: boolean): Observable<IdlPrestation[]> {
		return this.isvcPrestations.getPrestations({ customerIdOrPrefix: C_PREFIX_PATIENT }, null, null, true)
			.pipe(
				map((paPrestations: IdlPrestation[]) => {
					return this.maAllPrestations = paPrestations.filter((poPrestation: IdlPrestation) => pbSentPrestations ? poPrestation.status === EPrestationStatus.sent : poPrestation.status !== EPrestationStatus.sent);
				})
			);
	}

	/** 
	 * Récupère la liste des traitements en cours  
	 */
	private getTraitementsEnCours(): Observable<Traitement[]> {
		return this.isvcTraitement.getTraitementsEnCours().pipe(
			tap((traitementsTab: Traitement[]) => {
				this.traitementByPatientId = new Map();
				this.tabAllTraitementEnCours = [...traitementsTab]; // copie des traitements
			})
		);
	}

	private fillPrestations(paPrestations: IdlPrestation[]): void {
		paPrestations = this.applyFilterValues(paPrestations);
		const loPrestationsByTraitementId = new Map<string, IdlPrestation[]>();
		this.prestationsDataByPatientId.clear();

		paPrestations.forEach((poPrestation: IdlPrestation) => {
			const lsPatientId: string = poPrestation.customerId;
			const lsTraitementId: string = poPrestation.traitementId;

			if (loPrestationsByTraitementId.has(lsTraitementId))
				loPrestationsByTraitementId.get(lsTraitementId).push(poPrestation);
			else
				loPrestationsByTraitementId.set(lsTraitementId, [poPrestation]);

			this.maAllPatientsIds.add(lsPatientId);
			this.maAllContactsIds.add(poPrestation.vendorId);
			poPrestation.lines.forEach((poLine: IIdlPrestationLine) => {
				if (poLine.ordonnanceId)
					this.maAllOrdonnancesIds.add(poLine.ordonnanceId);
			});
		});

		loPrestationsByTraitementId.forEach((paTraitementPrestations: IdlPrestation[], psTraitementId) => {
			const lsPatientId: string = IdHelper.extractAllIds(psTraitementId).find((psId: string) => psId.startsWith(C_PREFIX_PATIENT));

			if (this.prestationsDataByPatientId.has(lsPatientId))
				this.prestationsDataByPatientId.get(lsPatientId).push({ traitementId: psTraitementId, prestations: paTraitementPrestations });
			else
				this.prestationsDataByPatientId.set(lsPatientId, [{ traitementId: psTraitementId, prestations: paTraitementPrestations }]);
		});
	}

	private getPatients(): Observable<IPatient[]> {
		if (this.maAllPatientsIds.size === 0)
			return of([]);
		return this.isvcPatients.getPatientsByIds(Array.from(this.maAllPatientsIds))
			.pipe(
				tap((paPatients: IPatient[]) => this.patients = paPatients),
				mergeMap(() => from(this.patients)),
				mergeMap((poPatient: IPatient) => this.isModeAgrement ? of(undefined) : this.isvcCouvertures.getPatientLastSortedCouvertures(poPatient._id)
					.pipe(
						tap((poCouvertures?: ISortedCouvertures) => {
							if (poCouvertures) {
								poPatient.AMO = poCouvertures.AMOP ? [poCouvertures.AMOP] : [];
								poPatient.AMC = poCouvertures.AMCP ? [poCouvertures.AMCP] : [];
							}
						})
					)
				),
				mapTo(this.patients)
			);
	}

	private getOrdonnances(): Observable<Ordonnance[]> {
		this.ordonnancesByTraitementId.clear();

		if (this.maAllOrdonnancesIds.size === 0)
			return of([]);
		

		return this.isvcOrdonnances.getOrdonnancesByIds(Array.from(this.maAllOrdonnancesIds))
			.pipe(
				tap((paOrdonnances: Ordonnance[]) => {
					paOrdonnances.forEach((poOrdonnance: Ordonnance) => {
						if (poOrdonnance.prescripteurContactId)
							this.maAllContactsIds.add(poOrdonnance.prescripteurContactId);

						this.ordonnancesById.set(poOrdonnance._id, poOrdonnance);

					 	if (this.ordonnancesByTraitementId.has(poOrdonnance.traitementId))
								
							{
    
						 if (! this.ordonnancesByTraitementId.get(poOrdonnance.traitementId).some(ord => ord._id === poOrdonnance._id)) {
						this.ordonnancesByTraitementId.get(poOrdonnance.traitementId).push(poOrdonnance);
						 }
						} 
						else
							this.ordonnancesByTraitementId.set(poOrdonnance.traitementId, [poOrdonnance]); 
					});
				})
			);
	}

	private getContacts(): Observable<IContact[]> {
		if (this.maAllContactsIds.size === 0)
			return of([]);
		return this.isvcContacts.getContactsByIds(Array.from(this.maAllContactsIds))
			.pipe(
				tap((paIntervenants: IContact[]) => {
					this.maAllContacts = paIntervenants;
					this.contactsById = new Map(this.maAllContacts.map((poContact: IContact) => [poContact._id, poContact]));
				})
			);
	}

	public openPrestations(poTraitement: Traitement, paPrestations: IdlPrestation[], pbSelectAll?: boolean): void {
		this.isvcModal.open({
			component: TraitementPrestationsModalComponent,
			componentProps: {
				traitement: poTraitement,
				prestations: paPrestations,
				selectAll: pbSelectAll
			}
		})
			.pipe(
				tap(() => this.detectChanges()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}


	public onFilteredPatientsChanged(paFilteredPatients: IPatient[]): void {
		const laNewPatientIds: string[] = paFilteredPatients.map((poPatient: IPatient) => poPatient._id);
		const laCurrentPatientIds: string[] = this.filteredPatients.map((poPatient: IPatient) => poPatient._id);
		const laPatientIdsToAdd: string[] = ArrayHelper.getDifferences(laNewPatientIds, laCurrentPatientIds);
		const laPatientIdsToRemove: string[] = ArrayHelper.getDifferences(laCurrentPatientIds, laNewPatientIds);

		this.filteredPatients.push(...laPatientIdsToAdd.map((psId: string) => paFilteredPatients.find((poPatient: IPatient) => poPatient._id === psId)));
		laPatientIdsToRemove.forEach((psId: string) => ArrayHelper.removeElementById(this.filteredPatients, psId));
		this.resetPaginator();
		this.detectChanges();
	}

	public getPrestationsIntervenants(paPrestations: IdlPrestation[]): IContact[] {
		const laIntervenantsIds: string[] = paPrestations.map((poPrestation: IdlPrestation) => poPrestation.vendorId);
		return [...ArrayHelper.unique(laIntervenantsIds).map((psId: string) => this.contactsById.get(psId))];
	}

	public getContactAvatar(poContact: IContact): IAvatar {
		return ContactsService.createContactAvatar(poContact, EAvatarSize.big);
	}

	/** Ouvre ou ferme un itemSliding en fonction de l'état avant slide.\
	 * On peut également stopper la propagation de l'événement de clic.
	 * @param poItemSliding Objet d'options qu'on veut ouvrir ou fermer (animation de swipe).
	 * @param poEvent Événement de clic à stopper si renseigné.
	 */
	public async openOrCloseItemSliding(poItemSliding: IonItemSliding, poEvent?: MouseEvent): Promise<void> {
		if (poEvent)
			poEvent.stopPropagation();	// Empêche la possible navigation vers l'item cliqué.

		// Si l'item est ouvert, la valeur est strictement supérieure à 0, sinon c'est que l'item est fermé.
		const lnAmountOpenPixels: number = await poItemSliding.getOpenAmount();

		if (lnAmountOpenPixels > 0) // Item ouvert, on veut le fermer
			poItemSliding.close();
		else // Item fermé, on veut l'ouvrir.
			poItemSliding.open("end");
	}

	//#endregion

	//#region Filters

	private resetPaginator(): void {
		this.startIndex = 0;
		if (this.paginator)
			this.paginator.pageIndex = 0;
	}

	private setSearchInputListener(): void {
		if (this.searchComponent.searchbar) {
			this.searchComponent.searchbar.nativeElement.onfocus = () => {
				this.isSearchOpen = true;
				this.detectChanges();
			};
			this.searchComponent.searchbar.nativeElement.onblur = () => {
				this.isSearchOpen = false;
				this.detectChanges();
			};
		};
	}

	public toggleFilterbarVisibility(): void {
		this.filterbarParams.hidden = !this.filterbarParams.hidden;
		this.detectChanges();
	}

	private resetCurrentFilters(): void {
		this.maFilterSelectedPatients = [];
		this.maFilterSelectedIntervenants = [];
		this.filterRangeDates = undefined;
		this.maFilterSelectedOthers = [];
	}

	public async exportToExcel(): Promise<void> {
		const laRows: ISeancesExportRow[] = [];
		const laActes: Acte[] = await this.isvcActes.getActes().toPromise();
		const laActesById = new Map<string, Acte>();
		laActes.forEach((poActe: Acte) => laActesById.set(poActe._id, poActe));
		const laTraitements: ITraitement[] = await this.isvcTraitement.getTraitements().toPromise();
		laTraitements.forEach((poTraitement: Traitement) => this.traitementsById.set(poTraitement._id, poTraitement));

		this.getFilteredPrestations(this.maAllPrestations)?.forEach((poPrestation: IdlPrestation) => {
			const loPatient: IPatient = this.patients.find((poPatient: IPatient) => poPrestation.customerId === poPatient._id);
			const ldSeanceDate: Date = poPrestation.dateFromId;
			const lnNbActe: number = poPrestation.lines.filter((poPrestationLine: IPrestationLine) => !poPrestation.deleted && poPrestationLine.category === EIdlPrestationLineCategory.actes).length;

			poPrestation.lines.forEach((poPrestationLine: IdlPrestationLine) => {
				if (!poPrestationLine.deleted) {
					const lsTraitementId: string = Traitement.createId(poPrestation.customerId, poPrestation.siteId, IdHelper.getLastGuidFromVirtualNode(poPrestation._id));
					const loTraitement: Traitement = this.traitementsById.get(lsTraitementId);
					const lsTraitementName = loTraitement ? `Traitement ${this.ioTraitementsDatesPipe.transform(loTraitement)}` : "Traitement non trouvé";
					const loActe: Acte = poPrestationLine.category === EIdlPrestationLineCategory.actes ? laActesById.get(poPrestationLine.ref) : undefined;

					laRows.push({
						nomPatient: loPatient.lastName,
						prenomPatient: loPatient.firstName,
						traitement: lsTraitementName,
						seanceDate: DateHelper.transform(ldSeanceDate, ETimetablePattern.dd_MM_yyyy_slash),
						seanceHeure: DateHelper.transform(ldSeanceDate, ETimetablePattern.HH_mm),
						etat: this.getSeanceStatusLabelFromPrestation(poPrestation.status),
						nbActe: lnNbActe,
						total: poPrestation.total,
						facturee: poPrestation.status === EPrestationStatus.sent ? "Oui" : "Non",
						acte: loActe ? loActe.keyLetters[0] : poPrestationLine.ref,
						coefficient: loActe ? loActe.priceCoefficient : undefined,
						abbatement: poPrestationLine.discountRate,
						tarif: poPrestationLine.total,
						description: poPrestationLine.description,
					});
				};
			});
		});

		this.isvcSeance.exportToExcel(laRows);
	}

	public getFilteredPrestations(paPrestations: IdlPrestation[]): IdlPrestation[] {
		let laPrestations: IdlPrestation[] = paPrestations;
		laPrestations = this.applyFilterValues(laPrestations);
		return ArrayHelper.hasElements(laPrestations) ? laPrestations : undefined;
	}

	/** Applique les filtres de la barre de filtrage sur les documents à afficher et retourne le nouveau tableau obtenu. */
	private applyFilterValues(paPrestations: IdlPrestation[]): IdlPrestation[] {
		let laResults: IdlPrestation[] = paPrestations;

		if (!ArrayHelper.hasElements(laResults))
			return [];

		// On filtre par patients.
		if (ArrayHelper.hasElements(this.filteredPatients)) {
			laResults = laResults.filter((poPrestation: IdlPrestation) =>
				this.filteredPatients.some((poPatient: IPatient) =>
					poPrestation.customerId === poPatient._id
				)
			);
		};

		// On filtre les intervenants s'il y en a.
		if (ArrayHelper.hasElements(this.maFilterSelectedIntervenants)) {
			laResults = laResults.filter((poPrestation: IdlPrestation) =>
				this.maFilterSelectedIntervenants.some((poPrescripteur: IIdelizyContact) =>
					!StringHelper.isBlank(poPrestation.vendorId) && poPrestation.vendorId === poPrescripteur._id
				),
			);
		};

		if (!ObjectHelper.isNullOrEmpty(this.filterRangeDates)) {
			laResults = laResults.filter((poPrestation: IdlPrestation) => {
				return DateHelper.compareTwoDates(poPrestation.dateFromId, this.filterRangeDates.begin) >= 0 && DateHelper.compareTwoDates(this.filterRangeDates.end, poPrestation.dateFromId) >= 0;
			});
		};

		// On filtre par les filtres restants s'il y en a.
		if (ArrayHelper.hasElements(this.maFilterSelectedOthers)) {
			this.maFilterSelectedOthers.forEach((psFilter: string) => {
				if (psFilter === FacturationService.C_UNDEFINED_ACT) {
					laResults = laResults.filter((poPrestation: IdlPrestation) =>
						poPrestation.lines.some((poLine: IPrestationLine) => poLine.ref === ActesService.C_UNDEFINED_ACT_ID)
					);
				}
				else if (psFilter === FacturationService.C_HORS_NOMENCLATURE) {
					laResults = laResults.filter((poPrestation: IdlPrestation) =>
						poPrestation.lines.some((poLine: IPrestationLine) => poLine.ref.includes(ActesService.C_HORS_NOMENCLATURE_ACT_ID))
					);
				};
			});
		};

		if (ArrayHelper.hasElements(this.maSelectedStatus)) {
			laResults = laResults.filter((poPrestation: IdlPrestation) => this.maSelectedStatus.includes(poPrestation.status));
		};

		return laResults;
	}

	private getSeanceStatusLabelFromPrestation(pePrestationStatus: EPrestationStatus): string {
		switch (pePrestationStatus) {
			case EPrestationStatus.canceled:
				return "Annulée";

			case EPrestationStatus.sent:
				return "Annulée";

			default:
				return "Réalisée";
		}
	}

	public onStatusSelectionChanged(paSelectedStatus: EPrestationStatus[]): void {
		this.maSelectedStatus = paSelectedStatus;
		this.refresh();
		this.detectChanges();
	}

	/** Événement de réinitialisation du filtrage. */
	public onFilterbarResetEvent(pbIsReset: boolean): void {
		this.filterbarParams.hidden = pbIsReset;
		this.onFilteredPatientsChanged(this.patients);
		this.resetCurrentFilters();
		this.refresh();
	}

	public onFilterCountChanged(pnFilterCount: number): void {
		this.filtersCount = pnFilterCount;
		this.detectChanges();
	}

	/** Événement de mise à jour des valeurs pour un filtre. */
	public onFilterValuesChanged(poEvent: IFilterValuesChangedEvent<IIdelizyContact | Date | IPatient>): void {
		this.setFilterValuesChanged(poEvent);
		this.refresh();
	}

	/** Modifie un des champs du comopsant pour garder en mémoire les différents filtres en cours. */
	private setFilterValuesChanged(poEvent: IFilterValuesChangedEvent<IIdelizyContact | Date | IPatient | string | IDateRange>): void {
		if (poEvent.id === FacturationService.C_INTERVENANT_FILTER_ID && ArrayHelper.hasElements(poEvent.changes))
			this.maFilterSelectedIntervenants = poEvent.changes as IIdelizyContact[];

		else if (poEvent.id === FacturationService.C_DATE_RANGE_FILTER_ID)
			this.filterRangeDates = ArrayHelper.getFirstElement(poEvent.changes) as IDateRange;

		else if (poEvent.id === PatientsService.C_FILTER_PATIENTS_ID) {
			this.maFilterSelectedPatients = poEvent.changes as IPatient[];
			this.filteredPatients = this.filteredPatients.filter((poPatient: IPatient) => (poEvent.changes as IPatient[]).some((poItem: IPatient) => poPatient._id === poItem._id));
		}
		else if (poEvent.id === FacturationService.C_OTHER_FILTER_ID) {
			const lsSelectedValue: string = ArrayHelper.getFirstElement(poEvent.changes as string[]);
			if (this.maTraitementTagsKeys.includes(lsSelectedValue)) {
				this.maFilterSelectedOthers = [];
			}
			else {
				this.maFilterSelectedOthers = [lsSelectedValue];
			}
		}
		else
			console.warn(`FACT.C:: L'identifiant de changements de valeur de filtres '${poEvent.id}' est inconnu.`);
	}

	public onPageChange(poPaginatorInfos: IPaginatorInfos): void {
		if (poPaginatorInfos.pageSize !== this.nbItemsByPage)
			this.nbItemsByPage = poPaginatorInfos.pageSize;

		this.startIndex = this.nbItemsByPage * poPaginatorInfos.pageIndex;
	}

	//#endregion

}