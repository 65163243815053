<osapp-header-modal [title]="modalType === 0 ? 'Création de l\'acte' : 'Édition de l\'acte'"
	[customClose]="customClose">
</osapp-header-modal>

<ion-content>
	<div class="ctn">
		<idl-acte-edit *ngIf="acte" [(acte)]="acte" [traitement]="traitement" [ordonnance]="ordonnance" [modalType]="modalType"
			[hasValidatedOrCanceledSeancesByActeGuid]="hasValidatedOrCanceledSeancesByActeGuid">
		</idl-acte-edit>
	</div>
</ion-content>

<div class="send-btn">
	<ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
		<ion-fab-button [ngClass]="getValidateButtonClass()" color="fab-button" (click)="validateActe()" title="Valider">
			<ion-icon name="checkmark"></ion-icon>
			<ion-label>{{ modalType === 0 ? "Créer" : "Modifier" }}</ion-label>
		</ion-fab-button>
	</ion-fab>
</div>