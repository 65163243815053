import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";

import { Router } from "@angular/router";
import { EMPTY, forkJoin } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { ITraitement } from "../../../../../model/ITraitement";
import { TraitementService } from "../../../../../services/traitement.service";
import { StoredSeance } from "../../../../models/StoredSeance";
import { ConfirmationSuppressionComponent } from "../../../shared/components/confirmation-suppression/confirmation-suppression.component";
import { DrawerPopoverService } from "../../../shared/services/drawer-popover.service";
import { SeanceService } from "../../../shared/services/seance.service";
import { InterruptionOrdonnanceComponent } from "../interruption-ordonnance/interruption-ordonnance.component";

@Component({
	selector: "di-menu-ordonnance",
	templateUrl: "./menu-ordonnance.component.html",
	styleUrls: ["./menu-ordonnance.component.scss"],
})
export class MenuOrdonnanceComponent implements OnInit {
	@Input() ordonnance: ITraitement;
	@Input() anchorElement? : any;
	@Input() edit? : boolean= true;
	public showPopover: boolean = false;
	@ViewChild("confirmationSuppression", { static: false })
	confirmationSuppression!: ElementRef<HTMLDivElement>;

	constructor(
		private svcTraitement: TraitementService,
		private svcDrawerPopover: DrawerPopoverService,
		private svcSeance : SeanceService,
    private router: Router) { }

	ngOnInit() {}

	@Output() onRenouvellerClick = new EventEmitter<MouseEvent>();
	@Output() onInterrompreClick = new EventEmitter<MouseEvent>();
	@Output() onSupprimerClick = new EventEmitter<MouseEvent>();

	handleConfirmSuppression(ordonnance: ITraitement) {
    this.svcDrawerPopover.close();

    this.svcTraitement.deleteTraitement(ordonnance, true).pipe(
        mergeMap(() => this.svcSeance.selectSeancesByTraitement(this.ordonnance)),
        mergeMap((listSeance: StoredSeance[]) => {
            if (listSeance.length === 0) {
                return EMPTY;
            }            
						const deleteSeanceObservables = listSeance.map(seance => this.svcSeance.deleteSeance(seance));
            return forkJoin(deleteSeanceObservables);
        })
    ).subscribe({
        next: () => {
            this.svcTraitement.emitDeleteTraitement(); 
        },
        error: (error) => {
            console.error('Erreur lors de la suppression :', error);
        }
    });
}

	public closeMenu(): void {
		this.showPopover = false;
		this.svcDrawerPopover.close();
	}

	//to-do : renouvellemnt d'ordonnance
	handleRenouvelerClick() {}

	handleFacturationClick(event: Event)
	{
		this.closeMenu()
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this.router.navigate(["facturation/controle", this.ordonnance._id])
		});
	}

	//to-do : Interrrompre d'ordonnance
	handleConfirmInterruption(ordonnance: ITraitement) {}
	handleInterrompreClick(event: MouseEvent) {
		this.showPopover = true;
		this.svcDrawerPopover.open(
			"",
			"50%",
			this.anchorElement?.nativeElement,
			InterruptionOrdonnanceComponent,
			{
				title: "Interruption de l'ordonnance",
				description:
					"Les soins à venir vont être supprimés, les soins passés seront conservés.",
				labelActionPrincipale: "Interrompre l'ordonnance",
				withIcon: true,
				onConfirm: () => this.handleConfirmInterruption(this.ordonnance),
				onCancel: () => this.closeMenu(),
			},
			() => (this.showPopover = false)
		);

		this.onRenouvellerClick.emit(event);
	}

	handleSupprimerClick(event: MouseEvent) {
		this.showPopover = true;
		this.svcDrawerPopover.open(
			"Confirmation de suppression",
			"50%",
			this.anchorElement?.nativeElement,
			ConfirmationSuppressionComponent,
			{
				onConfirm: () => this.handleConfirmSuppression(this.ordonnance),
				onCancel: () => this.closeMenu(),
			},
			() => (this.showPopover = false)
		);

		this.onSupprimerClick.emit(event);
	}

	handleEditClick(event: MouseEvent)
	{
		this.svcDrawerPopover.close();
    this.router.navigate(["ordonnances", "edit", this.ordonnance._id]);
	}
}
