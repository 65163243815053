import { Injectable, Type } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PopoverOptions } from '@ionic/core';
import { from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PopoverComponent } from '../components/popover/popover.component';
import { GuidHelper } from '../helpers/guidHelper';
import { IIndexedArray } from '../model/IIndexedArray';
import { IPopoverItemParams } from '../model/popover/IPopoverItemParams';

interface OsappPopoverOptions<T> {
	component: Type<T>,
	event: MouseEvent,
	componentProps: IIndexedArray<any>
}

@Injectable()
export class PopoverService {

	//#region METHODS

	constructor(private ioPopoverCtrl: PopoverController) { }

	/** Affiche un menu contextuel et retourne l'objet `HTMLIonPopoverElement` associé.
	 * @param paItems Tableau des paramètres des items de menu contextuel à afficher.
	 * @param poEvent Événement de la souris (permet d'afficher correctement le menu contextuel).
	 */
	public showPopover(paItems: IPopoverItemParams[], poEvent: MouseEvent): Observable<HTMLIonPopoverElement> {
		const loOptions: OsappPopoverOptions<PopoverComponent> = {
			component: PopoverComponent,
			event: poEvent,
			componentProps: { items: paItems.filter((poItem: IPopoverItemParams) => !!poItem) },
		};

		return this.show(loOptions);
	}

	/** Affiche un menu contextuel et retourne l'objet `HTMLIonPopoverElement` associé.
	 * @param poComponent Composant popover à instancier.
	 * @param poComponentProps Paramètres du composant menu contextuel à afficher.
	 * @param poEvent Événement de la souris (permet d'afficher correctement le menu contextuel).
	 */
	public showCustomPopover<T>(poComponent: Type<T>, poComponentProps: IIndexedArray<any>, poEvent: MouseEvent): Observable<HTMLIonPopoverElement> {
		const loOptions: OsappPopoverOptions<T> = {
			component: poComponent,
			event: poEvent,
			componentProps: poComponentProps,
		};

		return this.show(loOptions);
	}

	private show<T>(poOptions: OsappPopoverOptions<T>): Observable<HTMLIonPopoverElement> {
		const lsPopoverId: string = GuidHelper.newGuid();
		const loOptions: PopoverOptions = {
			component: poOptions.component,
			event: poOptions.event,
			componentProps: { ...poOptions.componentProps, componentId: lsPopoverId },
			keyboardClose: true,
			mode: "ios",
			id: lsPopoverId
		};

		return from(this.ioPopoverCtrl.create(loOptions))
			.pipe(tap((poPopover: HTMLIonPopoverElement) => poPopover.present()));
	}

	//#endregion
}