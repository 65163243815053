import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "di-commentaire",
	templateUrl: "./commentaire.component.html",
	styleUrls: ["./commentaire.component.scss"],
})
export class CommentaireComponent implements OnInit {
	@Input() commentaire = "";
	constructor() {}
	ngOnInit(): void {}
}
