export interface IBarElementDataOption {

	/** Index du slidebox où l'élément doit apparaître. */
	showOnSlideIndex?: number;
	/** Si `true` on reset les paramètres interne de la 'IBarElement' (toolbar par exemple) mais pas les boutons, pour cela appeler "clear". */
	reset?: boolean;
	/** Rend un bouton ciblé par son id "désactivé" et la fonction onTap est elle aussi désactivée. */
	isDisable?: boolean;
	/** Modifie la fonction appelée lors d'un tap sur le bouton à l'aide de l'id du bouton. */
	onTap?: Function;
}