<ion-header>
	<ion-toolbar color="toolbar" class="navbar">

		<ion-buttons slot="start">
			<!-- Bouton 'back'. -->
			<ion-button *ngIf="hasBackButton && canGoBack" (click)="goBack()">
				<ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
			</ion-button>

			<!-- Bouton 'menu'. -->
			<ion-menu-toggle *ngIf="hasMenuButton">
				<ion-button>
					<ion-icon slot="icon-only" name="menu"></ion-icon>
				</ion-button>
			</ion-menu-toggle>

			<!-- Bouton 'accueil'. -->
			<ion-button *ngIf="hasHomeButton" (click)="goHome()">
				<ion-icon slot="icon-only" name="home"></ion-icon>
			</ion-button>
		</ion-buttons>

		<!-- Titre. -->
		<ion-title [color]="titleColor" data-cy="page-title">{{ title }}</ion-title>

		<ion-buttons slot="end">
			<!-- Bouton d'état de la synchronisation -->
			<calao-database-sync-status-icon *ngIf="hasSyncButton"></calao-database-sync-status-icon>
			<!-- Bouton 'options'. -->
			<ion-button *ngIf="hasPopoverItems" slot="end" (click)="openOptions($event)">
				<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
			</ion-button>
		</ion-buttons>

		<ng-content></ng-content>

	</ion-toolbar>
</ion-header>