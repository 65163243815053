import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { Loader } from '@osapp/modules/loading/Loader';
import { C_ADMINISTRATORS_ROLE_ID, Roles } from '@osapp/modules/permissions/services/permissions.service';
import { EPrestationStatus } from '@osapp/modules/prestation/models/eprestation-status.enum';
import { PrestationService } from '@osapp/modules/prestation/services/prestation.service';
import { ValidationPopup } from '@osapp/modules/validations/decorators/validation-popup.decorator';
import { ContactsService } from '@osapp/services/contacts.service';
import { LoadingService } from '@osapp/services/loading.service';
import { defer } from 'rxjs';
import { finalize, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { TraitementDatesPipe } from '../../../../pipes/traitementDates.pipe';
import { SeanceService } from '../../../../services/seance.service';
import { TraitementService } from '../../../../services/traitement.service';
import { ActesService } from '../../../actes/actes.service';
import { PatientsService } from '../../../patients/services/patients.service';
import { FacturationService } from '../../facturation.service';
import { PrestationsListComponentBase } from '../../helpers/PrestationsListComponentBase';
import { IdlPrestation } from '../../models/idl-prestation';

@Component({
	selector: 'idl-sent-prestations-list',
	templateUrl: './sent-prestations-list.component.html',
	styleUrls: ['./sent-prestations-list.component.scss'],
})
export class SentPrestationsListComponent extends PrestationsListComponentBase implements OnInit {

	//#region PROPERTIES

	public static readonly C_LOG_ID = "SENT.INV.C::";

	@Roles(C_ADMINISTRATORS_ROLE_ID)
	public get canCancelSeanceExport(): boolean {
		return true;
	}

	//#endregion

	//#region METHODS

	constructor(
		public readonly isvcFacturation: FacturationService,
		public readonly isvcPrestation: PrestationService,
		public readonly isvcPatients: PatientsService,
		public readonly isvcContacts: ContactsService,
		public readonly isvcTraitement: TraitementService,
		public readonly isvcLoading: LoadingService,
		public readonly isvcSeance: SeanceService,
		public readonly isvcActes: ActesService,
		public readonly ioTraitementsDatesPipe: TraitementDatesPipe,
		public readonly ioRouter: Router,
		poChangeDetector: ChangeDetectorRef
	) {
		super(isvcFacturation, isvcPrestation, isvcPatients, isvcContacts, isvcTraitement, isvcLoading, isvcSeance, isvcActes, ioTraitementsDatesPipe, ioRouter, poChangeDetector);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		let loLoader: Loader;
		defer(() => this.isvcLoading.create("Chargement en cours..."))
			.pipe(
				mergeMap((poLoader: Loader) => poLoader.present()),
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap(() => this.init(true)),
				mergeMap(() => loLoader.dismiss()),
				finalize(() => loLoader?.dismiss()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	@ValidationPopup({
		title: "ATTENTION",
		message: "Cette action exige que les données transmises soient supprimées manuellement de Desmos, au risque de générer des erreurs de facturation !",
		okButton: "J'ai fait le nécessaire sur Desmos",
		cancelButton: "Annuler"
	})
	public onCancelExport(poPrestation: IdlPrestation, paPrestations: IdlPrestation[]): void {
		if (this.canCancelSeanceExport) {
			poPrestation.status = EPrestationStatus.created;
			this.savePrestations([poPrestation], SentPrestationsListComponent.C_LOG_ID)
				.pipe(
					tap(() => ArrayHelper.removeElementByFinder(paPrestations, (poItem: IdlPrestation) => poPrestation._id === poItem._id)),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		};
	}

	//#endregion

	//#endregion

}
