import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, mapTo, mergeMap, switchMap } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { DateHelper } from '../../../helpers/dateHelper';
import { StoreHelper } from '../../../helpers/storeHelper';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { IDataSource } from '../../../model/store/IDataSource';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { Store } from '../../../services/store.service';
import { SectorsService } from '../../sectors/services/sectors.service';
import { ModelResolver } from '../../utils/models/model-resolver';
import { IPrestation } from '../models/iprestation';
import { IPrestationConfig } from '../models/iprestation-config';
import { IPrestationIdBuilderParams } from '../models/iprestation-id-builder-params';
import { IPrestationLine } from '../models/iprestation-line';
import { Prestation } from '../models/prestation';
import { PrestationsIdBuilder } from '../models/prestation-id-builder';

export const PRESTATION_CONFIG = new InjectionToken<IPrestationConfig>("PRESTATION_CONFIG");

@Injectable()
export class PrestationService {

	//#region FIELDS

	private readonly moIdBuilder: PrestationsIdBuilder;

	//#endregion

	//#region PROPERTIES

	public get buildId(): <T extends IPrestationIdBuilderParams>(poParams: T) => string {
		return (poParams: any) => this.moIdBuilder.buildId(poParams);
	}

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcStore: Store,
		public readonly isvcSectors: SectorsService,
		private readonly ioRouter: Router,
		@Inject(PRESTATION_CONFIG) @Optional() ioPrestationConfig: IPrestationConfig
	) {
		this.moIdBuilder = new (ioPrestationConfig?.idBuilderType ?? PrestationsIdBuilder);
	}

	public getPrestationsByIds(paIds: string[]): Observable<Prestation[]> {
		const loIdsDataSource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				keys: paIds,
				include_docs: true
			},
		};
		return this.isvcStore.get(loIdsDataSource)
			.pipe(
				map((paPrestations: IPrestation[]) => paPrestations.map((poPrestation: IPrestation) => this.toClass(poPrestation)))
			);
	}

	public getPrestations(poParams: IPrestationIdBuilderParams, pdFrom?: Date, pdTo?: Date, pbLive?: boolean): Observable<Prestation[]> {
		const loDataSource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: this.moIdBuilder.buildId({ ...poParams, prestationDate: pdFrom, prestationGuid: "" }),
				endkey: `${this.moIdBuilder.buildId({ ...poParams, prestationDate: pdTo, prestationGuid: "" })}${Store.C_ANYTHING_CODE_ASCII}`
			},
			live: pbLive,
			filter: this.moIdBuilder.buildFilterFunction(poParams)
		};

		if (loDataSource.filter)
			return this.isvcStore.get(loDataSource).pipe(switchMap((paDocs: IStoreDocument[]) => this.getPrestationsByIds(paDocs.map((poDoc: IStoreDocument) => poDoc._id))));

		loDataSource.viewParams.include_docs = true;

		return this.isvcStore.get<IPrestation>(loDataSource)
			.pipe(map((paPrestations: IPrestation[]) => paPrestations.map((poPrestation: IPrestation) => this.toClass(poPrestation))));
	}

	/** Regroupe les lignes par groupes puis par catégorie.
	 * @param paLines
	 */
	public groupLines(paLines: IPrestationLine[]): Map<string, Map<string, IPrestationLine[]>> {
		const loGroupedLines = new Map<string, Map<string, IPrestationLine[]>>();

		paLines.forEach((poLine: IPrestationLine) => {
			const loLineGroup: Map<string, IPrestationLine[]> = loGroupedLines.get(poLine.group) ?? new Map<string, IPrestationLine[]>();
			const laLineGroupCategories: IPrestationLine[] = loLineGroup.get(poLine.category) ?? [];

			laLineGroupCategories.push(poLine);

			loLineGroup.set(poLine.category, laLineGroupCategories);
			loGroupedLines.set(poLine.group, loLineGroup);
		});

		return loGroupedLines;
	}

	/** Sauvegarde une facture.
	 * @param poPrestation
	 */
	public savePrestation(poPrestation: IPrestation): Observable<Prestation> {
		const loPrestation: Prestation = poPrestation instanceof Prestation ? poPrestation : this.toClass(poPrestation);

		return this.isvcStore.put(loPrestation,
			StoreHelper.getDatabaseIdFromCacheData(loPrestation,
				ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace))
			)
		)
			.pipe(mapTo(loPrestation));
	}

	/** Sauvegarde plusieurs factures.
	 * @param paPrestations
	 */
	public savePrestations(paPrestations: IPrestation[]): Observable<Prestation[]> {
		const laPrestations: Prestation[] = paPrestations.map((poPrestation: Prestation) => poPrestation instanceof Prestation ? poPrestation : this.toClass(poPrestation));

		return this.isvcStore.putMultipleDocuments(laPrestations,
			StoreHelper.getDatabaseIdFromCacheData(ArrayHelper.getFirstElement(laPrestations),
				ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace))
			)
		)
			.pipe(mapTo(laPrestations));
	}

	protected toClass(poPrestation: IPrestation): Prestation {
		return ModelResolver.toClass(poPrestation, Prestation);
	}

	public navigateToPrestation(pdFrom?: Date, pdTo?: Date): void {
		const loNavigationExtras: NavigationExtras = {
			queryParams: {
				from: DateHelper.isDate(pdFrom) ? DateHelper.toDateUrl(pdFrom) : undefined,
				to: DateHelper.isDate(pdTo) ? DateHelper.toDateUrl(pdTo) : undefined
			}
		};

		this.ioRouter.navigate(["facturation"], loNavigationExtras);
	}

	public getPrestationsIds(poParams: IPrestationIdBuilderParams, pdFrom?: Date, pdTo?: Date, pbLive?: boolean): Observable<string[]> {
		const loIdsDataSource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: this.moIdBuilder.buildId({ ...poParams, prestationDate: pdFrom, prestationGuid: "" }),
				endkey: `${this.moIdBuilder.buildId({ ...poParams, prestationDate: pdTo, prestationGuid: "" })}${Store.C_ANYTHING_CODE_ASCII}`
			},
			live: pbLive,
			filter: this.moIdBuilder.buildFilterFunction(poParams)
		};
		return this.isvcStore.get(loIdsDataSource)
			.pipe(map((paPrestations: IStoreDocument[]) => paPrestations.map((poPrestation: IStoreDocument) => poPrestation._id)));
	}

	public static getBillablePrestations(paPrestations: Prestation[]): Prestation[] {
		return paPrestations.filter((poPrestation: Prestation) => !poPrestation.isLocked);
	}

	public deletePrestations(idsPrestationsASuppr: string[]): Observable<boolean> {
		if (!idsPrestationsASuppr && idsPrestationsASuppr.length <= 0) return of(true);

		const datebaseId = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace))

		const loDataSource: IDataSource = {
			databaseId: datebaseId,
			viewParams: {
				include_docs: true,
				keys: idsPrestationsASuppr
			}
		};

		return this.isvcStore.get(loDataSource)
			.pipe(
				mergeMap((paResults: IStoreDocument[]) => this.isvcStore.deleteMultipleDocuments(paResults, datebaseId))
			);
	}

	//#endregion

}
