<div class="di-acte" [ngClass]="getClasses()">
	<ng-container *ngIf="!noCheckForce">
		<lua-checkbox *ngIf="status || favoriteIcon" [checked]="status === 'done'" [disabled]="status === 'not done'"
			[iconName]="favoriteIcon && 'favorite'" (checkedChange)="handleCheckboxChange($event)"></lua-checkbox>
	</ng-container>
	<div class="detail-acte" (click)="onSelectActe(acte)">
		<div class="description-container">
			<p #descriptionElement class="description">{{acte.keyLetters}} {{ acte.priceCoefficient }} - {{ acte.label }}</p>
			<small *ngIf="priseEnCharge && infoSecondaire" class="info-secondaire">{{ infoSecondaire }}</small>
		</div>
		<lua-tag *ngIf="status === 'not done'" color="Noir60" mode="outlined" label="Non réalisé"></lua-tag>
		<p *ngIf="status !== 'not done' && acte.price" class="price">{{ acte.price | priceFormat:2 }}
			€
		</p>
		<p *ngIf="status !== 'not done' && !favoriteIcon && acte.price === 0" class="price">GRATUIT</p>
	</div>
</div>