<osapp-header-modal [title]="title || 'Sélectionnez un site'" [hasCloseButton]="hasCloseButton"></osapp-header-modal>

<ion-content>
	<div class="ctn">
		<div class="search-ctn">
			<search #search [data]="sites" [options]="searchOptions" (filteredEntriesChanged)="onFilteredSitesChanged($event)"
				[(searchValue)]="searchValue">
			</search>
		</div>

		<ng-container *ngIf="filteredSites?.length > 0">
			<ion-list class="sites-list marg-btm">
				<ion-item *ngFor="let site of filteredSites" lines="full" (click)="selectSite(site)">
					<div class="item-ctn">
						<avatar [src]="getSiteAvatar(site)" [cssClass]="'round'" (srcError)="onImgError($event, site)">
						</avatar>
						<ion-grid class="no-margin">
							<ion-row>
								<ion-label class="ion-no-padding ion-text-wrap site-name">{{site.name}}</ion-label>
							</ion-row>
							<ion-row>
								<ion-note class="ion-text-nowrap">
									{{ site | contactAddress }} - {{site.finess}}
								</ion-note>
							</ion-row>
						</ion-grid>
					</div>
				</ion-item>
			</ion-list>
		</ng-container>
	</div>

	<div *ngIf="!(filteredSites?.length > 0)" class="empty-result-ctn">
		<ion-note>Aucun résultat</ion-note>
	</div>
</ion-content>