import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IHttpError } from '@osapp/model';
import { ConnexionService } from '../../../features/connexion/services/connexion.service';


@Component({
  selector: 'di-password-demande',
  templateUrl: './password-demande.page.html',
  styleUrls: ['./password-demande.page.scss'],
})

export class PasswordDemandePage implements OnInit {
	public requestError: IHttpError;
	public title: string;
  public text: string;
	public email: string = "";

	constructor(
		private svcConnexion: ConnexionService,
		private router: Router,
		private route: ActivatedRoute

	) { }
		
	ngOnInit(): void {
		this.getTitleAndText();
	}
	
	private getTitleAndText() {
		this.route.queryParams.subscribe(params => {
			const type = params['type'];
			if (type === 'block') {
				this.title = 'Compte bloqué ?';
				this.text = 'Veuillez saisir votre adresse email. Nous allons vous envoyer un lien pour débloquer votre compte et réinitialiser votre mot de passe.';
			} else if (type === 'lost') {
				this.title = 'Mot de passe perdu ?';
				this.text = 'Veuillez saisir votre adresse email. Nous allons vous envoyer un lien pour réinitialiser votre mot de passe.';
			}
		});
	}

	public async sendEmailLien() {
		this.requestError = undefined;
		const error = await this.svcConnexion.sendMailRequest(this.email);
		if (error)
      this.requestError = error;
		
	}

	public navigateToLogin(){
		this.router.navigate(['/authenticator']);
	}

	public handleChangeValue(email: string): void {
		this.email = email;
	}
}
