import { Injectable } from '@angular/core';
import { IdHelper } from '@osapp/helpers/idHelper';
import { IEntity } from '@osapp/model/entities/IEntity';
import { MetaEntityBuilderBase } from '@osapp/modules/dms/model/MetaEntityBuilderBase';
import { C_PREFIX_TRAITEMENT } from '../../../app/app.constants';
import { ITraitement } from '../../../model/ITraitement';
import { Traitement } from '../../../model/Traitement';

@Injectable()
export class MetaTraitementBuilder extends MetaEntityBuilderBase {

	//#region METHODS

	/** @override */
	public match(psDocumentId: string): boolean {
		return IdHelper.hasPrefixId(psDocumentId, C_PREFIX_TRAITEMENT);
	}

	/** @override */
	protected getEntityId(poEntity: IEntity<ITraitement>): Promise<string> {
		return Promise.resolve(Traitement.extractPatientId(poEntity.model._id));
	}

	/** @override */
	protected getEntityDocumentSubType(): string {
		return "OOR";
	}

	//#endregion
}
