import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Directory, Encoding, FileInfo, Filesystem, ReaddirResult } from '@capacitor/filesystem';
import write_blob from "capacitor-blob-writer";
import { EFileError } from '../../../model/file/EFileError';
import { IFileError } from '../../../model/file/IFileError';
import { PlatformService } from '../../../services/platform.service';

@Injectable()
export class FilesystemService {

	//#region FIELDS

	private static readonly C_LOG_ID = "FS.S::";
	private static readonly C_URI_START = "file://";

	//#endregion

	//#region METHODS

	constructor(private readonly ioHttpClient: HttpClient, private readonly isvcPlatform: PlatformService) { }

	/** Récupère un fichier enregistré.
	 * @param psPath Chemin d'accès jusqu'au fichier qu'on veut récupérer : 'cheminVersFichier/nomFichier'
	 * @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut.
	 */
	public async getFileAsync(psPath: string, peDirectory: Directory = Directory.External): Promise<Blob> {
		let lsUri: string;

		if (psPath.startsWith(FilesystemService.C_URI_START) || !this.isvcPlatform.isMobileApp)
			lsUri = psPath;
		else
			lsUri = (await Filesystem.getUri({ path: psPath, directory: peDirectory })).uri;

		return this.ioHttpClient.get(Capacitor.convertFileSrc(lsUri), { responseType: "blob" }).toPromise();
	}

	/** Vérification si un certain chemin existe ou non.
	 * @param psPath Chemin d'accès que l'on veut vérifier.
	 * @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut.
	 */
	public async existsAsync(psPath: string, peDirectory: Directory = Directory.External): Promise<boolean> {
		try {
			console.debug(`${FilesystemService.C_LOG_ID}Checking if ${psPath} exists...`);
			await Filesystem.stat({ path: psPath, directory: peDirectory });
			console.debug(`${FilesystemService.C_LOG_ID}${psPath} exists!`);
			return true;
		}
		catch (poError) {
			console.debug(`${FilesystemService.C_LOG_ID}${psPath} does not exist`);
			return false;
		}
	}

	/** Lecture du contenu textuel d'un fichier.
	 * @param psPath Chemin d'accès au dossier qu'on veut lire, peut contenir le nom du fichier : 'cheminVersFichier/nomFichier'.
	 * @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut.
	 */
	public async readFileAsTextAsync(
		psPath: string,
		peDirectory: Directory = Directory.External,
		peEncoding: Encoding = Encoding.UTF8
	): Promise<string> {
		try {
			return (await Filesystem.readFile({ path: psPath, directory: peDirectory, encoding: peEncoding })).data;
		}
		catch (poError) {
			console.error(`${FilesystemService.C_LOG_ID}Erreur lecture du fichier '${psPath}' : `, poError);

			if (poError.code === 1 || poError.code === 13) // "not found" ou "input is not a file"
				throw { error: poError, errorCode: EFileError.FileNotFound };

			else
				throw { error: poError, errorCode: EFileError.Other };
		}
	}

	/** Crée un dossier à un chemin spécifique.
	 * @param psPath Chemin d'accès au dossier qu'on veut créer : 'cheminVersFichier/nomFichier'.
	 * @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut.
	 * @param pbRaiseError Indique si une erreur dans le cas où le répertoire existe déjà, `true` par défaut.
	 */
	public async createDirectoryAsync(
		psPath: string,
		peDirectory: Directory = Directory.External,
		pbRaiseError: boolean = true
	): Promise<void> {
		if (await this.existsAsync(psPath, peDirectory)) {
			if (pbRaiseError)
				throw { errorCode: EFileError.DirectoryAlreadyExist, error: `Répertoire '${psPath} existe déjà.` } as IFileError;
		}
		else
			await Filesystem.mkdir({ path: psPath, directory: peDirectory, recursive: true });
	}

	/** Crée un fichier à un chemin spécifique et retourne son url.
	 * @param psPath Chemin d'accès au dossier qu'on veut créer, peut contenir le nom du fichier : 'cheminVersFichier/nomFichier'.
	 * @param poData Données du fichier à créer, crée un fichier vide si non renseigné.
	 * @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut.
	 * @param pbOverwriteIfExists Indique si on doit écraser le fichier s'il existe déjà, `false` par défaut.
	 */
	public async createFileAsync(
		psPath: string,
		poData?: string | Blob | ArrayBuffer,
		peDirectory: Directory = Directory.External,
		pbOverwriteIfExists?: boolean
	): Promise<string> {
		if (await this.existsAsync(psPath, peDirectory) && !pbOverwriteIfExists)
			throw { errorCode: EFileError.FileAlreadyExist, error: `Fichier '${psPath} existe déjà.` } as IFileError;

		try {
			let lsUri: string;
			if (!poData || typeof poData === "string") {
				lsUri = (await Filesystem.writeFile({
					data: poData as string ?? "",
					path: psPath,
					directory: peDirectory,
					recursive: true
				})).uri;
			}
			else {
				lsUri = await write_blob({
					blob: poData instanceof Blob ? poData : new Blob([poData]),
					path: psPath,
					directory: peDirectory,
					recursive: true,
				});
			}
			console.debug(`${FilesystemService.C_LOG_ID}Fichier '${psPath}' créé !`);
			return lsUri;
		}
		catch (poError) {
			console.error(`${FilesystemService.C_LOG_ID}Erreur création fichier '${psPath}' : `, poError);
			throw { errorCode: EFileError.Other, error: poError } as IFileError;
		}
	}

	/** Liste le contenu d'un répertoire et renvoie cette liste.
	 * @param psPath Chemin d'accès jusqu'au répertoire dont il faut lister le contenu.
	 * @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut.
	 */
	public async listDirectoryEntriesAsync(psPath: string, peDirectory: Directory = Directory.External): Promise<FileInfo[]> {
		try {
			const loResult: ReaddirResult = await Filesystem.readdir({ path: psPath, directory: peDirectory });
			console.debug(`${FilesystemService.C_LOG_ID}Contenu du répertoire ${psPath} : `, loResult.files);
			return loResult.files;
		}
		catch (poError) {
			console.error(`${FilesystemService.C_LOG_ID}Erreur listing entrées du répertoire ${psPath} : `, poError)
			throw poError;
		}
	}

	/** Supprime un répertoire et son contenu.
	 * @param psPath Chemin d'accès jusqu'au répertoire qu'il faut supprimer.
	 * @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut.
	 */
	private async removeDirectoryAsync(psPath: string, peDirectory: Directory = Directory.External): Promise<void> {
		try {
			await Filesystem.rmdir({ path: psPath, directory: peDirectory, recursive: true });
			console.debug(`${FilesystemService.C_LOG_ID}Directory ${psPath} removed.`);
		}
		catch (poError) {
			console.error(`${FilesystemService.C_LOG_ID}Error occurred while trying to remove folder '${psPath}' : `, poError);
			throw {
				errorCode: poError.code === 1 ? EFileError.DirectoryNotFound : EFileError.Other,
				error: poError
			} as IFileError;
		}
	}

	/** Supprime un fichier.
	 * @param psPath Chemin d'accès jusqu'au fichier qu'il faut supprimer.
	 * @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut.
	 */
	private async removeFileAsync(psPath: string, peDirectory: Directory = Directory.External): Promise<void> {
		try {
			await Filesystem.deleteFile({ path: psPath, directory: peDirectory });
			console.debug(`${FilesystemService.C_LOG_ID}File ${psPath} removed.`);
		}
		catch (poError) {
			console.error(`${FilesystemService.C_LOG_ID}Error occurred while trying to remove file '${psPath}' : `, poError);
			throw {
				errorCode: poError.code === 1 ? EFileError.DirectoryNotFound : EFileError.Other,
				error: poError
			} as IFileError;
		}
	}

	/** Supprime un dossier (et son contenu) ou fichier.
	 * @param psPath Chemin d'accès jusqu'au dossier ou fichier qu'il faut supprimer, peuit contenir le nom de la cible.
	 * @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut.
	 */
	public async removeAsync(psPath: string, peDirectory: Directory = Directory.External): Promise<void> {
		try {
			if (await this.isDirectoryAsync(psPath, peDirectory))
				return this.removeDirectoryAsync(psPath, peDirectory);
			else
				return this.removeFileAsync(psPath, peDirectory);
		}
		catch (poError) {
			console.error(`${FilesystemService.C_LOG_ID}Erreur suppression fichier/dossier '${psPath}'`, poError);
			throw { errorCode: EFileError.remove, error: poError } as IFileError;
		}
	}

	/** Retourne "true" si c'est un répertoire, "false" si c'est un fichier, ou une erreur.
	* @param psPath Chemin d'accès jusqu'au répertoire/fichier qu'on veut identifier, peut contenir le nom du répertoire/fichier : 'cheminVersFichier/nomFichier'.
	* @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut.
	*/
	public async isDirectoryAsync(psPath: string, peDirectory: Directory = Directory.External): Promise<boolean> {
		try {
			return (await Filesystem.stat({ path: psPath, directory: peDirectory })).type === "directory";
		}
		catch (poError) {
			return false;
		}
	}

	/** Copie un fichier dans un répertoire.
	 * @param psFromPath Chemin du fichier/répertoire à copier dans un autre répertoire.
	 * @param psToPath Chemin où copier le fichier/dossier.
	 * @param peFromDirectory Dossier dans lequel est stocké le fichier à copier. `Directory.External` par défaut.
	 * @param peToDirectory Dossier dans lequel copier le fichier. Utilise `peFromDirectory` par défaut.
	 */
	public async copyAsync(
		psFromPath: string,
		psToPath: string,
		peFromDirectory: Directory = Directory.External,
		peToDirectory: Directory = peFromDirectory
	): Promise<void> {
		try {
			if (await this.existsAsync(psToPath, peToDirectory))
				await this.removeAsync(psToPath, peToDirectory);
			await Filesystem.copy({ from: psFromPath, to: psToPath, directory: peFromDirectory, toDirectory: peToDirectory });
		}
		catch (poError) {
			console.error(`${FilesystemService.C_LOG_ID}Error occurred while trying to copy file '${psFromPath}' to ${psToPath} : `, poError);
			throw { errorCode: EFileError.Other, error: poError } as IFileError;
		}
	}

	/** Récupère le chemin vers un fichier.
	 * @param psPath
	 * @param peDirectory
	 */
	public async getFileUriAsync(psPath: string, peDirectory: Directory): Promise<string> {
		if (psPath.startsWith(FilesystemService.C_URI_START))
			return psPath;

		return `${(await Filesystem.getUri({ path: "", directory: peDirectory })).uri}/${psPath}`;
	}

	//#endregion

}
