import { ScrollingModule } from '@angular/cdk/scrolling';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { IonicModule } from '@ionic/angular';
import { ContactsModule } from '@osapp/components/contacts';
import { DateModule } from '@osapp/components/date';
import { GalleryModule } from '@osapp/components/gallery';
import { CalaoAvatarModule } from '@osapp/modules/avatar/calao-avatar.module';
import { ConversationsModule } from '@osapp/modules/conversations/conversations.module';
import { ModalModule } from '@osapp/modules/modal';
import { FavoritesModule } from '@osapp/modules/preferences/favorites/favorites.module';
import { RecentsModule } from '@osapp/modules/preferences/recents/recents.module';
import { SelectorModule } from '@osapp/modules/selector/selector.module';
import { OsappModule } from '@osapp/osapp.module';
import { PipeModule } from '@osapp/pipes/pipes.module';
import { VirtualScrollModule } from '../../../../../libs/osapp/src/modules/virtual-scroll/virtual-scroll.module';
import { IdlModalService } from '../../services/idl-modal.service';
import { IndemniteService } from '../../services/indemnite.service';
import { ActesModule } from '../actes/actes.module';
import { OrdonnancesModule } from '../ordonnances/ordonnances.module';
import { ConventionModalComponent } from '../patients/components/couvertures/convention-modal/convention-modal.component';
import { PatientsModule } from '../patients/patients.module';
import { PlanningRHModule } from '../planning-rh/planning-rh.module';
import { SeancesModule } from '../seances/seances.module';
import { ActeEditModalComponent } from './acte-edit-modal/acte-edit-modal.component';
import { CancelActeModalComponent } from './cancelActeModal/cancelActeModal.component';
import { CancelSeanceModalComponent } from './cancelSeanceModal/cancel-seance-modal.component';
import { InterruptionModalComponent } from './interruptionModal/interruptionModal.component';
import { MoveActeModalComponent } from './moveActeModal/move-acte-modal.component';
import { AccordPrealableModalComponent } from './slides-traitement/accordPrealable/accordPrealable-modal/accordPrealable-modal.component';
import { AccordPrealableListModalComponent } from './slides-traitement/accordPrealable/accordPrealable-pdf-modal/accordPrealable-list-modal.component';
import { AccordPrealableComponent } from './slides-traitement/accordPrealable/accordPrealable.component';
import { AccordPrealableService } from './slides-traitement/accordPrealable/accordPrealable.service';
import { IndemniteKilometriqueModalComponent } from './slides-traitement/indemnites/indemnite-kilometrique-modal/indemnite-kilometrique-modal.component';
import { IndemnitesComponent } from './slides-traitement/indemnites/indemnites.component';
import { MajorationsComponent } from './slides-traitement/majorations/majorations.component';
import { PrescriptionsComponent } from './slides-traitement/prescriptions/prescriptions.component';
import { SeancesComponent } from './slides-traitement/seances/seances.component';
import { SyntheseComponent } from './slides-traitement/synthese/synthese.component';
import { TraitementSlideComponent } from './traitement-slide-page.component';
import { ValidateActesModule } from './validateActes/validate-actes.module';

const modules: Array<Type<any> | ModuleWithProviders<any>> = [
	CommonModule,
	IonicModule,
	PipeModule,
	FormsModule,
	OsappModule,
	MatSelectModule,
	MatFormFieldModule,
	MatInputModule,
	TextFieldModule,
	MatExpansionModule,
	MatCheckboxModule,
	DateModule,
	ContactsModule,
	GalleryModule,
	ValidateActesModule,
	ConversationsModule,
	ActesModule,
	PatientsModule,
	ModalModule,
	CalaoAvatarModule,
	SeancesModule,
	ScrollingModule,
	FavoritesModule,
	PlanningRHModule,
	SelectorModule,
	MatChipsModule,
	OrdonnancesModule,
	VirtualScrollModule,
	RecentsModule.forRoot({ limit: 3 }),
];
const components: Type<any>[] = [
	TraitementSlideComponent,
	IndemnitesComponent,
	MajorationsComponent,
	PrescriptionsComponent,
	AccordPrealableComponent,
	SeancesComponent,
	SyntheseComponent,
	CancelActeModalComponent,
	InterruptionModalComponent,
	MoveActeModalComponent,
	CancelSeanceModalComponent,
	IndemniteKilometriqueModalComponent,
	ActeEditModalComponent,
	AccordPrealableModalComponent,
	AccordPrealableListModalComponent,
	ConventionModalComponent
];
const providers: Provider[] = [
	IndemniteService,
	IdlModalService,
	AccordPrealableService,
	DecimalPipe
];

/** Module de gestion des traitements. */
@NgModule({
	imports: modules,
	exports: components,
	declarations: components,
	providers: providers
})
export class TraitementSlideModule { }