<div [ngClass]="'layout'">
	<nav>
		<di-entete-mobile *ngIf="isMobile && isAuthenticated"></di-entete-mobile>
		<div *ngIf="!isMobile && isAuthenticated">
			<di-entete-desktop></di-entete-desktop>
			<di-sidemenu-desktop></di-sidemenu-desktop>
		</div>
	</nav>
	<main [ngClass]="{'main-desktop': !isMobile && isAuthenticated}">
		<router-outlet id="content"></router-outlet>
		<ng-template #panneauContainer></ng-template>
		<ng-template #popoverContainer></ng-template>
		<ng-template #drawerContainer></ng-template>
	</main>
</div>
