import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ArrayHelper, EnumHelper, NumberHelper, ObjectHelper, StoreHelper } from '@osapp/helpers';
import { IAvatar, IContact, IUiResponse } from '@osapp/model';
import { Loader } from '@osapp/modules/loading/Loader';
import { ModalComponentBase } from '@osapp/modules/modal';
import { ModalService } from '@osapp/modules/modal/services/modal.service';
import { IHasPermission, Permissions, PermissionsService } from '@osapp/modules/permissions/services/permissions.service';
import { EPrestationStatus } from '@osapp/modules/prestation/models/eprestation-status.enum';
import { PrestationLine } from '@osapp/modules/prestation/models/prestation-line';
import { PrestationService } from '@osapp/modules/prestation/services/prestation.service';
import { ValidationPopup } from '@osapp/modules/validations/decorators/validation-popup.decorator';
import { ContactsService } from '@osapp/services/contacts.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '@osapp/services/interfaces/ShowMessageParamsToast';
import { LoadingService } from '@osapp/services/loading.service';
import { PlatformService } from '@osapp/services/platform.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { EMPTY, Observable, Subject, defer } from 'rxjs';
import { debounceTime, filter, finalize, mergeMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { C_DESMOS_PERMISSION_ID } from '../../../../app/app.constants';
import { Acte } from '../../../../model/Acte';
import { EMajorationType } from '../../../../model/EMajorationType';
import { EProfession } from '../../../../model/EProfession';
import { IActeDocumentByLc } from '../../../../model/IActeDocumentByLc';
import { IDeplacementByProfession } from '../../../../model/IDeplacementByProfession';
import { IMajorationDetails } from '../../../../model/IMajorationDetails';
import { Majoration } from '../../../../model/Majoration';
import { Traitement } from '../../../../model/Traitement';
import { IndemniteService } from '../../../../services/indemnite.service';
import { SeanceService } from '../../../../services/seance.service';
import { TraitementService } from '../../../../services/traitement.service';
import { ActesService } from '../../../actes/actes.service';
import { IExportPrestationResult } from '../../../patients/model/IExportPrestationResult';
import { ExportService } from '../../../patients/services/export.service';
import { EIndemniteType } from '../../../traitement/model/EIndemniteType';
import { Indemnite } from '../../../traitement/model/Indemnite';
import { MajorationsComponent } from '../../../traitement/slides-traitement/majorations/majorations.component';
import { FacturationService } from '../../facturation.service';
import { EIdlPrestationLineCategory } from '../../models/eidl-prestation-line-category.enum';
import { IdlPrestation } from '../../models/idl-prestation';
import { IdlPrestationLine } from '../../models/idl-prestation-line';
import { FacturationModalComponent } from '../facturation-modal/facturation-modal.component';

@Component({
	selector: 'idl-traitement-prestations-modal',
	templateUrl: './traitement-prestations-modal.component.html',
	styleUrls: ['./traitement-prestations-modal.component.scss'],
})
export class TraitementPrestationsModalComponent extends ModalComponentBase<boolean> implements OnInit, IHasPermission {

	//#region FIELDS

	private readonly C_MAX_SMALL_SCREEN_SIZE = 930;

	private moSavePrestationSubject = new Subject<IdlPrestation>();

	private maMajorations: IActeDocumentByLc[];

	//#endregion

	//#region PROPERTIES

	public static readonly C_LOG_ID = "TRAIT.PRESTA.C::";

	@Input() public traitement: Traitement;
	@Input() public prestations: IdlPrestation[];
	@Input() public selectAll: boolean;

	sortedPrestations: IdlPrestation[]; 

	public contactsById = new Map<string, IContact>();

	/** Liste des prestations à envoyer. */
	public prestationsToSend: IdlPrestation[] = [];
	/** Map associant un message d'erreur d'export avec un id de prestation. */
	public exportFailedMessageByPrestationId = new Map<string, string>();

	public indemniteOptions: string[] = EnumHelper.getValues(EIndemniteType);
	public IKTypes: EIndemniteType[] = [EIndemniteType.IKM, EIndemniteType.IK, EIndemniteType.IKS];
	/** Map des détails des majorations, indexé par valeur de l'enum MajorationType (string représentant un type de majoration). */
	public majorationsDetails: IMajorationDetails[] = MajorationsComponent.C_MAJORATION_DETAILS;

	private mbIsSmallScreen: boolean = window.innerWidth < this.C_MAX_SMALL_SCREEN_SIZE;
	public get isSmallScreen(): boolean { return this.mbIsSmallScreen; }

	public discounRateOptions: { value: number, label: string }[] = [
		{ value: 0, label: "0%" },
		{ value: 0.5, label: "50%" },
		{ value: 1, label: "100%" }
	];

	@Permissions("create", C_DESMOS_PERMISSION_ID)
	public get canBillToDesmos(): boolean {
		return true;
	}

	public get canBillToFsv(): boolean {
		return true;
	}

	public get isModeAgrement(): boolean {
		return false;
	}

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcIndemnite: IndemniteService,
		private readonly isvcExport: ExportService,
		private readonly isvcFacturation: FacturationService,
		private readonly isvcPrestation: PrestationService,
		private readonly isvcContacts: ContactsService,
		private readonly isvcTraitement: TraitementService,
		private readonly isvcLoading: LoadingService,
		private readonly isvcSeance: SeanceService,
		private readonly isvcActes: ActesService,
		private readonly isvcModal: ModalService,
		public readonly isvcPermissions: PermissionsService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService
	) {
		super(poModalCtrl, psvcPlatform);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		if (this.prestations) {
      this.sortedPrestations = [...this.prestations];
      this.sortedPrestations.sort((a, b) => {
        const dateA = this.extractDateFromString(a['#lines'][0].group);
        const dateB = this.extractDateFromString(b['#lines'][0].group);
        return dateA.getTime() - dateB.getTime();
      });
    }

		let loLoader: Loader;
		defer(() => this.isvcLoading.create("Chargement en cours..."))
			.pipe(
				mergeMap((poLoader: Loader) => poLoader.present()),
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap(() => {
					const laIntervenantsIds: string[] = [];
					this.sortedPrestations.forEach((poPrestation: IdlPrestation) => laIntervenantsIds.push(...[poPrestation.authorId]));
					return this.isvcContacts.getContactsByIds(ArrayHelper.unique(laIntervenantsIds));
				}),
				tap((paContacts: IContact[]) => {
					this.contactsById = new Map(paContacts.map((poContact: IContact) => [poContact._id, poContact]))
				}),
				mergeMap(() => this.isvcSeance.getMajorations()),
				tap((paMajorations: IActeDocumentByLc[]) => this.maMajorations = paMajorations),
				tap(() => {
					if (this.selectAll) {
						this.onPrestationsCheckboxClick(this.sortedPrestations);
						if (this.sortedPrestations.filter((poPrestation: IdlPrestation) => poPrestation.isLocked).length > 0)
							this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
								header: "Attention",
								message: "Une ou plusieurs séances sont bloquées, vous devez les débloquer pour pouvoir les facturer."
							}));
					}
				}),
				mergeMap(() => loLoader.dismiss()),
				finalize(() => loLoader?.dismiss()),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.moSavePrestationSubject.asObservable()
			.pipe(
				debounceTime(1000),
				mergeMap((poPrestation: IdlPrestation) => this.savePrestations([poPrestation], TraitementPrestationsModalComponent.C_LOG_ID))
			).subscribe();
	}

	private extractDateFromString(groupString: string): Date {
    const dateParts = groupString
      .substring(groupString.indexOf("du") + 3)
      .split(/[\/: ]/);

    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const year = parseInt(dateParts[2], 10);
    const hour = parseInt(dateParts[3], 10);
    const minute = parseInt(dateParts[4], 10);

    return new Date(year, month, day, hour, minute);
  }

	public savePrestations(paPrestations: IdlPrestation[], psLogId: string): Observable<IdlPrestation[]> {
		return this.isvcPrestation.savePrestations(paPrestations)
			.pipe(
				tap(
					() => this.detectChanges(),
					(poError: any) => console.error(`${psLogId}Erreur lors de la sauvegarde des prestations.`, paPrestations, poError)
				),
				takeUntil(this.destroyed$)
			) as Observable<IdlPrestation[]>;
	}

	public onMajorationClick(poPrestation: IdlPrestation, peMajorationType: EMajorationType): void {
		if (poPrestation.status !== EPrestationStatus.checked) {
			if (poPrestation.lines.some((poLine: IdlPrestationLine) => poLine.lettreCle === peMajorationType)) {
				ArrayHelper.removeElementByFinder(poPrestation.lines, (poLine: IdlPrestationLine) => poLine.lettreCle === peMajorationType);
			}
			else {
				const loNewMajoration: Majoration = this.createMajoration(peMajorationType);
				const loFirstLine: IdlPrestationLine = ArrayHelper.getFirstElement(poPrestation.originalLines);
				poPrestation.lines.push(this.isvcSeance.createPrestationLineFromMajoration(loNewMajoration, loFirstLine.group));
			};
			this.moSavePrestationSubject.next(poPrestation);
		};
	}

	/** Crée une majoration.
	 * @param psMajorationTypeKey Clé du type de la majoration à créer.
	 */
	private createMajoration(peMajorationType: EMajorationType): Majoration {
		return new Majoration(
			this.isvcSeance.getMajorationIdFromType(peMajorationType),
			peMajorationType,
			this.isvcSeance.getMajorationPriceFromArray(this.maMajorations, peMajorationType),
			this.isvcSeance.getMajorationDescriptionFromArray(this.maMajorations, peMajorationType),
			true
		);
	}

	public onIndemniteClick(poTraitement: Traitement, poPrestation: IdlPrestation, peIndemniteType: EIndemniteType): void {
		if (poPrestation.status !== EPrestationStatus.checked) {
			if (poPrestation.lines.some((poLine: IdlPrestationLine) => poLine.lettreCle === peIndemniteType)) {
				ArrayHelper.removeElementByFinder(poPrestation.lines, (poLine: IdlPrestationLine) => poLine.lettreCle === peIndemniteType);
				this.moSavePrestationSubject.next(poPrestation);
			}
			else {
				this.isvcIndemnite.getDeplacementsByProfession(EProfession.idel)
					.pipe(
						tap((paResults: IDeplacementByProfession[]) => {
							const loNewIndemnite: Indemnite = this.isvcIndemnite.createNewIndemnite(
								"",
								peIndemniteType,
								paResults,
								poTraitement.deplacement
							);
							loNewIndemnite.price = this.isvcIndemnite.getDeplacementTarif(this.isvcIndemnite.getDeplacementByProfession(peIndemniteType, paResults));
							const loFirstLine: IdlPrestationLine = ArrayHelper.getFirstElement(poPrestation.originalLines);
							poPrestation.lines.push(new IdlPrestationLine({
								ref: loNewIndemnite.type,
								lettreCle: loNewIndemnite.type,
								price: loNewIndemnite.price,
								quantity: 4,
								description: loNewIndemnite.description,
								category: EIdlPrestationLineCategory.indemnites,
								group: loFirstLine.group,
								ordonnanceId: loFirstLine.ordonnanceId
							}));
							this.moSavePrestationSubject.next(poPrestation);
						}),
						takeUntil(this.destroyed$)
					)
					.subscribe();
			};
		};
	}

	public onDistanceChange(pnDistance: number, poPrestation: IdlPrestation): void {
		poPrestation.lines
			.filter((poLine: IdlPrestationLine) => this.IKTypes.includes(poLine.lettreCle as EIndemniteType))
			.forEach((poLine: PrestationLine) => poLine.quantity = +pnDistance);
		this.moSavePrestationSubject.next(poPrestation);
	}

	public changeActe(poPrestation: IdlPrestation, poActeLine: IdlPrestationLine): void {
		this.isvcActes.selectActeWithModal()
			.pipe(
				tap((poNewActe: Acte) => {
					if (!ObjectHelper.isNullOrEmpty(poNewActe)) {
						if (poPrestation.originalLines.some((poLine: PrestationLine) => poLine.ref === poActeLine.ref)) // Si l'acte se trouve dans la facturation initial.
							poActeLine.originalLineRef = poActeLine.originalLineRef ?? poActeLine.ref;
						poActeLine.ref = poNewActe._id;
						poActeLine.price = poNewActe.price;
						poActeLine.discountRate = Math.abs(1 - poNewActe.taxAllowance);
						poActeLine.extraCharge = poNewActe.extraCharge;
						poActeLine.description = poNewActe.description;

						this.moSavePrestationSubject.next(poPrestation);
					};
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public addActe(poPrestation: IdlPrestation): void {
		this.isvcActes.selectActeWithModal()
			.pipe(
				tap((poNewActe: Acte) => {
					if (!ObjectHelper.isNullOrEmpty(poNewActe)) {
						let lastActeIndex = 0;
						const loFirstLine: IdlPrestationLine = ArrayHelper.getFirstElement(poPrestation.lines);
						poPrestation.lines.forEach((poLine: PrestationLine, pnIndex: number) => lastActeIndex = poLine.category === "Actes" ? pnIndex : lastActeIndex);
						poPrestation.lines.splice(lastActeIndex + 1, 0, this.isvcSeance.createPrestationLineFromActe(poNewActe, loFirstLine.group));
						this.moSavePrestationSubject.next(poPrestation);
					};
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public onDiscountRateChanged(pnNewDiscountRate: number, poPrestation: IdlPrestation, poLine: PrestationLine): void {
		if (NumberHelper.isValid(pnNewDiscountRate)) {
			poLine.discountRate = pnNewDiscountRate;
			this.moSavePrestationSubject.next(poPrestation);
		};
	}

	public getDiscountRateLabel(pnDiscountRate: number): string {
		switch (pnDiscountRate) {
			case 0:
				return "0%";
			case 0.5:
				return "50%";
			case 1:
				return "100%";
			default:
				return "";
		}
	}

	@ValidationPopup({
		title: "Confirmation",
		message: "Êtes-sur de vouloir supprimer cet acte ?",
		okButton: "Oui",
		cancelButton: "Annuler"
	})
	public deleteActe(poPrestation: IdlPrestation, poActeLine: IdlPrestationLine): void {
		if (!ObjectHelper.isNullOrEmpty(this.getOriginalLine(poPrestation, poActeLine.ref)) || !ObjectHelper.isNullOrEmpty(this.getOriginalLine(poPrestation, poActeLine.originalLineRef)))
			poActeLine.deleted = true;
		else
			ArrayHelper.removeElement(poPrestation.lines, poActeLine);

		this.moSavePrestationSubject.next(poPrestation);
	}

	public onPrestationLockClick(poPrestation: IdlPrestation): void {
		poPrestation.status = poPrestation.status === EPrestationStatus.locked ? poPrestation.originalStatus : EPrestationStatus.locked;
		this.moSavePrestationSubject.next(poPrestation);
	}

	public getActesLines(poPrestation: IdlPrestation): PrestationLine[] {
		return poPrestation.lines.filter((poLine: PrestationLine) => poLine.category === EIdlPrestationLineCategory.actes);
	}

	public getSelectedIkLine(poPrestation: IdlPrestation): PrestationLine {
		return poPrestation.lines.find((poLine: PrestationLine) => poLine.ref.startsWith("IK"));
	}

	public async onTraitementLockClick(paPrestations: IdlPrestation[]): Promise<void> {
		let loReasonResponse: IUiResponse<boolean, { value: string; }>;
		this.traitement.isBillingLocked = !this.traitement.isBillingLocked;

		if (this.traitement.isBillingLocked) {
			loReasonResponse = await this.showInputPopup("Quelle est la raison de la mise en attente de cette séance ?").toPromise();
			if (ObjectHelper.isNullOrEmpty(loReasonResponse) || loReasonResponse.response)
				this.traitement.billingLockedReason = this.traitement.isBillingLocked ? loReasonResponse?.values?.value : undefined;
		}
		else
			this.traitement.billingLockedReason = undefined;

		paPrestations.forEach((poPrestation: IdlPrestation) => {
			if (poPrestation.status !== EPrestationStatus.canceled)
				poPrestation.status = this.traitement.isBillingLocked ? EPrestationStatus.locked : poPrestation.originalStatus;
		});

		StoreHelper.makeDocumentDirty(this.traitement);
		this.isvcTraitement.saveTraitement(this.traitement)
			.pipe(
				mergeMap(() => this.savePrestations(paPrestations, TraitementPrestationsModalComponent.C_LOG_ID)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public onPrestationStatusChange(poPrestation: IdlPrestation, peNewStatus: EPrestationStatus): void {
		poPrestation.status = peNewStatus;
		this.moSavePrestationSubject.next(poPrestation);
	}

	/** Affiche la popup de saisie de la raison du blocage de l'envoi en facturation.
	 * @param psMessage
	 */
	private showInputPopup(psMessage: string): Observable<IUiResponse<boolean, { value: string }>> {
		return this.isvcUiMessage.showAsyncMessage<boolean, { value: string }>(new ShowMessageParamsPopup({
			header: psMessage,
			inputs: [
				{
					type: "textarea",
					name: "value"
				}
			],
			buttons: [
				{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() },
				{ text: "Valider", handler: () => UiMessageService.getTruthyResponse() }
			]
		}));
	}

	/** Affiche la popup d'édition de la raison du blocage.
	 * @param poItem
	 */
	public showEditLockReason(poTraitement: Traitement): void {
		this.isvcUiMessage.showAsyncMessage<boolean, { reason: string }>(new ShowMessageParamsPopup({
			header: "Raison du blocage :",
			inputs: [
				{
					type: "textarea",
					name: "reason",
					value: poTraitement.billingLockedReason
				}
			],
			buttons: [
				{ text: "Fermer", handler: () => UiMessageService.getFalsyResponse() },
				{ text: "Éditer", handler: () => UiMessageService.getTruthyResponse() }
			]
		})).pipe(
			filter((poResponse: IUiResponse<boolean, { reason: string; }>) => poResponse.response),
			tap((poResponse: IUiResponse<boolean, { reason: string; }>) => {
				poTraitement.billingLockedReason = poResponse.values.reason;
				StoreHelper.makeDocumentDirty(poTraitement);
			}),
			mergeMap(() => this.isvcTraitement.saveTraitement(poTraitement)),
			takeUntil(this.destroyed$)
		)
			.subscribe();
	}

	/** Affiche le message d'erreur de l'export d'une prestation.
	 * @param poPrestationId Id de la prestation dont il faut afficher le message d'échec d'export.
	 */
	public displayExportPrestationFailedPopup(poPrestationId: string): void {
		this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Export impossible", message: this.exportFailedMessageByPrestationId.get(poPrestationId) }));
	}

	public selectIntervenant(poPrestation: IdlPrestation): void {
		this.isvcFacturation.selectIntervenant(poPrestation)
			.pipe(
				mergeMap((poIntervenant: IContact) => {
					if (!ObjectHelper.isNullOrEmpty(poIntervenant)) {
						poPrestation.vendorId = poIntervenant._id;
						return this.isvcPrestation.savePrestation(poPrestation)
							.pipe(tap(() => this.detectChanges()));
					};
					return EMPTY;
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public onObservationChange(poPrestation: IdlPrestation): void {
		this.moSavePrestationSubject.next(poPrestation);
	}

	@ValidationPopup({
		title: "Confirmation",
		message: "Vous allez ramener cette prestation à son état initial. Voulez-vous continuer?",
		okButton: "Oui",
		cancelButton: "Annuler"
	})
	public reset(poPrestation: IdlPrestation): void {
		poPrestation.reset();
		this.moSavePrestationSubject.next(poPrestation);
		this.detectChanges();
	}

	public onPrestationsCheckboxClick(paPrestations: IdlPrestation[]): void {
		if (ArrayHelper.hasElements(paPrestations)) {
			const lbAllChecked: boolean = this.includesInPrestationsToSend(paPrestations);
			paPrestations = paPrestations.filter((poPrestation: IdlPrestation) => !poPrestation.isLocked);
			paPrestations.forEach((poPrestation: IdlPrestation) => {
				if (lbAllChecked)
					ArrayHelper.removeElement(this.prestationsToSend, poPrestation);
				else
					ArrayHelper.pushIfNotPresent(this.prestationsToSend, poPrestation);
			});
		};
	}

	private includesInPrestationsToSend(paPrestations: IdlPrestation[]): boolean {
		return paPrestations?.filter((poPrestation: IdlPrestation) => !poPrestation.isLocked).every((poPrestation: IdlPrestation) => this.prestationsToSend.includes(poPrestation));
	}

	/** Envoie les prestations à Desmos  */
	public sendToDesmos(): void {
		const lfGetLoaderMessage: (pnCurrent: number) => string = (pnCurrent: number) => `Export de la facture n° ${pnCurrent} sur ${this.prestationsToSend.length} ...`;
		let lnCurrentExportCount = 1;
		let loLoader: Loader;

		defer(() => this.isvcLoading.create(lfGetLoaderMessage(lnCurrentExportCount)))
			.pipe(
				mergeMap((poLoader: Loader) => poLoader.present()),
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap(_ => this.isvcExport.exportPrestations(this.prestationsToSend)),
				tap((poResult: IExportPrestationResult) => {
					if (++lnCurrentExportCount < poResult.totalCount)
						loLoader.text = lfGetLoaderMessage(++lnCurrentExportCount);
				}),
				toArray(),
				tap((paResults: IExportPrestationResult[]) => this.showSendingResultMessage(ArrayHelper.getLastElement(paResults))),
				tap(() => {
					loLoader.dismiss();
					this.close();
				},
					_ => loLoader.dismiss()
				),
				finalize(() => loLoader?.dismiss()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Prépare les factures pour FSV */
	public prepareInvoices(): void {
		if (ArrayHelper.hasElements(this.prestationsToSend)) {
			this.isvcModal.open({
				component: FacturationModalComponent,
				componentProps: {
					patientId: this.traitement.patientId,
					prestations: this.prestationsToSend
				}
			})
				.pipe(
					tap((pbPrepared: boolean) => {
						if (pbPrepared) {
							ArrayHelper.removeElementByFinder(this.sortedPrestations, (poPrestationToRemove: IdlPrestation) => this.prestationsToSend.some((poPrestation: IdlPrestation) => poPrestation._id === poPrestationToRemove._id));
							this.prestationsToSend = [];
							this.close();
						}
						this.detectChanges();
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
	}

	/** Affiche un message informant l'utilisateur du nombre de prestations transmises et non transmises. */
	private showSendingResultMessage(poResult: IExportPrestationResult): void {
		const lnExportedPrestationsCount: number = poResult.successIds.length;
		const lsExportedPlurial: string = lnExportedPrestationsCount > 1 ? "s" : "";
		const loToastParams = new ShowMessageParamsToast({ message: `${lnExportedPrestationsCount} prestation${lsExportedPlurial} transmise${lsExportedPlurial}.` });
		this.exportFailedMessageByPrestationId.clear();

		if (lnExportedPrestationsCount < poResult.totalCount) { // Si le nombre de succès est inférieur au nombre total, il y a eu des erreurs.
			const lnNotExportedPrestationsCount: number = poResult.failIds.length;
			const lsNotExportedPlurial: string = lnNotExportedPrestationsCount > 1 ? "s" : "";

			loToastParams.message += `\n<span style="color:red">${lnNotExportedPrestationsCount} prestation${lsNotExportedPlurial} non transmise${lsNotExportedPlurial}.</span>`;
		}

		if (ArrayHelper.hasElements(poResult.successIds)) {
			ArrayHelper.removeElementsByFinder(this.prestationsToSend, (poPrestation: IdlPrestation) => poResult.successIds.includes(poPrestation._id));
			ArrayHelper.removeElementsByFinder(this.sortedPrestations, (poPrestation: IdlPrestation) => poResult.successIds.includes(poPrestation._id));
		}

		if (ArrayHelper.hasElements(poResult.failIds)) {
			poResult.failIds.forEach((psId: string) => {
				const loPrestation: IdlPrestation = this.prestationsToSend.find((poPrestation: IdlPrestation) => psId === poPrestation._id);
				this.exportFailedMessageByPrestationId.set(psId, this.isvcExport.getPrestationExportFailedMessage(loPrestation));
			});
		}

		this.isvcUiMessage.showMessage(loToastParams);
	}

	public getOriginalsMajorations(poPrestation: IdlPrestation): { shortLabel: string, deleted?: boolean }[] {
		const laOrignalsMajorations: string[] = this.getMajorationsAttributeFromLines(poPrestation.originalLines, "shortLabel");
		const laCurrentMajorations: string[] = this.getMajorationsAttributeFromLines(poPrestation.lines, "shortLabel");

		const loRes: { shortLabel: string, deleted?: boolean }[] = [];
		if (laOrignalsMajorations.length > 0) {
			laOrignalsMajorations.forEach((psLabel: string) => {
				const loMajo: { shortLabel: string, deleted?: boolean } = { shortLabel: psLabel };
				loMajo.deleted = !laCurrentMajorations.includes(psLabel);
				loRes.push(loMajo);
			});
		}
		else
			loRes.push({ shortLabel: "Aucune" });

		return loRes;
	}

	public getOriginalsIndemnites(poPrestation: IdlPrestation): { label: string, deleted?: boolean }[] {
		const laOrignalsIndemnites: string[] = this.getIndemnitesAttributeFromLines(poPrestation.originalLines, "ref");
		const laCurrentIndemnites: string[] = this.getIndemnitesAttributeFromLines(poPrestation.lines, "ref");

		const loRes: { label: string, deleted?: boolean }[] = [];
		if (laOrignalsIndemnites.length > 0) {
			laOrignalsIndemnites.forEach((psLabel: string) => {
				const loIndemnite: { label: string, deleted?: boolean } = { label: psLabel };
				loIndemnite.deleted = !laCurrentIndemnites.includes(psLabel);
				loRes.push(loIndemnite);
			});
		}
		else
			loRes.push({ label: "Aucune" });


		return loRes;
	}

	public getIndemnitesAttributeFromLines(poLines: PrestationLine[], psAttribute: string): any {
		const laMajoLines: PrestationLine[] = poLines.filter((poLine: PrestationLine) => poLine.category === EIdlPrestationLineCategory.indemnites);
		return laMajoLines.map((poLine: PrestationLine) => poLine[psAttribute]);
	}

	public getMajorationsAttributeFromLines(poLines: PrestationLine[], psAttribute: string): any {
		const laMajoLines: PrestationLine[] = poLines.filter((poLine: PrestationLine) => poLine.category === EIdlPrestationLineCategory.majorations);
		return this.majorationsDetails
			.filter((poMajDetail: IMajorationDetails) => laMajoLines.map((poLine: IdlPrestationLine) => poLine.lettreCle).includes(poMajDetail.valueType))
			.map((poMajDetail: IMajorationDetails) => poMajDetail[psAttribute]);
	}

	public getIndemnitesDistanceValue(poPrestation: IdlPrestation): number {
		return ArrayHelper.getFirstElement(poPrestation.lines.filter((poLine: PrestationLine) => this.IKTypes.includes(poLine.ref as EIndemniteType)))?.quantity ?? 0;
	}

	public getMajorationsTotalPrice(poPrestation: IdlPrestation): number {
		return this.getTotalPriceOf(poPrestation, EIdlPrestationLineCategory.majorations);
	}

	public getIndemnitesTotalPrice(poPrestation: IdlPrestation): number {
		return this.getTotalPriceOf(poPrestation, EIdlPrestationLineCategory.indemnites);
	}

	private getTotalPriceOf(poPrestation: IdlPrestation, peCategory: EIdlPrestationLineCategory): number {
		return poPrestation.lines.filter((poLine: PrestationLine) => poLine.category === peCategory).reduce((pnTotal: number, poLine: PrestationLine) => pnTotal += poLine.total, 0);
	}

	public hasIKType(poPrestation: IdlPrestation): boolean {
		return poPrestation.lines.some((poLine: PrestationLine) => this.IKTypes.includes(poLine.ref as EIndemniteType));
	}

	public getOriginalLine(poPrestation: IdlPrestation, psOriginalLineRef: string): PrestationLine {
		return poPrestation.originalLines.find((poLine: PrestationLine) => poLine.ref === psOriginalLineRef);
	}

	public getContactAvatar(psContactId: string): IAvatar {
		return ContactsService.createContactAvatar(this.contactsById.get(psContactId));
	}

	@HostListener('window:resize')
	public onResize(): void {
		this.mbIsSmallScreen = window.innerWidth < this.C_MAX_SMALL_SCREEN_SIZE;
	}

	//#endregion

}
