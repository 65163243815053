import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Navigation, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ERouteUrlPart } from '@osapp/model/route/ERouteUrlPart';
import { ApplicationService } from '@osapp/services/application.service';
import { PageManagerService } from '@osapp/services/pageManager.service';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ESurveilancesType } from '../../../model/constantes/ESurveillancesType';
import { IConstantesPageParams } from '../../../model/constantes/IConstantesPageParams';
import { IConstantesPageRouteParams } from '../../../model/constantes/IConstantesPageRouteParams';
import { InitConstantesError } from '../../../model/constantes/InitConstantesError';
import { ISurveillances } from '../../../model/constantes/ISurveillances';
import { IPatient } from '../../../model/IPatient';
import { ConstantesService } from '../../../services/constantes.service';

@Component({
	selector: "idl-constantes-page",
	templateUrl: './constantes-page.component.html',
	styleUrls: ['./constantes-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConstantesPageComponent extends ComponentBase implements OnInit {

	//#region FIELDS

	/** Fonction permettant de retourner sur la page précédente. */
	private readonly mfGoBack: () => Promise<void>;
	/** Fonction permettant de retourner sur la page précédente. */
	private readonly mfNavigateRoot: () => Promise<boolean>;
	/** Type de l'objet surveillances. */
	private msSurveillancesType: ESurveilancesType

	//#endregion

	//#region PROPERTIES

	public surveillances?: ISurveillances;
	/** @implements */
	public patientModel?: IPatient;
	/** Indique si on est en mode readOnly (visu) ou non (edit/new). */
	public isReadOnly: boolean;
	/** Titre de la page. */
	public title: string;
	/** Indique si l'objet constantes est en mode création ou non. */
	public isCreation: boolean;

	//#endregion

	//#region METHODS

	constructor(
		private ioActivatedRoute: ActivatedRoute,
		private ioRouter: Router,
		private isvcConstantes: ConstantesService,
		private ioTitleCasePipe: TitleCasePipe,
		poChangeDetectorRef: ChangeDetectorRef,
		psvcPageManager: PageManagerService,
		poNavCtrl: NavController,
	) {
		super(poChangeDetectorRef);
		this.mfGoBack = () => psvcPageManager.goBack();
		this.mfNavigateRoot = () => poNavCtrl.navigateRoot(ApplicationService.C_HOME_ROUTE_URL);
	}

	public ngOnInit(): void {
		const lsFirstUrlPart: string = ArrayHelper.getFirstElement(this.ioActivatedRoute.snapshot.url).path;
		const lsLastUrlPart: string = ArrayHelper.getLastElement(this.ioActivatedRoute.snapshot.url).path;
		this.msSurveillancesType = lsFirstUrlPart as ESurveilancesType;

		this.isCreation = lsLastUrlPart === ERouteUrlPart.new;
		this.isReadOnly = lsLastUrlPart !== ERouteUrlPart.edit && lsLastUrlPart !== ERouteUrlPart.new;

		if (this.isReadOnly)
			this.title = this.ioTitleCasePipe.transform(this.msSurveillancesType);
		else if (lsLastUrlPart === ERouteUrlPart.new) {
			switch (this.msSurveillancesType) {
				case ESurveilancesType.constantes:
					this.title = ConstantesService.C_CREATE_CONSTANTES_TITLE;
					break;
				case ESurveilancesType.injections:
					this.title = ConstantesService.C_CREATE_INJECTIONS_TITLE;
					break;
			};
		}
		else {
			switch (this.msSurveillancesType) {
				case ESurveilancesType.constantes:
					this.title = ConstantesService.C_EDIT_CONSTANTES_TITLE;
					break;
				case ESurveilancesType.injections:
					this.title = ConstantesService.C_EDIT_INJECTIONS_TITLE;
					break;
			};
		};

		this.getASurveillances() // Peut retourner plusieurs résultats (get live en mode visu).
			.pipe(
				tap((poResult: ISurveillances) => {
					this.surveillances = poResult;
					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private getASurveillances(): Observable<ISurveillances> {
		let loGetConstanteOrInjection$: Observable<ISurveillances | never>;

		this.surveillances = this.surveillances ?? (this.ioActivatedRoute.snapshot.data as IConstantesPageRouteParams).surveillances;

		if (!this.surveillances) { // Mode création.
			const loNavigation: Navigation = this.ioRouter.getCurrentNavigation();

			if ((loNavigation?.extras?.state as IConstantesPageParams)?.patientModel) {
				this.patientModel = JSON.parse((loNavigation.extras.state as IConstantesPageParams).patientModel as string);
				switch (this.msSurveillancesType) {
					case ESurveilancesType.constantes:
						loGetConstanteOrInjection$ = of(this.isvcConstantes.createAConstantes(this.patientModel));
						break;
					case ESurveilancesType.injections:
						loGetConstanteOrInjection$ = of(this.isvcConstantes.createAnInjections(this.patientModel));
						break;
				};
			}
			else
				loGetConstanteOrInjection$ = throwError(new InitConstantesError());
		}
		else if (this.isReadOnly) // Mode visu.
			loGetConstanteOrInjection$ = this.isvcConstantes.getASurveillancesUpdates(this.surveillances);
		else
			loGetConstanteOrInjection$ = of(this.surveillances);

		return loGetConstanteOrInjection$;
	}

	/** Navigue vers le mode edit de l'objet constantes. */
	public routeToEdit(): void {
		this.ioRouter.navigate([".", ERouteUrlPart.edit], { relativeTo: this.ioActivatedRoute });
	}

	/** Enregistre l'objet constantes. */
	public saveConstantes(): void {
		this.isvcConstantes.saveSurveillances(this.surveillances)
			.pipe(
				// delay(250), //todo : https://dev.azure.com/calaosoft/osapp-project/_workitems/edit/3499
				mergeMap(_ => this.mfGoBack()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public goHome(): void {
		this.mfNavigateRoot();
	}

	//#endregion
}