import { Observable, of } from 'rxjs';
import { ArrayHelper } from "../../helpers/arrayHelper";
import { IStoreDocument } from '../store';
import { IEntityGuard, IGuardResult } from "./IEntityGuard";
import { IEntityLink } from "./IEntityLink";

/** Assure les contrôles par défaut avant l'altération d'une entité. */
export class DefaultEntityGuard implements IEntityGuard {

	constructor(protected msLinkedElementMessage: string = "Cet élément est lié") {

	}

	/** Une entité peut être supprimée si elle n'est liée à aucune autre entité. */
	public isDeletable(_: IStoreDocument, paLinks: IEntityLink[]): Observable<IGuardResult> {
		if (ArrayHelper.hasElements(paLinks))
			return of({ result: false, message: `${this.msLinkedElementMessage} à ${paLinks.length} élément${paLinks.length > 1 ? "s" : ""}.` });
		else
			return of({ result: true, message: undefined });
	}

}