import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { defer, from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { DynamicPageComponent } from '../components/dynamicPage/dynamicPage.component';
import { ArrayHelper } from '../helpers/arrayHelper';
import { AvatarHelper } from '../helpers/avatarHelper';
import { EntityHelper } from '../helpers/entityHelper';
import { MapHelper } from '../helpers/mapHelper';
import { StoreHelper } from '../helpers/storeHelper';
import { StringHelper } from '../helpers/stringHelper';
import { UserData } from '../model';
import { IContact } from '../model/contacts/IContact';
import { IGroup } from '../model/contacts/IGroup';
import { IGroupMember } from '../model/contacts/IGroupMember';
import { IEntityLink } from '../model/entities/IEntityLink';
import { IEntityLinkPart } from '../model/entities/IEntityLinkPart';
import { EPrefix } from '../model/EPrefix';
import { ESuffix } from '../model/ESuffix';
import { IFormParams } from '../model/forms/IFormParams';
import { IIndexedArray } from '../model/IIndexedArray';
import { PageInfo } from '../model/PageInfo';
import { EAvatarSize } from '../model/picture/EAvatarSize';
import { IAvatar } from '../model/picture/IAvatar';
import { PermissionMissingError } from '../model/security/errors/PermissionMissingError';
import { IDataSource } from '../model/store/IDataSource';
import { IStoreDataResponse } from '../model/store/IStoreDataResponse';
import { EPermission } from '../modules/permissions/models/EPermission';
import { ICrudPermission } from '../modules/permissions/models/IScopePermissions';
import { C_SECTORS_ROLE_ID, PermissionsService } from '../modules/permissions/services/permissions.service';
import { ISector } from '../modules/sectors/models/isector';
import { ContactsService } from './contacts.service';
import { EntityLinkService } from './entityLink.service';
import { Store } from './store.service';

@Injectable({ providedIn: "root" })
export class GroupsService {

	//#region FIELDS

	/** Propriété de tri pour trier la liste des groupes dans un certain ordre ("name"). */
	private static readonly C_GROUP_PROPERTY_SORT = "name";
	private static readonly C_DEFAULT_GROUP_ICON = "group";
	private static readonly C_DEFAULT_SECTOR_ICON = "pin";

	/** Identifiant du descripteur de formulaire par défaut pour un groupe. */
	public static C_DEFAULT_GROUPES_FORMDESC_ID = "formDesc_groups";
	public static C_GROUPES_EDIT_FORMDEF_ID = `group${ESuffix.edit}`;

	/** Identifiant du descripteur de formulaire par défaut pour un secteur. */
	public static C_DEFAULT_SECTORS_FORMDESC_ID = "formDesc_sectors";

	/** Sujet permettant d'envoyer des événements liés a un groupe. */
	private readonly moGroupSubject = new Subject<IGroup>();

	//#endregion

	//#region PROPERTIES

	public static readonly C_EXCLUDE_ROLE_IDS = ["sectors"];

	//#endregion

	//#region METHODS

	constructor(
		/** Service pour les requêtes sur base de données. */
		private isvcStore: Store,
		/** Service de gestion des contacts. */
		private isvcEntityLink: EntityLinkService,
		private isvcContacts: ContactsService,
		private ioModalCtrl: ModalController,
		private isvcPermissions: PermissionsService,
	) { }

	private static isGroupMember(poIdOrModel: string | IGroupMember, pePrefix: EPrefix): boolean {
		const lsId: string = typeof poIdOrModel === "string" ? poIdOrModel : poIdOrModel._id;
		return !StringHelper.isBlank(lsId) && lsId.indexOf(pePrefix) === 0;
	}

	/** Construit un avatar à partir d'un groupe.
	 * @param poGroup
	 * @param peAvatarSize
	 */
	public static createGroupAvatar(poGroup: IGroup, peAvatarSize: EAvatarSize = EAvatarSize.big): IAvatar {
		if (poGroup.picture && (poGroup.picture.base64 || poGroup.picture.guid || poGroup.picture.url))
			return { ...AvatarHelper.createAvatarFromPicture(poGroup.picture, peAvatarSize), icon: poGroup.roles?.includes(C_SECTORS_ROLE_ID) ? GroupsService.C_DEFAULT_SECTOR_ICON : GroupsService.C_DEFAULT_GROUP_ICON };
		else
			return AvatarHelper.createAvatarFromIcon(poGroup.roles?.includes(C_SECTORS_ROLE_ID) ? GroupsService.C_DEFAULT_SECTOR_ICON : GroupsService.C_DEFAULT_GROUP_ICON, peAvatarSize);
	}

	/** Retourne `true` si l'utilisateur peut manipuler un groupe
	 * (permission des groupes accordée, et si non renseignée alors permission des contacts accordée),
	 * `false` sinon.
	 * */
	private checkPermission(psKey: keyof ICrudPermission): boolean {
		return this.isvcPermissions.evaluatePermission([EPermission.contacts, EPermission.groups], psKey);
	}

	/** Retourne `true` si l'utilisateur a la permission pour lire un groupe, retourne une erreur de lecture de permission sinon. */
	private checkReadPermission(): Observable<true | never> {
		return this.checkPermission("read") ? of(true) : throwError(new PermissionMissingError(ContactsService.C_NO_READ_PERMISSION_MESSAGE));
	}

	/** Ajoute un groupe en base de données ainsi que les documents de liens correspondant pour joindre les groupes et les contacts participants.
	 * Le groupe est automatiquement marqué comme étant un groupe "Utilisateur" et non "Système" grâce au marqueur isUserGroup.
	 * @param poGroup Nouveau groupe qu'il faut enregistrer en base pour finaliser sa création.
	 */
	public addGroup(poGroup: IGroup): Observable<boolean> {
		poGroup.isUserGroup = true;

		if (this.checkPermission("create"))
			return this.isvcStore.put(poGroup).pipe(mergeMap(_ => this.saveLinks(poGroup)));
		else {
			return throwError(new PermissionMissingError(ContactsService.C_NO_CREATE_PERMISSION_MESSAGE))
				.pipe(
					tap(
						_ => { },
						poError => console.error(`GRP.S::Erreur put nouveau groupe "${poGroup._id}" : `, poError))
				);
		}
	}

	/** Supprime un groupe en base de données ainsi que les documents de liens correspondant pour joindre les groupes et les contacts participants.
	 * @param poGroup Groupe qu'il faut supprimer en base pour finaliser sa suppression.
	 */
	public deleteGroup(poGroup: IGroup): Observable<boolean> {
		return this.isvcEntityLink.ensureIsDeletableEntity(poGroup)
			.pipe(
				filter((pbResult: boolean) => pbResult),
				mergeMap(_ => {
					if (this.checkPermission("delete"))
						return this.isvcEntityLink.deleteEntityLinksById(poGroup._id);
					else
						return throwError(new PermissionMissingError(ContactsService.C_NO_DELETE_PERMISSION_MESSAGE));
				}),
				mergeMap((pbResult: boolean) => {
					if (pbResult) {
						return this.isvcStore.delete(poGroup)
							.pipe(
								catchError(poError => { console.error(`GRP.S::Erreur suppression groupe "${poGroup._id}" : `, poError); return throwError(poError); }),
								map((poResult: IStoreDataResponse) => poResult.ok)
							);
					}
					else
						return of(pbResult);
				})
			);
	}

	/** Modifie un groupe en base de données ainsi que les documents de liens correspondant (ajout ou suppression)
	 * pour joindre les groupes et les contacts participants.
	 * @param poGroup Groupe qu'il faut modifier en base.
	 * @param paOldContacts Liste des contacts préalables.
	 * @param paNewContacts Liste des contacts sélectionnés.
	 */
	public updateGroup(poGroup: IGroup, paOldContacts?: Array<IContact>, paNewContacts?: Array<IContact>): Observable<boolean> {
		const laContacts: IContact[] = [];

		if (ArrayHelper.hasElements(paOldContacts))
			laContacts.push(...ArrayHelper.getDifferences(paOldContacts, paNewContacts));

		if (ArrayHelper.hasElements(paNewContacts))
			laContacts.push(...ArrayHelper.getDifferences(paNewContacts, paOldContacts));

		return defer(() => {
				this.isvcContacts.updateContactsLinks(poGroup, paOldContacts, ArrayHelper.unique(paNewContacts));

				return this.isvcStore.put(poGroup)
					.pipe(
						tap(
							_ => { },
							poError => console.error(`GRP.S::Erreur update groupe "${poGroup._id}" : `, poError)
						),
						mergeMap(_ => this.saveLinks(poGroup))
					);
		});
	}

	/** Récupère un groupe grâce à son id.
	 * @param psGroupId Id du groupe.
	 */
	public getGroup(psGroupId: string): Observable<IGroup> {
		if (!StringHelper.isBlank(psGroupId)) {
			return this.checkReadPermission()
				.pipe(
					mergeMap(_ => {
						const loDataSource: IDataSource = {
							databasesIds: this.isvcContacts.getContactsDatabaseIds(),
							viewParams: {
								key: psGroupId,
								include_docs: true
							}
						};

						return this.isvcStore.getOne<IGroup>(loDataSource);
					}),
					tap(
						_ => { },
						poError => console.error(`GRP.S::Erreur de récupération du groupe '${psGroupId}' : `, poError)
					)
				);
		}
		else {
			const lsMessage = `'${psGroupId}' n'est pas un identifiant de groupe valide !`;
			console.error(`GRP.S:: ${lsMessage}`);
			return throwError(lsMessage);
		}
	}

	/** Récupère les identifiants de groupes auxquels l'utilisateur appartient. */
	public getUserGroupsIds(): Observable<string[]> {
		return this.checkReadPermission()
			.pipe(
				mergeMap(_ => this.isvcEntityLink.getEntityLinks(ContactsService.getUserContactId(), [EPrefix.group])),
				map((paEntityLinks: IEntityLink[]) =>
					paEntityLinks.map((poEntityLink: IEntityLink) => EntityHelper.getEntityLinkPartFromPrefix(poEntityLink, EPrefix.group).entityId))
			);
	}

	/** Récupère les groupes auxquels l'utilisateur appartient.
	 * @throws PermissionMissingErrors
	*/
	public getUserGroups(psUserContactId: string = ContactsService.getUserContactId(), pbLive?: boolean): Observable<IGroup[]> {
		return this.checkReadPermission()
			.pipe(mergeMap(_ => this.isvcEntityLink.getLinkedEntities<IGroup>(psUserContactId, EPrefix.group, pbLive)));
	}

	/** Récupère les chemins (database/id) des groupes auxquels l'utilisateur appartient. */
	public getUserGroupsPaths(): Observable<string[]> {
		return this.checkReadPermission()
			.pipe(
				mergeMap(_ => this.isvcEntityLink.getEntityLinks(ContactsService.getUserContactId(), [EPrefix.group])),
				map((paEntityLinks: IEntityLink[]) => {
					return paEntityLinks.map((poEntityLink: IEntityLink) => {
						const loPart: IEntityLinkPart = EntityHelper.getEntityLinkPartFromPrefix(poEntityLink, EPrefix.group);
						return StoreHelper.getDocumentPathFromIdDatabaseId(loPart.entityId, loPart.databaseId);
					});
				})
			);
	}

	/** Récupère les identifiants de groupes auxquels un contact appartient. */
	public getContactGroupsIds(psContactId: string): Observable<string[]>;
	public getContactGroupsIds(poContact: IContact): Observable<string[]>;
	public getContactGroupsIds(poData: IContact | string): Observable<string[]> {
		return this.checkReadPermission()
			.pipe(
				mergeMap(_ => this.isvcEntityLink.getEntityLinks(typeof poData === "string" ? poData : poData._id, [EPrefix.group])),
				map((paEntityLinks: IEntityLink[]) =>
					paEntityLinks.map((poEntityLink: IEntityLink) => EntityHelper.getEntityLinkPartFromPrefix(poEntityLink, EPrefix.group).entityId))
			);
	}

	/** Récupère les groupes auxquels un contact appartient.
	 * @param poContact
	 * @param pbLive
	 */
	public getContactGroups(poContact: IContact, pbLive?: boolean): Observable<IGroup[]> {
		return this.checkReadPermission().pipe(mergeMap(_ => this.isvcEntityLink.getLinkedEntities<IGroup>(poContact, [EPrefix.group], pbLive)));
	}

	/** Récupère les groupes auxquels un contact appartient.
	 * @param poContact
	 */
	public getContactsGroups(paContactsIds: string[], pbLive?: boolean): Observable<Map<string, IGroup[]>>;
	public getContactsGroups(paContacts: IContact[], pbLive?: boolean): Observable<Map<string, IGroup[]>>;
	public getContactsGroups(paContacts: IContact[] | string[], pbLive?: boolean): Observable<Map<string, IGroup[]>> {
		return this.checkReadPermission()
			.pipe(
				mergeMap(_ => {
					return this.isvcEntityLink.getLinkedEntities<IGroup>(
						(paContacts as any as string[]).map((poContact: IContact | string) => typeof poContact === "string" ? poContact : poContact._id),
						[EPrefix.group],
						pbLive
					);
				})
			);
	}

	/** Récupère les identifiants des groupes auxquels un contact appartient.
	 * @param poContact
	 */
	public getContactsGroupIds(paContactsIds: string[], pbLive?: boolean): Observable<Map<string, string[]>>;
	public getContactsGroupIds(paContacts: IContact[], pbLive?: boolean): Observable<Map<string, string[]>>;
	public getContactsGroupIds(paContacts: IContact[] | string[], pbLive?: boolean): Observable<Map<string, string[]>> {
		return this.checkReadPermission()
			.pipe(
				mergeMap(_ => {
					const laIds: string[] = [];
					(paContacts as any as string[]).forEach((poContact: IContact | string) => {
						if (poContact) {
							if (typeof poContact === "string")
								laIds.push(poContact);
							else
								laIds.push(poContact._id);
						}
					});

					return this.isvcEntityLink.getLinkedEntityIds(
						laIds,
						[EPrefix.group],
						pbLive
					);
				})
			);
	}

	/** Récupère tous les groupes. */
	public getGroups(pbLive?: boolean, pbConflicts?: boolean): Observable<IGroup[]>;
	/** Récupère tous les groupes dont les identifiants sont passés en paramètres.
	 * @param paGroupIds Tableau des identifiants de groupes à récupérer.
	 */
	public getGroups(paGroupIds: string[], pbLive?: boolean, pbConflicts?: boolean): Observable<IGroup[]>;
	public getGroups(poData?: string[] | boolean, pbLiveOrConflicts?: boolean, pbConflicts?: boolean): Observable<IGroup[]> {
		return this.checkReadPermission()
			.pipe(
				mergeMap(_ => {
					const loDataSource: IDataSource = {
						databasesIds: this.isvcContacts.getContactsDatabaseIds(),
						viewParams: { include_docs: true, conflicts: pbConflicts },
						live: pbLiveOrConflicts
					};

					if (poData instanceof Array) { // Si on a renseigné un tableau même vide, on se fie à ce tableau pour récupérer les groupes.
						if (!ArrayHelper.hasElements(poData)) // Si il n'y a aucune clé, on retourne un tableau vide.
							return of([]);
						loDataSource.viewParams.keys = poData;
					}
					else {
						loDataSource.viewParams.startkey = EPrefix.group;
						loDataSource.viewParams.endkey = EPrefix.group + Store.C_ANYTHING_CODE_ASCII;
						loDataSource.live = !!poData;
						loDataSource.viewParams.conflicts = pbLiveOrConflicts;
					}

					return this.isvcStore.get<IGroup>(loDataSource);
				}),
				tap(
					(paResults: IGroup[]) => this.isvcContacts.sortMembers(paResults, GroupsService.C_GROUP_PROPERTY_SORT),
					poError => console.error(`GRP.S::Erreur récupération des groupes : `, poError)
				)
			);
	}

	/** Permet de récupérer un groupe mis à jour. */
	public getUpdatedGroup(): Observable<IGroup> {
		return this.moGroupSubject.asObservable();
	}

	/** Lève l'événement de mise à jour d'un groupe.
	 * @param poGroup Groupe mis à jour.
	 */
	public raiseUpdatedGroup(poGroup: IGroup): void {
		this.moGroupSubject.next(poGroup);
	}

	/** Récupère tous les contacts d'un groupe.
	 * @param psGroupId Identifiant du groupe dont on veut retrouver les contacts.
	 * @param paContactPrefixes Tableau des préfixes des contacts à récupérer, seulement `cont_` par défaut.
	 * @param pbLive Indique si la récupération est continue ou non, `false` par défaut.
	 */
	public getGroupContacts(psGroupId: string, paContactPrefixes?: EPrefix[], pbLive?: boolean): Observable<IContact[]>;
	/** Récupère tous les contacts d'un groupe.
	 * @param poGroup Groupe dont on veut retrouver les contacts.
	 * @param paContactPrefixes Tableau des préfixes des contacts à récupérer, seulement `cont_` par défaut.
	 * @param pbLive Indique si la récupération est continue ou non, `false` par défaut.
	 */
	public getGroupContacts(poGroup: IGroup, paContactPrefixes?: EPrefix[], pbLive?: boolean): Observable<IContact[]>;
	/** Récupère une map de tous les contacts d'un groupe en fonction de l'identifiant du groupe.
	 * @param paGroups Tableau des groupes dont on veut récupérer les contacts associés.
	 * @param paContactPrefixes Tableau des préfixes des contacts à récupérer, seulement `cont_` par défaut.
	 * @param pbLive Indique si la récupération est continue ou non, `false` par défaut.
	 */
	public getGroupContacts(paGroups: IGroup[], paContactPrefixes?: EPrefix[], pbLive?: boolean): Observable<Map<string, IContact[]>>;
	/** Récupère une map de tous les contacts d'un groupe en fonction de l'identifiant du groupe.
	 * @param paGroupsIds Tableau des identifiants de groupes dont on veut récupérer les contacts associés.
	 * @param paContactPrefixes Tableau des préfixes des contacts à récupérer, seulement `cont_` par défaut.
	 * @param pbLive Indique si la récupération est continue ou non, `false` par défaut.
	 */
	public getGroupContacts(paGroupsIds: string[], paContactPrefixes?: EPrefix[], pbLive?: boolean): Observable<Map<string, IContact[]>>;
	public getGroupContacts(poData: string | string[] | IGroup | IGroup[], paContactPrefixes = [EPrefix.contact], pbLive?: boolean): Observable<IContact[] | Map<string, IContact[]>> {
		return this.isvcContacts.checkReadPermission()
			.pipe(
				mergeMap(_ => {
					if (!(poData instanceof Array)) {
						const lsGroupId: string = typeof poData === "string" ? poData : poData._id;
						return this.innerGetLinkedContactsByGroupId(lsGroupId, paContactPrefixes, pbLive);
					}
					else
						return this.innerGetLinkedContactsByGroups(poData, paContactPrefixes, pbLive);
				}),
				tap(
					_ => { },
					poError => console.error("GRP.S::Récupération des contacts liés échouée : ", poError)
				)
			);
	}

	/** Récupère un tableau contenant tous les membres des groupes donnés en paramètre.
	 * @param paGroupsIds Tableau des identifiants de groupes dont on veut récupérer les contacts associés.
	 */
	public getGroupContactsArray(paGroupsIds: string[]): Observable<IContact[]> {
		return this.getGroupContacts(paGroupsIds).pipe(map((poContacts: Map<string, IContact[]>) => ArrayHelper.unique(ArrayHelper.flat(MapHelper.valuesToArray(poContacts)))));
	}

	/** Récupère les groupes filtrés par rôles.
	 * @param paRoles Liste des rôles que doivent contenir les groupes.
	 * @param pbLive
	 */
	public getGroupsByRoles(paRoles: string[], pbLive?: boolean): Observable<IGroup[]> {
		return this.getGroups(pbLive).pipe(
			map((paGroups: IGroup[]) => paGroups.filter((poGroup: IGroup) => paRoles.every((psRole: string) => poGroup.roles?.includes(psRole))))
		);
	}

	/** Récupère tous les contacts liés à un groupe.
	 * @param psGroupId Identifiant du groupe dont on veut retrouver les documents de liens.
	 */
	public getGroupContactsIds(psGroupId: string, paPrefixes?: EPrefix[], pbLive?: boolean): Observable<string[]>;
	/** Récupère tous les contacts liés à un groupe.
	 * @param poGroup Groupe dont on veut retrouver les documents de liens.
	 */
	public getGroupContactsIds(poGroup: IGroup, paPrefixes?: EPrefix[], pbLive?: boolean): Observable<string[]>;
	/** Récupère tous les contacts liés à des groupes.
	 * @param paGroupsIds Identifiants de groupes dont on veut retrouver les documents de liens.
	 * @returns Objet indexé par identifiant de groupe contenant les contacts pour chaque groupe.
	 */
	public getGroupContactsIds(paGroupsIds: string[], paPrefixes?: EPrefix[], pbLive?: boolean): Observable<IIndexedArray<string[]>>;
	/** Récupère tous les contacts liés à des groupes.
	 * @param paGroups Groupes dont on veut retrouver les documents de liens.
	 * @returns Objet indexé par identifiant de groupe contenant les contacts pour chaque groupe.
	 */
	public getGroupContactsIds(paGroups: IGroup[], paPrefixes?: EPrefix[], pbLive?: boolean): Observable<IIndexedArray<string[]>>;
	public getGroupContactsIds(poData: string | IGroup | IGroup[] | string[], paPrefixes: EPrefix[] = [EPrefix.contact], pbLive?: boolean): Observable<string[] | IIndexedArray<string[]>> {
		return this.isvcContacts.checkReadPermission()
			.pipe(
				mergeMap(_ => {
					if (!(poData instanceof Array))
						return this.innerGetLinkedContactsIdsByGroupId(typeof poData === "string" ? poData : poData._id, paPrefixes, pbLive);
					else
						return this.innerGetLinkedContactsIdsByGroups(poData, paPrefixes, pbLive);
				}),
				tap(
					_ => { },
					poError => console.error("GRP.S::Récupération des identifiants des contacts liés échouée : ", poError)
				)
			);
	}

	private innerGetLinkedContactsByGroups(paGroupsOrIds: IGroup[] | string[], paContactPrefixes?: EPrefix[], pbLive?: boolean): Observable<Map<string, IContact[]>> {
		return this.isvcEntityLink.getLinkedEntities<IContact>(paGroupsOrIds, paContactPrefixes, pbLive)
			.pipe(
				tap(
					_ => { },
					poError => console.error(`GRP.S::Erreur récupération des liens des groupes : `, poError)
				)
			);
	}

	private innerGetLinkedContactsByGroupId(psGroupId: string, paContactPrefixes?: EPrefix[], pbLive?: boolean): Observable<IContact[]> {
		return this.isvcEntityLink.getLinkedEntities<IContact>(psGroupId, paContactPrefixes, pbLive);
	}

	private innerGetLinkedContactsIdsByGroups(paGroupsOrIds: IGroup[] | string[], paPrefixes: EPrefix[], pbLive?: boolean): Observable<IIndexedArray<string[]>> {
		const laGroupsIds: string[] = (paGroupsOrIds as any[]).map((poGroupOrId: IGroup | string) => typeof poGroupOrId === "string" ? poGroupOrId : poGroupOrId._id);

		return this.isvcEntityLink.getLinkedEntityIds(laGroupsIds, paPrefixes, pbLive)
			.pipe(
				tap(
					_ => { },
					poError => console.error(`GRP.S::Erreur récupération identifiants des liens des groupes : `, poError)
				),
				map((poContactsIdsByGroupIds: Map<string, string[]>) => MapHelper.mapToObject(poContactsIdsByGroupIds))
			);
	}

	private innerGetLinkedContactsIdsByGroupId(psGroupId: string, paPrefixes: EPrefix[], pbLive?: boolean): Observable<string[]> {
		return this.isvcEntityLink.getLinkedEntityIds(psGroupId, paPrefixes, pbLive)
			.pipe(
				tap(
					_ => { },
					poError => console.error(`GRP.S::Erreur récupération des liens du groupe ${psGroupId} : `, poError)
				)
			);
	}

	/** Ajoute les documents-liens des groupes et membres de ces groupes en base de données.
	 * @param poGroup Groupe créé / modifié.
	 */
	private saveLinks(poGroup: IGroup): Observable<boolean> {
		return this.isvcEntityLink.saveEntityLinks(poGroup)
			.pipe(
				tap(
					_ => { },
					poError => console.error(`GRP.S::Erreur put de membre(s) dans le groupe "${poGroup.name}" : `, poGroup, poError)
				)
			);
	}

	/** Retourne `true` si l'identifiant est celui d'un groupe, `false` sinon.
	 * @param psId Identifiant à analyser.
	 */
	public static isGroup(psId: string): boolean;
	/** Retourne `true` si le modèle est celui d'un groupe, `false` sinon.
	 * @param poModel Modèle à analyser.
	 */
	public static isGroup(poModel: IGroupMember): boolean;
	public static isGroup(poIdOrModel: string | IGroupMember): boolean {
		return poIdOrModel ? this.isGroupMember(poIdOrModel, EPrefix.group) : false;
	}

	/** Ouvre la modale de création d'un groupe */
	public openCreateGroupeModal(poGroup?: IGroup): Observable<IGroup> {
		const pbIsSector: boolean = poGroup?.roles?.includes(C_SECTORS_ROLE_ID);

		if (pbIsSector)
			(poGroup as ISector).siteId = UserData.currentSite._id;

		if (this.checkPermission("create")) {
			const loPageInfo = new PageInfo({
				componentName: "form",
				params: {
					formDescriptorId: pbIsSector ? GroupsService.C_DEFAULT_SECTORS_FORMDESC_ID : GroupsService.C_DEFAULT_GROUPES_FORMDESC_ID,
					formDefinitionId: GroupsService.C_GROUPES_EDIT_FORMDEF_ID,
					model: poGroup,
					visuAfterCreate: false
				} as IFormParams,
				isModal: true,
				isClosable: true
			});

			return from(this.ioModalCtrl.create({ component: DynamicPageComponent, componentProps: { pageInfo: loPageInfo } }))
				.pipe(
					tap((poModal: HTMLIonModalElement) => poModal.present()),
					mergeMap((poModal: HTMLIonModalElement) => poModal.onDidDismiss()),
					filter((poResult: OverlayEventDetail<IGroup>) => !!poResult.data),
					map((poResult: OverlayEventDetail<IGroup>) => poResult.data)
				);
		}
		else
			return throwError(new PermissionMissingError(ContactsService.C_NO_CREATE_PERMISSION_MESSAGE));
	}

	public hasRole(poGroup: IGroup, psRole: string): boolean {
		return poGroup?.roles?.some((psGroupRole: string) => psGroupRole === psRole) ?? false;
	}

	//#endregion
}
