import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { DynHostModule } from '../dynHost/dynHost.module';
import { ApplicationSettingsComponent } from './applicationSettings.component';
import { SecuritySettingsComponent } from "./sections/securitySettings.component";

/**
 * Module de paramètre de l'application
 */
@NgModule({
	imports: [IonicModule, CommonModule, DynHostModule, FormsModule],
	exports: [ApplicationSettingsComponent],
	declarations: [ApplicationSettingsComponent, SecuritySettingsComponent],
	providers: []
})
export class ApplicationSettingsModule {

}
