import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { map, mergeMap } from 'rxjs/operators';
import { IContact } from '../../model/contacts/IContact';
import { GroupsService } from '../../services/groups.service';
import { ISector } from '../sectors/models/isector';
import { ISite } from './models/isite';
import { ISiteDetailsModel } from './models/isite-details-model';
import { SitesService } from './services/sites.service';

@Injectable({
	providedIn: 'root'
})
export class SiteResolver implements Resolve<ISiteDetailsModel> {

	//#region METHODS

	constructor(
		private isvcSites: SitesService,
		private isvcGroups: GroupsService
	) { }

	public resolve(poRoute: ActivatedRouteSnapshot) {
		const lsSiteId: string = poRoute.params.siteId;

		return this.isvcSites.getSite(lsSiteId)
			.pipe(
				mergeMap((poSite: ISite) => this.isvcGroups.getGroupContacts(poSite)
					.pipe(map((paContacts: IContact[]) => ({ ...poSite, contacts: paContacts } as ISiteDetailsModel)))),
				mergeMap((poSite: ISite) => this.isvcSites.getSiteSectors(poSite._id)
					.pipe(map((paSectors: ISector[]) => ({ ...poSite, sectors: paSectors?.map((poSector: ISector) => poSector._id) } as ISiteDetailsModel)))),
			);
	}

	//#endregion

}