import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IAffectation } from 'apps/idl/src/modules/planning-rh/model/IAffectation';

@Component({
  selector: 'di-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, OnChanges {

  @Input() month: number;
  @Input() year: number;
  @Input() user: string;
  @Input() planning: IAffectation[] = [];
  @Input() groupId: string;
  @Input() onSelectDayItem: (date: Date, isWorked: boolean, groupId: string) => void;
  @Input() onSelectDayWeekItem: (dates: Date[], isWorked: boolean, groupId: string) => void;

  daysOfWeek = [
    { label: 'L', number: 1 },
    { label: 'M', number: 2 },
    { label: 'M', number: 3 },
    { label: 'J', number: 4 },
    { label: 'V', number: 5 },
    { label: 'S', number: 6 },
    { label: 'D', number: 0 }
  ];
  monthDays: Array<Array<number | null>> = [];

  constructor() { }

  ngOnInit(): void {
    this.generateMonthDays();
  }

  ngOnChanges(changes: SimpleChanges): void {
      this.generateMonthDays();
  }

  dayItemClick = (date: Date, isWorked: boolean): void => {
    if (this.onSelectDayItem) {
      this.onSelectDayItem(date, isWorked, this.groupId);
    }
  }

  generateMonthDays() {
    const monthDays: (number | null)[] = [];
    const firstDayOfMonth = new Date(this.year, this.month, 1).getDay();
    const daysInMonth = new Date(this.year, this.month + 1, 0).getDate();

    // Create empty days
    const emptyDays = (firstDayOfMonth + 6) % 7;
    for (let i = 0; i < emptyDays; i++) {
      monthDays.push(null);
    }
    // Create days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      monthDays.push(day);
    }
    // Create empty days to finish the week
    while (monthDays.length % 7 !== 0) {
      monthDays.push(null);
    }
    const rows: Array<Array<number | null>> = [];
    // Divide into weeks
    for (let i = 0; i < monthDays.length; i += 7) {
      rows.push(monthDays.slice(i, i + 7));
    }

    this.monthDays = rows;
  }

  getDayClassesWorkedByMe(day: number): boolean {
    if (!day) return false;

    const date: string = new Date(this.year, this.month, day).toLocaleDateString();
    return this.planning && this.planning.some(affectation => {
      const affectationDate: string = new Date(affectation.date).toLocaleDateString();
      return affectation.contactIds && affectation.contactIds.length > 0 && affectationDate === date && affectation.contactIds.includes(this.user);
    });
  }

  getDayClassesWorkedNotByMe(day: number): boolean {
    if (!day) return false;

    const date: string = new Date(this.year, this.month, day).toLocaleDateString();
    return this.planning && this.planning.some(affectation => {
      const affectationDate: string = new Date(affectation.date).toLocaleDateString();
      return (affectation.contactIds && affectation.contactIds.length == 1 && affectationDate === date
        && !affectation.contactIds.includes(this.user) || affectation.contactIds && affectation.contactIds.length > 1 && affectationDate === date)
    });
  }

  getAllOccurrencesOfDayInMonth(dayOfWeek: number): void {
		const tabDayOfWeek: Date[] = [];
		const currentDate = new Date(this.year,this.month,1);
		if(currentDate.getDay() != dayOfWeek){
			while (currentDate.getDay() !== dayOfWeek) {
				currentDate.setDate(currentDate.getDate() + 1);
			}
		}
		while (currentDate.getMonth() === this.month) {
			tabDayOfWeek.push(new Date(currentDate)); 
			currentDate.setDate(currentDate.getDate() + 7);
		}

		let allDaysPresent = tabDayOfWeek.every((date) => {
			return this.getDayClassesWorkedByMe(date.getDate());
		});

		tabDayOfWeek.map((date) => {
			if(!this.planning)
			{
				this.planning = [];
			}
			const indexOfMatchingDate = this.planning?.findIndex((affectation) => {
				const dateAffectation = new Date(affectation.date).toLocaleDateString();
				return dateAffectation === date.toLocaleDateString() && affectation.contactIds.includes(this.user);
			});
			if(allDaysPresent)
			{
				const index = this.planning[indexOfMatchingDate].contactIds.indexOf(this.user);
				if (index !== -1) {
					this.planning[indexOfMatchingDate].contactIds.splice(index, 1);
				}
			}
			else if(indexOfMatchingDate == -1 ){
					this.planning.push({
						slotId:"jour" ,
						groupId:this.groupId ,
						date:date,
						contactIds:[this.user]
					})
				}
		});
		this.generateMonthDays();


    if (this.onSelectDayWeekItem) {
      this.onSelectDayWeekItem(tabDayOfWeek, !allDaysPresent, this.groupId);
    }
  }
}
