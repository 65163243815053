import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ArrayHelper, MapHelper } from '@osapp/helpers';
import { GuidHelper } from '@osapp/helpers/guidHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { IStoreDataResponse } from '@osapp/model/store/IStoreDataResponse';
import { ModalComponentBase } from '@osapp/modules/modal';
import { ShowMessageParamsToast } from '@osapp/services/interfaces/ShowMessageParamsToast';
import { PlatformService } from '@osapp/services/platform.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { takeUntil, tap } from 'rxjs/operators';
import { Acte } from '../../../model/Acte';
import { IChapter } from '../../../model/IChapter';
import { IChapterContent } from '../../../model/IChapterContent';
import { IPersonalizedActe } from '../../../model/IPersonalizedActe';
import { ActesService } from '../actes.service';

@Component({
	selector: 'idl-acte-personalization-modal',
	templateUrl: './acte-personalization-modal.component.html',
	styleUrls: ['./acte-personalization-modal.component.scss'],
})
export class ActePersonalizationModalComponent extends ModalComponentBase<void> implements OnInit, OnDestroy {

	//#region FIELDS

	private readonly C_PERSONALIZED_ACT_PREFIX = "perso_";

	//#endregion

	//#region PROPERTIES

	@Input() public acte: Acte;
	@Input() public mode: "personalize" | "duplicate";
	@Input() public actes: Acte[];
	@Input() public chapters: IChapter[];

	public personalizedActe: IPersonalizedActe;
	public isPersonalizedActeFromDatabase: boolean;

	public chaptersOptions = new Map<IChapter, string[]>();
	public selectedChapterIds: string[];
	public isActeHN: boolean;

	//#endregion

	//#region METHODS

	constructor(
		private isvcActes: ActesService,
		private isvcUiMessage: UiMessageService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService
	) {
		super(poModalCtrl, psvcPlatform);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		if (this.mode === "personalize" && this.acte?.personalizedActeId) {
			this.isvcActes.getPersonalizedActe(this.acte.personalizedActeId).pipe(
				tap((poPersonalizedActe: IPersonalizedActe) => {
					if (!ObjectHelper.isNullOrEmpty(poPersonalizedActe)) {
						this.personalizedActe = poPersonalizedActe;
						this.isPersonalizedActeFromDatabase = true;
						this.initActe();
					}
				}),
				takeUntil(this.destroyed$)
			)
				.subscribe();
		}
		else {
			this.personalizedActe = { _id: this.C_PERSONALIZED_ACT_PREFIX + this.acte?._id + (this.mode === "personalize" ? "" : "-" + GuidHelper.newGuid()) };
			this.personalizedActe.tags = this.acte?.tags
			this.personalizedActe.duration = this.acte?.duration;
			this.personalizedActe.extraCharge = this.acte?.extraCharge;
			this.initActe();
		};
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	private initActe(): void {
		this.isActeHN = this.personalizedActe._id.includes(ActesService.C_HORS_NOMENCLATURE_ACT_ID);
		this.initChaptersOptions();
		this.personalizedActe.chapters = this.acte?.chapters ?? [];
		this.selectedChapterIds = MapHelper.valuesToArray(this.chaptersOptions).find((paChaptersIds: string[]) => ArrayHelper.areArraysEqual(this.personalizedActe.chapters, paChaptersIds)) ?? [];
	}

	public async validateForm(): Promise<void> {
		this.personalizedActe.chapters = this.selectedChapterIds ?? [];

		if (this.isPersonalizedActeEdited(this.personalizedActe)) {
			const loResponse: IStoreDataResponse = await this.isvcActes.savePersonalizedActe(this.personalizedActe).toPromise();

			if (loResponse.ok)
				this.close();
			else
				this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ header: "Une erreur s'est produite", message: loResponse.message, color: "dark", duration: 5000 }));
		}
		else {
			this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: "Aucune valeur n'a été saisie", color: "dark", duration: 5000 }));
		};
	}

	private isPersonalizedActeEdited(poPersonalizedActe: IPersonalizedActe): boolean {
		return poPersonalizedActe.description !== undefined ||
			poPersonalizedActe.tags !== undefined ||
			poPersonalizedActe.duration !== undefined;
	}

	public async resetPersonalizedActe(): Promise<void> {
		const loResponse: IStoreDataResponse = await this.isvcActes.deletePersonalizedActe(this.personalizedActe).toPromise();
		if (loResponse.ok)
			this.close();
		else
			this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ header: "Une erreur s'est produite", message: loResponse.message, color: "dark", duration: 5000 }));
	}

	private initChaptersOptions(): void {
		if (this.isActeHN) {
			const laChapterIds: string[] = [ActesService.C_HAD_SSIAD_CHAPTER_ID];
			this.chaptersOptions.set(this.chapters.find((poChapter: IChapter) => poChapter._id === ActesService.C_HAD_SSIAD_CHAPTER_ID), laChapterIds)
			this.personalizedActe.chapters = laChapterIds;
		}
		else {
			const loActesByChapter: Map<IChapter, IChapterContent> = this.isvcActes.groupActsByChapter(this.chapters, this.actes);

			loActesByChapter.forEach((poChapterContent: IChapterContent, poChapter: IChapter) => {

				if (!!poChapterContent.subChapters)
					poChapterContent.subChapters.forEach((_, poSubChapter: IChapter) => this.chaptersOptions.set(poSubChapter, [poChapter._id, poSubChapter._id]));
				else
					this.chaptersOptions.set(poChapter, [poChapter._id]);
			});
		}
	}

	//#endregion

}
