export interface IWorkspaceInfo {
	/** Identifiant de l'espace de travail. */
	id: string;
	/** Nom de l'espace de travail. */
	name: string;
	/** Indique si l'espace de travail est partagé. */
	shared: boolean;
	/** Identifiant de l'application à laquelle de l'espace de travail. */
	appId: string;
	/** Version minimum pour pouvoir accéder au workspace. */
	minVersion?: string;
	/** Version maximale pour pouvoir accéder au workspace. */
	maxVersion?: string;
}