import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { UserData } from '@osapp/model/application/UserData';
import { IContact } from '@osapp/model/contacts/IContact';
import { ELogActionId } from '@osapp/modules/logger/models/ELogActionId';
import { LoggerService } from '@osapp/modules/logger/services/logger.service';
import { ModalComponentBase } from '@osapp/modules/modal';
import { EPermission } from '@osapp/modules/permissions/models/EPermission';
import { IHasPermission, Permissions, PermissionsService } from '@osapp/modules/permissions/services/permissions.service';
import { ContactsService } from '@osapp/services/contacts.service';
import { PlatformService } from '@osapp/services/platform.service';
import { EMPTY, from } from 'rxjs';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { EIdlLogActionId } from '../../../logger/models/EIdlLogActionId';
import { IPatient } from '../../model/IPatient';
import { IPatientSelectorParams } from '../../model/IPatientSelectorParams';

@Component({
	selector: 'idl-patient-selector-modal',
	templateUrl: './patient-selector-modal.component.html',
	styleUrls: ['./patient-selector-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientSelectorModalComponent extends ModalComponentBase<IPatient[]> implements OnInit, IHasPermission {

	//#region FIELDS

	private static readonly C_LOG_ID = "PAT-SELECT-MOD.C";

	//#endregion

	//#region PROPERTIES

	public permissionScope = EPermission.contacts;
	@Input() public params: IPatientSelectorParams;
	@Input() public title: string;

	@Permissions("create")
	public get canCreatePatient(): boolean {
		return true;
	}

	public customClose: () => Promise<boolean>;
	public patients: IPatient[] = [];

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcContacts: ContactsService,
		private readonly isvcLogger: LoggerService,
		private readonly ioRouter: Router,
		private readonly ioRoute: ActivatedRoute,
		public readonly isvcPermissions: PermissionsService,
		poModalCtrl: ModalController,
		psvcPlatformService: PlatformService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatformService, poChangeDetector);
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this.customClose = () => this.close();
	}

	/** @override */
	public async close(paPatients?: IPatient[]): Promise<boolean> {
		await this.ioRouter.navigate([], { relativeTo: this.ioRoute });
		return super.close(paPatients);
	}

	public onPatientSelectionChanged(paPatients: IPatient[]): void {
		this.patients = paPatients;
	}

	/** Crée un nouveau patient. */
	public createNewPatient(): void {
		from(this.ioRouter.navigate([], { relativeTo: this.ioRoute, queryParams: { newPatient: true } }))
			.pipe(
				mergeMap(() => this.isvcContacts.openCreateContactModal(this.params.formParams)),
				tap((poContact: IContact) => {
					this.isvcLogger.action(PatientSelectorModalComponent.C_LOG_ID, "Création du patient.", EIdlLogActionId.patientCreate as unknown as ELogActionId, { userId: UserData.current?._id, patientId: poContact._id });
					this.patients = !ObjectHelper.isNullOrEmpty(poContact) ? [poContact] : [];
				}),
				mergeMap(() => {
					if (ArrayHelper.hasElements(this.patients) && this.params.selectAfterCreate)
						return this.close(this.patients);
					return EMPTY;
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	//#endregion

}
