import { Component, Input, OnInit } from '@angular/core';
import { EPrefix, IContact } from '@osapp/model';
import { ContactsService } from '@osapp/services';
import { map } from 'rxjs/operators';

@Component({
  selector: 'di-choix-user',
  templateUrl: './choix-user.component.html',
  styleUrls: ['./choix-user.component.scss'],
})
export class ChoixUserComponent implements OnInit {

  @Input() setContactSelectedList: (user: IContact) => void;

  public users: IContact[];
  @Input() selectedUser?: IContact;
  private userClasses: Map<string, { [key: string]: boolean }> = new Map();

  constructor(private svcContacts: ContactsService) {
    this.getContactForList();
  }

  ngOnInit(): void {}

  private getContactForList(): void {
    this.svcContacts.getSiteContactsAnakin([], EPrefix.contact, true, true).pipe(
      map((contacts: IContact[]) => {
        return contacts.map((contact: IContact) => {
          if(!contact.isDoctor && contact.finess)
         		contact.isDoctor = true;
          return contact;
        });
      }),
    ).subscribe((contacts: IContact[]) => {
      this.users = contacts.filter(contact => contact.userId);
      this.calculateUserClasses();
    });
  }

  private calculateUserClasses(): void {
    this.users.forEach(user => {
      this.userClasses.set(user._id, this.createClasses(user._id));
    });
  }

  public setContactSelectedItem(user: IContact): void {
    this.selectedUser = user;
    if (this.setContactSelectedList) {
      this.setContactSelectedList(user);
    }
    this.calculateUserClasses();
  }

  private createClasses(id: string): { [key: string]: boolean } {
    return {
      "actifUser": this.selectedUser && this.selectedUser._id === id
    };
  }

  public getUserClasses(id: string): { [key: string]: boolean } {
    return this.userClasses.get(id) || {};
  }

	getAvatarColor(user : IContact) {
		return user.avatarCouleur ?? 'CouleurPrimaire';
  }
}
