<div class="di-utilisateurs">
	<div *ngIf="showListUtilisateur">
		<div class="header">
			<h1>Utilisateurs</h1>
			<small>{{ nbUtilisateurs$ | async }} Utilisateurs ont accès à DESMOS infirmiers, vous pouvez inviter un
				utilisateur
				ayant un compte DESMOS.</small>
		</div>
		<div class="utilisateurs">
			<ng-container *ngFor="let utilisateur of utilisateurs$ | async">
				<lua-divider-horizontal size="small"></lua-divider-horizontal>
				<lua-objet-utilisateur [firstName]="utilisateur.firstName" [lastName]="utilisateur.lastName"
					[info]="utilisateur.email" [fullWidth]="true" [tags]="utilisateur.tags"
					[avatarColor]="getAvatarColor(utilisateur)">
				</lua-objet-utilisateur>
			</ng-container>
			<lua-divider-horizontal size="small"></lua-divider-horizontal>
			<lua-action-secondaire libelle="Inviter un utilisateur" size="large" [fullWidth]="isMobileView"
				(click)="handelInvitation()"></lua-action-secondaire>
		</div>
	</div>
	<ng-container *ngIf="showInvitation">
		<di-inviter-utilisateur [onCancel]="onCancel" [onShowForm]="onShowForm"></di-inviter-utilisateur>
	</ng-container>
	<ng-container *ngIf="showFormInvitation && userFind">
		<di-form-inviter-utilisateur [userFind]="userFind" [onCancelForm]="onCancelForm"></di-form-inviter-utilisateur>
	</ng-container>
</div>