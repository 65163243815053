<div class="planning-page__container">
	<div class="planning-page__content">
		<div class="container">
			<ng-container *ngIf="!isMobileView">
				<div class="leftSide">
					<div class="content__entete">
						<div class="entete__title">
							<h1 class="entete__title-text">Planning</h1>
							<lua-divider-horizontal size="small"></lua-divider-horizontal>
						</div>
					</div>
					<di-choix-user [selectedUser]="userSelected" [setContactSelectedList]="setContactSelectedList">
					</di-choix-user>
				</div>
			</ng-container>


			<div class="rightSide">
				<ng-container *ngIf="showUserName">
					<div class="entete-planning">
						<h1 class="name-user">{{userSelected.lastName}} {{userSelected.firstName}}</h1>
						<ng-container *ngIf="isMobileView">
							<lua-bouton-icone (onClick)="openpanneauUserSelect()" iconName="menu" mode="outlined"></lua-bouton-icone>
						</ng-container>
					</div>
				</ng-container>
				<div class="monthSelector">
					<div class="monthSelectorPrevious">
						<lua-bouton-icone (onClick)="previousMonth()" iconName="chevron_left" mode="outlined"></lua-bouton-icone>
						<h3>{{months[month1]}} {{ year1 }}</h3>
						<lua-bouton-icone *ngIf="isMobileView" (onClick)="nextMonth()" iconName="chevron_right"
							mode="outlined"></lua-bouton-icone>
					</div>
					<div class="monthSelectorNext" *ngIf="!isMobileView">
						<h3>{{months[month2]}} {{ year2 }}</h3>
						<lua-bouton-icone (onClick)="nextMonth()" iconName="chevron_right" mode="outlined"></lua-bouton-icone>
					</div>
				</div>
				<div class="calendarContainer">
					<ng-container *ngIf="userSelected">
						<di-calendar [month]="month1" [year]="year1" [planning]="planningCalendar?.get(secteur)"
							[user]="userSelected._id" [onSelectDayItem]="calendarItemClick"
							[onSelectDayWeekItem]="calendarItemWeekClick" [groupId]="secteur"></di-calendar>
						<di-calendar *ngIf="!isMobileView" [month]="month2" [year]="year2"
							[planning]="planningCalendar?.get(secteur)" [user]="userSelected._id"
							[onSelectDayItem]="calendarItemClick" [onSelectDayWeekItem]="calendarItemWeekClick"
							[groupId]="secteur"></di-calendar>
					</ng-container>
				</div>
				<hr />
				<footer>
					<div class="legendContainer">
						<div class="item-legend">
							<di-day [isWorked]="false" [isWorkedNotByMe]="false" [number]=1 [disabled]="true"></di-day>
							<p>Jour non
								affecté</p>
						</div>
						<div class="item-legend">
							<di-day [isWorked]="false" [isWorkedNotByMe]="true" [number]=1 [disabled]="true"></di-day>
							<p>Jour
								affecté à quelqu'un d'autre</p>
						</div>
						<div class="item-legend">
							<di-day [isWorked]="true" [isWorkedNotByMe]="false" [number]=1 [disabled]="true"></di-day>
							<p>Jour
								travaillé</p>
						</div>
					</div>
				</footer>
			</div>
		</div>
	</div>
</div>