export { AdaptiveDatePipe } from './adaptiveDate.pipe';
export { ContactNamePipe } from './contactName.pipe';
export { DateWithLocalePipe } from './dateWithLocale.pipe';
export { FieldsBindingPipe } from './fieldsBinding.pipe';
export { IContactPipe } from './icontact.pipe';
export { IsDatePipe } from './isDate.pipe';
export { PipeModule } from './pipes.module';
export { PriceFormatPipe } from './priceFormat.pipe';
export { TimetablePipe } from './timetable.pipe';

