<div class="container">
	<di-side-page-desmos></di-side-page-desmos>
	<div class="contenu">
		<div class="text">
			<div class="entete">
				<lua-icone mode="outlined" iconName="key" color="Blanc" fontSize="60px"></lua-icone>
				<h2>Le lien a expiré</h2>
			</div>
			<div class="corps">
				<p>Votre lien n’est plus valide ! <br>Renseignez votre adresse email pour recevoir un nouveau lien de
					modification de votre mot de passe </p>
				<lua-input-filled [value]="email" (valueChange)="handleChangeValue($event)" [isAutoFocus]=true type="email"
					libelle=" Quel est votre email de connexion ?"></lua-input-filled>
				<lua-alerte *ngIf="requestError" [filled]="true" type="error" message="{{ requestError.message }}"></lua-alerte>
			</div>

			<div class="action">
				<lua-action-secondaire color="Blanc" size="large" (click)="sendEmailLien()"
					libelle="Envoyer le lien pour réinitialiser"></lua-action-secondaire><br>
				<lua-bouton-texte (click)="navigateToLogin()" color="Blanc" libelle="Annuler"></lua-bouton-texte>
			</div>
		</div>
	</div>
</div>