import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { IDateRange } from '@osapp/components/date/date-range-picker/model/IDateRange';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { EnumHelper } from '@osapp/helpers/enumHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { IPopoverItemParams } from '@osapp/model/popover/IPopoverItemParams';
import { ISearchOptions } from '@osapp/model/search/ISearchOptions';
import { EUTCAccuracy } from '@osapp/modules/date/model/eutcaccuracy.enum';
import { IFilterbarParams } from '@osapp/modules/filter/model/IFilterbarParams';
import { IFilterValuesChangedEvent } from '@osapp/modules/filter/model/IFilterValuesChangedEvent';
import { Loader } from '@osapp/modules/loading/Loader';
import { Observation } from '@osapp/modules/observations/models/observation';
import { ObservationsService } from '@osapp/modules/observations/observations.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '@osapp/services/loading.service';
import { PopoverService } from '@osapp/services/popover.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { defer, Observable, of } from 'rxjs';
import { finalize, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { C_PREFIX_PATIENT, C_PREFIX_TRAITEMENT } from '../../../../app/app.constants';
import { Acte } from '../../../../model/Acte';
import { IIdelizyContact } from '../../../../model/IIdelizyContact';
import { TraitementService } from '../../../../services/traitement.service';
import { ActesService } from '../../../actes/actes.service';
import { InterventionStatementService } from '../../../intervention-statement/intervention-statement.service';
import { IInterventionStatementActe } from '../../../intervention-statement/models/iintervention-statement-acte';
import { InterventionStatement } from '../../../intervention-statement/models/intervention-statement';
import { IPatient } from '../../../patients/model/IPatient';
import { PatientsService } from '../../../patients/services/patients.service';
import { ETraitementTags } from '../../../traitement/model/ETraitementTags';
import { FacturationService } from '../../facturation.service';
import { InterSateWithPatient } from '../../models/intervention-statement-with-patient';
import { IPaginatorInfos } from '../../models/ipaginator-infos';

class InterStateWithObservation extends InterventionStatement {
	public observation?: string;
}
@Component({
	selector: 'idl-intervention-statements-list',
	templateUrl: './intervention-statements-list.component.html',
	styleUrls: ['./intervention-statements-list.component.scss'],
})
export class InterventionStatementsListComponent extends ComponentBase implements OnInit {

	//#region FIELDS

	/** Tableau des séances filtrées. */
	private maFilteredInterStates: InterventionStatement[];
	/** Tableau de tous les patients. */
	private maPatients: IPatient[];
	/** Map qui associe un patient avec son id. */
	private moPatientById = new Map<string, IPatient>();
	/** Map qui associe un acte avec son id. */
	private moActesById = new Map<string, Acte>();

	/** Tableau des patients sélectionnés pour le filtrage. */
	private maFilterSelectedPatients: IPatient[] = [];
	/** Intervalle de dates sélectionnée pour le filtrage. */
	private moFilterRangeDates?: IDateRange;
	/** Tableau des contacts intervenants sélectionnés pour le filtrage. */
	private maFilterSelectedIntervenants: IIdelizyContact[] = [];
	/** Tableau des autres filtres possibles, ici 'Acte non défini' ou 'Observations'. */
	private maFilterSelectedOthers: string[] = [];

	//#endregion

	//#region PROPERTIES

	/** Tableau de toutes les séances avec patient. */
	public interStatesWithPatient: InterSateWithPatient[];
	/** Map qui associe un id de patient à ses séances. */
	public filteredInterStatesByPatient: Map<IPatient, InterventionStatement[]>;

	/** Valeur recherchée dans l'input de recherche. */
	public searchValue: string;
	/** Options de recherche. */
	public searchOptions: ISearchOptions<IPatient> = {
		hasPreFillData: true,
		searchboxPlaceholder: "Rechercher une séance",
		searchFunction: (poInterSateWithPatient: InterSateWithPatient, psSearchValue: string) => this.isvcFacturation.interStateAndPatientMatchSearchValue(psSearchValue, poInterSateWithPatient)
	};
	/** Paramètres pour la barre de filtrage. */
	public filterbarParams: IFilterbarParams;
	/** Nombre de filtres en cours. */
	public filtersCount: number;

	/** Composants de pagination. */
	@ViewChild("paginator") public paginator: MatPaginator;
	public nbItemsByPage = 10;
	public startIndex = 0;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcInterState: InterventionStatementService,
		private readonly isvcPatients: PatientsService,
		private readonly isvcTraitement: TraitementService,
		private readonly isvcActes: ActesService,
		private readonly isvcFacturation: FacturationService,
		private readonly isvcLoading: LoadingService,
		private readonly isvcPopover: PopoverService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcObservations: ObservationsService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);
	}

	public ngOnInit(): void {
		let loLoader: Loader;
		this.initFilterbarParams();
		defer(() => this.isvcLoading.create("Chargement en cours..."))
			.pipe(
				mergeMap((poLoader: Loader) => poLoader.present()),
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap(() => this.init()),
				mergeMap(() => loLoader.dismiss()),
				finalize(() => loLoader?.dismiss()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private init(): Observable<IPatient[]> {
		const laActeIds: string[] = [];
		const laPatientIds: string[] = [];
		const loInterStateByUTCDate = new Map();

		return this.isvcInterState.getInterventionStatements()
			.pipe(
				tap((paInterStates: InterventionStatement[]) => {
					this.maFilteredInterStates = paInterStates.filter((poInterState: InterventionStatement) => {
						poInterState.actes = poInterState.actes.filter((poActe: IInterventionStatementActe) => StringHelper.isBlank(poActe.skipReason));
						if (poInterState.actes.length > 0) {
							laActeIds.push(...poInterState.actes.map((poActe: IInterventionStatementActe) => poActe.acteId));
							laPatientIds.push(poInterState.patientId);

							const lsInterventionUTCDate: string = DateHelper.toUTCString(poInterState.interventionDate, EUTCAccuracy.minutes);
							if (loInterStateByUTCDate.has(lsInterventionUTCDate))
								loInterStateByUTCDate.set(lsInterventionUTCDate, [...loInterStateByUTCDate.get(lsInterventionUTCDate), poInterState]);
							else
								loInterStateByUTCDate.set(lsInterventionUTCDate, [poInterState]);

							return true;
						}
						return false;
					});
				}),
				mergeMap(() => this.isvcObservations.getSiteObservations(C_PREFIX_PATIENT, C_PREFIX_TRAITEMENT)),
				tap((paObservations: Observation[]) => {
					paObservations.forEach((poObservation: Observation) => {
						const lsObservationUTCDate: string = DateHelper.toUTCString(ArrayHelper.getFirstElement(IdHelper.extractDatesFromId(poObservation._id)), EUTCAccuracy.minutes);
						const loInterState: InterStateWithObservation = loInterStateByUTCDate.get(lsObservationUTCDate)?.find((poInterState: InterventionStatement) =>
							poObservation._id.includes(IdHelper.extractIdWithoutPrefix(poInterState.traitementId, C_PREFIX_TRAITEMENT))
						);
						if (!ObjectHelper.isNullOrEmpty(loInterState))
							loInterState.observation = poObservation.description;
					});
				}),
				mergeMap(() => {
					return this.isvcActes.getActes(ArrayHelper.unique(laActeIds))
						.pipe(
							tap((paActes: Acte[]) => paActes.forEach((poActe: Acte) => this.moActesById.set(poActe._id, poActe))),
							mergeMap(() => this.isvcPatients.getPatientsByIds(ArrayHelper.unique(laPatientIds)))
						);
				}),
				tap((paPatients: IPatient[]) => {
					const laInterStatesWithPatient: InterSateWithPatient[] = [];
					this.maPatients = paPatients;
					this.maPatients.forEach((poPatient: IPatient) => this.moPatientById.set(poPatient._id, poPatient));
					this.maFilteredInterStates.forEach((poInterState: InterSateWithPatient) => {
						poInterState.patient = this.moPatientById.get(poInterState.patientId);
						poInterState.actesData = poInterState.actes.map((poInterStateActe: IInterventionStatementActe) => this.moActesById.get(poInterStateActe.acteId));
						laInterStatesWithPatient.push(poInterState);
					});
					this.interStatesWithPatient = laInterStatesWithPatient;
					this.onFilteredInterStatesChanged(this.maFilteredInterStates);
				})
			);
	}

	private fillFilteredInterStatesByPatient(paInterStates: InterventionStatement[]): void {
		const loFilteredInterStatesByPatient = new Map<IPatient, InterventionStatement[]>();

		ArrayHelper.groupBy(paInterStates, (poInterState: InterventionStatement) => poInterState.patientId).forEach((paInterStatesList: InterventionStatement[], psPatientId) => {
			if (this.moPatientById.has(psPatientId))
				loFilteredInterStatesByPatient.set(this.moPatientById.get(psPatientId), InterventionStatementService.sortInterStateByDate(paInterStatesList));
		});

		this.filteredInterStatesByPatient = new Map([...Array.from(loFilteredInterStatesByPatient.entries()).sort((poEntryA: [IPatient, InterventionStatement[]], poEntryB: [IPatient, InterventionStatement[]]) => {
			return poEntryA[0].lastName.toLowerCase().localeCompare(poEntryB[0].lastName.toLowerCase());
		})]);
		this.detectChanges();
	}

	/** Ouvre un menu contextuel d'un relevé d'intervention.
	 * @param poEvent Événement du clic.
	 * @param poSelectedInterState Relevé d'intervention sélectionnée sur laquelle effectuer une action.
	 */
	public openInterStatePopover(poEvent: MouseEvent, poSelectedInterState: InterventionStatement): void {
		const laItems: IPopoverItemParams[] = [
			{
				action: () => of(this.isvcTraitement.openTraitement(poSelectedInterState.traitementId)),
				icon: "cure",
				title: "Afficher le traitement"
			}
		];

		this.isvcPopover.showPopover(laItems, poEvent)
			.pipe(takeUntil(this.destroyed$))
			.subscribe();
	}

	public getInterStatesHeightInPx(paInterStates: InterSateWithPatient[]): string {
		let lnNbPx = 0;

		paInterStates.forEach((poInterState: InterSateWithPatient) => {
			lnNbPx += 45;
			lnNbPx += poInterState.actes.length * 25;
		});

		return `${lnNbPx}px`;
	}

	public openObservation(poInterState: InterStateWithObservation): void {
		this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: poInterState.observation, header: "Observation", backdropDismiss: true }));
	}

	//#region Filters

	public onFilteredInterStatesChanged(paFilteredInterStates: InterventionStatement[]): void {
		this.maFilteredInterStates = Array.from(paFilteredInterStates);
		this.fillFilteredInterStatesByPatient(this.applyFilterValues(this.maFilteredInterStates));
		this.resetPaginator();
		this.detectChanges();
	}

	/** Initialise les paramètres de filtrage. */
	private initFilterbarParams(): void {
		this.resetCurrentFilters();

		this.filterbarParams = {
			filters: this.isvcFacturation.createFilterbarOptions(this.maFilterSelectedPatients, this.maPatients, this.maFilterSelectedIntervenants),
			hasResetButton: true,
			hidden: true
		};

		this.detectChanges();
	}

	private resetCurrentFilters(): void {
		this.maFilterSelectedPatients = [];
		this.maFilterSelectedIntervenants = [];
		this.moFilterRangeDates = undefined;
		this.maFilterSelectedOthers = [];
	}

	public toggleFilterbarVisibility(): void {
		this.filterbarParams.hidden = !this.filterbarParams.hidden;
		this.detectChanges();
	}

	/** Événement de réinitialisation du filtrage. */
	public onFilterbarResetEvent(pbIsReset: boolean): void {
		this.filterbarParams.hidden = pbIsReset;
		this.resetCurrentFilters();
		this.onFilteredInterStatesChanged(this.applyFilterValues(this.maFilteredInterStates));
	}

	public onFilterCountChanged(pnFilterCount: number): void {
		this.filtersCount = pnFilterCount;
		this.detectChanges();
	}

	/** Événement de mise à jour des valeurs pour un filtre. */
	public onFilterValuesChanged(poEvent: IFilterValuesChangedEvent<IIdelizyContact | Date | IPatient>): void {
		this.setFilterValuesChanged(poEvent);
		this.fillFilteredInterStatesByPatient(this.applyFilterValues(this.maFilteredInterStates));
		this.resetPaginator();
	}

	/** Modifie un des champs du comopsant pour garder en mémoire les différents filtres en cours. */
	private setFilterValuesChanged(poEvent: IFilterValuesChangedEvent<IIdelizyContact | Date | IPatient | string | IDateRange>): void {
		if (poEvent.id === FacturationService.C_INTERVENANT_FILTER_ID)
			this.maFilterSelectedIntervenants = poEvent.changes as IIdelizyContact[];

		else if (poEvent.id === FacturationService.C_DATE_RANGE_FILTER_ID)
			this.moFilterRangeDates = ArrayHelper.getFirstElement(poEvent.changes) as IDateRange;

		else if (poEvent.id === PatientsService.C_FILTER_PATIENTS_ID) {
			this.maFilterSelectedPatients = poEvent.changes as IPatient[];
		}
		else if (poEvent.id === FacturationService.C_OTHER_FILTER_ID) {
			const lsSelectedValue: string = ArrayHelper.getFirstElement(poEvent.changes as string[]);
			if (EnumHelper.getKeys(ETraitementTags).includes(lsSelectedValue)) {
				this.maFilterSelectedOthers = [];
			}
			else {
				this.maFilterSelectedOthers = [lsSelectedValue];
			}
		}
		else
			console.warn(`REAL-S-LIST.C:: L'identifiant de changements de valeur de filtres '${poEvent.id}' est inconnu.`);
	}

	/** Applique les filtres de la barre de filtrage sur les documents à afficher et retourne le nouveau tableau obtenu. */
	private applyFilterValues(paInterStates: InterventionStatement[]): InterventionStatement[] {
		let laResults: InterventionStatement[] = paInterStates;

		if (!ArrayHelper.hasElements(laResults)) // Si il n'y a pas de transmissions, on n'applique pas les filtres.
			return [];

		// On filtre les intervenants s'il y en a.
		if (ArrayHelper.hasElements(this.maFilterSelectedIntervenants)) {
			laResults = laResults.filter((poInterState: InterventionStatement) =>
				this.maFilterSelectedIntervenants.some((poPrescripteur: IIdelizyContact) => poInterState.intervenantId === poPrescripteur._id)
			);
		}

		// On filtre les patients s'il y en a.
		if (ArrayHelper.hasElements(this.maFilterSelectedPatients)) {
			laResults = laResults.filter((poInterState: InterventionStatement) =>
				this.maFilterSelectedPatients.some((poPatient: IPatient) => poInterState.patientId === poPatient._id)
			);
		}

		if (!ObjectHelper.isNullOrEmpty(this.moFilterRangeDates)) {
			laResults = laResults.filter((poInterState: InterventionStatement) =>
				DateHelper.compareTwoDates(poInterState.interventionDate, this.moFilterRangeDates.begin) >= 0 && DateHelper.compareTwoDates(this.moFilterRangeDates.end, poInterState.interventionDate) >= 0
			);
		}

		// On filtre par les filtres restants s'il y en a.
		if (ArrayHelper.hasElements(this.maFilterSelectedOthers)) {
			this.maFilterSelectedOthers.forEach((psFilter: string) => {
				if (psFilter === FacturationService.C_UNDEFINED_ACT) {
					laResults = laResults.filter((poInterState: InterventionStatement) =>
						poInterState.actes.some((poActe: IInterventionStatementActe) => poActe.acteId === ActesService.C_UNDEFINED_ACT_ID)
					);
				}
				else if (psFilter === FacturationService.C_HORS_NOMENCLATURE) {
					laResults = laResults.filter((poInterState: InterventionStatement) =>
						poInterState.actes.some((poActe: IInterventionStatementActe) => poActe.acteId.includes(ActesService.C_HORS_NOMENCLATURE_ACT_ID))
					);
				}
			});
		}

		return laResults;
	}

	public onPageChange(poPaginatorInfos: IPaginatorInfos): void {
		if (poPaginatorInfos.pageSize !== this.nbItemsByPage)
			this.nbItemsByPage = poPaginatorInfos.pageSize;

		this.startIndex = this.nbItemsByPage * poPaginatorInfos.pageIndex;
	}

	private resetPaginator(): void {
		this.startIndex = 0;
		if (this.paginator)
			this.paginator.pageIndex = 0;
	}

	//#endregion

	//#endregion

}
