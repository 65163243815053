<osapp-header-modal [title]="title"></osapp-header-modal>

<transmission-second-header [transmission]="transmission" [isCreation]="isCreation" [patient]="patientModel">
</transmission-second-header>

<ion-content>
	<transmission [model]="transmission" [readOnly]="false"></transmission>
</ion-content>

<ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
	<ion-fab-button color="success" (click)="saveTransmission()" title="Sauvegarder">
		<ion-icon name="checkmark"></ion-icon>
	</ion-fab-button>
</ion-fab>