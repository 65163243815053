import { Component, OnInit } from '@angular/core';
import { VersionsService } from '@osapp/modules/versions/services/versions.service';

@Component({
  selector: 'di-app-info',
  templateUrl: './app-info.page.html',
  styleUrls: ['./app-info.page.scss'],
})
export class AppInfoPage implements OnInit {

  public appVersion: string;

  constructor(
    private svcVersion: VersionsService
  ) { }

  ngOnInit() {
    this.appVersion = this.svcVersion.version;
  }

}
