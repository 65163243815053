
import { ContactHelper } from '@osapp/helpers/contactHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { Entity } from '@osapp/model/entities/Entity';
import { EAvatarSize } from '@osapp/model/picture/EAvatarSize';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { EntityBuilder } from '@osapp/services/EntityBuilder';
import { C_PREFIX_PATIENT } from 'apps/idl/src/app/app.constants';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { PatientEntityGuard } from './PatientEntityGuard';

export class PatientEntityBuilder extends EntityBuilder<IPatient>{
	constructor() {
		super(
			(psModelId: string) => RegExp(C_PREFIX_PATIENT).test(psModelId),
			(poPatient: IPatient) => new Entity(
				poPatient,
				`patients/${poPatient._id}`,
				() => {
					const lsName: string = ContactHelper.getCompleteFormattedName(poPatient.lastName, poPatient.firstName);
					return StringHelper.isBlank(lsName) ? "Nouveau patient" : lsName;
				}
			),
			(psModelId: string) => "Patients",
			EDatabaseRole.contacts,
			C_PREFIX_PATIENT,
			() => ({
				size: EAvatarSize.medium,
				icon: "person"
			}),
			(poPatient: IPatient) => ({
				size: EAvatarSize.medium,
				guid: poPatient.picture && poPatient.picture.guid ? poPatient.picture.guid : null,
				data: poPatient.picture && poPatient.picture.base64 ? poPatient.picture.base64 : null,
				icon: "person"
			}),
			undefined,
			undefined,
			new PatientEntityGuard()
		);
	}
}
