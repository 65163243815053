import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ArrayHelper } from '@osapp/helpers';
import { ModalComponentBase } from '@osapp/modules/modal';
import { PlatformService } from '@osapp/services/platform.service';
import { ISeanceStatusTournee } from '../../model/ISeanceStatusTournee';
import { ISelectSeanceStatusModalParams } from '../../model/ISelectSeanceStatusModalParams';
import { ISelectSeanceStatusModalResponse } from '../../model/ISelectSeanceStatusModalResponse';

type SeanceStatusSelection = {
	data: ISeanceStatusTournee;
	selected: boolean;
};

@Component({
	templateUrl: './select-seance-status-modal.component.html',
	styleUrls: ['./select-seance-status-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectSeanceStatusModalComponent extends ModalComponentBase<ISelectSeanceStatusModalResponse> implements OnInit, ISelectSeanceStatusModalParams {

	//#region PROPERTIES

	/** @implements */
	public seanceStatuses: ISeanceStatusTournee[];
	/** @implements */
	public preSeanceStatuses: ISeanceStatusTournee[];

	public dataSelections: SeanceStatusSelection[];

	//#endregion

	//#region METHODS

	constructor(poModalCtrl: ModalController, psvcPlatform: PlatformService) {
		super(poModalCtrl, psvcPlatform);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		if (!ArrayHelper.hasElements(this.seanceStatuses))
			this.seanceStatuses = [];

		this.dataSelections =
			this.seanceStatuses.map((poItem: ISeanceStatusTournee) => ({ data: poItem, selected: false } as SeanceStatusSelection));

		if (!ArrayHelper.hasElements(this.preSeanceStatuses))
			this.preSeanceStatuses = [];
		else {
			this.dataSelections.forEach((poItem: SeanceStatusSelection) => {
				if (this.preSeanceStatuses.some((poPreSelectedItem: ISeanceStatusTournee) => poPreSelectedItem.label === poItem.data.label))
					poItem.selected = true;
			});
		}
	}

	public onSelectionChanged(poItem: SeanceStatusSelection): void {
		this.dataSelections.forEach((poOtherItem: SeanceStatusSelection) => poOtherItem.selected = false);
		poItem.selected = true;
	}

	public validate(): void {
		const loResponse: ISelectSeanceStatusModalResponse = {
			selectedStatuses: this.dataSelections
				.filter((poItem: SeanceStatusSelection) => poItem.selected)
				.map((poItem: SeanceStatusSelection) => poItem.data)
		};

		this.close(loResponse);
	}

	public hasSelectedValues(): boolean {
		return this.dataSelections.some((poItem: SeanceStatusSelection) => poItem.selected);
	}

	//#endregion
}