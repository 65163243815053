import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
	selector: "di-compte-bloque",
	templateUrl: "./compte-bloque.page.html",
	styleUrls: ["./compte-bloque.page.scss"],
})
export class CompteBloquePage implements OnInit {
	constructor(private router: Router) {}

	ngOnInit(): void {}

	public navigateToPwdChange() {
		this.router.navigate(['/passwordDemande'], { queryParams: { type: 'block' } });
	}
}
