import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[diMaxTwoDecimalPrice]'
})
export class MaxTwoDecimalPriceDirective {

  constructor(private el: ElementRef, private control: NgControl) { }

  @HostListener('input', ['$event']) onInput(event: Event) {
    const inputValue: string = this.el.nativeElement.value;

    // Extraire la partie entière et la partie décimale
    const parts: string[] = inputValue.split('.');
    const integerPart: string = parts[0];
    let decimalPart: string = parts[1] || '';

    // Limiter la partie décimale à deux chiffres
    decimalPart = decimalPart.slice(0, 2);

    // Reconstruire la valeur avec au plus deux chiffres après la virgule
    const sanitizedValue: string = decimalPart.length > 0 ? integerPart + '.' + decimalPart : integerPart;

    // Mettre à jour la valeur du contrôle
    this.control.control.setValue(sanitizedValue, { emitEvent: false });
  }
}
