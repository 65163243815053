import { Injectable, Type } from '@angular/core';
import { MatBottomSheet, MatBottomSheetConfig, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Observable } from 'rxjs';

@Injectable()
export class BottomSheetService {

	//#region METHODS

	constructor(private ioMatBottomSheet: MatBottomSheet) { }

	/** Ouvre un composant `BottomSheet` et retourne le résultat après sa fermeture.
	 * @param poComponent Composant `BottomSheet` qu'il faut ouvrir.
	 * @param poOptions Options pour le composant.
	 */
	public open<Result, Options = any, Component = any>(poComponent: Type<Component>, poOptions: MatBottomSheetConfig<Options>): Observable<Result> {
		return this.ioMatBottomSheet.open<Component, Options, Result>(poComponent, poOptions).afterDismissed();
	}

	/** Créé un composant `BottomSheet` et retourne son instance.
	 * @param poComponent Composant `BottomSheet` qu'il faut ouvrir.
	 * @param poOptions Options pour le composant.
	 */
	public create<Result, Options = any, Component = any>(poComponent: Type<Component>, poOptions: MatBottomSheetConfig<Options>): MatBottomSheetRef<Component, Result> {
		return this.ioMatBottomSheet.open<Component, Options, Result>(poComponent, poOptions);
	}


	//#endregion

}