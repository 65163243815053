import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { EPermission } from '../models/EPermission';
import { IPermission } from '../models/ipermission';
import { PermissionsService } from '../services/permissions.service';

/** Utiliser ces champs dans la route pour la rendre accessible uniquement en fonction de permissions. 
 * Toutes les permissions doivent être acquise pour pouvoir passer. 
 * ```ts
 * { 
 *	data: { 
 *		permissions: [{permission: contact, type: "read"}] as IPermission[]
 *	}, 
 *	canActivate: [HasPermissionGuard] 
 *}
 * ```
*/
@Injectable()
export class HasPermissionGuard implements CanActivate {

	//#region METHODS

	constructor(private readonly isvcPermissions: PermissionsService) { }

	public canActivate(poNext: ActivatedRouteSnapshot): boolean {
		return ArrayHelper.hasElements(poNext.data.permissions) ?
			poNext.data.permissions.every((poPermission: IPermission) =>
				this.isvcPermissions.evaluatePermission(poPermission.permission as EPermission, poPermission.type)
			)
			: true;
	}

	//#endregion


}
