<osapp-header-modal title="Choix de la convention"></osapp-header-modal>

<ion-content>
	<ion-grid fixed>
		<ion-row class="input-row">
			<osapp-selector displayMode="list" [options]="conventionList" [preselectedValues]="conventionSelected"
				(selectionChanged)="onConventionChanged($event)" limit="1">
			</osapp-selector>
		</ion-row>
	</ion-grid>
</ion-content>

<ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
	<ion-fab-button color="success" (click)="close()" title="Valider">
		<ion-icon name="checkmark"></ion-icon>
	</ion-fab-button>
</ion-fab>