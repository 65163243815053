import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { IonicModule } from '@ionic/angular';
import { IntegrityModule } from '@osapp/modules/integrity/integrity.module';
import { ModalModule } from '@osapp/modules/modal/modal.module';
import { ChooseSeancesToModifyModalComponent } from './choose-seances-to-modify-modal/choose-seances-to-modify-modal.component';
import { SeanceConflictsModalComponent } from './components/seance-conflicts-modal/seance-conflicts-modal.component';
import { SeanceConflictsComponent } from './components/seance-conflicts/seance-conflicts.component';

@NgModule({
	declarations: [ChooseSeancesToModifyModalComponent, SeanceConflictsComponent, SeanceConflictsModalComponent],
	exports: [ChooseSeancesToModifyModalComponent, SeanceConflictsComponent, SeanceConflictsModalComponent],
	imports: [
		CommonModule,
		IonicModule,
		ModalModule,
		MatListModule,
		IntegrityModule
	]
})
export class SeancesModule { }