import { Directive, ElementRef, OnInit } from '@angular/core';
import { IonItemSliding } from '@ionic/angular';

@Directive({
	selector: '[calaoSlidingItemOptionsToggle]'
})
export class SlidingItemOptionsToggleDirective implements OnInit {

	constructor(
		private readonly ioItemSliding: IonItemSliding,
		/** Element sur lequel porte la directive. */
		private readonly ioElementRef: ElementRef<HTMLElement>
	) { }

	public ngOnInit(): void {
		if (this.ioElementRef) {
			this.ioElementRef.nativeElement.addEventListener("click", async (poEvent?: MouseEvent) => {
				if (this.ioItemSliding) {
					if (poEvent)
						poEvent.stopPropagation();	// Empêche la possible navigation vers l'item cliqué.

					// Si l'item est ouvert, la valeur est strictement supérieure à 0, sinon c'est que l'item est fermé.
					const lnAmountOpenPixels: number = await this.ioItemSliding.getOpenAmount();

					if (lnAmountOpenPixels > 0) // Item ouvert, on veut le fermer
						this.ioItemSliding.close();
					else // Item fermé, on veut l'ouvrir.
						this.ioItemSliding.open("end");
				}
			});
		}
	}
}
