import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ComponentRef } from '@ionic/core';
import { MapHelper, ObjectHelper, StringHelper } from '@osapp/helpers';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { StoreHelper } from '@osapp/helpers/storeHelper';
import { EPrefix } from '@osapp/model/EPrefix';
import { ERouteUrlPart } from '@osapp/model/route/ERouteUrlPart';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { IDataSource } from '@osapp/model/store/IDataSource';
import { IStoreDataResponse } from '@osapp/model/store/IStoreDataResponse';
import { ContactsService } from '@osapp/services/contacts.service';
import { EntityLinkService } from '@osapp/services/entityLink.service';
import { LoadingService } from '@osapp/services/loading.service';
import { Store } from '@osapp/services/store.service';
import { combineLatest, defer, from, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, finalize, map, mapTo, mergeMap, tap } from 'rxjs/operators';
import { C_CONSTANTES_PREFIX, C_INJECTIONS_PREFIX } from '../../../app/app.constants';
import { ESurveilancesType } from '../model/constantes/ESurveillancesType';
import { IConstantes, IOther } from '../model/constantes/IConstantes';
import { IConstantesModalParams } from '../model/constantes/IConstantesModalParams';
import { IConstantesPageParams } from '../model/constantes/IConstantesPageParams';
import { IInjection, IInjections } from '../model/constantes/IInjections';
import { ISurveillances } from '../model/constantes/ISurveillances';
import { IPatient } from '../model/IPatient';
import { PatientsService } from './patients.service';

@Injectable({ providedIn: "root" })
export class ConstantesService {

	//#region FIELDS

	/** Propriété "createdDate" d'un objet constantes. */
	public static readonly C_CREATED_DATE_PROPERTY = "createdDate";
	public static readonly C_CREATE_CONSTANTES_TITLE = "Création de constantes";
	public static readonly C_EDIT_CONSTANTES_TITLE = "Édition de constantes";
	public static readonly C_CREATE_INJECTIONS_TITLE = "Création de dosages";
	public static readonly C_EDIT_INJECTIONS_TITLE = "Édition des dosages";

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcEntityLink: EntityLinkService,
		private readonly ioRouter: Router,
		private readonly ioModalCtrl: ModalController,
		private readonly isvcLoading: LoadingService,
		private readonly isvcStore: Store,
		private readonly isvcPatient: PatientsService
	) { }

	/** Retourne un objet constantes vierge.
	 * @param psPatientId Identifiant du patient dont est liée la constante.
	 * @param psAuthorDatabaseId Identifiant de la base de données où se trouve l'auteur.
	 */
	private createANewConstantes(psPatientId: string, psAuthorDatabaseId: string): IConstantes {
		return {
			_id: IdHelper.buildChildId(C_CONSTANTES_PREFIX, psPatientId),
			createdDate: new Date(),
			authorPath: psAuthorDatabaseId,
			other: { label: "", value: "" }
		} as IConstantes;
	}

	/** Crée un objet constantes et la retourne.
	 * @param poPatientModel Modèle du patient qu'il faut lier au nouvel objet constantes (obligatoire).
	 */
	public createAConstantes(poPatientModel: IPatient): IConstantes {
		const loNewConstantes: IConstantes = this.createANewConstantes(poPatientModel._id, ContactsService.getCurrentWorkspaceUserPath(poPatientModel));
		this.innerCreateSurveillance(loNewConstantes, poPatientModel);
		return loNewConstantes;
	}

	/** Retourne un objet injections vierge.
	 * @param psPatientId Identifiant du patient dont est liée l'injection.
	 * @param psAuthorDatabaseId Identifiant de la base de données où se trouve l'auteur.
	 */
	private createANewInjections(psPatientId: string, psAuthorDatabaseId: string): IInjections {
		return {
			_id: IdHelper.buildChildId(C_INJECTIONS_PREFIX, psPatientId),
			createdDate: new Date(),
			authorPath: psAuthorDatabaseId
		} as IInjections;
	}

	/** Crée un objet injections et la retourne.
	 * @param poPatientModel Modèle du patient qu'il faut lier au nouvel objet constantes (obligatoire).
	 */
	public createAnInjections(poPatientModel: IPatient): IInjections {
		const loNewInjections: IInjections = this.createANewInjections(poPatientModel._id, ContactsService.getCurrentWorkspaceUserPath(poPatientModel));
		this.innerCreateSurveillance(loNewInjections, poPatientModel);
		return loNewInjections;
	}

	private innerCreateSurveillance(poNewSurveillances: ISurveillances, poPatientModel: IPatient): void {
		// On ajoute la même base de données où enregistrer le nouvel objet surveillances que celle du patient.
		StoreHelper.updateDocumentCacheData(poNewSurveillances, { databaseId: StoreHelper.getDatabaseIdFromCacheData(poPatientModel) });
	}

	/** Ouvre la page de constantes en mode 'visu'.
	 * @param poConstantes Constantes à afficher.
	 */
	public routeToASurveillances(poSurveillances: ISurveillances): Promise<boolean> {
		return this.ioRouter.navigate([IdHelper.getPrefixFromId(poSurveillances._id) === C_CONSTANTES_PREFIX ? "constantes" : "injections", poSurveillances._id, ERouteUrlPart.edit]);
	}

	/** Ouvre la page de création d'un nouvel obket constantes et retourne le résultat de la navigation.
	 * @param poPatientModel Modèle du patient qu'il faut lier à l'objet constantes.
	 * @param peSurveillanceType Type de l'objet surveillances.
	 */
	public routeToANewSurveillances(poPatientModel: IPatient, peSurveillanceType: ESurveilancesType): Promise<boolean> {
		return this.ioRouter.navigate(
			[peSurveillanceType, ERouteUrlPart.new],
			{
				state: {
					patientModel: JSON.stringify(poPatientModel)
				} as IConstantesPageParams
			} as NavigationExtras
		);
	}

	/** Ouvre la page de création d'un nouvel objet constantes et retourne le résultat de l'ouverture.
	 * @param poConstanteModalComponent Composant modale de l'objet constantes à ouvrir.
	 * @param poModalParams Paramètres pour l'ouverture de la modale de l'objet constantes.
	 */
	public openASurveillancesAsModal(poConstanteModalComponent: ComponentRef, poModalParams: IConstantesModalParams): Observable<boolean> {
		return from(this.ioModalCtrl.create({
			component: poConstanteModalComponent,
			componentProps: poModalParams
		}))
			.pipe(
				mergeMap((poModal: HTMLIonModalElement) => poModal.present()),
				mapTo(true),
				catchError(poError => { console.error("IDL.CONST.S:: Erreur ouverture modale constantes :", poError); return of(false); })
			);
	}

	/** Enregistre dans la base de données de l'objet surveillances.
	 * @param poSurveillances Surveillances à enregistrer en base de données.
	 */
	public saveSurveillances(poSurveillances: ISurveillances): Observable<boolean> {
		if (ObjectHelper.isNullOrEmpty(poSurveillances))
			return of(true);
		if (IdHelper.getPrefixFromId(poSurveillances._id) === C_INJECTIONS_PREFIX)
			this.clearInjections(poSurveillances);
		else if (IdHelper.getPrefixFromId(poSurveillances._id) === C_CONSTANTES_PREFIX)
			this.clearConstantes(poSurveillances);

		return defer(() => this.isvcLoading.present("Enregistrement en cours ..."))
			.pipe(
				mergeMap(_ => this.isvcStore.put(poSurveillances)),
				mergeMap(_ => this.isvcEntityLink.saveEntityLinks(poSurveillances)),
				tap(_ => console.debug("IDL.CONST.S:: Surveillances enregistrées.")),
				finalize(() => this.isvcLoading.dismiss())
			);
	}

	/** Supprime un objet surveillances ainsi que les liens qui lui sont associés.
	 * @param poSurveillances Surveillances à supprimer.
	 */
	public deleteASurveillances(poSurveillances: ISurveillances): Observable<boolean> {
		return this.isvcEntityLink.ensureIsDeletableEntity(poSurveillances)
			.pipe(
				filter((pbResult: boolean) => pbResult),
				mergeMap(_ => this.isvcEntityLink.deleteEntityLinksById(poSurveillances._id)),
				mergeMap(_ => this.isvcStore.delete(poSurveillances)),
				map((poResult: IStoreDataResponse) => poResult.ok)
			);
	}

	/** Retourne les mises à jour d'un objet surveillances spécifique
	 * (le premier résultat correspond à la récupération de ce même objet surveillances (`startWith` oblige)).
	 * @param poSurveillances Surveillances dont on veut recevoir les mises à jour.
	 */
	public getASurveillancesUpdates(poSurveillances: ISurveillances): Observable<ISurveillances> {
		const loDataSource: IDataSource = {
			databaseId: StoreHelper.getDatabaseIdFromCacheData(poSurveillances),
			live: true,
			viewParams: {
				include_docs: true,
				key: poSurveillances._id
			}
		};

		return this.isvcStore.get<ISurveillances>(loDataSource)
			.pipe(
				map((paResults: ISurveillances[]) =>
					paResults.find((poSurveillanceResult: ISurveillances) => poSurveillanceResult._id === poSurveillances._id)
				),
				filter((poSurveillanceResult: ISurveillances) => !!poSurveillanceResult)
			);
	}

	/** Retourne un tableau de toutes les surveillances existantes triées par date de création, liées à un patient si un patient a été renseigné.
	 * @param pePrefix Prefix de la surveillance.
	 * @param psPatientId Identifiant du patient dont on veut récupérer les constantes, s'il est renseigné.
	 * @param pbIsLive Indique si la récupération des données est continue ou non, `false` par défaut.
	 */
	private innerGetSurveillances(pePrefix: EPrefix, psPatientId: string = "", pbIsLive?: boolean): Observable<ISurveillances[]> {
		const loDataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				include_docs: true,
				startkey: `${pePrefix}${psPatientId}`,
				endkey: `${pePrefix}${psPatientId}${Store.C_ANYTHING_CODE_ASCII}`
			},
			live: pbIsLive
		};

		return this.isvcStore.get<ISurveillances>(loDataSource)
			.pipe(
				map((paResults: ISurveillances[]) =>
					paResults.sort((poSurveillanceA: ISurveillances, poSurveillanceB: ISurveillances) => DateHelper.compareTwoDates(poSurveillanceA.createdDate, poSurveillanceB.createdDate))
				),
				mergeMap((paResults: ISurveillances[]) => {
					if (pePrefix === C_CONSTANTES_PREFIX) {
						this.fillConstantesForRetroCompat(paResults); // Rétrocompatibilité
						paResults.forEach((poConstantes: IConstantes) => this.clearConstantes(poConstantes));
					}
					return of(paResults);
				})
			);
	}

	/** Récupère une surveillance en fonction de l'id passé en paramètre.
	 * @param psSurveillanceId Id de la surveillance à récupérer.
	 */
	public getSurveillance(psSurveillanceId: string): Observable<ISurveillances> {
		const loDataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				include_docs: true,
				key: psSurveillanceId
			}
		};

		return this.isvcStore.getOne<ISurveillances>(loDataSource, false)
			.pipe(
				tap((poSuveillances: ISurveillances) => {
					if (IdHelper.getPrefixFromId(poSuveillances._id) === C_CONSTANTES_PREFIX) {
						this.fillConstantesForRetroCompat([poSuveillances]); // Retrocompatibilité
						this.clearConstantes(poSuveillances);
					}
				})
			);
	}

	/** Récupère toutes les constantes de façon continue, récupère celles liées à un patient spécifique si renseigné.
	 * @param poPatient Patient dont on veut récupérer les constantes s'il est renseigné.
	 */
	public getConstantes(poPatient?: IPatient): Observable<IConstantes[]> {
		return this.innerGetSurveillances(C_CONSTANTES_PREFIX, poPatient._id, true)
			.pipe(
				distinctUntilChanged((paOldResults: IConstantes[], paNewResults: IConstantes[]) => ArrayHelper.areArraysFromDatabaseEqual(paOldResults, paNewResults))
			);
	}

	private fillConstantesForRetroCompat(paConstantes: IConstantes[]): void {
		paConstantes.forEach((poConstantes: IConstantes) => {
			// Retrocompatibilité pour le champ 'other'.
			if (!ObjectHelper.isNullOrEmpty(poConstantes.other) && !poConstantes.others?.some((poOtherConstante: IOther) => ConstantesService.areOtherConstantesSame(poConstantes.other, poOtherConstante)))
				poConstantes.others = ArrayHelper.hasElements(poConstantes.others) ? [poConstantes.other, ...poConstantes.others] : [poConstantes.other];
		})
	}

	/** Récupère toutes les injections de façon continue, récupère celles liées à un patient spécifique si renseigné.
	 * @param poPatient Patient dont on veut récupérer les constantes s'il est renseigné.
	 */
	public getInjections(poPatient?: IPatient): Observable<IInjections[]> {
		return this.innerGetSurveillances(C_INJECTIONS_PREFIX, poPatient._id, true)
			.pipe(
				distinctUntilChanged((paOldResults: IInjections[], paNewResults: IInjections[]) => ArrayHelper.areArraysFromDatabaseEqual(paOldResults, paNewResults))
			);
	}

	/** Récupère toutes les surveillances de façon continue, récupère celles liées à un patient spécifique si renseigné.
	 * @param poPatient Patient dont on veut récupérer les surveillances s'il est renseigné.
	 */
	public getSurveillances(poPatient?: IPatient) {
		return combineLatest([this.getConstantes(poPatient), this.getInjections(poPatient)])
			.pipe(
				map((paSurveillances: [ISurveillances[], ISurveillances[]]) => ArrayHelper.flat(paSurveillances).sort((poSurveillanceA: ISurveillances, poSurveillanceB: ISurveillances) => DateHelper.compareTwoDates(poSurveillanceA.createdDate, poSurveillanceB.createdDate)))
			);
	}

	/** Récupère le patient lié aux surveillances.
	 * @param poSurveillances Objet surveillances dont il faut récupérer le patient lié.
	 */
	public getPatientBySurveillances(poSurveillances: ISurveillances): Observable<IPatient> {
		return this.getPatientBySurveillanceIds([poSurveillances])
			.pipe(map((poPatientByConstantesId: Map<string, IPatient>) => poPatientByConstantesId.get(poSurveillances._id)));
	}

	/** Récupère les patients liés aux surveillances et les indexent par identifiant d'objet surveillances.
	 * @param paSurveillances Tableau des surveillances.
	 */
	public getPatientBySurveillanceIds(paSurveillances: ISurveillances[]): Observable<Map<string, IPatient>> {
		const loPatientIdBySurveillanceIds = new Map<string, string>();
		const loPatientByIds = new Map<string, IPatient>();

		paSurveillances
			.map((poSurveillances: IConstantes) => poSurveillances._id)
			.forEach((psSurveillancesId: string) => loPatientIdBySurveillanceIds.set(psSurveillancesId, IdHelper.extractParentId(psSurveillancesId)));

		return this.isvcPatient.getPatientsByIds(ArrayHelper.unique(MapHelper.valuesToArray(loPatientIdBySurveillanceIds)))
			.pipe(
				tap((paPatients: IPatient[]) => paPatients.forEach((poPatient: IPatient) => loPatientByIds.set(poPatient._id, poPatient))),
				map(() => {
					const loPatientBySurveillanceIds = new Map<string, IPatient>();
					loPatientIdBySurveillanceIds.forEach((psPatientId: string, psSurveillancesId: string) => loPatientBySurveillanceIds.set(psSurveillancesId, loPatientByIds.get(psPatientId)));
					return loPatientBySurveillanceIds;
				})
			);
	}

	public getPatientIdBySurveillances(poSurveillances: ISurveillances): string {
		return IdHelper.extractParentId(poSurveillances._id);
	}

	/** Retourne un tableau des surveillances qui ont été faites avant celle en paramètre et qui sont liées au même patient s'il a été renseigné.
	 * @param poSurveillances surveillances dont il faut récupérer les précédentes.
	 * @param poPatient Patient dont on veut récupérer les surveillances précédentes, s'il est renseigné.
	 */
	public getPreviousSurveillances(poSurveillances: ISurveillances, poPatient?: IPatient): Observable<ISurveillances[]> {
		return this.innerGetSurveillances(IdHelper.getPrefixFromId(poSurveillances._id), poPatient._id)
			.pipe(
				map((paResults: ISurveillances[]) => paResults.filter((poItem: ISurveillances) => DateHelper.compareTwoDates(poSurveillances.createdDate, poItem.createdDate) > 0))
			);
	}

	private clearInjections(poInjections: IInjections): void {
		let laInjectionsToSave: IInjection[] = [];

		poInjections.injections.forEach((poInjection: IInjection) => {
			if (!StringHelper.isBlank(poInjection.quantity))
				laInjectionsToSave.push(poInjection);
		});

		if (laInjectionsToSave.length > 0)
			poInjections.injections = laInjectionsToSave;
		else
			poInjections = undefined;
	}

	private clearConstantes(poConstantes: IConstantes): void {
		let laOthersConstantesToSave: IOther[] = [];

		poConstantes.others.forEach((poOther: IOther) => {
			if (!StringHelper.isBlank(poOther.value))
				laOthersConstantesToSave.push(poOther);
		});

		poConstantes.others = laOthersConstantesToSave;
	}

	/** Indique si les deux 'autres' constantes sont identiques. */
	public static areOtherConstantesSame(poOtherConstanteA: IOther, poOtherConstanteB: IOther): boolean {
		return poOtherConstanteA.label === poOtherConstanteB.label && poOtherConstanteA.value === poOtherConstanteB.value;
	}

	//#endregion
}