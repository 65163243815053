<div class="container">
	<di-side-page-desmos></di-side-page-desmos>
	<div class="contenu">
		<div class="text">
			<div class="entete">
				<lua-icone mode="outlined" iconName="mail" color="Blanc" fontSize="60px"></lua-icone>
				<h2>Lien envoyé par email</h2>
			</div>
			<div class="corps">
				<p>Un lien pour changer votre mot de passe vient de vous être envoyé à l'adresse :</p>
				<strong>{{ email }}</strong>
			</div>
			<div class="action">
				<lua-action-secondaire size="large" color="Blanc" (click)="resendEmail()"
					libelle="Ré-envoyer le mail"></lua-action-secondaire>
				<lua-bouton-texte (click)="navigateToLogin()" color="Blanc"
					libelle="Retour à la page de connexion"></lua-bouton-texte>
			</div>
		</div>

	</div>
</div>