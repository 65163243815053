import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './settingsPage.component.html'
})
export class SettingsPageComponent implements OnInit {
  //#region FIELDS
  
  //#endregion

  //#region PROPERTIES
  
  //#endregion

  //#region METHODS
   constructor() {
  }

  public ngOnInit(): void {
   }
  //#endregion
}