import { DateHelper } from '@osapp/helpers/dateHelper';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { ChildEntityGuard } from '@osapp/model/entities/ChildEntityGuard';
import { Entity } from '@osapp/model/entities/Entity';
import { EAvatarSize } from '@osapp/model/picture/EAvatarSize';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { EntityBuilder } from '@osapp/services/EntityBuilder';
import { C_PREFIX_PATIENT, C_PREFIX_RAPPORT } from 'apps/idl/src/app/app.constants';
import { ITransmissionRapport } from './model/ITransmissionRapport';

export class TransmissionRapportEntityBuilder extends EntityBuilder<ITransmissionRapport>{
	constructor() {
		super(
			(psModelId: string) => RegExp(C_PREFIX_RAPPORT).test(psModelId),
			(poRapport: ITransmissionRapport) => new Entity(
				poRapport,
				`transmissions/${poRapport._id}`,
				() => `Transmission du ${DateHelper.transform(poRapport.createdDate, ETimetablePattern.EEE_dd_MMMM_yyyy)} à ${DateHelper.transform(poRapport.createdDate, ETimetablePattern.HH_mm)}`
			),
			_ => "Transmissions",
			EDatabaseRole.workspace,
			C_PREFIX_RAPPORT,
			() => ({
				size: EAvatarSize.medium,
				icon: "clipboard"
			}),
			undefined,
			undefined,
			undefined,
			new ChildEntityGuard("Cette transmission est liée", C_PREFIX_PATIENT)
		);
	}
}