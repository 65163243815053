import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { IDynHostComponent } from '@osapp/components/dynHost';
import { DynamicPageComponent } from '@osapp/components/dynamicPage';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { StoreDocumentHelper } from '@osapp/helpers/storeDocumentHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { EBarElementDock } from '@osapp/model/barElement/EBarElementDock';
import { EBarElementPosition } from '@osapp/model/barElement/EBarElementPosition';
import { IBarElement } from '@osapp/model/barElement/IBarElement';
import { IContact } from '@osapp/model/contacts/IContact';
import { EAvatarSize } from '@osapp/model/picture/EAvatarSize';
import { IAvatar } from '@osapp/model/picture/IAvatar';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { IDataSource } from '@osapp/model/store/IDataSource';
import { IStoreDocument } from '@osapp/model/store/IStoreDocument';
import { ApplicationService } from '@osapp/services/application.service';
import { ContactsService } from '@osapp/services/contacts.service';
import { LoadingService } from '@osapp/services/loading.service';
import { SlideboxService } from '@osapp/services/slidebox.service';
import { Store } from '@osapp/services/store.service';
import { Observable, throwError } from 'rxjs';
import { catchError, concatMap, filter, mergeMap, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Acte } from '../../../../model/Acte';
import { EStatusSeance } from '../../../../model/EStatusSeance';
import { ISyntheseSeance } from '../../../../model/ISyntheseSeance';
import { Majoration } from '../../../../model/Majoration';
import { Seance } from '../../../../model/Seance';
import { Traitement } from '../../../../model/Traitement';
import { TraitementSlideParentBase } from '../../../../model/TraitementSlideParentBase';
import { IdlApplicationService } from '../../../../services/idlApplicationService.service';
import { SeanceService } from '../../../../services/seance.service';
import { TraitementService } from '../../../../services/traitement.service';
import { PatientsService } from '../../../patients/services/patients.service';
import { TraitementDataManager } from '../../traitement-data-manager.service';
import { TraitementSlideComponentBase } from '../traitement-slide-component-base.component';

interface IMajorationByProfession extends IStoreDocument {
	id: string;
	description: string;
};
interface ISeancesStats {
	total: number;
	pending: number;
	done: number;
	canceled: number;
	late: number;
}

@Component({
	templateUrl: "./synthese.component.html",
	styleUrls: ["./synthese.component.scss"],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SyntheseComponent extends TraitementSlideComponentBase implements IDynHostComponent, OnInit {

	//#region FIELDS

	/** Valeur max des select de pourcentage. */
	private static readonly C_MAX_SELECT = 101;

	/** Service donnant acces ax informations sur l'application (ex. la profession). */
	private isvcApplication: IdlApplicationService;

	/** Prix à payer pour le patient sans tenir compte du pourentage cpam. */
	private mnPatientTotalPrice = 0;
	private maMajorations: IMajorationByProfession[];

	//#endregion

	//#region PROPERTIES

	/** Input des données venant du compoanbt père qui a injecté ce composant. */
	@Input() public params: any;

	/** Tableau des valeurs des select des %. */
	public percentages: Array<number> = [];
	/** Indemnité kilométrique activé ou non. */
	public isIkEnable: boolean;
	/** Tarif total (cpam + patient). */
	public totalPrice = 0;
	/** Tarif remboursé par la cpam. */
	public cpamRefund = 0;
	/** Itération sur une structure adaptée des séances pour faire un récapitulatif. */
	public syntheseSeances: Array<ISyntheseSeance> = [];
	/** Tarif à payer par le patient. */
	public patientPrice = 0;
	/** Pourcentage de remboursement de la cpam. */
	public cpamPercent = 0;
	/** Pourcentage à payer par le patient. */
	public patientPercent = 0;
	/** Somme des actes pris à 100% par la cpam. */
	public cpam100Sum = 0;
	/** Map associant un id de contact avec un contact. */
	public contactsById = new Map<string, IContact>();
	/** Statistiques des séances du traitement */
	public seancesStats: ISeancesStats;

	//#endregion

	//#region METHODS

	constructor(
		private ioRouter: Router,
		/** Store service acces aux données. */
		private isvcStore: Store,
		private isvcContacts: ContactsService,
		psvcPatients: PatientsService,
		psvcTraitementDataManager: TraitementDataManager,
		psvcSeance: SeanceService,
		poParentComponent: TraitementSlideParentBase,
		psvcTraitement: TraitementService,
		psvcLoading: LoadingService,
		poParentPage: DynamicPageComponent<ComponentBase>,
		poChangeDetectorRef: ChangeDetectorRef,
		psvcSlidebox: SlideboxService,
		psvcApplication: ApplicationService) {

		super(
			psvcTraitementDataManager,
			psvcSeance,
			poParentComponent,
			psvcTraitement,
			psvcLoading,
			psvcPatients,
			poParentPage,
			poChangeDetectorRef,
			psvcSlidebox
		);

		this.isvcApplication = psvcApplication as IdlApplicationService;
	}

	public ngOnInit(): void {
		super.ngOnInit();

		// Init des select de la page 1-100.
		this.percentages = Array.from(new Array(SyntheseComponent.C_MAX_SELECT).keys());

		this.moParentComponent.getTraitementAsObservable()
			.pipe(
				filter((poTraitement: Traitement) => !StoreDocumentHelper.areDocumentRevisionsEqual(poTraitement, this.traitement)),
				startWith(this.traitement),
				tap((poTraitement: Traitement) => {
					this.traitement = poTraitement;
					this.cpamPercent = this.traitement.getCpamPercent();
					this.patientPercent = this.traitement.getPatientPercent();
				}),
				concatMap(_ => this.init()),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.moParentComponent.getTraitementSlideAsObservable()
			.pipe(
				tap((psResult: string) => this.onTraitementSlideEvent(psResult)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Méthode qui calcule et affecte le prix total de la synthèse séance.
	 * @param poSyntheseSeance Synthèse séance (format adapté pour la synthèse d'une séance).
	 */
	private setSyntheseSeanceTotalPrice(poSyntheseSeance: ISyntheseSeance): void {
		poSyntheseSeance.totalPrice = 0;

		// Gestion des ajouts des tarifs d'actes.
		poSyntheseSeance.actes.forEach((poActe: Acte) => {
			if (poActe.isAldExonerante)
				this.cpam100Sum += poActe.price;
			else
				this.mnPatientTotalPrice += poActe.price;

			poSyntheseSeance.totalPrice += poActe.price;
		});

		// Gestion des tarifs des majorations.
		const lnMajorationTotalPrice: number = poSyntheseSeance.majorations.map((poMajoration: Majoration) => poMajoration.price)
			.reduce((pnPreviousPrice: number, pnCurrentPrice: number) => pnPreviousPrice + pnCurrentPrice, 0);
		this.mnPatientTotalPrice += lnMajorationTotalPrice;
		poSyntheseSeance.totalPrice += lnMajorationTotalPrice;

		// Gestion des IK.
		this.mnPatientTotalPrice += poSyntheseSeance.indemnitePrice;
		poSyntheseSeance.totalPrice += poSyntheseSeance.indemnitePrice;
	}

	/** @override */
	protected createToolbarData(): Array<IBarElement> {
		const lsCircle = "circle";
		const lsFabButton = "fabButton";

		return [
			{
				id: lsCircle,
				component: lsFabButton,
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.left,
				icon: "arrow-back",
				onTap: this.mfPreviousSlide,
				name: "Précédent"
			},
			{
				id: lsCircle,
				component: lsFabButton,
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.middle,
				icon: "timetable",
				onTap: () => this.ioRouter.navigate([
					"tournees",
					DateHelper.toDateUrl(this.traitement.beginDate)
				]),
				name: "Tournées"
			},
			{
				id: lsCircle,
				component: lsFabButton,
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.right,
				background: "#1BC14D",
				icon: "checkmark",
				options: {
					isDisable: this.traitement.actes.length === 0
				},
				onTap: () => this.moParentPage.goBack(),
				name: "Valider"
			}
		];
	}

	/** Récupération des libellés de majoration.
	 * @param paMajorations Tableau de majorations.
	 */
	private getMajorations(): Observable<IMajorationByProfession[]> {
		const loParams: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.formsEntries),
			viewName: "majoration/by_profession",
			viewParams: { key: this.isvcApplication.profession }
		};

		return this.isvcStore.get<IMajorationByProfession>(loParams)
			.pipe(
				catchError(poError => { console.error("IDL.SYNT.C:: Erreur lors de la récuperation des majorations : ", poError); return throwError(poError); }),
				takeUntil(this.destroyed$)
			);
	}

	private initMajorations(paMajorations: Majoration[]): Majoration[] {
		this.maMajorations.forEach((poItem: IMajorationByProfession) => {
			paMajorations.forEach((poMajoration: Majoration) => {
				if (poItem.id.indexOf(poMajoration.type) !== -1)
					poMajoration.description = poItem.description;
			});
		});

		return paMajorations;
	}

	/** Initialise le composant de synthèse. */
	private init(): Observable<IContact[]> {
		return this.getMajorations()
			.pipe(
				tap((paMajorations: IMajorationByProfession[]) => this.maMajorations = paMajorations),
				switchMap(() => this.traitement.seances$),
				tap((paSeances: Seance[]) => {
					this.syntheseSeances = [];
					this.initSeancesStats(paSeances);
					this.totalPrice = 0;
					paSeances.forEach((poSeance: Seance) => {
						if (poSeance.status !== EStatusSeance.canceled) {
							const loSyntheseSeance: ISyntheseSeance = this.isvcSeance.createSyntheseSeance(this.traitement, poSeance, this.initMajorations(poSeance.majorations)); // On construit la synthèse de la séance.
							this.syntheseSeances.push(loSyntheseSeance); // On ajoute la nouvelle synthèse dnas le tableau des synthèses.
							this.setSyntheseSeanceTotalPrice(loSyntheseSeance); // On affecte le prix total de la synthèse.
							this.totalPrice += loSyntheseSeance.totalPrice; // On additionne le prix de la synthèse avec le prix total des synthèses.
						}
					});

					this.syntheseSeances = [...this.syntheseSeances];
					this.onCpamPercentChanged();
				}),
				mergeMap(() => this.isvcContacts.getContactsByIds(this.syntheseSeances
					.filter((poSyntheseSeance: ISyntheseSeance) => !StringHelper.isBlank(poSyntheseSeance.intervenantId))
					.map((poSyntheseSeance: ISyntheseSeance) => poSyntheseSeance.intervenantId)
				)),
				tap((paIntervenants: IContact[]) => {
					paIntervenants.forEach((poIntervenant: IContact) => this.contactsById.set(poIntervenant._id, poIntervenant));
					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			);
	}

	private initSeancesStats(paSeances: Seance[]): void {
		this.seancesStats = {
			total: paSeances.length,
			pending: 0,
			done: 0,
			canceled: 0,
			late: 0
		};

		paSeances.forEach((poSeance: Seance) => {
			switch (poSeance.status) {

				case EStatusSeance.completed:
				case EStatusSeance.done:
					this.seancesStats.done++;
					break;

				case EStatusSeance.canceled:
					this.seancesStats.canceled++;
					break;

				case EStatusSeance.pending:
				case EStatusSeance.inProgress:
				default:
					if (DateHelper.compareTwoDates(new Date(), poSeance.endDate) >= 0)
						this.seancesStats.late++;
					else
						this.seancesStats.pending++;
					break;
			};
		});
	}

	/** Le composant a reçu un événement indiquant un changement.
	 * @param psKey Chaîne de caractères correspondant à une clé, pour identifier l'abonnement.
	 */
	private onTraitementSlideEvent(psKey: string): void {

		if (psKey === TraitementSlideParentBase.C_RESET_MAJORATION) {
			this.reset();
			this.init().pipe(takeUntil(this.destroyed$))
				.subscribe();
		}
	}

	/** Modifications en fonction du pourcentage de restant à la charge du patient. */
	public onPatientPercentChanged(): void {
		this.traitement.setPatientPercent(this.patientPercent);
		this.onTraitementChanged();
		this.cpamPercent = this.traitement.getCpamPercent();
		this.refreshCpamRefundAndPatientPrice();
		this.detectChanges();
	}

	/** Rafraîchit le montant remboursé par la cpam et le montant à payer par le patient en fonction des pourcentages. */
	public refreshCpamRefundAndPatientPrice(): void {
		this.cpamRefund = (this.traitement.getCpamPercent() * 0.01 * this.mnPatientTotalPrice);
		this.patientPrice = this.totalPrice - this.cpamRefund - this.cpam100Sum;
	}

	/** Modifications en fonction du pourcentage de prise en charge par la CPAM. */
	public onCpamPercentChanged(): void {
		// Si le pourcentage cpam a été modifié, on enregistre le traitement.
		if (this.traitement.setCpamPercent(this.cpamPercent))
			this.onTraitementChanged();

		this.patientPercent = this.traitement.getPatientPercent();
		this.refreshCpamRefundAndPatientPrice();
		this.detectChanges();
	}

	private reset(): void {
		ArrayHelper.clear(this.syntheseSeances);
		this.totalPrice = 0;
		this.cpamRefund = 0;
		this.patientPrice = 0;
		this.mnPatientTotalPrice = 0;
	}

	/** Détection de changement dans une collection de données pour indiquer à Angular quel élément il doit rafraîchir.
	 * @param pnIndex Index généré par le ngFor.
	 */
	public trackByIndex(pnIndex: number): number {
		return pnIndex;
	}

	public getSeanceStatusIcon(poSynthese: ISyntheseSeance): string {
		switch (poSynthese.seanceStatus) {
			case EStatusSeance.completed:
				return "invoice";
			case EStatusSeance.done:
			case EStatusSeance.inProgress:
				return "checkmark";
			case EStatusSeance.canceled:
				return "close";
			case EStatusSeance.pending:
			default:
				if (DateHelper.compareTwoDates(new Date(), poSynthese.endDate) >= 0)
					return "filled-hourglass";
				else
					return "empty-hourglass";
		};
	}

	public getSeanceStatusColor(peStatus: EStatusSeance): string {
		switch (peStatus) {
			case EStatusSeance.completed:
			case EStatusSeance.done:
				return "success";
			case EStatusSeance.canceled:
				return "danger";
			case EStatusSeance.inProgress:
			case EStatusSeance.pending:
				return "warning";
			default:
				return "primary";
		};
	}

	public getContactAvatar(psContactId: string): IAvatar {
		const loAvatar: IAvatar = ContactsService.createContactAvatar(this.contactsById.get(psContactId), EAvatarSize.medium);
		if (!loAvatar.size)
			loAvatar.size = 20;
		return loAvatar;
	}

	//#endregion
}
