export abstract class StringHelper {

	//#region METHODS

	/** Vérifie qu'une chaîne de caractère est valide : ne doit pas être vide ou composée uniquement d'espaces.
	 * @param psString chaîne de caractères dont il faut vérifier si elle est valide ou non.
	 */
	public static isBlank(psString: string): boolean {
		return !psString ? true : psString.trim().length === 0; // Remplacement des caractères d'espace par rien.
	}

	/** Passe la 1ere lettre de la chaîne de caractère en majuscule.
	 * @param psString
	 */
	public static toPascalCase(psString: string): string {
		return psString[0].toUpperCase() + psString.substring(1);
	}

	/** Génère une chaîne de caractère contenant les champs de l'objet dont les nom sont données, en les séparant avec le séparateur indiqué.
	 * @param poObect
	 * @param paAttributeNames
	 * @param psSeparator
	 */
	public static concatenateAttributes(poObect: any, paAttributeNames: string[], psSeparator: string): string {
		let lsResult: string = "";

		paAttributeNames.forEach((psKey: string) => lsResult += (StringHelper.isBlank(poObect[psKey]) ? "" : poObect[psKey]) + psSeparator);

		return lsResult.trim();
	}

	/** Retourne `true` si la donnée est une chaîne de carctères non vide, `false` sinon.
	 * @param poData Donnée dont il faut vérifier si elle est uns string valide ou non.
	 */
	public static isValid(poData: any): boolean {
		return this.isString(poData) && !this.isBlank(poData);
	}

	/** Retourne true si c'est poData est une chaine de caractères
	 * @param poData Donnée à vérifier si c'est une string
	 */
	public static isString(poData: any): boolean {
		return typeof poData === "string";
	}

	/** Format une chaîne de caractères en remplaçant des chaînes de caractères par d'autres.
	 * @param psTarget Chaîne de caractères de base, qui doit être retourné avec des transformations.
	 * @param pmapReplacer Dictionnaire où les clés sont les sous-chaînes de `psTarget` à substituer et les valeurs, les chaînes de substitution.
	 * @example StringHelper.format("Je suis {prenom} {nom}", new Map([["{nom}", ["MACHIN"]], ["{prenom}", ["Toto"]]])); // Retourne "Je suis Toto MACHIN".
	 */
	public static format(psTarget: string, pmapReplacer: Map<string, string>): string {
		const loRegex = new RegExp(Array.from(pmapReplacer.keys()).join("|"), "gi");
		return psTarget.replace(loRegex, (psMatchedKey: string) => pmapReplacer.get(psMatchedKey));
	}

	/** Retourne un `string` représentant les queries string de l'objet passé en paramètre.
	 * @param poQueries Objet clé-valeur.
	 * @example StringHelper.toQueryString({ "val1": "Tr uc", "val2": "machin"}); // Retourne "val1=Tr%20uc&val2=machin".
	 */
	public static toQueryString(poQueries: Object): string {
		return Object.keys(poQueries)
			.map((poKey) => `${poKey}=${encodeURIComponent(`${poQueries[poKey]}`.replace(/\s/g, '_'))}`)
			.join('&');
	}

	/** coupe une chaîne à une longueur donnée.*/
	public static truncate(psValue: string, pnLength: number): string {
		return psValue.substring(0, pnLength);
	}

	//#endregion
}