<div class="di-etat-facturation" [ngClass]="getBillDetails().class">
	<strong class="text">{{ getBillDetails().text }}</strong>
	<lua-bouton-icone
		*ngIf="billedStatus === 'some'"
		[iconName]="getBillDetails().iconeName"
		[color]="getBillDetails().iconColor"
		(onClick)="handleButtonClick($event)"
	></lua-bouton-icone>
	<lua-icone
		*ngIf="billedStatus !== 'some'"
		[iconName]="getBillDetails().iconeName"
		[color]="getBillDetails().iconColor"
		mode="outlined"
	></lua-icone>
</div>
