import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ConversationButtonComponent } from './conversation-button/conversation-button.component';

@NgModule({
	declarations: [ConversationButtonComponent],
	imports: [
		CommonModule,
		IonicModule
	],
	exports: [ConversationButtonComponent]
})
export class ConversationsModule { }
