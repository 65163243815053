import { Component, OnInit } from '@angular/core';
import { FieldBase } from '../../../../model/forms/FieldBase';
import { ILikeable } from '../../../../model/like/ILikeable';
import { FormsService } from '../../../../services/forms.service';

@Component({
	templateUrl: './likeField.component.html'
})
export class LikeFieldComponent extends FieldBase implements OnInit { //todo : typer + params.

	//#region FIELDS

	private moParams: ILikeable;

	//#endregion

	//#region PROPERTIES

	public likes: number;
	public dislikes: number;
	public contentId: string;

	//#endregion

	//#region METHODS

	constructor(psvcForms: FormsService) {
		super(psvcForms);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		this.moParams = this.to.data;
		this.likes = this.model[this.moParams.likes];
		this.dislikes = this.model[this.moParams.dislikes];
		this.contentId = this.model[this.moParams.contentId];
	}

	//#endregion

}