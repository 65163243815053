import { Pipe, PipeTransform } from '@angular/core';
import { ContactNamePipe } from '@osapp/pipes';
import { IPatient } from '../model/IPatient';

@Pipe({ name: 'patientName' })
export class PatientNamePipe extends ContactNamePipe implements PipeTransform {

	//#region METHODS

	constructor() {
		super();
	}

	/** Renvoie le nom complet du patient 'Prénom NOM' s'il est renseigné, 'Patient inconnu' sinon.
	 * @param poValue Patient dont il faut récupérer le nom complet.
	 * @override
	 */
	public transform(poValue?: IPatient): string {
		const lsValue: string = super.transform(poValue);
		return lsValue === ContactNamePipe.C_UNKNOWN_CONTACT ? "Patient inconnu" : lsValue;
	}

	//#endregion
}