import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IHttpError } from '@osapp/model';
import { PasswordService } from '@osapp/modules/password/services/password.service';
import { tap } from 'rxjs/operators';
import { ConnexionService } from '../../../features/connexion/services/connexion.service';


@Component({
	selector: 'di-change-password',
	templateUrl: './password-change.page.html',
	styleUrls: ['./password-change.page.scss'],
})

export class ChangePasswordPage implements OnInit {
	public resetPasswordError: IHttpError;
	public resetPasswordControle: string[] = [];
	public token: string;
	public title: string;
	public isReset: boolean = true;
	public isValideMdp: boolean = false;
	public isPasswordError: boolean = false;

	public isPasswordVisibleNew: boolean = false;
	public passwordIconNew: string = "visibility";
	public passwordInputTypeNew = "password";
	public newPassword: string = "";


	public isPasswordVisibleConfirm: boolean = false;
	public passwordIconConfirm: string = "visibility";
	public passwordInputTypeConfirm = "password";
	public confirmNewPassword: string = "";

	constructor(
		private router: Router,
		private svcPassword: PasswordService,
		private svcConnexion: ConnexionService,
		private activatedRoute: ActivatedRoute,


	) { }

	ngOnInit(): void {
		this.token = this.activatedRoute.snapshot.queryParamMap.get("token");
		this.svcConnexion.verifToken(this.token);
		this.getTitle();

	}

	public handleClickIconNew(event: MouseEvent) {
		this.isPasswordVisibleNew = !this.isPasswordVisibleNew;
		this.passwordInputTypeNew = this.isPasswordVisibleNew ? 'text' : 'password';
		this.passwordIconNew = this.isPasswordVisibleNew ? 'visibility_off' : 'visibility';
	}

	public handleClickIconConfirm(event: MouseEvent) {
		this.isPasswordVisibleConfirm = !this.isPasswordVisibleConfirm;
		this.passwordInputTypeConfirm = this.isPasswordVisibleConfirm ? 'text' : 'password';
		this.passwordIconConfirm = this.isPasswordVisibleConfirm ? 'visibility_off' : 'visibility';
	}

	private getTitle(): void {
		this.activatedRoute.queryParams.subscribe(params => {
			const type = params['type'];
			if (type === 'reset') {
				this.title = 'Réinitialisation du mot de passe';
			} else if (type === 'create') {
				this.title = 'Création du mot de passe';
			}
		});
	}

	public async resetPassword() {

		if (this.confirmNewPassword === "" && this.newPassword === "") {
			this.resetPasswordError = { message: 'Veuillez remplir les champs.' } as IHttpError;
			return null;
		}

		if (!this.isValideMdp) {
			this.resetPasswordError = { message: 'Veuillez remplir toutes les conditions.' } as IHttpError;
			return null;
		}

		return this.svcPassword.resetPassword(this.token, this.newPassword)
			.pipe(
				tap(
					_ => this.router.navigate(['/confirmationMdpChange']),
					error => this.resetPasswordError = error
				)
			)
			.toPromise();
	}

	public navigateToLogin() {
		this.router.navigate(['/authenticator']);
	}

	private handleChangePassword(newValue: string, isConfirm: boolean): void {
		if (isConfirm) {
			this.confirmNewPassword = newValue;
		} else {
			this.newPassword = newValue;
		}
		this.resetPasswordControle = this.validatePassword(this.newPassword, this.confirmNewPassword);
	}

	public handleChangeValue(pwd: string): void {
		this.handleChangePassword(pwd, false);
	}

	public handleChangeValueConfirm(pwd: string): void {
		this.handleChangePassword(pwd, true);
	}

	private validatePassword(password: string, confirmPassword: string): string[] {
		let validationErrors: string[] = [];
		if (password === "") return validationErrors;
		const passwordErrors = this.svcConnexion.isValidCriters(password, true);
		validationErrors = validationErrors.concat(passwordErrors);
		this.isPasswordError = password !== confirmPassword;
		this.isValideMdp = (validationErrors.length === 6 && !this.isPasswordError);
		return validationErrors;
	}

	public hasError(errorCode: string): boolean {
		if (!this.resetPasswordControle || this.resetPasswordControle.length === 0) {
			return true;
		}
		return this.svcConnexion.hasError(this.resetPasswordControle, errorCode);
	}
}
