<section class="txt-left">
	<section class="sct-detail-parts txt14px">
		<!-- <div class="dis-flex ctn-part ctn-part_CPAM">
			<div class="txt14px width100pc">
				<span>CPAM</span>
			</div>

			<ion-item lines="none" class="width100pc">
				<ion-icon name="card"></ion-icon>
				<mat-select [(value)]="cpamPercent" (selectionChange)="onCpamPercentChanged()" class="citem">
					<mat-option *ngFor="let percent of percentages" [value]="percent">{{percent}}</mat-option>
				</mat-select>
				<div slot="end" class="txt12px">
					<p>{{cpamRefund | priceFormat:2}} €</p>
				</div>
			</ion-item>
			<ion-item lines="none" class="width100pc">
				<ion-label>Pris en charge à 100%</ion-label>
				<div slot="end" class="txt12px">
					<p>{{cpam100Sum | priceFormat:2}} €</p>
				</div>
			</ion-item>

		</div> -->

		<!-- <div class="dis-flex ctn-part ctn-part-mut-patient">
			<div class="txt14px width100pc">
				<span>Mutuelle et patient</span>
			</div>

			<ion-item lines="none" class="width100pc">
				<ion-icon name="umbrella"></ion-icon>
				<mat-select [(value)]="patientPercent" (selectionChange)="onPatientPercentChanged()" class="citem">
					<mat-option *ngFor="let percent of percentages" [value]="percent">{{percent}}</mat-option>
				</mat-select>
				<div slot="end" class="txt12px">
					<p>{{patientPrice | priceFormat:2}} €</p>
				</div>
			</ion-item>

		</div> -->

		<div class="dis-flex ctn-part ctn-part-total">
			<ion-item lines="none" class="width100pc">
				<span>Montant total</span>
				<div class="flx-grow1 txt12px txt-right txt-info">
					<p>{{ totalPrice | priceFormat:2 }} €</p>
				</div>
			</ion-item>

			<ng-container *ngIf="!!seancesStats">
				<ion-item lines="none" class="width100pc">
					<span>Séances total</span>
					<div class="flx-grow1 txt12px txt-right txt-info">
						<p>{{ seancesStats.total }}</p>
					</div>
				</ion-item>

				<ion-item lines="none" class="width100pc">
					<ion-icon slot="start" name="empty-hourglass" color="primary"></ion-icon>
					<span>Restantes</span>
					<div class="flx-grow1 txt12px txt-right">
						<p>{{ seancesStats.pending }}</p>
					</div>
				</ion-item>

				<ion-item lines="none" class="width100pc">
					<ion-icon slot="start" name="checkmark" color="success"></ion-icon>
					<span>Réalisées</span>
					<div class="flx-grow1 txt12px txt-right">
						<p>{{ seancesStats.done }}</p>
					</div>
				</ion-item>

				<ion-item lines="none" class="width100pc">
					<ion-icon slot="start" name="close" color="danger"></ion-icon>
					<span>Annulées</span>
					<div class="flx-grow1 txt12px txt-right">
						<p>{{ seancesStats.canceled }}</p>
					</div>
				</ion-item>

				<ion-item lines="none" class="width100pc">
					<ion-icon slot="start" name="filled-hourglass" color="warning"></ion-icon>
					<span>Non réalisées</span>
					<div class="flx-grow1 txt12px txt-right">
						<p>{{ seancesStats.late }}</p>
					</div>
				</ion-item>
			</ng-container>
		</div>
	</section>

	<div *ngIf="traitement.endDate" class="ctn-info-synthese dis-flex flex-col">
		<span class="txt-legende">Fin de traitement estimée</span>
		<span class="txt-info txt14px">{{traitement.endDate | dateWithLocale: 'fr-FR' : 'EEE dd MMMM yyyy'}}</span>
	</div>

	<div class="ctn-detail-calculs-seance">
		<i class="txt14px title-detail">Détail des calculs</i>
		<calao-virtual-scroll #virtualscroll [items]="syntheseSeances" itemSize="160">
			<ion-list>
				<ng-template let-synthese="$implicit">
					<ion-item-divider lines="none" class="ctn-intitule-seance">
						<div>
							<ion-label slot="start" class="seance-title">
								Séance du {{ synthese.startDate | dateWithLocale: 'fr-FR' : 'EEE dd MMMM yyyy'}} à {{ synthese.startDate
								|
								dateWithLocale: 'fr-FR' : 'HH:mm'}}
							</ion-label>
							<span>({{ synthese.acteLength }} acte{{ synthese.acteLength > 1 ? 's' : '' }})</span>
						</div>
						<div slot="end" class="txt12px seance-price">
							<avatar [src]="getContactAvatar(synthese.intervenantId)" cssClass="round">
							</avatar>
							<ion-icon [name]="getSeanceStatusIcon(synthese)" [color]="getSeanceStatusColor(synthese.seanceStatus)"
								class="icon">
							</ion-icon>
							<p>{{ synthese.totalPrice | priceFormat:2 }} €</p>
						</div>
					</ion-item-divider>

					<ion-item lines="full" *ngFor="let acte of synthese.actes; trackBy: trackByIndex" class="ctn-liste-actes">
						<div class="item-content">
							<ion-note slot="start" class="note">
								{{acte.keyLetters}} {{acte.priceCoefficient > 0 ? acte.priceCoefficient : ""}}
								<span *ngIf="acte.taxAllowance !== 1"> {{acte.taxAllowance >= 0 ? "x" + acte.taxAllowance :
									""}}</span>
							</ion-note>
							<ion-label>
								{{acte.label}}
							</ion-label>
							<div slot="end" class="txt12px seance-price">
								<p>{{acte.price | priceFormat:2}} €</p>
							</div>
						</div>
					</ion-item>

					<ion-item lines="full" *ngFor="let majoration of synthese.majorations; trackBy: trackByIndex"
						class="ctn-liste-actes">
						<div class="item-content">
							<ion-label>{{majoration.description}}</ion-label>
							<div slot="end" class="txt12px seance-price">
								<p>{{majoration.price | priceFormat:2}} €</p>
							</div>
						</div>
					</ion-item>

					<ion-item lines="full" class="ctn-liste-actes">
						<div class="item-content">
							<ion-label>Déplacement
								<span *ngIf="synthese.deplacementDetail">({{ synthese.deplacementDetail }})</span>
							</ion-label>
							<div slot="end" class="txt12px seance-price">
								<p>{{ synthese.indemnitePrice | priceFormat:2}} €</p>
							</div>
						</div>
					</ion-item>
				</ng-template>
			</ion-list>
		</calao-virtual-scroll>
	</div>
</section>