import { IConvSyncTaskParams, IStoreReplicationResponse } from 'libs/osapp/src/model';
import { Observable } from 'rxjs';
import { ConversationService } from '../conversation.service';
import { InjectorService } from '../injector.service';
import { TaskBase } from './TaskBase';
import { TaskDescriptor } from './TaskDescriptor';

export class ConvSyncTask<T extends IConvSyncTaskParams = IConvSyncTaskParams> extends TaskBase<T> {

	//#region FIELDS

	private misvcConversation: ConversationService;

	//#endregion

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<T>) {
		super(poDescriptor);
		this.misvcConversation = InjectorService.instance.get(ConversationService);
	}

	/** @override */
	public execTask(): Observable<IStoreReplicationResponse> {
		return this.misvcConversation.replicateConversationsToServer$();
	}

	//#endregion
}