import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'di-side-page-desmos',
  templateUrl: './side-page-desmos.component.html',
  styleUrls: ['./side-page-desmos.component.scss']
})
export class SidePageDesmosComponent implements OnInit {
  @Input()
  public colorTitre = 'CouleurPrimaire';

  @Input()
  public colorLogo = 'CouleurPrimaire';

  @Input()
  public colorBackground = 'Blanc';

  @Input()
  public showBackgroundImage: boolean = true;


  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    this.el.nativeElement.style.setProperty(
      '--my-color-titre',
      `var(--${this.colorTitre})`
    );
    this.el.nativeElement.style.setProperty(
      '--my-color-logo',
      `var(--${this.colorLogo})`
    );
    this.el.nativeElement.style.setProperty(
      '--my-color-background',
      `var(--${this.colorBackground})`
    );
  }
}