<div *ngIf="ordonnance">
	<span
		[ngClass]="{'warning': !ordonnance.documents?.length > 0, 'success': ordonnance.documents?.length > 0}">SCOR</span>

	<ion-icon *ngIf="!ordonnance.prescripteurContactId" name="person" color="warning" matTooltip="Pas de prescripteur">
	</ion-icon>
</div>

<div *ngIf="!ordonnance">
	<ion-icon name="alert-circle" color="warning" matTooltip="Pas d'ordonnance"></ion-icon>
</div>