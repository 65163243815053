import { Inject, InjectionToken, Optional, Pipe, PipeTransform } from '@angular/core';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { NumberHelper } from '@osapp/helpers/numberHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { Acte } from '../../model/Acte';
import { EResumeActeMode } from './model/EResumeActeMode';

export const C_RESUME_ACTE__MODE = new InjectionToken<EResumeActeMode>("C_RESUME_ACTE__MODE");

@Pipe({
	name: "concatActes"
})
export class ConcatActesPipe implements PipeTransform {

	//#region METHODS

	constructor(
		@Inject(C_RESUME_ACTE__MODE) @Optional() private ieActeResumeMode: EResumeActeMode = EResumeActeMode.lettreCle,
	) { }

	public transform(paActes: Acte[], psSeparator = " +", peResumeActeDisplayMode: EResumeActeMode = this.ieActeResumeMode): string {
		const loResumesMap = new Map<string, number>();
		let lsResumeActes = "";

		if (ArrayHelper.hasElements(paActes)) {
			paActes.forEach((poActe: Acte) => {
				const laKeyLetters: string[] = [];

				laKeyLetters.push(poActe.keyLetters);

				const lsResume: string = this.generateResume(poActe, peResumeActeDisplayMode);
				let lnNumber: number = loResumesMap.get(lsResume);

				if (NumberHelper.isValid(lnNumber))
					lnNumber++;

				else
					lnNumber = 1;

				loResumesMap.set(lsResume, lnNumber);
			});

			loResumesMap.forEach((pnNumber: number, psResume: string) => {
				if (!StringHelper.isBlank(lsResumeActes))
					lsResumeActes += `${psSeparator} `;

				if (pnNumber > 1)
					lsResumeActes += `${pnNumber}x `;

				lsResumeActes += psResume;
			});
		}

		return lsResumeActes;
	}


	private generateResume(poActe: Acte, peResumeActeDisplayMode: EResumeActeMode): string {
		switch (peResumeActeDisplayMode) {

			case EResumeActeMode.label:
				return this.generateResumeWithLabel(poActe);

			default:
				return this.generateResumeWithKeyLetter(poActe);
		}
	}

	private generateResumeWithKeyLetter(poActe: Acte): string {
		return `${poActe.keyLetters} ${poActe.priceCoefficient}`;
	}

	private generateResumeWithLabel(poActe: Acte): string {
		if (!poActe.label)
			return "";

		const lsTruncatedLabel: string = poActe.label.replace(/(\[.*\]|\s{2,})/g, "").trim().split(" ").slice(0, 3).join(" ") ?? "";
		return lsTruncatedLabel === poActe.label ? lsTruncatedLabel : `${lsTruncatedLabel} ...`;
	}

	//#endregion

}