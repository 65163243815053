<div class="panneau-recherche-actes">
	<div>
		<div class="panneau-recherche-actes__container">
			<div class="container__content">
				<di-recherche-actes [mobileViewForce]="true" [ordonnance]="ordonnance"
					[displayCount]="true"></di-recherche-actes>
			</div>
		</div>
	</div>

</div>