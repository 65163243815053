<ion-content>
	<!-- ZONE DE RECHERCHE -->
	<div *ngIf="params.hasSearchbox" class="search-box txt-center width100pc">
		<search #search [options]="params.searchOptions" [data]="patients"
			(filteredEntriesChanged)="onFilteredPatientsChanged($event)" (searchValueChange)="onSearchValueChanged($event)"
			data-cy="search-contact-selector">
		</search>
		<osapp-selector *ngIf="params.groupFilterParams && params.groupFilterParams.options.length > 0"
			[options]="params.groupFilterParams.options" (selectionChanged)="onGroupFilterSelectionChanged($event, search)"
			[multiple]="params.groupFilterParams.multiple" [color]="params.groupFilterParams.color"
			[displayMode]="params.groupFilterParams.displayMode || 'tags'" [limit]="params.groupFilterParams.limit"
			[searchOptions]="params.groupFilterParams.searchOptions"
			[preselectedValues]="params.groupFilterParams.preselectedValues" [selectCss]="params.groupFilterParams.selectCss"
			[label]="params.groupFilterParams.label">
		</osapp-selector>
	</div>

	<ng-container *ngTemplateOutlet="showDesmosPatients ? desmosContent : databaseContent"></ng-container>
</ion-content>

<ng-template #databaseContent>
	<ng-container *ngIf="!params.hasSearchbox || filteredPatientOptions?.length > 0; else emptyList">
		<!-- Sélection de tous les contacts -->
		<ion-item
			*ngIf="params.allSelectionButton && filteredPatientOptions.length > 1 && params.selectionLimit === infinity"
			lines="full" (click)="onSelectAllPatientChanged()" class="marg-container border-item-top">
			<ion-checkbox slot="start" [checked]="areAllContactsSelected" [disabled]="areAllContactsSelectionDisabled"
				class="ion-margin-top ion-margin-bottom">
			</ion-checkbox>
			<ion-label>
				Tout sélectionner
			</ion-label>
		</ion-item>
		<div class="list-ctn">
			<calao-virtual-scroll itemSize="53" [items]="filteredPatientOptions">
				<ng-template let-patientOption="$implicit">
					<ion-item data-cy="contact-item" (click)="onPatientSelectionChanged(patientOption)">
						<ion-checkbox *ngIf="params.selectionLimit > 1" slot="start" [checked]="patientOption.selected"
							[disabled]="(!patientOption.selected && !canSelectMore) || patientOption.disabled"
							data-cy="contact-checkbox" class="ion-margin-top ion-margin-bottom">
						</ion-checkbox>
						<mat-radio-button *ngIf="params.selectionLimit === 1" class="radio-selector" slot="start"
							[disabled]="patientOption.disabled" [value]="patientOption.value._id" [checked]="patientOption.selected">
						</mat-radio-button>
						<div class="avatar">
							<avatar [src]="getContactAvatar(patientOption.value)" cssClass="round">
							</avatar>
						</div>
						<div class="detail">
							<ion-label>
								{{patientOption.label}}
							</ion-label>
							<ion-note class="no-margin">
								<span *ngIf="patientOption.value.maidenName as maidenName">
									{{ maidenName }}
								</span>
								<span *ngIf="patientOption.value.birthDate as birthDate">
									{{ birthDate | dateWithLocale : 'fr-FR' : 'dd MMM yyyy' + " - " }}
								</span>
								<span *ngIf="patientOption.value.socialNumber as socialNumber">
									{{ socialNumber }}
								</span>
							</ion-note>
						</div>
					</ion-item>
				</ng-template>
			</calao-virtual-scroll>
		</div>
	</ng-container>
</ng-template>


<!-- Template pour le contenu issu de desmos. -->
<ng-template #desmosContent>
	<div *ngIf="desmosPatients.length > 0" class="width100pc">
		<ion-virtual-scroll class="dis-flex flex-col list" [items]="desmosPatients" [trackBy]="trackById"
			osappAutoCloseSlideItem>
			<ion-item *virtualItem="let patient">
				<div class="click-container" (click)="onDesmosItemClicked(patient)">
					<!-- Affichage des textes. -->
					<ion-text class="text-container center">
						<ion-label class="last-name">{{ patient | contactName }}</ion-label>
						<ion-label class="first-name">{{ patient.birthDate | date: "dd/MM/yyyy" }}</ion-label>
						<ion-label class="no-margin">{{ patient.socialNumber }}</ion-label>
					</ion-text>
				</div>
			</ion-item>
		</ion-virtual-scroll>
	</div>
</ng-template>

<!-- PAS DE RÉSULTAT DE RECHERCHE. -->
<ng-template #emptyList>
	<!-- Si le composant a terminé de s'initialiser et qu'il y a une barre de recherche et qu'elle n'a retourné aucun résultat. -->
	<div class="empty-screen flex-col">
		<ng-container *ngIf="isLoading; else empty">
			<osapp-spinner message="Chargement des patients"></osapp-spinner>
		</ng-container>
		<ng-template #empty>
			<span class="txt-message">
				<ion-note>Aucun résultat.</ion-note>
			</span>
			<idl-desmos-search-button *ngIf="!!searchValue && canUseDesmos" [searchValue]="searchValue"
				(patientsSearched)="onSearchWithDesmos($event)"></idl-desmos-search-button>
		</ng-template>
	</div>
</ng-template>