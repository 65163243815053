import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FingerprintAIO } from "@ionic-native/fingerprint-aio/ngx";
import { EMPTY, from, Observable } from 'rxjs';
import { catchError, filter, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { LifeCycleObserverComponentBase } from '../../../helpers/LifeCycleObserverComponentBase';
import { ILifeCycleEvent } from '../../../model/lifeCycle/ILifeCycleEvent';
import { EUnlockMode } from '../../../model/security/EUnlockMode';
import { ISecuritySettings } from '../../../model/security/ISecuritySettings';
import { ISecuritySettingsDocument } from "../../../model/security/ISecuritySettingsDocument";
import { SecurityService } from '../../../services/security.service';
import { DynamicPageComponent } from '../../dynamicPage/dynamicPage.component';
import { IDynHostComponent } from '../../dynHost/IDynHost.component';

@Component({
	templateUrl: 'securitySettings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecuritySettingsComponent extends LifeCycleObserverComponentBase implements IDynHostComponent, OnInit, OnDestroy {

	//#region PROPERTIES

	@Input() public params: any;

	public fingerprintUnlockEnabled = false;
	public fingerprintUnlockAvailable = false;
	public instanceId: string;

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des empreintes digitales. */
		private ioFaio: FingerprintAIO,
		/** Service de gestion de la sécurité. */
		private isvcSecurity: SecurityService,
		poParentPage: DynamicPageComponent<ComponentBase>,
		poChangeDetector: ChangeDetectorRef) {

		super(poParentPage, poChangeDetector);
	}

	public ngOnInit(): void {
		from(this.ioFaio.isAvailable())
			.pipe(
				tap(
					_ => this.setfingerPrintAuthenticationAvailable(true),
					_ => this.setfingerPrintAuthenticationAvailable(false)
				)
			)
			.subscribe();

		this.isvcSecurity.getSavedSecuritySettings()
			.pipe(
				catchError(poError => { console.error("SECU.STGS.C::", poError); return EMPTY; }),
				filter((poResult: ISecuritySettingsDocument) => !!poResult),
				tap((poResult: ISecuritySettingsDocument) => this.setFingerPrintAuthenticationEnabled(poResult.unlockMode === EUnlockMode.fingerprint))
			)
			.subscribe();
	}

	/** Permet de définir la valeur de la propriété fingerPrintAuthenticationAvailable et de rafraîchir l'interface.
	 * @param pbIsAvailable Indique si l'authentification par empreinte digitale est disponible ou non.
	 */
	private setfingerPrintAuthenticationAvailable(pbIsAvailable: boolean): void {
		this.fingerprintUnlockAvailable = pbIsAvailable;
		this.detectChanges();
	}

	/** Permet de définir la valeur de la propriété "fingerPrintAuthenticationEnabled" et de rafraîchir l'interface.
	 * @param pbIsFingerprintAuth
	 */
	private setFingerPrintAuthenticationEnabled(pbIsFingerprintAuth: boolean): void {
		this.fingerprintUnlockEnabled = pbIsFingerprintAuth;
		this.detectChanges();
	}

	/** Appelée lors de l'activation du toggle d'activation de l'authentification par empreinte. */
	public onFingerPrintAuthenticationClicked(): void {
		const loFingerprintUnlockEnabled$: Observable<void> = this.fingerprintUnlockEnabled ? this.requestAuthentication() : this.persistSettings();

		loFingerprintUnlockEnabled$
			.pipe(takeUntil(this.destroyed$))
			.subscribe();
	}

	private requestAuthentication(): Observable<void> {
		return this.isvcSecurity.showFingerprintScanner()
			.pipe(
				catchError(_ => {
					this.setFingerPrintAuthenticationEnabled(false);
					return EMPTY;
				}),
				mergeMap(() => this.persistSettings())
			);
	}

	/** Sauvegarde les paramètres. */
	private persistSettings(): Observable<void> {
		const loSecuritySettings: ISecuritySettings = {
			unlockMode: this.fingerprintUnlockEnabled ? EUnlockMode.fingerprint : EUnlockMode.loginPassword
		};

		return this.isvcSecurity.persistSecuritySettings(loSecuritySettings)
			.pipe(
				catchError(poError => { console.error(poError); return EMPTY; }),
				mergeMap(_ => EMPTY)
			);
	}

	protected onLifeCycleEvent(poEvent: ILifeCycleEvent): void { }

	//#endregion
}
