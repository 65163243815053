import { DecimalPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { IonItemSliding } from '@ionic/angular';
import { AlertButton } from '@ionic/core';
import { DynamicPageComponent } from '@osapp/components/dynamicPage/dynamicPage.component';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { EnumHelper } from '@osapp/helpers/enumHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { MapHelper } from '@osapp/helpers/mapHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { StoreHelper } from '@osapp/helpers/storeHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { EPrefix } from '@osapp/model/EPrefix';
import { UserData } from '@osapp/model/application/UserData';
import { EBarElementDock } from '@osapp/model/barElement/EBarElementDock';
import { EBarElementPosition } from '@osapp/model/barElement/EBarElementPosition';
import { IBarElement } from '@osapp/model/barElement/IBarElement';
import { IContact } from '@osapp/model/contacts/IContact';
import { IGroup } from '@osapp/model/contacts/IGroup';
import { EDateTimePickerMode } from '@osapp/model/date/EDateTimePickerMode';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { IDateTimePickerParams } from '@osapp/model/date/IDateTimePickerParams';
import { IFormListEvent } from '@osapp/model/forms/IFormListEvent';
import { ELifeCycleEvent } from '@osapp/model/lifeCycle/ELifeCycleEvent';
import { ILifeCycleEvent } from '@osapp/model/lifeCycle/ILifeCycleEvent';
import { IAvatar } from '@osapp/model/picture/IAvatar';
import { IMinMaxDates } from '@osapp/modules/date/model/IMinMaxDates';
import { IHoursMinutes } from '@osapp/modules/date/model/ihours-minutes';
import { DayRepetition } from '@osapp/modules/event-markers/models/day-repetition';
import { EDuree } from '@osapp/modules/event-markers/models/eduree';
import { ERepetition } from '@osapp/modules/event-markers/models/erepetition';
import { HoursMinutesRepetition } from '@osapp/modules/event-markers/models/hours-minutes-repetition';
import { RangeRepetition } from '@osapp/modules/event-markers/models/range-repetition';
import { Recurrence } from '@osapp/modules/event-markers/models/recurrence';
import { LogAction } from '@osapp/modules/logger/decorators/log-action.decorator';
import { ILogSource } from '@osapp/modules/logger/models/ILogSource';
import { LogActionHandler } from '@osapp/modules/logger/models/log-action-handler';
import { LoggerService } from '@osapp/modules/logger/services/logger.service';
import { ModalService } from '@osapp/modules/modal/services/modal.service';
import { IFavorites } from '@osapp/modules/preferences/favorites/model/IFavorites';
import { FavoritesService } from '@osapp/modules/preferences/favorites/services/favorites.service';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { IRange } from '@osapp/modules/utils/models/models/irange';
import { ContactsService } from '@osapp/services/contacts.service';
import { FormsService } from '@osapp/services/forms.service';
import { GroupsService } from '@osapp/services/groups.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '@osapp/services/interfaces/ShowMessageParamsToast';
import { LoadingService } from '@osapp/services/loading.service';
import { SlideboxService } from '@osapp/services/slidebox.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { EMPTY, GroupedObservable, Observable, Subject, defer, from } from 'rxjs';
import { filter, groupBy, map, mapTo, mergeMap, switchMap, take, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { C_PREFIX_ACT } from '../../../../app/app.constants';
import { Acte } from '../../../../model/Acte';
import { EModalActeType } from '../../../../model/EModalActeType';
import { EPathologie } from '../../../../model/EPathologies';
import { EStatusSeance } from '../../../../model/EStatusSeance';
import { IPrescriptionsParams } from '../../../../model/IPrescriptionsParams';
import { Seance } from '../../../../model/Seance';
import { Traitement } from '../../../../model/Traitement';
import { TraitementSlideParentBase } from '../../../../model/TraitementSlideParentBase';
import { IInterruption } from '../../../../model/interruption/IInterruption';
import { SeanceService } from '../../../../services/seance.service';
import { TraitementService } from '../../../../services/traitement.service';
import { ActesService } from '../../../actes/actes.service';
import { Constraint } from '../../../actes/model/constraint';
import { EIdlLogActionId } from '../../../logger/models/EIdlLogActionId';
import { OrdonnanceGallerySelectorModalOpenerService } from '../../../ordonnances/components/ordonnances-gallery-selector-modal/services/ordonnance-gallery-selector-modal-opener.service';
import { Ordonnance } from '../../../ordonnances/models/ordonnance';
import { OrdonnancesService } from '../../../ordonnances/services/ordonnances.service';
import { PatientsService } from '../../../patients/services/patients.service';
import { ActeEditModalComponent } from '../../acte-edit-modal/acte-edit-modal.component';
import { InterruptionModalComponent } from '../../interruptionModal/interruptionModal.component';
import { ETraitementTags } from '../../model/ETraitementTags';
import { TraitementDataManager } from '../../traitement-data-manager.service';
import { TraitementSlideComponentBase } from '../traitement-slide-component-base.component';

interface IConstraintItem {
	label: string;
	creatorAvatar: IAvatar;
	createDate: Date;
}
interface IActesByOrdonnance {
	ordonnance: Ordonnance,
	startDate?: Date,
	endDate?: Date,
	actes: Acte[]
}
interface IActeOption {
	label: string
	icon?: string;
}
@Component({
	templateUrl: './prescriptions.component.html',
	styleUrls: ['./prescriptions.component.scss']
})
export class PrescriptionsComponent extends TraitementSlideComponentBase implements ILogSource, OnInit, OnDestroy, AfterViewInit {

	//#region FIELDS

	private readonly moFavoritesClickSubject = new Subject<Acte>();
	/** Map de contacts */
	private moContactsById = new Map<string, IContact>();
	/** Map de groupes */
	private moGroupsById = new Map<string, IGroup>();
	/** Liste des ordonnances du traitement */
	private maOrdonnances: Ordonnance[];
	/** Liste des ids d'ordonnance par guid d'acte */
	private moOrdonnancesIdsByActeGuid: Map<string, string[]>;

	private readonly moPlaceOptionsByValue: ReadonlyMap<string, IActeOption> = new Map<string, IActeOption>([
		["home", { label: "Domicile", icon: "home" }],
		["center", { label: "Cabinet", icon: "center" }]
	]);
	private readonly moRangeOptionsByValue: ReadonlyMap<string, IActeOption> = new Map<string, IActeOption>([
		["06h00-10h00", { label: "à jeun" }],
		["10h00-12h00", { label: "matin" }],
		["12h00-14h00", { label: "midi" }],
		["14h00-18h00", { label: "après midi" }],
		["18h00-20h00", { label: "soir" }],
		["20h00-23h00", { label: "au coucher" }],
		["23h00-06h00", { label: "nuit" }]
	]);
	private readonly moDayOptionsByValue: ReadonlyMap<number, IActeOption> = new Map<number, IActeOption>([
		[1, { label: "lun" }],
		[2, { label: "mar" }],
		[3, { label: "mer" }],
		[4, { label: "jeu" }],
		[5, { label: "ven" }],
		[6, { label: "sam" }],
		[0, { label: "dim" }]
	]);

	private static readonly C_LOG_ID = "PRESCRI.C::";

	//#endregion

	//#region PROPERTIES

	/** @implements */
	public readonly logSourceId = "ACTES.C::";
	/** @implements */
	public readonly logActionHandler = new LogActionHandler(this);

	/** Format des dates à afficher. */
	public readonly displayDateFormat: string = ETimetablePattern.dd_MMM_yyyy;
	public readonly breakDateDateFormat: string = ETimetablePattern.EEEE_dd_MMMM_yyyy;
	public readonly shortDateFormat: string = ETimetablePattern.dd_MM_yyyy_slash;
	public readonly longDateFormat: string = ETimetablePattern.dd_MM_yyyy_HH_mm_slash;


	/** Données passées par le slidebox. */
	@Input() public params?: IPrescriptionsParams;

	/** Liste des items à afficher pour chacune des contraintes */
	private maConstraintItems: IConstraintItem[];
	public get constraintItems(): ReadonlyArray<IConstraintItem> { return this.maConstraintItems; }
	/** Liste des actes par ordonnance */
	private maActesByOrdonnance: IActesByOrdonnance[] = [];
	public get actesByOrdonnance(): ReadonlyArray<IActesByOrdonnance> { return this.maActesByOrdonnance; }
	/** Liste des actes sans ordonnance */
	private maActesWithoutOrdonnance: Acte[] = [];
	public get actesWithoutOrdonnance(): ReadonlyArray<Acte> { return this.maActesWithoutOrdonnance; }

	public readonly hasValidatedOrCanceledSeancesByActeGuid = new Map<string, boolean>();

	private moFavorites: IFavorites;
	public get favorites(): IFavorites { return this.moFavorites; }

	public readonly acteOptionsByGuid = new Map<string, string>();

	private mbIsSmallScreen: boolean;
	public get isSmallScreen(): boolean { return this.mbIsSmallScreen; }

	/** Modèle du composant contactsList pour le patient. */
	public contactsListModelForPatient: string;
	/** Modèle du composant contactsList pour le prescripteur. */
	public contactsListModelForPrescriptor: string;
	/** Indique si un identifiant de patient a été sélectionné ou non (pour cacher le bouton de création). */
	public hasPatientId: boolean;
	/** Indique si un identifiant de prescripteur a été sélectionné ou non (pour cacher le bouton de création). */
	public hasPrescriptorId: boolean;
	/** Paramètres du composant dateTimeSpinner pour la date d'interruption de la prescription. */
	public dateTimeSpinnerParamsForBreakDate: IDateTimePickerParams;
	/** Paramètres du composant osapp-selector pour la selection des tags du traitement. */
	public tagsOptions: ISelectOption[] = EnumHelper.getKeys(ETraitementTags).map((psKey: string) => ({ label: ETraitementTags[psKey], value: psKey }));
	/** Paramètres du composant osapp-selector pour la selection des pathologies du traitement. */
	public pathologiesOptions: ISelectOption[] = [
		{ label: "Soin palliatif", value: "palliatif" },
		{ label: "Diabète insulino-traité", value: "diabetic" },
		{ label: "Cancer / Immunodépression", value: "cancer" },
		{ label: "Mucoviscidose", value: "mucoviscidose" },
		{ label: "Patient dépendant", value: "dependant" },
	];

	//#endregion

	//#region METHODS

	constructor(
		/** @implements */
		public readonly isvcLogger: LoggerService,
		private readonly isvcForms: FormsService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcContacts: ContactsService,
		private readonly isvcGroups: GroupsService,
		private readonly isvcModal: ModalService,
		private readonly isvcOrdonnances: OrdonnancesService,
		public readonly isvcActes: ActesService,
		private readonly isvcFavorites: FavoritesService,
		private readonly ioDecimalPipe: DecimalPipe,
		private readonly isvcOrdonnancesGallerySelectorModalOpener: OrdonnanceGallerySelectorModalOpenerService,
		psvcPatients: PatientsService,
		psvcTraitementDataManager: TraitementDataManager,
		psvcSeance: SeanceService,
		poParentComponent: TraitementSlideParentBase,
		psvcTraitement: TraitementService,
		psvcLoading: LoadingService,
		poParentPage: DynamicPageComponent<ComponentBase>,
		poChangeDetectorRef: ChangeDetectorRef,
		psvcSlidebox: SlideboxService) {

		super(
			psvcTraitementDataManager,
			psvcSeance,
			poParentComponent,
			psvcTraitement,
			psvcLoading,
			psvcPatients,
			poParentPage,
			poChangeDetectorRef,
			psvcSlidebox
		);
		// Rafraîchissement de la vue lorsque le traitement a été modifié.
		this.moParentComponent.getTraitementAsObservable()
			.pipe(
				tap((poTraitement: Traitement) => {
					this.traitement = poTraitement;
					this.refreshToolbar();
				}),
				mergeMap(() => this.getConstraintItems(this.traitement.constraints)),
				tap((paConstraintsItems: IConstraintItem[]) => this.maConstraintItems = paConstraintsItems),
				mergeMap(() => this.isvcOrdonnances.getTraitementOrdonnances(this.traitement._id, true)),
				tap((paOrdonnances: Ordonnance[]) => this.maOrdonnances = paOrdonnances.sort((poA: Ordonnance, poB: Ordonnance) =>
					DateHelper.compareTwoDates(poB.prescriptionDate, poA.prescriptionDate)
				)),
				tap(() => {
					this.moOrdonnancesIdsByActeGuid = this.isvcOrdonnances.getOrdonnancesIdsByActeGuid(this.maOrdonnances);
					const loActesGuidsByOrdonnanceId = this.isvcOrdonnances.getActesByOrdonnanceId(this.maOrdonnances);
					this.setActesByOrdonnance(loActesGuidsByOrdonnanceId);
					this.refreshOptions();
					this.initSlideActions();
					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.isvcForms.onFormListEvent()
			.pipe(
				tap((poEvent: IFormListEvent) => this.isvcPatients.manageItemOptionEvent(poEvent)),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.moFavoritesClickSubject
			.pipe(
				groupBy((poAct: Acte) => poAct._id),
				mergeMap((poGroupedActs$: GroupedObservable<string, Acte>) => poGroupedActs$
					.pipe(
						throttleTime(500), // On ne throttle que pour les actions répétées sur un même acte.
						mergeMap((poAct: Acte) => this.isvcFavorites.toggle(poAct._id, this.favorites))
					)
				)
			)
			.subscribe();

		this.isvcFavorites.get(C_PREFIX_ACT, true)
			.pipe(
				tap((poFavorites: IFavorites) => {
					this.moFavorites = poFavorites;
					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this.mbIsSmallScreen = window.innerWidth < 700;
		this.hasPatientId = !StringHelper.isBlank(this.traitement.patientId);
		if (!StringHelper.isBlank(this.traitement.patientId))
			this.contactsListModelForPatient = this.traitement.patientId;
		else if (this.params)
			this.contactsListModelForPatient = this.params.patientId;

		this.prepareDateTimeSpinnerParams();

		this.traitement.seances$
			.pipe(
				tap((paSeances: Seance[]) => {
					this.hasValidatedOrCanceledSeancesByActeGuid.clear();
					if (ArrayHelper.hasElements(paSeances)) {
						paSeances.forEach((poSeance: Seance) => {
							if ([EStatusSeance.canceled, EStatusSeance.done].includes(poSeance.status))
								poSeance.actes.forEach((poActe: Acte) => this.hasValidatedOrCanceledSeancesByActeGuid.set(poActe.guid, true));
						});
					}
				}),
				takeUntil(this.destroyed$)
			).subscribe();
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moFavoritesClickSubject.complete();
	}

	public initSlideActions(): void {
		const lsAction: string = ArrayHelper.getFirstElement(this.moParentComponent.getInitializingSlideActions());

		switch (lsAction) {
			case TraitementSlideParentBase.C_ADD_ORDONNANCE_ACTION_ID:
				this.openOrdonnance(undefined);
				break;
		}

		if (!StringHelper.isBlank(lsAction)) {
			this.moParentComponent.removeInitializingSlideActions([lsAction]);
		}
	}

	protected onLifeCycleEvent(poEvent: ILifeCycleEvent): void {
		switch (poEvent.data.value) {

			case ELifeCycleEvent.viewWillEnter:
				this.getParentToolbar().init(this.createToolbarData(), this.instanceId);
				break;

			case ELifeCycleEvent.viewWillLeave:
				this.getParentToolbar().clear(this.instanceId);
				break;

			case ELifeCycleEvent.viewDidEnter:
				break;
		}
	}

	private refreshToolbar(): void {
		this.getParentToolbar().init(this.createToolbarData(), this.instanceId); // On recrée la toolbar car il n'y a peut-être plus d'acte : on bloque les slides.
	}

	private setActesByOrdonnance(poActesByOrdonnancesIds: Map<string, string[]>): void {
		this.maActesByOrdonnance = [];
		const actesWithOrdonnance: Acte[] = [];

		this.maOrdonnances?.forEach((poOrdonnance: Ordonnance) => {
			const laActesGuids: string[] = poActesByOrdonnancesIds.get(poOrdonnance._id) ?? [];
			const laActes: Acte[] = [];
			const loActeByOrdonnance: IActesByOrdonnance = {
				ordonnance: poOrdonnance,
				actes: []
			};

			laActesGuids.forEach((psActeGuid: string) => {
				const loActe: Acte = this.traitement.actes.find((poActe: Acte) => poActe.guid === psActeGuid);
				if (loActe)
					laActes.push(loActe);
			});

			if (ArrayHelper.hasElements(laActes)) {
				const laFilteredSeances: Seance[] = this.traitement.seances.filter((poSeance: Seance) => ArrayHelper.intersection(laActes.map((poActe: Acte) => poActe.guid), poSeance.actes.map((poActe: Acte) => poActe.guid))?.length > 0);
				const loMinAndMaxDates: IMinMaxDates = DateHelper.getMinAndMaxDates(ArrayHelper.flat(laFilteredSeances.map((poSeance: Seance) => [poSeance.startDate, poSeance.endDate])));

				loActeByOrdonnance.startDate = loMinAndMaxDates?.min ?? this.traitement.beginDate;
				loActeByOrdonnance.endDate = loMinAndMaxDates?.max ?? this.traitement.endDate;
				loActeByOrdonnance.actes = laActes;

				actesWithOrdonnance.push(...laActes);
			}
			this.maActesByOrdonnance.push(loActeByOrdonnance);
		});

		this.maActesWithoutOrdonnance = ArrayHelper.getDifferences(this.traitement.actes, actesWithOrdonnance);

		this.detectChanges();
	}

	/** Ouvre une page pour rechercher un acte puis l'ajouter. */
	public openSearchActePage(poLinkedOrdonnance?: Ordonnance): void {
		this.isvcActes.selectActeWithModal(this.traitement)
			.pipe(
				mergeMap((poActe: Acte) => {
					this.editActe(poActe, EModalActeType.Creation, poLinkedOrdonnance);
					if (!ObjectHelper.isNullOrEmpty(poLinkedOrdonnance)) {
						return this.isvcOrdonnances.linkOrdonnancesToActe([poLinkedOrdonnance], poActe.guid);
					}
					return EMPTY;
				}),
				takeUntil(this.destroyed$)
			).subscribe();
	}

	/** Modifie un acte.
	 * @param poActe Acte que l'utilisateur veut modifier.
	 */
	public editActe(poActe: Acte, peModalType: EModalActeType = EModalActeType.Edition, ordonanceLiee: Ordonnance): void {
		const loEditingActe = new Acte(poActe);

		this.isvcModal.open({
			component: ActeEditModalComponent,
			componentProps: {
				acte: loEditingActe,
				traitement: this.traitement,
				ordonnance: ordonanceLiee,
				modalType: peModalType,
				hasValidatedOrCanceledSeancesByActeGuid: this.hasValidatedOrCanceledSeancesByActeGuid
			}
		})
			.pipe(
				tap((pbSave: boolean) => {
					if (pbSave)
						this.saveActeChange(loEditingActe);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Modifie un acte.
	 * @param poActe L'acte que l'utilisateur veut modifier.
	 */
	private saveActeChange(poActe: Acte): void {
		if (this.traitement.actes.some((poItem: Acte) => poItem.guid === poActe.guid))
			ArrayHelper.replaceElementByFinder(this.traitement.actes, (poItem: Acte) => poItem.guid === poActe.guid, poActe);
		else
			this.traitement.actes.push(poActe);

		StoreHelper.makeDocumentDirty(this.traitement);
		this.onTraitementChanged();
		this.detectChanges();
	}

	public onFavoritesClicked(poAct: Acte): void {
		this.moFavoritesClickSubject.next(poAct);
	}

	/** Permet d'afficher toutes les options liées aux actes du traitement. */
	private refreshOptions(): void {
		this.acteOptionsByGuid.clear();

		this.traitement.actes.forEach((poActe: Acte) => {
			const laOptions: IActeOption[] = [];
			const ldDate: Date = poActe.startDate ?? this.traitement.beginDate;

			laOptions.push({ label: DateHelper.transform(ldDate, ETimetablePattern.dd_MM_yyyy_slash) });

			if (this.moPlaceOptionsByValue.has(poActe.place))
				laOptions.push(this.moPlaceOptionsByValue.get(poActe.place));

			const loRecurrence: Recurrence = ArrayHelper.getFirstElement(poActe.recurrences);

			loRecurrence?.dayRepetitions.filter((poRepetition: DayRepetition) => poRepetition instanceof RangeRepetition)
				.forEach((poRepetition: RangeRepetition) => {
					const lsRange: string = this.rangeToString(poRepetition);
					if (this.moRangeOptionsByValue.has(lsRange))
						laOptions.push(this.moRangeOptionsByValue.get(lsRange));
				});

			loRecurrence?.dayRepetitions.filter((poRepetition: DayRepetition) => poRepetition instanceof HoursMinutesRepetition)
				.forEach((poRepetition: HoursMinutesRepetition) => laOptions.push({ label: this.timeToString(poRepetition) }));

			this.addDurationOptions(laOptions, poActe);

			this.acteOptionsByGuid.set(poActe.guid, laOptions.map((poActeOption: IActeOption) => poActeOption.label).join(", "));
		});
	}

	private addDurationOptions(paOptions: IActeOption[], poActe: Acte): void {
		const loRecurrence: Recurrence = ArrayHelper.getFirstElement(poActe.recurrences);

		if (StringHelper.isBlank(loRecurrence?.durationType)) {
			console.warn(`${this.logSourceId}Duration type undefined.`);
			return;
		}

		switch (loRecurrence.durationType) {
			case EDuree.dates:
				paOptions.push({ label: "À dates fixes" });
				(loRecurrence.durationValue as Date[]).forEach((pdDate: Date) => paOptions.push({ label: DateHelper.transform(pdDate, ETimetablePattern.dd_MM_yyyy_slash) }));
				break;

			case EDuree.jour:
				paOptions.push({ label: `Pendant ${loRecurrence.durationValue} jour${typeof loRecurrence.durationValue === 'number' && loRecurrence.durationValue > 1 ? "s" : ""}` });
				this.addRepetitionOptions(paOptions, poActe);
				break;

			case EDuree.mois:
				paOptions.push({ label: `Pendant ${loRecurrence.durationValue} mois` });
				this.addRepetitionOptions(paOptions, poActe);
				break;

			case EDuree.jusquADate:
				paOptions.push({ label: `Jusqu'au ${DateHelper.transform(loRecurrence.durationValue[0], ETimetablePattern.dd_MM_yyyy_slash)}` });
				break;

			default:
				console.warn(`${this.logSourceId}Duration type '${loRecurrence.durationType}' unknown.`);
				break;
		}
	}

	private addRepetitionOptions(paOptions: IActeOption[], poActe: Acte): void {
		const loRecurrence: Recurrence = ArrayHelper.getFirstElement(poActe.recurrences);

		if (StringHelper.isBlank(loRecurrence?.repetitionType)) {
			console.warn(`${this.logSourceId}Repetition type undefined.`);
			return;
		}

		switch (loRecurrence.repetitionType) {
			case ERepetition.jours:
				(loRecurrence.repetitionValue as number[]).forEach((pnValue: number) => paOptions.push(this.moDayOptionsByValue.get(pnValue)));
				break;

			case ERepetition.tsLesXJours:
				paOptions.push({ label: `Tous les ${loRecurrence.repetitionValue} jour${typeof loRecurrence.repetitionValue === 'number' && loRecurrence.repetitionValue > 1 ? "s" : ""}` });
				break;

			case ERepetition.tsLesXMois:
				paOptions.push({ label: `Tous les ${loRecurrence.repetitionValue} mois` });
				break;

			case ERepetition.xFoisParMois:
				paOptions.push({ label: `${loRecurrence.repetitionValue} fois par mois` });
				break;

			default:
				console.warn(`${this.logSourceId}Repetition type '${loRecurrence.repetitionType}' unknown.`);
				break;
		}
	}

	private rangeToString(poRangeRepetition: IRange<IHoursMinutes>): string {
		return !poRangeRepetition ? "" : `${this.timeToString(poRangeRepetition.from)}-${this.timeToString(poRangeRepetition.to)}`;
	}

	private timeToString(poTimeRepetition: IHoursMinutes): string {
		return !poTimeRepetition ? "" : `${this.ioDecimalPipe.transform(poTimeRepetition.hours, "2.0")}h${this.ioDecimalPipe.transform(poTimeRepetition.minutes, "2.0")}`;
	}

	public linkOrdonnance(poActe: Acte): void {
		const loOrdonnanceIdsByActeGuid: Map<string, string[]> = this.isvcOrdonnances.getOrdonnancesIdsByActeGuid(this.maOrdonnances);

		this.selectOrdonnance(this.traitement, this.maOrdonnances, loOrdonnanceIdsByActeGuid.get(poActe.guid))
			.pipe(
				mergeMap((paSelectedOrdonnances: Ordonnance[]) => this.isvcOrdonnances.updateOrdonnancesLinksToActe(this.maOrdonnances, paSelectedOrdonnances, poActe, loOrdonnanceIdsByActeGuid)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public selectOrdonnance(poTrairement: Traitement, paOrdonnances: Ordonnance[], paPreselectedOrdonnancesIds: string[]): Observable<Ordonnance[]> {
		return this.isvcOrdonnancesGallerySelectorModalOpener.open(poTrairement, paOrdonnances, paPreselectedOrdonnancesIds, true);
	}

	public changeActe(poActe: Acte): void {
		if (this.isvcActes.canChangeActe(poActe._id)) {
			this.isvcActes.selectActeWithModal(this.traitement)
				.pipe(
					map((poNewActe: Acte) => {
						const lnIndex: number = this.traitement.actes.indexOf(poActe);
						let loLinkedOrdonnance: Ordonnance;

						if (poActe) {
							this.isvcTraitement.replaceActe(this.traitement, this.traitement.seances, lnIndex, poActe, poNewActe);

							this.maOrdonnances.forEach((poOrdonnance: Ordonnance) => {
								if (poOrdonnance.linkedActesGuids.includes(poActe.guid)) {
									ArrayHelper.removeElement(poOrdonnance.linkedActesGuids, poActe.guid);
									poOrdonnance.linkedActesGuids.push(poNewActe.guid);
									loLinkedOrdonnance = poOrdonnance;
								}
							});

							this.onTraitementChanged();
							this.detectChanges();
						}
						else
							console.error("Impossible de remplacer l'acte si l'acte source est non défini.");

						return loLinkedOrdonnance;
					}),
					filter((poOrdonnance: Ordonnance) => !ObjectHelper.isNullOrEmpty(poOrdonnance)),
					mergeMap((poOrdonnance: Ordonnance) => this.isvcOrdonnances.saveOrdonnance(this.traitement, poOrdonnance)),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		};
	}

	/** Supprime un acte de la liste des actes.
	 * @param poActe L'acte à supprimer.
	 */
	public deleteActe(poActe: Acte): void {
		this.displayDeleteActeMessage(this.traitement.seances, poActe);
	}

	private displayDeleteActeMessage(paSeances: Seance[], poActe: Acte): void {
		const lsActeGuid: string = poActe.guid;
		const lnValidatedSeances: number = paSeances.filter((poSeance: Seance) =>
			poSeance.actes.some((poItem: Acte) => poItem.guid === lsActeGuid) && poSeance.isPlanned()
		).length;
		let loMessageParams: ShowMessageParamsPopup | ShowMessageParamsToast;

		if (lnValidatedSeances > 0) {
			loMessageParams = new ShowMessageParamsToast({
				message: `Impossible de supprimer cet acte, il est lié à ${lnValidatedSeances} ${lnValidatedSeances > 1 ? "séances validées" : "séance validée"}.`
			});
		}
		else {
			loMessageParams = new ShowMessageParamsPopup({
				message: "Voulez-vous supprimer cet acte ?",
				header: "Suppression d'acte",
				buttons: [
					{ text: "Annuler" },
					this.getDeleteActeButton(poActe)
				] as AlertButton[]
			});
		}

		this.isvcUiMessage.showMessage(loMessageParams);
	}

	private getDeleteActeButton(poActeToRemove: Acte): AlertButton {
		return {
			text: "Supprimer",
			cssClass: "deleteButton",
			handler: () => this.innerDeleteActe(poActeToRemove)
		};
	}

	@LogAction<Parameters<PrescriptionsComponent["innerDeleteActe"]>, ReturnType<PrescriptionsComponent["innerDeleteActe"]>>({
		actionId: EIdlLogActionId.acteDelete,
		successMessage: "Suppression de l'acte.",
		errorMessage: "Echec de la suppression de l'acte.",
		dataBuilder: (poThis: PrescriptionsComponent, _, poActeToRemove: Acte) => ({ userId: UserData.current?._id, traitementId: poThis.traitement._id, acteId: poActeToRemove._id })
	})
	private async innerDeleteActe(poActeToRemove: Acte): Promise<void> {
		if (this.moOrdonnancesIdsByActeGuid.has(poActeToRemove.guid)) {
			const laOrdonnancesToSave: Ordonnance[] = [];
			this.maOrdonnances.forEach((poOrdonnance: Ordonnance) => {
				if (poOrdonnance.linkedActesGuids.includes(poActeToRemove.guid)) {
					ArrayHelper.removeElement(poOrdonnance.linkedActesGuids, poActeToRemove.guid);
					laOrdonnancesToSave.push(poOrdonnance);
				}
			});
			await this.isvcOrdonnances.saveOrdonnances(laOrdonnancesToSave).toPromise();
		}
		ArrayHelper.removeElementByFinder(this.traitement.actes, (poActe: Acte) => poActe.guid === poActeToRemove.guid);
		StoreHelper.makeDocumentDirty(this.traitement);
		// S'il n'y a plus d'acte, il n'y a plus de séance possible donc on fournit un tableau vide, sinon on ne fournit rien (`undefined`).
		this.onTraitementChanged();
		this.detectChanges();
	}

	public async openOrCloseItemSliding(poItemSliding: IonItemSliding, poEvent?: MouseEvent): Promise<void> {
		if (poEvent)
			poEvent.stopPropagation();

		const lnAmountOpenPixels: number = await poItemSliding.getOpenAmount();

		if (lnAmountOpenPixels > 0)
			poItemSliding.close();
		else
			poItemSliding.open("end");
	}

	@HostListener('window:resize')
	public onResize(): void {
		this.mbIsSmallScreen = window.innerWidth < 700;
	}

	/** Supprime l'ordonnance.
	 * @param poOrdonnance
	 */
	public deleteOrdonnance(poOrdonnance: Ordonnance): Promise<boolean> {
		return this.isvcOrdonnances.deleteOrdonnance(poOrdonnance).toPromise();
	}

	/** Initialise les paramètres pour les différents dateTimeSpinner du composant. */
	private prepareDateTimeSpinnerParams(): void {
		const leDisplayFormat = ETimetablePattern.dd_MM_yyyy_slash;

		this.dateTimeSpinnerParamsForBreakDate = {
			label: "Date d'interruption :",
			displayFormat: leDisplayFormat,
			min: this.traitement.beginDate.toISOString(),
			max: this.traitement.endDate.toISOString(),
			dateTimeCss: "edit-color",
			iconCss: "edit-color",
			iconSlot: "end",
			pickerMode: EDateTimePickerMode.date
		};
	}

	/** @override */
	protected createToolbarData(): Array<IBarElement> {
		const lsCircle = "circle";
		const lsFabButton = "fabButton";

		return [
			{
				id: lsCircle,
				component: lsFabButton,
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.right,
				icon: "arrow-forward",
				onTap: this.mfNextSlide,
				options: {
					isDisable: this.moParentComponent.manageBlockingSlides(),
				},
				name: "Suivant"
			}
		];
	}

	/** Gestion des changements aux niveaux des listes de contacts (factorisation comportement Patient/Precripteur).
	 * @param paSelectedContacts Tableau des contacts sélectionnés.
	 * @param psCurrentContactId Identifiant du contact courant.
	 * @param pfTraitementSetter Fonction qui modifie le patient ou le prescripteur du traitement par le(s) nouveau(x).
	 * @returns `true` si un contact est sélectionné par l'utilisateur.
	 */
	public onContactsListModelChanged(paSelectedContacts: Array<string>, psCurrentContactId: string, pfTraitementSetter: (psId: string) => void): boolean {
		let lsFirstContactId: string;
		let lbHasValue: boolean;

		if (ArrayHelper.hasElements(paSelectedContacts)) { // Si au moins un contact est sélectionné,
			// On affecte l'id du prescripteur de la prescription à l'id du contact sélectionné.
			lsFirstContactId = ArrayHelper.getFirstElement(paSelectedContacts);
			lbHasValue = true;
		}
		else { // Aucun contact n'est sélectionné, on réinitialise l'id du prescripteur de la prescription.
			lsFirstContactId = "";
			lbHasValue = false;
		}

		if (lsFirstContactId !== psCurrentContactId) {
			pfTraitementSetter(lsFirstContactId);
			this.onTraitementChanged();
		}

		return lbHasValue;
	}

	public stopTraitement(): void {
		from(this.isvcModal.open<IInterruption>({
			component: InterruptionModalComponent,
			componentProps: { dateTimeSpinnerParams: this.dateTimeSpinnerParamsForBreakDate }
		}))
			.pipe(
				filter((poInterruption: IInterruption) => !!poInterruption),
				switchMap((poInterruption: IInterruption) => this.traitement.seances$
					.pipe(
						take(1),
						mergeMap((paSeances: Seance[]) =>
							this.isvcTraitement.stopTraitement(this.traitement, paSeances, poInterruption.breakDate, poInterruption.explanation)
								.pipe(mapTo(paSeances))
						)
					)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public restartTraitement(): void {
		this.isvcTraitement.reactivateTraitement(this.traitement, this.traitement.seances).subscribe();
	}

	/** Appelée lors de la modification de la selection des tags du traitement.
	 * @param paTags Nouvelle liste de tags.
	 */
	public onTagsChanged(paTags: ETraitementTags[]): void {
		if (!ArrayHelper.areArraysEqual(this.traitement.tags, paTags)) {
			this.traitement.tags = paTags;
			StoreHelper.makeDocumentDirty(this.traitement);
			this.onTraitementChanged();
		}
	}

	/** Appelée lors de la modification de la selection de la pathologie du traitement.
	 * @param paPathologies Nouvelle liste de pathologie.
	 */
	public onPathologieChanged(paPathologies: EPathologie[]): void {
		if (!ArrayHelper.areArraysEqual(this.traitement.pathologies, paPathologies)) {
			this.isvcTraitement.onTraitementPathologiesChanged(this.traitement, this.traitement.seances, paPathologies).pipe(
				tap(
					() => {
						StoreHelper.makeDocumentDirty(this.traitement);
						this.onTraitementChanged();
					},
					(poError: any) => {
						this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: "Erreur lors de la sauvegarde des pathologies." }));
						console.error(`${PrescriptionsComponent.C_LOG_ID}Error during pathologies save.`, poError);
					})
			).subscribe();
		};
	}

	/** Récupère la liste des données utiles à l'affichage pour chacune des contraintes.
	 * @param paConstraints
	 */
	private getConstraintItems(paConstraints: Constraint[]): Observable<IConstraintItem[]> {
		let laIntervenantsIds: string[] = [];
		let laGroupsIds: string[] = [];

		paConstraints = paConstraints.sort((poConstraintA: Constraint, poConstraintB: Constraint) => DateHelper.compareTwoDates(poConstraintB.createDate, poConstraintA.createDate));

		paConstraints.forEach((poConstraint: Constraint) => {
			const laAllIds: string[] = ArrayHelper.unique(poConstraint.getIntervenantsIds());
			laIntervenantsIds.push(...[...laAllIds.filter((psId: string) => IdHelper.getPrefixFromId(psId) === EPrefix.contact), poConstraint.createUserContactId]);
			laGroupsIds.push(...laAllIds.filter((psId: string) => IdHelper.getPrefixFromId(psId) === EPrefix.group));
		});

		laIntervenantsIds = ArrayHelper.unique(laIntervenantsIds);
		laGroupsIds = ArrayHelper.unique(laGroupsIds);

		return this.isvcContacts.getContactsByIds(laIntervenantsIds)
			.pipe(
				tap((paContacts: IContact[]) => paContacts.forEach((poContact: IContact) => this.moContactsById.set(poContact._id, poContact))),
				mergeMap(() => this.isvcGroups.getGroups(laGroupsIds)),
				tap((paGroups: IGroup[]) => paGroups.forEach((poGroup: IGroup) => this.moGroupsById.set(poGroup._id, poGroup))),
				map(() => {
					const laContactsAndGroups: Array<IContact | IGroup> = [...MapHelper.valuesToArray(this.moContactsById), ...MapHelper.valuesToArray(this.moGroupsById)];

					return paConstraints
						.map((poConstraint: Constraint) => {
							return {
								label: poConstraint.getLabel({ intervenantsAndGroups: laContactsAndGroups }),
								creatorAvatar: this.getAvatar(poConstraint.createUserContactId),
								createDate: poConstraint.createDate
							} as IConstraintItem;
						});
				})
			);
	}

	/** Récupère l'avatar du contact.
	 * @param psContactId
	 */
	private getAvatar(psContactId: string): IAvatar {
		return ContactsService.createContactAvatar(this.moContactsById.get(psContactId));
	}

	public openOrdonnance(poOrdonnance?: Ordonnance): void {
		defer(() => {
			if (poOrdonnance)
				return this.isvcOrdonnances.editOrdonnance(this.traitement, poOrdonnance);
			return this.isvcOrdonnances.createNewOrdonnance(this.traitement, this.maOrdonnances);
		})
			.pipe(
				tap(() => {
					if (StoreHelper.isDocumentDirty(this.traitement))
						this.onTraitementChanged();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public traitementEndsSoon(): boolean {
		return this.traitement.seances?.length > 0 ? this.traitement.endsSoon : false;
	}

	//#endregion
}
