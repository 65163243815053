import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@osapp/services';
import { mergeMap, tap } from 'rxjs/operators';
import { AMCP } from '../../../../../modules/patients/model/amc-p';
import { IConvention } from '../../../../../modules/patients/model/iconvention';
import { CouverturesService } from '../../../../../modules/patients/services/couvertures.service';
import { DrawerPopoverService } from '../../../shared/services/drawer-popover.service';
import { PanneauService } from '../../../shared/services/panneau.service';

@Component({
	selector: 'di-choix-convention',
	templateUrl: './choix-convention.component.html',
	styleUrls: ['./choix-convention.component.scss'],
})
export class ChoixConventionComponent implements OnInit {

	@Input() listConventions: IConvention[];
	@Input() patientId: string;
	@Input() couvertureAMC: AMCP;

	conventionSelected: IConvention;

	constructor(
		private svcDrawerPopover: DrawerPopoverService,
		private svcCouverture: CouverturesService,
		private svcStore: Store,
		private svcPanneau: PanneauService

	) { }

	ngOnInit() { }

	public saveConvention() {
		this.couvertureAMC.idConvention = this.conventionSelected.idtConvention;
		this.couvertureAMC.convention = this.conventionSelected;
		this.svcStore.put(this.couvertureAMC).pipe(
			mergeMap(() => this.svcCouverture.selectionnerConvention(this.patientId, this.conventionSelected.idtConvention)
			),
			tap(() => {
				this.svcDrawerPopover.close();
				this.svcPanneau.close();
			})
		).subscribe();
	}

	public close() {
		this.svcDrawerPopover.close();
		this.svcPanneau.close();
	}

	public selectConvention(conv: IConvention) {
		this.conventionSelected = conv
	}

}
