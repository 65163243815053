import { InjectionToken } from '@angular/core';
import { RouteComponent } from './model/RouteComponent';

export const ROUTE_COMPONENTS = new InjectionToken<RouteComponent[]>("osapp.routeComponents");

export const POPUP_LOST_MODIFICATION_MESSAGE = "Des modifications ont été réalisées. Si vous continuez, les données modifiées seront perdues. Voulez-vous vraiment quitter ?"

export const POPUP_LOST_MODIFICATION_HEADER = "Modifications non enregistrées"

export const POPUP_LOST_MODIFICATION_BUTTON_STAY = "Rester"

export const POPUP_LOST_MODIFICATION_BUTTON_LEAVE = "Quitter"