import { IRange } from '../modules/utils/models/models/irange';
import { StringHelper } from './stringHelper';

/** Permet de mettre à disposition des méthodes pour aider à manipuler des nombres. */
export abstract class NumberHelper {

	//#region METHODS

	/** Détermine si un nombre est valide ou non (valide si c'est un nombre de type `nombre` non égal à `NaN`).
	 * @param poData Donnée à déterminer si elle est un nombre valide ou non.
	 */
	public static isValid(poData: any): boolean {
		return !isNaN(poData) && typeof poData === "number";
	}

	/** Retourne `true` si le nombre est un nombre valide sous forme de chaîne de caractère, `false` si ce n'est pas le cas.
	 * @param poNumber Nombre à déterminer s'il est sous forme de string ou non.
	 */
	public static isStringNumber(poNumber: number | string): boolean {
		// On doit vérifier que la chaîne n'est pas vide car +"" === 0 ; ce qui fausse le résultat.
		return typeof poNumber === "string" && !StringHelper.isBlank(poNumber) && this.isValid(+poNumber);
	}

	/** Retourne `true` si le nombre est valide et positif (0 compris), `false` sinon.
	 * @param pnNumber Nombre à déterminer s'il est un nombre positif valide.
	 */
	public static isValidPositive(pnNumber: number): boolean {
		return this.isValid(pnNumber) && pnNumber >= 0;
	}

	/** Retourne `true` si le nombre est valide et strictement positif, `false` sinon.
	 * @param pnNumber Nombre à déterminer s'il est un nombre valide strictement positif.
	 */
	public static isValidStrictPositive(pnNumber: number): boolean {
		return this.isValid(pnNumber) && pnNumber > 0;
	}

	/** Retourne un nombre entier aléatoire appartenant à un intervalle donné.
	 * @param pnEndInterval Nombre entier maximum, exclus, que la valeur retournée peut avoir.
	 * @param pnStartInterval Nombre entier minimum, inclus, que la valeur retournée peut avoir, par défaut : 0.
	 */
	public static getRandomInteger(pnEndInterval: number, pnStartInterval: number = 0): number {
		return Math.floor(Math.random() * (Math.floor(pnEndInterval) - Math.ceil(pnStartInterval))) + Math.ceil(pnStartInterval);
	}

	/** Met à jour les valeurs minimales et maximales avec en fonctions des valeurs issus d'un tableau. */
	public static updateMinMax<T>(paObjects: Array<T>, pfValue: (poValue: T) => number, pnStartMin?: number, psStartMax?: number): { min: number, max: number } {
		// On affecte des valeurs par défaut si minDb et maxDb sont indéfinis (première update).
		const loResult: { min: number, max: number } = { min: pnStartMin, max: psStartMax };

		// Si aucune valeur, on retourne les valeurs par défaut passées en paramètre.
		if (paObjects.length === 0)
			return loResult;

		// Si les valeurs par défaut ne sont pas définis, on leur donne la valeur du premier élément.
		if (loResult.min === undefined)
			loResult.min = pfValue(paObjects[0]);

		if (loResult.max === undefined)
			loResult.max = pfValue(paObjects[0]);

		/** Valeur courante de l'objet parcourue. */
		let lnCurrentValue: number;

		// Récupération des valeurs min et max.
		paObjects.forEach((poObject: T) => {
			lnCurrentValue = pfValue(poObject);

			if (loResult.min > lnCurrentValue)
				loResult.min = lnCurrentValue;
			if (loResult.max < lnCurrentValue)
				loResult.max = lnCurrentValue;
		});

		return loResult;
	}

	/** Retourne la valeur de poValue par rapport à pnMin et pnMax.
	 * @returns Un nombre entre 0 et 1 si `pnValue` est entre `pnMin` et `pnMax`.
	 */
	public static getRatio(pnMin: number, pnMax: number, pnValue: number): number {
		if (pnMax === pnMin)	// Évite une division par 0.
			return 0.5;
		return ((pnValue - pnMin) / (pnMax - pnMin));
	}

	public static getPercentage(pnMin: number, pnMax: number, pnValue: number): number {
		return Math.round(NumberHelper.getRatio(pnMin, pnMax, pnValue) * 100);
	}

	/** Ajoute deux nombres entre eux et retourne le résultat ; renvoie `NaN` si les deux valent `NaN` sinon renvoie la valeur du nombre défini.
	 * @param pnNumber1 Premier nombre qu'il faut ajouter au second.
	 * @param pnNumber2 Second nombre qu'il faut ajouter au premier.
	 */
	public static addTwoNumbers(pnNumber1: number, pnNumber2: number): number {
		if (this.isValid(pnNumber1))
			return this.isValid(pnNumber2) ? pnNumber1 + pnNumber2 : pnNumber1;
		else
			return this.isValid(pnNumber2) ? pnNumber2 : NaN;
	}

	/** Soustrait deux nombres et retourne le résultat.
	 * renvoie `NaN` si les deux valent `NaN` sinon renvoie la valeur du nombre défini (négatif si c'est le second nombre qui est défini).
	 * @param pnNumber1 Nombre à partir duquel soustraire le second.
	 * @param pnNumber2 Nombre qu'il faut soustraire au premier.
	 */
	public static substractTwoNumbers(pnNumber1: number, pnNumber2: number): number {
		if (this.isValid(pnNumber1))
			return this.isValid(pnNumber2) ? pnNumber1 - pnNumber2 : pnNumber1;
		else
			return this.isValid(pnNumber2) ? (-pnNumber2) : NaN;
	}

	/** Calcul et retourne un pourcentage d'une valeur par rapport à une autre ; retourne `NaN` si calcul impossible (division avec `NaN` ou division par `0`).
	 * @param pnValue Nombre avec lequel calculer le pourcentage (numérateur).
	 * @param pnReferenceValue Nombre de référence avec lequel calculer le pourcentage (diviseur).
	 * @param pnDigits Nombre de décimales après la virgule, `2` par défaut.
	 */
	public static calculatePercentage(pnValue: number, pnReferenceValue: number, pnDigits: number = 2): number {
		return NumberHelper.isValid(pnReferenceValue) && pnReferenceValue !== 0 && NumberHelper.isValid(pnValue) ?
			+(((pnValue / pnReferenceValue) - 1) * 100).toFixed(pnDigits) : NaN;
	}

	/** Ajoute chaque nombre du tableau à une valeur initiale et retourne le résultat.
	 * @param poData Tableau de nombres à réduire en ajoutant les valeurs entre elles.
	 * @param pnInitialValue Valeur initiale à partir de laquelle ajouter les nombres du tableau.
	 */
	public static reduceNumbers(poData: number[], pnInitialValue: number): number {
		return poData.reduce((pnPrevious: number, pnCurrent: number) => this.addTwoNumbers(pnPrevious, pnCurrent), pnInitialValue);
	}

	/** Indique si une valeur est compris dans une plage de valeur
	 * @param pnValue
	 * @param poRange
	 * @example
	 * isInRange(5, {from: 3, to: 6}) // true
	 * isInRange(5, {from: 6, to: 6}) // false
	 * isInRange(5, {from: 6, to: 4}) // false
	 * isInRange(5, {from: 6}) // false
	 * isInRange(5, {from: 3}) // true
	 * isInRange(5, {}) // true
	 */
	public static isInRange(pnValue: number, poRange: IRange<number>): boolean {
		return (!NumberHelper.isValid(poRange.from) || poRange.from <= pnValue) && (!NumberHelper.isValid(poRange.to) || (pnValue <= poRange.to));
	}

	/** Arrondie un nombre à un nombre de chiffre après la virgule.
	 * @param price Prix à transformer.
	 * @param trunkValue Nombre de décimale.
	 */
	public static round(price: number | string, trunkValue: number): string {
		if ((NumberHelper.isStringNumber(price) || NumberHelper.isValid(price)) && NumberHelper.isValidPositive(trunkValue))
			return (+(Math.round(Number(price + `e+${trunkValue}`)) + `e-${trunkValue}`)).toFixed(trunkValue);
		return "";
	}
	//#endregion
}