import { ETaskPrefix } from '../model/backgroundTask/ETaskPrefix';
import { EPrefix } from '../model/EPrefix';
import { ESuffix } from '../model/ESuffix';
import { ArrayHelper } from './arrayHelper';
import { DateHelper } from './dateHelper';
import { GuidHelper } from './guidHelper';
import { NumberHelper } from './numberHelper';
import { ObjectHelper } from './objectHelper';
import { StringHelper } from './stringHelper';

interface IPrefixAndGuidParts {
	prefixes: string[];
	guids: string[];
}

export abstract class IdHelper {

	//#region PROPERTIES

	public static readonly C_ID_SEPARATOR = "_";
	public static readonly C_VIRTUAL_NODE_SEPARATOR = "~";
	public static readonly C_ID_DATE_PART_REGEX = /^\d{4,17}$/;

	//#endregion

	//#region METHODS

	/** Récupère le guid depuis un identifiant.
	 * @param psId Identifiant qui contient le guid à récupérer.
	 * @param pePrefix Préfixe à utiliser pour récupérer le guid.
	 */
	public static getGuidFromId(psId: string, pePrefix: EPrefix): string {
		return ArrayHelper.getLastElement(psId?.split(pePrefix)) ?? "";
	}

	/** Extrait le dernier GUID d'un identifiant (composé ou non), chaîne vide si identifiant non valide, l'identifiant entier s'il ne comporte pas de `_`.
	 * @param psId Identifiant composé où extraire le dernier identifiant.
	 */
	public static getLastGuidFromId(psId: string): string {
		return StringHelper.isBlank(psId) ? "" : ArrayHelper.getLastElement(psId.split(IdHelper.C_ID_SEPARATOR)) ?? "";
	}

	/** Extrait le dernier GUID d'un identifiant (composé ou non), chaîne vide si identifiant non valide, l'identifiant entier s'il ne comporte pas de `_`.
	 * @param psId Identifiant composé où extraire le dernier identifiant.
	 */
	public static getLastGuidFromVirtualNode(psId: string): string {
		if (StringHelper.isBlank(psId) || !psId.includes(IdHelper.C_VIRTUAL_NODE_SEPARATOR))
			return "";

		return ArrayHelper.getFirstElement((ArrayHelper.getLastElement(psId.split(IdHelper.C_VIRTUAL_NODE_SEPARATOR)) ?? "").split(IdHelper.C_ID_SEPARATOR));
	}

	/** Construit un identifiant à partir d'un préfixe et d'un guid s'il est renseigné.
	 * @param pePrefix Préfixe à utiliser pour construire l'identifiant.
	 * @param psSubId Identifiant (partiel ou complet) à utiliser pour construire l'identifiant, optionnel (génère un nouveau guid si non renseigné).
	 */
	public static buildId(pePrefix: EPrefix | ETaskPrefix, psSubId: string = ""): string {
		const lsGuid: string = StringHelper.isBlank(psSubId) ? GuidHelper.newGuid() : psSubId;

		return lsGuid.startsWith(pePrefix) ? lsGuid : pePrefix + lsGuid;
	}

	/** Construit un identifiant à partir d'un préfixe, de l'id de l'entité parent dont il est lié et d'un guid s'il est renseigné.
	 * @param pePrefix Préfixe à utiliser pour construire l'identifiant.
	 * @param psParentId Identifiant de l'entité parent.
	 * @param psSubId Identifiant (partiel ou complet) à utiliser pour construire l'identifiant, optionnel (génère un nouveau guid si non renseigné).
	 */
	public static buildChildId(pePrefix: EPrefix, psParentId: string, psSubId?: string): string {
		const lsSubId: string = !ObjectHelper.isDefined(psSubId) ? GuidHelper.newGuid() : psSubId; // Si un subId vide est passé même vide on le prend en compte.
		return `${pePrefix}${psParentId}${StringHelper.isBlank(lsSubId) ? "" : IdHelper.C_ID_SEPARATOR}${lsSubId}`;
	}

	/** Construit une partie d'identifiant qui représentera un lien virtuel entre 2 entités qui n'ont pas de lien d'origine.
	 * Permet l'intégration d'une date en plus des identifiants. La position de la date dans le noeud virtuel est celle dans le tableau. (ex: prefix1-prefix2_guid1-DATE-guid2)
	 * @param paIds
	 */
	public static buildVirtualNode(paIds: string[]): string {
		let lsPrefixesPart = "";
		let lsGuidsPart = "";

		paIds.forEach((psId: string) => {
			if (!StringHelper.isBlank(psId)) {
				const lsPrefixPart: string = this.getPrefixFromId(psId).replace(IdHelper.C_ID_SEPARATOR, "");
				const lsGuidPart: string = this.getLastGuidFromId(psId);
				lsPrefixesPart = `${lsPrefixesPart}${StringHelper.isBlank(lsPrefixPart) ? "" : `${StringHelper.isBlank(lsPrefixesPart) ? "" : IdHelper.C_VIRTUAL_NODE_SEPARATOR}${lsPrefixPart}`}`;
				lsGuidsPart = `${lsGuidsPart}${StringHelper.isBlank(lsGuidPart) ? "" : `${StringHelper.isBlank(lsGuidsPart) ? "" : IdHelper.C_VIRTUAL_NODE_SEPARATOR}${lsGuidPart}`}`;
			}
		}, "");

		return `${lsPrefixesPart}_${lsGuidsPart}`;
	}

	/** Retourne les dates contenues dans un identifiant (format reverse date uniquement).
	 * @param psId
	 */
	public static extractDatesFromId(psId: string): Date[] {
		const laDates: Date[] = [];
		IdHelper.extractDatesStringsFromId(psId).forEach((psPart: string) => {
			if (IdHelper.C_ID_DATE_PART_REGEX.test(psPart)) {
				const ldParsed: Date = DateHelper.parseReverseDate(psPart);

				laDates.push(ldParsed);
			}
		});

		return laDates;
	}

	/** Retourne les dates contenues dans un identifiant (format reverse date uniquement).
	 * @param psId
	 */
	public static extractDatesStringsFromId(psId: string): string[] {
		return psId.split(/~|_/).filter((psPart: string) => IdHelper.C_ID_DATE_PART_REGEX.test(psPart));
	}

	/** Extrait tous les identifiants contenus dans un identifiant au format prefixe_id.
	 * @param psId
	 */
	public static extractAllIds(psId: string): string[] {
		const loPrefixAndGuidParts: IPrefixAndGuidParts = this.getPrefixAndGuidParts(psId);

		return this.gatherPrefixesAndGuids(loPrefixAndGuidParts.prefixes, loPrefixAndGuidParts.guids);
	}

	private static getIdParts(psId: string): string[] {
		return psId?.split(IdHelper.C_ID_SEPARATOR) ?? [];
	}

	private static getPrefixAndGuidParts(psId: string): IPrefixAndGuidParts {
		const laIdParts: string[] = this.getIdParts(psId);
		const lnSplitIndex: number = Math.ceil(laIdParts.length / 2);
		const laPrefixes: string[] = laIdParts.slice(0, lnSplitIndex);
		const laGuids: string[] = laIdParts.slice(lnSplitIndex);

		return { prefixes: laPrefixes, guids: laGuids };
	}

	/** Met en relation un tableau de préfixes et un tableau de guid.
	 * @param paPrefixes
	 * @param paGuids
	 */
	private static gatherPrefixesAndGuids(paPrefixes: string[], paGuids: string[]): string[] {
		const laIds: string[] = [];
		let lsPreviousId: string;
		let lnGuidsIndex = 0;
		for (let lnPrefixIndex = paPrefixes.length - 1; lnPrefixIndex > 0; lnPrefixIndex--) {
			const lsPrefix: string = paPrefixes[lnPrefixIndex];
			const lsGuid: string = paGuids[lnGuidsIndex++];

			const lsCurrentId = `${lsPrefix}_${lsPreviousId ? `${lsPreviousId}_` : ""}${lsGuid}`;
			if (lsPrefix.includes(IdHelper.C_VIRTUAL_NODE_SEPARATOR)) { // On est en présence d'un noeud virtuel
				const laVirtualNodePrefixes = lsPrefix.split(IdHelper.C_VIRTUAL_NODE_SEPARATOR);
				const laVirtualNodeGuids = lsGuid.split(IdHelper.C_VIRTUAL_NODE_SEPARATOR).filter((psPart: string) => {
					try {
						return !this.C_ID_DATE_PART_REGEX.test(psPart);// On exclut les dates du découpage de noeud virutel.
					}
					catch (poError) {
						return true;
					}
				});
				for (let lnVirtualNodePrefixIndex = laVirtualNodePrefixes.length - 1; lnVirtualNodePrefixIndex >= 0; lnVirtualNodePrefixIndex--) {
					const lsVirutalNodePrefix: string = laVirtualNodePrefixes[lnVirtualNodePrefixIndex];
					laIds.unshift(
						`${lsVirutalNodePrefix}_${lnVirtualNodePrefixIndex === 0 && lsPreviousId ? `${lsPreviousId}_` : ""}${laVirtualNodeGuids[lnVirtualNodePrefixIndex]}`
					);
				}
			}
			else
				laIds.unshift(lsCurrentId);

			lsPreviousId = lsCurrentId;
		}

		return laIds;
	}

	public static removeDateFromId(psValue: string): string {
		if (psValue.includes(IdHelper.C_VIRTUAL_NODE_SEPARATOR)) {
			const laVirtualNodeGuids = psValue.split(IdHelper.C_VIRTUAL_NODE_SEPARATOR).filter((psPart: string) => {
				try {
					return !this.C_ID_DATE_PART_REGEX.test(psPart);
				}
				catch (poError) {
					return true;
				}
			});
			return laVirtualNodeGuids.join(IdHelper.C_VIRTUAL_NODE_SEPARATOR);
		}
		return psValue;
	}

	/** Extrait l'identifiant de l'entité parent.
	 * @param psIdWithChildId Identifiant dont il faut extraire l'id de l'entité parent.
	 */
	public static extractParentId(psIdWithChildId: string): string {
		if (StringHelper.isBlank(psIdWithChildId))
			return "";

		const lnStartIndex: number = psIdWithChildId.indexOf(IdHelper.C_ID_SEPARATOR);
		const lnEndIndex: number = psIdWithChildId.lastIndexOf(IdHelper.C_ID_SEPARATOR);

		if (lnStartIndex === lnEndIndex)
			return "";

		return psIdWithChildId.substring(lnStartIndex + 1, lnEndIndex);
	}

	/** Extrait un identifiant sans son préfixe, retourne une chaîne vide si l'identifiant est nul, retourne l'identifiant tel quel si le préfixe n'est pas présent.
	 * @param psId Identifiant qu'il faut extraire sans le préfixe.
	 * @param pePrefix Préfixe de l'identifiant à supprimer.
	 */
	public static extractIdWithoutPrefix(psId: string, pePrefix: EPrefix): string {
		return StringHelper.isBlank(psId) ? "" : ArrayHelper.getLastElement(psId.split(pePrefix));
	}

	/** Extrait un identifiant sans son suffixe, retourne une chaîne vide si l'identifiant est nul ou si le suffixe n'est pas présent dans l'identifiant.
	 * @param psId Identifiant qu'il faut extraire sans le suffixe.
	 * @param peSuffix Suffixe de l'identifiant à supprimer.
	 */
	public static extractIdWithoutSuffix(psId: string, peSuffix: ESuffix): string {
		return StringHelper.isBlank(psId) ? "" : ArrayHelper.getFirstElement(psId.split(peSuffix));
	}

	/** Extrait un identifiant sans son préfixe et sans son suffixe, retourne une chaîne nulle si l'identifiant est nul ou si le suffixe n'est pas présent
	 * dans l'identifiant, une chaîne sans suffixe si le préfixe n'est pas présent dans l'identifiant.
	 * @param psId Identifiant dont il faut supprimer le préfixe et le suffixe.
	 * @param pePrefix Préfixe de l'identifiant à supprimer.
	 * @param peSuffix Suffixe de l'identifiant à supprimer.
	 */
	public static extractIdWithoutPrefixAndSuffix(psId: string, pePrefix: EPrefix, peSuffix: ESuffix): string {
		return StringHelper.isBlank(psId) ? "" : this.extractIdWithoutSuffix(this.extractIdWithoutPrefix(psId, pePrefix), peSuffix);
	}

	/** Extrait le timestamp à la fin d'un identifiant d'un document pouch (séparé par un '_') et le retourne sous forme de nombre, -1 si ce n'est pas valide.
	 * @param psId Identifiant du message dont il faut extraire le timestamp.
	 */
	public static extractTimestampFromId(psId: string): number {
		const lsTimestamp: string = ArrayHelper.getLastElement(psId.split(IdHelper.C_ID_SEPARATOR));

		return NumberHelper.isStringNumber(lsTimestamp) ? +lsTimestamp : -1;
	}

	/** Récupère le préfixe d'un identifiant.
	 * @param psId Identifiant dont il faut récupérer le préfixe.
	 */
	public static getPrefixFromId(psId: string): EPrefix {
		return (!StringHelper.isBlank(psId) && psId.includes(IdHelper.C_ID_SEPARATOR) ? `${ArrayHelper.getFirstElement(psId.split('_'))}_` : "") as EPrefix;
	}

	/** Retourne `true` si l'identifiant possède un préfixe, `false` sinon.
	 * @param psId Identifiant dont on doit vérifier la présence d'un préfixe.
	 * @param pePrefix Préfixe qui doit être présent dans l'identifiant.
	 */
	public static hasPrefixId(psId: string, pePrefix?: EPrefix): boolean {
		// On considère qu'un préfixe est composé de minuscule(s) et/ou majuscule(s) et/ou chiffre(s) suivis d'un '_'.
		return pePrefix ? psId.indexOf(pePrefix) === 0 : /[a-zA-Z0-9]+_/.test(psId);
	}

	/** Retourne le dernier suffixe d'un identifiant.
	 * @param psId Identifiant dont on souhaite obtenir le suffixe.
	 * @param psSeparator Séparateur, par défaut `_`.
	 */
	public static getSuffix(psId: string, psSeparator: string = IdHelper.C_ID_SEPARATOR): string {
		const laSplittedIds: Array<string> = psId.split(psSeparator);

		if (laSplittedIds.length <= 1) // Si la chaîne de caractères est vide ou qu'il n'y a pas de suffixe.
			return "";

		return ArrayHelper.getLastElement(laSplittedIds); // Sinon on retourne le dernier élément.
	}

	/** Récupère un sous-identifiant dans un identifiant composé.
	 * @param psId Identifiant dont il faut récupérer un sous-identifiant.
	 * @param peSearchedPrefix Préfixe de l'identifiant recherché.
	 */
	public static getIdFromComposedId(psId: string, peSearchedPrefix: EPrefix): string {
		const laSplittedFragmentIds: string[] = psId.split(IdHelper.C_ID_SEPARATOR);
		const leSearchedPrefixWithoutUnderscore: string = peSearchedPrefix.replace(IdHelper.C_ID_SEPARATOR, "");
		const lnPrefixIndex: number = laSplittedFragmentIds.findIndex((psFragment: string) => psFragment === leSearchedPrefixWithoutUnderscore);

		if (lnPrefixIndex === -1)
			return "";
		else // tsk_appoint_tour_tourId_appointId_tskId : longueur 6, id = longueur - 1 - indexPrefix associé.
			return `${peSearchedPrefix}${laSplittedFragmentIds[laSplittedFragmentIds.length - 1 - lnPrefixIndex]}`;
	}

	/** Remplace le préfixe d'un identifiant.
	 * @param psId Identifiant à modifier.
	 * @param peNewPrefix Préfixe à ajouter à l'identifiant.
	 * @param peOldPrefix Préfixe actuel de l'identifiant à remplacer (optionnel).
	 * @returns
	 */
	public static replacePrefixId(psId: string, peNewPrefix: EPrefix, peOldPrefix?: EPrefix): string {
		if (peOldPrefix && this.hasPrefixId(psId, peOldPrefix))
			return psId.replace(peOldPrefix, peNewPrefix);
		else if (!peOldPrefix && this.hasPrefixId(psId))
			return psId.replace(this.getPrefixFromId(psId), peNewPrefix);
		else
			return this.buildId(peNewPrefix, psId);
	}

	//#endregion
}