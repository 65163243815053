export enum EPrestationStatus {
	/** La facture est créée. */
	created,
	/** La facture est annulée. */
	canceled,
	/** La facture est vérifié. */
	checked,
	/** La facture est bloquée à l'envoi. */
	locked,
	/** La facture est envoyée. */
	sent
}
