import { IObservation } from "./iobservation";

export class Observation implements IObservation {

	//#region PROPERTIES

	/** @implements */
	_id: string;
	/** @implements */
	_rev?: string;
	/** @implements */
	_deleted?: boolean;
	/** @implements */
	deleted?: boolean;
	/** @implements */
	_conflicts?: string[];
	/** @implements */
	description: string;
	/** @implements */
	lastUpdateContactId: string;
	/** @implements */
	lastUpdateDate: Date;

	//#endregion

}
