import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ContactsListComponent } from '@osapp/components/contacts/contactsList/contactsList.component';
import { IContact } from '@osapp/model/contacts/IContact';
import { ContactsService } from '@osapp/services/contacts.service';
import { EntityLinkService } from '@osapp/services/entityLink.service';
import { GroupsService } from '@osapp/services/groups.service';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { PatientSelectorModalOpenerService } from '../patient-selector-modal/services/patient-selector-modal-opener.service';

@Component({
	selector: 'idl-patient-contacts-list',
	templateUrl: './patient-contacts-list.component.html',
	styleUrls: ['./patient-contacts-list.component.scss'],
})
export class PatientContactsListComponent extends ContactsListComponent {

	constructor(
		protected readonly isvcContacts: ContactsService,
		protected readonly isvcEntityLink: EntityLinkService,
		protected readonly ioRouter: Router,
		protected readonly isvcGroups: GroupsService,
		private readonly isvcPatientSelectorModalOpener: PatientSelectorModalOpenerService
	) {
		super(isvcContacts, isvcEntityLink, ioRouter, isvcGroups);
	}

	/** @override */
	public selectContacts(): void {
		const laOldContacts: IContact[] = this.getContactsFromContactsWithAvailability();

		this.isvcPatientSelectorModalOpener.open({
			required: true,
			preselectedPatientIds: laOldContacts.map((poContact: IContact) => poContact._id),
			canCreate: true,
			title: this.params.pageTitle,
			selectNewPatient: true
		})
			.pipe(
				filter((paContacts: IContact[]) => !!paContacts), // Ne prend en compte que si on a validé la sélection de contacts.
				tap((paContacts: IContact[]) => {
					this.model = paContacts;
					this.updateContactsLinks(laOldContacts, paContacts);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

}
