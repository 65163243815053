import { IHoursMinutes } from '../../date/model/ihours-minutes';
import { ModelMatch } from '../../utils/models/decorators/model-match.decorator';
import { DayRepetition } from './day-repetition';
import { IRangeRepetition } from './irange-repetition';
import { IRangeRepetitionParams } from './irange-repetition-params';

export const C_RANGE_REPETITION_TYPE = "range";

@ModelMatch(
	(poData: IRangeRepetition) => poData.type === C_RANGE_REPETITION_TYPE || (
		!!poData.from || !!poData.to
	),
	DayRepetition
)
export class RangeRepetition extends DayRepetition implements IRangeRepetition {

	//#region PROPERTIES

	public readonly type: string = C_RANGE_REPETITION_TYPE;
	/** @implements */
	public from?: IHoursMinutes;
	/** @implements */
	public to?: IHoursMinutes;

	//#endregion

	//#region METHODS

	constructor(poData?: IRangeRepetitionParams) {
		super();
		if (poData) {
			this.from = poData.from;
			this.to = poData.to;
		}

	}

	//#endregion

}
