import { ContactHelper } from '@osapp/helpers/contactHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { IContact } from '@osapp/model/contacts/IContact';
import { IGroup } from '@osapp/model/contacts/IGroup';
import { EPrefix } from '@osapp/model/EPrefix';
import { ModelMatch } from '@osapp/modules/utils/models/decorators/model-match.decorator';
import { Constraint } from './constraint';
import { EConstraintType } from './EConstraintType';
import { IActeOccurrence } from './iacte-occurrence';
import { IConstraintLabelParams } from './iconstraint-label-params';
import { IIntervenantConstraint } from './iintervenant-constraint';

const C_TYPE = EConstraintType.intervenant;

@ModelMatch((poData: IIntervenantConstraint) => poData?.type === C_TYPE, Constraint)
export class IntervenantConstraint extends Constraint implements IIntervenantConstraint {

	//#region PROPERTIES


	public readonly type: EConstraintType = C_TYPE;

	/** @implements */
	public intervenantIds?: string[];

	//#endregion

	//#region METHODS	

	/** @override */
	public action(poActeOccurrence: IActeOccurrence): void {
		poActeOccurrence.intervenantIds = this.intervenantIds ?? [];
	}

	/** @override */
	public buildlabel(poParams: IConstraintLabelParams): string {
		const laIntervenantsAndGroups: Array<IContact | IGroup> = [];

		this.intervenantIds.forEach((psIntervenantId: string) => {
			const loIntervenantOrGroup: IContact | IGroup = poParams.intervenantsAndGroups.find((poContact: IContact) => psIntervenantId === poContact._id);
			if (!ObjectHelper.isNullOrEmpty(loIntervenantOrGroup))
				laIntervenantsAndGroups.push(loIntervenantOrGroup);
		});

		return `intervenant remplacé par ${laIntervenantsAndGroups.map((poIntervenantOrGroup: IContact | IGroup) => {
			if (IdHelper.getPrefixFromId(poIntervenantOrGroup._id) === EPrefix.contact)
				return ContactHelper.getCompleteFormattedName(poIntervenantOrGroup as IContact);
			else
				return (poIntervenantOrGroup as IGroup).name ?? "inconnu";
		}).join(", ")}.`;
	}

	public getIntervenantsIds(): string[] {
		return this.intervenantIds ?? [];
	}

	//#endregion

}
