import { Injectable, Injector } from '@angular/core';
import { EMPTY, GroupedObservable, Observable, ReplaySubject, Subject, defer, from, merge, of, throwError } from 'rxjs';
import { catchError, concatMap, defaultIfEmpty, filter, finalize, groupBy, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { FileHelper } from '../../../helpers/fileHelper';
import { GuidHelper } from '../../../helpers/guidHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { EDatabaseRole } from '../../../model';
import { EPrefix } from '../../../model/EPrefix';
import { EStoreFlag } from '../../../model/store/EStoreFlag';
import { Store } from '../../../services';
import { ApplicationService } from '../../../services/application.service';
import { EntityLinkService } from '../../../services/entityLink.service';
import { PlatformService } from '../../../services/platform.service';
import { LogAction } from '../../logger/decorators/log-action.decorator';
import { ELogActionId } from '../../logger/models/ELogActionId';
import { ILogSource } from '../../logger/models/ILogSource';
import { LogActionHandler } from '../../logger/models/log-action-handler';
import { LoggerService } from '../../logger/services/logger.service';
import { afterSubscribe } from '../../utils/rxjs/operators/after-subscribe';
import { EDmsImageQuality } from '../EDmsImageQuality';
import { DmsFile } from '../model/DmsFile';
import { IDms } from '../model/IDms';
import { IDmsData } from '../model/IDmsData';
import { IDmsMeta } from '../model/IDmsMeta';
import { DmsMediaResult } from '../model/dms-media-result';
import { EDmsMediaPriority } from '../model/edms-media-priority';
import { DmsMetaService } from './dms-meta.service';
import { RemoteDmsService } from './remoteDms.service';
import { SyncDmsService } from './syncDms.service';

interface IDmsError {
	error: any;
	id: string;
}

interface IDmsGetParams {
	id: string;
	quality: number;
}

/** Service de gestion des fichiers sur le système de fichiers.
 * Récupère une implémentation de DMS spécifique en fonction des paramètres de l'application et de la plateforme.
 */
@Injectable()
export class DmsService implements IDms, ILogSource {

	//#region FIELDS

	/** Objet qui contient la liste des fichiers du DMS indexés par identifiant puis par qualité du fichier. */
	private readonly moDmsDataCache = new Map<string, Map<number, IDmsData>>();
	private readonly moExecGetSubject = new Subject<IDmsGetParams>();
	private readonly moGetSubject = new Subject<IDmsData>();
	private readonly moGetErrorSubject = new Subject<IDmsError>();

	/** Implémentation du dms configurée pour l'app dont il faut se servir pour appeler les méthodes. */
	private moDmsImplementation: IDms;

	//#endregion

	//#region PROPERTIES

	/** @implements */
	public readonly logSourceId = "DMS.S::";
	/** @implements */
	public readonly logActionHandler = new LogActionHandler(this);

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcPlatform: PlatformService,
		private readonly isvcMeta: DmsMetaService,
		private svcStore : Store,
		private readonly isvcEntities: EntityLinkService,
		/** @implements */
		public readonly isvcLogger: LoggerService,
		poInjector: Injector,
		psvcApplication: ApplicationService
	) {
		this.init(poInjector, psvcApplication);
	}

	/** Initialise le service du dms en utilisant une implémentation spécifique (sync ou remote).
	 * @param poInjector Service d'injecteur de providers.
	 * @param psvcApplication Service de gestion de l'application.
	 */
	private init(poInjector: Injector, psvcApplication: ApplicationService): void {
		psvcApplication.waitForFlag(EStoreFlag.DBInitialized, true)
			.pipe(tap(_ => this.moDmsImplementation = this.getDmsImplementation(poInjector, this.isvcPlatform.isMobileApp)))
			.subscribe();

		this.moExecGetSubject.asObservable()
			.pipe(
				groupBy((poGetParams: IDmsGetParams) => poGetParams.id),
				mergeMap((poGroupedRequest$: GroupedObservable<string, IDmsGetParams>) => {
					return poGroupedRequest$
						.pipe(
							concatMap((poGetParams: IDmsGetParams) =>
								this.getData(poGetParams).pipe(mergeMap((poDmsData: Map<number, IDmsData>) => this.reduceFileForCache(poDmsData, poGetParams)))
							),
							catchError(poError => {
								this.moGetErrorSubject.next({ error: poError, id: poGroupedRequest$.key });
								return EMPTY;
							}),
							tap((poDmsData: IDmsData) => this.moGetSubject.next(poDmsData))
						);
				})
			)
			.subscribe();
	}

	/** Récupère une donnée.
	 * @param poGetParams Paramètres de récupération d'une donnée.
	 */
	private getData(poGetParams: IDmsGetParams): Observable<Map<number, IDmsData>> {
		// Si on a en mémoire l'id du fichier à récupérer, on le retourne directement.
		if (this.moDmsDataCache.has(poGetParams.id))
			return of(this.moDmsDataCache.get(poGetParams.id));

		else { // Sinon on doit le récupérer, puis on garde en cache le fichier.
			return this.moDmsImplementation.get(poGetParams.id, poGetParams.quality)
				.pipe(
					map((poData: IDmsData) => {
						const loCachedData = new Map<number, IDmsData>();
						this.moDmsDataCache.set(poGetParams.id, loCachedData.set(EDmsImageQuality.natural, poData));
						return loCachedData;
					})
				);
		}
	}

	/** Réduit la taille d'un fichier et le garde en cache.
	 * @param poDmsDatas Map d'objet `IDmsData` indexé par taille de la donnée.
	 * @param poGetParams Paramètres de récupération de fichier.
	 */
	private reduceFileForCache(poDmsDatas: Map<number, IDmsData>, poGetParams: IDmsGetParams): Observable<IDmsData> {
		if (poDmsDatas.has(poGetParams.quality))
			return of(poDmsDatas.get(poGetParams.quality));
		else {
			const loNaturalDmsData: IDmsData = poDmsDatas.get(EDmsImageQuality.natural);

			return FileHelper.reduceImage(loNaturalDmsData.file.File as Blob, poGetParams.quality)
				.pipe(
					map((poFile: File) => {
						const loDmsData: IDmsData = { file: new DmsFile(poFile, loNaturalDmsData.meta), meta: loNaturalDmsData.meta } as IDmsData;
						poDmsDatas.set(poGetParams.quality, loDmsData);
						return loDmsData;
					})
				);
		}
	}

	/** Supprime un fichier du DMS.
	 * @param psId Identifiant du fichier à supprimer.
	 */
	public delete(psId: string): Observable<boolean> {
		// Si la suppression a réussi, on supprime le fichier correspondant du cache.
		return this.moDmsImplementation.delete(psId)
			.pipe(tap((pbResult: boolean) => pbResult ? this.moDmsDataCache.delete(psId) : ""));
	}

	/** Récupère un fichier du DMS.
	 * @param psId Identifiant du fichier à récupérer.
	 * @param pnQuality Qualité de l'image en Kb.
	 */
	public get(psId: string, pnQuality: number = EDmsImageQuality.natural): Observable<IDmsData> {
		return defer(() => {
			// Si on a en mémoire l'id du fichier à récupérer, on le retourne directement.
			if (this.moDmsDataCache.get(psId)?.has(pnQuality))
				return of(this.moDmsDataCache.get(psId).get(pnQuality));

			const lsDocId: string = IdHelper.buildId(EPrefix.dms, GuidHelper.extractGuid(psId));

			// On s'abonne aux récupération du dms avant de lancer la requête de téléchargement avec le `afterSubscribe`.
			return merge(
				this.moGetSubject.asObservable().pipe(filter((poDmsData: IDmsData) => poDmsData.meta._id === lsDocId)),
				this.moGetErrorSubject.asObservable()
					.pipe(filter((poError: IDmsError) => poError.id === psId), mergeMap((poError: IDmsError) => throwError(poError.error)))
			);
		})
			.pipe(
				afterSubscribe(() => this.moExecGetSubject.next({ id: psId, quality: pnQuality })),
				take(1)
			);
	}

	/** Retourne le type d'implémentation du DMS en fonction de la valeur indiquée dans le config.ts et si on est sur mobile ou non.
	 * @param poInjector Service d'injecteur de providers.
	 * @param pbIsMobileApp Indique si on est sur mobile ou non.
	 */
	private getDmsImplementation(poInjector: Injector, pbIsMobileApp: boolean): IDms {
		// Pour l'instant, vérification simple (dms remote ou sync). Si d'autres implémentations apparaissent, il faudra enrichir cete méthode.
		return pbIsMobileApp ? poInjector.get(SyncDmsService) : poInjector.get(RemoteDmsService);
	}

	/** Enregistre un fichier dans le DMS.
	 * @param poData Fichier à enregistrer dans le DMS.
	 * @param poGuidOrMeta Guid ou méta du fichier à enregistrer.
	 */
	@LogAction<Parameters<DmsService["save"]>, ReturnType<DmsService["save"]>>({
		actionId: ELogActionId.dmsDocSave,
		successMessage: "Document GED ajouté.",
		errorMessage: "Erreur lors de l'ajout du document GED.",
		dataBuilder: (poThis: DmsService, poMeta: IDmsMeta, _, poMetaParam?: IDmsMeta) => ({
			metaId: poMeta?._id ?? poMetaParam._id,
			entityId: poThis.isvcEntities.currentEntity?.id
		})
	})
	public save(poData: DmsFile, poMeta: IDmsMeta): Observable<IDmsMeta> {
		return defer(() => {
			if (this.isvcEntities.currentEntity)
				return this.isvcMeta.prepareEntityMeta(poMeta, this.isvcEntities.currentEntity);
			return of(poMeta);
		})
			.pipe(
				mergeMap((poPreparedMeta: IDmsMeta) => this.moDmsImplementation.save(poData, poPreparedMeta)),
				tap((poDmsMetaResult: IDmsMeta) => { // Si l'enregistrement a réussi, on garde en cache le fichier.
					this.moDmsDataCache.set(
						GuidHelper.extractGuid(poDmsMetaResult._id),
						new Map<number, IDmsData>().set(EDmsImageQuality.natural, { file: poData, meta: poDmsMetaResult })
					);
				})
			);
		}

		public saveANAKIN(poData: DmsFile, poMeta: IDmsMeta,entityId : string): Observable<IDmsMeta> {
			const databaseId = ArrayHelper.getFirstElement(this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace));

			poMeta.attributes = [
				{ name: "wsId", value: databaseId },
				{ name: "entityId", value: entityId },
				{ name: `${IdHelper.getPrefixFromId(entityId).replace("_", "")}Id`, value: entityId },
				{ name: "SUBTYPE", value: 'DIV' }
			];

			return defer(() => {
				return of(poMeta);
			})
				.pipe(
					mergeMap((poPreparedMeta: IDmsMeta) => this.moDmsImplementation.save(poData, poPreparedMeta)),
					tap((poDmsMetaResult: IDmsMeta) => { // Si l'enregistrement a réussi, on garde en cache le fichier.
						this.moDmsDataCache.set(
							GuidHelper.extractGuid(poDmsMetaResult._id),
							new Map<number, IDmsData>().set(EDmsImageQuality.natural, { file: poData, meta: poDmsMetaResult })
						);
					})
				);
			}

	/** Récupère un fichier de façon priorisée :
	 * - On récupère prioritairement le fichier principal mais s'il n'est pas dispo de suite on récupère potentiellement un des fichiers secondaires.
	 * - Dès qu'un fichier secondaire est récupéré, on coupe la récupération des autres (secondaires).
	 * @param psPrimaryId Identifiant du fichier principal à récupérer.
	 * @param paAlternativeIds Tableau des identifiants alternatifs/secondaires qu'on peut récupérer avant d'obtenir le principal (optionnel).
	 */
	public getPrioritizedFile(psPrimaryId: string, paAlternativeIds?: string[]): Observable<DmsMediaResult> {
		const loStopSubject = new ReplaySubject<DmsMediaResult>(1);

		const loGetPrimaryFile$: Observable<DmsMediaResult> = this.get(psPrimaryId).pipe(
			map((poResult: IDmsData) => new DmsMediaResult(psPrimaryId, EDmsMediaPriority.primary, poResult.file.File)),
			tap((poResult: DmsMediaResult) => loStopSubject.next(poResult)),
			catchError(_ => of(new DmsMediaResult(psPrimaryId, EDmsMediaPriority.primary)))
		);

		return merge(
			loGetPrimaryFile$,
			ArrayHelper.hasElements(paAlternativeIds) ? this.getAlternativeFileFromIds(paAlternativeIds, loStopSubject.asObservable()) : EMPTY
		).pipe(finalize(() => loStopSubject.complete()));
	}

	private getAlternativeFileFromIds(paAlternativeIds: string[], poStop$: Observable<DmsMediaResult>): Observable<DmsMediaResult> {
		return from(paAlternativeIds).pipe(
			mergeMap((psMediaId: string) => {
				return this.get(psMediaId).pipe(
					catchError(_ => of({ file: {} } as IDmsData)),
					filter((poResult: IDmsData) => !!poResult.file.File),
					map((poResult: IDmsData) => new DmsMediaResult(psMediaId, EDmsMediaPriority.alternative, poResult.file.File))
				);
			}),
			take(1),
			takeUntil(poStop$),
			defaultIfEmpty(new DmsMediaResult(ArrayHelper.getFirstElement(paAlternativeIds), EDmsMediaPriority.alternative))
		);
	}

	//#endregion

}
