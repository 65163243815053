import { ResolveModel } from "@osapp/modules/utils/models/decorators/resolve-model.decorator";
import { IRenouvellementOrdonnance } from "./irenouvellement-ordonnance";

export class RenouvellementOrdonnance implements IRenouvellementOrdonnance {

	//#region PROPERTIES	

	@ResolveModel(Date)
	/** @implements */
	public date: Date;
	/** @implements */
	public index: number;
	/** @implements */
	public previousOrdoId?: string;

	//#endregion

}
