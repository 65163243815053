import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LightingUpAngularModule } from 'lighting-up-angular';
import { SidemenuDesktopComponent } from './sidemenu-desktop.component';

@NgModule({
	declarations: [SidemenuDesktopComponent],
	imports: [CommonModule, LightingUpAngularModule],
	exports: [SidemenuDesktopComponent]
})
export class SidemenuDesktopModule { }
