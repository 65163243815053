<section class="txt-left">
	<section class="sct-detail-parts txt14px" *ngIf="filteredActes.length === 0">
		<div class="item item-divider ctn-date-seance">
			Traitement non impacté par l'accord préalable
			<div class="divider"></div>
		</div>
	</section>
	<section class="sct-detail-parts txt14px" *ngIf="filteredActes.length > 0">
		<div class="item item-divider ctn-date-seance">
			Actes impactés par un accord préalable
			<div class="divider"></div>
		</div>
		<ion-list>
			<ion-item *ngFor="let acte of filteredActes">
				<ion-label>{{ acte.ngapLabel }}</ion-label>
				<ion-note slot="end">{{ acte.keyLetters }} {{ acte.priceCoefficient }}</ion-note>
			</ion-item>
		</ion-list>
		<div class="item item-divider ctn-date-seance">
			Demande &nbsp; - &nbsp;<ion-note *ngIf="lastUpdateDemande">Dernière modification le : {{ lastUpdateDemande
				}}&nbsp;
				-
				&nbsp;</ion-note>
			<span
				[ngClass]="{ 'creation': etatAP === Etat.EnAttente , 'encours': etatAP === Etat.EnCours, 'close': etatAP === Etat.Cloture }">
				{{
				etatAP }}</span>
			<div class="divider"></div>
		</div>
		<form [formGroup]="formAP" (ngSubmit)="onSubmitAP()">
			<ion-row *ngIf="etatAP != Etat.EnAttente">
				<ion-col>
					<p>Date de la demande</p>
					<span (click)="dateClick()">
						<osapp-date-time [params]="startDateParamsDemande" *ngIf="accordCurrent" [model]="accordCurrent.dateDemande"
							(modelChange)="onStartDateDemandeChanged($event)" [disabled]="etatAP != Etat.EnAttente">
						</osapp-date-time>
					</span>
				</ion-col>
			</ion-row>
			<ion-row *ngIf="etatAP != Etat.EnAttente">
				<ion-col>
					<p>Actes urgents :</p>
					<ion-toggle formControlName="isUrgent" [disabled]="etatAP != Etat.EnAttente"></ion-toggle>
				</ion-col>
				<ion-col>
					<p>Actes en serie :</p>
					<ion-toggle formControlName="isSerie" [disabled]="etatAP != Etat.EnAttente"></ion-toggle>
				</ion-col>
			</ion-row>
			<ion-row *ngIf="etatAP != Etat.EnAttente && accordCurrent.elementMedicaux">
				<ion-col>
					<p>Eléments médicaux justifiants les actes :</p>
					<p>{{accordCurrent.elementMedicaux}}</p>
				</ion-col>
			</ion-row>
			<ion-row *ngIf="etatAP === Etat.EnCours || etatAP === Etat.Cloture">
				<ion-col>
					<p>Date du retour</p>
					<span (click)="dateClick()">
						<osapp-date-time [params]="startDateParamsResulat" [model]="dateResultat"
							(modelChange)="onStartDateResultatChanged($event)" [disabled]="etatAP == Etat.Cloture">
						</osapp-date-time>
					</span>
				</ion-col>
				<ion-col>
					<p>Résultat du retour</p>
					<ion-radio-group formControlName="avisResultat">
						<ion-item>
							<ion-label>Oui</ion-label>
							<ion-radio [value]="true" [checked]="true" [disabled]="etatAP === Etat.Cloture"></ion-radio>
						</ion-item>
						<ion-item>
							<ion-label>Non</ion-label>
							<ion-radio [value]="false" [disabled]="etatAP === Etat.Cloture"></ion-radio>
						</ion-item>
					</ion-radio-group>
				</ion-col>
			</ion-row>
			<div class="actionAP">
				<ion-button expand="full" *ngIf="accordCurrent" (click)="openAP()" type="button">Voir la demande</ion-button>
				<ion-button expand="full" *ngIf="etatAP != Etat.EnAttente" (click)="cancelDemande()" type="button">Annuler la
					demande</ion-button>
			</div>
			<ion-button expand="full" *ngIf="etatAP != Etat.Cloture" type="submit">{{titleFormAp}}</ion-button>
		</form>
	</section>
</section>