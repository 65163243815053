<div class="filters-ctn">
	<div class="left">
		<!-- Barre de recherche. -->
		<div class="search-box search-open txt-center form-list width100pc">
			<search #search [options]="searchOptions" [data]="patients"
				(filteredEntriesChanged)="onFilteredPatientsChanged($event)" [(searchValue)]="searchValue">
			</search>
		</div>
	</div>

	<span class="seperator"></span>

	<!-- Bouton de filtrage. -->
	<ion-button mode="md" (click)="toggleFilterbarVisibility()" size="small" class="filter-button-right"
		color="osapp-element">
		<ion-icon slot="icon-only" name="filter">
		</ion-icon>
		<span *ngIf="filtersCount > 0" class="badge">{{ filtersCount }}</span>
		<ion-label *ngIf="filterRangeDates" class="dates-filter-label">Du {{ filterRangeDates.begin | dateWithLocale:
			'fr-FR' :
			'dd/MM/yyyy'}} au {{
			filterRangeDates.end | dateWithLocale: 'fr-FR' : 'dd/MM/yyyy'}}</ion-label>
	</ion-button>
	<!-- Bouton d'export Excel. -->
	<ion-button mode="md" size="small" class="filter-button-right" color="osapp-element" (click)="exportToExcel()">
		<ion-icon slot="icon-only" name="csv">
		</ion-icon>
	</ion-button>
</div>

<!-- Barre de filtrage. -->
<ng-container *ngIf="filterbarParams">
	<osapp-filterbar #filterbarComponent [filters]="filterbarParams.filters"
		[hasResetButton]="filterbarParams.hasResetButton" [hidden]="filterbarParams.hidden"
		(onReset)="onFilterbarResetEvent($event)" (onFilterCountChanged)="onFilterCountChanged($event)"
		(onFilterValuesChanged)="onFilterValuesChanged($event)">
	</osapp-filterbar>
</ng-container>

<!-- Liste des patients. -->
<div class="list-ctn marg-container" *ngIf="displayedPatients?.length > 0; else empty">
	<mat-accordion multi>
		<ng-container *ngFor="let patient of displayedPatients | slice:startIndex:startIndex+nbItemsByPage">
			<mat-expansion-panel #panel *ngIf="getPatientFilteredPrestations(patient._id) as patientPrestations"
				togglePosition="after" [expanded]="false" class="patient-panel" (opened)="onPanelOpened(patient)">
				<mat-expansion-panel-header class="patient-hearder">
					<ion-item lines="none" mode="md">
						<ion-label>{{ patient | contactName }}</ion-label>
					</ion-item>
				</mat-expansion-panel-header>
				<!-- Liste des traitements. -->
				<ion-list *ngIf="panel.expanded" class="traitements-list">
					<ng-container *ngIf="getPatientPrestationsByTraitement(patient) as prestationsByTraitementId; else empty;">
						<ng-container *ngFor="let traitementMap of prestationsByTraitementId | keyvalue">
							<mat-expansion-panel *ngIf="getFilteredPrestations(traitementMap?.value) as prestations">
								<mat-expansion-panel-header class="traitement-header" collapsedHeight="40px" expandedHeight="40px">
									<ion-item *ngIf="traitementsById.get(traitementMap.key) as traitement" lines="none" mode="md">
										<ion-label (click)="$event.stopPropagation(); goToSynthese(traitement._id);">{{ traitement.label }}
										</ion-label>
									</ion-item>
								</mat-expansion-panel-header>
								<!-- Liste des séances. -->
								<cdk-virtual-scroll-viewport [style.height]="(300 * prestations.length) + 'px'"
									class="seances-list-wrapper" itemSize="300">
									<ng-container *cdkVirtualFor="let prestation of prestations">
										<ion-list *ngIf="prestation.lines?.length > 0" class="seances-list">
											<mat-expansion-panel class="seance-panel" [expanded]="true">
												<mat-expansion-panel-header collapsedHeight="55px" expandedHeight="55px">
													<ion-grid class="seance-grid">
														<ion-row>
															<ion-col class="flex" size="10">
																<ion-label class="seance-label center-v">{{ prestation.lines[0].group }}</ion-label>
																<div class="action-btns">
																	<ion-icon *ngIf="canCancelSeanceExport" name="close-circle-outline" color="danger"
																		matTooltip="Remettre en préparation"
																		(click)="$event.stopPropagation(); onCancelExport(prestation);">
																	</ion-icon>
																</div>
															</ion-col>
															<ion-col class="intervenant-col" size="1">
																<avatar [src]="getContactAvatar(prestation.vendorId)" cssClass="round">
																</avatar>
															</ion-col>
															<ion-col class="txt-primary price-col" size="1">{{prestation.total| priceFormat:2}} €
															</ion-col>
														</ion-row>
													</ion-grid>
												</mat-expansion-panel-header>
												<!-- Liste des Actes. -->
												<ion-grid class="seance-grid detail-grid">
													<ng-container *ngFor="let line of getActesLines(prestation)">
														<ion-row [ngClass]="line.deleted ? 'replaced-or-deleted-row' : ''">
															<ion-col size="10">
																<div class="description-ctn">
																	<ion-label class="line-description">{{line.description}}</ion-label>
																</div>
															</ion-col>
															<ion-col class="discount-rate-col" size="1">
																<ng-container *ngIf="prestation.status !== 2 && !line.deleted">
																	<ion-label>Ab. {{line.discountRate}}%</ion-label>
																</ng-container>
															</ion-col>
															<ion-col class="price-col" size="1">{{line.total | priceFormat:2}} €</ion-col>
														</ion-row>
													</ng-container>
													<!-- Ligne des Majorations. -->
													<ion-row>
														<ion-col size="11" class="center-v">
															<ion-label class="line-description">
																Majorations : {{ getMajorationsAttributeFromLines(prestation.lines, 'valueType')
																.join(',') }}
															</ion-label>
														</ion-col>
														<ion-col class="price-col" size="1">{{getMajorationsTotalPrice(prestation) | priceFormat:2}}
															€
														</ion-col>
													</ion-row>
													<!-- Ligne des Indemnités. -->
													<ion-row>
														<ion-col size="11" class="center-v indemnite-col">
															<ion-label class="line-description">
																Déplacement : {{ getIndemnitesAttributeFromLines(prestation.lines, 'lettreCle')
																.join(',') }}
																<span *ngIf="hasIKType(prestation)"> {{ getIndemnitesDistanceValue(prestation)
																	}}km</span>
															</ion-label>
														</ion-col>
														<ion-col class="price-col" size="1">{{getIndemnitesTotalPrice(prestation) | priceFormat:2}}
															€
														</ion-col>
													</ion-row>
													<!-- Ligne des Observations. -->
													<ion-row>
														<ion-col size="11" class="center-v">
															<ion-label class="line-description">
																Observation : {{ prestation.observation }}
															</ion-label>
														</ion-col>
													</ion-row>
												</ion-grid>
											</mat-expansion-panel>
										</ion-list>
									</ng-container>
								</cdk-virtual-scroll-viewport>
							</mat-expansion-panel>
						</ng-container>
					</ng-container>
				</ion-list>
			</mat-expansion-panel>
		</ng-container>
	</mat-accordion>

	<mat-paginator #paginator [length]="displayedPatients?.length" [pageSize]="nbItemsByPage"
		[pageSizeOptions]="[5, 10, 15, 20]" (page)="onPageChange($event)">
	</mat-paginator>
</div>

<!-- Message si aucun résultat. -->
<ng-template #empty>
	<div class="empty-screen flex-col">
		<span class="txt-message">
			<ion-icon name="information-circle" class="no-result"></ion-icon>
			Aucun résultat.
		</span>
	</div>
</ng-template>