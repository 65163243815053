import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ArrayHelper } from '../helpers/arrayHelper';
import { DateHelper } from '../helpers/dateHelper';
import { IdHelper } from '../helpers/idHelper';
import { StoreHelper } from '../helpers/storeHelper';
import { StringHelper } from '../helpers/stringHelper';
import { UserData } from '../model/application/UserData';
import { IEventDocument } from '../model/calendar/IEventDocument';
import { IEventMarker } from '../model/calendar/IEventMarker';
import { EPrefix } from '../model/EPrefix';
import { NoCurrentUserDataError } from '../model/errors/NoCurrentUserDataError';
import { EDatabaseRole } from '../model/store/EDatabaseRole';
import { IDataSource } from '../model/store/IDataSource';
import { IStoreDocument } from '../model/store/IStoreDocument';
import { EUTCAccuracy } from '../modules/date/model/eutcaccuracy.enum';
import { IRange } from '../modules/utils/models/models/irange';
import { Store } from './store.service';

@Injectable({ providedIn: "root" })
export class EventService {

	//#region METHODS

	constructor(private isvcStore: Store) { }

	/** Récupère les marqueurs d'événement d'une entité.
	 * @param psEntityGuid
	 * @param poRange
	 * @param pbLive
	 * @param pfEventIdFactory
	 */
	public getEntityEventMarkers(psEntityGuid?: string, poRange?: IRange<Date>, pbLive?: boolean, pfEventIdFactory?: (pdEvent?: Date) => string): Observable<IEventMarker[]> {
		return this.isvcStore.get<IEventMarker>(this.getEventMarkersDataSource(true, psEntityGuid, poRange, pfEventIdFactory, pbLive));
	}

	/** Récupère les identifiants de marqueurs d'événement d'une entité.
	 * @param psEntityGuid
	 * @param poRange
	 * @param pbLive
	 * @param pfEventIdFactory
	 */
	public getEntityEventMarkerIds(psEntityGuid?: string, poRange?: IRange<Date>, pbLive?: boolean, pfEventIdFactory?: (pdEvent?: Date) => string): Observable<string[]> {
		return this.isvcStore.get<IEventMarker>(this.getEventMarkersDataSource(false, psEntityGuid, poRange, pfEventIdFactory, pbLive)).pipe(
			map((paEventMarkers: IEventMarker[]) => paEventMarkers.map((poEventMarker: IEventMarker) => poEventMarker._id))
		);
	}

	/** Récupère les marqueurs d'événement.
	 * @param poRange
	 * @param pbLive
	 * @param pfEventIdFactory
	 */
	public getEventMarkers(poRange?: IRange<Date>, pbLive?: boolean, pfEventIdFactory?: (pdEvent?: Date) => string): Observable<IEventMarker[]> {
		return this.isvcStore.get<IEventMarker>(this.getEventMarkersDataSource(true, undefined, poRange, pfEventIdFactory, pbLive));
	}

	private getEventMarkersDataSource(pbIncludeDocs: boolean, psEntityGuid?: string, poRange?: IRange<Date>, pfEventIdFactory?: (pdEvent?: Date) => string, pbLive?: boolean): IDataSource {
		return {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			filter: !StringHelper.isBlank(psEntityGuid) ? (poDocument: IStoreDocument) => poDocument._id.indexOf(psEntityGuid) >= 0 : undefined,
			viewParams: {
				startkey: pfEventIdFactory ? pfEventIdFactory(poRange?.from) : EventService.getEventIdStart(poRange?.from),
				endkey: `${pfEventIdFactory ? pfEventIdFactory(poRange?.to) : EventService.getEventIdStart(poRange?.to)}${Store.C_ANYTHING_CODE_ASCII}`,
				include_docs: pbIncludeDocs
			},
			live: pbLive
		};
	}

	public deleteEventMarkers(paEventMarkersIds: string[], psDatabaseId: string): Observable<boolean> {
		return this.isvcStore.get({ databaseId: psDatabaseId, viewParams: { keys: paEventMarkersIds } })
			.pipe(mergeMap((paResults: IStoreDocument[]) => this.isvcStore.deleteMultipleDocuments(paResults)));
	}

	/** Extrait la date de l'identifiant du marqueur d'événement.
	 * @param psEventMarkerId Identifiant du marqueur d'événement.
	 */
	public static extractTimeFromEventMarkerId(psEventMarkerId: string): Date {
		return ArrayHelper.getFirstElement(IdHelper.extractDatesFromId(psEventMarkerId));
	}

	/** Extrait le timestamp de l'identifiant du marqueur d'événement qui représente sa date.
	 * @param psEventMarkerId Identifiant du marqueur d'événement.
	 */
	public static extractSourceObjectIdFromEventMarkerId(psEventMarkerId: string): string {
		const laEventParts: string[] = psEventMarkerId.split("_");
		return laEventParts.slice(4).join("_");
	}

	/** Permet de générer les marqueurs d'un événement.
	 * @param poEvent
	 * @param paParticipantContactsIds Identifiants des participants à l'événement.
	 * @param psAuthorId Identifiant de l'auteur de l'événement.
	 * @param pfEventIdFactory Constructeur d'identifiant custom
	 */
	public generateEventMarkers(
		poEvent: IEventDocument,
		paParticipantContactsIds?: string[],
		psAuthorId?: string,
		pfEventIdFactory?: (pdEvent: Date) => string
	): IEventMarker[] {
		if (StringHelper.isBlank(psAuthorId)) {
			if (UserData.current)
				psAuthorId = UserData.current.name;
			else
				throw new NoCurrentUserDataError();
		}

		const laEventMarkers: IEventMarker[] = [];
		let ldStartDate: Date = new Date(poEvent.startDate);
		const ldEndDate: Date = new Date(poEvent.endDate);
		const lnEventMarkers: number = Math.abs(DateHelper.diffDays(ldStartDate, ldEndDate));

		for (let lnIdx = 0; lnIdx <= lnEventMarkers; lnIdx++) {
			const loEventMarker: IEventMarker = {
				authorId: psAuthorId,
				_id: pfEventIdFactory ? pfEventIdFactory(ldStartDate) : `${EventService.getEventIdStart(ldStartDate)}${poEvent._id}`,
				participantContactIds: paParticipantContactsIds
			};

			StoreHelper.updateDocumentCacheData(loEventMarker, { databaseId: StoreHelper.getDatabaseIdFromCacheData(poEvent, "", false) });

			laEventMarkers.push(loEventMarker);

			if (laEventMarkers.length > 1)
				ldStartDate = DateHelper.resetDay(DateHelper.addDays(ldStartDate, 1));
		}

		return laEventMarkers;
	}

	/** Récupère la première partie de l'identifiant d'événement.
	 * @param pdDate Date de l'événement.
	 */
	public static getEventIdStart(pdDate: Date): string {
		const lsDate: string = DateHelper.toUTCString(pdDate, EUTCAccuracy.minutes);
		return `${EPrefix.event}${`${UserData.currentSite?._id}_` ?? ""}${StringHelper.isBlank(lsDate) ? "" : `${lsDate}_`}`;
	}

	public getEventsIds(poEvent: IEventDocument): string[] {
		return this.generateEventMarkers(poEvent).map((poEventMarker: IEventMarker) => poEventMarker._id);
	}

	//#endregion
}