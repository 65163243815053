import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { EnumHelper } from '@osapp/helpers/enumHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { EPrefix } from '@osapp/model/EPrefix';
import { IContact } from '@osapp/model/contacts/IContact';
import { IContactsListParams } from '@osapp/model/contacts/IContactsListParams';
import { IGalleryFile } from '@osapp/model/gallery/IGalleryFile';
import { EAvatarSize } from '@osapp/model/picture/EAvatarSize';
import { IAvatar } from '@osapp/model/picture/IAvatar';
import { IUiResponse } from '@osapp/model/uiMessage/IUiResponse';
import { ObservableArray } from '@osapp/modules/observable/models/observable-array';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { SelectorComponent } from '@osapp/modules/selector/selector/selector.component';
import { Queuer } from '@osapp/modules/utils/queue/models/queuer';
import { ContactsService } from '@osapp/services/contacts.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '@osapp/services/loading.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { takeUntil, tap } from 'rxjs/operators';
import { C_PREFIX_ACT } from '../../../../app/app.constants';
import { EMajorationType } from '../../../../model/EMajorationType';
import { IIdelizyContact } from '../../../../model/IIdelizyContact';
import { Traitement } from '../../../../model/Traitement';
import { EOriginePrescription } from '../../../ordonnances/models/eorigine-prescription.enum';
import { Ordonnance } from '../../../ordonnances/models/ordonnance';
import { OrdonnancesService } from '../../../ordonnances/services/ordonnances.service';
import { IPatient } from '../../../patients/model/IPatient';
import { AMCP } from '../../../patients/model/amc-p';
import { AMOP } from '../../../patients/model/amo-p';
import { IAMC } from '../../../patients/model/iamc';
import { IAMO } from '../../../patients/model/iamo';
import { CouverturesService } from '../../../patients/services/couvertures.service';
import { PatientsService } from '../../../patients/services/patients.service';
import { FacturationService } from '../../facturation.service';
import { EFormuleSTS } from '../../models/eformule-sts.enum';
import { EInvoiceStatus } from '../../models/einvoice-status';
import { EInvoiceType } from '../../models/einvoice-type.enum';
import { EModeSecurisation } from '../../models/emode-securisation.enum';
import { Invoice } from '../../models/invoice';
import { InvoiceLine } from '../../models/invoice-line';
import { InvoiceSeance } from '../../models/invoice-seance';

@Component({
	selector: 'idl-invoice',
	templateUrl: './invoice.component.html',
	styleUrls: ['./invoice.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceComponent extends ComponentBase implements OnInit {

	//#region FIELDS

	private readonly moEditInvoiceQueuer = new Queuer({
		thingToQueue: () => this.isvcFacturation.saveInvoice(this.invoice),
		excludePendings: true
	});

	private readonly moEditOrdonnanceQueuer = new Queuer({
		thingToQueue: () => this.isvcOrdonnances.editOrdonnance(this.traitement, this.ordonnance),
		excludePendings: true
	});

	private readonly moEditCouvertureQueuer = new Queuer({
		thingToQueue: () => this.isvcPatients.goToPatient(this.patient, "viewPatientSlideCouvertures"),
		excludePendings: true
	});

	//#endregion

	//#region PROPERTIES

	public fspDmsFiles: IGalleryFile[] = [];

	private moInvoice: Invoice;
	public get invoice(): Invoice { return this.moInvoice; }
	@Input()
	public set invoice(poInvoice: Invoice) {
		if (poInvoice !== this.moInvoice) {
			this.moInvoice = poInvoice;
			if (ArrayHelper.hasElements(this.moInvoice.fspDmsFiles))
				this.fspDmsFiles.push(...this.moInvoice.fspDmsFiles);
			this.initInvoiceSeances();
		}
	}

	private moPatient: IPatient;
	public get patient(): IPatient { return this.moPatient; }
	@Input()
	public set patient(poPatient: IPatient) {
		if (poPatient !== this.moPatient) {
			this.moPatient = poPatient;
			this.detectChanges();
		}
	}

	private moIntervenant: IContact;
	public get intervenant(): IContact { return this.moIntervenant; }
	@Input()
	public set intervenant(poIntervenant: IContact) {
		if (poIntervenant !== this.moIntervenant) {
			this.moIntervenant = poIntervenant;
			this.detectChanges();
		}
	}

	private moPrescripteur: IContact;
	public get prescripteur(): IContact { return this.moPrescripteur; }
	@Input()
	public set prescripteur(poPrescripteur: IContact) {
		if (poPrescripteur !== this.moPrescripteur) {
			this.moPrescripteur = poPrescripteur;
			this.detectChanges();
		}
	}

	private moOrdonnance: Ordonnance;
	public get ordonnance(): Ordonnance { return this.moOrdonnance; }
	@Input()
	public set ordonnance(poOrdonnance: Ordonnance) {
		if (poOrdonnance !== this.moOrdonnance) {
			this.moOrdonnance = poOrdonnance;
			this.detectChanges();
		}
	}

	private moTraitement: Traitement;
	public get traitement(): Traitement { return this.moTraitement; }
	@Input()
	public set traitement(poTraitement: Traitement) {
		if (poTraitement !== this.moTraitement) {
			this.moTraitement = poTraitement;
			this.detectChanges();
		}
	}

	private moCouvertures: { AMOP: AMOP, AMCP: AMCP };
	public get couvertures(): { AMOP: AMOP, AMCP: AMCP } { return this.moCouvertures; }
	@Input()
	public set couvertures(poCouvertures: { AMOP: AMOP, AMCP: AMCP }) {
		if (poCouvertures !== this.moCouvertures) {
			this.moCouvertures = poCouvertures;
			this.getEtablissements();
			this.detectChanges();
		}
	}

	public get displayGallery(): boolean { return this.invoice?.securisationMode === EModeSecurisation.DEGRADE; }
	public get canEditGallery(): boolean { return this.invoice?.status.value === EInvoiceStatus.checked; }
	public get canDisplayFSP(): boolean {
		return this.invoice?.securisationMode === EModeSecurisation.PAPIER && [EInvoiceStatus.closed, EInvoiceStatus.payment].includes(this.invoice?.status.value);
	}

	public etablissementsById = new Map<string, IAMC | IAMO>();
	public seances: InvoiceSeance[];
	public stsValue: EFormuleSTS;

	public ordonnanceOrigines = new Map<string, string>([
		{ label: "T (Médecin traitant)", value: EOriginePrescription.medTraitant },
		{ label: "O (Médecin correspondant)", value: EOriginePrescription.medCorrespondant },
		{ label: "P (Autre situation parcours de soin)", value: EOriginePrescription.autreSituationParcours },
		{ label: "S (Hors parcours de soin)", value: EOriginePrescription.horsParcoursDeSoin },
		{ label: "A (Renouvellement adapté)", value: EOriginePrescription.renouvellementAdapte },
		{ label: "I (Renouvellement identique)", value: EOriginePrescription.renouvellementIdentique }
	].map((poItem: { label: string, value: string }) => [poItem.value, poItem.label]));

	public readonly STSOptions = new ObservableArray<ISelectOption<EFormuleSTS>>([
		{ label: "Aucune", value: "none" as EFormuleSTS },
		{label : this.getFormuleSTSLabel(EFormuleSTS.fraisReelsPlafonnes) ,value: EFormuleSTS.fraisReelsPlafonnes},
		{label : this.getFormuleSTSLabel(EFormuleSTS.ticketModerateur) ,value: EFormuleSTS.ticketModerateur}
	]);

	public readonly TPOptions: Array<ISelectOption<boolean>> = [
		{ label: "Oui", value: true },
		{ label: "Non", value: false }
	];

	/** Paramètres du composant contactsList pour le prescripteur. */
	public contactsListParamsForPrescriptor: IContactsListParams;

	@ViewChild('stsSelector') public stsSelector: SelectorComponent;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcOrdonnances: OrdonnancesService,
		private readonly isvcPatients: PatientsService,
		private readonly isvcFacturation: FacturationService,
		private readonly isvcContacts: ContactsService,
		private readonly isvcCouvertures: CouverturesService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcLoading: LoadingService,
		private readonly ioRouter: Router,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);

		this.setQueuers([this.moEditInvoiceQueuer, this.moEditOrdonnanceQueuer, this.moEditCouvertureQueuer]);
	}

	public ngOnInit(): void {
		this.contactsListParamsForPrescriptor = this.createContactsListParams(EPrefix.contact, "Sélectionner le prescripteur", !StringHelper.isBlank(this.invoice?.externalId) || this.invoice?.invoiceType !== EInvoiceType.facture);
		this.contactsListParamsForPrescriptor.contactsSelectorParams.roles = [];
		this.contactsListParamsForPrescriptor.contactsSelectorParams.hasGroupSelector = true;

		if (this.moInvoice) {
			this.isvcFacturation.fillInvoiceSTSAndGU(this.moInvoice, this.moCouvertures?.AMCP);
			this.stsValue = this.moInvoice.sts ?? "none" as EFormuleSTS;

			if (this.moOrdonnance) {
				const ldMinDate: Date = DateHelper.getMinAndMaxDates(this.moInvoice.actes.map((poLine: InvoiceLine) => poLine.date)).min;
				if (DateHelper.compareTwoDates(ldMinDate, this.moOrdonnance.prescriptionDate) < 0) {
					this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
						header: "Attention !",
						message: "La date de prescription de l'ordonnance doit être antérieure aux dates des séances de la facture."
					}));
				}
			}
		}
		if (this.invoice && this.invoice.status.value == EInvoiceStatus.created) {
			this.action()// appel à tarification
		}
		this.detectChanges();
	}

	private initInvoiceSeances(): void {
		this.seances = [];
		const loInvoiceLinesByDates = new Map<string, InvoiceLine[]>();

		this.invoice?.actes.forEach((poLine: InvoiceLine) => {
			const lsDate: string = poLine.date as any as string;
			if (loInvoiceLinesByDates.has(lsDate))
				loInvoiceLinesByDates.set(lsDate, [...loInvoiceLinesByDates.get(lsDate), poLine]);
			else
				loInvoiceLinesByDates.set(lsDate, [poLine]);
		});

		loInvoiceLinesByDates.forEach((paInvoiceLines: InvoiceLine[], psDate: string) => {
			const loInvoiceSeance: InvoiceSeance = new InvoiceSeance(new Date(psDate));
			paInvoiceLines.forEach((poInvoiceLine: InvoiceLine) => {
				if (poInvoiceLine.acteId.startsWith(C_PREFIX_ACT))
					loInvoiceSeance.actes.push(poInvoiceLine);
				else if (EnumHelper.getValues(EMajorationType).includes(poInvoiceLine.acteId))
					loInvoiceSeance.majorations.push(poInvoiceLine);
				else
					loInvoiceSeance.indemnites.push(poInvoiceLine);
			});

			this.seances.push(loInvoiceSeance);
		});
		this.detectChanges();
	}

	public async action(): Promise<void> {
		const laReponseInvoices: Invoice[] = await this.isvcFacturation.forwardAction(this.patient._id, [this.invoice], FacturationService.arePatientAndAMOUpToDate(this.patient, this.couvertures.AMOP));

		if (!ArrayHelper.hasElements(laReponseInvoices))
			this.invoice = await this.isvcFacturation.getInvoice(this.invoice._id).toPromise();

		if (this.invoice.status.value === EInvoiceStatus.priced) {
			this.isvcFacturation.showErreursSVPopup(this.invoice);
		}

		//Permet de mettre à jour l'affichage du bouton de suppression du prescripteur
		this.contactsListParamsForPrescriptor.readOnly = !StringHelper.isBlank(this.invoice?.externalId) || this.invoice?.invoiceType !== EInvoiceType.facture;

		this.detectChanges();
	}

	public displayFsp(): void {
		this.isvcFacturation.displayFsp([this.invoice]);
	}

	public async onStsChanged(paNewSts: EFormuleSTS[]): Promise<void> {
		let leNewSts: EFormuleSTS = ArrayHelper.getFirstElement(paNewSts);

		if (leNewSts === "none" as EFormuleSTS)
			leNewSts = undefined;

		if (leNewSts !== this.moInvoice.sts) {
			if (this.moInvoice.status.value !== EInvoiceStatus.created) {
				const loResponse: IUiResponse<boolean> = await this.isvcUiMessage.showAsyncMessage<boolean>(new ShowMessageParamsPopup({
					header: "Attention !",
					message: "La modification de la formule STS nécessite une tarification. Voulez-vous vraiment continuer ?",
					buttons: [
						{ text: "Annuler", handler: UiMessageService.getFalsyResponse },
						{ text: "Continuer", handler: UiMessageService.getTruthyResponse }
					],
					backdropDismiss: false
				})).toPromise();

				if (loResponse.response) {
					this.moInvoice.sts = leNewSts;
					this.editInvoice();
					const loLoader = await (await this.isvcLoading.create("Tarification en cours")).present();
					this.moInvoice = await this.isvcFacturation.getInvoice(this.moInvoice._id).toPromise();
   				await this.isvcFacturation.priceInvoices([this.moInvoice]);
					await loLoader.dismiss();
				}
				else
					this.stsSelector.resetSelection(this.invoice.sts ?? "none");
			}
			else {
				this.moInvoice.sts = leNewSts;
				this.editInvoice();
			}
		}
	}

	public onAmcTPChanged(paValues: boolean[]) {
		const lbValue: boolean = ArrayHelper.getFirstElement(paValues);
		this.invoice.amcTp = lbValue;
		this.editInvoice();
	}

	public onAmoTPChanged(paValues: boolean[]) {
		const lbValue: boolean = ArrayHelper.getFirstElement(paValues);
		this.invoice.amoTp = lbValue;
		this.editInvoice();
	}

	public async cancelAction(): Promise<void> {
		await this.isvcFacturation.cancelAction(this.patient._id, [this.invoice]);
		this.ioRouter.navigateByUrl("/facturation");
	}

	public getLinesPrices(paLines: InvoiceLine[]): { honoraires: number, partAMO: number, partAMC: number, partPatient: number } {
		let lnHonoraires = 0;
		let lnPartAMO = 0;
		let lnPartAMC = 0;
		let lnPartPatient = 0;

		paLines.forEach((poLine: InvoiceLine) => {
			lnHonoraires += isNaN(poLine.honoraires) || poLine.honoraires === null ? 0 : poLine.honoraires;
			lnPartAMO += isNaN(poLine.partAMO) || poLine.partAMO === null ? 0 : poLine.partAMO;
			lnPartAMC += isNaN(poLine.partAMC) || poLine.partAMC === null ? 0 : poLine.partAMC;
			lnPartPatient += isNaN(poLine.partPatient) || poLine.partPatient === null ? 0 : poLine.partPatient;
		});

		return {
			honoraires: lnHonoraires,
			partAMO: lnPartAMO,
			partAMC: lnPartAMC,
			partPatient: lnPartPatient,
		};
	}

	private getEtablissements(): void {
		const lsIds: string[] = [];
		if (!StringHelper.isBlank(this.couvertures.AMOP?.amoId))
			lsIds.push(this.couvertures.AMOP.amoId);
		if (!StringHelper.isBlank(this.couvertures.AMCP?.amcId))
			lsIds.push(this.couvertures.AMCP.amcId);

		if (ArrayHelper.hasElements(lsIds))
			this.isvcCouvertures.getEtablissementsByIds(lsIds)
				.pipe(
					tap((paEtablissements: (IAMC | IAMO)[]) => {
						paEtablissements.forEach((poEtablissement: IAMC | IAMO) => this.etablissementsById.set(poEtablissement._id, poEtablissement));
						this.detectChanges();
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
	}

	getLabelCouverture(amoId: string): string | undefined {
		return this.isvcCouvertures.getLabelCouverture(amoId, this.etablissementsById)
	}

	public onDocumentsModelChanged(paDocuments: IGalleryFile[]): void {
		this.invoice.documents = [...(paDocuments ?? [])];
		this.editInvoice();
		this.detectChanges();
	}

	public getSeanceLines(poSeance: InvoiceSeance): InvoiceLine[] {
		return [...poSeance.actes, ...poSeance.majorations, ...poSeance.indemnites];
	}

	public getContactAvatar(poContact: IContact): IAvatar {
		return ContactsService.createContactAvatar(poContact, EAvatarSize.big);
	}

	public getFormuleSTSLabel(peFormuleSts: EFormuleSTS): string {
		switch (peFormuleSts) {
			case EFormuleSTS.fraisReelsPlafonnes:
				return "011 - Frais réels plafonnés";

			case EFormuleSTS.ticketModerateur:
				return "052 - 100% Ticket Modérateur";

			default:
				return "Aucune";
		}
	}

	public getBooleanLabel(pbValue: boolean): string {
		return pbValue ? "Oui" : "Non";
	}

	public getInvoiceLineAttributes<T>(poLines: InvoiceLine[], psAttributeName: string): T[] {
		return poLines
			.map((poLine: InvoiceLine) => poLine[psAttributeName]);
	}

	public getMajorationsDetail(poLines: InvoiceLine[]): string[] {
		return poLines
			.filter((poLine: InvoiceLine) => ![EMajorationType.NightFirstHalf, EMajorationType.SundayAndHolyday].includes(poLine.lettreCle as EMajorationType))
			.map((poLine: InvoiceLine) => poLine.lettreCle);
	}

	public getMajoIndemniteBsi(seance: InvoiceSeance): InvoiceSeance {
		return seance.actes.length === 0 && seance.indemnites.some(x => x.lettreCle === "IFI") ? seance : undefined;
	}

	public getFirstActeMajorationsDetail(poSeance: InvoiceSeance): string {
		const laActeMajoration: string[] = poSeance.majorations
			.filter((poLine: InvoiceLine) => [EMajorationType.NightFirstHalf, EMajorationType.SundayAndHolyday].includes(poLine.lettreCle as EMajorationType))
			.map((poLine: InvoiceLine) => poLine.lettreCle);

		if (ArrayHelper.hasElements(laActeMajoration)) {
			if (laActeMajoration.includes(EMajorationType.SundayAndHolyday))
				return "F";
			return "N";
		}
		return "";
	}

	public getIndemnitesDetail(poLines: InvoiceLine[]): string {
		return poLines
			.map((poLine: InvoiceLine) => `${poLine.lettreCle.startsWith("IK") ? poLine.quantity ?? "" : ""}${poLine.lettreCle}`)
			.join(" ");
	}

	public editInvoice(): void {
		this.moEditInvoiceQueuer.exec();
	}

	public editOrdonnance(): void {
		this.moEditOrdonnanceQueuer.exec();
	}

	public editCouverture(): void {
		this.moEditCouvertureQueuer.exec();
	}

	/** Crée et retourne les paramètres nécessaires pour le composant contactsList.
	 * @param peContactPrefix Préfixe à utiliser pour récupérer des contacts spécifiques.
	 * @param peContactType Indique pour quel type de contact on crée les paramètres.
	 * @param psSearchPlaceholder Placeholder de la barre de recherche du sélecteur de contacts.
	 * @param pbReadOnly Indique si le contact list doit être en lecture seule.
	 */
	private createContactsListParams(peContactPrefix: EPrefix, psSearchPlaceholder: string, pbReadOnly: boolean = false): IContactsListParams {
		return {
			contactsAsLinks: false,
			displayProperties: [],
			displayIcons: [],
			pageTitle: "Prescripteur",
			readOnly: pbReadOnly,
			contactsById: false,
			contactsSelectorParams: this.isvcContacts.createContactSelectorParams(peContactPrefix, psSearchPlaceholder),
			contactsContainer: this.traitement,
			addButtonIcon: "alert-circle-outline",
			addButtonText: "Non renseigné",
			addButtonColor: "danger",
			chooseAfterRemove: true
		};
	}

	public async onPrescriptorModelChanged(paSelectedContacts: Array<IIdelizyContact>): Promise<void> {
		const loFirstContact: IIdelizyContact = ArrayHelper.getFirstElement(paSelectedContacts);
		const lsFirstContactId: string = loFirstContact?._id;

		if (lsFirstContactId !== this.ordonnance.prescripteurContactId) {
			this.ordonnance.prescripteurContactId = lsFirstContactId;
			// this.ordonnance.facturationNumber = ArrayHelper.getFirstElement(loFirstContact?.facturationNumbers);
			await this.isvcOrdonnances.saveOrdonnance(this.traitement, this.ordonnance).toPromise();
		}
	}

	public exportInvoicePdf(): void {
		this.isvcFacturation.exportInvoicePdf(this.invoice);
	}

	//#endregion

}
