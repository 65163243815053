<div class="panneau-patient-allergies__container">
	<div class="container__content">
		<form [formGroup]="allergiesForm" (ngSubmit)="handleSubmit($event)">
			<div class="content__form-inputs">
				<div class="form-inputs">
					<h2 class="content__subtitle">Allergies</h2>
          <lua-input
          [fullWidth]="true"
          placeholder="Allergies"
          label="Allergies"
          formControlName="allergies"
        ></lua-input>
					<h2 class="content__subtitle">Antécédents</h2>
          <lua-input
          [fullWidth]="true"
          placeholder="Antécédents personnels"
          label="Antécédents personnels"
          formControlName="antecedentsPersonnels"
        ></lua-input>
        <lua-input
          [fullWidth]="true"
          placeholder="Antécédents familiaux"
          label="Antécédents familiaux"
          formControlName="antecedentsFamiliaux"
        ></lua-input>
        <lua-input
          [fullWidth]="true"
          placeholder="Facteurs de risques"
          label="Facteurs de risques"
          formControlName="facteursRisques"
        ></lua-input>
        <h2 class="content__subtitle">Vaccinations</h2>
        <lua-input
          [fullWidth]="true"
          placeholder="Vaccinations"
          label="Vaccinations"
          formControlName="vaccinations"
        ></lua-input>
				</div>
			</div>
			<div class="content__form-buttons">
				<div class="form-buttons__buttons">
					<lua-action-principale
						libelle="Enregistrer"
						[fullWidth]="true"
						(click)="handleSubmit($event)"
						[isDisabled]="allergiesForm.invalid"
					></lua-action-principale>
					<lua-bouton-texte
						[fullWidth]="true"
						libelle="Annuler"
						(click)="goBack()"
					></lua-bouton-texte>
				</div>
			</div>
		</form>
	</div>
</div>
