<div class="liste-users">
	<ng-container *ngIf="users && users.length > 0">
		<div *ngFor="let user of users" (click)="setContactSelectedItem(user)">
			<div class="content__item" [ngClass]="getUserClasses(user._id)">
				<lua-avatar size="medium" [color]="getAvatarColor(user)" [firstName]="user.firstName" [lastName]="user.lastName"
					[withTooltip]="false"></lua-avatar>
				<strong class="displayName">{{ user.lastName }} {{ user.firstName }}</strong>
			</div>
			<lua-divider-horizontal size="small"></lua-divider-horizontal>
		</div>
	</ng-container>
</div>