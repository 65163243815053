import { Component, Input, OnInit } from "@angular/core";
import { IContact } from "@osapp/model";

@Component({
	selector: "di-infirmier",
	templateUrl: "./infirmier.component.html",
	styleUrls: ["./infirmier.component.scss"],
})
export class InfirmierComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {
		if(!this.infirmier)
		{
			this.initInfirmier();
		}
	}

	@Input() infirmier: IContact
	@Input() color: string = "";
	@Input() date: string = "";
	@Input() time: string = "";
	@Input() showMenu: boolean = true;
	@Input() disabledIcon: boolean = false;
	@Input() toggleMenu!: () => void;

	private initInfirmier() {
		this.infirmier = { _id: "", firstName: "", lastName: "" ,avatarCouleur : "Noir20"};
	}

	getAvatarColor() {
		return this.infirmier.avatarCouleur ?? 'CouleurPrimaire';
  }
}
