import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DateTimePickerComponent } from '@osapp/components/date/dateTimePicker.component';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { UserHelper } from '@osapp/helpers/user.helper';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { IDateTimePickerParams } from '@osapp/model/date/IDateTimePickerParams';
import { IUiResponse } from '@osapp/model/uiMessage/IUiResponse';
import { Observation } from '@osapp/modules/observations/models/observation';
import { Queue } from '@osapp/modules/utils/queue/decorators/queue.decorator';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { Acte } from '../../../model/Acte';
import { Majoration } from '../../../model/Majoration';
import { Seance } from '../../../model/Seance';
import { Traitement } from '../../../model/Traitement';
import { TraitementSlideParentBase } from '../../../model/TraitementSlideParentBase';
import { SeanceService } from '../../../services/seance.service';
import { TraitementService } from '../../../services/traitement.service';
import { ActesService } from '../../actes/actes.service';
import { IPatient } from '../../patients/model/IPatient';
import { PatientsService } from '../../patients/services/patients.service';
import { Indemnite } from '../model/Indemnite';

type ActeWithCheckStatus = { acte: Acte, checked: boolean };
type MajorationWithCheckStatus = { majoration: Majoration, checked: boolean };
type IndemniteWithCheckStatus = { indemnite: Indemnite, checked: boolean };

enum EChangeActeChoice {
	traitement = "traitement",
	seances = "seances"
}

@Component({
	selector: "validate-actes",
	templateUrl: './validate-actes.component.html',
	styleUrls: ['./validate-actes.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidateActesComponent extends ComponentBase implements OnInit {

	//#region FIELDS

	private mbInitialized: boolean;
	/** Indique si on doit montrer les boutons d'action ou non, `true` par défaut. */
	private mbShowActionButtons = true;
	/** Indique si on doit montrer les checkbox ou non, `true` par défaut. */
	private mbShowCheckbox = true;

	@Output("onSeancesChanged") private readonly moOnSeancesChangedEventEmitter = new EventEmitter<Seance[]>();
	@Output("onSeanceValidateAndBilled") private readonly moOnSeanceValidateAndBilledEventEmitter = new EventEmitter<Seance[]>();
	@Output("onTraitementChanged") private readonly moOnTraitementChangedEventEmitter = new EventEmitter<Traitement>();
	@Output("onObservationChanged") private readonly moOnObservationChangedEventEmitter = new EventEmitter<Observation>();
	@Output("onSeanceMoved") private readonly moOnSeanceMovedEventEmitter = new EventEmitter<Date>();
	@Output("onNavigated") private readonly moOnNavigatedEventEmitter = new EventEmitter<void>();
	@Output("onAddActeClick") private readonly moOnAddActClickdEventEmitter = new EventEmitter();

	//#endregion

	//#region PROPERTIES

	private moSeance: Seance;
	public get seance(): Seance {
		return this.moSeance;
	}
	@Input() public set seance(poSeance: Seance) {
		if (poSeance) {
			this.moSeance = poSeance;
			this.isOngoing = SeanceService.isOngoingSeance(poSeance);
			this.updateMajorationsAndIndemnites();
		}
		this.detectChanges();
	}

	private mbReadonly: boolean;
	/** Indique si on est en mode visu ou non (visu = séance non bloquée et utilisateur est intervenant et statut séance renseigné et statut séance non 'pending'). */
	public get readonly(): boolean {
		return this.mbReadonly || this.seance.isProtected;
	}
	@Input() public set readonly(pbReadonly: boolean) {
		if (pbReadonly !== this.mbReadonly) {
			this.mbReadonly = pbReadonly;

			if (this.mbInitialized)
				this.detectChanges();
		}
	}

	@Input() public seances: Seance[];
	@Input() public traitement: Traitement;
	/** Observation de la séance */
	@Input() public observation: Observation;
	/** Indique si on doit montrer les majorations et les indemnités ou non, `false` par défaut. */
	@Input() public showMajorationsAndIndemnites: boolean;
	/** Indique si on doit montrer les boutons d'action ou non, `true` par défaut. */
	public get showActionButtons(): boolean { return this.mbShowActionButtons; }
	@Input() public set showActionButtons(pbShowActionButtons: boolean) { this.mbShowActionButtons = coerceBooleanProperty(pbShowActionButtons); }
	/** Indique si on doit afficher les checkbox ou non, `true` par défaut. */
	public get showCheckbox(): boolean { return this.mbShowCheckbox; }
	@Input() public set showCheckbox(pbShowCheckbox: boolean) {
		if (pbShowCheckbox !== this.mbShowCheckbox) {
			this.mbShowCheckbox = coerceBooleanProperty(pbShowCheckbox);
			this.detectChanges();
		}
	}

	public get areAllSelected(): boolean {
		return this.actesWithCheckStatus.every((poItem: ActeWithCheckStatus) => poItem.checked || poItem.acte.canceled) &&
			this.majorationsWithCheckStatus.every((poItem: MajorationWithCheckStatus) => poItem.checked || poItem.majoration.disabled) &&
			this.indemnitesWithCheckStatus.every((poItem: IndemniteWithCheckStatus) => poItem.checked || poItem.indemnite.disabled);
	}
	public set areAllSelected(pbAreAllSelected: boolean) {
		this.actesWithCheckStatus.forEach((poItem: ActeWithCheckStatus) => {
			if (!poItem.acte.canceled) // Seul les actes non annulés peuvent être sélectionnés.
				poItem.checked = pbAreAllSelected;
		});
		this.majorationsWithCheckStatus.forEach((poItem: MajorationWithCheckStatus) => {
			if (!poItem.majoration.disabled)
				poItem.checked = pbAreAllSelected;
		});
		this.indemnitesWithCheckStatus.forEach((poItem: IndemniteWithCheckStatus) => {
			if (!poItem.indemnite.disabled)
				poItem.checked = pbAreAllSelected;
		});

		this.detectChanges();
	}

	/** Retourne `true` si des éléments (actes, majorations, indemnités) sont présents. */
	public get hasElements(): boolean {
		return this.actesWithCheckStatus.length > 0 || this.majorationsWithCheckStatus.length > 0 || this.indemnitesWithCheckStatus.length > 0;
	}
	/** Retourne la longueur de toutes les listes. */
	public get itemsLength(): number {
		return this.actesWithCheckStatus.length + this.majorationsWithCheckStatus.length + this.indemnitesWithCheckStatus.length;
	}

	public readonly C_DATETIME_FORMAT = ETimetablePattern.dd_MM_yyyy_HH_mm_slash;

	public dateTimePickerParams: IDateTimePickerParams = DateHelper.datePickerParamsFactory(this.C_DATETIME_FORMAT);
	/** Tableau d'objets comprenant un acte et un état coché pour chaque élément. */
	public actesWithCheckStatus: ActeWithCheckStatus[] = [];
	/** Tableau d'objets comprenant une majoration et un état coché pour chaque élément. */
	public majorationsWithCheckStatus: MajorationWithCheckStatus[] = [];
	/** Tableau d'objets comprenant une indemnité et un état coché pour chaque élément. */
	public indemnitesWithCheckStatus: IndemniteWithCheckStatus[] = [];
	public seanceStartDate: Date;
	/** Indique si la séance est en cours ou non. */
	public isOngoing: boolean;
	public readonly C_UNDEFINED_ACT_ID = ActesService.C_UNDEFINED_ACT_ID;

	@ViewChild("seanceDateTimePicker", { read: DateTimePickerComponent, static: true })
	public readonly seanceDateTimePicker: DateTimePickerComponent;

	//#endregion

	//#region METHODS

	constructor(
		private isvcSeance: SeanceService,
		private isvcUiMessage: UiMessageService,
		private isvcTraitement: TraitementService,
		private isvcActes: ActesService,
		private isvcPatients: PatientsService,
		poChangeDetector: ChangeDetectorRef
	) {

		super(poChangeDetector);
	}

	public ngOnInit(): void {
		this.onSeancesChanged();

		this.mbInitialized = true;
	}

	public applyNewObservation(): void {
		this.observation.description = this.observation.description.trim();
		this.observation.lastUpdateDate = new Date();
		this.observation.lastUpdateContactId = UserHelper.getUserContactId();

		this.moOnObservationChangedEventEmitter.emit(this.observation);
	}

	private onSeancesChanged(paSeances?: Seance[]): void {
		if (ArrayHelper.hasElements(paSeances)) {
			this.seance = paSeances.find((poSeance: Seance) => poSeance.equals(this.seance));
			this.seances = paSeances;
		}

		this.seanceStartDate = this.seance.startDate;

		this.actesWithCheckStatus = this.seance.actes.map((poActe: Acte) => ({
			acte: poActe,
			checked: this.actesWithCheckStatus.find((poActeWithCheckStatus: ActeWithCheckStatus) => poActeWithCheckStatus.acte.guid === poActe.guid)?.checked ?? false
		}));

		this.detectChanges();
	}

	public onSelectAllChanged(): void {
		this.areAllSelected = !this.areAllSelected;
		this.detectChanges();
	}

	public onItemSelectionChanged(poItem: ActeWithCheckStatus | MajorationWithCheckStatus | IndemniteWithCheckStatus): void {
		poItem.checked = !poItem.checked;
	}

	public onValidateClicked(): void {
		let lsMessage: string;

		if (!this.seance.scheduled)
			lsMessage = "Veuillez planifier la séance avant de la valider.";
		else if (!this.areAllSelected)
			lsMessage = "Veuillez cocher les actes réalisés et annuler ou déplacer les autres afin de valider la séance.";

		if (!StringHelper.isBlank(lsMessage))
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: lsMessage }));
	}

	public onValidateSeanceClicked(pbBill?: boolean): void {
		this.validateSeance(pbBill).subscribe();
	}

	@Queue({ paramsReducer: (_, paNewArgs: any[]) => paNewArgs })
	private validateSeance(pbBill?: boolean): Observable<Seance> {
		return this.isvcSeance.validateSeance(this.seance, this.traitement).pipe(
			tap(() => {
				if (pbBill)
					this.moOnSeanceValidateAndBilledEventEmitter.emit(this.seances);
				else
					this.raiseSeancesChanged();
				this.detectChanges();
			})
		);
	}

	public onSeanceMoved(psNewISODate: string): void {
		this.seanceStartDate = new Date(psNewISODate);
		this.moOnSeanceMovedEventEmitter.emit(this.seanceStartDate);
		this.detectChanges();
	}

	public onCancelSeanceClicked(): void {
		this.cancelSeance().subscribe();
	}

	@Queue({ paramsReducer: (_, paNewArgs: any[]) => paNewArgs })
	private cancelSeance(): Observable<Seance> {
		return this.isvcTraitement.cancelDaySeances(
			{ day: this.seance.startDate, hours: this.seance.startDate.getHours(), minutes: this.seance.startDate.getMinutes() },
			[this.seance],
			this.traitement
		).pipe(
			tap(() => {
				this.raiseSeancesChanged();
				this.detectChanges();
			}),
			map((paSeances: Seance[]) => ArrayHelper.getFirstElement(paSeances))
		);
	}

	public refresh(): void {
		this.onSeancesChanged();
	}

	public moveSeance(): void {
		this.seanceDateTimePicker.pickDate();
	}

	//#region Actes

	public addAct(): void {
		this.moOnAddActClickdEventEmitter.emit();
	}

	/** Permet de déplacer un acte.
	 * @param poSeance
	 * @param poActe
	 */
	public moveAct(poActe: Acte): void {
		this.isvcSeance.moveActeWithModal(this.seance, this.seances, poActe, this.traitement)
			.pipe(
				tap((paSeances: Seance[]) => {
					this.onSeancesChanged(paSeances);
					this.moOnTraitementChangedEventEmitter.emit(this.traitement);
					this.raiseSeancesChanged();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Permet de supprimer un acte.
	 * @param poActe
	 */
	public cancelAct(poActe: Acte): void {
		this.isvcTraitement.cancelActeWithModal(this.seance, this.traitement, this.seances, poActe)
			.pipe(
				tap((paSeances: Seance[]) => {
					this.onSeancesChanged(paSeances);
					this.moOnTraitementChangedEventEmitter.emit(this.traitement);
					this.raiseSeancesChanged();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public changeActe(poActe: Acte): void {
		if (this.isvcActes.canChangeActe(poActe._id)) {
			this.isvcActes.selectActeWithModal()
				.pipe(
					mergeMap((poNewActe: Acte) => {
						return this.isvcUiMessage.showAsyncMessage(new ShowMessageParamsPopup({
							inputs: [
								{ type: "radio", value: EChangeActeChoice.traitement, label: "Pour tout le traitement", checked: true },
								{ type: "radio", value: EChangeActeChoice.seances, label: "Cette séance jusqu'à la fin du traitement." }
							],
							buttons: [
								{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() },
								{ text: "Continuer", handler: () => UiMessageService.getTruthyResponse() }
							],
							header: "Modifier l'acte",
							cssClass: "edit-acte-alert"
						}))
							.pipe(
								filter((poResponse: IUiResponse<boolean, string>) => poResponse.response),
								tap((poResponse: IUiResponse<any, EChangeActeChoice>) => {
									const lnActeIndex: number = this.traitement.actes.findIndex((poTraitementActe: Acte) => poActe.guid === poTraitementActe.guid);

									if (poResponse.values === EChangeActeChoice.seances)
										this.isvcTraitement.replaceActeFrom(this.traitement, this.seance, this.seances, this.traitement.actes[lnActeIndex], poNewActe);
									else if (poResponse.values === EChangeActeChoice.traitement)
										this.isvcTraitement.replaceActe(this.traitement, this.seances, lnActeIndex, this.traitement.actes[lnActeIndex], poNewActe);
								})
							);
					}),
					mergeMap(() => this.isvcPatients.getPatient(this.traitement.patientId)),
					mergeMap((poPatient: IPatient) => this.isvcSeance.generateSeances(this.traitement, poPatient, true, true)),
					tap((paSeances: Seance[]) => {
						this.onSeancesChanged(paSeances);
						this.moOnTraitementChangedEventEmitter.emit(this.traitement);
						this.raiseSeancesChanged();
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		};
	}

	//#endregion

	//#region Majorations

	/** Navigue vers la slide 'Majorations' dans le circuit du traitement. */
	public goToMajoration(): void {
		this.isvcTraitement.openTraitement(this.seance.traitementId, [TraitementSlideParentBase.C_MAJORATIONS_SLIDE_ID], { autoFocus: this.seance.startDate });
		this.moOnNavigatedEventEmitter.emit();
	}

	/** Supprime une majoration de la séance.
	 * @param poMajoration
	 * @param pnMajorationIndex Index de la majoration à supprimer.
	 */
	public removeMajoration(poMajoration: Majoration, pnMajorationIndex: number): void {
		this.isvcTraitement.addMajoration(this.traitement, [this.seance], {
			createDate: new Date,
			createUserContactId: UserHelper.getUserContactId(),
			id: poMajoration.type,
			disabled: true,
			matcher: this.seance.startDate
		}, poMajoration);
		ArrayHelper.removeElementByIndex(this.majorationsWithCheckStatus, pnMajorationIndex);
		this.raiseSeancesChanged();
	}

	//#endregion

	//#region Indemnités

	/** Navigue vers la slide 'Indemnités' dans le circuit du traitement. */
	public goToIndemnite(): void {
		this.isvcTraitement.openTraitement(this.seance.traitementId, [TraitementSlideParentBase.C_INDEMNITES_SLIDE_ID]);
		this.moOnNavigatedEventEmitter.emit();
	}

	/** Supprime une indemnité de la séance.
	 * @param poIndemnite
	 * @param pnIndemniteIndex Index de l'indemnité à supprimer.
	 */
	public removeIndemnite(poIndemnite: Indemnite, pnIndemniteIndex: number): void {
		this.isvcTraitement.addIndemnite(this.traitement, [this.seance], {
			createDate: new Date,
			createUserContactId: UserHelper.getUserContactId(),
			id: poIndemnite.type,
			disabled: true,
			matcher: this.seance.startDate
		}, []);
		ArrayHelper.removeElementByIndex(this.indemnitesWithCheckStatus, pnIndemniteIndex);
		this.raiseSeancesChanged();
	}

	private raiseSeancesChanged(): void {
		this.moOnSeancesChangedEventEmitter.emit(this.seances);
	}

	private updateMajorationsAndIndemnites(): void {
		const laTmpMajorationsWithCheckStatus: MajorationWithCheckStatus[] = Array.from(this.majorationsWithCheckStatus);
		const laTmpIndemnitesWithCheckStatus: IndemniteWithCheckStatus[] = Array.from(this.indemnitesWithCheckStatus);

		this.majorationsWithCheckStatus = this.seance.majorations.filter((poMajoration: Majoration) => !poMajoration.disabled).map((poMajoration: Majoration) => {
			const loLastMajoration: MajorationWithCheckStatus = laTmpMajorationsWithCheckStatus.find((poItem: MajorationWithCheckStatus) => poItem.majoration.id === poMajoration.id);
			return { majoration: poMajoration, checked: loLastMajoration?.checked ?? false } as MajorationWithCheckStatus;
		});

		this.indemnitesWithCheckStatus = this.seance.indemnites.filter((poIndemnite: Indemnite) => !poIndemnite.disabled).map((poIndemnite: Indemnite) => {
			const loLastIndemnite: IndemniteWithCheckStatus = laTmpIndemnitesWithCheckStatus.find((poItem: IndemniteWithCheckStatus) => poItem.indemnite.id === poIndemnite.id);
			return { indemnite: poIndemnite, checked: loLastIndemnite?.checked ?? false } as IndemniteWithCheckStatus;
		});
	}

	//#endregion

	//#endregion
}