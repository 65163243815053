import { Injectable } from '@angular/core';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { StoreHelper } from '../../../helpers/storeHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { IEntity } from '../../../model/entities/IEntity';
import { WorkspaceService } from '../../../services/workspace.service';
import { DmsFileHelper } from '../helpers/dmsFileHelper';
import { IDmsMeta } from './IDmsMeta';
import { IMetaEntityBuilderBase } from './IMetaEntityBuilderBase';

@Injectable()
export class MetaEntityBuilderBase implements IMetaEntityBuilderBase {

	//#region METHODS

	constructor(protected readonly isvcWorkspace: WorkspaceService) { }

	/** @implements */
	public match(psDocumentId: string): boolean {
		return true; // Pour le builder de base, tous les documents correspondent.
	}

	/** @implements */
	public async prepareMeta(poEntity: IEntity, poMeta: IDmsMeta): Promise<IDmsMeta> {
		if (!ArrayHelper.hasElements(poMeta.attributes)) {
			poMeta.attributes = [
				{ name: "wsId", value: this.getEntityContainerId(poEntity) },
				{ name: "entityId", value: await this.getEntityId(poEntity) },
				{ name: `${IdHelper.getPrefixFromId(poEntity.id).replace("_", "")}Id`, value: poEntity.id }, // Le nom de l'attribut est généré à partir du préfix (ex. : cont_ => contId, conv_ => convId)
				{ name: "SUBTYPE", value: this.getEntityDocumentSubType(poEntity) }
			];
		}

		if (StringHelper.isBlank(poMeta.documentType))
			poMeta.documentType = this.getEntityDocumentType(poEntity);

		return poMeta;
	}

	/** Permet de récupérer l'identifiant de l'entité qui sera considérée comme propriétaire du document.
	 * @param poEntity
	 * @returns
	 */
	protected getEntityId(poEntity: IEntity): Promise<string> {
		return Promise.resolve(poEntity.id);
	}

	/** Permet de récupérer le type de document.
	 * @param poEntity
	 * @returns
	 */
	protected getEntityDocumentType(poEntity: IEntity): string {
		return DmsFileHelper.getDefaultDocumentType();
	}

	/** Permet de récupérer le type de document.
	 * @param poEntity
	 * @returns
	 */
	protected getEntityDocumentSubType(poEntity: IEntity): string {
		return "DIV";
	}

	/** Permet de récupérer l'identifiant du conteneur de l'entité propriétaire du document.
	 * @param poEntity
	 * @returns
	 */
	protected getEntityContainerId(poEntity: IEntity): string {
		return this.isvcWorkspace.getWorkspaceIdFromModel(poEntity.model) ??
			ArrayHelper.getFirstElement(StoreHelper.getDatabaseRoles(poEntity.databaseName));
	}

	//#endregion

}
