import { Injectable } from '@angular/core';
import { IdHelper } from '@osapp/helpers/idHelper';
import { IEntity } from '@osapp/model/entities/IEntity';
import { MetaEntityBuilderBase } from '@osapp/modules/dms/model/MetaEntityBuilderBase';
import { C_PREFIX_TOURNEE } from '../../../app/app.constants';

@Injectable()
export class MetaTourneeBuilder extends MetaEntityBuilderBase {

	//#region METHODS

	/** @override */
	public match(psDocumentId: string): boolean {
		return IdHelper.hasPrefixId(psDocumentId, C_PREFIX_TOURNEE);
	}

	/** @override */
	public getEntityContainerId(poEntity: IEntity): string {
		return this.isvcWorkspace.getWorkspaceIdFromDatabaseId(this.isvcWorkspace.getDefaultWorkspaceDatabaseId());
	}

	//#endregion

}
