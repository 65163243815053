import { NumberHelper } from '@osapp/helpers';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { GuidHelper } from '@osapp/helpers/guidHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { DayRepetition } from '@osapp/modules/event-markers/models/day-repetition';
import { EDuree } from '@osapp/modules/event-markers/models/eduree';
import { HoursMinutesRepetition } from '@osapp/modules/event-markers/models/hours-minutes-repetition';
import { IRecurrence } from '@osapp/modules/event-markers/models/irecurrence';
import { RangeRepetition } from '@osapp/modules/event-markers/models/range-repetition';
import { Recurrence } from '@osapp/modules/event-markers/models/recurrence';
import { ResolveModel } from '@osapp/modules/utils/models/decorators/resolve-model.decorator';
import { ModelResolver } from '@osapp/modules/utils/models/model-resolver';
import { classToPlain, Exclude, Expose } from "class-transformer";
import { EEtatActe } from '../anakin/features/shared/enums/EEtatActe';
import { Constraint } from '../modules/actes/model/constraint';
import { IConstraint } from '../modules/actes/model/iconstraint';
import { ICotationRule } from '../modules/actes/model/ICotationRule';
import { IntervenantConstraint } from '../modules/actes/model/intervenant-constraint';
import { WeekRepetition } from '../modules/actes/model/week-repetition';
import { ActeModalEditBinding } from './ActeModalEditBinding';
import { EPlace } from './EPlace';
import { IActe } from './IActe';
import { IActeConstraint } from './IActeConstraint';

export class Acte implements IActe {

	//#region PROPERTIES

	/** @override */
	public _id: string;
	/** @implements */
	public _rev?: string;
	/** @implements */
	public readonly id: string;
	/** @implements */
	public readonly initialPriceCoefficient: number;
	/** @implements */
	public readonly isMciEligible: boolean;
	/** @implements */
	public isPriorAgreement: boolean;
	/** @implements */
	public readonly jobs: Array<number>;
	@Expose()
	/** @implements */
	public get label(): string { return StringHelper.isBlank(this.personalizedLabel) ? this.ngapLabel : this.personalizedLabel; };
	/** @implements */
	public ngapLabel: string;
	/** @implements */
	public personalizedLabel?: string;
	/** @implements */
	public tags: string;
	/** @implements */
	public readonly type: string;
	/** @implements */
	public readonly guid: string;

	/**
	 * @implements
	 * @deprecated
	 */
	public acteModalEditBinding: ActeModalEditBinding;
	/** @implements */
	public keyLetters: string;
	/** @implements */
	public initialPrice: number;
	/** Indique si la cpam prend en charge l'acte à 100% ou non (ald exonérante) */
	/** @implements */
	public isAldExonerante: boolean;
	/** @implements */
	public price: number;
	/** @implements */
	public priceCoefficient: number;
	@Exclude()
	private mnTaxAllowance: number;
	@Expose()
	/** @implements */
	public get taxAllowance(): number { return this.mnTaxAllowance; }
	public set taxAllowance(pnValue: number) {
		if (!isNaN(pnValue) && pnValue >= 0 && pnValue !== this.mnTaxAllowance) {
			this.mnTaxAllowance = pnValue;
			this.calculatePrice();
		}
	}
	@Exclude()
	private mePlace: EPlace;
	@Expose()
	/** @implements */
	public get place(): EPlace { return this.mePlace ?? EPlace.home; }
	public set place(peValue: EPlace) { this.mePlace = peValue; }
	@Exclude()
	private mnDuration: number;
	@Expose()
	/** @implements */
	public get duration(): number { return this.mnDuration ?? 15; }
	public set duration(pnValue: number) { this.mnDuration = pnValue; }
	/** @implements */
	public canceled?: boolean;
	/** @implements */
	public desmosId?: string;
	/** @implements */
	public externalId?: string;
	/** @implements */
	@ResolveModel(Constraint)
	public constraints: Constraint[];
	@Exclude()
	private mbSundayAndPublicHolidays = true;
	/** @implements */
	@Expose()
	public get sundayAndPublicHolidays(): boolean {
		return this.mbSundayAndPublicHolidays;
	}
	public set sundayAndPublicHolidays(pbSundayAndPublicHolidays: boolean) {
		if (pbSundayAndPublicHolidays !== this.mbSundayAndPublicHolidays) {
			this.mbSundayAndPublicHolidays = pbSundayAndPublicHolidays;
			this.recurrences.forEach((poRecurrence: Recurrence) => poRecurrence.sundayAndPublicHolidays = this.mbSundayAndPublicHolidays);
		}
	}
	/** @implements */
	public startDate?: Date;
	/** @implements */
	public endDate?: Date;
	/** @implements */
	public observations?: string;
	/**
	 * @implements
	 * @deprecated
	 */
	public cotationRules?: ICotationRule[];
	/** @implements */
	public startOffset?: number;
	/** @implements */
	public endOffset?: number;
	/** @implements */
	public usePlanning?: boolean;
	/** @implements */
	public isPackage?: boolean;
	/** @implements */
	public packageId?: string;
	/** @implements */
	public cotationRulesIds?: string[];
	/** @implements */
	@Exclude()
	public excludeFromTaxAllowance?: boolean;
	/** Référence de l'acte personnalisé. */
	@Exclude()
	public personalizedActeId?: string;
	/** @implements */
	public extraCharge?: number;
	/** @implements */
	public codeExoneration?: string;
	/** Force l'application des règles de cotations même dans un package. */
	@Exclude()
	public forceRullInPackage?: boolean;
	@Exclude()
	private moWeekRepetition: WeekRepetition;
	@Expose()
	/** @implements */
	public get weekRepetition(): WeekRepetition {
		return this.moWeekRepetition;
	}
	public set weekRepetition(poWeekRepetition: WeekRepetition) {
		if (poWeekRepetition !== this.moWeekRepetition)
			this.moWeekRepetition = poWeekRepetition;
	}
	/** @implements */
	public chapters?: string[];
	/** @implements */
	public acteAMX?: string;
	/** @implements */
	public acteAMI?: string;
	/** @implements */
	public excludeMajorations?: string[];
	/** @implements */
	public recurrences: Recurrence[];

	@Expose()
	/** Description de l'acte (Lettre clé coefficient label). */
	public get description(): string {
		return `${this.keyLetters} ${this.priceCoefficient} ${this.label}`;
	}

	public etat?: EEtatActe = EEtatActe.to_be_done;

	//#endregion

	//#region METHODS

	/** Construit un acte.
	 * @param poData
	 */
	constructor(poData?: IActe) {
		this._rev = poData?._rev;
		this.id = poData?.id;
		this._id = poData?._id ?? this.id;
		this.guid = poData?.guid ?? GuidHelper.newGuid();
		this.taxAllowance = poData?.taxAllowance ?? (poData as any)?.mnTaxAllowance ?? 1;
		this.ngapLabel = poData?.ngapLabel;
		this.initialPriceCoefficient = poData?.initialPriceCoefficient;
		this.priceCoefficient = poData?.priceCoefficient ?? poData?.initialPriceCoefficient;
		this.price = poData?.price ?? 0;
		// si on a un tableau, ok. Si on a un string on en fait un tableau de strings.
		this.keyLetters = (poData?.keyLetters as any) instanceof Array ? ArrayHelper.getFirstElement((poData.keyLetters as any)) : poData?.keyLetters;
		this.isPriorAgreement = poData?.isPriorAgreement;
		this.isMciEligible = poData?.isMciEligible;
		this.tags = poData?.tags;
		this.jobs = poData?.jobs;
		this.isAldExonerante = poData?.isAldExonerante ?? false;
		this.initialPrice = poData?.initialPrice ?? this.price;
		this.type = "acte";
		this.cotationRules = poData?.cotationRules;
		this.canceled = poData?.canceled;
		this.externalId = poData?.externalId;
		this.recurrences = poData?.recurrences?.map((poRecurrence: IRecurrence) => ModelResolver.toClass(poRecurrence, Recurrence)) ?? [];
		this.constraints = poData?.constraints?.map((poConstraint: IConstraint) => {
			if (StringHelper.isBlank(poConstraint.type))
				return this.retroCompatConstraints(poConstraint as any);
			else if (poConstraint instanceof Constraint)
				return ModelResolver.toClass(classToPlain(poConstraint), Constraint);
			else
				return ModelResolver.toClass(poConstraint, Constraint);
		}).filter((poConstraint: Constraint) => !!poConstraint) ?? [];

		if (poData?.acteModalEditBinding) {
			const loRecurrence: Recurrence = ArrayHelper.getFirstElement(this.recurrences) ?? new Recurrence;
			loRecurrence.durationValue = poData.acteModalEditBinding.durationValue;
			loRecurrence.durationType = poData.acteModalEditBinding.durationType;
			loRecurrence.repetitionType = poData.acteModalEditBinding.repetitionType;
			loRecurrence.repetitionValue = poData.acteModalEditBinding.repetitionValue;
			ArrayHelper.pushIfNotPresent(this.recurrences, loRecurrence);
		}

		this.sundayAndPublicHolidays = poData?.sundayAndPublicHolidays ?? true;
		this.recurrences.forEach((poRecurrence: Recurrence) => poRecurrence.sundayAndPublicHolidays = this.sundayAndPublicHolidays);
		this.startDate = DateHelper.isDate(poData?.startDate) ? new Date(poData.startDate) : undefined;
		this.endDate = DateHelper.isDate(poData?.endDate) ? new Date(poData.endDate) : undefined;
		this.observations = poData?.observations;
		this.place = poData?.place ?? (poData as any)?.mePlace;
		this.usePlanning = poData?.usePlanning ?? true;
		this.isPackage = poData?.isPackage;
		this.packageId = poData?.packageId;
		this.cotationRulesIds = poData?.cotationRulesIds;
		this.excludeFromTaxAllowance = poData?.excludeFromTaxAllowance;
		this.personalizedLabel = poData?.personalizedLabel;
		this.duration = poData?.duration;
		this.startOffset = poData?.startOffset;
		this.endOffset = poData?.endOffset;
		this.extraCharge = poData?.extraCharge;
		this.weekRepetition = new WeekRepetition(poData?.weekRepetition);
		this.codeExoneration = poData?.codeExoneration;
		this.chapters = ArrayHelper.hasElements(poData?.chapters) ? poData?.chapters : [];
		this.acteAMX = poData?.acteAMX;
		this.acteAMI = poData?.acteAMI;
		this.excludeMajorations = poData?.excludeMajorations;
		this.calculatePrice();
	}

	/** Permet de faire coorespondre l'ancien modèle de contraintes avec le nouveau.
	 * @param poOldConstraint
	 */
	private retroCompatConstraints(poOldConstraint: IActeConstraint): Constraint { // TODO TB : Supprimer quand inutile.
		const loRecurrence: Recurrence = ArrayHelper.getFirstElement(this.recurrences) ?? new Recurrence;

		if (poOldConstraint?.range) {
			loRecurrence.dayRepetitions.push(new RangeRepetition({
				from: poOldConstraint.range.from,
				to: poOldConstraint.range.to
			}));
		}
		else if (poOldConstraint.time) {
			loRecurrence.dayRepetitions.push(new HoursMinutesRepetition({
				hours: poOldConstraint.time.hours,
				minutes: poOldConstraint.time.minutes
			}));
		}
		else
			loRecurrence.dayRepetitions.push(new DayRepetition);

		ArrayHelper.pushIfNotPresent(this.recurrences, loRecurrence);

		if (poOldConstraint.intervenantIds) {
			const loConstraint = new IntervenantConstraint;

			loConstraint.intervenantIds = poOldConstraint.intervenantIds;
			return loConstraint;
		}
		return undefined;
	}

	/** Calcule le prix de l'acte en fonction de la lettre-clé. */
	public calculatePrice(): void {
		this.price = +(this.initialPrice * this.priceCoefficient * this.mnTaxAllowance);
		this.price = +NumberHelper.round(this.price, 2)
		this.price += this.extraCharge ?? 0;
	}

	public equals(poOtherActe: Acte): boolean {
		return this === poOtherActe || ((this.isAldExonerante === poOtherActe.isAldExonerante &&
			this.usePlanning === poOtherActe.usePlanning &&
			DateHelper.compareTwoDates(this.startDate, poOtherActe.startDate) === 0 &&
			this.observations === poOtherActe.observations &&
			this.place === poOtherActe.place &&
			this.sundayAndPublicHolidays === poOtherActe.sundayAndPublicHolidays &&
			ArrayHelper.areArraysEqual(this.constraints, poOtherActe.constraints,
				(poConstraintA: Constraint, poConstraintB: Constraint) => ObjectHelper.areEquals(poConstraintA, poConstraintB))) &&
			ArrayHelper.areArraysEqual(this.recurrences, poOtherActe.recurrences,
				(poRecurrenceA: Recurrence, poRecurrenceB: Recurrence) => ObjectHelper.areEquals(poRecurrenceA, poRecurrenceB)) &&
			WeekRepetition.equals(this.weekRepetition, poOtherActe.weekRepetition));
	}

	public recurrencesValid(): boolean {
		return this.recurrences.some((poRecurrence: Recurrence) => {
			return poRecurrence.durationType && poRecurrence.repetitionType &&
				(
					((poRecurrence.durationType != EDuree.jusquADate && poRecurrence.durationType != EDuree.dates) && (poRecurrence.durationValue && poRecurrence.repetitionValue))
					|| (poRecurrence.durationType == EDuree.dates && poRecurrence.repetitionValue === undefined && Array.isArray(poRecurrence.durationValue) && poRecurrence.durationValue.length > 0)
					|| (poRecurrence.durationType == EDuree.jusquADate && poRecurrence.repetitionValue && (Array.isArray(poRecurrence.durationValue) && poRecurrence.durationValue.length > 0))
				)
		});
	}

	//#endregion
}
