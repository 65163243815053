import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'contacts-book-page',
  templateUrl: './contactsBookPage.component.html'
})
export class ContactsBookPageComponent implements OnInit {
  //#region FIELDS
  //#endregion

  //#region PROPERTIES
  //#endregion

  //#region METHODS
  constructor() {
    
  }

  public ngOnInit(): void {
    
  }
  //#endregion
}