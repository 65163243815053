import { NumberHelper } from '@osapp/helpers';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { ModelMatch } from '@osapp/modules/utils/models/decorators/model-match.decorator';
import { Constraint } from './constraint';
import { EConstraintType } from './EConstraintType';
import { IActeOccurrence } from './iacte-occurrence';
import { IDelayConstraint } from './idelay-constraint';

const C_TYPE = EConstraintType.move;

@ModelMatch((poData: IDelayConstraint) => poData?.type === C_TYPE, Constraint)
export class DelayConstraint extends Constraint implements IDelayConstraint {

	//#region PROPERTIES

	public readonly type: EConstraintType = C_TYPE;

	/** @implements */
	public delayDays?: number;
	/** @implements */
	public delayHours?: number;
	/** @implements */
	public delayMinutes?: number;

	//#endregion

	//#region METHODS

	/** @override */
	public action(poActeOccurrence: IActeOccurrence): void {
		if (NumberHelper.isValid(this.delayDays))
			poActeOccurrence.date = DateHelper.addDays(poActeOccurrence.date, this.delayDays);
		if (NumberHelper.isValid(this.delayHours))
			poActeOccurrence.date = DateHelper.addHours(poActeOccurrence.date, this.delayHours);
		if (NumberHelper.isValid(this.delayMinutes))
			poActeOccurrence.date = DateHelper.addMinutes(poActeOccurrence.date, this.delayMinutes);
	}

	public setDelay(pdFrom: Date, pdTo: Date): void {
		this.delayDays = DateHelper.diffDays(pdTo, pdFrom);
		this.delayHours = DateHelper.diffHours(pdTo, pdFrom) % 24;
		this.delayMinutes = DateHelper.diffMinutes(pdTo, pdFrom) % 60;
	}

	/** @override */
	public buildlabel(): string {
		const lbIsPositive: boolean = NumberHelper.isValidStrictPositive(this.delayDays) ?? NumberHelper.isValidStrictPositive(this.delayHours) ?? NumberHelper.isValidStrictPositive(this.delayMinutes);
		const lsMoveLabel: string = lbIsPositive ? "avancée(s) de " : "reculée(s) de ";

		const laDelaysLabel: string[] = [];
		if (Math.abs(this.delayDays) > 0) {
			const pnDelay: number = Math.abs(this.delayDays);
			laDelaysLabel.push(Math.abs(this.delayDays) + " jour" + (pnDelay > 1 ? "s" : ""));
		};
		if (Math.abs(this.delayHours) > 0) {
			const pnDelay: number = Math.abs(this.delayHours);
			laDelaysLabel.push(Math.abs(this.delayHours) + " heure" + (pnDelay > 1 ? "s)" : ""));
		};
		if (Math.abs(this.delayMinutes) > 0) {
			const pnDelay: number = Math.abs(this.delayMinutes);
			laDelaysLabel.push(Math.abs(this.delayMinutes) + " minute" + (pnDelay > 1 ? "s" : ""));
		};

		const lsDelayLabel: string = laDelaysLabel.map((psDelayLabel: string, pnIndex: number) => {
			let lsSeparator: string;

			if (pnIndex + 1 === laDelaysLabel.length)
				lsSeparator = ".";
			else if (pnIndex + 2 === laDelaysLabel.length)
				lsSeparator = " et ";
			else
				lsSeparator = ", ";

			return psDelayLabel + lsSeparator;
		}).join("");

		return lsMoveLabel + lsDelayLabel;
	}

	//#endregion

}
