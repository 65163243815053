import { ModelMatch } from '@osapp/modules/utils/models/decorators/model-match.decorator';
import { EPlace } from '../../../model/EPlace';
import { Constraint } from './constraint';
import { EConstraintType } from './EConstraintType';
import { IActeOccurrence } from './iacte-occurrence';
import { IPlaceConstraint } from './iplace-constraint';

const C_TYPE = EConstraintType.place;

@ModelMatch((poData: IPlaceConstraint) => poData?.type === C_TYPE, Constraint)
export class PlaceConstraint extends Constraint implements IPlaceConstraint {

	//#region PROPERTIES

	public readonly type: EConstraintType = C_TYPE;

	/** @implements */
	public place?: EPlace;

	//#endregion

	//#region METHODS

	/** @override */
	public action(poActeOccurrence: IActeOccurrence): void {
		poActeOccurrence.place = this.place;
	}

	/** @override */
	public buildlabel(): string {
		switch (this.place) {
			case EPlace.center:
				return "au cabinet.";
			case EPlace.home:
				return "à domicile.";
		};
	}

	//#endregion

}
