import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalComponentBase } from '@osapp/modules/modal';
import { EntityLinkService } from '@osapp/services/entityLink.service';
import { PlatformService } from '@osapp/services/platform.service';
import { ReadStatusService } from '@osapp/services/read-status.service';
import { delay, mergeMap, takeUntil } from 'rxjs/operators';
import { ITraitement } from '../../../../../model/ITraitement';
import { IPatient } from '../../../model/IPatient';
import { ITransmissionModalParams } from '../../../model/ITransmissionModalParams';
import { ITransmissionRapport } from '../../../model/ITransmissionRapport';
import { InitTransmissionError } from '../../../model/InitTransmissionError';
import { RapportService } from '../../../services/rapport.service';

@Component({
	templateUrl: './transmission-modal.component.html',
	styleUrls: ['./transmission-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransmissionModalComponent extends ModalComponentBase<never> implements OnInit, OnDestroy, ITransmissionModalParams {

	//#region PROPERTIES

	/** @implements */
	@Input() public transmission?: ITransmissionRapport;
	/** @implements */
	@Input() public patientModel?: IPatient;
	/** @implements */
	@Input() public linkedEntities?: Array<ITraitement>;

	/** Titre de la modale. */
	public title: string;
	/** Indique si on est en mode création. */
	public isCreation: boolean;

	//#endregion

	//#region METHODS

	constructor(
		private isvcRapport: RapportService,
		private isvcReadStatus: ReadStatusService,
		private isvcEntityLink: EntityLinkService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService
	) {
		super(poModalCtrl, psvcPlatform);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		if (this.transmission) { // Si une transmission est renseignée, mode édition.
			this.isvcReadStatus.markAsRead(this.transmission).pipe(takeUntil(this.destroyed$))
				.subscribe();
			this.title = RapportService.C_EDIT_TRANSMISSION_TITLE;
		}
		else { // Sinon, mode création.
			this.title = RapportService.C_CREATE_TRANSMISSION_TITLE;
			this.isCreation = true;

			if (!this.patientModel) // Si le modèle du patient n'est pas renseigné pour une création => erreur car obligatoire.
				throw new InitTransmissionError();
			else
				this.transmission = this.isvcRapport.createTransmissionRapport(this.patientModel, this.linkedEntities);
		}

		this.isvcEntityLink.trySetCurrentEntity(this.transmission).pipe(takeUntil(this.destroyed$))
			.subscribe();

		if (this.transmission && this.patientModel) // Cas qui ne devrait pas arriver mais non bloquant.
			console.warn("IDL.TRANS.MOD.C:: Une transmission ET un modèle de patient ont été fournis, le modèle du patient sera ignoré.");
	}

	/** @override */
	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.isvcEntityLink.clearCurrentEntity(this.transmission?._id).subscribe();
	}

	/** @override */
	public saveTransmission(): void {
		this.isvcRapport.saveTransmission(this.transmission)
			.pipe(
				delay(250), //todo : https://dev.azure.com/calaosoft/osapp-project/_workitems/edit/3499
				mergeMap(_ => this.close()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	//#endregion

}