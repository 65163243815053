import { ModelMatch } from '@osapp/modules/utils/models/decorators/model-match.decorator';
import { Constraint } from './constraint';
import { EConstraintType } from './EConstraintType';
import { IActeOccurrence } from './iacte-occurrence';
import { IReactivateConstraint } from './ireactivate-constraint';

const C_TYPE = EConstraintType.reactivate;

@ModelMatch((poData: IReactivateConstraint) => poData?.type === C_TYPE, Constraint)
export class ReactivateConstraint extends Constraint implements IReactivateConstraint {

	//#region PROPERTIES

	public readonly type: EConstraintType = C_TYPE;

	//#endregion

	//#region METHODS

	/** @override */
	public action(poActeOccurrence: IActeOccurrence): void {
		if (poActeOccurrence.canceled)
			poActeOccurrence.canceled = false;
	}

	/** @override */
	public buildlabel(): string {
		return "réactivée(s).";
	}

	//#endregion
}