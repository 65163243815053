<ion-content>
	<div class="invoice-content">
		<div class="top-header-area">
			<div class="intervenant-area" slot="end">
				<avatar class="avatar" [cssClass]="'round'" [src]="getContactAvatar(intervenant)">
				</avatar>
				<div class="intervenants-labels">
					<ion-note>Intervenant</ion-note>
					<ion-label>{{ intervenant | contactName }}</ion-label>
				</div>
			</div>
		</div>

		<div *ngIf="seances?.length > 0" class="header">
			<div class="header-bloc">
				<ion-row>
					<ion-col size="12">
						<div class="avatar-infos">
							<avatar class="avatar up" [cssClass]="'round'" [src]="getContactAvatar(patient)"></avatar>
							<div class="patient-labels">
								<ion-note>Patient</ion-note>
								<ion-label>{{ patient | contactName }}</ion-label>
								<ion-label *ngIf="patient.maidenName as maidenName" class="maiden-name">{{ maidenName }}
								</ion-label>
							</div>
						</div>
					</ion-col>
					<ion-col size="12" class="patient-tags">
						<div class="patient-tag patient-wrap width-seance-date">
							<ion-item lines="none" color="primary" class="ion-no-padding">
								<ion-icon slot="start" [name]="invoice.statusIcon"></ion-icon>
								<ion-label>{{ invoice.statusLabel }}</ion-label>
							</ion-item>
						</div>
					</ion-col>
				</ion-row>

				<div class="bloc-infos-invoice">
					<p>
						{{ invoice.nbSeances }} {{ invoice.nbSeances === 1 ? 'séance' : 'séances' }} à facturer
					</p>

					<div *ngIf="ordonnance" class="ordonnance-ctn">
						<p>
							Ordonnance : {{ ordonnance.prescriptionDate | dateWithLocale: 'fr-FR' : 'dd/MM/yyyy' }}
						</p>
						<idl-ordonnance-status-icons [ordonnance]="ordonnance"></idl-ordonnance-status-icons>
					</div>
					<p *ngIf="!ordonnance">Ordonnance: Non renseignée</p>

					<div class="prescripteur-ctn">
						<p>Prescripteur :</p>
						<contacts-list *ngIf="contactsListParamsForPrescriptor && ordonnance"
							[params]="contactsListParamsForPrescriptor" [model]="ordonnance.prescripteurContactId"
							(modelChange)="onPrescriptorModelChanged($event)">
						</contacts-list>
					</div>
				</div>
			</div>

			<div class="header-bloc">
				<p>Couverture AMO :
					<span *ngIf="couvertures?.AMOP as amoP; else noAmo">
						<ng-container
							*ngTemplateOutlet="couvertureStatus; context: { icon: amoP.isActive ? 'checkmark' : 'alert', color: amoP.isActive ? 'success' : 'warn' }">
						</ng-container>
						{{ getLabelCouverture(amoP.amoId)}}
					</span>
				</p>
				<div class="wrap">
					<p>Couverture AMC :
						<span *ngIf="couvertures.AMCP as amcP; else noAmc">
							<ng-container
								*ngTemplateOutlet="couvertureStatus; context: { icon: amcP.isActive ? 'checkmark' : 'alert', color: amcP.isActive ? 'success' : 'warn' }">
							</ng-container>
							{{ etablissementsById.get(amcP.amcId)?.label }} -&nbsp;
						</span>
					</p>
					<p *ngIf="couvertures?.AMCP">
						{{ invoice.gestionUnique ? "Gestion Unique" : "Gestion Séparée" }}
					</p>
				</div>
				<p class="sts">AMO TP :
					<span>
						<div *ngIf="!invoice.invoiceNumber">
							<osapp-selector [options]="TPOptions" [preselectedValues]="invoice.amoTp"
								(selectionChanged)="onAmoTPChanged($event)" limit="1">
							</osapp-selector>
						</div>
						<div *ngIf="invoice.invoiceNumber">
							{{ getBooleanLabel(invoice.amoTp) }}
						</div>
					</span>
				</p>
				<p class="sts">AMC TP :
					<span>
						<div *ngIf="!invoice.invoiceNumber">
							<osapp-selector [options]="TPOptions" [preselectedValues]="invoice.amcTp"
								(selectionChanged)="onAmcTPChanged($event)" limit="1">
							</osapp-selector>
						</div>
						<div *ngIf="invoice.invoiceNumber">
							{{ getBooleanLabel(invoice.amcTp) }}
						</div>
					</span>
				</p>
				<p *ngIf="couvertures?.AMCP" class="sts">STS :
					<span>
						<div *ngIf="!invoice.invoiceNumber">
							<osapp-selector #stsSelector *ngIf="(STSOptions.changes$ | async) as options" [options]="options"
								[preselectedValues]="stsValue" (selectionChanged)="onStsChanged($event)" limit="1">
							</osapp-selector>
						</div>
						<div *ngIf="invoice.invoiceNumber">
							{{ getFormuleSTSLabel(invoice.sts) }}
						</div>
					</span>
				</p>
				<ng-template #couvertureStatus let-icon="icon" let-color="color">
					<span class="couverture-status" [ngClass]="color || 'warn'">
						<ion-icon slot="icon-only" [name]="icon || 'alert'"></ion-icon>
					</span>
				</ng-template>

				<ng-template #noAmc>
					<span>
						Aucune AMC
					</span>
				</ng-template>

				<ng-template #noAmo>
					<span>
						Aucune AMO
					</span>
				</ng-template>

				<div *ngIf="!invoice.externalId && invoice.invoiceType === 'facture'" class="header-btn">
					<ion-button (click)="editCouverture()" shape="round" color="osapp-element">
						<ion-icon slot="start" name="create"></ion-icon>
						Modifier la couverture
					</ion-button>
				</div>
			</div>

			<div *ngIf="displayGallery" class="header-bloc">
				<p>Scan des feuilles de soins signées : </p>
				<div class="gallery-ctn">
					<gallery [files]="invoice.documents" [readOnly]="!canEditGallery" [filePickerVisible]="false"
						(onFilesChanged)="onDocumentsModelChanged($event)">
					</gallery>
				</div>
			</div>

			<div *ngIf="fspDmsFiles.length > 0" class="header-bloc">
				<p>Fichiers PDF générés : </p>
				<div class="gallery-ctn">
					<gallery [files]="invoice.fspDmsFiles" [readOnly]="true">
					</gallery>
				</div>
			</div>
		</div>

		<ion-grid *ngIf="seances?.length > 0">
			<ion-row class="content">
				<ion-grid>
					<ion-row>
						<ion-col size="4.5"></ion-col>
						<ion-col size="1.5" class="price-col">Honoraires</ion-col>
						<ion-col size="1.5" class="price-col">Majo</ion-col>
						<ion-col size="1.5" class="price-col">AMO</ion-col>
						<ion-col size="1.5" class="price-col">AMC</ion-col>
						<ion-col size="1.5" class="price-col">PP</ion-col>
					</ion-row>

					<ng-container *ngFor="let seance of seances">
						<ion-row class="seance-row">
							<ion-col size="4.5">
								<ion-label>
									Séance du {{ seance.date | dateWithLocale: 'fr-FR' : 'dd/MM/yyyy'}}
									{{ seance.date | dateWithLocale: 'fr-FR' : 'HH:mm'}}
								</ion-label>
							</ion-col>
							<ng-container *ngTemplateOutlet="linePrices; context: { invoiceLines: getSeanceLines(seance) }">
							</ng-container>
						</ion-row>

						<ion-row *ngFor="let acte of seance.actes; let index = index" class="detail-row">
							<ion-col size="4.5">
								<ion-label class="row-description">{{ acte.description }}</ion-label>
							</ion-col>
							<ng-container
								*ngTemplateOutlet="linePrices; context: { invoiceLines: [acte], seance: index === 0 ? seance : undefined }">
							</ng-container>
						</ion-row>

						<ion-row *ngIf="getMajorationsDetail(seance.majorations) as majoDetails" class="detail-row">
							<ion-col size="4.5">
								<ion-label class="row-description">
									Majorations: {{ majoDetails.join(', ') || "Aucune" }}
								</ion-label>
							</ion-col>
							<ng-container *ngTemplateOutlet="linePrices; context: { invoiceLines: seance.majorations }">
							</ng-container>
						</ion-row>

						<ion-row *ngIf="seance.indemnites.length > 0" class="detail-row">
							<ion-col size="4.5">
								<ion-label class="row-description">
									Déplacement: {{ getIndemnitesDetail(seance.indemnites) || "Aucune" }}
								</ion-label>
							</ion-col>
							<ng-container *ngTemplateOutlet="linePrices; context: { invoiceLines: seance.indemnites, seance: getMajoIndemniteBsi(seance) }">
							</ng-container>
						</ion-row>
					</ng-container>
				</ion-grid>
			</ion-row>
		</ion-grid>
	</div>
</ion-content>

<div class="fab-ctn">
	<ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-margin fab-wrapper">
		<ion-fab-button *ngIf="canDisplayFSP" color="fab-button" class="fab-button pdf-button" (click)="displayFsp()">
			<ion-icon name="file-fsp"></ion-icon>
			<ion-label class="ion-hide-md-down">Feuilles de soins</ion-label>
		</ion-fab-button>
		<ion-fab-button *ngIf="invoice?.isClosed" color="fab-button" class="fab-button pdf-button"
			(click)="exportInvoicePdf()">
			<ion-icon name="file-pdf"></ion-icon>
			<ion-label class="ion-hide-md-down">Exporter en PDF</ion-label>
		</ion-fab-button>
		<ion-fab-button *ngIf="invoice?.canCancel" color="light" class="fab-button fab-cancel" (click)="cancelAction()">
			<ion-icon [name]="invoice.cancelIcon"></ion-icon>
			<ion-label class="ion-hide-md-down">{{invoice.cancelLabel}}</ion-label>
		</ion-fab-button>
		<ion-fab-button *ngIf="invoice?.canGoForward" color="success" class="fab-button" (click)="action()">
			<ion-icon [name]="invoice.nextActionIcon"></ion-icon>
			<ion-label class="ion-hide-md-down">{{invoice.nextActionLabel}}</ion-label>
		</ion-fab-button>
	</ion-fab>
</div>

<ng-template #linePrices let-invoiceLines="invoiceLines" let-seance="seance">
	<ng-container *ngIf="getLinesPrices(invoiceLines) as prices">
		<ion-col size="1.5" class="price-col">{{ invoice.isPriced ? (prices.honoraires | priceFormat:2) : "-,--" }} €
		</ion-col>
		<ion-col size="1.5" class="price-col">{{ seance ? getFirstActeMajorationsDetail(seance) : "" }}
		</ion-col>
		<ion-col size="1.5" class="price-col">{{ invoice.isPriced ? (prices.partAMO | priceFormat:2) : "-,--" }} €
		</ion-col>
		<ion-col size="1.5" class="price-col">{{ invoice.isPriced ? (prices.partAMC | priceFormat:2) : "-,--" }} €
		</ion-col>
		<ion-col size="1.5" class="price-col">{{ invoice.isPriced ? (prices.partPatient | priceFormat:2) : "-,--" }}
			€
		</ion-col>
	</ng-container>
</ng-template>