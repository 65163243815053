import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonInput, ModalController } from '@ionic/angular';
import { DateHelper } from 'libs/osapp/src/helpers/dateHelper';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { ModalComponentBase } from '../../../modules/modal/model/ModalComponentBase';
import { PlatformService } from '../../../services/platform.service';

enum EInputMode {
	hour = "hour",
	minute = "minute"
}

@Component({
	selector: 'calao-browser-time-picker',
	templateUrl: './browser-time-picker.component.html',
	styleUrls: ['./browser-time-picker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrowserTimePickerComponent extends ModalComponentBase<Date> implements OnInit, OnDestroy {

	//#region FIELDS

	private maHoursValues: number[] = DateHelper.getHourValues();
	private maMinutesValues: number[] = DateHelper.getMinuteValues();
	private mnPrevHourValue: number;
	private mnPrevMinuteValue: number;

	//#endregion

	//#region PROPERTIES

	@Input() public date: Date;

	private moMax: Date;
	public get max(): Date {
		return this.moMax;
	}
	@Input()
	public set max(poMax: Date) {
		const ldMax: Date = poMax instanceof Date ? poMax : DateHelper.isDate(poMax) ? new Date(poMax) : undefined;
		if (DateHelper.compareTwoDates(ldMax, this.moMax) !== 0) {
			this.moMax = ldMax;
			if (DateHelper.diffDays(new Date, this.max) === 0) {
				if (this.max && DateHelper.diffDays(this.date, this.max) === 0) {
					this.mnMaxHours = this.max.getHours();
					this.mnMaxMinutes = this.max.getMinutes();
				}
				else {
					this.mnMaxHours = undefined;
					this.mnMaxMinutes = undefined;
				}
			}
			this.detectChanges();
		}
	}

	private moMin: Date;
	public get min(): Date {
		return this.moMin;
	}
	@Input()
	public set min(poMin: Date) {
		const ldMin: Date = poMin instanceof Date ? poMin : DateHelper.isDate(poMin) ? new Date(poMin) : undefined;
		if (DateHelper.compareTwoDates(ldMin, this.moMax) !== 0) {
			this.moMin = ldMin;
			if (this.min && DateHelper.diffDays(this.date, this.min) === 0) {
				this.mnMinHours = this.min.getHours();
				this.mnMinMinutes = this.min.getMinutes();
			}
			else {
				this.mnMinHours = undefined;
				this.mnMinMinutes = undefined;
			}
			this.detectChanges();
		}
	}

	private mnMaxMinutes: number;
	public get maxMinutes(): number {
		return this.mnMaxMinutes ?? ArrayHelper.getLastElement(this.maMinutesValues);
	}
	private mnMinMinutes: number;
	public get minMinutes(): number {
		return this.mnMinMinutes ?? ArrayHelper.getFirstElement(this.maMinutesValues);
	}
	private mnMaxHours: number;
	public get maxHours(): number {
		return this.mnMaxHours ?? ArrayHelper.getLastElement(this.maHoursValues);
	}
	private mnMinHours: number;
	public get minHours(): number {
		return this.mnMinHours ?? ArrayHelper.getFirstElement(this.maHoursValues);
	}

	public hourValue: number;
	public minuteValue: number;

	@ViewChild("hourInput") public set hourInput(poHourInput: IonInput) {
		if (poHourInput)
			setTimeout(() => {
				poHourInput.setFocus();
				poHourInput.getInputElement().then((poHTMLElement: HTMLInputElement) => poHTMLElement.select())
			}, 500);
	};

	//#endregion

	//#region METHODS

	constructor(
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetector);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		if (!DateHelper.isDate(this.date))
			this.date = new Date();

		this.initDate();

		this.detectChanges();
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	private initDate(): void {
		const lsHoursAndMinutes: string = DateHelper.getHoursAndMinutes(this.date);
		this.hourValue = +lsHoursAndMinutes.split(":")[0];
		this.minuteValue = +lsHoursAndMinutes.split(":")[1];
	}

	/**
	 * Ajoute un 0 si la valeur de l'input est inférieur à 10.
	 * @param poInput L'input.
	 */
	public fillInput(poInput: IonInput) {
		poInput.value = +poInput.value;
		if (!isNaN(poInput.value) && poInput.value < 10)
			poInput.value = '0' + poInput.value;

		this.detectChanges();
	}

	/**
	 * Vérifie les valeurs saisies par l'utilisateur.
	 * @param poInput L'input.
	 * @param peInputMode Indique si l'input est celui des heures ou des minutes.
	 */
	public checkValue(poInput: IonInput, peInputMode: EInputMode | string) {
		let needToFillInput: boolean = false;

		if (peInputMode === EInputMode.hour) {
			if (!isNaN(+poInput.value) && +poInput.value !== this.mnPrevHourValue) {
				if (+poInput.value > this.maxHours)
					poInput.value = this.maxHours;
				else if (+poInput.value < this.minHours)
					poInput.value = this.minHours;

				this.mnPrevHourValue = +poInput.value;
				needToFillInput = true;
			}
		}
		else if (peInputMode === EInputMode.minute) {
			if (!isNaN(+poInput.value) && +poInput.value !== this.mnPrevMinuteValue) {
				if (+poInput.value > this.maxMinutes)
					poInput.value = this.maxMinutes;
				else if (+poInput.value < this.minMinutes)
					poInput.value = this.minMinutes;

				this.mnPrevMinuteValue = +poInput.value;
				needToFillInput = true;
			}
		}

		if (poInput.value === "" || needToFillInput)
			this.fillInput(poInput);
	}

	public confirm(): void {
		this.date.setHours(this.hourValue);
		this.date.setMinutes(this.minuteValue);
		this.close(this.date);
	}

	public cancel(): void {
		this.close();
	}

	//#endregion
}
