import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { HasPermissionGuard } from './guards/has-permission.guard';

const modules: Type<any>[] = [
	CommonModule,
	IonicModule
];

@NgModule({
	imports: modules,
	providers: [HasPermissionGuard]
})
export class PermissionsModule { }