import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { IonicModule } from '@ionic/angular';
import { CameraModule } from '../../modules/camera/camera.module';
import { ModalModule } from '../../modules/modal/modal.module';
import { SelectorModule } from '../../modules/selector/selector.module';
import { PipeModule } from '../../pipes/pipes.module';
import { FilePickerModule } from '../filePicker';
import { ImageModule } from '../image';
import { GalleryAddModalComponent } from './components/gallery-add-modal/gallery-add-modal.component';
import { GallerySelectorModalComponent } from './components/gallery-selector-modal/gallery-selector-modal.component';
import { GallerySelectorComponent } from './components/gallery-selector/gallery-selector.component';
import { GalleryModalComponent } from './gallery-modal/gallery-modal.component';
import { GalleryComponent } from './gallery.component';

const components: Type<any>[] = [
	GalleryComponent,
	GallerySelectorComponent,
	GallerySelectorModalComponent,
	GalleryAddModalComponent,
	GalleryModalComponent
];

@NgModule({
	imports: [
		IonicModule,
		CommonModule,
		FilePickerModule,
		ImageModule,
		PipeModule,
		CameraModule,
		ModalModule,
		FormsModule,
		MatFormFieldModule,
		MatInputModule,
		SelectorModule,
	],
	exports: components,
	declarations: components
})
export class GalleryModule { }