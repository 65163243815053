import { DateHelper } from '@osapp/helpers/dateHelper';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { ChildEntityGuard } from '@osapp/model/entities/ChildEntityGuard';
import { Entity } from '@osapp/model/entities/Entity';
import { EAvatarSize } from '@osapp/model/picture/EAvatarSize';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { EntityBuilder } from '@osapp/services/EntityBuilder';
import { C_INJECTIONS_PREFIX, C_PREFIX_PATIENT } from '../../../../app/app.constants';
import { IInjections } from './IInjections';

export class InjectionsEntityBuilder extends EntityBuilder<IInjections>{
	constructor() {
		super(
			(psModelId: string) => RegExp(C_INJECTIONS_PREFIX).test(psModelId),
			(poInjections: IInjections) => new Entity(
				poInjections,
				`injections/${poInjections._id}`,
				() => `Injections du ${DateHelper.transform(poInjections.createdDate, ETimetablePattern.EEE_dd_MMMM_yyyyy_HH_mm)}`
			),
			_ => "Injections",
			EDatabaseRole.workspace,
			C_INJECTIONS_PREFIX,
			() => ({
				size: EAvatarSize.medium,
				icon: "analytics-outline"
			}),
			undefined,
			undefined,
			undefined,
			new ChildEntityGuard("Ce suivi des injections est lié", C_PREFIX_PATIENT)
		);
	}
}