import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, mapTo, mergeMap } from 'rxjs/operators';
import { FlagService, SecurityService } from '@osapp/services';
import { IAuthStatus } from '@osapp/model';
import { EPermissionsFlag } from '@osapp/modules/permissions/models/EPermissionsFlag';

@Injectable({ providedIn: "root" })
export class AuthenticatedGuard implements CanActivate {

	//#region METHODS

	constructor(
		private readonly svcSecurity: SecurityService,
		private readonly isvcFlag: FlagService,
	) { }

	/** Garde du routage. */
	public canActivate(poRoute: ActivatedRouteSnapshot, poState: RouterStateSnapshot): Observable<boolean> {
		return this.svcSecurity.authenticationStatus$
			.pipe(
				map((poAuthStatus: IAuthStatus) => {
					const lbCanActivate: boolean = poAuthStatus.isAuthenticated;
					console.debug("AUTH.G:: canActivate", lbCanActivate);

					if (!lbCanActivate)
						this.svcSecurity.requestAuthentication(poState.url);

					return lbCanActivate;
				}),
				mergeMap((pbResult: boolean) => {
					if (!pbResult) // Si on ne peut pas passer, on retourne le résultat tel quel.
						return of(pbResult);
					else {
						// Si on peut passer, on s'assure ques les bases de données et les permissions soient initialisées avant de poursuivre.
						// Les permissions sont initialisées après que les bases de données le soient.
						return this.isvcFlag.waitForFlag(EPermissionsFlag.isLoaded, true).pipe(mapTo(pbResult));
					}
				})
			);
	}

	//#endregion

}