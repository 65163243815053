import { Injectable } from '@angular/core';
import { UserData } from '@osapp/model/application/UserData';
import { IGroup } from '@osapp/model/contacts/IGroup';
import { ModalService } from '@osapp/modules/modal/services/modal.service';
import { PermissionsService } from '@osapp/modules/permissions/services/permissions.service';
import { SitesService } from '@osapp/modules/sites/services/sites.service';
import { defer, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { C_PREFIX_PATIENT } from '../../../../../app/app.constants';
import { IdlApplicationService } from '../../../../../services/idlApplicationService.service';
import { IPatient } from '../../../model/IPatient';
import { IPatientSelectorParams } from '../../../model/IPatientSelectorParams';
import { PatientSelectorModalComponent } from '../patient-selector-modal.component';

export interface IPatientSelectorModalOpenerParams {
	patients?: IPatient[];
	required?: boolean;
	multiple?: boolean;
	preselectedPatientIds?: string[];
	canCreate?: boolean;
	title?: string;
	selectNewPatient?: boolean;
	removeGroupFilter?: boolean;
}

@Injectable()
export class PatientSelectorModalOpenerService {

	//#region METHODS

	constructor(
		private readonly isvcModal: ModalService,
		private readonly isvcSite: SitesService,
		private readonly isvcPermissions: PermissionsService
	) { }

	public open(poParams: IPatientSelectorModalOpenerParams): Observable<IPatient[]> {
		return defer(() => {
			poParams.canCreate =  poParams.canCreate;
			// On crée les paramètres du sélecteur de patients dans la fonction directement pour prendre en compte les modifications des patients présélectionnés.
			const loSelectorParams: IPatientSelectorParams = PatientSelectorModalOpenerService.createPatientSelectorParamsParams(poParams);

			if (!poParams.removeGroupFilter) {
				return this.isvcSite.getSiteSectors(UserData.currentSite._id).pipe(
					map((paSectors: IGroup[]) => {
						loSelectorParams.groupFilterParams = { options: paSectors.map((poSector: IGroup) => ({ label: poSector.name, value: poSector })) };
						return loSelectorParams;
					})
				);
			}
			return of(loSelectorParams);
		})
			.pipe(
				mergeMap((poSelectorParams: IPatientSelectorParams) =>
					this.isvcModal.open<IPatient[]>({ component: PatientSelectorModalComponent, componentProps: { title: poParams.title, params: poSelectorParams } })
				)
			);
	}

	public static createPatientSelectorParamsParams(poParams: IPatientSelectorModalOpenerParams): IPatientSelectorParams {
		return {
			userContactVisible: false,
			hasSearchbox: true,
			preSelectedIds: poParams.preselectedPatientIds,
			selectionMinimum: poParams.required ? 1 : 0,
			selectionLimit: poParams.multiple ? undefined : 1,
			contactsData: poParams.patients,
			allSelectionButton: true,
			prefix: C_PREFIX_PATIENT,
			searchOptions: {
				searchboxPlaceholder: "Rechercher un patient",
				searchableFields: [{ key: "firstName" }, { key: "lastName" }, { key: "socialNumber" }, { key: "birthDate" }]
			},
			canCreate: poParams.canCreate,
			selectAfterCreate: poParams.selectNewPatient,
			formParams: {
				formDefinitionId: IdlApplicationService.C_PATIENTS_BOOK_FORMDEF_ID_EDIT,
				formDescriptorId: IdlApplicationService.C_PATIENTS_BOOK_FORMDESC_ID
			}
		};
	}
	//#endregion

}
