import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { DateHelper } from '@osapp/helpers';
import { ETimetablePattern } from '@osapp/model';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { Acte } from 'apps/idl/src/model/Acte';
import { Observable, forkJoin } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { ETraitementState } from '../../../../model/ETraitementState';
import { ITraitement } from '../../../../model/ITraitement';
import { Traitement } from '../../../../model/Traitement';
import { IPatient } from '../../../../modules/patients/model/IPatient';
import { PatientsService } from '../../../../modules/patients/services/patients.service';
import { TraitementService } from '../../../../services/traitement.service';
import { PanneauPatientOrdonanceComponent } from '../../../features/ordonnances/components/panneau-patient-ordonnance/panneau-patient-ordonnance.component';
import { DeviceService } from '../../../features/shared/services/device.service';
import { PanneauService } from '../../../features/shared/services/panneau.service';

@Component({
  selector: 'di-ordonnance',
  templateUrl: './ordonnance.page.html',
  styleUrls: ['./ordonnance.page.scss'],
})
export class OrdonnancePage extends DestroyableComponentBase implements OnInit {
  public ordonnancefilters: Array<string> = ["ACTIVES", "TERMINEES"];
  public defaultFilter= this.ordonnancefilters[0];
  // public searchValue: string = "";
  public sousTexteAucuneOrdonnance: string = "Vous allez retrouver ici toutes les ordonnances des patients en cours.";
	public ordoActiveCountMessage :string;
	public isMobileView: boolean = false;
	public searchedValue : string = "";
	public ordonnances: ITraitement[] = [];
  public filteredOrdonnance: ITraitement[] = [];
	public aucuneOrdonnance : boolean = false;
	public MainTextNoResult : string;
	public SubTextNoResult : string;
	private patientIds :string[];
  public patientsMap: Map<string, IPatient> = new Map();
 
  public actes: Acte[]=[
   
  ];


  constructor( 
		private svcDevice : DeviceService,
		private svcPatient : PatientsService,
		private svcPanneau: PanneauService,
		private svcTraitement : TraitementService,	
		private router: Router,
		) {
			super()
		 }

  ngOnInit() {
		this.svcDevice.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((flag: boolean) => {
      this.isMobileView = flag;
    });
		this.svcTraitement.deletion$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.loadListTransmission();
    });
		this.svcPanneau.closePanel$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.loadListTransmission();
    });
		this.loadListTransmission();

	}

	private loadListTransmission() : void {
		this.initOrdonnance().pipe(
			tap(() => {
				this.applyFilter();
				this.getNbOrdonnance();
				this.aucuneOrdonnance = this.ordonnances.length === 0;
				if(this.aucuneOrdonnance)
				{
					this.MainTextNoResult = "Aucune transmission";
					this.SubTextNoResult = "Vous allez retrouver ici toutes les transmissions par patient.";
				}
			}),
			switchMap(() => {
				this.patientIds = Array.from(new Set(this.ordonnances.map(ordo => {
					return Traitement.extractPatientId(ordo._id);
				}).filter(id => id)));
				return forkJoin({
					patients: this.svcPatient.getPatientsByIds(this.patientIds)
				});
			}),
			tap(({patients}) => {
				this.patientsMap = new Map(patients.map(patient => [patient._id, patient]));
			}),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	private initOrdonnance(): Observable<ITraitement[]> {
    return this.svcTraitement.getTraitementsANAKIN(false).pipe(
        tap((traitements: ITraitement[]) => {
            if (traitements.length === 0) {
                this.ordonnances = [];
            } else {
                this.ordonnances = traitements.map(traitement => {
                    return {
                        ...traitement,
                        createdDate: new Date(traitement.createDate)
                    };
                });

                this.ordonnances.sort((a, b) => {
                    return (b.createDate as Date).getTime() - (a.createDate as Date).getTime();
                });
            }
        })
    );
}


	private applyFilter(): void {
    const currentDate = new Date();
		this.filteredOrdonnance = this.ordonnances;
    if (this.defaultFilter === "ACTIVES") {
      this.filteredOrdonnance = this.filteredOrdonnance.filter(ordonnance => 
        ordonnance.state !== ETraitementState.termine
      );
    } else if (this.defaultFilter === "TERMINEES") {
      this.filteredOrdonnance = this.filteredOrdonnance.filter(ordonnance => 
        ordonnance.state === ETraitementState.termine
      );
    }

    this.filteredOrdonnance.sort((a, b) => {
      return (b.createDate as Date).getTime() - (a.createDate as Date).getTime();
    });
  }

  public addOrdonnance() {

  }

  public filterOrdonnances(searchValue: string) {
		this.searchedValue = searchValue.trim().toLowerCase();
    if (this.searchedValue && this.searchedValue.length > 2) {
			  this.applyFilter();
        const searchTerms = this.searchedValue.split(' ').filter(term => term);
        this.filteredOrdonnance = this.filteredOrdonnance.filter(ordonnance => {
            const patient = this.patientsMap.get(Traitement.extractPatientId(ordonnance._id));
            if (!patient) return false;

            const firstName = patient.firstName ? patient.firstName.toLowerCase() : '';
            const lastName = patient.lastName ? patient.lastName.toLowerCase() : '';
						const formattedBirthDateSlash = DateHelper.transform(patient.birthDate, ETimetablePattern.dd_MM_yyyy_slash);

            const matchesOnlyFirstName = searchTerms.length === 1 &&
                searchTerms[0] && firstName.includes(searchTerms[0]);

            const matchesOnlyLastName = searchTerms.length === 1 &&
                searchTerms[0] && lastName.includes(searchTerms[0]);

            const matchesFirstLast = searchTerms.length === 2 &&
                firstName.includes(searchTerms[0]) && lastName.includes(searchTerms[1]);

            const matchesLastFirst = searchTerms.length === 2 &&
                lastName.includes(searchTerms[0]) && firstName.includes(searchTerms[1]);

					const matchesSocialNumber = patient.socialNumber?.startsWith(this.searchedValue);
					const matchesBirthDate = this.searchedValue === formattedBirthDateSlash;

					return matchesOnlyFirstName || matchesOnlyLastName || matchesFirstLast || matchesLastFirst || matchesSocialNumber || matchesBirthDate;
        });
    } else {
				if(searchValue === ""){
					this.applyFilter();
				}
        this.filteredOrdonnance = this.filteredOrdonnance;
    }
		this.aucuneOrdonnance = this.filteredOrdonnance.length === 0;
		if(this.aucuneOrdonnance)
		{
			this.MainTextNoResult = "Aucune ordonnance trouvée";
			this.SubTextNoResult = "Essayer de modifier votre recherche";
		}
		this.getNbOrdonnance();
  }

  public showOrdonnanceList() {
    return this.filteredOrdonnance.length > 0;
  }

	public getNbOrdonnance() : void {
    const nbOrdoActive = this.filteredOrdonnance.length ;
		if(nbOrdoActive == 0)
		{
			this.ordoActiveCountMessage = "Aucune ordonnance";
		}
		else
		{
    	this.ordoActiveCountMessage = `${nbOrdoActive} ordonnance${nbOrdoActive > 1 ? "s actives" : " active"}`;
		}
  }

	public changeFiltre(filtre: string )
	{
    this.defaultFilter	= filtre;
		this.applyFilter();
		if(this.searchedValue && this.searchedValue.length > 2)
      this.filterOrdonnances(this.searchedValue);
  }

  public handleClickAddOrdonnance(event: Event) {
    this.svcPanneau.open("Patients",PanneauPatientOrdonanceComponent,{})
  }

	public navigateToOrdo(ordonnanceId :ITraitement)
	{
		this.router.navigate(['/ordonnances/edit', ordonnanceId]);
	}

}