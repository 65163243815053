import { from, Observable, ObservableInput, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { ObjectHelper } from '../../../helpers/objectHelper';
import { ESortOrder } from '../../../model/ESortOrder';
import { MoveToObservableArrayError } from './errors/move-to-observable-array-error';

export class ObservableArray<T> extends Array<T> {

	//#region FIELDS

	private readonly moChanges: Subject<Array<T>>;

	//#endregion

	//#region PROPERTIES

	/** Flux continu de récupération des changements du tableau. */
	public get changes$(): Observable<Array<T>> { return this.moChanges.asObservable().pipe(startWith(this)); }

	/** Flux continu de récupération des changements sur la longueur du tableau. */
	public get length$(): Observable<number> { return this.changes$.pipe(map((paValues: T[]) => paValues.length)); }

	//#endregion

	//#region METHODS

	constructor(paValues?: T[] | ObservableInput<T[]> | number) {
		if (typeof paValues === "number")
			super(paValues);
		else if (paValues instanceof Array)
			super(...paValues);
		else
			super();

		this.moChanges = new Subject();
		ObjectHelper.initInstanceOf(this, ObservableArray);

		if (!!paValues && typeof paValues !== "number" && !(paValues instanceof Array))
			from(paValues).subscribe((paResults: T[]) => this.resetArray(paResults));
		else
			this.emitNewArray();
	}

	/** @override */
	public push(...paValues: T[]): number {
		if (ArrayHelper.hasElements(paValues)) {
			const lnResult: number = super.push(...paValues);

			this.emitNewArray();

			return lnResult;
		}
		else
			return this.length;
	}

	/** @override */
	public pop(): T {
		const loResult: T = super.pop();

		this.emitNewArray();

		return loResult;
	}

	/** @override */
	public sort(pfComp?: (a: T, b: T) => number): this {
		const loResult: this = super.sort(pfComp);

		this.emitNewArray();

		return loResult;
	}

	/** @override */
	public reverse(): this {
		super.reverse();

		this.emitNewArray();

		return this;
	}

	/** Envoi un événement contenant la liste dans le flux de changements. */
	private emitNewArray(): void {
		this.moChanges.next(this);
	}

	/** @override */
	public splice(start: number, deleteCount?: number): T[];
	/** @override */
	public splice(start: number, deleteCount: number, ...items: T[]): T[];
	public splice(start: number, deleteCount?: number, ...rest: T[]): T[] {
		let laResults: T[];

		if (!!rest)
			laResults = super.splice(start, deleteCount, ...rest);
		else
			laResults = super.splice(start, deleteCount);

		this.emitNewArray();

		return laResults;
	}

	/** @override */
	public shift(): T {
		const loResult: T = super.shift();

		if (loResult)
			this.emitNewArray();

		return loResult;
	}

	/** @override */
	public unshift(...items: T[]): number {
		if (ArrayHelper.hasElements(items)) {
			const lnResult: number = super.unshift(...items);

			this.emitNewArray();

			return lnResult;
		}

		return this.length;
	}

	/** Réinitialise le tableau courant avec des nouvelles données ou en réinitialisant à tableau vide.
	 * @param paNewArray Nouveau tableau avec lequel réinitialiser le tableau courant.
	 */
	public resetArray(paNewArray?: T[]): void {
		let lbChanged = false;

		if (!ArrayHelper.areArraysStrictEqual(paNewArray, this)) {
			if (ArrayHelper.hasElements(this)) {
				super.splice(0, this.length);
				lbChanged = true;
			}
			if (ArrayHelper.hasElements(paNewArray)) {
				super.push(...paNewArray);
				lbChanged = true;
			}
		}

		if (lbChanged)
			this.emitNewArray();
	}

	/** Supprime un élément spécifique du tableau et le retourne si celui-ci a été supprimé, retourne `undefined` sinon.
	 * @param poItem Objet qu'il faut supprimer du tableau.
	 */
	public remove(poItem: T): T | undefined;
	/** Supprime un élément spécifique du tableau et le retourne si celui-ci a été supprimé, retourne `undefined` sinon.
	 * @param pfFinder Fonction permettant de trouver l'objet à supprimer.
	 */
	public remove(pfFinder: (poItem: T) => boolean): T | undefined;
	public remove(poData: T | ((poItem: T) => boolean)): T | undefined {
		const lnIndex: number = typeof poData === "function" ? this.findIndex((poData as (poItem: T) => boolean)) : this.indexOf(poData);
		let loRemovedElement: T;

		if (lnIndex !== -1) {
			loRemovedElement = this.splice(lnIndex, 1)[0];
			this.emitNewArray();
		}

		return loRemovedElement;
	}

	/** Retourne `true` si au moins un éléments est présent dans le tableau, `false` sinon. */
	public hasElements(): boolean {
		return this.length > 0;
	}

	/** Retourne le premier élément du tableau, `undefined` s'il n'y en a pas. */
	public first(): T | undefined {
		return this[0];
	}

	/** Retourne le dernier élément du tableau, `undefined` s'il n'y en a pas. */
	public last(): T | undefined {
		return this[this.length - 1];
	}

	/** Déplace un élément du tableau vers un nouvel index, en déplaçant si besoin les éléments entre l'ancien et le nouvel index.
	 * @param pnFromIndex Index courant de l'élément à déplacer.
	 * @param pnToIndex Nouvel index de l'élément après déplacement.
	 * @throws `MoveToObservableArrayError` si le déplacement de l'élément n'a pas pu se réaliser.
	 */
	public moveTo(pnFromIndex: number, pnToIndex: number): true {
		if (ArrayHelper.moveElement(this, pnFromIndex, pnToIndex)) {
			this.emitNewArray();
			return true;
		}
		else
			throw new MoveToObservableArrayError(pnFromIndex, pnToIndex, this.length);
	}

	/** @override */
	public filter(pfPredicate: (poValue: T, pnIndex: number, paArray: ObservableArray<T>) => boolean): ObservableArray<T> {
		return new ObservableArray(super.filter(pfPredicate));
	}

	/** Retourne le tableau trié (en modifiant directement le tableau).
	 * @param psKey Clé sur laquelle trier le tableau.
	 * @param peSortOrder Ordre de tri : croissant par défaut (alphabétique, plus vieux au plus récent).
	 */
	public sortBy(psKey: keyof T, peSortOrder: ESortOrder = ESortOrder.ascending): this {
		ArrayHelper.dynamicSort(this, psKey, peSortOrder);
		this.emitNewArray();
		return this;
	}

	//#endregion

}