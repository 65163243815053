<ion-header>
	<!-- Toolbar principale. -->
	<ion-toolbar color="toolbar">
		<ion-buttons slot="start">
			<ion-button (click)="close()">
				<ion-icon slot="icon-only" name="close"></ion-icon>
			</ion-button>
		</ion-buttons>
		<div class="top-header-area">
			<ion-title>Séance</ion-title>
			<div class="intervenant-area" slot="end">
				<div class="intervenants-wrapper" (click)="$event.stopPropagation(); addIntervenant()">
					<ng-container *ngIf="seanceTournee?.intervenants?.length > 0; else emptyIntervenant;">
						<avatar class="avatar up" [cssClass]="'round'" [src]="seanceTournee.intervenants[0].avatar">
						</avatar>
						<a *ngIf="seanceTournee.intervenants.length > 1"
							class="avatar intervenant-count">+{{seanceTournee.intervenants.length - 1}}</a>
					</ng-container>
				</div>
				<div class="intervenants-labels">
					<ion-note>{{ seanceTournee.intervenants.length > 1 ? "Intervenants" : "Intervenant"}}</ion-note>
					<ion-label>{{ getIntervenantsNames() }}</ion-label>
				</div>
			</div>
		</div>
	</ion-toolbar>
	<!-- Toolbar secondaire. -->
	<ion-toolbar class="toolbar-bloc">
		<div [class]="isLockedSeance ? 'no-hover infos-content' : 'infos-content'">
			<div class="bloc-infos">
				<ion-row>
					<ion-col size="12" (click)="goToPatient()">
						<div class="avatar-infos">
							<avatar class="avatar up" [cssClass]="'round'" [src]="seanceTournee.patient.avatar"></avatar>
							<div class="patient-labels">
								<ion-note>Patient</ion-note>
								<ion-label>{{ seanceTournee.patient.groupMember | patientName }}</ion-label>
								<ion-label class="maiden-name">{{ seanceTournee.patient.groupMember.maidenName }}</ion-label>
							</div>
						</div>
					</ion-col>
				</ion-row>
				<ion-row>
					<ion-col size="12" class="patient-tags">
						<div class="patient-tag patient-wrap pad-etat">
							<ng-container *ngIf="seanceStateAvatar as avatar">
								<avatar [src]="avatar" [cssClass]="'round'" slot="start">
								</avatar>
								<ion-label>{{ getSeanceStateLabel(avatar.icon) }}&nbsp;</ion-label>
							</ng-container>
						</div>
						<div class="patient-tag width-seance-date">
							<ion-item [disabled]="isLockedSeance" lines="none" color="osapp-element" class="ion-no-padding">
								<ion-icon slot="start" name="time"></ion-icon>
								<div (click)="onChooseSeanceDateTime(seanceTimePicker)" class="patient-tag-label">
									{{ seance.scheduled ?
									(seance.startDate | timetable:C_TIME_FORMAT:seance.endDate:C_TIME_FORMAT) :
									'Planifier' }}
									<osapp-date-time #seanceTimePicker class="hidden" [model]="seance.startDate" [params]="timeParams"
										[disabled]="isLockedSeance" (modelChange)="onStartDateChanged($event)">
									</osapp-date-time>
								</div>
							</ion-item>
						</div>
						<div class="patient-tag width-seance-date">
							<ion-item [disabled]="isLockedSeance" lines="none" color="osapp-element" class="ion-no-padding">
								<ion-icon slot="start" name="timetable"></ion-icon>
								<div (click)="onChooseSeanceDateTime(seanceDatePicker)" class="patient-tag-label">
									{{ seance.scheduled ?
									(seance.startDate | dateWithLocale : 'fr-FR' : C_DAY_FORMAT) :
									'Planifier' }}
									<osapp-date-time #seanceDatePicker class="hidden" [model]="seance.startDate" [params]="dateParams"
										[disabled]="isLockedSeance" (modelChange)="onStartDateChanged($event)">
									</osapp-date-time>
								</div>
							</ion-item>
						</div>
						<div class="icon-status-seance">
							<ion-icon *ngIf="seance.isLast" matTooltip="Dernière séance" slot="start" name="alert-circle"
								color="warning">
							</ion-icon>
						</div>
					</ion-col>
				</ion-row>
			</div>
		</div>
	</ion-toolbar>
</ion-header>

<ion-content fullscreen [forceOverscroll]="false">
	<mat-accordion multi class="seance-wrapper">
		<!-- DÉPLIANT ORDONNNANCES / PRESCRIPTIONS -->
		<mat-expansion-panel togglePosition="after" class="add-pad">
			<mat-expansion-panel-header>
				<mat-panel-title>
					Ordonnances / Prescriptions
				</mat-panel-title>
			</mat-expansion-panel-header>
			<ng-container *ngIf="ordonnances?.length > 0; else emptyOrdonnance;">
				<idl-ordonnances-gallery [ordonnances]="ordonnances" [traitement]="traitement"
					[excludeOrdonnancesIds]="excludeOrdonnancesIds"></idl-ordonnances-gallery>
			</ng-container>
		</mat-expansion-panel>
		<div class="add-btn-area">
			<ion-button [disabled]="!canEdit" (click)="$event.stopPropagation(); createOrdonnance()"
				class="button-edit blue-fab-button">
				<ion-icon slot="icon-only" name="add"></ion-icon>
			</ion-button>
		</div>

		<!-- Dépliant surveillances. -->
		<mat-expansion-panel togglePosition="after" class="seance-list surveillance-list add-pad">
			<mat-expansion-panel-header>
				<mat-panel-title>
					Surveillances
				</mat-panel-title>
			</mat-expansion-panel-header>

			<div class="surveillance-area">
				<idl-surveillance [params]="constantesListParams">
				</idl-surveillance>
			</div>
		</mat-expansion-panel>
		<div class="add-btn-area">
			<ion-button [disabled]="!canEdit" (click)="$event.stopPropagation(); chooseSurveillanceType()"
				class="button-edit blue-fab-button">
				<ion-icon slot="icon-only" name="add"></ion-icon>
			</ion-button>
		</div>

		<!-- Dépliant transmissions. -->
		<mat-expansion-panel togglePosition="after" class="seance-list add-pad">
			<mat-expansion-panel-header>
				<mat-panel-title>
					Transmissions
				</mat-panel-title>
			</mat-expansion-panel-header>

			<transmissions-list [params]="transmissionsListParams"></transmissions-list>
		</mat-expansion-panel>
		<div class="add-btn-area">
			<ion-button [disabled]="!canEdit" (click)="$event.stopPropagation(); createTransmission()"
				class="button-edit blue-fab-button">
				<ion-icon slot="icon-only" name="add"></ion-icon>
			</ion-button>
		</div>

		<!-- Dépliant transmissions. -->
		<mat-expansion-panel togglePosition="after" class="facturation-bloc seance-list add-pad">
			<mat-expansion-panel-header>
				<mat-panel-title>
					Facturation
				</mat-panel-title>
			</mat-expansion-panel-header>

			<div class="facturation-list">
				<calao-virtual-scroll *ngIf="pendingInvoices.length > 0; else noInvoices" [maxHeight]="(4 * 78) + 'px'"
					itemSize="78" class="marg-container" [items]="pendingInvoices">
					<ng-template let-invoice="$implicit">
						<ion-item class="ion-no-padding pointer" (click)="goToInvoice(invoice._id)" lines="none">
							<div class="invoice-item">
								<ion-label>Facture {{ invoice.invoiceNumber }}</ion-label>
								<ion-note class="price">{{ invoice.totalPartPatient | priceFormat:2 }} €</ion-note>
								<ion-note>part patient à encaisser</ion-note>
							</div>
						</ion-item>
					</ng-template>
				</calao-virtual-scroll>


				<ng-template #noInvoices>
					<div *ngIf="!hasSearchResult" class="empty-screen flex-col">
						<span class="txt-message">
							<ion-icon name="information-circle" class="no-result"></ion-icon>
							Aucune facture en attente d'encaissement.
						</span>
					</div>
				</ng-template>
			</div>
		</mat-expansion-panel>

		<!-- DÉPLIANT ACTES -->
		<mat-expansion-panel #actsPanel togglePosition="after"
			[ngClass]="!canEdit ? 'bloc-actes' : 'pad-bottom bloc-actes'">
			<mat-expansion-panel-header>
				<mat-panel-title>
					Actes
				</mat-panel-title>
			</mat-expansion-panel-header>

			<validate-actes [readonly]="!canEdit" [seance]="seance" [seances]="seances" [observation]="observation"
				[showMajorationsAndIndemnites]="true" [traitement]="traitement" (onSeancesChanged)="onSeancesChanged($event)"
				(onSeanceValidateAndBilled)="onSeancesChanged($event, true)" (onSeanceMoved)="onSeanceMoved($event)"
				(onNavigated)="close()" (onAddActeClick)="addAct()" (onTraitementChanged)="onTraitementChanged($event)"
				(onObservationChanged)="onObservationChanged($event)">
			</validate-actes>

			<div *ngIf="showBillBtn" class="bill-btn">
				<ion-button (click)="goToFacturation(true)" color="success" shape="round">
					<ion-icon slot="start" name="invoice"></ion-icon>
					Facturer
				</ion-button>
			</div>
		</mat-expansion-panel>
	</mat-accordion>

	<ion-item *ngIf="!canEdit" lines="none" class="marge-btm-list">
		<ion-icon name="alert-circle"></ion-icon>
		<ion-note class="information-text">Pour effectuer des actions sur cette séance, il faut être intervenant.
		</ion-note>
	</ion-item>
</ion-content>

<!-- TEMPLATE AUCUNE ORDONNANCE / PRESCRIPTION -->
<ng-template #emptyOrdonnance>
	<ion-item class="empty-ordonnance" lines="none">
		<ion-icon name="information-circle"></ion-icon>
		<ion-note>Aucune ordonnance/prescription.</ion-note>
	</ion-item>
</ng-template>

<!-- TEMPLATE AUCUN INTERVENANT -->
<ng-template #emptyIntervenant>
	<avatar [src]="emptyAvatar"></avatar>
</ng-template>