<osapp-header-modal [title]="title"></osapp-header-modal>

<ion-content>
	<idl-constantes-details [model]="surveillances" [readOnly]="false" [patient]="patientModel">
	</idl-constantes-details>
</ion-content>

<ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
	<ion-fab-button color="success" (click)="saveConstantes()" title="Sauvegarder">
		<ion-icon name="checkmark"></ion-icon>
	</ion-fab-button>
</ion-fab>