<div class="panneau-patient-identite__container">
	<div class="container__content">
		<di-formulaire-patient
			[patient]="patient"
			[showIdentityInputs]="true"
			[showLocationInputs]="false"
			libelleButton="Enregistrer"
			[onSubmit]="updatePatient"
		></di-formulaire-patient>
	</div>
</div>
