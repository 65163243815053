import { ObjectHelper } from '@osapp/helpers/objectHelper';

export class IndemniteError extends Error {

	constructor(psMessage: string) {
		super(psMessage);
		// Nécessaire pour que le 'instanceof IndemniteError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, IndemniteError);
	}

}