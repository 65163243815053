<osapp-header-modal title="Demande d'accord préalable"></osapp-header-modal>

<ion-content>
	<div *ngFor="let item of ApList | keyvalue" class="main-ctn">
		<div *ngFor="let fsp of item.value" class="fsp">
			<div class="fsp-detail">
				<ion-label>{{ fsp.name }} du {{ item.key.dateDemande | date: 'dd-MM-yyyy' }}</ion-label>

			</div>
			<div class="btns">
				<ion-icon (click)="goToPdf(fsp)" color="osapp-element" name="see-document">
				</ion-icon>
				<ion-icon (click)="onDownloadPdfClicked(item.key, fsp)" color="osapp-element" name="download">
				</ion-icon>
			</div>
		</div>
	</div>
</ion-content>