import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { InitComponentError } from '@osapp/model/errors/InitComponentError';

export class InitTransmissionError extends InitComponentError {

	constructor() {
		super("Un modèle de patient est nécessaire pour la création d'une transmission.");
		// Nécessaire pour que le 'instanceof InitTransmissionError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, InitTransmissionError);
	}

}