import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalComponentBase } from '@osapp/modules/modal';
import { PlatformService } from '@osapp/services/platform.service';

export interface ICancelActeResponse {
	hasToCancelFollowings: boolean,
	explanation: string
}

@Component({
	templateUrl: './cancelActeModal.component.html',
	styleUrls: ['./cancelActeModal.component.scss']
})
export class CancelActeModalComponent extends ModalComponentBase<ICancelActeResponse> {

	//#region PROPERTIES

	public hasToCancelFollowings = false;
	public explanation: string;

	//#endregion

	//#region METHODS

	constructor(poModalCtrl: ModalController, psvcPlatform: PlatformService) {
		super(poModalCtrl, psvcPlatform);
	}

	public validate(): void {
		const loResponse: ICancelActeResponse = {
			hasToCancelFollowings: this.hasToCancelFollowings,
			explanation: this.explanation
		};

		this.close(loResponse);
	}

	//#endregion
}