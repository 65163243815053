import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MedecinService {
	
	private selectMedecinAction = new Subject<string>();

	selectMedecin$ = this.selectMedecinAction.asObservable();

	public emitSelectMedecin(medecinId: string) {
    this.selectMedecinAction.next(medecinId);
  }
}