export enum EIdlLogActionId {
	seancesExport = "idl-seances-export",
	seancesBill = "idl-seances-bill",
	seancesCancel = "idl-seances-cancel",
	seancesReactivate = "idl-seances-reactivate",
	seancesValidate = "idl-seances-validate",
	seancesExportCancel = "idl-seances-export-cancel",
	seancesBillingLock = "idl-seances-billing-lock",
	traitementDelete = "idl-traitement-delete",
	traitementCreate = "idl-traitement-create",
	traitementStop = "idl-traitement-stop",
	traitementReactivate = "idl-traitement-reactivate",
	traitementUpdate = "idl-traitement-update",
	traitementBillingLock = "idl-traitement-billing-lock",
	traitementDuplicate = "idl-traitement-duplicate",
	acteDelete = "idl-acte-delete",
	acteCancel = "idl-acte-cancel",
	patientCreate = "idl-patient-create",
	patientDelete = "idl-patient-delete",
	patientUpdate = "idl-patient-update",
	transmissionCreate = "idl-transmission-create",
	transmissionClose = "idl-transmission-close",
	planningCreate = "idl-planning-create",
	planningDelete = "idl-planning-delete",
	planningApply = "idl-planning-apply",
	prestationExport = "idl-prestations-export",
	invoiceSave = "idl-invoice-save",
	invoiceExport = "idl-invoice-export",
	invoiceCancel = "idl-invoice-cancel",
	invoiceSecure = "idl-invoice-secure",
	invoiceCashOut = "idl-invoice-cash-out"
}