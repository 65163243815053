import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule, Type } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { RouterModule } from '@angular/router';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { FormlyModule } from '@ngx-formly/core';
import { BadgeModule } from '../../modules/badge/badge.module';
import { CalaoCheckboxModule } from '../../modules/checkbox/checkbox.module';
import { ConflictsModule } from '../../modules/conflicts/conflicts.module';
import { ContactsModule as OsappContactsModule } from '../../modules/contacts/contacts.module';
import { FavoritesModule } from '../../modules/preferences/favorites/favorites.module';
import { SelectorModule } from '../../modules/selector/selector.module';
import { PipeModule } from '../../pipes/pipes.module';
import { SharedComponentsModule } from '../sharedComponents.module';
import { SlideboxModule } from '../slidebox';
import { ContactComponent } from './contact/contact.component';
import { ContactsComponent } from './contacts.component';
import { ContactsDynHostComponent } from './contactsDynHost/contactsDynHost.component';
import { ContactSelectorComponent } from './contactSelector/contactSelector.component';
import { ContactsListComponent } from './contactsList/contactsList.component';
import { GroupDetailsComponent } from './group-details/group-details.component';
import { GroupsChecklistComponent } from './groups-checklist/groups-checklist.component';
import { GroupsDynHostComponent } from './groupsDynHost/groupsDynHost.component';

const modules: Array<Type<any>> = [
	SharedComponentsModule,
	SlideboxModule,
	FormlyModule,
	PipeModule,
	SuperTabsModule,
	CalaoCheckboxModule,
	SelectorModule,
	FavoritesModule,
	MatBadgeModule,
	ScrollingModule,
	MatRadioModule,
	RouterModule,
	BadgeModule,
	ConflictsModule,
	OsappContactsModule
];
const exportComponents: Array<Type<any>> = [
	ContactSelectorComponent,
	ContactComponent,
	ContactsComponent,
	GroupDetailsComponent,
	ContactsListComponent,
	GroupsChecklistComponent
];
const components: Array<Type<any>> = [
	ContactsDynHostComponent,
	GroupsDynHostComponent
];

/** Module de gestion des contacts. */
@NgModule({
	imports: modules,
	exports: exportComponents,
	declarations: [...exportComponents, ...components],
	providers: [{ provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: "primary" } }]
})
export class ContactsModule { }