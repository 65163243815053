import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { EFormEventType } from '../../../../model/forms/EFormEventType';
import { FieldBase } from '../../../../model/forms/FieldBase';
import { IDrawingFieldParams } from '../../../../model/forms/IDrawingFieldParams';
import { DrawingModalComponent } from '../../../../modules/drawing/components/drawing-modal/drawing-modal.component';
import { IDrawingModalParams } from '../../../../modules/drawing/models/IDrawingModalParams';
import { IDrawingResult } from '../../../../modules/drawing/models/IDrawingResult';
import { ModalService } from '../../../../modules/modal/services/modal.service';
import { FormsService } from '../../../../services/forms.service';

@Component({
	templateUrl: './drawingField.component.html',
	styleUrls: ['./drawingField.component.scss'],
})
export class DrawingFieldComponent extends FieldBase<any> implements OnInit {

	//#region FIELDS

	private readonly C_DEFAULT_DRAWING_PREFIX = "draw_";

	//#endregion

	//#region PROPERTIES

	private moDrawing: IDrawingResult;
	/** Dessin représentant le dessin, peut être `undefined` si aucun dessin de fait. */
	public get drawing(): IDrawingResult { return this.moDrawing; }

	/** Paramètres que l'on peut fournir à ce composant. */
	public params: IDrawingFieldParams;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcModal: ModalService,
		psvcForms: FormsService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetector);
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this.params = this.to.data;

		const localData: string = localStorage.getItem(`${this.params.drawingPrefix ?? this.C_DEFAULT_DRAWING_PREFIX}${this.model._id}`);
		if (localData)
			this.moDrawing = JSON.parse(localData);

		this.isvcForms.waitFormEvent(this.model._id, EFormEventType.beforeSubmit)
			.pipe(
				tap(_ => {
					this.localSave(this.moDrawing);
				}),
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();
	}

	/** Ouvre la modale pour dessiner. */
	public openDrawingModal(): void {
		this.isvcModal.open<IDrawingResult>({
			component: DrawingModalComponent,
			componentProps: {
				title: this.params.modalTitle
			} as IDrawingModalParams
		})
			.pipe(
				tap((poResult: IDrawingResult) => {
					if (poResult) {
						this.moDrawing = poResult;
						this.markAsDirty();
						this.detectChanges();
					}
				}),
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();
	}

	public remove(): void {
		this.moDrawing = undefined;
		this.markAsDirty();
		this.detectChanges();
	}

	private localSave(poDrawing?: IDrawingResult): void {
		const lsItemId = `${this.params.drawingPrefix ?? this.C_DEFAULT_DRAWING_PREFIX}${this.model._id}`;
		if (poDrawing)
			localStorage.setItem(lsItemId, JSON.stringify(poDrawing));
		else
			localStorage.removeItem(lsItemId);
	}

	//#endregion METHODS

}
