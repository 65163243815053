import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConversationService } from '.';
import { IConversationActivity } from '../model';

@Injectable()
export class ArchivingService {

	constructor(private readonly isvcConversation: ConversationService) { }

	/** Archive une conversation.
	 * @param poConversationActivity Le document d'activité à modifier.
	 */
	public archiveConversation(poConversationActivity: IConversationActivity): Observable<IConversationActivity> {
		poConversationActivity.archive = new Date();
		return this.isvcConversation.updateActivity(poConversationActivity)
	}

	/** Restaure une conversation.
	 * @param poConversationActivity Le document d'activité à modifier.
	 */
	public restoreConversation(poConversationActivity: IConversationActivity): Observable<IConversationActivity> {
		delete poConversationActivity.archive;
		return this.isvcConversation.updateActivity(poConversationActivity)
	}
}
