import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { EnumHelper } from '@osapp/helpers/enumHelper';
import { ETransmissionType } from '../../../model/ETransmissionType';
import { ITransmissionParams } from '../../../model/ITransmissionParams';
import { ITransmissionRapport } from '../../../model/ITransmissionRapport';

@Component({
	selector: 'transmission',
	templateUrl: './transmission.component.html',
	styleUrls: ['./transmission.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransmissionComponent extends ComponentBase implements ITransmissionParams {

	//#region PROPERTIES

	/** @implements */
	@Input() public model: ITransmissionRapport;
	/** @implements */
	@Input() public readOnly: boolean;

	/** Tableau contenant les différents types de transmissions possibles. */
	public readonly transmissionTypeValues: ETransmissionType[] = EnumHelper.getValues(ETransmissionType);

	//#endregion

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	public onFilesChanged(): void {
		this.detectChanges();
	}

	//#endregion
}