<div class="list-item-ctn" *ngFor="let item of ordonnances">
	<div class="v-center gap">
		<div *ngIf="item.documents?.length > 0" class="gallery-content">
			<gallery [files]="[item.documents[0]]" [readOnly]="true" [hideFileDetails]="true">
			</gallery>
		</div>
		<div *ngIf="!excludeOrdonnancesIds?.includes(item.guid)" class="list-item" (click)="openOrdonnance(item)">
			<div class="v-center">
				<div class="list-item-detail">
					<div class="list-item-description" class="list-item-description">
						{{ item.prescriptionDate | date:'dd/MM/yyyy' }}
					</div>
					<div *ngIf="contactsById.get(item.prescripteurContactId) as prescripteur" class="list-item-file-name">
						{{ prescripteur | contactName }}
					</div>
					<div *ngIf="getResumeActes(item) as resume" class="list-item-file-name">
						{{ resume }}
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="list-item-options">
		<ion-icon class="option-icon" (click)="openOrdonnance(item)" color="osapp-element" name="create">
		</ion-icon>
		<ion-icon class="option-icon" (click)="onDeleteOrdonnance(item)" color="danger" name="trash">
		</ion-icon>
	</div>
</div>