import { Observable, throwError, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { NumberHelper } from '../../../helpers/numberHelper';
import { ENetworkFlag } from '../../../model/application/ENetworkFlag';
import { IDmsTaskParams } from '../../../model/backgroundTask/taskParams/IDmsTaskParams';
import { TaskDescriptor } from '../../../services/backgroundTask/TaskDescriptor';
import { DmsTaskBase } from './DmsTaskBase';

export class DownloadDmsFileTask<T extends IDmsTaskParams> extends DmsTaskBase<T> {

	//#region FIELDS

	/** Indique le nombre d'essai maximum pour une tâche avant qu'elle ne soit supprimée en abandonnant. */
	private static readonly C_TRY_LIMIT = 10;

	//#endregion

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<T>) {
		super(poDescriptor);
	}

	/** @override */
	public execTask(): Observable<Array<string>> {
		return this.msvcFlag.waitForFlag(ENetworkFlag.isOnlineReliable, true)
			.pipe(mergeMap(_ => this.msvcSyncDms.execPendingDownload(this.descriptor.params.fileGuid)));
	}

	/** @override */
	public retryStrategy(poErrors$: Observable<any>): Observable<any> {
		return poErrors$ // Observable qui fournit chaque erreur de la tâche.
			.pipe(
				mergeMap((poError: any, pnIndex: number) => {
					if (pnIndex === DownloadDmsFileTask.C_TRY_LIMIT)
						return throwError(poError);
					else {
						const lbHasValidIntervalRepetition: boolean = NumberHelper.isValidPositive(this.descriptor.intervalRepetition);

						if (!lbHasValidIntervalRepetition)
							this.descriptor.retryInterval *= this.descriptor.intervalMultiplicator;

						const lnIntervalTime: number = lbHasValidIntervalRepetition ? this.descriptor.intervalRepetition : this.descriptor.retryInterval;

						this.descriptor.lastExec = new Date();

						return timer(lnIntervalTime);
					}
				})
			);
	}

	//#endregion
}