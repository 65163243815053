<div class="panneau-patient-ordonance">
	<div>
		<div class="panneau-patient-ordonance__container">
			<div class="container__content">
				<di-recherche-patients [mobileViewForce]="true" [displayCount]="true"
					(onClickLigne)="onClickPatient($event)"></di-recherche-patients>
			</div>
		</div>
	</div>

</div>