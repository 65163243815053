import { EOccurrenceType } from './EOccurrenceType';
import { IActeOccurrence } from './iacte-occurrence';
import { IActeOccurrenceComparator } from './iacte-occurrence-comparator';
import { IActeOccurrenceComparatorParams } from './iacte-occurrence-comparator-params';
import { IConstraintLabelParams } from './iconstraint-label-params';

export abstract class ActeOccurrenceComparator<T extends IActeOccurrenceComparatorParams = IActeOccurrenceComparatorParams> implements IActeOccurrenceComparator<T> {

	//#region PROPERTIES	

	/** @implements */
	public params?: T;
	/** @implements */
	public abstract type: EOccurrenceType;

	//#endregion

	//#region METHODS

	constructor(poParams?: T) {
		this.params = poParams;
	}

	public abstract getLabel(poParams: IConstraintLabelParams): string;

	/** Indique si l'occurrence correspond au comparateur.
	 * @param poActeOccurrence 
	 */
	public abstract match(poActeOccurrence: IActeOccurrence): boolean;

	public getIntervenantsIds(): string[] {
		return [];
	}

	//#endregion

}
