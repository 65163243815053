import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BlobUrlManager } from "@osapp/components/image/model/BlobUrlManager";

@Component({
	selector: "di-interruption-ordonnance",
	templateUrl: "./interruption-ordonnance.component.html",
	styleUrls: ["./interruption-ordonnance.component.scss"],
})
export class InterruptionOrdonnanceComponent implements OnInit {
	isLargeScreen: boolean = false;
	private readonly moBlobUrlManager = new BlobUrlManager();

	constructor() {}

	ngOnInit(): void {}
	@Input() title: string = "";
	@Input() description = "";
	@Input() labelActionPrincipale = "";
	@Input() withIcon: boolean = false;
	@Output() confirm = new EventEmitter<void>();
	@Output() cancel = new EventEmitter<void>();

	onConfirm() {
		this.confirm.emit();
	}

	onCancel() {
		this.cancel.emit();
	}
}
