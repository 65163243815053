import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Renderer2, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IonImg } from '@ionic/angular';
import { EMPTY, Observable, Subscription, of, timer } from 'rxjs';
import { finalize, mergeMap, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { FileHelper } from '../../../../helpers/fileHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { IBase64Data } from '../../../../model/file/IBase64Data';
import { EFormEventType } from '../../../../model/forms/EFormEventType';
import { FieldBase } from '../../../../model/forms/FieldBase';
import { IPictureFieldParams } from '../../../../model/forms/IPictureFieldParams';
import { IPicture } from '../../../../model/picture/IPicture';
import { IPictureData } from '../../../../model/picture/IPictureData';
import { CameraService } from '../../../../modules/camera/services/camera.service';
import { DmsFile } from '../../../../modules/dms/model/DmsFile';
import { IDmsData } from '../../../../modules/dms/model/IDmsData';
import { IDmsMeta } from '../../../../modules/dms/model/IDmsMeta';
import { DmsService } from '../../../../modules/dms/services/dms.service';
import { LongGuidBuilder } from '../../../../modules/guid/models/long-guid-builder';
import { PatternsHelper } from '../../../../modules/utils/helpers/patterns.helper';
import { FormsService } from '../../../../services/forms.service';
import { ShowMessageParamsPopup } from '../../../../services/interfaces/ShowMessageParamsPopup';
import { PatternResolverService } from '../../../../services/pattern-resolver.service';
import { UiMessageService } from '../../../../services/uiMessage.service';
import { FormComponent } from '../form.component';

type PictureUrlAndAlt = { url: string, alt: string };

/** Champs de formulaire contenant une image. */
@Component({
	templateUrl: './pictureField.component.html',
	styleUrls: ['./pictureField.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PictureFieldComponent extends FieldBase<IPicture> implements OnInit {

	//#region FIELDS

	/** Alt et icône par défaut de l'image : "image". */
	private static readonly C_DEFAULT_ALT_OR_ICON = "image";

	/** Indique si l'image a été changée afin de ne pas enregistrer à chaque fois dans le DMS. */
	private mbHasChanged = false;

	public msDeletedGUID ?: string;

	/** On doit garder un longGuidBuilder pour être compatible avec la GED. */
	private readonly moGuidBuilder = new LongGuidBuilder();

	//#endregion

	//#region PROPERTIES

	/** Paramètres possibles pour ce composant. */
	public params: IPictureFieldParams;
	/** Image manipulée à afficher. */
	public imageSource: string | Blob;
	/** Indique si le composant est en train de charger une image ou non. */
	public isLoading = false;

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des popups et toasts. */
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcDms: DmsService,
		private readonly ioSanitizer: DomSanitizer,
		private readonly ioRenderer: Renderer2,
		private readonly ioParentForm: FormComponent,
		private readonly isvcPatternResolver: PatternResolverService,
		private readonly isvcCamera: CameraService,
		psvcForms: FormsService,
		/** Service de gestion du rafraîchissement de la vue. */
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	/** Endroit où initialiser le composant après sa création. */
	public ngOnInit(): void {
		super.ngOnInit();

		this.initParams();
		this.initModel();

		if (this.params.readOnly) {
			this.ioParentForm.getParentPage().viewBackTo$ // TODO Voir tâche 1330. Permet de rafraîchir l'image lors du retour en mode visu.
				.pipe(
					tap(_ => this.initModel()),
					takeUntil(this.fieldDestroyed$)
				)
				.subscribe();
		}

		this.detectChanges();

		this.isvcForms.waitFormEvent(this.model._id, EFormEventType.afterSubmit).pipe(
			takeWhile(_ => !this.params.readOnly),
			//! Il faut faire le takeUntil AVANT le mergeMap pour que le téléversement de l'image se fasse même si le composant est détruit.
			takeUntil(this.fieldDestroyed$),
			mergeMap(() => {
				if(!StringHelper.isBlank(this.fieldValue?.guid) && this.mbHasChanged)
				{
					return this.saveGuidPicture();
				}
				else if(!StringHelper.isBlank(this.msDeletedGUID))
				{
					return this.isvcDms.delete(this.fieldValue.guid);
				}
				else
				{
					return EMPTY;
				}
			}
		)).subscribe();
	}

	/** Enregistre l'image en mode `guid`. */
	private saveGuidPicture(): Observable<IDmsMeta> {
		let loDmsFile: DmsFile;

		if (this.imageSource instanceof Blob)
			loDmsFile = new DmsFile(this.imageSource, this.fieldValue.alt);
		else
			loDmsFile = new DmsFile(this.imageSource, this.fieldValue.alt, this.fieldValue.mimeType, this.fieldValue.size);

		return this.isvcDms.save(loDmsFile, loDmsFile.createDmsMeta(this.fieldValue.guid));
	}

	/** Initialise les données du modèle. */
	private initModel(): void {
		if (!this.fieldValue && this.params.value)
			this.fillPictureFromValue();
		else if (!this.fieldValue)
			this.setEmptyPicture();

		if (!StringHelper.isBlank(this.fieldValue.base64))
			this.imageSource = this.fieldValue.base64;
		else if (!StringHelper.isBlank(this.fieldValue.url))
			this.imageSource = this.fieldValue.url;
		else if (!StringHelper.isBlank(this.fieldValue.guid))
			this.innerInitModel_withGuid();
			else{
				this.imageSource = undefined;
				this.detectChanges();
			}

		if (StringHelper.isBlank(this.fieldValue.alt))
			this.fieldValue.alt = PictureFieldComponent.C_DEFAULT_ALT_OR_ICON;

		if (this.params.forceSaving)
			this.model[this.fieldKey] = this.fieldValue;
	}

	private innerInitModel_withGuid(): void {
		this.isvcDms.get(this.fieldValue.guid)
			.pipe(
				tap(
					(poDmsData: IDmsData) => {
						this.imageSource = poDmsData.file.File;
						this.detectChanges();
					},
					poError => console.error("PICF.C::", poError)
				),
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();
	}

	/** Résoud le pattern d'une valeur et la retourne.
	 * @param psValue Valeur sous forme de patter qu'on veut résoudre.
	 */
	private getDynValue(psValue: string): PictureUrlAndAlt {
		return this.isvcPatternResolver.resolveFormsPattern(
			psValue.split(PatternsHelper.C_START_PATTERN)[1].split(PatternsHelper.C_END_PATTERN)[0],
			"",
			psValue,
			this.ioParentForm.parentEntity
		);
	}

	/** Initialise les paramètres d'entrées du composant. */
	private initParams(): void {
		this.params = this.to.data;

		if (this.params.editable === false)
			this.params.readOnly = true;

		this.params.cameraOptions = this.isvcCamera.getAndSetCameraOptions(this.params.cameraOptions);

		if (!this.params.maxSizeKb)
			this.params.maxSizeKb = CameraService.C_MAX_PICTURE_SIZE_KB;

		if (StringHelper.isBlank(this.params.icon))
			this.params.icon = PictureFieldComponent.C_DEFAULT_ALT_OR_ICON;
	}

	/** Remplit l'image si elle a une valeur dans les options de paramètres. */
	private fillPictureFromValue(): void {
		const loPicture: IPictureData = this.getDynValue(this.params.value);

		if (loPicture) {
			this.setEmptyPicture();

			if (StringHelper.isBlank(loPicture.url))
				this.fieldValue.base64 = loPicture.base64;

			else {
				this.fieldValue.url = loPicture.url;
				this.fieldValue.alt = loPicture.alt;
				this.fieldValue.mimeType = FileHelper.extractMimeTypeFromFileNameWithExtension(loPicture.url);

				if (!this.fieldValue.mimeType)
					console.error(`PICF.C::No mimeType for picture from ${this.fieldValue.url ? "url" : "base64"}.`);
			}
		}
	}

	/** Définit l'objet image avec des paramètres vides. */
	private setEmptyPicture(): void {
		this.fieldValue = {
			mimeType: "",
			size: 0,
			base64: "",
			url: "",
			alt: ""
		};
	}

	/** Reçoit un événement qui indique que le chargement d'une image est en cours (true) ou s'il n'y en a pas (false).
	 * @param pbIsLoading Indique si le composant set en train de charger une image ou non.
	 */
	public onLoadingChanged(pbIsLoading: boolean): void {
		if (this.isLoading !== pbIsLoading) {
			this.isLoading = pbIsLoading;
			this.detectChanges();
		}
	}

	/** L'image choisie par le filepicker a changé, on récupère sa base64 pour mettre à jour le modèle.
	 * @param poFile Fichier image récupéré lors d'un changement.
	 */
	public onSelectedPictureChanged(poFile?: File): void {
		if (poFile) {
			this.imageSource = poFile;

			const loTransform$: Observable<IBase64Data | undefined> = this.params.saveAsBase64 ?
				FileHelper.blobToBase64Data(poFile) : of(undefined);

			loTransform$
				.pipe(
					tap((poResult?: IBase64Data) => {
						this.setPicture(poFile, poResult);
						this.onLoadingChanged(false);
					}),
					takeUntil(this.fieldDestroyed$)
				)
				.subscribe();
		}
		else
			this.onLoadingChanged(false);
	}

	/** Modifie l'image courante par la nouvelle image sélectionnée/prise.
	 * @param poFile Fichier de la nouvelle image.
	 * @param poBase64Object Objet contenant la base64 de l'image, optionnel.
	 */
	private setPicture(poFile: File, poBase64Object?: IBase64Data): void {
		this.mbHasChanged = true;
		this.msDeletedGUID = undefined;

		// Si la nouvelle valeur est différente de l'ancienne, il faut marquer le champ comme 'dirty'.
		if (this.fieldValue.alt !== poFile.name || this.fieldValue.size !== poFile.size || this.fieldValue.mimeType !== poFile.type)
			this.markAsDirty();

		this.fieldValue = {
			base64: this.params.saveAsBase64 ? poBase64Object.base64 : undefined,
			mimeType: poFile.type,
			size: poFile.size,
			alt: poFile.name,
			guid: this.params.saveAsBase64 ? undefined : this.moGuidBuilder.build()
		} as IPicture;
	}

	/** Prend une photo et met à jour le modèle. */
	public takePhoto(): void {
		const loTimerSubscription: Subscription = timer(500).pipe(tap(_ => this.onLoadingChanged(true))).subscribe();

		this.isvcCamera.takePicture(this.params.cameraOptions, this.params.maxSizeKb)
			.pipe(
				tap(
					(poFile?: File) => this.onSelectedPictureChanged(poFile),
					poError => {
						console.error("PICF.C::", poError);
						this.onLoadingChanged(false); // Dans le cas d'une erreur, on enlève le loader.
						this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Une erreur est survenue lors du traitement de l'image.", header: "Erreur" }));
					}
				),
				finalize(() => loTimerSubscription.unsubscribe()), // On se désabonne du timer à la fin (prise de photo ou erreur).
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();
	}

	/** Gère l'erreur d'une balise `ion-img` en remplaçant sa source si possible ou en ne mettant aucune source.
	 * @param poPicture Balise `ion-img` qui est tombée en erreur.
	 */
	public onError(poPicture: IonImg & { el: any }): void {
		const lsDefaultUrl: string = StringHelper.isBlank(this.params.defaultImageUrl) ? "" : this.params.defaultImageUrl;
		poPicture.src = this.ioSanitizer.sanitize(SecurityContext.URL, lsDefaultUrl);
		this.ioRenderer.addClass(poPicture.el, "defaultImg");
	}

	/** Supprime l'image de la fiche.
	 * @param poFile Fichier image récupéré lors d'un changement.
	 */
	public deleteImage(): void
	{
			this.msDeletedGUID = this.fieldValue.guid;
			this.mbHasChanged = false;
			this.setEmptyPicture();
			this.imageSource = undefined;
			this.detectChanges();
	}

	//#endregion
}
