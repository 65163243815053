<div [ngClass]="className" class="arrows">
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		viewBox="0 0 47.5 25.6" style="enable-background:new 0 0 47.5 25.6;" xml:space="preserve">
		<path id="arrow-bottom" fill="var(--ion-color-primary-contrast)"
			d="M26.8,18.4c0-0.2,0-0.3,0-0.4c0-2.5,0-5,0-7.5c0-0.5,0-0.5,0.5-0.5c1.3,0,2.6,0,3.9,0c0.4,0,0.5,0.1,0.5,0.5
	c0,2.5,0,5,0,7.6c0,0.1,0,0.2,0,0.4c0.2,0,0.3,0,0.5,0c0.8,0,1.5,0,2.3,0c0.1,0,0.3,0.1,0.4,0.2c0.1,0.2,0,0.4-0.1,0.5
	c-1.6,1.9-3.2,3.7-4.7,5.6c-0.2,0.2-0.4,0.4-0.5,0.6c-0.2,0.3-0.4,0.3-0.6,0c-1-1.2-2.1-2.5-3.1-3.7c-0.7-0.8-1.4-1.6-2-2.4
	c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.3,0-0.4c0-0.1,0.2-0.2,0.3-0.2c0.8,0,1.7,0,2.5,0C26.5,18.4,26.6,18.4,26.8,18.4z" />
		<path fill="var(--ion-color-primary-contrast)" d="M44.2,7.2c0,0.2,0,0.3,0,0.4c0,2.5,0,5,0,7.5c0,0.5,0,0.5-0.5,0.5c-1.3,0-2.6,0-4,0c-0.4,0-0.5-0.1-0.5-0.5c0-2.5,0-5,0-7.6
	c0-0.1,0-0.2,0-0.4c-0.3,0-0.5,0-0.7,0c-0.7,0-1.4,0-2,0c-0.1,0-0.4-0.1-0.4-0.2c0-0.2,0-0.4,0.1-0.5c1.1-1.3,2.2-2.6,3.2-3.9
	c0.7-0.8,1.3-1.6,2-2.4c0.3-0.3,0.4-0.3,0.6,0c1.7,2.1,3.5,4.2,5.2,6.2c0.1,0.2,0.2,0.3,0.1,0.5c-0.1,0.2-0.3,0.2-0.5,0.2
	C46,7.2,45.1,7.2,44.2,7.2z" />
	</svg>
</div>
<div>
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		viewBox="0 0 50 32" style="enable-background:new 0 0 50 32;" xml:space="preserve">
		<g>
			<g>
				<path fill="var(--ion-color-primary-contrast)" d="M2.3,17.8c1.2-1.5,2.8-2.5,4.7-3c-0.6-0.9-0.9-2-0.9-3.1c0-1.6,0.5-2.9,1.6-4c1.1-1.1,2.4-1.7,4-1.7
c1.4,0,2.6,0.5,3.6,1.4c0.9-2.1,2.2-3.8,4.1-5.1c1.9-1.3,4-2,6.3-2c3.1,0,5.8,1.1,7.9,3.3c2.2,2.2,3.3,4.9,3.3,8c0,0.2,0,0.5,0,1
c1.7,0.8,3.1,2,4.1,3.6c1,1.6,1.6,3.4,1.6,5.4c0,2.7-1,5.1-2.9,7c-1.9,1.9-4.2,2.9-6.9,2.9H8.9c-2.3,0-4.3-0.8-6-2.5
c-1.6-1.7-2.5-3.7-2.5-6C0.5,21.1,1.1,19.4,2.3,17.8z" />
			</g>
		</g>
		<ellipse fill="var(--ion-color-primary-contrast)" cx="34.6" cy="16.8" rx="15" ry="14.9" />
		<path fill="var(--ion-color-success)" class="st0"
			d="M47.1,16.7c0,6.8-5.5,12.2-12.3,12.2s-12.3-5.5-12.3-12.2c0-6.8,5.5-12.2,12.3-12.2S47.1,9.9,47.1,16.7z" />
	</svg>
</div>