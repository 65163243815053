import { Injectable } from "@angular/core";
import { ArrayHelper, DateHelper } from "@osapp/helpers";
import { EDatabaseRole, ETimetablePattern, IDataSource, IStoreDataResponse } from "@osapp/model";
import { Store } from "@osapp/services";
import { Acte } from "apps/idl/src/model/Acte";
import { EStatusSeance } from "apps/idl/src/model/EStatusSeance";
import { ITraitement } from "apps/idl/src/model/ITraitement";
import { Traitement } from "apps/idl/src/model/Traitement";
import { TraitementService } from "apps/idl/src/services/traitement.service";
import { Observable, Subject, combineLatest, of } from "rxjs";
import { map, mergeMap, tap } from "rxjs/operators";
import { IModifActesSeance } from "../../../models/IModifActesSeance";
import { StoredSeance } from "../../../models/StoredSeance";

@Injectable({
    providedIn: "root"
})
export class SeanceService {

    constructor(private svcStore: Store, private svcTraitement: TraitementService) {
    }

    private refreshSeanceSubject = new Subject<void>();
    refreshSeanceList$ = this.refreshSeanceSubject.asObservable();

    triggerRefreshSeanceList() {
        this.refreshSeanceSubject.next();
    }

    // Récupère les seances pour une ordonnance/traitement donné
    public selectSeancesByTraitement(traitement: ITraitement): Observable<StoredSeance[]> {
        const dataSource: IDataSource = {
            databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
            viewName: "seances/by_traitement",
            viewParams: {
                key: traitement._id
            }
        };
        return this.svcStore.get<StoredSeance>(dataSource).pipe(
            map(seances => this.sortSeancesChronologically(seances))
        );
    }

    // Récupère les séances qui commencent à la date donnée
    public selectSeancesByDate(date: Date): Observable<StoredSeance[]> {
        const startOfDay = DateHelper.transform(date, ETimetablePattern.isoFormat_hyphen) + "T00:00:00.000Z";
        const endOfDay = DateHelper.transform(date, ETimetablePattern.isoFormat_hyphen) + "T23:59:59.000Z";

        const dataSource: IDataSource = {
            databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
            viewName: "seances/by_date",
            viewParams: {
                startkey: startOfDay,
                endkey: endOfDay
            }
        };
        return this.svcStore.get<StoredSeance>(dataSource).pipe(
            map(seances => this.sortSeancesChronologically(seances))
        );
    }

    // Récupère les séances qui commencent sur l'intervalle de date donné
    public selectSeancesByRange(startDate: Date, endDate: Date): Observable<StoredSeance[]> {
        const dataSource: IDataSource = {
            databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
            viewName: "seances/by_date",
            viewParams: {
                startkey: DateHelper.transform(startDate, ETimetablePattern.isoFormat_hyphen),
                endkey: DateHelper.transform(DateHelper.addDays(endDate, 1), ETimetablePattern.isoFormat_hyphen)
            }
        };
        return this.svcStore.get<StoredSeance>(dataSource).pipe(
            map(seances => this.sortSeancesChronologically(seances))
        );
    }

    // Récupère les séances affectés à l'infirmier ainsi que celles non affectées
    public selectSeancesByInfirmier(infirmierId: string): Observable<StoredSeance[]> {
        const filterKeys: string[] = [infirmierId, "non assigné"];
        const dataSource: IDataSource = {
            databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
            viewName: "seances/by_infirmier",
            viewParams: {
                keys: filterKeys
            }
        };
        return this.svcStore.get<StoredSeance>(dataSource).pipe(
            map(seances => this.sortSeancesChronologically(seances))
        );
    }


    // Récupère les séances affectés à l'infirmier ainsi que celles non affectées pour un jour donné
    public selectSeancesByDateAndInfirmier(startDate: Date, infirmierId: string): Observable<StoredSeance[]> {
        const seancesByInfirmier$ = this.selectSeancesByInfirmier(infirmierId);
        const seancesByDate$ = this.selectSeancesByDate(startDate);

        return combineLatest([seancesByInfirmier$, seancesByDate$]).pipe(
            map(([seancesByInfirmier, seancesByDate]) => {
                const infirmierSeanceIds = new Set(seancesByInfirmier.map(seance => seance._id))
                const filteredSeances = seancesByDate.filter(seance => infirmierSeanceIds.has(seance._id));
                return this.sortSeancesChronologically(filteredSeances);
            })
        );
    }


    // Récupère les séances sur une plage de date donnée en fonction de l'infirmier choisi
    public selectSeancesByRangeAndInfirmier(startDate: Date, endDate: Date, infirmierId: string): Observable<StoredSeance[]> {
        const seancesByInfirmier$ = this.selectSeancesByInfirmier(infirmierId);

        const seancesByRange$ = this.selectSeancesByRange(startDate, endDate);

        return combineLatest([seancesByInfirmier$, seancesByRange$]).pipe(
            map(([seancesByInfirmier, seancesByRange]) => {
                const infirmierSeanceIds = new Set(seancesByInfirmier.map(seance => seance._id));
                const filteredSeances = seancesByRange.filter(seance => infirmierSeanceIds.has(seance._id));
                return this.sortSeancesChronologically(filteredSeances);
            })
        );
    }

    public sortSeancesChronologically(seances: StoredSeance[]): StoredSeance[] {
        if (!seances) return [];
        return seances.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
    }


    // Récupère une séance
    public selectSeance(seanceId: string): Observable<StoredSeance> {
        const dataSource: IDataSource = {
            databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
            viewParams: {
                include_docs: true,
                key: seanceId
            }
        };
        return this.svcStore.getOne<StoredSeance>(dataSource);
    }

    // Met à jour une séance
    public updateSeance(seance: StoredSeance): Observable<boolean> {
        const databaseId = ArrayHelper.getFirstElement(this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace));
        return this.svcStore.put(seance, databaseId).pipe(
            tap(() => {
                this.triggerRefreshSeanceList();
            }),
            mergeMap((response: IStoreDataResponse) => {
                return of(response.ok)
            }
            )
        )
    }

    //Supprime une seance
    public deleteSeance(seance: StoredSeance): Observable<boolean> {
        return this.svcStore.delete(seance).pipe(
            tap(() => {
                this.triggerRefreshSeanceList();
            }),
            map((poResponse: IStoreDataResponse) => poResponse.ok)
        )
    }

    // A partir d'une séance donnée, retourne la liste des séances futurs
    public getSeancesNonFactures(selectedSeance: StoredSeance): Observable<StoredSeance[]> {
        return this.svcTraitement.getTraitementANAKIN(selectedSeance.traitementId).pipe(
            mergeMap((traitement: Traitement) => this.selectSeancesByTraitement(traitement)),
            map((seances: StoredSeance[]) =>
                seances.filter((seance: StoredSeance) =>
                    seance.status !== EStatusSeance.completed
                )
            )
        );
    }

    // A partir d'une séance donnée, retourne la liste de toutes les séances du traitement qui ne sont pas facturées
    public getFuturSeances(selectedSeance: StoredSeance): Observable<StoredSeance[]> {
			return this.svcTraitement.getTraitementANAKIN(selectedSeance.traitementId).pipe(
					mergeMap((traitement: Traitement) => this.selectSeancesByTraitement(traitement)),
					map((seances: StoredSeance[]) =>
							seances.filter((seance: StoredSeance) =>
									DateHelper.compareTwoDates(seance.startDate, selectedSeance.startDate) > 0 // futures seulement
							)
					),
					map((futurSeances: StoredSeance[]) => {
							futurSeances.push(selectedSeance);
							return futurSeances.sort((a: StoredSeance, b: StoredSeance) =>
									DateHelper.compareTwoDates(a.startDate, b.startDate)
							);
					})
			);
	}
	

    // Met à jour les actes d'une seance en fonction d'une liste de modifications d'acte à appliquer
    public updateActesSeance(seance: StoredSeance, modifActesSeance: IModifActesSeance): StoredSeance {
        modifActesSeance.actesUpdated.forEach(({ src, dest }: { src: Acte; dest: Acte }) => {
            const indexToUpdate = seance.actes.findIndex((acte: Acte) => acte.guid === src.guid);
            if (indexToUpdate !== -1) {
                seance.actes[indexToUpdate] = dest;
            }
        })

        modifActesSeance.actesAdded.forEach((acte: Acte) => {
            seance.actes.push(acte)
        })

        modifActesSeance.actesDeleted.forEach((toDeleteActe: Acte) => {
            const index = seance.actes.findIndex((acte: Acte) => acte.guid === toDeleteActe.guid);
            if (index !== -1) {
                seance.actes.splice(index, 1);
            }
        });
        return seance;
    }
}