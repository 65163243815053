<ion-header>
	<ion-toolbar>
		<ion-title>Nouveau compte</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content [forceOverscroll]="false">

	<input type="text" placeholder="e-mail" [(ngModel)]="account.login" />
	<input type="password" placeholder="Mot de Passe" [(ngModel)]="account.password" />
	<input type="password" placeholder="Confirmer le mot de Passe" [(ngModel)]="account.confirmPassword" />

	<ion-button color="secondary" (click)="cancel()">Annuler</ion-button>
	<ion-button color="primary" (click)="createAccount()">Créer mon compte</ion-button>

</ion-content>