import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IContact } from '../../../../model/contacts/IContact';
import { EAvatarSize } from '../../../../model/picture/EAvatarSize';
import { IAvatar } from '../../../../model/picture/IAvatar';
import { ContactsService } from '../../../../services/contacts.service';
import { FilterbarBaseComponent } from '../../model/FilterbarBaseComponent';

/** Composant pour afficher un filtrage avec des avatars. */
@Component({
	selector: 'osapp-filterbar-contact-avatar',
	templateUrl: './filterbar-contact-avatar.component.html',
	styleUrls: ['../filterbar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterbarContactAvatarComponent<T extends IContact, U = IAvatar> extends FilterbarBaseComponent<T, U> {

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	protected transform(poValue: T): U;
	protected transform(paValues: T[]): U[];
	/** @override */
	protected transform(paValues: T | T[]): U | U[] {
		if (paValues instanceof Array)
			return paValues.map((poContact: T) => ContactsService.createContactAvatar(poContact, EAvatarSize.big)) as unknown as U[];
		else
			return ContactsService.createContactAvatar(paValues, EAvatarSize.big) as unknown as U;
	}

	/** @override */
	public action(): Observable<T[]> { return of([]); }

	//#endregion
}