import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Navigation, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { ERouteUrlPart } from '@osapp/model/route/ERouteUrlPart';
import { ApplicationService } from '@osapp/services/application.service';
import { EntityLinkService } from '@osapp/services/entityLink.service';
import { PageManagerService } from '@osapp/services/pageManager.service';
import { ReadStatusService } from '@osapp/services/read-status.service';
import { ITraitement } from 'apps/idl/src/model/ITraitement';
import { defer, Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { InitTransmissionError } from '../../../model/InitTransmissionError';
import { IPatient } from '../../../model/IPatient';
import { ITransmissionPageParams } from '../../../model/ITransmissionPageParams';
import { ITransmissionRapport } from '../../../model/ITransmissionRapport';
import { RapportService } from '../../../services/rapport.service';

@Component({
	selector: 'transmission-page',
	templateUrl: './transmission-page.component.html',
	styleUrls: ['./transmission-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransmissionPageComponent extends ComponentBase implements ITransmissionPageParams, OnInit, OnDestroy {

	//#region PROPERTIES

	public readonly C_TRANSMISSION_DATE_FORMAT: string = ETimetablePattern.dd_MM_yyyy_HH_mm_slash;

	/** Transmission à afficher. */
	public transmission: ITransmissionRapport;
	/** Indique si on est en mode readOnly (visu) ou non (edit/new). */
	public isReadOnly: boolean;
	/** @implements */
	public patientModel?: IPatient;
	/** @implements */
	public linkedEntities?: ITraitement[];
	/** Titre de la page. */
	public title: string;
	/** Indique si la transmission est en mode création ou non. */
	public isCreation: boolean;

	//#endregion

	//#region METHODS

	constructor(
		private ioRouter: Router,
		private ioActivatedRoute: ActivatedRoute,
		private isvcRapport: RapportService,
		private isvcPageManager: PageManagerService,
		private isvcReadStatus: ReadStatusService,
		private ioNavCtrl: NavController,
		private isvcEntityLink: EntityLinkService,
		poChangeDetectorRef: ChangeDetectorRef) {

		super(poChangeDetectorRef);
	}

	public ngOnInit(): void {
		const lsLastUrlPart: string = ArrayHelper.getLastElement(this.ioActivatedRoute.snapshot.url).path;

		this.isCreation = lsLastUrlPart === ERouteUrlPart.new;
		this.isReadOnly = lsLastUrlPart !== ERouteUrlPart.edit && lsLastUrlPart !== ERouteUrlPart.new;

		if (this.isReadOnly)
			this.title = "Transmission";
		else if (lsLastUrlPart === ERouteUrlPart.new)
			this.title = RapportService.C_CREATE_TRANSMISSION_TITLE;
		else
			this.title = RapportService.C_EDIT_TRANSMISSION_TITLE;

		this.getTransmission() // Peut retourner plusieurs résultats (get live en mode visu).
			.pipe(
				tap((poTransmission: ITransmissionRapport) => {
					this.transmission = poTransmission;
					this.detectChanges();
				}),
				mergeMap((poTransmission: ITransmissionRapport) => this.isvcEntityLink.trySetCurrentEntity(poTransmission)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** @override */
	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.isvcEntityLink.clearCurrentEntity(this.transmission?._id).subscribe();
	}

	private getTransmission(): Observable<ITransmissionRapport> {
		let loGetTransmission$: Observable<ITransmissionRapport | never>;

		this.transmission = this.ioActivatedRoute.snapshot.data.transmission;

		if (!this.transmission) { // Mode création.
			const loNavigation: Navigation = this.ioRouter.getCurrentNavigation();

			if (loNavigation && loNavigation.extras && loNavigation.extras.state && (loNavigation.extras.state as ITransmissionPageParams).patientModel) {
				this.patientModel = JSON.parse((loNavigation.extras.state as ITransmissionPageParams).patientModel as string);
				this.linkedEntities = (loNavigation.extras.state as ITransmissionPageParams).linkedEntities;
				loGetTransmission$ = of(this.isvcRapport.createTransmissionRapport(this.patientModel, this.linkedEntities));
			}
			else
				loGetTransmission$ = throwError(new InitTransmissionError());
		}
		else if (this.isReadOnly) // Mode visu.
			loGetTransmission$ = this.isvcRapport.getTransmissionUpdates(this.transmission);
		else
			loGetTransmission$ = of(this.transmission);

		return defer(() => {
			if (this.transmission)
				return this.isvcReadStatus.markAsRead(this.transmission);
			return of(null);
		})
			.pipe(mergeMap(_ => loGetTransmission$));
	}

	/** Navigue vers le mode edit de la transmission. */
	public routeToEdit(): void {
		this.ioRouter.navigate(['.', ERouteUrlPart.edit], { relativeTo: this.ioActivatedRoute });
	}

	/** Enregistre la transmission. */
	public saveTransmission(): void {
		this.isvcRapport.saveTransmission(this.transmission)
			.pipe(
				delay(250), //todo : https://dev.azure.com/calaosoft/osapp-project/_workitems/edit/3499
				mergeMap(_ => this.isvcPageManager.goBack()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public goHome(): void {
		this.ioNavCtrl.navigateRoot(ApplicationService.C_HOME_ROUTE_URL);
	}

	//#endregion
}