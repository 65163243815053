import { Type } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { ConfigOption, FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { MailHelper } from '../../../helpers/MailHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { ITextareaFieldParams } from '../../../model/forms/inputs/ITextareaFieldParams';
import { ActionButtonFieldConversationComponent } from './customFields/actionButtons/actionButtonFieldConversation.component';
import { ActionButtonFieldMapComponent } from './customFields/actionButtons/actionButtonFieldMap.component';
import { ActionButtonFieldNavigationComponent } from './customFields/actionButtons/actionButtonFieldNavigation.component';
import { ActionButtonFieldPositionComponent } from './customFields/actionButtons/actionButtonFieldPosition.component';
import { BoolLabelFieldComponent } from './customFields/boolLabelField.component';
import { ContactsListFieldComponent } from './customFields/contactsListField.component';
import { DashboardFieldComponent } from './customFields/dashboardField.component';
import { DateTimeSpinnerFieldComponent } from './customFields/dateTimeSpinnerField.component';
import { DrawingFieldComponent } from './customFields/drawingField.component';
import { FormFieldComponent } from './customFields/formField.component';
import { FormListFieldComponent } from './customFields/formListField.component';
import { GalleryFieldComponent } from './customFields/galleryField.component';
import { GroupsChecklistFieldComponent } from './customFields/groups-checklist-field.component';
import { HiddenFieldComponent } from './customFields/hiddenField.component';
import { HTMLTextEditorFieldComponent } from './customFields/htmlTextEditorField.component';
import { ColorFieldComponent } from './customFields/inputs/color-field.component';
import { EmailFieldComponent } from './customFields/inputs/email-field.component';
import { InputFieldComponent } from './customFields/inputs/inputField.component';
import { MultiInputFieldComponent } from './customFields/inputs/multiInputField.component';
import { TextareaFieldComponent } from './customFields/inputs/textareaField.component';
import { LabelFieldComponent } from './customFields/labelField.component';
import { LikeFieldComponent } from './customFields/likeField.component';
import { LinkFieldComponent } from './customFields/linkField.component';
import { PermissionRolesSelectFieldComponent } from './customFields/permission-roles-select-field.component';
import { PictureFieldComponent } from './customFields/pictureField.component';
import { SectorsListFieldComponent } from './customFields/sectors-list-field.component';
import { SelectFieldComponent } from './customFields/select-field.component';
import { SlideboxFieldComponent } from './customFields/slideboxField.component';
import { SliderFieldComponent } from './customFields/sliderField.component';
import { ToggleFieldComponent } from './customFields/toggleField.component';
import { ReadOnlyWrapperComponent } from './customWrappers/readonlyWrapper/readOnlyWrapper.component';
import { SectionWrapperComponent } from './customWrappers/sectionWrapper/sectionWrapper.component';

/** Clé pour le champ `email`. */
const emailKey = "email";
/** Objet d'erreur de validation du champ `email` (email non valide). */
const emailValidationErrors = { [emailKey]: true };

/** Fonction permettant de valider un champs email. */
export function isNotValidEmail(poControl: AbstractControl, poField: FormlyFieldConfig): ValidationErrors {
	if (StringHelper.isBlank(poControl.value))
		return poField.templateOptions.required ? emailValidationErrors : null;
	else
		return MailHelper.isValid(poControl.value) ? null : emailValidationErrors;
}

export function readonlySwitchExtension(poField: FormlyFieldConfig): void {
	const lbIsReadOnly: boolean = poField.templateOptions && poField.templateOptions.data && poField.templateOptions.data.readOnly;

	if (lbIsReadOnly && poField.wrappers && poField.wrappers.indexOf("readOnly") === -1)
		poField.wrappers = ["readOnly"];
}

/** Ce tableau contient l'ensemble des composants de liens customs. Ils seront inclus via ce tableau dans le module supérieur. */
export const C_FORMLY_CUSTOM: Array<Type<FieldType>> = [
	InputFieldComponent,
	BoolLabelFieldComponent,
	ContactsListFieldComponent,
	DashboardFieldComponent,
	FormFieldComponent,
	FormListFieldComponent,
	HTMLTextEditorFieldComponent,
	LabelFieldComponent,
	LinkFieldComponent,
	ActionButtonFieldConversationComponent,
	ActionButtonFieldNavigationComponent,
	ActionButtonFieldPositionComponent,
	ActionButtonFieldMapComponent,
	PictureFieldComponent,
	SelectFieldComponent,
	SlideboxFieldComponent,
	SliderFieldComponent,
	MultiInputFieldComponent,
	DateTimeSpinnerFieldComponent,
	ToggleFieldComponent,
	HiddenFieldComponent,
	GalleryFieldComponent,
	DrawingFieldComponent,
	LikeFieldComponent,
	TextareaFieldComponent,
	SectionWrapperComponent,
	ReadOnlyWrapperComponent,
	GroupsChecklistFieldComponent,
	PermissionRolesSelectFieldComponent,
	EmailFieldComponent,
	ColorFieldComponent,
	SectorsListFieldComponent
];

/** Configuration des formulaires. Cette config doit être incluse en forRoot lors de l'inclusion du module FormlyModule. */
export const FormlyConfig: ConfigOption = {
	validators: [
		{ name: emailKey, validation: isNotValidEmail }
	],
	/** Messages de validation. */
	validationMessages: [
		{ name: "required", message: "Le champs doit être renseigné" },
		{ name: emailKey, message: "Veuillez entrer une adresse valide" }
	],
	/** Configuration des champs customs. */
	types: [
		{
			name: "input",
			component: InputFieldComponent
		},
		{
			name: emailKey,
			component: EmailFieldComponent,
			extends: "input",
			defaultOptions: {
				validators: {
					validation: [emailKey]
				}
			}
		},
		{
			name: "boolLabel",
			component: BoolLabelFieldComponent
		},
		{
			name: "contactsList",
			component: ContactsListFieldComponent
		},
		{
			name: "dashboard",
			component: DashboardFieldComponent
		},
		{
			name: "dateTimeSpinner",
			component: DateTimeSpinnerFieldComponent,
			wrappers: ["form-field"]
		},
		{
			name: "form",
			component: FormFieldComponent
		},
		{
			name: "formList",
			component: FormListFieldComponent
		},
		{
			name: "label",
			component: LabelFieldComponent
		},
		{
			name: "link",
			component: LinkFieldComponent
		},
		{
			name: "actionButtonConversation",
			component: ActionButtonFieldConversationComponent
		},
		{
			name: "actionButtonNavigation",
			component: ActionButtonFieldNavigationComponent
		},
		{
			name: "actionButtonPosition",
			component: ActionButtonFieldPositionComponent
		},
		{
			name: "actionButtonMap",
			component: ActionButtonFieldMapComponent
		},
		{
			name: "picture",
			component: PictureFieldComponent
		},
		{
			name: "select",
			component: SelectFieldComponent
		},
		{
			name: "slidebox",
			component: SlideboxFieldComponent
		},
		{
			name: "range",
			component: SliderFieldComponent,
			defaultOptions: {
				templateOptions: {
					data: {
						range: true
					}
				}
			}
		},
		{
			name: "slider",
			component: SliderFieldComponent,
			defaultOptions: {
				templateOptions: {
					data: {
						range: false
					}
				}
			}
		},
		{
			name: "multiInput",
			component: MultiInputFieldComponent
		},
		{
			name: "toggleField",
			component: ToggleFieldComponent
		},
		{
			name: "hidden",
			component: HiddenFieldComponent
		},
		{
			name: "gallery",
			component: GalleryFieldComponent
		},
		{
			name: "drawing",
			component: DrawingFieldComponent
		},
		{
			name: "like",
			component: LikeFieldComponent
		},
		{
			name: "textarea",
			component: TextareaFieldComponent,
			defaultOptions: {
				templateOptions: {
					appearance: "outline",
					data: {
						label: "",
						placeholder: ""
					} as ITextareaFieldParams
				}
			}
		},
		{
			name: "htmlTextEditor",
			component: HTMLTextEditorFieldComponent,
			wrappers: ["form-field"],
			defaultOptions: {
				templateOptions: {
					floatLabel: "always"
				}
			}
		},
		{
			name: "groupsChecklist",
			component: GroupsChecklistFieldComponent
		},
		{
			name: "sectorsList",
			component: SectorsListFieldComponent
		},
		{
			name: "permissionRolesSelect",
			component: PermissionRolesSelectFieldComponent
		},
		{
			name: "color",
			component: ColorFieldComponent
		}
	],
	wrappers: [
		{
			name: "section",
			component: SectionWrapperComponent
		},
		{
			name: "readOnly",
			component: ReadOnlyWrapperComponent
		}
	],
	extensions: [
		{
			name: "readOnlySwitch",
			extension: { onPopulate: readonlySwitchExtension }
		}
	]
};