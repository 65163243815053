<ion-grid class="little-size border-radius-top">
	<!-- En-têtes des checkbox (IFD / IFI / IK). -->
	<ng-container *ngTemplateOutlet="checkBoxesHeader"></ng-container>
	<!-- Checkboxes "Toutes les séances" à cocher. -->
	<ng-container *ngTemplateOutlet="allSeancesCheckboxes"></ng-container>
</ion-grid>
<!-- Toutes les checkboxes à cocher. -->
<ng-container *ngTemplateOutlet="seancesCheckboxes"></ng-container>

<!-- Template des en-têtes des checkbox (IFD / IFI / IK). -->
<ng-template #checkBoxesHeader>
	<ion-row>
		<!-- Rien sur la colonne de gauche. -->
		<ion-col size="6"></ion-col>
		<!-- En-têtes des checkboxes. -->
		<ion-col size="6" class="ion-no-padding">
			<ion-row class="adjust-height">
				<!-- Colonne IFD. -->
				<ion-col size="4">
					<ion-label>IFD</ion-label>
				</ion-col>
				<!-- Colonne IFI. -->
				<ion-col size="4">
					<ion-label>IFI</ion-label>
				</ion-col>
				<!-- Colonne IK. -->
				<ion-col size="4" (click)="onOpenIKModalClicked()">
					<!-- Ligne IK et bouton pour éditer. -->
					<ion-row class="center">
						<div>
							<ion-label>IK&nbsp;</ion-label>
							<ion-icon name="create" color="osapp-element"></ion-icon>
						</div>
					</ion-row>
					<!-- Ligne récap IK : secteur et nombre de Kms. -->
					<ion-row class="center">
						<div>
							<ion-icon [name]="isvcIndemnite.getIconSector(traitement.deplacement.sectorType)" color="dark"
								class="reduce-size">
							</ion-icon>
							<ion-label class="little-size">
								/{{ ikDistance }}km
							</ion-label>
						</div>
					</ion-row>
				</ion-col>
			</ion-row>
		</ion-col>
	</ion-row>
</ng-template>

<!-- Template des checkboxes "Toutes les séances" à cocher. -->
<ng-template #allSeancesCheckboxes>
	<!-- Vérification permettant d'attendre l'initialisation du tableau qui est nécessaire pour créer de nouvelles indemnités. -->
	<ion-row *ngIf="deplacementsByProfession" class="color3">
		<ion-col size="6">
			<ion-row>
				<ion-label>Toutes les séances</ion-label>
			</ion-row>
		</ion-col>
		<ion-col size="6" class="ion-no-padding">
			<ion-row class="adjust-height">
				<!-- Colonne IFD -->
				<ion-col size="4" (click)="onAllIFDSelectionClicked()">
					<ion-checkbox class="disable-pointer" [checked]="allIFDSelected"
						[disabled]="!allIFDEnabled || !canSelectAllIndemnites">
					</ion-checkbox>
				</ion-col>
				<!-- Colonne IFI -->
				<ion-col size="4" (click)="onAllIFISelectionClicked()">
					<ion-checkbox class="disable-pointer" [checked]="allIFISelected"
						[disabled]="!allIFIEnabled || !canSelectAllIndemnites">
					</ion-checkbox>
				</ion-col>
				<!-- Colonne IK -->
				<ion-col size="4" (click)="onAllIKSelectionClicked()">
					<ion-checkbox class="disable-pointer" [checked]="allIKSelected" [disabled]="!canSelectAllIndemnites">
					</ion-checkbox>
				</ion-col>
			</ion-row>
		</ion-col>
	</ion-row>
</ng-template>

<!-- Template de toutes les checkboxes à cocher. -->
<ng-template #seancesCheckboxes>
	<div class="scroll-wrapper border-radius-bottom" *ngIf="(virtualScrollItems?.length$ | async) > 0">
		<calao-virtual-scroll [items]="virtualScrollItems" itemSize="45">
			<!-- Itération sur la map des séances ordonnées par jour. -->
			<ng-template let-virtualScrollItem="$implicit">
				<!-- Date du jour. -->
				<ion-row class="scroll-item date" *ngIf="isDate(virtualScrollItem); else seance;">
					<ion-label>{{ virtualScrollItem }}</ion-label>
				</ion-row>
				<ng-template #seance>
					<!-- Itération des séances pour un jour donné. -->
					<ion-row class="color3 scroll-item">
						<!-- Colonne d'affichage de l'identifiant de séance et le résumé de ses actes. -->
						<ion-col size="6">
							<!-- Identifiant de séance. -->
							<ion-row class="seance">
								<ion-label>
									Séance de {{ virtualScrollItem.seance.startDate | date : "HH:mm" }} {{
									virtualScrollItem.seance.statusLabel
									? ("(" + virtualScrollItem.seance.statusLabel + ")") : "" }}
								</ion-label>
								<ion-icon class="place-icon" [name]="virtualScrollItem.seance.place"></ion-icon>
							</ion-row>
							<!-- Résumé des actes de la séance. -->
							<ion-row class="resume">
								<ion-note class="little-size crop">
									{{ virtualScrollItem.seance.actes | concatActes: "," : acteDisplayMode }}
								</ion-note>
							</ion-row>
						</ion-col>
						<ion-col size="6" class="ion-no-padding">
							<ion-row class="adjust-height">
								<!-- Colonne IFD -->
								<ion-col size="4" (click)="onIFDSelectionClicked(virtualScrollItem)" class="center-checkbox">
									<ion-checkbox class="disable-pointer" [checked]="hasIFDIndemnite(virtualScrollItem.seance)"
										[disabled]="isIndemniteDisabled(virtualScrollItem.seance)">
									</ion-checkbox>
								</ion-col>
								<!-- Colonne IFI -->
								<ion-col size="4" (click)="onIFISelectionClicked(virtualScrollItem)" class="center-checkbox">
									<ion-checkbox class="disable-pointer" [checked]="hasIFIIndemnite(virtualScrollItem.seance)"
										[disabled]="isIndemniteDisabled(virtualScrollItem.seance)">
									</ion-checkbox>
								</ion-col>
								<!-- Colonne IK -->
								<ion-col size="4" (click)="onIKSelectionClicked(virtualScrollItem)" class="center-checkbox">
									<ion-checkbox class="disable-pointer" [checked]="hasIKIndemnite(virtualScrollItem.seance)"
										[disabled]="isIndemniteDisabled(virtualScrollItem.seance)">
									</ion-checkbox>
								</ion-col>
							</ion-row>
						</ion-col>
					</ion-row>
				</ng-template>
			</ng-template>
		</calao-virtual-scroll>
	</div>
</ng-template>