import { IPermission } from '../../modules/permissions/models/ipermission';
import { EBarElementDock } from './EBarElementDock';
import { EBarElementPosition } from './EBarElementPosition';
import { IBarElementDataOption } from './IBarElementDataOption';

export interface IBarElement {

	/** Identifiant de l'élément (ex. un bouton). */
	id: string;
	/** Identifiant du composant qui représente l'élément. */
	component: string;
	/** Identifiant du composant qui modifie la 'IBarElement'. */
	componentId?: string;
	/** Endroit dans l'application où va venir s'encrer la 'IBarElement' (toolbar par exemple). */
	dock: EBarElementDock;
	/** Position du bouton dans le dock de la 'IBarElement' (toolbar par exemple). */
	position: EBarElementPosition;
	/** Couleur de fond en hexa #000000. */
	background?: string;
	/** Identifiant de l'icône à afficher. */
	icon?: string;
	/** Couleur de l'icône / texte. */
	color?: string;
	/** Fonction à exécuter lors de l'appui sur le bouton. */
	onTap?: Function;
	/** Css inline qu'on va venir injecter dans le composant. */
	inlineCssRender?: any;
	/** Option de modification des configs de la 'IBarElement' (toolbar par exemple). */
	options?: IBarElementDataOption;
	/** Priorité du bouton afin de le placer en fonction d'autres boutons (plus élevée à droite des autres). */
	priority?: number;
	/** Masque le bouton */
	hidden?: boolean;
	/** Lien de la page où router. */
	link?: string;
	/** Indique s'il faut mettre l'id dans l'url. */
	linkWithModelId?: boolean;
	/** Liste des permissions pour afficher l'élément. */
	permissions?: IPermission[];
	/** Nom du Title */
	name: string;
}