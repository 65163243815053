import { Observable, throwError, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { NumberHelper } from '../../../helpers/numberHelper';
import { IDmsTaskParams } from '../../../model/backgroundTask/taskParams/IDmsTaskParams';
import { TaskBase } from '../../../services/backgroundTask/TaskBase';
import { TaskDescriptor } from '../../../services/backgroundTask/TaskDescriptor';
import { FlagService } from '../../../services/flag.service';
import { InjectorService } from '../../../services/injector.service';
import { SyncDmsService } from '../services/syncDms.service';

export abstract class DmsTaskBase<T extends IDmsTaskParams> extends TaskBase<T> {

	//#region FIELDS

	protected msvcSyncDms: SyncDmsService;
	/** Service permettant de manipuler les flags. */
	protected msvcFlag: FlagService;

	//#endregion

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<T>) {
		super(poDescriptor);
		this.msvcSyncDms = InjectorService.instance.get(SyncDmsService);
		this.msvcFlag = InjectorService.instance.get(FlagService);
	}

	/** @override */
	public retryStrategy(poErrors$: Observable<any>): Observable<any> {
		return poErrors$ // Observable qui fournit chaque erreur de la tâche. 
			.pipe(
				mergeMap((poError: any) => {
					if (this.msvcSyncDms.isPermanentError(poError))
						return throwError(poError);

					else {
						const lbHasValidIntervalRepetition: boolean = NumberHelper.isValidPositive(this.descriptor.intervalRepetition);

						if (!lbHasValidIntervalRepetition)
							this.descriptor.retryInterval *= this.descriptor.intervalMultiplicator;

						const lnIntervalTime: number = lbHasValidIntervalRepetition ? this.descriptor.intervalRepetition : this.descriptor.retryInterval;

						this.descriptor.lastExec = new Date();

						return timer(lnIntervalTime);
					}
				})
			);
	}

	//#endregion
}