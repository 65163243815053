import {
	Injectable,
	ApplicationRef,
	ComponentRef,
	createComponent,
	Inject,
} from "@angular/core";
import { LoaderComponent } from "../components/loader/loader.component";
import { DOCUMENT } from "@angular/common";

@Injectable({
	providedIn: "root",
})
export class LoaderService {
	private loaderComponentRef: ComponentRef<LoaderComponent> | null = null;

	constructor(
		private appRef: ApplicationRef,
		@Inject(DOCUMENT) private document: Document
	) {}

	showLoader(loaderText?: string, color?: string, size?: number): void {
		if (!this.loaderComponentRef) {
			this.loaderComponentRef = createComponent(LoaderComponent, {
				environmentInjector: this.appRef.injector,
			});

			size && this.loaderComponentRef.setInput("size", size);
			color && this.loaderComponentRef.setInput("color", color);
			loaderText && this.loaderComponentRef.setInput("loaderText", loaderText);

			this.appRef.attachView(this.loaderComponentRef.hostView);

			const domElem = this.loaderComponentRef.location.nativeElement;
			this.document.body.appendChild(domElem);
		}
	}

	hideLoader(): void {
		if (this.loaderComponentRef) {
			this.appRef.detachView(this.loaderComponentRef.hostView);
			this.loaderComponentRef.destroy();
			this.loaderComponentRef = null;
		}
	}
}
