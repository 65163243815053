<div class="di-majoration">
	<lua-icone
		color="CouleurPrimaire"
		[mode]="getIconMode()"
		[width]="getIconSize()"
		[height]="getIconSize()"
		[iconName]="getMajorationDetails().iconName"
		[customIconName]="getMajorationDetails().customIconeName"
		[isIcon]="!!getMajorationDetails().customIconeName"
	></lua-icone>
	<p class="label">{{ getMajorationDetails().label }}</p>
	<p *ngIf="price" class="price">{{ price }} €</p>
</div>
