import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DynHostModule } from '../dynHost/dynHost.module';
import { LinkModule } from '../link/link.module';
import { SharedComponentsModule } from '../sharedComponents.module';
import { SlideboxComponent } from './slidebox.component';



const modules: Array<Type<any>> = [
	SharedComponentsModule,
	DynHostModule
];
const components: Array<Type<any>> = [
	SlideboxComponent
];
/**
 * Module de gestion des menus.
 */
@NgModule({
	imports: [CommonModule, IonicModule, LinkModule, DynHostModule, ...modules],
	exports: [SlideboxComponent, ...components],
	declarations: [SlideboxComponent, ...components]
})
export class SlideboxModule {

}