import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { IDynHostComponent } from '@osapp/components/dynHost';
import { DynamicPageComponent } from '@osapp/components/dynamicPage';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { EBarElementDock } from '@osapp/model/barElement/EBarElementDock';
import { EBarElementPosition } from '@osapp/model/barElement/EBarElementPosition';
import { IBarElement } from '@osapp/model/barElement/IBarElement';
import { EDateTimePickerMode } from '@osapp/model/date/EDateTimePickerMode';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { IDateTimePickerParams } from '@osapp/model/date/IDateTimePickerParams';
import { Loader } from '@osapp/modules/loading/Loader';
import { ModalService } from '@osapp/modules/modal/services/modal.service';
import { ShowMessageParamsToast, UiMessageService } from '@osapp/services';
import { LoadingService } from '@osapp/services/loading.service';
import { SlideboxService } from '@osapp/services/slidebox.service';
import { EMPTY, Observable, from, of } from 'rxjs';
import { catchError, finalize, mergeMap, tap } from 'rxjs/operators';
import { TraitementSlideParentBase } from '../../../../model/TraitementSlideParentBase';
import { SeanceService } from '../../../../services/seance.service';
import { TraitementService } from '../../../../services/traitement.service';
import { IAccordPrealable } from '../../../patients/model/IAccordPrealable';
import { PatientsService } from '../../../patients/services/patients.service';
import { TraitementDataManager } from '../../traitement-data-manager.service';
import { TraitementSlideComponentBase } from '../traitement-slide-component-base.component';
import { AccordPrealableModalComponent } from './accordPrealable-modal/accordPrealable-modal.component';
import { AccordPrealableService } from './accordPrealable.service';

enum Etat {
  EnAttente = 'Demande à faire',
  EnCours = 'En cours de traitement',
  Cloture = 'Traité'
}

enum Title {
  EnAttente = "Faire une demande",
  EnCours = "Enregistrer le retour"
}

@Component({
	templateUrl: "./accordPrealable.component.html",
	styleUrls: ["./accordPrealable.component.scss"],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class AccordPrealableComponent extends TraitementSlideComponentBase implements IDynHostComponent, OnInit {

	//#region PROPERTIES

	/** Input des données venant du compoanbt père qui a injecté ce composant. */
	@Input() public params: any;

	formAP : FormGroup;
	dateToday : Date = new Date();
	public readonly startDateParamsDemande: IDateTimePickerParams = {
		displayFormat: ETimetablePattern.dd_MM_yyyy_slash,
		iconSlot: "end",
		pickerMode: EDateTimePickerMode.date,
		label: "le",
		max : DateHelper.transform(this.dateToday, ETimetablePattern.isoFormat_hyphen),
	};
	public readonly startDateParamsResulat: IDateTimePickerParams = {
		displayFormat: ETimetablePattern.dd_MM_yyyy_slash,
		iconSlot: "end",
		pickerMode: EDateTimePickerMode.date,
		label: "le",
		max : DateHelper.transform(this.dateToday, ETimetablePattern.isoFormat_hyphen),
	};
	Etat = Etat;
	etatAP = Etat.EnAttente;
	accordCurrent: IAccordPrealable;
	titleFormAp :string =Title.EnAttente;
	dateDemande: Date;
	dateResultat: Date;
	lastUpdateDemande :string;
	



	public get filteredActes() {
    return Object.values(this.traitement.actes).filter(acte => acte.isPriorAgreement === true);
  }

	//#endregion

	//#region METHODS

	constructor(
		private ioRouter: Router,
		/** Store service acces aux données. */
		psvcPatients: PatientsService,
		private isvcAccordPrealable:AccordPrealableService,
		psvcTraitementDataManager: TraitementDataManager,
		psvcSeance: SeanceService,
		poParentComponent: TraitementSlideParentBase,
		psvcTraitement: TraitementService,
		psvcLoading: LoadingService,
		poParentPage: DynamicPageComponent<ComponentBase>,
		poChangeDetectorRef: ChangeDetectorRef,
		private changeDetectorRef: ChangeDetectorRef,
		psvcSlidebox: SlideboxService,
		private isvcUiMessage: UiMessageService,
		private isvcModal : ModalService,
		private formBuilder: FormBuilder) {

		super(
			psvcTraitementDataManager,
			psvcSeance,
			poParentComponent,
			psvcTraitement,
			psvcLoading,
			psvcPatients,
			poParentPage,
			poChangeDetectorRef,
			psvcSlidebox
		);
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this.initializeForm();
		if(this.filteredActes.length > 0){
			this.getAccordPrealable().subscribe();
		}	
	}

	initializeForm(): void {
		this.dateDemande = new Date;
		this.dateResultat = new Date;
    this.formAP = this.formBuilder.group({
      dateDemande: this.dateDemande,
      dateResultat: this.dateResultat,
      avisResultat: [''],
			isUrgent: [''],
			isSerie: ['']
    });
  }

	onSubmitAP()
	{
		let accord : IAccordPrealable;
		if(this.etatAP == Etat.EnAttente){
			this.openAP(accord);
			return;
		}
		if((this.etatAP === Etat.EnCours) && (this.formAP.value["avisResultat"]==null))
		{
			this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: `Merci de sélectionner une réponse.`, position: "middle" }));
			return ;
		}
		if(this.accordCurrent)
		{
			this.accordCurrent.dateResultat = DateHelper.transform(this.dateResultat, ETimetablePattern.isoFormat_hyphen);
			this.accordCurrent.resultat = Boolean(this.formAP.value["avisResultat"]);
			this.etatAP = Etat.Cloture;

		}
		this.lastUpdateDemande = DateHelper.transform(new Date(this.accordCurrent.lastUpdate), ETimetablePattern.dd_MM_yyyy_slash);
		this.isvcAccordPrealable.createOrUpdate(this.accordCurrent).subscribe();
		this.titleFormAp=Title.EnCours;
	}

	public openAP(accord: IAccordPrealable): void {
		from(this.isvcModal.open({
			component: AccordPrealableModalComponent,
			componentProps: {
				traitement: this.traitement,
				accord: this.accordCurrent
			}
		}))
		.subscribe(
			(accord: IAccordPrealable | null) => {
				if (accord) {
					this.accordCurrent = accord;
					this.etatAP = this.etatAP === Etat.EnAttente ? Etat.EnCours : this.etatAP;
					this.lastUpdateDemande = DateHelper.transform(new Date(this.accordCurrent.lastUpdate), ETimetablePattern.dd_MM_yyyy_slash);
					this.updateFormFormAccord();			
				}
			},
			error => {
				console.error('Erreur lors de l\'ouverture de la modal : ', error);
			}
		);
	}

	private getAccordPrealable(): Observable<IAccordPrealable | Observable<never>> {
		let loader: Loader;
	
		return from(this.isvcLoading.create("Chargement de l'accord préalable...")).pipe(
			tap((createdLoader: Loader) => loader = createdLoader),
			mergeMap(_ => loader.present()),
			mergeMap(() => {
				return this.isvcAccordPrealable.get(this.traitement._id).pipe(
					tap((accordPrealable: IAccordPrealable) => {
						this.accordCurrent = accordPrealable;
						if (this.accordCurrent) {
							this.etatAP = Etat.EnCours;
							this.lastUpdateDemande = DateHelper.transform(new Date(accordPrealable.lastUpdate), ETimetablePattern.dd_MM_yyyy_slash);
							this.updateFormFormAccord();			
					}
					}),
					catchError(() => {
						this.accordCurrent = undefined
						this.formAP.reset();
						this.titleFormAp = Title.EnAttente;
						return of(EMPTY);
					})
				);
			}),
			finalize(() => loader?.dismiss())
		);
	}

	public updateFormFormAccord() : void
	{
		if(this.accordCurrent.dateDemande){
			this.formAP.get('avisResultat').setValue(true);
			this.formAP.get('isUrgent').setValue(this.accordCurrent.isUrgent ?? false);
			this.formAP.get('isSerie').setValue(this.accordCurrent.isSerie?? false);
			this.titleFormAp=Title.EnCours;
		}
		if(this.accordCurrent.dateResultat && this.accordCurrent.resultat != undefined){
			this.dateResultat = new Date(this.accordCurrent.dateResultat);
			this.formAP.get('avisResultat').setValue(this.accordCurrent.resultat);
			this.etatAP = Etat.Cloture;
		}	
		this.changeDetectorRef.detectChanges();
	}

	public dateClick(): void {
		
	}

	public cancelDemande(){
			this.isvcAccordPrealable.delete(this.accordCurrent).subscribe();
			this.dateDemande = new Date;
			this.dateResultat = new Date;
			this.accordCurrent = undefined
			this.formAP.reset();
			this.titleFormAp = Title.EnAttente;
			this.etatAP = Etat.EnAttente;
			this.changeDetectorRef.detectChanges();
	}

	public onStartDateDemandeChanged(pdStartDate: Date): void {
		this.dateDemande = pdStartDate
	}
	
	public onStartDateResultatChanged(pdStartDate: Date): void {
		this.dateResultat = pdStartDate
	}

	/** @override */
	protected createToolbarData(): Array<IBarElement> {
		const lsCircle = "circle";
		const lsFabButton = "fabButton";

		return [
			{
				id: lsCircle,
				component: lsFabButton,
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.left,
				icon: "arrow-back",
				onTap: this.mfPreviousSlide,
				name:"Précédent"
			},
			{
				id: lsCircle,
				component: lsFabButton,
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.right,
				icon: "arrow-forward",
				onTap: this.mfNextSlide,
				name:"Suivant"
			}
		];
	}


	/** Détection de changement dans une collection de données pour indiquer à Angular quel élément il doit rafraîchir.
	 * @param pnIndex Index généré par le ngFor.
	 */
	public trackByIndex(pnIndex: number): number {
		return pnIndex;
	}

	//#endregion
}
