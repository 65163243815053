<ion-item-sliding #itemSliding *ngIf="prestations">
	<ion-item color="tertiary" class="treatment-item transform-lg-up">
		<div class="item-ctn">
			<div *ngIf="getAvatarsParams(prestations) as avatarsParams" class="avatar-area">
				<calao-avatars *ngIf="avatarsParams.avatar" [avatar]="avatarsParams.avatar"
					[avatarsCount]="avatarsParams.avatarsCount">
				</calao-avatars>
			</div>

			<div class="item-detail">
				<ion-label class="title">Traitement</ion-label>
				<ion-label>
					<span *ngIf="getBillablePrestations(prestations) as billablePrestations">
						{{ billablePrestations.length }}/{{ prestations.length }} {{
						billablePrestations.length < 2 ? 'séance validée' : 'séances validées' }} à facturer <span
							*ngIf="getPrestationsTotalPrice(billablePrestations) as price">
							: {{ price | priceFormat:2 }} €
					</span>
					</span>
				</ion-label>
				<ion-label>
					Du {{ traitement?.beginDate | dateWithLocale: 'fr-FR' : 'dd/MM/yyyy' }}
					<span *ngIf="traitement?.endDate as endDate">
						au {{ endDate | dateWithLocale: 'fr-FR' : 'dd/MM/yyyy' }}
					</span>
				</ion-label>
				<ion-label>
					<span class="success-icon">
						<ion-icon slot="icon-only" name="checkmark"></ion-icon>
					</span>{{ nbOrdonnances }} {{ nbOrdonnances < 2 ? 'ordonnance renseignée' : 'ordonnances renseignées' }}
						</ion-label>
						<ion-label *ngIf="getPrestationsEmptyOrdonnances(prestations) as nbEmptyOrdonnances">
							<span class="warn-icon">
								<ion-icon slot="icon-only" name="alert"></ion-icon>
							</span>{{ nbEmptyOrdonnances }} {{ nbEmptyOrdonnances < 2 ? 'séance sans ordonnance renseignée'
								: 'séances sans ordonnance renseignée' }} </ion-label>
			</div>
		</div>

		<!-- Bouton des options de l'item. -->
		<ion-button class="btn-no-margin btn-more btn-white ion-hide-lg-up" fill="clear" slot="end"
			(click)="openOrCloseItemSliding(itemSliding, $event)">
			<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
		</ion-button>

		<div class="buttons ion-hide-lg-down">
			<ion-button shape="round" fill="clear" color="dark" size="big"
				(click)="openPrestations(traitement, prestations);">
				<ion-icon slot="start" name="create"></ion-icon>
				<ion-label>Éditer</ion-label>
			</ion-button>

			<ng-container *ngIf="getBillablePrestations(prestations).length > 0">
				<ion-button *ngIf="canBillToFsv" color="success" shape="round" size="big"
					(click)="billPrestations(prestations)">
					<ion-icon slot="start" name="invoice"></ion-icon>
					<ion-label>Facturer</ion-label>
				</ion-button>

				<ion-button *ngIf="canBillToDesmos" color="success" shape="round" size="big"
					(click)="sendToDesmos(prestations)">
					<ion-icon slot="start" name="desmos"></ion-icon>
					<ion-label>Exporter</ion-label>
				</ion-button>
			</ng-container>
		</div>
	</ion-item>

	<!-- Options des items.-->
	<ion-item-options class="ion-hide-lg-up" side="end" (ionSwipe)="openOrCloseItemSliding(itemSliding)">
		<ion-button class="option-btn edit-btn" fill="clear" color="dark"
			(click)="itemSliding.close(); openPrestations(traitement, prestations);">
			<ion-icon slot="icon-only" name="create"></ion-icon>
			<ion-label class="ion-hide-sm-down">Éditer</ion-label>
		</ion-button>

		<ng-container *ngIf="getBillablePrestations(prestations).length > 0">
			<ion-button *ngIf="canBillToFsv" class="option-btn" color="success"
				(click)="itemSliding.close(); billPrestations(prestations)">
				<ion-icon slot="icon-only" name="invoice"></ion-icon>
				<ion-label class="ion-hide-sm-down">Facturer</ion-label>
			</ion-button>

			<ion-button *ngIf="canBillToDesmos" class="option-btn" color="success"
				(click)="itemSliding.close(); sendToDesmos(prestations)">
				<ion-icon slot="icon-only" name="desmos"></ion-icon>
				<ion-label class="ion-hide-sm-down">Exporter</ion-label>
			</ion-button>
		</ng-container>
	</ion-item-options>
</ion-item-sliding>