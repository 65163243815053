import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { SecurityService } from 'libs/osapp/src/services';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { ObjectHelper } from '../../../../helpers/objectHelper';
import { EPrefix } from '../../../../model/EPrefix';
import { Store } from '../../../../services/store.service';
import { Queue } from '../../../utils/queue/decorators/queue.decorator';
import { IPreferencesConfig } from '../../model/IPreferencesConfig';
import { PreferencesServiceBase } from '../../preferences-service-base';
import { IFavorites } from '../model/IFavorites';

export const FAVORITES_CONFIG = new InjectionToken<IPreferencesConfig>("FAVORITES_CONFIG");

@Injectable()
/** Service de gestion des favoris. Paramètrable à l'aide du module. */
export class FavoritesService extends PreferencesServiceBase<IFavorites> {

	//#region METHODS

	constructor(
		psvcStore: Store,
		psvcSecurity: SecurityService,
		@Inject(FAVORITES_CONFIG) @Optional() poFavoritesConfig?: IPreferencesConfig
	) {
		super(EPrefix.favorites, psvcStore, psvcSecurity, poFavoritesConfig);
	}

	/** Permet d'affecter la valeur du tri préféré par l'utilisateur.
	 * @param pePrefix Prefix de la donnée concernée par le tri.
	 * @param psSort Valeur du tri.
	 * @param poData Document de favoris.
	 * @returns 
	 */
	@Queue<FavoritesService, Parameters<FavoritesService["setSort"]>, ReturnType<FavoritesService["setSort"]>>(
		{ idBuilder: (_, pePrefix: EPrefix) => pePrefix }
	)
	public setSort(pePrefix: EPrefix, psSort: string, poData?: IFavorites): Observable<boolean> {
		return this.getOrReturnPreference(pePrefix, poData)
			.pipe(mergeMap((poPreferences: IFavorites) => {
				const loNewPreferences: IFavorites = this.createOrClonePrefences(pePrefix, poPreferences);

				loNewPreferences.sort = psSort;

				return this.update(poPreferences, loNewPreferences);
			}));
	}

	/** Permet d'affecter la valeur du filtres préféré par l'utilisateur.
	 * @param pePrefix Prefix de la donnée concernée par le tri.
	 * @param paFilters Valeur des filtres.
	 * @param poData Document de favoris.
	 * @returns 
	 */
	@Queue<FavoritesService, Parameters<FavoritesService["setFilters"]>, ReturnType<FavoritesService["setFilters"]>>(
		{ idBuilder: (_, pePrefix: EPrefix) => pePrefix }
	)
	public setFilters(pePrefix: EPrefix, paFilters: string[], poData?: IFavorites): Observable<boolean> {
		return this.getOrReturnPreference(pePrefix, poData)
			.pipe(mergeMap((poPreferences: IFavorites) => {
				const loNewPreferences: IFavorites = this.createOrClonePrefences(pePrefix, poPreferences);

				loNewPreferences.filters = paFilters;

				return this.update(poPreferences, loNewPreferences);
			}));
	}

	/** Permet d'affecter la valeur du mode d'affichage préféré par l'utilisateur.
	 * @param pePrefix Prefix de la donnée concernée.
	 * @param psDisplay Mode d'affichage.
	 * @param poData Document de favoris.
	 * @returns 
	 */
	@Queue<FavoritesService, Parameters<FavoritesService["setDisplay"]>, ReturnType<FavoritesService["setDisplay"]>>(
		{ idBuilder: (_, pePrefix: EPrefix) => pePrefix }
	)
	public setDisplay(pePrefix: EPrefix, psDisplay: string, poData?: IFavorites): Observable<boolean> {
		return this.getOrReturnPreference(pePrefix, poData)
			.pipe(mergeMap((poPreferences: IFavorites) => {
				const loNewPreferences: IFavorites = this.createOrClonePrefences(pePrefix, poPreferences);

				loNewPreferences.display = psDisplay;

				return this.update(poPreferences, loNewPreferences);
			}));
	}

	/** Permet d'affecter la version de la popup rgpd acceptée par l'utilisateur.
	 * @param pePrefix Prefix de la donnée concernée par le tri.
	 * @param psGdprVersion La version du rgpd à sauvegarder.
	 * @param poData Document de favoris.
	 * @returns 
	 */
	@Queue<FavoritesService, Parameters<FavoritesService["setGdprPopupVersion"]>, ReturnType<FavoritesService["setGdprPopupVersion"]>>(
		{ idBuilder: (_, pePrefix: EPrefix) => pePrefix }
	)
	public setGdprPopupVersion(pePrefix: EPrefix, psGdprVersion: string, poData?: IFavorites): Observable<boolean> {
		return this.getOrReturnPreference(pePrefix, poData)
			.pipe(mergeMap((poPreferences: IFavorites) => {
				const loNewPreferences: IFavorites = this.createOrClonePrefences(pePrefix, poPreferences);

				loNewPreferences.gdprPopupVersion = psGdprVersion;

				return this.update(poPreferences, loNewPreferences);
			}));
	}

	/** @override */
	protected preferenceDirty(poOldPreferences: IFavorites, poNewPreferences: IFavorites): boolean {
		return ObjectHelper.isNullOrEmpty(poOldPreferences) ||
			poOldPreferences.sort !== poNewPreferences?.sort ||
			poOldPreferences.gdprPopupVersion !== poNewPreferences?.gdprPopupVersion ||
			!ArrayHelper.areArraysEqual(poOldPreferences.filters, poNewPreferences.filters) ||
			poOldPreferences.display !== poNewPreferences?.display ||
			super.preferenceDirty(poOldPreferences, poNewPreferences);
	}

	//#endregion

}
