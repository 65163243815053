
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicPageComponent } from '@osapp/components/dynamicPage';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { GuidHelper } from '@osapp/helpers/guidHelper';
import { LifeCycleObserverComponentBase } from '@osapp/helpers/LifeCycleObserverComponentBase';
import { IBarElement } from '@osapp/model/barElement/IBarElement';
import { ELifeCycleEvent } from '@osapp/model/lifeCycle/ELifeCycleEvent';
import { ILifeCycleEvent } from '@osapp/model/lifeCycle/ILifeCycleEvent';
import { LoadingService } from '@osapp/services/loading.service';
import { SlideboxService } from '@osapp/services/slidebox.service';
import { Seance } from '../../../model/Seance';
import { Traitement } from '../../../model/Traitement';
import { TraitementSlideParentBase } from '../../../model/TraitementSlideParentBase';
import { SeanceService } from '../../../services/seance.service';
import { TraitementService } from '../../../services/traitement.service';
import { PatientsService } from '../../patients/services/patients.service';
import { TraitementDataManager } from '../traitement-data-manager.service';

/** Composant utilisé dans le contexte du slide des traitements.
 * Cette classe recentralise le comportement en commun, notamment l'accès au modèle de données Traitement.
 */
@Component({ template: "" })
export abstract class TraitementSlideComponentBase extends LifeCycleObserverComponentBase implements OnInit, OnDestroy {

	//#region FIELDS

	/** Méthode permettant d'aller à la slide suivante. */
	protected mfNextSlide: () => Promise<boolean>;
	/** Méthode permettant d'aller à la slide précédente. */
	protected mfPreviousSlide: () => Promise<boolean>;
	/** Méthode permettant d'aller à une slide spécifique par identifiant ou index. */
	protected mfSlideTo: (pnSlideToIndex: string | number) => Promise<boolean>;

	//#endregion

	//#region PROPERTIES

	public readonly instanceId: string = GuidHelper.newGuid();

	public traitement: Traitement;
	public lastCreatedActeGuid: string;

	/** Indique si on doit créer automatiquement les majorations ou non. */
	public get shouldAutoCreateMajoration(): boolean {
		return this.isvcTraitementDataManager.shouldAutoCreateMajoration;
	}
	/** Indique si on applique les règles de calcul ou non. */
	public get isAutoRulesEnable(): boolean {
		return this.isvcTraitementDataManager.isAutoRulesEnable;
	}

	//#region METHODS

	constructor(
		protected isvcTraitementDataManager: TraitementDataManager,
		protected isvcSeance: SeanceService,
		protected moParentComponent: TraitementSlideParentBase,
		protected isvcTraitement: TraitementService,
		protected isvcLoading: LoadingService,
		protected isvcPatients: PatientsService,
		poParentPage: DynamicPageComponent<ComponentBase>,
		poChangeDetectorRef: ChangeDetectorRef,
		psvcSlidebox: SlideboxService) {

		super(poParentPage, poChangeDetectorRef);

		this.traitement = this.moParentComponent.traitement;
		this.lastCreatedActeGuid = this.moParentComponent.lastCreatedActeGuid;

		// Si pas de nouvelle fonction qui appelle celle du service alors, le contexte change et on n'aura pas accès
		// au container de slide dans le service, mais au contexte de l'objet 'IBarElementEvent' qui exécute cette fonction dans le 'onTap'.
		this.mfNextSlide = () => psvcSlidebox.goToNextSlide(this.moParentComponent.componentId);
		this.mfPreviousSlide = () => psvcSlidebox.goToPreviousSlide(this.moParentComponent.componentId);
		this.mfSlideTo = (pnSlideToIndex: number) => psvcSlidebox.slideTo(pnSlideToIndex, moParentComponent.componentId);
	}

	public ngOnInit(): void {
		this.getParentToolbar().init(this.createToolbarData(), this.instanceId);
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.getParentToolbar().clear(this.instanceId);
	}

	protected onLifeCycleEvent(poEvent: ILifeCycleEvent): void {
		switch (poEvent.data.value) {

			case ELifeCycleEvent.viewWillEnter:
				this.getParentToolbar().init(this.createToolbarData(), this.instanceId);
				break;

			case ELifeCycleEvent.viewWillLeave:
				this.getParentToolbar().clear(this.instanceId);
				break;
		}
	}

	private raiseSaveTraitementRequest(paOldSeances?: Seance[], paNewSeances?: Seance[]): void {
		this.isvcTraitement.raiseSaveTraitementRequest({
			traitement: this.traitement,
			oldSeances: paOldSeances,
			newSeances: paNewSeances,
			traitementId: this.traitement._id
		});
	}

	/** Déclencheur en cas d'altération du traitement. */
	public onTraitementChanged(): void {
		this.raiseSaveTraitementRequest();
	}

	/** Crée les données pour la toolbar d'une slide. */
	protected abstract createToolbarData(): IBarElement[];

	//#endregion

}
