import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ArrayHelper } from '@osapp/helpers';
import { ModalComponentBase } from '@osapp/modules/modal/model/ModalComponentBase';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { PlatformService } from '@osapp/services/platform.service';
import { takeUntil, tap } from 'rxjs/operators';
import { AMCP } from '../../../patients/model/amc-p';
import { AMOP } from '../../../patients/model/amo-p';
import { EUpdateMode } from '../../../patients/model/eupdate-mode.enum';
import { IPatient } from '../../../patients/model/IPatient';
import { CouverturesService } from '../../../patients/services/couvertures.service';
import { EModeSecurisation } from '../../models/emode-securisation.enum';
import { Invoice } from '../../models/invoice';

@Component({
	selector: 'idl-facturation-context-modal',
	templateUrl: './facturation-context-modal.component.html',
	styleUrls: ['./facturation-context-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacturationContextModalComponent extends ModalComponentBase<boolean> implements OnInit {

	//#region PROPERTIES

	@Input() public patient: IPatient;
	@Input() public invoice: Invoice;

	public fse = true;

	public readonly modeOptions: ReadonlyArray<ISelectOption<EModeSecurisation>> = [
		{ label: "SESAM Vitale", value: EModeSecurisation.SECURISE },
		{ label: "Papier", value: EModeSecurisation.PAPIER },
		{ label: "Dégradée", value: EModeSecurisation.DEGRADE },
		{ label: "SESAM sans vitale", value: EModeSecurisation.SANSVITA },
	];

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcCouvertures: CouverturesService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetectorRef);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		if (this.invoice) {
			this.invoice.securisationMode = EModeSecurisation.SECURISE;

			this.isvcCouvertures.getPatientSortedCouvertures(this.patient?._id)
				.pipe(
					tap((paCouvertures: { AMOPs: AMOP[]; AMCPs: AMCP[]; }) => {
						const loAMOP: AMOP = ArrayHelper.getFirstElement(paCouvertures.AMOPs);

						if (![EUpdateMode.carteVitale, EUpdateMode.ADRi].includes(this.patient?.updateMode) ||
							![EUpdateMode.carteVitale, EUpdateMode.ADRi].includes(loAMOP?.updateMode)) {
							this.invoice.securisationMode = EModeSecurisation.DEGRADE;
						}

						this.detectChanges();
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
	}

	public onModeOptionClick(peMode: EModeSecurisation): void {
		if (peMode === EModeSecurisation.PAPIER) {
			this.fse = this.invoice.dre = false;
		}
	}

	public onFseChanged(): void {
		this.fse = !this.fse;
		if (!this.fse) {
			this.invoice.securisationMode = EModeSecurisation.PAPIER;
			this.invoice.dre = this.invoice.amoTp = this.invoice.amoTp = false;
		}
		this.detectChanges();
	}

	public onDreChanged(): void {
		this.invoice.dre = !this.invoice.dre;
		this.detectChanges();
	}

	public onAmoTPChanged(): void {
		this.invoice.amoTp = !this.invoice.amoTp;
		this.detectChanges();
	}

	public onAmcTPChanged(): void {
		this.invoice.amcTp = !this.invoice.amcTp;
		this.detectChanges();
	}

	//#endregion METHODS

}
