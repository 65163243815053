export enum ESpeExerciceCondition {
		MedecineGenerale = 1,
    AnesthesieReanimation = 2,
    Cardiologie = 3,
    ChirurgieGenerale = 4,
    DermatologieVenereologie = 5,
    Radiologie = 6,
    GynecologieObstetrique = 7,
    GastroEnterologieHepathologie = 8,
    MedecineInterne = 9,
    Neurochirurgie = 10,
    OtoRhinoLaryngologie = 11,
    Pediatrie = 12,
    Pneumologie = 13,
    Rhumatologie = 14,
    Ophtalmologie = 15,
    ChirurgieUrologique = 16,
    Neuropsychiatrie = 17,
    Stomatologie = 18,
    ChirurgienDentiste = 19,
    ReanimationMedicale = 20,
    SageFemme = 21,
    MedecineGeneraleAvecDiplome = 22,
    MedecineGeneraleReconnuParOrdre = 23,
    Infirmier = 24,
    MasseurKinesitherapeute = 26,
    PedicurePodologue = 27,
    Orthophoniste = 28,
    Orthoptiste = 29,
    LaboratoireAnalysesMedicales = 30,
    ReeducationReadaptationFonctionnelle = 31,
    Neurologie = 32,
    Psychiatrie = 33,
    Geriatrie = 34,
    Nephrologie = 35,
    ChirurgienDentisteSpecialiteODF = 36,
    AnatomoCytoPathologie = 37,
    MedecinBiologiste = 38,
    LaboratoirePolyvalent = 39,
    LaboratoireAnatomoCytoPathologie = 40,
    ChirurgieOrthopediqueTraumatologie = 41,
    EndocrinologieMetabolisme = 42,
    ChirurgieInfantile = 43,
    ChirurgieMaxilloFaciale = 44,
    ChirurgieMaxilloFacialeStomatologie = 45,
    ChirurgiePlastiqueReconstructriceEsthetique = 46,
    ChirurgieThoraciqueCardioVasculaire = 47,
    ChirurgieVasculaire = 48,
    ChirurgieVisceraleDigestive = 49,
    PharmacieOfficine = 50,
    PharmacieMutualiste = 51,
    ChirurgienDentisteSpecialiteCO = 53,
    ChirurgienDentisteSpecialiteMBD = 54,
    PrestataireTypeSociete = 60,
    PrestataireTypeArtisan = 61,
    PrestataireTypeAssociation = 62,
    Orthesistes = 63,
    Opticien = 64,
    Audioprothesistes = 65,
    EpithesisteOculariste = 66,
    PodoOrthesistes = 67,
    Orthoprothesistes = 68,
    ChirurgieOrale = 69,
    GynecologieMedicale = 70,
    Hematologie = 71,
    MedecineNucleaire = 72,
    OncologieMedicale = 73,
    OncologieRadiotherapique = 74,
    PsychiatrieEnfantAdolescent = 75,
    Radiotherapie = 76,
    Obstetrique = 77,
    GenetiqueMedicale = 78,
    ObstetriqueGynecologieMedicale = 79,
    SantePubliqueMedecineSociale = 80,
    MedecineMaladiesInfectieusesTropicales = 81,
    MedecineLegaleExpertisesMedicales = 82,
    MedecineUrgence = 83,
    MedecineVasculaire = 84,
    Allergologie = 85,
    InfirmierPratiquesAvancees = 86
}