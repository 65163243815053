<osapp-header-modal title="Indemnité kilométrique"></osapp-header-modal>

<ion-content>
	<!-- Tableau de disposition des deux éléments (distance réelle et après abattement). -->
	<div class="indemniteskm-area">
		<ion-grid>
			<!-- Première ligne : en-têtes (labels). -->
			<ion-row class="ion-text-center">
				<!-- Colonne de la distance réelle. -->
				<ion-col size="6">
					<ion-note class="little-size">Distance réelle (km)</ion-note>
				</ion-col>
				<!-- Colonne de la distance après abattement. -->
				<ion-col size="6">
					<ion-note class="little-size">Distance après abattement (km)</ion-note>
				</ion-col>
			</ion-row>
			<!-- Deuxième ligne : valeurs. -->
			<ion-row class="ion-text-center">
				<!-- Colonne de la distance réelle. -->
				<ion-col size="6" class="ion-no-padding">
					<ion-input [(ngModel)]="deplacement.distance" debounce="300" inputmode="numeric" max="99" min="0"
						(ionChange)="updateIkDistance($event)" type="number" class="ion-no-padding"></ion-input>
				</ion-col>
				<!-- Colonne de la distance après abattement. -->
				<ion-col size="6" class="ion-no-padding">
					<ion-input [ngModel]="ikDistance" type="number" disabled="true" class="ion-no-padding"></ion-input>
				</ion-col>
			</ion-row>
		</ion-grid>
		<!-- Indications. -->
		<ion-item lines="none">
			<ion-note color="medium" class="little-size ion-text-wrap" lines="full">
				Distance aller-retour de cabinet le plus proche au lieu de traitement du patient (en kilomètres).
			</ion-note>
		</ion-item>
	</div>
	<!-- Liste des différents secteurs sélectionnables. -->
	<ion-list>
		<ion-list-header>
			Type de secteur
		</ion-list-header>
		<ion-radio-group [(ngModel)]="deplacement.sectorType">
			<ion-item *ngFor="let item of sectorDetails">
				<ion-radio mode="md" slot="start" (click)="onSelectionChanged(item)" [value]="item.sectorType"
					class="margin-radio">
				</ion-radio>
				<ion-icon [name]="item.icon" color="dark" class="icon-margin-right"></ion-icon>
				<ion-label>{{ item.label }}</ion-label>
			</ion-item>
		</ion-radio-group>
	</ion-list>
	<!-- Tableau de disposition du prix de l'IK et de l'indication manuelle ou automatique. -->
	<ion-grid class="indemniteskm-area">
		<ion-row>
			<!-- Colonne pour automatiser ou rendre manuel le prix de l'IK. -->
			<ion-col size="6" class="ion-text-center">
				<ion-item lines="none">
					<ion-label class="ion-text-wrap">Calcul manuel</ion-label>
					<ion-toggle slot="end" color="primary" [(ngModel)]="deplacement.isManualPrice"
						(ngModelChange)="onToggleManualPriceChanged()"></ion-toggle>
				</ion-item>
			</ion-col>
			<!-- Colonne prix de l'IK. -->
			<ion-col size="6" class="ion-text-center">
				<ion-item lines="none" class="price-label">
					<ion-label class="ion-text-wrap">Prix de l'IK</ion-label>
					<ion-input *ngIf="ikIndemnite" [ngModel]="ikPrice" type="number" inputmode="decimal"
						[disabled]="!deplacement.isManualPrice" (ionChange)="setIkPrice($event)" class="price-input"></ion-input>
				</ion-item>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-content>

<ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
	<ion-fab-button color="success" (click)="validate()" title="Valider">
		<ion-icon name="checkmark"></ion-icon>
	</ion-fab-button>
</ion-fab>