import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Params, Router } from '@angular/router';
import { Keyboard } from '@capacitor/keyboard';
import { IonSearchbar, ModalController } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';
import { MenuPopoverComponent } from '@osapp/components/menu';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ContactHelper } from '@osapp/helpers/contactHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { EnumHelper } from '@osapp/helpers/enumHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { MapHelper } from '@osapp/helpers/mapHelper';
import { NumberHelper } from '@osapp/helpers/numberHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { EPrefix } from '@osapp/model/EPrefix';
import { IIndexedArray } from '@osapp/model/IIndexedArray';
import { PageInfo } from '@osapp/model/PageInfo';
import { UserData } from '@osapp/model/application/UserData';
import { EContactsType } from '@osapp/model/contacts/EContactsType';
import { IContact } from '@osapp/model/contacts/IContact';
import { IGroup } from '@osapp/model/contacts/IGroup';
import { IGroupMember } from '@osapp/model/contacts/IGroupMember';
import { EDateTimePickerMode } from '@osapp/model/date/EDateTimePickerMode';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { IDateTimePickerParams } from '@osapp/model/date/IDateTimePickerParams';
import { ActivePageManager } from '@osapp/model/navigation/ActivePageManager';
import { IPopoverItemParams } from '@osapp/model/popover/IPopoverItemParams';
import { IHydratedGroupMember } from '@osapp/modules/groups/model/IHydratedGroupMember';
import { EModalSize } from '@osapp/modules/modal/model/EModalSize';
import { ModalService } from '@osapp/modules/modal/services/modal.service';
import { C_SECTORS_ROLE_ID, PermissionsService } from '@osapp/modules/permissions/services/permissions.service';
import { IFavorites } from '@osapp/modules/preferences/favorites/model/IFavorites';
import { FavoritesService } from '@osapp/modules/preferences/favorites/services/favorites.service';
import { ISector } from '@osapp/modules/sectors/models/isector';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { ContactsService } from '@osapp/services/contacts.service';
import { EntityLinkService } from '@osapp/services/entityLink.service';
import { GroupsService } from '@osapp/services/groups.service';
import { PlatformService } from '@osapp/services/platform.service';
import { PopoverService } from '@osapp/services/popover.service';
import { BehaviorSubject, Subject, from, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, mapTo, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { C_PREFIX_PATIENT, C_PREFIX_TOURNEE } from '../../../../app/app.constants';
import { EStatusSeance } from '../../../../model/EStatusSeance';
import { IIdelizyContact } from '../../../../model/IIdelizyContact';
import { Seance } from '../../../../model/Seance';
import { ISeanceTournee } from '../../../../model/seances/ISeanceTournee';
import { SeanceService } from '../../../../services/seance.service';
import { TraitementService } from '../../../../services/traitement.service';
import { IPlanningRH } from '../../../planning-rh/model/IPlanningRH';
import { PlanningRHService } from '../../../planning-rh/services/planning-rh.service';
import { SeanceConflictsModalComponent } from '../../../seances/components/seance-conflicts-modal/seance-conflicts-modal.component';
import { ISeancesConflicts } from '../../../seances/model/ISeancesConflicts';
import { ExportTourneeModalComponent } from '../../components/export-tournee/export-tournee-modal.component';
import { ETourneeDisplayMode } from '../../model/ETourneeDisplayMode';
import { ETourneeMode } from '../../model/ETourneeMode';
import { IExportTourneeParams } from '../../model/IExportTourneeParams';
import { ITourneeFilterOptions } from '../../model/ITourneeFilterOptions';
import { ITourneeFilterValues } from '../../model/ITourneeFilterValues';
import { TourneeFilterService } from '../../services/tournee-filter.service';
import { TourneesService } from '../../services/tournees.service';

const forwardA = "forwardA";
const forwardB = "forwardB";
const backwardA = "backwardA";
const backwardB = "backwardB";

const forwardTransition = [
	query(":enter, :leave", style({ position: "fixed", width: "100%" }), { optional: true }),
	group([
		query(":enter", [
			style({ transform: "translateX(100%)" }),
			animate("0.5s ease-in-out", style({ transform: "translateX(0%)" }))
		], { optional: true }),
		query(":leave", [
			style({ transform: "translateX(0%)" }),
			animate("0.5s ease-in-out", style({ transform: "translateX(-100%)" }))
		], { optional: true }),
	])
];

const backwardTransition = [
	query(":enter, :leave", style({ position: "fixed", width: "100%" }), { optional: true }),
	group([
		query(":enter", [
			style({ transform: "translateX(-100%)" }),
			animate("0.5s ease-in-out", style({ transform: "translateX(0%)" }))
		], { optional: true }),
		query(":leave", [
			style({ transform: "translateX(0%)" }),
			animate("0.5s ease-in-out", style({ transform: "translateX(100%)" }))
		], { optional: true }),
	])
];

export const routerTransition = trigger("routerTransition", [
	transition(`* => ${forwardA}`, forwardTransition),
	transition(`* => ${forwardB}`, forwardTransition),
	transition(`* => ${backwardA}`, backwardTransition),
	transition(`* => ${backwardB}`, backwardTransition),
]);

export const TOURNEE_PAGE_INFO = {
	hasMenuButton: true,
	hasHomeButton: true,
	hasSettingButton: true,
	hasBackButton: true,
	hasSyncButton: true
};

enum ETourneeFilterPrefix {
	sectors = "sectors_",
	intervenants = "intervenants_",
	seanceStatuses = "seance-statuses_",
}

@Component({
	selector: "idl-tournees-page",
	templateUrl: "./tournees-page.component.html",
	styleUrls: ["./tournees-page.component.scss"],
	animations: [routerTransition],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TourneesPageComponent extends ComponentBase implements OnInit, OnDestroy {

	//TODO 1401: DateTimeSpinnerComponent ne peut pas être utilisé ici car il dépend du DynamicPageComponent.

	//#region FIELDS

	private moUpdateDisplayModeSubject = new Subject<ETourneeDisplayMode>();
	private moUpdateFiltersSubject = new Subject();

	/** Contact représentant 'aucun intervenant' */
	private static readonly C_NO_INTERVENANT_CONTACT: IIdelizyContact = ContactsService.createBlankContact(undefined, TourneeFilterService.C_NO_INTERVENANT_NAME);
	/** Intervenant représentant 'aucun intervenant' (si la séance n'a pas d'intervenant). */
	private static readonly C_NO_INTERVENANT: IHydratedGroupMember = {
		avatar: ContactsService.createContactAvatar(TourneesPageComponent.C_NO_INTERVENANT_CONTACT),
		groupMember: TourneesPageComponent.C_NO_INTERVENANT_CONTACT
	};

	/** Dernière date de tournée affichée, permet de déterminer le type de transition à réaliser lors d'un changement de tournée. */
	private mdLastDate: Date;
	/** Direction de la transition. */
	private msDirection: string;
	/** Tableau des intervenants sur lequel filtrer les intervenants. */
	private maIntervenants: IHydratedGroupMember[] = [];

	/** Tableau des "SeanceTournee" sur lequel filtrer un état spécifique. */
	private maSeances: ISeanceTournee[] = [];
	private moActivePageManager: ActivePageManager;
	private moIntervenantsByGroupIds = new Map<string, IIdelizyContact[]>();
	private moPatientAndIntervenantIdsByGroupIds: IIndexedArray<string[]> = {};
	private mbGroupModeGroup = true;
	private moCurrentDateSubject = new Subject<Date>();
	private moFilteredSeancesSubject = new BehaviorSubject<ISeanceTournee[]>([]);

	//#endregion

	//#region PROPERTIES

	/** Temps d'attente avant de lancer une recherche pour permettre à l'utilisateur de taper de nouveaux caractères. */
	public readonly debounceTimeMs = 1500;
	/** Placeholder pour la barre de recherche. */
	public readonly searchPlaceholder = "Rechercher par nom (patients/intervenants)";

	/** Infos de la page. */
	public pageInfo: PageInfo = {} as PageInfo;

	private mdCurrentDate = new Date;
	public get currentDate(): Date {
		return this.mdCurrentDate;
	}
	public set currentDate(pdCurrentDate: Date) {
		if (DateHelper.compareTwoDates(pdCurrentDate, this.mdCurrentDate) !== 0) {
			this.moCurrentDateSubject.next(this.mdCurrentDate = pdCurrentDate);
			this.detectChanges();
		}
	}

	public datePickerParams: IDateTimePickerParams = DateHelper.datePickerParamsFactory(ETimetablePattern.dd_MMM_yyyy);
	/** Indique si la zone de filtrage est ouverte ou non. */
	public isFilteringAreaOpen = false;
	/** Nombre de filtres activés. */
	public filteringCounter = 0;
	/** Tableau des intervenants sélectionnés pour le filtrage. */
	public selectedIntervenantIds: string[] = [];
	public selectedSectorIds: string[] = [];
	public intervenantOptions: ISelectOption<string>[] = [];
	public sectorsOptions: ISelectOption<string>[] = [];
	public readonly statusOptions: ReadonlyArray<ISelectOption<EStatusSeance>> = [
		{ label: "En attente", value: EStatusSeance.pending, icon: "cog" },
		{ label: "En retard", value: "late" as any as EStatusSeance, icon: "time-outline" },
		{ label: "Validée", value: EStatusSeance.done, icon: "checkmark" },
		{ label: "Annulée", value: EStatusSeance.canceled, icon: "close" }
	];
	/** Tableau des états de séances et avatars sélectionnés pour le filtrage. */
	public selectedSeanceStatuses: EStatusSeance[] = [];

	private get previousDay(): Date {
		return DateHelper.addDays(this.currentDate, -1 * (this.displayMode === ETourneeDisplayMode.week ? 7 : 1));
	}

	private get nextDay(): Date {
		return DateHelper.addDays(this.currentDate, 1 * (this.displayMode === ETourneeDisplayMode.week ? 7 : 1));
	}

	private get noIntervenantAndIntervenants(): IHydratedGroupMember[] {
		return this.maIntervenants.concat(TourneesPageComponent.C_NO_INTERVENANT);
	}

	/** Indique si le mode actuel est "planification" ou non (mode tournée). */
	public isPlanificationMode: boolean;

	/** Valeur de la recherche pour le filtrage par patient ou intervenant. */
	private msSearchValue: string;
	public get searchValue(): string { return this.msSearchValue; }
	public set searchValue(psSearchValue: string) {
		this.msSearchValue = psSearchValue;
		if (psSearchValue !== undefined && psSearchValue !== null) // Permet de filtrer même sur une chaîne vide (pour tout afficher dans ce cas).
			this.filterSeances();
	}

	public readonly displayModeSelectOptions: ReadonlyArray<ISelectOption<ETourneeDisplayMode>> = EnumHelper.getValues(ETourneeDisplayMode).map((psValue: ETourneeDisplayMode) => ({ label: psValue, value: psValue }));
	/** Mode d'affichage de la tournée. */
	public displayMode = ETourneeDisplayMode.day;
	/** Modes d'affichages de la tournée. */
	public availableDisplayModes = ETourneeDisplayMode;
	/** Date de début de la semaine. */
	public beginWeek: Date;
	/** Date de fin de la semaine. */
	public endWeek: Date;
	/** Indique si l'on peut changer de mode d'affichage. */
	public get canChangeDisplayMode(): boolean {
		return !this.isvcPlatform.isMobileApp;
	}
	public conflicts: ISeancesConflicts;

	//#endregion

	//#region METHODS

	constructor(
		private ioRoute: ActivatedRoute,
		private ioRouter: Router,
		private isvcModal: ModalService,
		private ioModalCtrl: ModalController,
		private isvcTourneeFilter: TourneeFilterService,
		private isvcPopover: PopoverService,
		public isvcTournees: TourneesService,
		private isvcEntityLink: EntityLinkService,
		private isvcPlatform: PlatformService,
		private isvcGroups: GroupsService,
		private isvcPermissions: PermissionsService,
		private isvcTraitement: TraitementService,
		private isvcPlanningRH: PlanningRHService,
		private isvcSeances: SeanceService,
		private isvcFavorites: FavoritesService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);

		this.moActivePageManager = new ActivePageManager(this, ioRouter, (psNewUrl: string, psPageUrl: string) => psNewUrl.indexOf("tournees") >= 0);
	}

	public ngOnInit(): void {
		// Initialise le filtrage de la tournée.
		this.isvcFavorites.get(C_PREFIX_TOURNEE)
			.pipe(
				tap((poPreferences: IFavorites) => {
					if (!this.isvcPlatform.isMobileApp) {
						// Mode d'affichage.
						const leDisplayMode: ETourneeDisplayMode = poPreferences?.display as ETourneeDisplayMode ?? this.displayMode;
						this.onDisplayModeChanged([leDisplayMode], true);

						// Filtres.
						if (ArrayHelper.hasElements(poPreferences?.filters)) {
							poPreferences.filters = ArrayHelper.unique(poPreferences.filters);
							this.selectedSectorIds = this.extractFilters(poPreferences.filters, ETourneeFilterPrefix.sectors);
							this.selectedIntervenantIds = this.extractFilters(poPreferences.filters, ETourneeFilterPrefix.intervenants);
							this.selectedSeanceStatuses = this.extractFilters(poPreferences.filters, ETourneeFilterPrefix.seanceStatuses)
								.map((psValue: string) => {
									if (NumberHelper.isStringNumber(psValue))
										return +psValue;
									return psValue;
								}) as any as EStatusSeance[];
						};
					}
					else
						this.onDisplayModeChanged([this.displayMode], true)
				}),
				takeUntil(this.destroyed$)
			).subscribe();

		// On initialise la sauvegarde du mode d'affichage.
		this.moUpdateDisplayModeSubject.asObservable()
			.pipe(
				debounceTime(1000),
				switchMap((peDisplayMode: ETourneeDisplayMode) => this.isvcFavorites.setDisplay(C_PREFIX_TOURNEE, peDisplayMode))
			).subscribe();

		// On initialise la sauvegarde du mode d'affichage.
		this.moUpdateFiltersSubject.asObservable()
			.pipe(
				debounceTime(1000),
				switchMap(() => this.isvcFavorites.setFilters(C_PREFIX_TOURNEE, [
					...this.selectedSectorIds.map((psSectorId: string) => ETourneeFilterPrefix.sectors + psSectorId),
					...this.selectedIntervenantIds.map((psIntervenantId: string) => ETourneeFilterPrefix.intervenants + psIntervenantId),
					...this.selectedSeanceStatuses.map((peStatusSeance: EStatusSeance) => ETourneeFilterPrefix.seanceStatuses + peStatusSeance)
				]))
			).subscribe();

		this.datePickerParams.pickerMode = EDateTimePickerMode.date;
		this.datePickerParams.color = "background";
		this.datePickerParams.hideIcon = true;

		this.initPlanningRHConflicts();
		this.initRouteSubscriptions();
		this.filterSubscription();
		this.initFilterOptions();
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moCurrentDateSubject.complete();
		this.moFilteredSeancesSubject.complete();
		this.moUpdateDisplayModeSubject.complete();
		this.moUpdateFiltersSubject.complete();
	}

	private initPlanningRHConflicts(): void {
		this.moCurrentDateSubject.asObservable().pipe(
			switchMap((pdCurrentDate: Date) => this.isvcPlanningRH.getPlanning(pdCurrentDate, this.moActivePageManager)),
			switchMap((poPlanningRH: IPlanningRH) => this.moFilteredSeancesSubject.asObservable().pipe(
				map((paFilteredSeance: ISeanceTournee[]) => paFilteredSeance.map((poSeanceTournee: ISeanceTournee) => poSeanceTournee.seance)),
				tap((paFilteredSeances: Seance[]) => {
					this.conflicts = this.isvcSeances.getConflicts(paFilteredSeances, poPlanningRH);
					this.detectChanges();
				})
			)),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	private initFilterOptions(): void {
		this.isvcGroups.getGroups(true)
			.pipe(
				switchMap((paGroups: IGroup[]) => {
					const laGroupsIntervenants: IGroup[] = [];
					const laSectors: IGroup[] = [];

					this.intervenantOptions = [];
					this.sectorsOptions = [];
					ArrayHelper.dynamicSort(paGroups, "name");

					paGroups.forEach((poGroup: IGroup) => {
						if (this.isvcGroups.hasRole(poGroup, C_SECTORS_ROLE_ID) && (poGroup as ISector).siteId === UserData.currentSite._id) {
							laSectors.push(poGroup);
							this.sectorsOptions.push({ label: poGroup.name, value: poGroup._id });
						}
						else
						{
							laGroupsIntervenants.push(poGroup);
							this.intervenantOptions.push({ label: poGroup.name, value: poGroup._id, icon: "people" });
						}
					});

					return this.isvcGroups.getGroupContactsIds(laSectors, [C_PREFIX_PATIENT, EPrefix.contact], true)
						.pipe(
							tap((poPatientIdsByGroupIds: IIndexedArray<string[]>) => this.moPatientAndIntervenantIdsByGroupIds = poPatientIdsByGroupIds),
							switchMap(_ => this.isvcGroups.getGroupContacts(laGroupsIntervenants, undefined, true))
						);
				}),
				tap((poContactsByGroupId: Map<string, IIdelizyContact[]>) => this.moIntervenantsByGroupIds = poContactsByGroupId),
				map((poContactsByGroupId: Map<string, IIdelizyContact[]>) => ArrayHelper.dynamicSort(ArrayHelper.unique(ArrayHelper.flat(MapHelper.valuesToArray(poContactsByGroupId))), "lastName")),
				map((paContacts: IIdelizyContact[]) => this.isvcTournees.transformContactsToHydratedContacts(paContacts)),
				tap((paHydratedContacts: IHydratedGroupMember[]) => {
					this.maIntervenants = paHydratedContacts;

					paHydratedContacts.forEach((poContact: IHydratedGroupMember<IContact>) =>
						this.intervenantOptions.unshift({
							label: ContactHelper.getInitials(poContact.groupMember),
							value: poContact.groupMember._id,
							icon: "person",
							tooltip: ContactHelper.getCompleteFormattedName(poContact.groupMember)
						})
					);

					this.intervenantOptions.unshift({ label: TourneeFilterService.C_NO_INTERVENANT_NAME, value: TourneeFilterService.C_NO_INTERVENANT_NAME, icon: "person" });

					if (this.isvcPlatform.isMobileApp) {
						const lsUserContactId: string = ContactsService.getUserContactId();
						this.selectedIntervenantIds = ArrayHelper.unique([...this.selectedIntervenantIds, ...(this.selectedIntervenantIds.includes(lsUserContactId), [], [lsUserContactId])]);
					}

					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private initRouteSubscriptions(): void {
		// Abonnement des changements de route pour récupérer les infos de la page affichée.
		this.ioRouter.events
			.pipe(
				filter((poEvent: ActivationEnd) => poEvent instanceof ActivationEnd && poEvent.snapshot.data.pageInfo),
				tap((poEvent: ActivationEnd) => this.pageInfo = poEvent.snapshot.data.pageInfo),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		// Abonnement des changements de paramètres de la route pour passer au composant fils ces paramètres.
		this.ioRoute.params
			.pipe(
				map(() => this.ioRoute.snapshot.firstChild?.params.date ?? new Date),
				tap((poDate: Date | string) => {
					this.initDate(poDate);
					this.isPlanificationMode = DateHelper.diffDays(this.currentDate, new Date) > 0;

					const leTourneeMode: ETourneeMode = this.isPlanificationMode ? ETourneeMode.planification : ETourneeMode.tournee;
					this.isvcTournees.raiseTourneeModeEvent(leTourneeMode);
				}),
				switchMap(_ => this.moActivePageManager.isActive$),
				distinctUntilChanged(),
				mergeMap((pbIsActive: boolean) => this.isvcEntityLink.currentEntity?.id.includes(C_PREFIX_TOURNEE) ? // Nettoyage de l'ancienne tournée active
					this.isvcEntityLink.clearCurrentEntity(this.isvcEntityLink.currentEntity.id).pipe(mapTo(pbIsActive)) : of(pbIsActive)),
				mergeMap((pbIsActive: boolean) => {
					return pbIsActive ?
						this.isvcEntityLink.trySetCurrentEntity(TourneesService.createTournee(this.currentDate)) :
						this.isvcEntityLink.clearCurrentEntity(TourneesService.createTournee(this.currentDate)._id);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.ioRoute.queryParams
			.pipe(
				map((poParams: Params) => poParams.mode),
				distinctUntilChanged(),
				tap((peMode: ETourneeDisplayMode) => {
					if (!StringHelper.isBlank(peMode)) {
						this.displayMode = peMode;
						this.detectChanges();
					}
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private filterSubscription(): void {
		// Abonnement des changements de filtres pour mettre à jour les séances à afficher.
		this.isvcTourneeFilter.getFilterOptionsAsObservable()
			.pipe(
				tap((poFilterOptions: ITourneeFilterOptions) => {
					// On affecte les séances planifiées et non planifiées.
					this.maSeances = poFilterOptions.seances;
					// On ajoute les possibles nouveaux intervenants (ceux qui ne sont pas dans le cabinet mais qui ont des séances).
					this.addNewIntervenants(this.maSeances);
					// On filtre les séances en notifiant la tournée quels sont les filtres en cours.
					this.filterSeances();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Ajoute les intervenants qui ne sont pas déjà présents dnas le tableau dédié depuis un tableau de données.
	 * @param paItems Tableau des éléments dont il faut vérifier la présence des intervenants dnas le tableau des intervenants du composant.
	 */
	private addNewIntervenants(paItems: ISeanceTournee[]): void {
		paItems.forEach((poItem: ISeanceTournee) => {
			poItem.intervenants.forEach((poHydrated: IHydratedGroupMember) => {
				const lsHydratedId: string = poHydrated.groupMember._id;

				if (!this.maIntervenants.some((poIntervenant: IHydratedGroupMember) => poIntervenant.groupMember._id === lsHydratedId))
					this.maIntervenants.push(poHydrated);
			});
		});
	}

	private initDate(poCurrentDate?: Date | string): void {
		const ldShortDate: Date = new Date(poCurrentDate);
		this.currentDate = DateHelper.isDate(ldShortDate) ? ldShortDate : new Date();
		this.calculateWeekDate();
		this.mdLastDate = ldShortDate;
	}

	/** Récupère "l'état" courant de la route (pour l'animation de transition entre deux tournées).\
	 * Pour permettre le déclenchement de l'animation, l'état doit obligatoirement être modifié.\
	 * 2 constantes sont utilisées - 'forwardA' et 'forwardB' - lorsque la direction de l'animation est 'forward' 2 fois de suite.
	 */
	public getTransitionState(): string {
		const lnDateComparison: number = DateHelper.compareTwoDates(this.currentDate, this.mdLastDate);

		if (lnDateComparison !== 0) {
			if (lnDateComparison > 0) // Direction 'forward'
				this.msDirection = this.msDirection === forwardA ? forwardB : forwardA;
			else
				this.msDirection = this.msDirection === backwardA ? backwardB : backwardA;

			this.mdLastDate = this.currentDate;
		}

		return this.msDirection;
	}

	/** Ouvre la popover de settings.
	 * @param poEvent Indique où l'événement s'est produit.
	 */
	public openSettings(poEvent: MouseEvent): void {
		this.isvcPopover.showCustomPopover(MenuPopoverComponent, {}, poEvent)
			.subscribe();
	}

	//#region Toolbar dédiée à une tournée (composant fils) : date, jour préc./suiv., ...

	public goToNextDay(): void {
		this.onDateChanged(this.nextDay);
	}

	public goToPreviousDay(): void {
		this.onDateChanged(this.previousDay);
	}

	public onDateChanged(pdDate: Date): void {
		this.currentDate = pdDate;
		this.calculateWeekDate();
		this.ioRouter.navigate([DateHelper.toDateUrl(this.currentDate)], {
			relativeTo: this.ioRoute,
			queryParams: { mode: this.displayMode }
		});
	}

	public exportTournee(pbAutoExport: boolean = false): void {
		this.isvcTourneeFilter.selectIntervenants(this.noIntervenantAndIntervenants,
			this.maIntervenants.filter((poIntervenant: IHydratedGroupMember) => this.selectedIntervenantIds.includes(poIntervenant.groupMember._id)),
			true, EContactsType.contacts, 1)
			.pipe(
				mergeMap((paSelectedIntervenants: IHydratedGroupMember[]) => {
					const laSelectedIntervenantIds: string[] = paSelectedIntervenants.map((poSelectedIntervenant: IHydratedGroupMember) => poSelectedIntervenant.groupMember._id);
					const loExportTourneeParams: IExportTourneeParams = {
						intervenants: paSelectedIntervenants,
						plannedSeances: this.filterSeancesFromSelectedIntervenants(this.maSeances.filter((poSeanceTournee: ISeanceTournee) => poSeanceTournee.seance.scheduled), laSelectedIntervenantIds),
						toBePlannedSeances: this.filterSeancesFromSelectedIntervenants(this.maSeances.filter((poSeanceTournee: ISeanceTournee) => !poSeanceTournee.seance.scheduled), laSelectedIntervenantIds),
						autoExport: pbAutoExport
					};
					const loModalOptions: ModalOptions = {
						component: ExportTourneeModalComponent,
						componentProps: loExportTourneeParams
					};

					return from(this.ioModalCtrl.create(loModalOptions));
				}),
				mergeMap((poModal: HTMLIonModalElement) => poModal.present())
			)
			.subscribe();
	}

	public openMorePopover(poEvent: MouseEvent): void {
		const laPopoverItems: IPopoverItemParams[] = [
			{
				action: () => of(this.exportTournee()),
				icon: "preview",
				title: "Aperçu avant export pdf"
			}
		];

		this.isvcPopover.showPopover(laPopoverItems, poEvent).subscribe();
	}

	/** Ouvre ou crée la conversation associée à la tournée actuelle. */
	public createOrOpenTourneeConversation(): void {
		this.isvcTournees.createOrOpenConversation(this.currentDate, this.maSeances)
			.pipe(takeUntil(this.destroyed$))
			.subscribe();
	}

	/** Calcule la date de début et de fin de la semaine. */
	public calculateWeekDate(): void {
		this.beginWeek = DateHelper.resetWeek(this.currentDate);
		this.endWeek = DateHelper.endWeek(this.currentDate);
	}

	//#endregion

	//#region Filtrage

	/** Permute l'ouverture/fermeture de la zone de filtrage. */
	public swapFilteringArea(): void {
		this.isFilteringAreaOpen = !this.isFilteringAreaOpen;
	}

	public async closeKeyboard(poSearchBar: IonSearchbar): Promise<void> {
		try {
			await Keyboard.hide();
		}
		finally {
			(await poSearchBar.getInputElement()).blur();
		}
	}

	/** Remise à zéro des filtres. */
	public resetFilters(): void {
		this.filteringCounter = 0;
		this.isFilteringAreaOpen = false;
		this.selectedIntervenantIds = [];
		this.selectedSeanceStatuses = [];
		this.selectedSectorIds = [];
		this.searchValue = "";
		this.raiseFilterValues(this.maSeances);
		this.moUpdateFiltersSubject.next();
	}

	private filterSeances(): void {
		let laFilteredSeances: ISeanceTournee[];

		laFilteredSeances = this.filterByIntervenants(this.selectedIntervenantIds);
		laFilteredSeances = this.filterBySeanceStatuses(this.selectedSeanceStatuses, laFilteredSeances);
		laFilteredSeances = this.filterBySearchValue(laFilteredSeances);
		laFilteredSeances = this.filterBySectors(laFilteredSeances);

		this.moFilteredSeancesSubject.next(laFilteredSeances);
		// Filtrage terminé, on envoie les résultats.
		this.raiseFilterValues(laFilteredSeances);
		this.detectChanges();
	}

	private extractFilters(paFilters: string[], peFilterPrefix: ETourneeFilterPrefix): string[] {
		return paFilters.filter((psValue: string) => psValue.startsWith(peFilterPrefix)).map((psValue: string) => IdHelper.extractIdWithoutPrefix(psValue, peFilterPrefix as any as EPrefix));
	}

	private filterBySectors(paSeancesTournee: ISeanceTournee[]): ISeanceTournee[] {
		if (!ArrayHelper.hasElements(this.selectedSectorIds))
			return paSeancesTournee;

		return paSeancesTournee.filter((poSeanceTournee: ISeanceTournee) =>
			this.selectedSectorIds.some((psSectorId: string) =>
				this.moPatientAndIntervenantIdsByGroupIds[psSectorId]?.some((psPatientId: string) => poSeanceTournee.seance.patientId === psPatientId)
			)
		);
	}

	private raiseFilterValues(paFilteredSeances: ISeanceTournee[]): void {
		const loFilterValues: ITourneeFilterValues = {
			filteredSeances: paFilteredSeances,
			isGroupModeGroup: this.mbGroupModeGroup
		};
		this.isvcTourneeFilter.raiseFilterValues(loFilterValues);

		// On compte le nombre de filtres : nombre d'intervenants sélectionnés + nombre d'états de séances sélectionnés + recherche en cours (1) ou non (0).
		this.filteringCounter = this.selectedIntervenantIds.length +
			this.selectedSeanceStatuses.length + this.selectedSectorIds.length +
			(StringHelper.isBlank(this.searchValue) ? 0 : 1);
	}

	//#region Filtrage par intervenants

	/** Filtre les séances planifiées et non planifiées par sélection d'intervenant(s) à l'aide d'une modale. */
	public onSelectedIntervenantIdsChanged(paSelectedIntervenantIds: string[]): void {
		this.selectedIntervenantIds = paSelectedIntervenantIds;
		this.moUpdateFiltersSubject.next();

		const lbHasGroups: boolean = this.selectedIntervenantIds.some((psIntervenantId: string) => IdHelper.hasPrefixId(psIntervenantId, EPrefix.group));

		this.mbGroupModeGroup = !ArrayHelper.hasElements(this.selectedSectorIds) || lbHasGroups;

		if (this.displayMode === ETourneeDisplayMode.day) {
			this.sectorsOptions.forEach((poOption: ISelectOption<string>) => poOption.disabled = lbHasGroups);
			this.sectorsOptions = [...this.sectorsOptions];
		}

		this.filterSeances();
	}

	/** Filtre les séances planifiées et non planifiées d'un objet par intervenants et renvoie le résultat.
	 * @param paSelectedIntervenants Tableau des intervenants avec lequel filtrer les séances.
	 */
	private filterByIntervenants(paSelectedIntervenantIds: string[]): ISeanceTournee[] {
		// Si le tableau des intervenants sélectionnés est vide, il faut filtrer avec tous les intervenants (pas de filtrage dessus).
		const laIntervenantIds: string[] = ArrayHelper.hasElements(paSelectedIntervenantIds) ?
			paSelectedIntervenantIds : this.noIntervenantAndIntervenants.map((poIntervenant: IHydratedGroupMember) => poIntervenant.groupMember._id);

		// Filtrage par intervenants sélectionnés.
		return this.filterSeancesFromSelectedIntervenants(this.maSeances, laIntervenantIds);
	}

	public onSelectedSectorChanged(paSelectedSectorIds: string[]): void {
		this.selectedSectorIds = paSelectedSectorIds;
		this.moUpdateFiltersSubject.next();

		this.mbGroupModeGroup = !ArrayHelper.hasElements(this.selectedSectorIds);

		if (this.displayMode === ETourneeDisplayMode.day) {
			this.intervenantOptions.forEach((poOption: ISelectOption<string>) => {

				if (IdHelper.hasPrefixId(poOption.value, EPrefix.group))
					poOption.disabled = !this.mbGroupModeGroup;
			});
			this.intervenantOptions = [...this.intervenantOptions];
		}

		this.filterSeances();
	}

	/** Retourne le tableau des séances filtrées dont au moins un des intervenants sélectionnés est présent dans la séance.
	 * @param paSeances Tableau des séances qu'il faut filtrer.
	 * NB : les séances sans intervenants sont toujours affichées.
	 */
	private filterSeancesFromSelectedIntervenants(paSeances: ISeanceTournee[], paSelectedIntervenantIds: string[]): ISeanceTournee[] {
		return paSeances.filter((poItem: ISeanceTournee) => {
			if (!ArrayHelper.hasElements(poItem.intervenants))
				return true;

			// S'il y a des intervenants, on garde l'item seulement si un des intervenants de l'item est présent dans les intervenants sélectionnés.
			return paSelectedIntervenantIds.some((psSelectedIntervenantId: string) => {
				if (IdHelper.hasPrefixId(psSelectedIntervenantId, EPrefix.contact)) {
					const laIntervenantIds: string[] = this.getContactsIdsFromIntervenantId(psSelectedIntervenantId);
					return poItem.intervenants.some((poIntervenant: IHydratedGroupMember) => laIntervenantIds.includes(poIntervenant.groupMember._id));
				}
				else {
					return poItem.intervenants.some((poIntervenant: IHydratedGroupMember) => poIntervenant.groupMember._id === psSelectedIntervenantId ||
						(this.moIntervenantsByGroupIds.get(psSelectedIntervenantId) ?? []).some((poGroupIntervenant: IIdelizyContact) => poGroupIntervenant._id === poIntervenant.groupMember._id));
				}
			});
		});
	}

	private getContactsIdsFromIntervenantId(psIntervenantId: string): string[] {
		const laIntervenantIds: string[] = [psIntervenantId];

		Object.keys(this.moPatientAndIntervenantIdsByGroupIds).forEach((psGroupId: string) => {
			if (this.moPatientAndIntervenantIdsByGroupIds[psGroupId]?.some((psId: string) => psId === psIntervenantId))
				laIntervenantIds.push(psGroupId);
		});

		return laIntervenantIds;
	}

	//#endregion

	//#region Filtrage par état de séance

	/** Filtre les séances planifiées et non planifiées par sélection d'état(s) de séance(s) à l'aide d'une modale. */
	public onSeanceStatusesChanged(paSeanceStatuses: EStatusSeance[]): void {
		this.selectedSeanceStatuses = paSeanceStatuses;
		this.moUpdateFiltersSubject.next();
		this.filterSeances();
	}

	/** Filtre les séances planifiées et non planifiées d'un objet par états de séances et renvoie le résultat.
	 * @param paSelectedSeanceStatuses Tableau des états de séances avec lequel filtrer les séances.
	 * @param paSeances Liste des séances qu'il faut filtrer.
	 */
	private filterBySeanceStatuses(paSelectedSeanceStatuses: EStatusSeance[], paSeances: ISeanceTournee[]): ISeanceTournee[] {
		// Si le tableau des états de séances est rempli, on filtre ces états.
		if (ArrayHelper.hasElements(paSelectedSeanceStatuses)) {
			return this.filterSeancesFromSelectedSeanceStatuses(paSeances, paSelectedSeanceStatuses);
		}
		else // Sinon, on a terminé le filtrage.
			return paSeances;
	}

	/** Retourne le tableau des séances filtrées dont les états sont sélectionnés dans le filtrage.
	 * @param paSeances Tableau des séances qu'il faut filtrer.
	 */
	private filterSeancesFromSelectedSeanceStatuses(paSeances: ISeanceTournee[], paSelectedSeanceStatuses: EStatusSeance[]): ISeanceTournee[] {
		const lbHasValidateStatus: boolean =
			paSelectedSeanceStatuses.some((poFilterItem: EStatusSeance) => poFilterItem === EStatusSeance.done);

		return paSeances.filter((poItem: ISeanceTournee) => {
			// Si le filtre par séance validée est présent et que la séance n'est pas planifiée, on l'exclue.
			if (lbHasValidateStatus && !poItem.seance.scheduled)
				return false;
			else // Sinon, on vérifie juste que de l'état d'une séance est présente dans le filtrage.
				return paSelectedSeanceStatuses.some((poFilterItem: EStatusSeance) => poFilterItem === poItem.seance.status || (poFilterItem === EStatusSeance.pending && !poItem.seance.status) || (poFilterItem === "late" as any as EStatusSeance && !poItem.seance.status && poItem.seance.isLate));
		});
	}

	//#endregion

	//#region Filtrage par recherche

	/** Filtre les séances par recherche et renvoie le résultat.
	 * @param paSeances Liste des séances qu'il faut filtrer.
	 */
	private filterBySearchValue(paSeances: ISeanceTournee[]): ISeanceTournee[] {
		if (StringHelper.isBlank(this.searchValue)) // Si la recherche par patient/intervenant n'a pas été lancée, pas besoin de filtrer.
			return paSeances;

		else // Sinon, filtrage par prénom/nom de patient/intervenant.
			return this.filterBySearchValueFromSeances(paSeances);
	}

	/** Retourne le tableau des séances filtrées en fonction de la recherche en cours.
	 * @param paSeances Tableau des séances qu'il faut filtrer.
	 */
	private filterBySearchValueFromSeances(paSeances: ISeanceTournee[]): ISeanceTournee[] {
		return paSeances.filter((poItem: ISeanceTournee) => {

			if (this.checkFilterByContactName(poItem.patient.groupMember)) // Filtrage par patient.
				return true;

			else { // Filtrage par intervenant.
				return !ArrayHelper.hasElements(poItem.intervenants) ? // S'il n'y a pas d'intervenant, on exclue la séance.
					false : poItem.intervenants.some((poIntervenant: IHydratedGroupMember) => this.checkFilterByContactName(poIntervenant.groupMember));
			}
		});
	}

	/** Retourne `true` si une partie du nom du contact correspond à la recherche en cours.
	 * @param poIntervenant Contact dont on veut vérifier s'il est éligible au filtre de la recherche ou non.
	 */
	private checkFilterByContactName(poIntervenant?: IGroupMember): boolean {
		return !poIntervenant ?
			false : this.isvcTraitement.getIntervenantName(poIntervenant).toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1;
	}

	public onDisplayModeChanged(paDisplayModes: ETourneeDisplayMode[], pbInit?: boolean): void {
		const leDisplayMode: ETourneeDisplayMode = ArrayHelper.getFirstElement(paDisplayModes);
		if (leDisplayMode !== this.displayMode || pbInit) {
			this.ioRouter.navigate([DateHelper.toDateUrl(this.currentDate)], {
				relativeTo: this.ioRoute,
				queryParams: { mode: this.displayMode = leDisplayMode },
			});
		}
	}

	public saveDisplayModeSelection(paDisplayModes: ETourneeDisplayMode[]): void {
		this.moUpdateDisplayModeSubject.next(ArrayHelper.getFirstElement(paDisplayModes));
	}

	//#endregion

	//#endregion

	public routeToPlanning(): void {
		this.ioRouter.navigateByUrl(`planning-rh?date=${DateHelper.transform(this.currentDate, ETimetablePattern.isoFormat_hyphen)}`);
	}

	public onConflictsClicked(): void {
		this.isvcModal.open<Seance[]>(
			{
				component: SeanceConflictsModalComponent,
				componentProps: { seancesConflicts: this.conflicts },
				backdropDismiss: true,
				cssClass: 'tournee-conflicts'
			}, EModalSize.small)
			.pipe(
				takeUntil(this.destroyed$)
			).subscribe();

	}

	//#endregion
}