<!-- Mode edit. -->
<ng-container *ngIf="!params.readOnly">
	<ion-item *ngIf="!params.displayStyle || params.displayStyle === 'list'" lines="none" class="ion-no-padding">
		<ion-icon *ngIf="fieldValue && (fieldValue.icon || fieldValue.url)" [name]="fieldValue.icon ? fieldValue.icon : ''"
			[src]="(!fieldValue.icon && fieldValue.url) ? fieldValue.url : ''" [class]="params.iconCss"></ion-icon>
		<ion-select [ngModel]="values" [multiple]="!!params.multiple" (ngModelChange)="change($event)"
			[class]="params.selectCss" [cancelText]="cancelText">
			<ion-select-option *ngFor="let option of params.options" [value]="option.value">{{option[labelFieldName]}}
			</ion-select-option>
		</ion-select>
	</ion-item>
</ng-container>

<!-- Mode visu. -->
<div *ngIf="params.readOnly && fieldValue" class="padd-btm-affection pad-top-report">
	<ion-item lines="none" class="ion-no-padding pad-end">
		<div class="text-align-report">
			<ion-label *ngIf='params.label && (label || iconName)' position="stacked"
				class="margin-b label-group txt10px min-w">
				{{params.label}}</ion-label>
			<ion-icon *ngIf="iconName && !params.multiple" [name]="iconName" [class]="params.iconCss"></ion-icon>
			<ion-label *ngIf="selectLabels.length > 0 && !!params.multiple" class="ion-text-wrap">
				<ng-container *ngFor="let selectLabel of selectLabels">
					<ion-chip color="primary">
						<ion-label>{{selectLabel}}</ion-label>
					</ion-chip>
				</ng-container>
			</ion-label>
			<div lines="none" class="just-flex marg-infos pad-type">
				<span *ngIf="params.readOnly && label && !params.multiple" [class]="params.className">
					{{label}}
				</span>
			</div>
		</div>
	</ion-item>
</div>