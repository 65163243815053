import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, filter, finalize, map, mapTo, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { ComponentBase } from '../../helpers/ComponentBase';
import { ContactHelper } from '../../helpers/contactHelper';
import { LifeCycleObserverComponentBase } from '../../helpers/LifeCycleObserverComponentBase';
import { StringHelper } from '../../helpers/stringHelper';
import { ConfigData } from '../../model/config/ConfigData';
import { IContact } from '../../model/contacts/IContact';
import { IDatabaseMeta } from '../../model/databaseDocument/IDatabaseMeta';
import { ETimetablePattern } from '../../model/date/ETimetablePattern';
import { EPrefix } from '../../model/EPrefix';
import { ELifeCycleEvent } from '../../model/lifeCycle/ELifeCycleEvent';
import { ILifeCycleEvent } from '../../model/lifeCycle/ILifeCycleEvent';
import { ENotificationFlag } from '../../model/notification/ENotificationFlag';
import { EDatabaseRole } from '../../model/store/EDatabaseRole';
import { IDataSource } from '../../model/store/IDataSource';
import { IDiagnosticsDocument } from '../../model/store/IDiagnosticsDocument';
import { IStoreDocument } from '../../model/store/IStoreDocument';
import { IUpdate } from '../../model/update/IUpdate';
import { IDmsMeta } from '../../modules/dms/model/IDmsMeta';
import { LocalDmsService } from '../../modules/dms/services/localDms.service';
import { SyncDmsService } from '../../modules/dms/services/syncDms.service';
import { IPermissionSet } from '../../modules/permissions/models/IPermissionSet';
import { C_ADMINISTRATORS_ROLE_ID, PermissionsService } from '../../modules/permissions/services/permissions.service';
import { IDatabaseSyncMarker } from '../../modules/store/model/IDatabaseSyncMarker';
import { ApplicationService } from '../../services/application.service';
import { BackgroundTaskService } from '../../services/backgroundTask.service';
import { ITask } from '../../services/backgroundTask/ITask';
import { ContactsService } from '../../services/contacts.service';
import { GroupsService } from '../../services/groups.service';
import { ShowMessageParamsPopup } from '../../services/interfaces/ShowMessageParamsPopup';
import { NetworkService } from '../../services/network.service';
import { NotificationService } from '../../services/notification.service';
import { PlatformService } from '../../services/platform.service';
import { Store } from '../../services/store.service';
import { UiMessageService } from '../../services/uiMessage.service';
import { UpdateService } from '../../services/update.service';
import { WorkspaceService } from '../../services/workspace.service';
import { DynamicPageComponent } from '../dynamicPage/dynamicPage.component';

@Component({
	selector: "calao-diagnostic",
	templateUrl: './diagnostics.component.html',
	styleUrls: ['./diagnostics.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiagnosticsComponent extends LifeCycleObserverComponentBase implements OnInit {

	//#region FIELDS

	/** Identifiant du document pouchDb permettant d'avoir des renseignements de diagnostique. */
	private static readonly C_DIAGNOSTICS_DOCUMENT_ID: string = "app_storage_diagnostics";
	/** Identifiant du composant dans les logs. */
	private static readonly C_LOG_ID = "DIAG.C::";

	/** Identifiant de la base de données d'application locale de l'app. */
	private readonly C_APP_STORAGE_DATABASE_ID: string;

	//#endregion

	//#region PROPERTIES

	/** Identifiant de l'application. */
	public appId: string = ConfigData.appInfo.appId;
	/** Numéro de version de l'application. */
	public appVersion: string = ConfigData.appInfo.appVersion;
	/** Identifiant de l'environnement sélectionné. */
	public appSelectedEnvironment: string = ConfigData.environment.id;
	/** Document de diagnostique de l'app. */
	public diagnosticsDocument: IDiagnosticsDocument = { _id: DiagnosticsComponent.C_DIAGNOSTICS_DOCUMENT_ID } as any;
	/** Prenom NOM de l'utilisateur. */
	public userName?: string;
	/** Login de l'utilisateur. */
	public userLogin: string = ConfigData.appInfo.login;
	/** Identifiant de l'apparail utilisé. */
	public systemDeviceId: string = ConfigData.appInfo.deviceId;
	/** Url utilisée pour les requêtes https. */
	public dataUrl: string = ConfigData.environment.cloud_url;
	/** Objet contenant l'identifiant de la base de données de config et le document méta associé. */
	public dataConfigDatabase: { id: string, databaseMeta: string } = { id: ConfigData.environment.coreRoleAppConfig, databaseMeta: "" };
	/** Si aucune mise à jour '0', sinon la version de la mise à jour disponible. */
	public availableUpdateVersion: string;
	public pluginGoogleMap;
	/** WidgetId de l'app `fr.calaosoft.mobile.[nom app]`. */
	public widgetId = "";
	/** Réseau connecté */
	public networkOnline: string;
	/** Réseau fiable */
	public networkReliable: string;
	/** Indique si l'on doit afficher le boutton de mise à jour. */
	public canUpdate: boolean = !ConfigData.environment.storeRelease && this.isvcPlatform.isMobileApp;
	/** Identifiant des workspaces de l'utilisateur. */
	public workspaceIds: string[] = [];
	/** Indique si l'on est sur une application mobile native. */
	public get isMobileApp(): boolean {
		return this.isvcPlatform.isMobileApp;
	}

	// Notification
	/** Indication sur l'état du service de notification. */
	public notificationState: string;
	/** Id obtenu par le service de notifications. */
	public notificationId: string;
	/** Token obtenu par le service de notifications. */
	public notificationPushToken: string;
	public notificationToken: string;

	// DMS
	public readonly C_DATABASE_SYNC_DATE_FORMAT = ETimetablePattern.dd_MM_yyyy_HH_mm_slash;
	/** Nombre de fichiers téléchargés. */
	public numberOfFiles = 0;
	/** Poids des fichiers téléchargés. */
	public fileSize: number;
	/** Nombre de fichiers en attente d'upload. */
	public pendingUpload = 0;
	/** Nombre de fichiers en attente de téléchargement. */
	public pendingDownload = 0;
	/** Tableau regroupant les documents du DMS. */
	public files: IDmsMeta[];
	public networkType: string;
	public networkDownlinkMax: string;
	public databaseSyncMarkers: IDatabaseSyncMarker[] = [];

	//BTS
	/** Nombre de tâches en cours. */
	public numberOfRunningTasks: number;
	/** Nombre de tâches en attente. */
	public numberOfWaitingTasks: number;

	private readonly mbIsCurrentUserAdmin: boolean;
	/** Indique si l'utilisateur courant est administrateur */
	public get isCurrentUserAdmin(): boolean { return this.mbIsCurrentUserAdmin; }

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcStore: Store,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcUpdate: UpdateService,
		private readonly isvcGroups: GroupsService,
		private readonly isvcPermissions: PermissionsService,
		private readonly isvcNotifications: NotificationService,
		private readonly isvcApp: ApplicationService,
		private readonly isvcLocalDms: LocalDmsService,
		private readonly ioRouter: Router,
		private readonly ioRoute: ActivatedRoute,
		private readonly isvcNetwork: NetworkService,
		private readonly isvcWorkspace: WorkspaceService,
		private readonly isvcBackgroundTasks: BackgroundTaskService,
		private readonly isvcSyncDms: SyncDmsService,
		private readonly isvcPlatform: PlatformService,
		private readonly isvcContact: ContactsService,
		@Optional() poParentPage: DynamicPageComponent<ComponentBase>,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poParentPage, poChangeDetectorRef);

		if (!poParentPage)
			console.warn(`${DiagnosticsComponent.C_LOG_ID}No dynamic page params.`);

		try {
			this.C_APP_STORAGE_DATABASE_ID = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.applicationStorage));
		}
		catch (poError) {
			console.warn(`${DiagnosticsComponent.C_LOG_ID}Error while getting application storage database ID.`, poError);
		}

		if (ArrayHelper.hasElements(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.dmsStorage, false)))
			this.initDmsDiagnostics();

		this.mbIsCurrentUserAdmin = this.isvcPermissions.hasRole(C_ADMINISTRATORS_ROLE_ID);
	}

	public ngOnInit(): void {
		if (this.C_APP_STORAGE_DATABASE_ID) {
			this.initDiagnosticsDocument().subscribe(); // Initialisation du document de diagnostique.
			this.initDatabaseMeta().subscribe(); // Initialisation du document de méta.
		}

		this.onRefreshNetworkButtonClick(); // Initialisation du widgetId de l'app.
		this.onCheckUpdatesButtonClick(); // Cherche si une mise à jour est disponible.
		this.initDatabaseSyncMarkers();

		this.initTasks();

		if (this.isvcPlatform.isMobileApp) { // Initialisations pertinentes sur mobile uniquement
			this.initWidgetId(); // Initialisation du widgetId de l'app.
			this.waitNotificationInit(); // Récupère les données du service de notification.
		}

		this.workspaceIds = this.isvcWorkspace.getUserWorkspaceIds();

		this.initUserName().pipe(takeUntil(this.destroyed$)).subscribe();
	}

	/** Initialise les informations relatives au DMS */
	private initDmsDiagnostics(): void {
		this.ioRouter.events.pipe(
			filter((poEvent: Event) => poEvent instanceof NavigationEnd && poEvent.url === "/diagnostics"),
			mergeMap(_ => this.initLocalFiles()),
			mergeMap(_ => this.initPendingFiles()),
			catchError(poError => {
				console.error(`${DiagnosticsComponent.C_LOG_ID}Error while initializing DMS diagnostics.`, poError);
				return EMPTY;
			}),
			takeUntil(this.destroyed$)
		)
			.subscribe();
	}

	/** Initialise les marqueurs de synchronisation des bases de données. */
	private initDatabaseSyncMarkers(): void {
		this.isvcStore.getSyncMarkers(true)
			.pipe(
				tap((paSyncMarkers: IDatabaseSyncMarker[]) => {
					ArrayHelper.clear(this.databaseSyncMarkers);
					this.databaseSyncMarkers.push(...paSyncMarkers);
					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			).subscribe();
	}

	public onRefreshNetworkButtonClick(): void {
		this.isvcNetwork.asyncIsNetworkReliable().pipe(
			map((pbReliable: boolean) => {
				this.networkType = this.isvcNetwork.getNetworkType();
				this.networkDownlinkMax = this.isvcNetwork.getNetworkDonwlinkMax();
				this.networkOnline = this.isvcNetwork.isNetworkOnline() ? "connecté" : "déconnecté";
				this.networkReliable = (pbReliable ? "fiable" : "non fiable") + " (" + (this.isvcNetwork.isNetworkReliable() ? "fiable" : "non fiable") + ")";

				return true;
			}),
			catchError(poError => {
				console.error(`${DiagnosticsComponent.C_LOG_ID}Error while refreshing networks diagnostics.`, poError);
				return EMPTY;
			}))
			.subscribe();
	}

	protected onLifeCycleEvent(poEvent: ILifeCycleEvent): void {
		switch (poEvent.data.value) {

			case ELifeCycleEvent.viewWillLeave:
				this.isvcStore.put(this.diagnosticsDocument, this.C_APP_STORAGE_DATABASE_ID)
					.pipe(
						tap(
							_ => console.log(`${DiagnosticsComponent.C_LOG_ID}Diagnostics settings saved.`),
							poError => console.error(DiagnosticsComponent.C_LOG_ID, poError)
						)
					)
					.subscribe();
				break;
		}
	}

	private initDiagnosticsDocument(): Observable<IDiagnosticsDocument[]> {
		return this.isvcStore.get({
			databaseId: this.C_APP_STORAGE_DATABASE_ID,
			viewParams: { key: DiagnosticsComponent.C_DIAGNOSTICS_DOCUMENT_ID, include_docs: true }
		} as IDataSource)
			.pipe(
				tap(
					(paResults: IDiagnosticsDocument[]) => {
						if (ArrayHelper.hasElements(paResults))
							this.diagnosticsDocument = ArrayHelper.getFirstElement(paResults);
						this.detectChanges();
					},
					poError => console.error(DiagnosticsComponent.C_LOG_ID, poError)
				),
				takeUntil(this.destroyed$)
			);
	}

	private initDatabaseMeta(): Observable<IDatabaseMeta> {
		return this.isvcStore.getDatabaseMeta()
			.pipe(
				tap(
					(poDatabaseMeta?: IDatabaseMeta) => {
						this.dataConfigDatabase.databaseMeta = JSON.stringify(poDatabaseMeta);
						this.detectChanges();
					},
					poError => console.error(DiagnosticsComponent.C_LOG_ID, poError)
				),
				takeUntil(this.destroyed$)
			);
	}

	private initWidgetId(): void {
		this.widgetId = this.isvcPlatform.widgetId;
		this.detectChanges();
	}

	private initLocalFiles(): Observable<IDmsMeta[]> {
		return this.isvcLocalDms.getLocalMetaDataFiles()
			.pipe(
				tap(
					(paFiles: IDmsMeta[]) => {
						this.fileSize = 0;
						this.files = [...paFiles];
						this.numberOfFiles = paFiles.length;
						paFiles.forEach((poFile: IDmsMeta) => {
							this.fileSize += poFile.size;
						});

						this.detectChanges();
					},
					poError => console.error(DiagnosticsComponent.C_LOG_ID, poError)
				),
				takeUntil(this.destroyed$)
			);
	}

	private initPendingFiles(): Observable<IStoreDocument[]> {
		return this.isvcSyncDms.getPendingFiles()
			.pipe(
				tap(
					(paFiles: IStoreDocument[]) => {
						this.pendingDownload = 0;
						this.pendingUpload = 0;

						paFiles.forEach((poFile: IStoreDocument) => {
							if (poFile._id.includes(EPrefix.pendingDownload))
								this.pendingDownload++;
							else if (poFile._id.includes(EPrefix.pendingUpload))
								this.pendingUpload++;
						});

						this.detectChanges();
					},
					poError => console.error(`${DiagnosticsComponent.C_LOG_ID}Error while getting waiting docs.`, poError)
				),
				takeUntil(this.destroyed$)
			);
	}

	/** Initialise l'affichage du nombre de tâches en fonction de leur état d'activité. */
	private initTasks(): void {

		this.isvcBackgroundTasks.getWaitingTasks().pipe(takeUntil(this.destroyed$)).subscribe((laTasks: ITask[]) => {
			this.numberOfWaitingTasks = laTasks.length;
			this.detectChanges();
		});

		this.isvcBackgroundTasks.getRunningTasks().pipe(takeUntil(this.destroyed$)).subscribe((laTasks: ITask[]) => {
			this.numberOfRunningTasks = laTasks.length;
			this.detectChanges();
		});

	}

	/** Appelée lors du changement d'état du toggle associé au debug pouchDB.
	 * @param pbEvent Booléen indiquant à quel état le toggle associé est passé.
	 */
	public onPouchDbDebugChanged(pbEvent: boolean): void {
		this.diagnosticsDocument.isPouchDbDebugEnabled = pbEvent;
		this.diagnosticsDocument.isPouchDbDebugEnabled ? Store.enablePouchDBDebug() : Store.disablePouchDBDebug();
	}

	/** Appelée lors du changement d'état du toggle associé au debug OneSignal.
	 * @param pbEvent Booléen indiquant à quel état le toggle associé est passé.
	 */
	public onOneSignalDebugChanged(pbEvent: boolean): void {
		this.diagnosticsDocument.isOneSignalDebugEnabled = pbEvent;
		this.diagnosticsDocument.isOneSignalDebugEnabled ? this.isvcNotifications.enableOneSignalDebug() : this.isvcNotifications.disableOneSignalDebug();
	}

	public onCheckUpdatesButtonClick(): void {
		this.showUpdatePopup().pipe(
			catchError(poError => {
				console.error(`${DiagnosticsComponent.C_LOG_ID}Error while checking updates.`, poError);
				return EMPTY;
			}),
			takeUntil(this.destroyed$)).subscribe();
	}

	private showUpdatePopup(): Observable<any> {
		return this.isvcUpdate.popupUpdate()
			.pipe(
				tap(
					(poUpdate: IUpdate) => {
						if (!poUpdate) {	// Si aucune mise à jour.
							if (this.availableUpdateVersion === "Aucune") {
								// Affichage d'un message pour indiquer à l'utilisateur qu'une recherche de mise à jour a bien été faite.
								this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Aucune mise à jour disponible.", header: "Mise à jour" }));
							}

							this.availableUpdateVersion = "Aucune";
						}
						else
							this.availableUpdateVersion = poUpdate.version;
					},
					(poError: any) => {
						this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Problème lors de la recherche de mise à jour.", message: poError }));
						this.availableUpdateVersion = "Erreur";
					}
				),
				finalize(() => this.detectChanges())
			);
	}

	/** Affiche la liste des groupes auxquels appartient l'utilisateur actif. */
	public onShowUserGroupsClick(): void {
		this.isvcGroups.getUserGroupsIds()
			.pipe(
				tap((psGroupIds: string[]) =>
					this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: JSON.stringify(psGroupIds), header: "Groupes" }))
				),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Affiche les permissions de l'utilisateur actif. */
	public onShowUserPermissionsClick(): void {
		if (!this.isvcPermissions.hasPermissions) {
			this.isvcUiMessage.showMessage(
				new ShowMessageParamsPopup({ message: "Le contrôle des permissions n'est pas activé pour cette application.", header: "Permissions" })
			);
		}
		else
			this.isvcPermissions["getUserPermissionSet"]() // Méthode privée
				.pipe(
					tap((poPermissions: IPermissionSet) => {
						const lsPermissionsMessage: string = JSON.stringify(poPermissions) // Formattage de l'affichage des permissions (pas parfait mais plus lisible).
							.replace(/:/g, ": ")
							.replace(/,/g, ",<br>")
							.replace(/{/g, "{<br>")
							.replace(/}/g, "<br>}<br>");

						this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: lsPermissionsMessage, header: "Permissions" }));
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
	}

	/** Affiche les métadonnées des bases de données courantes. */
	public showDatabaseMeta(): void {
		this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: this.dataConfigDatabase.databaseMeta, header: "Métadonnées" }));
	}

	// Navigation vers la page de diagnostics des fichiers du DMS.
	public navigateToDiagnosticFiles(): void {
		this.ioRouter.navigate(["files"], { relativeTo: this.ioRoute });
	}

	public navigateToDiagnosticTasks(): void {
		this.ioRouter.navigate(["tasks"], { relativeTo: this.ioRoute });
	}

	public navigateToLogsPage(): void {
		this.ioRouter.navigate(["logs"], { relativeTo: this.ioRoute });
	}

	/** Attend l'événement d'initialisation de OneSignal, puis récupère l'identifiant de l'utilisateur. */
	private waitNotificationInit(): void {
		this.notificationId = "";

		if (!ConfigData.oneSignal) {
			this.notificationState = "Pas de configuration";
			this.detectChanges();
		}
		else {
			this.notificationState = "Initialisation ...";

			this.isvcApp.observeFlag(ENotificationFlag.Initialized)
				.pipe(
					tap(() => {
						if (!StringHelper.isBlank(this.isvcNotifications.deviceId)) {
							this.notificationId = this.isvcNotifications.deviceId; // Affectation de l'identifiant de l'appareil.
							this.notificationPushToken = this.isvcNotifications.pushToken; // Affectation du pushToken.
							this.notificationToken = ConfigData.authentication?.token; // Affectation du token de session => utilité à vérifier, non applicable dans AFH-Admin.
							this.notificationState = "Initialisé";
						}
						else
							this.notificationState = this.isvcPlatform.isMobileApp ? "Pas d'identifiant" : "Indisponible sur navigateur web";

						this.detectChanges();
					}),
					catchError(poError => {
						console.error(`${DiagnosticsComponent.C_LOG_ID}Error while initializing notifications diagnostics.`, poError);
						return EMPTY;
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
	}

	private initUserName(): Observable<void> {
		const lsContactId: string | undefined = ContactsService.getUserContactId();
		let loGetContact$: Observable<IContact | undefined>;

		if (!lsContactId)
			loGetContact$ = of(undefined);
		else
			loGetContact$ = this.isvcContact.getContact(lsContactId);

		return loGetContact$
			.pipe(
				take(1),
				tap((poContact?: IContact) => {
					this.userName = poContact ? ContactHelper.getCompleteFormattedName(poContact) : "Utilisateur";
					this.detectChanges();
				}),
				mapTo(undefined)
			);
	}

	//#endregion

}
