
import { Entity } from '@osapp/model/entities/Entity';
import { EAvatarSize } from '@osapp/model/picture/EAvatarSize';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { EntityBuilder } from '@osapp/services';
import { C_PREFIX_TOURNEE } from 'apps/idl/src/app/app.constants';
import { Observable, of } from 'rxjs';
import { TourneesService } from '../services/tournees.service';
import { ITournee } from './ITournee';

export class TourneeEntityBuilder<T extends ITournee = ITournee> extends EntityBuilder<T> {

	//#region METHODS

	constructor() {
		super(
			(psModelId: string) => RegExp(C_PREFIX_TOURNEE).test(psModelId),
			(poTournee: T) => new Entity(
				poTournee,
				this.getEntityRoute(poTournee),
				() => this.getEntityName(poTournee)
			),
			(psModelId: string) => "Tournées",
			EDatabaseRole.workspace,
			C_PREFIX_TOURNEE,
			() => ({
				size: EAvatarSize.medium,
				icon: "calendar"
			}),
			(poTournee: ITournee) => ({
				size: EAvatarSize.medium,
				icon: "calendar"
			}),
			// Paramètre optionel non utilisé.
			undefined,
			// Récupération d'entité personnalisée.
			(psDatabaseId: string, psEntityId: string): Observable<Entity<T>> =>
				of(this.build(TourneesService.createTournee(TourneesService.getDateFromTourneeId(psEntityId)) as T))
		);
	}

	public getEntityName(poTournee: ITournee): string {
		return TourneesService.getTourneeName(poTournee);
	}

	public getEntityRoute(poTournee: ITournee): string {
		return TourneesService.getTourneeRoute(poTournee);
	}

	//#endregion
}