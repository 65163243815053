<osapp-header-modal title="Déplacer acte"></osapp-header-modal>

<ion-content>
	<mat-accordion>
		<mat-expansion-panel #panelSeances [expanded]="filteredSeances && filteredSeances.length > 0"
			togglePosition="before">
			<mat-expansion-panel-header>
				<mat-panel-title>
					Séance
				</mat-panel-title>
			</mat-expansion-panel-header>
			<ng-container *ngIf="filteredSeances && filteredSeances.length > 0; else emptyTemplate">
				<ion-list>
					<ion-radio-group (ionChange)="onSeanceSelected($event)">
						<ion-item *ngFor="let seance of filteredSeances">
							<ion-radio mode="md" slot="start" [value]="seance"></ion-radio>
							<ion-label>Séance du
								{{seance.startDate | dateWithLocale: 'fr-FR' : 'EEE dd MMMM yyyy'}}{{seance.scheduled ? ' à ' +
								(seance.startDate | dateWithLocale: 'fr-FR' : 'HH:mm') : ''}}
							</ion-label>
						</ion-item>
					</ion-radio-group>
				</ion-list>
			</ng-container>
			<ng-template #emptyTemplate>
				<ion-label>Aucune séance existante disponible.</ion-label>
			</ng-template>
		</mat-expansion-panel>

		<mat-expansion-panel [expanded]="!filteredSeances || filteredSeances.length === 0" togglePosition="before">
			<mat-expansion-panel-header>
				<mat-panel-title>
					Nouvelle séance
				</mat-panel-title>
			</mat-expansion-panel-header>
			<osapp-date-time [params]="datePickerParams" (modelChange)="createSeance($event)"></osapp-date-time>
		</mat-expansion-panel>
	</mat-accordion>
</ion-content>

<ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
	<ion-fab-button [disabled]="!selectedSeance" color="danger" (click)="validate()" title="Valider">
		<ion-icon name="checkmark"></ion-icon>
	</ion-fab-button>
</ion-fab>