import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from '@angular/forms';
import { HoursMinutesRepetition } from "@osapp/modules/event-markers/models/hours-minutes-repetition";
import { Recurrence } from "@osapp/modules/event-markers/models/recurrence";
import { StoredSeance } from "apps/idl/src/anakin/models/StoredSeance";
import { EMoments } from "../../enums/EMoments";
@Component({
	selector: "di-modifier-heures-soins",
	templateUrl: "./modifier-heures-soins.component.html",
	styleUrls: ["./modifier-heures-soins.component.scss"],
})
export class ModifierHeuresSoinsComponent implements OnInit {
	@Input() seance: StoredSeance;
	@Input() onConfirm: (seance: StoredSeance) => void;
	@Input() onCancel: () => void;
	public selectedMoment: EMoments | string;
	public moments: EMoments[] = Object.values(EMoments);
	public horairePrecise: string = "";
	newDate: Date
	public modifForm: FormGroup;

	constructor(
		private fb: FormBuilder,
	) { }

	ngOnInit(): void {
		this.modifForm = this.fb.group({
			heure: [this.horairePrecise],
		});
	}

	handleConfirm() {
		if (this.modifForm.get('heure')?.value !== "") {
			const [hoursString, minutesString] = this.modifForm.get('heure')?.value.split(":");
			const hours = parseInt(hoursString, 10);
			const minutes = parseInt(minutesString, 10);
			let updatedDate = new Date(this.seance.startDate)
			updatedDate.setHours(hours);
			updatedDate.setMinutes(minutes);
			this.seance.startDate = updatedDate
			this.seance.moment =  new HoursMinutesRepetition({hours: hoursString, minutes: minutesString});
		} else {
			this.newDate= StoredSeance.getStartDateFromMoment(this.selectedMoment as EMoments,this.seance.startDate);
			this.seance.moment = Recurrence.getRepetition(this.selectedMoment as EMoments)
			this.seance.startDate = this.newDate
		}

		if (this.onConfirm)
			this.onConfirm(this.seance);
	}


	cancel() {
		if (this.onCancel)
			this.onCancel();
	}

	onSelectMoment(moment: EMoments) {
		this.selectedMoment = moment;
	}
}
