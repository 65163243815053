import { ILogActionHandler } from "../models/ILogActionHandler";
import { ILogActionParams } from "../models/ILogActionParams";
import { ILogActionPropertyDescriptor } from '../models/ILogActionPropertyDescriptor';
import { ILogActionPropertyFunctionType } from '../models/ILogActionPropertyFunctionType';
import { ILogActionPropertyReturnType } from '../models/ILogActionPropertyReturnType';

/** Permet d'activer un log action lors de l'éxecution de la méthode. Utiliser le snippet `logAction`.
 * @param poParams 
 */
export function LogAction<T extends Array<any>, V>(poParams: ILogActionParams<T, ILogActionPropertyReturnType<V>>):
	(poTarget: ILogActionHandler,
		psMethodName: string | symbol,
		poDescriptor: ILogActionPropertyDescriptor<T, V>
	) => ILogActionPropertyDescriptor<T, V> | void {

	return function (poTarget: ILogActionHandler,
		psMethodName: string | symbol,
		poDescriptor: ILogActionPropertyDescriptor<T, V>
	): ILogActionPropertyDescriptor<T, V> | void {

		const lfOriginalMethod: ILogActionPropertyFunctionType<T, V> = poDescriptor.value; // On sauvegarde l'ancienne implémentation du getter.

		poDescriptor.value = function (): V {
			const laArguments: T = arguments as any as T; // on capture les arguments de la méthode.
			const loTarget: ILogActionHandler = this; // Représente la classe qui appelle le décorateur.

			return loTarget.logActionHandler.handle(poParams, laArguments, lfOriginalMethod);
		};

		return poDescriptor;
	}
}