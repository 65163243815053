export abstract class AdeliHelper {

	//#region METHODS

	/** Indique si un texte correspond au format ADELI (2 caractères pour le département puis 7 chiffres).
	 * @param psValue Texte à valider.
	 * @returns 
	 */
	public static isValid(psValue: string): boolean {
		return /^[0-9]{1}[0-9a-zA-Z]{1}[0-9]{7}$/.test(psValue);
	}

	//#endregion

}
