import { Component, Input, OnInit } from '@angular/core';
import { StoredSeance } from '../../../../models/StoredSeance';
import { ESeanceOptionModification } from '../../enums/ESeanceOptionModification';


@Component({
  selector: 'di-choix-seance',
  templateUrl: './choix-seance.component.html',
  styleUrls: ['./choix-seance.component.scss'],
})
export class ChoixSeanceComponent implements OnInit {

	public selectedOption: string;
	public options = Object.values(ESeanceOptionModification);
	@Input() seance : StoredSeance;
  @Input() onSave: (option : string) => void;
  @Input() onCancel: () => void;

  constructor(
  ) { }

  ngOnInit() { 
		this.selectedOption = ESeanceOptionModification.Seance;
	}
  
  selectOption(option: string) {
    this.selectedOption = option;
  }

	public getLabelForOption(option: ESeanceOptionModification): string {
		switch(option) {
			case ESeanceOptionModification.Seance:
				return 'Uniquement cette séance';
			case ESeanceOptionModification.AllSeances:
				return 'Toutes les séances';
			case ESeanceOptionModification.OnlyFutur:
				return 'Cette séance et les séances futures';
			default:
				return '';
		}
	}

  public save(): void {
		if(this.onSave)
  		this.onSave(this.selectedOption)
  }
  

  public cancel(): void {
		if(this.onCancel)
   		this.onCancel()
  }
}
