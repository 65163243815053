import { DateHelper } from '@osapp/helpers/dateHelper';
import { ISeance } from '../../../model/ISeance';
export abstract class SeancesHelper {

	//#region METHODS

	public static sortSeancesByDate<T extends ISeance>(paSeances: T[]): T[] {
		return paSeances.sort((poSeanceA: T, poSeanceB: T) => DateHelper.compareTwoDates(poSeanceA.startDate, poSeanceB.startDate));
	}

	//#endregion

}
