import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { DateTimePickerComponent } from '@osapp/components/date/dateTimePicker.component';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { MapHelper } from '@osapp/helpers/mapHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { StoreHelper } from '@osapp/helpers/storeHelper';
import { UserHelper } from '@osapp/helpers/user.helper';
import { EPrefix } from '@osapp/model/EPrefix';
import { IIndexedArray } from '@osapp/model/IIndexedArray';
import { IContact } from '@osapp/model/contacts/IContact';
import { IGroup } from '@osapp/model/contacts/IGroup';
import { EDateTimePickerMode } from '@osapp/model/date/EDateTimePickerMode';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { IDateTimePickerParams } from '@osapp/model/date/IDateTimePickerParams';
import { EAvatarSize } from '@osapp/model/picture/EAvatarSize';
import { IAvatar } from '@osapp/model/picture/IAvatar';
import { OsappError } from '@osapp/modules/errors/model/OsappError';
import { IHydratedGroupMember } from '@osapp/modules/groups/model/IHydratedGroupMember';
import { Loader } from '@osapp/modules/loading/Loader';
import { ModalComponentBase } from '@osapp/modules/modal/model/ModalComponentBase';
import { Observation } from '@osapp/modules/observations/models/observation';
import { ObservationsService } from '@osapp/modules/observations/observations.service';
import { IHasPermission, PermissionsService } from '@osapp/modules/permissions/services/permissions.service';
import { PrestationService } from '@osapp/modules/prestation/services/prestation.service';
import { Queue } from '@osapp/modules/utils/queue/decorators/queue.decorator';
import { ContactNamePipe } from '@osapp/pipes/contactName.pipe';
import { ContactsService } from '@osapp/services/contacts.service';
import { GroupsService } from '@osapp/services/groups.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '@osapp/services/interfaces/ShowMessageParamsToast';
import { LoadingService } from '@osapp/services/loading.service';
import { PlatformService } from '@osapp/services/platform.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { Observable, combineLatest, defer, of } from 'rxjs';
import { filter, finalize, map, mapTo, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { C_CONSTANTES_PREFIX, C_INJECTIONS_PREFIX } from '../../../../app/app.constants';
import { Acte } from '../../../../model/Acte';
import { ESeanceStatusIcon } from '../../../../model/ESeanceStatusIcon';
import { EStatusSeance } from '../../../../model/EStatusSeance';
import { IIdelizyContact } from '../../../../model/IIdelizyContact';
import { Seance } from '../../../../model/Seance';
import { Traitement } from '../../../../model/Traitement';
import { TraitementSlideParentBase } from '../../../../model/TraitementSlideParentBase';
import { ISeanceTournee } from '../../../../model/seances/ISeanceTournee';
import { SeanceService } from '../../../../services/seance.service';
import { TraitementService } from '../../../../services/traitement.service';
import { CancelConstraint } from '../../../actes/model/cancel-constraint';
import { FacturationService } from '../../../facturation/facturation.service';
import { Invoice } from '../../../facturation/models/invoice';
import { Ordonnance } from '../../../ordonnances/models/ordonnance';
import { OrdonnancesService } from '../../../ordonnances/services/ordonnances.service';
import { ConstantesModalComponent } from '../../../patients/components/constantes/constantes-modal/constantes-modal.component';
import { TransmissionModalComponent } from '../../../patients/components/transmissions/transmission-modal/transmission-modal.component';
import { ITransmissionsListParams } from '../../../patients/model/ITransmissionsListParams';
import { IConstantesListParams } from '../../../patients/model/constantes/IConstantesListParams';
import { IConstantesModalParams } from '../../../patients/model/constantes/IConstantesModalParams';
import { ConstantesService } from '../../../patients/services/constantes.service';
import { PatientsService } from '../../../patients/services/patients.service';
import { RapportService } from '../../../patients/services/rapport.service';
import { EChoiceFilter } from '../../../seances/choose-seances-to-modify-modal/EChoiceFilter';
import { IChoice } from '../../../seances/choose-seances-to-modify-modal/IChoice';
import { IChooseSeancesToModifiyResponse } from '../../../seances/model/ichoose-seances-to-modifiy-response';
import { ValidateActesComponent } from '../../../traitement/validateActes/validate-actes.component';
import { ISeanceModalResponse } from '../../model/ISeanceModalResponse';
import { TourneesService } from '../../services/tournees.service';

@Component({
	templateUrl: './seance-modal.component.html',
	styleUrls: ['./seance-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeanceModalComponent extends ModalComponentBase<ISeanceModalResponse> implements OnInit, IHasPermission {

	//TODO 1401: DateTimeSpinnerComponent ne peut pas être utilisé ici car il dépend du DynamicPageComponent.
	//#region FIELDS

	private maOtherSeances: Seance[] = [];
	private mdTraitementBreakDate: Date;
	private msExplanation: string;
	private mbTraitementChanged: boolean;
	private msUserContactId: string = ContactsService.getUserContactId();
	/** Compteur du nombre d'enregistrement en cours. */
	private mnSavingCount = 0;
	private maIntervenantIds: string[] = [];
	/** Indique si la séance était à l'état `done` avant l'ouverture de la modale. */
	private mbAlreadyDone = false;
	/** Loader de la sauvegarde des modifications. */
	private moSavingLoader: Loader;

	//#endregion

	//#region PROPERTIES

	public readonly C_DATE_FORMAT = ETimetablePattern.dd_MM_yyyy_HH_mm_slash;
	public readonly C_DAY_FORMAT = ETimetablePattern.dd_MM_yyyy_slash;
	public readonly C_TIME_FORMAT = ETimetablePattern.HH_mm;

	public dateParams: IDateTimePickerParams = DateHelper.datePickerParamsFactory(ETimetablePattern.dd_MM_yyyy, EDateTimePickerMode.date);
	public timeParams: IDateTimePickerParams = DateHelper.datePickerParamsFactory(ETimetablePattern.HH_mm, EDateTimePickerMode.time);
	public seance: Seance;
	public isLockedSeance: boolean;
	/** Objet de paramètres pour le composant `transmissions-list`. */
	public transmissionsListParams: ITransmissionsListParams;
	/** Objet de paramètres pour le composant `idl-surveillances-list`. */
	public constantesListParams: IConstantesListParams;
	/** Liste des ordonnances filtrées suivant les actes de la séance. */
	public excludeOrdonnancesIds: string[];
	public readonly actesByOrdonnanceId = new Map<string, string[]>();
	/** Observation de la séance */
	public observation: Observation;

	public get seances(): Seance[] {
		return [...this.maOtherSeances, this.seance];
	}

	public get canEdit(): boolean {
		return !this.isLockedSeance && this.isIntervenant;
	}

	public get isIntervenant(): boolean {
		return this.maIntervenantIds.includes(this.msUserContactId);
	}

	public get seanceStateAvatar(): IAvatar {
		return {
			icon: TourneesService.getStatusIcon(this.seanceTournee),
			size: EAvatarSize.medium,
			color: TourneesService.getStatusIcon(this.seanceTournee) === ESeanceStatusIcon.done ? "success" : undefined
		};
	}

	/** Retourne la classe css à appliquer sur le badge d'export de la séance. */
	public get exportSeanceBadgeClass(): string {
		let lsClass: string;

		if (this.seance.status === EStatusSeance.inProgress)
			lsClass = "partial-exported";
		else if (this.seance.status === EStatusSeance.completed)
			lsClass = "exported";
		else
			lsClass = "not-exported";

		return lsClass;
	}

	/** Retourne `true` si l'utilisateur peut exporter la séance (doit être intervenant et la séance doit être validée). */
	public get canExport(): boolean {
		return this.isIntervenant &&
			this.seance.status && this.seance.status !== EStatusSeance.pending && this.seance.status !== EStatusSeance.canceled;
	}

	public readonly emptyAvatar: IAvatar = SeanceService.emptyAvatar;

	@Input() public seanceTournee: ISeanceTournee;
	@Input() public traitement: Traitement;
	/** Indique si l'on doit déplacer la séance à l'ouverture. */
	@Input() public moveSeance: boolean;
	/** Indique si l'on doit changer le status de la séance à l'ouverture. */
	@Input() public changeStatus: boolean;
	@ViewChild(MatAccordion) public accordion: MatAccordion;
	@ViewChild(ValidateActesComponent) public validateActesComp: ValidateActesComponent;
	@ViewChild("actsPanel") public actsPanel: MatExpansionPanel;

	public ordonnances: Ordonnance[] = [];
	public pendingInvoices: Invoice[] = [];

	public showBillBtn: boolean;

	public get canBillToFsv(): boolean {
		return true;
	}

	//#endregion

	//#region METHODS

	constructor(
		public readonly isvcPermissions: PermissionsService,
		private readonly isvcSeance: SeanceService,
		private readonly isvcLoading: LoadingService,
		private readonly isvcPatients: PatientsService,
		private readonly isvcTraitement: TraitementService,
		private readonly isvcRapport: RapportService,
		private readonly isvcPrestation: PrestationService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcConstantes: ConstantesService,
		private readonly isvcGroups: GroupsService,
		private readonly isvcObservations: ObservationsService,
		private readonly isvcOrdonnances: OrdonnancesService,
		private readonly ioContactNamePipe: ContactNamePipe,
		private readonly ioRouter: Router,
		private readonly isvcFacturation: FacturationService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetector);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		this.initSeance();

		let loLoader: Loader;

		defer(() => this.isvcLoading.create("Chargement des données ..."))
			.pipe(
				mergeMap((poLoader: Loader) => poLoader.present()),
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap(() => combineLatest([this.initIntervenantsIds(), this.initSeances(), this.initPendingInvoices()])),
				tap(() => {
					this.filterOrdonnances();
					this.detectChanges();
				}),
				mergeMap(() => loLoader.dismiss()),
				finalize(() => loLoader?.dismiss()),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.transmissionsListParams = {
			model: this.seanceTournee.patient.groupMember,
			hideSearchbar: true,
			hideToolbar: true,
			openClickedItemAsModal: true,
			hideFilterbar: true,
			nbItems: 5
		};

		this.constantesListParams = {
			model: this.seanceTournee.patient.groupMember,
			hideToolbar: true,
			hideSearchbar: true,
			openClickedItemAsModal: true,
			nbItems: 5
		} as IConstantesListParams;
	}

	/** Enregistre les séances et leurs ordonnances.
	 * @param pbLastSave Indique si cette sauvegarde est la dernière.
	*/
	@Queue<SeanceModalComponent, Parameters<SeanceModalComponent["save"]>, ReturnType<SeanceModalComponent["save"]>>({
		paramsReducer: (_, paNewArgs: [boolean]) => paNewArgs,
		shouldFinishQueue: (_, pbLastSave: boolean) => pbLastSave,
		onQueueFinished: (poThis: SeanceModalComponent) => {
			console.debug("SEANCE.C:: Fermeture du canal de mise à jour auto.");
			if (poThis.moSavingLoader)
				poThis.moSavingLoader.dismiss();

			const loResponse: ISeanceModalResponse = {
				seances: poThis.seances,
				traitementBreakDate: poThis.mdTraitementBreakDate,
				explanation: poThis.msExplanation,
				traitementChanged: poThis.mbTraitementChanged
			};

			poThis.ioModalCtrl.dismiss(loResponse);
		}
	})
	private save(pbLastSave?: boolean): Observable<Traitement> {
		this.mnSavingCount++;

		if (pbLastSave)
			return of(this.traitement);

		return this.prepareTraitement(this.seances, false)
			.pipe(
				mergeMap((poTraitement: Traitement) => this.isvcTraitement.saveTraitement(poTraitement).pipe(mapTo(poTraitement))),
				tap(() => {
					this.mnSavingCount--;
					this.detectChanges();
				})
			);
	}

	private prepareTraitement(paSeances: Seance[], pbGetObservations?: boolean): Observable<Traitement> {
		return this.getTraitement(this.seance, pbGetObservations)
			.pipe(
				mergeMap((poTraitement: Traitement) => {
					const ldNewBeginDate: Date = this.isvcSeance.getSeancesMinDate(paSeances);
					const ldNewEndDate: Date = this.isvcSeance.getSeancesMaxDate(paSeances);

					if (+ldNewBeginDate !== +poTraitement.beginDate) {
						poTraitement.beginDate = ldNewBeginDate;
						this.makeTraitementDirty(poTraitement);
					}

					if (+ldNewEndDate !== +poTraitement.endDate) {
						poTraitement.endDate = ldNewEndDate;
						this.makeTraitementDirty(poTraitement);
					}

					if (this.mdTraitementBreakDate)
						return this.isvcTraitement.stopTraitement(poTraitement, paSeances, this.mdTraitementBreakDate, this.msExplanation);
					return of(poTraitement);
				})
			);
	}

	private makeTraitementDirty(poTraitement: Traitement): void {
		this.mbTraitementChanged = true;
		StoreHelper.makeDocumentDirty(poTraitement);
	}

	public onTraitementChanged(poTraitement: Traitement): void {
		this.makeTraitementDirty(poTraitement);
		this.save().subscribe();
	}

	public onObservationChanged(poObservation: Observation): void {
		this.isvcObservations.saveObservation(poObservation)
			.pipe(
				tap((poSavedObservation: Observation) => this.observation = poSavedObservation),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private initIntervenantsIds(): Observable<any> {
		const laGroups: IGroup[] = [];

		this.seanceTournee.intervenants.forEach((poIntervenant: IHydratedGroupMember) => {
			if (IdHelper.hasPrefixId(poIntervenant.groupMember._id, EPrefix.group))
				laGroups.push(poIntervenant.groupMember as IGroup);

			this.maIntervenantIds.push(poIntervenant.groupMember._id);
		});

		if (ArrayHelper.hasElements(laGroups)) {
			return this.isvcGroups.getGroupContactsIds(laGroups, undefined, true)
				.pipe(tap((poIntervenantIdsByGroupIds: IIndexedArray<string[]>) => {
					Object.keys(poIntervenantIdsByGroupIds).forEach((psKey: string) => {
						const laIntervenantIds: string[] = poIntervenantIdsByGroupIds[psKey];
						if (ArrayHelper.hasElements(laIntervenantIds))
							laIntervenantIds.forEach((psIntervenantId: string) => ArrayHelper.pushIfNotPresent(this.maIntervenantIds, psIntervenantId));
					});
				}));
		}

		return of(null);
	}

	private initSeances(): Observable<Seance[]> {
		return this.getSeances(this.seance)
			.pipe(
				tap((paSeances: Seance[]) => {
					this.applySeances(paSeances);

					if (this.moveSeance)
						this.validateActesComp.moveSeance();

					if (this.changeStatus)
						this.actsPanel.open();
					else
						this.accordion.openAll();
				}),
				takeUntil(this.destroyed$)
			);
	}

	private initSeance(): void {
		this.seance = this.seanceTournee.seance;
		this.isLockedSeance = this.seance.isProtected;
		this.showBillBtn = this.seance.validated;

		this.mbAlreadyDone = this.seance.status === EStatusSeance.done;
	}

	private initObservation(): Observable<Observation> {
		return this.isvcObservations.getEntityObservation(this.traitement._id, this.seance.startDate)
			.pipe(
				tap((poObservation: Observation) => {
					if (ObjectHelper.isNullOrEmpty(poObservation))
						this.observation = ObservationsService.createObservation(this.traitement._id, this.seance.startDate, "", UserHelper.getUserContactId());
					else
						this.observation = poObservation;
				})
			);
	}

	private initPendingInvoices(): Observable<Invoice[]> {
		return this.isvcFacturation.getPatientInvoicesPendingCashOut(this.seance.patientId)
			.pipe(
				tap((paInvoices: Invoice[]) => this.pendingInvoices = FacturationService.sortInvoices(paInvoices)),
				takeUntil(this.destroyed$)
			);
	}

	public goToInvoice(poInvoiceId: string): void {
		this.close();
		this.ioRouter.navigate(["/invoices", poInvoiceId]);
	}

	public onStartDateChanged(psNewISODate: string): void {
		const ldNewDate = new Date(psNewISODate);
		if (DateHelper.compareTwoDates(ldNewDate, this.seance.startDate) !== 0) {
			this.isvcSeance.selectSeancesForUpdateWithModal(this.seance, this.maOtherSeances, this.innerOnStartDateChanged_createChoices(ldNewDate), "Modification de la séance")
				.pipe(
					mergeMap((poResponse: IChooseSeancesToModifiyResponse) =>
						this.isvcTraitement.applyNewDateToSeances(ldNewDate, this.seance, poResponse, this.traitement)
					),
					tap(() => this.detectChanges()),
					mergeMap(() => this.save()),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
	}

	private innerOnStartDateChanged_createChoices(ldNewDate: Date): IChoice[] {
		const lnDiffDays: number = DateHelper.diffDays(ldNewDate, this.seance.startDate);
		const lnHours: number = ldNewDate.getHours();
		const lnMinutes: number = ldNewDate.getMinutes();
		const lnDiffHours: number = lnHours - this.seance.startDate.getHours();
		const lnDiffMinutes: number = lnMinutes - this.seance.startDate.getMinutes();
		const lbSameDay: boolean = lnDiffDays === 0;
		const lbSameTime: boolean = lnHours === this.seance.startDate.getHours() && lnMinutes === this.seance.startDate.getMinutes();
		const laChoices: IChoice[] = [];

		if (lbSameDay && this.seance.scheduled) {
			laChoices.push({
				label: `Décaler toutes les séances de ${DateHelper.transform(this.seance.startDate, ETimetablePattern.HH_mm)} à ${lnHours}:${lnMinutes}`,
				filters: [EChoiceFilter.sameTime, EChoiceFilter.future]
			});
		}
		else if (lbSameTime) {
			if (this.seance.scheduled)
				laChoices.push({ label: `Toutes les séances de ${DateHelper.transform(this.seance.startDate, ETimetablePattern.HH_mm)}`, filters: [EChoiceFilter.sameTime] });

			laChoices.push(
				{
					label: `Toutes les séances du ${DateHelper.transform(this.seance.startDate, ETimetablePattern.dd_MM_yyyy_slash)}`,
					filters: [EChoiceFilter.sameDay, EChoiceFilter.future]
				},
				{
					label: `Toutes les séances du ${DateHelper.transform(this.seance.startDate, ETimetablePattern.dd_MM_yyyy_slash)} et décaler de ${lnDiffDays} jour${lnDiffDays > 1 ? "s" : ""} les suivantes`,
					filters: [EChoiceFilter.future]
				}
			);
		}
		else if (this.seance.scheduled) {
			laChoices.push(
				{
					label: `Toutes les séances de ${DateHelper.transform(this.seance.startDate, ETimetablePattern.HH_mm)} des jours à venir`,
					filters: [EChoiceFilter.sameTime, EChoiceFilter.future]
				},
				{
					label: `Toutes les séances de ${DateHelper.transform(this.seance.startDate, ETimetablePattern.dd_MM_yyyy_slash)} et décaler de ${lnDiffHours}heures ${lnDiffMinutes}minutes les suivantes`,
					filters: [EChoiceFilter.sameDay, EChoiceFilter.future]
				},
				{
					label: `Toutes les séances de ${DateHelper.transform(this.seance.startDate, ETimetablePattern.dd_MM_yyyy_slash)} et décaler de ${lnDiffHours}heures ${lnDiffMinutes}minutes les suivantes et des jours à venir`,
					filters: [EChoiceFilter.future]
				}
			);
		}

		return laChoices;
	}

	public onSeanceMoved(pdNewDate: Date): void {
		this.onStartDateChanged(pdNewDate.toISOString());
		this.close();
	}

	public onSeancesChanged(paSeances: Seance[], pbBill?: boolean): void {
		this.applySeances(paSeances);

		if (this.seance.status === EStatusSeance.canceled)
			this.onSeanceCanceled(this.seance);

		else if (this.seance.status === EStatusSeance.done && !this.mbAlreadyDone) {
			this.save()
				.pipe(
					tap(() => {
						if (pbBill)
							this.goToFacturation(true);
						else
							this.close();
					})
				)
				.subscribe();
		}
	}

	public goToFacturation(pbClose: boolean = false): void {
		this.ioRouter.navigate(["facturation"], { queryParams: { traitementId: this.traitement._id } });
		if (pbClose)
			this.close();
	}

	/** @override */
	public async close(): Promise<boolean> {
		if (StoreHelper.isDocumentDirty(this.observation))
			this.save().subscribe();
		if (this.mnSavingCount > 0) {
			this.moSavingLoader = await this.isvcLoading.create(TraitementService.C_TRAITEMENT_SAVE_TEXT);
			await this.moSavingLoader.present();
		};
		this.save(true).subscribe();

		return true;
	}

	public addIntervenant(): void {
		this.isvcSeance.selectIntervenants(this.seance)
			.pipe(
				filter((paIntervenants: IIdelizyContact[]) =>
					!ArrayHelper.areArraysEqual(paIntervenants, this.seance.intervenantIds, (poIntervenant: IIdelizyContact, psIntervenantId: string) => poIntervenant._id === psIntervenantId)
				),
				mergeMap((paIntervenants: IIdelizyContact[]) => {
					this.maIntervenantIds = paIntervenants.map((poIntervenant: IIdelizyContact) => poIntervenant._id);

					return this.isvcSeance.selectSeancesForUpdateWithModal(this.seance, this.maOtherSeances, this.innerAddIntervenant_createChoices(), "Modifier l'intervenant")
						.pipe(
							tap(() => {
								this.isvcSeance.applyNewIntervenants(this.seanceTournee, paIntervenants);
								this.detectChanges();
							}),
							mergeMap((poResponse: IChooseSeancesToModifiyResponse) => this.isvcTraitement.changeSeancesWithConstraint(
								poResponse.seances,
								(poSeance: Seance) => this.isvcSeance.applyNewIntervenants(poSeance, paIntervenants),
								this.traitement,
								() => this.isvcSeance.getIntervenantConstraint(
									poResponse,
									this.seance,
									paIntervenants
								)
							)),
						);
				}),
				tap(
					_ => this.detectChanges(),
					(poError: any) => {
						if (poError instanceof OsappError) {
							this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: poError.message }));
							console.warn("TOUR.C::Problème lors de la sélection des intervenants: ", poError);
						}
						else
							console.error("TOUR.C::Erreur lors de la sélection des intervenants: ", poError);
					}
				),
				mergeMap(() => this.save()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private innerAddIntervenant_createChoices(): IChoice[] {
		const laChoices: IChoice[] = [];

		if (this.seance.scheduled)
			laChoices.push({
				label: `Toutes les séances de ${DateHelper.transform(this.seance.startDate, ETimetablePattern.HH_mm)}`,
				filters: [EChoiceFilter.sameTime, EChoiceFilter.future]
			});

		laChoices.push({
			label: `Toutes les séances des jours à venir`,
			filters: [EChoiceFilter.future]
		});

		return laChoices;
	}

	private applySeances(paSeances: Seance[]): void {
		this.maOtherSeances = paSeances;
		this.detectChanges();
	}

	private getSeances(poSeance: Seance): Observable<Seance[]> {
		return this.getTraitement(poSeance)
			.pipe(
				mergeMap((poTraitement: Traitement) => this.initObservation().pipe(mapTo(poTraitement))),
				mergeMap((poTraitement: Traitement) => ArrayHelper.hasElements(poTraitement.seances) ? poTraitement.seances$ : this.isvcSeance.generateSeances(
					poTraitement,
					this.seanceTournee.patient.groupMember,
					true,
					true
				)),
				map((paSeances: Seance[]) => ArrayHelper.hasElements(paSeances) ? paSeances : [poSeance])
			);
	}

	private getTraitement(poSeance: Seance, pbGetObservations: boolean = true): Observable<Traitement> {
		return defer(() => this.traitement ? of(this.traitement) : this.isvcTraitement.getTraitement(poSeance.traitementId))
			.pipe(
				tap((poTraitement: Traitement) => {
					if (!poTraitement) {
						this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: "Cette séance n'est liée à aucun traitement." }));
						this.close();
					}
					else
						this.traitement = poTraitement;
				}),
				filter((poTraitement: Traitement) => !!poTraitement),
				mergeMap(() => {
					if (pbGetObservations) {
						return this.isvcOrdonnances.getTraitementOrdonnances(this.traitement._id, true)
							.pipe(
								tap((paOrdonnances: Ordonnance[]) => this.ordonnances = paOrdonnances),
								mapTo(this.traitement)
							);
					}
					return of(this.traitement);
				})
			);
	}

	private filterOrdonnances(): Map<string, string[]> {
		const laOrdonnancesIds: string[] = [];
		const loOrdonnancesIdsByActeGuid: Map<string, string[]> = this.isvcOrdonnances.getOrdonnancesIdsByActeGuid(this.ordonnances);
		this.actesByOrdonnanceId.clear();
		let showNonLinkedOrdonnances = false;

		this.seance.actes.forEach((poActe: Acte) => { // Récupère les ordonnances liées aux actes de la séance.
			if (loOrdonnancesIdsByActeGuid.has(poActe.guid)) {
				loOrdonnancesIdsByActeGuid.get(poActe.guid).forEach((psOrdonnanceId: string) => { // On provisionne la map d'actes par id d'ordonnances
					const laActesGuids: string[] = this.actesByOrdonnanceId.get(psOrdonnanceId) ?? [];

					laOrdonnancesIds.push(psOrdonnanceId);
					laActesGuids.push(poActe.guid);
					this.actesByOrdonnanceId.set(psOrdonnanceId, laActesGuids);
				});
			}
			else
				showNonLinkedOrdonnances = true;
		});

		if (showNonLinkedOrdonnances) { // Récupère les ordonnances liées à aucun acte.
			const loActesByOrdonnanceId: Map<string, string[]> = this.isvcOrdonnances.getActesByOrdonnanceId(this.ordonnances);

			const laNonLinkedOrdonnancesIds: string[] = ArrayHelper.getDifferences(
				this.ordonnances?.map((poOrdonnance: Ordonnance) => poOrdonnance._id),
				MapHelper.keysToArray(loActesByOrdonnanceId).map((psOrdonnanceId: string) => psOrdonnanceId)
			);
			laOrdonnancesIds.push(...laNonLinkedOrdonnancesIds);
			return loActesByOrdonnanceId;
		}

		this.excludeOrdonnancesIds = ArrayHelper.getDifferences(this.ordonnances?.map((poOrdonnance: Ordonnance) => poOrdonnance._id), laOrdonnancesIds);

		return null;
	}

	public addAct(): void {
		this.close().then(() => this.isvcTraitement.openTraitement(this.traitement._id, [TraitementSlideParentBase.C_ADD_ACTE_ACTION_ID]));
	}

	public onSeanceCanceled(poSeance: Seance): void {
		this.isvcSeance.selectSeancesForUpdateWithModal(poSeance, this.maOtherSeances, this.innerOnSeanceCanceled_createChoices(), "Annuler la séance")
			.pipe(
				mergeMap((poResponse: IChooseSeancesToModifiyResponse) => {
					ArrayHelper.removeElement(poResponse.seances, poSeance); // On exclut la séance car elle est déjà annulée.

					return this.isvcTraitement.changeSeancesWithConstraint(
						poResponse.seances,
						(poSeanceToChange: Seance) => this.isvcSeance.cancelSeance(poSeanceToChange, this.traitement),
						this.traitement,
						() => this.isvcSeance.getConstraint(
							CancelConstraint,
							poResponse,
							poSeance
						)
					);
				}),
				mergeMap(() => {
					this.seance = poSeance;
					return this.save();
				}),
				tap(() => this.close()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private innerOnSeanceCanceled_createChoices(): IChoice[] {
		const laChoices: IChoice[] = [];

		laChoices.push(
			{
				label: `Toutes les séances des jours à venir`,
				filters: [EChoiceFilter.future]
			},
			{
				label: `Toutes les séances du ${DateHelper.transform(this.seance.startDate, ETimetablePattern.dd_MM_yyyy_slash)}`,
				filters: [EChoiceFilter.sameDay, EChoiceFilter.future]
			}
		);

		if (this.seance.scheduled)
			laChoices.push({
				label: `Toutes les séances de ${DateHelper.transform(this.seance.startDate, ETimetablePattern.HH_mm)}`,
				filters: [EChoiceFilter.sameTime, EChoiceFilter.future]
			});

		return laChoices;
	}

	/** Permet de naviguer vers le patient lié à la séance. */
	public goToPatient(): void {
		if (this.seanceTournee.patient) {
			this.close();
			this.isvcPatients.goToPatient(this.seanceTournee.patient.groupMember._id);
		}
	}

	/** Crée une nouvelle transmission en ouvrant une modale. */
	public createTransmission(): void {
		this.isvcRapport.openTransmissionAsModal(TransmissionModalComponent, { patientModel: this.seanceTournee.patient.groupMember, linkedEntities: [this.traitement] })
			.pipe(takeUntil(this.destroyed$))
			.subscribe();
	}

	public getSeanceStateLabel(psIcon: string): string {
		return TourneesService.getStatusLabelFromIcon(psIcon);
	}

	public exportActes(): void {
		this.isvcPrestation.navigateToPrestation(this.seance.startDate, this.seance.endDate);
	}

	/** Ouvre le sélecteur de dat pour modifier la date et l'heure de début de la séance.
	 * @param poDateTimePicker Composant de changement de date et heure.
	 */
	public onChooseSeanceDateTime(poDateTimePicker: DateTimePickerComponent): void {
		poDateTimePicker.pickDate();
	}

	/** Ouvre une popup pour choisir le type de surveillance à créer. */
	public chooseSurveillanceType(): void {
		this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
			header: "Quel type de surveillance voulez-vous créer ?",
			buttons: [
				{
					text: "Une constante", handler: () => this.createSurveillance(C_CONSTANTES_PREFIX)
				},
				{
					text: "Un dosage", handler: () => this.createSurveillance(C_INJECTIONS_PREFIX)
				},
				{ text: "Annuler", role: "cancel" },
			]
		}));
	}

	/** Crée un nouvel objet constantes en ouvrant une modale. */
	private createSurveillance(pePrefix: EPrefix): void {
		this.isvcConstantes.openASurveillancesAsModal(
			ConstantesModalComponent,
			{ patientModel: this.seanceTournee.patient.groupMember, surveillancesPrefix: pePrefix, linkedEntities: [this.traitement] } as IConstantesModalParams
		)
			.pipe(takeUntil(this.destroyed$))
			.subscribe();
	}

	/** Action du bouton des conversations executée, on ferme la modale si une conversation a été créée.
	 * @param pbEvent Événement qui indique si l'action du bouton s'est bien passée.
	 */
	public onActionconversationDone(pbEvent: boolean): void {
		if (pbEvent)
			this.close();
	}

	public createOrdonnance(): void {
		this.close().then(() => this.isvcTraitement.openTraitement(this.traitement._id, [TraitementSlideParentBase.C_ADD_ORDONNANCE_ACTION_ID]));
	}

	public getIntervenantsNames(): string {
		if (ArrayHelper.hasElements(this.seanceTournee.intervenants)) {
			return this.seanceTournee.intervenants.map((poItem: IHydratedGroupMember<IContact | IGroup>) => {
				if (poItem.groupMember._id.startsWith(EPrefix.contact))
					return this.ioContactNamePipe.transform(poItem.groupMember as IContact);
				else
					return (poItem.groupMember as IGroup).name;
			}).join(", ");
		}
		return "";
	}

	//#endregion

}