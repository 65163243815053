import { Entity } from '@osapp/model/entities/Entity';
import { EAvatarSize } from '@osapp/model/picture/EAvatarSize';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { EntityBuilder } from '@osapp/services/EntityBuilder';
import { IDemandePreparationMedicaments } from 'apps/idl/src/model/IDemandePreparationMedicaments';
import { C_PREFIX_PHARMACIE } from '../app/app.constants';

export class DemandePreparationMedicamentsEntityBuilder extends EntityBuilder<IDemandePreparationMedicaments>{
	constructor() {
		super(
			(psModelId: string) => RegExp(C_PREFIX_PHARMACIE).test(psModelId),
			(poDemande: IDemandePreparationMedicaments) => new Entity(
				poDemande,
				`pharmas/${poDemande._id}/edit`,
				() => "Demande de préparation"
			),
			(psModelId: string) => "Demandes de préparation",
			EDatabaseRole.workspace,
			C_PREFIX_PHARMACIE,
			() => ({
				size: EAvatarSize.medium,
				icon: "clipboard"
			})
		);
	}
}