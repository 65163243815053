import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DmsModule } from '@osapp/modules/dms/dms.module';
import { CONV_META_CONFIG } from '@osapp/modules/dms/model/MetaConversationBuilder';
import { MetaConstantesBuilder } from './model/MetaConstantesBuilder';
import { MetaInvoiceBuilder } from './model/MetaInvoiceBuilder';
import { MetaTourneeBuilder } from './model/MetaTourneeBuilder';
import { MetaTraitementBuilder } from './model/MetaTraitementBuilder';
import { MetaTransmissionBuilder } from './model/MetaTransmissionBuilder';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		DmsModule.forRoot([
			MetaTraitementBuilder,
			MetaTransmissionBuilder,
			MetaTourneeBuilder,
			MetaInvoiceBuilder
		])
	],
	providers: [
		MetaTraitementBuilder,
		MetaTransmissionBuilder,
		MetaConstantesBuilder,
		MetaTourneeBuilder,
		MetaInvoiceBuilder,
		{
			provide: CONV_META_CONFIG,
			useValue: [
				MetaTransmissionBuilder,
				MetaTraitementBuilder,
				MetaConstantesBuilder,
				MetaTourneeBuilder,
				MetaInvoiceBuilder
			]
		}
	]
})
export class IdlDmsModule { }
