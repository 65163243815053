import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DateModule } from '../../components/date/date.module';
import { SelectorModule } from '../../modules/selector/selector.module';
import { PipeModule } from '../../pipes/pipes.module';
import { CalaoAvatarModule } from '../avatar/calao-avatar.module';
import { FilterbarContactAvatarComponent } from './components/filterbar-contact-avatar/filterbar-contact-avatar.component';
import { FilterbarDateRangePickerComponent } from './components/filterbar-date-range-picker/filterbar-date-range-picker.component';
import { FilterbarDatepickerComponent } from './components/filterbar-datepicker/filterbar-datepicker.component';
import { FilterbarTagsComponent } from './components/filterbar-tags/filterbar-tags/filterbar-tags.component';
import { FilterbarComponent } from './components/filterbar/filterbar.component';

const modules: Type<any>[] = [
	CommonModule,
	IonicModule,
	CalaoAvatarModule,
	DateModule,
	PipeModule,
	SelectorModule
];
const components: Type<any>[] = [
	FilterbarComponent,
	FilterbarContactAvatarComponent,
	FilterbarDatepickerComponent,
	FilterbarTagsComponent,
	FilterbarDateRangePickerComponent
];

@NgModule({
	imports: modules,
	declarations: components,
	exports: components
})
export class FilterModule { }