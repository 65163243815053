import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ISelectOption } from 'libs/osapp/src/modules/selector/selector/ISelectOption';
import { SelectorComponent } from 'libs/osapp/src/modules/selector/selector/selector.component';
import { Observable, of } from 'rxjs';
import { FilterbarBaseComponent } from '../../../model/FilterbarBaseComponent';

@Component({
	selector: 'osapp-filterbar-tags',
	templateUrl: './filterbar-tags.component.html',
	styleUrls: ['./filterbar-tags.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterbarTagsComponent<T = string, U = string> extends FilterbarBaseComponent<T, U> {

	//#region FIELDS

	/** Composant osapp-selector. */
	@ViewChild(SelectorComponent) private moSelectorComponent: SelectorComponent;

	//#endregion

	//#region PROPERTIES

	/** Les différents tags à afficher. */
	public selectOptions: ISelectOption[] = [];
	/** Les tags sélectionnés */
	public selectedValues: string[];

	private maTags: { label: string, value: string }[];
	public get tags(): { label: string, value: string }[] { return this.maTags; }
	@Input() public set tags(paNewTags: { label: string, value: string }[]) {
		if (paNewTags) {
			this.maTags = paNewTags;
			this.selectOptions = this.maTags;
		}
	}

	@Output() private readonly onTagClicked = new EventEmitter();

	//#endregion

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	protected transform(poValue: T): U;
	protected transform(paValues: T[]): U[];
	/** @override */
	protected transform(paValues: T | T[]): U | U[] {
		if (paValues instanceof Array)
			return paValues as unknown as U[];
		else
			return paValues as unknown as U;
	}

	/** @override */
	public action(): Observable<T[]> {
		return of(this.selectedValues as unknown as T[]);
	}

	public selectionChanged(paSelectedValues: string[]) {
		this.selectedValues = paSelectedValues;
		this.onTagClicked.emit();
	}

	public resetSelection(): void {
		this.moSelectorComponent.resetSelection();
	}

	//#endregion
}
