import { Component, Input, Type } from '@angular/core';
import { Router } from '@angular/router';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { mergeMap } from 'rxjs/internal/operators';
import { IPatient } from '../../../../../modules/patients/model/IPatient';
import { ConfirmationSuppressionComponent } from '../../../shared/components/confirmation-suppression/confirmation-suppression.component';
import { PanneauPatientAllergiesComponent } from '../../../shared/components/panneaux/panneau-patient-allergies/panneau-patient-allergies.component';
import { PanneauPatientAmoComponent } from '../../../shared/components/panneaux/panneau-patient-amo/panneau-patient-amo.component';
import { PanneauPatientDecesComponent } from '../../../shared/components/panneaux/panneau-patient-deces/panneau-patient-deces.component';
import { PanneauPatientPriseEnChargeComponent } from '../../../shared/components/panneaux/panneau-patient-prise-en-charge/panneau-patient-prise-en-charge.component';
import { DrawerPopoverService } from '../../../shared/services/drawer-popover.service';
import { GeocodingService } from '../../../shared/services/geocoding.service';
import { PanneauService } from '../../../shared/services/panneau.service';

@Component({
	selector: 'di-menu-patient',
	templateUrl: './menu-patient.component.html',
	styleUrls: ['./menu-patient.component.scss'],
})
export class MenuPatientComponent {

	@Input() patient: IPatient;

	public panneauTitle = "";
	public panneauContent: Type<any>;
	public panneauInputs: any;

	constructor(
		private router: Router,
		private svcPanneau: PanneauService,
		private svcPatient: PatientsService,
		private svcDrawerPopover: DrawerPopoverService,
		private svcGeocoding : GeocodingService,
	) { }

	public editPatient() {
		this.router.navigate(["patients", "edit", this.patient._id])
		this.svcDrawerPopover.close();
	}

	public openPanneauPriseEnChargeSpecifique(): void {
		this.panneauTitle = "Prise en charge spécifique";
		this.panneauContent = PanneauPatientPriseEnChargeComponent
		this.panneauInputs = {
			patient: this.patient
		}
		this.openPanneau();
		this.svcDrawerPopover.close();
	}

	public openPanneauAllergies(): void {
		this.panneauTitle = "Allergies, antécédents";
		this.panneauContent = PanneauPatientAllergiesComponent
		this.panneauInputs = {
			patient: this.patient
		}
		this.openPanneau();
		this.svcDrawerPopover.close();
	}

	public openPanneauDeces(): void {
		this.panneauTitle = "Patient décédé";
		this.panneauContent = PanneauPatientDecesComponent
		this.panneauInputs = {
			patient: this.patient
		}
		this.openPanneau();
		this.svcDrawerPopover.close();
	}

	public openPanneauSecuriteSociale(): void {
		this.panneauTitle = "Assurance maladie obligatoire"
		this.panneauContent = PanneauPatientAmoComponent
		this.panneauInputs = {
			patient: this.patient,
			carteVitale: true
		}
		this.openPanneau();
		this.svcDrawerPopover.close();
	}

	public openPanneau() {
		this.svcPanneau.open(this.panneauTitle, this.panneauContent, this.panneauInputs);
		this.svcDrawerPopover.close();
	}

	public navigatePatient() {
		this.svcDrawerPopover.close();
		const address = `${this.patient.street} ${this.patient.zipCode} ${this.patient.city}`;
    this.svcGeocoding.getGeolocation(address).pipe(
			mergeMap(coords => {
        return this.svcGeocoding.navigateToCoordinates(coords.lat, coords.lng);
      })
    ).subscribe();		
	}

	public emailPatient() {
		window.location.href = `mailto:${this.patient.email}`;
		this.svcDrawerPopover.close();
	}

	public phonePatient() {
		window.location.href = `tel:${this.patient.phone}`;
		this.svcDrawerPopover.close();
	}

	public deletePatient() {
		this.svcDrawerPopover.open("Confirmation de suppression", "30%", null, ConfirmationSuppressionComponent, {
			onConfirm: () => this.handleConfirm(),
			onCancel: () => this.handleCancel()
		})
	}

	private handleConfirm() {
		this.svcPatient.deletePatientAnakin(this.patient).subscribe((result: boolean) => {
			if (result) {
				this.router.navigate(["patients"])
			} else {
				console.log(`Erreur lors de la suppression du patient ${this.patient.firstName} ${this.patient.lastName}`)
			}
		})
		this.svcDrawerPopover.close();
	}

	private handleCancel() {
		this.svcDrawerPopover.close();
	}

}