import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { IDateRange } from '@osapp/components/date/date-range-picker/model/IDateRange';
import { MapHelper } from '@osapp/helpers';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { EnumHelper } from '@osapp/helpers/enumHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { IContact } from '@osapp/model/contacts/IContact';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { IAvatar } from '@osapp/model/picture/IAvatar';
import { ERouteUrlPart } from '@osapp/model/route/ERouteUrlPart';
import { ISearchOptions } from '@osapp/model/search/ISearchOptions';
import { IFilterValuesChangedEvent } from '@osapp/modules/filter/model/IFilterValuesChangedEvent';
import { IFilterbarParams } from '@osapp/modules/filter/model/IFilterbarParams';
import { Loader } from '@osapp/modules/loading/Loader';
import { EPrestationStatus } from '@osapp/modules/prestation/models/eprestation-status.enum';
import { IPrestationLine } from '@osapp/modules/prestation/models/iprestation-line';
import { PrestationLine } from '@osapp/modules/prestation/models/prestation-line';
import { PrestationService } from '@osapp/modules/prestation/services/prestation.service';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { ContactsService } from '@osapp/services/contacts.service';
import { LoadingService } from '@osapp/services/loading.service';
import { GroupedObservable, Observable, defer, from } from 'rxjs';
import { defaultIfEmpty, finalize, groupBy, map, mergeMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { C_PREFIX_PATIENT } from '../../../app/app.constants';
import { Acte } from '../../../model/Acte';
import { IIdelizyContact } from '../../../model/IIdelizyContact';
import { IMajorationDetails } from '../../../model/IMajorationDetails';
import { ISeancesExportRow } from '../../../model/ISeancesExportRow';
import { ITraitement } from '../../../model/ITraitement';
import { Traitement } from '../../../model/Traitement';
import { TraitementDatesPipe } from '../../../pipes/traitementDates.pipe';
import { SeanceService } from '../../../services/seance.service';
import { TraitementService } from '../../../services/traitement.service';
import { ActesService } from '../../actes/actes.service';
import { IPatient } from '../../patients/model/IPatient';
import { PatientsService } from '../../patients/services/patients.service';
import { EIndemniteType } from '../../traitement/model/EIndemniteType';
import { ETraitementTags } from '../../traitement/model/ETraitementTags';
import { MajorationsComponent } from '../../traitement/slides-traitement/majorations/majorations.component';
import { FacturationService } from '../facturation.service';
import { EIdlPrestationLineCategory } from '../models/eidl-prestation-line-category.enum';
import { IdlPrestation } from '../models/idl-prestation';
import { IdlPrestationLine } from '../models/idl-prestation-line';
import { IPaginatorInfos } from '../models/ipaginator-infos';

@Component({ template: "" })
export abstract class PrestationsListComponentBase extends ComponentBase implements OnInit {

	//#region FIELDS

	/** Tableau des patients sélectionnés pour le filtrage. */
	private maFilterSelectedPatients: IPatient[] = [];
	/** Tableau des contacts intervenants sélectionnés pour le filtrage. */
	private maFilterSelectedIntervenants: IIdelizyContact[] = [];
	/** Tableau des autres filtres possibles, ici 'Acte non défini' ou 'Observations'. */
	private maFilterSelectedOthers: string[] = [];
	/** Tableau des états des prestations à afficher. */
	private maSelectedStatus: EPrestationStatus[];

	/** Tableau de tous les tags traitement. */
	private maTraitementTagsKeys: string[] = EnumHelper.getKeys(ETraitementTags);
	/** Map associant un id de patient avec un patient. */
	private moPatientsById = new Map<string, IPatient>();
	/** Tableau de toutes les prestations. */
	private maAllPrestations: IdlPrestation[] = [];
	/** Tableau de tous les patients filtrés. */
	private maFilteredPatients: IPatient[] = [];

	//#endregion

	//#region PROPERTIES

	/** Map associant un id de patient avec ses documents de facturation. */
	public prestationsByPatientId = new Map<string, IdlPrestation[]>();
	/** Map associant un id de contact avec un contact. */
	public contactsById = new Map<string, IContact>();

	/** Tableau de tous les patients. */
	public patients: IPatient[] = [];
	/** Tableau des patients qu'il faut afficher. */
	public displayedPatients: IPatient[] = [];
	/** Factures triées par traitement et patient. */
	public prestationsByTraitemetIdByPatientId = new Map<string, Map<string, IdlPrestation[]>>();
	/** Map associant un id de traitement avec un traitement. */
	public traitementsById = new Map<string, Traitement>();

	/** Valeur recherchée dans l'input de recherche. */
	public searchValue: string;
	/** Options de recherche. */
	public searchOptions: ISearchOptions<IPatient> = {
		hasPreFillData: true,
		searchboxPlaceholder: "Rechercher un patient",
		searchFunction: (poPatient: IPatient, psSearchValue: string) => this.isvcFacturation.patientMatchSearchValue(psSearchValue, poPatient)
	};
	/** Paramètres pour la barre de filtrage. */
	public filterbarParams: IFilterbarParams;
	/** Intervalle de dates sélectionnée pour le filtrage. */
	public filterRangeDates?: IDateRange;
	/** Nombre de filtres en cours. */
	public filtersCount: number;

	public readonly seanceStatusOptions: ReadonlyArray<ISelectOption<EPrestationStatus>> = [
		{ label: "Réalisé", value: EPrestationStatus.created },
		{ label: "Annulé", value: EPrestationStatus.canceled },
		{ label: "Vérifié", value: EPrestationStatus.checked },
		{ label: "Bloqué", value: EPrestationStatus.locked }
	];
	public readonly preselectedPrestationStatus: EPrestationStatus[] = [EPrestationStatus.created, EPrestationStatus.canceled, EPrestationStatus.checked, EPrestationStatus.locked];

	/** Composants de pagination. */
	@ViewChild("paginator") public paginator: MatPaginator;
	public nbItemsByPage = 10;
	public startIndex = 0;

	public discounRateOptions: { value: number, label: string }[] = [
		{ value: 0, label: "0%" },
		{ value: 0.5, label: "50%" },
		{ value: 100, label: "100%" }
	];

	/** Map des détails des majorations, indexé par valeur de l'enum MajorationType (string représentant un type de majoration). */
	public majorationsDetails: IMajorationDetails[] = MajorationsComponent.C_MAJORATION_DETAILS;

	public indemniteOptions: string[] = EnumHelper.getValues(EIndemniteType);
	public IKTypes: EIndemniteType[] = [EIndemniteType.IKM, EIndemniteType.IK, EIndemniteType.IKS];

	public static readonly C_SYNTHESE_TAB_NAME = "synthese";

	//#endregion

	//#region METHODS

	constructor(
		public readonly isvcFacturation: FacturationService,
		public readonly isvcPrestation: PrestationService,
		public readonly isvcPatients: PatientsService,
		public readonly isvcContacts: ContactsService,
		public readonly isvcTraitement: TraitementService,
		public readonly isvcLoading: LoadingService,
		public readonly isvcSeance: SeanceService,
		public readonly isvcActes: ActesService,
		public readonly ioTraitementsDatesPipe: TraitementDatesPipe,
		public readonly ioRouter: Router,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);
	}

	public ngOnInit(): void {
		this.initFilterbarParams();
	}

	public init(pbOnlySentPrestations?: boolean): Observable<IContact[]> {
		this.prestationsByTraitemetIdByPatientId = new Map();
		this.prestationsByPatientId = new Map();
		this.maFilteredPatients = [];
		const loVendorIds = new Set<string>();

		return this.isvcPrestation.getPrestations({ customerIdOrPrefix: C_PREFIX_PATIENT })
			.pipe(
				map((paPrestations: IdlPrestation[]) => {
					this.maAllPrestations = paPrestations.filter((poPrestation: IdlPrestation) => {
						if (pbOnlySentPrestations)
							return poPrestation.status === EPrestationStatus.sent;
						else
							return poPrestation.status !== EPrestationStatus.sent;
					});
					this.patients = [];
					return this.maAllPrestations;
				}),
				tap((paPrestations: IdlPrestation[]) => {
					paPrestations.forEach((poPrestation: IdlPrestation) => {
						const lsCustomerId: string = poPrestation.customerId;
						const laPatientPrestations: IdlPrestation[] = this.prestationsByPatientId.get(lsCustomerId) ?? [];
						laPatientPrestations.push(poPrestation);
						this.prestationsByPatientId.set(lsCustomerId, laPatientPrestations);
						loVendorIds.add(poPrestation.vendorId);
					});
				}),
				mergeMap(() => this.isvcPatients.getPatientsByIds(MapHelper.keysToArray(this.prestationsByPatientId))),
				tap((paPatients: IPatient[]) => {
					this.patients = paPatients;
					this.moPatientsById.clear();
					this.patients.forEach((poPatient: IPatient) => this.moPatientsById.set(poPatient._id, poPatient));
				}),
				mergeMap(() => this.isvcContacts.getContactsByIds(Array.from(loVendorIds))),
				tap((paIntervenants: IContact[]) => {
					paIntervenants.forEach((poIntervenant: IContact) => this.contactsById.set(poIntervenant._id, poIntervenant));
					this.onFilteredPatientsChanged(Array.from(this.patients.sort((poPatientA: IPatient, poPatientB: IPatient) => poPatientA.lastName.toLowerCase().localeCompare(poPatientB.lastName.toLowerCase()))));
					this.detectChanges();
				})
			);
	}

	public onPanelOpened(poPatient: IPatient): void {
		let loLoader: Loader;
		const laPrestations: IdlPrestation[] = this.prestationsByPatientId.get(poPatient._id);

		if (this.prestationsByTraitemetIdByPatientId.get(poPatient._id) === undefined)
			defer(() => this.isvcLoading.create("Chargement des factures en cours..."))
				.pipe(
					mergeMap((poLoader: Loader) => poLoader.present()),
					tap((poLoader: Loader) => loLoader = poLoader),
					mergeMap(() => from(laPrestations)
						.pipe(
							groupBy((poPrestation: IdlPrestation) => Traitement.createId(poPrestation.customerId, poPrestation.siteId, IdHelper.getLastGuidFromId(IdHelper.extractParentId(poPrestation._id)))),
							mergeMap((poGroupedPrestation: GroupedObservable<string, IdlPrestation>) => poGroupedPrestation.pipe(
								toArray(),
								mergeMap((paPrestations: IdlPrestation[]) => this.isvcTraitement.getTraitement(poGroupedPrestation.key)
									.pipe(
										defaultIfEmpty(null),
										tap((poTraitement: Traitement) => {
											this.traitementsById.set(poGroupedPrestation.key, poTraitement);
											if (!this.prestationsByTraitemetIdByPatientId.has(poPatient._id))
												this.prestationsByTraitemetIdByPatientId.set(poPatient._id, new Map());

											const laPreviousPrestations: IdlPrestation[] = this.prestationsByTraitemetIdByPatientId.get(poPatient._id).get(poGroupedPrestation.key) ?? [];
											this.prestationsByTraitemetIdByPatientId.get(poPatient._id).set(poGroupedPrestation.key, [...laPreviousPrestations, ...paPrestations]);
										})
									)
								)
							))
						)
					),
					tap(() => this.detectChanges()),
					mergeMap(() => loLoader.dismiss()),
					finalize(() => loLoader?.dismiss()),
					takeUntil(this.destroyed$)
				)
				.subscribe();
	}

	public savePrestations(paPrestations: IdlPrestation[], psLogId: string): Observable<IdlPrestation[]> {
		return this.isvcPrestation.savePrestations(paPrestations)
			.pipe(
				tap(
					() => this.detectChanges(),
					(poError: any) => console.error(`${psLogId}Erreur lors de la sauvegarde des prestations.`, paPrestations, poError)
				),
				takeUntil(this.destroyed$)
			) as Observable<IdlPrestation[]>;
	}

	public getPatientPrestationsByTraitement(poPatient: IPatient): Map<string, IdlPrestation[]> {
		return this.prestationsByTraitemetIdByPatientId.get(poPatient._id);
	}

	public getFilteredPrestations(paPrestations: IdlPrestation[]): IdlPrestation[] {
		let laPrestations: IdlPrestation[] = paPrestations;
		laPrestations = this.applyFilterValues(laPrestations);
		return ArrayHelper.hasElements(laPrestations) ? laPrestations : undefined;
	}

	public getPatientFilteredPrestations(psPatientId: string): IdlPrestation[] {
		return this.getFilteredPrestations(this.prestationsByPatientId.get(psPatientId));
	}

	public getActesLines(poPrestation: IdlPrestation): PrestationLine[] {
		return poPrestation.lines.filter((poLine: PrestationLine) => poLine.category === EIdlPrestationLineCategory.actes);
	}

	public getMajorationsAttributeFromLines(poLines: PrestationLine[], psAttribute: string): any {
		const laMajoLines: PrestationLine[] = poLines.filter((poLine: PrestationLine) => poLine.category === EIdlPrestationLineCategory.majorations);
		return this.majorationsDetails
			.filter((poMajDetail: IMajorationDetails) => laMajoLines.map((poLine: IdlPrestationLine) => poLine.lettreCle).includes(poMajDetail.valueType))
			.map((poMajDetail: IMajorationDetails) => poMajDetail[psAttribute]);
	}

	public getIndemnitesAttributeFromLines(poLines: PrestationLine[], psAttribute: string): any {
		const laMajoLines: PrestationLine[] = poLines.filter((poLine: PrestationLine) => poLine.category === EIdlPrestationLineCategory.indemnites);
		return laMajoLines.map((poLine: PrestationLine) => poLine[psAttribute]);
	}

	public getOriginalsMajorations(poPrestation: IdlPrestation): { shortLabel: string, deleted?: boolean }[] {
		const laOrignalsMajorations: string[] = this.getMajorationsAttributeFromLines(poPrestation.originalLines, "shortLabel");
		const laCurrentMajorations: string[] = this.getMajorationsAttributeFromLines(poPrestation.lines, "shortLabel");

		const loRes: { shortLabel: string, deleted?: boolean }[] = [];
		if (laOrignalsMajorations.length > 0) {
			laOrignalsMajorations.forEach((psLabel: string) => {
				const loMajo: { shortLabel: string, deleted?: boolean } = { shortLabel: psLabel };
				loMajo.deleted = !laCurrentMajorations.includes(psLabel);
				loRes.push(loMajo);
			});
		}
		else
			loRes.push({ shortLabel: "Aucune" });

		return loRes;
	}

	public getOriginalsIndemnites(poPrestation: IdlPrestation): { label: string, deleted?: boolean }[] {
		const laOrignalsIndemnites: string[] = this.getIndemnitesAttributeFromLines(poPrestation.originalLines, "ref");
		const laCurrentIndemnites: string[] = this.getIndemnitesAttributeFromLines(poPrestation.lines, "ref");

		const loRes: { label: string, deleted?: boolean }[] = [];
		if (laOrignalsIndemnites.length > 0) {
			laOrignalsIndemnites.forEach((psLabel: string) => {
				const loIndemnite: { label: string, deleted?: boolean } = { label: psLabel };
				loIndemnite.deleted = !laCurrentIndemnites.includes(psLabel);
				loRes.push(loIndemnite);
			});
		}
		else
			loRes.push({ label: "Aucune" });


		return loRes;
	}

	public getIndemnitesDistanceValue(poPrestation: IdlPrestation): number {
		return ArrayHelper.getFirstElement(poPrestation.lines.filter((poLine: PrestationLine) => this.IKTypes.includes(poLine.ref as EIndemniteType)))?.quantity ?? 0;
	}

	public getMajorationsTotalPrice(poPrestation: IdlPrestation): number {
		return this.getTotalPriceOf(poPrestation, EIdlPrestationLineCategory.majorations);
	}

	public getIndemnitesTotalPrice(poPrestation: IdlPrestation): number {
		return this.getTotalPriceOf(poPrestation, EIdlPrestationLineCategory.indemnites);
	}

	private getTotalPriceOf(poPrestation: IdlPrestation, peCategory: EIdlPrestationLineCategory): number {
		return poPrestation.lines.filter((poLine: PrestationLine) => poLine.category === peCategory).reduce((pnTotal: number, poLine: PrestationLine) => pnTotal += poLine.total, 0);
	}

	public hasIKType(poPrestation: IdlPrestation): boolean {
		return poPrestation.lines.some((poLine: PrestationLine) => this.IKTypes.includes(poLine.ref as EIndemniteType));
	}

	public getOriginalLine(poPrestation: IdlPrestation, psOriginalLineRef: string): PrestationLine {
		return poPrestation.originalLines.find((poLine: PrestationLine) => poLine.ref === psOriginalLineRef);
	}

	public getContactAvatar(psContactId: string): IAvatar {
		return ContactsService.createContactAvatar(this.contactsById.get(psContactId));
	}

	public async exportToExcel(): Promise<void> {
		const laRows: ISeancesExportRow[] = [];
		const laActes: Acte[] = await this.isvcActes.getActes().toPromise();
		const laActesById = new Map<string, Acte>();
		laActes.forEach((poActe: Acte) => laActesById.set(poActe._id, poActe));
		const laTraitements: ITraitement[] = await this.isvcTraitement.getTraitements().toPromise();
		laTraitements.forEach((poTraitement: Traitement) => this.traitementsById.set(poTraitement._id, poTraitement));

		this.getFilteredPrestations(this.maAllPrestations)?.forEach((poPrestation: IdlPrestation) => {
			const loPatient: IPatient = this.moPatientsById.get(poPrestation.customerId);
			const ldSeanceDate: Date = poPrestation.dateFromId;
			const lnNbActe: number = poPrestation.lines.filter((poPrestationLine: IPrestationLine) => !poPrestation.deleted && poPrestationLine.category === EIdlPrestationLineCategory.actes).length;

			poPrestation.lines.forEach((poPrestationLine: IdlPrestationLine) => {
				if (!poPrestationLine.deleted) {
					const lsTraitementId: string = Traitement.createId(poPrestation.customerId, poPrestation.siteId, IdHelper.getLastGuidFromVirtualNode(poPrestation._id));
					const loTraitement: Traitement = this.traitementsById.get(lsTraitementId);
					const lsTraitementName = loTraitement ? `Traitement ${this.ioTraitementsDatesPipe.transform(loTraitement)}` : "Traitement non trouvé";
					const loActe: Acte = poPrestationLine.category === EIdlPrestationLineCategory.actes ? laActesById.get(poPrestationLine.ref) : undefined;

					laRows.push({
						nomPatient: loPatient.lastName,
						prenomPatient: loPatient.firstName,
						traitement: lsTraitementName,
						seanceDate: DateHelper.transform(ldSeanceDate, ETimetablePattern.dd_MM_yyyy_slash),
						seanceHeure: DateHelper.transform(ldSeanceDate, ETimetablePattern.HH_mm),
						etat: this.getSeanceStatusLabelFromPrestation(poPrestation.status),
						nbActe: lnNbActe,
						total: poPrestation.total,
						facturee: poPrestation.status === EPrestationStatus.sent ? "Oui" : "Non",
						acte: loActe ? loActe.keyLetters[0] : poPrestationLine.ref,
						coefficient: loActe ? loActe.priceCoefficient : undefined,
						abbatement: poPrestationLine.discountRate,
						tarif: poPrestationLine.total,
						description: poPrestationLine.description,
					});
				};
			});
		});

		this.isvcSeance.exportToExcel(laRows);
	}

	public goToSynthese(psTraitementId: string): void {
		this.ioRouter.navigate(["traitements", psTraitementId, ERouteUrlPart.edit], { queryParams: { tab: PrestationsListComponentBase.C_SYNTHESE_TAB_NAME } });
	}

	private getSeanceStatusLabelFromPrestation(pePrestationStatus: EPrestationStatus): string {
		switch (pePrestationStatus) {
			case EPrestationStatus.canceled:
				return "Annulée";

			case EPrestationStatus.sent:
				return "Annulée";

			default:
				return "Réalisée";
		}
	}

	public refreshDisplayedPatient(): void {
		const laPatientIds: string[] = ArrayHelper.unique(this.getFilteredPrestations(this.maAllPrestations)?.map((poPrestation: IdlPrestation) => poPrestation.customerId));
		this.displayedPatients = this.maFilteredPatients.filter((poPatient: IPatient) => laPatientIds?.includes(poPatient._id));
	}

	//#region Filters

	public onFilteredPatientsChanged(paFilteredPatients: IPatient[]): void {
		if (ArrayHelper.hasElements(this.maFilterSelectedPatients))
			this.maFilteredPatients = Array.from(paFilteredPatients?.filter((poPatient: IPatient) => this.maFilterSelectedPatients.map((poItem: IPatient) => poItem._id).includes(poPatient._id)));
		else
			this.maFilteredPatients = Array.from(paFilteredPatients);
		this.refreshDisplayedPatient();
		this.resetPaginator();
		this.detectChanges();
	}

	/** Initialise les paramètres de filtrage. */
	private initFilterbarParams(): void {
		this.resetCurrentFilters();

		this.filterbarParams = {
			filters: this.isvcFacturation.createFilterbarOptions(this.maFilterSelectedPatients, this.patients, this.maFilterSelectedIntervenants),
			hasResetButton: true,
			hidden: true
		};

		this.detectChanges();
	}

	private resetCurrentFilters(): void {
		this.maFilterSelectedPatients = [];
		this.maFilterSelectedIntervenants = [];
		this.filterRangeDates = undefined;
		this.maFilterSelectedOthers = [];
	}

	public toggleFilterbarVisibility(): void {
		this.filterbarParams.hidden = !this.filterbarParams.hidden;
		this.detectChanges();
	}

	/** Événement de réinitialisation du filtrage. */
	public onFilterbarResetEvent(pbIsReset: boolean): void {
		this.filterbarParams.hidden = pbIsReset;
		this.resetCurrentFilters();
		this.onFilteredPatientsChanged(this.patients);
	}

	public onFilterCountChanged(pnFilterCount: number): void {
		this.filtersCount = pnFilterCount;
		this.detectChanges();
	}

	/** Événement de mise à jour des valeurs pour un filtre. */
	public onFilterValuesChanged(poEvent: IFilterValuesChangedEvent<IIdelizyContact | Date | IPatient>): void {
		this.setFilterValuesChanged(poEvent);
		this.refreshDisplayedPatient();
	}

	/** Modifie un des champs du comopsant pour garder en mémoire les différents filtres en cours. */
	private setFilterValuesChanged(poEvent: IFilterValuesChangedEvent<IIdelizyContact | Date | IPatient | string | IDateRange>): void {
		if (poEvent.id === FacturationService.C_INTERVENANT_FILTER_ID && ArrayHelper.hasElements(poEvent.changes))
			this.maFilterSelectedIntervenants = poEvent.changes as IIdelizyContact[];

		else if (poEvent.id === FacturationService.C_DATE_RANGE_FILTER_ID)
			this.filterRangeDates = ArrayHelper.getFirstElement(poEvent.changes) as IDateRange;

		else if (poEvent.id === PatientsService.C_FILTER_PATIENTS_ID) {
			this.maFilterSelectedPatients = poEvent.changes as IPatient[];
			this.maFilteredPatients = this.maFilteredPatients.filter((poPatient: IPatient) => (poEvent.changes as IPatient[]).some((poItem: IPatient) => poPatient._id === poItem._id));
		}
		else if (poEvent.id === FacturationService.C_OTHER_FILTER_ID) {
			const lsSelectedValue: string = ArrayHelper.getFirstElement(poEvent.changes as string[]);
			if (this.maTraitementTagsKeys.includes(lsSelectedValue)) {
				this.maFilterSelectedOthers = [];
			}
			else {
				this.maFilterSelectedOthers = [lsSelectedValue];
			}
		}
		else
			console.warn(`FACT.C:: L'identifiant de changements de valeur de filtres '${poEvent.id}' est inconnu.`);
	}

	/** Applique les filtres de la barre de filtrage sur les documents à afficher et retourne le nouveau tableau obtenu. */
	private applyFilterValues(paPrestations: IdlPrestation[]): IdlPrestation[] {
		let laResults: IdlPrestation[] = paPrestations;

		if (!ArrayHelper.hasElements(laResults))
			return [];

		// On filtre les intervenants s'il y en a.
		if (ArrayHelper.hasElements(this.maFilterSelectedIntervenants)) {
			laResults = laResults.filter((poPrestation: IdlPrestation) =>
				this.maFilterSelectedIntervenants.some((poPrescripteur: IIdelizyContact) =>
					!StringHelper.isBlank(poPrestation.vendorId) || poPrestation.vendorId === poPrescripteur._id
				),
			);
		};

		if (!ObjectHelper.isNullOrEmpty(this.filterRangeDates)) {
			laResults = laResults.filter((poPrestation: IdlPrestation) => {
				return DateHelper.compareTwoDates(poPrestation.dateFromId, this.filterRangeDates.begin) >= 0 && DateHelper.compareTwoDates(this.filterRangeDates.end, poPrestation.dateFromId) >= 0;
			}
			);
		};

		// On filtre par les filtres restants s'il y en a.
		if (ArrayHelper.hasElements(this.maFilterSelectedOthers)) {
			this.maFilterSelectedOthers.forEach((psFilter: string) => {
				if (psFilter === FacturationService.C_UNDEFINED_ACT) {
					laResults = laResults.filter((poPrestation: IdlPrestation) =>
						poPrestation.lines.some((poLine: IPrestationLine) => poLine.ref === ActesService.C_UNDEFINED_ACT_ID)
					);
				}
				else if (psFilter === FacturationService.C_HORS_NOMENCLATURE) {
					laResults = laResults.filter((poPrestation: IdlPrestation) =>
						poPrestation.lines.some((poLine: IPrestationLine) => poLine.ref.includes(ActesService.C_HORS_NOMENCLATURE_ACT_ID))
					);
				};
			});
		};

		if (ArrayHelper.hasElements(this.maSelectedStatus)) {
			laResults = laResults.filter((poPrestation: IdlPrestation) => this.maSelectedStatus.includes(poPrestation.status));
		};

		return laResults;
	}

	public onPageChange(poPaginatorInfos: IPaginatorInfos): void {
		if (poPaginatorInfos.pageSize !== this.nbItemsByPage)
			this.nbItemsByPage = poPaginatorInfos.pageSize;

		this.startIndex = this.nbItemsByPage * poPaginatorInfos.pageIndex;
	}

	public onStatusSelectionChanged(paSelectedStatus: EPrestationStatus[]): void {
		this.maSelectedStatus = paSelectedStatus;
		this.detectChanges();
		this.refreshDisplayedPatient();
	}

	private resetPaginator(): void {
		this.startIndex = 0;
		if (this.paginator)
			this.paginator.pageIndex = 0;
	}

	//#endregion

	//#endregion

}
