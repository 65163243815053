import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { FileHelper, GuidHelper, IdHelper } from '@osapp/helpers';
import { EPrefix, IGalleryFile } from '@osapp/model';
import { DmsFile } from '@osapp/modules/dms/model/DmsFile';
import { LongGuidBuilder } from '@osapp/modules/guid/models/long-guid-builder';
import { ContactsService } from '@osapp/services';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { InputFileComponent } from 'lighting-up-angular/public-api';
import { tap } from 'rxjs/operators';
import { ITransmissionRapport } from '../../../../../modules/patients/model/ITransmissionRapport';
import { RapportService } from '../../../../../modules/patients/services/rapport.service';
import { ICommentaireTransmission } from '../../../../models/ICommentaireTransmission';
import { PanneauService } from '../../../shared/services/panneau.service';

export interface ControlConfig {
  name: string;
  value: any;
  validators?: ValidatorFn[] | ValidatorFn;
}

@Component({
  selector: 'di-panneau-comment-transmission',
  templateUrl: './panneau-comment-transmission.component.html',
  styleUrls: ['./panneau-comment-transmission.component.scss'],
})
export class PanneauCommentTransmissionComponent implements OnInit {

  public patient: IPatient;
	@Input() public transmission : ITransmissionRapport;
	@Input() public comment? : ICommentaireTransmission;
	@Input() public openDoc? : boolean;

	@ViewChild('fileInput', {static: false}) fileInputComponent!: InputFileComponent;


	public model : ICommentaireTransmission;
	public listDoc : IGalleryFile[] = [];
	public listNewFile : File[] = [];

	public commentForm: FormGroup;
	public errorMessage : string = "Le fichier sélectionné est trop volumineux (5Mo maximum)";;
	public isError : boolean = false;

	public transmissionControlsConfig: ControlConfig[];
	private readonly guidBuilder = new LongGuidBuilder();



  constructor(
    private svcPanneau: PanneauService,
		private svcTransmission :RapportService,
		private fb: FormBuilder,
		) { }

  ngOnInit() {

		if (this.transmission && this.comment) {
			this.model = this.comment;
			this.listDoc = [...this.comment.files];
		}

		this.transmissionControlsConfig = [
			{ name: 'description', value: this.model ? this.model.description : '' }
    ];

		this.commentForm = this.fb.group({});
      this.transmissionControlsConfig.forEach(control => {
        this.commentForm.addControl(
          control.name,
          this.fb.control(control.value, control.validators || [])
        );
      });
  }

	public handleSubmit(event: Event): void {
    event.preventDefault();
    if (this.commentForm.valid) {
      const formValues = this.commentForm.value;
      let formComment = {}
      if (this.model) {
        formComment = { ...this.model }
      } else {
        formComment = {
          id: ''
        }
      }
      formComment = {
        ...formComment,
        ...formValues
      }
			this.createOrUpdateComment(formComment);
		}
  }

	public deleteNewFile(event:File):void{
		const index = this.listNewFile.findIndex(file =>
			file.name === event.name &&
			file.size === event.size &&
			file.type === event.type
		);
		
		if (index !== -1) {
			this.listNewFile.splice(index, 1);
		}	
	}

	public deleteOldFile(event:IGalleryFile):void{
		const index = this.listDoc.findIndex(file =>
			file.guid === event.guid
		);
		
		if (index !== -1) {
			this.listDoc.splice(index, 1);
		}	
	}

	public handleaddDocumentClick(fileInput: InputFileComponent) : void{
    fileInput.onAddAttestation();
	}

	public handleFileSelected(file: File): void {
		if (file) {
			if(file.size > 5120 * 1024)
			{
				this.isError = true;
			}
			else
			{
				this.isError = false;
				this.listNewFile.push(file);
			}
		}
	}

	private createOrUpdateComment(commentValue :any)
	{
		if(commentValue.id === '')
		{
			let comment:ICommentaireTransmission =
			{
				id : GuidHelper.newGuid(),
				auteurId:ContactsService.getUserContactId(),
				dateCreation:new Date(),
				dateLastModification:new Date(),
				description:commentValue.description,
				files : []
			}

			this.listNewFile.map((file :File) =>
			{
				const dmsFile: DmsFile = new DmsFile(file, file.name);
				const galleryFile: IGalleryFile = {
					file: dmsFile,
					isNew: true,
					name: dmsFile.Name,
					description: "",
					guid: this.guidBuilder.build({ withHyphens: false, upperCase: false })
				};
				this.listDoc.push(galleryFile);
			});

			comment.files = this.listDoc;
			
			if(!this.transmission.commentaires)
				this.transmission.commentaires = new Array<ICommentaireTransmission>();
			this.transmission.commentaires.push(comment);
		}
		else
		{
			
			const index = this.transmission.commentaires.findIndex(
				(comment: ICommentaireTransmission) => comment.id === this.model.id
			);

			if (index !== -1) {

				this.listNewFile.map((file :File) =>
				{
					FileHelper.reduceImage(file, 5120).pipe(
						tap((newFile: File) => {
							const dmsFile: DmsFile = new DmsFile(newFile, newFile.name);
							const galleryFile: IGalleryFile = {
								file: dmsFile,
								isNew: true,
								name: dmsFile.Name,
								description: "",
								guid: this.guidBuilder.build({ withHyphens: false, upperCase: false })
							};
							this.listDoc.push(galleryFile);
						})
					)	
				});

				this.transmission.dateLastModification = new Date(),
				this.transmission.commentaires[index] = {
						...this.transmission.commentaires[index],
						description: commentValue.description,
						dateLastModification: new Date(),
						files : this.listDoc
				}
			}
		}
		this.svcTransmission.saveTransmissionANAKIN(this.transmission).subscribe({
			next: () => {
					this.svcPanneau.close();
			},
			error: (error) => {
			}
		});
	}


	public getIdPatient(string:string) : string{
		return `${EPrefix.patient}${IdHelper.getGuidFromId(string, EPrefix.patient).split(IdHelper.C_ID_SEPARATOR)[0]}`;
	}

	public close(){
		this.svcPanneau.close();
	}
}