<osapp-selector *ngIf="(terminalOptions.changes$ | async) as options; else loadingOrError;" [options]="options"
	[preselectedValues]="preselectedTerminalId$ | async" (selectionChanged)="setLastUsedTerminal($event)" min="1"
	label="Choix du lecteur">
</osapp-selector>

<ng-template #loading>
	<ion-item lines="none">
		<ion-label>Chargement des lecteurs...</ion-label>
		<ion-spinner></ion-spinner>
	</ion-item>
</ng-template>

<ng-template #error>
	<ion-item lines="none">
		<ion-label class="ion-text-wrap">Erreur lors du chargement des lecteurs.</ion-label>
	</ion-item>
</ng-template>

<ng-template #loadingOrError>
	<ng-container *ngIf="hasError; then error; else loading;"></ng-container>
</ng-template>