<div class="main-ctn">
	<ng-container *ngTemplateOutlet="readOnly ? view : edit"></ng-container>

	<!-- Galerie. -->
	<div class="documents">
		<h5>Documents</h5>
		<gallery #galleryComponent *ngIf="patient.directives" [files]="patient.directives.documents" [readOnly]="readOnly"
			(onFilesChanged)="onDucumentsModelChanged($event)" accept="image"></gallery>
	</div>
</div>

<!-- TEMPLATE MODE VISU -->
<ng-template #view>
	<div *ngIf="patient.directives?.description; else noDirectives" class="flex">
		<div class="description-ctn">
			<h5>Directives anticipées</h5>
			<p>{{ patient.directives.description }}</p>
		</div>
		<div class="author-ctn">
			<h5>Dernière modification</h5>
			<div class="last-edit-ctn">
				<div class="date-ctn" *ngIf="patient.directives?.updateDate as updateDate">
					<p>{{ updateDate | dateWithLocale : 'fr-FR' : 'dd/MM/yy' }}</p>
					<p>{{ updateDate | dateWithLocale : 'fr-FR' : 'HH:mm' }}</p>
				</div>
				<avatar *ngIf="authorAvatar" [src]="authorAvatar" [matTooltip]="author | contactName"></avatar>
			</div>
		</div>
	</div>
</ng-template>

<!-- TEMPLATE MODE EDIT -->
<ng-template #edit>
	<mat-form-field appearance="outline" class="description-input">
		<mat-label>Directives anticipées</mat-label>
		<textarea matInput matTextareaAutosize matAutosizeMinRows="3" [(ngModel)]="descriptionModel"></textarea>
	</mat-form-field>
</ng-template>

<!-- TEMPLATE AUCUNE DIRECTIVE -->
<ng-template #noDirectives class="empty-screen flex-col">
	<h5>Directives anticipées</h5>
	<div class="message">
		<ion-icon name="information-circle" class="no-result"></ion-icon>
		Aucune directive.
	</div>
</ng-template>