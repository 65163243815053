import { Injectable, InjectionToken, Injector, Type } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class InjectorService {

	//#region PROPERTIES

	public static instance: InjectorService;

	//#endregion

	//#region METHODS

	constructor(private injector: Injector) {
		InjectorService.instance = this;
	}

	public get<T>(poToken: Type<T> | InjectionToken<T>): T {
		return this.injector.get<T>(poToken);
	}

	//#endregion
}