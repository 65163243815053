import { Component, Input, OnInit } from '@angular/core';
import { IUser } from '@osapp/model';
import { tap } from 'rxjs/operators';
import { UsersService } from '../../../shared/services/users.service';

@Component({
	selector: 'di-inviter-utilisateur',
	templateUrl: './inviter-utilisateur.component.html',
	styleUrls: ['./inviter-utilisateur.component.scss'],
})
export class InviterUtilisateurComponent implements OnInit {
	public showInviterUtilisateur: boolean;
	public showLoader: boolean;
	public showAucunUtilisateur: boolean;
	public email: string = '';
	@Input() onCancel : () => void;
	@Input() onShowForm : (user : IUser) => void;
	
	constructor(
		private svcUsers : UsersService
	) {}

	ngOnInit() {
		this.showInviterUtilisateur = true;
		this.showLoader = false;
		this.showAucunUtilisateur = false;
	}
	/*TODO naviguer vers creer compte */
	createCompte() {
		this.showInviterUtilisateur = false;
	}

	public handleEmailChange(mail: string): void {
		this.email = mail;
		this.showInviterUtilisateur = true;
		this.showAucunUtilisateur = false;
	}

	public handelAnnuler (){
		if(this.onCancel)
			this.onCancel();
	}

	rechercherUtilisateurByMail() {
    this.showInviterUtilisateur = false;
    this.showLoader = true;
    this.showAucunUtilisateur = false;

    this.svcUsers.getUser(this.email)
        .pipe(
            tap((user: IUser) => {
                this.showLoader = false;
                if (!user) {
                    this.showAucunUtilisateur = true;
                } else {
									if(this.onShowForm)
										this.onShowForm(user)
                }
            })
        )
        .subscribe();
}

}
