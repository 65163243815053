import { Injectable } from '@angular/core';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { IEntity } from '@osapp/model/entities/IEntity';
import { IEntityLink } from '@osapp/model/entities/IEntityLink';
import { MetaEntityBuilderBase } from '@osapp/modules/dms/model/MetaEntityBuilderBase';
import { EntityLinkService } from '@osapp/services/entityLink.service';
import { WorkspaceService } from '@osapp/services/workspace.service';
import { C_PREFIX_PATIENT, C_PREFIX_RAPPORT } from '../../../app/app.constants';
import { ITransmissionRapport } from '../../patients/model/ITransmissionRapport';

@Injectable()
export class MetaTransmissionBuilder extends MetaEntityBuilderBase {

	//#region METHODS

	constructor(
		private readonly isvcEntityLink: EntityLinkService,
		psvcWorkspace: WorkspaceService
	) {
		super(psvcWorkspace);
	}

	/** @override */
	public match(psEntityId: string): boolean {
		return IdHelper.hasPrefixId(psEntityId, C_PREFIX_RAPPORT);
	}

	/** @override */
	protected async getEntityId(poEntity: IEntity<ITransmissionRapport>): Promise<string> {
		const loPatientLink: IEntityLink = ArrayHelper.getFirstElement(
			await this.isvcEntityLink.getEntityLinks(poEntity.id, [C_PREFIX_PATIENT]).toPromise()
		);

		if (loPatientLink)
			return this.isvcEntityLink.getLinkTargetId(loPatientLink);

		return poEntity.id;
	}

	//#endregion

}
