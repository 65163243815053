import { Component, Input, OnInit } from '@angular/core';
import { GuidHelper } from '../../../../helpers/guidHelper';
import { FieldBase } from '../../../../model/forms/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { IDynHostComponent } from '../../../dynHost/IDynHost.component';
import { IFormDynHostOptions } from '../../../dynHost/IFormDynHostOptions';

@Component({
	templateUrl: './formDynHostWrapper.component.html'
})
export class FormDynHostWrapperComponent extends FieldBase implements IDynHostComponent, OnInit { //todo : typer + params

	//#region PROPERTIES

	/** @implements */
	public instanceId: string;
	/** @implements */
	@Input() public params: IFormDynHostOptions;

	//#endregion

	//#region METHODS

	constructor(psvcForms: FormsService) {
		super(psvcForms);

		this.instanceId = GuidHelper.newGuid();
	}

	public ngOnInit(): void {
		super.ngOnInit();

		if (!this.params)
			this.params = {};
	}

	//#endregion

}