import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { ModalComponentBase } from '@osapp/modules/modal/model/ModalComponentBase';
import { Queuer } from '@osapp/modules/utils/queue/models/queuer';
import { PlatformService } from '@osapp/services/platform.service';
import { defer, Observable, of } from 'rxjs';
import { catchError, filter, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { ITerminalInfo } from '../../models/iterminal-info';
import { OlaqinService } from '../../services/olaqin.service';
import { ITerminalInfoModalParams } from './models/iterminal-info-modal-params';

@Component({
	selector: 'idl-terminal-info-modal',
	templateUrl: './terminal-info-modal.component.html',
	styleUrls: ['./terminal-info-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TerminalInfoModalComponent extends ModalComponentBase<ITerminalInfo> implements OnInit {

	//#region FIELDS

	private static readonly C_DEFAULT_TITLE = "État du lecteur";
	private static readonly C_DEFAULT_VALIDATION_BUTTON_TEXT = "VALIDER";

	private msLastTerminalId: string;

	private readonly moQueuer = new Queuer({
		thingToQueue: (psTerminalId: string) => {
			this.terminal = undefined;
			this.detectChanges();
			return defer(() => StringHelper.isBlank(psTerminalId) ? of({}) : this.isvcOlaqin.getTerminalInfos(psTerminalId)).pipe(
				takeUntil(this.terminalId$.pipe(filter((psNewTerminalId: string) => psTerminalId !== psNewTerminalId))),
				catchError((poError: any) => {
					return of({});
				}),
				tap((poTerminal: ITerminalInfo) => {
					this.terminal = poTerminal;
					this.canValidate = this.isTerminalReady();
					this.detectChanges();
				}
				),
			);
		},
		excludePendings: true
	});

	//#endregion

	//#region PROPERTIES

	public readonly terminalId$: Observable<string>;
	public terminal?: ITerminalInfo;
	public canValidate = false;
	@Input() public params?: ITerminalInfoModalParams;

	public get title(): string {
		return this.params?.title ?? TerminalInfoModalComponent.C_DEFAULT_TITLE;
	}

	public get validationButtonText(): string {
		return this.params?.validationButtonText ?? TerminalInfoModalComponent.C_DEFAULT_VALIDATION_BUTTON_TEXT;
	}

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcOlaqin: OlaqinService,
		ioModalCtrl: ModalController,
		isvcPlatform: PlatformService,
		ioChangeDetector: ChangeDetectorRef
	) {
		super(ioModalCtrl, isvcPlatform, ioChangeDetector);
		this.terminalId$ = this.isvcOlaqin.getLastUsedTerminalId(true).pipe(takeUntil(this.destroyed$), shareReplay(1));
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this.setQueuers([this.moQueuer]);
		this.terminalId$.subscribe((psTermninalId: string) => this.getInfo(this.msLastTerminalId = psTermninalId));
	}

	public getInfo(psTerminalId: string = this.msLastTerminalId): void {
		this.moQueuer.exec(psTerminalId);
	}

	private isTerminalReady(): boolean {
		return this.terminal?.terminalConnected && this.terminal?.cpsInserted && (this.terminal?.vitaleInserted || this.params?.bypassVitale);
	}

	//#endregion

}
