import { Component, Input, OnInit } from '@angular/core';
import { ISeance } from '../../../../../model/ISeance';
import { Traitement } from '../../../../../model/Traitement';
import { IPatient } from '../../../../../modules/patients/model/IPatient';

@Component({
  selector: 'di-liste-seance',
  templateUrl: './liste-seances-component.html',
  styleUrls: ['./liste-seances-component.scss']
})
export class ListeSeancesComponent  implements OnInit {
	
  @Input() ordonnance: Traitement;
  @Input() patient: IPatient;
	@Input() visibleGroupedSeances?: { date: string, seances: ISeance[] }[];


	ngOnInit(): void {
	}
}
