import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatorComponent } from '@osapp/components/authenticator/authenticator.component';
import { CanEditGroupGuard } from '@osapp/components/contacts/can-edit-group.guard';
import { ContactEditPageInfoResolver } from '@osapp/components/contacts/contact-edit-pageInfo.resolver';
import { ContactResolver } from '@osapp/components/contacts/contact.resolver';
import { GroupResolver } from '@osapp/components/contacts/group.resolver';
import { ConversationResolver } from '@osapp/components/conversation/conversation.resolver';
import { EDynamicTitle } from '@osapp/components/dynamicPage/EDynamicTitle';
import { DynamicPageComponent } from '@osapp/components/dynamicPage/dynamicPage.component';
import { GoogleMapComponent } from '@osapp/components/geolocation/googleMap.component';
import { AuthenticatedGuard } from '@osapp/guards/authenticated.guard';
import { CanDeactivateGuard } from '@osapp/guards/canDeactivate.guard';
import { DisconnectGuard } from '@osapp/guards/disconnect.guard';
import { GuestGuard } from '@osapp/guards/guest.guard';
import { NotAuthenticatedGuard } from '@osapp/guards/not-authenticated.guard';
import { ESuffix } from '@osapp/model/ESuffix';
import { PageInfo } from '@osapp/model/PageInfo';
import { IFormParams } from '@osapp/model/forms/IFormParams';
import { HasPermissionGuard } from '@osapp/modules/permissions/guards/has-permission.guard';
import { EPermission } from '@osapp/modules/permissions/models/EPermission';
import { IPermission } from '@osapp/modules/permissions/models/ipermission';
import { SiteResolver } from '@osapp/modules/sites/site.resolver';
import { ConfirmationEmailPage } from '../anakin/pages/connexion/confirmation-email/confirmation-email.page';
import { ConfirmationMdpChangePage } from '../anakin/pages/connexion/confirmation-mdp-change/confirmation-mdp-change.page';
import { LienExpirePage } from '../anakin/pages/connexion/lien-expire/lien-expire.page';
import { ChangePasswordPage } from '../anakin/pages/connexion/password-change/password-change.page';
import { PasswordDemandePage } from '../anakin/pages/connexion/password-demande/password-demande.page';
import { FirstRdvGuard } from '../guards/first-rdv.guard';
import { ConstantesPageComponent } from '../modules/patients/components/constantes/constantes-page/constantes-page.component';
import { TransmissionPageComponent } from '../modules/patients/components/transmissions/transmission-page/transmission-page.component';
import { ConstantesResolver } from '../modules/patients/resolvers/constantes.resolver';
import { TransmissionResolver } from '../modules/patients/resolvers/transmission.resolver';
import { PatientResolver } from '../modules/patientsBook/patient.resolver';
import { PharmaResolver } from '../modules/pharma/pharma.resolver';
import { TraitementResolver } from '../modules/traitement/traitement.resolver';

const HOME_PAGE_INFO = new PageInfo({
	templateId: "home",
	componentName: "home",
	title: EDynamicTitle.site,
	hasMenuButton: true,
	hasSettingButton: true,
	hasSyncButton: true,
	params: {
		menuKey: "homeMenu"
	}
});

const HOME_PAGE_DEBUG = new PageInfo({
	title: "debug",
	componentName: "debug",
	hasHomeButton: false,
	hasBackButton: false
});

const AUTHENTICATION_PAGE_INFO = new PageInfo({
	title: "Authentification",
	componentName: "authenticator",
	hasHomeButton: false,
	hasBackButton: false
});

const PATIENTS_PAGE_INFO = new PageInfo({
	title: "Patients",
	componentName: "patients-book-page",
	hasSettingButton: true,
	hasMenuButton: true,
	hasSyncButton: true,
});

const PATIENT_VIEW_PAGE_INFO = new PageInfo({
	title: "Patient",
	componentName: "idl-patient-form",
	params: {
		formDefinitionId: `patient${ESuffix.view}`,
		formDescriptorId: "formDesc_patientsBook"
	},
	hasMenuButton: true,
	hasSyncButton: true,
});

const PATIENT_EDIT_PAGE_INFO = new PageInfo({
	title: "Patient",
	componentName: "idl-patient-form",
	params: {
		formDefinitionId: `patient${ESuffix.edit}`,
		formDescriptorId: "formDesc_patientsBook"
	},
	hasMenuButton: true,
	hasSettingButton: true,
	hasSyncButton: true,
});

const GOOGLE_MAP_PAGE_INFO = {
	hasMenuButton: true,
	hasHomeButton: true,
	hasSettingButton: true,
	hasBackButton: true,
	hasSyncButton: true,
};

const REVERSE_GEOCODING_PAGE_INFO = new PageInfo({
	title: "Géolocalisation",
	componentName: "reverseGeocoding",
	hasHomeButton: false
});

const CONTACTS_PAGE_INFO = new PageInfo({
	title: "Contacts",
	componentName: "contacts-book",
	hasSettingButton: true,
	hasMenuButton: true,
	hasSyncButton: true,
});

const CONTACT_VIEW_PAGE_INFO = new PageInfo({
	title: "Contact",
	componentName: "contact-form",
	params: {
		formDefinitionId: `contact${ESuffix.view}`,
		formDescriptorId: "formDesc_contacts"
	},
	hasMenuButton: true,
	hasSyncButton: true,
});

const CONTACT_NEW_PAGE_INFO = new PageInfo({
	title: "Contact",
	componentName: "contact-form",
	params: {
		formDefinitionId: `contact${ESuffix.edit}`,
		formDescriptorId: "formDesc_contacts"
	},
	hasMenuButton: true,
	hasSyncButton: true,
});

const GROUP_EDIT_PAGE_INFO = new PageInfo({
	title: "Détails du groupe",
	componentName: "group-details",
	hasMenuButton: true,
	hasSyncButton: true,
});

const SITE_EDIT_PAGE_INFO = new PageInfo({
	title: "Détails du site",
	componentName: "site-details",
	hasMenuButton: true,
	hasSyncButton: true,
});

const CONVERSATIONS_PAGE_INFO = new PageInfo({
	title: "Conversations",
	componentName: "idl-conversations-tab",
	hasSettingButton: true,
	params: {
		params: {
			galleryCameraButtonVisible: false,
			galleryFilePickerVisible: false,
			galleryAcceptFiles: "image",
			filterConversationsByWorkspace: true
		}
	},
	hasMenuButton: true,
	hasSyncButton: true,
});

const CONVERSATION_PAGE_INFO = new PageInfo({
	title: "Conversation",
	componentName: "conversation",
	params: {
		galleryCameraButtonVisible: false,
		galleryFilePickerVisible: false,
		galleryAcceptFiles: "image"
	},
	hasMenuButton: true,
	hasSettingButton: true,
	hasSyncButton: true,
});

const CONVERSATION_EDIT_PAGE_INFO = new PageInfo({
	title: "Conversation",
	componentName: "conversationEdit",
	hasMenuButton: true,
	hasSyncButton: true,
});

const TRAITEMENTS_PAGE_INFO = new PageInfo({
	title: "Traitements",
	componentName: "traitements-page",
	hasMenuButton: true,
	hasSettingButton: true,
	hasSyncButton: true,
});

const TRAITEMENT_PAGE_INFO = new PageInfo({
	title: "Traitement",
	componentName: "traitement-slide-page",
	hasMenuButton: true,
	hasSyncButton: true,
});

const PHARMA_EDIT_PAGE_INFO = new PageInfo({
	title: "Demande de préparation",
	componentName: "form",
	params: {
		formDefinitionId: `pharma${ESuffix.edit}`,
		formDescriptorId: "formDesc_prepaPharma"
	} as IFormParams,
	hasMenuButton: true,
	hasSyncButton: true,
});

const TRANSMISSIONS_PAGE_INFO = new PageInfo({
	title: "Transmissions",
	componentName: "transmissions-list",
	hasMenuButton: true,
	hasHomeButton: true,
	hasSettingButton: true,
	hasBackButton: true,
	hasSyncButton: true,
});

const TRANSMISSION_PAGE_INFO = {
	hasMenuButton: true,
	hasHomeButton: true,
	hasSettingButton: true,
	hasBackButton: true,
	hasSyncButton: true,
};

const LEGALES_PAGE_INFO = new PageInfo({
	title: "Mentions légales",
	componentName: "webview",
	hasMenuButton: true,
	params: {
		source: "https://healthcare.orisha.com/mentions-legales",
		cssClass: "full-size"
	}
});

const AMELI_PRO_PAGE_INFO = new PageInfo({
	title: "Mentions légales",
	componentName: "webview",
	hasMenuButton: true,
	params: {
		source: "https://www.ameli.fr/meurthe-et-moselle/infirmier/exercice-liberal/prescription-prise-charge/regles-exercice-formalites/bilan-de-soins-infirmiers-bsi",
		cssClass: "full-size"
	}
});

const CONFIDENTIAL_PAGE_INFO = new PageInfo({
	title: "Politique de confidentialité",
	componentName: "webview",
	hasMenuButton: true,
	params: {
		source: "https://www.idelizy.fr/politique-de-confidentialite",
		cssClass: "full-size"
	}
});
const CGV_PAGE_INFO = new PageInfo({
	title: "CGV",
	componentName: "webview",
	hasMenuButton: true,
	params: {
		source: "https://www.juxta.fr/conditions-generales-de-vente/",
		cssClass: "full-size"
	}
});

const FORMULAIRE_DEMO_PAGE_INFO = new PageInfo({
	title: "Demande de démo",
	componentName: "webview",
	hasMenuButton: true,
	params: {
		source: "",
		cssClass: "full-size"
	}
});
const FORMULAIRE_INFOS_PAGE_INFO = new PageInfo({
	title: "Demande d'informations",
	componentName: "webview",
	hasMenuButton: true,
	params: {
		source: "",
		cssClass: "full-size"
	}
});

const CONSTANTES_LIST_PAGE_INFO = new PageInfo({
	title: "Constantes",
	componentName: "constantes-list",
	hasMenuButton: true,
	hasHomeButton: true,
	hasSettingButton: true,
	hasBackButton: true,
	hasSyncButton: true,
});

const CONSTANTES_PAGE_INFO = {
	hasMenuButton: true,
	hasHomeButton: true,
	hasSettingButton: true,
	hasBackButton: true,
	hasSyncButton: true,
};

export const routes: Routes = [
	{ path: '', redirectTo: 'home', pathMatch: 'full' },
	{
		path: 'home',
		component: DynamicPageComponent,
		data: {
			pageInfo: HOME_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'debugComponent',
		loadChildren: () => import('./debug/debug.module').then( m => m.DebugPageModule)
	},

	// DEBUT  A delete après mise en prod
	{
		path: "lienExpire",
		component: LienExpirePage,
		data: {},
		canActivate: [NotAuthenticatedGuard],
	},
	{
		path: "passwordDemande",
		component: PasswordDemandePage,
		data: {},
		canActivate: [NotAuthenticatedGuard],
	},
	{
		path: "passwordChange",
		component: ChangePasswordPage,
		data: {},
		canActivate: [NotAuthenticatedGuard],
	},
	{
		path: "confirmationEmail",
		component: ConfirmationEmailPage,
		data: {},
		canActivate: [NotAuthenticatedGuard],
	},
	{
		path: "confirmationMdpChange",
		component: ConfirmationMdpChangePage,
		data: {},
		canActivate: [NotAuthenticatedGuard],
	},
	// FIN  A delete après mise en prod

	{
		path: 'authenticator',
		component: DynamicPageComponent,
		data: {
			pageInfo: AUTHENTICATION_PAGE_INFO
		},
		canActivate: [NotAuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'patients',
		component: DynamicPageComponent,
		data: {
			pageInfo: PATIENTS_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'patients/new',
		component: DynamicPageComponent,
		data: {
			pageInfo: PATIENT_EDIT_PAGE_INFO
		},
		resolve: { model: PatientResolver },
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'patients/:contactId',
		component: DynamicPageComponent,
		data: {
			pageInfo: PATIENT_VIEW_PAGE_INFO
		},
		resolve: { model: ContactResolver },
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'patients/:contactId/edit',
		component: DynamicPageComponent,
		data: {
			pageInfo: PATIENT_EDIT_PAGE_INFO
		},
		resolve: { model: ContactResolver },
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'googleMap',
		component: GoogleMapComponent,
		data: {
			pageInfo: GOOGLE_MAP_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'reverseGeocoding',
		component: DynamicPageComponent,
		data: {
			pageInfo: REVERSE_GEOCODING_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'contacts',
		component: DynamicPageComponent,
		data: {
			pageInfo: CONTACTS_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'contacts/new',
		component: DynamicPageComponent,
		data: {
			pageInfo: CONTACT_NEW_PAGE_INFO
		},
		resolve: { model: ContactResolver },
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'contacts/:contactId',
		component: DynamicPageComponent,
		data: {
			pageInfo: CONTACT_VIEW_PAGE_INFO
		},
		resolve: { model: ContactResolver },
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'contacts/:contactId/edit',
		component: DynamicPageComponent,
		resolve: {
			model: ContactResolver,
			pageInfo: ContactEditPageInfoResolver
		},
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'groupes/new',
		component: DynamicPageComponent,
		data: {
			pageInfo: GROUP_EDIT_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'groupes/:groupId',
		component: DynamicPageComponent,
		data: {
			pageInfo: GROUP_EDIT_PAGE_INFO
		},
		resolve: { model: GroupResolver },
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'groupes/:groupId/edit',
		component: DynamicPageComponent,
		data: {
			pageInfo: GROUP_EDIT_PAGE_INFO
		},
		resolve: { model: GroupResolver },
		canActivate: [AuthenticatedGuard, CanEditGroupGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'sectors/new',
		component: DynamicPageComponent,
		data: {
			pageInfo: GROUP_EDIT_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'sectors/:groupId',
		component: DynamicPageComponent,
		data: {
			pageInfo: GROUP_EDIT_PAGE_INFO
		},
		resolve: { model: GroupResolver },
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'sectors/:groupId/edit',
		component: DynamicPageComponent,
		data: {
			pageInfo: GROUP_EDIT_PAGE_INFO
		},
		resolve: { model: GroupResolver },
		canActivate: [AuthenticatedGuard, CanEditGroupGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'sites/new',
		component: DynamicPageComponent,
		data: {
			pageInfo: SITE_EDIT_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'sites/:siteId',
		component: DynamicPageComponent,
		data: {
			pageInfo: SITE_EDIT_PAGE_INFO
		},
		resolve: { model: SiteResolver },
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'sites/:siteId/edit',
		component: DynamicPageComponent,
		data: {
			pageInfo: SITE_EDIT_PAGE_INFO
		},
		resolve: { model: SiteResolver },
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'conversations',
		component: DynamicPageComponent,
		data: {
			pageInfo: CONVERSATIONS_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'conversations/:conversationId',
		component: DynamicPageComponent,
		data: {
			pageInfo: CONVERSATION_PAGE_INFO
		},
		resolve: { conversation: ConversationResolver },
		canActivate: [GuestGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'conversations/:conversationId/edit',
		component: DynamicPageComponent,
		data: {
			pageInfo: CONVERSATION_EDIT_PAGE_INFO
		},
		resolve: { conversation: ConversationResolver },
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'traitements',
		component: DynamicPageComponent,
		data: {
			pageInfo: TRAITEMENTS_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'traitements/new',
		component: DynamicPageComponent,
		data: {
			pageInfo: TRAITEMENT_PAGE_INFO
		},
		resolve: { traitement: TraitementResolver },
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'traitements/first-rdv',
		component: DynamicPageComponent,
		data: {
			pageInfo: TRAITEMENT_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard, FirstRdvGuard]
	},
	{
		path: 'traitements/:traitementId/edit',
		component: DynamicPageComponent,
		data: {
			pageInfo: TRAITEMENT_PAGE_INFO
		},
		resolve: { traitement: TraitementResolver },
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'diagnostics',
		loadChildren: () => import('./diagnostics/diagnostics.module').then(poModule => poModule.DiagnosticsPageModule)
	},
	{
		path: 'tournees',
		loadChildren: () => import('../modules/tournees/tournees.module').then(poModule => poModule.TourneesModule),
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'signup',
		loadChildren: () => import('@osapp/modules/signup').then(poModule => poModule.SignupModule),
		canActivate: [NotAuthenticatedGuard]
	},
	{
		path: 'password',
		loadChildren: () => import('@osapp/modules/password').then(poModule => poModule.PasswordModule)
	},
	{
		path: 'pharmas/:pharmaId/edit',
		component: DynamicPageComponent,
		data: {
			pageInfo: PHARMA_EDIT_PAGE_INFO
		},
		resolve: { model: PharmaResolver },
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'transmissions',
		component: DynamicPageComponent,
		data: {
			pageInfo: TRANSMISSIONS_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'transmissions/new',
		component: TransmissionPageComponent,
		data: {
			pageInfo: TRANSMISSION_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'transmissions/:transmissionId',
		component: TransmissionPageComponent,
		data: {
			pageInfo: TRANSMISSION_PAGE_INFO
		},
		resolve: { transmission: TransmissionResolver },
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'transmissions/:transmissionId/edit',
		component: TransmissionPageComponent,
		data: {
			pageInfo: TRANSMISSION_PAGE_INFO
		},
		resolve: { transmission: TransmissionResolver },
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'a-propos',
		loadChildren: () => import('./a-propos/a-propos.module').then(m => m.AProposPageModule),
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'mentions',
		loadChildren: () => import('./mentions/mentions.module').then(m => m.MentionsPageModule),
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'ameli-pro',
		loadChildren: () => import('./ameli-pro/ameli-pro.module').then(m => m.AmeliProPageModule),
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'confidential',
		component: DynamicPageComponent,
		data: {
			pageInfo: CONFIDENTIAL_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'cgv',
		component: DynamicPageComponent,
		data: {
			pageInfo: CGV_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard],
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: 'demo',
		component: DynamicPageComponent,
		data: {
			pageInfo: FORMULAIRE_DEMO_PAGE_INFO
		}
	},
	{
		path: 'infos',
		component: DynamicPageComponent,
		data: {
			pageInfo: FORMULAIRE_INFOS_PAGE_INFO
		}
	},
	{
		path: 'facturation',
		loadChildren: () => import('./facturation/facturation.module').then(m => m.FacturationPageModule),
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'teletransmissions',
		loadChildren: () => import('./teletransmissions/teletransmissions.module').then(m => m.TeletransmissionsPageModule),
		canActivate: [AuthenticatedGuard]
	},

	{
		path: 'retour-noemie',
		loadChildren: () => import('./retour-noemie/retour-noemie.module').then(m => m.RetourNoemiePageModule),
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'disconnect',
		component: AuthenticatorComponent,
		canActivate: [DisconnectGuard]
	},
	{
		path: 'constantes',
		component: DynamicPageComponent,
		data: {
			pageInfo: CONSTANTES_LIST_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'constantes/new',
		component: ConstantesPageComponent,
		data: {
			pageInfo: CONSTANTES_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'injections/new',
		component: ConstantesPageComponent,
		data: {
			pageInfo: CONSTANTES_PAGE_INFO
		},
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'constantes/:surveillanceId',
		component: ConstantesPageComponent,
		data: {
			pageInfo: CONSTANTES_PAGE_INFO
		},
		resolve: { surveillances: ConstantesResolver },
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'injections/:surveillanceId',
		component: ConstantesPageComponent,
		data: {
			pageInfo: CONSTANTES_PAGE_INFO
		},
		resolve: { surveillances: ConstantesResolver },
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'constantes/:surveillanceId/edit',
		component: ConstantesPageComponent,
		data: {
			pageInfo: CONSTANTES_PAGE_INFO
		},
		resolve: { surveillances: ConstantesResolver },
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'injections/:surveillanceId/edit',
		component: ConstantesPageComponent,
		data: {
			pageInfo: CONSTANTES_PAGE_INFO
		},
		resolve: { surveillances: ConstantesResolver },
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'retrocession',
		loadChildren: () => import('./retrocession/retrocession.module').then(m => m.RetrocessionPageModule),
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'planning-rh',
		loadChildren: () => import('./planning-rh/planning-rh.module').then(m => m.PlanningRHPageModule),
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'settings',
		loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule),
		data: {
			permissions: [{ permission: EPermission.parameters, type: "read" }] as IPermission[]
		},
		canActivate: [AuthenticatedGuard, HasPermissionGuard]
	},
	{
		path: 'invoices/:invoiceId',
		loadChildren: () => import('./invoice/invoice.module').then(m => m.InvoicePageModule),
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'fsv-lecture-carte',
		loadChildren: () => import('./fsv-lecture-carte/fsv-lecture-carte.module').then(m => m.FsvLectureCartePageModule),
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'fsv-patient/:patientId',
		loadChildren: () => import('./fsv-patient/fsv-patient.module').then(m => m.FsvPatientPageModule),
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'fsv-invoice/:invoiceId',
		loadChildren: () => import('./fsv-invoice/fsv-invoice.module').then(m => m.FsvInvoicePageModule),
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'desmos-sv',
		loadChildren: () => import('./desmos-sv/desmos-sv.module').then(m => m.DesmosSVPageModule),
		canActivate: [AuthenticatedGuard]
	},
	{
		path: 'configuration-ps',
		loadChildren: () => import('./configuration-ps/configuration-ps.module').then(m => m.ConfigurationPSPageModule),
		canActivate: [AuthenticatedGuard]
	},
  {
    path: 'retour-noemie',
    loadChildren: () => import('./retour-noemie/retour-noemie.module').then( m => m.RetourNoemiePageModule)
  },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
