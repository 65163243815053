<div class="modal-acte-manager dis-flex flex-col">
	<!-- Zone lettre-clé / coefficient / prix de l'acte. -->
	<mat-expansion-panel>
		<mat-expansion-panel-header>
			<mat-panel-title>
				<h3>Paramètres de l'acte</h3>
			</mat-panel-title>
		</mat-expansion-panel-header>
		<ion-grid>
			<ion-row class="label-acte">
				<ion-note>{{acte.label}}</ion-note>
			</ion-row>
			<ion-row class="padding-params">
				<ion-col size="3">
					<ion-label for="iptLettreCle" class="txt12px">Lettre-clé</ion-label>
					<ion-input id="iptLettreCle" type="text" [(ngModel)]="acte.keyLetters"
						class="width100pc txt-center background-parametres" required readonly></ion-input>
				</ion-col>
				<ion-col size="4">
					<ion-label for="iptCoeff" class="txt12px">Coefficient</ion-label>
					<ion-input id="iptCoeff" type="number" [min]="0" class="width100pc txt-center background-parametres"
						[(ngModel)]="acte.priceCoefficient" (ngModelChange)="calculatePrice()" required readonly>
					</ion-input>
					<p *ngIf="acte.initialPriceCoefficient" class="txt-legende">Coef. NGAP =
						{{ acte.initialPriceCoefficient }}</p>
				</ion-col>
				<ion-col size="4">
					<ion-label for="iptTotalActe" class="txt12px">Total acte</ion-label>
					<div class="ctn-input-symbole dis-flex row-center width100pc">
						<ion-input id="iptTotalActe" type="number" [min]="0" [(ngModel)]="acte.price"
							class="width100pc txt-center background-parametres" required readonly></ion-input>
						<span class="txt-symbole">€</span>
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>
	</mat-expansion-panel>

	<!-- Zone CPAM. -->
	<div class="ctn-item date-area dis-flex row-wrap">
		<h3>ALD Exonérante</h3>
		<ion-item lines="none">
			<ion-toggle [(ngModel)]="acte.isAldExonerante"></ion-toggle>
		</ion-item>
	</div>

	<div class="ctn-item date-area dis-flex row-wrap">
		<h3>Accord préalable</h3>
		<ion-item lines="none">
			<ion-toggle [(ngModel)]="acte.isPriorAgreement"></ion-toggle>
		</ion-item>
	</div>

	<!-- Zone date. -->
	<div class="ctn-item dis-flex row-wrap first-visit-date">
		<div class="date-area">
			<h3>À partir du</h3>
			<span (click)="onActeDateClick()">
				<osapp-date-time [params]="startDateParams" [model]="acte.startDate || traitement.beginDate"
					(modelChange)="onStartDateChanged($event)"
					[disabled]="hasEndDate(acte) || hasValidatedOrCanceledSeancesByActeGuid?.get(acte.guid)">
				</osapp-date-time>
			</span>
		</div>
		<div *ngIf="acte.endDate as endDate" class="date-area">
			<h3>Intéruption le</h3>
			<ion-item lines="none" class="center-date interuption">
				<ion-label>{{ endDate | dateWithLocale : 'fr-FR' : 'dd/MM/yy' }}</ion-label>
			</ion-item>
		</div>
	</div>

	<!-- Zone lieu -->
	<div class="ctn-item dis-flex row-wrap">
		<h3>Lieu</h3>
		<div class="date-constraints-wrapper">
			<div class="tags">
				<osapp-selector displayMode="tags" [options]="placeOptions" [preselectedValues]="acte.place"
					scrollWrapper="false" (selectionChanged)="onPlaceChanged($event)">
				</osapp-selector>
			</div>
		</div>
	</div>

	<!-- Zone Tranche horaire. -->
	<div class="ctn-item dis-flex row-wrap">
		<h3>Dans la journée</h3>
		<div class="date-constraints-wrapper">
			<div class="tags">
				<osapp-selector #rangeSelector displayMode="tags" [options]="rangeOptions"
					[preselectedValues]="rangeRecurrences" scrollWrapper="false" (selectionChanged)="onRangesChanged($event)"
					multiple [disabled]="hasEndDate(acte)">
				</osapp-selector>
			</div>
			<div>
				<osapp-date-time #timeConstraintPicker class="hidden" [params]="dateTimeParams" [disabled]="hasEndDate(acte)"
					(modelChange)="onTimeConstraintChanged($event)">
				</osapp-date-time>
				<div class="btn-hours">
					<ion-button (click)="timeConstraintPicker.pickDate()" size="small" class="btn-add-hour">
						<ion-icon name="add-outline" slot="start"></ion-icon>
						??h??
					</ion-button>
					<ion-button *ngFor="let recurrence of timeRecurrences" (click)="removeTimeConstraint(recurrence)" size="small"
						class="btn-tag">
						<ion-icon name="close-outline" slot="end"></ion-icon>
						{{timeToString(recurrence)}}
					</ion-button>
				</div>
			</div>
		</div>
	</div>

	<!-- Zone fréquence. -->
	<div *ngIf="modalType !== modalActeType.Seance" class="item-frequence-acte ctn-item dis-flex row-wrap">
		<div class="date-area repeat-title">
			<h3>Répéter</h3>
			<ion-item lines="none">
				<ion-label>Dimanche et jour férié inclus</ion-label>
				<ion-toggle [(ngModel)]="acte.sundayAndPublicHolidays"></ion-toggle>
			</ion-item>
		</div>
		<div class="frequence-acte">
			<mat-chip-list [selectable]="hasEndDate(acte)">
				<div class="select-repeat">
					<mat-chip #dates="matChip" color="primary" [value]="dureeEnum.dates"
						[selected]="recurrence.durationType === dureeEnum.dates" (click)="onDureeSelected(dates.value)">
						<ion-label>À dates fixes</ion-label>
						<ion-icon *ngIf="dates.selected" name="checkmark"></ion-icon>
					</mat-chip>
					<mat-chip #jusquADate="matChip" color="primary" [value]="dureeEnum.jusquADate"
						[selected]="recurrence.durationType === dureeEnum.jusquADate" (click)="onDureeSelected(jusquADate.value)">
						<ion-label>Jusqu'au</ion-label>
						<ion-icon *ngIf="jusquADate.selected" name="checkmark"></ion-icon>
					</mat-chip>
					<mat-chip #days="matChip" color="primary" [value]="dureeEnum.jour"
						[selected]="recurrence.durationType === dureeEnum.jour" (click)="onDureeSelected(days.value)">
						<ion-label>Pendant {{recurrence.durationType === dureeEnum.jour ?
							recurrence.durationValue : "?"}} jours</ion-label>
						<ion-icon *ngIf="days.selected" name="checkmark"></ion-icon>
					</mat-chip>
					<mat-chip #months="matChip" color="primary" [value]="dureeEnum.mois"
						[selected]="recurrence.durationType === dureeEnum.mois" (click)="onDureeSelected(months.value)">
						<ion-label>Pendant {{recurrence.durationType === dureeEnum.mois ?
							recurrence.durationValue : "?"}} mois</ion-label>
						<ion-icon *ngIf="months.selected" name="checkmark"></ion-icon>
					</mat-chip>
				</div>
			</mat-chip-list>

			<div *ngIf="recurrence.durationType === dureeEnum.dates" class="fixed-dates-wrapper">
				<osapp-date-time #dateConstraintPicker class="hidden" [params]="fixedDateParams" [disabled]="hasEndDate(acte)"
					(modelChange)="addDateValue($event)">
				</osapp-date-time>
				<ion-button (click)="hasEndDate(acte) ? '' : dateConstraintPicker.pickDate()" size="small" class="btn-add-hour">
					<ion-icon name="add-outline" slot="start"></ion-icon>
					??/??/????
				</ion-button>
				<ion-button *ngFor="let date of recurrence.durationValue" (click)="removeDateValue(date)" size="small"
					class="btn-tag">
					<ion-icon *ngIf="recurrence.durationValue.length > 1" name="close-outline" slot="end">
					</ion-icon>
					{{date | dateWithLocale : 'fr-FR' : C_DAY_DATE_FORMAT}}
				</ion-button>
			</div>
			<div *ngIf="recurrence.durationType === dureeEnum.jusquADate" class="fixed-dates-wrapper">
				<osapp-date-time #dateConstraintPicker class="hidden" [params]="fixedDateParams" [disabled]="hasEndDate(acte)"
					(modelChange)="addDateValue($event)">
				</osapp-date-time>
				<ion-button *ngIf="recurrence.durationValue.length == 0"
					(click)="hasEndDate(acte) ? '' : dateConstraintPicker.pickDate()" size="small" class="btn-add-hour">
					<ion-icon name="add-outline" slot="start"></ion-icon>
					??/??/????
				</ion-button>
				<ion-button *ngFor="let date of recurrence.durationValue" (click)="removeDateValue(date)" size="small"
					class="btn-tag">
					<ion-icon *ngIf="recurrence.durationValue.length == 1" name="close-outline" slot="end">
					</ion-icon>
					{{date | dateWithLocale : 'fr-FR' : C_DAY_DATE_FORMAT}}
				</ion-button>
			</div>
			<ng-container
				*ngIf="recurrence.durationType === dureeEnum.jour || recurrence.durationType === dureeEnum.mois || recurrence.durationType === dureeEnum.jusquADate">
				<mat-chip-list selectable>
					<mat-chip #day="matChip" color="primary" [value]="repetitionEnum.jours"
						[selected]="recurrence.repetitionType === repetitionEnum.jours" (click)="onRepetitionChanged(day.value)">
						<ion-label>Les jours suivants</ion-label>
						<ion-icon *ngIf="day.selected" name="checkmark"></ion-icon>
					</mat-chip>
					<mat-chip #xDay="matChip" color="primary" [value]="repetitionEnum.tsLesXJours"
						[selected]="recurrence.repetitionType === repetitionEnum.tsLesXJours"
						(click)="onRepetitionChanged(xDay.value)">
						<ion-label>Tous les {{xDay.selected ?
							recurrence.repetitionValue : "?"}} jours</ion-label>
						<ion-icon *ngIf="xDay.selected" name="checkmark"></ion-icon>
					</mat-chip>
					<ng-container *ngIf="recurrence.durationType === dureeEnum.mois">
						<mat-chip #xMonth="matChip" color="primary" [value]="repetitionEnum.tsLesXMois"
							[selected]="recurrence.repetitionType === repetitionEnum.tsLesXMois"
							(click)="onRepetitionChanged(xMonth.value)">
							<ion-label>Tous les {{xMonth.selected ? recurrence.repetitionValue : "?"}} mois</ion-label>
							<ion-icon *ngIf="xMonth.selected" name="checkmark"></ion-icon>
						</mat-chip>
					</ng-container>
				</mat-chip-list>
				<div class="tags-days">
					<osapp-selector *ngIf="recurrence.repetitionType === repetitionEnum.jours" displayMode="tags"
						[options]="dayOptions" [preselectedValues]="recurrence.repetitionValue" [min]="1"
						(selectionChanged)="onRepetitionValueChanged($event)" multiple scrollWrapper="false"
						[disabled]="hasEndDate(acte)">
					</osapp-selector>
				</div>
			</ng-container>
			<ng-container *ngIf="recurrence.repetitionType === repetitionEnum.jours">
				<mat-chip-list selectable>
					<mat-chip #everyWeek="matChip" color="primary" [value]="weekRepetitionType.everyWeek"
						[selected]="acte.weekRepetition.type === weekRepetitionType.everyWeek"
						(click)="onWeekRepetitionChanged(everyWeek.value)">
						<ion-label>Toutes les semaines</ion-label>
						<ion-icon *ngIf="everyWeek.selected" name="checkmark"></ion-icon>
					</mat-chip>
					<mat-chip *ngIf="recurrence.durationType === dureeEnum.mois" #everyNWeek="matChip" color="primary"
						[value]="weekRepetitionType.everyNWeek"
						[selected]="acte.weekRepetition.type === weekRepetitionType.everyNWeek"
						(click)="onWeekRepetitionChanged(everyNWeek.value)">
						<ion-label>Toutes les {{acte.weekRepetition.type === weekRepetitionType.everyNWeek ?
							acte.weekRepetition.value :
							"?"}} semaines</ion-label>
						<ion-icon *ngIf="everyNWeek.selected" name="checkmark"></ion-icon>
					</mat-chip>
				</mat-chip-list>
			</ng-container>
		</div>
	</div>

	<!-- Zone planification. -->
	<div class="ctn-item dis-flex flex-col row-wrap fle">
		<h3>Planification</h3>
		<ion-label class="lbl-planif">Attribué en fonction du planning RH</ion-label>
	</div>

	<!-- Zone observation. -->
	<div class="ctn-item dis-flex row-wrap">
		<h3>Observations</h3>
		<mat-form-field appearance="outline" class="observation-field">
			<mat-label>Laissez un commentaire</mat-label>
			<textarea [(ngModel)]="acte.observations" matInput></textarea>
		</mat-form-field>
	</div>
</div>