<osapp-header-modal title="Contexte de facturation">
</osapp-header-modal>

<ion-content>

	<ion-grid *ngIf="invoice">
		<ion-row>
			<ion-col size="5">
				<div class="just-flex row-center gap h-max">
					<ion-label>Mode de facturation</ion-label>
				</div>
			</ion-col>

			<ion-col size="7">
				<mat-form-field appearance="outline">
					<mat-select [(value)]="invoice.securisationMode">
						<mat-option *ngFor="let item of modeOptions" [value]="item.value" (click)="onModeOptionClick()">
							{{item.label}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</ion-col>
		</ion-row>

		<ion-row>
			<ion-col size="5">
				<ion-label>Créer</ion-label>
			</ion-col>

			<ion-col size="7" class="just-flex gap">
				<div class="just-flex row-center gap">
					<div (click)="onFseChanged()" class="checkbox-ctn">
						<ion-checkbox mode="md" class="disable-pointer" [checked]="fse"></ion-checkbox>
					</div>
					<ion-label>FSE</ion-label>
				</div>
				<div class="just-flex row-center gap">
					<div (click)="onDreChanged()" class="checkbox-ctn">
						<ion-checkbox mode="md" class="disable-pointer" [checked]="invoice.dre"></ion-checkbox>
					</div>
					<ion-label>DRE</ion-label>
				</div>
			</ion-col>
		</ion-row>

		<ion-row>
			<ion-col size="5">
				<ion-label>Tiers Payant</ion-label>
			</ion-col>

			<ion-col size="7" class="just-flex gap">
				<div class="just-flex row-center gap">
					<div (click)="onAmoTPChanged()" class="checkbox-ctn">
						<ion-checkbox mode="md" class="disable-pointer" [checked]="invoice.amoTp"></ion-checkbox>
					</div>
					<ion-label>AMO</ion-label>
				</div>
				<div class="just-flex row-center gap">
					<div (click)="onAmcTPChanged()" class="checkbox-ctn">
						<ion-checkbox mode="md" class="disable-pointer" [checked]="invoice.amcTp"></ion-checkbox>
					</div>
					<ion-label>AMC</ion-label>
				</div>
			</ion-col>
		</ion-row>

	</ion-grid>

</ion-content>

<ion-fab vertical="bottom" horizontal="end" slot="fixed">
	<ion-fab-button color="success" (click)="close(true)" title="Valider">
		<ion-icon name="checkmark"></ion-icon>
	</ion-fab-button>
</ion-fab>