import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DateHelper } from '@osapp/helpers';
import { ISeance } from '../../../../../model/ISeance';
import { Traitement } from '../../../../../model/Traitement';
import { IPatient } from '../../../../../modules/patients/model/IPatient';
import { StoredSeance } from '../../../../models/StoredSeance';

@Component({
  selector: 'di-groupe-liste-seance',
  templateUrl: './groupe-liste-seances-component.html',
  styleUrls: ['./groupe-liste-seances-component.scss']
})
export class GroupeListeSeancesComponent  implements OnInit,OnChanges {
	
	
  @Input() ordonnance: Traitement;
  @Input() patient: IPatient;
	@Input() seances?: StoredSeance[];

	public groupedSeances: { date: string, seances: ISeance[] }[] = [];

	ngOnInit(): void {
		this.groupSeancesByDate();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['seances']) {	
			this.groupSeancesByDate();
		}
	}



	private groupSeancesByDate(): void {
    const seancesByDate: { [key: string]: ISeance[] } = {};
    const today = new Date();
  

    this.seances.forEach(seance => {
      const dateKey = this.getFormattedDate(new Date(seance.startDate), today);
      if (!seancesByDate[dateKey]) {
        seancesByDate[dateKey] = [];
      }
      seancesByDate[dateKey].push(seance);
    });


    this.groupedSeances = Object.keys(seancesByDate).map(date => ({
      date: date,
      seances: seancesByDate[date]
    }));
  }

  public getFormattedDate(date: Date, today: Date): string {
   
    const dateOnly = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const todayOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    const isToday = DateHelper.compareTwoDates(dateOnly, todayOnly) === 0;

    return isToday
        ? `Aujourd'hui, ${date.toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`
        : date.toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
}
}
