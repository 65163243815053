<osapp-header-modal title="Explications"></osapp-header-modal>

<ion-content>
	<osapp-date-time [(model)]="breakDate" [params]="dateTimeSpinnerParams"></osapp-date-time>

	<mat-form-field appearance="outline" class="center-explains">
		<mat-label>Explications</mat-label>
		<textarea matInput matTextareaAutosize matAutosizeMinRows=2 matAutosizeMaxRows=6
			[(ngModel)]="explanation"></textarea>
	</mat-form-field>
</ion-content>

<ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
	<ion-fab-button color="success" (click)="validate()" title="Valider">
		<ion-icon name="checkmark"></ion-icon>
	</ion-fab-button>
</ion-fab>