import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'di-confirmation-mdp-change',
  templateUrl: './confirmation-mdp-change.page.html',
  styleUrls: ['./confirmation-mdp-change.page.scss'],
})

export class ConfirmationMdpChangePage implements OnInit {

	constructor(
		private router: Router
	) { }
		
	ngOnInit(): void {
	}

	public navigateToLogin(){
		this.router.navigate(['/authenticator']);
	}
}
