import { Component, Input } from '@angular/core';
import { ComponentBase, DateHelper } from '@osapp/helpers';
import { IEvent } from '@osapp/model';

@Component({
	selector: 'idl-tournee-pause',
	templateUrl: './tournee-pause.component.html',
	styleUrls: ['./tournee-pause.component.scss']
})
export class TourneePauseComponent extends ComponentBase {

	//#region FIELDS

	/** Taille pour une petite pause. */
	private static readonly C_SMALL_PAUSE_SLOT_HEIGHT = 20;
	/** Taille pour une grande pause. */
	private static readonly C_LARGE_PAUSE_SLOT_HEIGHT = 40;

	//#endregion

	//#region PROPERTIES

	@Input() public pause: IEvent;

	public get timelineExists(): boolean {
		const ldNow = new Date();

		return DateHelper.compareTwoDates(ldNow, this.pause.startDate) > 0 &&
			DateHelper.compareTwoDates(ldNow, this.pause.endDate) < 0;
	}

	//#endregion

	//#region METHODS

	constructor() { super(); }

	public getDisplay(): string {
		if (!this.pause)
			return "";
		else if (this.pause.duration > 60)
			return "> 1h";
		else
			return `${this.pause.duration}mn`;
	}

	public getHeight(): number {
		let lnHeight: number = (this.pause ? this.pause.duration : 0) + TourneePauseComponent.C_SMALL_PAUSE_SLOT_HEIGHT;

		if (lnHeight > TourneePauseComponent.C_LARGE_PAUSE_SLOT_HEIGHT)
			lnHeight = TourneePauseComponent.C_LARGE_PAUSE_SLOT_HEIGHT;

		return lnHeight;
	}

	/** Renvoi la distance entre le début de la pause et la timeline (en pourcentage)
	 * Cette distance est calculée de la façon suivante : ((date courante - date de début de la pause) / 60000) * 100 / durée de la pause 
	 */
	public getTimelineFromTop(): number {
		return (DateHelper.compareTwoDates(new Date(), this.pause.startDate) / 60000) * 100 / this.pause.duration;
	}

	//#endregion
}