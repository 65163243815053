import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ModalComponentBase } from '../../../modules/modal/model/ModalComponentBase';
import { PlatformService } from '../../../services/platform.service';
import { IUnlockDeviceResult } from './IUnlockDeviceResult';

@Component({
	templateUrl: './unlock-device-modal.component.html',
	styleUrls: ['./unlock-device-modal.component.scss'],
})
export class UnlockDeviceModalComponent extends ModalComponentBase<IUnlockDeviceResult> implements OnInit {

	//#region PROPERTIES

	public firstname = "";
	public lastname = "";
	public formControl: UntypedFormGroup;

	//#endregion

	//#region METHODS

	constructor(
		private isvcFormBuilder: UntypedFormBuilder,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetectorRef);

		this.formControl = this.isvcFormBuilder.group({
			lastname: new UntypedFormControl("", { validators: Validators.required }),
			firstname: new UntypedFormControl(""),
		});
	}

	/** @override Le parent initialise l'abonnement au bouton physique 'back', pas besoin pour ce composant, il s'en occupe. */
	public ngOnInit(): void {
		// Surcharge le comportement du bouton physique de retour.
		this.moBackButtonSubscription = this.isvcPlatform.getBackButtonSubscription(() => this.cancel());
	}

	/** Méthode appelée lorsque l'utilisateur souhaite annuler et fermer la modale. */
	public cancel(): void {
		this.close({ cancel: true } as IUnlockDeviceResult);
	}

	/** Méthode appelée lorsque l'utilisateur souhaite envoyer le mail. */
	public validate(): void {
		this.close({ firstname: this.firstname, lastname: this.lastname, cancel: false } as IUnlockDeviceResult);
	}

	//#endregion

}