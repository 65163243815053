import { Component, Input } from '@angular/core';

@Component({
	selector: 'calao-database-sync-animated-icon',
	templateUrl: './database-sync-animated-icon.component.html',
	styleUrls: ['./database-sync-animated-icon.component.scss'],
})
export class DatabaseSyncAnimatedIconComponent {

	//#region PROPERTIES

	@Input() public className: string;

	//#endregion

	constructor() { }

}