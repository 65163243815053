import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BlobUrlManager } from '@osapp/components/image/model/BlobUrlManager';
import { FileHelper } from '@osapp/helpers';
import { IGalleryFile } from '@osapp/model';
import { IDmsData } from '@osapp/modules/dms/model/IDmsData';
import { DmsService } from '@osapp/modules/dms/services/dms.service';
import { GalleryService } from '@osapp/services';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'di-carte-photo',
  templateUrl: './carte-photo.component.html',
  styleUrls: ['./carte-photo.component.scss'],
})
export class CartePhotoComponent implements OnInit {

	isLargeScreen: boolean = false;
	private readonly moBlobUrlManager = new BlobUrlManager();


  constructor(
		private svcDms:DmsService,
		private readonly sanitizer: DomSanitizer,
		private readonly svcGallery : GalleryService) {}

  ngOnInit(): void {
		this.isImageAvailable = false;
		if (this.isGalleryFile(this.photoFile)) {
			this.svcDms.get(this.photoFile.guid).pipe(
				tap((doc : IDmsData) => {
					this.fileData = doc;
					this.checkFileType();
					if ( this.fileData.file.File) {
						if(this.isFile)
						{
							this.fileName = decodeURIComponent(escape(this.fileData.file.Name));
						}
						const lsValueToSanitize: string = this.fileData.file.File instanceof Blob ? this.moBlobUrlManager.createUrl(this.fileData.file.File) : this.fileData.file.File;
						this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(lsValueToSanitize) as string;
						this.isImageAvailable = true;
					}
				}),
				catchError(error => {
					this.isImageAvailable = false;
					return of(null);
				})
			)
			.subscribe()
		} else if (this.photoFile instanceof File) {
			this.checkFileType();
      const fileReader = new FileReader();
      fileReader.onload = () => {
				this.fileUrl = fileReader.result;
        this.isImageAvailable = true;
      };
      fileReader.readAsDataURL(this.photoFile);
    }
  }

  @Input() photoFile: IGalleryFile | File;
  @Input() edit: boolean = false;
  @Output() delete = new EventEmitter<File | IGalleryFile>();
  isFile: boolean = false;
	fileData : IDmsData;
	fileName : string;


	fileUrl :string | ArrayBuffer = '';
  isOverlayOpen: boolean = false;
  isImageAvailable: boolean = true;

	private isGalleryFile(file: any): file is IGalleryFile {
    return file && (file as IGalleryFile).guid !== undefined;
  }


  openOverlay(): void {
    this.isOverlayOpen = true;
  }

  closeOverlay(): void {
    this.isOverlayOpen = false;
  }

	download(){
		this.svcGallery.innerOpenFile(this.fileData.file,this.fileName).subscribe();
	}

  deletePhoto(): void {  
    this.delete.emit(this.photoFile);
	}

  checkFileType(): void {
    const imageTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
		if (this.photoFile instanceof File) {
			const fileExtension = FileHelper.getExtensionFromMimeType(this.photoFile.type);
    	this.isFile = !imageTypes.includes(fileExtension);
		}
		else
		{
			const fileExtension = FileHelper.getExtensionFromMimeType(this.fileData.file.MimeType);
    	this.isFile = !imageTypes.includes(fileExtension);
		}
		if(this.isFile)
		{
			this.fileName =  this.photoFile instanceof File ? this.photoFile.name : this.photoFile.file.Name
		}
    
  }
}
