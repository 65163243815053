import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IonItemSliding } from '@ionic/angular';
import { ArrayHelper, ComponentBase } from '@osapp/helpers';
import { IContact } from '@osapp/model/contacts/IContact';
import { EAvatarSize } from '@osapp/model/picture/EAvatarSize';
import { IAvatar } from '@osapp/model/picture/IAvatar';
import { PermissionsService } from '@osapp/modules/permissions/services/permissions.service';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { ContactsService } from '@osapp/services/contacts.service';
import { Ordonnance } from '../../../ordonnances/models/ordonnance';
import { FacturationService } from '../../facturation.service';
import { EInvoiceStatus } from '../../models/einvoice-status';
import { IdlPrestation } from '../../models/idl-prestation';
import { Invoice } from '../../models/invoice';

@Component({
	selector: 'idl-invoice-item',
	templateUrl: './invoice-item.component.html',
	styleUrls: ['./invoice-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceItemComponent extends ComponentBase {

	//#region FIELDS
	@Output("onInvoiceCanceled") private readonly onInvoiceCanceled = new EventEmitter<Invoice>();
	//#endregion

	//#region PROPERTIES

	@Input() public invoice: Invoice;
	@Input() public ordonnance: Ordonnance;
	@Input() public intervenant: IContact;
	@Input() public prescripteur: IContact;
	@Input() public readonly: boolean;
	@Input() public arePatientAndAMOUpToDate: boolean;

	public readonly TPOptions: ReadonlyArray<ISelectOption<boolean>> = [
		{ label: "Oui", value: true },
		{ label: "Non", value: false }
	];

	public get isModeAgrement(): boolean {
		return false;
	}

	//#endregion

	//#region METHODS

	constructor(
		public readonly isvcPermissions: PermissionsService,
		private readonly isvcFacturation: FacturationService,
		private readonly ioRouter: Router,
		poChangeDetectorRef: ChangeDetectorRef,
	) {
		super(poChangeDetectorRef);
	}

	public getContactAvatar(poContact: IContact): IAvatar {
		return ContactsService.createContactAvatar(poContact, EAvatarSize.big);
	}

	public goToInvoice(poInvoiceId: string): void {
		this.ioRouter.navigate([this.isModeAgrement ? "/fsv-invoice" : "/invoices", poInvoiceId]);
	}

	public async invoiceAction(poInvoice: Invoice): Promise<void> {
		await this.isvcFacturation.forwardAction(poInvoice.patientId, [poInvoice], this.arePatientAndAMOUpToDate);

		if (this.invoice.status.value === EInvoiceStatus.priced) {
			this.isvcFacturation.showErreursSVPopup(this.invoice);
		}

		this.detectChanges();
	}

	public async exportInvoiceAgrement(poInvoice: Invoice): Promise<void> {
		const exportedInvoice: Invoice = await this.isvcFacturation.exportInvoiceAgrement(poInvoice);
		if (exportedInvoice) {
			poInvoice._rev = exportedInvoice._rev;
			poInvoice.status.value = exportedInvoice.status.value;
			poInvoice.externalId = exportedInvoice.externalId;
			poInvoice.invoiceNumber = exportedInvoice.invoiceNumber;
			this.isvcFacturation.saveInvoice(poInvoice).toPromise();
		}
	}

	public exportInvoicePdf(poInvoice: Invoice): void {
		if (this.isModeAgrement)
			this.ioRouter.navigate(["/fsv-invoice", poInvoice._id], { queryParams: { pdf: true } });
		else
			this.isvcFacturation.exportInvoicePdf(poInvoice);
	}

	public async invoiceCancelAction(poInvoice: Invoice): Promise<void> {
		const idTraitement: string = await this.isvcFacturation.cancelAction(poInvoice.patientId, [poInvoice]);
		if (idTraitement) {
			this.isvcFacturation.triggerMajPatientPanel(idTraitement);
			this.onInvoiceCanceled.emit(poInvoice);
		}
		this.detectChanges();
	}

	public onAmcTPChanged(paValues: boolean[]): void {
		this.invoice.amcTp = ArrayHelper.getFirstElement(paValues);
	}

	public onAmoTPChanged(paValues: boolean[]): void {
		this.invoice.amoTp = ArrayHelper.getFirstElement(paValues);
	}

	/** Ouvre ou ferme un itemSliding en fonction de l'état avant slide.\
	 * On peut également stopper la propagation de l'événement de clic.
	 * @param poItemSliding Objet d'options qu'on veut ouvrir ou fermer (animation de swipe).
	 * @param poEvent Événement de clic à stopper si renseigné.
	 */
	public async openOrCloseItemSliding(poItemSliding: IonItemSliding, poEvent?: MouseEvent): Promise<void> {
		if (poEvent)
			poEvent.stopPropagation();	// Empêche la possible navigation vers l'item cliqué.

		// Si l'item est ouvert, la valeur est strictement supérieure à 0, sinon c'est que l'item est fermé.
		const lnAmountOpenPixels: number = await poItemSliding.getOpenAmount();

		if (lnAmountOpenPixels > 0) // Item ouvert, on veut le fermer
			poItemSliding.close();
		else // Item fermé, on veut l'ouvrir.
			poItemSliding.open("end");
	}

	//#endregion METHODS

}
