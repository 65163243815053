import { Component, Input } from '@angular/core';
import { Ordonnance } from '../../models/ordonnance';

@Component({
	selector: 'idl-ordonnance-status-icons',
	templateUrl: './ordonnance-status-icons.component.html',
	styleUrls: ['./ordonnance-status-icons.component.scss'],
})
export class OrdonnanceStatusIconsComponent {

	//#region PROPERTIES

	@Input() public ordonnance: Ordonnance;

	//#endregion

	constructor() { }

}
