import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalOptions, OverlayEventDetail } from '@ionic/core';
import { from, Observable } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { IIKParams } from '../modules/traitement/model/IIKParams';
import { IIKResponse } from '../modules/traitement/model/IIKResponse';
import { IndemniteKilometriqueModalComponent } from '../modules/traitement/slides-traitement/indemnites/indemnite-kilometrique-modal/indemnite-kilometrique-modal.component';

@Injectable()
export class IdlModalService {

	//#region METHODS

	constructor(private ioModalCtrl: ModalController) { }

	/** Ouvre la modale des indemnités kilométriques et retourne la réponse de celle-ci.
	 * @param poParams Paramètres pour la modale.
	 * @param pbFilterResponse Indique s'il faut filtrer les absences de réponse de la modale ou non, `true` par défaut.
	 * @returns La réponse obtenue lors de la fermeture de la modale, peut être `undefined`.
	 */
	public openIndemniteKilometriqueModal(poParams: IIKParams, pbFilterResponse: boolean = true): Observable<IIKResponse> {
		const loModalOptions: ModalOptions = {
			component: IndemniteKilometriqueModalComponent,
			componentProps: {
				params: poParams
			}
		};

		return from(this.ioModalCtrl.create(loModalOptions))
			.pipe(
				tap((poModal: HTMLIonModalElement) => poModal.present()),
				mergeMap((poModal: HTMLIonModalElement) => poModal.onDidDismiss()),
				filter((poResult: OverlayEventDetail<IIKResponse>) => { return !!poResult.data || !pbFilterResponse; }),
				map((poResult: OverlayEventDetail<IIKResponse>) => poResult.data)
			);
	}

	//#endregion
}