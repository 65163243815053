import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalOptions } from '@ionic/core';
import { DateTimePickerComponent } from '@osapp/components/date';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ContactHelper } from '@osapp/helpers/contactHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { StoreHelper } from '@osapp/helpers/storeHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { UserData } from '@osapp/model/application/UserData';
import { IEvent } from '@osapp/model/calendar/IEvent';
import { EDateTimePickerMode } from '@osapp/model/date/EDateTimePickerMode';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { IDateTimePickerParams } from '@osapp/model/date/IDateTimePickerParams';
import { ICoordinates } from '@osapp/model/navigation/ICoordinates';
import { EAvatarSize } from '@osapp/model/picture/EAvatarSize';
import { IAvatar } from '@osapp/model/picture/IAvatar';
import { IPopoverItemParams } from '@osapp/model/popover/IPopoverItemParams';
import { IUiResponse } from '@osapp/model/uiMessage/IUiResponse';
import { OsappError } from '@osapp/modules/errors/model/OsappError';
import { IHydratedGroupMember } from '@osapp/modules/groups/model/IHydratedGroupMember';
import { Loader } from '@osapp/modules/loading/Loader';
import { EModalSize } from '@osapp/modules/modal';
import { ModalService } from '@osapp/modules/modal/services/modal.service';
import { Site } from '@osapp/modules/sites/models/site';
import { ContactsService } from '@osapp/services/contacts.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '@osapp/services/interfaces/ShowMessageParamsToast';
import { LoadingService } from '@osapp/services/loading.service';
import { NavigationService } from '@osapp/services/navigation.service';
import { PopoverService } from '@osapp/services/popover.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { intersectionBy } from 'lodash';
import { BehaviorSubject, EMPTY, Observable, defer, of, throwError } from 'rxjs';
import { catchError, filter, finalize, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { Acte } from '../../../../model/Acte';
import { EPlace } from '../../../../model/EPlace';
import { EStatusSeance } from '../../../../model/EStatusSeance';
import { IIdelizyContact } from '../../../../model/IIdelizyContact';
import { IPreviousAndNextSeances } from '../../../../model/IPreviousAndNextSeances';
import { Seance } from '../../../../model/Seance';
import { Traitement } from '../../../../model/Traitement';
import { TraitementSlideParentBase } from '../../../../model/TraitementSlideParentBase';
import { ISeanceTournee } from '../../../../model/seances/ISeanceTournee';
import { SeanceService } from '../../../../services/seance.service';
import { TraitementService } from '../../../../services/traitement.service';
import { EResumeActeMode } from '../../../actes/model/EResumeActeMode';
import { CancelConstraint } from '../../../actes/model/cancel-constraint';
import { PlaceConstraint } from '../../../actes/model/place-constraint';
import { ReactivateConstraint } from '../../../actes/model/reactivate-constraint';
import { IPatient } from '../../../patients/model/IPatient';
import { PatientsService } from '../../../patients/services/patients.service';
import { EChoiceFilter } from '../../../seances/choose-seances-to-modify-modal/EChoiceFilter';
import { IChoice } from '../../../seances/choose-seances-to-modify-modal/IChoice';
import { IChooseSeancesToModifiyResponse } from '../../../seances/model/ichoose-seances-to-modifiy-response';
import { ISeanceModalResponse } from '../../model/ISeanceModalResponse';
import { TourneesService } from '../../services/tournees.service';
import { SeancePlaceModalComponent } from '../seance-place-modal/seance-place-modal.component';
import { SeanceModalComponent } from '../seance/seance-modal.component';

@Component({
	selector: "idl-tournee",
	templateUrl: './tournee.component.html',
	styleUrls: ['./tournee.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TourneeComponent extends ComponentBase {

	//#region FIELDS

	private maSeances: Seance[];
	private moEditingDateSeanceTournee: ISeanceTournee;

	//#endregion

	//#region PROPERTIES

	private mbIsPlanificationMode: boolean;
	public get isPlanificationMode(): boolean { return this.mbIsPlanificationMode; }
	@Input() public set isPlanificationMode(pbIsPlanificationMode: boolean) {
		if (this.mbIsPlanificationMode !== pbIsPlanificationMode) {
			this.mbIsPlanificationMode = pbIsPlanificationMode;
			this.detectChanges();
		}
	}

	private mbShowPlannedSeanceStatuses: boolean;
	public get showPlannedSeanceStatuses(): boolean { return this.mbShowPlannedSeanceStatuses; }
	@Input() public set showPlannedSeanceStatuses(pbShowPlannedSeanceStatuses: boolean) {
		if (this.mbIsPlanificationMode !== pbShowPlannedSeanceStatuses) {
			this.mbShowPlannedSeanceStatuses = pbShowPlannedSeanceStatuses;
			this.detectChanges();
		}
	}

	private msTitle: string;
	public get title(): string { return this.msTitle; }
	@Input()
	public set title(psTitle: string) {
		if (psTitle !== this.msTitle) {
			this.msTitle = psTitle;
			this.detectChanges();
		}
	}

	private maSeancesTournee: ISeanceTournee[];
	@Input() public set seancesTournee(paSeancesTournee: ISeanceTournee[]) {
		if (paSeancesTournee && !ArrayHelper.areArraysEqual(this.maSeancesTournee, paSeancesTournee)) {
			this.maSeancesTournee = paSeancesTournee;
			this.scheduledSeancesTournee = [];
			this.canceledSeancesTournee = [];
			this.doneSeancesTournee = [];
			this.maSeances = [];

			this.maSeancesTournee.forEach((poSeanceTournee: ISeanceTournee) => {
				this.maSeances.push(poSeanceTournee.seance);

				this.fillSeanceTournee(poSeanceTournee);

				if (poSeanceTournee.seance.isCanceled)
					this.canceledSeancesTournee.push(poSeanceTournee);
				else if (poSeanceTournee.seance.validated || poSeanceTournee.seance.isCompleted)
					this.doneSeancesTournee.push(poSeanceTournee);
				else if (poSeanceTournee.seance.scheduled)
					this.scheduledSeancesTournee.push(poSeanceTournee);
			});

			this.scheduledSeancesTournee = TourneesService.sortSeanceTournees(this.scheduledSeancesTournee);
		}
		else {
			this.maSeancesTournee.forEach((poSeanceTournee: ISeanceTournee) => this.fillSeanceTournee(poSeanceTournee));

			this.scheduledSeancesTournee = TourneesService.sortSeanceTournees(this.scheduledSeancesTournee);
		}
		this.detectChanges();
	}

	private moSeanceTourneeToEdit: ISeanceTournee;
	@Input() public set seanceTourneeToEdit(poSeanceTourneeToEdit: ISeanceTournee) {
		if (poSeanceTourneeToEdit && poSeanceTourneeToEdit !== this.moSeanceTourneeToEdit && this.maSeancesTournee?.includes(poSeanceTourneeToEdit)) {
			this.openSeanceModal(poSeanceTourneeToEdit);

			this.moSeanceTourneeToEdit = poSeanceTourneeToEdit;
		}
	}

	@Output() private readonly onModalStatusChange = new EventEmitter<boolean>();

	public scheduledSeancesTournee: ISeanceTournee[];
	public canceledSeancesTournee: ISeanceTournee[];
	public doneSeancesTournee: ISeanceTournee[];
	public statusSeance = EStatusSeance;
	public dateParams: IDateTimePickerParams = DateHelper.datePickerParamsFactory(ETimetablePattern.dd_MM_yyyy, EDateTimePickerMode.date);
	public timeParams: IDateTimePickerParams = DateHelper.datePickerParamsFactory(ETimetablePattern.HH_mm, EDateTimePickerMode.time);
	public readonly emptyAvatar: IAvatar = SeanceService.emptyAvatar;
	public currentSite: Site = UserData.currentSite;

	private meActeDisplayMode: EResumeActeMode;
	public get acteDisplayMode(): EResumeActeMode {
		return this.meActeDisplayMode;
	}
	@Input() public set acteDisplayMode(peActeDisplayMode: EResumeActeMode) {
		if (peActeDisplayMode !== this.meActeDisplayMode) {
			this.meActeDisplayMode = peActeDisplayMode;
			this.scheduledSeancesTournee = Array.from(this.scheduledSeancesTournee);
			this.canceledSeancesTournee = Array.from(this.canceledSeancesTournee);
			this.doneSeancesTournee = Array.from(this.doneSeancesTournee);
			this.detectChanges();
		}
	}

	//#endregion

	//#region METHODS

	constructor(
		private isvcSeance: SeanceService,
		private isvcUiMessage: UiMessageService,
		private isvcNavigation: NavigationService,
		private isvcPopover: PopoverService,
		private isvcPatients: PatientsService,
		private isvcTraitement: TraitementService,
		private isvcLoading: LoadingService,
		private isvcModal: ModalService,
		private ioRouter: Router,
		poChangeDetector: ChangeDetectorRef,
	) {
		super(poChangeDetector);
	}

	/** Permet d'identifié la couleur d'affichage d'une séance.\
	 * Si aucun conflit : couleur primaire.\
	 * Sinon : warning.
	 * */
	public hasConflict(poSeanceTournee: ISeanceTournee): boolean {
		const ldStartDate: Date = poSeanceTournee.seance.startDate;
		const ldEndDate: Date = poSeanceTournee.seance.endDate;

		return this.scheduledSeancesTournee.some((poOtherSeanceTournee: ISeanceTournee) => {
			if (!poOtherSeanceTournee.seance.equals(poSeanceTournee.seance)) {
				const ldPlannedStartDate: Date = poOtherSeanceTournee.seance.startDate;
				const ldPlannedEndDate: Date = poOtherSeanceTournee.seance.endDate;

				// Si les dates se chevauchent
				if ((DateHelper.diffMinutes(ldPlannedStartDate, ldStartDate) >= 0 && DateHelper.diffMinutes(ldPlannedStartDate, ldEndDate) < 0) ||
					(DateHelper.diffMinutes(ldPlannedEndDate, ldStartDate) > 0 && DateHelper.diffMinutes(ldPlannedEndDate, ldEndDate) <= 0)) {

					return ArrayHelper.hasElements(intersectionBy(poSeanceTournee.seance.intervenantIds, poOtherSeanceTournee.seance.intervenantIds));
				}
			}
			else
				console.warn("IDL.TOUR.C:: Problème récupération couleur seanceTournee, deux objets ont le même identifiant.");

			return false;
		});
	}

	private fillSeanceTournee(poSeanceTournee: ISeanceTournee): void {
		poSeanceTournee.conflicted = this.hasConflict(poSeanceTournee);
		poSeanceTournee.statusIcon = TourneesService.getStatusIcon(poSeanceTournee);

		if (poSeanceTournee.seance.status !== EStatusSeance.canceled && poSeanceTournee.seance.scheduled) {
			if (ArrayHelper.hasElements(this.scheduledSeancesTournee)) {
				const loPreviousSeanceTournee: ISeanceTournee = ArrayHelper.getLastElement(this.scheduledSeancesTournee);
				loPreviousSeanceTournee.pause = this.getPause(loPreviousSeanceTournee, poSeanceTournee);
			}
		}
	}

	public openSeanceModal(poSeanceTournee: ISeanceTournee, pbHasToChangeStatus?: boolean): void {
		this.onModalStatusChange.emit(true);
		this.isvcModal.open<ISeanceModalResponse>(this.getOpenSeanceModalOptions(poSeanceTournee, pbHasToChangeStatus))
			.pipe(
				tap((poResponse: ISeanceModalResponse) => {
					this.onModalStatusChange.emit(false);
					const loSeance: Seance = poResponse.seances.find((poSeance: Seance) => poSeance.equals(poSeanceTournee.seance));
					if (loSeance) {
						poSeanceTournee.seance = loSeance;

						this.moveSeance(loSeance);
					}

					if (poResponse.traitementChanged)
						this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: " Traitement mis à jour." }));

				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private getOpenSeanceModalOptions(poSeanceTournee: ISeanceTournee, pbHasToChangeStatus: boolean) {
		return {
			component: SeanceModalComponent,
			backdropDismiss: false,
			componentProps: {
				seanceTournee: {
					intervenants: [...(poSeanceTournee.intervenants ?? [])],
					patient: poSeanceTournee.patient,
					seance: Seance.createFromData(poSeanceTournee.seance)
				} as ISeanceTournee,
				changeStatus: pbHasToChangeStatus
			}
		} as ModalOptions;
	}

	private moveSeance(loSeance: Seance): void {
		if (loSeance.isCanceled || loSeance.validated || loSeance.isCompleted) {
			const loSeanceToMove: ISeanceTournee = ArrayHelper.removeElementByFinder(this.scheduledSeancesTournee,
				(poSeanceTournee: ISeanceTournee) => loSeance.equals(poSeanceTournee.seance));

			if (loSeanceToMove) {
				if (loSeance.isCanceled)
					this.canceledSeancesTournee.push(loSeanceToMove);
				else if (loSeance.validated || loSeance.isCompleted)
					this.doneSeancesTournee.push(loSeanceToMove);
			}
		}
	}

	public selectIntervenants(poSeanceTournee: ISeanceTournee): void {
		const lsTitle = "Modifier l'intervenant";
		this.isvcSeance.selectIntervenants(poSeanceTournee.seance, lsTitle)
			.pipe(
				mergeMap((paIntervenants: IIdelizyContact[]) => {
					return this.isvcSeance.getSeances(poSeanceTournee.seance.startDate, DateHelper.addWeeks(poSeanceTournee.seance.startDate, 1))
						.pipe(
							mergeMap((paSeances: Seance[]) => this.isvcSeance.selectSeancesForUpdateWithModal(
								poSeanceTournee.seance,
								paSeances,
								this.getSelectIntervenantsChoices(poSeanceTournee),
								lsTitle
							)),
							tap(() => {
								this.isvcSeance.applyNewIntervenants(poSeanceTournee, paIntervenants);
								this.detectChanges();
							}),
							mergeMap((poResponse: IChooseSeancesToModifiyResponse) => this.isvcTraitement.changeSeancesWithConstraint(
								poResponse.seances,
								(poSeance: Seance) => this.isvcSeance.applyNewIntervenants(poSeance, paIntervenants),
								undefined,
								() => this.isvcSeance.getIntervenantConstraint(
									poResponse,
									poSeanceTournee.seance,
									paIntervenants
								)
							)),
							tap(_ => this.detectChanges())
						);
				}),
				tap(
					() => { },
					(poError: any) => {
						if (poError instanceof OsappError) {
							this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: poError.message }));
							console.warn("TOUR.C::Problème lors de la sélection des intervenants: ", poError);
						}
						else
							console.error("TOUR.C::Erreur lors de la sélection des intervenants: ", poError);
					}
				),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public cancelSeances(poSeanceTournee: ISeanceTournee): void {
		const lsTitle = "Annuler une séance";
		this.isvcSeance
			.getSeances(poSeanceTournee.seance.startDate, DateHelper.addWeeks(poSeanceTournee.seance.startDate, 1))
			.pipe(
				mergeMap((paSeances: Seance[]) =>
					this.isvcSeance.selectSeancesForUpdateWithModal(
						poSeanceTournee.seance,
						paSeances,
						this.getSelectCancelSeanceChoices(poSeanceTournee),
						lsTitle
					)
				),
				mergeMap((poResponse: IChooseSeancesToModifiyResponse) =>
					this.isvcTraitement.changeSeancesWithConstraint(
						poResponse.seances,
						(poSeance: Seance) =>
							this.isvcTraitement.getTraitement(poSeance.traitementId)
								.pipe(mergeMap((poTraitement: Traitement) => this.isvcSeance.cancelSeance(poSeance, poTraitement))),
						undefined,
						() => this.isvcSeance.getConstraint(CancelConstraint, poResponse, poSeanceTournee.seance)
					)
					.pipe(
						tap((paSeances: Seance[]) => {
							paSeances.forEach((poSeance: Seance) => this.moveSeance(poSeance));
							this.detectChanges();
						})
					)
				),
				tap(_ => this.detectChanges())
			)
			.subscribe();
	}

	private getSelectIntervenantsChoices(poSeanceTournee: ISeanceTournee): IChoice[] {
		return [{
			label: `Les séances du ${DateHelper.transform(poSeanceTournee.seance.startDate, ETimetablePattern.dd_MM_yyyy_slash)} de ${ContactHelper.getCompleteFormattedName(poSeanceTournee.patient.groupMember)}`,
			filters: [EChoiceFilter.sameDay, EChoiceFilter.samePatient]
		}] as IChoice[];
	}

	private getSelectCancelSeanceChoices(poSeanceTournee: ISeanceTournee): IChoice[] {
		return [
			{
				label: `Toutes les séances du ${DateHelper.transform(poSeanceTournee.seance.startDate, ETimetablePattern.dd_MM_yyyy_slash)} de ${ContactHelper.getCompleteFormattedName(poSeanceTournee.patient.groupMember)}`,
				filters: [EChoiceFilter.sameDay, EChoiceFilter.samePatient]
			},{
				label: `Cette séance et les séances à venir de ${ContactHelper.getCompleteFormattedName(poSeanceTournee.patient.groupMember)}`,
				filters: [EChoiceFilter.current,EChoiceFilter.future,EChoiceFilter.samePatient]
			}			
		] as IChoice[];
	}

	public onNavigateToClicked(poSeance: ISeanceTournee): void {
		this.isvcSeance.getSeanceCoordinates(poSeance.seance)
			.pipe(
				catchError(poError => {
					if (typeof poError === "string")
						this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Erreur", message: poError }));

					return throwError(poError);
				}),
				mergeMap((poCoordinates: ICoordinates) => this.isvcNavigation.navigateToCoordinates(poCoordinates.latitude, poCoordinates.longitude)),
				tap(
					_ => { },
					poError => console.error("TOUR.C::", poError)
				),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public openSeancePlaceModal(poSeanceTournee: ISeanceTournee): void {
		const loSelectedPlaceSubject = new BehaviorSubject<EPlace>(poSeanceTournee.seance.place);
		this.isvcModal.open(
			{
				component: SeancePlaceModalComponent,
				componentProps: {
					seanceTournee: poSeanceTournee,
					selectedPlaceSubject: loSelectedPlaceSubject
				}
			},
			EModalSize.small
		).pipe(
			mergeMap(_ => loSelectedPlaceSubject.asObservable()),
			tap(() => loSelectedPlaceSubject.complete()),
			filter((peSelectedPlace: EPlace) => poSeanceTournee.seance.place !== peSelectedPlace),
			mergeMap((peSelectedPlace: EPlace) => {
				return this.isvcTraitement.getTraitement(poSeanceTournee.seance.traitementId).pipe(
					mergeMap((poTraitement: Traitement) => {

						poSeanceTournee.seance.actes.forEach((poActe: Acte) => {
							const loTraitementActe: Acte = poTraitement.actes.find((poTraitementActe: Acte) => poActe.guid === poTraitementActe.guid);
							if (loTraitementActe) {
								const loConstraint: PlaceConstraint = this.isvcSeance.getConstraint(PlaceConstraint, { seances: [poSeanceTournee.seance], filters: [] }, poSeanceTournee.seance);
								loConstraint.place = peSelectedPlace;
								loTraitementActe.constraints.push(loConstraint);
							}
						});

						StoreHelper.makeDocumentDirty(poTraitement);
						return this.updateTraitement(poTraitement);
					})
				);
			}),
			tap(() => this.detectChanges()),
			takeUntil(this.destroyed$)
		)
			.subscribe();
	}

	/** Ouvre le menu contextuel associé à un objet séance-tournée.
	 * @param poSeanceTournee Séance-tournée dont on veut ouvrir un menu contextuel.
	 */
	public openTourneePopover(
		poEvent: MouseEvent,
		poSeanceTournee: ISeanceTournee,
		poTimePicker: DateTimePickerComponent,
		poDatePicker: DateTimePickerComponent
	): void {
		let loLoader: Loader;

		defer(() => this.isvcLoading.create("Chargement des données de la séance"))
			.pipe(
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(() => this.isvcTraitement.getTraitement(poSeanceTournee.seance.traitementId)),
				mergeMap((poTraitement: Traitement) =>
					this.isvcSeance.getSeances(poTraitement).pipe(
						mergeMap((paSeances: Seance[]) => {
							const loPreviousAndNextSeances: IPreviousAndNextSeances = this.isvcSeance.getPreviousAndNextSeances(poSeanceTournee.seance, paSeances);
							const laPopoverItems: IPopoverItemParams[] = [
								this.createEditSeancePopoverAction(poSeanceTournee),
								this.createMoveSeancePopoverAction(poSeanceTournee, poDatePicker, "Reporter la séance", "arrow-redo"),
								this.createMoveSeancePopoverAction(poSeanceTournee, poTimePicker, "Modifier l'heure", "time"),
								this.createAssignIntervenantPopoverAction(poSeanceTournee),
								this.createCancelSeancePopoverAction(poSeanceTournee),
								this.createReactivateSeancePopoverAction(poSeanceTournee),
								this.createSeancePlacePopoverAction(poSeanceTournee),
								this.createScanOrdonnancePopoverAction(poSeanceTournee)
							];
							const laSecondPartPopoverItems: IPopoverItemParams[] = [
								this.createDisplayPatientPopoverAction(poSeanceTournee),
								poTraitement ? this.createDisplayTraitementPopoverAction(poSeanceTournee) : undefined
							];

							if (loPreviousAndNextSeances.previousSeance)
								laSecondPartPopoverItems.unshift(this.createDisplaySeancePopoverAction(loPreviousAndNextSeances.previousSeance, "arrow-undo", "Afficher la séance précédente"));
							if (loPreviousAndNextSeances.nextSeance)
								laSecondPartPopoverItems.unshift(this.createDisplaySeancePopoverAction(loPreviousAndNextSeances.nextSeance, "arrow-redo", "Afficher la séance suivante"));

							laPopoverItems.push(...laSecondPartPopoverItems);

							return this.isvcPopover.showPopover(laPopoverItems, poEvent);
						})
					)
				),
				finalize(() => loLoader.dismiss()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private createEditSeancePopoverAction(poSeanceTournee: ISeanceTournee): IPopoverItemParams {
		return {
			action: () => of(this.openSeanceModal(poSeanceTournee)),
			icon: "date-edit",
			title: "Éditer la séance"
		} as IPopoverItemParams;
	}

	private createCancelSeancePopoverAction(poSeanceTournee: ISeanceTournee): IPopoverItemParams {
		if (poSeanceTournee.seance.isProtected)
			return undefined;

		return {
			action: () => of(this.cancelSeances(poSeanceTournee)),
			icon: "close",
			title: "Annuler la séance"
		} as IPopoverItemParams;
	}

	private createReactivateSeancePopoverAction(poSeanceTournee: ISeanceTournee): IPopoverItemParams {
		if (!poSeanceTournee.seance.isCanceled)
			return undefined;

		return {
			action: () => this.innerReactivateSeance(poSeanceTournee),
			icon: "close",
			title: "Réactiver la séance"
		} as IPopoverItemParams;
	}

	private innerReactivateSeance(poCanceledSeanceTournee: ISeanceTournee): void {
		this.isvcUiMessage.showAsyncMessage(
			new ShowMessageParamsPopup({
				message: "Voulez-vous réactiver la séance ?",
				buttons: [{ text: "Non", handler: () => UiMessageService.getFalsyResponse() },
				{ text: "Oui", handler: () => UiMessageService.getTruthyResponse() }]
			})
		).pipe(
			filter((poUiResponse: IUiResponse<any, any>) => poUiResponse.response),
			mergeMap(() => this.isvcTraitement.getTraitement(poCanceledSeanceTournee.seance.traitementId)),
			mergeMap((poTraitement: Traitement) => {
				const laSeances: Seance[] = [this.isvcSeance.reactivate(poCanceledSeanceTournee.seance)];

				poCanceledSeanceTournee.seance.actes.forEach((poActe: Acte) => {
					const loTraitementActe: Acte = poTraitement.actes.find((poTraitementActe: Acte) => poActe.guid === poTraitementActe.guid);
					if (loTraitementActe) {
						loTraitementActe.constraints.push(
							this.isvcSeance.getConstraint(ReactivateConstraint, { seances: laSeances, filters: [] }, poCanceledSeanceTournee.seance)
						);
						StoreHelper.makeDocumentDirty(poTraitement);
					}
				});

				return this.updateTraitement(poTraitement);
			}),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	private createCancelSeancePopoverActionChoices(poSelectedSeanceTournee: ISeanceTournee): IChoice[] {
		return [
			{
				label: `Toutes les séances du ${DateHelper.transform(poSelectedSeanceTournee.seance.startDate, ETimetablePattern.dd_MM_yyyy_slash)} de ${ContactHelper.getCompleteFormattedName(poSelectedSeanceTournee.patient.groupMember)}`,
				filters: [EChoiceFilter.sameDay, EChoiceFilter.samePatient]
			}
		] as IChoice[];
	}

	private createAssignIntervenantPopoverAction(poSeanceTournee: ISeanceTournee): IPopoverItemParams {
		if (poSeanceTournee.seance.isProtected)
			return undefined;

		return {
			action: () => of(this.selectIntervenants(poSeanceTournee)),
			icon: "person-circle",
			title: "Modifier l'intervenant"
		} as IPopoverItemParams;
	}

	private createSeancePlacePopoverAction(poSeanceTournee: ISeanceTournee): IPopoverItemParams {
		return {
			action: () => of(this.openSeancePlaceModal(poSeanceTournee)),
			icon: "pin",
			title: "Afficher le lieu de réalisation"
		} as IPopoverItemParams;
	}

	private createDisplayPatientPopoverAction(poSeanceTournee: ISeanceTournee): IPopoverItemParams {
		return {
			action: () => {
				if (poSeanceTournee.patient.groupMember)
					return this.isvcPatients.goToPatient(poSeanceTournee.patient.groupMember as IPatient);
				else {
					this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Patient inconnu", message: "Impossible d'afficher ce patient." }));
					return EMPTY;
				}
			},
			avatar: poSeanceTournee.patient.avatar ? ContactsService.createContactAvatar(poSeanceTournee.patient.groupMember, EAvatarSize.medium) : undefined,
			icon: poSeanceTournee.patient.avatar ? undefined : "person",
			title: "Afficher le patient"
		} as IPopoverItemParams;
	}

	private createDisplaySeancePopoverAction(poSeance: Seance, psIcon: string, psTitle: string): IPopoverItemParams {
		return {
			action: () => {
				this.ioRouter.navigate(["tournees", DateHelper.toDateUrl(poSeance.startDate), "seances", poSeance.traitementId, poSeance.startDate]);
				return EMPTY;
			},
			icon: psIcon,
			title: psTitle
		} as IPopoverItemParams;
	}

	private createScanOrdonnancePopoverAction(poSeanceTournee: ISeanceTournee): IPopoverItemParams {
		return {
			action: () => {
				return this.isvcTraitement.getTraitement(poSeanceTournee.seance.traitementId)
					.pipe(
						catchError(poError => this.onGetTraitementFromSeanceIdError(poError)),
						tap((poTraitement: Traitement) =>
							this.isvcTraitement.openTraitement(poTraitement._id, [TraitementSlideParentBase.C_ADD_ORDONNANCE_ACTION_ID])
						),
					);
			},
			icon: "camera",
			title: "Ajouter une ordonnance"
		} as IPopoverItemParams;
	}

	private createDisplayTraitementPopoverAction(poSeanceTournee: ISeanceTournee): IPopoverItemParams {
		return {
			action: () => this.isvcTraitement.getTraitement(poSeanceTournee.seance.traitementId)
				.pipe(
					catchError(poError => this.onGetTraitementFromSeanceIdError(poError)),
					tap((poTraitement: Traitement) => this.isvcTraitement.openTraitement(poTraitement._id))
				),
			icon: "cure",
			title: "Afficher le traitement"
		} as IPopoverItemParams;
	}

	private createMoveSeancePopoverAction(poSeanceTournee: ISeanceTournee, poDatePicker: DateTimePickerComponent, psLabel: string, psIcon: string): IPopoverItemParams {
		if (poSeanceTournee.seance.isProtected)
			return undefined;

		return {
			action: () => of(this.pickDate(poDatePicker, poSeanceTournee)),
			icon: psIcon,
			title: psLabel
		} as IPopoverItemParams;
	}

	public onDateModified(pdDate: Date): void {
		if (!this.moEditingDateSeanceTournee || DateHelper.compareTwoDates(pdDate, this.moEditingDateSeanceTournee.seance.startDate) === 0)
			return;

		const lnDiffDays: number = DateHelper.diffDays(pdDate, this.moEditingDateSeanceTournee.seance.startDate);
		let loLoader: Loader;

		defer(() => this.isvcLoading.create("Chargement des séances éligibles à la modification"))
			.pipe(
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(() => this.isvcSeance.getPatientSeances(this.moEditingDateSeanceTournee.seance.patientId, this.moEditingDateSeanceTournee.seance.startDate)),
				tap(() => loLoader.dismiss()),
				mergeMap((paSeances: Seance[]) => this.isvcSeance.selectSeancesForUpdateWithModal(
					this.moEditingDateSeanceTournee.seance,
					paSeances,
					this.getDateModifiedChoices(pdDate, lnDiffDays, paSeances),
					lnDiffDays !== 0 ? "Reporter la séance" : "Modifier l'heure"
				)),
				mergeMap((poResponse: IChooseSeancesToModifiyResponse) => this.isvcTraitement.applyNewDateToSeances(
					pdDate,
					this.moEditingDateSeanceTournee.seance,
					poResponse
				)),
				tap(_ => {
					let lsMessage = "Séance planifiée ";

					if (DateHelper.diffDays(pdDate, this.moEditingDateSeanceTournee.seance.startDate) !== 0)
						lsMessage += `le ${DateHelper.transform(pdDate, this.moEditingDateSeanceTournee.seance.scheduled ? ETimetablePattern.EEE_dd_MMMM_yyyyy_HH_mm : ETimetablePattern.EEE_dd_MMMM_yyyy)}`;
					else
						lsMessage += `à ${DateHelper.transform(pdDate, ETimetablePattern.HH_mm)}`;

					this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: lsMessage }));
					this.detectChanges();
				}),
				finalize(() => this.moEditingDateSeanceTournee = undefined)
			)
			.subscribe();
	}

	private getDateModifiedChoices(pdDate: Date, pnDiffDays: number, paSeances: Seance[]): IChoice[] {
		if (pnDiffDays !== 0)
			return this.getDateModifiedChoicesWithDiffDays(paSeances);
		else
			return this.getDateModifiedChoicesNoDiffDays(pdDate);
	}

	private getDateModifiedChoicesWithDiffDays(paSeances: Seance[]): IChoice[] {
		const laChoices: IChoice[] = [];

		if (this.moEditingDateSeanceTournee.seance.scheduled) {
			laChoices.push({
				label: `Toutes les séances de ${DateHelper.transform(this.moEditingDateSeanceTournee.seance.startDate, ETimetablePattern.HH_mm)} de ${ContactHelper.getCompleteFormattedName(this.moEditingDateSeanceTournee.patient.groupMember)}`,
				filters: [EChoiceFilter.sameTime, EChoiceFilter.future, EChoiceFilter.samePatient]
			});
		}

		laChoices.push(
			{
				label: `Les séances du ${DateHelper.transform(this.moEditingDateSeanceTournee.seance.startDate, ETimetablePattern.dd_MM_yyyy_slash)} de ${ContactHelper.getCompleteFormattedName(this.moEditingDateSeanceTournee.patient.groupMember)}`,
				filters: [EChoiceFilter.sameDay, EChoiceFilter.samePatient]
			},
			{
				label: `Toutes les séances du ${DateHelper.transform(this.moEditingDateSeanceTournee.seance.startDate, ETimetablePattern.dd_MM_yyyy_slash)} de ${ContactHelper.getCompleteFormattedName(this.moEditingDateSeanceTournee.patient.groupMember)} et les suivantes`,
				value: paSeances.filter((poSeance: Seance) => poSeance.patientId === this.moEditingDateSeanceTournee.seance.patientId &&
					(DateHelper.areDayEqual(poSeance.startDate, this.moEditingDateSeanceTournee.seance.startDate) || DateHelper.compareTwoDates(poSeance.startDate, this.moEditingDateSeanceTournee.seance.startDate) > 0)
				)
			}
		);

		return laChoices;
	}

	private getDateModifiedChoicesNoDiffDays(pdDate: Date): IChoice[] {
		return [{
			label: `Décaler toutes les séances de ${DateHelper.transform(this.moEditingDateSeanceTournee.seance.startDate, ETimetablePattern.HH_mm)} à ${DateHelper.transform(pdDate, ETimetablePattern.HH_mm)} de ${ContactHelper.getCompleteFormattedName(this.moEditingDateSeanceTournee.patient.groupMember)}`,
			filters: [EChoiceFilter.sameTime, EChoiceFilter.future, EChoiceFilter.samePatient]
		}];
	}

	private onGetTraitementFromSeanceIdError(poError: any): Observable<never> {
		this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Erreur", message: "Le traitement associé à cette séance est introuvable." }));
		return throwError(poError);
	}

	public validateSeance(poSeanceTournee: ISeanceTournee): void {
		this.openSeanceModal(poSeanceTournee, true);
	}

	private getPause(poSeanceTournee: ISeanceTournee, poNextSeanceTournee: ISeanceTournee): IEvent {
		const lnPauseInMinutes: number = DateHelper.diffMinutes(poNextSeanceTournee.seance.startDate, poSeanceTournee.seance.endDate);

		if (lnPauseInMinutes > 0) {
			return {
				startDate: poSeanceTournee.seance.endDate,
				endDate: poNextSeanceTournee.seance.startDate,
				duration: Math.round(lnPauseInMinutes)
			};
		}
		else
			return null;
	}

	/** Permute un objet `ISeanceTournee` sélectionné avec le précédent/suivant (position, date de début et date de fin sont permutés) et enregistre les changements.
	 * @param poSelectedItem Objet `ISeanceTournee` sélectionné pour permuter avec le précédent/suivant.
	 * @param pnSelectedItemIndex Index de l'objet sélectionné.
	 * @param poOtherItem Objet `ISeanceTournee` qu'on va permuter avec l'objet `ISeanceTournee` sélectionné.
	 * @param pnOtherIndex Index de l'objet qu'on va permuter.
	 */
	public async permuteSeanceTournee(poSelectedItem: ISeanceTournee, pnSelectedIndex: number, poOtherItem: ISeanceTournee, pnOtherIndex: number): Promise<void> {
		const ldOtherStartDate = new Date(poOtherItem.seance.startDate);

		// On intervertit les deux items dans le tableau pour le rendu graphique.
		ArrayHelper.moveElement(this.scheduledSeancesTournee, pnSelectedIndex, pnOtherIndex);

		// Enregistrement des changements.
		await this.isvcTraitement.applyNewDateToSeances(poSelectedItem.seance.startDate, poOtherItem.seance, { filters: [], seances: [] }).toPromise();
		await this.isvcTraitement.applyNewDateToSeances(ldOtherStartDate, poSelectedItem.seance, { filters: [], seances: [] }).toPromise();
	}

	public pickDate(poTimePicker: DateTimePickerComponent, poSeanceTournee: ISeanceTournee): void {
		this.moEditingDateSeanceTournee = poSeanceTournee;

		poTimePicker.model = poSeanceTournee.seance.startDate;

		return poTimePicker.pickDate();
	}

	/** Permet de traquer l'identifiant du document afin de rafraîchir l'affichage si celui-ci change.
	 * @param pnIndex Index du traitement.
	 * @param poDocument Données du document.
	 */
	public trackById(pnIndex: number, poSeanceTournee: ISeanceTournee): string {
		return poSeanceTournee?.seance.traitementId + poSeanceTournee?.seance.startDate;
	}

	private updateTraitement(poTraitement?: Traitement): Observable<boolean> {
		let loLoader: Loader;

		return defer(() => this.isvcLoading.create("Enregistrement du traitement ..."))
			.pipe(
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(() => this.isvcTraitement.saveTraitement(poTraitement)),
				finalize(() => loLoader?.dismiss())
			);
	}

	public getIntervenantsNames(paIntervenants: IHydratedGroupMember[]): string {
		return paIntervenants?.map((poIntervenant: IHydratedGroupMember) => this.isvcTraitement.getIntervenantName(poIntervenant?.groupMember)).join(", ");
	}

	public hasAddress(poSeanceTournee: ISeanceTournee): boolean {
		return !StringHelper.isBlank(poSeanceTournee.patient?.groupMember?.street) ||
			!StringHelper.isBlank(poSeanceTournee.patient?.groupMember?.zipCode) ||
			!StringHelper.isBlank(poSeanceTournee.patient?.groupMember?.city);
	}

	//#endregion

}
