import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { IDateRange } from '@osapp/components/date/date-range-picker/model/IDateRange';
import { DateHelper, IdHelper, ObjectHelper, StringHelper } from '@osapp/helpers';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { EAvatarSize } from '@osapp/model';
import { ListComponentBase2 } from '@osapp/model/ListComponentBase2';
import { IContact } from '@osapp/model/contacts/IContact';
import { IAvatar } from '@osapp/model/picture/IAvatar';
import { ISearchOptions } from '@osapp/model/search/ISearchOptions';
import { IFilterValuesChangedEvent } from '@osapp/modules/filter/model/IFilterValuesChangedEvent';
import { IFilterbarOptions } from '@osapp/modules/filter/model/IFilterbarOptions';
import { IFilterbarParams } from '@osapp/modules/filter/model/IFilterbarParams';
import { Loader } from '@osapp/modules/loading/Loader';
import { ContactsService } from '@osapp/services/contacts.service';
import { LoadingService } from '@osapp/services/loading.service';
import { Observable, combineLatest, defer } from 'rxjs';
import { finalize, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Ordonnance } from '../../ordonnances/models/ordonnance';
import { OrdonnancesService } from '../../ordonnances/services/ordonnances.service';
import { IPatient } from '../../patients/model/IPatient';
import { PatientsService } from '../../patients/services/patients.service';
import { FacturationService } from '../facturation.service';
import { Invoice } from '../models/invoice';
import { IPaginatorInfos } from '../models/ipaginator-infos';

interface InvoiceData extends Invoice {
	intervenant?: IContact;
	patient?: IPatient;
	ordonnance?: Ordonnance;
}

@Component({ template: "" })
export abstract class InvoicesListComponentBase extends ListComponentBase2<InvoiceData> implements OnInit {

	//#region FIELDS

	private maFilteredPatients: IPatient[] = [];
	private maFilterSelectedIntervenants: IContact[] = [];

	montantTotal:number = 0;


	//#endregion

	//#region PROPERTIES

	public intervenantsByIds = new Map<string, IContact>();
	public intervenants: IContact[];
	public ordonnancesByIds = new Map<string, Ordonnance>();
	public patientsByIds = new Map<string, IPatient>();
	public patients: IPatient[];
	public searchValue: string;
	public filtersCount: number;
	public filterbarParams: IFilterbarParams;
	public filterRangeDates: IDateRange;

	public invoicesByPatientId = new Map<string, InvoiceData[]>();

	/** Composants de pagination. */
	@ViewChild("paginator") public paginator: MatPaginator;
	public nbItemsByPage = 10;
	public startIndex = 0;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcFacturation: FacturationService,
		private readonly isvcContacts: ContactsService,
		private readonly isvcOrdonnances: OrdonnancesService,
		private readonly isvcLoading: LoadingService,
		private readonly isvcPatients: PatientsService,
		protected readonly ioRouter: Router,
		poChangeDetectorRef: ChangeDetectorRef,
	) {
		super(poChangeDetectorRef);
	}

	public ngOnInit(): void {
		this.searchOptions = this.createSearchOptions();
	}

	public init(pbClosedInvoices: boolean = false): void {
		let loLoader: Loader;
		let laInvoices: Invoice[];
		const laIntervenantsIds = [];
		const laOrdonnancesIds = [];
		const laPatientIds = [];

		defer(() => this.isvcLoading.create("Chargement en cours..."))
			.pipe(
				mergeMap((poLoader: Loader) => poLoader.present()),
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap(() => this.isvcFacturation.getInvoices(true)),
				tap((paInvoices: Invoice[]) => {
					laInvoices = paInvoices
						.filter((poInvoice: Invoice) => pbClosedInvoices ? poInvoice.isClosed : !poInvoice.isClosed);

					laInvoices.forEach((poInvoice: Invoice) => {
						if (!StringHelper.isBlank(poInvoice.intervenantId))
							laIntervenantsIds.push(poInvoice.intervenantId);
						if (!StringHelper.isBlank(poInvoice.ordonnanceId))
							laOrdonnancesIds.push(poInvoice.ordonnanceId);
						laPatientIds.push(ArrayHelper.getLastElement(IdHelper.extractAllIds(poInvoice._id)));
					});
				}),
				switchMap(() => combineLatest([
					this.getPatients(ArrayHelper.unique(laPatientIds)),
					this.getOrdonnances(laOrdonnancesIds),
					this.getIntervenants(laIntervenantsIds)
				])),
				tap(() => {
					this.initFilterbarParams();
					this.onFilteredDocumentsChanged(this.documents = this.fillInvoicesData(FacturationService.sortInvoices(laInvoices)));
					this.detectChanges();
				}),
				mergeMap(() => loLoader.dismiss()),
				finalize(() => loLoader?.dismiss()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private getPatients(psIds: string[]): Observable<IPatient[]> {
		return this.isvcPatients.getPatientsByIds(psIds)
			.pipe(
				tap((paPatients: IPatient[]) => {
					this.patients = paPatients;
					paPatients.forEach((popatient: IPatient) => this.patientsByIds.set(popatient._id, popatient));
				})
			);
	}

	private getOrdonnances(psIds: string[]): Observable<Ordonnance[]> {
		return this.isvcOrdonnances.getOrdonnancesByIds(psIds)
			.pipe(
				tap((paOrdonnances: Ordonnance[]) => paOrdonnances.forEach((poOrdonnance: Ordonnance) => this.ordonnancesByIds.set(poOrdonnance._id, poOrdonnance)))
			);
	}

	private getIntervenants(psIds: string[]): Observable<IContact[]> {
		return this.isvcContacts.getContactsByIds(ArrayHelper.unique(psIds))
			.pipe(
				tap((paIntervenants: IContact[]) => {
					this.intervenants = paIntervenants;
					paIntervenants.forEach((poIntervenants: IContact) => this.intervenantsByIds.set(poIntervenants._id, poIntervenants));
				}),
			);
	}

	private fillInvoicesData(paInvoices: Invoice[]): InvoiceData[] {
		paInvoices.forEach((poInvoice: InvoiceData) => {
			poInvoice.intervenant = this.intervenantsByIds.get(poInvoice.intervenantId);
			poInvoice.ordonnance = poInvoice.ordonnanceId ? this.ordonnancesByIds.get(poInvoice.ordonnanceId) : undefined;
			poInvoice.patient = this.patientsByIds.get(ArrayHelper.getLastElement(IdHelper.extractAllIds(poInvoice._id)));
			this.montantTotal += Number.isNaN(poInvoice.honoraires)? 0 : poInvoice.honoraires;
		});
		return paInvoices;
	}

	/** @override */
	public onFilteredDocumentsChanged(paChanges: InvoiceData[]): void {
		super.onFilteredDocumentsChanged(paChanges);

		this.invoicesByPatientId.clear();
		this.filteredDocuments.forEach((poInvoice: InvoiceData) => {
			if (this.invoicesByPatientId.has(poInvoice.patientId))
				this.invoicesByPatientId.set(poInvoice.patientId, [...this.invoicesByPatientId.get(poInvoice.patientId), poInvoice]);
			else
				this.invoicesByPatientId.set(poInvoice.patientId, [poInvoice]);
		});

		this.detectChanges();
	}

	public goToInvoice(poInvoiceId: string): void {
		this.ioRouter.navigate(["/invoices", poInvoiceId]);
	}

	//#region Filters

	/** @override */
	protected createSearchOptions(): ISearchOptions<Invoice> {
		return {
			hasPreFillData: true,
			searchboxPlaceholder: "Rechercher un patient",
			searchFunction: (patient: IPatient, psSearchValue: string) => this.invoiceSearch(patient, psSearchValue)
		};
	}

	/** Recherche la correspondance de la recherche avec une facture.
	 * @param patient Facture dans la quelle rechercher une correspondance avec la valeur.
	 * @param psSearchValue Valeur recherchée.
	 */
	private invoiceSearch(patient: IPatient, psSearchValue: string): boolean {
		const lsSearchValue: string = psSearchValue.toLowerCase();

		return this.checkSearchValueInPatient(lsSearchValue, patient);
	}

	/** Vérifie la correspondance d'un patient avec la valeur recherchée.
	 * @param psSearchValue Valeur recherchée.
	 * @param poPatient Patient dont il faut vérifier la correspondance avec la valeur recherchée.
	 */
	private checkSearchValueInPatient(psSearchValue: string, poPatient?: IPatient): boolean {
		return poPatient && this.checkSearchValueByStringProperties([poPatient.firstName, poPatient.lastName], psSearchValue);
	}

	/** Vérifie la présence d'au moins une correspondance de propriété avec la valeur recherchée.
	 * @param paProperties Tableau des propriétés dont il faut vérifier une correspondance avec la valeur recherchée.
	 * @param psSearchValue Valeur recherchée.
	 */
	private checkSearchValueByStringProperties(paProperties: string[], psSearchValue: string): boolean {
		return paProperties.some((psPropertyValue: string) => !StringHelper.isBlank(psPropertyValue) && psPropertyValue.toLowerCase().includes(psSearchValue));
	}

	public onFilteredPatientsChanged(paFilteredPatients: IPatient[]): void {
		this.maFilteredPatients = Array.from(paFilteredPatients);
		this.onFilteredDocumentsChanged(this.applyFilterValues(this.documents));
		this.detectChanges();
	}

	public toggleFilterbarVisibility(): void {
		this.filterbarParams.hidden = !this.filterbarParams.hidden;
		this.detectChanges();
	}

	public onFilterCountChanged(pnFilterCount: number): void {
		this.filtersCount = pnFilterCount;
		this.detectChanges();
	}

	/** Initialise les paramètres de filtrage. */
	private initFilterbarParams(): void {
		this.resetCurrentFilters();

		this.filterbarParams = {
			filters: this.createFilterbarOptions(this.maFilterSelectedIntervenants),
			hasResetButton: true,
			hidden: true
		};

		this.detectChanges();
	}

	/** Crée les différentes options de filtrage. */
	public createFilterbarOptions(paSelectedIntervenants: IContact[]): IFilterbarOptions[] {
		return [
			this.isvcFacturation.createDatesRangeFilterbarOptions(),
			this.isvcFacturation.createIntervenantFilterbarOptions(paSelectedIntervenants),
		];
	}

	private resetCurrentFilters(): void {
		this.maFilterSelectedIntervenants = [];
		this.filterRangeDates = undefined;
	}

	/** Événement de mise à jour des valeurs pour un filtre. */
	public onFilterValuesChanged(poEvent: IFilterValuesChangedEvent<IContact | IDateRange>): void {
		this.setFilterValuesChanged(poEvent);
		this.onFilteredDocumentsChanged(this.applyFilterValues(this.documents));
	}

	/** Modifie un des champs du comopsant pour garder en mémoire les différents filtres en cours. */
	private setFilterValuesChanged(poEvent: IFilterValuesChangedEvent<IContact | IDateRange>): void {
		if (poEvent.id === FacturationService.C_INTERVENANT_FILTER_ID && ArrayHelper.hasElements(poEvent.changes))
			this.maFilterSelectedIntervenants = poEvent.changes as IContact[];

		else if (poEvent.id === FacturationService.C_DATE_RANGE_FILTER_ID)
			this.filterRangeDates = ArrayHelper.getFirstElement(poEvent.changes) as IDateRange;

		else
			console.warn(`FACT.C:: L'identifiant de changements de valeur de filtres '${poEvent.id}' est inconnu.`);
	}

	/** Applique les filtres de la barre de filtrage sur les documents à afficher et retourne le nouveau tableau obtenu. */
	private applyFilterValues(paInvoices: InvoiceData[]): Invoice[] {
		this.montantTotal = 0;
		let laResults: InvoiceData[] = paInvoices;

		if (!ArrayHelper.hasElements(laResults))
			return [];

		// On filtre les intervenants s'il y en a.
		if (ArrayHelper.hasElements(this.maFilterSelectedIntervenants)) {
			laResults = laResults.filter((poInvoice: InvoiceData) =>
				this.maFilterSelectedIntervenants.some((poIntervenant: IContact) =>
					poInvoice.intervenantId === poIntervenant._id
				),
			);
		};

		if (ArrayHelper.hasElements(this.maFilteredPatients)) {
			laResults = laResults.filter((poInvoice: InvoiceData) =>
				this.maFilteredPatients.some((poPatient: IPatient) =>
					poInvoice.patientId === poPatient._id
				),
			);
		}

		if (!ObjectHelper.isNullOrEmpty(this.filterRangeDates)) {
			laResults = laResults.filter((poInvoice: InvoiceData) => {
				return DateHelper.compareTwoDates(poInvoice.date, this.filterRangeDates.begin) >= 0 && DateHelper.compareTwoDates(this.filterRangeDates.end, poInvoice.date) >= 0;
			}
			);
		};
		laResults.map((invoice:InvoiceData)=> {this.montantTotal += Number.isNaN(invoice.honoraires)? 0 : invoice.honoraires})
		return laResults;
	}

	/** Événement de réinitialisation du filtrage. */
	public onFilterbarResetEvent(pbIsReset: boolean): void {
		this.filterbarParams.hidden = pbIsReset;
		this.resetCurrentFilters();
		this.onFilteredPatientsChanged(this.patients);
	}

	public getContactAvatar(poContact: IContact): IAvatar {
		return ContactsService.createContactAvatar(poContact, EAvatarSize.big);
	}

	public onPageChange(poPaginatorInfos: IPaginatorInfos): void {
		if (poPaginatorInfos.pageSize !== this.nbItemsByPage)
			this.nbItemsByPage = poPaginatorInfos.pageSize;

		this.startIndex = this.nbItemsByPage * poPaginatorInfos.pageIndex;
	}

	//#endregion

	//#endregion

}