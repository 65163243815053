<div class="di-infirmier">
	<lua-avatar size="medium" [color]="getAvatarColor()" [firstName]="infirmier.firstName" [lastName]="infirmier.lastName"
		[withTooltip]="false"></lua-avatar>
	<div class="content">
		<small *ngIf="infirmier" class="name">{{ infirmier.firstName }} {{ infirmier.lastName }}
		</small>

		<small *ngIf="infirmier && (date || time)"> • {{ date }} {{ time }}</small>
		<small *ngIf="!infirmier">Non affecté</small>
	</div>
	<div *ngIf="showMenu" class="menu">
		<lua-bouton-icone iconName="More_Vert" size="medium" mode="filled" [isDisabled]="disabledIcon"
			(click)="toggleMenu()"></lua-bouton-icone>
	</div>
</div>