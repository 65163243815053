<div *ngIf="showDisplayModeSelector" class="selector">
	<osapp-selector displayMode="tags" [options]="displayModeOptions" [preselectedValues]="displayMode"
		scrollWrapper="false" (selectionChanged)="displayMode = $event">
	</osapp-selector>
</div>

<ng-container [ngSwitch]="displayMode">
	<ng-container *ngSwitchCase="'thumbnails'">
		<ng-container *ngTemplateOutlet="thumbnailsMode"></ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="'list'">
		<ng-container *ngTemplateOutlet="listMode"></ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="'add'">
		<ng-container *ngTemplateOutlet="addMode"></ng-container>
	</ng-container>
</ng-container>

<!-- MODE MINIATURES -->
<ng-template #thumbnailsMode>
	<!-- GALLERY NOT READ ONLY SECTION -->
	<div *ngIf="!readOnly" class="ctn-liste-gallery ctn-liste-gallery-preview">

		<div class="thumbnails">
			<div *ngFor="let item of files; let i = index" [class]="'item' + (!item.isAvailable? ' stable-bg': '')">
				<ng-container *ngIf="!excludeFileGuids?.includes(item.guid)">
					<div class="preview">
						<ng-container
							[ngSwitch]="!!item.file && !!item.file.MimeType && item.file.MimeType.indexOf('image') !== -1">
							<div *ngSwitchCase="true" (click)="openFileAt(i)" class="ctn-apercu">
								<osapp-image [src]="item.file.File" [alt]="item.name"></osapp-image>
							</div>
							<ion-icon *ngSwitchCase="false" (click)="openFileAt(i)" class="file-icon" name="document"></ion-icon>
						</ng-container>
						<div class="ctn-ico">
							<ng-container *ngIf="!!customButtonsTemplate">
								<div class="ctn-ico-suppr">
									<ng-container *ngTemplateOutlet="customButtonsTemplate; context: { item: item }"></ng-container>
								</div>
							</ng-container>
							<div class="ctn-ico-suppr">
								<ion-icon (click)="deleteItem(i)" class="icone ico-suppr" color="danger" name="trash"></ion-icon>
							</div>
							<div *ngIf="allowCustomDescription" class="ctn-ico-suppr">
								<ion-icon (click)="editDescription(item)" class="icone ico-suppr" color="primary" name="create">
								</ion-icon>
							</div>
						</div>
					</div>
					<div *ngIf="!hideFileDetails" class="ctn-details-fichier">
						<div *ngIf="item.description as description" class="file-description">
							{{ description }}
						</div>
						<div [ngClass]="item.description ? 'small-details' : ''" class="details">
							<ng-container [ngSwitch]="!!item.isAvailable || !!item.isNew">
								<ion-icon *ngSwitchCase="true" class="icone ico-doc txt24px" name="checkmark-circle"></ion-icon>
								<ng-container *ngSwitchCase="false">
									<ng-container *ngIf="networkAvailable && item.isLoading;else canNotDownload">
										<ion-spinner></ion-spinner>
									</ng-container>
								</ng-container>
							</ng-container>
							<ng-template #canNotDownload>
								<ion-icon class="icone ico-doc txt24px" name="code-download"></ion-icon>
							</ng-template>
							<div class="sub-text">
								<div *ngIf="getItemNameWithoutExtension(item.name) as nameWithoutExtension" class="file-name">
									{{ nameWithoutExtension }}
								</div>
								<div *ngIf="getItemExtensionFromName(item.name) as extension">
									{{ extension }}
								</div>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
			<ion-item class="min-width" lines="none" *ngIf="isLoading">
				<ion-spinner name="crescent"></ion-spinner>
			</ion-item>
		</div>
		<div [ngClass]="!filePickerVisible && !cameraButtonVisible ? 'collapsed' : ''" class="picpos">
			<span *ngIf="cameraButtonVisible" style="background-color: transparent">
				<ion-button [disabled]="reachedLimit" (click)="takePicture()" class="licamera-button">
					<ion-icon [slot]="cameraButtonText ? 'start' : 'icon-only'" name="camera"></ion-icon>
					<ion-label *ngIf="cameraButtonText">
						{{ cameraButtonText }}
					</ion-label>
				</ion-button>
			</span>
			<span>
				<file-picker #imagePicker [disabled]="reachedLimit" [ngClass]="imagePickerVisible ? '' : 'collapsed'"
					(fileSelected)="addToModel($event)" [options]="cameraOptions" [multiple]="true" [maxSizeKb]="maxSizeKb"
					[accept]="acceptedImages" [label]="filePickerImagesButtonText" [iconName]="imagePickerIcon"
					(isLoading)="onLoadingChanged($event)">
				</file-picker>
			</span>
			<span>
				<file-picker #filePicker [disabled]="reachedLimit" [ngClass]="filePickerVisible ? '' : 'collapsed'"
					(fileSelected)="addToModel($event)" [options]="cameraOptions" [multiple]="true" [maxSizeKb]="maxSizeKb"
					[accept]="acceptedFiles" [label]="filePickerFilesButtonText" [iconName]="filePickerIcon"
					(isLoading)="onLoadingChanged($event)">
				</file-picker>
			</span>
			<span *ngIf="patientId" style="background-color: transparent">
				<ion-button [disabled]="reachedLimit" (click)="selectImageDossierPatient()" class="licamera-button">
					<ion-icon name="list"></ion-icon>
				</ion-button>
			</span>
		</div>
	</div>

	<!-- GALLERY READ ONLY SECTION -->
	<ng-container *ngIf="readOnly">
		<div *ngIf="files?.length > 0" class="ctn-liste-gallery thumbnails">
			<div *ngFor="let item of files; let i = index" [class]="'item' + (!item.isAvailable? ' stable-bg': '')">
				<ng-container *ngIf="!excludeFileGuids?.includes(item.guid)">
					<ng-container [ngSwitch]="!!item.file && !!item.file.MimeType && item.file.MimeType.indexOf('image') !== -1">
						<div *ngSwitchCase="true" (click)="openFileAt(i)" class="ctn-apercu">
							<osapp-image [src]="item.file.File" [alt]="item.name"></osapp-image>
						</div>
						<ion-icon *ngSwitchCase="false" (click)="openFileAt(i)" class="file-icon" name="document"></ion-icon>
					</ng-container>
					<ng-template #icon>
						<ion-icon (click)="openFileAt(i)" class="file-icon" name="document"></ion-icon>
					</ng-template>
					<div *ngIf="!hideFileDetails" (click)="openFileAt(i)" class="ctn-details-fichier">
						<div *ngIf="item.description as description" class="file-description">
							{{ description }}
						</div>
						<div [ngClass]="item.description ? 'small-details' : ''" class="details">
							<ng-container [ngSwitch]="!!item.isAvailable || !!item.isNew">
								<ion-icon *ngSwitchCase="true" class="icone ico-doc txt24px" name="checkmark-circle"></ion-icon>
								<ng-container *ngSwitchCase="false">
									<ng-container *ngIf="networkAvailable && item.isLoading;else canNotDownload">
										<ion-spinner></ion-spinner>
									</ng-container>
								</ng-container>
							</ng-container>
							<ng-template #canNotDownload>
								<ion-icon class="icone ico-doc txt24px" name="code-download"></ion-icon>
							</ng-template>
							<div class="sub-text">
								<div class="file-name">{{ getItemNameWithoutExtension(item.name) }}</div>
								<div>{{ getItemExtensionFromName(item.name) }}</div>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</div>

		<div *ngIf="!hideNoFileText && (!files || files.length === 0)" class="empty-gallery">
			<p>Aucun fichier.</p>
		</div>
	</ng-container>
</ng-template>

<!-- MODE LISTE -->
<ng-template #listMode>
	<div class="list-item-ctn" *ngFor="let item of files; let i = index">
		<ng-container *ngIf="!excludeFileGuids?.includes(item.guid)">
			<div class="list-item" (click)="openFileAt(i)">
				<div class="list-item-icon">
					<ion-icon slot="icon-only" name="see-document"></ion-icon>
				</div>
				<div class="v-center">
					<div [ngClass]="!item.description ? 'only-name-ctn' : ''" class="list-item-detail">
						<div class="list-item-description" *ngIf="item.description as description" class="list-item-description">
							{{ description }}
						</div>
						<div [ngClass]="!item.description ? 'only-name' : ''" class="list-item-file-name">
							{{ item.name }}
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="!readOnly" class="list-item-options">
				<ng-container *ngIf="!!customButtonsTemplate">
					<ng-container *ngTemplateOutlet="customButtonsTemplate; context: { item: item }"></ng-container>
				</ng-container>
				<ion-icon class="option-icon" *ngIf="allowCustomDescription" (click)="editDescription(item)" color="primary"
					name="create">
				</ion-icon>
				<ion-icon class="option-icon" (click)="deleteItem(i)" color="danger" name="trash"></ion-icon>
			</div>
		</ng-container>
	</div>

	<div [ngClass]="!filePickerVisible && !cameraButtonVisible ? 'collapsed' : ''" class="picpos">
		<ng-container *ngIf="!readOnly">
			<span *ngIf="cameraButtonVisible" style="background-color: transparent">
				<ion-button [disabled]="reachedLimit" (click)="takePicture()" class="licamera-button">
					<ion-icon [slot]="cameraButtonText ? 'start' : 'icon-only'" name="camera"></ion-icon>
					<ion-label *ngIf="cameraButtonText">
						{{ cameraButtonText }}
					</ion-label>
				</ion-button>
			</span>
			<span>
				<file-picker #imagePicker [disabled]="reachedLimit" [ngClass]="imagePickerVisible ? '' : 'collapsed'"
					(fileSelected)="addToModel($event)" [options]="cameraOptions" [multiple]="true" [maxSizeKb]="maxSizeKb"
					[accept]="acceptedImages" [label]="filePickerImagesButtonText" [iconName]="imagePickerIcon"
					(isLoading)="onLoadingChanged($event)">
				</file-picker>
			</span>
			<span>
				<file-picker #filePicker [disabled]="reachedLimit" [ngClass]="filePickerVisible ? '' : 'collapsed'"
					(fileSelected)="addToModel($event)" [options]="cameraOptions" [multiple]="true" [maxSizeKb]="maxSizeKb"
					[accept]="acceptedFiles" [label]="filePickerFilesButtonText" [iconName]="filePickerIcon"
					(isLoading)="onLoadingChanged($event)">
				</file-picker>
			</span>
			<span *ngIf="patientId" style="background-color: transparent">
				<ion-button [disabled]="reachedLimit" (click)="selectImageDossierPatient()" class="licamera-button">
					<ion-icon name="list"></ion-icon>
				</ion-button>
			</span>
		</ng-container>

		<div *ngIf="!hideNoFileText && readOnly && (!files || files.length === 0)" class="empty-gallery">
			<p>Aucun fichier.</p>
		</div>
	</div>
</ng-template>

<ng-template #addMode>
	<div [ngClass]="!filePickerVisible && !cameraButtonVisible ? 'collapsed' : ''" class="picpos">
		<ng-container *ngIf="!readOnly">
			<span *ngIf="cameraButtonVisible" style="background-color: transparent">
				<ion-button [disabled]="reachedLimit" (click)="takePicture()" class="licamera-button">
					<ion-icon [slot]="cameraButtonText ? 'start' : 'icon-only'" name="camera"></ion-icon>
					<ion-label *ngIf="cameraButtonText">
						{{ cameraButtonText }}
					</ion-label>
				</ion-button>
			</span>
			<span>
				<file-picker #imagePicker [disabled]="reachedLimit" [ngClass]="imagePickerVisible ? '' : 'collapsed'"
					(fileSelected)="addToModel($event)" [options]="cameraOptions" [multiple]="true" [maxSizeKb]="maxSizeKb"
					[accept]="acceptedImages" [label]="filePickerImagesButtonText" [iconName]="imagePickerIcon"
					(isLoading)="onLoadingChanged($event)">
				</file-picker>
			</span>
			<span>
				<file-picker #filePicker [disabled]="reachedLimit" [ngClass]="filePickerVisible ? '' : 'collapsed'"
					(fileSelected)="addToModel($event)" [options]="cameraOptions" [multiple]="true" [maxSizeKb]="maxSizeKb"
					[accept]="acceptedFiles" [label]="filePickerFilesButtonText" [iconName]="filePickerIcon"
					(isLoading)="onLoadingChanged($event)">
				</file-picker>
			</span>
		</ng-container>
	</div>
</ng-template>