import { from, Observable, of } from 'rxjs';
import { map, mergeMap, toArray } from 'rxjs/operators';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { ISaveLinksTaskParams } from '../../model/backgroundTask/taskParams/ISaveLinksTaskParams';
import { IContact } from '../../model/contacts/IContact';
import { IGroup } from '../../model/contacts/IGroup';
import { IGroupSaveLinksItem } from '../../model/contacts/IGroupSaveLinksItem';
import { ContactsService } from '../contacts.service';
import { GroupsService } from '../groups.service';
import { InjectorService } from '../injector.service';
import { TaskBase } from './TaskBase';
import { TaskDescriptor } from './TaskDescriptor';

export class GroupSaveLinksTask extends TaskBase<ISaveLinksTaskParams<IGroupSaveLinksItem>> {

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<ISaveLinksTaskParams<IGroupSaveLinksItem>>) {
		super(poDescriptor);
	}

	/** @override */
	public execTask(): Observable<boolean> {
		const laAllGroupIds: string[] = this.descriptor.params.items.map((poItem: IGroupSaveLinksItem) => poItem.groupId);
		const laAllContactIds: string[] =
			ArrayHelper.flat(this.descriptor.params.items.map((poItem: IGroupSaveLinksItem) => [...poItem.newMemberIds, ...poItem.oldMemberIds]));

		return InjectorService.instance.get(ContactsService).getContactsByIds(laAllContactIds)
			.pipe(
				mergeMap((paContacts: IContact[]) => {
					const lsvcGroup: GroupsService = InjectorService.instance.get(GroupsService);

					return lsvcGroup.getGroups(laAllGroupIds)
						.pipe(mergeMap((paGroups: IGroup[]) => this.updateGroups(paGroups, paContacts, lsvcGroup)));
				}),
				toArray(),
				map((paResults: boolean[]) => paResults.every((pbResult: boolean) => pbResult))
			);
	}

	/** Met à jour les différents groupes.
	 * @param paGroups Tableau des groupes qu'il faut mettre à jour.
	 * @param paContacts Tableau des contacts avec lequel mettre à jour les groupes.
	 * @param psvcGroup Service des groupes pour mettre à jour les différents groupes.
	 */
	private updateGroups(paGroups: IGroup[], paContacts: IContact[], psvcGroup: GroupsService): Observable<boolean> {
		return from(this.descriptor.params.items)
			.pipe(
				mergeMap((poItem: IGroupSaveLinksItem) => {
					const laOldMembers: IContact[] = [];
					const laNewMembers: IContact[] = [];

					paContacts.forEach((poContact: IContact) => {
						if (poItem.oldMemberIds.some((psOldMemberId: string) => psOldMemberId === poContact._id))
							laOldMembers.push(poContact);
						if (poItem.newMemberIds.some((psNewMemberId: string) => psNewMemberId === poContact._id))
							laNewMembers.push(poContact);
					});

					if (!ArrayHelper.areArraysFromDatabaseEqual(laOldMembers, laNewMembers))
						return psvcGroup.updateGroup(paGroups.find((poGroup: IGroup) => poGroup._id === poItem.groupId), laOldMembers, laNewMembers);
					else
						return of(true);
				})
			);
	}

	//#endregion

}