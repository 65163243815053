import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ModalModule } from '@osapp/modules/modal';
import { RecentsModule } from '@osapp/modules/preferences/recents/recents.module';
import { SelectorModule } from '@osapp/modules/selector/selector.module';
import { TerminalInfoModalOpenerService } from './components/terminal-info-modal/services/terminal-info-modal-opener.service';
import { TerminalInfoModalComponent } from './components/terminal-info-modal/terminal-info-modal.component';
import { TerminalsSelectorComponent } from './components/terminals-selector/terminals-selector.component';
import { OlaqinService } from './services/olaqin.service';



@NgModule({
	declarations: [TerminalInfoModalComponent, TerminalsSelectorComponent],
	exports: [TerminalInfoModalComponent, TerminalsSelectorComponent],
	imports: [
		CommonModule,
		ModalModule,
		IonicModule,
		RecentsModule.forRoot({ limit: 1 }),
		SelectorModule
	],
	providers: [OlaqinService, TerminalInfoModalOpenerService]
})
export class OlaqinModule { }
