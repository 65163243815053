import { Injectable, ComponentRef, Type } from "@angular/core";
import {
	PopoverComponent,
	SwipeableDrawerComponent,
} from "lighting-up-angular";
import { Subject, Subscription } from "rxjs";
import { DeviceService } from "./device.service";

@Injectable({
	providedIn: "root",
})
export class DrawerPopoverService {
	constructor(private svcDevice: DeviceService) {
		this.subscription = this.svcDevice.isMobile$.subscribe((flag: boolean) => {
			this.isMobile = flag;
		});
	}

	private drawerComponentRef: ComponentRef<SwipeableDrawerComponent>;
	private popoverComponentRef: ComponentRef<PopoverComponent>;
	private closePopoverSubject = new Subject<void>();
	private subscription: Subscription;
	isMobile = false;

	setPopoverComponentRef(componentRef: ComponentRef<PopoverComponent>) {
		this.popoverComponentRef = componentRef;
	}

	setDrawerComponentRef(componentRef: ComponentRef<SwipeableDrawerComponent>) {
		this.drawerComponentRef = componentRef;
	}

	open(
		title: string,
		height: string,
		anchorElement: any,
		content: Type<any>,
		inputs: any,
		onClose?: () => void
	) {
		if (this.isMobile) {
			if (this.drawerComponentRef) {
				this.drawerComponentRef.instance.setContent(content, inputs);
				this.drawerComponentRef.instance.title = title;
				this.drawerComponentRef.instance.height = height;
				this.drawerComponentRef.instance.visible = true;
				if(onClose){
					this.drawerComponentRef.instance.onDrawerClose.subscribe(() => {
						onClose();
					});
				}
			}
		} else {
			if (this.popoverComponentRef) {
				this.popoverComponentRef.instance.setContent(content, inputs);
				this.popoverComponentRef.instance.title = title;
				this.popoverComponentRef.instance.anchorElement = anchorElement;
				this.popoverComponentRef.instance.open = true;
				this.popoverComponentRef.instance.onPopoverClose.subscribe(() => {
					if(onClose){
						onClose();
					}
					this.close();
				});
			}
		}
	}

	close() {
		if (this.popoverComponentRef) {
			this.popoverComponentRef.instance.open = false;
			this.popoverComponentRef.instance.isReady = false;
			this.closePopoverSubject.next();
		}
		if(this.drawerComponentRef) {
			this.drawerComponentRef.instance.close();
			this.closePopoverSubject.next();
		}
	}

	get closePopover$() {
		return this.closePopoverSubject.asObservable();
	}

	cleanup(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
