import { Injectable } from '@angular/core';
import { EModalSize } from '@osapp/modules/modal';
import { ModalService } from '@osapp/modules/modal/services/modal.service';
import { Observable } from 'rxjs';
import { ITerminalInfo } from '../../../models/iterminal-info';
import { ITerminalInfoModalParams } from '../models/iterminal-info-modal-params';
import { TerminalInfoModalComponent } from '../terminal-info-modal.component';

@Injectable()
export class TerminalInfoModalOpenerService {

	//#region METHODS

	constructor(private readonly isvcModal: ModalService) { }

	public open(poParams?: ITerminalInfoModalParams): Observable<ITerminalInfo> {
		return this.isvcModal.open({
			component: TerminalInfoModalComponent,
			componentProps: { params: poParams }
		}, EModalSize.small);
	}

	//#endregion

}
