import { BehaviorSubject, Observable } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { DestroyableComponentBase } from "../../utils/components/destroyable-component-base";

export class ObservableProperty<T> {

	//#region FIELDS

	private readonly moSubject: BehaviorSubject<T>;
	private readonly mfOnValueChanged?: (poValue: T) => void;

	//#endregion

	//#region PROPERTIES

	private moValue: T;
	public get value(): T { return this.moValue; }
	public set value(poNewValue: T) {
		if (poNewValue !== this.moValue) {
			this.moSubject.next(this.moValue = poNewValue);
			if (this.mfOnValueChanged)
				this.mfOnValueChanged(this.moValue);
		}
	}

	public get value$(): Observable<T> { return this.moSubject.asObservable(); }

	//#endregion

	//#region METHODS

	constructor(poInitialValue?: T, pfOnValueChanged?: (poValue: T) => void) {
		this.moValue = poInitialValue;
		this.moSubject = new BehaviorSubject<T>(this.moValue);
		this.mfOnValueChanged = pfOnValueChanged;
	}

	/** Lie les changements de l'instance avec le flux en paramètre.
	 * @param poNewValue$ Flux de la nouvelle valeur.
	 * @param poCaller Composant appelant.
	 */
	public bind(poNewValue$: Observable<T>, poCaller: DestroyableComponentBase): void {
		poNewValue$.pipe(
			tap((poRes: T) => this.moSubject.next(poRes)),
			takeUntil(poCaller.destroyed$)
		)
			.subscribe();
	}

	//#endregion

}
