import { ModelMatch } from '@osapp/modules/utils/models/decorators/model-match.decorator';
import { Constraint } from './constraint';
import { EConstraintType } from './EConstraintType';
import { IActeOccurrence } from './iacte-occurrence';
import { ICancelConstraint } from './icancel-constraint';

const C_TYPE = EConstraintType.cancel;

@ModelMatch((poData: ICancelConstraint) => poData?.type === C_TYPE, Constraint)
export class CancelConstraint extends Constraint implements ICancelConstraint {

	//#region PROPERTIES

	public readonly type: EConstraintType = C_TYPE;

	//#endregion

	//#region METHODS

	/** @override */
	public action(poActeOccurrence: IActeOccurrence): void {
		poActeOccurrence.canceled = true;
	}

	/** @override */
	public buildlabel(): string {
		return "annulée(s).";
	}

	//#endregion
}
