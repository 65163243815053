<osapp-date-time #timePicker class="hidden" [params]="timeParams" (modelChange)="onDateModified($event)">
</osapp-date-time>

<osapp-date-time #datePicker class="hidden" [params]="dateParams" (modelChange)="onDateModified($event)">
</osapp-date-time>

<div class="planification listes-tournees marg-btm">
	<ion-list
		*ngIf="canceledSeancesTournee?.length > 0 || scheduledSeancesTournee?.length > 0 || doneSeancesTournee?.length > 0"
		class="colonne ion-no-padding marg-container">
		<ion-list-header *ngIf="title">
			<ion-label class="col-title">{{title}}</ion-label>
		</ion-list-header>

		<ng-container *ngIf="doneSeancesTournee?.length > 0">
			<mat-expansion-panel class="canceled-seances">
				<mat-expansion-panel-header>
					<mat-panel-title>
						<ion-icon name="checkmark"></ion-icon>
						<ion-label class="color-title">Validées</ion-label>
					</mat-panel-title>
				</mat-expansion-panel-header>
				<ion-item *ngFor="let doneSeance of doneSeancesTournee; trackBy: trackById" color="success" lines="none"
					class="seance-tournee" [disabled]="doneSeance.seance.shadow">
					<calao-conflict-list-marker *ngIf="doneSeance.seance._conflicts"></calao-conflict-list-marker>
					<!-- Boutons de positionnement 'haut' et 'bas'. -->
					<ng-container *ngIf="isPlanificationMode">
						<div slot="start" class="arrow-replace">
							<ion-button *ngIf="idx > 0" fill="clear" class="ion-no-margin white"
								(click)="permuteSeanceTournee(doneSeance, idx, scheduledSeancesTournee[idx-1], idx-1)">
								<ion-icon slot="icon-only" name="caret-up" class="marg-arrow"></ion-icon>
							</ion-button>
							<ion-button *ngIf="idx < scheduledSeancesTournee?.length - 1" fill="clear" class="ion-no-margin white"
								(click)="permuteSeanceTournee(doneSeance, idx, scheduledSeancesTournee[idx+1], idx+1)">
								<ion-icon slot="icon-only" name="caret-down-outline"></ion-icon>
							</ion-button>
						</div>
					</ng-container>
					<!-- Date de la séance, édition séance, durée de la séance. -->
					<div class="hour-tournee" (click)="pickDate(timePicker, doneSeance)">
						<ion-label color="osapp-element pointer" class="schedule">
							<ion-label class="txt-info white">{{ doneSeance.seance.startDate | dateWithLocale:
								'fr-FR':'HH:mm'}}
							</ion-label>
							<ion-note class="txt-info white">{{ doneSeance.seance.duration }}mn</ion-note>
						</ion-label>
					</div>
					<!-- Nom du patient, édition séance, résumé des actes. -->
					<ion-label class="margin-label white pointer" (click)="openSeanceModal(doneSeance)">
						<ion-label class="white">
							{{ doneSeance.patient.groupMember | patientName }}
						</ion-label>
						<p class="seance-note">{{ doneSeance.seance.actes | concatActes: "," : acteDisplayMode }}</p>
						<div class="address" *ngIf="doneSeance.seance.place === 'home' && hasAddress(doneSeance)">
							<ion-icon slot="icon-only" name="home"></ion-icon>
							<p class="seance-note">{{ doneSeance.patient.groupMember.street | titlecase}} {{
								doneSeance.patient.groupMember.zipCode }} {{ doneSeance.patient.groupMember.city |
								titlecase }}
							</p>
						</div>
						<div class="address" *ngIf="doneSeance.seance.place === 'center'">
							<ion-icon slot="icon-only" name="center"></ion-icon>
							<p class="seance-note">
								{{ currentSite.street | titlecase}} {{ currentSite.zipCode }} {{currentSite.city | titlecase }}
							</p>
						</div>
					</ion-label>
					<!-- Avatars des intervenants. -->
					<ion-avatar slot="end" (click)="$event.stopPropagation(); selectIntervenants(doneSeance)"
						class="marge-left pointer">
						<div class="intervenants-wrapper">
							<ng-container *ngIf="doneSeance.intervenants?.length > 0;else defaultAvatar">
								<avatar class="avatar up" [cssClass]="'round'" [src]="doneSeance.intervenants[0].avatar"
									#tooltip="matTooltip" [matTooltip]="getIntervenantsNames(doneSeance.intervenants)">
								</avatar>
								<a *ngIf="doneSeance.intervenants.length > 1"
									class="avatar intervenant-count">+{{doneSeance.intervenants.length - 1}}</a>
							</ng-container>
						</div>
					</ion-avatar>

					<!-- Bouton d'options. -->
					<ion-button class="ion-no-margin white" fill="clear" slot="end"
						(click)="openTourneePopover($event, doneSeance, timePicker, datePicker)">
						<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
					</ion-button>
				</ion-item>
			</mat-expansion-panel>
		</ng-container>

		<ng-container *ngIf="scheduledSeancesTournee?.length > 0">
			<mat-expansion-panel class="canceled-seances" [expanded]="true">
				<mat-expansion-panel-header>
					<mat-panel-title>
						<ion-icon name="filled-hourglass"></ion-icon>
						<ion-label class="color-title">À venir</ion-label>
					</mat-panel-title>
				</mat-expansion-panel-header>
				<ng-container *ngFor="let plannedSeance of scheduledSeancesTournee; index as idx; trackBy: trackById">
					<ng-container *ngIf="!!plannedSeance">
						<ion-item color="osapp-element" lines="none" class="seance-tournee"
							[disabled]="plannedSeance.seance.shadow">
							<calao-conflict-list-marker *ngIf="plannedSeance.seance._conflicts">
							</calao-conflict-list-marker>
							<!-- Boutons de positionnement 'haut' et 'bas'. -->
							<ng-container *ngIf="isPlanificationMode">
								<div slot="start" class="arrow-replace">
									<ion-button *ngIf="idx > 0" fill="clear" class="ion-no-margin white"
										(click)="permuteSeanceTournee(plannedSeance, idx, scheduledSeancesTournee[idx-1], idx-1)">
										<ion-icon slot="icon-only" name="caret-up" class="marg-arrow"></ion-icon>
									</ion-button>
									<ion-button *ngIf="idx < scheduledSeancesTournee?.length - 1" fill="clear" class="ion-no-margin white"
										(click)="permuteSeanceTournee(plannedSeance, idx, scheduledSeancesTournee[idx+1], idx+1)">
										<ion-icon slot="icon-only" name="caret-down-outline"></ion-icon>
									</ion-button>
								</div>
							</ng-container>
							<!-- Date de la séance, édition séance, durée de la séance. -->
							<div class="hour-tournee active-seance" (click)="pickDate(timePicker, plannedSeance)">
								<ion-label color="osapp-element pointer" class="schedule">
									<div *ngIf="plannedSeance.seance as seance" class="icon-status-seance">
										<ion-icon *ngIf="seance.isLast" matTooltip="Dernière séance" slot="start" name="alert-circle"
											color="warning"></ion-icon>
										<ion-icon *ngIf="plannedSeance.conflicted" matTooltip="Conflit horaire" slot="start"
											name="conflict-circle" color="warning"></ion-icon>
										<ion-icon *ngIf="seance.isLate && !plannedSeance.conflicted && !seance.isLast" matTooltip="Retard"
											slot="start" name="hourglass-circle" color="warning">
										</ion-icon>
									</div>
									<ion-label
										[ngClass]="plannedSeance.seance.isLast || plannedSeance.conflicted || plannedSeance.seance.isLate ? 'warning-label' : ''">
										{{plannedSeance.seance.startDate | dateWithLocale:'fr-FR':'HH:mm'}}
									</ion-label>
									<ion-note>{{ plannedSeance.seance.duration }}mn</ion-note>
								</ion-label>
							</div>
							<!-- Nom du patient, édition séance, résumé des actes. -->
							<ion-label class="margin-label white pointer" (click)="openSeanceModal(plannedSeance)">
								<ion-label class="white">
									{{ plannedSeance.patient.groupMember | patientName }}
								</ion-label>
								<p class="seance-note">{{ plannedSeance.seance.actes | concatActes: "," :
									acteDisplayMode }}</p>
								<div class="address" *ngIf="plannedSeance.seance.place === 'home' && hasAddress(plannedSeance)">
									<ion-icon slot="icon-only" name="home"></ion-icon>
									<p class="seance-note">{{ plannedSeance.patient.groupMember.street | titlecase}} {{
										plannedSeance.patient.groupMember.zipCode }} {{
										plannedSeance.patient.groupMember.city | titlecase
										}}
									</p>
								</div>
								<div class="address" *ngIf="plannedSeance.seance.place === 'center'">
									<ion-icon slot="icon-only" name="center"></ion-icon>
									<p class="seance-note">
										{{ currentSite.street | titlecase}} {{ currentSite.zipCode }} {{currentSite.city | titlecase }}
									</p>
								</div>
							</ion-label>
							<!-- Avatars des intervenants. -->
							<ion-avatar slot="end" (click)="$event.stopPropagation(); selectIntervenants(plannedSeance)"
								class="marge-left pointer">
								<div class="intervenants-wrapper">
									<ng-container *ngIf="plannedSeance.intervenants?.length > 0;else defaultAvatar">
										<avatar class="avatar up" [cssClass]="'round'" [src]="plannedSeance.intervenants[0].avatar"
											#tooltip="matTooltip" [matTooltip]="getIntervenantsNames(plannedSeance.intervenants)">
										</avatar>
										<a *ngIf="plannedSeance.intervenants.length > 1"
											class="avatar intervenant-count">+{{plannedSeance.intervenants.length -
											1}}</a>
									</ng-container>
								</div>
							</ion-avatar>

							<!-- Bouton d'options. -->
							<ion-button class="ion-no-margin white" fill="clear" slot="end"
								(click)="openTourneePopover($event, plannedSeance, timePicker, datePicker)">
								<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
							</ion-button>
						</ion-item>

						<ng-container *ngIf="plannedSeance.pause">
							<idl-timelined-event [event]="plannedSeance.pause">
								<idl-tournee-pause [pause]="plannedSeance.pause"></idl-tournee-pause>
							</idl-timelined-event>
						</ng-container>
					</ng-container>

				</ng-container>
			</mat-expansion-panel>
		</ng-container>

		<ng-container *ngIf="canceledSeancesTournee?.length > 0">
			<mat-expansion-panel class="canceled-seances">
				<mat-expansion-panel-header>
					<mat-panel-title>
						<ion-icon name="close"></ion-icon>
						<ion-label class="color-title">Annulées</ion-label>
					</mat-panel-title>
				</mat-expansion-panel-header>
				<ion-item *ngFor="let canceledSeance of canceledSeancesTournee; trackBy: trackById" color="medium" lines="full"
					class="seance-tournee" [disabled]="canceledSeance.seance.shadow">
					<calao-conflict-list-marker *ngIf="canceledSeance.seance._conflicts"></calao-conflict-list-marker>
					<!-- Date de la séance, édition séance, durée de la séance. -->
					<div class="hour-tournee">
						<ion-label color="osapp-element pointer" class="schedule">
							<ion-label class="txt-info white">{{ canceledSeance.seance.startDate | dateWithLocale:
								'fr-FR':'HH:mm'}}
							</ion-label>
							<ion-note class="txt-info white">{{ canceledSeance.seance.duration }}mn</ion-note>
						</ion-label>
					</div>
					<!-- Nom du patient, édition séance, résumé des actes. -->
					<ion-label class="margin-label white pointer" (click)="openSeanceModal(canceledSeance)">
						<ion-label>
							{{ canceledSeance.patient.groupMember | patientName }}
						</ion-label>
						<p class="seance-note">{{ canceledSeance.seance.actes | concatActes: "," : acteDisplayMode }}
						</p>
						<div class="address" *ngIf="canceledSeance.seance.place === 'home' && hasAddress(canceledSeance)">
							<ion-icon slot="icon-only" name="home"></ion-icon>
							<p class="seance-note">{{ canceledSeance.patient.groupMember | contactAddress }}</p>
						</div>
					</ion-label>
					<!-- Avatars des intervenants. -->
					<ion-avatar slot="end" disabled>
						<div class="intervenants-wrapper">
							<ng-container *ngIf="canceledSeance.intervenants?.length > 0;else defaultAvatar">
								<avatar class="avatar up" [cssClass]="'round'" [src]="canceledSeance.intervenants[0].avatar">
								</avatar>
								<a *ngIf="canceledSeance.intervenants.length > 1"
									class="avatar intervenant-count">+{{canceledSeance.intervenants.length - 1}}</a>
							</ng-container>
						</div>
					</ion-avatar>

					<!-- Bouton d'options. -->
					<ion-button class="ion-no-margin white" fill="clear" slot="end"
						(click)="openTourneePopover($event, canceledSeance, timePicker, datePicker)">
						<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
					</ion-button>
				</ion-item>
			</mat-expansion-panel>
		</ng-container>
	</ion-list>
</div>

<ng-template #defaultAvatar>
	<avatar [src]="emptyAvatar" [cssClass]="'round'">
	</avatar>
</ng-template>