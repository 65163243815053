<div class="page-parametres__container">
	<div class="page-parametres__page-gauche" *ngIf="showMenu">
		<h1 *ngIf="isMobileView">Paramètres</h1>
		<div class="menu-items">
			<div class="list-item">
				<lua-list-item
					iconName="mail"
					text="Utilisateurs"
					iconColor="CouleurPrimaire"
					[isSelected]="!isMobileView && utilisateursSelected"
					(click)="handleSelectionUtilisateurs()"
				></lua-list-item>
			</div>
		</div>
	</div>
	<div class="page-parametres__page-droite" *ngIf="showPageDroite">
		<di-utilisateurs
			*ngIf="showUtilisateurs"
			[isMobileView]="isMobileView"
		></di-utilisateurs>
		<lua-bouton-texte *ngIf="isMobileView" libelle="Retour aux paramètres" [fullWidth]="true" (click)="handleRetourParametres()" ></lua-bouton-texte>
	</div>
</div>
