<osapp-header-modal [title]="traitement ? traitement.label : 'Traitement'">
</osapp-header-modal>

<ion-content>
	<div *ngIf="sortedPrestations?.length > 0">
		<div class="select-all-ctn">
			<div class="center-v pointer" (click)="$event.stopPropagation(); onPrestationsCheckboxClick(sortedPrestations)">
				<mat-checkbox class="disable-clicks" [checked]="prestationsToSend.length === sortedPrestations.length">
				</mat-checkbox>
			</div>
			<ion-label class="seance-label center-v">Tout sélectionner</ion-label>
		</div>
		<calao-virtual-scroll maxHeight="82vh" itemSize="350" [items]="sortedPrestations">
			<ng-template let-prestation="$implicit">
				<div>
					<div>
						<ion-grid class="seance-grid">
							<ion-row>
								<ion-col class="flex" [size]="isSmallScreen ? '7' : '6'">
									<div class="center-v pointer"
										(click)="$event.stopPropagation(); onPrestationsCheckboxClick([prestation])">
										<mat-checkbox *ngIf="!prestation.isLocked" [checked]="prestationsToSend.includes(prestation)"
											class="disable-clicks">
										</mat-checkbox>
									</div>
									<div class="action-btns">
										<ion-icon *ngIf="exportFailedMessageByPrestationId.has(prestation._id)" name="alert-circle"
											color="danger"
											(click)="$event.stopPropagation(); displayExportPrestationFailedPopup(prestation._id)">
										</ion-icon>
									</div>
									<ion-label class="seance-label center-v">{{ prestation.lines[0].group }}</ion-label>
									<div class="action-btns">
										<ion-icon [name]="prestation.status === 3 ? 'lock-closed' : 'lock-open-outline'"
											[color]="prestation.status === 3 ? 'danger' : 'primary'"
											(click)="$event.stopPropagation(); onPrestationLockClick(prestation);">
										</ion-icon>
										<span *ngIf="prestation.status === 2" class="checked-seance-btn"
											(click)="$event.stopPropagation(); onPrestationStatusChange(prestation, prestation.originalStatus);">
											<ion-icon name="certified"></ion-icon>
											<ion-label>Vérifié</ion-label>
										</span>
										<ion-icon *ngIf="prestation.status !== 2" name="certified" color="primary"
											(click)="$event.stopPropagation(); onPrestationStatusChange(prestation, 2);">
										</ion-icon>
									</div>
								</ion-col>
								<ion-col *ngIf="!isSmallScreen" class="seance-btns-col center" size="1.5">
									<ng-container *ngIf="prestation.status !== 2">
										<ion-fab-button color="primary" shape="round" fill="clear"
											(click)="$event.stopPropagation(); addActe(prestation);" title="Ajouter">
											<ion-icon name="add-act"></ion-icon>
										</ion-fab-button>
										<ion-fab-button color="primary" shape="round" class="reset-btn"
											(click)="$event.stopPropagation(); reset(prestation);" title="Actualiser">
											<ion-icon name="refresh-outline">
											</ion-icon>
										</ion-fab-button>
									</ng-container>
								</ion-col>
								<ion-col class="status-col center" [size]="isSmallScreen ? '1.5' : '2'">
									<ng-container *ngIf="prestation.status !== 2 && !isSmallScreen">
										<ion-icon [name]="prestation.originalStatusIcon" [color]="prestation.originalStatusColor">
										</ion-icon>
										<ion-icon name="arrow-forward" color="primary" class="icon-arrow"></ion-icon>
										<span class="status-selection" [ngClass]="prestation.status === 0 ? 'background-success' : ''"
											(click)="$event.stopPropagation(); onPrestationStatusChange(prestation, 0);">
											<ion-icon name="date-validation" color="success"></ion-icon>
										</span>
										<span class="status-selection" [ngClass]="prestation.status === 1 ? 'background-danger' : ''"
											(click)="$event.stopPropagation(); onPrestationStatusChange(prestation, 1);">
											<ion-icon name="date-supp" color="danger"></ion-icon>
										</span>
									</ng-container>
									<ng-container *ngIf="prestation.status !== 2 && isSmallScreen">
										<span *ngIf="prestation.status === 0" class="status-selection">
											<ion-icon name="date-validation" color="success"></ion-icon>
										</span>
										<span *ngIf="prestation.status === 1" class="status-selection">
											<ion-icon name="date-supp" color="danger"></ion-icon>
										</span>
									</ng-container>
								</ion-col>
								<ion-col class="intervenant-col" size="1.5">
									<ng-container *ngIf="!isSmallScreen">
										<avatar [src]="getContactAvatar(prestation.authorId)" cssClass="round">
										</avatar>
										<ion-icon name="arrow-forward" color="primary" class="icon-arrow"></ion-icon>
										<avatar *ngIf="prestation.status !== 2" [src]="getContactAvatar(prestation.authorId)"
											cssClass="round" (click)="$event.stopPropagation(); selectIntervenant(prestation);">
										</avatar>
										<avatar *ngIf="prestation.status === 2" [src]="getContactAvatar(prestation.authorId)"
											cssClass="round">
										</avatar>
									</ng-container>
									<ng-container *ngIf="isSmallScreen">
										<avatar [src]="getContactAvatar(prestation.authorId)" cssClass="round">
										</avatar>
									</ng-container>
								</ion-col>
								<ion-col class="txt-primary price-col" [size]="isSmallScreen ? '2' : '1'">
									{{ prestation.total | priceFormat:2 }} €
								</ion-col>
							</ion-row>
						</ion-grid>
					</div>
					<!-- Liste des Actes. -->
					<ion-grid class="seance-grid detail-grid">
						<ng-container *ngFor="let line of getActesLines(prestation)">
							<ng-container *ngIf="line.originalLineRef">
								<ion-row *ngIf="getOriginalLine(prestation, line.originalLineRef) as originalLine"
									class="replaced-or-deleted-row">
									<ion-col size="6">
										<ion-label class="line-description">{{originalLine.description}}</ion-label>
									</ion-col>
									<ion-col size="3"></ion-col>
									<ion-col size="1.5"></ion-col>
									<ion-col class="price-col" size="1.5">{{originalLine.total | priceFormat:2}} €
									</ion-col>
								</ion-row>
							</ng-container>

							<ion-row [ngClass]="line.deleted ? 'replaced-or-deleted-row' : ''">
								<ion-col size="5">
									<div class="description-ctn">
										<ion-icon *ngIf="line.originalLineRef" name="return-down-forward-outline" color="primary">
										</ion-icon>
										<ion-label class="line-description">{{line.description}}</ion-label>
									</div>
								</ion-col>
								<ion-col class="discount-rate-col" size="4">
									<ng-container *ngIf="prestation.status !== 2 && !line.deleted">
										<ng-container *ngIf="!isSmallScreen">
											<ion-label>Ab.
												<ng-container *ngIf="getOriginalLine(prestation, line.ref) as originalLine">
													<span [class]="originalLine.discountRate !== line.discountRate ? 'txt-crossed' : ''">
														{{ getDiscountRateLabel(originalLine.discountRate) }}
													</span>
												</ng-container>
											</ion-label>
											<ion-icon name="arrow-forward" color="primary"></ion-icon>
											<mat-radio-group (change)="onDiscountRateChanged($event?.value, prestation, line)">
												<mat-radio-button *ngFor="let option of discounRateOptions" [value]="option.value"
													[checked]="option.value === line.discountRate">{{
													option.label }}</mat-radio-button>
											</mat-radio-group>
										</ng-container>
									</ng-container>
									<ng-container *ngIf="isSmallScreen">
										<ion-label>Ab. {{ getDiscountRateLabel(line.discountRate) }}%</ion-label>
									</ng-container>
								</ion-col>
								<ion-col *ngIf="!isSmallScreen" size="2">
									<div *ngIf="prestation.status !== 2 && !line.deleted" class="acte-btns">
										<ion-fab-button color="primary" shape="round" fill="clear" (click)="changeActe(prestation, line)"
											title="Changer">
											<ion-icon name="change-act"></ion-icon>
										</ion-fab-button>
										<ion-fab-button color="danger" shape="round" (click)="deleteActe(prestation, line)"
											title="Supprimer">
											<ion-icon name="remove-outline"></ion-icon>
										</ion-fab-button>
									</div>
								</ion-col>
								<ion-col class="price-col" [size]="isSmallScreen ? '3' : '1'">
									{{ line.total | priceFormat:2 }} €
								</ion-col>
							</ion-row>
						</ng-container>
						<!-- Ligne des Majorations. -->
						<ion-row>
							<ion-col *ngIf="!isSmallScreen" size="11" class="center-v">
								<ion-label class="line-description">
									Majorations :
									<ng-container
										*ngFor="let majoration of getOriginalsMajorations(prestation) as majorations; let i = index;">
										<span [class]="majoration.deleted ? 'txt-crossed' : ''">{{ majoration.shortLabel
											}}</span><span>{{ i + 1 !== majorations.length ? ', ' : '' }}</span>
									</ng-container>
								</ion-label>
								<ion-icon name="arrow-forward" color="primary"></ion-icon>
								<div class="majo-checkbox-ctn center-v">
									<div *ngFor="let option of majorationsDetails" class="center-v">
										<div class="checkbox"
											(click)="$event.stopPropagation(); onMajorationClick(prestation, option.valueType)">
											<mat-checkbox class="disable-clicks"
												[checked]="getMajorationsAttributeFromLines(prestation.lines, 'valueType')?.includes(option.valueType)">
											</mat-checkbox>
										</div>
										<ion-label>{{ option.shortLabel }}</ion-label>
									</div>
								</div>
							</ion-col>
							<ion-col *ngIf="isSmallScreen" size="9" class="center-v">
								<ion-label class="line-description">
									Majorations : {{ getMajorationsAttributeFromLines(prestation.lines,
									'valueType').join(', ')
									|| "Aucune" }}
								</ion-label>
							</ion-col>
							<ion-col class="price-col" [size]="isSmallScreen ? '3' : '1'">
								{{getMajorationsTotalPrice(prestation) | priceFormat:2}} €
							</ion-col>
						</ion-row>
						<!-- Ligne des Indemnités. -->
						<ion-row>
							<ion-col [size]="isSmallScreen ? '9' : '11'" class="center-v indemnite-col">
								<ng-container *ngIf="!isSmallScreen">
									<ion-label class="line-description">
										Déplacement :
										<ng-container
											*ngFor="let indemnite of getOriginalsIndemnites(prestation) as indemnites; let i = index;">
											<span [class]="indemnite.deleted ? 'txt-crossed' : ''">{{ indemnite.label
												}}</span><span>{{ i + 1 !== indemnites.length ? ', ' : '' }}</span>
										</ng-container>
									</ion-label>
									<ion-icon name="arrow-forward" color="primary"></ion-icon>
									<div class="majo-checkbox-ctn center-v">
										<div *ngFor="let option of indemniteOptions" class="center-v">
											<div class="checkbox"
												(click)="$event.stopPropagation(); onIndemniteClick(traitement, prestation, option)">
												<mat-checkbox class="disable-clicks"
													[checked]="getIndemnitesAttributeFromLines(prestation.lines, 'lettreCle')?.includes(option)">
												</mat-checkbox>
											</div>
											<ion-label>{{ option }}</ion-label>
										</div>
									</div>
									<ng-container *ngIf="hasIKType(prestation)">
										<input type="number" [value]="getIndemnitesDistanceValue(prestation)"
											(change)="onDistanceChange($event.target.value, prestation)">
										<ion-label>Km</ion-label>
										<ng-container *ngIf="!isSmallScreen">
											<div *ngIf="getSelectedIkLine(prestation) as IKLine" class="ik-discount-rate">
												<ion-label>Ab.</ion-label>
												<mat-radio-group (change)="onDiscountRateChanged($event?.value, prestation, IKLine)">
													<mat-radio-button *ngFor="let option of discounRateOptions" [value]="option.value"
														[checked]="option.value === IKLine.discountRate">
														{{ option.label }}
													</mat-radio-button>
												</mat-radio-group>
											</div>
										</ng-container>
									</ng-container>
								</ng-container>
								<ng-container *ngIf="isSmallScreen">
									<ion-label class="line-description">
										Déplacement : {{ getIndemnitesAttributeFromLines(prestation.lines,
										'lettreCle').join(', ')
										||
										"Aucun" }}
										<span *ngIf="hasIKType(prestation)"> {{ getIndemnitesDistanceValue(prestation)
											}}km</span>
									</ion-label>
									<ion-label *ngIf="getSelectedIkLine(prestation) as IKLine">Ab. {{
										getDiscountRateLabel(IKLine.discountRate) }}</ion-label>
								</ng-container>
							</ion-col>
							<ion-col class="price-col" [size]="isSmallScreen ? '3' : '1'">
								{{getIndemnitesTotalPrice(prestation) | priceFormat:2}} €
							</ion-col>
						</ion-row>
					</ion-grid>
					<mat-form-field appearance="outline" class="description-input observation">
						<mat-label>Observations</mat-label>
						<textarea matInput matTextareaAutosize matAutosizeMinRows="3"
							(ngModelChange)="onObservationChange(prestation)" [(ngModel)]="prestation.observation"></textarea>
					</mat-form-field>
				</div>
			</ng-template>
		</calao-virtual-scroll>
	</div>
</ion-content>

<!-- Bouton de facturation (slide 'en attente'). -->
<div class="send-btn">
	<ion-fab *ngIf="canBillToDesmos" vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
		<ion-fab-button color="fab-button" (click)="sendToDesmos()">
			<ion-icon name="desmos"></ion-icon>
			<ion-label>Envoyer</ion-label>
		</ion-fab-button>
	</ion-fab>

	<ion-fab *ngIf="canBillToFsv || isModeAgrement" vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
		<ion-fab-button color="fab-button" (click)="prepareInvoices()">
			<ion-label>Préparer</ion-label>
		</ion-fab-button>
	</ion-fab>
</div>