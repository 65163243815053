<osapp-header-modal [title]="title"></osapp-header-modal>

<ion-content>
	<!-- Zone de signature. -->
	<div class="drawing-area">
		<calao-drawing #drawingComponent (onEmptyChanged)="updateEmptyDrawStatus($event)" [showUndoButton]="showUndoButton"
			[showClearButton]="showClearButton" [options]="options" [readonly]="readonly" [base64Data]="base64Data"
			[data]="data" [missingDrawingText]="missingDrawingText">
		</calao-drawing>
	</div>
</ion-content>

<ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
	<ion-fab-button (click)="validate(drawingComponent)" [disabled]="!canValidate" color="validation" title="Valider">
		<ion-icon name="checkmark-outline"></ion-icon>
	</ion-fab-button>
</ion-fab>