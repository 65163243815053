import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ArrayHelper, DateHelper } from '@osapp/helpers';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { IDateTimePickerParams } from '@osapp/model/date/IDateTimePickerParams';
import { ICustomEvent } from '@osapp/model/event/ICustomEvent';
import { ModalComponentBase } from '@osapp/modules/modal';
import { PlatformService } from '@osapp/services/platform.service';
import { Acte } from '../../../model/Acte';
import { EStatusSeance } from '../../../model/EStatusSeance';
import { Seance } from '../../../model/Seance';
import { SeancesHelper } from '../../seances/helpers/seances.helper';
import { IMoveActeModalResponse } from '../model/IMoveActeModalResponse';

@Component({
	templateUrl: './move-acte-modal.component.html',
	styleUrls: ['./move-acte-modal.component.scss']
})
export class MoveActeModalComponent extends ModalComponentBase<IMoveActeModalResponse> implements OnInit {

	//#region PROPERTIES

	public selectedSeance: Seance;
	public acte: Acte;
	public seances: Seance[] = [];
	public filteredSeances: Seance[] = [];
	public datePickerParams: IDateTimePickerParams = DateHelper.datePickerParamsFactory(ETimetablePattern.EEE_dd_MMMM_yyyyy_HH_mm);

	//#endregion

	//#region METHODS

	constructor(poModalCtrl: ModalController, psvcPlatform: PlatformService) {
		super(poModalCtrl, psvcPlatform);
		this.datePickerParams.placeholder = "Créer une nouvelle séance";
	}

	public ngOnInit(): void {
		super.ngOnInit();

		if (ArrayHelper.hasElements(this.seances)) {
			SeancesHelper.sortSeancesByDate(
				this.filteredSeances = this.seances.filter((poSeance: Seance) =>
					poSeance.status !== EStatusSeance.canceled &&
					poSeance.status !== EStatusSeance.done &&
					poSeance.actes.every((poActe: Acte) => poActe.id !== this.acte.id)
				)
			);
		};
	}

	public validate(): void {
		const loActe: Acte = new Acte(this.acte);
		this.selectedSeance.actes.push(loActe);

		const loResponse: IMoveActeModalResponse = {
			seance: this.selectedSeance
		};

		this.close(loResponse);
	}

	public createSeance(pdDate: Date): void {
		this.selectedSeance = new Seance([], this.seances.length, pdDate);
		this.selectedSeance.scheduled = true;
	}

	public onSeanceSelected(poEvent: ICustomEvent<Seance>): void {
		this.selectedSeance = poEvent.detail.value;
	}

	//#endregion
}