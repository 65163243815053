import { Injectable } from '@angular/core';
import PCancelable, { CancelError } from 'p-cancelable';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { MapHelper } from '../../../../helpers/mapHelper';
import { StoreDocumentHelper } from '../../../../helpers/storeDocumentHelper';
import { StoreHelper } from '../../../../helpers/storeHelper';
import { Database } from '../../../../model/store/Database';
import { IStoreDocument } from '../../../../model/store/IStoreDocument';
import { IStoreReplicationResponse } from '../../../../model/store/IStoreReplicationResponse';
import { NetworkService } from '../../../../services/network.service';
import { OsappError } from '../../../errors/model/OsappError';
import { PerformanceManager } from '../../../performance/PerformanceManager';
import { CancelablePromiseTracker } from '../../../promises/models/cancelable-promise-tracker';
import { IChangeTrackerItem } from '../../change-tracking/models/ichange-tracker-item';
import { ILot } from '../../change-tracking/models/ilot';
import { ChangeTrackingService } from '../../change-tracking/services/change-tracking.service';
import { ISyncronizationEvent } from '../../model/isyncronization-event';
import { IBulkGetResult } from '../models/ibulk-get-result';
import { IOnProgressFunction } from '../models/ion-progress-function';
import { IReplicatorOptions } from '../models/ireplicator-options';
import { ReplicatorBase } from '../models/replicator-base';

type IDocRevsInfo = PouchDB.Core.Document<unknown> & PouchDB.Core.GetMeta;
interface IDocIdRev {
	id: string;
	rev: string;
}


@Injectable()
export class ChangeTrackingReplicatorService extends ReplicatorBase {

	//#region FIELDS

	private static readonly C_LOG_ID = "CHANGETRACKREPLIC.S::";
	private static readonly C_DEFAULT_BATCH_SIZE = 100;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcChangeTracker: ChangeTrackingService,
		psvcNetwork: NetworkService
	) {
		super(psvcNetwork);
	}

	/** @override */
	public replicateAsync(
		poDatabase: Database,
		poSource: PouchDB.Database<any>,
		poTarget: PouchDB.Database<any>,
		poOptions?: IReplicatorOptions,
		pfOnProgress?: IOnProgressFunction
	): PCancelable<IStoreReplicationResponse> {
		const loPromise = new PCancelable<IStoreReplicationResponse>(async (pfResolve, pfReject, pfOnCancel) => {
			const loReplicatePerformanceManager = new PerformanceManager().markStart();
			const lsStartTime: string = new Date(loReplicatePerformanceManager.startTime).toISOString();
			const laResponses: IStoreDocument[] = [];
			const lnSeqNumber: number = (await poSource.info()).update_seq as number;
			let lnDocs = 0;
			const loCancelablePromiseTracker = new CancelablePromiseTracker;

			if (StoreHelper.isRemoteDatabase(poSource.name))
				throw new OsappError("La base source doit être une base locale.");

			console.debug(`${ChangeTrackingReplicatorService.C_LOG_ID}Starting replication for ${poDatabase.id}.`);
			try {
				await this.handleNetworkAsync(poDatabase);

				pfOnCancel(() => loCancelablePromiseTracker.cancelAll());

				// On met à jour et on récupère les lots avant la mise à jour, pour que les nouveaux track se fassent sur un nouveau lot
				const laLots: ILot[] = await loCancelablePromiseTracker.track(this.isvcChangeTracker.getAndUpdateLastLot(poDatabase.id, (await poSource.info()).update_seq as number));
				const loLastLot: ILot = ArrayHelper.getLastElement(laLots);
				const laChangeTrackerItems: IChangeTrackerItem[] = await loCancelablePromiseTracker.track(this.isvcChangeTracker.getTracked(poDatabase.id, loLastLot));
				// On groupe les trackers par identifiants de document.
				const loChangeTrackerItemsGroupedByLot: Map<number, IChangeTrackerItem[]> = this.groupChangeTrackerItemsByLot(laChangeTrackerItems);
				const loLotsById: Map<number, ILot> = ArrayHelper.groupByUnique(laLots, (poLot: ILot) => poLot.id);
				// On fonctionne par groupe de 100 par défaut
				const lnBatchSize: number = poOptions?.batch_size ?? ChangeTrackingReplicatorService.C_DEFAULT_BATCH_SIZE;

				// Pour chaque lot
				for (const [lnLotId, laLotChangeTrackerItems] of Array.from(loChangeTrackerItemsGroupedByLot)) {
					if (loPromise.isCanceled) break;
					while (ArrayHelper.hasElements(laLotChangeTrackerItems)) { // Tant qu'il y a des éléments à envoyer
						const laBatchItems: IChangeTrackerItem[] = laLotChangeTrackerItems.splice(0, lnBatchSize);
						laResponses.push(...(await loCancelablePromiseTracker.track(this.processBatch(poDatabase, laBatchItems, poSource, loLotsById, lnLotId, poTarget))));

						const loISyncronizationEvent: ISyncronizationEvent = { total: laChangeTrackerItems.length, loaded: lnDocs += laBatchItems.length };

						this.setSynchronizationEvent(poDatabase, loISyncronizationEvent, poSource, poTarget);

						if (pfOnProgress)
							await pfOnProgress(loISyncronizationEvent, this.createReplicationResponse(lnDocs, lnSeqNumber, lsStartTime, laResponses, true));
					};
				}
				console.debug(`${ChangeTrackingReplicatorService.C_LOG_ID}Replication time for database ${poDatabase.id} : ${loReplicatePerformanceManager.markEnd().measure()}ms.`, laResponses);

				pfResolve(this.createReplicationResponse(lnDocs, lnSeqNumber, lsStartTime, laResponses));
			}
			catch (poError) {
				if (poError instanceof CancelError)
					pfResolve(this.createReplicationResponse(lnDocs, lnSeqNumber, lsStartTime, laResponses));

				pfReject(poError);
			}
			finally {
				this.setSynchronizationEvent(poDatabase, undefined, poSource, poTarget);
			}
		});

		return loPromise;
	}

	private setSynchronizationEvent(poDatabase: Database, loISyncronizationEvent: ISyncronizationEvent, poSource: PouchDB.Database<any>, poTarget: PouchDB.Database<any>): void {
		if (StoreHelper.isRemoteDatabase(poSource.name))
			poDatabase.isSynchronizingFromServer = loISyncronizationEvent;
		else if (StoreHelper.isRemoteDatabase(poTarget.name))
			poDatabase.isSynchronizingToServer = loISyncronizationEvent;
	}

	private groupChangeTrackerItemsByLot(paChangeTrackerItems: IChangeTrackerItem[]): Map<number, IChangeTrackerItem[]> {
		// On va récupérer pour chaque document le tracker le plus ancien
		const laUniqChangeTrackerItems: IChangeTrackerItem[] = ArrayHelper.unique(
			paChangeTrackerItems,
			(poChangeTrackerItem: IChangeTrackerItem) => poChangeTrackerItem.id
		);

		return ArrayHelper.groupBy(
			laUniqChangeTrackerItems,
			(poChangeTrackerItem: IChangeTrackerItem) => poChangeTrackerItem.lotId
		);
	}

	private createReplicationResponse(pnDocs: number, pnSeqNumber: number, psStartTime: string, paResponses: IStoreDocument[], pbRunning = false): IStoreReplicationResponse {
		return {
			doc_write_failures: 0,
			docs_read: pnDocs,
			docs_written: pnDocs,
			errors: [],
			last_seq: pnSeqNumber,
			ok: true,
			start_time: psStartTime,
			end_time: new Date().toISOString(),
			status: pbRunning ? undefined : "complete",
			docs: paResponses
		};
	}

	private processBatch(
		poDatabase: Database,
		paBatchItems: IChangeTrackerItem[],
		poSource: PouchDB.Database<{}>,
		poLotsById: Map<number, ILot>,
		pnLotId: number,
		poTarget: PouchDB.Database<{}>
	): PCancelable<IStoreDocument[]> {
		const loPromise = new PCancelable<IStoreDocument[]>(async (pfResolve, pfReject, pfOnCancel) => {
			try {
				const laLotChangeTrackerItemsGroupedById: Map<string, IChangeTrackerItem> = ArrayHelper.groupByUnique(
					paBatchItems,
					(poChangeTrackerItem: IChangeTrackerItem) => poChangeTrackerItem.id
				);
				const laDocsRevsInfo: IDocRevsInfo[] = await this.getDocuments(poSource, laLotChangeTrackerItemsGroupedById, poLotsById.get(pnLotId));
				const loPerformanceManager = new PerformanceManager().markStart();

				if (loPromise.isCanceled)
					return;

				if (ArrayHelper.hasElements(laDocsRevsInfo)) {
					await poTarget.bulkDocs(laDocsRevsInfo, { new_edits: false }); // Le new_edits false fait que le bulk docs ne retourne pas les documents sauvegardés

					console.debug(`${ChangeTrackingReplicatorService.C_LOG_ID}Bulk ${laDocsRevsInfo.length} docs time: ${loPerformanceManager.markEnd().measure()} ms.`);
				}

				if (loPromise.isCanceled)
					return;

				const laIdsToDrop: string[] = [];
				const laDocsToReturn: IStoreDocument[] = [];

				laDocsRevsInfo.forEach((poDocRevInfo: IDocRevsInfo) => {
					laIdsToDrop.push(poDocRevInfo._id);
					laDocsToReturn.push({ _id: poDocRevInfo._id, _rev: poDocRevInfo._rev });
				});

				const loDropPromise: PCancelable<void> = this.isvcChangeTracker.dropTracked(poDatabase.id, pnLotId, laIdsToDrop);

				pfOnCancel(() => loDropPromise.cancel());

				await loDropPromise;

				pfResolve(laDocsToReturn);
			}
			catch (poError) {
				pfReject(poError);
			}
		});

		return loPromise;
	}

	/** Permet de récupérer les révisions, conflits et corps des documents à synchroniser.
	 *
	 * @param poChangeTrackerItemsById
	 */
	private async getDocuments(
		poSource: PouchDB.Database<{}>,
		poChangeTrackerItemsById: Map<string, IChangeTrackerItem>,
		poLot?: ILot
	): Promise<IDocRevsInfo[]> {
		const loPerformanceManager = new PerformanceManager().markStart();
		const laKeys: string[] = MapHelper.keysToArray(poChangeTrackerItemsById);

		const [laConflictedRevsByIds, laDocsIdRev]: [Map<string, string[]>, IDocIdRev[]] = await this.getDocumentsIdRev(laKeys, poSource, poLot);

		const laRevsInfo: IDocRevsInfo[] = await this.getDocumentsBody(laDocsIdRev, poSource, laConflictedRevsByIds, poChangeTrackerItemsById);

		console.debug(`${ChangeTrackingReplicatorService.C_LOG_ID}Get docs revs info time: ${loPerformanceManager.markEnd().measure()} ms.`, laRevsInfo);
		return laRevsInfo;
	}

	private async getDocumentsIdRev(
		paKeys: string[],
		poSource: PouchDB.Database<{}>,
		poLot?: ILot
	): Promise<[Map<string, string[]>, IDocIdRev[]]> {
		const laConflictedRevsByIds = new Map<string, string[]>();
		const laDocs: IDocIdRev[] = [];
		if (paKeys.length > 0) {
			// On récupère les infos des docs (sans include_docs) pour voir si les docs existent et s'ils ont des conflits.
			// On prépare un tableau de id/rev pour pouvoir faire un requête contenant toutes les versions à envoyer.
			(await poSource.changes({ doc_ids: paKeys, style: "all_docs", since: poLot?.since })).results.forEach(poRow => {
				laDocs.push({ id: poRow.id, rev: poRow.changes.shift().rev }); // Il semble que la première rev soit toujours la winning.

				if (ArrayHelper.hasElements(poRow.changes)) { // Si > 0, c'est qu'on a des conflits.
					const laConflictedRevs: string[] = [];
					poRow.changes.forEach((poChange) => {
						laConflictedRevs.push(poChange.rev);
						laDocs.push({ id: poRow.id, rev: poChange.rev });
					});
					laConflictedRevsByIds.set(poRow.id, laConflictedRevs);
				}
			});
		}
		return [laConflictedRevsByIds, laDocs];
	}

	private async getDocumentsBody(
		paDocs: IDocIdRev[],
		poSource: PouchDB.Database<{}>,
		paConflictedRevsByIds: Map<string, string[]>,
		poChangeTrackerItemsById: Map<string, IChangeTrackerItem>
	): Promise<IDocRevsInfo[]> {
		const laRevsInfo: IDocRevsInfo[] = [];

		if (paDocs.length > 0) {
			// On récupère le contenu de toutes les versions (winning et conflits) de tous les documents passés en paramètre.
			(await poSource.bulkGet({
				docs: paDocs,
				revs: true
			})).results.forEach((poResult: IBulkGetResult) => {
				const laConflictRevs: string[] | undefined = paConflictedRevsByIds.get(poResult.id);

				poResult.docs.forEach((poDoc: any) => {
					const loOkDoc: PouchDB.Core.ExistingDocument<unknown> & PouchDB.Core.GetMeta = poDoc.ok;
					if (loOkDoc) {
						if (!laConflictRevs?.includes(loOkDoc._rev)) { // On est dans le cas de la winnig rev, donc on tronque l'historique si besoin
							loOkDoc._conflicts = laConflictRevs;

							const lsRevGuid: string = StoreDocumentHelper.getRevisionGuid(poChangeTrackerItemsById.get(poResult.id)?.rev);
							if (lsRevGuid) {
								const lnRevIndex: number = loOkDoc._revisions.ids.indexOf(lsRevGuid);
								if (lnRevIndex > 0)
									loOkDoc._revisions.ids = loOkDoc._revisions.ids.slice(0, lnRevIndex + 1); // On veut inclure la dernière révision envoyée pour éviter les conflits
							}
						}

						laRevsInfo.push(loOkDoc);
					}
				});
			});
		}
		return laRevsInfo;
	}

	//#endregion

}
