import { EEnvironmentId } from '@osapp/model/application/EEnvironmentId';
import { IStatusBarConfig } from '@osapp/model/statusBar/IStatusBarConfig';
import { IIdlEnvironment } from '../model/IIdlEnvironment';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const APP_ID = "idl";
export const APP_CORE_DATABASE_PREFIX = `${APP_ID}_core_`;

export const environment: IIdlEnvironment = {
	production: false,
	id: EEnvironmentId.dev1,
	description: "DEV1",
	cloud_url: "https://dev-api.desmos-infirmiers.juxta.network",
	coreRoleAppConfig: `${APP_CORE_DATABASE_PREFIX}common_app_configs`,
	statusBar: { backgroundColorByHexString: "#ff9900" },
	GOOGLE_MAPS_BROWSER_API_KEY: "AIzaSyCIyQyPoFRFpBwGKvfqbyYcy8Ut2DZokts",
	GOOGLE_MAPS_IOS_API_KEY: "AIzaSyCIyQyPoFRFpBwGKvfqbyYcy8Ut2DZokts",
	API_KEY: "MDI2Njc2ZTM4YWY0MzU4YzEyZGY4YmVmOTUxODY4YWY",

	cloud_api_suffix: "/api/",
	cloud_dms_suffix: "/dms/idl/documents/",
	cloud_api_data_suffix: `/api/apps/${APP_ID}/data/`,
	cloud_api_apps_suffix: `/api/apps/${APP_ID}/`,

	fsv_url: "https://infirmiers-desmossv.juxta.network",
	fsv_teletransmission_url: "https://infirmiers-desmossv.juxta.network/Pages/Post/FSE-DRE/Fsv_Teletransmission.aspx",

	dms: {},

	appCredentials: {
		login: "idl",
		password: "abozifec"
	},

	credentials: {
		login: "",
		password: ""
	},

	excludeLogins: [
		"invite.idl@idelizy.fr"
	],

	accountBlockedMessage: "Votre compte est bloqué, vous devez réinitialiser votre mot de passe",

	unlockRequired: true,

	onesignalId: "95389791-75b1-44fb-86d0-7c8f27c0fcbd",
	googleProjectNumber: "145175130950"
};

export const statusBar: IStatusBarConfig = { backgroundColorByHexString: "#ff9900" };

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.