import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { InitComponentError } from '@osapp/model/errors/InitComponentError';

export class InitConstantesError extends InitComponentError {

	constructor() {
		super("Un modèle de patient est nécessaire pour la création de constantes.");
		// Nécessaire pour que le 'instanceof InitConstantesError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, InitConstantesError);
	}

}