<!--
	<router-outlet> est utilisé ici pour éviter les animations du `ion-router-outlet` et mettre en place une animation spécifique au composant de tournées.
	Cela permet aussi d'éviter que les changements de date dans la route s'ajoutent à l'historique de navigation ionic.
	-->
<ion-header>
	<!-- TOOLBAR PRINCIPALE : PAGE PRINCIPALE TOURNEES -->
	<osapp-header [title]="isPlanificationMode ? 'Planification' : 'Tournée'" [hasHomeButton]="pageInfo.hasHomeButton"
		[hasBackButton]="pageInfo.hasBackButton" [hasMenuButton]="pageInfo.hasMenuButton"
		[hasSyncButton]="pageInfo.hasSyncButton">
		<!-- Boutons des paramètres et édition/validation de tournée. -->
		<ion-buttons *ngIf="pageInfo.hasSettingButton" slot="end" color="primary" class="primary-toolbar-btns">
			<osapp-selector *ngIf="canChangeDisplayMode" [options]="displayModeSelectOptions"
				[preselectedValues]="displayMode" displayMode="tags"
				(selectionChanged)="onDisplayModeChanged($event); saveDisplayModeSelection($event);" color="primary"
				class="selector-tags"></osapp-selector>
			<!-- Affichage pour les petits écrans. -->
			<osapp-selector *ngIf="canChangeDisplayMode" [options]="displayModeSelectOptions" label="Mode"
				[preselectedValues]="displayMode" (selectionChanged)="onDisplayModeChanged($event)" color="primary"
				class="selector-default">
			</osapp-selector>
		</ion-buttons>
	</osapp-header>

	<!-- TOOLBAR SECONDAIRE : PAGE TOURNEE (précédent, suivant, export, ...) -->
	<ion-toolbar color="toolbar" class="secondary-toolbar size-icon">
		<!-- Boutons tournée précédente et filtrage. -->
		<ng-container *ngTemplateOutlet="leftToolbarButtons"></ng-container>
		<!-- Titre pour la tournée affichée (date de la tournée). -->
		<div class="just-flex flex-row date-wrapper">
			<ion-button (click)="dateSelector.pickDate()" fill="clear" size="small">
				<ion-icon slot="icon-only" name="timetable"></ion-icon>
			</ion-button>
			<ion-button
				*ngIf="!!conflicts &&
				(conflicts.noIntervenants.length > 0 || conflicts.noPlanning.length > 0 || conflicts.multipleIntervenants.length > 0)"
				(click)="onConflictsClicked()" color="danger" shape="round" class="conflicts-btn">
				<ion-icon slot="icon-only" name="alert"></ion-icon>
			</ion-button>
			<osapp-date-time #dateSelector [model]="currentDate" (modelChange)="onDateChanged($event)"
				[params]="datePickerParams" [ngClass]="displayMode === availableDisplayModes.week ? 'hidden' : ''"
				class="ion-no-padding pointer">
			</osapp-date-time>
			<ion-label *ngIf="displayMode === availableDisplayModes.week" class="underline pointer"
				(click)="dateSelector.pickDate()">
				Semaine du {{ beginWeek | dateWithLocale : 'fr-FR' : 'dd MMM yyyy' }} au {{ endWeek | dateWithLocale :
				'fr-FR'
				:
				'dd MMM yyyy' }}
			</ion-label>
		</div>

		<!-- Boutons d'export pdf, de conversation et de tournée suivante. -->
		<ng-container *ngTemplateOutlet="rightToolbarButtons"></ng-container>
	</ion-toolbar>

	<!-- TOOLBAR TERTIAIRE : ZONE DE RECHERCHE -->
	<ion-toolbar color="toolbar" class="search-toolbar size-icon border-filter">

		<ion-item color="toolbar" lines="none" class="ion-no-padding">
			<!-- Recherche. -->
			<div class="search-box txt-center form-list width100pc">
				<ion-searchbar mode="md" #searchBar (keyup.enter)="closeKeyboard(searchBar);" [(ngModel)]="searchValue"
					[debounce]="debounceTimeMs" [placeholder]="searchPlaceholder" inputmode="search" color="light">
				</ion-searchbar>
			</div>
			<!-- Bouton de filtrage. -->
			<ion-button mode="md" color="osapp-element" (click)="swapFilteringArea()" size="small" class="filter-button">
				<ion-icon slot="icon-only" [name]="filteringCounter > 0 ? 'filter-fill' : 'filter'">
				</ion-icon>
				<span *ngIf="filteringCounter > 0" class="badge">{{ filteringCounter }}</span>
			</ion-button>
		</ion-item>

	</ion-toolbar>

	<!-- TOOLBAR QUATERNAIRE : ZONE FILTRAGE -->
	<ion-toolbar [hidden]="!isFilteringAreaOpen" color="osapp-element" class="filter size-icon border-filter">

		<ion-grid>
			<ion-row>
				<!-- Colonne de filtrage sur les secteurs. -->
				<ng-container *ngTemplateOutlet="secteursFilterColumn"></ng-container>
			</ion-row>
			<!-- Ligne pour les filtres intervenants / états de séance / reset. -->
			<ion-row>
				<!-- Colonne de filtrage sur les intervenants et groupes. -->
				<ng-container *ngTemplateOutlet="intervenantsFilterColumn"></ng-container>
			</ion-row>
			<ion-row>
				<!-- Colonne de filtrage sur les états de séances. -->
				<ng-container *ngTemplateOutlet="seanceStatusesFilterColumn"></ng-container>
			</ion-row>
			<ion-row>
				<!-- Colonne d'annulation des filtres. -->
				<ng-container *ngTemplateOutlet="resetFiltersColumn"></ng-container>
			</ion-row>

		</ion-grid>
	</ion-toolbar>

</ion-header>

<ion-content [forceOverscroll]="false" [@routerTransition]="getTransitionState()">
	<router-outlet></router-outlet>
</ion-content>

<!--#region BOUTONS DE LA TOOLBAR SECONDAIRE -->
<!-- Template pour les boutons à gauche de la toolbar : boutons tournée précédente. -->
<ng-template #leftToolbarButtons>
	<ion-buttons slot="start">
		<!-- Bouton tournée précédente. -->
		<ion-button (click)="goToPreviousDay()" size="small">
			<ion-icon slot="icon-only" name="arrow-back"></ion-icon>
		</ion-button>
	</ion-buttons>
</ng-template>

<!-- Template pour les boutons à droite de la toolbar : boutons d'export pdf, de conversation et de tournée suivante. -->
<ng-template #rightToolbarButtons>
	<ion-buttons slot="end">
		<!-- Bouton export pdf. -->
		<ion-button (click)="exportTournee(true)" size="small">
			<ion-icon slot="icon-only" name="file-pdf"></ion-icon>
		</ion-button>
		<!-- Bouton tournée suivante. -->
		<ion-button (click)="goToNextDay()" size="small">
			<ion-icon slot="icon-only" name="arrow-forward"></ion-icon>
		</ion-button>
	</ion-buttons>
</ng-template>
<!--#endregion -->

<!--#region COLONNES DE FILTRAGE -->

<!--#region Filtrage sur les intervenants -->
<!-- Template de la colonne de filtrage sur les intervenants. -->
<ng-template #intervenantsFilterColumn>
	<ion-col class="pad-filter">
		<osapp-selector [options]="intervenantOptions" [preselectedValues]="selectedIntervenantIds" displayMode="tags"
			multiple label="Intervenants" (selectionChanged)="onSelectedIntervenantIdsChanged($event)">
		</osapp-selector>
	</ion-col>
</ng-template>

<!-- Template de la colonne de filtrage sur les secteurs. -->
<ng-template #secteursFilterColumn>
	<ion-col class="pad-filter">
		<osapp-selector [options]="sectorsOptions" [preselectedValues]="selectedSectorIds" displayMode="tags" multiple
			label="Secteurs" (selectionChanged)="onSelectedSectorChanged($event)">
		</osapp-selector>
	</ion-col>
</ng-template>

<!-- Template d'affichage de l'avatar principal par défaut. -->
<ng-template #defaultAvatar>
	<avatar [src]="{ text: 'N A', size: 40 }" class="avatars round-etat">
	</avatar>
</ng-template>

<!--#endregion -->

<!--#region Filtrage sur les statuts de séance. -->
<!-- Template de la colonne de filtrage sur les états de séances. -->
<ng-template #seanceStatusesFilterColumn>
	<ion-col class="pad-filter">
		<osapp-selector [options]="statusOptions" [preselectedValues]="selectedSeanceStatuses" displayMode="tags" multiple
			label="État" (selectionChanged)="onSeanceStatusesChanged($event)"></osapp-selector>
	</ion-col>
</ng-template>

<!--#region Remise à zéro des filtres. -->
<!-- Template de remise à zéro des filtres. -->
<ng-template #resetFiltersColumn>
	<ion-col class="reset-filter">
		<ion-buttons>
			<ion-button (click)="resetFilters()">
				<ion-icon slot="icon-only" name="close">
				</ion-icon>
				<ion-label class="reset-label">Réinitialiser les filtres</ion-label>
			</ion-button>

			<ion-button (click)="swapFilteringArea()" class="validate-btn">
				<ion-icon slot="icon-only" name="checkmark">
				</ion-icon>
				<ion-label class="reset-label">Appliquer les filtres</ion-label>
			</ion-button>
		</ion-buttons>
	</ion-col>
</ng-template>
<!--#endregion -->

<!--#endregion -->