import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Directory } from '@capacitor/filesystem';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { ModalController } from '@ionic/angular';
import { FileHelper } from '@osapp/helpers/fileHelper';
import { FilesystemService } from '@osapp/modules/filesystem/services/filesystem.service';
import { ModalComponentBase } from '@osapp/modules/modal/model/ModalComponentBase';
import { Queuer } from '@osapp/modules/utils/queue/models/queuer';
import { PlatformService } from '@osapp/services/platform.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { Invoice } from '../../models/invoice';

interface IFspItem {
	blob: Blob;
	name: string;
	url: string;
}

interface IDownloadFspParams {
	invoice: Invoice;
	fsp: IFspItem;
}

@Component({
	selector: 'idl-fsp-list-modal',
	templateUrl: './fsp-list-modal.component.html',
	styleUrls: ['./fsp-list-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FspListModalComponent extends ModalComponentBase<boolean> {

	//#region FIELDS

	private readonly moQueuer = new Queuer({ excludePendings: true, thingToQueue: (poParams: IDownloadFspParams) => this.downloadPdfAsync(poParams.invoice, poParams.fsp) });

	//#endregion

	//#region PROPERTIES

	@Input() public fspListByInvoice = new Map<Invoice, IFspItem[]>();

	public get canPreview(): boolean {
		return !this.isvcPlatform.isMobileApp;
	}

	//#endregion

	constructor(
		private readonly isvcFilesystem: FilesystemService,
		private readonly ioFileOpener: FileOpener,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetectorRef);

		this.moQueuer.start().pipe(takeUntil(this.destroyed$), finalize(() => this.moQueuer.end())).subscribe();
	}

	public goToPdf(poFsp: IFspItem): void {
		window.open(poFsp.url, '_blank');
	}

	public onDownloadPdfClicked(poInvoice: Invoice, poFsp: IFspItem): void {
		this.moQueuer.exec({ invoice: poInvoice, fsp: poFsp });
	}

	private async downloadPdfAsync(poInvoice: Invoice, poFsp: IFspItem): Promise<void> {
		const lsInvoicePdfName = `Facture n°${poInvoice.invoiceNumber} - ${poFsp.name}.pdf`;
		if (this.isvcPlatform.isMobileApp) {
			const lsPath = `Download/${lsInvoicePdfName}`;

			const lsUri = await this.isvcFilesystem.createFileAsync(lsPath, poFsp.blob, Directory.ExternalStorage, true);
			await this.ioFileOpener.open(lsUri, "application/pdf");
		}
		else
			FileHelper.downloadBlob(poFsp.blob, lsInvoicePdfName);
	}

}
