import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { PanneauService } from '../../../services/panneau.service';
import { EPathologie } from 'apps/idl/src/model/EPathologies';

@Component({
  selector: 'di-panneau-patient-prise-en-charge',
  templateUrl: './panneau-patient-prise-en-charge.component.html',
  styleUrls: ['./panneau-patient-prise-en-charge.component.scss'],
})
export class PanneauPatientPriseEnChargeComponent implements OnInit {

  @Input()
  public patient: IPatient;

  public model: IPatient;

  public priseEnChargeForm: FormGroup;

  public optionsAld = [{ value: "0", label: "Non" }, { value: "1", label: "Oui" }]
  public optionsPriseEnCharge = [
    { label: "Soins palliatif", value: EPathologie.palliatif },
    { label: "Diabète insulino traité", value: EPathologie.diabetic },
    { label: "Cancer / Immunodépression", value: EPathologie.cancer },
    { label: "Mucoviscidose", value: EPathologie.mucoviscidose },
    { label: "Patient dépendant", value: EPathologie.dependant }
  ]

  constructor(private svcPatients: PatientsService, private svcPanneau: PanneauService, private fb: FormBuilder) { }

  ngOnInit() {
    this.priseEnChargeForm = this.fb.group({
      ald: [this.patient.ald || ""],
      priseEnCharge: [this.patient.pathologies || ""],
    });
    this.model = this.patient;
  }

  public goBack(): void {
    this.svcPanneau.close();
  }

  public handleSubmit(event: Event): void {
    event.preventDefault();
    if (this.priseEnChargeForm.valid) {
      const formValues = this.priseEnChargeForm.value;
      this.model = {
        ...this.model,
        ald: formValues.ald[0],
        pathologies: formValues.priseEnCharge
      }
      this.svcPatients.savePatientAnakin(this.model).subscribe(() => {
        this.svcPatients.saveAdressCacheData(this.model);
        this.svcPanneau.close();
      })
    }
  }


}
