import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StringHelper } from '../../../helpers/stringHelper';
import { PlatformService } from '../../../services/platform.service';
import { IOsappHeaderModalParams } from '../model/IOsappHeaderModalParams';
import { ModalComponentBase } from '../model/ModalComponentBase';

@Component({
	selector: "osapp-header-modal",
	templateUrl: './osapp-header-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OsappHeaderModalComponent extends ModalComponentBase<never> implements IOsappHeaderModalParams, OnInit {

	//#region FIELDS

	/** Couleur par défaut de la toolbar. */
	private static readonly C_DEFAULT_TOOLBAR_COLOR = "toolbar";
	/** Icone de fermeture par défaut. */
	private static readonly C_DEFAULT_CLOSE_ICON = "close";

	//#endregion

	//#region PROPERTIES

	private mbHasCloseButton: boolean = true;
	/** @implements */
	public get hasCloseButton(): boolean { return this.mbHasCloseButton; }
	@Input() public set hasCloseButton(pbHasCloseButton: boolean) {
		if (pbHasCloseButton !== undefined && pbHasCloseButton !== this.mbHasCloseButton) {
			this.mbHasCloseButton = pbHasCloseButton;
			this.detectChanges();
		}
	}

	private msTitle: string;
	/** @implements */
	public get title(): string { return this.msTitle; }
	@Input() public set title(psValue: string) {
		if (psValue !== this.msTitle) {
			this.msTitle = psValue;
			this.detectChanges();
		}
	}

	private msToolbarColor: string;
	/** @implements */
	public get toolbarColor(): string { return this.msToolbarColor; }
	@Input() public set toolbarColor(psValue: string) {
		if (psValue !== this.msToolbarColor && !StringHelper.isBlank(psValue)) {
			this.msToolbarColor = psValue;
			this.detectChanges();
		}
	}

	private msCloseIcon: string;
	/** @implements */
	public get closeIcon(): string { return this.msCloseIcon; }
	@Input() public set closeIcon(psValue: string) {
		if (psValue !== this.msCloseIcon && !StringHelper.isBlank(psValue)) {
			this.msCloseIcon = psValue;
			this.detectChanges();
		}
	}

	private msToolbarButtonsSlot: "start" | "end";
	/** @implements */
	public get toolbarButtonsSlot(): "start" | "end" { return this.msToolbarButtonsSlot; }
	@Input() public set toolbarButtonsSlot(psValue: "start" | "end") {
		if (psValue !== this.msToolbarButtonsSlot && !StringHelper.isBlank(psValue)) {
			this.msToolbarButtonsSlot = psValue;
			this.detectChanges();
		}
	}

	@Input() public customClose?: () => Promise<boolean>;

	//#endregion

	//#region METHODS

	constructor(poModalCtrl: ModalController, psvcPlatform: PlatformService, poChangeDetector: ChangeDetectorRef) {
		super(poModalCtrl, psvcPlatform, poChangeDetector);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		if (StringHelper.isBlank(this.toolbarColor))
			this.toolbarColor = OsappHeaderModalComponent.C_DEFAULT_TOOLBAR_COLOR;

		if (StringHelper.isBlank(this.closeIcon))
			this.closeIcon = OsappHeaderModalComponent.C_DEFAULT_CLOSE_ICON;

		if (StringHelper.isBlank(this.toolbarButtonsSlot))
			this.toolbarButtonsSlot = "start";
	}

	/** @override */
	public close(): Promise<boolean> {
		if (this.customClose)
			return this.customClose();
		else
			return super.close();
	}

	//#endregion
}