import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ArrayHelper } from '@osapp/helpers';
import { EPrefix, IContact } from '@osapp/model';
import { ContactsService } from '@osapp/services';
import { EMPTY, Observable, Subject, Subscription } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { DeviceService } from '../../features/shared/services/device.service';

@Component({
  selector: 'di-contacts',
  templateUrl: './contacts.page.html',
  styleUrls: ['./contacts.page.scss'],
})
export class ContactsPage implements OnInit, OnDestroy {
  public showPageFormContacts: boolean = false;
  public mode: "edit" | "add" = "add";
  public contactSelectionne?: IContact = null;
  public isMobileView: boolean = false;
  public messageSuccessContact$: string = "";
  public contactsList: IContact[] = [];

  private subscriptions: Subscription[] = [];
  private initContacts$: Subject<void> = new Subject<void>();
  private idContactToSelect: string = "";

  constructor(
    private svcContacts: ContactsService,
    private svcDevice: DeviceService,
    private router: Router
  ) { }

  public ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach(sub => sub.unsubscribe());
      this.subscriptions = [];
    }
  }

  ngOnInit() {
    this.initContacts();

    this.subscriptions.push(this.svcDevice.isMobile$.subscribe((flag: boolean) => {
      this.isMobileView = flag;
      if (this.isMobileView && this.showPageFormContacts) {
        this.contactSelectionne ? this.navigateToEditContact() : this.navigateToAddContact();
      }
    }));
  }

  initContacts() {
    const getContactsSub = this.initContacts$.
      pipe(
        mergeMap(() => this.getContacts()),
				tap((listeContacts) => {
					this.contactsList = listeContacts;
					this.initValueErgo();
				})
      ).subscribe(() => {
        if (this.idContactToSelect) {
          this.selectContact(this.idContactToSelect);
        }
      });

    this.initContacts$.next();
    this.subscriptions.push(getContactsSub);
  }

	private initValueErgo() :void{
		this.contactsList.map((contact :IContact) => {
				contact.isDoctor = contact.isDoctor || !!contact.finess;
		});
	}

  handleSelectionContact(idContact: string) {
    this.selectContact(idContact);
    if (this.isMobileView) {
      this.navigateToEditContact();
    } else {
      this.showPageFormContacts = true;
    }
  }

  private selectContact(idContact: string) {
    this.contactSelectionne = this.contactsList.find(x => x._id === idContact);
    this.idContactToSelect = "";
  }

  handleClickAddContactOnMobile() {
    this.navigateToAddContact();
  }


  handleClickAddContact(event: Event) {
    if (this.isMobileView) {
      this.navigateToAddContact();
    } else {
      this.resetSelectionContact();
      this.showPageFormContacts = true;
    }
  }

  handleSearchTerm(recherche: string) { }

  handleDeleteContact(idContactSuppr: string, event: Event) {
    this.resetSelectionContact();
    this.messageSuccessContact$ = "Contact supprimé !";
    this.hideAlertMessage();
  }

  handleSaveContact(idContact: string) {
    if (idContact) {
      this.idContactToSelect = idContact;
      this.messageSuccessContact$ = this.contactSelectionne ? "Enregistrement OK" : "Contact ajouté !";
      this.hideAlertMessage();
    }
  }

  handleCancelContact(idContact: string) {
    this.messageSuccessContact$ = this.contactSelectionne ? "Modifications annulées" : "Contact réinitialisé";
    this.hideAlertMessage();
  }

  private hideAlertMessage() {
    setTimeout(() => {
      this.messageSuccessContact$ = "";
    }, 3000);
  }

  private resetSelectionContact() {
    this.contactSelectionne = null;
    if (this.isMobileView) {
      this.navigateToContacts();
    } else {
      this.showPageFormContacts = false;
    }
  }

  private navigateToAddContact() {
    this.showPageFormContacts = false;
    this.router.navigate(["contacts", "add"]);
  }

  private navigateToEditContact() {
    this.showPageFormContacts = false;
    this.router.navigate(["contacts", "edit", this.contactSelectionne._id]);
  }

  private navigateToContacts() {
    this.showPageFormContacts = false;
    this.router.navigate(["contacts"]);
  }

  private getContacts(): Observable<IContact[]> {
    return this.getContactsObservable()
      .pipe(
        catchError(poError => { console.error("CONTSLCT.C:: Erreur récupération contacts :", poError); return EMPTY; }),
        map((paContacts: IContact[]) => {
          return ArrayHelper.unique(paContacts);
        })
      );
  }

  private getContactsObservable(): Observable<IContact[]> {
    return this.svcContacts.getSiteContactsAnakin([], EPrefix.contact, true, false);
  }
}
