import { INotificationTaskParams } from 'libs/osapp/src/model';
import { Observable, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ContactsService } from '../contacts.service';
import { ConversationService } from '../conversation.service';
import { InjectorService } from '../injector.service';
import { SecurityService } from '../security.service';
import { TaskBase } from './TaskBase';
import { TaskDescriptor } from './TaskDescriptor';

export abstract class NotificationTaskBase<T extends INotificationTaskParams> extends TaskBase<T> {

	//#region FIELDS

	protected msvcConversation: ConversationService;
	protected msvcSecurity: SecurityService;
	protected msvcContact: ContactsService;

	//#endregion

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<T>) {
		super(poDescriptor);
		this.msvcConversation = InjectorService.instance.get(ConversationService);
		this.msvcSecurity = InjectorService.instance.get(SecurityService);
		this.msvcContact = InjectorService.instance.get(ContactsService);
	}

	/** @override
	 * @param poErrors$ Observable qui fournit chaque erreur de la tâche.
	 */
	public retryStrategy(poErrors$: Observable<any>): Observable<any> {
		return poErrors$.pipe(mergeMap(poError => throwError(poError)));
	}

	//#endregion
}