import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Provider, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { Network } from '@ionic-native/network/ngx';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { IonicModule } from '@ionic/angular';
import { ComponentsModule } from './components/components.module';
import { SharedComponentsModule } from './components/sharedComponents.module';
import { DownloadModule } from './modules/download/download.module';
import { FilesystemModule } from './modules/filesystem/filesystem.module';
import { GuidModule } from './modules/guid/guid.module';
import { LoggerModule } from './modules/logger/logger.module';
import { DevicesModule } from './modules/security/devices/devices.module';
import { StoreModule } from './modules/store/store.module';
import { UnzipModule } from './modules/unzip/unzip.module';
import { VersionsModule } from './modules/versions/versions.module';
import { PipeModule } from './pipes/pipes.module';
import { InjectorService, OrchestratorService } from './services';
import { ServiceModule } from './services/services.module';

const importModules: (Type<any> | ModuleWithProviders<any>)[] = [
	IonicModule,
	CommonModule,
	FormsModule,
	ReactiveFormsModule,
	ServiceModule,
	PipeModule,
	ComponentsModule,
	HttpClientModule,
	SuperTabsModule.forRoot(),
	DownloadModule,
	UnzipModule,
	VersionsModule,
	DevicesModule,
	GuidModule,
	StoreModule,
	FilesystemModule
];
const exportModules: Type<any>[] = [
	ComponentsModule,
	ServiceModule,
	PipeModule,
	SharedComponentsModule,
	LoggerModule
];
const providers: Provider[] = [
	Network,
	FileOpener,
	FileTransfer,
	HttpClient
];

@NgModule({
	imports: importModules,
	exports: exportModules,
	providers: [
		providers
	]
})
export class OsappModule {

	constructor(
		psvcOrchestrator: OrchestratorService,
		//A CONSERVER : Nécessaire à l'initialisation du singleton
		psvcInjector: InjectorService
	) {
		psvcOrchestrator.init();
	}

}
