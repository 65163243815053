import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DateHelper } from '@osapp/helpers';
import { ETimetablePattern } from '@osapp/model';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { tap } from 'rxjs/operators';
import { PatientsService } from '../../../../../modules/patients/services/patients.service';

@Component({
  selector: 'di-recherche-patients',
  templateUrl: './recherche-patients.component.html',
  styleUrls: ['./recherche-patients.component.scss'],
})
export class RecherchePatientsComponent implements OnInit {

  public patients: IPatient[];

  @Input()
  public displayCount = false;

	@Input()
  public mobileViewForce? = false;

	@Output()
  public onClickLigne = new EventEmitter<string>();

  public searchedValue: string = "";

  public filteredPatients: IPatient[];

  public patientsCount: number;
  public patientsCountMessage: string = "";

  public noResultMessage: string;

  constructor(
    public router: Router,
		private svcPatients: PatientsService
  ) { }

  ngOnInit() {
		this.svcPatients.getSitePatients().pipe(
			tap((patients: IPatient[]) => {
			  this.patients = patients;
				this.filteredPatients = patients;
				this.getPatientsCount();
			})
		).subscribe();
  }

	onClickPatient(patientId)
	{
		this.onClickLigne.emit(patientId);
	}

  public filterPatients(newValue: string): void {
    this.searchedValue = newValue;

    if(this.searchedValue.length > 2){
      this.filteredPatients = this.patients.filter(patient =>
        patient.lastName.toLowerCase().includes(this.searchedValue.toLowerCase()) ||
        patient.firstName.toLowerCase().includes(this.searchedValue.toLowerCase()) ||
				(patient.socialNumber?.startsWith(this.searchedValue)) ||
				(DateHelper.transform(patient.birthDate,ETimetablePattern.dd_MM_yyyy_slash) === this.searchedValue) ||
        (patient.lastName + ' ' + patient.firstName).toLowerCase().includes(this.searchedValue.toLowerCase())
      );
    }else{
      this.filteredPatients = this.patients;
    }
    this.getPatientsCount();
  }

  public navigateToCreatePatient(): void {
    this.router.navigate(["/patients/add"]);
  }

  public getPatientsCount(): void {
    this.patientsCount = this.filteredPatients.length;
    this.patientsCountMessage = `${this.patientsCount > 0 ? this.patientsCount : "Aucun"} patient${this.patientsCount > 1 ? "s" : ""}  
    ${this.searchedValue.length > 0 ? (this.patientsCount > 1 ? "trouvés" : "trouvé") : ""}`
    if(this.searchedValue.length > 0){
      this.noResultMessage = "Aucun patient trouvé"
    }else{
      this.noResultMessage = "Aucun patient"
    }
  }

}
