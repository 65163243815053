import { IStoreDocument } from '../model';
import { StringHelper } from "./stringHelper";

export abstract class FormHelper {

	//#region METHODS

	/** Renvoie un booléen qui indique si la valeur peut être remplie ou pas.
	 * @param pbOneTimeFilled Booléen qui indique si la valeur ne doit être remplie qu'une fois.
	 * @param poModel Modele a remplir.
	 * @param poValue Valeur a remplir.
	 */
	public static canBeFilled(pbOneTimeFilled: boolean, poModel: IStoreDocument, poValue: any): boolean {
		if (!pbOneTimeFilled)
			return true;
		else if (typeof poValue === "string")
			return StringHelper.isBlank(poValue);
		else
			return !poModel._rev || !poValue;
	}

	//#endregion

}