import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { NumberHelper } from '@osapp/helpers/numberHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { isEqual } from 'lodash';
import { IIndemnite } from '../modules/traitement/model/IIndemnite';
import { Indemnite } from '../modules/traitement/model/Indemnite';
import { Acte } from './Acte';
import { EMajorationType } from './EMajorationType';
import { EPlace } from './EPlace';
import { EStatusSeance } from './EStatusSeance';
import { IActe } from './IActe';
import { IMajoration } from './IMajoration';
import { ISeance } from './ISeance';
import { Majoration } from './Majoration';

export class Seance implements ISeance {

	//#region PROPERTIES

	/** Durée par défaut d'une séance. */
	public static readonly C_DEFAULT_DURATION = 15;

	/** @implements */
	public scheduled?: boolean;
	/** @implements */
	public patientId: string;
	/** @implements */
	private meStatus: EStatusSeance;
	public get status(): EStatusSeance {
		return this.actes?.every((poActe: Acte) => poActe.canceled) ? EStatusSeance.canceled : this.meStatus;
	}
	/** @implements */
	public intervenantIds?: string[];
	/** @implements */
	public actes: Array<Acte>;
	/** @implements */
	public startDate: Date;
	/** @implements */
	public get endDate(): Date {
		return DateHelper.addMinutes(this.startDate, this.duration);
	};
	/** @implements */
	public readonly id: number;
	/** @implements */
	public majorations: Array<Majoration>;
	/** @implements */
	public get price(): number {
		return NumberHelper.reduceNumbers(this.actes.map((poActe: Acte) => poActe.price), 0);
	}
	private mePlace: EPlace;
	/** @implements */
	public get place(): EPlace { return this.actes.some((poActe: Acte) => poActe.place === EPlace.center) ? EPlace.center : this.mePlace ?? EPlace.home; }
	public set place(peValue: EPlace) { this.mePlace = peValue; }
	/** @implements */
	public duration: number;
	private maIndemnites: Indemnite[];
	/** @implements */
	public get indemnites(): Indemnite[] {
		return this.place === EPlace.center ? [] : this.maIndemnites;
	}
	public set indemnites(paIndemnites: Indemnite[]) {
		this.maIndemnites = paIndemnites;
	}
	/** @implements */
	public planningApplied?: boolean;
	/** @implements */
	public isBillingLocked?: boolean;
	/** @implements */
	public billingLockedReason?: string;
	/** @implements */
	public traitementId: string;
	/** @implements */
	public traitementRev: string;
	/** @implements */
	public statusChangeDate?: Date;
	/** Indique si la séance est validée. */
	public get isPending(): boolean {
		return !this.status || this.status === EStatusSeance.pending;
	}
	/** Indique si la séance est en retard. */
	public get isLate(): boolean {
		return (DateHelper.compareTwoDates(new Date(), this.endDate) >= 0);
	}
	/** Indique si la séance est facturable */
	public get isBillable(): boolean {
		return this.status === EStatusSeance.done || this.status === EStatusSeance.inProgress;
	}
	/** Indique si la séance est complétée (facturée). */
	public get isCompleted(): boolean {
		return this.status === EStatusSeance.completed;
	}
	/** Indique si la séance est annulée. */
	public get isCanceled(): boolean {
		return this.status === EStatusSeance.canceled;
	}
	/** Indique si la séance est en cours de facturation. */
	public get isInProgress(): boolean {
		return this.status === EStatusSeance.inProgress;
	}
	/** Indique si le circuit de facturation à commencé ou est fini pour la séance. */
	public get isBilled(): boolean {
		return this.isInProgress || this.isCompleted;
	}
	/** Indique si la séance est validée. */
	public get validated(): boolean {
		return this.status === EStatusSeance.done;
	}
	/** Indique si la séance est considérée comme protected.
	 */
	public get isProtected(): boolean {
		return this.status === EStatusSeance.canceled ||
			this.status === EStatusSeance.completed ||
			this.status === EStatusSeance.inProgress ||
			this.status === EStatusSeance.done;
	}

	public get statusLabel(): string {
		if (this.validated)
			return "validée";
		if (this.isInProgress)
			return "facturée partiellement";
		else if (this.isCompleted)
			return "facturée";
		else if (this.isCanceled)
			return "annulée";
		else
			return undefined;
	}

	public get label(): string {
		return `Séance du ${DateHelper.transform(this.startDate, ETimetablePattern.dd_MM_yyyy_HH_mm_slash)}`;
	}

	public shadow: boolean;
	public isLast: boolean;

	//#endregion

	//#region METHODS

	constructor(paActes: Array<Acte>, pnId: number, pdStartDate: Date) {
		ObjectHelper.initInstanceOf(this, Seance);

		this.actes = paActes;
		this.startDate = pdStartDate;
		this.duration = Seance.C_DEFAULT_DURATION;
		this.id = pnId;
		this.majorations = [];
		this.indemnites = [];
	}

	/** Crée une instance de séance à partir des données d'un objet similaire récupérées en base de données.
	 * @param poData Données de l'objet récupérées en bases de données dont il faut créer une instance de `Seance`.
	 */
	public static createFromData(poData: ISeance): Seance {
		const laActes: Acte[] = ArrayHelper.hasElements(poData.actes) ?
			poData.actes.map((poActe: IActe) => new Acte({ ...poActe, guid: poActe.guid ?? poActe._id })) : []; // Pour rétrocompat
		const loSeance: Seance = new Seance([], poData.id, new Date(poData.startDate));
		const laIndemnites: Indemnite[] = [];

		//! On filtre les indemnités non valides pour prévenir un bug non reproduit :
		//! https://dev.azure.com/calaosoft/osapp-project/_git/osapp/pullrequest/1546?discussionId=17990
		if (ArrayHelper.hasElements(poData.indemnites))
			laIndemnites.push(...poData.indemnites.filter((poItem: IIndemnite) => !!poItem).map((poItem: IIndemnite) => Indemnite.getInstanceFromData(poItem)));

		delete poData.price; // Gestion de la propriété get only;
		ObjectHelper.assign(loSeance, poData);

		if (loSeance.startDate)
			loSeance.startDate = new Date(loSeance.startDate);

		loSeance.actes = laActes;
		loSeance.majorations = poData.majorations ? poData.majorations.map((poMajoration: IMajoration) => Majoration.getInstanceFromData(poMajoration)) : [];
		loSeance.indemnites = laIndemnites;

		return loSeance;
	}

	/** Retourne `true` si la séance est planifiée, `false` sinon. */
	public isPlanned(): boolean {
		return this.status !== undefined && this.status !== null && this.status !== EStatusSeance.pending;
	}

	public getDeplacementDetail(pnDistance: number = 1): string {
		const laIndemnitesDetail: string[] = [];

		this.indemnites.forEach((poIndemnite: Indemnite) => {
			if (!poIndemnite.disabled) {
				const loIndemniteDetail: string = poIndemnite.isIKType ? (`${pnDistance}IK`) : poIndemnite.type;
				laIndemnitesDetail.push(loIndemniteDetail);
			}
		});

		return laIndemnitesDetail.join(", ");
	}

	public equals(poOtherSeance: Seance): boolean {
		return isEqual(this, poOtherSeance);
	}

	public hasMajoration(peMajorationType: EMajorationType): boolean {
		return this.majorations?.some((poMajoration: Majoration) => poMajoration.type === peMajorationType && !poMajoration.disabled);
	}

	public wasProtected(pdDate: Date): boolean {
		return this.isProtected && DateHelper.compareTwoDates(this.statusChangeDate, pdDate) < 0;
	}

	public setStatus(peStatus: EStatusSeance, pdUpdateDate: Date = new Date): void {
		this.meStatus = peStatus;
		this.statusChangeDate = pdUpdateDate;
	}

	//#endregion
}