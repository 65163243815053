import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { DateHelper } from "@osapp/helpers";
import { tap } from "rxjs/operators";
import { EStatusSeance } from "../../../../../model/EStatusSeance";
import { IActe } from "../../../../../model/IActe";
import { ITraitement } from "../../../../../model/ITraitement";
import { Traitement } from "../../../../../model/Traitement";
import { IPatient } from "../../../../../modules/patients/model/IPatient";
import { PatientsService } from "../../../../../modules/patients/services/patients.service";
import { StoredSeance } from "../../../../models/StoredSeance";
import { EEtatFacturationSeance } from "../../../shared/enums/EEtatFacturationSeance";
import { DrawerPopoverService } from "../../../shared/services/drawer-popover.service";
import { SeanceService } from "../../../shared/services/seance.service";
import { MenuOrdonnanceComponent } from "../menu-ordonnance/menu-ordonnance.component";
import { ETraitementState } from "apps/idl/src/model/ETraitementState";
import { ETimetablePattern } from "@osapp/model";

// export interface IActeOrdonnance {
// 	acteCoef: string;
// 	acteLabel: string;
// 	acteInfoSecondaire: string;
// 	actePrice: string;
// }

@Component({
	selector: "di-carte-ordonnance",
	templateUrl: "./carte-ordonnance.component.html",
	styleUrls: ["./carte-ordonnance.component.scss"],
})
export class CarteOrdonnanceComponent implements OnInit, OnChanges {
	constructor(
		private svcPatient: PatientsService,
		private svcDrawerPopover: DrawerPopoverService,
		private svcSeance: SeanceService
	) { }


	@Input() ordonnance?: ITraitement;
	public ordonnanceObj?: Traitement;
	public seancesList?: StoredSeance[];
	public patient: IPatient;
	public patientId: string;
	public showPopover: boolean;
	@Input() cardTitle = "";
	public actes: IActe[] = [];
	@Input() currentSeance: number;
	@Input() totalSeance: number;
	@Input() dateFacturation: Date;
	@Input() isNew = false;
	@Input() inCabinet = false;
	@Input() status: "active" | "finished" = "active";
	@Input() finishDate = "";
	@Input() modeFacturation = false;
	@Input() aFacturer: number = 0;
	@Input() etatFacturation: EEtatFacturationSeance = EEtatFacturationSeance.Default;
	@Input() onFacturerClick: (idOrdonnance: string) => void;

	@ViewChild('menuOrdonnance', { static: false }) menuOrdonnance!: ElementRef<HTMLDivElement>;

	ngOnInit() {
		this.initializeActes();
		this.extractAndFetchPatient();
		this.initializeOrdonnance();
		this.fetchSeances();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['dateFacturation']) {
			if (this.modeFacturation)
				this.etatFacturation = this.initSeanceFacturation();
		}
	}

	private initSeanceFacturation(): EEtatFacturationSeance {
		if (!this.seancesList) return EEtatFacturationSeance.None;
		if (this.ordonnance?.state === ETraitementState.termine) {
			this.status = "finished";
			return EEtatFacturationSeance.All;
		}

		const dateFacturationFinJournee = DateHelper.fillDay(this.dateFacturation ?? new Date());
		const seanceRangeDate = this.seancesList.filter((seance: StoredSeance) => {
			return DateHelper.isBetweenTwoDates(seance.startDate, DateHelper.resetDay(this.ordonnance.beginDate), dateFacturationFinJournee);
		});

		const seancesDone = seanceRangeDate.filter(seance => seance.status === EStatusSeance.done);
		const seancesNotDone = seanceRangeDate.filter(seance => seance.status === EStatusSeance.to_be_done || seance.status === EStatusSeance.canceled);


		if (seancesDone.length - seancesNotDone.length === 0) {
			return EEtatFacturationSeance.None;
		}
		if (seancesDone.length - seancesNotDone.length != 0) {
			this.aFacturer = seanceRangeDate.filter(seance => seance.status != EStatusSeance.completed).length;
			return EEtatFacturationSeance.Some;
		}
		return EEtatFacturationSeance.All;
	}

	private initDateFinTraitement() {
		if (this.ordonnance?.endDate && this.totalSeance > 0) {
			this.finishDate = DateHelper.transform(this.ordonnance?.endDate, ETimetablePattern.dd_MM_yyyy_slash);
		}
	}


	private initializeActes(): void {
		if (this.ordonnance?.actes) {
			this.actes = this.ordonnance.actes;
		}
	}

	private extractAndFetchPatient(): void {
		this.patientId = Traitement.extractPatientId(this.ordonnance._id);
		this.svcPatient.getPatient(this.patientId).pipe(
			tap((patient: IPatient) => this.patient = patient)
		).subscribe();
	}

	private initializeOrdonnance(): void {
		this.ordonnanceObj = Traitement.createFromData(this.ordonnance);
	}

	private fetchSeances(): void {
		this.svcSeance.selectSeancesByTraitement(this.ordonnanceObj).pipe(
			tap((seances: StoredSeance[]) => {
				this.seancesList = seances;
				this.currentSeance = seances.filter(seance => seance.status === EStatusSeance.done || seance.status === EStatusSeance.completed).length;
				this.totalSeance = seances.length;
				if (this.modeFacturation)
					this.etatFacturation = this.initSeanceFacturation();
				this.initDateFinTraitement();
			})
		).subscribe();
	}


	handleFacturerClick() {
		if (this.ordonnance?.state !== ETraitementState.termine && this.aFacturer > 0 && this.onFacturerClick) {
			this.onFacturerClick(this.ordonnance._id);
		}
	}

	getSoinNumber() {
		const remaining = Number(this.totalSeance) - Number(this.currentSeance);
		if (this.currentSeance === 0) {
			return "1er soin";
		}
		if (remaining === 1) {
			return "Dernier soin";
		}
		if (remaining > 1 && remaining <= 7) {
			return `J-${remaining}`;
		}
		return "";
	}

	getProgressColor() {
		if (Number(this.currentSeance) === 0 && Number(this.totalSeance) === 0) {
			return "Gris"
		}

		if (Number(this.currentSeance) > Number(this.totalSeance) / 2) {
			return "Raspberry";
		} else {
			return "CouleurPrimaire";
		}
	}

	public getAdressePatient(pat: IPatient): string {
		const addressParts: string[] = [];
		if (pat.street) {
			addressParts.push(pat.street);
		}
		if (pat.zipCode) {
			addressParts.push(pat.zipCode);
		}
		if (pat.city) {
			addressParts.push(pat.city);
		}

		return addressParts.join(' ');
	}

	public openMenu(): void {
		this.showPopover = true;
		this.svcDrawerPopover.open("", "30%", this.menuOrdonnance?.nativeElement, MenuOrdonnanceComponent, { ordonnance: this.ordonnance, anchorElement: this.menuOrdonnance }, () => this.showPopover = false)
	}

	public closeMenu(): void {
		this.showPopover = false;
		this.svcDrawerPopover.close();
	}
}
