<div class="const-space marg-btm infos-list">
	<ng-container *ngTemplateOutlet="readOnly ? view : edit"></ng-container>
</div>

<!-- TEMPLATE MODE VISU -->
<ng-template #view>
	<div lines="none" class="separator">
		<ion-label class="label-title">Non implémenté</ion-label>
		<ion-label class="label-value"></ion-label>
	</div>
</ng-template>

<!-- TEMPLATE MODE EDIT -->
<ng-template #edit>

	<div class="margin-edit">
		<!-- Grille qui contient une colonne pour le lable, une pour un objet constantes précédent, une pour l'objet constantes actuel. -->
		<ion-grid>
			<!-- Ligne des en-têtes. -->
			<ion-row class="entete-const">
				<ion-col [size]="C_LABEL_COL_SIZE">{{ injectionsModel ? "Soins" : "Constantes" }}</ion-col>
				<ion-col [size]="C_PREVIOUS_VALUE_COL_SIZE" class="ion-no-padding">
					<ion-item *ngIf="previousItem" lines="none" class="select-const">
						<!-- Bouton précédent. -->
						<ion-button [disabled]="previousItemIndex === 0" fill="clear" slot="start"
							(click)="onPreviousItemIndexChanged(previousItemIndex - 1)">
							<ion-icon name="caret-back-outline"></ion-icon>
						</ion-button>
						<p class="txt-center previous-date">Précédentes
							{{ previousItem.createdDate | dateWithLocale:"fr-FR": C_CONSTANTE_DATE_FORMAT }}
						</p>
						<!-- Bouton suivant. -->
						<ion-button [disabled]="previousItemIndex === (previousConstantesLength - 1)" fill="clear" slot="end"
							(click)="onPreviousItemIndexChanged(previousItemIndex + 1)">
							<ion-icon name="caret-forward-outline"></ion-icon>
						</ion-button>
					</ion-item>
					<ion-spinner *ngIf="isLoading" color="primary"></ion-spinner>
				</ion-col>
				<ion-col *ngIf="constantesModel; else injectionsDate" [size]="C_CURRENT_VALUE_COL_SIZE"
					class="txt-center pointer" (click)="constantesDatePicker.pickDate()">
					<osapp-date-time #constantesDatePicker class="hidden" [params]="dateTimeParams"
						(modelChange)="onDateChanged($event)" [model]="constantesModel.createdDate">
					</osapp-date-time>
					<ion-label class="txt-center ion-text-wrap">
						{{ constantesModel.createdDate | dateWithLocale:"fr-FR":
						C_CONSTANTE_DATE_FORMAT }}
					</ion-label>
				</ion-col>
				<ng-template #injectionsDate>
					<ion-col *ngIf="injectionsModel" [size]="C_CURRENT_VALUE_COL_SIZE" class="txt-center pointer"
						(click)="injectionsDatePicker.pickDate()">
						<osapp-date-time #injectionsDatePicker class="hidden" [params]="dateTimeParams"
							(modelChange)="onDateChanged($event)" [model]="injectionsModel.createdDate">
						</osapp-date-time>
						<ion-label class="txt-center ion-text-wrap">
							{{ injectionsModel.createdDate | dateWithLocale:"fr-FR":
							C_CONSTANTE_DATE_FORMAT }}
						</ion-label>
					</ion-col>
				</ng-template>
			</ion-row>

			<ng-container *ngIf="constantesModel; else injections">
				<!-- Ligne température. -->
				<ion-row>
					<ion-col [size]="C_LABEL_COL_SIZE" matTooltip="Température">T° (°C)</ion-col>
					<ion-col [size]="C_PREVIOUS_VALUE_COL_SIZE" class="txt-center">
						<ion-label *ngIf="previousItem">{{ previousItem.temperature }}</ion-label>
					</ion-col>
					<ion-col [size]="C_CURRENT_VALUE_COL_SIZE">
						<mat-form-field appearance="outline">
							<input [(ngModel)]="constantesModel.temperature" (ngModelChange)="onModelChanged()" matInput type="number"
								min="25" max="50" step="0.1">
						</mat-form-field>
					</ion-col>
				</ion-row>

				<!-- Ligne fréquence cardiaque. -->
				<ion-row>
					<ion-col [size]="C_LABEL_COL_SIZE" matTooltip="Fréquence cardiaque">Pouls (bpm)</ion-col>
					<ion-col [size]="C_PREVIOUS_VALUE_COL_SIZE" class="txt-center">
						<ion-label *ngIf="previousItem">{{ previousItem.heartRate }}</ion-label>
					</ion-col>
					<ion-col [size]="C_CURRENT_VALUE_COL_SIZE">
						<mat-form-field appearance="outline">
							<input [(ngModel)]="constantesModel.heartRate" (ngModelChange)="onModelChanged()" matInput type="number"
								min="1" max="400">
						</mat-form-field>
					</ion-col>
				</ion-row>

				<!-- Section saturation en oxygène du sang. -->
				<!-- Ligne saturation en oxygène du sang. -->
				<ion-row>
					<ion-col [size]="C_LABEL_COL_SIZE" matTooltip="Saturation en oxygène">SpO2 (%)</ion-col>
					<ion-col [size]="C_PREVIOUS_VALUE_COL_SIZE" class="txt-center">
						<ion-label *ngIf="previousItem">{{ previousItem.oxygenSaturation?.saturation }}</ion-label>
					</ion-col>
					<ion-col [size]="C_CURRENT_VALUE_COL_SIZE">
						<mat-form-field appearance="outline">
							<input *ngIf="constantesModel.oxygenSaturation" [(ngModel)]="constantesModel.oxygenSaturation.saturation"
								(ngModelChange)="onModelChanged()" matInput type="number" min="1" max="100">
						</mat-form-field>
					</ion-col>
				</ion-row>
				<!-- Ligne quantité d'oxygène inspiré. -->
				<ion-row class="more-detail"
					*ngIf="constantesModel.oxygenSaturation?.saturation || previousItem?.oxygenSaturation?.saturation">
					<ion-col [size]="C_LABEL_COL_SIZE" matTooltip="Quantité d'oxygène inspiré">Débit O2 (L)</ion-col>
					<ion-col [size]="C_PREVIOUS_VALUE_COL_SIZE" class="txt-center">
						<ion-label *ngIf="previousItem">{{ previousItem.oxygenSaturation?.oxygenQuantity ?
							previousItem.oxygenSaturation?.oxygenQuantity : 'AA' }}</ion-label>
					</ion-col>
					<ion-col [size]="C_CURRENT_VALUE_COL_SIZE">
						<mat-form-field appearance="outline">
							<input [(ngModel)]="constantesModel.oxygenSaturation.oxygenQuantity" (ngModelChange)="onModelChanged()"
								matInput type="number" placeholder="AA">
						</mat-form-field>
					</ion-col>
				</ion-row>

				<!-- Ligne pression artérielle. -->
				<ion-row>
					<ion-col [size]="C_LABEL_COL_SIZE" matTooltip="Pression artérielle">TA (mmHg)</ion-col>
					<ion-col [size]="C_PREVIOUS_VALUE_COL_SIZE" class="txt-center">
						<ion-label *ngIf="previousItem">{{ previousItem.bloodPressure }}</ion-label>
					</ion-col>
					<ion-col [size]="C_CURRENT_VALUE_COL_SIZE">
						<mat-form-field appearance="outline">
							<input [(ngModel)]="constantesModel.bloodPressure" (ngModelChange)="onModelChanged()" matInput>
						</mat-form-field>
					</ion-col>
				</ion-row>

				<!-- Ligne fréquence respiratoire. -->
				<ion-row>
					<ion-col [size]="C_LABEL_COL_SIZE" matTooltip="Fréquence respiratoire"> FR (cpm)</ion-col>
					<ion-col [size]="C_PREVIOUS_VALUE_COL_SIZE" class="txt-center">
						<ion-label *ngIf="previousItem">{{ previousItem.respiratoryRate }}</ion-label>
					</ion-col>
					<ion-col [size]="C_CURRENT_VALUE_COL_SIZE">
						<mat-form-field appearance="outline">
							<input [(ngModel)]="constantesModel.respiratoryRate" (ngModelChange)="onModelChanged()" matInput
								type="number" min="1" max="100">
						</mat-form-field>
					</ion-col>
				</ion-row>

				<!-- Ligne glycémie. -->
				<ion-row>
					<ion-col [size]="C_LABEL_COL_SIZE" matTooltip="Glycémie">DEXTRO (g/L)</ion-col>
					<ion-col [size]="C_PREVIOUS_VALUE_COL_SIZE" class="txt-center">
						<ion-label *ngIf="previousItem">{{ previousItem.bloodSugar }}</ion-label>
					</ion-col>
					<ion-col [size]="C_CURRENT_VALUE_COL_SIZE">
						<mat-form-field appearance="outline">
							<input [(ngModel)]="constantesModel.bloodSugar" (ngModelChange)="onModelChanged()" matInput type="number"
								min="0" max="10" step="0.1">
						</mat-form-field>
					</ion-col>
				</ion-row>

				<!-- Ligne diurèse. -->
				<ion-row>
					<ion-col [size]="C_LABEL_COL_SIZE">Diurèse (mL/24h)</ion-col>
					<ion-col [size]="C_PREVIOUS_VALUE_COL_SIZE" class="txt-center">
						<ion-label *ngIf="previousItem">{{ previousItem.diuresis }}</ion-label>
					</ion-col>
					<ion-col [size]="C_CURRENT_VALUE_COL_SIZE">
						<mat-form-field appearance="outline">
							<input [(ngModel)]="constantesModel.diuresis" (ngModelChange)="onModelChanged()" matInput type="number"
								min="0" max="10000">
						</mat-form-field>
					</ion-col>
				</ion-row>

				<!-- Ligne EVA. -->
				<ion-row>
					<ion-col [size]="C_LABEL_COL_SIZE" matTooltip="Échelle visuelle analogique">EVA (de 0 à 10)</ion-col>
					<ion-col [size]="C_PREVIOUS_VALUE_COL_SIZE" class="txt-center">
						<ion-label *ngIf="previousItem">{{ previousItem.painScale }}</ion-label>
					</ion-col>
					<ion-col [size]="C_CURRENT_VALUE_COL_SIZE">
						<mat-form-field appearance="outline">
							<input [(ngModel)]="constantesModel.painScale" (ngModelChange)="onModelChanged()" matInput type="number"
								min="0" max="10" step="1">
						</mat-form-field>
					</ion-col>
				</ion-row>

				<!-- Ligne masse. -->
				<ion-row>
					<ion-col [size]="C_LABEL_COL_SIZE">Poids (kg)</ion-col>
					<ion-col [size]="C_PREVIOUS_VALUE_COL_SIZE" class="txt-center">
						<ion-label *ngIf="previousItem">{{ previousItem.weight }}</ion-label>
					</ion-col>
					<ion-col [size]="C_CURRENT_VALUE_COL_SIZE">
						<mat-form-field appearance="outline">
							<input [(ngModel)]="constantesModel.weight" (ngModelChange)="onModelChanged()" matInput type="number"
								min="1" max="500" step="0.1">
						</mat-form-field>
					</ion-col>
				</ion-row>

				<!-- Ligne custom. -->
				<ng-container>
					<ion-row *ngFor="let other of constantesModel.others">
						<ion-col [size]="C_LABEL_COL_SIZE">
							<mat-form-field appearance="outline">
								<input [(ngModel)]="other.label" placeholder="Autre" (ngModelChange)="onModelChanged()" matInput>
							</mat-form-field>
						</ion-col>
						<ion-col [size]="C_PREVIOUS_VALUE_COL_SIZE" class="txt-center">
							<ion-label *ngIf="getPreviousOtherConstanteValue(other.label) as previousValue">{{ previousValue }}
							</ion-label>
						</ion-col>
						<ion-col [size]="C_CURRENT_VALUE_COL_SIZE">
							<mat-form-field appearance="outline">
								<input [(ngModel)]="other.value" (ngModelChange)="onModelChanged()" matInput>
							</mat-form-field>
						</ion-col>
					</ion-row>
				</ng-container>
			</ng-container>
		</ion-grid>
	</div>
</ng-template>

<ng-template #injections>
	<!-- Lignes injections. -->
	<ion-row *ngFor="let injection of injectionsModel?.injections">
		<ion-col [size]="C_LABEL_COL_SIZE">
			<mat-form-field appearance="outline">
				<input [(ngModel)]="injection.label" placeholder="Injection,..." (ngModelChange)="onModelChanged()" matInput>
			</mat-form-field>
		</ion-col>
		<ion-col [size]="C_PREVIOUS_VALUE_COL_SIZE" class="txt-center">
			<ion-label *ngIf="getPreviousInjectionQuantity(injection.label) as previousQuantity">{{ previousQuantity }}
			</ion-label>
		</ion-col>
		<ion-col [size]="C_CURRENT_VALUE_COL_SIZE">
			<mat-form-field appearance="outline">
				<input [(ngModel)]="injection.quantity" (ngModelChange)="onModelChanged()" matInput>
			</mat-form-field>
		</ion-col>
	</ion-row>
</ng-template>