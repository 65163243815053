import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { LightingUpAngularModule } from 'lighting-up-angular';
import { SidePageDesmosComponent } from './side-page-desmos.component';


@NgModule({
  declarations: [SidePageDesmosComponent],
  imports: [CommonModule,MatIconModule,LightingUpAngularModule],
  exports: [SidePageDesmosComponent]
})
export class SidePageDesmosModule {}
