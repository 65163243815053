import { ArrayHelper } from "@osapp/helpers/arrayHelper";
import { EPrefix } from "@osapp/model";
import { IEntityGuard, IGuardResult } from "@osapp/model/entities/IEntityGuard";
import { IEntityLink } from "@osapp/model/entities/IEntityLink";
import { IStoreDocument } from "@osapp/model/store/IStoreDocument";
import { Observable, of } from "rxjs";

export class PatientEntityGuard implements IEntityGuard {

	/** Une entité peut être supprimée si elle n'est liée à aucune autre entité. */
	public isDeletable(_: IStoreDocument, paLinks: IEntityLink[], paLinkedDocs: IStoreDocument[]): Observable<IGuardResult> {
		if (ArrayHelper.hasElements(paLinkedDocs) || ArrayHelper.hasElements(paLinks.filter((poEntityLink: IEntityLink) => !poEntityLink._id.includes(EPrefix.group)))) {
			const pnLength: number = paLinks.length + paLinkedDocs.length;
			return of({ result: false, message: `Ce patient est lié à ${pnLength} élément${pnLength > 1 ? "s" : ""}.` });
		}
		else
			return of({ result: true, message: undefined });
	}

}