import { Component, Input, OnInit } from '@angular/core';
import { IConvention } from '../../../../../modules/patients/model/iconvention';

@Component({
  selector: 'di-convention',
  templateUrl: './convention.component.html',
  styleUrls: ['./convention.component.scss'],
})
export class ConventionComponent implements OnInit {

	@Input() convention : IConvention;

  constructor(
  ) { }

  ngOnInit() { }

	public getLabelTP(valueTp : boolean) :string{
		return valueTp ? "TP" : "HTP";
	}

	public getInfoConvention(conv: IConvention): string {
		const values = [
			conv.typeConvention,
			conv.idtConvention,
			conv.critereSecondaire
		];
		return values.filter(value => value).join(' - ');
	}

}
