import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { AMCP } from '../../../model/amc-p';
import { AMOP } from '../../../model/amo-p';
import { ICouvertureP } from '../../../model/icouverture-p';
import { IPatient } from '../../../model/IPatient';
import { PatientsService } from '../../../services/patients.service';

@Component({
	selector: 'idl-couverture-status',
	templateUrl: './couverture-status.component.html',
	styleUrls: ['./couverture-status.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouvertureStatusComponent extends ComponentBase {

	//#region PROPERTIES

	public icon: string;
	public color: string;

	@Input() public set couvertures(paCouvertures: ICouvertureP[]) {
		if (paCouvertures) {
			const lbIsActive: boolean = ArrayHelper.getFirstElement(paCouvertures as Array<AMOP | AMCP>)?.isActive;
			this.icon = lbIsActive ? "checkmark" : "alert";
			this.color = lbIsActive ? "success" : "warn";
			this.detectChanges();
		}
	}
	@Input() public label: string;
	@Input() public patient: IPatient;

	//#endregion

	//#region PROPERTIES

	constructor(
		private readonly isvcPatients: PatientsService
	) {
		super();
	}

	public routeToCouvertures(): void {
		if (this.patient)
			this.isvcPatients.goToPatient(this.patient, "viewPatientSlideCouvertures");
	}

	//#endregion

}
