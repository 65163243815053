import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'di-bouton-panneau',
  templateUrl: './bouton-panneau.component.html',
  styleUrls: ['./bouton-panneau.component.scss'],
})
export class BoutonPanneauComponent implements OnInit, OnChanges {
  
  @Input()
  public iconName?: string;

  @Input()
  public content?: string;

  @Input()
  public placeHolder = "";

  @Input()
  public dotted = false;

  @Input()
  public large = false;

  @Input()
  public hasContent = false;

  constructor(
    private el: ElementRef
  ) { }

  ngOnInit() {
    this.setBoutonColor();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.content){
      this.setBoutonColor();
    }
  }

  public setBoutonColor() {
    if (!this.content) {
      this.el.nativeElement.style.setProperty('--my-color', `var(--Gris2)`);
    }else{
      this.el.nativeElement.style.setProperty('--my-color', `var(--CouleurPrimaire)`);
    }
  }

  public getClasses() {
    return {
      'bouton-panneau-component__container--dotted': this.dotted,
      'bouton-panneau-component__container--large': this.large
    }
  }

}
