import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { IPatient } from '../patients/model/IPatient';

@Injectable({
	providedIn: 'root'
})
export class PatientResolver implements Resolve<IPatient> {

	//#region METHODS

	constructor(private ioRouter: Router) { }

	public resolve(poRoute: ActivatedRouteSnapshot, poState: RouterStateSnapshot): IPatient {
		return this.ioRouter.getCurrentNavigation()?.extras?.state?.model;
	}

	//#endregion
}