import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService } from '@osapp/services';
import { AuthenticatorService } from '../../services/authenticator.service';
import { DrawerPopoverService } from '../../services/drawer-popover.service';

@Component({
  selector: 'di-menu-compte-utilisateur',
  templateUrl: './menu-compte-utilisateur.component.html',
  styleUrls: ['./menu-compte-utilisateur.component.scss'],
})
export class MenuCompteUtilisateurComponent implements OnInit {

  constructor(
    private svcSecurity: SecurityService,
    private router: Router,
    private svcAuthenticator: AuthenticatorService,
		private svcDrawerPopover : DrawerPopoverService
  ) { }

  ngOnInit() { }

  public disconnect(): void {
		this.svcDrawerPopover.close();
    this.svcAuthenticator.setAuthenticationStatus(false);
    this.svcSecurity.disconnect().subscribe();
  }

  public redirectAppInfo(): void {
		this.svcDrawerPopover.close();
    this.router.navigate(['app-info'])
  }

  public redirectMyAccount(): void {
		this.svcDrawerPopover.close();
    this.router.navigate(['mon-compte'])
  }
}
