import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { ITraitement } from '../model/ITraitement';

@Pipe({ name: 'traitementDates' })
export class TraitementDatesPipe implements PipeTransform {

	//#region METHODS

	constructor(private ioDatePipe: DatePipe) { }

	/** Transforme la valeur reçue pour l'afficher.
	 * @param poValue Valeur à transformer/vérifier.
	 */
	public transform(poTraitement: ITraitement, pePattern: ETimetablePattern = ETimetablePattern.dd_MM_yyyy_slash): string {
		return `du ${this.ioDatePipe.transform(poTraitement.beginDate, pePattern)} au ${this.ioDatePipe.transform(poTraitement.endDate, pePattern)}`;
	}

	//#endregion
}