import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { ComponentBase } from '../../helpers/ComponentBase';
import { IPopoverItemParams } from '../../model/popover/IPopoverItemParams';
import { PlatformService } from '../../services';
import { ApplicationService } from '../../services/application.service';
import { PageManagerService } from '../../services/pageManager.service';
import { PopoverService } from '../../services/popover.service';
import { IHeaderParams } from './model/IHeaderParams';

/** Template de header standard. Ce header est composé du bouton back, du bouton menu, et d'un titre.
 * Ce template peut être utilisé grâce à la directive `HeaderDirective` ou bien avec le selecteur `osapp-header`.
 * Il est possible d'ajouter du contenu à la balise qui sera affiché dans le header ionic.
 */
@Component({
	selector: "osapp-header",
	templateUrl: './header.component.html',
	styleUrls: ["./header-template.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent extends ComponentBase implements IHeaderParams, OnDestroy {

	//#region FIELDS

	/** Abonnement du bouton natif 'back'. */
	private readonly moBackButtonSubscription: Subscription;

	//#endregion FIELDS

	//#region PROPERTIES

	public readonly homeRouteUrl: string = `/${ApplicationService.C_HOME_ROUTE_URL}`;

	private msTitle = "";
	/** @override */
	public get title(): string { return this.msTitle; }
	@Input() public set title(psNewValue: string) {
		if (this.msTitle !== psNewValue) {
			this.msTitle = psNewValue;
			this.detectChanges();
		}
	}

	private mbHasHomeButton = true;
	/** @override */
	public get hasHomeButton(): boolean { return this.mbHasHomeButton; }
	@Input() public set hasHomeButton(pbNewValue: boolean) {
		if (this.mbHasHomeButton !== pbNewValue) {
			this.mbHasHomeButton = pbNewValue;
			this.detectChanges();
		}
	}

	private mbHasBackButton = true;
	/** @override */
	public get hasBackButton(): boolean { return this.mbHasBackButton; }
	@Input() public set hasBackButton(pbNewValue: boolean) {
		if (this.mbHasBackButton !== pbNewValue) {
			this.mbHasBackButton = pbNewValue;
			this.detectChanges();
		}
	}

	private mbHasSyncButton = false;
	/** @override */
	public get hasSyncButton(): boolean { return this.mbHasSyncButton; }
	@Input() public set hasSyncButton(pbNewValue: boolean) {
		if (this.mbHasSyncButton !== pbNewValue) {
			this.mbHasSyncButton = pbNewValue;
			this.detectChanges();
		}
	}

	private msTitleColor: string;
	/** @override */
	public get titleColor(): string { return this.msTitleColor; }
	@Input() public set titleColor(psNewValue: string) {
		if (this.msTitleColor !== psNewValue) {
			this.msTitleColor = psNewValue;
			this.detectChanges();
		}
	}

	private mbHasMenuButton = true;
	/** @override */
	public get hasMenuButton(): boolean { return this.mbHasMenuButton; }
	@Input() public set hasMenuButton(pbNewValue: boolean) {
		if (this.mbHasMenuButton !== pbNewValue) {
			this.mbHasMenuButton = pbNewValue;
			this.detectChanges();
		}
	}

	private mfCustomBackButtonAction: () => void;
	/** @override */
	public get customBackButtonAction(): () => void { return this.mfCustomBackButtonAction; }
	@Input() public set customBackButtonAction(pfCustomAction: () => void) {
		this.mfCustomBackButtonAction = pfCustomAction;
		this.detectChanges();
	}

	private maPopoverItems: IPopoverItemParams[];
	/** @override */
	public get popoverItems(): IPopoverItemParams[] { return this.maPopoverItems; }
	@Input() public set popoverItems(paValues: IPopoverItemParams[]) {
		this.maPopoverItems = paValues;
		this.mbHasPopoverItems = ArrayHelper.hasElements(this.maPopoverItems);
		this.detectChanges();
	}

	public get canGoBack(): boolean { return this.ioRouter.url !== ApplicationService.C_HOME_ROUTE_URL; }

	private mbHasPopoverItems: boolean;
	/** Indique si des éléments sont présents pour le menu contextuel. */
	public get hasPopoverItems(): boolean { return this.mbHasPopoverItems; }

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcPageManager: PageManagerService,
		private readonly ioRouter: Router,
		private readonly isvcPopover: PopoverService,
		poChangeDetectorRef: ChangeDetectorRef,
		psvcPlatform: PlatformService
	) {
		super(poChangeDetectorRef);
		this.moBackButtonSubscription = psvcPlatform.getBackButtonSubscription(() => this.goBack());
	}

	public goHome(): void {
		this.isvcPageManager.goHome(this.homeRouteUrl);
	}

	public goBack(): void {
		if (this.customBackButtonAction)
			this.customBackButtonAction();
		else
			this.isvcPageManager.goBack();
	}

	/** Ouvre le menu contextuel de la page de prélèvement.
	 * @param poEvent Événement de la souris lors du clic.
	 */
	public openOptions(poEvent: MouseEvent): void {
		this.isvcPopover.showPopover(this.maPopoverItems, poEvent)
			.pipe(takeUntil(this.destroyed$))
			.subscribe();
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moBackButtonSubscription.unsubscribe();
	}

	//#endregion

}