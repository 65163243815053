import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { DateHelper } from '../../helpers/dateHelper';
import { GuidHelper } from '../../helpers/guidHelper';
import { IdHelper } from '../../helpers/idHelper';
import { UserData } from '../../model/application/UserData';
import { EPrefix } from '../../model/EPrefix';
import { EDatabaseRole } from '../../model/store/EDatabaseRole';
import { IDataSource } from '../../model/store/IDataSource';
import { Store } from '../../services/store.service';
import { EUTCAccuracy } from '../date/model/eutcaccuracy.enum';
import { IObservation } from './models/iobservation';
import { Observation } from './models/observation';

@Injectable()
export class ObservationsService {

	//#region METHODS

	constructor(
		private readonly isvcStore: Store
	) { }

	/** Créé un objet Observation.
	 * @param psEntityId Id de l'entité.
	 * @param pdDate Date de la séance.
	 * @param psDescription Description de l'observation.
	 * @param psContactId Auteur de l'observation.
	 */
	public static createObservation(psEntityId: string, pdDate: Date, psDescription: string, psContactId: string): Observation {
		return {
			_id: `${EPrefix.observation}${psEntityId}_${DateHelper.toUTCString(pdDate, EUTCAccuracy.seconds)}_${GuidHelper.newGuid()}`,
			description: psDescription,
			lastUpdateContactId: psContactId,
			lastUpdateDate: new Date()
		};
	}

	/** Sauvegarde une observation.
	 * @param poObservation 
	 */
	public saveObservation(poObservation: Observation): Observable<Observation> {
		const loObservation: Observation = poObservation instanceof Observation ? poObservation : this.toClass(poObservation);
		return this.isvcStore.put(loObservation).pipe(mapTo(loObservation));
	}

	/** Récupère l'observation d'une entité.
	 * @param psEntityId Id de l'entité.
	 * @param pdDate Date de l'entité.
	 */
	public getEntityObservation(psEntityId: string, pdDate: Date): Observable<Observation> {
		const lsStartKey = IdHelper.buildChildId(EPrefix.observation, psEntityId, DateHelper.toUTCString(pdDate, EUTCAccuracy.seconds));
		const loIdsDataSource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: lsStartKey,
				endkey: `${lsStartKey}${Store.C_ANYTHING_CODE_ASCII}`,
				include_docs: true
			},
		};
		return this.isvcStore.getOne<Observation>(loIdsDataSource, false)
			.pipe(
				map((poObservation: Observation) => this.toClass(poObservation))
			);
	}

	/** Récupère les observations du site courant.
	 * @param psContactPrefix 
	 * @param psObservedEntityPrefix
	 */
	public getSiteObservations(psContactPrefix: EPrefix, psObservedEntityPrefix: EPrefix): Observable<Observation[]> {
		const lsStartKey: string = IdHelper.buildChildId(EPrefix.observation,
			IdHelper.buildChildId(psObservedEntityPrefix, IdHelper.buildVirtualNode([UserData.currentSite?._id ?? "", psContactPrefix]), ""),
			""
		);
		const loIdsDataSource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: lsStartKey,
				endkey: `${lsStartKey}${Store.C_ANYTHING_CODE_ASCII}`,
				include_docs: true
			},
		};
		return this.isvcStore.get<Observation>(loIdsDataSource)
			.pipe(map((paObservations: Observation[]) => paObservations.map((poObservation: Observation) => this.toClass(poObservation))));
	}

	protected toClass(poInterState: IObservation): Observation {
		return plainToClass(Observation, poInterState);
	}

	//#endregion

}
