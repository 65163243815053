import { ObjectHelper } from "@osapp/helpers/objectHelper";
import { EType } from "@osapp/model/EType";

export enum EWeekRepetitionType {
	everyWeek = "everyWeek",
	everyNWeek = "everyNWeek"
}

export namespace EWeekRepetitionType {
	/** Récupère les clés de l'enum sous forme d'un tableau. */
	export function keys(): Array<string> {

		// Récupération des clés de l'enum.
		const laKeys: Array<string> = Object.keys(EWeekRepetitionType);
		// -2 pour éviter les valeurs 'values' et 'keys' (inutile).
		const lnLength: number = laKeys.length - 2;
		// Clés et valeurs inversées dans une enum de number donc on prend la 2°moitié du tableau si on est dans une enum de number.
		const lnStartIndex: number = ObjectHelper.checkType(EWeekRepetitionType[laKeys[0]], EType.number) ? lnLength * 0.5 : 0;
		return laKeys.slice(lnStartIndex, lnLength);
	}

	/** Récupère les valeurs associées aux clés de l'enum et les retourne sous forme d'un tableau. */
	export function values(): Array<string> {

		// Récupération des valeurs de l'enum.
		const laValues: Array<string> = Object.keys(EWeekRepetitionType).map((psKey: string) => EWeekRepetitionType[psKey]);
		// -2 pour éviter les valeurs 'values' et 'keys' (inutile).
		const lnLength: number = laValues.length - 2;
		// Clés et valeurs inversées dans une enum de number donc on prend la 2°moitié du tableau si on est dans une enum de number.
		const lnStartIndex: number = ObjectHelper.checkType(laValues[0], EType.number) ? lnLength * 0.5 : 0;
		return laValues.slice(lnStartIndex, lnLength);
	}
}