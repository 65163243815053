import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LightingUpAngularModule } from 'lighting-up-angular';
import { EnteteDesktopComponent } from './entete-desktop.component';
import { MenuCompteUtilisateurModule } from '../menu-compte-utilisateur/menu-compte-utilisateur.module';

@NgModule({
	declarations: [EnteteDesktopComponent],
	imports: [CommonModule, LightingUpAngularModule, MenuCompteUtilisateurModule],
	exports: [EnteteDesktopComponent]
})
export class EnteteDesktopModule { }
