<lua-papier class="bouton-surface" [ngClass]="mode" [mode]="mode" [fullWidth]="fullWidth" [selectionnable]="selectionnable" [selected]="selected" (click)="handleClick()">
	<div>
		<lua-icone *ngIf="iconName" [iconName]="iconName" [color]="getColorIcon()"></lua-icone>
		<lua-icone *ngIf="!iconName" [customIconName]="customIconName" [isIcon]="true" [color]="getColorIcon()" [width]="'60px'" [height]="'60px'"></lua-icone>
	</div>
	<div class="text-container">
		<div class="title">{{title}}</div>
		<div class="subtitle">{{subtitle}}</div>
	</div>
	<lua-icone class="hover-icon" [color]="getColorIcon()" [iconName]='"east"'></lua-icone>
</lua-papier>