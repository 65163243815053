import { IStoreDocument } from '../store/IStoreDocument';
import { EAuthenticationAction } from './EAuthenticationAction';

export interface IAuthenticatorParams extends IStoreDocument {

	/** Login de l'utilisateur. */
	login?: string;
	/** Mot de passe de l'utilisateur. */
	password?: string;
	/** Est-ce que le bouton "Oublié ?" pour le mot de passe doit s'afficher. */
	passwordForgottenEnabled?: boolean;
	/** Css pour le fond du composant. */
	backgroundStyles?: string;
	/** Permet de savoir si on a la possiblité de changer le login ou non. */
	loginEditEnabled?: boolean;
	/** Permet de savoir si on peut créer un compte ou non. */
	newAccountEnabled?: boolean;
	/** Permet de savoir le type d'authentification demandé. */
	authenticationAction?: EAuthenticationAction;
	/** Indique si l'image doit apparaître ou non. */
	imgEnabled?: boolean;
	/** Chemin de l'image. */
	thumbnailPicture?: string;
	/** Chemin de l'icône. */
	thumbnailIcon?: string;
	/** Indique si le code pin est activé. */
	pinEnabled?: boolean;
	/** Indique si on doit afficher la sélection du site. */
	selectSite?: boolean;
	/** Indique si on doit afficher le lien vers le formulaire de demande de démo */
	demoEnabled?: boolean;
	/** Indique si on doit afficher le lien vers le formulaire de demande d'informations */
	infosEnabled?: boolean;
	/** Initialise le contact utilisateur après authentification. */
	initUserContactAfterAuth?: boolean;
	/** Chemin de l'adresse où router après authentification. */
	destinationPathAfterAuth?: string
}
