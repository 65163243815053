import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { VirtualScrollComponent } from './components/virtual-scroll/virtual-scroll.component';
import { AutoCloseSlideItemDirective } from './directives/auto-close-slide-item.directive';

@NgModule({
	declarations: [AutoCloseSlideItemDirective, VirtualScrollComponent],
	exports: [AutoCloseSlideItemDirective, VirtualScrollComponent],
	imports: [
		CommonModule,
		ScrollingModule,
		IonicModule
	]
})
export class VirtualScrollModule { }
