import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { DynamicPageComponent } from '@osapp/components/dynamicPage/dynamicPage.component';
import { AvatarHelper, ObjectHelper, StringHelper } from '@osapp/helpers';
import { ComponentBase } from '@osapp/helpers/ComponentBase';
import { UserData } from '@osapp/model/application/UserData';
import { EBarElementDock } from '@osapp/model/barElement/EBarElementDock';
import { EBarElementPosition } from '@osapp/model/barElement/EBarElementPosition';
import { IBarElement } from '@osapp/model/barElement/IBarElement';
import { IContact } from '@osapp/model/contacts/IContact';
import { IGalleryFile } from '@osapp/model/gallery/IGalleryFile';
import { EAvatarSize } from '@osapp/model/picture/EAvatarSize';
import { IAvatar } from '@osapp/model/picture/IAvatar';
import { ContactsService } from '@osapp/services/contacts.service';
import { GalleryService } from '@osapp/services/gallery.service';
import { takeUntil, tap } from 'rxjs/operators';
import { IDirectivesListParams } from '../../../model/IDirectivesListParams';
import { IPatient } from '../../../model/IPatient';
import { PatientsService } from '../../../services/patients.service';

@Component({
	selector: 'idl-directives',
	templateUrl: './directives.component.html',
	styleUrls: ['./directives.component.scss'],
})
export class DirectivesComponent extends ComponentBase implements OnInit, OnDestroy {

	//#region FIELDS

	private msLastUpdateUserId: string;

	//#endregion

	//#region PROPERTIES

	@Input() public params: IDirectivesListParams;
	public readOnly: boolean = true;

	public patient: IPatient;
	public author: IContact;
	public authorAvatar: IAvatar;
	public descriptionModel: string;

	//#endregion

	//#region METHODS

	constructor(
		private isvcContacts: ContactsService,
		private isvcPatients: PatientsService,
		private isvcGallery: GalleryService,
		@Optional() private moParentComponent: DynamicPageComponent<ComponentBase>,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);
	}

	public ngOnInit(): void {
		this.patient = this.params.model;
		if (ObjectHelper.isNullOrEmpty(this.patient.directives)) {
			this.patient.directives = {
				createUserId: ContactsService.getContactIdFromUserId(UserData.current?._id),
				createDate: new Date(),
				documents: []
			};
		};
		this.refreshAuthor();
		this.updateToolbar();
	}

	public ngOnDestroy(): void {
		if (this.moParentComponent)
			this.moParentComponent.toolbar.clear(this.msInstanceId);
	}

	/** Récupère les informations de l'autheur des dernières modifications si besoin. */
	private refreshAuthor(): void {
		if (!StringHelper.isBlank(this.patient.directives.updateUserId) && this.patient.directives.updateUserId !== this.msLastUpdateUserId) {
			this.isvcContacts.getContact(this.patient.directives.updateUserId)
				.pipe(
					tap((poContact: IContact) => {
						this.author = poContact;
						this.authorAvatar = AvatarHelper.createAvatarFromContact(this.author, EAvatarSize.big, true);
						this.detectChanges();
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		};
	}

	/** Permet de passer en mode édition. */
	private editDirectives(): void {
		this.readOnly = false;
		this.descriptionModel = this.patient.directives.description;
		this.updateToolbar();
	}

	/** Sauvegarde les dernières directives si besoin. */
	private saveDirectives(): void {
		if (this.descriptionModel !== this.patient.directives.description) {
			this.patient.directives.updateUserId = ContactsService.getContactIdFromUserId(UserData.current?._id);
			this.patient.directives.updateDate = new Date();
			this.patient.directives.description = this.descriptionModel;
			this.savePatient(this.patient);
			this.refreshAuthor();
		};
		this.readOnly = true;
		this.updateToolbar();
		this.detectChanges();
	}

	/** Gestion des changements au niveaux de la galerie des documents.
 * @param paDocuments
 */
	public onDucumentsModelChanged(paDocuments: IGalleryFile[]): void {
		this.patient.directives.documents = paDocuments;
		this.savePatient(this.patient);
		this.isvcGallery.saveFiles(paDocuments)
			.pipe(
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Sauveagarde un patient.
	 * @param poPatient Le patient à sauveagarder.
	 */
	private savePatient(poPatient: IPatient): void {
		this.isvcPatients.savePatient(poPatient)
			.pipe(
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Met à jour le bouton flottant. */
	private updateToolbar(): void {
		if (this.moParentComponent)
			this.moParentComponent.toolbar.clear(this.msInstanceId);
		// Si on n'a pas ajouté la toolbar, on peut l'initialiser.
		if (!this.moParentComponent?.toolbar.eltContainer.some((poBarElement: IBarElement) => poBarElement.componentId === this.msInstanceId)) {
			this.moParentComponent?.toolbar.init(
				[
					this.readOnly ?
						{
							id: "circle",
							component: "fabButton",
							dock: EBarElementDock.bottom,
							position: EBarElementPosition.right,
							icon: "create",
							onTap: () => this.editDirectives(),
							name:"Editer"
						} :
						{
							id: "circle",
							component: "fabButton",
							dock: EBarElementDock.bottom,
							position: EBarElementPosition.right,
							icon: "checkmark-outline",
							background: "#1BC14D",
							onTap: () => this.saveDirectives(),
							name:"Sauvegarder"
						}
				] as IBarElement[],
				this.msInstanceId
			);

			this.detectChanges();
		}
	}

	//#endregion

}
