import { DateHelper } from '@osapp/helpers/dateHelper';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { ChildEntityGuard } from '@osapp/model/entities/ChildEntityGuard';
import { Entity } from '@osapp/model/entities/Entity';
import { EAvatarSize } from '@osapp/model/picture/EAvatarSize';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { EntityBuilder } from '@osapp/services/EntityBuilder';
import { C_CONSTANTES_PREFIX, C_PREFIX_PATIENT } from '../../../../app/app.constants';
import { IConstantes } from './IConstantes';

export class ConstantesEntityBuilder extends EntityBuilder<IConstantes>{
	constructor() {
		super(
			(psModelId: string) => RegExp(C_CONSTANTES_PREFIX).test(psModelId),
			(poConstantes: IConstantes) => new Entity(
				poConstantes,
				`constantes/${poConstantes._id}`,
				() => `Constantes du ${DateHelper.transform(poConstantes.createdDate, ETimetablePattern.EEE_dd_MMMM_yyyyy_HH_mm)}`
			),
			_ => "Constantes",
			EDatabaseRole.workspace,
			C_CONSTANTES_PREFIX,
			() => ({
				size: EAvatarSize.medium,
				icon: "analytics-outline"
			}),
			undefined,
			undefined,
			undefined,
			new ChildEntityGuard("Ce suivi de constantes est lié", C_PREFIX_PATIENT)
		);
	}
}