import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { INavigationItem } from '../../../core/models/navigation/INavigationItem';
import { NavigationItems } from '../../../shared/navigation-items.constants';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private navigationItemsSubject = new BehaviorSubject<INavigationItem[]>(NavigationItems);
  navigationItems$ = this.navigationItemsSubject.asObservable();

  constructor() {}

  public setBadgeCount(label: string, count: number) {
    const items = this.navigationItemsSubject.getValue();
    const itemIndex = items.findIndex(item => item.label === label);

    if (itemIndex !== -1) {
      items[itemIndex].badgeCount = count;
      this.navigationItemsSubject.next([...items]);
    }
  }
}