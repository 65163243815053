<ion-list *ngIf="traitements?.length > 0; else loading" class="traitements-list">
	<ion-item-sliding *ngFor="let traitement of traitements" (click)="onTraitementClicked(traitement)">
		<ion-item class="ion-no-padding">
			<ion-grid class="cursor treatment-item">
				<div class="pad-left-trait just-flex flex-col traitement">
					<!-- Nombre de séances prévues et résumé des actes. -->
					<ion-label class="flex">
						<span *ngIf="traitement.nbSeances; else noSeance">
							{{traitement.nbSeances}} {{traitement.nbSeances <= 1 ? 'séance' : 'séances' }} </span>
								<ng-template #noSeance>
									<ion-badge class="warn-badge">!</ion-badge>
									<ion-note class="warn-message">Aucun acte enregistré (traitement vide)</ion-note>
								</ng-template>
								<ng-container *ngIf="isvcTraitement.getResumeActes(traitement); let resumeActes">({{resumeActes}})
								</ng-container>
					</ion-label>
					<ion-note>{{traitement | traitementDates}}</ion-note>
				</div>

				<!-- Icones status. -->
				<div class="treatment-icons-area">
					<idl-traitements-list-marker [traitement]="traitement"
						[ordonnances]="ordonnancesByTraitementId.get(traitement._id)">
					</idl-traitements-list-marker>
				</div>
			</ion-grid>

			<!-- Bouton d'options. -->
			<ion-button calaoSlidingItemOptionsToggle class="ion-no-margin" fill="clear" slot="end">
				<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
			</ion-button>

		</ion-item>
		<!-- Options des items. -->
		<ion-item-options side="end">
			<ion-button calaoSlidingItemOptionsToggle class="swipe-btn" color="danger"
				(click)="onRemoveTraitementClicked(traitement, $event)">
				<ion-icon name="trash"></ion-icon>
				Supprimer
			</ion-button>
			<ion-button calaoSlidingItemOptionsToggle *ngIf="isAdmin" class="swipe-btn" color="primary"
				(click)="onDuplicateTraitementClicked(traitement, $event)">
				<ion-icon name="duplicate"></ion-icon>
				Dupliquer
			</ion-button>
		</ion-item-options>
	</ion-item-sliding>
</ion-list>

<ng-template #loading>
	<div class="empty-list-message">Aucun traitement.</div>
</ng-template>