import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { DatabaseSynchroModule } from '../database-synchro/database-synchro.module';
import { HeaderComponent } from './header.component';
import { HeaderDirective } from './header.directive';

@NgModule({
	declarations: [HeaderComponent, HeaderDirective],
	exports: [HeaderDirective, HeaderComponent],
	imports: [CommonModule, IonicModule, RouterModule, DatabaseSynchroModule]
})
export class HeaderModule { }