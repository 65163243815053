<ion-menu type="overlay" persistent="true" contentId="content">
	<ion-header *ngIf="menuTitle">
		<ion-toolbar class="toolbar-menu">
			<ion-buttons slot="start">
				<ion-button>
					<ion-icon name="desmos-infirmiers-logo"></ion-icon>
				</ion-button>
			</ion-buttons>
			<ion-title>{{menuTitle}}</ion-title>
		</ion-toolbar>
	</ion-header>
	<ion-content [forceOverscroll]="false">
		<ion-list [ngClass]="menuTitle ? 'side-menu-with-header' : 'side-menu-without-header'">
			<calao-menu [menuKey]="menuKey"></calao-menu>
		</ion-list>
		<calao-menu [menuKey]="menuKeyCutomUser"></calao-menu>
	</ion-content>
</ion-menu>

<ion-router-outlet id="content" main> </ion-router-outlet>

<calao-gdpr-popup></calao-gdpr-popup>