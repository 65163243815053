<osapp-header-modal [hasCloseButton]="true" title="Ordonnance"></osapp-header-modal>

<ion-content>
	<ion-grid fixed>

		<ion-row class="input-row">
			<ion-col size="12" class="label">
				<div class="flex-center margin">
					<div (click)="toggleRenouvellement(); $event.stopPropagation()">
						<ion-checkbox class="disable-clicks" [checked]="ordonnance.renouvellement"></ion-checkbox>
					</div>
					<ion-label>Renouvellement
					</ion-label>
				</div>
			</ion-col>

			<ng-container *ngIf="ordonnance.renouvellement">
				<ion-col size="auto" class="label">
					<ion-label>Date de renouvellement : </ion-label>
				</ion-col>
				<ion-col size="auto">
					<osapp-date-time [model]="ordonnance.renouvellement.date" [params]="dateParams"
						(modelChange)="onRenouvellementDateChanged($event)">
					</osapp-date-time>
				</ion-col>
				<ion-col size="auto" class="label">
					<ion-label>N° de renouvellement : </ion-label>
				</ion-col>
				<ion-col size="auto">
					<ion-input [(ngModel)]="ordonnance.renouvellement.index" type="number">
					</ion-input>
				</ion-col>
			</ng-container>
		</ion-row>

		<ion-row class="input-row">
			<ion-col size="auto" class="label">
				<ion-label>Date de prescription : </ion-label>
			</ion-col>
			<ion-col size="auto">
				<osapp-date-time [model]="ordonnance.prescriptionDate" [params]="dateParamsForPrescriptionDate"
					(modelChange)="onPrescriptionDateChanged($event)">
				</osapp-date-time>
			</ion-col>
		</ion-row>

		<ion-row class="input-row">
			<ion-col size="auto" class="label">
				<ion-label>Prescripteur : </ion-label>
			</ion-col>
			<ion-col size="auto">
				<contacts-list [params]="contactsListParamsForPrescriptor" [model]="ordonnance.prescripteurContactId"
					(modelChange)="onPrescriptorModelChanged($event)">
				</contacts-list>
			</ion-col>
		</ion-row>

		<ion-row class="input-row">
			<ion-col size="auto" class="label">
				<ion-label>Origine : </ion-label>
			</ion-col>
			<ion-col size="auto">
				<div class="selector">
					<osapp-selector [options]="originesOptions" [preselectedValues]="ordonnance.originePrescription"
						(selectionChanged)="onOriginSelectionChanged($event)" scrollWrapper="false">
					</osapp-selector>
				</div>
			</ion-col>
		</ion-row>

		<ion-row class="input-row">
			<ion-col size="auto" class="label">
				<ion-label>Soins conforme au protocole d'affection de longue durée (si ALD)
				</ion-label>
				<ion-radio-group class="flex-center" [(ngModel)]="ordonnance.isAld">
					<ion-item lines="none">
						<ion-radio mode="md" slot="start" [value]="true"></ion-radio>
						<ion-label>Oui</ion-label>
					</ion-item>

					<ion-item lines="none">
						<ion-radio mode="md" slot="start" [value]="false"></ion-radio>
						<ion-label>Non</ion-label>
					</ion-item>
				</ion-radio-group>
			</ion-col>
		</ion-row>

		<ion-row class="input-row">
			<ion-col size="12" class="label">
				<div [ngClass]="ordonnance.adcDate ? 'adc-date' : ''">
					<ion-label>Accident de droit commun (ADC)</ion-label>
					<ion-radio-group #adcGroup class="flex-center" (ionChange)="toggleADC(adcGroup)" [(ngModel)]="hasADC">
						<ion-item lines="none">
							<ion-radio mode="md" slot="start" [value]="true"></ion-radio>
							<ion-label>Oui</ion-label>
						</ion-item>

						<ion-item lines="none">
							<ion-radio mode="md" slot="start" [value]="false"></ion-radio>
							<ion-label>Non</ion-label>
						</ion-item>
					</ion-radio-group>
				</div>
			</ion-col>

			<ng-container *ngIf="ordonnance.adcDate">
				<ion-col size="auto" class="label">
					<ion-label>Date : </ion-label>
				</ion-col>
				<ion-col size="auto">
					<osapp-date-time [model]="ordonnance.adcDate" [params]="dateParams" (modelChange)="onADCDateChanged($event)">
					</osapp-date-time>
				</ion-col>
			</ng-container>
		</ion-row>

		<ion-row class="input-row">
			<ion-col size="12" class="label">
				<div class="flex-space-bt">
					<ion-label>Photos : </ion-label>
					<ion-button (click)="toggleDocDisplayMode()" size="small" class="btn-small">
						<ion-icon slot="icon-only" [name]="docDisplayMode !== 'list' ? 'list' : 'grid'"></ion-icon>
					</ion-button>
				</div>
			</ion-col>
			<ion-col>
				<gallery *ngIf="documents" [files]="documents" [readOnly]="false" [maxSizeKb]="250"
					[patientId]="traitement.patientId" (onFilesChanged)="onDocumentsModelChanged($event)" accept="image"
					[displayMode]="docDisplayMode">
				</gallery>
			</ion-col>
		</ion-row>

	</ion-grid>
</ion-content>

<ion-fab vertical="bottom" horizontal="end" slot="fixed">
	<ion-fab-button color="success" (click)="close()" title="Valider">
		<ion-icon name="checkmark"></ion-icon>
	</ion-fab-button>
</ion-fab>