import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LightingUpAngularModule } from 'lighting-up-angular';
import { MenuCompteUtilisateurComponent } from './menu-compte-utilisateur.component';

@NgModule({
	declarations: [MenuCompteUtilisateurComponent],
	imports: [CommonModule, LightingUpAngularModule],
	exports: [MenuCompteUtilisateurComponent]
})
export class MenuCompteUtilisateurModule { }
