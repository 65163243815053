import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EPrefix, IContact } from '@osapp/model';
import { ContactsService } from '@osapp/services';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { map } from 'rxjs/operators';
import { PanneauService } from '../../../services/panneau.service';

@Component({
  selector: 'di-panneau-choix-medecin-traitant',
  templateUrl: './panneau-choix-medecin-traitant.component.html',
  styleUrls: ['./panneau-choix-medecin-traitant.component.scss'],
})
export class PanneauChoixMedecinTraitantComponent implements OnInit {

  @Input()
  public patient: IPatient;

  public model: IPatient;

  public contacts: IContact[];

  constructor(
    private svcContacts: ContactsService,
    private svcPanneau: PanneauService,
    private svcPatients: PatientsService,
    private router: Router) { }

  ngOnInit() {
    this.svcContacts.getSiteContactsAnakin([], EPrefix.contact, true, false).pipe(
      map((contacts: IContact[]) => {
        return contacts.map((contact: IContact) => {
					if(!contact.isDoctor && contact.finess)
         		contact.isDoctor = true;
          return contact;
        });
      })
    ).subscribe((contacts: IContact[]) => {
      this.contacts = contacts.filter(contact => contact.isDoctor || !!contact.finess);
    });
    
    this.model = this.patient;
  }

  public selectContact(contactId: string) {
    console.log("maj doctors : ", contactId)
    this.model = {
      ...this.model,
      doctors: [contactId]
    }
    this.svcPatients.savePatientAnakin(this.model).subscribe(() => {
      this.svcPanneau.close();
    })
  }

  handleClickAddContact(patientId: string) {
    this.svcPanneau.close();
    this.router.navigate(["contacts", "add", patientId]);
  }

}
