import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { MatBadgePosition } from '@angular/material/badge';
import { StringHelper } from '../../../helpers/stringHelper';

@Directive({
	selector: '[calaoBadgeIcon]',
	host: {
		'class': 'mat-badge'
	}
})
export class BadgeIconDirective {

	//#region FIELDS

	private moBadgeIconElement: HTMLIonIconElement;
	private static readonly C_CONTENT_CLASS = "mat-badge-content";
	private static readonly C_SIZE_FOR_POSITIONNING = 22;

	//#endregion

	//#region PROPERTIES

	private msIcon: string;
	public get icon(): string {
		return this.msIcon;
	}
	@Input("calaoBadgeIcon")
	public set icon(psIcon: string) {
		if (psIcon !== this.msIcon) {
			this.msIcon = psIcon;
			this.updateIcon();
		}
	}

	private msPosition: MatBadgePosition = "above after";
	public get position(): MatBadgePosition {
		return this.msPosition;
	}
	@Input("calaoBadgeIconPosition")
	public set position(psPosition: MatBadgePosition) {
		if (psPosition !== this.msPosition) {
			this.msPosition = psPosition;
			this.updatePosition();
		}
	}

	private mbOverlap: boolean = true;
	/** Permet de faire chevaucher le badge sur l'hôte. `true` par défaut. */
	public get overlap(): boolean {
		return this.mbOverlap;
	}
	@Input("calaoBadgeIconOverlap")
	public set overlap(pbOverlap: boolean) {
		this.mbOverlap = coerceBooleanProperty(pbOverlap);
		this.updatePosition();
	}

	private mbHidden: boolean;
	public get hidden(): boolean {
		return this.mbHidden;
	}
	@Input("calaoBadgeIconHidden")
	public set hidden(pbHidden: boolean) {
		this.mbHidden = coerceBooleanProperty(pbHidden);
		this.updateVisibility();
	}

	private msColor: string;
	public get color(): string {
		return this.msColor;
	}
	@Input("calaoBadgeIconColor")
	public set color(psColor: string) {
		if (psColor !== this.msColor) {
			this.msColor = psColor;
			this.updateColor();
		}
	}

	private msCssClass: string;
	public get cssClass(): string {
		return this.msCssClass;
	}
	@Input("calaoBadgeIconCssClass")
	public set cssClass(psCssClass: string) {
		if (psCssClass !== this.msCssClass) {
			this.msCssClass = psCssClass;
			this.updateCssClass();
		}
	}

	private msTitle: string;
	public get title(): string {
		return this.msTitle;
	}
	@Input("calaoBadgeIconTitle")
	public set title(psTitle: string) {
		if (psTitle !== this.title) {
			this.msTitle = psTitle;
			this.updateTitle();
		}
	}

	@Output("calaoBadgeIconClicked") private readonly moClickedEventEmitter = new EventEmitter<MouseEvent>();

	//#endregion

	//#region METHODS

	constructor(
		private ioElementRef: ElementRef<HTMLElement>,
		private ioRenderer: Renderer2
	) { }

	/** */
	public isAbove(): boolean {
		return !this.position?.includes("below");
	}

	/**  */
	public isAfter(): boolean {
		return !this.position?.includes("before");
	}

	private updateCssClass(): void {
		if (!this.moBadgeIconElement)
			this.moBadgeIconElement = this.createBadgeIconElement();
		else
			this.moBadgeIconElement.classList.add(this.cssClass);
	}

	private updatePosition(): void {
		if (!this.moBadgeIconElement)
			this.moBadgeIconElement = this.createBadgeIconElement();
		else
			this.initElementPosition();
	}

	private updateVisibility(): void {
		if (!this.moBadgeIconElement)
			this.moBadgeIconElement = this.createBadgeIconElement();
		else
			this.initElementVisibility();
	}

	private updateColor(): void {
		if (!this.moBadgeIconElement)
			this.moBadgeIconElement = this.createBadgeIconElement();
		else
			this.moBadgeIconElement.color = this.color;
	}

	private updateIcon(): void {
		if (!this.moBadgeIconElement)
			this.moBadgeIconElement = this.createBadgeIconElement();
		else
			this.moBadgeIconElement.icon = this.icon;
	}

	private updateTitle(): void {
		if (!this.moBadgeIconElement)
			this.moBadgeIconElement = this.createBadgeIconElement();
		else
			this.moBadgeIconElement.title = this.title;
	}

	private createBadgeIconElement(): HTMLIonIconElement {
		const loBadgeElement: HTMLIonIconElement = this.ioRenderer.createElement("ion-icon");

		this.clearExistingBadges();

		loBadgeElement.classList.add(BadgeIconDirective.C_CONTENT_CLASS);

		if (!StringHelper.isBlank(this.cssClass))
			loBadgeElement.classList.add(this.cssClass);

		loBadgeElement.icon = this.icon;
		loBadgeElement.color = this.color;
		loBadgeElement.size = "large";
		loBadgeElement.title = this.title;
		loBadgeElement.style.padding = "4px";
		loBadgeElement.style.width = "24px";
		loBadgeElement.style.height = "24px";
		loBadgeElement.style.border = "solid";
		this.initElementPosition(loBadgeElement);
		this.initElementVisibility(loBadgeElement);
		loBadgeElement.addEventListener("click", (poEvent: MouseEvent) => this.moClickedEventEmitter.emit(poEvent));

		this.ioElementRef.nativeElement.appendChild(loBadgeElement);

		return loBadgeElement;
	}

	private initElementVisibility(poBadgeElement: HTMLIonIconElement = this.moBadgeIconElement): void {
		if (this.hidden || !this.icon)
			poBadgeElement.style.display = "none";
		else
			poBadgeElement.style.removeProperty("display");
	}

	private initElementPosition(poBadgeElement: HTMLIonIconElement = this.moBadgeIconElement): void {
		const lnVerticalPosition: number = this.getVerticalPosition();
		const lnHorizontalPosition: number = this.getHorizontalPosition();

		if (this.isAbove()) {
			poBadgeElement.style.top = `-${lnVerticalPosition}px`;
			poBadgeElement.style.removeProperty("bottom");
		}
		else {
			poBadgeElement.style.bottom = `-${lnVerticalPosition}px`;
			poBadgeElement.style.removeProperty("top");
		}

		if (this.isAfter()) {
			poBadgeElement.style.right = `-${lnHorizontalPosition}px`;
			poBadgeElement.style.removeProperty("left");
		}
		else {
			poBadgeElement.style.left = `-${lnHorizontalPosition}px`;
			poBadgeElement.style.removeProperty("right");
		}
	}

	private clearExistingBadges(): void {
		let lnChildCount = this.ioElementRef.nativeElement.children.length;

		while (lnChildCount--) {
			const loCurrentChild: Element = this.ioElementRef.nativeElement.children[lnChildCount];

			if (loCurrentChild.classList.contains(BadgeIconDirective.C_CONTENT_CLASS))
				this.ioElementRef.nativeElement.removeChild(loCurrentChild);
		}
	}

	private getVerticalPosition(): number {
		return BadgeIconDirective.C_SIZE_FOR_POSITIONNING / 2;
	}

	private getHorizontalPosition(): number {
		if (this.overlap)
			return BadgeIconDirective.C_SIZE_FOR_POSITIONNING / 2;
		return BadgeIconDirective.C_SIZE_FOR_POSITIONNING;
	}

	//#endregion


}
