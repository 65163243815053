import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';
import { defer, Observable } from 'rxjs';
import { SecurityService } from '../services/security.service';

/** Garde qui déconnecte l'utilisateur courant. */
@Injectable({ providedIn: "root" })
export class DisconnectGuard implements CanActivate, CanLoad {

	//#region METHODS

	constructor(private isvcSecurityService: SecurityService) { }

	public canActivate(): Observable<boolean> {
		return this.disconnect();
	}

	public canLoad(): Observable<boolean> {
		return this.disconnect();
	}

	private disconnect(): Observable<boolean> {
		console.debug("DSCNECT.G::Déconnexion de l'utilisateur.");
		return defer(() => this.isvcSecurityService.disconnect());
	}

	//#endregion

}