import { Exclude, Expose } from 'class-transformer';
import { ESecteur } from '../../../model/ESecteur';
import { IDeplacement } from './IDeplacement';

export class Deplacement implements IDeplacement {

	//#region FIELDS

	//#endregion

	//#region PROPERTIES

	@Exclude()
	private mnDistance: number;
	@Expose()
	/** @implements */
	public get distance(): number { return this.mnDistance; }
	public set distance(pnDistance: number) {
		this.mnDistance = (pnDistance && !isNaN(pnDistance) && pnDistance > 0) ? pnDistance : 0;
	}

	@Exclude()
	private mnSectorPrice: number;
	@Expose()
	/** @implements */
	public get sectorPrice(): number { return this.mnSectorPrice; }
	public set sectorPrice(pnPrice: number) {
		this.mnSectorPrice = pnPrice;
	}

	@Exclude()
	private meSectorType: ESecteur;
	@Expose()
	/** @implements */
	public get sectorType(): ESecteur { return this.meSectorType; }
	public set sectorType(peType: ESecteur) {
		this.meSectorType = peType;
	}

	/** @implements */
	public isManualPrice: boolean;

	@Exclude()
	private mnManualPrice: number;
	@Expose()
	/** @implements */
	public get manualPrice(): number {
		return this.isManualPrice ? this.mnManualPrice : undefined;
	}
	public set manualPrice(pnManualPrice: number) {
		if (pnManualPrice !== this.mnManualPrice)
			this.mnManualPrice = pnManualPrice;
	}

	//#endregion

	//#region METHODS

	constructor(poSectorType: ESecteur = ESecteur.Plaine, pbIsManualPrice: boolean = false) {
		this.mnSectorPrice = 0;
		this.sectorType = poSectorType;
		this.isManualPrice = pbIsManualPrice;
	}

	/** Crée une instance de déplacement à partir des données d'un objet similaire récupérées en base de données.
	 * @param poData Données de l'objet récupérées en bases de données dont il faut créer une instance de `Deplacement`.
	 */
	public static getInstanceFromData(poData: IDeplacement): Deplacement {
		const loDeplacement = new Deplacement();

		// Pour retro compat des champs foireux
		Object.assign(loDeplacement, poData);

		return loDeplacement;
	}

	//#endregion
}