<osapp-header-modal [title]="mode === 'personalize' ? 'Personnaliser un acte' : 'Dupliquer un acte'">
</osapp-header-modal>

<ion-content class="ion-padding">
	<ng-container *ngIf="personalizedActe">
		<h2>Description NGAP</h2>
		<p>{{ acte?.ngapLabel }}</p>

		<h2>Description personnalisée</h2>
		<ion-textarea [(ngModel)]="personalizedActe.description">
		</ion-textarea>

		<div class="chapters-ctn">
			<h2>Chapitre</h2>
			<mat-form-field appearance="fill">
				<mat-select [(value)]="selectedChapterIds">
					<mat-option *ngIf="!this.isActeHN">
						Aucun
					</mat-option>
					<mat-option *ngFor="let item of chaptersOptions | keyvalue" [value]="item.value">
						{{item.key.description}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<h2>Mots-clés</h2>
		<ion-input type="text" [(ngModel)]="personalizedActe.tags" [placeholder]="acte?.tags"></ion-input>

		<div class="flex">
			<div class="form-item">
				<h2>Lettre clé</h2>
				<p>{{ acte?.keyLetters }}</p>
			</div>
			<div class="form-item">
				<h2>Coef.</h2>
				<p>{{ acte?.priceCoefficient }}</p>
			</div>
			<div class="form-item">
				<h2>Tarif (€)</h2>
				<p>{{ acte?.price }}</p>
			</div>
		</div>

		<div class="flex-left">
			<div *ngIf="personalizedActe.extraCharge >= 0" class="form-item">
				<h2>Supplément (€)</h2>
				<ion-input type="number" [(ngModel)]="personalizedActe.extraCharge" [placeholder]="acte?.extraCharge">
				</ion-input>
			</div>

			<div class="form-item">
				<h2>Durée (min)</h2>
				<ion-input type="number" [(ngModel)]="personalizedActe.duration" [placeholder]="acte?.duration"></ion-input>
			</div>
		</div>

		<div class="buttons flex">
			<ion-button *ngIf="isPersonalizedActeFromDatabase" shape="round" expand="block" color="danger"
				(click)="resetPersonalizedActe()">
				Supprimer
			</ion-button>
			<ion-button color="success" shape="round" expand="block" (click)="validateForm()">
				<ion-icon name="checkmark"></ion-icon>
				Valider
			</ion-button>
		</div>
	</ng-container>
</ion-content>