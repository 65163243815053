<div class="di-acte-actions-content">
	<ng-container *ngIf="seance.actes.length > 0">
		<div *ngFor="let acte of seance.actes; let i = index" class="acte">
			<di-acte [acte]="acte"></di-acte>
			<lua-bouton-icone
				iconName="autorenew"
				mode="filled"
				(onClick)="replaceActe(acte)"
			></lua-bouton-icone>
			<lua-bouton-icone
				iconName="delete"
				mode="outlined"
				color="Error"
				(onClick)="deleteActe($event, acte)"
			></lua-bouton-icone>
		</div>
	</ng-container>
	<div *ngIf="seance.actes.length === 0" class="aucun-acte">
		<lua-message-aucune-donnee
			iconName="contract"
			mainText="Aucun acte"
			subText="Ajoutez des actes pour lister les soins à réaliser"
			buttonLabel=""
		></lua-message-aucune-donnee>
	</div>

	<div class="button-container">
		<lua-bouton-texte
			startIcon="add"
			libelle="Ajouter un acte"
			(click)="addActe()"
		></lua-bouton-texte>
	</div>
</div>
