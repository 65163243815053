import { customAlphabet } from 'nanoid';
import { alphanumeric, numbers, uppercase } from 'nanoid-dictionary';
import { IGuidOptions } from "../../../model";
import { IGuidBuilder } from "./iguid-builder";

export class ShortGuidBuilder implements IGuidBuilder {

	/** @implements
	 * @returns Guid de 21 caractères.
	*/
	public build(poOptions: IGuidOptions = {}): string {
		if (poOptions.upperCase)
			return customAlphabet(uppercase + numbers)();

		return customAlphabet(alphanumeric)();
	}

	/** @implements */
	public isValid(psGuid: string): boolean {
		return (/^[0-9a-zA-Z]{21}/i).test(psGuid);
	}
}
