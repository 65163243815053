<div class="page-container">
	<div class="content">
		<div class="logo-mobile">
			<lua-icone
				[isIcon]="true"
				width="100px"
				height="100px"
				color="CouleurPrimaire"
				customIconName="desmos"
			></lua-icone>
			<h1>DESMOS</h1>
			<div class="sous-titre">Infirmiers</div>
		</div>
		<div class="message">
			<lua-message-erreur
				[messageTitle]="'Votre compte est bloqué'"
				[messageDetail]="'Pour des raisons de sécurité votre compte à été bloqué pour en protéger l’accès. Vous devez réinitialiser votre mot de passe.'"
				[buttonLabel]="'Changer mon mot de passe'"
				(onClick)="navigateToPwdChange()"
			></lua-message-erreur>
		</div>
	</div>
	<di-side-page-desmos
		colorTitre="Blanc"
		colorLogo="Blanc"
		colorBackground="DegradeOrisha2Couleurs"
		[showBackgroundImage]="false"
	></di-side-page-desmos>
</div>
