import { NgModule, PipeTransform, Type } from '@angular/core';
import { TraitementStatePipe } from './traitement-state.pipe';
import { TraitementDatesPipe } from './traitementDates.pipe';

const pipes: Array<Type<PipeTransform>> = [
	TraitementStatePipe,
	TraitementDatesPipe
];

@NgModule({
	imports: [],
	exports: pipes,
	declarations: pipes,
	providers: pipes
})
export class IdlPipeModule { }