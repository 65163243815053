<div class="form-inviter-utilisateur-container">
	<form [formGroup]="utilisateurForm" (ngSubmit)="handleClickSaveBtn()" class="form-inviter-utilisateur-form">
		<div class="form-inviter-utilisateur-header">
			<h1 class="form-inviter-utilisateur-name">
				{{ userFind.lastName | uppercase }} {{ userFind.firstName | titlecase }}
			</h1>
			<small>Seul l’utilisateur concerné peut modifier son adresse email</small>
		</div>
		<div class="form-inviter-utilisateur-body">
			<div class="gap-20 form-inviter-utilisateur-infos-telmail">
				<lua-input formControlName="email" disabled="true" label="Email de connexion" placeholder="Email de connexion"
					[fullWidth]="true"></lua-input>
			</div>
			<div class="gap-20 form-inviter-utilisateur-color">
				<lua-color-picker [selectedOptionsChange]="onOptionClick"></lua-color-picker>
			</div>
			<div class="gap-20 ">
				<h2>Quel est le profil</h2>

				<lua-data-picker label="" [options]="options" (selectedOptionsChange)="onDataSelect($event)"
					[selectedOptions]="selectedOptions"></lua-data-picker>
			</div>
			<div class="gap-20 ">
				<h2>Liste des cabinets</h2>
				<div *ngFor="let item of cabinets">
					<lua-papier mode="outlined" [fullWidth]="true" [selected]="selectedCabinet.includes(item)" color="Indigo"
						[selectionnable]="false">
						<div class="cabinet">
							<lua-objet-cabinet [name]="item.name" [lightColors]="false"></lua-objet-cabinet>
							<lua-checkbox [checked]="selectedCabinet?.includes(item)"
								(checkedChange)="onCabinetSelect($event, item)"></lua-checkbox>
						</div>
					</lua-papier>
				</div>

				<div class="gap-20 switch-container">
					<div class="switch" *ngIf="selectedOptions.includes(infirmierObj.value)">
						<lua-switch label="Administrateur" [isChecked]="isAdmin" [texteSecondaire]="texteSecondaireGereCabinet"
							(clickSwitch)="toggleIsAdmin($event)"></lua-switch>
					</div>
					<!-- <div class="switch">
						<lua-switch label="Compte utilisateur actif" [isChecked]="isActif"
							[texteSecondaire]="texteSecondaireGereCompte" (clickSwitch)="toggleIsActif($event)"></lua-switch>
					</div> -->
				</div>
			</div>
		</div>
		<div class="gap-20 form-inviter-utilisateur-footer">
			<lua-alerte *ngIf="isError" [filled]="true" [fullWidth]="true" type="error" iconName="info"
				[message]="errorMessage"></lua-alerte>
			<lua-action-principale libelle="Enregistrer" [isDisabled]="allValidForm()" (click)="handleClickSaveBtn()"
				[fullWidth]="true"></lua-action-principale>
			<lua-bouton-texte libelle="Annuler" [fullWidth]="true" (click)="handleClickCancelBtn()"></lua-bouton-texte>
		</div>
	</form>
</div>