import { DateHelper } from "@osapp/helpers";
import { IdHelper } from "@osapp/helpers/idHelper";
import { IGalleryFile } from "@osapp/model";
import { Exclude, Expose } from "class-transformer";
import { C_PREFIX_AMCP } from "../../../app/app.constants";
import { ETypePieceJustificative } from "./etype-piece-justificative.enum";
import { EUpdateMode } from "./eupdate-mode.enum";
import { IAMCP } from "./iamc-p";
import { IUpdateHistory } from "./iupdate-history";
import { IConvention } from "./iconvention";

export class AMCP implements IAMCP {

	//#region PROPERTIES

	/** @implements */
	public _id: string;
	/** @implements */
	public _rev?: string;
	/** @implements */
	public _deleted?: boolean;
	/** @implements */
	public deleted?: boolean;
	/** @implements */
	public _conflicts?: string[];
	@Exclude()
	private msAmcId: string;
	/** @implements */
	@Expose()
	public get amcId(): string { return this.msAmcId; }
	public set amcId(psAmcId: string) {
		this.msAmcId = psAmcId;
	}
	/** @implements */
	public numAdherent: string;
	/** @implements */
	public mutnum: string;
	/** @implements */
	public dateDebut: Date;
	/** @implements */
	public dateFin: Date;
	/** @implements */
	public tp = true;
	/** @implements */
	public gestionUnique = false;
	/** @implements */
	public sts = true;
	/** @implements */
	public idConvention?: number;
	/** @implements */
	public datePieceJustificative?: Date;
	/** @implements */
	public pieceJustificative?: ETypePieceJustificative;
	/** @implements */
	public dre?: boolean;
	/** @implements */
	public updateDate?: Date;
	/** @implements */
	public updateMode?: EUpdateMode;
	/** @implements */
	public updateHistory?: IUpdateHistory[];
	/** @implements */
	public files: IGalleryFile[];
	/** @implements */
	public convention?: IConvention;


	public get isActive(): boolean {
		return DateHelper.isBetweenTwoDates(new Date(), DateHelper.resetDay(this.dateDebut), DateHelper.fillDay(this.dateFin));
	}

	public get isActiveAnakin(): boolean {
		if(this.dateDebut && this.dateFin)
			return DateHelper.isBetweenTwoDates(new Date(), DateHelper.resetDay(this.dateDebut), DateHelper.fillDay(this.dateFin));
		if(this.dateDebut)
			return DateHelper.isBetweenTwoDates(new Date(), DateHelper.resetDay(this.dateDebut), DateHelper.fillDay(new Date(8640000000000000)));
		if(this.dateFin)
			return DateHelper.isBetweenTwoDates(new Date(), DateHelper.resetDay(new Date(-8640000000000000)), DateHelper.fillDay(this.dateFin));
		// Si il n'y a pas de dateDebut ni de dateFin alors l'AMC est active
		return true
	}

	public get gestionUniqueLabel(): string {
		return this.gestionUnique ? "Unique" : "Séparé";
	}

	//#endregion

	//#region METHODS

	constructor(psPatientId: string) {
		this._id = IdHelper.buildChildId(C_PREFIX_AMCP, psPatientId);
		this.dateDebut = DateHelper.resetYear(new Date());
		this.dateFin = DateHelper.fillYear(new Date());
	}

	//#endregion

}
