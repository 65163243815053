import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ContentChildren, Input, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { IonItemSliding } from '@ionic/angular';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { NumberHelper } from '../../../../helpers/numberHelper';
import { OsappError } from '../../../errors/model/OsappError';
import { ObservableArray } from '../../../observable/models/observable-array';
import { EOrientation } from '../../models/eorientation.enum';

@Component({
	selector: 'calao-virtual-scroll',
	templateUrl: './virtual-scroll.component.html',
	styleUrls: ['./virtual-scroll.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class VirtualScrollComponent<T> extends ComponentBase implements OnInit, OnDestroy {

	//#region FIELDS

	private static readonly C_DEFAULT_MAX_HEIGHT = "60vh";

	private readonly moHasHeightSubject = new BehaviorSubject<boolean>(false);
	private readonly moDivStyleSubject = new BehaviorSubject<CSSStyleDeclaration>(undefined);
	private moMutationObserver: MutationObserver;
	private moIntersectionObserver: IntersectionObserver;
	private moVirtualScrollScrolledIndexChangedSubscription: Subscription;
	private readonly moSlidingItemsSubject = new ReplaySubject<QueryList<IonItemSliding>>(1);

	//#endregion

	//#region PROPERTIES

	@ContentChild(TemplateRef) public readonly templateRef: TemplateRef<any>;

	private moItems: ObservableArray<T>;
	public get displayedItems(): ObservableArray<T> { return this.moItems; }

	@Input() public set items(paNewItems: T[]) {
		if (!this.moItems) {
			if (paNewItems) {
				this.moItems = paNewItems instanceof ObservableArray ? paNewItems : new ObservableArray(paNewItems);
				this.initDivStyleObservable();
				this.detectChanges();
			}
		}
		else
			this.moItems.resetArray(paNewItems);
	}

	private mnItemSize: number;
	public get itemSize(): number { return this.mnItemSize; }
	@Input() public set itemSize(pnItemSize: number) {
		const lnItemSize: number = coerceNumberProperty(pnItemSize);
		if (NumberHelper.isValid(lnItemSize) && lnItemSize !== this.mnItemSize) {
			this.mnItemSize = lnItemSize;
			this.updateStyle(this.moItems?.length);
			this.detectChanges();
		}
	}

	private msMaxHeight: string;
	public get maxHeight(): string { return this.msMaxHeight ?? VirtualScrollComponent.C_DEFAULT_MAX_HEIGHT; }
	@Input() public set maxHeight(poMaxHeight: string) {
		if (poMaxHeight !== this.msMaxHeight) {
			this.msMaxHeight = poMaxHeight;
			this.detectChanges();
		}
	}

	private meOrientation: EOrientation;
	public get orientation(): EOrientation { return this.meOrientation ?? EOrientation.vertical; }
	@Input() public set orientation(peOrientation: EOrientation) {
		if (peOrientation !== this.meOrientation) {
			this.meOrientation = peOrientation;
			this.updateStyle(this.moItems?.length);
			this.detectChanges();
		}
	}

	private mnMaxBufferPx: number;
	public get maxBufferPx(): number { return this.mnMaxBufferPx; }
	@Input() public set maxBufferPx(pnMaxBufferPx: number) {
		if (pnMaxBufferPx !== this.mnMaxBufferPx) {
			this.mnMaxBufferPx = pnMaxBufferPx;
			this.detectChanges();
		}
	}

	private mnMinBufferPx: number;
	public get minBufferPx(): number { return this.mnMinBufferPx; }
	@Input() public set minBufferPx(pnMinBufferPx: number) {
		if (pnMinBufferPx !== this.mnMinBufferPx) {
			this.mnMinBufferPx = pnMinBufferPx;
			this.detectChanges();
		}
	}

	private mbTransparent: boolean;
	public get transparent(): boolean { return this.mbTransparent; }
	@Input() public set transparent(pbTransparent: boolean) {
		const lbTransparent: boolean = coerceBooleanProperty(pbTransparent);
		if (lbTransparent !== this.mbTransparent) {
			this.mbTransparent = lbTransparent;
			this.detectChanges();
		}
	}

	private moViewport: CdkVirtualScrollViewport;
	public get viewport(): CdkVirtualScrollViewport { return this.moViewport; }
	@ViewChild("viewportId", { read: CdkVirtualScrollViewport }) public set viewport(poViewport: CdkVirtualScrollViewport) {
		if (poViewport !== this.moViewport) {
			this.moViewport = poViewport;

			this.initCloseAllSlidingItems();

			this.moHasHeightSubject.next(this.viewportHasHeight());
			this.moMutationObserver = new MutationObserver(() => this.moHasHeightSubject.next(this.viewportHasHeight()));
			this.moMutationObserver.observe(this.viewport.elementRef.nativeElement, { attributeFilter: ["opacity", "display", "visibility"] });
			this.moIntersectionObserver = new IntersectionObserver(() => this.moHasHeightSubject.next(this.viewportHasHeight()), { threshold: [0, 0.25, 0.5, 0.75, 1] });
			this.moIntersectionObserver.observe(this.viewport.elementRef.nativeElement);

			this.detectChanges();
		}
	}

	// C'est angular qui vient set directement cette valeur, à ne pas supprimer.
	@ContentChildren(IonItemSliding, { read: IonItemSliding, descendants: true }) private set slidingItems(poSlidingItems: QueryList<IonItemSliding>) {
		this.moSlidingItemsSubject.next(poSlidingItems);
	}

	public readonly divStyle$: Observable<CSSStyleDeclaration> = this.moDivStyleSubject.asObservable();

	public get viewportHeight(): string {
		if (!this.viewport)
			return undefined;

		return `${this.viewport?.elementRef.nativeElement.clientHeight}px`;
	}

	//#endregion

	//#region METHODS

	constructor(poChangeDetector: ChangeDetectorRef) {
		super(poChangeDetector);
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moIntersectionObserver?.disconnect();
		this.moMutationObserver?.disconnect();
		this.moHasHeightSubject.complete();
		this.moDivStyleSubject.complete();
		this.moSlidingItemsSubject.complete();
		this.moVirtualScrollScrolledIndexChangedSubscription?.unsubscribe();
	}

	public ngOnInit(): void {
		if (!NumberHelper.isValid(this.itemSize))
			throw new OsappError("Le paramètre 'itemSize' doit être renseigné.");
	}

	private viewportHasHeight(): boolean {
		return this.viewport?.elementRef.nativeElement.clientHeight > 0;
	}

	private initDivStyleObservable(): void {
		let laPreviousValues: [number, boolean] = [0, false];

		combineLatest([
			this.moItems.length$.pipe(distinctUntilChanged()),
			this.moHasHeightSubject.asObservable()])
			.pipe(
				filter((paValues: [number, boolean]) => {
					if (paValues[1] && (laPreviousValues[0] !== paValues[0] || laPreviousValues[1] !== paValues[1])) {
						this.updateStyle(paValues[0]);
						this.viewport.checkViewportSize();
					}

					laPreviousValues = paValues;

					return paValues[1];
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private updateStyle(pnLength: number = 0) {
		if (this.orientation === EOrientation.horizontal)
			this.moDivStyleSubject.next({ width: "100%" } as CSSStyleDeclaration);
		else
			this.moDivStyleSubject.next({ height: `${pnLength * this.itemSize}px`, maxHeight: this.viewportHeight } as CSSStyleDeclaration);
	}

	public scrollToIndex(pnIndex: number, psBehavior?: ScrollBehavior): void {
		return this.moViewport.scrollToIndex(pnIndex, psBehavior);
	}

	private initCloseAllSlidingItems(): void {
		if (this.moVirtualScrollScrolledIndexChangedSubscription)
			this.moVirtualScrollScrolledIndexChangedSubscription.unsubscribe();

		this.moVirtualScrollScrolledIndexChangedSubscription = this.moViewport.scrolledIndexChange.pipe(
			takeUntil(this.destroyed$),
			switchMap(() => this.moSlidingItemsSubject.asObservable()),
			tap((poSlidingItems: QueryList<IonItemSliding>) => poSlidingItems.forEach((poItem: IonItemSliding) => poItem.close()))
		).subscribe();
	}

	//#endregion

}