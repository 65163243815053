/** Différents types d'indemnités possibles. */
export enum EIndemniteType {

	/** Indemnité Forfétaire de Déplacement. */
	IFD = "IFD",
	/** Indemnité Forfaitaire de déplacement dans le cadre d’un forfait dépendance ou d’une prise en charge IPA. */
	IFI = "IFI",
	/** Indemnité Kilométrique Montagne. */
	IKM = "IKM",
	/** Indemnité Kilométrique Plaine. */
	IK = "IK",
	/** Indemnité Kilométrique à pied / Ski. */
	IKS = "IKS"
}