import { Component, Input, OnInit } from '@angular/core';
import { ComponentBase } from '@osapp/helpers';
import { IConversationListParams } from '@osapp/model';
import { IGroup } from '@osapp/model/contacts/IGroup';
import { EConversationStatus } from '@osapp/model/conversation/EConversationStatus';
import { C_SECTORS_ROLE_ID } from '@osapp/modules/permissions/services/permissions.service';
import { EntityLinkService } from '@osapp/services';
import { GroupsService } from '@osapp/services/groups.service';
import { takeUntil, tap } from 'rxjs/operators';
import { IIdlConversationsListParams } from '../../model/IIdlConversationsListParams';

@Component({
	selector: 'idl-conversations-list',
	templateUrl: './idlConversationsList.component.html'
})
export class IdlConversationsListComponent extends ComponentBase implements OnInit {

	//#region PROPERTIES

	/** Paramètres pour le composant. */
	@Input() public params: IIdlConversationsListParams;

	@Input() public conversationsStatusFilter: EConversationStatus;

	/** Paramètres pour le composant osapp `conversationList`. */
	public convListParams: IConversationListParams;

	//#endregion

	//#region METHODS

	constructor(private isvcEntityLink: EntityLinkService, private isvcGroups: GroupsService) {
		super();
	}

	public ngOnInit(): void {
		this.isvcGroups.getGroupsByRoles([C_SECTORS_ROLE_ID], true)
			.pipe(
				tap((paSectors: IGroup[]) => {
					this.convListParams = {
						galleryAcceptFiles: this.params.galleryAcceptFiles !== undefined ? this.params.galleryAcceptFiles : "",
						linkedEntities: this.params.linkedEntities !== undefined ? this.params.linkedEntities : this.params.model ? [this.isvcEntityLink.buildEntity(this.params.model)] : undefined,
						swipeDisabled: this.params.swipeDisabled !== undefined ? this.params.swipeDisabled : true,
						contactSelectorParams: {
							groupFilterParams: {
								options: paSectors.map((poSector: IGroup) => ({ label: poSector.name, value: poSector }))
							}
						},
						filterConversationsByWorkspace: this.params.filterConversationsByWorkspace
					};
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	//#endregion
}