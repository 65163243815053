import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ArrayHelper } from '@osapp/helpers';
import { EPrefix } from '@osapp/model/EPrefix';
import { ISelectOption } from '@osapp/modules/selector/selector/ISelectOption';
import { C_CONSTANTES_PREFIX, C_INJECTIONS_PREFIX } from 'apps/idl/src/app/app.constants';
import { IConstantesListParams } from '../../../model/constantes/IConstantesListParams';

@Component({
	selector: 'idl-surveillance',
	templateUrl: './surveillance.component.html',
	styleUrls: ['./surveillance.component.scss'],
})
export class SurveillanceComponent {

	//#region PROPERTIES

	/** Émetteur d'événement pour indique si l'action s'est passée correctement `true` ou s'il y a eu un problème (conversation existante par exempole) `false`. */
	@Output("onSelectedTypeChanged") private readonly moOnSelectedTypeChanged = new EventEmitter<EPrefix>();

	//#endregion

	//#region PROPERTIES

	@Input() public params: IConstantesListParams;

	public readonly typeOptions: ReadonlyArray<ISelectOption<string>> = [
		{ label: "Constantes", value: C_CONSTANTES_PREFIX },
		{ label: "Dosages", value: C_INJECTIONS_PREFIX }
	];
	public selectedType: EPrefix;

	//#endregion

	//#region METHODS

	constructor() { }

	/** Lève un événement pour indiquer que le chois de l'affichage a changé.
	 * @param paTypes 
	 */
	public onSelectedTypeChanged(paTypes: EPrefix[]): void {
		this.selectedType = ArrayHelper.getFirstElement(paTypes);
		this.moOnSelectedTypeChanged.next(this.selectedType);
	}

	//#endregion

}
