import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { ISectionWrapperParams } from '../../../../../model/forms/ISectionWrapperParams';

@Component({
	selector: 'section-wrapper',
	templateUrl: './sectionWrapper.component.html',
	styleUrls: ['./sectionWrapper.component.scss']
})
export class SectionWrapperComponent extends FieldWrapper implements OnInit, ISectionWrapperParams {

	//#region FIELDS



	//#endregion

	//#region PROPERTIES

	/** @implements */
	public sectionCollapsed = true;

	/** Conteneur pour le champs de formulaire. */
	@ViewChild("fieldComponent", { read: ViewContainerRef, static: true }) public fieldComponent: ViewContainerRef;

	//#endregion

	//#region METHODS

	constructor() {
		super();
	}

	public ngOnInit(): void {
		if (typeof this.to?.data?.sectionCollapsed === "boolean")
			this.sectionCollapsed = this.to.data.sectionCollapsed;
	}

	public onExpand(): void {
		this.sectionCollapsed = !this.sectionCollapsed;
	}

	//#endregion
}