<div class="menu-compte-utilisateur-container">
	<div>
		<lua-list-item
			class="list-item"
			iconColor="CouleurPrimaire"
			textColor="Noir"
			iconName="person"
			text="Mon Compte"
			(click)="redirectMyAccount()"
			>
		</lua-list-item>
	</div>
	<lua-action-secondaire
		libelle="Déconnexion"
		(click)="disconnect()"
	></lua-action-secondaire>
	<lua-bouton-texte libelle="A propos de DESMOS" (click)="redirectAppInfo()"></lua-bouton-texte>
</div>
