<ng-container *ngTemplateOutlet="isListingAllTransmissions ? slides : noSlide">
</ng-container>

<!-- Bouton de création -->
<div *ngIf="!params?.hideToolbar && !params?.useToolbar" class="fab-space">
	<ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
		<ion-fab-button color="fab-button" (click)="createNewTransmission()" class="fab-shadow" title="Ajouter">
			<ion-icon name="add"></ion-icon>
		</ion-fab-button>
	</ion-fab>
</div>

<!-- Template listing des transmissions depuis un patient (pas de slide). -->
<ng-template #noSlide>
	<ng-container *ngTemplateOutlet="content; context: { index: 0 }">
	</ng-container>
</ng-template>

<!-- Template de listing de toutes les transmissions (avec slides). -->
<ng-template #slides>
	<super-tabs (tabChange)="onTabChanged($event)">
		<super-tabs-toolbar slot="top">
			<super-tab-button>
				<ion-label>Non clôturées</ion-label>
			</super-tab-button>
			<super-tab-button>
				<ion-label>Clôturées</ion-label>
			</super-tab-button>
		</super-tabs-toolbar>

		<super-tabs-container>
			<!-- Première slide (en cours). -->
			<super-tab class="hide-status">
				<ion-content scroll-y="false" class="slide-transmissions">
					<ng-container *ngTemplateOutlet="content; context: { index: 0 }">
					</ng-container>
				</ion-content>
			</super-tab>
			<!-- Seconde slide (toutes). -->
			<super-tab>
				<ion-content scroll-y="false" class="slide-transmissions">
					<ng-container *ngTemplateOutlet="content; context: { index: 1 }">
					</ng-container>
				</ion-content>
			</super-tab>
		</super-tabs-container>
	</super-tabs>
</ng-template>

<!-- Template de la liste avec barre de recherche à afficher. -->
<ng-template #content let-contentIndex="index">
	<div class="ion-no-padding search-item">
		<div class="just-flex">
			<!-- Barre de recherche. -->
			<div *ngIf="!params?.hideSearchbar" class="search-box txt-center form-list width100pc">
				<search #search [data]="documents" [options]="searchOptions"
					(filteredEntriesChanged)="onFilteredDocumentsChanged($event)"
					(searchValueChange)="onSearchValueChanged($event)">
				</search>
			</div>
			<!-- Bouton de filtrage. -->
			<ion-button mode="md" *ngIf="!params?.hideFilterbar" (click)="swapFilterbarHiddenParams()" size="small"
				class="filter-button-right" color="osapp-element">
				<ion-icon slot="icon-only" [name]="filtersCount > 0 ? 'filter-fill' : 'filter'">
				</ion-icon>
				<span *ngIf="filtersCount > 0" class="badge">{{ filtersCount }}</span>
			</ion-button>
		</div>
		<ng-container *ngIf="!params?.hideSearchbar && !params?.hideTags">
			<osapp-selector [options]="sectorSelectOptions" displayMode="tags"
				(selectionChanged)="onSectorSelectionChanged($event)">
			</osapp-selector>
			<osapp-selector [preselectedValues]="selectedSort" displayMode="tags" label="Trier par"
				[options]="sortSelectOptions" (selectionChanged)="onSortChanged($event)">
			</osapp-selector>
		</ng-container>

	</div>

	<!-- Barre de filtrage. -->
	<ng-container *ngIf="filterbarParams[contentIndex] as currentFilterbarParams">
		<osapp-filterbar *ngIf=" !params?.hideFilterbar" [filters]="currentFilterbarParams.filters"
			[hasResetButton]="currentFilterbarParams.hasResetButton" [hidden]="currentFilterbarParams.hidden"
			(onReset)="onFilterbarResetEvent($event)" (onFilterCountChanged)="onFilterCountChanged($event)"
			(onFilterValuesChanged)="onFilterValuesChanged($event)">
		</osapp-filterbar>
	</ng-container>

	<!-- Message si aucun résultat. -->
	<div *ngIf="!hasSearchResult" class="empty-screen flex-col">
		<span class="txt-message">
			<ion-icon name="information-circle" class="no-result"></ion-icon>
			Aucune transmission.
		</span>
	</div>

	<!-- Contenu.-->
	<!-- Liste des transmissions. -->
	<div class="dis-flex flex-col marg-container">
		<calao-virtual-scroll [maxHeight]="params?.nbItems ? (params?.nbItems * 72)+ 'px': undefined" itemSize="72"
			[items]="filteredDocumentsByIndex[contentIndex]"
			[ngClass]="!filterbarParams[contentIndex] || filterbarParams[contentIndex].hidden ? '' : 'open-filter'">
			<ng-template let-transmission="$implicit">
				<ion-item-sliding #itemSliding *ngIf="!!transmission" [disabled]="params?.swipeDisabled">
					<ion-item class="ion-no-padding" (click)="onTransmissionClicked(transmission)">
						<calao-conflict-list-marker *ngIf="transmission._conflicts"></calao-conflict-list-marker>
						<div class="infos-transmission">
							<div class="text-container"
								[ngClass]="readStatusesByTransmissionId.get(transmission._id)?.dataRev === transmission._rev ? '' : 'not-read'">
								<ion-label *ngIf="!params?.model && patientByTransmissionIds.has(transmission._id)">
									{{ patientByTransmissionIds.get(transmission._id) | contactName }}</ion-label>
								<div *ngIf="!transmission.closeDate || !transmission.closeUserId" class="actif">
									<span class="actif">
										<ion-icon name="alert" class="alert-circle"></ion-icon>
										<ion-note class="no-margin">Non clôturée</ion-note>
									</span>
								</div>
								<ion-note class="align-note txt-info">{{ transmission.transmissionType }}</ion-note>
								<ion-note class="avatar-align date-note">
									{{ transmission.createdDate | dateWithLocale:"fr-FR": C_TRANSMISSION_DATE_FORMAT }}</ion-note>
							</div>

							<div class="transmissions-align">
								<div class="author-area">
									<ng-container *ngIf="avatarsByAuthorPathMap.get(transmission.authorPath) as authorAvatar">
										<div class="avatar-align">
											<avatar [src]="authorAvatar" cssClass="round" slot="end">
											</avatar>
										</div>
									</ng-container>
									<ion-note>{{ authorsById.get(getAuthorId(transmission)) | contactName }}</ion-note>
								</div>
							</div>
						</div>


						<!-- Bouton des options de l'item. -->
						<ion-button class="btn-no-margin btn-more" fill="clear" slot="end"
							(click)="openOrCloseItemSliding(itemSliding, $event)">
							<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
						</ion-button>
					</ion-item>

					<!-- Options des items.-->
					<ion-item-options side="end" (ionSwipe)="openOrCloseItemSliding(itemSliding)">
						<ion-button class="remove-btn" color="danger" (click)="onRemoveTransmission(transmission, itemSliding)">
							<ion-icon name="trash"></ion-icon>
							Supprimer
						</ion-button>
					</ion-item-options>
				</ion-item-sliding>
			</ng-template>
		</calao-virtual-scroll>
	</div>

</ng-template>