<!-- Mode Edit (!RO) -->
<osapp-date-time *ngIf="!params.readOnly" [params]="params" [(model)]="fieldValue" (modelChange)="onDateChange()"
	[ngClass]="params.cssClass ? params.cssClass : 'ion-no-padding'">
</osapp-date-time>

<!-- Mode Visu (RO) -->
<ion-item lines="none" *ngIf="params.readOnly" [ngClass]="params.cssClass ? params.cssClass : 'ion-no-padding'">
	<ion-label *ngIf="params.label">{{params.label}}</ion-label>
	<ion-icon *ngIf="params.icon && fieldValue" [name]="params.icon" class="margelect"></ion-icon>
	<ion-label>{{value | dateWithLocale : 'fr-FR' : params.displayFormat}}</ion-label>
</ion-item>