import { ChangeDetectorRef, Component } from '@angular/core';
import { Keyboard } from '@capacitor/keyboard';
import { IonItemSliding } from '@ionic/angular';
import { ArrayHelper, ComponentBase } from '../helpers';
import { ESortOrder } from './ESortOrder';
import { IItemOption } from './forms';
import { ISearchOptions } from './search';
import { IDataSource, IStoreDocument } from './store';

/** Composant générique pour les listes qui permet d'automatiser certains points.
 * Deuxième du nom car le premier utilise le DynamicPage et n'est donc pas compatible avec la bonne façon de faire.
 * todo : À renommer quand `ListComponentBase` ne sera plus utilisé. https://dev.azure.com/calaosoft/osapp-project/_workitems/edit/2805
 */
@Component({ template: "" })
export abstract class ListComponentBase2<T extends IStoreDocument> extends ComponentBase {

	//#region FIELDS

	/** Indique de quelle manière on veut trier les éléments. */
	protected meSortOrder: ESortOrder;
	/** Source de données pour les requêtes sur les bases de données. */
	protected moDataSource: IDataSource;

	//#endregion

	//#region PROPERTIES

	/** Contient tous les documents. */
	private maDocuments: T[];
	public get documents(): T[] { return this.maDocuments; }
	public set documents(paDocuments: T[]) {
		if (!ArrayHelper.areArraysFromDatabaseEqual(this.maDocuments, paDocuments)) {
			this.sortDocuments(paDocuments);
			this.maDocuments = paDocuments;
			this.onFilteredDocumentsChanged(Array.from(this.maDocuments)); // `Array.from` permet d'éviter des bugs de rafraîchissement.
			this.detectChanges();
		}
	}

	/** Contient les documents filtrés. */
	private maFilteredDocuments: T[] = [];
	public get filteredDocuments(): T[] { return this.maFilteredDocuments; }
	public set filteredDocuments(paNewValues: T[]) {
		if (!ArrayHelper.areArraysFromDatabaseEqual(this.maFilteredDocuments, paNewValues))
			this.maFilteredDocuments = Array.from(paNewValues);
	}
	/** Options pour le composant de recherche. */
	public searchOptions: ISearchOptions<T>;
	/** Indique si on a des résultats après une recherche ou non. */
	public hasSearchResult = true;
	/** Clé désignant l'ordre de tri des documents. */
	public sortKey: keyof T;

	//#endregion

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	/** Crée et retourne un objet d'options pour le composant de recherche qui s'appuiera sur les valeurs de cet objet. */
	protected abstract createSearchOptions(): ISearchOptions<T>;

	/** Ferme le clavier. */
	protected closeKeyboard(): void {
		Keyboard.hide().catch(() => { });
	}

	/** Réception du changement du tableau des documents filtrés depuis le composant de recherche.
	 * @param paChanges Nouveau tableau contenant les documents filtrés.
	 */
	public onFilteredDocumentsChanged(paChanges: T[]): void {
		this.hasSearchResult = ArrayHelper.hasElements(paChanges);
		this.filteredDocuments = paChanges;
	}

	/** Ouvre une nouvelle page pour afficher un document après un clic sur l'item associé.
	 * @param poDocument Document qu'on souhaite afficher dans une nouvelle page.
	 */
	public onItemClicked(poDocument: T): void {
		this.closeKeyboard();
	}

	/** Lance les traitements de l'option cliquée.
	 * @param poDocument Document sur lequel on a cliqué l'option à effectuer.
	 * @param psAction Action à effectuer.
	 * @param poItemSliding Objet d'options sur lequel on a cliqué.
	 * @param poOption Objet contenant les informations de l'objet d'options cliqué, optionnel.
	 */
	public onItemOptionClicked(poDocument: T, psAction: string, poItemSliding: IonItemSliding, poOption?: IItemOption): void {
		console.debug(`LCB.C:: itemOption cliqué sur l'instance '${this.getInstanceId()}' ; Action '${psAction} à réaliser sur le document :`, poDocument, "avec l'option :", poOption);
		this.openOrCloseItemSliding(poItemSliding);
	}

	/** Ouvre ou ferme un itemSliding en fonction de l'état avant slide.\
	 * On peut également stopper la propagation de l'événement de clic.
	 * @param poItemSliding Objet d'options qu'on veut ouvrir ou fermer (animation de swipe).
	 * @param poEvent Événement de clic à stopper si renseigné.
	 */
	public async openOrCloseItemSliding(poItemSliding: IonItemSliding, poEvent?: MouseEvent): Promise<void> {
		if (poEvent)
			poEvent.stopPropagation();	// Empêche la possible navigation vers l'item cliqué.

		// Si l'item est ouvert, la valeur est strictement supérieure à 0, sinon c'est que l'item est fermé.
		const lnAmountOpenPixels: number = await poItemSliding.getOpenAmount();

		if (lnAmountOpenPixels > 0) // Item ouvert, on veut le fermer
			poItemSliding.close();
		else // Item fermé, on veut l'ouvrir.
			poItemSliding.open("end");
	}

	/** Trie le tableau des documents en fonction d'une clé.
	 * @param paDocuments Tableau de documents à trier.
	 */
	protected sortDocuments(paDocuments: T[] = this.filteredDocuments): void {
		const lnStart: number = performance.now();

		ArrayHelper.dynamicSort(paDocuments, this.sortKey, this.meSortOrder);
		console.debug(`LCB.C:: Array sorted in ${Math.round(performance.now() - lnStart)}ms.`);
	}

	/** Permet de traquer l'identifiant du document afin de rafraîchir l'affichage si celui-ci change.
	 * @param pnIndex Index du traitement.
	 * @param poDocument Données du document.
	 */
	public trackById(pnIndex: number, poDocument: T): string {
		return poDocument._id;
	}

	//#endregion
}