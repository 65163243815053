<div class="component-recherche-patients__container">
	<div *ngIf="displayCount" class="component-recherche-patients__header">
		<small>{{ patientsCountMessage }}</small>
	</div>
	<div class="component-recherche-patients__filtres" [ngClass]="{'mobileViewForce': mobileViewForce}">
		<lua-recherche (onValueChange)="filterPatients($event)" iconeDefault="search" [secondaryAction]="false"
			[searchText]="searchedValue">
		</lua-recherche>
	</div>
	<ng-container *ngIf="patientsCount > 0">
		<di-liste-patients [mobileViewForce]="mobileViewForce" [patients]="filteredPatients"
			(onClickLigne)="onClickPatient($event)"></di-liste-patients>
	</ng-container>
	<ng-container *ngIf="patientsCount === 0">
		<div>
			<lua-message-aucune-donnee [mainText]="noResultMessage" iconName="group" [subText]=""
				buttonLabel="Nouveau patient" (onClick)="navigateToCreatePatient()"></lua-message-aucune-donnee>
		</div>
	</ng-container>
</div>